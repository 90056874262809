import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AgentLovComponent } from 'src/app/components/common/agent-lov/agent-lov.component';
import { BmaLovComponent } from 'src/app/components/common/bma-lov/bma-lov.component';
import { ProjectLovComponent } from 'src/app/components/common/project-lov/project-lov.component';
import { RefLovComponent } from 'src/app/components/common/ref-lov/ref-lov.component';
import { SrcCdLovComponent } from 'src/app/components/common/src-cd-lov/src-cd-lov.component';
import { SrcExtLovComponent } from 'src/app/components/common/src-ext-lov/src-ext-lov.component';

import { APICallService } from 'src/app/services/api-call.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { ClientGroupLovDialog } from 'src/app/components/common/client-group-lov-dialog/client-group-lov-dialog.component';
import { FormService } from 'src/app/services/form.service';
import { DialogBoxService } from 'src/app/services/dialog-box.service';
import { DatePipe } from '@angular/common';

@Component({
    templateUrl: './generate-voucher-dialog.component.html',
    styleUrls: ['./generate-voucher-dialog.component.css'],
})
export class GenerateVoucherDialogComponent {

    private moduleId: string = 'BMM158';
    private datePipe = new DatePipe('en-us');

    public minEffDate: Date;
    public maxEffDate!: Date | undefined;
    public minExpDate!: Date | undefined;

    public paymentMethodRequired: boolean = false;

    public generateVoucherCodeForm = this.formBuilder.group({
        lineCd: ["", [Validators.required]],
        lineDisplay: [""],
        sublineCd: ["", [Validators.required]],
        sublineDisplay: [""],
        planCd: ["", [Validators.required]],
        planDisplay: [""],
        clientTypeGroup: [""],
        clientTypeGroupDisplay: ["", [Validators.required]],
        count: ["", [Validators.required]],
        effectiveDate: [new Date(), [Validators.required]],
        expiryDate: [""],
        withPayment: ['N'],
        paymentMethod: [""],
        projectCode: [""],
        agent: [""],
        bma: [""],
        referrer: [""],
        sourceCode: [""],
        sourceExtension: [""],
        projectCd: [""],
        agentCd: [""],
        bmaCd: [""],
        referrorCd: [""],
        srcCd: [""],
        srcExtCd: [""],
    });

    constructor(
        // PUBLIC
        @Inject(MAT_DIALOG_DATA) public dataIn: any,

        // PRIVATE
        private formBuilder: FormBuilder,
        private matDialogRef: MatDialogRef<GenerateVoucherDialogComponent>,
        private _matDialog: MatDialog,
        private apiCallService: APICallService,
        private jsonDataService: JsonDataService,
        private securityService: SecurityService,
        private dialogBoxService: DialogBoxService,
        private formService: FormService,
    ) {
        this.generateVoucherCodeForm.patchValue({
            ...this.dataIn.voucherCodeFilterForm
        });
        this.minEffDate = new Date();
        this.minExpDate = this.addDay(new Date(), 1);
        this.generateVoucherCodeForm.get('effectiveDate')?.valueChanges.subscribe((res) => {
            this.minExpDate = this.addDay(new Date(res), 1);
        });
        this.generateVoucherCodeForm.get('expiryDate')?.valueChanges.subscribe((res) => {
            this.maxEffDate = res ? this.addDay(new Date(res), -1) : undefined;
        });
        this.generateVoucherCodeForm.get('paymentMethod')?.disable();
        this.withPaymentChanges();
    }

    private withPaymentChanges(): void {
        this.generateVoucherCodeForm.get("withPayment")?.valueChanges.subscribe((control: any) => {
            if (control === 'N') {
                this.generateVoucherCodeForm.patchValue({
                    paymentMethod: null,
                });
                this.generateVoucherCodeForm.get('paymentMethod')?.disable();
                this.generateVoucherCodeForm.get('paymentMethod')?.clearValidators();
                this.generateVoucherCodeForm.get('paymentMethod')?.updateValueAndValidity({ onlySelf: true, emitEvent: false });
                this.paymentMethodRequired = false;
                return;
            }
            this.generateVoucherCodeForm.get('paymentMethod')?.enable();
            this.generateVoucherCodeForm.get('paymentMethod')?.setValidators(Validators.required);
            this.generateVoucherCodeForm.get('paymentMethod')?.updateValueAndValidity({ onlySelf: true, emitEvent: false });
            this.paymentMethodRequired = true;
        });
    }

    private addDay(date: Date, day: number): Date {
        date = new Date(date);
        date.setDate(date.getDate() + day);
        return date;
    }

    private paymentOptsData(): any[] {
        return this.jsonDataService.data.paymentOptions?.sort((a: any, b: any) => a.paytCd - b.paytCd).map((data: any) => { return { cd: data.paytCd, disp: `${data.paytCd?.toString()?.padStart(2,'0')} - ${data.paytDesc}` } }) ?? [];
    }

    public selections = {
        withPaymentTypes: [
            { cd: 'N', name: 'No' },
            { cd: 'Y', name: 'Yes' },
        ],
        paymentOptions: [
            { cd: null, disp: '' },
            ...this.paymentOptsData(),
        ]
    };

    private refreshTable: boolean = false;

    public openLineLOV(option: string): void {
        if (option === 'project') {
            const LOVOPTIONS: { [key: string]: any } = {
                disableClose: true,
                width: '512px',
                data: {
                    moduleId: this.moduleId
                },
            };
            this._matDialog.open(ProjectLovComponent, LOVOPTIONS).afterClosed().subscribe((lovData: any) => {
                if (lovData?.button === 'OK') {
                    if (lovData?.content?.PROJECT_CD) {
                        let data = lovData?.content;
                        this.generateVoucherCodeForm.patchValue({
                            projectCd: data.PROJECT_CD,
                            projectCode: `${data.PROJECT_CD} - ${data.PROJECT_DESC}`,
                        });
                    }
                    else {
                        this.generateVoucherCodeForm.patchValue({
                            projectCd: null,
                            projectCode: null,
                        });
                    }
                }
            });
        }
        else if (option === 'agent') {
            const LOVOPTIONS: { [key: string]: any } = {
                disableClose: true,
                width: '512px',
                data: {
                    moduleId: this.moduleId
                },
            };
            this._matDialog.open(AgentLovComponent, LOVOPTIONS).afterClosed().subscribe((lovData: any) => {
                if (lovData?.button === 'OK') {
                    if (lovData?.content?.AGENT_CD) {
                        let data = lovData?.content;
                        this.generateVoucherCodeForm.patchValue({
                            agentCd: data.AGENT_CD,
                            agent: `${data.AGENT_CD} - ${data.AGENT_NAME}`,
                        });
                    }
                    else {
                        this.generateVoucherCodeForm.patchValue({
                            agentCd: null,
                            agent: null,
                        });
                    }
                }
            });
        }
        else if (option === 'bma') {
            const LOVOPTIONS: { [key: string]: any } = {
                disableClose: true,
                width: '512px',
                data: {
                    table: "BMA_CD",
                    moduleId: this.moduleId,
                    lpad: 6,
                },
            };
            this._matDialog.open(BmaLovComponent, LOVOPTIONS).afterClosed().subscribe((lovData: any) => {
                if (!lovData.option) {
                    let data = lovData.content;
                    if (data) {
                        this.generateVoucherCodeForm.patchValue({
                            bmaCd: data.BMA_CD,
                            bma: `${data.BMA_CD} - ${data.BMA_DESC}`,
                        });
                    }
                    else {
                        this.generateVoucherCodeForm.patchValue({
                            bmaCd: null,
                            bma: null,
                        });
                    }
                }
            });
        }
        else if (option === 'referror') {
            const LOVOPTIONS: { [key: string]: any } = {
                disableClose: true,
                width: '512px',
                data: {
                    col: 'CD',
                    moduleId: this.moduleId,
                    lpad: 6,
                }
            };
            this._matDialog.open(RefLovComponent, LOVOPTIONS).afterClosed().subscribe((lovData: any) => {
                if (lovData?.content?.REFERROR_CD) {
                    const data = lovData?.content;
                    this.generateVoucherCodeForm.patchValue({
                        referrorCd: data.REFERROR_CD,
                        referrer: `${data.REFERROR_CD?.toString().padStart(4, 0)} - ${data.REFERROR_DESC}`,
                    });
                }
                else {
                    this.generateVoucherCodeForm.patchValue({
                        referrorCd: null,
                        referrer: null,
                    });
                }
            });
        }
        else if (option === 'src') {
            const LOVOPTIONS: { [key: string]: any } = {
                disableClose: true,
                width: '512px',
                data: {
                    col: 'CD',
                    moduleId: this.moduleId
                }
            };
            this._matDialog.open(SrcCdLovComponent, LOVOPTIONS).afterClosed().subscribe((lovData: any) => {
                if (lovData?.content?.SRC_CD) {
                    const data = lovData?.content;
                    this.generateVoucherCodeForm.patchValue({
                        srcCd: data.SRC_CD,
                        sourceCode: `${data.SRC_CD?.toString().padStart(3, 0)} - ${data.SRC_CD_DESC}`,
                    });
                }
                else {
                    this.generateVoucherCodeForm.patchValue({
                        srcCd: null,
                        sourceCode: null,
                    });
                }
            });
        }
        else if (option === 'srcExt') {
            const LOVOPTIONS: { [key: string]: any } = {
                disableClose: true,
                width: '512px',
                data: {
                    col: 'CD',
                    moduleId: this.moduleId,
                    lpad: 6,
                }
            };
            this._matDialog.open(SrcExtLovComponent, LOVOPTIONS).afterClosed().subscribe((lovData: any) => {
                if (lovData?.content?.SRC_EXT_CD) {
                    const data = lovData?.content;
                    this.generateVoucherCodeForm.patchValue({
                        srcExtCd: data.SRC_EXT_CD,
                        sourceExtension: `${data.SRC_EXT_CD?.toString().padStart(3, 0)} - ${data.SRC_EXT_DESC}`,
                    });
                }
                else {
                    this.generateVoucherCodeForm.patchValue({
                        srcExtCd: null,
                        sourceExtension: null,
                    });
                }
            });
        }
        else if (option === 'clientTypeGroup') {
            const LOVOPTIONS: { [key: string]: any } = {
                disableClose: true,
                width: '512px',
                data: {
                    table: 'CLIENT_GROUP',
                    moduleId: this.moduleId,
                },
            };
            this._matDialog.open(ClientGroupLovDialog, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
                let output = dataOutput.content;
                if (output.CLIENT_CD) {
                    this.generateVoucherCodeForm.patchValue({
                        clientTypeGroup: output.CLIENT_CD,
                        clientTypeGroupDisplay: `${output.CLIENT_CD} - ${output.CLIENT_DESC}`,
                    });
                }
                else {
                    this.generateVoucherCodeForm.patchValue({
                        clientTypeGroup: null,
                        clientTypeGroupDisplay: null,
                    });
                }
            });
        }
    }

    public onCancel(): void {
        this.matDialogRef.close({ refreshTable: this.refreshTable });
    }

    public onProceed(): void {
        this.formService.hideFormMsg("generate-voucher-code-error-message");
        if (this.generateVoucherCodeForm.valid) {
            this.jsonDataService.contorlLoading(true);
            let values = this.prepareData({ ...this.generateVoucherCodeForm.getRawValue() });
            this.apiCallService.generateVouchers(values).subscribe({
                next: (response: any) => {
                    this.securityService.checkRequestKeyResponse(response, () => {
                        this.securityService.hasValidCsrfToken(response, () => {
                            if (response.status === "SUCCESS") {
                                this.jsonDataService.contorlLoading(false);
                                this.dialogBoxService.open({
                                    messageType: "S",
                                    content: `Generate success. ${values.count} code(s) has been generated.`,
                                    buttons: [{
                                        text: "OK",
                                        action: () => { this.refreshTable = true; }
                                    }]
                                }, true);
                            }
                            else {
                                this.jsonDataService.contorlLoading(false);
                                this.dialogBoxService.open({
                                    messageType: "E",
                                    content: `Generate failed.`,
                                    buttons: [{
                                        text: "OK",
                                        action: () => { }
                                    }]
                                }, true);
                            }
                        });
                    });
                },
                error: (data: any) => {
                    this.securityService.checkRequestKeyResponse(data, () => {
                        this.jsonDataService.contorlLoading(false);
                        this.dialogBoxService.open({
                            messageType: "E",
                            content: `Generate failed.`,
                            buttons: [{
                                text: "OK",
                                action: () => { }
                            }]
                        }, true);
                    });
                }
            });
        }
        else {
            if (this.generateVoucherCodeForm.get('effectiveDate')?.errors || this.generateVoucherCodeForm.get('expiryDate')?.errors) {
                this.formService.showFormMsg("generate-voucher-code-error-message", "Invalid Effective/Expiry Date. Please input valid dates.", "E");
            }
            else {
                this.formService.showFormMsg("generate-voucher-code-error-message", "There are missing information. Please provide necessary information needed.", "E");
            }
            setTimeout(() => {
                this.formService.hideFormMsg("generate-voucher-code-error-message");
            }, 5000);
        }
    }

    private prepareData(data: any): any {
        return {
            lineCd: data.lineCd,
            sublineCd: data.sublineCd,
            planCd: data.planCd,
            clientTypeGroup: data.clientTypeGroup,
            count: data.count,
            effectiveDate: data.effectiveDate ? this.datePipe.transform(new Date(data.effectiveDate), 'yyyy-MM-dd') : "",
            expiryDate: data.expiryDate ? this.datePipe.transform(new Date(data.expiryDate), 'yyyy-MM-dd') : "",
            withPayment: data.withPayment,
            paymentMethod: data.paymentMethod,
            projectCd: data.projectCd,
            agentCd: data.agentCd,
            bmaCd: data.bmaCd,
            referrorCd: data.referrorCd,
            srcCd: data.srcCd,
            srcExtCd: data.srcExtCd,
        };
    };

}
