import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

@Component({
  selector: 'user-lov-dialog',
  templateUrl: './user-lov-dialog.component.html',
  styleUrls: ['./user-lov-dialog.component.css']
})
export class UserLovDialog {

  moduleId: string = 'BMM013';

  constructor(private apiCallService: APICallService,
    private jsonDataService: JsonDataService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    private securityService: SecurityService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<UserLovDialog>) {
    try {
      this.getUsers();
    } catch (e) {
    console.error(e);
      
    }
  }

  userTblConfig: any = {
    cols: [
      {
        key: "USER_ID",
        header: "User ID",
        dataType: "string"
      },
      {
        key: "USER_NAME",
        header: "User Name",
        dataType: "string"
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15
  }

  getUsers() {
    try {
      this.userTblConfig.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.apiCallService.getAllUserID({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "LOV"
      }).subscribe((data: any) => {
        this.securityService.checkRequestKeyResponse(data, () => {
          this.securityService.hasValidCsrfToken(data, ()=>{
            if (data.status === "SUCCESS") {
              data = JSON.parse(this.jsonDataService.decrypt(data.response));
              this.jsonDataService.contorlLoading(false);
              this.userTblConfig.tblData = data.filter((e: any) => e.USER_TYPE != 'P');
              this.userTblConfig.loading = false;
            } else {
              this.jsonDataService.contorlLoading(false);
              this.userTblConfig.loading = false;
              this.appMessageService.showAppMessage(data.message, "error");
            }
          });
        });
      });
    } catch (e) {
    console.error(e);
      
    }
  }

  onRowClick(ev: any) {
    try {
      this.data = ev;
    } catch (e) {
    console.error(e);
      
    }
  }

  onClose() {
    try {
      if (this.data == '' || this.data == null) {
        this._dialogRef.close({ content: '' });
      } else {
        this._dialogRef.close({ content: this.data, moduleId: this.moduleId });
      }
    } catch (e) {
    console.error(e);
      
    }
  }

  onBack() {
    try {
      this._dialogRef.close({ content: '', option: 'back' });
    } catch (e) {
    console.error(e);
      
    }
  }
}