<section id="voucher-code-maintenance">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <form id="voucher-code-filter-form" [formGroup]="voucherCodeFilterForm">
            <div class="form">
                <div class="row row-2">
                    <div class="field required">
                        <label>Line</label>
                        <div class="with-button">
                            <input formControlName="lineDisplay" class="no-color" readonly />
                            <button matSuffix class="btn btn-search" type="button" (click)="openLovDialog('line')" [disabled]="btns.lineSearchDisabled">
                                <mat-icon>search</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="field required">
                        <label>Subline</label>
                        <div class="with-button">
                            <input formControlName="sublineDisplay" class="no-color" readonly />
                            <button matSuffix class="btn btn-search" type="button" (click)="openLovDialog('subline')" [disabled]="btns.sublineSearchDisabled">
                                <mat-icon>search</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row row-2">
                    <div class="field required">
                        <label>Plan</label>
                        <div class="with-button">
                            <input formControlName="planDisplay" class="no-color" readonly />
                            <button matSuffix class="btn btn-search" type="button" (click)="openLovDialog('plan')" [disabled]="btns.planSearchDisabled">
                                <mat-icon>search</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="field">
                        <label>Client Type Group</label>
                        <div class="with-button">
                            <input formControlName="clientTypeGroupDisplay" class="no-color" readonly />
                            <button matSuffix class="btn btn-search" type="button" (click)="openLovDialog('clientTypeGroup')" [disabled]="btns.clientTypeSearchDisabled">
                                <mat-icon>search</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <osp-table [tblConfig]="tblConfig" (onRowClick)="onVoucherCodeFormRowClick($event)"></osp-table>
        <form [formGroup]="voucherCodeForm">
            <div class="form">
                <div class="row row-2">
                    <div class="field">
                        <label>Batch No</label>
                        <input formControlName="batchNo" readonly />
                    </div>
                    <div class="field">
                        <label>Active</label>
                        <input formControlName="active" readonly />
                    </div>
                </div>
                <div class="row row-2">
                    <div class="field">
                        <label>Voucher Code</label>
                        <input formControlName="voucherCode" readonly />
                    </div>
                    <div class="field">
                        <label>Client Type Group</label>
                        <input formControlName="clientTypeGroup" readonly />
                    </div>
                </div>
                <div class="row row-2">
                    <div class="field">
                        <label>With Payment</label>
                        <input formControlName="withPayment" readonly />
                    </div>
                    <div class="field">
                        <label>Payment Method</label>
                        <input formControlName="paymentMethod" readonly />
                    </div>
                </div>
                <div class="row row-2">
                    <div class="field">
                        <label>Effective Date</label>
                        <input formControlName="effectiveDate" readonly />
                    </div>
                    <div class="field">
                        <label>Expiry Date</label>
                        <input formControlName="expiryDate" readonly />
                    </div>
                </div>
                <div class="row row-2">
                    <div class="field">
                        <label>Used By</label>
                        <input formControlName="usedBy" readonly />
                    </div>
                    <div class="field">
                        <label>Used Date</label>
                        <input formControlName="usedDate" readonly />
                    </div>
                </div>
            </div>
            <div class="form">
                <div class="row row-2">
                    <div class="field">
                        <label>Project Code</label>
                        <input formControlName="projectCode" readonly />
                    </div>
                    <div class="field">
                        <label>Agent</label>
                        <input formControlName="agent" readonly />
                    </div>
                </div>
                <div class="row row-2">
                    <div class="field">
                        <label>BMA</label>
                        <input formControlName="bma" readonly />
                    </div>
                    <div class="field">
                        <label>Referrer</label>
                        <input formControlName="referrer" readonly />
                    </div>
                </div>
                <div class="row row-2">
                    <div class="field">
                        <label>Source Code</label>
                        <input formControlName="sourceCode" readonly />
                    </div>
                    <div class="field">
                        <label>Source Extension</label>
                        <input formControlName="sourceExtension" readonly />
                    </div>
                </div>
                <div class="row">
                    <div class="field">
                        <label>Remarks</label>
                        <input formControlName="remarks" readonly />
                    </div>
                </div>
                <div class="row row-2">
                    <div class="field">
                        <label>Create User</label>
                        <input formControlName="createUser" readonly />
                    </div>
                    <div class="field">
                        <label>Create Date</label>
                        <input formControlName="createDate" readonly />
                    </div>
                </div>
                <div class="row row-2">
                    <div class="field">
                        <label>Last Update User</label>
                        <input formControlName="lastUpdateUser" readonly />
                    </div>
                    <div class="field">
                        <label>Last Update</label>
                        <input formControlName="lastUpdate" readonly />
                    </div>
                </div>
            </div>
            <form [formGroup]="voucherEmailForm">
                <div class="form">
                    <p id="save-email-voucher-code-error-message" class="form-error-msg"></p>
                    <div class="row row-2">
                        <div class="field center">
                            <label>Email Address</label>
                            <div class="side-button">
                                <input formControlName="email" />
                                <button class="btn btn2" type="button" [disabled]="disableSave()" (click)="onSave()">Save</button>
                            </div>
                        </div>
                    </div>
                    <div class="form-btns">
                        <button class="btn btn2" type="button" (click)="onDownload()">Download list for Update</button>
                        <button class="btn btn2" type="button" (click)="onUpload()">Upload list for Update</button>
                    </div>
                </div>
            </form>
            <div class="form-btns">
                <button class="btn btn2" type="button" (click)="onGenerate()">Generate</button>
                <button class="btn btn2" type="button" (click)="onDeactivate()">Deactivate</button>
            </div>
        </form>
    </div>
</section>
