<div class="mtn-container">
    <div class="mtn-title">
        <p>{{ moduleName }}</p>
    </div>
    <div *ngIf="showForm" class="form-content">
        <form [formGroup]="forms.carTypePerilLovForm" class="form">
            <div class="form-container-grid top-form triple">
                <div class="field required">
                    <label>Client Group</label>
                </div>
                <div class="">
                    <input formControlName="clientGrpCdDisp" >
                    <button matSuffix class="btn btn-search" type="button" (click)="openLov('clienttype')" >
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
                <div class="field required">
                    <label>Car Type</label>
                </div>
                <div class="">
                    <input formControlName="carTypeCdDisp" >
                    <button matSuffix class="btn btn-search" type="button" (click)="openLov('cartype')" >
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
                <div class="field required">
                    <label>Peril</label>
                </div>
                <div class="">
                    <input formControlName="perilCdDisp" >
                    <button matSuffix class="btn btn-search" type="button" (click)="openLov('peril')" >
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
            </div>
        </form>
    </div>
    <osp-table [tblConfig]="sacTblConfig" (onRowClick)="onRowClick($event)"></osp-table>
    <div id="sac-maintenance-loading">
        <div class="form-loader"></div>
    </div>
    <div *ngIf="showForm" class="form-content">
        <form [formGroup]="forms.sacForm" class="form">
            <div id="sac-error-message"></div>
            <div class="form-container-grid top-form">
                <div class="field required">
                    <label>Amount Covered</label>
                </div>
                <div class="">
                    <input class="currency" formControlName="tsiAmt" (focus)="_formService.setFieldToNumberWithDecimal(forms.sacForm, 'tsiAmt', $event)"
                    (keypress)="allowNumericDigitsOnly($event)" amount >
                </div>
                <div class="field required">
                    <label>Active</label>
                </div>
                <div class="">
                    <select formControlName="active">
                        <option *ngFor="let activeType of selections.activeTypes" [value]="activeType.cd">
                            {{activeType.name}}
                        </option>
                    </select>
                </div>
                <div class="field required">
                    <label>Premium Amount</label>
                </div>
                <div class="">
                    <input class="currency" formControlName="premAmt" (focus)="_formService.setFieldToNumberWithDecimal(forms.sacForm, 'premAmt', $event)"
                    (keypress)="allowNumericDigitsOnly($event)" amount >
                </div>
                <div class="field required">
                    <label>Default</label>
                </div>
                <div class="">
                    <select formControlName="default">
                        <option *ngFor="let defaultType of selections.defaultTypes" [value]="defaultType.cd">
                            {{defaultType.name}}
                        </option>
                    </select>
                </div>
                <div class="field">
                    <label>Maximum Number of Seats</label>
                </div>
                <div class="">
                    <input type="number" posNumInput formControlName="maxNoSeats" (keypress)="allowNumericDigitsOnly($event)" >
                </div>
                <div class="empty"></div>
                <div class="empty"></div>
                <div class="field">
                    <label>Remarks</label>
                </div>
                <div class="span">
                    <input formControlName="remarks" >
                </div>
                <div class="field">
                    <label>Last Update User</label>
                </div>
                <div class="">
                    <input formControlName="lastUpdateUser" >
                </div>
                <div class="field">
                    <label>Last Update</label>
                </div>
                <div class="">
                    <input formControlName="lastUpdate" >
                </div>
            </div>
        </form>
        <div class="btn-grp">
            <button *ngIf="btns.sac.updateDisabled" class="btn btn2" type="button"  (click)="onAdd()" [disabled]="btns.sac.addDisabled" >ADD</button>
            <button *ngIf="!btns.sac.updateDisabled" class="btn btn2" type="button" (click)="onUpdate()" >UPDATE</button>
            <button class="btn btn2" type="button" (click)="onDelete()" [disabled]='btns.sac.deleteDisabled'>DELETE</button>
        </div>
        <div class="mtn-title"></div>
        <div class="btn-grp">
            <button class="btn btn2" type="button" [disabled]='btns.sac.saveDisabled' (click)="onSave()">SAVE</button>
        </div>
    </div>
</div>