import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { Observable } from 'rxjs/internal/Observable';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { AccountTypeLovComponent } from '../../common/acc-type-lov/acc-type-lov.component';
import { ClientGroupLovDialog } from '../../common/client-group-lov-dialog/client-group-lov-dialog.component';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { CheckLovComponent } from '../../common/guard/check-lov/check-lov.component';

@Component({
  selector: 'client-group-per-user-type-maintenance',
  templateUrl: './client-group-per-user-type-maintenance.component.html',
  styleUrls: ['./client-group-per-user-type-maintenance.component.css']
})
export class ClientGroupPerUserTypeMaintenanceComponent implements OnInit {


  addUpdateFlag: boolean = true;
  addDisabled: boolean = true;
  datePipe = new DatePipe('en-us');
  selectedRow: any;
  tempNewData: any = [];
  tempUpdateData: any = [];
  userId: string = this.userDetailService.userId;
  errorMessage: string = '';
  saveDisabler: boolean = true;
  deleteDisabler: boolean = true;
  formHider: boolean = false;
  moduleId: string = 'BMM132';
  moduleName = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId === 'BMM132' })[0].moduleDesc.toUpperCase();
  itemLevel1DropDown: any = this.jsonDataService.data.refCds.filter((data: any) => { return data?.rvDomain === 'ITEM_LEVEL1' });
  itemLevel2DropDown: any = this.jsonDataService.data.refCds.filter((data: any) => { return data?.rvDomain === 'ITEM_LEVEL2' });
  clientGroupPerUserTypeDbData: any;
  afterSave: boolean = false;
  
  clientGroupPerUserTypeTable: any = {
    cols: [
      {
        key: "EFF_DATE_FROM",
        header: "Effective Date",
        dataType: "date"
      },
      {
        key: "EFF_DATE_TO",
        header: "Expiry Date",
        dataType: "date"
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  clientGroupPerUserTypeForm = this.fb.group({
    USER_TYPE:          [{value: '', disabled: true}, [Validators.required]],
    ITEM_LEVEL1:        [{value: '', disabled: true}, [Validators.required]],
    ITEM_LEVEL2:        ['', [Validators.required]],
    CLIENT_CD:          [{value: '', disabled: true}, [Validators.required]],
    ACTIVE_TAG:         ['A', [Validators.required]],
    EFF_DATE_FROM:      [moment(new Date()), {validators: [Validators.required, this.effDatesValidator(), this.concurrentValidator()], updateOn: 'blur'}],
    EFF_DATE_TO:        ['', {validators: [this.effDatesValidator(), this.concurrentValidator()], updateOn: 'blur'}],
    REMARKS:            [''],
    LAST_USER:          [{value: '', disabled: true}],
    LAST_UPDATE:        [{value: '', disabled: true}],
  });

  constructor(
    private fb: FormBuilder,
    private api: APICallService,
    private dialog: MatDialog,
    private jsonDataService: JsonDataService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    private formService: FormService,
    private securityService: SecurityService,
  ) { }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempNewData!='' || this.tempUpdateData!='') {
      return false;
    } else {
      return true;
    }
  }

  effDatesValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const effDateFrom = this.clientGroupPerUserTypeForm?.get("EFF_DATE_FROM")?.value;
      const effDateTo = this.clientGroupPerUserTypeForm?.get("EFF_DATE_TO")?.value;
      if(effDateFrom && effDateTo && effDateTo.isBefore(effDateFrom)) {
        return { "effDate": "Expiry Date must not be earlier than Effective Date." }
      }
      return null;
    }
  }

  concurrentValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const effDateFrom = this.clientGroupPerUserTypeForm?.get("EFF_DATE_FROM")?.value;
      const effDateTo = this.clientGroupPerUserTypeForm?.get("EFF_DATE_TO")?.value;
      const formData = this.clientGroupPerUserTypeForm?.getRawValue();
      let isConcurrent: boolean = false;
      if(formData?.USER_TYPE && formData?.ITEM_LEVEL1 && formData?.ITEM_LEVEL2 && formData?.CLIENT_CD) {
        this.clientGroupPerUserTypeTable.tblData.map((a: any) => {
          const existEffDateFrom = moment(a?.EFF_DATE_FROM, "MM/DD/YYYY");
          const existEffDateTo = moment(a?.EFF_DATE_TO, "MM/DD/YYYY");

          // null yung existing na expiry date equals on going yung existing
          if(!a?.EFF_DATE_TO && effDateFrom && effDateFrom.isAfter(existEffDateFrom) && !effDateFrom.isSame(moment(this.selectedRow?.EFF_DATE_FROM, "MM/DD/YYYY"))) {
            isConcurrent = true;
          }

          // null yung existing tapos before yung effective date
          if(!a?.EFF_DATE_TO && effDateFrom && effDateTo && effDateTo.isAfter(existEffDateFrom) && !effDateFrom.isSame(moment(this.selectedRow?.EFF_DATE_FROM, "MM/DD/YYYY"))) {
            isConcurrent = true;
          }

          // null yung bagong expiry date equals on going yung bago
          if(!effDateTo && effDateFrom && effDateFrom.isBefore(existEffDateFrom) && !effDateFrom.isSame(moment(this.selectedRow?.EFF_DATE_FROM, "MM/DD/YYYY"))) {
            isConcurrent = true;
          }

          // existing yung eff at expiry date dapat between na
          if(a?.EFF_DATE_FROM && a?.EFF_DATE_TO) {

            if(effDateFrom && effDateFrom.isBetween(existEffDateFrom, existEffDateTo) && !effDateFrom.isSame(moment(this.selectedRow?.EFF_DATE_FROM, "MM/DD/YYYY"))) {
              isConcurrent = true;
            }

            if(effDateFrom && effDateTo && existEffDateFrom.isBetween(effDateFrom, effDateTo) && !effDateFrom.isSame(moment(this.selectedRow?.EFF_DATE_FROM, "MM/DD/YYYY"))) {
              isConcurrent = true;
            }

            if(effDateTo && effDateTo.isBetween(existEffDateFrom, existEffDateTo) && !effDateFrom.isSame(moment(this.selectedRow?.EFF_DATE_FROM, "MM/DD/YYYY"))) {
              isConcurrent = true;
            }

            if(effDateFrom && effDateTo && existEffDateTo.isBetween(effDateFrom, effDateTo) && !effDateFrom.isSame(moment(this.selectedRow?.EFF_DATE_FROM, "MM/DD/YYYY"))) {
              isConcurrent = true;
            }

          }

          // same effDate
          if(effDateFrom && existEffDateFrom && effDateFrom.isSame(existEffDateFrom) && !effDateFrom.isSame(moment(this.selectedRow?.EFF_DATE_FROM, "MM/DD/YYYY"))) {
            isConcurrent = true;
          }
          
        });
      }
      if(isConcurrent) {
        return { "concurrent": "Unable to add record. Date of Effectivity is concurrent with another record. Please enter a different range of Date of Effectivity." }
      }
      return null;
    }
  }

  ngOnInit(): void {
    this.getClientGroupPerUserType();
  }

  getClientGroupPerUserType() {
    try {
      // this.clientGroupPerUserTypeTable.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.api.getClientGroupPerUserType({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE"
      }).subscribe((data: any) => {
        this.securityService.checkRequestKeyResponse(data, () => {
          this.securityService.hasValidCsrfToken(data, () => {
            if (data.status === "SUCCESS") {
              data = JSON.parse(this.jsonDataService.decrypt(data.response));
              this.jsonDataService.contorlLoading(false);
              this.clientGroupPerUserTypeDbData = data.map((e: any) => {
                return {...e, EFF_DATE_FROM: new Date(e.EFF_DATE_FROM),
                              EFF_DATE_TO: e.EFF_DATE_TO ? new Date(e.EFF_DATE_TO) : ""}
              });
              this.getValidator();
              if(this.afterSave) {
                this.setTableData();
                this.afterSave = false;
              }
            } else {
                this.jsonDataService.contorlLoading(false);
                // this.clientGroupPerUserTypeTable.loading = false;
                this.appMessageService.showAppMessage(data.message, "error");
            }
          });
        });
      });
    } catch(e) {
      
    }
  }

  onRowClick(ev: any): void {
    try {
      if(ev!=null) {
        this.selectedRow = ev;
        this.addUpdateFlag = false;
        this.formLoader();
      } else {
        this.selectedRow = '';
        this.addUpdateFlag = true;
        this.formClearer();
      }
      this.deleteEnabler();
    } catch(e) {
      
    }
  }

  formLoader() {
    try {
      this.clientGroupPerUserTypeForm.get("EFF_DATE_FROM")?.disable();
      this.clientGroupPerUserTypeForm.patchValue({
        ACTIVE_TAG:         this.selectedRow.ACTIVE_TAG,
        EFF_DATE_FROM:      moment(this.selectedRow.EFF_DATE_FROM, "MM/DD/YYYY"),
        // EFF_DATE_TO:        moment(this.selectedRow.EFF_DATE_TO, "MM/DD/YYYY"),
        EFF_DATE_TO:        moment.isMoment(this.selectedRow.EFF_DATE_TO) ? this.selectedRow.EFF_DATE_TO : !this.selectedRow.EFF_DATE_TO ? "" : moment(this.selectedRow.EFF_DATE_TO, "MM/DD/YYYY"),
        REMARKS:            this.selectedRow.REMARKS,
        LAST_USER:          this.selectedRow.USER_ID,
        LAST_UPDATE:        this.selectedRow.LAST_UPDATE,
      });
    } catch(e) {
      
    }
  }
  
  formClearer() {
    try {
      this.clientGroupPerUserTypeForm.get("EFF_DATE_FROM")?.enable();
      this.clientGroupPerUserTypeForm.markAsUntouched();
      this.clientGroupPerUserTypeForm.patchValue({
        ACTIVE_TAG:         'A',
        EFF_DATE_FROM:      moment(new Date()),
        EFF_DATE_TO:        '',
        REMARKS:            '',
        LAST_USER:          '',
        LAST_UPDATE:        '',
        emitEvent:          false
      });
      this.selectedRow = [];
    } catch(e) {
      
    }
  }

  deleteEnabler() {
    try {
      let tempDeleteFlag = this.tempNewData.filter((e: any) => {
        const selectedEffDate = this.selectedRow?.EFF_DATE_FROM ? moment(this.selectedRow?.EFF_DATE_FROM, "MM/DD/YYYY").format("YYYY-MM-DD") : "";
        return e.EFF_DATE_FROM == selectedEffDate
      });
      if(tempDeleteFlag=='' || tempDeleteFlag==null) {
        this.deleteDisabler = true;
      } else {
        this.deleteDisabler = false;
      }
    } catch(e) {
      
    }
  }

  addRow(): void {
    try {
      this.clientGroupPerUserTypeForm.get("EFF_DATE_FROM")?.updateValueAndValidity();
      this.clientGroupPerUserTypeForm.get("EFF_DATE_TO")?.updateValueAndValidity();
      if(this.clientGroupPerUserTypeForm.valid) {
        this.formService.hideFormMsg("client-group-per-user-type-error-message");
        this.tableInserter();
        this.tempAddInserter();
        this.formClearer();
        this.saveDisabler = false;
      } else {
        this.errorMessageSetter();
      }
    } catch(e) {
      
    }
  }

  tableInserter(): void {
    try {
      const values = this.clientGroupPerUserTypeForm.getRawValue();
      this.clientGroupPerUserTypeTable.tblData.unshift({
        USER_TYPE:          values?.USER_TYPE,
        ITEM_LEVEL1:        values?.ITEM_LEVEL1,
        ITEM_LEVEL2:        values?.ITEM_LEVEL2,
        CLIENT_CD:          values?.CLIENT_CD,
        ACTIVE_TAG:         values?.ACTIVE_TAG,
        EFF_DATE_FROM:      new Date(values?.EFF_DATE_FROM.format("MM/DD/YYYY")),
        // EFF_DATE_TO:        values?.EFF_DATE_TO.format("MM/DD/YYYY"),
        EFF_DATE_TO:        moment.isMoment(values?.EFF_DATE_TO) ? new Date(values?.EFF_DATE_TO.format("MM/DD/YYYY")) : "",
        REMARKS:            values?.REMARKS,
        LAST_USER:          this.userDetailService.userId,
      });
      this.clientGroupPerUserTypeTable.tblData = [...this.clientGroupPerUserTypeTable.tblData];
    } catch(e) {
      
    }
  }

  tempAddInserter(): void {
    try {
      this.tempNewData.push(this.formValues());
    } catch(e) {
      
    }
  }

  formValues(): any {
    try {
      const values = this.clientGroupPerUserTypeForm.getRawValue();
      return {
        USER_TYPE:          values?.USER_TYPE,
        ITEM_LEVEL1:        values?.ITEM_LEVEL1,
        ITEM_LEVEL2:        values?.ITEM_LEVEL2,
        CLIENT_CD:          values?.CLIENT_CD,
        ACTIVE_TAG:         values?.ACTIVE_TAG,
        EFF_DATE_FROM:      values?.EFF_DATE_FROM.format("YYYY-MM-DD"),
        // EFF_DATE_TO:        values?.EFF_DATE_TO.format("MM/DD/YYYY"),
        EFF_DATE_TO:        moment.isMoment(values?.EFF_DATE_TO) ? values?.EFF_DATE_TO.format("YYYY-MM-DD") : "",
        REMARKS:            values?.REMARKS,
        LAST_USER:          this.userDetailService.userId,
      }
    } catch(e) {
      
    }
  }

  errorMessageSetter() {
    try {
      if(this.clientGroupPerUserTypeForm.invalid) {
        for(const field in this.clientGroupPerUserTypeForm.controls) {
          const controlName = this.clientGroupPerUserTypeForm.get(field);
          if(controlName?.errors?.required) {
            this.errorMessage = 'There are missing information. Please provide necessary information needed.';
          }
          if(controlName?.errors?.effDate) {
            this.errorMessage = controlName?.errors?.effDate;
          }
          if(controlName?.errors?.concurrent) {
            this.errorMessage = controlName?.errors?.concurrent;
          }
          if(controlName?.errors?.matDatepickerParse) {
            this.errorMessage = "Invalid Date. Please input a valid date.";
          }
          this.formService.showFormMsg("client-group-per-user-type-error-message", this.errorMessage, "E");
        }
      }
    } catch(e) {
      
    }
  }

  onUpdate(): void {
    try {
      this.clientGroupPerUserTypeForm.get("EFF_DATE_TO")?.updateValueAndValidity();
      if(this.clientGroupPerUserTypeForm.valid) {
        this.updateTable();
        if(this.deleteDisabler) {
          this.updateDbData();
        } else {
          this.updateNewData();
        }
        this.selectedRow = '';
        this.addUpdateFlag = true;
        this.formClearer();
        this.saveDisabler = false;
        this.deleteEnabler();
        this.formService.hideFormMsg('client-group-per-user-type-error-message');
      } else {
        this.errorMessageSetter();
      }
    } catch(e) {
      
    }
  }

  updateTable() {
    try {
      const values = this.clientGroupPerUserTypeForm.getRawValue();
      let updateIndex = this.clientGroupPerUserTypeTable.tblData.indexOf(this.selectedRow);
      this.clientGroupPerUserTypeTable.tblData[updateIndex] = {
        USER_TYPE:          values?.USER_TYPE,
        ITEM_LEVEL1:        values?.ITEM_LEVEL1,
        ITEM_LEVEL2:        values?.ITEM_LEVEL2,
        CLIENT_CD:          values?.CLIENT_CD,
        ACTIVE_TAG:         values?.ACTIVE_TAG,
        EFF_DATE_FROM:      new Date(values?.EFF_DATE_FROM.format("MM/DD/YYYY")),
        // EFF_DATE_TO:        values?.EFF_DATE_TO.format("MM/DD/YYYY"),
        EFF_DATE_TO:        moment.isMoment(values?.EFF_DATE_TO) ? new Date(values?.EFF_DATE_TO.format("MM/DD/YYYY")) : "",
        REMARKS:            values?.REMARKS,
        LAST_USER:          this.userDetailService.userId,
      };
      this.clientGroupPerUserTypeTable.tblData = [...this.clientGroupPerUserTypeTable.tblData];
    } catch(e) {
      
    }
  }

  updateDbData() {
    try {
      const selectedEffDate = this.selectedRow?.EFF_DATE_FROM ? moment(this.selectedRow?.EFF_DATE_FROM, "MM/DD/YYYY").format("YYYY-MM-DD") : "";
      let tempDbData = this.tempUpdateData.find(
        (data: { EFF_DATE_FROM: any }) => data.EFF_DATE_FROM == selectedEffDate
      );
      if((this.tempUpdateData == '' || this.tempUpdateData == null) || (tempDbData=='' || tempDbData==null)) {
        this.tempUpdateData.push(this.formValues());
      } else {
        tempDbData.USER_TYPE      = this.clientGroupPerUserTypeForm.get("USER_TYPE")?.value;
        tempDbData.ITEM_LEVEL1    = this.clientGroupPerUserTypeForm.get("ITEM_LEVEL1")?.value;
        tempDbData.ITEM_LEVEL2    = this.clientGroupPerUserTypeForm.get("ITEM_LEVEL2")?.value;
        tempDbData.CLIENT_CD      = this.clientGroupPerUserTypeForm.get("CLIENT_CD")?.value;
        tempDbData.ACTIVE_TAG     = this.clientGroupPerUserTypeForm.get("ACTIVE_TAG")?.value;
        tempDbData.EFF_DATE_FROM  = this.clientGroupPerUserTypeForm.get("EFF_DATE_FROM")?.value.format("YYYY-MM-DD");
        // tempDbData.EFF_DATE_TO    = this.clientGroupPerUserTypeForm.get("EFF_DATE_TO")?.value;
        tempDbData.EFF_DATE_TO    = moment.isMoment(this.clientGroupPerUserTypeForm.get("EFF_DATE_TO")?.value) ? this.clientGroupPerUserTypeForm.get("EFF_DATE_TO")?.value.format("YYYY-MM-DD") : "";
        tempDbData.REMARKS        = this.clientGroupPerUserTypeForm.get("REMARKS")?.value;
        tempDbData.LAST_USER      = this.userId;       
        tempDbData.LAST_UPDATE    = this.clientGroupPerUserTypeForm.get("LAST_UPDATE")?.value;
      }
    } catch(e) {
      
    }
  }

  updateNewData() {
    try {
      const selectedEffDate = this.selectedRow?.EFF_DATE_FROM ? moment(this.selectedRow?.EFF_DATE_FROM, "MM/DD/YYYY").format("YYYY-MM-DD") : "";
      let tempNewConfigdata = this.tempNewData.find(
        (data: { EFF_DATE_FROM: any }) => data.EFF_DATE_FROM == selectedEffDate
      );
      tempNewConfigdata.USER_TYPE      = this.clientGroupPerUserTypeForm.get("USER_TYPE")?.value;
      tempNewConfigdata.ITEM_LEVEL1    = this.clientGroupPerUserTypeForm.get("ITEM_LEVEL1")?.value;
      tempNewConfigdata.ITEM_LEVEL2    = this.clientGroupPerUserTypeForm.get("ITEM_LEVEL2")?.value;
      tempNewConfigdata.CLIENT_CD      = this.clientGroupPerUserTypeForm.get("CLIENT_CD")?.value;
      tempNewConfigdata.ACTIVE_TAG     = this.clientGroupPerUserTypeForm.get("ACTIVE_TAG")?.value;
      tempNewConfigdata.EFF_DATE_FROM  = this.clientGroupPerUserTypeForm.get("EFF_DATE_FROM")?.value.format("YYYY-MM-DD");
      // tempNewConfigdata.EFF_DATE_TO    = this.clientGroupPerUserTypeForm.get("EFF_DATE_TO")?.value;
      tempNewConfigdata.EFF_DATE_TO    = moment.isMoment(this.clientGroupPerUserTypeForm.get("EFF_DATE_TO")?.value) ? this.clientGroupPerUserTypeForm.get("EFF_DATE_TO")?.value.format("YYYY-MM-DD") : "";
      tempNewConfigdata.REMARKS        = this.clientGroupPerUserTypeForm.get("REMARKS")?.value;
      tempNewConfigdata.LAST_USER      = this.userId;       
      tempNewConfigdata.LAST_UPDATE    = this.clientGroupPerUserTypeForm.get("LAST_UPDATE")?.value;
    } catch(e) {
      
    }
  }

  deleteRow(): void {
    try {
      let clickedData = this.selectedRow;
      this.clientGroupPerUserTypeTable.tblData = this.clientGroupPerUserTypeTable.tblData.filter(function(e: any) {
        return e.EFF_DATE_FROM != clickedData.EFF_DATE_FROM;
      });
      this.tempNewData = this.tempNewData.filter(function(e: any) {
        return e.EFF_DATE_FROM != clickedData.EFF_DATE_FROM;
      });
      this.selectedRow = '';
      this.formClearer();
      this.deleteDisabler = true;
      this.addUpdateFlag = true;
      if((this.tempNewData=='' || this.tempNewData==null) && (this.tempUpdateData=='' || this.tempUpdateData==null)) {
        this.saveDisabler = true;
      }
    } catch(e) {
      
    }
  }

  onSave(): void {
    try {
      this.formHider = true;
      this.formService.showFormLoader(null, "client-group-per-user-type-form", "Saving.<br>Please wait ...", null, null);
      this.jsonDataService.contorlLoading(true);
      this.api.saveClientGroupPerUserType(this.tempNewData).subscribe({
        next: (data: any) => {
          this.securityService.checkRequestKeyResponse(data, () => {
            this.securityService.hasValidCsrfToken(data, () => {
              if(data.status == 'SUCCESS') {
                this.api.saveClientGroupPerUserType(this.tempUpdateData).subscribe({
                  next: (res: any) => {
                    this.securityService.checkRequestKeyResponse(res, () => {
                      this.securityService.hasValidCsrfToken(res, () => {
                        if(res.status == 'SUCCESS') {
                          this.onComplete();
                        } else {
                          this.onFail();
                        }
                      });
                    });
                  },
                  error: () => {
                    this.onFail();
                  }
                });
              } else {
                this.onFail();
              }
            });
          });
        },
        error: () => {
          this.onFail();
        }
      });
    } catch(e) {
      
    }
  }
  
  onComplete() {
    try {
      this.afterSave = true;
      this.formService.hideFormLoader("client-group-per-user-type-form");
      this.formHider = false;
      this.tempNewData = [];
      this.tempUpdateData = [];
      this.saveDisabler = true;
      this.deleteEnabler();
      this.formClearer();
      this.getClientGroupPerUserType();
      this.formService.hideFormMsg("client-group-per-user-type-error-message");
      this.openSaveDialog();
    } catch(e) {
      
    }
  }

  onFail() {
    try {
      this.formService.hideFormLoader("client-group-per-user-type-form");
      this.formHider = false;
      this.tempNewData = [];
      this.tempUpdateData = [];
      this.deleteEnabler();
      this.formClearer();
      this.getClientGroupPerUserType();
      this.formService.hideFormMsg("client-group-per-user-type-error-message");
      this.openErrorDialog("Saving Client Group Per User Type Failed");
    } catch(e) {
      
    }
  }

  openErrorDialog(message: string) {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'ERROR',
          content: message,
        }
      });
    } catch(e) {
      
    }
  }

  openSaveDialog() {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'SUCCESS',
          content: 'Client Group Per User Type Details Successfully Saved!',
        }
      });
    } catch(e) {
      
    }
  }

  // checkOpenUserTypeDialog() {
  //   try {
  //     if(this.tempNewData!='' || this.tempUpdateData!='') {
  //       this.dialog.open(CheckLovComponent, {
  //         disableClose: true,
  //         width: '512px',
  //         data: {
  //             title: "Confirmation Message",
  //             content:
  //                 "You have unsaved changes. Do you want to continue?"
  //         }
  //       }).afterClosed().subscribe((a:any) => {
  //           if (a) {
  //             this.tempNewData = [];
  //             this.tempUpdateData = [];
  //             this.clientGroupPerUserTypeTable.tblData = [];
  //             this.openUserTypeDialog();
  //           }
  //       });
  //     } else {
  //       this.openUserTypeDialog();
  //     }
  //   } catch(e) {
      
  //   }
  // }

  openUserTypeDialog() {
    try {
      this.dialog.open(AccountTypeLovComponent, {
        disableClose: true,
        width: '512px',
        data: {
        table: 'USERTYPE',
        moduleId: this.moduleId
        },
      }).afterClosed().subscribe((dataOutput: any) => {
        const data = dataOutput?.content;
        if(dataOutput?.button === 'OK') {
          this.tempNewData = [];
          this.tempUpdateData = [];
          this.clientGroupPerUserTypeTable.tblData = [];

          this.clientGroupPerUserTypeForm.get("USER_TYPE")?.setValue(data?.USER_TYPE + " - " + data?.USER_TYPE_DESC, {emitEvent: false});
          this.clientGroupPerUserTypeForm.get("USER_TYPE")?.setValue(data?.USER_TYPE, {emitModelToViewChange: false});

          if(data?.USER_TYPE === 'A') {
            this.clientGroupPerUserTypeForm.get("ITEM_LEVEL1")?.enable();
            this.clientGroupPerUserTypeForm.patchValue({
              ITEM_LEVEL1: ""
            });
          } else {
            this.clientGroupPerUserTypeForm.patchValue({
              ITEM_LEVEL1: "0"
            });
            this.clientGroupPerUserTypeForm.get("ITEM_LEVEL1")?.disable({emitEvent: false});
          }

        }
      });
    } catch(e) {
      
    }
  }

  // checkOpenClientGroupDialog() {
  //   try {
  //     if(this.tempNewData!='' || this.tempUpdateData!='') {
  //       this.dialog.open(CheckLovComponent, {
  //         disableClose: true,
  //         width: '512px',
  //         data: {
  //             title: "Confirmation Message",
  //             content:
  //                 "You have unsaved changes. Do you want to continue?"
  //         }
  //       }).afterClosed().subscribe((a:any) => {
  //           if (a) {
  //             this.tempNewData = [];
  //             this.tempUpdateData = [];
  //             this.clientGroupPerUserTypeTable.tblData = [];
  //             this.openClientGroupDialog();
  //           }
  //       });
  //     } else {
  //       this.openClientGroupDialog();
  //     }
  //   } catch(e) {
      
  //   }
  // }

  openClientGroupDialog() {
    try {
      this.dialog.open(ClientGroupLovDialog, {
        disableClose: true,
        width: '512px',
        data: {
        table: 'USERTYPE',
        moduleId: this.moduleId
        },
      }).afterClosed().subscribe((dataOutput: any) => {
        const data = dataOutput?.content;
        if(!dataOutput?.option) {
          this.tempNewData = [];
          this.tempUpdateData = [];
          this.clientGroupPerUserTypeTable.tblData = [];
          this.clientGroupPerUserTypeForm.get("CLIENT_CD")?.setValue(data?.CLIENT_CD + " - " + data?.CLIENT_DESC, {emitEvent: false});
          this.clientGroupPerUserTypeForm.get("CLIENT_CD")?.setValue(data?.CLIENT_CD, {emitModelToViewChange: false});
        }
      });
    } catch(e) {
      
    }
  }

  getValidator() {
    try {

      // USER_TYPE
      this.clientGroupPerUserTypeForm.get("USER_TYPE")?.valueChanges.subscribe((userType: any) => {
        setTimeout(() => {
          this.setTableData();
        })
      });
      
      // ITEM_LEVEL1
      this.clientGroupPerUserTypeForm.get("ITEM_LEVEL1")?.valueChanges.subscribe((itemLevel1: any) => {
        setTimeout(() => {
          this.setTableData();
        })
      });
      
      // ITEM_LEVEL2
      this.clientGroupPerUserTypeForm.get("ITEM_LEVEL2")?.valueChanges.subscribe((itemLevel2: any) => {
        setTimeout(() => {
          this.setTableData();
        })
      });
      
      // CLIENT_CD
      this.clientGroupPerUserTypeForm.get("CLIENT_CD")?.valueChanges.subscribe((clientCd: any) => {
        setTimeout(() => {
          this.setTableData();
        })
      });
    } catch(e) {
      
    }
  }

  setTableData() {
    try {
      this.tempNewData = [];
      this.tempUpdateData = [];
      this.formClearer();
      this.deleteEnabler();
      const form = this.clientGroupPerUserTypeForm.getRawValue();
      this.clientGroupPerUserTypeTable.tblData = [];
      if(form?.USER_TYPE !== "" && form?.ITEM_LEVEL1 !== "" && form?.ITEM_LEVEL2 !== "" && form?.CLIENT_CD !== "") {
        this.clientGroupPerUserTypeTable.loading = true;
        this.clientGroupPerUserTypeTable.tblData = this.clientGroupPerUserTypeDbData.filter((a: any) => {
          return a.USER_TYPE === form?.USER_TYPE && a.ITEM_LEVEL1 === form?.ITEM_LEVEL1 && a.ITEM_LEVEL2 === form?.ITEM_LEVEL2 && a.CLIENT_CD === form?.CLIENT_CD
        });
        this.clientGroupPerUserTypeTable.loading = false;
        this.addDisabled = false;
      } else {
        this.addDisabled = true;
      }
      this.addUpdateFlag = true;
      this.saveDisabler = true;
    } catch(e) {
      
    }
  }
}
