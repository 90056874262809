import { DatePipe } from '@angular/common';
import { Component, OnInit, Type } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';
import { PlanLovComponent } from '../../common/plan-lov/plan-lov.component';
import { AccountTypeLovComponent } from '../../common/acc-type-lov/acc-type-lov.component';
import { PaymentOptionsLovComponent } from '../../common/payt-opt-lov/payt-opt-lov.component';
import { CheckLovComponent } from 'src/app/components/common/guard/check-lov/check-lov.component';
import { filter } from 'rxjs/operators';

interface RowData {
    purpose: any
}

@Component({
    selector: 'purpose-maintenance',
    templateUrl: './purpose-maintenance.html',
    styleUrls: ['./purpose-maintenance.css']
})
export class PurposeMaintenanceComponent implements OnInit, LeaveConfirmation {

    moduleId: string = 'BMM036';
    public moduleName: string = "";
    toBeDeleted: any = [];
    purpose: any = [];
    selectedRow: boolean = false;

    constructor(
        private fb: FormBuilder,
        private _matDialog: MatDialog,
        private _api: APICallService,
        private _formService: FormService,
        private _jsonDS: JsonDataService,
        private _securityService: SecurityService,
        private _userDetailService: UserDetailsService,
        private _appMessageService: AppMessageService
    ) {
        this.userIdLoggedIn = this._userDetailService.userId || "";
        //this.purpose = this.getData.purposeList();
    }

    ngOnInit(): void {
        this.moduleName = this._jsonDS.data.moduleList.filter((data: any) => { return data.moduleId === this.moduleId })[0].moduleDesc.toUpperCase();
        this.setDisabledFields("disable");
    }

    canDeactivate(): boolean | Observable<boolean> {
        return this.tblConfig.purpose.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true)).length > 0 ? false : true;
    }

    test() {
        console.table(this.forms.purposeForm.value);
    }

    /* Boolean Stuffs */

    public btns = {
        purpose: {
            //updateDisabled: true,
            deleteDisabled: true,
            saveDisabled: true,
            addDisabled: true
        },
        lov: {
            lineDisabled: false,
            sublineDisabled: true
        }
    }

    public showForm = {
        purpose: true
    }

    /* Data Stuffs */

    private rowData: RowData = {
        purpose: null
    }

    private errorMessage: RowData = {
        purpose: null
    }

    private userIdLoggedIn: string;

    /* Form Object  */

    public forms = {
        lineSubLineLovForm: this.fb.group({
            lineCd: [null],
            sublineCd: [null],
            lineName: [null],
            subLineName: [null],
            lineCdWithDescDisplay: [{ value: '', disabled: 'true' }],
            subLineCdWithDescDisplay: [{ value: '', disabled: 'true' }]
        }),
        purposeForm: this.fb.group({
            lineCd: [null],
            sublineCd: [null],
            purposeCd: [null],
            purposeDesc: ['', [this.validatePurposeDesc(), Validators.required]],
            meaning: [null],
            lastUpdateUser: [{ value: '', disabled: 'true' }],
            lastUpdate: [{ value: '', disabled: 'true' }]
        }),
        resetLineSubLineForm: function () {
            this.lineSubLineLovForm.reset();
        },
        resetPurposeForm: () => {
            this.forms.purposeForm.reset();
            //this.setDisabledFields("disable");
            //this.btns.purpose.addDisabled = false;
            this.btns.purpose.deleteDisabled = true;
            this._formService.hideFormMsg("purpose-error-message");
            this.rowData.purpose = null;
        },
        resetAllForms: function () {
            this.resetLineSubLineForm();
            this.resetPurposeForm();
        }
    };

    /* Table Object */

    public tblConfig: RowData = {
        purpose: {
            cols: [
                {
                    key: "RV_MEANING",
                    header: "Purpose",
                    dataType: "string",
                    width: "100%"
                }
            ],
            tblData: [] as any[],
            selection: "single",
            paginator: true,
            rowsPerPage: 10,
            lazy: false,
            totalRecords: 15,
            loading: false,
        },
    }

    private formManipulation = {
        purpose: {
            dbToForm: function (data: any) {
                return {
                    lineCd: data.LINE_CD,
                    sublineCd: data.SUBLINE_CD,
                    purposeCd: data.PURPOSE_CD,
                    purposeDesc: data.PURPOSE_CD,
                    meaning: data.RV_MEANING,
                    lastUpdateUser: data.USER_ID,
                    lastUpdate: data.LAST_USER_UPDATE
                };
            },
            formToDb: function (data: any) {
                return {
                    LINE_CD: data.lineCd,
                    SUBLINE_CD: data.sublineCd,
                    PURPOSE_CD: data.purposeCd,
                    RV_MEANING: data.meaning,
                    USER_ID: null,
                    LAST_USER_UPDATE: null,
                };
            },
        }
    }

    /* Open Lov Time */

    public lovLine(LOVOPTIONS: any) {
        this.forms.resetPurposeForm();
        this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
            let output = dataOutput.content;
            if (output) {
                this.forms.lineSubLineLovForm.patchValue({
                    lineCd: output.LINE_CD,
                    lineName: output.LINE_NAME,
                    lineCdWithDescDisplay: `${output.LINE_CD} - ${output.LINE_NAME}`,
                    sublineCd: null,
                    subLineName: null,
                    subLineCdWithDescDisplay: null,
                });
                this.forms.purposeForm.patchValue({
                    lineCd: output.LINE_CD,
                    sublineCd: null
                });
                this.btns.lov.sublineDisabled = false;
                this.btns.purpose.addDisabled = true;
                //this.forms.purposeForm.disable();
                this.setDisabledFields("disable");
            } else {
                this.forms.lineSubLineLovForm.patchValue({
                    lineCd: null,
                    lineName: null,
                    lineCdWithDescDisplay: null,
                    sublineCd: null,
                    subLineName: null,
                    subLineCdWithDescDisplay: null,
                });
                this.forms.purposeForm.patchValue({
                    lineCd: null,
                    sublineCd: null
                });
                this.btns.purpose.addDisabled = true;
                this.btns.lov.sublineDisabled = true;
                //this.forms.purposeForm.disable();
                this.setDisabledFields("disable");
            }
            this.tblConfig.purpose.tblData = [];
            this.toBeDeleted = [];
            this.btns.purpose.saveDisabled = true;
        });
    }

    public lovSubline(LOVOPTIONS: any) {
        this.forms.resetPurposeForm();
        LOVOPTIONS.data.lineCd = this.forms.lineSubLineLovForm.get('lineCd')?.value;
        this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
            let output = dataOutput.content;
            if (output) {
                this.forms.lineSubLineLovForm.patchValue({
                    lineCd: output.LINE_CD,
                    lineName: output.LINE_NAME,
                    lineCdWithDescDisplay: `${output.LINE_CD} - ${output.LINE_NAME}`,
                    sublineCd: output.SUBLINE_CD,
                    subLineName: output.SUBLINE_NAME,
                    subLineCdWithDescDisplay: `${output.SUBLINE_CD} - ${output.SUBLINE_NAME}`
                });
                this.forms.purposeForm.patchValue({
                    lineCd: output.LINE_CD,
                    sublineCd: output.SUBLINE_CD
                });
                this.btns.purpose.addDisabled = false;
                //this.forms.purposeForm.disable();
                this.setDisabledFields("enable");
            } else {
                this.forms.lineSubLineLovForm.patchValue({
                    sublineCd: null,
                    subLineName: null,
                    subLineCdWithDescDisplay: null
                });
                this.forms.purposeForm.patchValue({
                    lineCd: null,
                    sublineCd: null
                });
                this.btns.purpose.addDisabled = true;
                this.setDisabledFields("disable");
            }
            this.getData.purpose();
            this.tblConfig.purpose.tblData = [];
            this.toBeDeleted = [];
            this.btns.purpose.saveDisabled = true;
        });
    }
    /*
    public lovPlan(LOVOPTIONS: any) {
        LOVOPTIONS.data.lineCd = this.forms.lineSubLineLovForm.get('lineCd')?.value;
        LOVOPTIONS.data.sublineCd = this.forms.lineSubLineLovForm.get('sublineCd')?.value;
        this._matDialog.open(PlanLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
            let output = dataOutput.content;
            if (dataOutput.button === 'OK') {
                if (output) {
                    this.forms.lineSubLineLovForm.patchValue({
                        planCd: output.PLAN_CD,
                        planDesc: output.PLAN_NAME,
                        planCdWithDescDisplay: `${output.PLAN_CD.toString().padStart(2, 0)} - ${output.PLAN_NAME}`,
                        userType: null,
                        userTypeDesc: null,
                        userTypeCdWithDescDisplay: null
                    });
                    this.forms.paytOptForm.patchValue({
                        planCd: output.PLAN_CD,
                        userType: null
                    });
                    this.btns.lov.userTypeDisabled = false;
                    this.btns.lov.paytOptDisabled = true;
                    this.forms.paytOptForm.disable();
                } else {
                    this.forms.lineSubLineLovForm.patchValue({
                        planCd: null,
                        planDesc: null,
                        planCdWithDescDisplay: null,
                        userType: null,
                        userTypeDesc: null,
                        userTypeCdWithDescDisplay: null
                    });
                    this.forms.paytOptForm.patchValue({
                        planCd: null,
                        userType: null
                    });
                    this.btns.lov.userTypeDisabled = true;
                    this.btns.lov.paytOptDisabled = true;
                    this.forms.paytOptForm.disable();
                }
            }
            this.tblConfig.paytOpt.tblData = [];
            this.toBeDeleted = [];
            this.btns.paytOpt.saveDisabled = true;
        });
    }

    public lovUserType(LOVOPTIONS: any) {
        this._matDialog.open(AccountTypeLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
            let output = dataOutput.content;
            if (dataOutput.button === 'OK') {
                if (output) {
                    this.forms.lineSubLineLovForm.patchValue({
                        userType: output.USER_TYPE,
                        userTypeDesc: output.USER_TYPE_DESC,
                        userTypeCdWithDescDisplay: `${output.USER_TYPE} - ${output.USER_TYPE_DESC}`,
                    });
                    this.forms.paytOptForm.patchValue({
                        userType: output.USER_TYPE
                    });
                    this.toBeDeleted = [];
                    this.getData.paytOpt();
                } else {
                    this.forms.lineSubLineLovForm.patchValue({
                        userType: null,
                        userTypeDesc: null,
                        userTypeCdWithDescDisplay: null,
                    });
                    this.forms.paytOptForm.patchValue({
                        userType: null
                    });
                }
            }
            this.btns.paytOpt.saveDisabled = true;
        });
    }
    */

    public openLov(lovToOpen: string) {
        try {
            let LOVOPTIONS: { [key: string]: any } = {
                disableClose: true,
                width: '512px',
                data: {
                table: lovToOpen,
                moduleId: this.moduleId
                },
            }
            switch (lovToOpen.toUpperCase()) {
                case "LINE":
                    if (this.forms.purposeForm.dirty) {
                        this._matDialog.open(CheckLovComponent, {
                            disableClose: true,
                            width: '512px',
                            data: {
                                title: "Confirmation Message",
                                content:
                                    "You have unsaved changes. Do you want to continue?"
                            }
                        }).afterClosed().subscribe((a:any) => {
                            if (a) {
                                this.forms.purposeForm.markAsPristine();
                                this.lovLine(LOVOPTIONS);
                            }
                        });
                    } else {
                        this.lovLine(LOVOPTIONS);
                    }
                    break;
                case "SUBLINE":
                    if (this.forms.purposeForm.dirty) {
                        this._matDialog.open(CheckLovComponent, {
                            disableClose: true,
                            width: '512px',
                            data: {
                                title: "Confirmation Message",
                                content:
                                    "You have unsaved changes. Do you want to continue?"
                            
                            }
                        }).afterClosed().subscribe((a:any) => {
                            if (a) {
                                this.forms.purposeForm.markAsPristine();
                                this.lovSubline(LOVOPTIONS);
                            }
                        });
                    } else {
                        this.lovSubline(LOVOPTIONS);
                    }
                    break;
                /*case "PLAN": // recode pa
                    if (this.forms.paytOptForm.dirty) {
                        this._matDialog.open(CheckLovComponent, {
                            disableClose: true,
                            width: '512px',
                            data: {
                                title: "Confirmation Message",
                                content:
                                    "You have unsaved changes. Do you want to continue?"
                            
                            }
                        }).afterClosed().subscribe((a:any) => {
                            if (a) {
                                this.forms.paytOptForm.markAsPristine();
                                this.lovPlan(LOVOPTIONS);
                            }
                        });
                    } else {
                        this.lovPlan(LOVOPTIONS);
                    }
                    break;
                case "USERTYPE": // recode pa
                    if (this.forms.paytOptForm.dirty) {
                        this._matDialog.open(CheckLovComponent, {
                            disableClose: true,
                            width: '512px',
                            data: {
                                title: "Confirmation Message",
                                content:
                                    "You have unsaved changes. Do you want to continue?"
                            
                            }
                        }).afterClosed().subscribe((a:any) => {
                            if (a) {
                                this.forms.paytOptForm.markAsPristine();
                                this.lovUserType(LOVOPTIONS);
                            }
                        });
                    } else {
                        this.lovUserType(LOVOPTIONS);
                    }
                    break;
                case "PAYTOPT": // recode pa
                    LOVOPTIONS.data.rows = this.tblConfig.paytOpt.tblData;
                    this._matDialog.open(PaymentOptionsLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
                        let output = dataOutput.content;
                        if (dataOutput.button === 'OK') {
                            if (output) {
                                this.forms.paytOptForm.patchValue({
                                    paytCd: output.PAYT_CD,
                                    paytDesc: output.PAYT_DESC,
                                    paytOptDisplay: `${output.PAYT_CD.toString().padStart(2, 0)} - ${output.PAYT_DESC}`
                                });
                                this.btns.paytOpt.addDisabled = false;
                            } else {
                                this.forms.paytOptForm.patchValue({
                                    paytCd: null,
                                    paytDesc: null,
                                    paytOptDisplay: null
                                });
                                this.btns.paytOpt.addDisabled = true;
                            }
                        }
                    });
                    break;*/
            }
        } catch (e) {
    console.error(e);
        }
    }

    setDisabledFields (enable: string) {
        if (enable === 'enable') {
            this.forms.purposeForm.get("purposeDesc")?.enable();
            this.forms.purposeForm.controls["purposeDesc"].setValidators([this.validatePurposeDesc(), Validators.required]);
            this.selectedRow = false;
        } else {
            this.forms.purposeForm.get("purposeDesc")?.disable();
            this.selectedRow = true;
        }

        this.forms.purposeForm.controls["purposeDesc"].updateValueAndValidity();
    }

    public getData = {
        purpose: () => { //recode pa
            try {
                this.tblConfig.purpose.tblData = [];
                if (this.forms.lineSubLineLovForm.get('lineCd')?.value && this.forms.lineSubLineLovForm.get('sublineCd')?.value) {
                    this.tblConfig.purpose.loading = true;
                    this._jsonDS.contorlLoading(true);
                    this._api.getPurpose({
                        moduleId: this.moduleId,
                        userId: this._userDetailService.userId,
                        type: "MODULE"
                    }).subscribe({
                        next: (data: any) => {
                            this._securityService.checkRequestKeyResponse(data, () => {
                                this._securityService.hasValidCsrfToken(data, ()=>{
                                    try {
                                        if (data.status === 'SUCCESS') {
                                            data = JSON.parse(this._jsonDS.decrypt(data.response));
                                            this._jsonDS.contorlLoading(false);
                                            let filteredData = data.data.filter((d: any) => (d.LINE_CD == this.forms.lineSubLineLovForm.get('lineCd')?.value 
                                                && d.SUBLINE_CD == this.forms.lineSubLineLovForm.get('sublineCd')?.value));
                                            this.tblConfig.purpose.tblData = filteredData;
                                            this.toBeDeleted = [];
                                            //this.forms.resetPurposeForm();
                                            this.setDisabledFields("enable");
                                            this.purpose = this.getData.purposeList();
                                        } else {
                                            this.tblConfig.purpose.loading = false;
                                            this._jsonDS.contorlLoading(false);
                                            this.toBeDeleted = [];
                                            //this.getData.purposeList();
                                            this._appMessageService.showAppMessage(data.message, "error");
                                        }
                                    } catch (e) {
    console.error(e);
                                    }
                                    this.tblConfig.purpose.loading = false;
                                });
                            });
                        },
                        error: (e: any) => {
                            this.tblConfig.purpose.loading = false;
                            this._jsonDS.contorlLoading(false);
                        }
                    });
                } else {
                    this.tblConfig.purpose.loading = false;
                    this._jsonDS.contorlLoading(false);
                }
            } catch (e) {
    console.error(e);
                this.tblConfig.purpose.loading = false;
                this._jsonDS.contorlLoading(false);
            }
        },
        purposeList: () => {
            this.purpose = [];
            let purpose: any[] = [];
            let addedPurpose: any = [];

            for (const added of this.tblConfig.purpose.tblData) {
                addedPurpose.push(String(added.PURPOSE_CD));
            }
            purpose.push({      
                cd: '',
                name: ''
            });
            
            for (const pur of this._jsonDS.data.refCds.filter((a:any) => a.rvDomain === 'BMM036.PURPOSE_CD' && !addedPurpose.includes(a.rvLowValue))) {
                purpose.push({      
                                cd: pur.rvLowValue, 
                                name: pur.rvMeaning
                            });
            }
            return purpose;
        }
    }

    public onRowClick = {
        purpose: (ev: any) => {
            try {
                //ev.update = true;
                this.setDisabledFields("disable");
                this.rowData.purpose = ev;
                if (ev != null) {
                    for (const pur of this._jsonDS.data.refCds.filter((a:any) => a.rvDomain === 'BMM036.PURPOSE_CD')) {
                        this.purpose.push({      
                                        cd: pur.rvLowValue, 
                                        name: pur.rvMeaning
                                    });
                    }
                    this.populateFormFromTable.purpose();
                    this.btns.purpose.deleteDisabled = false;
                    this.btns.purpose.addDisabled = true;
                    this._formService.hideFormMsg("purpose-error-message");
                } else {
                    this.forms.resetPurposeForm();
                    this.setDisabledFields("enable");
                    this.purpose = this.getData.purposeList();  
                    this.btns.purpose.addDisabled = false;  
                    this.btns.purpose.deleteDisabled = true;
                }
            } catch (e) {
    console.error(e);
                this.forms.resetPurposeForm();
                this.setDisabledFields("enable");
                this.purpose = this.getData.purposeList(); 
                this.rowData.purpose = null;
                this.btns.purpose.addDisabled = false;
                this.btns.purpose.deleteDisabled = true;
            }
        }
    }

    private populateFormFromTable = {
        purpose: () => {
            try {
                let data = this.rowData.purpose;
                //this.btns.paytOpt.updateDisabled = !data.update;
                this.btns.purpose.deleteDisabled = !data.temp;
                this.forms.purposeForm.patchValue(this.formManipulation.purpose.dbToForm(data));
            }
            catch (e) {
    console.error(e);
            }
        }
    }

    public onAdd = {
        purpose: () => {
            try {
                this._formService.hideFormMsg("purpose-error-message");
                let descValue = this.forms.purposeForm.get('purposeDesc')?.value || '';
                let desc = '';
                if (descValue != '' ) {
                    desc = this.purpose.filter((data: any) => { return data.cd === this.forms.purposeForm.get('purposeDesc')?.value })[0].name;
                }
                if (this.forms.purposeForm.valid) {
                    this.forms.purposeForm.patchValue({
                        lineCd: this.forms.lineSubLineLovForm.get('lineCd')?.value,
                        sublineCd: this.forms.lineSubLineLovForm.get('sublineCd')?.value,
                        purposeCd: this.forms.purposeForm.get('purposeDesc')?.value || '',
                        meaning: desc
                    });
                    let rowToBeAdded: { [key: string]: any } = this.formManipulation.purpose.formToDb(this.forms.purposeForm.value);
                    var del = this.toBeDeleted.map(function (row:any) { return row.LINE_CD && row.SUBLINE_CD && row.PURPOSE_CD; }).indexOf(rowToBeAdded.LINE_CD && rowToBeAdded.SUBLINE_CD && rowToBeAdded.PURPOSE_CD); // edit pa kng ano unique columns
                    if (del != -1) {
                        this.toBeDeleted.splice(del,1);
                    }
                    
                    rowToBeAdded.temp = true;
                    this.tblConfig.purpose.tblData = [rowToBeAdded, ...this.tblConfig.purpose.tblData];
                    this.purpose = this.getData.purposeList();
                    this.forms.resetPurposeForm();
                    this.btns.purpose.saveDisabled = false;
                    this.forms.purposeForm.markAsDirty();
                    this.btns.purpose.addDisabled = false;
                    this.setDisabledFields('enable');
                    
                    
                } else {
                    this.showErrorValidator.purpose();
                }
            } catch (e) {
    console.error(e);
            }
        }
    }

    public onDelete = {
        purpose: () => {
            try {
                this.tblConfig.purpose.tblData = this.tblConfig.purpose.tblData.filter((d: any) => {
                    return d !== this.rowData.purpose;
                });

                this.toBeDeleted.push(this.rowData.purpose);
                this.purpose = this.getData.purposeList();
                this.forms.resetPurposeForm();
                this.btns.purpose.addDisabled = false;
                this.btns.purpose.saveDisabled = false;
                this.forms.purposeForm.markAsDirty();
                this.setDisabledFields('enable');
            } catch (e) {
    console.error(e);
            }
        }
    }

    /*public onUpdate = {
        paytOpt: () => {
            try {
                this._formService.hideFormMsg("paytOpt-error-message");
                if (this.forms.paytOptForm.valid) {
                    let toBeUpdatedIndex = this.tblConfig.paytOpt.tblData.indexOf(this.rowData.paytOpt);
                    let rowToBeUpdated: { [key: string]: any } = this.formManipulation.paytOpt.formToDb(this.forms.paytOptForm.value);
                    if (this.rowData.paytOpt.temp) {
                        rowToBeUpdated.temp = true;
                    } else {
                        rowToBeUpdated.onDbButUpdatedTemp = true;
                    }
                    this.forms.resetPaytOptForm();
                    this.tblConfig.paytOpt.tblData[toBeUpdatedIndex] = rowToBeUpdated;
                    this.btns.paytOpt.saveDisabled = false;
                    this.tblConfig.paytOpt.tblData = [... this.tblConfig.paytOpt.tblData];
                } else {
                    this.showErrorValidator.paytOpt();
                }
            } catch (e) {
    console.error(e);
            }
        }
    }*/

    public onSave = { //recode pa
        purpose: () => {
            this.showForm.purpose = false;
            this._formService.showFormLoader(null, "purpose-maintenance-loading", "Saving.<br>Please wait ...", null, null);
            let toBeSaved = this.tblConfig.purpose.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true));
            if (toBeSaved.length > 0 || this.toBeDeleted.length > 0) {
                toBeSaved.map((d: any) => {
                    d.USER_ID = this.userIdLoggedIn;
                });
                this._api.savePurpose({
                    tobeSaved: toBeSaved,
                    tobeDeleted: this.toBeDeleted
                }).subscribe({
                    next: (data: any) => {
                        this._securityService.checkRequestKeyResponse(data, () => {
                            this._securityService.hasValidCsrfToken(data, ()=>{
                                if (data.status === 'SUCCESS') {
                                    this.onComplete.purpose();
                                } else { 
                                    this.onComplete.purposeError();
                                }
                            });
                        });
                    },
                    error: (data: any) => {
                        this.onComplete.purposeError();
                    },
                });
            } else {
                this.onComplete.purpose();
            }
        }
    }

    openDialog(title: string, contentTitle: string, contentDetail: string) {
        try {
            this._matDialog.open(DialogMsgComponent, {
                disableClose: true,
                width: '512px',
                data: {
                title: title,
                content:
                    title === 'SUCCESS'
                    ? `${contentTitle} details successfully ${contentDetail}!`
                    : `${contentTitle} details ${contentDetail} failed!`,
                },
            });
        } catch (e) {
    console.error(e);
        }
    }

    private onComplete = {
        purpose: () => {
            this.getData.purpose();
            this.btns.purpose.saveDisabled = true;
            this.showForm.purpose = true;
            this.toBeDeleted = [];
            this._formService.hideFormLoader("purpose-maintenance-loading");
            this.openDialog('SUCCESS', 'Purpose', 'saved');
            this.setDisabledFields("enable");
            this.forms.purposeForm.markAsPristine();
        },
        purposeError: () => {
            this.showForm.purpose = true;
            this.btns.purpose.saveDisabled = false;
            this.openDialog('FAILED', 'Purpose', 'saving');
            this._formService.hideFormLoader("purpose-maintenance-loading");
        }
    }

    /* Validator Stuffs Down here */

    /*checkDuplicatePlanName(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (!value) return null;
        let hasDuplicate: boolean = this.tblConfig.paytOpt.tblData.filter((d: any) => {
            if (!this.rowData.paytOpt)
            return d.PLAN_NAME === value;
            else
            return this.rowData.paytOpt.PLAN_NAME !== value && d.PLAN_NAME === value;
        }).length > 0 ? true : false;
        return hasDuplicate ? { hasDupe: true } : null;
        }
    }

    checkDuplicateSeqNo(module: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (!value) return null;
        let hasDuplicate: boolean = this.tblConfig[module as keyof RowData].tblData.filter((d: any) => {
            if (!this.rowData[module as keyof RowData])
            return d.SEQ_NO == value;
            else
            return this.rowData[module as keyof RowData].SEQ_NO != value && d.SEQ_NO == value;
        }).length > 0 ? true : false;
        return hasDuplicate ? { hasDupeSeqNo: true } : null;
        }
    }*/

    validatePurposeDesc(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value || '';
            let hasDuplicate: boolean;
            if(value === ''){
                hasDuplicate = true;
            } else {
                hasDuplicate = false;
            }
            return hasDuplicate ? { hasDupe: true } : null;
        }
    }

    public showErrorValidator = {
        purpose: () => {
            try {
                Object.keys(this.forms.purposeForm.controls).forEach(key => {
                    const controlErrors = this.forms.purposeForm.get(key)?.errors;
                    if (controlErrors != null) {
                        Object.keys(controlErrors).forEach(keyError => {
                        if (keyError == 'hasDupe' && controlErrors[keyError] === true)
                            this.errorMessage.purpose = "There are missing information. Please provide necessary information needed.";
                        else if (keyError == 'required' && controlErrors[keyError] === true)
                            this.errorMessage.purpose = "There are missing information. Please provide necessary information needed.";
                        else if (keyError == 'hasDupeSeqNo' && controlErrors[keyError] === true)
                            this.errorMessage.purpose = "Sequence No. already exists. Please choose a different Sequence No.";
                        });
                    }
                });
                this._formService.showFormMsg("purpose-error-message", this.errorMessage.purpose, "E");
            } catch (e) {
    console.error(e);
            }
        }
    }
}
