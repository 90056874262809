<section id="dialog">
    <div class="mtn-title">
        {{titleLov}}
    </div>
</section>
<!-- Table Contents -->
<div>
    <osp-table *ngIf="!isShortDesc" [tblConfig]="table" (onRowClick)="onRowClick($event)"></osp-table>
    <osp-table *ngIf="isShortDesc" [tblConfig]="tableShortDesc" (onRowClick)="onRowClick($event)"></osp-table>
    <div class="button">
        <button mat-raised-button class="btn4" type="button" (click)="onClose('BACK')">BACK</button>
        <button mat-raised-button class="btn2" type="button" (click)="onClose('OK')">OK</button>
    </div>
</div>