import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { SecurityService } from 'src/app/services/security.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { Router } from '@angular/router';
import { AppMessageService } from 'src/app/services/app-message.service';

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent {

  showOldPasswordValue: boolean = false;
  showNewPasswordValue: boolean = false;
  showRepPasswordValue: boolean = false;
  formHider: boolean = false;
  errorMessage: string = '';

    constructor(
        private router: Router,
        private appMessageService: AppMessageService,
        private userDetailService: UserDetailsService,
        private fb: FormBuilder,
        private formService: FormService,
        private api: APICallService,
        private dialog: MatDialog,
        private securityService: SecurityService
    ) { }

  changePasswordForm = this.fb.group({
    userId          : [this.userDetailService.userId],
    name            : [this.userDetailService.name],
    email           : [this.userDetailService.email],
    oldPassword     : ['', Validators.required],
    newPassword     : ['', Validators.required],
    repeatPassword  : ['', Validators.required],
  });

    public onSave(): void {
        try {
            if (this.changePasswordForm.valid && (this.changePasswordForm.get('oldPassword')?.value != this.changePasswordForm.get('newPassword')?.value)) {
                this.formService.hideFormMsg("change-password-error-message");
                this.formService.showFormLoader(null, "change-password-form", "Changing password.<br>Please wait ...", null, null);
                this.formHider = true;
                this.api.changePassword(this.changePasswordForm.value).subscribe({
                    next: (data: any) => {
                        this.securityService.checkRequestKeyResponse(data, () => {
                            if (data.status == 'SUCCESS') {
                                this.api.logout().subscribe();
                                sessionStorage.removeItem("bssid");
                                sessionStorage.removeItem("rkc");
                                this.userDetailService.resetUserDetails();
                                this.router.navigate([""]);
                                this.appMessageService.showAppMessage("Password successfully changed.", "success");
                                this.formClearer();this.formService.hideFormLoader("change-password-form");
                                this.openSuccessDialog();
                            } else {
                                this.formHider = false;
                                this.formService.hideFormLoader("change-password-form");
                                this.formClearer();
                                this.formService.showFormMsg("change-password-error-message", data.message, "E");
                            }
                        });
                    }
                });
            } else {
                this.errorMessageSetter();
                this.formService.showFormMsg("change-password-error-message", this.errorMessage, "E");
            }      
        } catch(e) {
            console.log("Error in change password maintenance: onSave");
        }
    }

    private openSuccessDialog(): void {
        try {
            this.dialog.open(DialogMsgComponent, {
                disableClose: true,
                width: "500px",
                data: {
                    title: 'SUCCESS',
                    content: "Password successfully changed",
                }
            });

            setTimeout(() => {
              window.location.reload();
            }, 300);
        } catch(e) {
            console.log("Error in change password maintenance: openSuccessDialog");
        }
    }

  formClearer() {
    try {
      this.changePasswordForm.patchValue({
        oldPassword     : '',
        newPassword     : '',
        repeatPassword  : '',
      });
    } catch(e) {
      console.log("Error in change password maintenance: formClearer");
    }
  }

  errorMessageSetter() {
    try {
      if(this.changePasswordForm.invalid) {
        for(const field in this.changePasswordForm.controls) {
          const controlName = this.changePasswordForm.get(field);
          if(controlName?.errors?.required) {
            this.errorMessage = 'There are missing information. Please provide necessary information needed';
          }
        }
      } else if(this.changePasswordForm.get('oldPassword')?.value == this.changePasswordForm.get('newPassword')?.value) {
        this.errorMessage = "New password must not be the same with your current password.";
      } else {
        this.errorMessage = "";
      }
    } catch(e) {
      console.log("Error in change password maintenance: errorMessage");
    }
  }
}
