<ng-template #rhDialog>
    <h3 mat-dialog-title>Revision History</h3>
    <mat-dialog-content>
        <div content id="rh-overlay">
            <ng-container *ngIf="data.tblConfig?.lazy">
                <osp-table #tbl [tblConfig]="data.tblConfig" (onRowClick)="rowClick($event)" (onLazyLoad)="lazyLoad($event)"></osp-table>
            </ng-container>
            <ng-container *ngIf="!data.tblConfig?.lazy">
                <osp-table #tbl [tblConfig]="data.tblConfig" (onRowClick)="rowClick($event)"></osp-table>
            </ng-container>
            <div id="rh-remarks">
                <textarea id="rh-remarks-text-area" [ngModel]="historyRemarks" readonly></textarea>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div id="rh-btn-container">
            <button mat-button class="btn btn2" [mat-dialog-close]="true">Return</button>
        </div>
    </mat-dialog-actions>
</ng-template>