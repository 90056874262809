import { Injectable, isDevMode } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { JsonDataService } from './json-data.service';
import { Subject } from 'rxjs';
import { SecurityService } from './security.service';
import { UserDetailsService } from './user-details.service';

@Injectable({
    providedIn: 'root'
})
export class APICallService {

    private headers: HttpHeaders = new HttpHeaders;

    private encryptedParams: string = "";
    private apiUrl = "";
    private apiUrl2 = "";
    private apiAdminUrl = "";
    private apiGeneralMaintenanceUrl = "";
    private apiMotorMaintenanceUrl = "";
    private apiPremiumRates = "";
    private apiUserAccount = "";
    private apiCustInfo = "";
    private apiPolicyHeader = "";

    private apiAdminUrl2 = "";
    private apiGeneralMaintenanceUrl2 = "";
    private apiMotorMaintenanceUrl2 = "";
    private apiPremiumRates2 = "";
    private apiUserAccount2 = "";
    private apiCustInfo2 = "";
    private apiPolicyHeader2 = "";

    constructor(
        private httpClient: HttpClient,
        private jsonDataService: JsonDataService,
        private securityService: SecurityService,
        private userDetailsService: UserDetailsService
    ) {
        this.apiUrl = this.jsonDataService.apiUrl || "/api";
        // this.apiUrl2 = this.apiUrl + (isDevMode() ? "" : "2"); //---
        this.apiUrl2 = this.apiUrl + "2"; //---
        this.apiAdminUrl = this.apiUrl + "/admin";
        this.apiAdminUrl2 = this.apiUrl2 + "/admin"; //---
        this.apiGeneralMaintenanceUrl = this.apiUrl + "/maintenance/general";
        this.apiGeneralMaintenanceUrl2 = this.apiUrl2 + "/maintenance/general"; //---
        this.apiMotorMaintenanceUrl = this.apiUrl + "/maintenance/motor";
        this.apiMotorMaintenanceUrl2 = this.apiUrl2 + "/maintenance/motor"; //---
        this.apiPremiumRates = this.apiUrl + "/maintenance/premium-rates";
        this.apiPremiumRates2 = this.apiUrl2 + "/maintenance/premium-rates"; //---
        this.apiUserAccount = this.apiUrl + "/maintenance/user-account";
        this.apiUserAccount2 = this.apiUrl2 + "/maintenance/user-account"; //---
        this.apiCustInfo = this.apiUrl + "/maintenance/customer-information";
        this.apiCustInfo2 = this.apiUrl2 + "/maintenance/customer-information"; //--
        this.apiPolicyHeader = this.apiUrl + "/maintenance/policy-header";
        this.apiPolicyHeader2 = this.apiUrl2 + "/maintenance/policy-header"; //---
    }

    private _refreshNeeded$ = new Subject<void>();

    get refreshNeeded$() {
        return this._refreshNeeded$;
    }

    private baseRequest(params: any = "-1"): void {
        this.userDetailsService.lastActionTime = new Date();
        this.headers = this.headers
            .set("uid", sessionStorage.getItem("bssid") || "1")
            .set("rk", this.securityService.generateBssidRequestKey())
            .set("X-Original-User-Agent", navigator.userAgent)
            .set("rid", sessionStorage.getItem("rid") || "1")
            .set("ct", "1");

        if (params && params !== "-1") {
            this.encryptedParams = this.jsonDataService.encrypt(params);
            this.headers = this.headers.set("ct", this.securityService.buildCsrfToken(this.encryptedParams));
        }
    }

    // SEND EMAIL
    // POLICY RENEWAL

    public sendMail(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiUrl + "/mail", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // SEND SMS
    public sendSms(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiUrl + "/sms", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public checkAuthorization(): any {
        this.baseRequest();
        return this.httpClient.post(this.apiUrl + "/check-authorization", {}, { headers: this.headers });
    }

    public deployChanges(): any {
        this.baseRequest();
        return this.httpClient.post(this.apiUrl + "/deploy-changes", {}, { headers: this.headers });
    }

    public login(params: any): any {
        this.baseRequest(new HttpParams().set("un", params.un).set("pw", params.pw).set("localStorage", params.localStorage));
        return this.httpClient.post(this.apiAdminUrl + "/login", {
            params: this.encryptedParams, withCredentials: true
        }, { headers: this.headers });
    }

    public hccSession(params: any): any {
        this.baseRequest(new HttpParams().set("sessionData", params.sessionData));
        return this.httpClient.post(params.url + "/session", {
            params: this.encryptedParams, withCredentials: true
        }, { headers: this.headers });
    }

    public logout(): any {
        this.baseRequest();
        return this.httpClient.post(this.apiAdminUrl + "/logout", {}, { headers: this.headers });
    }

    public forgotPassword(params: any): any {
        this.baseRequest(new HttpParams().set("userId", params.userId));
        return this.httpClient.post(this.apiAdminUrl + "/forgot", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public resetPassword(params: any): any {
        this.baseRequest(new HttpParams()
            .set("rqId", params.rqId).set("un", params.un)
            .set("npw", params.npw).set("rpw", params.rpw));
        return this.httpClient.post(this.apiAdminUrl + "/reset", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public changePassword(params: any): any {
        this.baseRequest(new HttpParams()
            .set("un", params.userId).set("pw", params.oldPassword)
            .set("npw", params.newPassword).set("rpw", params.repeatPassword));
        return this.httpClient.post(this.apiAdminUrl + "/change", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public resetUserPw(params: any): any {
        this.baseRequest(new HttpParams()
            .set("userId", params.userId).set("isAdmin", params.isAdmin)
            .set("userIdAdmin", params.userIdAdmin));
        return this.httpClient.post(this.apiAdminUrl + "/reset-user-pw", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public validateExtraction(params: any): any {
        this.baseRequest();
        return this.httpClient.post(this.apiUrl + "/generate-rpa/validate-extraction", {
            params: params
        }, { headers: this.headers });
    }

    public extractRPA(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiUrl + "/generate-rpa/extract", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public printRPA(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiUrl + "/generate-rpa/print", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public generateReports(params: any, format: string): any {
        this.baseRequest(params);
        if (format.toLocaleUpperCase() !== "PDF") {
            return this.httpClient.post(this.apiUrl + "/generate-reports/" + format, {
                params: this.encryptedParams
            }, { headers: this.headers });
        }

        return this.httpClient.post(this.apiUrl + "/generate-reports/" + format, this.encryptedParams, { headers: this.headers });
    }

    public generateITReports(params: any, format: string): any {
        this.baseRequest(params);
        if (format.toLocaleUpperCase() !== "PDF") {
            return this.httpClient.post(this.apiUrl + "/generate-it-reports/" + format, {
                params: this.encryptedParams
            }, { headers: this.headers });
        }

        return this.httpClient.post(this.apiUrl + "/generate-it-reports/" + format, this.encryptedParams, { headers: this.headers });
    }

    public getAllAgents(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/agents/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveAgents(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/agents/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public updateAgents(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/agents/update", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getAgentGroup(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/agent-group/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveAgentGroup(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/agent-group/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getAgencyList(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/agency/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveAgency(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/agency/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getAgentBma(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/bma/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveBma(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/bma/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getEmployees(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/employee/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveEmployee(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/employee/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getBFB(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/bfb/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveBFB(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/bfb/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getFmvList(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiMotorMaintenanceUrl + "/fmv/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveFmvDetails(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiMotorMaintenanceUrl + "/fmv/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getFMVUploadData(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiMotorMaintenanceUrl + "/fmv/fmv-upload/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public addFMVUploadData(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiMotorMaintenanceUrl + "/fmv/fmv-upload/add", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveFMVUploadData(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiMotorMaintenanceUrl + "/fmv/fmv-upload/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getSystemUsersMtn(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/system-users/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveSystemUsersMtn(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/system-users/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getUserAccessMtnModules(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/system-users/user-access-modules/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveUserAccessMtnMdls(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/system-users/user-access-modules/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getUserAccessMtnModuleGrp(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/system-users/user-access-module-grp/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveUserAccessMtnMdlGrp(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/system-users/user-access-module-grp/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getVoucherCodes(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/vouchers/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public generateVouchers(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/vouchers/generate", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public deactivateVouchers(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/vouchers/deactivate", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public downloadVouchers(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/vouchers/download", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveVouchers(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/vouchers/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getAllVehicles(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiMotorMaintenanceUrl + "/vehicles/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getVehicle(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiMotorMaintenanceUrl + "/vehicles/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveVehicleMaintenance(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiMotorMaintenanceUrl + "/vehicles/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getAllVehiclesCompany(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiMotorMaintenanceUrl + "/vehicles/company/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveVehicleCompany(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiMotorMaintenanceUrl + "/vehicles/company/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getAllVehiclesMake(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiMotorMaintenanceUrl + "/vehicles/make/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveVehicleMake(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiMotorMaintenanceUrl + "/vehicles/make/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getAllUserID(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/all-users", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getAllSourceCode(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/source-code/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveSourceCode(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/source-code/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getAllSourceExtension(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/source-extension/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveSourceExtension(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/source-extension/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getAllIssueSource(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/issue-source/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveIssueSource(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/issue-source/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getAllSubline(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/subline/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveSubline(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/subline/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getAllLine(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/line/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveLine(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/line/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getAllPlan(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/plan/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public savePlan(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/plan/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getAllPlanPeril(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/plan/peril/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public savePlanPeril(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/plan/peril/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getAllPeril(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/peril/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public savePeril(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/peril/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getEmailContent(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/email-content/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveEmailContent(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/email-content/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getBenefitsCoverage(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/benefits-coverage", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveBenefitsCoverage(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/benefits-coverage/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getBenefitsCoveragePerPlanPeril(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/benefits-coverage-per-plan-peril", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveBenefitsCoveragePerPlanPeril(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/benefits-coverage-per-plan-peril/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getWarrClausePerPlan(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/warr-clause-per-plan", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveWarrClausePerPlan(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/warr-clause-per-plan/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getAllTax(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/tax/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveTax(params: any): any {
        this.baseRequest(new HttpParams().set("params", params));
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/tax/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getTaxCharges(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/tax/charges/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveTaxCharges(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/tax/charges/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getAllAssuredIdType(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/assured-id-type/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveAssuredIdType(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/assured-id-type/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getStandardAmtCovered(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiMotorMaintenanceUrl + "/std-amt-covered/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveStandardAmtCovered(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiMotorMaintenanceUrl + "/std-amt-covered/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getAllCarTypes(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiMotorMaintenanceUrl + "/car-type", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getIntroduction(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/introduction/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveIntroduction(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/introduction/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getProject(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/project/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveProject(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/project/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getMortgagee(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiMotorMaintenanceUrl + "/mortgagee/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveMortgagee(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiMotorMaintenanceUrl + "/mortgagee/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getUploadRenewalData(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/renewal/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveUploadRenewalData(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/renewal/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getRenewalProcessLogs(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/renewal/logs", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveRenewalData(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/renewal/update", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getRenewalData(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/renewal/view", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveRenewalAuditTrail(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/renewal/audit", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getReport(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/report/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveReport(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/report/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getReportSignatory(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/report-signatory/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveReportSignatory(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/report-signatory/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getReportParameter(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/report-parameter", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveReportParameter(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/report-parameter/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getOccupation(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/occupation/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveOccupation(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/occupation/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getContractType(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/contract-type/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveContractType(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/contract-type/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getFAQMain(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/faq-main/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveFAQMain(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/faq-main/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getFAQSubline(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/faq-subline/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveFAQSubline(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/faq-subline/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getReferrorPageCount(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/referror/page-count", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getReferror(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/referror/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveReferror(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/referror/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getClientGroup(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/client-group/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveClientGroup(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/client-group/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getReasonCancellation(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/reason-cancellation/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveReasonCancellation(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/reason-cancellation/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getSystemParameter(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/system-parameter/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveSystemParameter(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/system-parameter/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public validateUserAccess(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/validate-user-access", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getOccupationClass(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/occupation-class/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveOccupationClass(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/occupation-class/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getCategory(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/category/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getAllPublicUserStandardRates(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPremiumRates + "/public-user-standard-rates/get", {
            params: this.encryptedParams
        },
            { headers: this.headers });
    }

    public getAllContractType(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/contract-type/get", {
            params: this.encryptedParams
        },
            { headers: this.headers });
    }

    public savePublicUserStandardRates(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPremiumRates + "/public-user-standard-rates/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getCurrency(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/currency/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveCurrency(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/currency/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getCurrencyRt(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/currency-rt/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveCurrencyRt(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/currency-rt/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getPaytOpt(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/payment-options/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public savePaytOpt(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/payment-options/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getCredCardInstPaytOpt(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/credit-card-options/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveCredCardInstPaytOpt(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/credit-card-options/save", {
            params: this.encryptedParams
        }, { headers: this.headers });

    }

    public getPublicUserStandardRatesPerVehicle(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPremiumRates + "/public-user-standard-rates-per-vehicle/get", {
            params: this.encryptedParams
        },
            { headers: this.headers });
    }

    public savePublicUserStandardRatesPerVehicle(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPremiumRates + "/public-user-standard-rates-per-vehicle/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getPaytOptAccess(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/payment-options-access/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public savePaytOptAccess(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/payment-options-access/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getAllAccType(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiUserAccount + "/account-type/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getBfbStandardRates(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPremiumRates + "/bfb-standard-rates/get", {
            params: this.encryptedParams
        },
            { headers: this.headers });
    }

    public saveBfbStandardRates(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPremiumRates + "/bfb-standard-rates/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getBfbSrcExt(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/bfb-src-ext/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getBfbSrcExtPageCount(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/bfb-src-ext/page-count", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveBfbSrcExt(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/bfb-src-ext/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getTariff(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/tariff-code/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveTariff(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/tariff-code/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getMvPremType(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/mv-prem-type/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveMvPremType(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/mv-prem-type/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }
    public getBpiMsStandardRates(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPremiumRates + "/bpi-ms-standard-rates/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveBpiMsStandardRates(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPremiumRates + "/bpi-ms-standard-rates/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getMaritalStatus(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/marital-status/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveMaritalStatus(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/marital-status/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getJobCategory(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/job-category/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveJobCategory(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/job-category/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getCoverageType(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/coverage-type", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveCoverageType(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/coverage-type/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getRpaPerilMapping(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/rpa-peril-mapping/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveRpaPerilMapping(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/rpa-peril-mapping/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getRpaGenPageMapping(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/rpa-gen-page-mapping/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveRpaGenPageMapping(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/rpa-gen-page-mapping/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getRpaClauseMapping(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/rpa-clause-mapping/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getLineWarrClause(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/line-warr-clause/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveLineWarrClause(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/line-warr-clause/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveRpaClauseMapping(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/rpa-clause-mapping/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getBfbStandardRatesPerVehicle(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPremiumRates + "/bfb-standard-rates-per-vehicle/get", {
            params: this.encryptedParams
        },
            { headers: this.headers });
    }

    public saveBfbStandardRatesPerVehicle(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPremiumRates + "/bfb-standard-rates-per-vehicle/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getBpiMsStandardRatesPerVehicle(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPremiumRates + "/bpi-ms-standard-rates-per-vehicle/get", {
            params: this.encryptedParams
        },
            { headers: this.headers });
    }

    public saveBpiMsStandardRatesPerVehicle(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPremiumRates + "/bpi-ms-standard-rates-per-vehicle/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getMvType(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/mv-type/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveMvType(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/mv-type/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getSrcFund(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiCustInfo + "/src-fund/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveSrcFund(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiCustInfo + "/src-fund/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getDealershipStandardRates(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPremiumRates + "/dealership-standard-rates/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveDealershipStandardRates(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPremiumRates + "/dealership-standard-rates/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getNationality(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiCustInfo + "/nationality/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveNationality(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiCustInfo + "/nationality/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getRelationship(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/relationship/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveRelationship(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/relationship/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getMHighRisk(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiMotorMaintenanceUrl + "/motor-high-risk/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveMHighRisk(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiMotorMaintenanceUrl + "/motor-high-risk/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getAgencyStandardRates(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPremiumRates + "/agency-standard-rates/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveAgencyStandardRates(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPremiumRates + "/agency-standard-rates/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getAgencyStandardRatesPerVehicle(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPremiumRates + "/agency-standard-rates-per-vehicle/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveAgencyStandardRatesPerVehicle(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPremiumRates + "/agency-standard-rates-per-vehicle/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getRefCodes(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/reference-codes/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveRefCodes(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/reference-codes/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getSegmentCode(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/segment-code/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveSegmentCode(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/segment-code/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getCustomerUserAccount(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/customer-user-account/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getAssured(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/assured/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getAssuredId(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/assured-id/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getAssuredAttachment(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/assured-attachment/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getPurpose(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/purpose/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public savePurpose(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/purpose/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getRiskProfile(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiCustInfo + "/risk-profile/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveRiskProfile(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiCustInfo + "/risk-profile/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getDeductible(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/deductible/get-specific", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveDeductible(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/deductible/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getPlanAccess(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/plan-access/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public savePlanAccess(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/plan-access/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getDeductiblePerClientGroup(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/deductible-per-client-group/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveDeductiblePerClientGroup(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/deductible-per-client-group/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getAllDeductibles(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/deductible/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getDeductiblePerClientGroupPerIssueSource(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/deductible-per-client-group-per-iss-src/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveDeductiblePerClientGroupPerIssueSource(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/deductible-per-client-group-per-iss-src/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getDeductiblePerClientGroupCMF(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/deductible-per-client-group-cmf/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveDeductiblePerClientGroupCMF(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/deductible-per-client-group-cmf/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getDeductiblePerClientGroupPerIssueSourceCMF(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/deductible-per-client-group-per-iss-src-cmf", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveDeductiblePerClientGroupPerIssueSourceCMF(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/deductible-per-client-group-per-iss-src-cmf/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getApplicableDeductiblePerPlan(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/applicable-deductible-per-plan", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveApplicableDeductiblePerPlan(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/applicable-deductible-per-plan/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getUploadRenewalDataBatch(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/renewal/get/batch", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveRenewalDataBatch(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/renewal/update/batch", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getProductCodes(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/product-codes", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveProductCodes(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/product-codes/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getCancellationPolicy(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/policy-cancellation", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public proceedCancellation(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/policy-cancellation/cancel", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getClientGroupStandardRates(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/client-group-standard-rates", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveClientGroupStandardRates(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/client-group-standard-rates/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getApplicableClientGroupPerUserID(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/applicable-client-group-per-user-id", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveApplicableClientGroupPerUserID(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/applicable-client-group-per-user-id/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getStandardRatesPerPlan(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/standard-rates-per-plan", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveStandardRatesPerPlan(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/standard-rates-per-plan/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getClientGroupPerUserType(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/client-group-per-user-type", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getStandardRatesPerPlanBasedIssuingOffice(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/standard-rates-per-plan-based-io", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveStandardRatesPerPlanBasedIssuingOffice(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/standard-rates-per-plan-based-io/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getTowingAmtPerClientGrpVehicle(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiMotorMaintenanceUrl + "/towing-amt-per-client-grp-vehicle", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveTowingAmtPerClientGrpVehicle(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiMotorMaintenanceUrl + "/towing-amt-per-client-grp-vehicle/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getTowingAmtPerClient(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiMotorMaintenanceUrl + "/towing-amt-per-client-grp", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveTowingAmtPerClient(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiMotorMaintenanceUrl + "/towing-amt-per-client-grp/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getRiskRatingQuestions(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/risk-rating-questions/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveRiskRatingQuestions(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/risk-rating-questions/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getBlockedVehicles(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiMotorMaintenanceUrl + "/blocked-vehicles", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveBlockedVehicles(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiMotorMaintenanceUrl + "/blocked-vehicles/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getQuickLinks(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/quick-links/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveQuickLinks(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/quick-links/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveAllAccType(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiUserAccount2 + "/account-type/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getVehicleFilter(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiMotorMaintenanceUrl + "/vehicle-filter/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveVehicleFilter(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiMotorMaintenanceUrl2 + "/vehicle-filter/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getPAHCIPParameterValidation(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/pa-hcip-parameter-validation/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public savePAHCIPParameterValidation(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/pa-hcip-parameter-validation/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getStandardAmountPerClientGrpPerCarType(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiMotorMaintenanceUrl2 + "/std-amt-per-client-grp-per-car/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveStandardAmountPerClientGrpPerCarType(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiMotorMaintenanceUrl2 + "/std-amt-per-client-grp-per-car/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveClientGroupPerUserType(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/client-group-per-user-type/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }


    public getClientGroupRatesForFixedPlan(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/client-group-rates-for-fixed-plan", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveClientGroupRatesForFixedPlan(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/client-group-rates-for-fixed-plan/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getClientGroupRatesForFixedPlanBasedIO(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/client-group-rates-for-fixed-plan-based-io", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveClientGroupRatesForFixedPlanBasedIO(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/client-group-rates-for-fixed-plan-based-io/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getClientGroupRatesFlexPlanPerIss(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiMotorMaintenanceUrl + "/client-group-rates-flexible-plan-per-iss", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveClientGroupRatesFlexPlanPerIss(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiMotorMaintenanceUrl + "/client-group-rates-flexible-plan-per-iss/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getPolicyAccumulationRegister(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl + "/pol-accumulation-register/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public savePolicyAccumulationRegister(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/pol-accumulation-register/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getFireAccumulationRegister(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/fire-accumulation-register/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveFireAccumulationRegister(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/fire-accumulation-register/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }
    public getAllAmtCoveredPremAmtLmtPerAccType(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiUserAccount2 + "/std-amt-covered-prem-amt-per-acc-type/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveAllAmtCoveredPremAmtLmtPerAccType(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiUserAccount2 + "/std-amt-covered-prem-amt-per-acc-type/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getEarthquakeZone(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/earthquake-zone", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveEarthquakeZone(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/earthquake-zone/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getRegion(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/region", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveRegion(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/region/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getPlanAmtLimits(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/plan-amt-limits", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public savePlanAmtLimits(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/plan-amt-limits/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getPropertyAssessmentWordings(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/prop-ass-wordings", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public savePropertyAssessmentWordings(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/prop-ass-wordings/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getPropertySiLimit(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/property-si-limit", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public savePropertySiLimit(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/property-si-limit/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getSrcCdPerDealership(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/src-cd-per-dealership", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveSrcCdPerDealership(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/src-cd-per-dealership/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getSrcExtPerDealership(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/src-ext-per-dealership", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveSrcExtPerDealership(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/src-ext-per-dealership/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getTyphoonFloodZoning(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/typhoon-flood-zoning", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveTyphoonFloodZoning(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/typhoon-flood-zoning/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getOccupancy(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/occupancy", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveOccupancy(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/occupancy/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getFireDistrict(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/fire-district", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getFireDistrictCount(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/fire-district/count", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveFireDistrict(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/fire-district/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getHazardGrade(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/hazard-grade", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveHazardGrade(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/hazard-grade/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getStates(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/state", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getConstructionType(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/construction-type", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveConstructionType(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/construction-type/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveState(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/state/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // OTP
    // VERIFY OTP
    public sendOtp(params: any): any {
        this.baseRequest(params);
        sessionStorage.removeItem("bssid");
        return this.httpClient.post(this.apiAdminUrl2 + "/otp/send", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    // VERIFY OTP
    public verifyOtp(params: any): any {
        this.baseRequest(params);
        sessionStorage.removeItem("bssid");
        return this.httpClient.post(this.apiAdminUrl2 + "/otp/verify", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public security(params: any): any {
        this.baseRequest(params);
        sessionStorage.removeItem("bssid");
        return this.httpClient.post(this.apiAdminUrl2 + "/otp", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveOtpToken(params: any): any {
        this.baseRequest(params);
        sessionStorage.removeItem("bssid");
        return this.httpClient.post(this.apiAdminUrl2 + "/otp/token", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getConstructionYear(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/construction-year", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveConstructionYear(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/construction-year/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getClassCd(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/class-cd", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveClassCd(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/class-cd/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getPlanNotification(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/plan-notification", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public savePlanNotification(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/plan-notification/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getEffectiveDateBasis(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/effective-date-basis", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveEffectiveDateBasis(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/effective-date-basis/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getPropertyDetails(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/property-details", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public savePropertyDetails(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/property-details/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getDetermineHcaPlan(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/determine-hca-plan", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveDetermineHcaPlan(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/determine-hca-plan/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getFirePolWordings(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/fire-pol-wordings", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveFirePolWordings(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/fire-pol-wordings/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getGeographicZoning(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/geographic-zoning", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveGeographicZoning(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/geographic-zoning/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getPreApprovedSubdivision(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/pre-approved-subdivision", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public savePreApprovedSubdivision(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/pre-approved-subdivision/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getClientGrpPerAgentGrp(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiMotorMaintenanceUrl2 + "/client-group-per-agent-group", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveClientGrpPerAgentGrp(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiMotorMaintenanceUrl2 + "/client-group-per-agent-group/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getCatZoneFireRatesPu(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/cat-zone-fire-rates-pu", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveCatZoneFireRatesPu(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/cat-zone-fire-rates-pu/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getCatZoneFireRatesBpims(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/cat-zone-fire-rates-bpims", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveCatZoneFireRatesBpims(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/cat-zone-fire-rates-bpims/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getCatZoneFireRatesBpi(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/cat-zone-fire-rates-bpi", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveCatZoneFireRatesBpi(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/cat-zone-fire-rates-bpi/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getCatZoneFireRatesAgent(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/cat-zone-fire-rates-agent", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveCatZoneFireRatesAgent(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/cat-zone-fire-rates-agent/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getTyphoonZoning(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/typhoon-zoning", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getFloodZoning(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/flood-zoning", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getBusinessCode(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/business-code", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveBusinessCode(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/business-code/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getApplicableItemCodePerPlan(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/applicable-item-code-per-plan", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveFireItemCodePerPlan(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/applicable-item-code-per-plan/save-fire-item-code-per-plan", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public savePerilPerFireItemCode(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/applicable-item-code-per-plan/save-peril-per-fire-item-code", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getFireItemCode(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/fire-item-code", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveFireItemCode(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/fire-item-code/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getFirePremRates(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/fire-prem-rates", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveFirePremRates(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/fire-prem-rates/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getFirePremiumRatesPerClientGroup(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/fire-premium-rate-per-client-group", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveFirePremiumRatesPerClientGroup(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/fire-premium-rate-per-client-group/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getOccupancyAndBusinessCodeperPlan(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/occupancy-business-code-per-plan", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveOccupancyAndBusinessCodeperPlan(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/occupancy-business-code-per-plan/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getOpenPolicySeqNo(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/open-policy-seq-no", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getOpenPolicySeqNoViewPolicies(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/open-policy-seq-no/view-policies", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveOpenPolicySeqNo(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/open-policy-seq-no/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }
        
    public getFireFloodZone(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/fire-flood-zone/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveFireFloodZone(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/fire-flood-zone/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }
    
    public getFireTyphoonZone(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/fire-typhoon-zone/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveFireTyphoonZone(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/fire-typhoon-zone/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }
    
    public getQuotationWordings(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/quotation-wordings/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveQuotationWordings(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/quotation-wordings/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getFmvHistoryList(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiMotorMaintenanceUrl2 + "/fmv/get/history", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getFireCatPremiumRatesPerClientGroup(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/fire-cat-perils-prem-rates-per-client", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveFireCatPremiumRatesPerClientGroup(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/fire-cat-perils-prem-rates-per-client/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getSrcExtforRelaCode(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPolicyHeader2 + "/src-ext-for-rela-code/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveSrcExtforRelaCode(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPolicyHeader2 + "/src-ext-for-rela-code/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getAdminLov(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/lov", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveUploadSrcExtForRelaCode(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiPolicyHeader2 + "/src-ext-for-rela-code/upload", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getPolicySequenceNumber(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/policy-sequence-number/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public savePolicySequenceNumber(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/policy-sequence-number/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveUploadPolicySequenceNumber(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/policy-sequence-number/upload", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getUserLevel(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/user-level/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveUserLevel(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/user-level/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public saveUploadUserLevel(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/user-level/upload", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }
    public getFreqList(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/payment-frequency/get", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getAllCyberPremiums(params = {}): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + '/cyber/premiums/get', {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public getAllCyberPremiumAvailableFrequencies(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + '/cyber/premiums/frequencies', {
            params: this.encryptedParams
        }, { headers: this.headers });
    }

    public handleCyberPremiums(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + '/cyber/premiums/handle', {
            params: this.encryptedParams
        }, { headers: this.headers });
    }
    
    public savePaymentFrequency(params: any): any {
        this.baseRequest(params);
        return this.httpClient.post(this.apiGeneralMaintenanceUrl2 + "/payment-frequency/save", {
            params: this.encryptedParams
        }, { headers: this.headers });
    }
}
