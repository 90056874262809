<div class="mtn-container">
    <div class="mtn-title">
        <p>GENERATE VOUCHER CODE</p>
    </div>
    <form [formGroup]="generateVoucherCodeForm">
        <div class="form">
            <p id="generate-voucher-code-error-message" class="form-error-msg"></p>
            <div class="row">
                <div class="field required">
                    <label>Line</label>
                    <input formControlName="lineDisplay" class="no-color" readonly />
                </div>
            </div>
            <div class="row">
                <div class="field required">
                    <label>Subline</label>
                    <input formControlName="sublineDisplay" class="no-color" readonly />
                </div>
            </div>
            <div class="row">
                <div class="field required">
                    <label>Plan</label>
                    <input formControlName="planDisplay" class="no-color" readonly />
                </div>
            </div>
            <div class="row">
                <div class="field required">
                    <label>Client Type Group</label>
                    <div class="with-button">
                        <input formControlName="clientTypeGroupDisplay" class="no-color" readonly />
                        <button matSuffix class="btn btn-search" type="button" (click)="openLineLOV('clientTypeGroup')">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="field required">
                    <label>Count</label>
                    <input type="text" min="1" posNumInput formControlName="count" maxlength="4" />
                </div>
            </div>
            <div class="row">
                <div class="field required">
                    <label>Effective Date</label>
                    <div class="with-button">
                        <input matInput date-input formControlName="effectiveDate" type="text" placeholder="MM/DD/YYYY" [min]="minEffDate" [max]="maxEffDate" [matDatepicker]="effectiveDatePicker" />
                        <mat-datepicker-toggle matSuffix [for]="effectiveDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #effectiveDatePicker></mat-datepicker>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="field">
                    <label>Expiry Date</label>
                    <div class="with-button">
                        <input matInput date-input formControlName="expiryDate" type="text" placeholder="MM/DD/YYYY" [min]="minExpDate" [matDatepicker]="expiryDatePicker" />
                        <mat-datepicker-toggle matSuffix [for]="expiryDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #expiryDatePicker></mat-datepicker>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="field">
                    <label>With Payment</label>
                    <select formControlName="withPayment">
                        <option *ngFor="let withPaymentType of selections.withPaymentTypes" [value]="withPaymentType.cd">
                            {{ withPaymentType.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="field" [ngClass]="{'required' : paymentMethodRequired }">
                    <label>Payment Method</label>
                    <select formControlName="paymentMethod">
                        <option *ngFor="let paymentOption of selections.paymentOptions" [ngValue]="paymentOption.cd">
                            {{ paymentOption.disp }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="form">
            <div class="row">
                <div class="field">
                    <label>Project Code</label>
                    <div class="with-button">
                        <input formControlName="projectCode" class="no-color" readonly />
                        <button matSuffix class="btn btn-search" type="button" (click)="openLineLOV('project')">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="field">
                    <label>Agent</label>
                    <div class="with-button">
                        <input formControlName="agent" class="no-color" readonly />
                        <button matSuffix class="btn btn-search" type="button" (click)="openLineLOV('agent')">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="field">
                    <label>BMA</label>
                    <div class="with-button">
                        <input formControlName="bma" class="no-color" readonly />
                        <button matSuffix class="btn btn-search" type="button" (click)="openLineLOV('bma')">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="field">
                    <label>Referrer</label>
                    <div class="with-button">
                        <input formControlName="referrer" class="no-color" readonly />
                        <button matSuffix class="btn btn-search" type="button" (click)="openLineLOV('referror')">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="field">
                    <label>Source Code</label>
                    <div class="with-button">
                        <input formControlName="sourceCode" class="no-color" readonly />
                        <button matSuffix class="btn btn-search" type="button" (click)="openLineLOV('src')">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="field">
                    <label>Source Extension</label>
                    <div class="with-button">
                        <input formControlName="sourceExtension" class="no-color" readonly />
                        <button matSuffix class="btn btn-search" type="button" (click)="openLineLOV('srcExt')">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-btns">
            <button class="btn btn2" type="button" (click)="onCancel()">Cancel</button>
            <button class="btn btn2" type="button" (click)="onProceed()">Proceed</button>
        </div>
    </form>
</div>
