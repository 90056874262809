<section id="construction-type">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{this.moduleName}}</p>
        </div>
        <div class = 'table-line'>
            <osp-table [tblConfig]="constructionTypeTblConfig" (onRowClick)="onRowClick($event)"></osp-table>
        </div>
        <!-- Loading Animation -->
        <div id="generate-line-form">
            <div class="form-loader"></div>
        </div>
        <!-- Container for Line Fields -->
        <div class="form-content">
            <form [formGroup]="constructionTypeForm" class="form" *ngIf="noLoadingInProcess">
                <div id="construction-type-error-message"></div>
                <div class="form-container-grid">
                    <div class="field required">
                        <label>Construction Code</label>
                    </div>
                    <div class="">
                        <input formControlName="constructionCode" type="text" readonly>
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag">
                            <option value="A" selected>Yes</option>
                            <option value="I">No</option>
                        </select>
                    </div>
                    <div class="field required">
                        <label>BPI MS Code</label>
                    </div>
                    <div class="">
                        <input formControlName="bpimsCode" type="text" maxlength="8" alphanumeric>
                    </div>
                    <div class="field">
                        <label></label>
                    </div>
                    <div class="">
                    </div>
                    <div class="field required">
                        <label>Construction Description</label>
                    </div>
                    <div class="span">
                        <input formControlName="constructionDesc" type="text" maxlength="2000">
                    </div>
                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" type="text" maxlength="1000">
                    </div>
                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="userID">
                    </div>
                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUserUpdate">
                    </div>
                </div>
                <div class='btn-row'>
                    <button type="button" id='btn-add' *ngIf="!isLineSelected" title='Register a new line' class="btn btn2" value='ADD' type="submit" [disabled]='addDisabled' (click)="insertLine()">ADD</button>
                    <button type="button" id='btn-update' *ngIf="isLineSelected" title='Update a line' class="btn btn2" value='UPDATE' type="submit" (click)="updateLine()">UPDATE</button>
                    <button type="button" id='btn-delete' title='Remove a line' class="btn btn2" value='DELETE' type="submit" [disabled]='deleteDisabled' (click)="deleteLine()">DELETE</button>
                </div> 

                <div class="mtn-title"></div>

                <div class='btn-row'>
                    <button type="button" id='btn-save' title='Save changes on line details' class="btn btn2" value='SAVE' type="submit" (click)="toSave()" [disabled]='saveDisabled'>SAVE</button>
                </div>
            </form>
        </div>
    </div>
</section>