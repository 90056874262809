import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';

@Component({
  selector: 'region-maintenance',
  templateUrl: './region-maintenance.component.html',
  styleUrls: ['./region-maintenance.component.css']
})
export class RegionMaintenanceComponent implements OnInit, LeaveConfirmation {

  private moduleId: string = 'BMM106';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc?.toUpperCase() ?? "";

  constructor(
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    public _formService: FormService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService
  ) { 
    this.userIdLoggedIn = this._userDetailService.userId ?? "";
  }

  ngOnInit(): void {
    this.getData.region();
  }

  canDeactivate(): boolean | Observable<boolean> {
    if(this.tempData!='') {
      return false;
    } else {
      return true;
    }
  }

  public btns = {
    region: {
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      addDisabled: true,
    },
  }

  public selections = {
    activeTypes: [
      { cd: 'A', name: 'Yes' },
      { cd: 'I', name: 'No' },
    ]
  }

  public showForm: boolean = true;

  private rowData: any;
  private tempDataRow: any;
  private errorMessage: string = "";
  private userIdLoggedIn: string;
  private tempData: any = [];

  public forms = {
    regionForm: this.fb.group({
      regionCd: [null],
      regionCdDisp: [{ value: '', disabled: 'true' }],
      regShortDesc: [null, [Validators.required, Validators.maxLength(25), this.checkDuplicateRegShortDesc(), this.noWhiteSpaceCheck()]],
      regionDesc: [null, [Validators.required, Validators.maxLength(1000), this.noWhiteSpaceCheck()]],
      activeTag: ['A', [Validators.required]],
      bmCode: [null, [Validators.required, Validators.maxLength(8), this.noWhiteSpaceCheck()]],
      remarks: [null, [Validators.maxLength(1000)]],
      userID: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }],
    }),
    resetRegionForm: () => {
      this.forms.regionForm.reset();
      this.forms.regionForm.patchValue({
        activeTag: 'A'
      });
      this.btns.region.updateDisabled = this.btns.region.deleteDisabled = true;
      this._formService.hideFormMsg("region-error-message");
      // this.rowData = null;
    }
  }

  private regionFormManipulate = {
    dbToForm: (data: any) => {
      return {
        regionCd: data.REGION_CD,
        regionCdDisp: data.REGION_CD_DISP,
        regShortDesc: data.REG_SHORT_DESC,
        regionDesc: data.REGION_DESC,
        activeTag: data.ACTIVE_TAG,
        bmCode: data.BM_CODE,
        remarks: data.REMARKS,
        userID: data.USER_ID,
        lastUpdate: data.LAST_UPDATE,
      };
    },
    formToDb: (data: any, mode: any) => {
      return {
        REGION_CD: data.regionCd,
        REGION_CD_DISP: data.regionCd?.toString().padStart(2, 0) ?? null,
        REG_SHORT_DESC: data.regShortDesc.trim(),
        REGION_DESC: data.regionDesc.trim(),
        ACTIVE_TAG: data.activeTag,
        BM_CODE: data.bmCode,
        REMARKS: data.remarks,
        USER_ID: this.userIdLoggedIn,
        LAST_UPDATE: null,
        MODE: mode
      };
    },
  }

  public regionTblConfig = {
    cols: [
      {
        key: "REGION_CD_DISP",
        header: "Region Code",
        dataType: "string",
        width: '16%'
      },
      {
        key: "REG_SHORT_DESC",
        header: "Region Short Description",
        dataType: "string",
        width: '30%'
      },
      {
        key: "REGION_DESC",
        header: "Region Long Description",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        width: '64px'
      }
    ],
    tblData: [] as any[],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  private getData = {
    region: () => {
      try {
        this.regionTblConfig.tblData = [];
        this.regionTblConfig.loading = true;
        this._jDS.contorlLoading(true);
        this._api.getRegion({
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          type: "MODULE"
        }).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                let content = JSON.parse(this._jDS.decrypt(data.response));
                this._jDS.contorlLoading(false);
                this.regionTblConfig.tblData = content.data;
                this.btns.region.addDisabled = false;
                this.regionTblConfig.loading = false;
              });
            });
          },
          error: (error: any) => {
            this.regionTblConfig.loading = false;
            this._jDS.contorlLoading(false);
          }
        });
      }
      catch (e) {
        console.error(e);

      }
    }
  }

  public onRowClick(ev: any) {
    try {
      ev.update = true;
      this.rowData = ev;
      this.populateFormFromTable();
    }
    catch (e) {
      this.forms.resetRegionForm();
      this.rowData = null;
      //this.indexRow = null;
    }
  }

  private populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.region.updateDisabled = !data.update;
      this.btns.region.deleteDisabled = !data.temp;
      this.tempDataRow = !data.temp;
      this.forms.regionForm.patchValue(this.regionFormManipulate.dbToForm(data));
    }
    catch (e) {
      console.error(e);
    }
  }

  public onAdd() {
    try {
      this._formService.hideFormMsg("region-error-message");
      if (this.forms.regionForm.valid) {
        let rowToBeAdded: { [key: string]: any } = this.regionFormManipulate.formToDb(this.forms.regionForm.value, 'I');
        rowToBeAdded.temp = true;
        this.tempData.push(rowToBeAdded);
        this.regionTblConfig.tblData = [rowToBeAdded, ...this.regionTblConfig.tblData];
        this.forms.resetRegionForm();
        this.btns.region.saveDisabled = false;
      }
      else {
        this.showErrorValidator.region();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public onDelete() {
    try {
      let clickData = this.rowData;
      this.regionTblConfig.tblData = this.regionTblConfig.tblData.filter((d: any) => {
        return d !== this.rowData;
      });
      this.tempData = this.tempData.filter(
        function(e: any) {
          return e.REG_SHORT_DESC != clickData.REG_SHORT_DESC || e.REGION_DESC != clickData.REGION_DESC;
        }
      );
      this.forms.resetRegionForm();
    }
    catch (e) {
      console.error(e);
    }
  }

  public onUpdate() {
    try {
      this._formService.hideFormMsg("region-error-message");
      if (this.forms.regionForm.valid) {
        let clickData = this.rowData;
        let toBeUpdatedIndex = this.regionTblConfig.tblData.indexOf(this.rowData);
        let regionCdRowValue = this.rowData.REGION_CD;
        let rowToBeUpdated: { [key: string]: any } = this.regionFormManipulate.formToDb(this.forms.regionForm.value, 'U');
        let tempInsertChecker = this.tempData.find(
          (j: { REG_SHORT_DESC: any, REGION_DESC: any; }) => j.REG_SHORT_DESC == clickData.REG_SHORT_DESC && j.REGION_DESC == clickData.REGION_DESC && clickData.MODE == 'I');
        let tempUpdateChecker = this.tempData.find(
          (j: { REG_SHORT_DESC: any, REGION_DESC: any; }) => j.REG_SHORT_DESC == clickData.REG_SHORT_DESC && j.REGION_DESC == clickData.REGION_DESC && clickData.MODE == 'U');
        if (this.rowData.temp) {
          rowToBeUpdated.temp = true;
        }
        else {
          rowToBeUpdated.onDbButUpdatedTemp = true;
          rowToBeUpdated.REGION_CD = regionCdRowValue;
        }

        if (tempInsertChecker!=null && tempInsertChecker!='') {
          //tempInsertChecker = rowToBeUpdated;
          tempInsertChecker.REG_SHORT_DESC = rowToBeUpdated.REG_SHORT_DESC;
          tempInsertChecker.REGION_DESC = rowToBeUpdated.REGION_DESC;
          tempInsertChecker.ACTIVE_TAG = rowToBeUpdated.ACTIVE_TAG;
          tempInsertChecker.BM_CODE = rowToBeUpdated.BM_CODE;
          tempInsertChecker.REMARKS = rowToBeUpdated.REMARKS;
          tempInsertChecker.USER_ID = rowToBeUpdated.USER_ID;
          tempInsertChecker.MODE = 'I';
          rowToBeUpdated.MODE = 'I';
        }
        else if (tempUpdateChecker!=null && tempUpdateChecker!='') {
          tempUpdateChecker.REG_SHORT_DESC = rowToBeUpdated.REG_SHORT_DESC;
          tempUpdateChecker.REGION_DESC = rowToBeUpdated.REGION_DESC;
          tempUpdateChecker.ACTIVE_TAG = rowToBeUpdated.ACTIVE_TAG;
          tempUpdateChecker.BM_CODE = rowToBeUpdated.BM_CODE;
          tempUpdateChecker.REMARKS = rowToBeUpdated.REMARKS;
          tempUpdateChecker.USER_ID = rowToBeUpdated.USER_ID;
          tempUpdateChecker.MODE = 'U';
        }
        else { 
          this.tempData.push(rowToBeUpdated);
        }
        this.forms.resetRegionForm();
        this.regionTblConfig.tblData[toBeUpdatedIndex] = rowToBeUpdated;
        this.btns.region.saveDisabled = false;
        this.regionTblConfig.tblData = [... this.regionTblConfig.tblData];
      }
      else {
        this.showErrorValidator.region();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public onSave() {
    try {
      this.btns.region.saveDisabled = true;
      this.showForm = false;
      this._formService.showFormLoader(null, "region-maintenance-loading", "Saving.<br>Please wait ...", null, null);
      if (this.tempData.length > 0) {
        this._api.saveRegion(this.tempData).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                if (data.status === 'SUCCESS') {
                  this.onComplete.region();
                }
                else {
                  this.onComplete.regionError();
                }
              });
            });
          },
          error: (data: any) => {
            this.onComplete.regionError();
          },
        });
      }
      else
        this.onComplete.region();
    }
    catch (e) {
      console.error(e);
    }
  }

  private onComplete = {
    region: () => {
      this.tempData = [];
      this.getData.region();
      this.btns.region.saveDisabled = true;
      this.showForm = true;
      this._formService.hideFormLoader("region-maintenance-loading");
      this.forms.resetRegionForm();
      this.openDialog('SUCCESS', 'Region details successfully saved');
    },
    regionError: () => {
      this.showForm = true;
      this.btns.region.saveDisabled = false;
      this.openDialog('FAILED', 'Region details saving failed');
      this._formService.hideFormLoader("region-maintenance-loading");
    }
  }

  openDialog(title: any, message: any) {
    try {
      this.showForm = true;
      this._formService.hideFormLoader("region-maintenance-loading");
      this._matDialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        } 
      });
    } catch(e) {
      console.error(e);
    }
  }

  noWhiteSpaceCheck(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: true };
    }
  }
  
  checkDuplicateRegShortDesc(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.trim()?.toUpperCase();
      if (!value) return null;
      let hasDuplicate: boolean = this.regionTblConfig.tblData.filter((d: any) => {
        if (!this.rowData && this.btns.region.updateDisabled)
          return d.REG_SHORT_DESC.trim().toUpperCase() === value;
        else
          return this.rowData.REG_SHORT_DESC.trim().toUpperCase() !== value && d.REG_SHORT_DESC.trim().toUpperCase() === value;
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupe: true } : null;
    }
  }

  public showErrorValidator = {
    region: () => {
      try {
        Object.keys(this.forms.regionForm.controls).forEach(key => {
          const controlErrors = this.forms.regionForm.get(key)?.errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              if (keyError == 'whitespace' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
              else if (keyError == 'hasDupe' && controlErrors[keyError] === true)
                this.errorMessage = "Region already exists. Please add a unique Region.";
              else if (keyError == 'required' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
            });
          }
        });
        this._formService.showFormMsg("region-error-message", this.errorMessage, "E");
        //this.openDialog('ERROR', this.errorMessage);
        setTimeout(()=>{                           
          this._formService.hideFormMsg("region-error-message");
        }, 10000);
      }
      catch (e) {
        console.error(e);
      }
    },
  }

}