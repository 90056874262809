import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { PerilLovComponent } from '../../common/peril-lov/peril-lov.component';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';

@Component({
  selector: 'coverage-maintenance',
  templateUrl: './coverage-maintenance.component.html',
  styleUrls: ['./coverage-maintenance.component.css']
})
export class CoverageMaintenanceComponent implements OnInit, LeaveConfirmation {

  moduleId: string = 'BMM023';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc?.toUpperCase() ?? "";
  
  constructor(
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    public _formService: FormService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService
  ) { 
    this.userIdLoggedIn = this._userDetailService.userId ?? "";
  }

  ngOnInit(): void {
    this.btns.coverage.addDisabled = true;
    this.btns.coverage.basicPerilDisabled = true;
    this.forms.coverageForm.get('fmvSw')?.disable();
    this.forms.coverageForm.get('ratingTblSw')?.disable();
  }

  canDeactivate(): boolean | Observable<boolean> {
    if(this.tempData!='') {
      return false;
    } else {
      return true;
    }
  }

  /* Boolean Stuffs */

  public btns = {
    coverage: {
      addDisabled: true,
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      basicPerilDisabled: false
    },
  }

  public showForm: boolean = true;

  private rowData: any;
  private tempDataRow: any;
  private errorMessage: string = "";
  private userIdLoggedIn: string;
  private tempData: any = [];

  public forms = {
    lineLovForm: this.fb.group({
      lineCd: [null],
      lineName: [null],
      lineCdDisp: [{ value: '', disabled: 'true' }],
    }),
    coverageForm: this.fb.group({
      lineCd: [null],
      perilCd: [null],
      activeTag: ['A', [Validators.required]],
      perilCdDisp: [{ value: '', disabled: 'true' }],
      perilShortName: [null, [Validators.required, this.noWhiteSpaceCheck()]],
      perilLName: [null, [Validators.required, this.noWhiteSpaceCheck()]],
      perilType: [null, [Validators.required]],
      perilTypeDisp: [null],
      fixedSITag: [null, [Validators.required]],
      fixedPremTag: [null, [Validators.required]],
      defaultCommRate: [null],
      defaultRate: [null],
      defaultTSI: [null, [Validators.required]],
      multiplierRate: [null, [Validators.required]],
      fmvSw: [null, [Validators.required]],
      ratingTblSw: [null, [Validators.required]],
      basicPerilCd: [null],
      basicPerilName: [null],
      basicPerilCdDisp: [null],
      seqNo: [null],
      groupCd: [null],
      premClassCd: [null],
      bmCode: [null, [Validators.required, Validators.maxLength(8), this.noWhiteSpaceCheck()]],
      bmShortDesc: [null],
      shortDesc: [null],
      remarks: [null, [Validators.maxLength(4000)]],
      lastUpdateUser: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }]
    }),
    resetCoverageForm: () => {
      this.forms.coverageForm.reset();
      this.forms.coverageForm.patchValue({
        activeTag: 'A'
      });
      this.btns.coverage.updateDisabled = true;
      this.btns.coverage.deleteDisabled = true;
      this.checkBasicPerilDisable(this.forms.coverageForm.get('perilType')?.value);
      this.checkMotorFieldDisable(this.forms.lineLovForm.get('lineCd')?.value);
      this._formService.hideFormMsg("coverage-error-message");
      this.rowData = null;
    },
    resetLineLovForm: () => {
      this.forms.lineLovForm.reset();
    },
    resetAllForms: () => {
      this.forms.resetLineLovForm();
      this.forms.resetCoverageForm();
    }
  }

  private coverageFormManipulate = {
    dbToForm: (data: any) => {
      return {
        lineCd: data.LINE_CD,
        perilCd: data.PERIL_CD,
        activeTag: data.ACTIVE_TAG,
        perilCdDisp: data.PERIL_CD_DISP,
        perilShortName: data.PERIL_SNAME.toUpperCase(),
        perilLName: data.PERIL_LNAME,
        perilType: data.PERIL_TYPE,
        perilTypeDisp: data.PERIL_TYPE_DISP,
        fixedSITag: data.FIXED_SI_TAG,
        fixedPremTag: data.FIXED_PREM_TAG,
        defaultCommRate: data.DEFAULT_COMM_RATE==null ? null : parseFloat(data.DEFAULT_COMM_RATE).toFixed(9),
        defaultRate: data.DEFAULT_RATE==null ? null : parseFloat(data.DEFAULT_RATE).toFixed(9),
        defaultTSI: data.DEFAULT_TSI==null ? null : this._formService.setToAmountFormat(data.DEFAULT_TSI),
        multiplierRate: data.MULTIPLIER_RATE==null ? null : parseFloat(data.MULTIPLIER_RATE).toFixed(9),
        fmvSw: data.FMV_SW,
        ratingTblSw: data.RATING_TBL_SW,
        basicPerilCd: data.BASIC_PERIL_CD,
        basicPerilName: data.BASIC_PERIL_NAME,
        basicPerilCdDisp: data.BASIC_PERIL_CD_DISP,
        seqNo: data.SEQ_NO,
        groupCd: data.GROUP_CD,
        premClassCd: data.PREM_CLASS_CD,
        bmCode: data.BM_CODE,
        bmShortDesc: data.BM_SHORT_DESC,
        shortDesc: data.SHORT_DESC,
        remarks: data.REMARKS,
        lastUpdateUser: data.USER_ID,
        lastUpdate: data.LAST_UPDATE
      };
    },
    formToDb: (data: any, mode: any) => {
      return {
        LINE_CD: data.lineCd,
        PERIL_CD: data.perilCd,
        ACTIVE_TAG: data.activeTag,
        PERIL_CD_DISP: data.perilCd?.toString().padStart(3, 0) ?? null,
        PERIL_SNAME: data.perilShortName.trim().toUpperCase(),
        PERIL_LNAME: data.perilLName.trim(),
        PERIL_TYPE: data.perilType,
        PERIL_TYPE_DISP: data.perilTypeDisp,
        FIXED_SI_TAG: data.fixedSITag,
        FIXED_PREM_TAG: data.fixedPremTag,
        DEFAULT_COMM_RATE: data.defaultCommRate=='' || data.defaultCommRate==null ? null : parseFloat(data.defaultCommRate).toFixed(9),
        DEFAULT_RATE: data.defaultRate=='' || data.defaultRate==null ? null : parseFloat(data.defaultRate).toFixed(9),
        DEFAULT_TSI: data.defaultTSI=='' || data.defaultTSI==null ? null : parseFloat(this._formService.setToNumberWithDecimal(data.defaultTSI)).toFixed(2),
        MULTIPLIER_RATE: data.multiplierRate=='' || data.multiplierRate==null ? null : parseFloat(data.multiplierRate).toFixed(9),
        FMV_SW: data.fmvSw,
        RATING_TBL_SW: data.ratingTblSw,
        BASIC_PERIL_CD: data.basicPerilCd,
        BASIC_PERIL_NAME: data.basicPerilName,
        BASIC_PERIL_CD_DISP: data.basicPerilCdDisp,
        SEQ_NO: data.seqNo,
        GROUP_CD: data.groupCd,
        PREM_CLASS_CD: data.premClassCd,
        BM_CODE: data.bmCode,
        BM_SHORT_DESC: data.bmShortDesc,
        SHORT_DESC: data.shortDesc,
        REMARKS: data.remarks,
        USER_ID: this.userIdLoggedIn,
        LAST_UPDATE: null,
        MODE: mode
      };
    },
  }

  public openLov = {
    line: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'LINE',
          moduleId: this.moduleId
        },
      }
      this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button === 'OK') {
          this.forms.resetCoverageForm();
          if (output) {
            this.forms.lineLovForm.patchValue({
              lineCd: output.LINE_CD,
              lineName: output.LINE_NAME,
              lineCdDisp: `${output.LINE_CD} - ${output.LINE_NAME}`
            });
          }
          else {
            this.forms.lineLovForm.patchValue({
              lineCd: null,
              lineName: null,
              lineCdDisp: null
            });
          }
          this.getData.coverage();
        }
      });
    },
    basicPeril: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'PERIL',
          lineCd: this.forms.lineLovForm.get('lineCd')?.value,
          moduleId: this.moduleId, 
          lpad: 3
        },
      };
      this._matDialog.open(PerilLovComponent, LOVOPTIONS).afterClosed().subscribe((lovData: any) => {
        if (lovData?.content?.PERIL_CD) {
          const data = lovData?.content;
          this.forms.coverageForm.patchValue({
            basicPerilCdDisp: `${data.PERIL_CD?.toString().padStart(3, 0)} - ${data.PERIL_LNAME}`,
            basicPerilCd: data.PERIL_CD?.toString().padStart(3, 0),
            basicPerilName: data.PERIL_LNAME,
          });
        }
        else {
          this.forms.coverageForm.patchValue({
            basicPerilCdDisp: null,
            basicPerilCd: null,
            basicPerilCdName: null,
          });
        }
      });
    }
  };

  private getData = {
    coverage: () => {
      try {
        this.coverageTblConfig.tblData = [];
        this.coverageTblConfig.loading = true;
        this._jDS.contorlLoading(true);
        this._api.getAllPeril({
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          type: "MODULE"
        }).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                let content = JSON.parse(this._jDS.decrypt(data.response));
                this._jDS.contorlLoading(false);
                this.coverageTblConfig.tblData = content.data.filter((d: any) => d.LINE_CD == this.forms.lineLovForm.get('lineCd')?.value);
                this.btns.coverage.addDisabled = false;
                this.checkMotorFieldDisable(this.forms.lineLovForm.get('lineCd')?.value);
                this.coverageTblConfig.loading = false;
                this.tempData = [];
              });
            });
          },
          error: (error: any) => {
            this.coverageTblConfig.loading = false;
            this._jDS.contorlLoading(false);
          }
        });
      }
      catch (e) {
        console.error(e);
      }
    },
    perilTypes: () => {
      const filteredMag = this._jDS.data.refCds.filter((data: any) => data.rvDomain === 'BMM023.PERIL_TYPE').map((data: any) => { return { cd: data.rvLowValue, name: data.rvMeaning } });
      return filteredMag;
    }
  }

  public selections = {
    activeTypes: [
      { cd: 'A', name: 'Yes' },
      { cd: 'I', name: 'No' },
    ],
    fixedSITags: [
      { cd: 'Y', name: 'Yes' },
      { cd: 'N', name: 'No' },
    ],
    fixedPremTags: [
      { cd: 'Y', name: 'Yes' },
      { cd: 'N', name: 'No' },
    ],
    fmvSws: [
      { cd: 'Y', name: 'Yes' },
      { cd: 'N', name: 'No' },
    ],
    ratingTblSws: [
      { cd: 'Y', name: 'Yes' },
      { cd: 'N', name: 'No' },
    ],
    perilTypes: [
      ...this.getData.perilTypes()
    ],
  }

  coverageTblConfig = {
    cols: [
      {
        key: "PERIL_CD_DISP",
        header: "Peril Code",
        dataType: "string",
        width: '16%'
      },
      {
        key: "PERIL_SNAME",
        header: "Peril Short Name",
        dataType: "string",
        width: '22%'
      },
      {
        key: "PERIL_LNAME",
        header: "Peril Name",
        dataType: "string"
      },
      {
        key: "PERIL_TYPE_DISP",
        header: "Peril Type",
        dataType: "string",
        width: '15%'
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        width: '64px'
      }
    ],
    tblData: [] as any[],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  onRowClick(ev: any) {
    try {
      ev.update = true;
      this.rowData = ev;
      this.populateFormFromTable();
    }
    catch (e) {
      this.forms.resetCoverageForm();
      this.rowData = null;
      //this.indexRow = null;
    }
  }

  populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.coverage.updateDisabled = !data.update;
      this.btns.coverage.deleteDisabled = !data.temp;
      this.tempDataRow = !data.temp;
      this.forms.coverageForm.patchValue(this.coverageFormManipulate.dbToForm(data));
      this.checkBasicPerilDisable(this.forms.coverageForm.get('perilType')?.value);
    }
    catch (e) {
      console.error(e);
    }
  }

  onAdd() {
    try {
      this._formService.hideFormMsg("coverage-error-message");
      this.forms.coverageForm.get('fmvSw')?.enable();
      this.forms.coverageForm.get('ratingTblSw')?.enable();
      if (!this.defaultTSICheck()) {
        this._formService.showFormMsg("coverage-error-message", "Invalid value in Default Amount Covered. The value entered exceeded the maximum limit.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("coverage-error-message");
        }, 10000);
      }
      else if (!this.defaultCommRateCheck()) {
        this._formService.showFormMsg("coverage-error-message", "Invalid value in Default Commission Rate. The value entered exceeded the maximum limit.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("coverage-error-message");
        }, 10000);
      }
      else if (!this.defaultRateCheck()) {
        this._formService.showFormMsg("coverage-error-message", "Invalid value in Default Premium Rate. The value entered exceeded the maximum limit.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("coverage-error-message");
        }, 10000);
      }
      else if (!this.multiplierRateCheck()) {
        this._formService.showFormMsg("coverage-error-message", "Invalid value in Multiplier Rate. The value entered exceeded the maximum limit.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("coverage-error-message");
        }, 10000);
      }
      else if (this.forms.coverageForm.valid) {
        this.forms.coverageForm.patchValue({
          lineCd: this.forms.lineLovForm.get('lineCd')?.value,
          perilTypeDisp: this.selections.perilTypes.filter((d: any) => d.cd == this.forms.coverageForm.get('perilType')?.value)[0].name
        });
        let rowToBeAdded: { [key: string]: any } = this.coverageFormManipulate.formToDb(this.forms.coverageForm.value, 'I');
        rowToBeAdded.temp = true;
        this.tempData.push(rowToBeAdded);
        this.coverageTblConfig.tblData = [rowToBeAdded, ...this.coverageTblConfig.tblData];
        this.forms.resetCoverageForm();
        this.btns.coverage.saveDisabled = false;
      }
      else {
        this.showErrorValidator.coverage();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public onDelete() {
    try {
      let clickData = this.rowData;
      this.coverageTblConfig.tblData = this.coverageTblConfig.tblData.filter((d: any) => {
        return d !== this.rowData;
      });
      this.tempData = this.tempData.filter(
        function(e: any) {
          return e.PERIL_SNAME != clickData.PERIL_SNAME;
        }
      );
      if (this.tempData==null || this.tempData=='') {
        this.btns.coverage.saveDisabled = true;
      }
      this.forms.resetCoverageForm();
    }
    catch (e) {
      console.error(e);
    }
  }

  public onUpdate() {
    try {
      this._formService.hideFormMsg("coverage-error-message");
      if (!this.defaultTSICheck()) {
        this._formService.showFormMsg("coverage-error-message", "Invalid value in Default Amount Covered. The value entered exceeded the maximum limit.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("coverage-error-message");
        }, 10000);
      }
      else if (!this.defaultCommRateCheck()) {
        this._formService.showFormMsg("coverage-error-message", "Invalid value in Default Commission Rate. The value entered exceeded the maximum limit.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("coverage-error-message");
        }, 10000);
      }
      else if (!this.defaultRateCheck()) {
        this._formService.showFormMsg("coverage-error-message", "Invalid value in Default Premium Rate. The value entered exceeded the maximum limit.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("coverage-error-message");
        }, 10000);
      }
      else if (!this.multiplierRateCheck()) {
        this._formService.showFormMsg("coverage-error-message", "Invalid value in Multiplier Rate. The value entered exceeded the maximum limit.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("coverage-error-message");
        }, 10000);
      }
      else if (this.forms.coverageForm.valid) {
        this.forms.coverageForm.get('fmvSw')?.enable();
        this.forms.coverageForm.get('ratingTblSw')?.enable();
        let clickData = this.rowData;
        let toBeUpdatedIndex = this.coverageTblConfig.tblData.indexOf(this.rowData);
        let coverageCdRowValue = this.rowData.PERIL_CD;
        let rowToBeUpdated: { [key: string]: any } = this.coverageFormManipulate.formToDb(this.forms.coverageForm.value, 'U');
        let tempInsertChecker = this.tempData.find(
          (p: { PERIL_SNAME: any; }) => p.PERIL_SNAME == clickData.PERIL_SNAME && clickData.MODE == 'I');
        let tempUpdateChecker = this.tempData.find(
          (p: { PERIL_SNAME: any; }) => p.PERIL_SNAME == clickData.PERIL_SNAME && clickData.MODE == 'U');
        if (this.rowData.temp) {
          rowToBeUpdated.temp = true;
        }
        else {
          rowToBeUpdated.onDbButUpdatedTemp = true;
          rowToBeUpdated.PERIL_CD = coverageCdRowValue;
        }

        if (tempInsertChecker!=null && tempInsertChecker!='') {
          //tempInsertChecker = rowToBeUpdated;
          tempInsertChecker.PERIL_CD = rowToBeUpdated.PERIL_CD;
          tempInsertChecker.ACTIVE_TAG = rowToBeUpdated.ACTIVE_TAG;
          tempInsertChecker.PERIL_SNAME = rowToBeUpdated.PERIL_SNAME;
          tempInsertChecker.PERIL_LNAME = rowToBeUpdated.PERIL_LNAME;
          tempInsertChecker.PERIL_TYPE = rowToBeUpdated.PERIL_TYPE;
          tempInsertChecker.FIXED_SI_TAG = rowToBeUpdated.FIXED_SI_TAG;
          tempInsertChecker.FIXED_PREM_TAG = rowToBeUpdated.FIXED_PREM_TAG;
          tempInsertChecker.DEFAULT_COMM_RATE = rowToBeUpdated.DEFAULT_COMM_RATE;
          tempInsertChecker.DEFAULT_RATE = rowToBeUpdated.DEFAULT_RATE;
          tempInsertChecker.DEFAULT_TSI = rowToBeUpdated.DEFAULT_TSI;
          tempInsertChecker.MULTIPLIER_RATE = rowToBeUpdated.MULTIPLIER_RATE;
          tempInsertChecker.FMV_SW = rowToBeUpdated.FMV_SW;
          tempInsertChecker.RATING_TBL_SW = rowToBeUpdated.RATING_TBL_SW;
          tempInsertChecker.BASIC_PERIL_CD = rowToBeUpdated.BASIC_PERIL_CD;
          tempInsertChecker.BASIC_PERIL_CD_DISP = rowToBeUpdated.BASIC_PERIL_CD_DISP;
          tempInsertChecker.BASIC_PERIL_NAME = rowToBeUpdated.BASIC_PERIL_NAME;
          tempInsertChecker.SEQ_NO = rowToBeUpdated.SEQ_NO;
          tempInsertChecker.GROUP_CD = rowToBeUpdated.GROUP_CD;
          tempInsertChecker.PREM_CLASS_CD = rowToBeUpdated.PREM_CLASS_CD;
          tempInsertChecker.BM_CODE = rowToBeUpdated.BM_CODE;
          tempInsertChecker.BM_SHORT_DESC = rowToBeUpdated.BM_SHORT_DESC;
          tempInsertChecker.REMARKS = rowToBeUpdated.REMARKS;
          tempInsertChecker.USER_ID = rowToBeUpdated.USER_ID;
          tempInsertChecker.MODE = 'I';
          rowToBeUpdated.MODE = 'I';
        }
        else if (tempUpdateChecker!=null && tempUpdateChecker!='') {
          tempUpdateChecker.PERIL_CD = rowToBeUpdated.PERIL_CD;
          tempUpdateChecker.ACTIVE_TAG = rowToBeUpdated.ACTIVE_TAG;
          tempUpdateChecker.PERIL_SNAME = rowToBeUpdated.PERIL_SNAME;
          tempUpdateChecker.PERIL_LNAME = rowToBeUpdated.PERIL_LNAME;
          tempUpdateChecker.PERIL_TYPE = rowToBeUpdated.PERIL_TYPE;
          tempUpdateChecker.FIXED_SI_TAG = rowToBeUpdated.FIXED_SI_TAG;
          tempUpdateChecker.FIXED_PREM_TAG = rowToBeUpdated.FIXED_PREM_TAG;
          tempUpdateChecker.DEFAULT_COMM_RATE = rowToBeUpdated.DEFAULT_COMM_RATE;
          tempUpdateChecker.DEFAULT_RATE = rowToBeUpdated.DEFAULT_RATE;
          tempUpdateChecker.DEFAULT_TSI = rowToBeUpdated.DEFAULT_TSI;
          tempUpdateChecker.MULTIPLIER_RATE = rowToBeUpdated.MULTIPLIER_RATE;
          tempUpdateChecker.FMV_SW = rowToBeUpdated.FMV_SW;
          tempUpdateChecker.RATING_TBL_SW = rowToBeUpdated.RATING_TBL_SW;
          tempUpdateChecker.BASIC_PERIL_CD = rowToBeUpdated.BASIC_PERIL_CD;
          tempUpdateChecker.BASIC_PERIL_CD_DISP = rowToBeUpdated.BASIC_PERIL_CD_DISP;
          tempUpdateChecker.BASIC_PERIL_NAME = rowToBeUpdated.BASIC_PERIL_NAME;
          tempUpdateChecker.SEQ_NO = rowToBeUpdated.SEQ_NO;
          tempUpdateChecker.GROUP_CD = rowToBeUpdated.GROUP_CD;
          tempUpdateChecker.PREM_CLASS_CD = rowToBeUpdated.PREM_CLASS_CD;
          tempUpdateChecker.BM_CODE = rowToBeUpdated.BM_CODE;
          tempUpdateChecker.BM_SHORT_DESC = rowToBeUpdated.BM_SHORT_DESC;
          tempUpdateChecker.REMARKS = rowToBeUpdated.REMARKS;
          tempUpdateChecker.USER_ID = rowToBeUpdated.USER_ID;
          tempUpdateChecker.MODE = 'U';
        }
        else { 
          this.tempData.push(rowToBeUpdated);
        }
        this.forms.resetCoverageForm();
        this.coverageTblConfig.tblData[toBeUpdatedIndex] = rowToBeUpdated;
        this.btns.coverage.saveDisabled = false;
        this.coverageTblConfig.tblData = [... this.coverageTblConfig.tblData];
      }
      else {
        this.showErrorValidator.coverage();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public onSave() {
    try {
      this.btns.coverage.saveDisabled = true;
      this.showForm = false;
      this._formService.showFormLoader(null, "coverage-maintenance-loading", "Saving.<br>Please wait ...", null, null);
      if (this.tempData.length > 0) {
        this._api.savePeril(this.tempData).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                if (data.status === 'SUCCESS') {
                  this.onComplete.coverage();
                }
                else {
                  this.onComplete.coverageError();
                }
              });
            });
          },
          error: (data: any) => {
            this.onComplete.coverageError();
          },
        });
      }
      else
        this.onComplete.coverage();
    }
    catch (e) {
      console.error(e);
    }
  }

  private onComplete = {
    coverage: () => {
      this.tempData = [];
      this.getData.coverage();
      this.forms.resetCoverageForm();
      this.btns.coverage.saveDisabled = true;
      this.showForm = true;
      this._formService.hideFormLoader("coverage-maintenance-loading");
      this.openDialog('SUCCESS', 'Coverage details successfully saved');
    },
    coverageError: () => {
      this.showForm = true;
      this.btns.coverage.saveDisabled = false;
      this.openDialog('FAILED', 'Coverage details saving failed');
      this._formService.hideFormLoader("coverage-maintenance-loading");
    },
  }

  openDialog(title: any, message: any) {
    try {
      this.showForm = true;
      this._formService.hideFormLoader("coverage-maintenance-loading");
      this._matDialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        } 
      });
    } catch(e) {
      console.error(e);
    }
  }

  /*
  basicPerilCheck() {
    if ((this.forms.coverageForm.get('perilType')?.value === 'B')
    || (this.forms.coverageForm.get('perilType')?.value === 'A' && this.forms.coverageForm.get('basicPerilCd')?.value)) {
      return true;
    }
    else {
      return false;
    }
  }

  checkDuplicatePerilSName(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.trim()?.toUpperCase();
      if (!value) return null;
      let hasDuplicate: boolean = this.coverageTblConfig.tblData.filter((d: any) => {
        if (!this.rowData)
          return d.PERIL_SNAME.toUpperCase() === value;
        else
          return this.rowData.PERIL_SNAME.trim().toUpperCase() !== value && d.PERIL_SNAME.trim().toUpperCase() === value;
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupeSName: true } : null;
    }
  }
  */

  defaultTSICheck() {
    let resultCheck: boolean = true;
    if (parseFloat(this._formService.setToNumberWithDecimal(this.forms.coverageForm.get('defaultTSI')?.value)) > 99999999999999.99) {
      resultCheck = false;
    }
    else {
      resultCheck = true;
    }
    return resultCheck;
  }

  defaultCommRateCheck() {
    let resultCheck: boolean = true;
    if (parseFloat(this._formService.setToNumberWithDecimal(this.forms.coverageForm.get('defaultCommRate')?.value)) > 999.999999999) {
      resultCheck = false;
    }
    else {
      resultCheck = true;
    }
    return resultCheck;
  }

  defaultRateCheck() {
    let resultCheck: boolean = true;
    if (parseFloat(this._formService.setToNumberWithDecimal(this.forms.coverageForm.get('defaultRate')?.value)) > 999.999999999) {
      resultCheck = false;
    }
    else {
      resultCheck = true;
    }
    return resultCheck;
  }

  multiplierRateCheck() {
    let resultCheck: boolean = true;
    if (parseFloat(this._formService.setToNumberWithDecimal(this.forms.coverageForm.get('multiplierRate')?.value)) > 999.999999999) {
      resultCheck = false;
    }
    else {
      resultCheck = true;
    }
    return resultCheck;
  }

  noWhiteSpaceCheck(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: true };
    }
  }

  public showErrorValidator = {
    coverage: () => {
      try {
        Object.keys(this.forms.coverageForm.controls).forEach(key => {
          const controlErrors = this.forms.coverageForm.get(key)?.errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              if (keyError == 'whitespace' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
              else if (keyError == 'hasDupeSName' && controlErrors[keyError] === true)
                this.errorMessage = "Peril Short Name already exists. Please add a unique Peril Short Name.";
              else if (keyError == 'hasDupeLName' && controlErrors[keyError] === true)
                this.errorMessage = "Peril Name already exists. Please add a unique Peril Name.";
              else if (keyError == 'required' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
            });
          }
        });
        this._formService.showFormMsg("coverage-error-message", this.errorMessage, "E");
        //this.openDialog('ERROR', this.errorMessage);
        setTimeout(()=>{                           
          this._formService.hideFormMsg("coverage-error-message");
        }, 10000);
      }
      catch (e) {
        console.error(e);
      }
    },
  }

  onPerilTypeChange(ev: any) {
    this.checkBasicPerilDisable(ev.target.value);
  }

  checkBasicPerilDisable(perilType: any) {
    if (perilType === 'A') {
      this.btns.coverage.basicPerilDisabled = false;
    }
    else {
      this.forms.coverageForm.patchValue({
        basicPerilCd: null,
        basicPerilName: null,
        basicPerilCdDisp: null
      });
      this.btns.coverage.basicPerilDisabled = true;
    }
  }

  checkMotorFieldDisable(lineCd: any) {
    if (lineCd === 'MC') {
      this.forms.coverageForm.get('fmvSw')?.enable();
      this.forms.coverageForm.get('ratingTblSw')?.enable();
      this.forms.coverageForm.patchValue({
        fmvSw: null,
        ratingTblSw: null
      });
    }
    else {
      this.forms.coverageForm.get('fmvSw')?.enable();
      this.forms.coverageForm.get('ratingTblSw')?.enable();
      this.forms.coverageForm.patchValue({
        fmvSw: 'Y',
        ratingTblSw: 'N'
      });
      this.forms.coverageForm.get('fmvSw')?.disable();
      this.forms.coverageForm.get('ratingTblSw')?.disable();
    }
  }

  autoUppercase(ev: any) {
    try{
      ev.target.value = ev.target.value.toUpperCase();
    }
    catch(e) {
      console.error(e);
    }
  }

  rateFormat(fieldName:string){
    try {
      if(this.forms.coverageForm.get(fieldName)?.value!==''&&this.forms.coverageForm.get(fieldName)?.value!==null){
        let fieldFloat = parseFloat(this.forms.coverageForm.get(fieldName)?.value.replace(/,/g, ''))
        let fieldRate = fieldFloat.toFixed(9);
        if(isNaN(fieldFloat)||fieldRate=='NaN'){
          this.forms.coverageForm.get(fieldName)?.setValue('');
        }
        else{
          this.forms.coverageForm.get(fieldName)?.setValue(fieldRate);
        }
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  integerFormat(fieldName:string){
    try {
      if(this.forms.coverageForm.get(fieldName)?.value!==''&&this.forms.coverageForm.get(fieldName)?.value!==null){
        let fieldFloat = parseFloat(this.forms.coverageForm.get(fieldName)?.value)
        if(isNaN(fieldFloat)){
          this.forms.coverageForm.get(fieldName)?.setValue('');
        }
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  allowNumericDigitsOnly(event: any) {
    try {
      if (/[0-9.]/.test(String.fromCharCode(event.keyCode))) {
        return true;
      }
      else {
        event.preventDefault();
        return false;
      }
    }
    catch (e) {
      console.error(e);
      return undefined;
    }
  }

  allowNumericDigitsOnlyWODecimal(event: any) {
    try {
      if (/[0-9]/.test(String.fromCharCode(event.keyCode))) {
        return true;
      }
      else {
        event.preventDefault();
        return false;
      }
    }
    catch (e) {
      console.error(e);
      return undefined; 
    }
  }

}
