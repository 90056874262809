<section id="client-group-per-user-type">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>

        <div class="form-content" [hidden]="formHider">

            <form [formGroup]="clientGroupPerUserTypeForm" class="form">
                <div class="form-container-grid">
                    
                    <div class="field required">
                        <label>User Type</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="USER_TYPE" readonly>
                        <button class="btn-search btn" (click)="openUserTypeDialog()">
                            <mat-icon>
                                search
                            </mat-icon>
                        </button>
                    </div>
    
                    <div class="field required">
                        <label>Item Level 1</label>
                    </div>
                    <div class="">
                        <select formControlName="ITEM_LEVEL1">
                            <option value=""></option>
                            <option *ngFor="let itemLvl1 of itemLevel1DropDown" [value]="itemLvl1.rvLowValue">{{itemLvl1.rvLowValue + ' - ' + itemLvl1.rvMeaning}}</option>
                        </select>
                    </div>
                    
                    <div class="field required">
                        <label>Client Group</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="CLIENT_CD" readonly>
                        <button class="btn-search btn" (click)="openClientGroupDialog()">
                            <mat-icon>
                                search
                            </mat-icon>
                        </button>
                    </div>
    
                    <div class="field required">
                        <label>Item Level 2</label>
                    </div>
                    <div class="">
                        <select formControlName="ITEM_LEVEL2">
                            <option value=""></option>
                            <option *ngFor="let itemLvl2 of itemLevel2DropDown" [value]="itemLvl2.rvLowValue">{{itemLvl2.rvLowValue + ' - ' + itemLvl2.rvMeaning}}</option>
                        </select>
                    </div>

                </div>
            </form>
        </div>

        <osp-table [tblConfig]="clientGroupPerUserTypeTable" (onRowClick)="onRowClick($event)" [selectedRow]="selectedRow"></osp-table>

        <!-- <div id="client-group-per-user-type-form"><div class="form-loader"></div></div> -->

        <div class="form-content" [hidden]="formHider">

            <form [formGroup]="clientGroupPerUserTypeForm" class="form">
                <div id="client-group-per-user-type-error-message"></div>
                <div class="form-container-grid">
                    
                    <div class="field">
                        <label></label>
                    </div>
                    <div class="">
                        
                    </div>

                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="ACTIVE_TAG">
                            <option value="A">Yes</option>
                            <option value="I">No</option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>Effective Date</label>
                    </div>
                    <div class="">
                        <input formControlName="EFF_DATE_FROM" type="text" placeholder="MM/DD/YYYY" [matDatepicker]="effDatePicker" date-input>
                        <mat-datepicker-toggle matSuffix [for]="effDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #effDatePicker></mat-datepicker>
                    </div>

                    <div class="field">
                        <label>Expiry Date</label>
                    </div>
                    <div class="">
                        <input formControlName="EFF_DATE_TO" type="text" placeholder="MM/DD/YYYY" [matDatepicker]="expDatePicker" date-input>
                        <mat-datepicker-toggle matSuffix [for]="expDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #expDatePicker></mat-datepicker>
                    </div>

                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="REMARKS" maxlength="4000">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input formControlName="LAST_USER">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="LAST_UPDATE">
                    </div>
                </div>
            </form>

            <div class="btn-grp">
                <button type="button" class="btn btn2" [hidden]="!addUpdateFlag" [disabled]="addDisabled" (click)="addRow()">ADD</button>
                <button type="button" class="btn btn2" [hidden]="addUpdateFlag" (click)="onUpdate()">UPDATE</button>
                <button type="button" class="btn btn2" [disabled]="deleteDisabler" (click)="deleteRow()">DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-grp">
                <button type="button" class="btn btn2" [disabled]="saveDisabler" (click)="onSave()">SAVE</button>
            </div>
        </div>
    </div>
</section>