<section id='generate-rpa'>
    <div class="mtn-title">
        <p>GENERATE FILE FOR RPA</p>
    </div>
    <form [formGroup]="extractParams">
        <div id="generate-rpa-form">
            <div class="form-loader"></div>
            <div class="form-fields">
                <mat-radio-button class="cstm-radio-pos-1" (click)="selectAsOfOption()" [checked]='true'></mat-radio-button>
                <mat-radio-button class="cstm-radio-pos-2" (click)="selectFromToOption()"></mat-radio-button>
                <div class="form">
                    <div class="col-case">
                        <div class="field required">
                            <label>Business Type</label>
                            <mat-form-field appearance="fill">
                                <mat-select placeholder="Business Type" formControlName="businessType" (selectionChange)="evaluateFields()">
                                    <mat-option *ngFor="let option of businessTypes" [value]="option.rvLowValue">
                                        {{option.rvMeaning}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="field required">
                            <label>Product</label>
                            <mat-form-field appearance="fill">
                                <mat-select placeholder="Product Name" formControlName="lineCd" (selectionChange)="getSublines()">
                                    <mat-option *ngFor="let option of filteredLineOptions" [value]="option.lineCd">
                                        {{option.lineName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="field required">
                            <label>Product Type</label>
                            <mat-form-field appearance="fill" >
                                <mat-select placeholder="Type Name" formControlName="sublineCd">
                                    <mat-option *ngFor="let option of filteredSublineOptions" [value]="option.sublineCd">
                                        {{option.sublineName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="field required">
                            <label>Extract By</label>
                            <mat-form-field appearance="fill">
                                <mat-select placeholder="Extraction Method" formControlName="extractBy">
                                    <mat-option *ngFor="let option of extractMethod" [value]="option.methodId">
                                        {{option.methodName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="field">
                            <label>As Of</label>
                            <mat-form-field appearance="fill">
                                <input matInput [matDatepicker]="picker" formControlName="dateAsOf" placeholder="MM/DD/YYYY">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="field">
                            <label>From</label>
                            <mat-form-field appearance="fill">
                                <input matInput [matDatepicker]="picker2" formControlName="dateFrom" placeholder="MM/DD/YYYY">
                                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="field">
                            <label>To</label>
                            <mat-form-field appearance="fill">
                                <input matInput [matDatepicker]="picker3" formControlName="dateTo" placeholder="MM/DD/YYYY">
                                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                <mat-datepicker #picker3></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div id="generate-rpa-form-msg"></div>
        </div>  
        <div class="form-btns">
            <button class="btn btn2" (click)="extractRPA()">Extract</button>
        </div>
    </form>
</section>