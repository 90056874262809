import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { BmaLovComponent } from '../bma-lov/bma-lov.component';

@Component({
  selector: 'category-lov',
  templateUrl: './category-lov.component.html',
  styleUrls: ['./category-lov.component.css']
})
export class CategoryLovComponent implements OnInit {

  categoryModuleId: string = 'BMM020';

  constructor(private apiCallService: APICallService,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<BmaLovComponent>) { 
      this.getCategory();
    }

  ngOnInit(): void {
  }

  // Variable Initializer
  categoryData: any = [];

  // Occupation Class Initializer
  categoryTableConfig: any = {
    cols: [
      {
        key: "CATEGORY_CD_DISPLAY",
        header: "Code",
        dataType: "string"
      },
      {
        key: "CATEGORY_DESC",
        header: "Description",
        dataType: "string"
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15
  }

  // Gets data for Occupation Class Table
  async getCategory() {
    try {
      this.categoryTableConfig.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.apiCallService.getCategory({
        moduleId: this.data.moduleId,
        userId: this.userDetailService.userId,
        type: "LOV"
      }).subscribe((data: any) => {
            this.securityService.checkRequestKeyResponse(data, () => {
              this.securityService.hasValidCsrfToken(data, () => {
                if (data.status === 'SUCCESS') {
                  data = JSON.parse(this.jsonDataService.decrypt(data.response));
                  if (data.moduleId === this.categoryModuleId) {
                      this.jsonDataService.contorlLoading(false);
                      this.categoryTableConfig.loading = false;
                      data.data = data.data.map((e: any) => {
                        return { ...e, CATEGORY_CD_DISPLAY: e.CATEGORY_CD.toString().padStart(2, '0') };
                      });
                      this.categoryTableConfig.tblData = data.data.filter((a: any) => a.ACTIVE_TAG === 'A').sort((a: any, b:any) => (a.CATEGORY_CD > b.CATEGORY_CD) ? 1 : -1);
                      this.data = "";
                  } else {
                      this.categoryTableConfig.loading = false;
                      this.jsonDataService.contorlLoading(false);
                      this.appMessageService.showAppMessage("You do not have the correct privilege to access the data. Kindly contact your administrator for assistance.", "error");
                  }
                } else {
                    this.categoryTableConfig.loading = false;
                    this.jsonDataService.contorlLoading(false);
                    this.appMessageService.showAppMessage(data.message, "error");
                }
              });
            });
      });
    } 
    catch(e) {
      console.error(e);
    }
  }

  onRowClick(ev: any) {
    try {
      this.data = ev;
    }
    catch(e) {
      console.error(e);
    }
  }

  onClose() {
    try {
      if(this.data=='' || this.data==null){
        this._dialogRef.close({content: ''}); 
      } else {
        this._dialogRef.close({content: this.data});
      }
    }
    catch(e) {
      console.error(e);
    }
  }

}
