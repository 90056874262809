import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClientGroupLovDialog } from 'src/app/components/common/client-group-lov-dialog/client-group-lov-dialog.component';
import { APICallService } from 'src/app/services/api-call.service';
import { DialogBoxService } from 'src/app/services/dialog-box.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';

@Component({
    selector: 'download-voucher-dialog',
    templateUrl: './download-voucher-dialog.component.html',
    styleUrls: ['./download-voucher-dialog.component.css']
})
export class DownloadVoucherDialogComponent implements OnInit {

    private moduleId: string = 'BMM158';
    public dialogTitle: string = 'DOWNLOAD LIST FOR UPDATE';
    private datePipe = new DatePipe('en-us');

    private dateParameters: any[] = [];
    public datesRequired: boolean = false;

    public downloadVoucherCodeForm = this.formBuilder.group({
        lineCd: ["", [Validators.required]],
        lineDisplay: [""],
        sublineCd: ["", [Validators.required]],
        sublineDisplay: [""],
        planCd: ["", [Validators.required]],
        planDisplay: [""],
        clientTypeGroup: [""],
        clientTypeGroupDisplay: [""],
        batchNo: [""],
        voucherCode: [""],
        fromDate: [""],
        toDate: [""],
        dateParameter: [""],
    });

    constructor(
        // PUBLIC
        @Inject(MAT_DIALOG_DATA) public dataIn: any,

        // PRIVATE
        private formBuilder: FormBuilder,
        private matDialogRef: MatDialogRef<DownloadVoucherDialogComponent>,
        private _matDialog: MatDialog,
        private apiCallService: APICallService,
        private jsonDataService: JsonDataService,
        private securityService: SecurityService,
        private dialogBoxService: DialogBoxService,
        private formService: FormService,
    ) {
        this.downloadVoucherCodeForm.patchValue({
            ...this.dataIn.voucherCodeFilterForm
        });
        this.dateParameters = this.jsonDataService.data.refCds.filter((data: any) => data.rvDomain === 'VOUCHER_DATE_PARAM').map((data: any) => { return { cd: data.rvLowValue, name: data.rvMeaning } });
        if (this.dateParameters.length > 0) {
            this.selections.dateParameters = [
                { cd: '', name: '' },
                ...this.dateParameters
            ];
        }
        this.dateParameterChanges();
    }

    private dateParameterChanges(): void {
        this.downloadVoucherCodeForm.get("dateParameter")?.valueChanges.subscribe((control: any) => {
            if (control) {
                this.downloadVoucherCodeForm.get('fromDate')?.setValidators(Validators.required);
                this.downloadVoucherCodeForm.get('toDate')?.setValidators(Validators.required);
                this.datesRequired = true;
            }
            else {
                this.downloadVoucherCodeForm.get('fromDate')?.clearValidators();
                this.downloadVoucherCodeForm.get('toDate')?.clearValidators();
                this.datesRequired = false;
            }
            this.downloadVoucherCodeForm.get('fromDate')?.updateValueAndValidity({ onlySelf: true, emitEvent: false });
            this.downloadVoucherCodeForm.get('toDate')?.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        });
    }

    public selections = {
        dateParameters: [
            { cd: '', name: '' },
            { cd: 'eff_date_from', name: 'Effective Date' },
            { cd: 'eff_date_to', name: 'Expiry Date' },
        ]
    };

    ngOnInit(): void {
    }

    public openLineLOV(option: string): void {
        if (option === 'clientTypeGroup') {
            const LOVOPTIONS: { [key: string]: any } = {
                disableClose: true,
                width: '512px',
                data: {
                    table: 'CLIENT_GROUP',
                    moduleId: this.moduleId,
                },
            };
            this._matDialog.open(ClientGroupLovDialog, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
                let output = dataOutput.content;
                if (output.CLIENT_CD) {
                    this.downloadVoucherCodeForm.patchValue({
                        clientTypeGroup: output.CLIENT_CD,
                        clientTypeGroupDisplay: `${output.CLIENT_CD} - ${output.CLIENT_DESC}`,
                    });
                }
                else {
                    this.downloadVoucherCodeForm.patchValue({
                        clientTypeGroup: null,
                        clientTypeGroupDisplay: null,
                    });
                }
            });
        }
    }

    public onCancel(): void {
        this.matDialogRef.close();
    }

    public onProceed(): void {
        this.formService.hideFormMsg("download-voucher-code-error-message");
        if (this.downloadVoucherCodeForm.valid) {
            let values = this.prepareData({ ...this.downloadVoucherCodeForm.getRawValue() });
            this.jsonDataService.contorlLoading(true);
            this.apiCallService.downloadVouchers(values).subscribe({
                next: (response: any) => {
                    this.securityService.checkRequestKeyResponse(response, () => {
                        this.securityService.hasValidCsrfToken(response, () => {
                            if (response.status === "SUCCESS") {
                                let content = JSON.parse(this.jsonDataService.decrypt(response.response)).result;
                                if (content.count) {
                                    this.dialogBoxService.open({
                                        messageType: "S",
                                        content: `Click OK to download file.`,
                                        buttons: [{
                                            text: "OK",
                                            action: () => {
                                                let dateNow = this.datePipe.transform(new Date(), 'yyyyMMdd');
                                                let planName = values.planDisplay?.split("-")[1].trim();
                                                let fileName = `${values.lineCd}-${values.sublineCd}-${values.planCd}-${planName}_${dateNow}`;
                                                this.downloadFile(content.rows, fileName);
                                            }
                                        }]
                                    }, true);
                                }
                                else {
                                    this.dialogBoxService.open({
                                        messageType: "I",
                                        content: `No record found for the given parameter.`,
                                        buttons: [{
                                            text: "OK",
                                            action: () => { }
                                        }]
                                    }, true);
                                }
                                this.jsonDataService.contorlLoading(false);
                            }
                            else {
                                this.jsonDataService.contorlLoading(false);
                            }
                        });
                    });
                },
                error: (data: any) => {
                    this.securityService.checkRequestKeyResponse(data, () => {
                        this.jsonDataService.contorlLoading(false);
                    });
                }
            });
        }
        else {
            this.formService.showFormMsg("download-voucher-code-error-message", "There are missing information. Please provide necessary information needed.", "E");
            setTimeout(() => {
                this.formService.hideFormMsg("download-voucher-code-error-message");
            }, 5000);
        }
    }

    private prepareData(data: any): any {
        return {
            lineCd: data.lineCd,
            sublineCd: data.sublineCd,
            planCd: data.planCd,
            planDisplay: data.planDisplay,
            clientTypeGroup: data.clientTypeGroup,
            batchNo: data.batchNo === '' ? null : data.batchNo,
            voucherCode: data.voucherCode,
            dateParam: data.dateParameter,
            fromDate: data.fromDate ? this.datePipe.transform(new Date(data.fromDate), 'yyyy-MM-dd') : "",
            toDate: data.toDate ? this.datePipe.transform(new Date(data.toDate), 'yyyy-MM-dd') : "",
        };
    };

    private downloadFile(data: any, filename = 'data') {
        let cols = ['CLIENT_TYPE_GROUP', 'CLIENT_TYPE_GROUP_DESC', 'BATCH_NO', 'VOUCHER_CODE', 'EMAIL_ADDRESS', 'EFFECTIVE_DATE', 'EXPIRY_DATE'];
        let csvData = this.convertToCSV(data, cols);
        let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
        let donwloadLink = document.createElement("a");
        let url = URL.createObjectURL(blob);
        let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
        if (isSafariBrowser) {  // ? if Safari open in new window to save file with random filename.
            donwloadLink.setAttribute("target", "_blank");
        }
        donwloadLink.setAttribute("href", url);
        donwloadLink.setAttribute("download", filename + ".csv");
        donwloadLink.style.visibility = "hidden";
        document.body.appendChild(donwloadLink);
        donwloadLink.click();
        document.body.removeChild(donwloadLink);
    }

    private convertToCSV(objArray: any, headerList: any) {
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        let row = '';

        for (let index in headerList) {
            row += headerList[index] + ',';
        }
        row = row.slice(0, -1);
        str += row + '\r\n';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in headerList) {
                let head = headerList[index];
                let cell = array[i][head];
                if ((cell + '').includes(',')) {
                    cell = '\"' + cell + '\"';
                }
                line += (cell ?? '') + ',';
            }
            str += line + '\r\n';
        }
        return str;
    }

}
