<div class="mtn-container">
    <div class="mtn-title">
        <p>{{ moduleName.toUpperCase() }}</p>
    </div>
    <osp-table [tblConfig]="occupancyTypeTblConfig" (onRowClick)="onRowClick($event)" [selectedRow]="selectedRow"></osp-table>
    <div id="occupancy-type-maintenance-loading">
        <div class="form-loader"></div>
    </div>
    <div *ngIf="showForm" class="form-content">
        <form [formGroup]="forms.occupancyTypeForm" class="form">
            <div id="occupancy-type-error-message"></div>
            <div class="form-container-grid">
                <div class="field">
                    <label>Occupancy Code</label>
                </div>
                <div class="">
                    <input formControlName="occupancyCd" >
                </div>
                <div class="field required">
                    <label>Active</label>
                </div>
                <div class="">
                    <select formControlName="active">
                        <option *ngFor="let activeType of selections.activeTypes" [value]="activeType.cd">
                            {{activeType.name}}
                        </option>
                    </select>
                </div>
                <div class="field required">
                    <label>Occupancy Description</label>
                </div>
                <div class="span">
                    <input formControlName="occupancyDesc" maxlength="2000" >
                </div>
                <div class="field required">
                    <label>Tariff Code</label>
                </div>
                <div class="">
                    <input formControlName="bmCode" maxlength="8" alphanumeric >
                </div>
                <div class="field">
                    <label>Tariff Short Description</label>
                </div>
                <div class="">
                    <input formControlName="tariffDisp">
                    <button matSuffix class="btn btn-search" type="button" (click)="openLov['tariff']()" >
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
                <div class="field">
                    <label>Hazard Grade Code</label>
                </div>
                <div class="">
                    <!-- <input formControlName="hazardGrdCd" maxlength="8" alphanumeric > -->
                    <input formControlName="hazardGrdDisp">
                    <button matSuffix class="btn btn-search" type="button" (click)="openLov['hazardGrd']()" >
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
                <div class=""></div>
                <div class=""></div>
                <div class="field">
                    <label>Remarks</label>
                </div>
                <div class="span">
                    <input formControlName="remarks" maxlength="1000" >
                </div>
                <div class="field">
                    <label>Last Update User</label>
                </div>
                <div class="">
                    <input formControlName="lastUpdateUser" >
                </div>
                <div class="field">
                    <label>Last Update</label>
                </div>
                <div class="">
                    <input formControlName="lastUpdate" >
                </div>
            </div>
        </form>
        <div class="btn-grp">
            <button *ngIf="btns.occupancyType.updateDisabled" class="btn btn2" type="button" (click)="onAdd()" [disabled]="btns.occupancyType.addDisabled" >ADD</button>
            <button *ngIf="!btns.occupancyType.updateDisabled" class="btn btn2" type="button" (click)="onUpdate()" >UPDATE</button>
            <button class="btn btn2" type="button" [disabled]='btns.occupancyType.deleteDisabled' (click)="onDelete()" >DELETE</button>
        </div>
        <div class="mtn-title"></div>
        <div class="btn-grp">
            <button class="btn btn2" type="button" [disabled]='btns.occupancyType.saveDisabled' (click)="onSave()" >SAVE</button>
        </div>
    </div>
</div>
