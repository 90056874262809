<section id="system-parameter">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <osp-table [tblConfig]="systemParameterTable" (onRowClick)="onRowClick($event)"></osp-table>
        <!-- <div id="system-parameter-form"><div class="form-loader"></div></div> -->
        <div class="form-content" [hidden]="formHider">
            <form [formGroup]="systemParameterForm" class="form">
                <div id="system-parameter-error-message"></div>
                <div class="form-container-grid">

                    <div class="field required">
                        <label>Parameter Type</label>
                    </div>
                    <div class="">
                        <select formControlName="PARAM_TYPE" (change)="parameterTypeDisabler()">
                            <option *ngFor="let type of parameterType" [value]="type.rvLowValue">{{ type.rvMeaning }}</option>
                        </select>
                    </div>

                    <div class="field required">
                    </div>
                    <div class="">
                    </div>

                    <div class="field required">
                        <label>Parameter Name</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="PARAM_NAME" maxlength="50" oninput="this.value = this.value.toUpperCase()">
                    </div>

                    <div class="field">
                        <label>Parameter (Number)</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="PARAM_VALUE_N" amount>
                    </div>

                    <div class="field">
                        <label>Parameter (Character)</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="PARAM_VALUE_V">
                    </div>

                    <div class="field">
                        <label>Parameter (Date)</label>
                    </div>
                    <div class="">
                        <input formControlName="PARAM_VALUE_D" type="text" placeholder="MM/DD/YYYY" [matDatepicker]="paramDate" date-input>
                        <mat-datepicker-toggle matSuffix [for]="paramDate"></mat-datepicker-toggle>
                        <mat-datepicker #paramDate></mat-datepicker>
                    </div>

                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input type="text" formControlName="REMARKS" maxlength="4000">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input formControlName="USER_ID">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="LAST_UPDATE">
                    </div>

                </div>
            </form>
            <div class="btn-grp">
                <button type="button" class="btn btn2" [hidden]="!addUpdateToggler" (click)="addRow()">ADD</button>
                <button type="button" class="btn btn2" [hidden]="addUpdateToggler" (click)="onUpdate()">UPDATE</button>
                <button type="button" class="btn btn2" [disabled]="deleteDisabler" (click)="deleteRow()">DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-grp">
                <button type="button" class="btn btn2" [disabled]="saveDisabler" (click)="onSave()">SAVE</button>
            </div>
        </div>
    </div>
</section>