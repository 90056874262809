import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { CustomValidators } from 'src/app/utils/custom-validator';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';
import { PlanLovComponent } from '../../common/plan-lov/plan-lov.component';

@Component({
  selector: 'fire-pol-wordings-maintenance',
  templateUrl: './fire-pol-wordings-maintenance.component.html',
  styleUrls: ['./fire-pol-wordings-maintenance.component.css']
})
export class FirePolWordingsMaintenanceComponent implements OnInit, LeaveConfirmation {

  private moduleId: string = 'BMM188';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc ?? "";

  constructor(
    public _formService: FormService,
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService,
    private _appMessageService: AppMessageService,
  ) {
    this.userIdLoggedIn = this._userDetailService.userId ?? "";
  }

  public canDeactivate(): boolean | Observable<boolean> {
    return !(this.firePolWordingsTblConfig.tblData?.filter((row: any) => ['I', 'U'].includes(row.operation)).length > 0);
  }

  public btns = {
    firePolWordings: {
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      addDisabled: true,
      formLovSearchDisabled: true,
    },
    filterLov: {
      lineLovSearchDisabled: false,
      sublineLovSearchDisabled: true,
      planLovSearchDisabled: true,
    }
  };

  public showForm: boolean = true;

  //* Data Stuffs */
  private rowData: any;
  private errorMessage: string = "";
  private userIdLoggedIn: string;
  //? set selectedRow to [] to unselect the table
  public selectedRow!: any[];

  public forms = {
    filterLovForm: this.fb.group({
      lineCd: [null],
      lineName: [null],
      sublineCd: [null],
      sublineName: [null],
      planCd: [null],
      planName: [null],
      lineCdDisp: [{ value: '', disabled: 'true' }],
      sublineCdDisp: [{ value: '', disabled: 'true' }],
      planDisp: [{ value: '', disabled: 'true' }],
    }),
    firePolWordingsForm: this.fb.group({
      lineCd: [null],
      sublineCd: [null],
      planCd: [null],
      wordingCd: [null, [Validators.required, this.checkDuplicateWordingCd(), CustomValidators.requiredTrim]],
      wordings: [null, [Validators.required, CustomValidators.requiredTrim]],
      seqNo: [null, [this.checkDuplicateSeqNo()]],
      active: ["A", [Validators.required]],
      remarks: [null],
      lastUpdateUser: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }],
    }),
    resetFirePolWordingsForm: () => {
      this.forms.firePolWordingsForm.reset();
      this.btns.firePolWordings.updateDisabled = true;
      this.btns.firePolWordings.deleteDisabled = true;

      this.forms.firePolWordingsForm.patchValue({
        active: 'A',
      });

      this._formService.hideFormMsg("fire-pol-wordings-error-message");
      this.rowData = null;
      this.forms.firePolWordingsForm.get('wordingCd')?.enable();
    },
    resetFilterLovForm: () => {
      this.forms.filterLovForm.reset();
    },
    resetAllForms: () => {
      this.forms.resetFilterLovForm();
      this.forms.resetFirePolWordingsForm();
    }
  }

  private firePolWordingsFormManipulate = {
    dbToForm: (data: any) => {
      return {
        lineCd: data.LINE_CD,
        sublineCd: data.SUBLINE_CD,
        planCd: data.PLAN_CD,
        wordingCd: data.WORDING_CD,
        wordings: data.WORDINGS,
        active: data.ACTIVE_TAG,
        seqNo: data.SEQ_NO,
        remarks: data.REMARKS,
        lastUpdateUser: data.USER_ID,
        lastUpdate: data.LAST_UPDATE,
      };
    },
    formToDb: (data: any) => {
      return {
        LINE_CD: data.lineCd,
        SUBLINE_CD: data.sublineCd,
        PLAN_CD: data.planCd,
        WORDING_CD: data.wordingCd,
        WORDINGS: data.wordings,
        ACTIVE_TAG: data.active,
        SEQ_NO: data.seqNo,
        REMARKS: data.remarks,
        USER_ID: null,
        LAST_UPDATE: null,
      };
    },
  }

  public firePolWordingsTblConfig: any = {
    cols: [
      {
        key: "WORDING_CD",
        header: "Code",
        dataType: "string",
        width: "128px"
      },
      {
        key: "WORDINGS",
        header: "Wordings",
        dataType: "string"
      },
      {
        key: "SEQ_NO",
        header: "Sequence No.",
        dataType: "string",
        width: "128px"
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        width: '48px',
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  ngOnInit(): void {
  }

  public openLov = {
    line: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'LINE',
          moduleId: this.moduleId
        },
      }
      this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button?.toUpperCase() === 'OK') {
          this.forms.resetFirePolWordingsForm();
          if (output) {
            this.forms.filterLovForm.patchValue({
              lineCd: output.LINE_CD,
              lineName: output.LINE_NAME,
              lineCdDisp: `${output.LINE_CD} - ${output.LINE_NAME}`,
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null,
              planCd: null,
              planName: null,
              planDisp: null,
            });
            this.btns.firePolWordings.addDisabled = true;
            this.btns.filterLov.sublineLovSearchDisabled = false;
            this.btns.filterLov.planLovSearchDisabled = true;
          }
          else {
            this.forms.filterLovForm.patchValue({
              lineCd: null,
              lineName: null,
              lineCdDisp: null,
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null,
              planCd: null,
              planName: null,
              planDisp: null,
            });
            this.btns.firePolWordings.addDisabled = this.btns.firePolWordings.formLovSearchDisabled = true;
            this.btns.filterLov.sublineLovSearchDisabled = true;
            this.btns.filterLov.planLovSearchDisabled = true;
          }
          this.getData.firePolWordings();
        }
      });
    },
    subline: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          lineCd: this.forms.filterLovForm.get('lineCd')?.value,
          table: 'SUBLINE',
          moduleId: this.moduleId
        },
      }
      this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button?.toUpperCase() === 'OK') {
          this.forms.resetFirePolWordingsForm();
          if (output) {
            this.forms.filterLovForm.patchValue({
              lineCd: output.LINE_CD,
              lineName: output.LINE_NAME,
              lineCdDisp: `${output.LINE_CD} - ${output.LINE_NAME}`,
              sublineCd: output.SUBLINE_CD,
              sublineName: output.SUBLINE_NAME,
              sublineCdDisp: `${output.SUBLINE_CD} - ${output.SUBLINE_NAME}`,
              planCd: null,
              planName: null,
              planDisp: null,
            });
            this.btns.filterLov.planLovSearchDisabled = false;
          }
          else {
            this.forms.filterLovForm.patchValue({
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null,
              planCd: null,
              planName: null,
              planDisp: null,
            });
            this.btns.firePolWordings.addDisabled = this.btns.firePolWordings.formLovSearchDisabled = true;
            this.btns.filterLov.planLovSearchDisabled = true;
          }
          this.getData.firePolWordings();
        }
      });
    },
    plan: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'PLAN',
          moduleId: this.moduleId,
          lineCd: this.forms.filterLovForm.get('lineCd')?.value,
          sublineCd: this.forms.filterLovForm.get('sublineCd')?.value,
          lpad: 2,
        },
      };
      this._matDialog.open(PlanLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button?.toUpperCase() === 'OK') {
          this.forms.resetFirePolWordingsForm();
          if (output.PLAN_CD) {
            this.forms.filterLovForm.patchValue({
              planCd: output.PLAN_CD,
              planName: output.PLAN_NAME,
              planDisp: `${output.PLAN_CD} - ${output.PLAN_NAME}`,
            });
          }
          else {
            this.forms.filterLovForm.patchValue({
              planCd: null,
              planName: null,
              planDisp: null,
            });
            this.btns.firePolWordings.addDisabled = this.btns.firePolWordings.formLovSearchDisabled = true;
          }
          this.getData.firePolWordings();
        }
      });
    },
  }

  private getData = {
    firePolWordings: () => {
      try {
        this.firePolWordingsTblConfig.tblData = [];
        this.btns.firePolWordings.saveDisabled = true;
        if (this.forms.filterLovForm.get('lineCd')?.value
          && this.forms.filterLovForm.get('sublineCd')?.value
          && this.forms.filterLovForm.get('planCd')?.value) {
          this._jDS.contorlLoading(true);
          this.firePolWordingsTblConfig.loading = true;
          this._api.getFirePolWordings({
            lineCd: this.forms.filterLovForm.get('lineCd')?.value,
            sublineCd: this.forms.filterLovForm.get('sublineCd')?.value,
            planCd: this.forms.filterLovForm.get('planCd')?.value,
            moduleId: this.moduleId,
            userId: this._userDetailService.userId,
            type: "MODULE",
          }).subscribe({
            next: (data: any) => {
              this._securityService.checkRequestKeyResponse(data, () => {
                this._securityService.hasValidCsrfToken(data, () => {
                  if (data.status === "SUCCESS") {
                    let content = JSON.parse(this._jDS.decrypt(data?.response));
                    this.firePolWordingsTblConfig.tblData = content.data;
                    this._jDS.contorlLoading(false);
                    this.firePolWordingsTblConfig.loading = false;
                    this.btns.firePolWordings.addDisabled = this.btns.firePolWordings.formLovSearchDisabled = false;
                  }
                  else {

                    this._jDS.contorlLoading(false);
                    this.firePolWordingsTblConfig.loading = false;
                    this.btns.firePolWordings.addDisabled = this.btns.firePolWordings.formLovSearchDisabled = true;
                  }
                });
              });
            },
            error: (e: any) => {
              this._securityService.checkRequestKeyResponse(e, () => {

                this._jDS.contorlLoading(false);
                this.firePolWordingsTblConfig.loading = false;
                this.btns.firePolWordings.addDisabled = this.btns.firePolWordings.formLovSearchDisabled = true;
              });
            }
          });
        }
        else {
          this.firePolWordingsTblConfig.loading = false;
          this.btns.firePolWordings.addDisabled = this.btns.firePolWordings.formLovSearchDisabled = true;
        }
      }
      catch (e) {
    console.error(e);

      }
    },
    wordingCds: () => {
      const filteredWordingsCds = this._jDS.data.refCds.filter((data: any) => data.rvDomain === 'BMM188.WORDING_CD').map((data: any) => {
        return {
          cd: data.rvLowValue,
          disp: data.rvLowValue
        };
      });
      return filteredWordingsCds;
    },
  }

  public selections = {
    wordingCds: [
      { cd: null, disp: '' },
      ...this.getData.wordingCds()
    ],
    activeTypes: [
      { cd: 'A', name: 'Yes' },
      { cd: 'I', name: 'No' },
    ],
  };

  public onRowClick(ev: any) {
    try {
      if (ev) {
        this.rowData = ev;
        this.forms.firePolWordingsForm.get('wordingCd')?.disable();
        this.populateFormFromTable();
      }
      else {
        this.rowData = null;
        this.forms.resetFirePolWordingsForm();
      }
    }
    catch (e) {
    console.error(e);
      this.forms.resetFirePolWordingsForm();
      this.rowData = null;
    }
  }

  private populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.firePolWordings.updateDisabled = false;
      this.btns.firePolWordings.deleteDisabled = !(this.rowData?.operation === 'I');
      this.forms.firePolWordingsForm.patchValue(this.firePolWordingsFormManipulate.dbToForm(data));
    }
    catch (e) {
    console.error(e);

    }
  }

  public onAdd(): void {
    try {
      this._formService.hideFormMsg("fire-pol-wordings-error-message");
      if (this.forms.firePolWordingsForm.valid) {
        this.forms.firePolWordingsForm.patchValue({
          lineCd: this.forms.filterLovForm.get('lineCd')?.value,
          sublineCd: this.forms.filterLovForm.get('sublineCd')?.value,
          planCd: this.forms.filterLovForm.get('planCd')?.value,
        });
        let rowToBeAdded: { [key: string]: any } = this.firePolWordingsFormManipulate.formToDb(this.forms.firePolWordingsForm.getRawValue());
        rowToBeAdded.operation = 'I';
        this.firePolWordingsTblConfig.tblData = [rowToBeAdded, ...this.firePolWordingsTblConfig.tblData];
        this.forms.resetFirePolWordingsForm();
        this.btns.firePolWordings.saveDisabled = false;
      }
      else {
        this.showErrorValidator.firePolWordings();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onUpdate(): void {
    try {
      this._formService.hideFormMsg("fire-pol-wordings-error-message");
      if (this.forms.firePolWordingsForm.valid) {
        const indexOfRow = this.firePolWordingsTblConfig.tblData.indexOf(this.rowData);
        let rowToBeUpdated: { [key: string]: any } = this.firePolWordingsFormManipulate.formToDb(this.forms.firePolWordingsForm.getRawValue());
        rowToBeUpdated.operation = (this.rowData.operation === 'I') ? 'I' : 'U';
        this.forms.resetFirePolWordingsForm();
        this.firePolWordingsTblConfig.tblData[indexOfRow] = rowToBeUpdated;
        this.selectedRow = [];
        this.btns.firePolWordings.saveDisabled = false;
        this.firePolWordingsTblConfig.tblData = [...this.firePolWordingsTblConfig.tblData];
      }
      else {
        this.showErrorValidator.firePolWordings();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onDelete(): void {
    try {
      this.firePolWordingsTblConfig.tblData = this.firePolWordingsTblConfig.tblData.filter((row: any) => {
        return row !== this.rowData;
      });
      this.forms.resetFirePolWordingsForm();
      this.btns.firePolWordings.saveDisabled = !(this.firePolWordingsTblConfig.tblData.filter((row: any) => ['I', 'U'].includes(row.operation)).length > 0);
    }
    catch (e) {
    console.error(e);

    }
  }

  public onSave(): void {
    try {
      this._jDS.contorlLoading(true);
      this.showForm = false;
      this._formService.showFormLoader(null, "fire-pol-wordings-maintenance-loading", "Saving.<br>Please wait ...", null, null, true);
      let toBeSaved = this.firePolWordingsTblConfig.tblData.filter((row: any) => ['I', 'U'].includes(row.operation));
      if (toBeSaved.length > 0) {
        this._api.saveFirePolWordings(toBeSaved).subscribe({
          next: (response: any) => {
            this._securityService.checkRequestKeyResponse(response, () => {
              this._securityService.hasValidCsrfToken(response, () => {
                if (response.status === 'SUCCESS') {
                  this.onComplete.firePolWordings();
                }
                else {
                  this.onComplete.firePolWordingsError();
                }
              });
            });
          },
          error: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {

              this.onComplete.firePolWordingsError();
            });
          }
        });
      }
      else {
        this.onComplete.firePolWordings();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  private onComplete = {
    firePolWordings: () => {
      this._jDS.contorlLoading(false);
      this._formService.hideFormLoader("fire-pol-wordings-maintenance-loading");
      this.openDialog('SUCCESS', this.moduleName.replace('Maintenance', ''), 'saved');
      this.btns.firePolWordings.saveDisabled = true;
      this.showForm = true;
      this.forms.resetFirePolWordingsForm();
    },
    firePolWordingsError: () => {
      this._jDS.contorlLoading(false);
      this.openDialog('FAILED', this.moduleName.replace('Maintenance', ''), 'saving');
      this._formService.hideFormLoader("fire-pol-wordings-maintenance-loading");
      this.showForm = true;
      this.forms.resetFirePolWordingsForm();
    },
  }

  openDialog(title: string, contentTitle: string, contentDetail: string) {
    try {
      this._matDialog.open(DialogMsgComponent, {
        disableClose: true,
        width: '512px',
        data: {
          title: title,
          content:
            title === 'SUCCESS'
              ? `${contentTitle} details successfully ${contentDetail}!`
              : `${contentTitle} details ${contentDetail} failed!`,
        },
      }).afterClosed().subscribe(() => {
        if (title === 'SUCCESS') {
          this.getData.firePolWordings();
        }
      });
    }
    catch (e) {
    console.error(e);

    }
  }

  //* Validator Stuffs Down here */

  private checkDuplicateWordingCd(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.trim()?.toUpperCase();
      if (!value) return null;
      let hasDuplicate: boolean = this.firePolWordingsTblConfig?.tblData.filter((d: any) => {
        if (!this.rowData)
          return d['WORDING_CD']?.trim().toUpperCase() === value;
        else
          return this.rowData['WORDING_CD']?.trim()?.toUpperCase() !== value && d['WORDING_CD']?.trim()?.toUpperCase() === value;
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupe: true } : null;
    }
  }

  private checkDuplicateSeqNo(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) return null;
      let hasDuplicate: boolean = this.firePolWordingsTblConfig.tblData.filter((d: any) => {
        if (!this.rowData)
          return d['SEQ_NO'] == value;
        else
          return this.rowData['SEQ_NO'] != value && d['SEQ_NO'] == value;
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupeSeqNo: true } : null;
    }
  }

  public showErrorValidator = {
    firePolWordings: () => {
      try {
        Object.keys(this.forms.firePolWordingsForm.controls).forEach(key => {
          const controlErrors = this.forms.firePolWordingsForm.get(key)?.errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              if (keyError == 'hasDupe' && controlErrors[keyError] === true)
                this.errorMessage = "Code already exists. Please use a unique Code.";
              else if (keyError == 'hasDupeSeqNo' && controlErrors[keyError] === true)
                this.errorMessage = "Sequence No. already exists. Please choose a different Sequence No.";
              else if (keyError == 'min')
                this.errorMessage = `Sequence No. must be greater than ${controlErrors[keyError]['min']}. Please choose a different Sequence No.`;
              else if (keyError == 'required' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
            });
          }
        });
        this._formService.showFormMsg("fire-pol-wordings-error-message", this.errorMessage, "E");
      }
      catch (e) {
    console.error(e);

      }
    },
  }
}
