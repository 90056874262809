<section id="generate-it-reports">
    <div class="mtn-title">
        <p>GENERATE IT REPORTS</p>
    </div>
    <form [formGroup]="reportParams">
        <div id="generate-it-form">
            <div class="form-loader"></div>
            <div class="form-fields"><div class="form">
                    <div class="col-case">
                        <div class="field required">
                            <label>Report Name</label>
                            <mat-form-field appearance="fill">
                                <mat-select placeholder="Report Name" formControlName="reportType">
                                    <mat-option *ngFor="let option of reportTypes" [value]="option.id">
                                        {{option.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="field required">
                            <label>Transaction Type</label>
                            <mat-form-field appearance="fill" >
                                <mat-select placeholder="Type Name" formControlName="transactionType" multiple>
                                    <mat-option *ngFor="let option of repTranTypes" [value]="option.rvLowValue">
                                        {{option.rvMeaning}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="field">
                            <label>From</label>
                            <mat-form-field appearance="fill">
                                <input matInput [matDatepicker]="picker" formControlName="dateFrom" placeholder="MM/DD/YYYY">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="field">
                            <label>To</label>
                            <mat-form-field appearance="fill">
                                <input matInput [matDatepicker]="picker2" formControlName="dateTo" placeholder="MM/DD/YYYY">
                                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="field required">
                            <label>Destination</label>
                            <mat-form-field appearance="fill" >
                                <mat-select placeholder="Format" formControlName="format">
                                    <mat-option *ngFor="let option of formats" [value]="option.id">
                                        {{option.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div id="generate-it-form-msg"></div>
        </div>  
        <div class="form-btns">
            <button class="btn btn2" (click)="validateParams()">Print</button>
        </div>
    </form>
</section>