import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe, formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';

@Component({
  selector: 'warranties-clauses-maintenance',
  templateUrl: './warranties-clauses-maintenance.component.html',
  styleUrls: ['./warranties-clauses-maintenance.component.css']
})
export class WarrantiesClausesMaintenanceComponent implements OnInit, LeaveConfirmation {

  private moduleId: string = 'BMM066';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc?.toUpperCase() ?? "";

  datePipe = new DatePipe('en-us');
  minEffDate: Date = new Date();

  constructor(
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    public _formService: FormService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService
  ) {
    this.userIdLoggedIn = this._userDetailService.userId ?? "";
   }

  ngOnInit(): void {
    this.btns.warrClau.addDisabled = true;
  }

  canDeactivate(): boolean | Observable<boolean> {
    if(this.tempData!='') {
      return false;
    } else {
      return true;
    }
  }

  /* Boolean Stuffs */

  public btns = {
    warrClau: {
      addDisabled: true,
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      basicPerilDisabled: false
    },
  }

  public showForm: boolean = true;

  private rowData: any;
  private tempDataRow: any;
  private errorMessage: string = "";
  private userIdLoggedIn: string;
  private tempData: any = [];

  public forms = {
    lineLovForm: this.fb.group({
      lineCd: [null],
      lineName: [null],
      lineCdDisp: [{ value: '', disabled: 'true' }],
    }),
    warrClauForm: this.fb.group({
      lineCd: [null],
      wcCd: [{ value: '', disabled: 'true' }, [Validators.required, Validators.maxLength(4), this.noWhiteSpaceCheck()]],
      activeTag: ['A', [Validators.required]],
      wcTitle: [null, [Validators.required, this.noWhiteSpaceCheck()]],
      effDateFrom: [{ value: '', disabled: 'true' }, [Validators.required]],
      effDateTo: [null],
      seqNo: [null],
      groupCd: [null],
      wcText: [null],
      remarks: [null, [Validators.maxLength(4000)]],
      userID: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }]
    }),
    resetWarrClauForm: () => {
      this.forms.warrClauForm.reset();
      this.forms.warrClauForm.patchValue({
        activeTag: 'A'
      });
      this.btns.warrClau.updateDisabled = true;
      this.btns.warrClau.deleteDisabled = true;
      this.forms.warrClauForm.get('wcCd')?.enable();
      this.forms.warrClauForm.get('effDateFrom')?.enable();
      this._formService.hideFormMsg("warr-clau-error-message");
      this.rowData = null;
    },
    resetLineLovForm: () => {
      this.forms.lineLovForm.reset();
    },
    resetAllForms: () => {
      this.forms.resetLineLovForm();
      this.forms.resetWarrClauForm();
    }
  }

  private warrClauFormManipulate = {
    dbToForm: (data: any) => {
      return {
        lineCd: data.LINE_CD,
        wcCd: data.WC_CD,
        activeTag: data.ACTIVE_TAG,
        wcTitle: data.WC_TITLE,
        effDateFrom: this.dateFilter(data.EFF_DATE_FROM),
        effDateTo: this.dateFilter(data.EFF_DATE_TO),
        seqNo: data.SEQ_NO,
        groupCd: data.GROUP_CD,
        wcText: data.WC_TEXT,
        remarks: data.REMARKS,
        userID: data.USER_ID,
        lastUpdate: data.LAST_UPDATE,
      };
    },
    formToDb: (data: any, mode: any) => {
      return {
        LINE_CD: data.lineCd,
        WC_CD: data.wcCd,
        ACTIVE_TAG: data.activeTag,
        WC_TITLE: data.wcTitle,
        EFF_DATE_FROM: this.datePipe.transform(new Date(data.effDateFrom), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
        EFF_DATE_TO: data.effDateTo=='' || data.effDateTo==null ? null : this.datePipe.transform(new Date(data.effDateTo), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
        SEQ_NO: data.seqNo,
        GROUP_CD: data.groupCd,
        WC_TEXT: data.wcText,
        REMARKS: data.remarks,
        USER_ID: this.userIdLoggedIn,
        LAST_UPDATE: null,
        MODE: mode
      };
    },
    formToTemp: (data: any, mode: any) => {
      return {
        LINE_CD: data.lineCd,
        WC_CD: data.wcCd,
        ACTIVE_TAG: data.activeTag,
        WC_TITLE: data.wcTitle,
        EFF_DATE_FROM: this.datePipe.transform(new Date(data.effDateFrom), 'yyyy-MM-dd'),
        EFF_DATE_TO: data.effDateTo=='' || data.effDateTo==null ? null : this.datePipe.transform(new Date(data.effDateTo), 'yyyy-MM-dd'),
        SEQ_NO: data.seqNo,
        GROUP_CD: data.groupCd,
        WC_TEXT: data.wcText,
        REMARKS: data.remarks,
        USER_ID: this.userIdLoggedIn,
        LAST_UPDATE: null,
        MODE: mode
      };
    }
  }

  public warrClauTblConfig = {
    cols: [
      {
        key: "WC_CD",
        header: "Warranties and Clauses Code",
        dataType: "string",
        width: '18%'
      },
      {
        key: "WC_TITLE",
        header: "Warranties and Clauses Title",
        dataType: "string",
        width: '30%'
      },
      {
        key: "EFF_DATE_FROM",
        header: "Effective Date",
        dataType: "date"
      },
      {
        key: "EFF_DATE_TO",
        header: "Expiry Date",
        dataType: "date"
      },
      {
        key: "SEQ_NO",
        header: "Sequence No.",
        dataType: "number",
        width: '12%'
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        width: '64px'
      }
    ],
    tblData: [] as any[],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  private getData = {
    warrClau: () => {
      try {
        this.warrClauTblConfig.tblData = [];
        this.warrClauTblConfig.loading = true;
        this._jDS.contorlLoading(true);
        this._api.getLineWarrClause({
          lineCd: this.forms.lineLovForm.get('lineCd')?.value,
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          type: "MODULE"
        }).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                let content = JSON.parse(this._jDS.decrypt(data.response));
                this._jDS.contorlLoading(false);
                this.warrClauTblConfig.tblData = content.data.filter((d: any) => d.LINE_CD == this.forms.lineLovForm.get('lineCd')?.value);
                this.btns.warrClau.addDisabled = false;
                this.forms.warrClauForm.get('wcCd')?.enable();
                this.forms.warrClauForm.get('effDateFrom')?.enable();
                this.warrClauTblConfig.loading = false;
              });
            });
          },
          error: (error: any) => {
            this.warrClauTblConfig.loading = false;
            this._jDS.contorlLoading(false);
          }
        });
      }
      catch (e) {
        console.error(e);
      }
    },
    groupCodes: () => {
      const filteredMag = this._jDS.data.refCds.filter((data: any) => data.rvDomain === 'BMM066.GROUP_CD').map((data: any) => { return { cd: data.rvLowValue, name: data.rvMeaning } });
      return filteredMag;
    }
  }

  public selections = {
    activeTypes: [
      { cd: 'A', name: 'Yes' },
      { cd: 'I', name: 'No' },
    ],
    groupCodes: [
      ...this.getData.groupCodes()
    ],
  }

  public onRowClick(ev: any) {
    try {
      ev.update = true;
      this.rowData = ev;
      this.populateFormFromTable();
    }
    catch (e) {
      this.forms.resetWarrClauForm();
      this.rowData = null;
    }
  }

  private populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.warrClau.updateDisabled = !data.update;
      this.btns.warrClau.deleteDisabled = !data.temp;
      this.tempDataRow = !data.temp;
      this.forms.warrClauForm.patchValue(this.warrClauFormManipulate.dbToForm(data));
      this.forms.warrClauForm.get('wcCd')?.disable();
      this.forms.warrClauForm.get('effDateFrom')?.disable();
    }
    catch (e) {
      console.error(e);
    }
  }

  public onAdd() {
    try {
      this._formService.hideFormMsg("warr-clau-error-message");
      if (!this.effDateToCheck()) {
        this._formService.showFormMsg("warr-clau-error-message", "Effectivity Date To cannot be earlier than Effective Date From. Please enter a different Date of Effectivity.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("warr-clau-error-message");
        }, 10000);
      }
      else if (!this.effDateFromCheck('I')) {
        this._formService.showFormMsg("warr-clau-error-message", "Date of Effectivity cannot be concurrent with another record under the same Line and Warranties and Clause Code. Please enter a different Date of Effectivity.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("warr-clau-error-message");
        }, 10000);
      }
      else if (this.forms.warrClauForm.valid) {
        this.forms.warrClauForm.patchValue({
          lineCd: this.forms.lineLovForm.get('lineCd')?.value
        });
        let rowToBeAdded: { [key: string]: any } = this.warrClauFormManipulate.formToDb(this.forms.warrClauForm.value, 'I');
        let rowToBeAddedTemp: { [key: string]: any } = this.warrClauFormManipulate.formToTemp(this.forms.warrClauForm.value, 'I');
        rowToBeAdded.temp = true;
        this.tempData.push(rowToBeAddedTemp);
        this.warrClauTblConfig.tblData = [rowToBeAdded, ...this.warrClauTblConfig.tblData];
        this.forms.resetWarrClauForm();
        this.btns.warrClau.saveDisabled = false;
      }
      else {
        this.showErrorValidator.warrClau();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public onDelete() {
    try {
      let clickData = this.rowData;
      clickData.EFF_DATE_FROM = this.datePipe.transform(new Date(clickData.EFF_DATE_FROM), 'yyyy-MM-dd');
      clickData.EFF_DATE_TO = clickData.EFF_DATE_TO=='' || clickData.EFF_DATE_TO==null ? null : this.datePipe.transform(new Date(clickData.EFF_DATE_TO), 'yyyy-MM-dd');
      this.warrClauTblConfig.tblData = this.warrClauTblConfig.tblData.filter((d: any) => {
        return d !== this.rowData;
      });
      this.tempData = this.tempData.filter(
        function(e: any) {
          return e.WC_CD != clickData.WC_CD || e.EFF_DATE_FROM != clickData.EFF_DATE_FROM || e.EFF_DATE_TO != clickData.EFF_DATE_TO;
        }
      );
      if (this.tempData==null || this.tempData=='') {
        this.btns.warrClau.saveDisabled = true;
      }
      this.forms.resetWarrClauForm();
    }
    catch (e) {
      console.error(e);
    }
  }

  public onUpdate() {
    try {
      this._formService.hideFormMsg("warr-clau-error-message");
      if (!this.effDateToCheck()) {
        this._formService.showFormMsg("warr-clau-error-message", "Effectivity Date To cannot be earlier than Effective Date From. Please enter a different Date of Effectivity.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("warr-clau-error-message");
        }, 10000);
      }
      else if (!this.effDateFromCheck('U')) {
        this._formService.showFormMsg("warr-clau-error-message", "Date of Effectivity cannot be concurrent with another record under the same Line and Warranties and Clause Code. Please enter a different Date of Effectivity.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("warr-clau-error-message");
        }, 10000);
      }
      else if (this.forms.warrClauForm.valid) {
        this.forms.warrClauForm.get('wcCd')?.enable();
        this.forms.warrClauForm.get('effDateFrom')?.enable();
        let clickData = this.rowData;
        clickData.EFF_DATE_FROM = this.datePipe.transform(new Date(clickData.EFF_DATE_FROM), 'yyyy-MM-dd');
        clickData.EFF_DATE_TO = clickData.EFF_DATE_TO=='' || clickData.EFF_DATE_TO==null ? null : this.datePipe.transform(new Date(clickData.EFF_DATE_TO), 'yyyy-MM-dd');
        let toBeUpdatedIndex = this.warrClauTblConfig.tblData.indexOf(this.rowData);
        let warrClauCdRowValue = this.rowData.WC_CD;
        let rowToBeUpdated: { [key: string]: any } = this.warrClauFormManipulate.formToDb(this.forms.warrClauForm.value, 'U');
        let rowToBeUpdatedTemp: { [key: string]: any } = this.warrClauFormManipulate.formToTemp(this.forms.warrClauForm.value, 'U');
        this.forms.warrClauForm.get('wcCd')?.disable();
        this.forms.warrClauForm.get('effDateFrom')?.disable();
        let tempInsertChecker = this.tempData.find(
          (m: { WC_CD: any, EFF_DATE_FROM: any, EFF_DATE_TO: any }) => 
            m.WC_CD == clickData.WC_CD && m.EFF_DATE_FROM == clickData.EFF_DATE_FROM && clickData.MODE == 'I');
        let tempUpdateChecker = this.tempData.find(
          (m: { WC_CD: any, EFF_DATE_FROM: any, EFF_DATE_TO: any }) => 
            m.WC_CD == clickData.WC_CD && m.EFF_DATE_FROM == clickData.EFF_DATE_FROM && clickData.MODE == 'U');
        if (this.rowData.temp) {
          rowToBeUpdated.temp = true;
        }
        else {
          rowToBeUpdated.onDbButUpdatedTemp = true;
          rowToBeUpdated.WC_CD = warrClauCdRowValue;
        }
        if (tempInsertChecker!=null && tempInsertChecker!='') {
          //tempInsertChecker = rowToBeUpdated;
          tempInsertChecker.WC_TITLE = rowToBeUpdatedTemp.WC_TITLE;
          tempInsertChecker.ACTIVE_TAG = rowToBeUpdatedTemp.ACTIVE_TAG;
          tempInsertChecker.EFF_DATE_TO = rowToBeUpdatedTemp.EFF_DATE_TO;
          tempInsertChecker.SEQ_NO = rowToBeUpdatedTemp.SEQ_NO;
          tempInsertChecker.GROUP_CD = rowToBeUpdatedTemp.GROUP_CD;
          tempInsertChecker.REMARKS = rowToBeUpdatedTemp.REMARKS;
          tempInsertChecker.USER_ID = rowToBeUpdatedTemp.USER_ID;
          tempInsertChecker.MODE = 'I';
          rowToBeUpdated.MODE = 'I';
          rowToBeUpdatedTemp.MODE = 'I';
        }
        else if (tempUpdateChecker!=null && tempUpdateChecker!='') {
          tempUpdateChecker.WC_TITLE = rowToBeUpdatedTemp.WC_TITLE;
          tempUpdateChecker.ACTIVE_TAG = rowToBeUpdatedTemp.ACTIVE_TAG;
          tempUpdateChecker.EFF_DATE_TO = rowToBeUpdatedTemp.EFF_DATE_TO;
          tempUpdateChecker.SEQ_NO = rowToBeUpdatedTemp.SEQ_NO;
          tempUpdateChecker.GROUP_CD = rowToBeUpdatedTemp.GROUP_CD;
          tempUpdateChecker.REMARKS = rowToBeUpdatedTemp.REMARKS;
          tempUpdateChecker.USER_ID = rowToBeUpdatedTemp.USER_ID;
          tempUpdateChecker.MODE = 'U';
        }
        else { 
          this.tempData.push(rowToBeUpdatedTemp);
        }
        this.forms.resetWarrClauForm();
        this.warrClauTblConfig.tblData[toBeUpdatedIndex] = rowToBeUpdated;
        this.btns.warrClau.saveDisabled = false;
        this.warrClauTblConfig.tblData = [... this.warrClauTblConfig.tblData];
      }
      else {
        this.showErrorValidator.warrClau();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public onSave() {
    try {
      this.btns.warrClau.saveDisabled = true;
      this.showForm = false;
      this._formService.showFormLoader(null, "warr-clau-maintenance-loading", "Saving.<br>Please wait ...", null, null);
      if (this.tempData.length > 0) {
        this._api.saveLineWarrClause(this.tempData).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                if (data.status === 'SUCCESS') {
                  this.onComplete.warrClau();
                }
                else {
                  this.onComplete.warrClauError();
                }
              });
            });
          },
          error: (data: any) => {
            this.onComplete.warrClauError();
          },
        });
      }
      else
        this.onComplete.warrClau();
    }
    catch (e) {
      console.error(e);
    }
  }

  private onComplete = {
    warrClau: () => {
      this.tempData = [];
      this.getData.warrClau();
      this.btns.warrClau.saveDisabled = true;
      this.showForm = true;
      this._formService.hideFormLoader("warr-clau-maintenance-loading");
      this.forms.resetWarrClauForm();
      this.openDialog('SUCCESS', 'Line and Warranties and Clause Code details successfully saved');
    },
    warrClauError: () => {
      this.showForm = true;
      this.btns.warrClau.saveDisabled = false;
      this.openDialog('FAILED', 'Line and Warranties and Clause Code details saving failed');
      this._formService.hideFormLoader("warr-clau-maintenance-loading");
    },
  }

  openDialog(title: any, message: any) {
    try {
      this.showForm = true;
      this._formService.hideFormLoader("warr-clau-maintenance-loading");
      this._matDialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        } 
      });
    } catch(e) {
      console.error(e);
    }
  }

  public openLov = {
    line: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'LINE',
          moduleId: this.moduleId
        },
      }
      this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button === 'OK') {
          this.forms.resetWarrClauForm();
          if (output) {
            this.forms.lineLovForm.patchValue({
              lineCd: output.LINE_CD,
              lineName: output.LINE_NAME,
              lineCdDisp: `${output.LINE_CD} - ${output.LINE_NAME}`
            });
          }
          else {
            this.forms.lineLovForm.patchValue({
              lineCd: null,
              lineName: null,
              lineCdDisp: null
            });
          }
          this.tempData = [];
          this.getData.warrClau();
        }
      });
    }
  };

  effDateToCheck() {
    try {
      let effDateFromTemp = this.datePipe.transform(new Date(this.forms.warrClauForm.get('effDateFrom')?.value), 'yyyy-MM-dd');
      let effDateToTemp = this.forms.warrClauForm.get('effDateTo')?.value=='' || this.forms.warrClauForm.get('effDateTo')?.value==null ? null : this.datePipe.transform(new Date(this.forms.warrClauForm.get('effDateTo')?.value), 'yyyy-MM-dd');
    
      if(effDateToTemp!=null && effDateFromTemp!=null) {
        if(effDateToTemp>effDateFromTemp) {
          return true;
        }
        else {
          return false;
        }
      } else {
        return true;
      }
    }
    catch (e) {
      console.error(e);
      return false;
    }
  }

  effDateFromCheck(mode: any) {
    try {
      if (mode === 'U') {
        this.forms.warrClauForm.get('wcCd')?.enable();
        this.forms.warrClauForm.get('effDateFrom')?.enable();
      }
      let wcCdTemp = this.forms.warrClauForm.get('wcCd')?.value;
      let effDateFromTemp = this.datePipe.transform(new Date(this.forms.warrClauForm.get('effDateFrom')?.value), 'yyyy-MM-dd');
      let effDateToTemp = this.forms.warrClauForm.get('effDateTo')?.value=='' || this.forms.warrClauForm.get('effDateTo')?.value==null ? null : this.datePipe.transform(new Date(this.forms.warrClauForm.get('effDateTo')?.value), 'yyyy-MM-dd');
      let effDateFromField: any;
      let resultCheck: boolean = true;
      if (mode === 'I') {
        effDateFromField = this.warrClauTblConfig.tblData.filter(function(e: any) {
          return e.WC_CD==wcCdTemp;
        });
      }
      else if (mode === 'U') {
        let effDateFromTemp2 = new Date(this.forms.warrClauForm.get('effDateFrom')?.value);
        effDateFromField = this.warrClauTblConfig.tblData.filter(function(e: any) {
          return e.WC_CD==wcCdTemp && (new Date(e.EFF_DATE_FROM).getMonth()!==effDateFromTemp2.getMonth() || new Date(e.EFF_DATE_FROM).getDate()!==effDateFromTemp2.getDate() || new Date(e.EFF_DATE_FROM).getFullYear()!==effDateFromTemp2.getFullYear());
        });
      }
      for (let r of effDateFromField) {
        let effDateFromTbl = this.datePipe.transform(new Date(r.EFF_DATE_FROM), 'yyyy-MM-dd');
        let effDateToTbl = r.EFF_DATE_TO=='' || r.EFF_DATE_TO==null ? null : this.datePipe.transform(new Date(r.EFF_DATE_TO), 'yyyy-MM-dd');
        if (!effDateToTbl && !effDateToTemp) {
          resultCheck = false;
          break;
        } 
        else if (!effDateToTbl && effDateToTemp && effDateFromTemp && effDateFromTbl) {
          if (effDateFromTemp >= effDateFromTbl) {
            resultCheck = false;
            break;
          } 
          else if (effDateFromTemp < effDateFromTbl && effDateToTemp >= effDateFromTbl) {
            resultCheck = false;
            break;
          }
        }
        else if (!effDateToTemp && effDateFromTemp && effDateFromTbl && effDateToTbl) {
          if (effDateFromTemp <= effDateToTbl) {
            resultCheck = false;
            break;
          }
        }
        else if (effDateToTemp && effDateFromTemp && effDateFromTbl && effDateToTbl) {
          if (effDateToTemp >= effDateFromTbl && effDateToTemp <= effDateToTbl) {
            resultCheck = false;
            break;
          }
          else if (effDateFromTemp >= effDateFromTbl && effDateFromTemp <= effDateToTbl) {
            resultCheck = false;
            break;
          }
          else if (effDateFromTemp >= effDateFromTbl && effDateToTemp <= effDateToTbl) {
            resultCheck = false;
            break;
          }
          else if (effDateFromTemp <= effDateFromTbl && effDateToTemp >= effDateToTbl) {
            resultCheck = false;
            break;
          }
        }
        else {
          resultCheck = false;
        }
      }
      if (mode === 'U') {
        this.forms.warrClauForm.get('wcCd')?.disable();
        this.forms.warrClauForm.get('effDateFrom')?.disable();
      }
      return resultCheck;
    }
    catch (e) {
      console.error(e);
      return false;
    }
  }

  noWhiteSpaceCheck(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: true };
    }
  }

  public showErrorValidator = {
    warrClau: () => {
      try {
        Object.keys(this.forms.warrClauForm.controls).forEach(key => {
          const controlErrors = this.forms.warrClauForm.get(key)?.errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              if (keyError == 'whitespace' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
              else if (keyError == 'matDatepickerParse')
                this.errorMessage = "Invalid date format. Kindly input as MM/DD/YYYY";
              else if (keyError == 'hasDupe' && controlErrors[keyError] === true)
                this.errorMessage = "Warranties and Clause already exists. Please add a unique Warranties and Clause.";
              else if (keyError == 'required' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
            });
          }
        });
        this._formService.showFormMsg("warr-clau-error-message", this.errorMessage, "E");
        //this.openDialog('ERROR', this.errorMessage);
        setTimeout(()=>{                           
          this._formService.hideFormMsg("warr-clau-error-message");
        }, 10000);
      }
      catch (e) {
        console.error(e);
      }
    },
  }

  dateFilter(dateString: any) {
    try {
      if (dateString=='' || dateString==null) {
        return '';
      }
      else if (dateString=='0000-00-00' || dateString=='0000-00-00 12:00:00 AM' || dateString=='1970-01-01') {
        return '';
      }
      else {
        return formatDate(dateString, 'yyyy-MM-dd', 'en');
      }
    }
    catch(e) {
      console.error(e);
      return '';
    }
  }

  autoUppercase(ev: any) {
    try{
      ev.target.value = ev.target.value.toUpperCase();
    }
    catch(e) {
      console.error(e);
    }
  }

  integerFormat(fieldName:string){
    try {
      if(this.forms.warrClauForm.get(fieldName)?.value!==''&&this.forms.warrClauForm.get(fieldName)?.value!==null){
        let fieldFloat = parseFloat(this.forms.warrClauForm.get(fieldName)?.value)
        if(isNaN(fieldFloat)){
          this.forms.warrClauForm.get(fieldName)?.setValue('');
        }
      }
    }
    catch(e) {
      console.error(e);
    }
  }

  allowNumericDigitsOnlyWODecimal(event: any) {
    try {
      if (/[0-9]/.test(String.fromCharCode(event.keyCode))) {
        return true;
      }
      else {
        event.preventDefault();
        return false;
      }
    }
    catch (e) {
      console.error(e);
      return undefined; 
    }
  }

  allowNumericAndSlashOnly(event: any) {
    try {
      if (/^([0-9/]+)$/.test(String.fromCharCode(event.keyCode))) {
        return true;
      }
      else {
        event.preventDefault();
        return false;
      }
    }
    catch (e) {
      console.error(e);
      return undefined; 
    }
  }

}
