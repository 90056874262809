<div class="mtn-container">
    <div class="mtn-title">
        <p>{{moduleName}}</p>
    </div>
    <div class="outer-wrapper p-datatable mtn-header">
        <div *ngIf="showForm.nationality" class="form-content"></div>
        <osp-table [tblConfig]="tblConfig.nationality" (onRowClick)="onRowClick.nationality($event)"></osp-table>
        <div id="nationality-maintenance-loading"><div class="form-loader"></div></div>

        <div class="form-content" [hidden]="!showForm.nationality">

            <form [formGroup]="forms.nationalityForm" class="form">
                <div id="nationality-error-message"></div>
                <div class="form-container-grid">
                    <div class="field">
                        <label>Nationality Code</label>
                    </div>
                    <div class="">
                        <input formControlName="nationalityCdPadded" type="text">
                    </div>

                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag" >
                            <option *ngFor="let activeType of selections.activeTypes" [value]="activeType.cd">
                                {{activeType.name}}
                            </option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>Nationality</label>
                    </div>
                    <div class="">
                        <input formControlName="nationalityDesc" type="text" maxlength="50">
                    </div>

                    <div class="field required">
                        <label>BPI MS Code</label>
                    </div>
                    <div class="">
                        <input formControlName="bpimsCd" type="text" maxlength="8">
                    </div>

                    <div class="field required">
                        <label>BPI MS Short Description</label>
                    </div>
                    <div class="">
                        <input formControlName="bpimsShortDesc" type="text" maxlength="10">
                    </div>
                    <div class="">
                        <label></label>
                    </div>
                    <div class="">
                    </div>

                    <div class="field">
                        <label>Remarks</label>
                        </div>
                    <div class="span">
                        <input formControlName="remarks" type="text" maxlength="4000">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="lastUpdateUser">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="lastUpdate">
                    </div>
                </div>    
            </form>

            <div class="btn-grp">
                <button *ngIf="btns.nationality.updateDisabled" class="btn btn2" type="button" (click)="onAdd.nationality()" [disabled]="btns.nationality.addDisabled" >ADD</button>
                <button *ngIf="!btns.nationality.updateDisabled" class="btn btn2" type="button" (click)="onUpdate.nationality()" >UPDATE</button>
                <button class="btn btn2" type="button" (click)="onDelete.nationality()" [disabled]='btns.nationality.deleteDisabled'>DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-grp">
                <button type="button" class="btn btn2" [disabled]="btns.nationality.saveDisabled" (click)="onSave.nationality()">SAVE</button>
            </div>
        </div>
    </div> 
</div>