<div class="mtn-wrapper">
    <div class="mtn-title">
        <p>{{ moduleName }}</p>
    </div>
    <section id="user-level">
        <mat-accordion multi>
            <mat-expansion-panel [expanded]="true" hideToggle disabled>
                <mat-expansion-panel-header class="panel-header ph-mg-b">
                    <mat-panel-title>
                        VIEW ALL
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="tbl">
                    <osp-table #tblViewAll [tblConfig]="tblConfigViewAll" (onRowClick)="onRowClick($event)" (onLazyLoad)="lazyLoad($event, 'all')"></osp-table>
                </div>
                <div class="form-btns">
                    <div class="p-text-center bot-form form-btns">
                        <button mat-button class="btn btn2 upload-btn" (click)="upload.open()">UPLOAD</button>  
                    </div>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel #ep1 hideToggle (opened)="opened('l1'); l1Expanded=true" (closed)="expansionPanelController(null,'l1'); l1Expanded=false">
                <mat-expansion-panel-header class="panel-header">
                    <mat-panel-title>
                        USER LEVEL 1
                    </mat-panel-title>
                    <mat-icon *ngIf="!l1Expanded" class="ic">expand_more</mat-icon>
                    <mat-icon *ngIf="l1Expanded" class="ic">expand_less</mat-icon>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <div class="tbl">
                        <osp-table #tblL1 [tblConfig]="tblConfigL1" (onRowClick)="onRowClick($event, 'l1')" (onLazyLoad)="lazyLoad($event, 'l1')"></osp-table>
                    </div>
                    <div>
                        <div class="p-grid">
                            <div class="p-col-1"></div>
                            <form class="p-col-10 form" [formGroup]="l1Form">
                                <div id="user-level-1-form-msg"></div>
                                <div id="user-level-1-form">
                                    <div class="form-loader"></div>
                                    <div class="form-fields">
                                        <div class="p-grid cust-margin">
                                            <div class="p-col-6 p-grid">
                                                <div class="p-col-6 p-text-right field required">
                                                    <label>User ID</label>
                                                </div>
                                                <div class="p-col-6">
                                                    <input matInput type="text" formControlName="headUserId" class="lov-inp" readonly>
                                                    <button matSuffix class="btn btn-search" type="button" (click)="openLov('l1')" [disabled]="selectedRowL1 !== null">
                                                        <mat-icon>search</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="p-col-6 p-grid">
                                                <div class="p-col-6 p-text-right field">
                                                    <label>Name</label>
                                                </div>
                                                <div class="p-col-6">
                                                    <input matInput type="text" formControlName="headUserName" class="lov-inp">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-grid cust-margin">
                                            <div class="p-col-12 p-grid">
                                                <div class="p-col-12 p-grid">
                                                    <div class="p-col-3 p-text-right field">
                                                        <label>Remarks</label>
                                                    </div>
                                                    <div class="p-col-9">
                                                        <input matInput type="text" formControlName="remarks">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-grid cust-margin">
                                            <div class="p-col-6 p-grid">
                                                <div class="p-col-6 p-text-right field">
                                                    <label>Last Update User</label>
                                                </div>
                                                <div class="p-col-6">
                                                    <input matInput type="text" formControlName="userId">
                                                </div>
                                            </div>
                                            <div class="p-col-6 p-grid">
                                                <div class="p-col-6 p-text-right field">
                                                    <label>Last Update</label>
                                                </div>
                                                <div class="p-col-6">
                                                    <input matInput type="text" formControlName="lastUpdate" [value]="l1Form.get('lastUpdate')?.value | date: 'MM/dd/yyyy hh:mm:ss a'">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-grid form-btns">
                                    <div class="p-col p-text-center bot-form form-btns">
                                        <button class="btn btn2" (click)="onClickAddUpdate('l1')">{{ selectedRowL1 == null ? 'ADD' : 'UPDATE' }}</button>
                                        <button class="btn btn2" (click)="onClickAddUpdate('l1', true, true)" [disabled]="selectedRowL1 == null">DELETE</button>
                                        <div class="mtn-title-2"></div>
                                        <button class="btn btn2" (click)="onClickSave('l1')" [disabled]="saveListL1.length == 0">SAVE</button>
                                    </div>
                                </div>
                            </form>
                            <div class="p-col-1"></div>
                        </div>
                    </div>
                </ng-template>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="l2Expanded" [disabled]="disableL2" hideToggle (closed)="l2Expanded=false" (opened)="l2Expanded=true">
                <mat-expansion-panel-header class="panel-header" [ngClass]="{'panel-header-d': disableL2}" >
                    <mat-panel-title>
                        USER LEVEL 2
                    </mat-panel-title>
                    <mat-icon *ngIf="!disableL2 && !l2Expanded" class="ic">expand_more</mat-icon>
                    <mat-icon *ngIf="!disableL2 && l2Expanded" class="ic">expand_less</mat-icon>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <div class="tbl">
                        <osp-table #tblL2 [tblConfig]="tblConfigL2" (onRowClick)="onRowClick($event, 'l2')" (onLazyLoad)="lazyLoad($event, 'l2')"></osp-table>
                    </div>
                    <div>
                        <div class="p-grid">
                            <div class="p-col-1"></div>
                            <form class="p-col-10 form" [formGroup]="l2Form">
                                <div id="user-level-2-form-msg"></div>
                                <div id="user-level-2-form">
                                    <div class="form-loader"></div>
                                    <div class="form-fields">
                                        <div class="p-grid cust-margin">
                                            <div class="p-col-6 p-grid">
                                                <div class="p-col-6 p-text-right field required">
                                                    <label>User ID</label>
                                                </div>
                                                <div class="p-col-6">
                                                    <input matInput type="text" formControlName="officerUserId" class="lov-inp" readonly>
                                                    <button matSuffix class="btn btn-search" type="button" (click)="openLov('l2')" [disabled]="selectedRowL2 !== null">
                                                        <mat-icon>search</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="p-col-6 p-grid">
                                                <div class="p-col-6 p-text-right field">
                                                    <label>Name</label>
                                                </div>
                                                <div class="p-col-6">
                                                    <input matInput type="text" formControlName="officerUserName" class="lov-inp">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-grid cust-margin">
                                            <div class="p-col-12 p-grid">
                                                <div class="p-col-12 p-grid">
                                                    <div class="p-col-3 p-text-right field">
                                                        <label>Remarks</label>
                                                    </div>
                                                    <div class="p-col-9">
                                                        <input matInput type="text" formControlName="remarks">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-grid cust-margin">
                                            <div class="p-col-6 p-grid">
                                                <div class="p-col-6 p-text-right field">
                                                    <label>Last Update User</label>
                                                </div>
                                                <div class="p-col-6">
                                                    <input matInput type="text" formControlName="userId">
                                                </div>
                                            </div>
                                            <div class="p-col-6 p-grid">
                                                <div class="p-col-6 p-text-right field">
                                                    <label>Last Update</label>
                                                </div>
                                                <div class="p-col-6">
                                                    <input matInput type="text" formControlName="lastUpdate" [value]="l2Form.get('lastUpdate')?.value | date: 'MM/dd/yyyy hh:mm:ss a'">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-grid form-btns">
                                    <div class="p-col p-text-center bot-form form-btns">
                                        <button class="btn btn2" (click)="onClickAddUpdate('l2')">{{ selectedRowL2 == null ? 'ADD' : 'UPDATE' }}</button>
                                        <button class="btn btn2" (click)="onClickAddUpdate('l2', true, true)" [disabled]="selectedRowL2 == null">DELETE</button>
                                        <div class="mtn-title-2"></div>
                                        <button class="btn btn2" (click)="onClickSave('l2')" [disabled]="saveListL2.length == 0">SAVE</button>
                                    </div>
                                </div>
                            </form>
                            <div class="p-col-1"></div>
                        </div>
                    </div>
                </ng-template>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="l3Expanded" [disabled]="disableL3" hideToggle (closed)="l3Expanded=false" (opened)="l3Expanded=true">
                <mat-expansion-panel-header class="panel-header" [ngClass]="{'panel-header-d': disableL3}">
                    <mat-panel-title>
                        USER LEVEL 3
                    </mat-panel-title>
                    <mat-icon *ngIf="!disableL3 && !l3Expanded" class="ic">expand_more</mat-icon>
                    <mat-icon *ngIf="!disableL3 && l3Expanded" class="ic">expand_less</mat-icon>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <div class="tbl">
                        <osp-table #tblL3 [tblConfig]="tblConfigL3" (onRowClick)="onRowClick($event, 'l3')" (onLazyLoad)="lazyLoad($event, 'l3')"></osp-table>
                    </div>
                    <div>
                        <div class="p-grid">
                            <div class="p-col-1"></div>
                            <form class="p-col-10 form" [formGroup]="l3Form">
                                <div id="user-level-3-form-msg"></div>
                                <div id="user-level-3-form">
                                    <div class="form-loader"></div>
                                    <div class="form-fields">
                                        <div class="p-grid cust-margin">
                                            <div class="p-col-6 p-grid">
                                                <div class="p-col-6 p-text-right field required">
                                                    <label>User ID</label>
                                                </div>
                                                <div class="p-col-6">
                                                    <input matInput type="text" formControlName="assistantUserId" class="lov-inp" readonly>
                                                    <button matSuffix class="btn btn-search" type="button" (click)="openLov('l3')" [disabled]="selectedRowL3 !== null">
                                                        <mat-icon>search</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="p-col-6 p-grid">
                                                <div class="p-col-6 p-text-right field">
                                                    <label>Name</label>
                                                </div>
                                                <div class="p-col-6">
                                                    <input matInput type="text" formControlName="assistantUserName" class="lov-inp">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-grid cust-margin">
                                            <div class="p-col-12 p-grid">
                                                <div class="p-col-12 p-grid">
                                                    <div class="p-col-3 p-text-right field">
                                                        <label>Remarks</label>
                                                    </div>
                                                    <div class="p-col-9">
                                                        <input matInput type="text" formControlName="remarks">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-grid cust-margin">
                                            <div class="p-col-6 p-grid">
                                                <div class="p-col-6 p-text-right field">
                                                    <label>Last Update User</label>
                                                </div>
                                                <div class="p-col-6">
                                                    <input matInput type="text" formControlName="userId">
                                                </div>
                                            </div>
                                            <div class="p-col-6 p-grid">
                                                <div class="p-col-6 p-text-right field">
                                                    <label>Last Update</label>
                                                </div>
                                                <div class="p-col-6">
                                                    <input matInput type="text" formControlName="lastUpdate" [value]="l3Form.get('lastUpdate')?.value | date: 'MM/dd/yyyy hh:mm:ss a'">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-grid form-btns">
                                    <div class="p-col p-text-center bot-form form-btns">
                                        <button class="btn btn2" (click)="onClickAddUpdate('l3')">{{ selectedRowL3 == null ? 'ADD' : 'UPDATE' }}</button>
                                        <button class="btn btn2" (click)="onClickAddUpdate('l3', true, true)" [disabled]="selectedRowL3 == null">DELETE</button>
                                        <div class="mtn-title-2"></div>
                                        <button class="btn btn2" (click)="onClickSave('l3')" [disabled]="saveListL3.length == 0">SAVE</button>
                                    </div>
                                </div>
                            </form>
                            <div class="p-col-1"></div>
                        </div>
                    </div>
                </ng-template>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="l4Expanded" [disabled]="disableL4" hideToggle (closed)="l4Expanded=false" (opened)="l4Expanded=true">
                <mat-expansion-panel-header class="panel-header" [ngClass]="{'panel-header-d': disableL4}">
                    <mat-panel-title>
                        USER LEVEL 4
                    </mat-panel-title>
                    <mat-icon *ngIf="!disableL4 && !l4Expanded" class="ic">expand_more</mat-icon>
                    <mat-icon *ngIf="!disableL4 && l4Expanded" class="ic">expand_less</mat-icon>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                    <div class="radio-container">
                        <mat-radio-group [(ngModel)]="l4Type" class="example-radio-group" (change)="onL4RadioChange()">
                            <mat-radio-button [value]="'l4a'" class="example-radio-button radio-gap">
                                User ID
                            </mat-radio-button>
                            <mat-radio-button [value]="'l4b'" class="example-radio-button">
                                Branch Code
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="tbl">
                        <osp-table #tblL4 [tblConfig]="tblConfigL4" (onRowClick)="onRowClick($event, 'l4')" (onLazyLoad)="lazyLoad($event, 'l4')"></osp-table>
                    </div>
                    <div>
                        <div class="p-grid">
                            <div class="p-col-1"></div>
                            <form class="p-col-10 form" [formGroup]="l4Form">
                                <div id="user-level-4-form-msg"></div>
                                <div id="user-level-4-form">
                                    <div class="form-loader"></div>
                                    <div class="form-fields">
                                        <ng-container *ngIf="l4Type == 'l4a'">
                                            <div class="p-grid cust-margin">
                                                <div class="p-col-6 p-grid">
                                                    <div class="p-col-6 p-text-right field required">
                                                        <label>User ID</label>
                                                    </div>
                                                    <div class="p-col-6">
                                                        <input matInput type="text" formControlName="staffUserId" class="lov-inp" readonly>
                                                        <button matSuffix class="btn btn-search" type="button" (click)="openLov('l4a')">
                                                            <mat-icon>search</mat-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="p-col-6 p-grid">
                                                    <div class="p-col-6 p-text-right field">
                                                        <label>Name</label>
                                                    </div>
                                                    <div class="p-col-6">
                                                        <input matInput type="text" formControlName="staffUserName" class="lov-inp">
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="l4Type == 'l4b'">
                                            <div class="p-grid cust-margin">
                                                <div class="p-col-6 p-grid">
                                                    <div class="p-col-6 p-text-right field required">
                                                        <label>Source Extension</label>
                                                    </div>
                                                    <div class="p-col-6">
                                                        <input matInput type="text" formControlName="srcExtCd" class="lov-inp" readonly>
                                                        <button matSuffix class="btn btn-search" type="button" (click)="openLov('l4b')">
                                                            <mat-icon>search</mat-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="p-col-6 p-grid">
                                                    <div class="p-col-6 p-text-right field">
                                                        <label>Branch Code</label>
                                                    </div>
                                                    <div class="p-col-6">
                                                        <input matInput type="text" formControlName="branchCode" class="lov-inp">
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <div class="p-grid cust-margin">
                                            <div class="p-col-12 p-grid">
                                                <div class="p-col-12 p-grid">
                                                    <div class="p-col-3 p-text-right field">
                                                        <label>Remarks</label>
                                                    </div>
                                                    <div class="p-col-9">
                                                        <input matInput type="text" formControlName="remarks">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="p-grid cust-margin">
                                            <div class="p-col-6 p-grid">
                                                <div class="p-col-6 p-text-right field">
                                                    <label>Last Update User</label>
                                                </div>
                                                <div class="p-col-6">
                                                    <input matInput type="text" formControlName="userId">
                                                </div>
                                            </div>
                                            <div class="p-col-6 p-grid">
                                                <div class="p-col-6 p-text-right field">
                                                    <label>Last Update</label>
                                                </div>
                                                <div class="p-col-6">
                                                    <input matInput type="text" formControlName="lastUpdate" [value]="l4Form.get('lastUpdate')?.value | date: 'MM/dd/yyyy hh:mm:ss a'">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-grid form-btns">
                                    <div class="p-col p-text-center bot-form form-btns">
                                        <button class="btn btn2" (click)="onClickAddUpdate('l4')">{{ selectedRowL4 == null ? 'ADD' : 'UPDATE' }}</button>
                                        <button class="btn btn2" (click)="onClickAddUpdate('l4', true)" [disabled]="selectedRowL4 == null">DELETE</button>
                                        <div class="mtn-title-2"></div>
                                        <button class="btn btn2" (click)="onClickSave('l4')" [disabled]="saveListL4.length == 0">SAVE</button>
                                    </div>
                                </div>
                            </form>
                            <div class="p-col-1"></div>
                        </div>
                    </div>
                </ng-template>
            </mat-expansion-panel>
        </mat-accordion>
    </section>
</div>

<lov #lov [data]="lovData" (selection)="selectedFromLov($event)"></lov>
<upload #upload [data]="uploadConfig"></upload>

<ng-template #deleteDialog>
    <h3 class="overlay-header" mat-dialog-title>Confirmation</h3>
    <mat-dialog-content>
        There are sub-records under this item. Deleting this item will automatically delete all sub-records associated to it. Do you want to proceed?
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-button class="btn btn2" [mat-dialog-close]="true" (click)="confirmDelete()">Yes</button>
        <button mat-button class="btn" [mat-dialog-close]="false">No</button>
    </mat-dialog-actions>
</ng-template>