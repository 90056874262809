import { Component, OnInit } from '@angular/core';

import { APICallService } from 'src/app/services/api-call.service'
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { DialogBoxService } from 'src/app/services/dialog-box.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { SecurityService } from 'src/app/services/security.service';

import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe, formatDate } from '@angular/common';

import { Observable } from 'rxjs';

import { IssSrcComponent } from '../../common/iss-src/iss-src.component';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { RefLovComponent } from '../../common/ref-lov/ref-lov.component';
import { SrcCdLovComponent } from '../../common/src-cd-lov/src-cd-lov.component';
import { SrcExtLovComponent } from '../../common/src-ext-lov/src-ext-lov.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';

@Component({
  selector: 'employee-maintenance',
  templateUrl: './employee-maintenance.component.html',
  styleUrls: ['./employee-maintenance.component.css']
})
export class EmployeeMaintenanceComponent implements OnInit, LeaveConfirmation {

  //userID: string = '';
  //activeTag: string = '';
  //emailAdd: string = '';
  //firstName : string = '';
  //middleName : string = '';
  //lastName : string = '';
  //issueSource: string = '';
  //roleAccess: string = '';
  //lastUpdateName: string = '';
  //allUserSwitch: string = '';
  //roleApproval: string = '';
  //effDate : string = '';
  //expiryDate : string = '';
  //remarks : string = '';
  //lastUserUpdate : string = '';

  datePipe = new DatePipe('en-us');
  moduleName: string = '';
  saveDisabled : boolean = true;
  deleteDisabled : boolean = true;
  userIDReadOnly : boolean = false;
  tranPosted : boolean = false;
  noLoadingInProcess : boolean = true;
  validated : boolean = false;
  submitted : boolean = false;
  isEmpSelected : boolean = false;
  isSuccessSaved : boolean = false;
  userIDField: string = '';
  emailAddField: string = '';
  empNumError : boolean = false;
  emailFormatError : boolean = false;
  currentUser: string = this.userDetailService.userId || "";
  rowClickedData: any;
  moduleId: string = "BMM044";
  activeTags: any = [];

  effDate = new FormControl(new Date());

  selectedIss: any = {
    ISS_CD: null,
    ISS_NAME: null,
    ISS_FIELD: null
  };

  selectedRole: any = {
    ROLE_ID: null,
    ROLE_NAME: null
  };

  selectedRef: any = {
    REFERROR_CD: null,
    REFERROR_DESC: null
  };

  selectedSrcCd: any = {
    SRC_CD: null,
    SRC_CD_DESC: null
  };

  selectedSrcExt: any = {
    SRC_EXT_CD: null,
    SRC_EXT_DESC: null
  };

  rolesList : any = [];
  userIDList : any;

  tempAddedData: any = [];
  tempUpdateData: any = [];

  usersForm = this.fb.group({
    userID: [null, [Validators.required, Validators.maxLength(10)]],
    activeTag: ['A', [Validators.required]],
    firstName: [null, [Validators.required, Validators.maxLength(20)]],
    middleName: [null, [Validators.maxLength(30)]],
    lastName: [null, [Validators.required, Validators.maxLength(30)]],
    fullName: [null],
    emailAdd: ['', [Validators.required, Validators.pattern("^[a-zA-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    mobileNumber: [null, [Validators.required, Validators.maxLength(16)]],
    issueCode: [null, [Validators.required]],
    issueName: [null, [Validators.required]],
    issueField: [null],
    roleID: [null],
    roleAccess: [null],
    roleApproval: [null, [Validators.required]],
    roleName: [null],
    employeeNo: ['', [Validators.required, Validators.maxLength(8), Validators.pattern(/^[0-9]\d*$/)]],
    renNoticeTag:['N', [Validators.required]],
    refCode: [null],
    refDesc: [null],
    srcCode: [null],
    srcCodeDesc: [null],
    srcExt: [null],
    srcExtDesc: [null],
    effDate: [new Date(), [Validators.required]],
    expiryDate: [null],
    remarks: [null],
    lastUpdateName: [null],
    lastUserUpdate: [null],
    mode: [null]
  });

  constructor(
    private _APICallService: APICallService, 
    public _Dialog: MatDialog, 
    private fb: FormBuilder, 
    private formService: FormService, 
    private appMessageService: AppMessageService,
    private dialogBoxService: DialogBoxService,
    private userDetailService: UserDetailsService,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService) {

    this.moduleName = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId === this.moduleId })[0].moduleDesc.toUpperCase();
    for (const refCd of this.jsonDataService.data.refCds.filter((a:any) => a.rvDomain === "BMM013.ACTIVE_TAG")) {
      this.activeTags.push({
          value: refCd.rvLowValue,
          text: refCd.rvMeaning
      });
    }

  }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempAddedData!='' || this.tempUpdateData!='') {
      return false;
    } else {
      return true;
    }
  }

  tblConfigEmp: any = {
    cols: [
      {
        key: "USER_ID",
        header: "User ID",
        dataType: "string",
        width: '20%'
      },
      {
        key: "FULL_NAME",
        header: "Name",
        dataType: "string",
        disabled: false
      },
      {
        key: "ROLE_NAME",
        header: "Role",
        dataType: "string",
        disabled: false,
        width: '17%'
      },
      {
        key: "ISS_NAME",
        header: "Issue Source",
        dataType: "string",
        disabled: false,
        width: '18%'
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        disabled: true,
        width: '64px'
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15
  }

  ngOnInit():void {
    try {
      /*
      this._APICallService.refreshNeeded$
        .subscribe(() => {
          this.getAllUsers();
        });
      */
      this.getAllUsers();
    }
    catch(e) {
      console.error(e);
    }
  }

  /*
  initOnGet():void {
    try {
      this._APICallService.refreshNeeded$
        .subscribe(() => {
          this.getAllUsers();
        });
      this.getAllUsers();
      this.getAllUserID();
    }
    catch(e) {

    }
  }
  */

  getAllUsers() {
    try {
      this.tblConfigEmp.loading = true;
      this.rolesList = this.jsonDataService.data.refCds.filter((e: any) => e.rvDomain == "ROLE_ID");
      this.jsonDataService.contorlLoading(true);
      this._APICallService.getEmployees({
          moduleId: this.moduleId,
          userId: this.userDetailService.userId,
          type: "MODULE"
      }).subscribe((data: any) => {
            this.securityService.checkRequestKeyResponse(data, () => {
              this.securityService.hasValidCsrfToken(data, ()=>{
                if (data.status === "SUCCESS") {
                  data = JSON.parse(this.jsonDataService.decrypt(data.response));
                  this.tblConfigEmp.loading = false;
                  this.tblConfigEmp.tblData = data;
                  this.getAllUserID();
                } else {
                  this.jsonDataService.contorlLoading(false);
                  this.tblConfigEmp.loading = false;
                  this.appMessageService.showAppMessage(data.message, "error");
                }                
              });
            });
        });
    }
    catch (e) {
    console.error(e);

    }
  }

  async getAllUserID() {
    try {
      /*
      this.userIDList = await this._APICallService.getAllUserID().toPromise();
      this.userIDList = this.userIDList.content;
      */
      this.jsonDataService.contorlLoading(true);
      this._APICallService.getAllUserID({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE"
      }).subscribe((data: any) => {
            this.securityService.checkRequestKeyResponse(data, () => {
              this.securityService.hasValidCsrfToken(data, ()=>{
                data = JSON.parse(this.jsonDataService.decrypt(data.response));
                this.jsonDataService.contorlLoading(false);
                this.userIDList = data;
              });
            });
      });
    } catch(e) {
      console.error(e);
      this.jsonDataService.contorlLoading(false);
    }
  }

  insertEmployee() {
    try {
      this.tranPosted = true;
      this.validateInsert();
      if (this.validated) {
        this.addFormToTable();
      }
    }
    catch(e) {
      console.error(e);
    }
  }

  addFormToTable() {
    try {
      let middleInitial : string;
      if (this.usersForm.get('middleName')?.value==null || this.usersForm.get('middleName')?.value=='') {
        middleInitial = '';
      }
      else {
        middleInitial = this.usersForm.get('middleName')?.value.toString();
        middleInitial = ' '.concat(middleInitial.substring(0,1), '. ');
      }
      
      this.selectedRole.ROLE_ID = this.rolesList.find(
        (x: { rvLowValue: any; }) => x.rvLowValue === this.usersForm.get('roleID')?.value).rvLowValue;
      this.selectedRole.ROLE_NAME = this.rolesList.find(
        (x: { rvLowValue: any; }) => x.rvLowValue === this.usersForm.get('roleID')?.value).rvMeaning;

      this.usersForm.get('roleID')
        ?.setValue(this.selectedRole==null || this.selectedRole=='' ? '' : this.selectedRole.ROLE_ID);
      this.usersForm.get('roleName')
        ?.setValue(this.selectedRole==null || this.selectedRole=='' ? '' : this.selectedRole.ROLE_NAME);

      this.usersForm.patchValue({
        activeTag: this.usersForm.get('activeTag')?.value,
        roleID: this.usersForm.get('roleID')?.value,
        roleName: this.selectedRole.ROLE_NAME,
        lastUpdateName: this.currentUser,
        lastUserUpdate: new Date(),
        issueCode: this.selectedIss.ISS_CD,
        issueName: this.selectedIss.ISS_NAME,
        refCode: this.selectedRef.REFERROR_CD,
        refDesc: this.selectedRef.REFERROR_DESC,
        srcCode: this.selectedSrcCd.SRC_CD,
        srcCodeDesc: this.selectedSrcCd.SRC_CD_DESC,
        srcExt: this.selectedSrcExt.SRC_EXT_CD,
        srcExtDesc: this.selectedSrcExt.SRC_EXT_DESC
      });
      let formToData: any = { // this.datePipe.transform(new Date(), 'MM/dd/yyyy')
        USER_ID: this.usersForm.get('userID')?.value.toUpperCase(),
        ACTIVE_TAG: this.usersForm.get('activeTag')?.value,
        FIRST_NAME: this.usersForm.get('firstName')?.value,
        MIDDLE_NAME: this.usersForm.get('middleName')?.value==null ? null : this.usersForm.get('middleName')?.value.trim(),
        LAST_NAME: this.usersForm.get('lastName')?.value,
        FULL_NAME: this.usersForm.get('firstName')?.value.concat(middleInitial,' ',this.usersForm.get('lastName')?.value).toUpperCase(),
        EMAIL_ADDRESS: this.usersForm.get('emailAdd')?.value,
        MOBILE_NUMBER: this.usersForm.get('mobileNumber')?.value,
        ROLE_ID: this.usersForm.get('roleID')?.value,
        ROLE_NAME: this.usersForm.get('roleName')?.value,
        ISS_CD: this.usersForm.get('issueCode')?.value,
        ISS_NAME: this.usersForm.get('issueName')?.value,
        ISS_FIELD: this.usersForm.get('issueField')?.value,
        EMPLOYEE_NO: this.usersForm.get('employeeNo')?.value,
        REN_NOTICE_TAG: this.usersForm.get('renNoticeTag')?.value,
        REFERROR_CD: this.usersForm.get('refCode')?.value,
        REFERROR_DESC: this.usersForm.get('refDesc')?.value,
        SRC_CD: this.usersForm.get('srcCode')?.value,
        SRC_CD_DESC: this.usersForm.get('srcCodeDesc')?.value,
        SRC_EXT_CD: this.usersForm.get('srcExt')?.value,
        SRC_EXT_DESC: this.usersForm.get('srcExtDesc')?.value,
        EFF_DATE: this.datePipe.transform(new Date(this.usersForm.get('effDate')?.value), 'yyyy-MM-dd'),
        EXPIRY_DATE: this.usersForm.get('expiryDate')?.value=='' || this.usersForm.get('expiryDate')?.value==null ? null : this.datePipe.transform(new Date(this.usersForm.get('expiryDate')?.value), 'yyyy-MM-dd'),
        REMARKS: this.usersForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.usersForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        LAST_USER: this.usersForm.get('lastUpdateName')?.value,
        MODE: 'I'
      }
      this.tblConfigEmp.tblData.unshift(formToData);
      this.tblConfigEmp.tblData = [...this.tblConfigEmp.tblData];
      this.tempAddedData.push(formToData);
      this.resetFormValues();
      this.appMessageService.showAppMessage("BPIMS details added!", "success");
    }
    catch(e) {
      console.error(e);
    }
  }

  updateEmployee() {
    //this.noLoadingInProcess = false;
    //this.formService.showFormLoader(null, "generate-emp-form", "Please wait ...", null, null);
    try {
      this.tranPosted = true;
      this.validateUpdate();
      if (this.validated) {
        this.updateFormTable();
      }
    }
    catch(e) {
      console.error(e);
    }
  }

  updateFormTable() {
    try {
      let middleInitial : string;
      if (this.usersForm.get('middleName')?.value==null || this.usersForm.get('middleName')?.value=='') {
        middleInitial = '';
      }
      else {
        middleInitial = this.usersForm.get('middleName')?.value.toString();
        middleInitial = ' '.concat(middleInitial.substring(0,1), '. ');
      }

      this.selectedRole.ROLE_ID = this.rolesList.find(
        (x: { rvLowValue: any; }) => x.rvLowValue === this.usersForm.get('roleID')?.value).rvLowValue;
      this.selectedRole.ROLE_NAME = this.rolesList.find(
        (x: { rvLowValue: any; }) => x.rvLowValue === this.usersForm.get('roleID')?.value).rvMeaning;
      this.usersForm.get('roleID')
        ?.setValue(this.selectedRole==null || this.selectedRole=='' ? '' : this.selectedRole.ROLE_ID);
      this.usersForm.get('roleName')
        ?.setValue(this.selectedRole==null || this.selectedRole=='' ? '' : this.selectedRole.ROLE_NAME);

      let tempInsertChecker = this.tempAddedData.find(
        (user: { USER_ID: any; }) => user.USER_ID == this.usersForm.get('userID')?.value);
      let tempUpdateChecker = this.tempUpdateData.find(
        (user: { USER_ID: any; }) => user.USER_ID == this.usersForm.get('userID')?.value);
      
      this.tableDataUpdate();
        
      // Adds Updated Data that exists in the database to an object array for updated datas
      if(tempInsertChecker!=null && tempInsertChecker!='') {
        //this.tempUpdateData.push(this.formValueToData());
        tempInsertChecker.USER_ID = this.usersForm.get('userID')?.value.toUpperCase();
        tempInsertChecker.ACTIVE_TAG = this.usersForm.get('activeTag')?.value;
        tempInsertChecker.FIRST_NAME = this.usersForm.get('firstName')?.value;
        tempInsertChecker.MIDDLE_NAME = this.usersForm.get('middleName')?.value==null ? null : this.usersForm.get('middleName')?.value.trim();
        tempInsertChecker.LAST_NAME = this.usersForm.get('lastName')?.value;
        tempInsertChecker.FULL_NAME = this.usersForm.get('firstName')?.value.concat(middleInitial,' ',this.usersForm.get('lastName')?.value).toUpperCase();
        tempInsertChecker.EMAIL_ADDRESS = this.usersForm.get('emailAdd')?.value;
        tempInsertChecker.MOBILE_NUMBER = this.usersForm.get('mobileNumber')?.value;
        tempInsertChecker.ROLE_ID = this.usersForm.get('roleID')?.value;
        tempInsertChecker.ROLE_NAME = this.usersForm.get('roleName')?.value,
        tempInsertChecker.ISS_CD = this.usersForm.get('issueCode')?.value;
        tempInsertChecker.ISS_NAME = this.usersForm.get('issueName')?.value;
        tempInsertChecker.ISS_FIELD = this.usersForm.get('issueField')?.value;
        tempInsertChecker.EMPLOYEE_NO = this.usersForm.get('employeeNo')?.value;
        tempInsertChecker.REN_NOTICE_TAG = this.usersForm.get('renNoticeTag')?.value;
        tempInsertChecker.REFERROR_CD = this.usersForm.get('refCode')?.value;
        tempInsertChecker.REFERROR_DESC = this.usersForm.get('refDesc')?.value;
        tempInsertChecker.SRC_CD = this.usersForm.get('srcCode')?.value;
        tempInsertChecker.SRC_CD_DESC = this.usersForm.get('srcCodeDesc')?.value;
        tempInsertChecker.SRC_EXT_CD = this.usersForm.get('srcExt')?.value;
        tempInsertChecker.SRC_EXT_DESC = this.usersForm.get('srcExtDesc')?.value;
        tempInsertChecker.EFF_DATE = this.datePipe.transform(new Date(this.usersForm.get('effDate')?.value), 'yyyy-MM-dd');
        tempInsertChecker.EXPIRY_DATE = this.usersForm.get('expiryDate')?.value=='' || this.usersForm.get('expiryDate')?.value==null ? null : this.datePipe.transform(new Date(this.usersForm.get('expiryDate')?.value), 'yyyy-MM-dd');
        tempInsertChecker.REMARKS = this.usersForm.get('remarks')?.value;
        tempInsertChecker.LAST_USER_UPDATE = this.datePipe.transform(new Date(this.usersForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a');
        //tempInsertChecker.LAST_USER = this.usersForm.get('lastUpdateName')?.value;
        tempInsertChecker.MODE = 'I';
      } 
      else if(tempUpdateChecker!=null && tempUpdateChecker!='') {
        tempUpdateChecker.USER_ID = this.usersForm.get('userID')?.value.toUpperCase();
        tempUpdateChecker.ACTIVE_TAG = this.usersForm.get('activeTag')?.value;
        tempUpdateChecker.FIRST_NAME = this.usersForm.get('firstName')?.value;
        tempUpdateChecker.MIDDLE_NAME = this.usersForm.get('middleName')?.value==null ? null : this.usersForm.get('middleName')?.value.trim();
        tempUpdateChecker.LAST_NAME = this.usersForm.get('lastName')?.value;
        tempUpdateChecker.FULL_NAME = this.usersForm.get('firstName')?.value.concat(middleInitial,' ',this.usersForm.get('lastName')?.value).toUpperCase();
        tempUpdateChecker.EMAIL_ADDRESS = this.usersForm.get('emailAdd')?.value;
        tempUpdateChecker.MOBILE_NUMBER = this.usersForm.get('mobileNumber')?.value;
        tempUpdateChecker.ROLE_ID = this.usersForm.get('roleID')?.value;
        tempUpdateChecker.ROLE_NAME = this.usersForm.get('roleName')?.value,
        tempUpdateChecker.ISS_CD = this.usersForm.get('issueCode')?.value;
        tempUpdateChecker.ISS_NAME = this.usersForm.get('issueName')?.value;
        tempUpdateChecker.ISS_FIELD = this.usersForm.get('issueField')?.value;
        tempUpdateChecker.EMPLOYEE_NO = this.usersForm.get('employeeNo')?.value;
        tempUpdateChecker.REN_NOTICE_TAG = this.usersForm.get('renNoticeTag')?.value;
        tempUpdateChecker.REFERROR_CD = this.usersForm.get('refCode')?.value;
        tempUpdateChecker.REFERROR_DESC = this.usersForm.get('refDesc')?.value;
        tempUpdateChecker.SRC_CD = this.usersForm.get('srcCode')?.value;
        tempUpdateChecker.SRC_CD_DESC = this.usersForm.get('srcCodeDesc')?.value;
        tempUpdateChecker.SRC_EXT_CD = this.usersForm.get('srcExt')?.value;
        tempUpdateChecker.SRC_EXT_DESC = this.usersForm.get('srcExtDesc')?.value;
        tempUpdateChecker.EFF_DATE = this.datePipe.transform(new Date(this.usersForm.get('effDate')?.value), 'yyyy-MM-dd');
        tempUpdateChecker.EXPIRY_DATE = this.usersForm.get('expiryDate')?.value=='' || this.usersForm.get('expiryDate')?.value==null ? null : this.datePipe.transform(new Date(this.usersForm.get('expiryDate')?.value), 'yyyy-MM-dd');
        tempUpdateChecker.REMARKS = this.usersForm.get('remarks')?.value;
        tempUpdateChecker.LAST_USER_UPDATE = this.datePipe.transform(new Date(this.usersForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a');
        //tempUpdateChecker.LAST_USER = this.usersForm.get('lastUpdateName')?.value;
        tempUpdateChecker.MODE = 'U';
        //let tempUpdateChecker = this.tempUpdateData.find(
        //  (user: { USER_ID: any; }) => user.USER_ID == this.usersForm.get('userID')?.value);
      }
      else {
        this.tempUpdateData.push(this.formValueToData());
      }
      this.saveDisabled = false;
      this.resetFormValues();
      this.appMessageService.showAppMessage("BPIMS details updated!", "success");
    }
    catch(e) {
      console.error(e);
    }
  }

  tableDataUpdate() {
    try {
      let middleInitial : string;
      if (this.usersForm.get('middleName')?.value==null || this.usersForm.get('middleName')?.value=='') {
        middleInitial = '';
      }
      else {
        middleInitial = this.usersForm.get('middleName')?.value.toString();
        middleInitial = ' '.concat(middleInitial.substring(0,1), '. ');
      }
      this.usersForm.patchValue({
        activeTag: this.usersForm.get('activeTag')?.value,
        roleID: this.usersForm.get('roleID')?.value,
        roleName:  this.usersForm.get('roleName')?.value,//this.selectedRole.ROLE_NAME,
        lastUpdateName: this.currentUser,
        lastUserUpdate: new Date(),
        issueCode: this.usersForm.get('issueCode')?.value,
        issueName: this.usersForm.get('issueName')?.value,
        issueField: this.usersForm.get('issueField')?.value
      });
      let updateIndex = this.tblConfigEmp.tblData.indexOf(this.rowClickedData);
      this.tblConfigEmp.tblData[updateIndex] = {
        USER_ID: this.usersForm.get('userID')?.value.toUpperCase(),
        ACTIVE_TAG: this.usersForm.get('activeTag')?.value,
        FIRST_NAME: this.usersForm.get('firstName')?.value,
        MIDDLE_NAME: this.usersForm.get('middleName')?.value==null ? null : this.usersForm.get('middleName')?.value.trim(),
        LAST_NAME: this.usersForm.get('lastName')?.value,
        FULL_NAME: this.usersForm.get('firstName')?.value.concat(middleInitial,' ',this.usersForm.get('lastName')?.value).toUpperCase(),
        EMAIL_ADDRESS: this.usersForm.get('emailAdd')?.value,
        MOBILE_NUMBER: this.usersForm.get('mobileNumber')?.value,
        ROLE_ID: this.usersForm.get('roleID')?.value,
        ROLE_NAME: this.usersForm.get('roleName')?.value,
        ISS_CD: this.usersForm.get('issueCode')?.value,
        ISS_NAME: this.usersForm.get('issueName')?.value,
        ISS_FIELD: this.usersForm.get('issueField')?.value,
        EMPLOYEE_NO: this.usersForm.get('employeeNo')?.value,
        REN_NOTICE_TAG: this.usersForm.get('renNoticeTag')?.value,
        REFERROR_CD: this.usersForm.get('refCode')?.value,
        REFERROR_DESC: this.usersForm.get('refDesc')?.value,
        SRC_CD: this.usersForm.get('srcCode')?.value,
        SRC_CD_DESC: this.usersForm.get('srcCodeDesc')?.value,
        SRC_EXT_CD: this.usersForm.get('srcExt')?.value,
        SRC_EXT_DESC: this.usersForm.get('srcExtDesc')?.value,
        EFF_DATE: this.datePipe.transform(new Date(this.usersForm.get('effDate')?.value), 'yyyy-MM-dd'),
        EXPIRY_DATE: this.usersForm.get('expiryDate')?.value=='' || this.usersForm.get('expiryDate')?.value==null ? null : this.datePipe.transform(new Date(this.usersForm.get('expiryDate')?.value), 'yyyy-MM-dd'),
        REMARKS: this.usersForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.usersForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        LAST_USER: this.usersForm.get('lastUpdateName')?.value,
        MODE: 'U'
      };
      this.tblConfigEmp.tblData = [...this.tblConfigEmp.tblData];
    }
    catch(e) {
      console.error(e);
    }
  }

  formValueToData() {
    try {
      let middleInitial : string;
      if (this.usersForm.get('middleName')?.value==null || this.usersForm.get('middleName')?.value=='') {
        middleInitial = '';
      }
      else {
        middleInitial = this.usersForm.get('middleName')?.value.toString();
        middleInitial = ' '.concat(middleInitial.substring(0,1), '. ');
      }
      let formToData: any = { // this.datePipe.transform(new Date(), 'MM/dd/yyyy')
        USER_ID: this.usersForm.get('userID')?.value.toUpperCase(),
        ACTIVE_TAG: this.usersForm.get('activeTag')?.value,
        FIRST_NAME: this.usersForm.get('firstName')?.value,
        MIDDLE_NAME: this.usersForm.get('middleName')?.value==null ? null : this.usersForm.get('middleName')?.value.trim(),
        LAST_NAME: this.usersForm.get('lastName')?.value,
        FULL_NAME: this.usersForm.get('firstName')?.value.concat(middleInitial,' ',this.usersForm.get('lastName')?.value).toUpperCase(),
        EMAIL_ADDRESS: this.usersForm.get('emailAdd')?.value,
        MOBILE_NUMBER: this.usersForm.get('mobileNumber')?.value,
        ROLE_ID: this.usersForm.get('roleID')?.value,
        ROLE_NAME: this.usersForm.get('roleName')?.value,
        ISS_CD: this.usersForm.get('issueCode')?.value,
        ISS_NAME: this.usersForm.get('issueName')?.value,
        ISS_FIELD: this.usersForm.get('issueField')?.value,
        EMPLOYEE_NO: this.usersForm.get('employeeNo')?.value,
        REN_NOTICE_TAG: this.usersForm.get('renNoticeTag')?.value,
        REFERROR_CD: this.usersForm.get('refCode')?.value,
        REFERROR_DESC: this.usersForm.get('refDesc')?.value,
        SRC_CD: this.usersForm.get('srcCode')?.value,
        SRC_CD_DESC: this.usersForm.get('srcCodeDesc')?.value,
        SRC_EXT_CD: this.usersForm.get('srcExt')?.value,
        SRC_EXT_DESC: this.usersForm.get('srcExtDesc')?.value,
        EFF_DATE: this.datePipe.transform(new Date(this.usersForm.get('effDate')?.value), 'yyyy-MM-dd'),
        EXPIRY_DATE: this.usersForm.get('expiryDate')?.value=='' || this.usersForm.get('expiryDate')?.value==null ? null : this.datePipe.transform(new Date(this.usersForm.get('expiryDate')?.value), 'yyyy-MM-dd'),
        REMARKS: this.usersForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.usersForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        LAST_USER: this.usersForm.get('lastUpdateName')?.value,
        MODE: 'U'
      }
      return formToData;
    }
    catch(e) {
      console.error(e);
    }
  }

  deleteEmployee() {
    try {
      let clickData = this.rowClickedData;
      this.tblConfigEmp.tblData = this.tblConfigEmp.tblData.filter(
        function(e: any) {
          return e.USER_ID != clickData.USER_ID;
        }
      );
      this.tempAddedData = this.tempAddedData.filter(
        function(e: any) {
          return e.USER_ID != clickData.USER_ID;
        }
      );
      this.resetFormValues();
    }
    catch(e) {
      console.error(e);
    }
  }

  deleteChecker(ev: any) {
    try {
      let tempDataFlag;
      tempDataFlag = this.tempAddedData.filter(function(e: any) {
        return e.USER_ID==ev.USER_ID;
      });
      if(tempDataFlag==''||tempDataFlag==null) {
        return true;
      } else {
        return false;
      }
    }
    catch(e) {
      console.error(e);
      return true;
    }
  }

  saveChecker() {
    try {
      if((this.tempAddedData==null || this.tempAddedData=='') && (this.tempUpdateData==null || this.tempUpdateData=='')) {
        return true;
      }
      else {
        return false;
      }
    }
    catch(e) {
      console.error(e);
      return true;
    }
  }

  saveAddedData () {
    this._APICallService.saveEmployee(this.tempAddedData)
        .subscribe({
          next: (response : any) => {
                this.securityService.checkRequestKeyResponse(response, () => {
                  this.securityService.hasValidCsrfToken(response, ()=>{
                    if (response.status == "SUCCESS" && this.tranPosted) {
                      if(this.tempUpdateData.length!=0) {
                          this.saveUpdateData();
                          //*/
                      } else {
                          this.openDialog('SUCCESS', 'New employee details Successfully saved!');
                          this.isSuccessSaved = true;
                          //this.initOnGet();
                          this.getAllUsers();
                          //this.getAllUserID();
                          this.resetFormValues();
                          this.tempAddedData = [];
                          this.tempUpdateData = [];
                          this.saveDisabled = this.saveChecker();
                      }
                    } else {
                      this.openDialog('ERROR', 'An error has occured while saving details for new user');
                    }
                  });
                });
          }, 
          error: () => {
            this.submitted = false;
            this.isSuccessSaved = false;
            this.openDialog('ERROR', 'An error has occured while saving details for new user');
          }
        });
  }

  saveUpdateData() {
    this._APICallService.saveEmployee(this.tempUpdateData)
    .subscribe({
      next: (response : any) => {
            this.securityService.checkRequestKeyResponse(response, () => {
              this.securityService.hasValidCsrfToken(response, ()=>{
                if (response.status == "SUCCESS" && this.tranPosted) {
                  this.openDialog('SUCCESS', 'Existing employee details successfully saved!');
                  this.isSuccessSaved = true;
                  //this.initOnGet();
                  this.getAllUsers();
                  this.resetFormValues();
                  this.tempAddedData = [];
                  this.tempUpdateData = [];
                  this.saveDisabled = this.saveChecker();
              //this.getAllUserID();
                } else {
                  this.openDialog('ERROR', 'An error has occured while saving details for existing user');
                }
              });

            });
      }, 
      error: () => {
        this.submitted = false;
        this.isSuccessSaved = false;
        this.openDialog('ERROR', 'An error has occured while saving details for existing user');
      }
    });
  }

  toSave() {
    try {
      this.noLoadingInProcess = false;
      this.formService.showFormLoader(null, "generate-emp-form", "Please wait ...", null, null);
      if(this.tempAddedData.length!=0) {
        ///*
        this.saveAddedData();
        //*/
      } else {
        if(this.tempUpdateData.length!=0) {
            this.saveUpdateData();
            //*/
        } else {
            this.resetFormValues();
            this.tempAddedData = [];
            this.tempUpdateData = [];
            this.saveDisabled = this.saveChecker();
        }
      }
    }
    catch(e) {
      console.error(e);
    }
  }

  dateFilter(dateString: any) {
    try {
      if (dateString=='' || dateString==null) {
        return '';
      }
      else if (dateString=='0000-00-00' || dateString=='0000-00-00 12:00:00 AM' || dateString=='1970-01-01') {
        return '';
      }
      else {
        return formatDate(dateString, 'yyyy-MM-dd', 'en');
      }
    }
    catch(e) {
      return '';
    }
  }

  onRowClick(ev: any) {
    try {    
      if(ev!=null) {
        this.rowClickedData = ev;
        this.usersForm.get('userID')?.disable();
        this.loadFormValues();
        this.saveDisabled = this.saveChecker();
        this.deleteDisabled = this.deleteChecker(ev);
      }
      else {
        this.resetFormValues();
      }
    }
    catch(e) {
      console.error(e);
    }
  }

  loadFormValues() {
    try {
      this.usersForm.patchValue({
        userID: this.rowClickedData.USER_ID,
        activeTag: this.rowClickedData.ACTIVE_TAG,
        firstName: this.rowClickedData.FIRST_NAME,
        middleName: this.rowClickedData.MIDDLE_NAME,
        lastName: this.rowClickedData.LAST_NAME,
        fullName: this.rowClickedData.FULL_NAME,
        emailAdd: this.rowClickedData.EMAIL_ADDRESS,
        mobileNumber: this.rowClickedData.MOBILE_NUMBER,
        issueCode: this.rowClickedData.ISS_CD,
        issueName: this.rowClickedData.ISS_NAME,
        issueField: this.rowClickedData.ISS_FIELD,
        roleID: this.rowClickedData.ROLE_ID,
        roleName: this.rowClickedData.ROLE_NAME,
        employeeNo: this.rowClickedData.EMPLOYEE_NO,
        renNoticeTag: this.rowClickedData.REN_NOTICE_TAG,
        refCode: this.rowClickedData.REFERROR_CD, 
        refDesc: this.rowClickedData.REFERROR_DESC,
        srcCode: this.rowClickedData.SRC_CD,
        srcCodeDesc: this.rowClickedData.SRC_CD_DESC,
        srcExt: this.rowClickedData.SRC_EXT_CD,
        srcExtDesc: this.rowClickedData.SRC_EXT_DESC,
        effDate: this.dateFilter(this.rowClickedData.EFF_DATE),
        expiryDate: this.dateFilter(this.rowClickedData.EXPIRY_DATE),
        remarks: this.rowClickedData.REMARKS,
        lastUpdateName: this.rowClickedData.LAST_USER,   
        lastUserUpdate: this.rowClickedData.LAST_USER_UPDATE
      });
      this.selectedRole.ROLE_ID = this.rowClickedData.ROLE_ID;
      this.selectedRole.ROLE_NAME = this.rowClickedData.ROLE_NAME;
      this.userIDReadOnly = true;
      this.isEmpSelected = true;
    }
    catch (e) {
    console.error(e);

    }
  }

  resetFormValues() {
    try {
      this.usersForm.get('userID')?.enable();
      this.usersForm.patchValue({
        userID: null,
        activeTag: 'A',
        firstName: null,
        middleName: null,
        lastName: null,
        fullName: null,
        emailAdd: null,
        mobileNumber: null,
        issueCode: null,
        issueName: null,
        issueField: null,
        roleID: null,
        roleName: null,
        roleAccess: null,
        roleApproval: null,
        employeeNo: null,
        renNoticeTag: 'N',
        refCode: null,
        refDesc: null,
        srcCode: null,
        srcCodeDesc: null,
        srcExt: null,
        srcExtDesc: null,
        effDate: new Date(),
        expiryDate: null,
        remarks: null,
        lastUpdateName: null,
        lastUserUpdate: null
      });
      this.selectedRole.ROLE_ID = null;
      this.selectedRole.ROLE_NAME = null;
      this.selectedIss.ISS_CD = null;
      this.selectedIss.ISS_NAME = null;
      this.selectedIss.ISS_FIELD = null;
      this.selectedRef.REFERROR_CD = null;
      this.selectedRef.REFERROR_DESC = null;
      this.selectedSrcCd.SRC_CD = null;
      this.selectedSrcCd.SRC_CD_DESC = null;
      this.selectedSrcExt.SRC_EXT_CD = null;
      this.selectedSrcExt.SRC_EXT_DESC = null;
      this.saveDisabled = this.saveChecker();
      this.deleteDisabled = true;
      this.userIDReadOnly = false;
      this.isEmpSelected = false;
      this.rowClickedData = '' || null;
      this.formService.hideFormMsg("bpims-error-message");
    }
    catch (e) {
    console.error(e);

    }
  }

  resetUserPw() {
    try {
      let resetPWAcct: any = {
        userId: '', 
        isAdmin: '',
        userIdAdmin: ''
      };
      resetPWAcct.userId = this.rowClickedData.USER_ID;
      resetPWAcct.isAdmin = 'N'
      resetPWAcct.userIdAdmin = this.currentUser;
      this.dialogBoxService.open({
        messageType: "C",
        content: "Are you sure you want to reset the password of User " + resetPWAcct.userId + "?",
        buttons: [
          { text: "No" },
          { text: "Yes", action: () => {
            this._APICallService.resetUserPw(resetPWAcct).subscribe((res: any) => {
                this.securityService.checkRequestKeyResponse(res, () => {
                  this.securityService.hasValidCsrfToken(res, ()=>{
                    res = JSON.parse(this.jsonDataService.decrypt(res.response));
                    if (res.status == "SUCCESS") {
                      this.openDialog('SUCCESS', 'Password reset successful!');
                    } else {
                      this.openDialog('FAILED', 'Password reset was not successful. Please contact your administrator.');
                    }
                  });
                });
            });
          }}
        ]
      }); 
    }
    catch (e) {
    console.error(e);

    }
  }

  openIssDialog() {
    try {
      const dialogIss = this._Dialog.open(IssSrcComponent, { disableClose: true, width: '512px', data: {moduleId: this.moduleId}});
      dialogIss.afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedIss = data.content;
          this.usersForm.get('issueCode')
              ?.setValue(this.selectedIss==null || this.selectedIss=='' ? '' : this.selectedIss.ISS_CD);
          this.usersForm.get('issueName')
              ?.setValue(this.selectedIss==null || this.selectedIss=='' ? '' : this.selectedIss.ISS_NAME);
          this.usersForm.get('issueField')
              ?.setValue(this.selectedIss==null || this.selectedIss=='' ? '' : this.selectedIss.ISS_CD + " - " + this.selectedIss.ISS_NAME);
        }
      });
    }
    catch (e) {
    console.error(e);

    }
  }

  openRefDialog() {
    try {
      const dialogSrcCode = this._Dialog.open(RefLovComponent, { disableClose: true, width: '700px', data: {moduleId: this.moduleId}});
      dialogSrcCode.afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedRef = data.content,
          this.usersForm.get('refCode')
              ?.setValue(this.selectedRef==null || this.selectedRef=='' ? '' : this.selectedRef.REFERROR_CD);
          this.usersForm.get('refDesc')
              ?.setValue(this.selectedRef==null || this.selectedRef=='' ? '' : this.selectedRef.REFERROR_DESC);
        }
      });
    }
    catch (e) {
    console.error(e);

    }
  }

  openSrcCodeDialog() {
    try {
      const dialogSrcCode = this._Dialog.open(SrcCdLovComponent, { disableClose: true, width: '700px', data: {moduleId: this.moduleId}});
      dialogSrcCode.afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedSrcCd = data.content,
          this.usersForm.get('srcCode')
              ?.setValue(this.selectedSrcCd==null || this.selectedSrcCd=='' ? '' : this.selectedSrcCd.SRC_CD);
          this.usersForm.get('srcCodeDesc')
              ?.setValue(this.selectedSrcCd==null || this.selectedSrcCd=='' ? '' : this.selectedSrcCd.SRC_CD_DESC);
        }
      });
    }
    catch (e) {
    console.error(e);

    }
  }

  openSrcExtDialog() {
    try {
      const dialogSrcExt = this._Dialog.open(SrcExtLovComponent, { disableClose: true, width: '700px', data: {moduleId: this.moduleId}});
      dialogSrcExt.afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedSrcExt = data.content;
          this.usersForm.get('srcExt')
              ?.setValue(this.selectedSrcExt==null || this.selectedSrcExt=='' ? '' : this.selectedSrcExt.SRC_EXT_CD);
          this.usersForm.get('srcExtDesc')
              ?.setValue(this.selectedSrcExt==null || this.selectedSrcExt=='' ? '' : this.selectedSrcExt.SRC_EXT_DESC);
        }
      });
    }
    catch (e) {
    console.error(e);

    }
  }

  openDialog(title: any, message: any) {
    try {
      this.noLoadingInProcess = true;
      this.formService.hideFormLoader("generate-emp-form");
      this._Dialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        } 
      });
    } catch(e) {
      console.error(e);
    }
  }

  validateInsert() {
    try {
      this.validated = false;
      if (!this.effDateCheck()) {
        setTimeout(()=>{                         
          this.formService.hideFormMsg("bpims-error-message");
        }, 10000);
      }
      else if (!this.requiredFieldCheck()){
        setTimeout(()=>{                           //10 secs countdown
          this.formService.hideFormMsg("bpims-error-message");
        }, 10000);
      }
      else if (!this.userIDDupCheck()) {
        setTimeout(()=>{                           
          this.formService.hideFormMsg("bpims-error-message");
        }, 10000);
      }
      ///*
      else if (!this.userIDDupCheckAll()) {
        setTimeout(()=>{                           
          this.formService.hideFormMsg("bpims-error-message");
        }, 10000);
      }
      //*/
      else if (!this.emailFormat()) {
        setTimeout(()=>{                         
          this.formService.hideFormMsg("bpims-error-message");
        }, 10000);
      }
      else if (!this.empNumFormat()) {
        setTimeout(()=>{                         
          this.formService.hideFormMsg("bpims-error-message");
        }, 10000);
      }
      else if (!this.expiryDateCheck()) {
        setTimeout(()=>{                         
          this.formService.hideFormMsg("bpims-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  validateUpdate() {
    try {
      this.validated = false;
      if (!this.effDateCheck()) {
        setTimeout(()=>{                         
          this.formService.hideFormMsg("bpims-error-message");
        }, 10000);
      }
      else if (!this.requiredFieldCheck()){
        setTimeout(()=>{                          
          this.formService.hideFormMsg("bpims-error-message");
        }, 10000);
      }
      else if (!this.emailFormat()) {
        setTimeout(()=>{  
          this.emailFormatError = false;                         
          this.formService.hideFormMsg("bpims-error-message");
        }, 10000);
      }
      else if (!this.empNumFormat()) {
        setTimeout(()=>{  
          this.empNumError = false;                       
          this.formService.hideFormMsg("bpims-error-message");
        }, 10000);
      }
      else if (!this.expiryDateCheck()) {
        setTimeout(()=>{                         
          this.formService.hideFormMsg("bpims-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  userIDDupCheck() {
    try {
      let userIDForm = this.usersForm.get('userID')?.value?.trim().toUpperCase() ?? "";
      this.userIDField = this.tblConfigEmp.tblData.filter(function(e: any) {
        return e.USER_ID?.trim().toUpperCase()==userIDForm;
      });
      if(this.userIDField=='' || this.userIDField==null) {
        return true;
      } else {
        this.formService.showFormMsg("bpims-error-message", 'User ID already exists. Please add a unique User ID.', "E");
        return false;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }  

  userIDDupCheckAll() {
    try {
        let userIDCheckerAll = this.userIDList.find(
          (user: { USER_ID: any; }) => user.USER_ID?.trim().toUpperCase() == this.usersForm.get('userID')?.value?.trim().toUpperCase());
          
        if(userIDCheckerAll==null || userIDCheckerAll=='') {
          return true;
        }
        else { 
          this.formService.showFormMsg("bpims-error-message", 'User ID already exists. Please add a unique User ID.', "E");
          return false;
        }
    }
    catch (e) {
    console.error(e);
      return false;
    }
    
  }

  requiredFieldCheck() {
    try {
      if(this.usersForm.get('userID')?.value?.trim()=='' || this.usersForm.get('userID')?.value==null 
        || this.usersForm.get('activeTag')?.value=='' || this.usersForm.get('activeTag')?.value==null 
        || this.usersForm.get('firstName')?.value?.trim()=='' || this.usersForm.get('firstName')?.value==null
        || this.usersForm.get('lastName')?.value?.trim()=='' || this.usersForm.get('lastName')?.value==null
        || this.usersForm.get('emailAdd')?.value?.trim()=='' || this.usersForm.get('emailAdd')?.value==null
        || this.usersForm.get('roleID')?.value=='' || this.usersForm.get('roleID')?.value==null
        || this.usersForm.get('issueCode')?.value=='' || this.usersForm.get('issueCode')?.value==null
        || this.usersForm.get('employeeNo')?.value?.trim()=='' || this.usersForm.get('employeeNo')?.value==null
        || this.usersForm.get('effDate')?.value=='' || this.usersForm.get('effDate')?.value==null
        || this.usersForm.get('mobileNumber')?.value=='' || this.usersForm.get('mobileNumber')?.value==null
        || this.usersForm.get('renNoticeTag')?.value=='' || this.usersForm.get('renNoticeTag')?.value==null) {
        this.formService.showFormMsg("bpims-error-message", 'There are missing information. Please provide the necessary information needed.', "E");
        return false;
      }
      else {
        return true;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }

  emailFormat() {
    try {
      this.emailFormatError = false;
      if(this.usersForm.invalid) {
        for(const field in this.usersForm.controls) {
          const control = this.usersForm.get(field)?.validator; //Checks if a field has a validator
          const controlName = this.usersForm.get(field); //Use to use specific field

          // Validator checker
          if(controlName?.errors?.pattern) {
            if(field=='emailAdd') {
              this.emailFormatError = true;
              this.formService.showFormMsg("bpims-error-message", 'Email address is invalid. Kindly provide a valid email address.', "E");
            } 
          }
        }
      } 
      if (this.emailFormatError) {
        return false;
      }
      else {
        return true;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }

  empNumFormat() {
    try {
      this.empNumError = false;
      if(this.usersForm.invalid) {
        for(const field in this.usersForm.controls) {
          const control = this.usersForm.get(field)?.validator; //Checks if a field has a validator
          const controlName = this.usersForm.get(field); //Use to use specific field

          // Validator checker
          if(controlName?.errors?.pattern) {
            if(field=='employeeNo') {
              this.empNumError = true;
              this.formService.showFormMsg("bpims-error-message", 'Employee No. is invalid. Kindly use numeric characters only.', "E");
            } 
          }
        }
      } 
      if (this.empNumError) {
        return false;
      }
      else {
        return true;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
    
  }

  effDateCheck() {
    try {
      if (this.usersForm.get('effDate')?.value!='' && this.usersForm.get('effDate')?.value!=null) {
        /*
        if (formatDate(this.usersForm.get('effDate')?.value, 'yyyy-MM-dd', 'en') < formatDate(this.usersForm.get('effDate')?.value, 'yyyy-MM-dd', 'en')) {
          this.expDateError = true;
          this.formService.showFormMsg("bpims-error-message", 'Expiry date is past effectivity date.', "E");
          return false;
        }
        else {
          return true;
        }
        */
        return true;

      }
      else if (this.datePipe.transform(new Date(this.usersForm.get('effDate')?.value), 'yyyy-MM-dd') == null
        || this.datePipe.transform(new Date(this.usersForm.get('effDate')?.value), 'yyyy-MM-dd') == '1970-01-01') {
        this.formService.showFormMsg("bpims-error-message", 'Kindly input effectivity date as MM/DD/YYYY', "E");
        return false;
      }
      else {
        return true;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }

  expiryDateCheck() {
    try {
      if (this.usersForm.get('expiryDate')?.value!='' && this.usersForm.get('expiryDate')?.value!=null) {
        if (formatDate(this.usersForm.get('expiryDate')?.value, 'yyyy-MM-dd', 'en') < formatDate(this.usersForm.get('effDate')?.value, 'yyyy-MM-dd', 'en')) {
          this.formService.showFormMsg("bpims-error-message", 'Expiry date is past effectivity date.', "E");
          return false;
        }
        else {
          return true;
        }
      }
      else {
        return true;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }

  checkNameLength(fieldName:string){
    try {
      if(this.usersForm.get(fieldName)?.value!=='' && this.usersForm.get(fieldName)?.value!==null) {
        let trimmedName = this.usersForm.get(fieldName)?.value?.trim();
        if (fieldName == 'firstName' && trimmedName.length > 20) {
          this.usersForm.get(fieldName)?.setValue(trimmedName.substring(0, 20));
        } else if (fieldName == 'middleName' && trimmedName.length > 30) {
          this.usersForm.get(fieldName)?.setValue(trimmedName.substring(0, 30));
        } else if (fieldName == 'lastName' && trimmedName.length > 30) {
          this.usersForm.get(fieldName)?.setValue(trimmedName.substring(0, 30));
        } else {
          this.usersForm.get(fieldName)?.setValue(trimmedName);
        }
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  numOnlyPattern(ev: any) {
    try{
      const pattern = /^[0-9]\d*$/;   /^[0-9]\d*$/
      //let inputChar = String.fromCharCode(event.charCode)
      if (!pattern.test(ev.target.value)) {
        ev.target.value = ev.target.value.replace(/[^0-9]/g, "");
        // invalid character, prevent input
      }
    }
    catch(e) {
      console.error(e);
    }
  }

  datePattern(ev: any) {
    try{
      //let inputChar = String.fromCharCode(event.charCode)
      if (ev.target.value.length<4) {
        let firstPattern = /^\d{2}\/$/;
        if(!ev.target.value.match(firstPattern)){
          ev.target.value = ev.target.value.substring(0,2);
        }
      } else if(ev.target.value.length<7){
        let firstPattern = /^\d{2}\/\d{2}\/$/;
        if(!ev.target.value.match(firstPattern)){
          ev.target.value = ev.target.value.substring(0,5);
        }
      } else if(ev.target.value.length>6){
        let firstPattern = /^\d{2}\/\d{2}\/\d+$/;
        if(!ev.target.value.match(firstPattern)){
          ev.target.value = ev.target.value.substring(0,ev.target.value.length-1);
        }
      }
    }
    catch(e) {
      console.error(e);
    }
  }
  autoUppercase(ev: any) {
    try{
      ev.target.value = ev.target.value.toUpperCase();
    }
    catch(e) {
      console.error(e);
    }
  }
}