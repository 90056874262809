import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { DatePipe } from '@angular/common';
import { forkJoin, Observable } from 'rxjs';
import { LeaveConfirmation } from '../../common/guard/check.guard';

@Component({
  selector: 'issue-source-maintenance',
  templateUrl: './issue-source-maintenance.component.html',
  styleUrls: ['./issue-source-maintenance.component.css']
})
export class IssueSourceMaintenanceComponent implements OnInit, LeaveConfirmation {

  selectedRowComponent: any;
  addUpdateFlag: boolean = true;
  tempNewData: any = [];
  tempDbData: any = [];
  deleteDisabler: boolean = true;
  saveDisabler: boolean = true;
  errorMessage: string = '';
  userId = this.jsonDataService.retrieveFromStorage('userId');
  formHider: boolean = false;
  datePipe = new DatePipe('en-ph');
  moduleId: string = 'BMM022';
  rpaStaffList: any = [];
  public moduleName: string = this.jsonDataService.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc?.toUpperCase() ?? "";

  issueSourceTable: any = {
    cols: [
      {
        key: "ISS_CD",
        header: "Issue Source",
        dataType: "string"
      },
      {
        key: "ISS_NAME",
        header: "Issue Name",
        dataType: "string"
      },
      {
        key: "COMPLETE_ADDRESS",
        header: "Address",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "Active",
        dataType: "checkbox"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  issForm = this.fb.group({
    ACTIVE_TAG: ['A', [Validators.required]],
    ADDRESS_1: ['', [Validators.required]],
    ADDRESS_2: [''],
    ADDRESS_3: [''],
    BM_CODE: [''],
    BM_SHORT_DESC: [''],
    FAX_NO: ['', [Validators.required]],
    ISS_CD: ['', [Validators.required, this.duplicateIssCode()]],
    ISS_NAME: ['', [Validators.required]],
    LAST_UPDATE: [{value: '', disabled: true}],
    REMARKS: [''],
    RPA_DEPT: [''],
    RPA_STAFF: [''],
    RPA_STAFF_CD: [''],
    TEL_NO: ['', [Validators.required]],
    TIN: ['', [Validators.required, Validators.pattern('[0-9][0-9][0-9]-[0-9][0-9][0-9]-[0-9][0-9][0-9]-[0-9]{3,5}')]],
    USER_ID: [{value: '', disabled: true}],
  });

  constructor(
    private api: APICallService,
    private fb: FormBuilder,
    private formService: FormService,
    private dialog: MatDialog,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService
  ) { }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempNewData!='' || this.tempDbData!='') {
      return false;
    } else {
      return true;
    }
  }

  ngOnInit(): void {
    try {
      this.rpaStaffList = this.jsonDataService.data.rpaStaff;
      this.getIssueSource();
    } catch(e) {

    }
  }

  getIssueSource() {
    try {
      this.issueSourceTable.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.api.getAllIssueSource({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE"
      }).subscribe({
        next: (data: any) => {
            this.securityService.checkRequestKeyResponse(data, () => {
              this.securityService.hasValidCsrfToken(data, ()=>{
                if (data.status === "SUCCESS") {
                  data = JSON.parse(this.jsonDataService.decrypt(data.response));
                  this.jsonDataService.contorlLoading(false);
                  this.issueSourceTable.tblData = data.data;
                  this.issueSourceTable.loading = false;
                } else {
                  this.jsonDataService.contorlLoading(false);
                  this.issueSourceTable.loading = false;
                  this.appMessageService.showAppMessage(data.message, "error");
                }
              });
            });
        },
      });
    } catch(e) {

    }
  }

  onRowClick(ev: any) {
    try {
      this.selectedRowComponent = ev;
      this.buttonToggler('onRowClick');
      this.formLoader();
    } catch(e) {

    }
  }

  formLoader() {
    try {
      if(this.selectedRowComponent==null) {
        this.issForm.get('ISS_CD')?.enable();
        this.issForm.patchValue({
          ACTIVE_TAG: 'A',
          ADDRESS_1: '',
          ADDRESS_2: '',
          ADDRESS_3: '',
          BM_CODE: '',
          BM_SHORT_DESC: '',
          FAX_NO: '',
          ISS_CD: '',
          ISS_NAME: '',
          LAST_UPDATE: '',
          REMARKS: '',
          RPA_DEPT: '',
          RPA_STAFF: '',
          TEL_NO: '',
          TIN: '',
          USER_ID: '',
          RPA_STAFF_CD: '',
        });
      } else {
        this.issForm.get('ISS_CD')?.disable();
        this.issForm.patchValue({
          ACTIVE_TAG: this.selectedRowComponent.ACTIVE_TAG,
          ADDRESS_1: this.selectedRowComponent.ADDRESS_1,
          ADDRESS_2: this.selectedRowComponent.ADDRESS_2,
          ADDRESS_3: this.selectedRowComponent.ADDRESS_3,
          BM_CODE: this.selectedRowComponent.BM_CODE,
          BM_SHORT_DESC: this.selectedRowComponent.BM_SHORT_DESC,
          FAX_NO: this.selectedRowComponent.FAX_NO,
          ISS_CD: this.selectedRowComponent.ISS_CD,
          ISS_NAME: this.selectedRowComponent.ISS_NAME,
          LAST_UPDATE: this.datePipe.transform(this.selectedRowComponent.LAST_UPDATE, 'MM/dd/YYYY h:mm:ss a', '+0000'),
          REMARKS: this.selectedRowComponent.REMARKS,
          RPA_DEPT: this.selectedRowComponent.RPA_DEPT,
          RPA_STAFF: this.selectedRowComponent.RPA_STAFF,
          TEL_NO: this.selectedRowComponent.TEL_NO,
          TIN: this.selectedRowComponent.TIN,
          USER_ID: this.selectedRowComponent.USER_ID,        
          RPA_STAFF_CD: this.selectedRowComponent.RPA_STAFF_CD,
        });
      }
    } catch(e) {

    }
  }

  formValidator() {
    try {
      if(this.issForm.invalid) {
        for(const field in this.issForm.controls) {
          const controlName = this.issForm.get(field);
          if(controlName?.errors?.required) {
            this.errorMessage = 'There are missing information. Please provide necessary information needed';
          }
          if(controlName?.errors?.issCodeDuplicate) {
            this.errorMessage = 'Issue Source already exists. Please add a unique Issue Source.';
          }
          if(controlName?.errors?.pattern) {
            this.errorMessage = 'Invalid TIN. Please add a valid TIN format'
          }
        }
        this.formService.showFormMsg("issue-source-error-message", this.errorMessage, "E");
        return false;
      } else if(this.checkRequired()){
        this.errorMessage = 'There are missing information. Please provide necessary information needed';
        this.formService.showFormMsg("issue-source-error-message", this.errorMessage, "E");
        return false;
      } else {
        this.formService.hideFormMsg("issue-source-error-message");
        return true;
      }
    } catch(e) {
      return true;
    }
  }

  checkRequired(){
    if(this.issForm.get('ISS_CD')?.value.trim()=='' || this.issForm.get('ISS_CD')?.value==null
      ||this.issForm.get('ISS_NAME')?.value.trim()=='' || this.issForm.get('ISS_NAME')?.value==null
      ||this.issForm.get('TEL_NO')?.value.trim()=='' || this.issForm.get('TEL_NO')?.value==null
      ||this.issForm.get('TIN')?.value.trim()=='' || this.issForm.get('TIN')?.value==null
      ||this.issForm.get('FAX_NO')?.value.trim()=='' || this.issForm.get('FAX_NO')?.value==null
      ||this.issForm.get('RPA_STAFF')?.value.trim()=='' || this.issForm.get('RPA_STAFF')?.value==null
      ||this.issForm.get('RPA_DEPT')?.value.trim()=='' || this.issForm.get('RPA_DEPT')?.value==null
      ||this.issForm.get('ADDRESS_1')?.value.trim()=='' || this.issForm.get('ADDRESS_1')?.value==null) {
      return true;
    }else{
      return false;
    }
  }
  addRow() {
    try {
      if(this.formValidator()) {
        this.issueSourceTable.tblData.unshift(this.tableAdder());
        this.issueSourceTable.tblData = [...this.issueSourceTable.tblData];
        this.tempNewData.push(this.tempFormAdder());
        this.buttonToggler('buttonClick');
        this.formLoader();
      }
    } catch(e) {

    }
  }

  onUpdate() {
    try {
      if(this.formValidator()) {
        this.updateTable();
        if(this.deleteDisabler) {
          this.updateDbData();
        } else {
          this.updateNewData();
        }
        this.selectedRowComponent = null;
        this.addUpdateFlag = true;
        this.buttonToggler('buttonClick');
        this.deleteDisabler = true;
        this.formLoader();
      }
    } catch(e) {

    }
  }

  onSave() {
    try {
        this.formHider = true;
        this.formService.showFormLoader(null, "issue-source-form", "Saving.<br>Please wait ...", null, null);
        this.api.saveIssueSource(this.tempNewData).subscribe({
            next: (data: any) => {
                this.securityService.checkRequestKeyResponse(data, () => {
                  this.securityService.hasValidCsrfToken(data, ()=>{
                    if(data.status == 'SUCCESS') {
                      this.api.saveIssueSource(this.tempDbData).subscribe({
                          next: (res: any) => {
                              this.securityService.checkRequestKeyResponse(res, () => {
                                this.securityService.hasValidCsrfToken(res, ()=>{
                                  if(res.status == 'SUCCESS') {
                                    this.onComplete();
                                    this.getIssueSource();
                                  } else {
                                    this.openErrorDialog('Saving issue source details failed.');
                                  }
                                });
                              });
                          },
                          error: () => {
                              this.openErrorDialog('Saving issue source details failed.');
                          }
                      });
                    } else {
                      this.openErrorDialog('Saving issue source details failed.');
                    }
                  });
                });
            },
            error: () => {
                this.openErrorDialog('Saving issue source details failed.');
            }
        });
      
      
      /*forkJoin({
        newData: this.api.saveIssueSource(this.tempNewData),
        dbData: this.api.saveIssueSource(this.tempDbData),
      }).subscribe({
        next: (data: any) => {
            this.securityService.checkRequestKeyResponse(data, () => {
                if(data.newData.status == 'SUCCESS' && data.dbData.status == 'SUCCESS') {
                    this.onComplete();
                    this.getIssueSource();
                } else {
                    this.openErrorDialog('Saving issue source details failed.');
                }
            });
        },
        error: () => {
          this.openErrorDialog('Saving issue source details failed.');
        }
      });*/
    } catch(e) {

    }
  }

  onComplete() {
    try {
      this.formService.hideFormLoader("issue-source-form");
      this.formHider = false;
      this.openSaveDialog();
      this.tempNewData = [];
      this.tempDbData = [];
      this.buttonToggler('buttonClick');
      this.formLoader();
    } catch(e) {

    }
  }

  updateTable() {
    try {
      let updateIndex = this.issueSourceTable.tblData.indexOf(this.selectedRowComponent);
      this.issueSourceTable.tblData[updateIndex] = {
        ACTIVE_TAG: this.issForm.get('ACTIVE_TAG')?.value,
        ADDRESS_1: this.issForm.get('ADDRESS_1')?.value,
        ADDRESS_2: this.issForm.get('ADDRESS_2')?.value,
        ADDRESS_3: this.issForm.get('ADDRESS_3')?.value,
        BM_CODE: this.issForm.get('BM_CODE')?.value,
        BM_SHORT_DESC: this.issForm.get('BM_SHORT_DESC')?.value,
        FAX_NO: this.issForm.get('FAX_NO')?.value,
        ISS_CD: this.issForm.get('ISS_CD')?.value,
        ISS_NAME: this.issForm.get('ISS_NAME')?.value,
        REMARKS: this.issForm.get('REMARKS')?.value,
        RPA_DEPT: this.issForm.get('RPA_DEPT')?.value,
        RPA_STAFF: this.issForm.get('RPA_STAFF')?.value,
        TEL_NO: this.issForm.get('TEL_NO')?.value,
        TIN: this.issForm.get('TIN')?.value,
        COMPLETE_ADDRESS: this.issForm.get('ADDRESS_1')?.value + ' ' + this.issForm.get('ADDRESS_2')?.value + ' ' + this.issForm.get('ADDRESS_3')?.value,
        USER_ID: this.selectedRowComponent.USER_ID,
        LAST_UPDATE: this.datePipe.transform(this.selectedRowComponent.LAST_UPDATE, 'MM/dd/YYYY h:mm:ss a', '+0000'),
        RPA_STAFF_CD: this.issForm.get('RPA_STAFF_CD')?.value,
      };
      this.issueSourceTable.tblData = [...this.issueSourceTable.tblData];
    } catch(e) {

    }
  }

  updateNewData() {
    try {
      let tempNewConfigData = this.tempNewData.find(
        (iss: { ISS_CD: any }) => iss.ISS_CD == this.issForm.get('ISS_CD')?.value
      );
        tempNewConfigData.ACTIVE_TAG = this.issForm.get('ACTIVE_TAG')?.value;
        tempNewConfigData.ADDRESS_1 = this.issForm.get('ADDRESS_1')?.value;
        tempNewConfigData.ADDRESS_2 = this.issForm.get('ADDRESS_2')?.value;
        tempNewConfigData.ADDRESS_3 = this.issForm.get('ADDRESS_3')?.value;
        tempNewConfigData.BM_CODE = this.issForm.get('BM_CODE')?.value;
        tempNewConfigData.BM_SHORT_DESC = this.issForm.get('BM_SHORT_DESC')?.value;
        tempNewConfigData.FAX_NO = this.issForm.get('FAX_NO')?.value;
        tempNewConfigData.ISS_CD = this.issForm.get('ISS_CD')?.value;
        tempNewConfigData.ISS_NAME = this.issForm.get('ISS_NAME')?.value;
        tempNewConfigData.REMARKS = this.issForm.get('REMARKS')?.value;
        tempNewConfigData.RPA_DEPT = this.issForm.get('RPA_DEPT')?.value;
        tempNewConfigData.RPA_STAFF = this.issForm.get('RPA_STAFF')?.value;
        tempNewConfigData.TEL_NO = this.issForm.get('TEL_NO')?.value;
        tempNewConfigData.TIN = this.issForm.get('TIN')?.value;
        tempNewConfigData.USER_ID = this.userId;
        tempNewConfigData.RPA_STAFF_CD = this.issForm.get('RPA_STAFF_CD')?.value;
    } catch(e) {

    }
  }

  updateDbData() {
    try {
      if(this.tempDbData=='' || this.tempDbData==null) {
        this.tempDbData.push(this.tempFormAdder());
      } else {
        let tempDbData = this.tempDbData.find(
          (iss: { ISS_CD: any }) => iss.ISS_CD == this.issForm.get('ISS_CD')?.value
        );
        if(tempDbData=='' || tempDbData==null) {
          this.tempDbData.push(this.tempFormAdder());
        } else {
          tempDbData.ACTIVE_TAG = this.issForm.get('ACTIVE_TAG')?.value;
          tempDbData.ADDRESS_1 = this.issForm.get('ADDRESS_1')?.value;
          tempDbData.ADDRESS_2 = this.issForm.get('ADDRESS_2')?.value;
          tempDbData.ADDRESS_3 = this.issForm.get('ADDRESS_3')?.value;
          tempDbData.BM_CODE = this.issForm.get('BM_CODE')?.value;
          tempDbData.BM_SHORT_DESC = this.issForm.get('BM_SHORT_DESC')?.value;
          tempDbData.FAX_NO = this.issForm.get('FAX_NO')?.value;
          tempDbData.ISS_CD = this.issForm.get('ISS_CD')?.value;
          tempDbData.ISS_NAME = this.issForm.get('ISS_NAME')?.value;
          tempDbData.REMARKS = this.issForm.get('REMARKS')?.value;
          tempDbData.RPA_DEPT = this.issForm.get('RPA_DEPT')?.value;
          tempDbData.RPA_STAFF = this.issForm.get('RPA_STAFF')?.value;
          tempDbData.TEL_NO = this.issForm.get('TEL_NO')?.value;
          tempDbData.TIN = this.issForm.get('TIN')?.value;
          tempDbData.USER_ID = this.userId;
          tempDbData.RPA_STAFF_CD = this.issForm.get('RPA_STAFF_CD')?.value;
        }
      }
    } catch(e) {

    }
  }

  tableAdder() {
    try {
      return {
        ACTIVE_TAG: this.issForm.get('ACTIVE_TAG')?.value,
        ADDRESS_1: this.issForm.get('ADDRESS_1')?.value,
        ADDRESS_2: this.issForm.get('ADDRESS_2')?.value,
        ADDRESS_3: this.issForm.get('ADDRESS_3')?.value,
        BM_CODE: this.issForm.get('BM_CODE')?.value,
        BM_SHORT_DESC: this.issForm.get('BM_SHORT_DESC')?.value,
        FAX_NO: this.issForm.get('FAX_NO')?.value,
        ISS_CD: this.issForm.get('ISS_CD')?.value,
        ISS_NAME: this.issForm.get('ISS_NAME')?.value,
        REMARKS: this.issForm.get('REMARKS')?.value,
        RPA_DEPT: this.issForm.get('RPA_DEPT')?.value,
        RPA_STAFF: this.issForm.get('RPA_STAFF')?.value,
        TEL_NO: this.issForm.get('TEL_NO')?.value,
        TIN: this.issForm.get('TIN')?.value,
        COMPLETE_ADDRESS: this.issForm.get('ADDRESS_1')?.value + ' ' + this.issForm.get('ADDRESS_2')?.value + ' ' + this.issForm.get('ADDRESS_3')?.value,
        RPA_STAFF_CD: this.issForm.get('RPA_STAFF_CD')?.value,
      }
    } catch(e) {
      return {
        ACTIVE_TAG: this.issForm.get('ACTIVE_TAG')?.value,
        ADDRESS_1: this.issForm.get('ADDRESS_1')?.value,
        ADDRESS_2: this.issForm.get('ADDRESS_2')?.value,
        ADDRESS_3: this.issForm.get('ADDRESS_3')?.value,
        BM_CODE: this.issForm.get('BM_CODE')?.value,
        BM_SHORT_DESC: this.issForm.get('BM_SHORT_DESC')?.value,
        FAX_NO: this.issForm.get('FAX_NO')?.value,
        ISS_CD: this.issForm.get('ISS_CD')?.value,
        ISS_NAME: this.issForm.get('ISS_NAME')?.value,
        REMARKS: this.issForm.get('REMARKS')?.value,
        RPA_DEPT: this.issForm.get('RPA_DEPT')?.value,
        RPA_STAFF: this.issForm.get('RPA_STAFF')?.value,
        TEL_NO: this.issForm.get('TEL_NO')?.value,
        TIN: this.issForm.get('TIN')?.value,
        COMPLETE_ADDRESS: this.issForm.get('ADDRESS_1')?.value + ' ' + this.issForm.get('ADDRESS_2')?.value + ' ' + this.issForm.get('ADDRESS_3')?.value,
        RPA_STAFF_CD: this.issForm.get('RPA_STAFF_CD')?.value,
      }
    }
  }

  tempFormAdder() {
    try {
      return {
        ACTIVE_TAG: this.issForm.get('ACTIVE_TAG')?.value,
        ADDRESS_1: this.issForm.get('ADDRESS_1')?.value,
        ADDRESS_2: this.issForm.get('ADDRESS_2')?.value,
        ADDRESS_3: this.issForm.get('ADDRESS_3')?.value,
        BM_CODE: this.issForm.get('BM_CODE')?.value,
        BM_SHORT_DESC: this.issForm.get('BM_SHORT_DESC')?.value,
        FAX_NO: this.issForm.get('FAX_NO')?.value,
        ISS_CD: this.issForm.get('ISS_CD')?.value,
        ISS_NAME: this.issForm.get('ISS_NAME')?.value,
        REMARKS: this.issForm.get('REMARKS')?.value,
        RPA_DEPT: this.issForm.get('RPA_DEPT')?.value,
        RPA_STAFF: this.issForm.get('RPA_STAFF')?.value,
        TEL_NO: this.issForm.get('TEL_NO')?.value,
        TIN: this.issForm.get('TIN')?.value,
        USER_ID: this.userId,
        RPA_STAFF_CD: this.issForm.get('RPA_STAFF_CD')?.value,
      }
    } catch(e) {
      return {
        ACTIVE_TAG: this.issForm.get('ACTIVE_TAG')?.value,
        ADDRESS_1: this.issForm.get('ADDRESS_1')?.value,
        ADDRESS_2: this.issForm.get('ADDRESS_2')?.value,
        ADDRESS_3: this.issForm.get('ADDRESS_3')?.value,
        BM_CODE: this.issForm.get('BM_CODE')?.value,
        BM_SHORT_DESC: this.issForm.get('BM_SHORT_DESC')?.value,
        FAX_NO: this.issForm.get('FAX_NO')?.value,
        ISS_CD: this.issForm.get('ISS_CD')?.value,
        ISS_NAME: this.issForm.get('ISS_NAME')?.value,
        REMARKS: this.issForm.get('REMARKS')?.value,
        RPA_DEPT: this.issForm.get('RPA_DEPT')?.value,
        RPA_STAFF: this.issForm.get('RPA_STAFF')?.value,
        TEL_NO: this.issForm.get('TEL_NO')?.value,
        TIN: this.issForm.get('TIN')?.value,
        USER_ID: this.userId,
        RPA_STAFF_CD: this.issForm.get('RPA_STAFF_CD')?.value,
      }
    }
  }

  buttonToggler(status: string) {
    try {
      if(status=='onRowClick') {
        this.formService.hideFormMsg("issue-source-error-message");
        if(this.selectedRowComponent==null) {
          this.addUpdateFlag = true;
          this.deleteDisabler = true;
        } else {
          this.addUpdateFlag = false;
          this.deleteDisabler = this.deleteEnabler(this.selectedRowComponent);
        }
      }
  
      if(status=='buttonClick') {
        if((this.tempNewData==null || this.tempNewData=='') && (this.tempDbData==null || this.tempDbData=='')) {
          this.saveDisabler = true;
        } else {
          this.saveDisabler = false;
        }
      }
    } catch(e) {

    }
  }

  deleteRow() {
    try {
      let clickData = this.selectedRowComponent;
      this.issueSourceTable.tblData = this.issueSourceTable.tblData.filter(function(e: any) {
        return e.ISS_CD != clickData.ISS_CD;
      });
      this.tempNewData = this.tempNewData.filter(function(e: any) {
        return e.ISS_CD != clickData.ISS_CD;
      });
      this.selectedRowComponent = null;
      this.formLoader();
      this.deleteDisabler = true;
      this.addUpdateFlag = true;
      this.buttonToggler('buttonClick');
    } catch(e) {

    }
  }

  deleteEnabler(data: any) {
    try {
      let tempDeleteFlag = this.tempNewData.filter((e: any) => {
        return e.ISS_CD==data.ISS_CD;
      });
      if(tempDeleteFlag=='' || tempDeleteFlag==null) {
        return true;
      } else {
        return false;
      }
    } catch(e) {
      return false;
    }
  }

  duplicateIssCode(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      let issCodeDuplicate = this.issueSourceTable.tblData.filter(function(e: any) {
        return e.ISS_CD==control.value;
      });
      if(issCodeDuplicate!='') {
        return { "issCodeDuplicate": { value: control.value } }
      }
      return null;
    }
  }

  openErrorDialog(message: string) {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'ERROR',
          content: message,
        }
      });
    } catch(e) {

    }
  }

  openSaveDialog() {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'SUCCESS',
          content: 'Issue Source details Successfully saved!',
        }
      });
    } catch(e) {

    }
  }

  tinDashAdder() {
    try {
      let tinValue = this.issForm.get('TIN')?.value.length;
      if(tinValue==3 || tinValue==7 || tinValue==11) {
        this.issForm.patchValue({
          TIN: this.issForm.get('TIN')?.value + '-'
        });
      }
    } catch(e) {

    }
  }

  rpaOnChange() {
    this.issForm.get("RPA_DEPT")?.setValue(
      this.issForm.get("RPA_STAFF_CD")?.value != null && this.issForm.get("RPA_STAFF_CD")?.value != "" ? this.jsonDataService.data.rpaStaff.find((e: any) => +e.rpaStaffCd === +this.issForm.get("RPA_STAFF_CD")?.value)?.rpaDept : ""
    );
    this.issForm.get("RPA_STAFF")?.setValue(
      this.issForm.get("RPA_STAFF_CD")?.value != null && this.issForm.get("RPA_STAFF_CD")?.value != "" ? this.jsonDataService.data.rpaStaff.find((e: any) => +e.rpaStaffCd === +this.issForm.get("RPA_STAFF_CD")?.value)?.rpaStaff : ""
    );
  }

}