<section id="bma-dialog">
    <div class="mtn-title">
        <p>BMA</p>
    </div>
    <div>
        <osp-table [tblConfig]="bmaTableConfig" (onRowClick)="onRowClick($event)"></osp-table>
    </div>
    <div class='button'>
        <button mat-button class="btn4" type="button" (click)="onBack()">Back</button>
        <button mat-button class="btn2" type="button" (click)="onClose()">OK</button>
    </div>

</section>