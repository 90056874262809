<section id="issue-source">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>

        <osp-table [tblConfig]="issueSourceTable" (onRowClick)="onRowClick($event)"></osp-table>

        <div id="issue-source-form"><div class="form-loader"></div></div>

        <div class="form-content" [hidden]="formHider">

            <form [formGroup]="issForm" class="form">
                <div id="issue-source-error-message"></div>
                <div class="form-container-grid">
                    
                    <div class="field required">
                        <label>Issue Source</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="ISS_CD" oninput="this.value = this.value.toUpperCase()" maxlength="2">
                    </div>

                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="ACTIVE_TAG">
                            <option value="A">Yes</option>
                            <option value="I">No</option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>Issue Name</label>
                    </div>
                    <div class="">
                        <input formControlName="ISS_NAME" maxlength="20">
                    </div>

                    <div class="field required">
                        <label>Telephone No.</label>
                    </div>
                    <div class="">
                        <input formControlName="TEL_NO" maxlength="60">
                    </div>

                    <div class="field required">
                        <label>Tax Identification No.</label>
                    </div>
                    <div class="">
                        <input formControlName="TIN" placeholder="NNN-NNN-NNN-NNN" (keypress)="tinDashAdder()" maxlength="17">
                    </div>

                    <div class="field required">
                        <label>Fax No.</label>
                    </div>
                    <div class="">
                        <input formControlName="FAX_NO" maxlength="60">
                    </div>

                    <div class="field">
                        <label>RPA Staff</label>
                    </div>
                    <div class="">
                        <select formControlName="RPA_STAFF_CD" (change)="rpaOnChange()">
                            <option value=''></option>
                            <option *ngFor="let rpa of rpaStaffList" [value]="rpa.rpaStaffCd">{{ rpa.rpaStaff + " - " + rpa.rpaDept }}</option>
                        </select>
                    </div>

                    <div class="field">
                        <label>RPA Department</label>
                    </div>
                    <div class="">
                        <input formControlName="RPA_DEPT" maxlength="50" readonly>
                    </div>

                    <div class="field required">
                        <label>Address</label>
                    </div>
                    <div class="span">
                        <input formControlName="ADDRESS_1" maxlength="100">
                    </div>
                    <div></div>
                    <div class="span">
                        <input formControlName="ADDRESS_2" maxlength="100">
                    </div>
                    <div></div>
                    <div class="span">
                        <input formControlName="ADDRESS_3" maxlength="100">
                    </div>

                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="REMARKS" maxlength="4000">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input formControlName="USER_ID">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="LAST_UPDATE">
                    </div>

                </div>
            </form>

            <div class="btn-grp">
                <button type="button" class="btn btn2" [hidden]="!addUpdateFlag" (click)="addRow()">ADD</button>
                <button type="button" class="btn btn2" [hidden]="addUpdateFlag" (click)="onUpdate()">UPDATE</button>
                <button type="button" class="btn btn2" [disabled]="deleteDisabler" (click)="deleteRow()">DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-grp">
                <button type="button" class="btn btn2" [disabled]="saveDisabler" (click)="onSave()">SAVE</button>
            </div>

        </div>

    </div>
</section>