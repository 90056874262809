<div class="mtn-container">
    <div class="mtn-title">
        <p>{{ moduleName?.toUpperCase() }}</p>
    </div>
    <div *ngIf="showForm" class="form-content">
        <form [formGroup]="forms.lineSublineGpgLovForm" class="form">
            <div class="form-container-grid top-form triple">
                <div class="field required">
                    <label>Line</label>
                </div>
                <div class="">
                    <input formControlName="lineCdDisp">
                    <button matSuffix class="btn btn-search" type="button" (click)="openLov['line']()"
                        [disabled]="btns.rpaGenPageMapping.lineSearchDisabled" >
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
                <div class="field required">
                    <label>Subline</label>
                </div>
                <div class="">
                    <input formControlName="sublineCdDisp">
                    <button matSuffix class="btn btn-search" type="button" (click)="openLov['subline']()"
                        [disabled]="btns.rpaGenPageMapping.sublineSearchDisabled" >
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
                <div class="field required">
                    <label>Gen Page Group</label>
                </div>
                <div class="">
                    <select formControlName="genPageGrp">
                        <option *ngFor="let genPageGrp of selections.genPageGrps" [ngValue]="genPageGrp.cd">
                            {{genPageGrp.name}}
                        </option>
                    </select>
                </div>
            </div>
        </form>
    </div>
    <osp-table [tblConfig]="rpaGenPageMappingTblConfig" (onRowClick)="onRowClick($event)" [selectedRow]="selectedRow"></osp-table>
    <div id="rpa-gen-page-mapping-maintenance-loading">
        <div class="form-loader"></div>
    </div>
    <div *ngIf="showForm" class="form-content">
        <form [formGroup]="forms.rpaGenPageMappingForm" class="form">
            <div id="rpa-gen-page-mapping-error-message"></div>
            <div class="form-container-grid">
                <div class="field required">
                    <label>Gen Page No.</label>
                </div>
                <div class="">
                    <input posNumInput formControlName="genPageNo" type="number" min="1">
                </div>
                <div class="field">
                </div>
                <div class="">
                </div>
                <div class="field">
                    <label>Gen Page Label</label>
                </div>
                <div class="">
                    <input formControlName="genPageLabel" >
                </div>
                <div class="field required">
                    <label>Parameter</label>
                </div>
                <div class="">
                    <select formControlName="paramTag">
                        <option *ngFor="let parameterType of selections.parameterTypes" [ngValue]="parameterType.cd">
                            {{parameterType.name}}
                        </option>
                    </select>
                </div>
                <div class="field">
                    <label>Gen Page Description</label>
                </div>
                <div class="span">
                    <input formControlName="genPageDesc" >
                </div>
                <div class="field">
                    <label>Last Update User</label>
                </div>
                <div class="">
                    <input formControlName="lastUpdateUser" >
                </div>
                <div class="field">
                    <label>Last Update</label>
                </div>
                <div class="">
                    <input formControlName="lastUpdate" >
                </div>
            </div>
        </form>
        <div class="btn-grp">
            <button *ngIf="btns.rpaGenPageMapping.updateDisabled" class="btn btn2" type="button" (click)="onAdd()" [disabled]="btns.rpaGenPageMapping.addDisabled" >ADD</button>
            <button *ngIf="!btns.rpaGenPageMapping.updateDisabled" class="btn btn2" type="button" (click)="onUpdate()" >UPDATE</button>
            <button class="btn btn2" type="button" [disabled]='btns.rpaGenPageMapping.deleteDisabled' (click)="onDelete()" >DELETE</button>
        </div>
        <div class="mtn-title"></div>
        <div class="btn-grp">
            <button class="btn btn2" type="button" [disabled]='btns.rpaGenPageMapping.saveDisabled' (click)="onSave()" >SAVE</button>
        </div>
    </div>
</div>
