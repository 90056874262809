<section id="occ-class-maint">    
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <!-- Container for Occupation Class Table using prime ng -->
        <div class = 'table-user'>
            <osp-table [tblConfig]="occClassTblConfig" (onRowClick)="onRowClick($event)"></osp-table>
        </div>
        <!-- Loading Animation -->
        <div id="occ-class-maintenance-loading">
            <div class="form-loader"></div>
        </div>
        <div class="form-content" *ngIf="showForm">
            <form [formGroup]="forms.occClassForm" class="form">
                <div id="occ-class-error-message"></div>
                <div class="form-container-grid">
                    <div class="field">
                        <label>Occupation Class Code</label>
                    </div>
                    <div class="">
                        <input formControlName="occClassCdDisp" type="text" readonly>
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag">
                            <option *ngFor="let activeType of selections.activeTypes" [value]="activeType.cd">
                                {{activeType.name}}
                            </option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>Occupation Class</label>
                    </div>
                    <div class="span">
                        <input formControlName="occClassDesc" type="text" maxlength="50">
                    </div>

                    <div class="field required">
                        <label>BPIMS Code</label>
                    </div>
                    <div class="">
                        <input formControlName="bmCode" type="text" maxlength="8">
                    </div>
                    <div class="field">
                        <label>BPIMS Short Description</label>
                    </div>
                    <div class="">
                        <input formControlName="bmShortDesc" type="text" maxlength="10">
                    </div>
                    
                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" type="text" maxlength="4000">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="userID">
                    </div>
                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUserUpdate">
                    </div>
                </div>
            </form>
            <div class="btn-row">
                <button *ngIf="btns.occClass.updateDisabled" class="btn btn2" type="button" (click)="onAdd()">ADD</button>
                <button *ngIf="!btns.occClass.updateDisabled" class="btn btn2" type="button" (click)="onUpdate()">UPDATE</button>
                <button class="btn btn2" type="button" [disabled]='btns.occClass.deleteDisabled' (click)="onDelete()"> DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-row">
                <button class="btn btn2" type="button" [disabled]='btns.occClass.saveDisabled' (click)="onSave()">SAVE</button>
            </div>
        </div>
    </div>
</section>