<section id="ben-cov-maint">    
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <div class="form-content">
            <form [formGroup]="forms.lineSublinePlanLovForm" class="form">
                <div class="form-container-grid top-form">
                    <div class="field required">
                        <label>Line</label>
                    </div>
                    <div class="">
                        <input formControlName="lineCdDisp" >
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['line']()" 
                        [disabled]='btns.lov.lineDisabled'>
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                    <div class="field required">
                        <label>Subline</label>
                    </div>
                    <div class="">
                        <input formControlName="sublineCdDisp" >
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['subline']()" 
                        [disabled]='btns.lov.sublineDisabled'>
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                    <div class="field required">
                        <label>Plan</label>
                    </div>
                    <div class="">
                        <input formControlName="planCdDisp" >
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['plan']()" 
                        [disabled]='btns.lov.planDisabled'>
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <!-- Container for Email Content Table using prime ng -->
        <div class = 'table-user'>
            <osp-table [tblConfig]="benCovTblConfig" (onRowClick)="onRowClick($event)"></osp-table>
        </div>
        <!-- Loading Animation -->
        <div id="ben-cov-maintenance-loading">
            <div class="form-loader"></div>
        </div>
        <div class="form-content" *ngIf="showForm">
            <form [formGroup]="forms.benCovForm" class="form">
                <div id="ben-cov-error-message"></div>
                <div class="form-container-grid">
                    <div class="field required">
                        <label>Plan Subgroup</label>
                    </div>
                    <div class="">
                        <input formControlName="planSubgrp" type="text" maxlength="5" (input)="autoUppercase($event)">
                    </div>
                    <div class="field">
                        <label>Sequence No.</label>
                    </div>
                    <div class="">
                        <input formControlName="seqNo" type="text" (keypress)="allowNumericDigitsOnlyWODecimal($event)" (blur)="integerFormat('seqNo')">
                    </div>

                    <div class="field required">
                        <label>Subgroup Title</label>
                    </div>
                    <div class="span">
                        <input formControlName="planSubgrpTitle" type="text">
                    </div>

                    <div class="field">
                        <label>Subgroup Text</label>
                    </div>
                    <div class="span">
                        <textarea maxlength="4000" formControlName="planSubgrpText"></textarea>
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUpdateUser">
                    </div>
                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUpdate">
                    </div>
                </div>
            </form>
            <div class="btn-row">
                <button *ngIf="btns.benCov.updateDisabled" class="btn btn2" type="button" [disabled]='btns.benCov.addDisabled' (click)="onAdd()">ADD</button>
                <button *ngIf="!btns.benCov.updateDisabled" class="btn btn2" type="button" (click)="onUpdate()">UPDATE</button>
                <button class="btn btn2" type="button" [disabled]='btns.benCov.updateDisabled' (click)="onDelete()"> DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-row">
                <button class="btn btn2" type="button" [disabled]='btns.benCov.saveDisabled' (click)="onSave()">SAVE</button>
            </div>
        </div>
    </div>
</section>