import { Component, OnInit } from '@angular/core';
import { APICallService } from 'src/app/services/api-call.service';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { forkJoin, Observable } from 'rxjs';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { SecurityService } from 'src/app/services/security.service'; 
import { RegionLovComponent } from '../../common/region-lov/region-lov.component';
import { EQZoneLovComponent } from '../../common/eq-zone-lov/eq-zone-lov.component';
import { TFZoneLovComponent } from '../../common/tf-zone-lov/tf-zone-lov.component';
@Component({
  selector: 'state-maintenance',
  templateUrl: './state-maintenance.component.html',
  styleUrls: ['./state-maintenance.component.css']
})
export class StateMaintenanceComponent implements OnInit, LeaveConfirmation {
  
  datePipe = new DatePipe('en-us');

  addDisabled : boolean = false;
  saveDisabled : boolean = true;
  deleteDisabled : boolean = true;
  tranPosted : boolean = false;
  noLoadingInProcess : boolean = true;
  validated : boolean = false;
  userIDReadOnly : boolean = false;
  isLineSelected : boolean = false;
  pubUserStdRatesCodeField: string = '';
  currentUser: string = this.jsonDataService.retrieveFromStorage('userId');
  rowClickedData: any;
  selectedData : any = null;
  moduleId: string = 'BMM145';
  perilDisabled: boolean = true;
  moduleName: string = '';
  tempAddedData: any = [];
  tempUpdateData: any = [];
  selectedRegion: any = {
    regionCd: '',
    regShortDesc: '',
    regionField: ''
  };
  selectedEQZone: any = {
    eqZoneCd: '',
    eqZoneShortDesc: '',
    eqZoneField: ''
  };
  selectedTFZone: any = {
    tfZoneCd: '',
    tfZoneShortDesc: '',
    tfZoneField: ''
  };
  stateForm = this.formBuilder.group({
    stateCode: [null, [Validators.required]],
    activeTag: ['A', [Validators.required]],
    stateShortDesc: [null, [Validators.required, Validators.maxLength(50)]],
    origStateShortDesc: [null],
    stateLongDesc: [null, [Validators.required, Validators.maxLength(1000)]],
    bpimsCode: [null, [Validators.required, Validators.maxLength(8)]],
    regionCode: [null],
    regShortDesc: [null],
    regionField: [null],
    eqZoneCode: [null],
    eqZoneShortDesc: [null],
    eqZoneField: [null],
    tfZoneCode: [null],
    tfZoneShortDesc: [null],
    tfZoneField: [null],
    remarks: [null],
    userID: [null],
    lastUserUpdate: [null]
  });

  constructor(
    private apiCallService: APICallService,
    public _Dialog: MatDialog, 
    private userDetailService: UserDetailsService,
    private formService: FormService, 
    private formBuilder: FormBuilder, 
    private appMessageService: AppMessageService,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService
  ) {
    let moduleLists = JSON.parse(this.jsonDataService.decrypt(sessionStorage.getItem("mdls") || "")); 
    this.moduleName = moduleLists.filter((data: any) => { return data.moduleId === this.moduleId })[0].moduleDesc.toUpperCase();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempAddedData!='' || this.tempUpdateData!='') {
      return false;
    } else {
      return true;
    }
  }

  stateTblConfig: any = {
    cols: [
      {
        key: "STATE_CD",
        header: "State Code",
        dataType: "padNumber"
      },
      {
        key: "STATE_SHORT_DESC",
        header: "State Short Description",
        dataType: "string",
        width: '22%'
      },
      {
        key: "REG_SHORT_DESC",
        header: "Region",
        dataType: "string"
      },
      {
        key: "EQ_ZONE_SHORT_DESC",
        header: "EQ Zone",
        dataType: "string"
      },
      {
        key: "TF_ZONE_SHORT_DESC",
        header: "TF Zone",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        width: '10%'
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }
  ngOnInit() {
    try {
      this.apiCallService.refreshNeeded$
            .subscribe(() => {
              this.getAllSegmentCode();
          });
      this.getAllSegmentCode();
    }
    catch(e) {
    }
  }

  async getAllSegmentCode(){
    try{
      const params = {
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE"
      }
      this.stateTblConfig.loading = true;
      this.apiCallService.getStates(params).subscribe({
        next: (data: any) => {
          this.securityService.checkRequestKeyResponse(data, ()=>{
            this.securityService.hasValidCsrfToken(data, ()=>{
              if (data.status === "SUCCESS") {
                let content = JSON.parse(this.jsonDataService.decrypt(data?.response));
                let tableData = content.data;
                if(tableData.length){
                  for(let d of tableData){
                    d.STATE_CD = d.stateCd;
                    d.STATE_SHORT_DESC = d.stateShortDesc;
                    d.STATE_LONG_DESC = d.stateLongDesc;
                    d.EQ_ZONE_CD = d.eqZoneCd;
                    d.EQ_ZONE_SHORT_DESC = d.eqZoneShortDesc;
                    d.EQ_ZONE_FIELD = d.eqZoneShortDesc;
                    d.TF_ZONE_CD = d.tfZoneCd;
                    d.TF_ZONE_SHORT_DESC = d.tfZoneShortDesc;
                    d.TF_ZONE_FIELD = d.tfZoneShortDesc;
                    d.REGION_CD = d.regionCd;
                    d.REG_SHORT_DESC = d.regShortDesc;
                    d.REGION_FIELD = d.regShortDesc;
                    d.ACTIVE_TAG = d.activeTag;
                    d.BM_CODE = d.bmCode;
                    d.REMARKS = d.remarks;
                    d.USER_ID = d.userId;
                    d.LAST_UPDATE = d.lastUpdate;
                    d.LAST_USER_UPDATE = d.lastUserUpdate;
                  }
                }
                this.stateTblConfig.tblData = tableData;
                this.stateTblConfig.loading = false;
              }
              else {
                this.jsonDataService.contorlLoading(false);
                this.stateTblConfig.loading = false;
              }
            });
          });
        },
        error: (e: any) => {
          this.stateTblConfig.loading = false;
        }
      });
    }catch(e){
    }
  }

  updateLine() {
    try {
      this.tranPosted = true;
      this.validateUpdate();
      if (this.validated) {
        this.updateFormTable();
      }
    }
    catch(e) {
    }
  }

  updateFormTable() {
    try {
      let tempInsertChecker = this.tempAddedData.find((line: { STATE_SHORT_DESC: any }) => 
        line.STATE_SHORT_DESC.trim().toUpperCase() == this.stateForm.get('origStateShortDesc')?.value.trim().toUpperCase()
      );
      let tempUpdateChecker = this.tempUpdateData.find((line: { STATE_SHORT_DESC: any }) => 
        line.STATE_SHORT_DESC.trim().toUpperCase() == this.stateForm.get('origStateShortDesc')?.value.trim().toUpperCase()
      );
      
      this.tableDataUpdate();
        
      // Adds Updated Data that exists in the database to an object array for updated datas
      if(tempInsertChecker!=null && tempInsertChecker!='') {
        //this.tempUpdateData.push(this.formValueToData());
        tempInsertChecker.STATE_CD = this.stateForm.get('stateCode')?.value;
        tempInsertChecker.STATE_SHORT_DESC = this.stateForm.get('stateShortDesc')?.value;
        tempInsertChecker.STATE_LONG_DESC = this.stateForm.get('stateLongDesc')?.value;
        tempInsertChecker.BM_CODE = this.stateForm.get('bpimsCode')?.value;
        tempInsertChecker.EQ_ZONE_CD = this.stateForm.get('eqZoneCode')?.value;
        tempInsertChecker.EQ_ZONE_SHORT_DESC = this.stateForm.get('eqZoneShortDesc')?.value;
        tempInsertChecker.EQ_ZONE_FIELD = this.stateForm.get('eqZoneField')?.value;
        tempInsertChecker.TF_ZONE_CD = this.stateForm.get('tfZoneCode')?.value;
        tempInsertChecker.TF_ZONE_SHORT_DESC = this.stateForm.get('tfZoneShortDesc')?.value;
        tempInsertChecker.TF_ZONE_FIELD = this.stateForm.get('tfZoneField')?.value;
        tempInsertChecker.REGION_CD = this.stateForm.get('regionCode')?.value;
        tempInsertChecker.REG_SHORT_DESC = this.stateForm.get('regShortDesc')?.value;
        tempInsertChecker.REGION_FIELD = this.stateForm.get('regionField')?.value;
        tempInsertChecker.ACTIVE_TAG = this.stateForm.get('activeTag')?.value;
        tempInsertChecker.REMARKS = this.stateForm.get('remarks')?.value;
        tempInsertChecker.LAST_USER_UPDATE = this.datePipe.transform(new Date(this.stateForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a');
        tempInsertChecker.USER_ID = this.stateForm.get('userID')?.value;
        tempInsertChecker.MODE = 'I';
      } 
      else if(tempUpdateChecker!=null && tempUpdateChecker!='') {
        tempUpdateChecker.STATE_CD  = this.stateForm.get('stateCode')?.value;
        tempUpdateChecker.STATE_SHORT_DESC = this.stateForm.get('stateShortDesc')?.value;
        tempUpdateChecker.STATE_LONG_DESC = this.stateForm.get('stateLongDesc')?.value;
        tempUpdateChecker.BM_CODE = this.stateForm.get('bpimsCode')?.value;
        tempUpdateChecker.EQ_ZONE_CD = this.stateForm.get('eqZoneCode')?.value;
        tempUpdateChecker.EQ_ZONE_SHORT_DESC = this.stateForm.get('eqZoneShortDesc')?.value;
        tempUpdateChecker.EQ_ZONE_FIELD = this.stateForm.get('eqZoneField')?.value;
        tempUpdateChecker.TF_ZONE_CD = this.stateForm.get('tfZoneCode')?.value;
        tempUpdateChecker.TF_ZONE_SHORT_DESC = this.stateForm.get('tfZoneShortDesc')?.value;
        tempUpdateChecker.TF_ZONE_FIELD = this.stateForm.get('tfZoneField')?.value;
        tempUpdateChecker.REGION_CD = this.stateForm.get('regionCode')?.value;
        tempUpdateChecker.REG_SHORT_DESC = this.stateForm.get('regShortDesc')?.value;
        tempUpdateChecker.REGION_FIELD = this.stateForm.get('regionField')?.value;
        tempUpdateChecker.ACTIVE_TAG = this.stateForm.get('activeTag')?.value;
        tempUpdateChecker.REMARKS = this.stateForm.get('remarks')?.value;
        tempUpdateChecker.LAST_USER_UPDATE = this.datePipe.transform(new Date(this.stateForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a');
        tempUpdateChecker.USER_ID = this.stateForm.get('userID')?.value;
        tempUpdateChecker.MODE = 'U';
      }
      else {
        this.tempUpdateData.push(this.formValueToData());
      }
      this.saveDisabled = false;
      this.resetFormValues();
      this.appMessageService.showAppMessage("State details updated!", "success");
    }
    catch(e) {
    }
  }

  tableDataUpdate() {
    try {
      this.stateForm.patchValue({
        activeTag: this.stateForm.get('activeTag')?.value,
        userID: this.currentUser,
        lastUserUpdate: new Date()
      });
      let updateIndex = this.stateTblConfig.tblData.indexOf(this.rowClickedData);
      
      this.stateTblConfig.tblData[updateIndex] = {
        STATE_CD: this.stateForm.get('stateCode')?.value,
        STATE_SHORT_DESC: this.stateForm.get('stateShortDesc')?.value,
        STATE_LONG_DESC: this.stateForm.get('stateLongDesc')?.value,
        BM_CODE: this.stateForm.get('bpimsCode')?.value,
        EQ_ZONE_CD: this.stateForm.get('eqZoneCode')?.value,
        EQ_ZONE_SHORT_DESC: this.stateForm.get('eqZoneShortDesc')?.value,
        EQ_ZONE_FIELD: this.stateForm.get('eqZoneField')?.value,
        TF_ZONE_CD: this.stateForm.get('tfZoneCode')?.value,
        TF_ZONE_SHORT_DESC: this.stateForm.get('tfZoneShortDesc')?.value,
        TF_ZONE_FIELD: this.stateForm.get('tfZoneField')?.value,
        REGION_CD: this.stateForm.get('regionCode')?.value,
        REG_SHORT_DESC: this.stateForm.get('regShortDesc')?.value,
        REGION_FIELD: this.stateForm.get('regionField')?.value,
        ACTIVE_TAG: this.stateForm.get('activeTag')?.value,
        REMARKS: this.stateForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.stateForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.stateForm.get('userID')?.value,
        MODE: 'U'
      };
      this.stateTblConfig.tblData = [...this.stateTblConfig.tblData];
    }
    catch(e) {
    }
  }

  formValueToData() {
    try {
      let formToData: any = { // this.datePipe.transform(new Date(), 'MM/dd/yyyy')
        STATE_CD: this.stateForm.get('stateCode')?.value,
        STATE_SHORT_DESC: this.stateForm.get('stateShortDesc')?.value,
        STATE_LONG_DESC: this.stateForm.get('stateLongDesc')?.value,
        BM_CODE: this.stateForm.get('bpimsCode')?.value,
        EQ_ZONE_CD: this.stateForm.get('eqZoneCode')?.value,
        EQ_ZONE_SHORT_DESC: this.stateForm.get('eqZoneShortDesc')?.value,
        EQ_ZONE_FIELD: this.stateForm.get('eqZoneField')?.value,
        TF_ZONE_CD: this.stateForm.get('tfZoneCode')?.value,
        TF_ZONE_SHORT_DESC: this.stateForm.get('tfZoneShortDesc')?.value,
        TF_ZONE_FIELD: this.stateForm.get('tfZoneField')?.value,
        REGION_CD: this.stateForm.get('regionCode')?.value,
        REG_SHORT_DESC: this.stateForm.get('regShortDesc')?.value,
        REGION_FIELD: this.stateForm.get('regionField')?.value,
        ACTIVE_TAG: this.stateForm.get('activeTag')?.value,
        REMARKS: this.stateForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.stateForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.stateForm.get('userID')?.value,
        MODE: 'U'
      }
      return formToData;
    }
    catch(e) {
    }
  }
  deleteLine() {
    try {
      let clickData = this.rowClickedData;
      this.stateTblConfig.tblData = this.stateTblConfig.tblData.filter(
        function(e: any) {
          return e.STATE_SHORT_DESC.trim().toUpperCase() !== clickData.STATE_SHORT_DESC.trim().toUpperCase();
        }
      );
      this.tempAddedData = this.tempAddedData.filter(
        function(e: any) {

          return e.STATE_SHORT_DESC.trim().toUpperCase() !== clickData.STATE_SHORT_DESC.trim().toUpperCase();
        }
      );
      this.resetFormValues();
    }
    catch(e) {
    }
  }

  insertLine() {
    try {
      this.tranPosted = true;
      this.validateInsert();
      if (this.validated) {
        this.addFormToTable();
      }
    }
    catch(e) {
    }
  }
  addFormToTable() {
    try {
      this.stateForm.patchValue({
        activeTag: this.stateForm.get('activeTag')?.value,
        userID: this.currentUser,
        lastUserUpdate: new Date()
      });
      let formToData: any = { // this.datePipe.transform(new Date(), 'MM/dd/yyyy')
        STATE_CD: this.stateForm.get('stateCode')?.value,
        STATE_SHORT_DESC: this.stateForm.get('stateShortDesc')?.value,
        STATE_LONG_DESC: this.stateForm.get('stateLongDesc')?.value,
        BM_CODE: this.stateForm.get('bpimsCode')?.value,
        EQ_ZONE_CD: this.stateForm.get('eqZoneCode')?.value,
        EQ_ZONE_SHORT_DESC: this.stateForm.get('eqZoneShortDesc')?.value,
        EQ_ZONE_FIELD: this.stateForm.get('eqZoneField')?.value,
        TF_ZONE_CD: this.stateForm.get('tfZoneCode')?.value,
        TF_ZONE_SHORT_DESC: this.stateForm.get('tfZoneShortDesc')?.value,
        TF_ZONE_FIELD: this.stateForm.get('tfZoneField')?.value,
        REGION_CD: this.stateForm.get('regionCode')?.value,
        REG_SHORT_DESC: this.stateForm.get('regShortDesc')?.value,
        REGION_FIELD: this.stateForm.get('regionField')?.value,
        ACTIVE_TAG: this.stateForm.get('activeTag')?.value,
        REMARKS: this.stateForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.stateForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.stateForm.get('userID')?.value,
        MODE: 'I'
      }
      this.stateTblConfig.tblData.unshift(formToData);
      this.stateTblConfig.tblData = [...this.stateTblConfig.tblData];
      this.tempAddedData.push(formToData);
      this.resetFormValues();
      this.appMessageService.showAppMessage("State details added!", "success");
    }
    catch(e) {
    }
  }

  saveData(tempChangeData:any[]){
    this.apiCallService.saveState(tempChangeData)
    .subscribe({
      next: (response : any) => {
        this.securityService.checkRequestKeyResponse(response, () => {
          this.securityService.hasValidCsrfToken(response, ()=>{
            if (response.status == "SUCCESS" && this.tranPosted) {
              this.openDialog('SUCCESS', 'Details Successfully saved!');
              this.getAllSegmentCode();
              this.resetFormValues();
              this.tempAddedData = [];
              this.tempUpdateData = [];
              this.saveDisabled = this.saveChecker();
            }  
            else {
              this.getAllSegmentCode();
              this.saveDisabled = this.saveChecker();
              this.openDialog('ERROR', 'An error has occured while saving details');
            }
          });
        });
      }, 
      error: () => {
        this.openDialog('ERROR', 'An error has occured while saving details');
      }
    });
  }

  toSave() {
    try {
      this.noLoadingInProcess = false;
      this.formService.showFormLoader(null, "generate-line-form", "Please wait ...", null, null);
      let tempChangeData= this.tempAddedData.concat(this.tempUpdateData);
      if(tempChangeData.length!=0) {
        this.saveData(tempChangeData);
      } else {
        this.getAllSegmentCode();
        this.resetFormValues();
        this.tempAddedData = [];
        this.tempUpdateData = [];
        this.saveDisabled = this.saveChecker();
      }
    }
    catch(e) {
    }
  }
  saveChecker() {
    try {
      if((this.tempAddedData==null || this.tempAddedData=='') && (this.tempUpdateData==null || this.tempUpdateData=='')) {
        return true;
      }
      else {
        return false;
      }
    }
    catch(e) {
      return true;
    }
  }

  deleteChecker(ev: any) {
    try {
      let tempDataFlag;
      tempDataFlag = this.tempAddedData.filter(function(e: any) {
        let returnValue = e.STATE_CD==ev.STATE_CD;
        return returnValue;
      });
      if(tempDataFlag==''||tempDataFlag==null) {
        return true;
      } else {
        return false;
      }
    }
    catch(e) {
      return true;
    }
  }

  onRowClick(ev: any) {
    try {    
      if(ev!=null) {
        this.rowClickedData = ev;
        this.loadFormValues();
        this.saveDisabled = this.saveChecker();
        this.deleteDisabled = this.deleteChecker(ev);
      }
      else {
        this.resetFormValues();
      }
    }
    catch(e) {
    }
  }

  loadFormValues() {
    try {
      this.stateForm.patchValue({
        stateCode: this.rowClickedData.STATE_CD,
        stateShortDesc: this.rowClickedData.STATE_SHORT_DESC,
        stateLongDesc: this.rowClickedData.STATE_LONG_DESC,
        origStateShortDesc: this.rowClickedData.STATE_SHORT_DESC,
        bpimsCode: this.rowClickedData.BM_CODE,
        eqZoneCd: this.rowClickedData.EQ_ZONE_CD,
        eqZoneShortDesc: this.rowClickedData.EQ_ZONE_SHORT_DESC,
        eqZoneField: this.rowClickedData.EQ_ZONE_FIELD,
        tfZoneCd: this.rowClickedData.TF_ZONE_CD,
        tfZoneShortDesc: this.rowClickedData.TF_ZONE_SHORT_DESC,
        tfZoneField: this.rowClickedData.TF_ZONE_FIELD,
        regionCd: this.rowClickedData.REGION_CD,
        regShortDesc: this.rowClickedData.REG_SHORT_DESC,
        regionField: this.rowClickedData.REGION_FIELD,
        activeTag: this.rowClickedData.ACTIVE_TAG,
        remarks: this.rowClickedData.REMARKS,
        userID: this.rowClickedData.USER_ID,
        lastUserUpdate: this.rowClickedData.LAST_USER_UPDATE
      });
      this.addDisabled = true;
      this.userIDReadOnly = true;
      this.isLineSelected = true;
    }
    catch(e) {
    }
  }

  resetFormValues() {
    try {
      this.selectedData = null;
      // this.paramForm.get('lineCD')?.enable();
      this.stateForm.patchValue({
        stateCode: null,
        stateShortDesc: null,
        stateLongDesc: null,
        origStateShortDesc: null,
        bpimsCode: null,
        eqZoneCd: null,
        eqZoneShortDesc: null,
        eqZoneField: null,
        tfZoneCd: null,
        tfZoneShortDesc: null,
        tfZoneField: null,
        regionCd: null,
        regShortDesc: null,
        regionField: null,
        activeTag: 'A',
        remarks: null,
        userID: null,
        lastUserUpdate: null
      });

      this.addDisabled = false;
      this.saveDisabled = this.saveChecker();
      this.deleteDisabled = true;
      this.userIDReadOnly = false;
      this.isLineSelected = false;

      this.rowClickedData = '' || null;
    }
    catch (e) {
    console.error(e);
    }
  }

  openRegionDialog() {
    try {
      this._Dialog.open(RegionLovComponent, {
        width: '512px',
        disableClose: true,
        data: {
          table: 'REGION',
          moduleId: this.moduleId,
          userId: this.userDetailService.userId,
          desc: 'SHORT'
        }
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedRegion = data.content;
          this.stateForm.get('regionCode')?.setValue(this.selectedRegion.regionCd==null || this.selectedRegion.regionCd=='' ? '' : this.selectedRegion.regionCd);
          this.stateForm.get('regShortDesc')?.setValue(this.selectedRegion.regionCd==null || this.selectedRegion.regionCd=='' ? '' : this.selectedRegion.regionDesc);
          this.stateForm.get('regionField')?.setValue(this.selectedRegion.regionCd==null || this.selectedRegion.regionCd=='' ? '' : this.selectedRegion.regionDesc);
        }
      });
    } catch(e) {
    }
  }
  openEQZoneDialog() {
    try {
      this._Dialog.open(EQZoneLovComponent, {
        width: '512px',
        disableClose: true,
        data: {
          table: 'REGION',
          moduleId: this.moduleId,
          userId: this.userDetailService.userId,
          desc: "SHORT"
        }
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedEQZone = data.content;
          this.stateForm.get('eqZoneCode')?.setValue(this.selectedEQZone.eqZoneCd==null || this.selectedEQZone.eqZoneCd=='' ? '' : this.selectedEQZone.eqZoneCd);
          this.stateForm.get('eqZoneShortDesc')?.setValue(this.selectedEQZone.eqZoneCd==null || this.selectedEQZone.eqZoneCd=='' ? '' : this.selectedEQZone.eqZoneDesc);
          this.stateForm.get('eqZoneField')?.setValue(this.selectedEQZone.eqZoneCd==null || this.selectedEQZone.eqZoneCd=='' ? '' : this.selectedEQZone.eqZoneDesc);
        }
      });
    } catch(e) {
    }
  }
  openTFZoneDialog() {
    try {
      this._Dialog.open(TFZoneLovComponent, {
        width: '512px',
        disableClose: true,
        data: {
          table: 'REGION',
          moduleId: this.moduleId,
          userId: this.userDetailService.userId,
          desc: "SHORT"
        }
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedTFZone = data.content;
          this.stateForm.get('tfZoneCode')?.setValue(this.selectedTFZone.tfZoneCd==null || this.selectedTFZone.tfZoneCd=='' ? '' : this.selectedTFZone.tfZoneCd);
          this.stateForm.get('tfZoneShortDesc')?.setValue(this.selectedTFZone.tfZoneCd==null || this.selectedTFZone.tfZoneCd=='' ? '' : this.selectedTFZone.tfZoneDesc);
          this.stateForm.get('tfZoneField')?.setValue(this.selectedTFZone.tfZoneCd==null || this.selectedTFZone.tfZoneCd=='' ? '' : this.selectedTFZone.tfZoneDesc);
        }
      });
    } catch(e) {
    }
  }
  openDialog(title: any, message: any) {
    try {
      this.noLoadingInProcess = true;
      this.formService.hideFormLoader("generate-line-form");
      this._Dialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        } 
      });
    } catch(e) {
    }
  }

  validateInsert() {
    try {
      this.validated = false;
      if (!this.requiredFieldCheck()){
        setTimeout(()=>{                           //10 secs countdown
          this.formService.hideFormMsg("state-error-message");
        }, 10000);
      }
      else if (!this.duplicateCheck(this.stateTblConfig.tblData)) {
        setTimeout(()=>{                           
          this.formService.hideFormMsg("state-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
    }
  }

  requiredFieldCheck() {
    try {
      if(this.stateForm.get('activeTag')?.value===null || this.stateForm.get('activeTag')?.value===''
        || this.stateForm.get('stateShortDesc')?.value===null|| this.stateForm.get('stateShortDesc')?.value.trim()===''  
        || this.stateForm.get('stateLongDesc')?.value===null || this.stateForm.get('stateLongDesc')?.value.trim()==='' 
        ||this.stateForm.get('bpimsCode')?.value===null || this.stateForm.get('bpimsCode')?.value.trim()==='') {
        this.formService.showFormMsg("state-error-message", 'There are missing information. Please provide the necessary information needed.', "E");
        return false;
      }
      else {
        return true;
      }
   }
    catch (e) {
      console.error(e);
      return false;
    }
  }

  duplicateCheck(tblData:any) {
    try {
      let fieldStateShortDesc = this.stateForm.get('stateShortDesc')?.value;
      this.pubUserStdRatesCodeField = tblData.filter(function(e: any) {
        if(e.STATE_SHORT_DESC.trim().toUpperCase()==fieldStateShortDesc.trim().toUpperCase()){
          return true
        }
        else{
          return false
        }
      });
      if(this.pubUserStdRatesCodeField=='' || this.pubUserStdRatesCodeField==null) {
        return true;
      } else {
        this.formService.showFormMsg("state-error-message", 'State Short Description already exists. Please add a unique State Short Description', "E");
        return false;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }

  validateUpdate() {
    try {
      this.validated = false;
      let fieldStateShortDesc = this.stateForm.get('origStateShortDesc')?.value;
      let filteredTblData = this.stateTblConfig.tblData.filter(function(e:any){
        if(e.STATE_SHORT_DESC.trim().toUpperCase()==fieldStateShortDesc.trim().toUpperCase()){
          return false;
        }
        else{
          return true;
        }
      });
      if (!this.requiredFieldCheck()){
        setTimeout(()=>{                          
          this.formService.hideFormMsg("state-error-message");
        }, 10000);
      }
      else if (!this.duplicateCheck(filteredTblData)) {
        setTimeout(()=>{                           
          this.formService.hideFormMsg("state-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
    }
  }
  
}
