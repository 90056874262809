import { Component, Inject, OnInit } from '@angular/core';
import { APICallService } from 'src/app/services/api-call.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContentObserver } from '@angular/cdk/observers';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';


@Component({
  selector: 'zone-lov',
  templateUrl: './zone-lov.component.html',
  styleUrls: ['./zone-lov.component.css']
})
export class ZoneLovDialogComponent implements OnInit {

  userTypeModuleId: string = 'BMM167';
  isDesc: boolean = false;
  isNameDesc: boolean =true;
  isName: boolean = false;
  public titleLov: string = "Zone".toUpperCase();
  // Variable Initializer
  issueSourceData: any = [];

  // Issue Source Initializer
  zoneTableConfig: any = {
    cols: [],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15
  }

  private zoneTableNameDescCol: any[] = [
    {
      key: "ZONE_CD",
      header: "Code",
      dataType: "string",
      width: '15%'
    },
    {
      key: "ZONE_NAME",
      header: "Name",
      dataType: "string",
      width: '30%'
    },
    {
      key: "ZONE_DESC",
      header: "Description",
      dataType: "string"
    },
  ];

  private zoneTableNameCol: any[] = [
    {
      key: "ZONE_CD",
      header: "Code",
      dataType: "string",
      width: '15%'
    },
    {
      key: "ZONE_NAME",
      header: "Description",
      dataType: "string",
    },
  ];

  private zoneTableDescCol: any[] = [
    {
      key: "ZONE_CD",
      header: "Code",
      dataType: "string",
      width: '15%'
    },
    {
      key: "ZONE_DESC",
      header: "Description",
      dataType: "string"
    },
  ];

  private zoneTablePlanCol: any[] = [
    {
      key: "ZONE_CD",
      header: "Code",
      dataType: "string",
      width: '15%'
    },
    {
      key: "ZONE_DESC",
      header: "Plan",
      dataType: "string"
    },
  ];
  constructor(private apiCallService: APICallService,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<ZoneLovDialogComponent>) { 
      if("tableData" in this.data){
        if(this.data.tableData == "DESC"){
          this.zoneTableConfig.cols = this.zoneTableDescCol;
        }
        else if(this.data.tableData == "NAME"){
          this.zoneTableConfig.cols = this.zoneTableNameCol;
        }
        else if(this.data.tableData == "PLAN"){
          this.zoneTableConfig.cols = this.zoneTablePlanCol;
        }
        else{
          this.zoneTableConfig.cols = this.zoneTableNameDescCol;
        }
      }
      else{
        this.zoneTableConfig.cols = this.zoneTableNameDescCol;
      }
      if("title" in this.data){
        this.titleLov = this.data.title;
      }
      this.getZone();
    }
  
  ngOnInit(): void {
    
  }

  // Gets data for Issue Source Table
  getZone() {
    try {
      /*
      this.zoneTableConfig.loading = true;
      this.issueSourceData = await this.apiCallService.getAllIssueSource().toPromise();
      this.issueSourceData = this.issueSourceData.content;
      this.zoneTableConfig.loading = false;
      this.zoneTableConfig.tblData = this.issueSourceData;
      */
      this.zoneTableConfig.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.apiCallService.getGeographicZoning({
        moduleId: this.data.moduleId,
        userId: this.userDetailService.userId,
        type: "LOV"
      }).subscribe((data: any) => {
            this.securityService.checkRequestKeyResponse(data, () => {
              this.securityService.hasValidCsrfToken(data, () => {
                if (data.status === "SUCCESS") {
                  data = JSON.parse(this.jsonDataService.decrypt(data.response));
                  this.jsonDataService.contorlLoading(false);
                  this.zoneTableConfig.loading = false;
                  this.zoneTableConfig.tblData = data.filter((e: any) => e.ACTIVE_TAG == 'A');
                } else {
                    this.jsonDataService.contorlLoading(false);
                    this.zoneTableConfig.loading = false;
                    this.appMessageService.showAppMessage(data.message, "error");
                }
              });
            });
      });
    } 
    catch(e) {
      
    }
  }

  onRowClick(ev: any) {
    try {
      this.data = ev;
    }
    catch(e) {
      
    }
  }

  onClose() {
    try {
      if(this.data=='' || this.data==null){
        this._dialogRef.close({content: ''}); 
      } else {
        this._dialogRef.close({content: this.data, moduleId: this.data.moduleId});
      }
    }
    catch(e) {
      
    }
  }

  onBack() {
    try {
      this._dialogRef.close({content: '', option: 'back'});
    }
    catch(e) {
      
    }
  }

}

