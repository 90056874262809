import { DatePipe } from '@angular/common';
import { Component, OnInit, Type } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';
import { PlanLovComponent } from '../../common/plan-lov/plan-lov.component';
import { AccountTypeLovComponent } from '../../common/acc-type-lov/acc-type-lov.component';
import { PaymentOptionsLovComponent } from '../../common/payt-opt-lov/payt-opt-lov.component';
import { CheckLovComponent } from 'src/app/components/common/guard/check-lov/check-lov.component';

interface RowData {
    paytOpt: any
}

@Component({
    selector: 'payment-options-access-maintenance',
    templateUrl: './payment-options-access-maintenance.html',
    styleUrls: ['./payment-options-access-maintenance.css']
})
export class PaymentOptionsAccessMaintenanceComponent implements OnInit, LeaveConfirmation {

    moduleId: string = 'BMM103';
    public moduleName: string = "";
    toBeDeleted: any = [];

    constructor(
        private fb: FormBuilder,
        private _matDialog: MatDialog,
        private _api: APICallService,
        private _formService: FormService,
        private _jsonDS: JsonDataService,
        private _securityService: SecurityService,
        private _userDetailService: UserDetailsService,
        private _appMessageService: AppMessageService
    ) {
        this.userIdLoggedIn = this._userDetailService.userId || "";
    }

    ngOnInit(): void {
        this.moduleName = this._jsonDS.data.moduleList.filter((data: any) => { return data.moduleId === this.moduleId })[0].moduleDesc.toUpperCase();
    }

    canDeactivate(): boolean | Observable<boolean> {
        return this.tblConfig.paytOpt.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true)).length > 0 ? false : true;
    }

    test() {
        //console.table(this.forms.paytOptForm.value);
    }

    /* Boolean Stuffs */

    public btns = {
        paytOpt: {
            //updateDisabled: true,
            deleteDisabled: true,
            saveDisabled: true,
            addDisabled: true
        },
        lov: {
            lineDisabled: false,
            sublineDisabled: true,
            planDisabled: true,
            userTypeDisabled: true,
            paytOptDisabled: true
        }
    }

    public showForm = {
        paytOpt: true
    }

    /* Data Stuffs */

    private rowData: RowData = {
        paytOpt: null
    }

    private errorMessage: RowData = {
        paytOpt: null
    }

    private userIdLoggedIn: string;

    /* Form Object  */

    public forms = {
        lineSubLineLovForm: this.fb.group({
            lineCd: [null],
            sublineCd: [null],
            lineName: [null],
            subLineName: [null],
            planCd: [null],
            planDesc: [null],
            userType: [null],
            userTypeDesc: [null],
            lineCdWithDescDisplay: [{ value: '', disabled: 'true' }],
            subLineCdWithDescDisplay: [{ value: '', disabled: 'true' }],
            planCdWithDescDisplay: [{ value: '', disabled: 'true' }],
            userTypeCdWithDescDisplay: [{ value: '', disabled: 'true' }]
        }),
        paytOptForm: this.fb.group({
            lineCd: [null],
            sublineCd: [null],
            planCd: [null],
            userType: [null],
            paytCd: [null, [Validators.required]],
            paytDesc: [null],
            paytOptDisplay: [{ value: '', disabled: 'true' }],
            lastUpdateUser: [{ value: '', disabled: 'true' }],
            lastUpdate: [{ value: '', disabled: 'true' }]
        }),
        resetLineSubLineForm: function () {
            this.lineSubLineLovForm.reset();
        },
        resetPaytOptForm: () => {
            this.forms.paytOptForm.reset();
            this.btns.paytOpt.addDisabled = true;
            this.btns.paytOpt.deleteDisabled = true;
            this._formService.hideFormMsg("paytOpt-error-message");
            this.rowData.paytOpt = null;
        },
        resetAllForms: function () {
            this.resetLineSubLineForm();
            this.resetPaytOptForm();
        }
    };

    /* Table Object */

    public tblConfig: RowData = {
        paytOpt: {
            cols: [
                {
                    key: "PAYT_CD_PADDED",
                    header: "Payment Code",
                    dataType: "string",
                    width: "20%"
                },
                {
                    key: "PAYT_DESC",
                    header: "Payment Option",
                    dataType: "string",
                    width: "80%"
                }
            ],
            tblData: [] as any[],
            selection: "single",
            paginator: true,
            rowsPerPage: 10,
            lazy: false,
            totalRecords: 15,
            loading: false,
        },
    }

    private formManipulation = {
        paytOpt: {
            dbToForm: function (data: any) {
                return {
                    lineCd: data.LINE_CD,
                    sublineCd: data.SUBLINE_CD,
                    planCd: data.PLAN_CD,
                    userType: data.USER_TYPE,
                    payt_cd: data.PAYT_CD,
                    paytCdPadded: data.PAYT_CD_PADDED,
                    paytDesc: data.PAYT_DESC,
                    paytOptDisplay: (data.PAYT_CD) ? `${data.PAYT_CD.toString().padStart(2, 0)} - ${data.PAYT_DESC}` : data.PAYT_CD,
                    lastUpdateUser: data.LAST_USER,
                    lastUpdate: data.LAST_USER_UPDATE
                };
            },
            formToDb: function (data: any) {
                return {
                    LINE_CD: data.lineCd,
                    SUBLINE_CD: data.sublineCd,
                    PLAN_CD: data.planCd,
                    USER_TYPE: data.userType,
                    PAYT_CD: data.paytCd,
                    PAYT_CD_PADDED: (data.paytCd) ? data.paytCd.toString().padStart(2, 0) : null,
                    PAYT_DESC: data.paytDesc,
                    LAST_USER: null,
                    LAST_USER_UPDATE: null,
                };
            },
        }
    }

    /* Open Lov Time */

    public lovLine(LOVOPTIONS: any) {
        this.forms.resetPaytOptForm();
        this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
            let output = dataOutput.content;
            if (output) {
                this.forms.lineSubLineLovForm.patchValue({
                    lineCd: output.LINE_CD,
                    lineName: output.LINE_NAME,
                    lineCdWithDescDisplay: `${output.LINE_CD} - ${output.LINE_NAME}`,
                    sublineCd: null,
                    subLineName: null,
                    subLineCdWithDescDisplay: null,
                    planCd: null,
                    planDesc: null,
                    planCdWithDescDisplay: null,
                    userType: null,
                    userTypeDesc: null,
                    userTypeCdWithDescDisplay: null
                    
                });
                this.forms.paytOptForm.patchValue({
                    lineCd: output.LINE_CD,
                    sublineCd: null,
                    planCd: null,
                    userType: null
                });
                this.btns.lov.sublineDisabled = false;
                this.btns.lov.planDisabled = true;
                this.btns.lov.userTypeDisabled = true;
                this.btns.lov.paytOptDisabled = true;
                this.forms.paytOptForm.disable();
            } else {
                this.forms.lineSubLineLovForm.patchValue({
                    lineCd: null,
                    lineName: null,
                    lineCdWithDescDisplay: null,
                    sublineCd: null,
                    subLineName: null,
                    subLineCdWithDescDisplay: null,
                    planCd: null,
                    planDesc: null,
                    planCdWithDescDisplay: null,
                    userType: null,
                    userTypeDesc: null,
                    userTypeCdWithDescDisplay: null
                });
                this.forms.paytOptForm.patchValue({
                    lineCd: null,
                    sublineCd: null,
                    planCd: null,
                    userType: null
                });
                //this.btns.paytOpt.addDisabled = true;
                this.btns.lov.sublineDisabled = true;
                this.btns.lov.planDisabled = true;
                this.btns.lov.userTypeDisabled = true;
                this.btns.lov.paytOptDisabled = true;
                this.forms.paytOptForm.disable();
            }
            this.tblConfig.paytOpt.tblData = [];
            this.toBeDeleted = [];
            this.btns.paytOpt.saveDisabled = true;
        });
    }

    public lovSubline(LOVOPTIONS: any) {
        //this.forms.resetPaytOptForm();
        LOVOPTIONS.data.lineCd = this.forms.lineSubLineLovForm.get('lineCd')?.value;
        this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
            let output = dataOutput.content;
            if (output) {
                this.forms.lineSubLineLovForm.patchValue({
                    lineCd: output.LINE_CD,
                    lineName: output.LINE_NAME,
                    lineCdWithDescDisplay: `${output.LINE_CD} - ${output.LINE_NAME}`,
                    sublineCd: output.SUBLINE_CD,
                    subLineName: output.SUBLINE_NAME,
                    subLineCdWithDescDisplay: `${output.SUBLINE_CD} - ${output.SUBLINE_NAME}`,
                    planCd: null,
                    planDesc: null,
                    planCdWithDescDisplay: null,
                    userType: null,
                    userTypeDesc: null,
                    userTypeCdWithDescDisplay: null
                });
                this.forms.paytOptForm.patchValue({
                    lineCd: output.LINE_CD,
                    sublineCd: output.SUBLINE_CD,
                    planCd: null,
                    userType: null,
                });
                //this.btns.paytOpt.addDisabled = false;
                this.btns.lov.planDisabled = false;
                this.btns.lov.userTypeDisabled = true;
                this.btns.lov.paytOptDisabled = true;
                this.forms.paytOptForm.disable();
            } else {
                this.forms.lineSubLineLovForm.patchValue({
                    sublineCd: null,
                    subLineName: null,
                    subLineCdWithDescDisplay: null,
                    planCd: null,
                    planDesc: null,
                    planCdWithDescDisplay: null,
                    userType: null,
                    userTypeDesc: null,
                    userTypeCdWithDescDisplay: null
                });
                this.forms.paytOptForm.patchValue({
                    lineCd: null,
                    sublineCd: null,
                    planCd: null,
                    userType: null,
                });
                //this.btns.paytOpt.addDisabled = true;
                this.btns.lov.planDisabled = true;
                this.btns.lov.userTypeDisabled = true;
                this.btns.lov.paytOptDisabled = true;
                this.forms.paytOptForm.disable();
            }
            // this.getData.plan();
            this.tblConfig.paytOpt.tblData = [];
            this.toBeDeleted = [];
            this.btns.paytOpt.saveDisabled = true;
        });
    }

    public lovPlan(LOVOPTIONS: any) {
        LOVOPTIONS.data.lineCd = this.forms.lineSubLineLovForm.get('lineCd')?.value;
        LOVOPTIONS.data.sublineCd = this.forms.lineSubLineLovForm.get('sublineCd')?.value;
        this._matDialog.open(PlanLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
            let output = dataOutput.content;
            if (dataOutput.button === 'OK') {
                if (output) {
                    this.forms.lineSubLineLovForm.patchValue({
                        planCd: output.PLAN_CD,
                        planDesc: output.PLAN_NAME,
                        planCdWithDescDisplay: `${output.PLAN_CD.toString().padStart(2, 0)} - ${output.PLAN_NAME}`,
                        userType: null,
                        userTypeDesc: null,
                        userTypeCdWithDescDisplay: null
                    });
                    this.forms.paytOptForm.patchValue({
                        planCd: output.PLAN_CD,
                        userType: null
                    });
                    this.btns.lov.userTypeDisabled = false;
                    this.btns.lov.paytOptDisabled = true;
                    this.forms.paytOptForm.disable();
                } else {
                    this.forms.lineSubLineLovForm.patchValue({
                        planCd: null,
                        planDesc: null,
                        planCdWithDescDisplay: null,
                        userType: null,
                        userTypeDesc: null,
                        userTypeCdWithDescDisplay: null
                    });
                    this.forms.paytOptForm.patchValue({
                        planCd: null,
                        userType: null
                    });
                    this.btns.lov.userTypeDisabled = true;
                    this.btns.lov.paytOptDisabled = true;
                    this.forms.paytOptForm.disable();
                }
            }
            this.tblConfig.paytOpt.tblData = [];
            this.toBeDeleted = [];
            this.btns.paytOpt.saveDisabled = true;
        });
    }

    public lovUserType(LOVOPTIONS: any) {
        this._matDialog.open(AccountTypeLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
            let output = dataOutput.content;
            if (dataOutput.button === 'OK') {
                if (output) {
                    this.forms.lineSubLineLovForm.patchValue({
                        userType: output.USER_TYPE,
                        userTypeDesc: output.USER_TYPE_DESC,
                        userTypeCdWithDescDisplay: `${output.USER_TYPE} - ${output.USER_TYPE_DESC}`,
                    });
                    this.forms.resetPaytOptForm(); // Testing baka gumana
                    this.forms.paytOptForm.patchValue({
                        userType: output.USER_TYPE
                    });
                    this.toBeDeleted = [];
                    this.getData.paytOpt();
                } else {
                    this.forms.lineSubLineLovForm.patchValue({
                        userType: null,
                        userTypeDesc: null,
                        userTypeCdWithDescDisplay: null,
                    });
                    this.forms.resetPaytOptForm(); // Testing baka gumana
                    this.forms.paytOptForm.patchValue({
                        userType: null
                    });
                }
            }
            this.btns.paytOpt.saveDisabled = true;
        });
    }

    public openLov(lovToOpen: string) {
        try {
            let LOVOPTIONS: { [key: string]: any } = {
                disableClose: true,
                width: '512px',
                data: {
                table: lovToOpen,
                moduleId: this.moduleId
                },
            }
            switch (lovToOpen.toUpperCase()) {
                case "LINE":
                    if (this.forms.paytOptForm.dirty) {
                        this._matDialog.open(CheckLovComponent, {
                            disableClose: true,
                            width: '512px',
                            data: {
                                title: "Confirmation Message",
                                content:
                                    "You have unsaved changes. Do you want to continue?"
                            }
                        }).afterClosed().subscribe((a:any) => {
                            if (a) {
                                this.forms.paytOptForm.markAsPristine();
                                this.lovLine(LOVOPTIONS);
                            }
                        });
                    } else {
                        this.lovLine(LOVOPTIONS);
                    }
                    break;
                case "SUBLINE":
                    if (this.forms.paytOptForm.dirty) {
                        this._matDialog.open(CheckLovComponent, {
                            disableClose: true,
                            width: '512px',
                            data: {
                                title: "Confirmation Message",
                                content:
                                    "You have unsaved changes. Do you want to continue?"
                            
                            }
                        }).afterClosed().subscribe((a:any) => {
                            if (a) {
                                this.forms.paytOptForm.markAsPristine();
                                this.lovSubline(LOVOPTIONS);
                            }
                        });
                    } else {
                        this.lovSubline(LOVOPTIONS);
                    }
                    break;
                case "PLAN": // recode pa
                    if (this.forms.paytOptForm.dirty) {
                        this._matDialog.open(CheckLovComponent, {
                            disableClose: true,
                            width: '512px',
                            data: {
                                title: "Confirmation Message",
                                content:
                                    "You have unsaved changes. Do you want to continue?"
                            
                            }
                        }).afterClosed().subscribe((a:any) => {
                            if (a) {
                                this.forms.paytOptForm.markAsPristine();
                                this.lovPlan(LOVOPTIONS);
                            }
                        });
                    } else {
                        this.lovPlan(LOVOPTIONS);
                    }
                    break;
                case "USERTYPE": // recode pa
                    if (this.forms.paytOptForm.dirty) {
                        this._matDialog.open(CheckLovComponent, {
                            disableClose: true,
                            width: '512px',
                            data: {
                                title: "Confirmation Message",
                                content:
                                    "You have unsaved changes. Do you want to continue?"
                            
                            }
                        }).afterClosed().subscribe((a:any) => {
                            if (a) {
                                this.forms.paytOptForm.markAsPristine();
                                this.lovUserType(LOVOPTIONS);
                            }
                        });
                    } else {
                        this.lovUserType(LOVOPTIONS);
                    }
                    break;
                case "PAYTOPT":
                    LOVOPTIONS.data.rows = this.tblConfig.paytOpt.tblData;
                    this._matDialog.open(PaymentOptionsLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
                        let output = dataOutput.content;
                        if (dataOutput.button === 'OK') {
                            if (output) {
                                this.forms.paytOptForm.patchValue({
                                    paytCd: output.PAYT_CD,
                                    paytDesc: output.PAYT_DESC,
                                    paytOptDisplay: `${output.PAYT_CD.toString().padStart(2, 0)} - ${output.PAYT_DESC}`
                                });
                                this.btns.paytOpt.addDisabled = false;
                            } else {
                                this.forms.paytOptForm.patchValue({
                                    paytCd: null,
                                    paytDesc: null,
                                    paytOptDisplay: null
                                });
                                this.btns.paytOpt.addDisabled = true;
                            }
                        }
                    });
                    break;
            }
        } catch (e) {
    console.error(e);
        }
    }

    private getData = {
        paytOpt: () => { //recode pa
            try {
                this.tblConfig.paytOpt.tblData = [];
                if (this.forms.lineSubLineLovForm.get('lineCd')?.value && this.forms.lineSubLineLovForm.get('sublineCd')?.value) {
                    this.tblConfig.paytOpt.loading = true;
                    this._jsonDS.contorlLoading(true);
                    this._api.getPaytOptAccess({
                        moduleId: this.moduleId,
                        userId: this._userDetailService.userId,
                        type: "MODULE"
                    }).subscribe({
                        next: (response: any) => {
                            this._securityService.checkRequestKeyResponse(response, () => {
                                this._securityService.hasValidCsrfToken(response, () => {
                                    try {
                                        if (response.status === 'SUCCESS') {
                                            response = JSON.parse(this._jsonDS.decrypt(response.response));
                                            this._jsonDS.contorlLoading(false);
                                            let filteredData = response.data.filter((d: any) => (d.LINE_CD == this.forms.lineSubLineLovForm.get('lineCd')?.value 
                                                && d.SUBLINE_CD == this.forms.lineSubLineLovForm.get('sublineCd')?.value 
                                                && d.PLAN_CD == this.forms.lineSubLineLovForm.get('planCd')?.value)
                                                && d.USER_TYPE == this.forms.lineSubLineLovForm.get('userType')?.value);
                                            this.tblConfig.paytOpt.tblData = filteredData;
                                            this.btns.lov.paytOptDisabled = false;
                                            this.toBeDeleted = [];
                                        } else {
                                            this.tblConfig.paytOpt.loading = false;
                                            this._jsonDS.contorlLoading(false);
                                            this.btns.lov.paytOptDisabled = true;
                                            this.toBeDeleted = [];
                                            this._appMessageService.showAppMessage(response.message, "error");
                                        }
                                    } catch (e) {
    console.error(e);
                                    }
                                    this.tblConfig.paytOpt.loading = false;
                                });
                            });
                        }, error: (e: any) => {
                            this.tblConfig.paytOpt.loading = false;
                            this._jsonDS.contorlLoading(false);
                        }
                    });
                } else {
                    this.tblConfig.paytOpt.loading = false;
                    this._jsonDS.contorlLoading(false);
                }
            } catch (e) {
    console.error(e);
                this.tblConfig.paytOpt.loading = false;
                this._jsonDS.contorlLoading(false);
            }
        }
    }

    public onRowClick = {
        paytOpt: (ev: any) => {
            try {
                //ev.update = true;
                this.rowData.paytOpt = ev;
                if (ev != null) {
                    this.populateFormFromTable.paytOpt();
                    this.btns.paytOpt.deleteDisabled = false;
                } else {
                    this.forms.resetPaytOptForm();    
                    this.btns.paytOpt.deleteDisabled = true;
                }
                
            } catch (e) {
    console.error(e);
                this.forms.resetPaytOptForm();
                this.rowData.paytOpt = null;
            }
        }
    }

    private populateFormFromTable = {
        paytOpt: () => {
            try {
                let data = this.rowData.paytOpt;
                //this.btns.paytOpt.updateDisabled = !data.update;
                this.btns.paytOpt.deleteDisabled = !data.temp;
                this.forms.paytOptForm.patchValue(this.formManipulation.paytOpt.dbToForm(data));
            } catch (e) {
    console.error(e);
            }
        }
    }

    public onAdd = {
        paytOpt: () => {
            try {
                this._formService.hideFormMsg("paytOpt-error-message");
                
                //if (this.forms.paytOptForm.valid) {
                    this.forms.paytOptForm.patchValue({
                        lineCd: this.forms.lineSubLineLovForm.get('lineCd')?.value,
                        sublineCd: this.forms.lineSubLineLovForm.get('sublineCd')?.value,
                        planCd : this.forms.lineSubLineLovForm.get('planCd')?.value,
                        userType: this.forms.lineSubLineLovForm.get('userType')?.value,
                    });
                    let rowToBeAdded: { [key: string]: any } = this.formManipulation.paytOpt.formToDb(this.forms.paytOptForm.value);
                    var del = this.toBeDeleted.map(function (row:any) { return row.PAYT_CD; }).indexOf(rowToBeAdded.PAYT_CD);
                    if (del != -1) {
                        this.toBeDeleted.splice(del,1);
                    }
                    rowToBeAdded.temp = true;
                    this.tblConfig.paytOpt.tblData = [rowToBeAdded, ...this.tblConfig.paytOpt.tblData];
                    this.forms.resetPaytOptForm();
                    this.btns.paytOpt.saveDisabled = false;
                    this.forms.paytOptForm.markAsDirty();
                    
                    
                /*} else {
                    this.showErrorValidator.paytOpt();
                    console.log(this.forms.paytOptForm);
                }*/
            } catch (e) {
    console.error(e);
            }
        }
    }

    public onDelete = {
        paytOpt: () => {
            try {
                this.tblConfig.paytOpt.tblData = this.tblConfig.paytOpt.tblData.filter((d: any) => {
                    return d !== this.rowData.paytOpt;
                });

                this.toBeDeleted.push(this.rowData.paytOpt);
                this.forms.resetPaytOptForm();
                this.btns.paytOpt.saveDisabled = false;
                this.forms.paytOptForm.markAsDirty();
            } catch (e) {
    console.error(e);
            }
        }
    }

    /*public onUpdate = {
        paytOpt: () => {
            try {
                this._formService.hideFormMsg("paytOpt-error-message");
                if (this.forms.paytOptForm.valid) {
                    let toBeUpdatedIndex = this.tblConfig.paytOpt.tblData.indexOf(this.rowData.paytOpt);
                    let rowToBeUpdated: { [key: string]: any } = this.formManipulation.paytOpt.formToDb(this.forms.paytOptForm.value);
                    if (this.rowData.paytOpt.temp) {
                        rowToBeUpdated.temp = true;
                        
                    } else {
                        rowToBeUpdated.onDbButUpdatedTemp = true;
                        
                    }
                    this.forms.resetPaytOptForm();
                    this.tblConfig.paytOpt.tblData[toBeUpdatedIndex] = rowToBeUpdated;
                    this.btns.paytOpt.saveDisabled = false;
                    this.tblConfig.paytOpt.tblData = [... this.tblConfig.paytOpt.tblData];
                } else {
                    
                    this.showErrorValidator.paytOpt();
                }
            } catch (e) {
    console.error(e);
                console.log("Error @ onUpdate.paytOpt() paytOpt-mtn");
            }
        }
    }*/

    public onSave = { //recode pa
        paytOpt: () => {
            this.showForm.paytOpt = false;
            this._formService.showFormLoader(null, "paytOpt-maintenance-loading", "Saving.<br>Please wait ...", null, null);
            let toBeSaved = this.tblConfig.paytOpt.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true));
            if (toBeSaved.length > 0 || this.toBeDeleted.length > 0) {
                toBeSaved.map((d: any) => {
                    d.LAST_USER = this.userIdLoggedIn;
                });
                this._api.savePaytOptAccess({
                    tobeSaved: toBeSaved,
                    tobeDeleted: this.toBeDeleted
                }).subscribe({
                    next: (response: any) => {
                        this._securityService.checkRequestKeyResponse(response, () => {
                            this._securityService.hasValidCsrfToken(response, () => {
                                if (response.status === 'SUCCESS') {
                                    this.onComplete.paytOpt();
                                } else { 
                                    this.onComplete.paytOptError();
                                }
                            });
                        });
                    }, error: (response: any) => {
                        this.onComplete.paytOptError();
                    },
                });
            } else {
                this.onComplete.paytOpt();
            }
        }
    }

    openDialog(title: string, contentTitle: string, contentDetail: string) {
        try {
            this._matDialog.open(DialogMsgComponent, {
                disableClose: true,
                width: '512px',
                data: {
                title: title,
                content:
                    title === 'SUCCESS'
                    ? `${contentTitle} details successfully ${contentDetail}!`
                    : `${contentTitle} details ${contentDetail} failed!`,
                },
            });
        } catch (e) {
    console.error(e);
        }
    }

    private onComplete = {
        paytOpt: () => {
            this.getData.paytOpt();
            this.btns.paytOpt.saveDisabled = true;
            this.showForm.paytOpt = true;
            this.toBeDeleted = [];
            this._formService.hideFormLoader("paytOpt-maintenance-loading");
            this.openDialog('SUCCESS', 'Payment Options Access', 'saved');
            this.forms.paytOptForm.markAsPristine();
        },
        paytOptError: () => {
            this.showForm.paytOpt = true;
            this.btns.paytOpt.saveDisabled = false;
            this.openDialog('FAILED', 'Payment Options Access', 'saving');
            this._formService.hideFormLoader("paytOpt-maintenance-loading");
        }
    }

    /* Validator Stuffs Down here */

    /*checkDuplicatePlanName(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (!value) return null;
        let hasDuplicate: boolean = this.tblConfig.paytOpt.tblData.filter((d: any) => {
            if (!this.rowData.paytOpt)
            return d.PLAN_NAME === value;
            else
            return this.rowData.paytOpt.PLAN_NAME !== value && d.PLAN_NAME === value;
        }).length > 0 ? true : false;
        return hasDuplicate ? { hasDupe: true } : null;
        }
    }

    checkDuplicatePeril(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (!value) return null;
        let hasDuplicate: boolean = this.tblConfig.planPeril.tblData.filter((d: any) => {
            if (!this.rowData.planPeril)
            return d.PERIL_CD === value;
            else
            return this.rowData.planPeril.PERIL_CD !== value && d.PERIL_CD === value;
        }).length > 0 ? true : false;
        return hasDuplicate ? { hasDupe: true } : null;
        }
    }

    checkDuplicateSeqNo(module: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (!value) return null;
        let hasDuplicate: boolean = this.tblConfig[module as keyof RowData].tblData.filter((d: any) => {
            if (!this.rowData[module as keyof RowData])
            return d.SEQ_NO == value;
            else
            return this.rowData[module as keyof RowData].SEQ_NO != value && d.SEQ_NO == value;
        }).length > 0 ? true : false;
        return hasDuplicate ? { hasDupeSeqNo: true } : null;
        }
    }*/

    public showErrorValidator = {
        paytOpt: () => {
            try {
                Object.keys(this.forms.paytOptForm.controls).forEach(key => {
                    const controlErrors = this.forms.paytOptForm.get(key)?.errors;
                    if (controlErrors != null) {
                        Object.keys(controlErrors).forEach(keyError => {
                        if (keyError == 'hasDupe' && controlErrors[keyError] === true)
                            this.errorMessage.paytOpt = "Unable to add record. Plan Name for this Line and Subline already exists.";
                        else if (keyError == 'required' && controlErrors[keyError] === true)
                            this.errorMessage.paytOpt = "There are missing information. Please provide necessary information needed.";
                        else if (keyError == 'hasDupeSeqNo' && controlErrors[keyError] === true)
                            this.errorMessage.paytOpt = "Sequence No. already exists. Please choose a different Sequence No.";
                        });
                    }
                });
                this._formService.showFormMsg("paytOpt-error-message", this.errorMessage.paytOpt, "E");
            } catch (e) {
    console.error(e);
            }
        }
    }
}
