<section id="src-cd-per-dealership-maint">    
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <div class="form-content">
            <form [formGroup]="forms.agentLovForm" class="form">
                <div class="form-container-grid top-form">
                    <div class="field required">
                        <label>Dealership</label>
                    </div>
                    <div class="">
                        <input formControlName="agentCdDisp" >
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['agent']()">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <!-- Container for Source Code Per Dealership Table using prime ng -->
        <div class = 'table-user'>
            <osp-table [tblConfig]="srcCdPerDealershipTblConfig" (onRowClick)="onRowClick($event)"></osp-table>
        </div>
        <!-- Loading Animation -->
        <div id="src-cd-per-dealership-maintenance-loading">
            <div class="form-loader"></div>
        </div>
        <div class="form-content" *ngIf="showForm">
            <form [formGroup]="forms.srcCdPerDealershipForm" class="form">
                <div id="src-cd-per-dealership-error-message"></div>
                <div class="form-container-grid">
                    <div class="field required">
                        <label>Source Code</label>
                    </div>
                    <div class="span lov-search">
                        <input type="text" readonly formControlName="srcCdField">
                        <button type="button" class="btn-search btn" (click)="openLov['srcCd']()" [disabled]='btns.srcCdPerDealership.srcCdDisabled'>
                            <mat-icon>
                                search
                            </mat-icon>
                        </button>
                    </div>

                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag">
                            <option *ngFor="let activeType of selections.activeTypes" [value]="activeType.cd">
                                {{activeType.name}}
                            </option>
                        </select>
                    </div>

                    <div></div>
                    <div></div>

                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" type="text" maxlength="4000">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUser">
                    </div>
                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUpdate">
                    </div>
                </div>
            </form>
            <div class="btn-row">
                <button *ngIf="btns.srcCdPerDealership.updateDisabled" class="btn btn2" type="button" [disabled]='btns.srcCdPerDealership.addDisabled' (click)="onAdd()">ADD</button>
                <button *ngIf="!btns.srcCdPerDealership.updateDisabled" class="btn btn2" type="button" (click)="onUpdate()">UPDATE</button>
                <button class="btn btn2" type="button" [disabled]='btns.srcCdPerDealership.deleteDisabled' (click)="onDelete()">DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-row">
                <button class="btn btn2" type="button" [disabled]='btns.srcCdPerDealership.saveDisabled' (click)="onSave()">SAVE</button>
            </div>
        </div>
    </div>
</section>