
<mat-card>
    
    <!-- Header -->

    <!-- Contents -->
    <mat-card-content>
        <section id="agent-dialog">
            <div class="mtn-title">
                {{ data.title }}
            </div>
        </section>

        <!-- Table Contents -->
        <div>
            <osp-table [tblConfig]="tableConfig" (onRowClick)="onRowClick($event)"></osp-table>
            
            <div class="button">
                <button mat-raised-button class="btn2" type="button" mat-dialog-close>Back</button>
                <button mat-raised-button class="btn2" type="button" mat-dialog-close (click)="onClose()">OK</button>
            </div>
        </div>

    </mat-card-content>

</mat-card>
