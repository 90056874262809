import { Component, OnInit } from '@angular/core';

import { APICallService } from 'src/app/services/api-call.service'
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';

import { Observable } from 'rxjs';

import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';

@Component({
  selector: 'tax-maintenance',
  templateUrl: './tax-maintenance.component.html',
  styleUrls: ['./tax-maintenance.component.css']
})
export class TaxMaintenanceComponent implements OnInit, LeaveConfirmation {

  datePipe = new DatePipe('en-us');

  addDisabled: boolean = false;
  saveDisabled: boolean = true;
  deleteDisabled: boolean = true;
  userIDReadOnly: boolean = false;
  tranPosted: boolean = false;
  noLoadingInProcess: boolean = true;
  validated: boolean = false;
  submitted: boolean = false;
  isTaxSelected: boolean = false;
  isSuccessSaved: boolean = false;
  taxNameField: string = '';
  taxShortNameField: string = '';
  currentUser: string = this.jsonDataService.retrieveFromStorage('userId');
  rowClickedData: any;
  effDate = new FormControl(new Date());
  tempAddedData: any = [];
  tempUpdateData: any = [];
  moduleId: string = 'BMM031';
  public moduleName: string = this.jsonDataService.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc?.toUpperCase() ?? "";

  taxForm = this.fb.group({
    taxCd: [null],
    taxCode: [null],
    activeTag: ['A', [Validators.required]],
    taxName: [null, [Validators.required]],
    ocTag: ['N'],
    taxShortName: [null, [Validators.required]],
    remarks: [null],
    userID: [null],
    lastUserUpdate: [null],
    mode: [null]
  });

  constructor(
    private _APICallService: APICallService,
    public _Dialog: MatDialog,
    private fb: FormBuilder,
    private formService: FormService,
    private appMessageService: AppMessageService,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService,
    private userDetailService: UserDetailsService
  ) { }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.tempAddedData != '' || this.tempUpdateData != '') {
      return false;
    } else {
      return true;
    }
  }

  tblConfigTax: any = {
    cols: [
      {
        key: "TAX_CODE",
        header: "Tax Code",
        dataType: "string",
        width: '12%'
      },
      {
        key: "TAX_NAME",
        header: "Tax Name",
        dataType: "string",
        disabled: false
      },
      {
        key: "TAX_SNAME",
        header: "Tax Short Name",
        dataType: "string",
        disabled: false,
        width: '20%'
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        disabled: true,
        width: '64px'
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15
  }

  ngOnInit(): void {
    try {
      this._APICallService.refreshNeeded$
        .subscribe(() => {
          this.getAllTax();
        })
      this.getAllTax();
    }
    catch (e) {
    console.error(e);
    }
  }

  initOnGet(): void {
    try {
      this._APICallService.refreshNeeded$
        .subscribe(() => {
          this.getAllTax();
        })
      this.getAllTax();
    }
    catch (e) {
    console.error(e);

    }
  }

  getAllTax() {
    try {
      this.tblConfigTax.loading = true;
      this.jsonDataService.contorlLoading(true);
      this._APICallService.getAllTax({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE"
      }).subscribe((response: any) => {
        this.securityService.checkRequestKeyResponse(response, () => {
          this.securityService.hasValidCsrfToken(response, () => {
            if (response.status === 'SUCCESS') {
              let data = JSON.parse(this.jsonDataService.decrypt(response.response));
              this.jsonDataService.contorlLoading(false);
              this.tblConfigTax.loading = false;
              this.tblConfigTax.tblData = data.data;
            } else {
              this.jsonDataService.contorlLoading(false);
              this.tblConfigTax.loading = false;
              this.appMessageService.showAppMessage(response.message, "error");
            }
          });
        });
      });
    }
    catch (e) {
    console.error(e);

    }
  }

  insertTax() {
    try {
      this.tranPosted = true;
      this.validateInsert();
      if (this.validated) {
        this.addFormToTable();
      }
      this.tblConfigTax.tblData = [...this.tblConfigTax.tblData];
    }
    catch (e) {
    console.error(e);

    }
  }

  addFormToTable() {
    try {
      this.taxForm.patchValue({
        activeTag: this.taxForm.get('activeTag')?.value,
        userID: this.currentUser,
        lastUserUpdate: new Date()
      });
      let formToData: any = { // this.datePipe.transform(new Date(), 'MM/dd/yyyy')
        TAX_CD: this.taxForm.get('taxCd')?.value,
        TAX_CODE: this.taxForm.get('taxCode')?.value,
        ACTIVE_TAG: this.taxForm.get('activeTag')?.value,
        TAX_NAME: this.taxForm.get('taxName')?.value,
        OC_TAG: this.taxForm.get('ocTag')?.value,
        TAX_SNAME: this.taxForm.get('taxShortName')?.value?.toUpperCase(),
        REMARKS: this.taxForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.taxForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.taxForm.get('userID')?.value,
        MODE: 'I'
      }
      this.tblConfigTax.tblData.unshift(formToData);
      this.tempAddedData.push(formToData);
      this.resetFormValues();
      this.appMessageService.showAppMessage("Tax details added!", "success");
    }
    catch (e) {
    console.error(e);

    }
  }

  updateTax() {
    try {
      this.tranPosted = true;
      this.validateUpdate();
      if (this.validated) {
        this.updateFormTable();
      }
      this.tblConfigTax.tblData = [...this.tblConfigTax.tblData];
    }
    catch (e) {
    console.error(e);

    }
  }

  updateFormTable() {
    try {
      let tempInsertChecker = this.tempAddedData.find(
        (tax: { TAX_NAME: any; }) => tax.TAX_NAME == this.taxForm.get('taxName')?.value);
      let tempUpdateChecker = this.tempUpdateData.find(
        (tax: { TAX_NAME: any; }) => tax.TAX_NAME == this.taxForm.get('taxName')?.value);

      this.tableDataUpdate();
      if (tempInsertChecker != null && tempInsertChecker != '') {
        tempInsertChecker.TAX_CD = this.taxForm.get('taxCd')?.value;
        tempInsertChecker.TAX_CODE = this.taxForm.get('taxCode')?.value;
        tempInsertChecker.ACTIVE_TAG = this.taxForm.get('activeTag')?.value;
        tempInsertChecker.TAX_NAME = this.taxForm.get('taxName')?.value;
        tempInsertChecker.OC_TAG = this.taxForm.get('ocTag')?.value;
        tempInsertChecker.TAX_SNAME = this.taxForm.get('taxShortName')?.value?.toUpperCase();
        tempInsertChecker.REMARKS = this.taxForm.get('remarks')?.value;
        tempInsertChecker.USER_ID = this.taxForm.get('userID')?.value;
        tempInsertChecker.MODE = 'I';
      }
      else if (tempUpdateChecker != null && tempUpdateChecker != '') {
        tempUpdateChecker.TAX_CD = this.taxForm.get('taxCd')?.value;
        tempUpdateChecker.TAX_CODE = this.taxForm.get('taxCode')?.value;
        tempUpdateChecker.ACTIVE_TAG = this.taxForm.get('activeTag')?.value;
        tempUpdateChecker.TAX_NAME = this.taxForm.get('taxName')?.value;
        tempUpdateChecker.OC_TAG = this.taxForm.get('ocTag')?.value;
        tempUpdateChecker.TAX_SNAME = this.taxForm.get('taxShortName')?.value?.toUpperCase();
        tempUpdateChecker.REMARKS = this.taxForm.get('remarks')?.value;
        tempUpdateChecker.USER_ID = this.taxForm.get('userID')?.value;
        tempUpdateChecker.MODE = 'U';
      }
      else {
        this.tempUpdateData.push(this.formValueToData());
      }
      this.saveDisabled = false;
      this.resetFormValues();
      this.appMessageService.showAppMessage("Tax details updated!", "success");
    }
    catch (e) {
    console.error(e);

    }
  }

  tableDataUpdate() {
    try {
      this.taxForm.patchValue({
        activeTag: this.taxForm.get('activeTag')?.value,
        userID: this.currentUser,
        lastUserUpdate: new Date()
      });
      let updateIndex = this.tblConfigTax.tblData.indexOf(this.rowClickedData);
      this.tblConfigTax.tblData[updateIndex] = {
        TAX_CD: this.taxForm.get('taxCd')?.value,
        TAX_CODE: this.taxForm.get('taxCode')?.value,
        ACTIVE_TAG: this.taxForm.get('activeTag')?.value,
        TAX_NAME: this.taxForm.get('taxName')?.value,
        OC_TAG: this.taxForm.get('ocTag')?.value,
        TAX_SNAME: this.taxForm.get('taxShortName')?.value?.toUpperCase(),
        REMARKS: this.taxForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.taxForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.taxForm.get('userID')?.value,
        MODE: 'U'
      };
    }
    catch (e) {
    console.error(e);

    }
  }

  formValueToData() {
    try {
      let formToData: any = {
        TAX_CD: this.taxForm.get('taxCd')?.value,
        TAX_CODE: this.taxForm.get('taxCode')?.value,
        ACTIVE_TAG: this.taxForm.get('activeTag')?.value,
        TAX_NAME: this.taxForm.get('taxName')?.value,
        OC_TAG: this.taxForm.get('ocTag')?.value,
        TAX_SNAME: this.taxForm.get('taxShortName')?.value?.toUpperCase(),
        REMARKS: this.taxForm.get('remarks')?.value,
        USER_ID: this.taxForm.get('userID')?.value,
        MODE: 'U'
      }
      return formToData;
    }
    catch (e) {
    console.error(e);

    }
  }

  toSave() {
    try {
      this.noLoadingInProcess = false;
      this.formService.showFormLoader(null, "generate-tax-form", "Please wait ...", null, null, true);
      this.jsonDataService.contorlLoading(true);
      this._APICallService.saveTax(this.tempAddedData).subscribe({
        next: (response: any) => {
          this.securityService.checkRequestKeyResponse(response, () => {
            this.securityService.hasValidCsrfToken(response, () => {
              if (response.status == "SUCCESS" && this.tranPosted) {
                this._APICallService.saveTax(this.tempUpdateData).subscribe({
                  next: (response: any) => {
                    this.securityService.checkRequestKeyResponse(response, () => {
                      this.securityService.hasValidCsrfToken(response, () => {
                        if (response.status == "SUCCESS" && this.tranPosted) {
                          this.jsonDataService.contorlLoading(false);
                          this.openDialog('SUCCESS', 'Tax details Successfully saved!');
                          this.initOnGet();
                          this.tempAddedData = [];
                          this.tempUpdateData = [];
                          this.resetFormValues();
                        }
                        else {
                          this.jsonDataService.contorlLoading(false);
                          this.openDialog('ERROR', 'An error has occured while saving details for tax');
                        }
                      });
                    });
                  },
                  error: (e: any) => {
                    this.jsonDataService.contorlLoading(false);
                    this.openDialog('ERROR', 'An error has occured while saving details for tax');
                  }
                });
              }
              else {
                this.jsonDataService.contorlLoading(false);
                this.openDialog('ERROR', 'An error has occured while saving details for tax');
              }
            });
          });
        },
        error: (e: any) => {
          this.jsonDataService.contorlLoading(false);
          this.openDialog('ERROR', 'An error has occured while saving details for tax');
        }
      });
      // if (this.tempAddedData.length !==0) {
      //   this._APICallService.saveTax(this.tempAddedData)
      //   .subscribe({
      //     next: (response : any) => {
      //       this.securityService.checkRequestKeyResponse(response, () => {
      //         if (response.status == "SUCCESS" && this.tranPosted) {
      //           this.openDialog('SUCCESS', 'New tax details Successfully saved!');
      //           this.initOnGet();
      //         }   
      //         else {
      //           this.openDialog('ERROR', 'An error has occured while saving details for new tax');
      //         }
      //       });
      //     },
      //     error: () => {
      //       this.openDialog('ERROR', 'An error has occured while saving details for new tax');
      //     }
      //   });
      // }

      // if (this.tempUpdateData.length !== 0) {
      //   this._APICallService.saveTax(this.tempUpdateData)
      //   .subscribe({
      //     next: (response : any) => {
      //       this.securityService.checkRequestKeyResponse(response, () => {
      //         if (response.status == "SUCCESS" && this.tranPosted) {
      //           this.openDialog('SUCCESS', 'Existing tax details successfully saved!');
      //           this.initOnGet();
      //         }  
      //         else {
      //           this.openDialog('ERROR', 'An error has occured while saving details for existing tax');
      //         }
      //       });
      //     }, 
      //     error: () => {
      //       this.openDialog('ERROR', 'An error has occured while saving details for existing tax');
      //     }
      //   });
      // }

      // this.resetFormValues();
      // this.tempAddedData = [];
      // this.tempUpdateData = [];
    } catch (e) {
    console.error(e);

    }
  }

  deleteTax() {
    try {
      let clickData = this.rowClickedData;
      this.tblConfigTax.tblData = this.tblConfigTax.tblData.filter(
        function (e: any) {
          return e.TAX_NAME.toUpperCase() != clickData.TAX_NAME.toUpperCase();
        }
      );
      this.tempAddedData = this.tempAddedData.filter(
        function (e: any) {
          return e.TAX_NAME.toUpperCase() != clickData.TAX_NAME.toUpperCase();
        }
      );
      this.resetFormValues();
    }
    catch (e) {
    console.error(e);

    }
  }

  saveChecker() {
    try {
      if ((this.tempAddedData == null || this.tempAddedData == '') && (this.tempUpdateData == null || this.tempUpdateData == '')) {
        return true;
      }
      else {
        return false;
      }
    }
    catch (e) {
    console.error(e);

      return true;
    }
  }

  deleteChecker(ev: any) {
    try {
      let tempDataFlag;
      tempDataFlag = this.tempAddedData.filter(function (e: any) {
        return e.TAX_CD == ev.TAX_CD;
      });
      if (tempDataFlag == '' || tempDataFlag == null) {
        return true;
      } else {
        return false;
      }
    }
    catch (e) {
    console.error(e);

      return true;
    }
  }

  onRowClick(ev: any) {
    try {
      if (ev != null) {
        this.rowClickedData = ev;
        this.taxForm.get('taxCode')?.disable();
        this.loadFormValues();
        this.saveDisabled = this.saveChecker();
        this.deleteDisabled = this.deleteChecker(ev);
      }
      else {
        this.resetFormValues();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  loadFormValues() {
    try {
      this.taxForm.patchValue({
        taxCd: this.rowClickedData.TAX_CD,
        taxCode: this.rowClickedData.TAX_CODE,
        activeTag: this.rowClickedData.ACTIVE_TAG,
        taxName: this.rowClickedData.TAX_NAME,
        ocTag: this.rowClickedData.OC_TAG,
        taxShortName: this.rowClickedData.TAX_SNAME,
        remarks: this.rowClickedData.REMARKS,
        userID: this.rowClickedData.USER_ID,
        lastUserUpdate: this.rowClickedData.LAST_USER_UPDATE
      });
      this.addDisabled = true;
      this.userIDReadOnly = true;
      this.isTaxSelected = true;
    }
    catch (e) {
    console.error(e);

    }
  }

  resetFormValues() {
    try {
      this.taxForm.get('taxCode')?.enable();
      this.taxForm.patchValue({
        taxCd: null,
        taxCode: null,
        activeTag: 'A',
        taxName: null,
        ocTag: 'N',
        taxShortName: null,
        remarks: null,
        userID: null,
        lastUserUpdate: null,
      });

      this.addDisabled = false;
      this.saveDisabled = this.saveChecker();
      this.deleteDisabled = true;
      this.userIDReadOnly = false;
      this.isTaxSelected = false;

      this.rowClickedData = '' || null;
    }
    catch (e) {
    console.error(e);

    }
  }

  openDialog(title: any, message: any) {
    try {
      this.noLoadingInProcess = true;
      this.formService.hideFormLoader("generate-tax-form");
      this._Dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        }
      });
    } catch (e) {
    console.error(e);

    }
  }

  validateInsert() {
    try {
      this.validated = false;
      if (!this.requiredFieldCheck()) {
        //this.openDialog('ERROR', 'Kindly fill-out required fields.');
        setTimeout(() => {                           //10 secs countdown
          this.formService.hideFormMsg("tax-error-message");
        }, 10000);
      }
      else if (!this.taxNameDupCheck()) {
        //this.openDialog('ERROR', 'Tax Name already exists. Please add a unique Tax Name');
        setTimeout(() => {
          this.formService.hideFormMsg("tax-error-message");
        }, 10000);
      }
      else if (!this.taxSNameDupCheck()) {
        //this.openDialog('ERROR', 'Tax Short Name already exists. Please add a unique Tax Short Name');
        setTimeout(() => {
          this.formService.hideFormMsg("tax-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
    }

  }

  validateUpdate() {
    try {
      this.validated = false;
      if (!this.requiredFieldCheck()) {
        //this.openDialog('ERROR', 'There are missing information. Please provide the necessary information needed.');
        setTimeout(() => {
          this.formService.hideFormMsg("tax-error-message");
        }, 10000);
      }
      else if (!this.taxNameDupCheck()) {
        //this.openDialog('ERROR', 'Tax Name already exists. Please add a unique Tax Name');
        setTimeout(() => {
          this.formService.hideFormMsg("tax-error-message");
        }, 10000);
      }
      else if (!this.taxSNameDupCheck()) {
        //this.openDialog('ERROR', 'Tax Short Name already exists. Please add a unique Tax Short Name');
        setTimeout(() => {
          this.formService.hideFormMsg("tax-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
    }
  }

  taxNameDupCheck() {
    try {
      let taxNameForm = this.taxForm.get('taxName')?.value?.trim()?.toUpperCase() ?? "";
      this.taxNameField = this.tblConfigTax.tblData.filter((e: any) => {
        return (!this.isTaxSelected) ? (e.TAX_NAME?.trim()?.toUpperCase() == taxNameForm)
          : (this.rowClickedData.TAX_NAME?.trim()?.toUpperCase() !== taxNameForm && e.TAX_NAME?.trim()?.toUpperCase() == taxNameForm);
      });
      if (this.taxNameField == '' || this.taxNameField == null) {
        return true;
      } else {
        this.formService.showFormMsg("tax-error-message", 'Tax Name already exists. Please add a unique Tax Name.', "E");
        return false;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }

  taxSNameDupCheck() {
    try {
      if (this.taxForm.get('taxShortName')?.value != '' && this.taxForm.get('taxShortName')?.value != null) {
        let taxShortNameForm = this.taxForm.get('taxShortName')?.value?.trim()?.toUpperCase() ?? "";
        this.taxShortNameField = this.tblConfigTax.tblData.filter((e: any) => {
          return (!this.isTaxSelected) ? (e.TAX_SNAME?.trim()?.toUpperCase() == taxShortNameForm)
            : (this.rowClickedData.TAX_SNAME?.trim()?.toUpperCase() !== taxShortNameForm && e.TAX_SNAME?.trim()?.toUpperCase() == taxShortNameForm);
        });
        if (this.taxShortNameField == '' || this.taxShortNameField == null) {
          return true;
        } else {
          this.formService.showFormMsg("tax-error-message", 'Tax Short Name already exists. Please add a unique Tax Short Name.', "E");
          return false;
        }
      }
      else {
        return true;
      }

    }
    catch (e) {
    console.error(e);
      return false;
    }
  }

  requiredFieldCheck() {
    try {
      if (this.taxForm.get('activeTag')?.value == '' || this.taxForm.get('activeTag')?.value == null
        || this.taxForm.get('taxName')?.value == '' || this.taxForm.get('taxName')?.value == null
        || this.onlyWhitespace(this.taxForm.get('taxName')?.value ?? '')) {
        this.formService.showFormMsg("tax-error-message", 'There are missing information. Please provide the necessary information needed.', "E");
        return false;
      }
      else {
        return true;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }

  private onlyWhitespace(val: string) {
    return (val ?? '').trim().length === 0;
  }
}