<section id="warr-clau-maint">    
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <div class="form-content">
            <form [formGroup]="forms.lineLovForm" class="form">
                <div class="form-container-grid top-form">
                    <div class="field required">
                        <label>Line</label>
                    </div>
                    <div class="">
                        <input formControlName="lineCdDisp" >
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['line']()">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <!-- Container for Warranties and Clauses Table using prime ng -->
        <div class = 'table-user'>
            <osp-table [tblConfig]="warrClauTblConfig" (onRowClick)="onRowClick($event)"></osp-table>
        </div>
        <!-- Loading Animation -->
        <div id="warr-clau-maintenance-loading">
            <div class="form-loader"></div>
        </div>
        <div class="form-content" *ngIf="showForm">
            <form [formGroup]="forms.warrClauForm" class="form">
                <div id="warr-clau-error-message"></div>
                <div class="form-container-grid">
                    <div class="field required">
                        <label>Warranties and Clause Code</label>
                    </div>
                    <div class="">
                        <input formControlName="wcCd" type="text" maxlength="4" (input)="autoUppercase($event)">
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag">
                            <option *ngFor="let activeType of selections.activeTypes" [value]="activeType.cd">
                                {{activeType.name}}
                            </option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>Warranties and Clause Title</label>
                    </div>
                    <div class="span">
                        <input formControlName="wcTitle" type="text">
                    </div>

                    <div class="field required">
                        <label>Effective Date</label>
                    </div>
                    <div class="">
                        <input formControlName="effDateFrom" type="text" placeholder="MM/DD/YYYY" [matDatepicker]="effDateFromPicker" (keypress)="allowNumericAndSlashOnly($event)">
                        <mat-datepicker-toggle matSuffix [for]="effDateFromPicker"></mat-datepicker-toggle>
                        <mat-datepicker #effDateFromPicker></mat-datepicker>
                    </div>
                    <div class="field">
                        <label>Expiry Date</label>
                    </div>
                    <div class="">
                        <input formControlName="effDateTo" type="text" placeholder="MM/DD/YYYY" [matDatepicker]="effDateToPicker" (keypress)="allowNumericAndSlashOnly($event)">
                        <mat-datepicker-toggle matSuffix [for]="effDateToPicker"></mat-datepicker-toggle>
                        <mat-datepicker #effDateToPicker></mat-datepicker>
                    </div>

                    <div class="field">
                        <label>Sequence No.</label>
                    </div>
                    <div class="">
                        <input formControlName="seqNo" type="text" (keypress)="allowNumericDigitsOnlyWODecimal($event)" (blur)="integerFormat('seqNo')">
                    </div>
                    <div class="field">
                        <label>Group Code</label>
                    </div>
                    <div class="">
                        <select formControlName="groupCd">
                            <option *ngFor="let groupCd of selections.groupCodes" [value]="groupCd.cd">
                                {{groupCd.name}}
                            </option>
                        </select>
                    </div>

                    <div class="field">
                        <label>Warranties and Clause Text</label>
                    </div>
                    <div class="span">
                        <textarea formControlName="wcText"></textarea>
                    </div>
                    
                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" type="text" maxlength="4000">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="userID">
                    </div>
                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUpdate">
                    </div>
                </div>
            </form>
            <div class="btn-row">
                <button *ngIf="btns.warrClau.updateDisabled" class="btn btn2" type="button" (click)="onAdd()">ADD</button>
                <button *ngIf="!btns.warrClau.updateDisabled" class="btn btn2" type="button" (click)="onUpdate()">UPDATE</button>
                <button class="btn btn2" type="button" [disabled]='btns.warrClau.deleteDisabled' (click)="onDelete()"> DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-row">
                <button class="btn btn2" type="button" [disabled]='btns.warrClau.saveDisabled' (click)="onSave()">SAVE</button>
            </div>
        </div>
    </div>
</section>