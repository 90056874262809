import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { CustomValidators } from 'src/app/utils/custom-validator';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';

@Component({
  selector: 'hazard-grade-maintenance',
  templateUrl: './hazard-grade-maintenance.component.html',
  styleUrls: ['./hazard-grade-maintenance.component.css']
})
export class HazardGradeMaintenanceComponent implements OnInit, LeaveConfirmation {

  private moduleId: string = 'BMM105';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc ?? "";

  constructor(
    public _formService: FormService,
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService,
    private _appMessageService: AppMessageService,
  ) {
    this.userIdLoggedIn = this._userDetailService.userId ?? "";
  }

  public canDeactivate(): boolean | Observable<boolean> {
    return !(this.hazardGradeTblConfig.tblData?.filter((row: any) => ['I', 'U'].includes(row.operation)).length > 0);
  }

  public btns = {
    hazardGrade: {
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      addDisabled: false,
    },
  };

  public showForm: boolean = true;

  //* Data Stuffs */
  private rowData: any;
  private errorMessage: string = "";
  private userIdLoggedIn: string;
  //? set selectedRow to [] to unselect the table
  public selectedRow!: any[];

  public forms = {
    hazardGradeForm: this.fb.group({
      hazardGrdCd: [null, [Validators.required, Validators.maxLength(8), this.checkDuplicateHazardGrd(), CustomValidators.requiredTrim]],
      hazardGrdDesc: [null, [Validators.required, Validators.maxLength(1000), CustomValidators.requiredTrim]],
      active: ['A', [Validators.required]],
      remarks: [null, [Validators.maxLength(1000)]],
      lastUpdateUser: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }],
    }),
    resetHazardGradeForm: () => {
      this.forms.hazardGradeForm.reset();
      this.forms.hazardGradeForm.patchValue({
        active: 'A',
      });
      this.btns.hazardGrade.updateDisabled = this.btns.hazardGrade.deleteDisabled = true;
      this._formService.hideFormMsg("hazard-grade-error-message");
      this.rowData = null;
      this.forms.hazardGradeForm.get('hazardGrdCd')?.enable();
    },
  };

  private hazardGradeFormManipulate = {
    dbToForm: (data: any) => {
      return {
        hazardGrdCd: data.HAZARD_GRD_CD,
        hazardGrdDesc: data.HAZARD_GRD_DESC,
        active: data.ACTIVE_TAG,
        remarks: data.REMARKS,
        lastUpdateUser: data.USER_ID,
        lastUpdate: data.LAST_UPDATE,
      };
    },
    formToDb: (data: any) => {
      return {
        HAZARD_GRD_CD: data.hazardGrdCd?.trim(),
        HAZARD_GRD_DESC: data.hazardGrdDesc?.trim(),
        ACTIVE_TAG: data.active,
        REMARKS: data.remarks,
        USER_ID: null,
        LAST_UPDATE: null,
      };
    },
  }

  public hazardGradeTblConfig: any = {
    cols: [
      {
        key: "HAZARD_GRD_CD",
        header: "Hazard Grade Code",
        dataType: "string",
        width: '144px',
      },
      {
        key: "HAZARD_GRD_DESC",
        header: "Hazard Grade Description",
        dataType: "string",
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        width: '48px',
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  ngOnInit(): void {
    this.getData.hazardGrade();
  }

  private getData = {
    hazardGrade: () => {
      try {
        this.hazardGradeTblConfig.tblData = [];
        this._jDS.contorlLoading(true);
        this.hazardGradeTblConfig.loading = true;
        this._api.getHazardGrade({
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          type: "MODULE",
        }).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                if (data.status === "SUCCESS") {
                  let content = JSON.parse(this._jDS.decrypt(data?.response));
                  this.hazardGradeTblConfig.tblData = [...content.data.filter((d: any) => d.ACTIVE_TAG == 'A'), ...content.data.filter((d: any) => d.ACTIVE_TAG != 'A')];
                  this._jDS.contorlLoading(false);
                  this.hazardGradeTblConfig.loading = false;
                }
                else {

                  this._jDS.contorlLoading(false);
                  this.hazardGradeTblConfig.loading = false;
                }
              });
            });
          },
          error: (e: any) => {
            this._securityService.checkRequestKeyResponse(e, () => {

              this._jDS.contorlLoading(false);
              this.hazardGradeTblConfig.loading = false;
            });
          }
        });
      }
      catch (e) {
    console.error(e);

      }
    },
  };

  public selections = {
    activeTypes: [
      { cd: 'A', name: 'Yes' },
      { cd: 'I', name: 'No' },
    ],
  };

  public onRowClick(ev: any) {
    try {
      if (ev) {
        this.rowData = ev;
        this.forms.hazardGradeForm.get('hazardGrdCd')?.disable();
        this.populateFormFromTable();
      }
      else {
        this.rowData = null;
        this.forms.resetHazardGradeForm();
      }
    }
    catch (e) {
    console.error(e);
      this.rowData = null;
      this.forms.resetHazardGradeForm();
    }
  }

  private populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.hazardGrade.updateDisabled = false;
      this.btns.hazardGrade.deleteDisabled = !(this.rowData?.operation === 'I');
      this.forms.hazardGradeForm.patchValue(this.hazardGradeFormManipulate.dbToForm(data));
    }
    catch (e) {
    console.error(e);

    }
  }

  public onAdd(): void {
    try {
      this._formService.hideFormMsg("hazard-grade-error-message");
      if (this.forms.hazardGradeForm.valid) {
        let rowToBeAdded: { [key: string]: any } = this.hazardGradeFormManipulate.formToDb(this.forms.hazardGradeForm.getRawValue());
        rowToBeAdded.operation = 'I';
        this.hazardGradeTblConfig.tblData = [rowToBeAdded, ...this.hazardGradeTblConfig.tblData];
        this.forms.resetHazardGradeForm();
        this.btns.hazardGrade.saveDisabled = false;
      }
      else {
        this.showErrorValidator.hazardGrade();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onUpdate(): void {
    try {
      this._formService.hideFormMsg("hazard-grade-error-message");
      if (this.forms.hazardGradeForm.valid) {
        const indexOfRow = this.hazardGradeTblConfig.tblData.indexOf(this.rowData);
        let rowToBeUpdated: { [key: string]: any } = this.hazardGradeFormManipulate.formToDb(this.forms.hazardGradeForm.getRawValue());
        rowToBeUpdated.operation = (this.rowData.operation === 'I') ? 'I' : 'U';
        rowToBeUpdated.HAZARD_GRD_CD = this.rowData?.HAZARD_GRD_CD;
        this.forms.resetHazardGradeForm();
        this.hazardGradeTblConfig.tblData[indexOfRow] = rowToBeUpdated;
        this.selectedRow = [];
        this.btns.hazardGrade.saveDisabled = false;
        this.hazardGradeTblConfig.tblData = [...this.hazardGradeTblConfig.tblData];
      }
      else {
        this.showErrorValidator.hazardGrade();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onDelete(): void {
    try {
      this.hazardGradeTblConfig.tblData = this.hazardGradeTblConfig.tblData.filter((row: any) => {
        return row !== this.rowData;
      });
      this.forms.resetHazardGradeForm();
      this.btns.hazardGrade.saveDisabled = false;
    }
    catch (e) {
    console.error(e);

    }
  }

  public onSave(): void {
    try {
      this._jDS.contorlLoading(true);
      this.showForm = false;
      this._formService.showFormLoader(null, "hazard-grade-maintenance-loading", "Saving.<br>Please wait ...", null, null, true);
      let toBeSaved = this.hazardGradeTblConfig.tblData.filter((row: any) => ['I', 'U'].includes(row.operation));
      if (toBeSaved.length > 0) {
        this._api.saveHazardGrade(toBeSaved).subscribe({
          next: (response: any) => {
            this._securityService.checkRequestKeyResponse(response, () => {
              this._securityService.hasValidCsrfToken(response, () => {
                if (response.status === 'SUCCESS') {
                  this.onComplete.hazardGrade();
                }
                else {
                  this.onComplete.hazardGradeError();
                }
              });
            });
          },
          error: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {

              this.onComplete.hazardGradeError();
            });
          }
        });
      }
      else {
        this.onComplete.hazardGrade();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  private onComplete = {
    hazardGrade: () => {
      this._jDS.contorlLoading(false);
      this._formService.hideFormLoader("hazard-grade-maintenance-loading");
      this.openDialog('SUCCESS', this.moduleName.replace('Maintenance', ''), 'saved');
      this.btns.hazardGrade.saveDisabled = true;
      this.showForm = true;
      this.forms.resetHazardGradeForm();
    },
    hazardGradeError: () => {
      this._jDS.contorlLoading(false);
      this.openDialog('FAILED', this.moduleName.replace('Maintenance', ''), 'saving');
      this._formService.hideFormLoader("hazard-grade-maintenance-loading");
      this.showForm = true;
      this.forms.resetHazardGradeForm();
    },
  }

  private openDialog(title: string, contentTitle: string, contentDetail: string) {
    try {
      this._matDialog.open(DialogMsgComponent, {
        disableClose: true,
        width: '512px',
        data: {
          title: title,
          content:
            title === 'SUCCESS'
              ? `${contentTitle} details successfully ${contentDetail}!`
              : `${contentTitle} details ${contentDetail} failed!`,
        },
      }).afterClosed().subscribe(() => {
        if (title === 'SUCCESS') {
          this.getData.hazardGrade();
        }
      });
    }
    catch (e) {
    console.error(e);

    }
  }

  //* Validator Stuffs Down here */

  private checkDuplicateHazardGrd(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.trim()?.toUpperCase();
      if (!value) return null;
      let hasDuplicate: boolean = this.hazardGradeTblConfig?.tblData.filter((d: any) => {
        if (!this.rowData)
          return d['HAZARD_GRD_CD']?.trim().toUpperCase() === value;
        else
          return this.rowData['HAZARD_GRD_CD']?.trim()?.toUpperCase() !== value && d['HAZARD_GRD_CD']?.trim()?.toUpperCase() === value;
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupe: true } : null;
    }
  }

  public showErrorValidator = {
    hazardGrade: () => {
      try {
        Object.keys(this.forms.hazardGradeForm.controls).forEach(key => {
          const controlErrors = this.forms.hazardGradeForm.get(key)?.errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              if (keyError == 'hasDupe' && controlErrors[keyError] === true && key === 'hazardGrdCd')
                this.errorMessage = "Hazard Grade Code already exists. Please use a unique Hazard Grade Code.";
              else if (keyError == 'required' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
            });
          }
        });
        this._formService.showFormMsg("hazard-grade-error-message", this.errorMessage, "E");
      }
      catch (e) {
    console.error(e);

      }
    },
  }

}
