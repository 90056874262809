import { Component, OnInit } from '@angular/core';

import { APICallService } from 'src/app/services/api-call.service'
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { SecurityService } from 'src/app/services/security.service';

import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe, formatDate } from '@angular/common';

import { Observable } from 'rxjs';

import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';

@Component({
  selector: 'report-maintenance',
  templateUrl: './report-maintenance.component.html',
  styleUrls: ['./report-maintenance.component.css']
})
export class ReportMaintenanceComponent implements OnInit, LeaveConfirmation {

  datePipe = new DatePipe('en-us');

  addDisabled: boolean = false;
  saveDisabled: boolean = true;
  deleteDisabled: boolean = true;
  userIDReadOnly: boolean = false;
  tranPosted: boolean = false;
  noLoadingInProcess: boolean = true;
  validated: boolean = false;
  submitted: boolean = false;
  isReportSelected: boolean = false;
  isSuccessSaved: boolean = false;
  reportIDField: string = '';
  currentUser: string = this.userDetailService.userId || "";
  rowClickedData: any;
  effDate = new FormControl(new Date());
  selectedData: any = null;
  private moduleId: string = 'BMM033';
  public moduleName: string = this.jsonDataService.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc?.toUpperCase() ?? "";

  selectedRepGrp: any = {
    REPORT_GRP: '',
    REPORT_GROUP: ''
  };

  reportGrpList: any = [];

  tempAddedData: any = [];
  tempUpdateData: any = [];

  reportForm = this.fb.group({
    reportID: [null, [Validators.required]],
    activeTag: ['A', [Validators.required]],
    reportName: [null, [Validators.required]],
    reportGrp: [null],
    reportGroup: [null],
    fileName: [null],
    remarks: [null],
    userID: [null],
    lastUserUpdate: [null],
    mode: [null]
  });

  constructor(
    private _APICallService: APICallService,
    public _Dialog: MatDialog,
    private fb: FormBuilder,
    private formService: FormService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService
  ) { }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.tempAddedData != '' || this.tempUpdateData != '') {
      return false;
    } else {
      return true;
    }
  }

  tblConfigReport: any = {
    cols: [
      {
        key: "REPORT_ID",
        header: "Report ID",
        dataType: "string",
        width: '12%'
      },
      {
        key: "REPORT_NAME",
        header: "Report Name",
        dataType: "string",
        disabled: false
      },
      {
        key: "FILE_NAME",
        header: "File Name",
        dataType: "string",
        disabled: false
      },
      {
        key: "REPORT_GROUP",
        header: "Report Group",
        dataType: "string",
        disabled: false,
        width: '15%'
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        disabled: true,
        width: '64px'
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15
  }

  ngOnInit(): void {
    try {
      this._APICallService.refreshNeeded$
        .subscribe(() => {
          this.getAllReports();
        })
      this.getAllReports();
    }
    catch (e) {
    console.error(e);
    }
  }

  initOnGet(): void {
    try {
      this._APICallService.refreshNeeded$
        .subscribe(() => {
          this.getAllReports();
        })
      this.getAllReports();
    }
    catch (e) {
    console.error(e);
    }
  }

  getAllReports() {
    try {
      this.tblConfigReport.tblData = [];
      this.tblConfigReport.loading = true;
      this.reportGrpList = this.jsonDataService.data.refCds.filter((e: any) => e.rvDomain == "REPORT_GRP");
      this.jsonDataService.contorlLoading(true);
      this._APICallService.getReport({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE"
      }).subscribe({
        next: (response: any) => {
          this.securityService.checkRequestKeyResponse(response, () => {
            this.securityService.hasValidCsrfToken(response, () => {
              let content = JSON.parse(this.jsonDataService.decrypt(response.response));
              this.jsonDataService.contorlLoading(false);
              this.tblConfigReport.tblData = content.data;
              this.tblConfigReport.loading = false;
            });
          });
        },
        error: (error: any) => {
          this.tblConfigReport.loading = false;
          this.jsonDataService.contorlLoading(false);
        }
      });
    }
    catch (e) {
    console.error(e);
    }
  }

  insertReport() {
    try {
      this.tranPosted = true;
      this.validateInsert();
      if (this.validated) {
        this.addFormToTable();
      }
      this.tblConfigReport.tblData = [...this.tblConfigReport.tblData];
    }
    catch (e) {
    console.error(e);
    }
  }

  addFormToTable() {
    try {
      if (this.reportForm.get('reportGrp')?.value == null || this.reportForm.get('reportGrp')?.value == '') {
        this.reportForm.get('reportGrp')
          ?.setValue(null);
        this.reportForm.get('reportGroup')
          ?.setValue(null);
      }
      else {
        this.selectedRepGrp.REPORT_GRP = this.reportGrpList.find(
          (x: { rvLowValue: any; }) => x.rvLowValue === this.reportForm.get('reportGrp')?.value).rvLowValue;
        this.selectedRepGrp.REPORT_GROUP = this.reportGrpList.find(
          (x: { rvLowValue: any; }) => x.rvLowValue === this.reportForm.get('reportGrp')?.value).rvMeaning;

        this.reportForm.get('reportGrp')
          ?.setValue(this.selectedRepGrp == null || this.selectedRepGrp == '' ? null : this.selectedRepGrp.REPORT_GRP);
        this.reportForm.get('reportGroup')
          ?.setValue(this.selectedRepGrp == null || this.selectedRepGrp == '' ? null : this.selectedRepGrp.REPORT_GROUP);
      }

      this.reportForm.patchValue({
        activeTag: this.reportForm.get('activeTag')?.value,
        userID: this.currentUser,
        lastUserUpdate: new Date()
      });
      let formToData: any = { // this.datePipe.transform(new Date(), 'MM/dd/yyyy')
        REPORT_ID: this.reportForm.get('reportID')?.value.toUpperCase(),
        ACTIVE_TAG: this.reportForm.get('activeTag')?.value,
        REPORT_NAME: this.reportForm.get('reportName')?.value,
        FILE_NAME: this.reportForm.get('fileName')?.value,
        REPORT_GRP: this.reportForm.get('reportGrp')?.value,
        REPORT_GROUP: this.reportForm.get('reportGroup')?.value,
        REMARKS: this.reportForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.reportForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.reportForm.get('userID')?.value,
        MODE: 'I'
      }
      this.tblConfigReport.tblData.unshift(formToData);
      this.tempAddedData.push(formToData);
      this.resetFormValues();
      this.appMessageService.showAppMessage("New Report details added!", "success");
    }
    catch (e) {
    console.error(e);
    }
  }

  updateReport() {
    try {
      this.tranPosted = true;
      this.validateUpdate();
      if (this.validated) {
        this.updateFormTable();
      }
      this.tblConfigReport.tblData = [...this.tblConfigReport.tblData];
    }
    catch (e) {
    console.error(e);
    }
  }

  updateFormTable() {
    try {
      if (this.reportForm.get('reportGrp')?.value == null || this.reportForm.get('reportGrp')?.value == '') {
        this.reportForm.get('reportGrp')
          ?.setValue(null);
        this.reportForm.get('reportGroup')
          ?.setValue(null);
      }
      else {
        this.selectedRepGrp.REPORT_GRP = this.reportGrpList.find(
          (x: { rvLowValue: any; }) => x.rvLowValue === this.reportForm.get('reportGrp')?.value).rvLowValue;
        this.selectedRepGrp.REPORT_GROUP = this.reportGrpList.find(
          (x: { rvLowValue: any; }) => x.rvLowValue === this.reportForm.get('reportGrp')?.value).rvMeaning;

        this.reportForm.get('reportGrp')
          ?.setValue(this.selectedRepGrp == null || this.selectedRepGrp == '' ? null : this.selectedRepGrp.REPORT_GRP);
        this.reportForm.get('reportGroup')
          ?.setValue(this.selectedRepGrp == null || this.selectedRepGrp == '' ? null : this.selectedRepGrp.REPORT_GROUP);
      }

      let tempInsertChecker = this.tempAddedData.find(
        (rep: { REPORT_ID: any; }) => rep.REPORT_ID == this.reportForm.get('reportID')?.value);
      let tempUpdateChecker = this.tempUpdateData.find(
        (rep: { REPORT_ID: any; }) => rep.REPORT_ID == this.reportForm.get('reportID')?.value);

      this.tableDataUpdate();

      // Adds Updated Data that exists in the database to an object array for updated datas
      if (tempInsertChecker != null && tempInsertChecker != '') {
        //this.tempUpdateData.push(this.formValueToData());
        tempInsertChecker.REPORT_ID = this.reportForm.get('reportID')?.value.toUpperCase();
        tempInsertChecker.ACTIVE_TAG = this.reportForm.get('activeTag')?.value;
        tempInsertChecker.REPORT_NAME = this.reportForm.get('reportName')?.value;
        tempInsertChecker.FILE_NAME = this.reportForm.get('fileName')?.value;
        tempInsertChecker.REPORT_GRP = this.reportForm.get('reportGrp')?.value;
        tempInsertChecker.REPORT_GROUP = this.reportForm.get('reportGroup')?.value;
        tempInsertChecker.REMARKS = this.reportForm.get('remarks')?.value;
        tempInsertChecker.LAST_USER_UPDATE = this.datePipe.transform(new Date(this.reportForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a');
        tempInsertChecker.USER_ID = this.reportForm.get('userID')?.value;
        tempInsertChecker.MODE = 'I';
      }
      else if (tempUpdateChecker != null && tempUpdateChecker != '') {
        tempUpdateChecker.REPORT_ID = this.reportForm.get('reportID')?.value.toUpperCase();
        tempUpdateChecker.ACTIVE_TAG = this.reportForm.get('activeTag')?.value;
        tempUpdateChecker.REPORT_NAME = this.reportForm.get('reportName')?.value;
        tempUpdateChecker.FILE_NAME = this.reportForm.get('fileName')?.value;
        tempUpdateChecker.REPORT_GRP = this.reportForm.get('reportGrp')?.value;
        tempInsertChecker.REPORT_GROUP = this.reportForm.get('reportGroup')?.value;
        tempUpdateChecker.REMARKS = this.reportForm.get('remarks')?.value;
        tempUpdateChecker.LAST_USER_UPDATE = this.datePipe.transform(new Date(this.reportForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a');
        tempUpdateChecker.USER_ID = this.reportForm.get('userID')?.value;
        tempUpdateChecker.MODE = 'U';
      }
      else {
        this.tempUpdateData.push(this.formValueToData());
      }
      this.saveDisabled = false;
      this.resetFormValues();
      this.appMessageService.showAppMessage("Report details updated!", "success");
    }
    catch (e) {
    console.error(e);
    }
  }

  tableDataUpdate() {
    try {
      this.reportForm.patchValue({
        activeTag: this.reportForm.get('activeTag')?.value,
        userID: this.currentUser,
        lastUserUpdate: new Date()
      });
      let updateIndex = this.tblConfigReport.tblData.indexOf(this.rowClickedData);
      this.tblConfigReport.tblData[updateIndex] = {
        REPORT_ID: this.reportForm.get('reportID')?.value.toUpperCase(),
        ACTIVE_TAG: this.reportForm.get('activeTag')?.value,
        REPORT_NAME: this.reportForm.get('reportName')?.value,
        FILE_NAME: this.reportForm.get('fileName')?.value,
        REPORT_GRP: this.reportForm.get('reportGrp')?.value,
        REPORT_GROUP: this.reportForm.get('reportGroup')?.value,
        REMARKS: this.reportForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.reportForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.reportForm.get('userID')?.value,
        MODE: 'U'
      };
    }
    catch (e) {
    console.error(e);
    }
  }

  formValueToData() {
    try {
      let formToData: any = { // this.datePipe.transform(new Date(), 'MM/dd/yyyy')
        REPORT_ID: this.reportForm.get('reportID')?.value.toUpperCase(),
        ACTIVE_TAG: this.reportForm.get('activeTag')?.value,
        REPORT_NAME: this.reportForm.get('reportName')?.value,
        FILE_NAME: this.reportForm.get('fileName')?.value,
        REPORT_GRP: this.reportForm.get('reportGrp')?.value,
        REPORT_GROUP: this.reportForm.get('reportGroup')?.value,
        REMARKS: this.reportForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.reportForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.reportForm.get('userID')?.value,
        MODE: 'U'
      }
      return formToData;
    }
    catch (e) {
    console.error(e);
    }
  }

  deleteReport() {
    try {
      let clickData = this.rowClickedData;
      this.tblConfigReport.tblData = this.tblConfigReport.tblData.filter(
        function (e: any) {
          return e.REPORT_ID != clickData.REPORT_ID;
        }
      );
      this.tempAddedData = this.tempAddedData.filter(
        function (e: any) {
          return e.REPORT_ID != clickData.REPORT_ID;
        }
      );
      this.resetFormValues();
    }
    catch (e) {
    console.error(e);
    }
  }

  private saveAddedData(): void {
    this._APICallService.saveReport(this.tempAddedData)
      .subscribe({
        next: (response: any) => {
          this.securityService.checkRequestKeyResponse(response, () => {
            this.securityService.hasValidCsrfToken(response, () => {
              if (response.status == "SUCCESS" && this.tranPosted) {
                if (this.tempUpdateData.length != 0) {
                  this.saveUpdatedData();
                } else {
                  this.jsonDataService.contorlLoading(false);
                  this.openDialog('SUCCESS', 'New report details Successfully saved!');
                  this.initOnGet();
                  this.tempAddedData = [];
                  this.tempUpdateData = [];
                  this.resetFormValues();
                }
              }
              else {
                this.jsonDataService.contorlLoading(false);
                this.openDialog('ERROR', 'An error has occured while saving details for new report');
              }
            });
          });
        },
        error: () => {
          this.jsonDataService.contorlLoading(false);
          this.openDialog('ERROR', 'An error has occured while saving details for new report');
        }
      });
  }

  private saveUpdatedData(): void {
    this._APICallService.saveReport(this.tempUpdateData)
      .subscribe({
        next: (response: any) => {
          this.securityService.checkRequestKeyResponse(response, () => {
            this.securityService.hasValidCsrfToken(response, () => {
              if (response.status == "SUCCESS" && this.tranPosted) {
                this.jsonDataService.contorlLoading(false);
                this.openDialog('SUCCESS', 'Existing report details successfully saved!');
                this.initOnGet();
                this.tempAddedData = [];
                this.tempUpdateData = [];
                this.resetFormValues();
              }
              else {
                this.jsonDataService.contorlLoading(false);
                this.openDialog('ERROR', 'An error has occured while saving details for existing report');
              }
            });
          });
        },
        error: () => {
          this.jsonDataService.contorlLoading(false);
          this.openDialog('ERROR', 'An error has occured while saving details for existing report');
        }
      });
  }

  toSave() {
    try {
      this.noLoadingInProcess = false;
      this.formService.showFormLoader(null, "generate-report-form", "Please wait ...", null, null, true);
      this.jsonDataService.contorlLoading(true);
      if (this.tempAddedData.length != 0) {
        this.saveAddedData();
      } else {
        if (this.tempUpdateData.length != 0) {
          this.saveUpdatedData();
        } else {
          this.jsonDataService.contorlLoading(false);
          this.initOnGet();
          this.tempAddedData = [];
          this.tempUpdateData = [];
          this.resetFormValues();
        }
      }
    }
    catch (e) {
    console.error(e);
    }
  }

  saveChecker() {
    try {
      if ((this.tempAddedData == null || this.tempAddedData == '') && (this.tempUpdateData == null || this.tempUpdateData == '')) {
        return true;
      }
      else {
        return false;
      }
    }
    catch (e) {
    console.error(e);
      return true;
    }
  }

  deleteChecker(ev: any) {
    try {
      let tempDataFlag;
      tempDataFlag = this.tempAddedData.filter(function (e: any) {
        return e.REPORT_ID == ev.REPORT_ID;
      });
      if (tempDataFlag == '' || tempDataFlag == null) {
        return true;
      } else {
        return false;
      }
    }
    catch (e) {
    console.error(e);
      return true;
    }
  }

  onRowClick(ev: any) {
    try {
      if (ev != null) {
        this.rowClickedData = ev;
        this.reportForm.get('reportID')?.disable();
        this.loadFormValues();
        this.saveDisabled = this.saveChecker();
        this.deleteDisabled = this.deleteChecker(ev);
      }
      else {
        this.resetFormValues();
      }
    }
    catch (e) {
    console.error(e);
    }
  }

  loadFormValues() {
    try {
      this.reportForm.patchValue({
        reportID: this.rowClickedData.REPORT_ID,
        activeTag: this.rowClickedData.ACTIVE_TAG,
        reportName: this.rowClickedData.REPORT_NAME,
        reportGrp: this.rowClickedData.REPORT_GRP,
        reportGroup: this.rowClickedData.REPORT_GROUP,
        fileName: this.rowClickedData.FILE_NAME,
        remarks: this.rowClickedData.REMARKS,
        userID: this.rowClickedData.USER_ID,
        lastUserUpdate: this.rowClickedData.LAST_USER_UPDATE
      });
      this.addDisabled = true;
      this.userIDReadOnly = true;
      this.isReportSelected = true;
    }
    catch (e) {
    console.error(e);
    }
  }

  resetFormValues() {
    try {
      this.selectedData = null;
      this.reportForm.get('reportID')?.enable();
      this.reportForm.patchValue({
        reportID: null,
        activeTag: 'A',
        reportName: null,
        reportGrp: null,
        reportGroup: null,
        fileName: null,
        remarks: null,
        userID: null,
        lastUserUpdate: null
      });

      this.addDisabled = false;
      this.saveDisabled = this.saveChecker();
      this.deleteDisabled = true;
      this.userIDReadOnly = false;
      this.isReportSelected = false;

      this.rowClickedData = '' || null;
    }
    catch (e) {
    console.error(e);
    }
  }

  openDialog(title: any, message: any) {
    try {
      this.noLoadingInProcess = true;
      this.formService.hideFormLoader("generate-report-form");
      this._Dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        }
      });
    } catch (e) {
    console.error(e);
    }
  }

  validateInsert() {
    try {
      this.validated = false;
      if (!this.requiredFieldCheck()) {
        /// this.openDialog('ERROR', 'Kindly fill-out required fields.');
        setTimeout(() => {                           //10 secs countdown
          this.formService.hideFormMsg("report-error-message");
        }, 10000);
      }
      else if (!this.reportDupCheck()) {
        // this.openDialog('ERROR', 'Report ID already exists. Please add a unique Report ID.');
        setTimeout(() => {
          this.formService.hideFormMsg("report-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
    }
  }

  validateUpdate() {
    try {
      this.validated = false;
      if (!this.requiredFieldCheck()) {
        // this.openDialog('ERROR', 'There are missing information. Please provide the necessary information needed.');
        setTimeout(() => {
          this.formService.hideFormMsg("report-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
    }
  }

  reportDupCheck() {
    try {
      let reportIDForm = this.reportForm.get('reportID')?.value?.trim()?.toUpperCase() ?? "";
      this.reportIDField = this.tblConfigReport.tblData.filter(function (e: any) {
        return e.REPORT_ID.trim()?.toUpperCase() == reportIDForm;
      });
      if (this.reportIDField == '' || this.reportIDField == null) {
        return true;
      } else {
        this.formService.showFormMsg("report-error-message", 'Report ID already exists. Please add a unique Report ID.', "E");
        return false;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }

  requiredFieldCheck() {
    try {
      if (this.reportForm.get('reportID')?.value == '' || this.reportForm.get('reportID')?.value == null || this.onlyWhitespace(this.reportForm.get('reportID')?.value ?? '')
        || this.reportForm.get('activeTag')?.value == '' || this.reportForm.get('activeTag')?.value == null
        || this.reportForm.get('reportName')?.value == '' || this.reportForm.get('reportName')?.value == null || this.onlyWhitespace(this.reportForm.get('reportName')?.value ?? '')
        || this.reportForm.get('fileName')?.value == '' || this.reportForm.get('fileName')?.value == null || this.onlyWhitespace(this.reportForm.get('fileName')?.value ?? '')) {
        this.formService.showFormMsg("report-error-message", 'There are missing information. Please provide the necessary information needed.', "E");
        return false;
      }
      else {
        return true;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }

  private onlyWhitespace(val: string) {
    return (val ?? '').trim().length === 0;
  }

}
