import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { DeviceDetectorService } from 'ngx-device-detector';

import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { JsonDataService } from 'src/app/services/json-data.service';
// import { ParameterService } from 'src/app/services/parameter.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

@Component({
  selector: 'otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css']
})
export class OtpComponent implements OnInit {

  @Input() parentChildCommon:any;

  successOtp: boolean = false;
  withOtpMethod: boolean = false;
  otpMethod: string = "";
  otpReceiver: string = "";
  otpErrorMsg: string = "";
  noOfDays: string = "";
  daysToBypassOtp: number = 0;
  sendOtpInterval: number = 0;
  chkOTP: string = "chkResponse";
  params: any[] = [];

  otpForm: FormGroup = this.formBuilder.group({
    otp: [""]
  });

  constructor (
    private router: Router,
    private formBuilder: FormBuilder,
    private deviceDetectorService: DeviceDetectorService,
    private apiCallService: APICallService,
    private appMessageService: AppMessageService,
    private jsonDataService: JsonDataService,
    // private parameterService: ParameterService,
    private securityService: SecurityService,
    private userDetailService: UserDetailsService,
  ) {
    this.params = this.jsonDataService.data.params;
    // var days = this.jsonDataService.data.params.filter((a: any) => a.paramName == 'DAYS_TO_BYPASS_OTP')[0]['paramValueN'];
    // this.daysToBypassOtp = days || 0;
    // this.noOfDays = this.daysToBypassOtp + (this.daysToBypassOtp > 1 ? " days" : " day");

    setInterval(() => {
      if (this.sendOtpInterval > 0) {
        this.sendOtpInterval -= 1;
      }
    }, 1000);
  }

  ngOnInit(): void {
    this.daysToBypassOtp = this.params.filter((a: any) => a.paramName == 'DAYS_TO_BYPASS_OTP')[0]['paramValueN'] || 0;
    this.noOfDays = this.daysToBypassOtp + (this.daysToBypassOtp > 1 ? " days" : " day");
  }

  public sendOtp(method: string, withTimeInterval: string): void {
    this.otpErrorMsg = "";
    sessionStorage.setItem("bssid", this.parentChildCommon.userAuth.bssid);
    this.jsonDataService.contorlLoading(true);
    this.apiCallService.sendOtp({
        name: this.parentChildCommon.userAuth.name,
        method: method,
        withTimeInterval: withTimeInterval
    }).subscribe((response: any) => {
        this.securityService.checkRequestKeyResponse(response, () => {
            this.jsonDataService.contorlLoading(false);
            if (response.status === "SUCCESS") {
                this.sendOtpInterval = this.params.filter((a: any) => a.paramName == 'OTP_REQUEST_INTERVAL')[0]['paramValueN'];
                this.withOtpMethod = true;
                this.otpMethod = method;
                this.otpForm.controls["otp"].setValue("");

                if (method === "mobile") {
                    this.otpReceiver = this.parentChildCommon.userAuth.mobileNo;
                } else {
                    this.otpReceiver = this.parentChildCommon.userAuth.email;
                }
            } else {
                this.appMessageService.showAppMessage("There's something wrong sending your OTP. Please try again later.", "error");
            }
        });
    });
  }

  public cancelOTP(): void {
    sessionStorage.setItem("bssid", this.parentChildCommon.userAuth.bssid);
    this.apiCallService.logout().subscribe((response: any) => {
        if (response.status == "SUCCESS") {
            sessionStorage.removeItem("bssid");
            sessionStorage.removeItem("rkc");
            this.userDetailService.resetUserDetails();
            this.parentChildCommon.userAuth.hideOtpForm();
        } else {
            this.appMessageService.showAppMessage("An error has occured", "error");
        }
    })
  }

  public verifyOTP(): void {
    if (this.otpForm.controls["otp"].value) {
        this.otpErrorMsg = "";
        sessionStorage.setItem("bssid", this.parentChildCommon.userAuth.bssid);
        this.jsonDataService.contorlLoading(true);
        this.apiCallService.verifyOtp({
            otp: this.otpForm.controls["otp"].value
        }).subscribe((response: any) => {
            this.securityService.checkRequestKeyResponse(response, () => {
                this.securityService.hasValidCsrfToken(response, () => {
                    this.jsonDataService.contorlLoading(false);
                    const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                    if (res === "SUCCESS") {
                        if (this.daysToBypassOtp > 0) {
                            sessionStorage.setItem("bssid", this.parentChildCommon.userAuth.bssid);
                            this.jsonDataService.contorlLoading(true);
                            let randomId = this.generateId();
                            this.apiCallService.security({chk: this.chkOTP, id: randomId}).subscribe((data: any) => {
                                this.securityService.checkRequestKeyResponse(data, () => {
                                    this.securityService.hasValidCsrfToken(data, () => {
                                        this.jsonDataService.contorlLoading(false);
                                        const sec = JSON.parse(this.jsonDataService.decrypt(data.response));
                                        if (sec.randomId === randomId) {
                                            if (sec.response === "PASSED") {
                                                this.successOtp = true;
                                                sessionStorage.setItem("bssid", this.parentChildCommon.userAuth.bssid);
                                            }
                                        } else {
                                            this.otpErrorMsg = "Sorry, you provided the wrong OTP. Please try again or resend another OTP.";
                                        }
                                    });  
                                });
                            });
                        } else {
                            this.parentChildCommon.userAuth.proceedLogin(this.parentChildCommon);
                        }
                    } else if (res === "WRONG_OTP") {
                        this.otpErrorMsg = "Sorry, you provided the wrong OTP. Please try again or resend another OTP.";
                    } else if (res === "EXPIRED") {
                        this.otpErrorMsg = "Sorry, the OTP you’ve keyed in has expired. Please click Resend OTP and try again.";
                    } else {
                        this.otpErrorMsg = "An active session is on-going.";
                    }
                });
            });
        });
    } else {
        this.otpErrorMsg = "Complete the OTP to help us verify your identity.";
    }
  }

    public saveOtpToken(): void {
        const tokenValue = this.jsonDataService.encrypt({
            userId: this.parentChildCommon.userAuth.userId,
            browser: this.deviceDetectorService.browser,
            datetime: this.generateDateTime()
        });
        sessionStorage.setItem("bssid", this.parentChildCommon.userAuth.bssid);
        this.jsonDataService.contorlLoading(true);
        this.apiCallService.saveOtpToken({chk: this.chkOTP}).subscribe((data: any) => {
            this.jsonDataService.contorlLoading(false);
            this.securityService.checkRequestKeyResponse(data, () => {
                this.securityService.hasValidCsrfToken(data, () => {
                    this.jsonDataService.contorlLoading(false);
                    const sec = JSON.parse(this.jsonDataService.decrypt(data.response));
                    localStorage.setItem(this.createToken(tokenValue), sec.token);
                    this.parentChildCommon.userAuth.proceedLogin(this.parentChildCommon);
                });  
            });
        });
        // localStorage.setItem(this.createToken(tokenValue), tokenValue);
        // this.parentChildCommon.proceedLogin(this.parentChildCommon.loginResponse);
    }

    public proceedLogin(): void {
        this.apiCallService.checkAuthorization().subscribe((data: any) => {
            if(data.status == 'UNAUTHORIZED') {
                this.appMessageService.showAppMessage("We are unable to process your request. Please try again later.", "error");
            } else {
                this.parentChildCommon.userAuth.proceedLogin(this.parentChildCommon);
            }
        });
        // this.parentChildCommon.userAuth.proceedLogin(this.parentChildCommon);
    }

    private createToken(data: string): string {
        return this.jsonDataService.encrypt(
            data.substr(30, 1) + data.substr(10, 1) + data.substr(40, 1) +
            data.substr(15, 1) + data.substr(35, 1) + data.substr( 5, 1) +
            data.substr(25, 1) + data.substr(20, 1));
    }

    private generateDateTime(): string {
        const date = new Date().toLocaleDateString("en-US").split("/");
        const year = date[2], month = date[0], day = date[1];

        const hour = ("0" + (new Date().getHours() || 0)).slice(-2);
        const minute = ("0" + (new Date().getMinutes() || 0)).slice(-2);
        const second = ("0" + (new Date().getSeconds() || 0)).slice(-2);

        return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
    }

    public generateId(){
        var length = 20;
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

}
