import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { CustomValidators } from '../../../utils/custom-validator';

@Component({
  selector: 'mortgagee-maintenance',
  templateUrl: './mortgagee-maintenance.component.html',
  styleUrls: ['./mortgagee-maintenance.component.css']
})
export class MortgageeMaintenanceComponent implements OnInit, LeaveConfirmation {

  private moduleId: string = 'BMM045';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc?.toUpperCase() ?? "";

  constructor(
    public _formService: FormService,
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService,
    private _appMessageService: AppMessageService,
  ) {
    this.userIdLoggedIn = this._userDetailService.userId ?? "";
  }

  ngOnInit(): void {
    this.getData.mortgagee();
  }

  canDeactivate(): boolean | Observable<boolean> {
    return this.mortgageeTblConfig.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true)).length > 0 ? false : true;
  }

  public btns = {
    mortgagee: {
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      addDisabled: true,
    },
  }

  public showForm: boolean = true;

  /* Data Stuffs */

  private rowData: any;
  private tempDataRow: any;
  private errorMessage: string = "";
  private userIdLoggedIn: string;

  public forms = {
    mortgageeForm: this.fb.group({
      bankCd: [null],
      bankCdDisp: [{ value: '', disabled: 'true' }],
      active: ['A', [Validators.required]],
      bankName: [null, [Validators.required, Validators.maxLength(200), this.checkDuplicateBankName(), CustomValidators.requiredTrim]],
      bmCode: [null, [Validators.required, Validators.maxLength(8), CustomValidators.requiredTrim, this.checkDuplicateBmCode()]],
      alfes: ['N'],
      mortAccGrp: [null],
      remarks: [null, [Validators.maxLength(4000)]],
      lastUpdateUser: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }],
    }),
    resetMortgageeForm: () => {
      this.forms.mortgageeForm.reset();
      this.forms.mortgageeForm.patchValue({
        active: 'A',
        alfes: 'N'
      });
      this.btns.mortgagee.updateDisabled = this.btns.mortgagee.deleteDisabled = true;
      this._formService.hideFormMsg("mortgagee-error-message");
      // this.rowData = null;
      // this.forms.sacForm.get('tsiAmt')?.enable();
    }
  }

  private mortgageeFormManipulate = {
    dbToForm: (data: any) => {
      return {
        bankCd: data.BANK_CD,
        bankCdDisp: data.BANK_CD?.toString().padStart(3, 0) ?? null,
        bankName: data.BANK_NAME,
        bmCode: data.BM_CODE,
        alfes: data.ALFES_TAG,
        mortAccGrp: data.MORT_ACC_GRP,
        active: data.ACTIVE_TAG,
        remarks: data.REMARKS,
        lastUpdateUser: data.USER_ID,
        lastUpdate: data.LAST_UPDATE,
      };
    },
    formToDb: (data: any) => {
      return {
        BANK_CD: data.bankCd,
        BANK_NAME: data.bankName.trim(),
        BM_CODE: data.bmCode,
        ACTIVE_TAG: data.active,
        ALFES_TAG: data.alfes,
        MORT_ACC_GRP: data.mortAccGrp,
        REMARKS: data.remarks,
        USER_ID: null,
        LAST_UPDATE: null,
      };
    },
  }

  public mortgageeTblConfig = {
    cols: [
      {
        key: "BANK_CD",
        header: "Bank Code",
        dataType: "string",
        width: '128px'
      },
      {
        key: "BANK_NAME",
        header: "Bank Name",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "Active",
        dataType: "checkbox",
        width: '64px'
      }
    ],
    tblData: [] as any[],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  private getData = {
    mortgagee: () => {
      try {
        this.mortgageeTblConfig.tblData = [];
        this.mortgageeTblConfig.loading = true;
        this._jDS.contorlLoading(true);
        this._api.getMortgagee({
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          type: "MODULE"
        }).subscribe({
          next: (response: any) => {
            this._securityService.checkRequestKeyResponse(response, () => {
              this._securityService.hasValidCsrfToken(response, () => {
                if (response.status === 'SUCCESS') {
                  let content = JSON.parse(this._jDS.decrypt(response.response));
                  this.mortgageeTblConfig.tblData = content.data;
                  this.btns.mortgagee.addDisabled = false;
                }
                else {
                  this._appMessageService.showAppMessage(response.message, "error");
                }
                this._jDS.contorlLoading(false);
                this.mortgageeTblConfig.loading = false;
              });
            });
          },
          error: (error: any) => {

            this.mortgageeTblConfig.loading = false;
            this._jDS.contorlLoading(false);
          }
        });
      }
      catch (e) {
    console.error(e);

      }
    },
    mortAccGrps: () => {
      const filteredMag = this._jDS.data.refCds.filter((data: any) => data.rvDomain === 'MORT_ACC_GRP').map((data: any) => { return { cd: data.rvLowValue, name: data.rvMeaning } });
      return filteredMag;
    }
  }

  public selections = {
    activeTypes: [
      { cd: 'A', name: 'Yes' },
      { cd: 'I', name: 'No' },
    ],
    alfesTypes: [
      { cd: 'Y', name: 'Yes' },
      { cd: 'N', name: 'No' },
    ],
    mortAccGrps: [
      { cd: null, name: '' },
      ...this.getData.mortAccGrps()
    ],
  }

  public onRowClick(ev: any) {
    try {
      ev.update = true;
      this.rowData = ev;
      this.populateFormFromTable();
    }
    catch (e) {
    console.error(e);
      this.forms.resetMortgageeForm();
      this.rowData = null;
      //this.indexRow = null;
    }
  }

  private populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.mortgagee.updateDisabled = !data.update;
      this.btns.mortgagee.deleteDisabled = !data.temp;
      this.tempDataRow = !data.temp;
      this.forms.mortgageeForm.patchValue(this.mortgageeFormManipulate.dbToForm(data));
    }
    catch (e) {
    console.error(e);

    }
  }

  public onAdd() {
    try {
      this._formService.hideFormMsg("mortgagee-error-message");
      if (this.forms.mortgageeForm.valid) {
        let rowToBeAdded: { [key: string]: any } = this.mortgageeFormManipulate.formToDb(this.forms.mortgageeForm.value);
        rowToBeAdded.temp = true;
        this.mortgageeTblConfig.tblData = [rowToBeAdded, ...this.mortgageeTblConfig.tblData];
        this.forms.resetMortgageeForm();
        this.btns.mortgagee.saveDisabled = false;
      }
      else {
        this.showErrorValidator.mortgagee();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onDelete() {
    try {
      this.mortgageeTblConfig.tblData = this.mortgageeTblConfig.tblData.filter((d: any) => {
        return d !== this.rowData;
      });
      this.forms.resetMortgageeForm();
    }
    catch (e) {
    console.error(e);

    }
  }

  public onUpdate() {
    try {
      this._formService.hideFormMsg("mortgagee-error-message");
      if (this.forms.mortgageeForm.valid) {
        let toBeUpdatedIndex = this.mortgageeTblConfig.tblData.indexOf(this.rowData);
        let bankCdRowValue = this.rowData.BANK_CD;
        let rowToBeUpdated: { [key: string]: any } = this.mortgageeFormManipulate.formToDb(this.forms.mortgageeForm.value);
        if (this.rowData.temp) {
          rowToBeUpdated.temp = true;
        }
        else {
          rowToBeUpdated.onDbButUpdatedTemp = true;
          rowToBeUpdated.BANK_CD = bankCdRowValue;
        }
        this.forms.resetMortgageeForm();
        this.mortgageeTblConfig.tblData[toBeUpdatedIndex] = rowToBeUpdated;
        this.btns.mortgagee.saveDisabled = false;
        this.mortgageeTblConfig.tblData = [... this.mortgageeTblConfig.tblData];
      }
      else {
        this.showErrorValidator.mortgagee();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onSave() {
    try {
      this.showForm = false;
      this._formService.showFormLoader(null, "mortgagee-maintenance-loading", "Saving.<br>Please wait ...", null, null, true);
      let toBeSaved = this.mortgageeTblConfig.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true));
      if (toBeSaved.length > 0) {
        toBeSaved.map((d: any) => {
          d.USER_ID = this.userIdLoggedIn;
        });
        //? To Be Uncomment later if chronological is needed ?//
        // let toBeAdded = toBeSaved.filter((d: any) => d.temp).reverse();
        // let toBeUpdated = toBeSaved.filter((d: any) => d.onDbButUpdatedTemp);
        // toBeSaved = [...toBeAdded, ...toBeUpdated];
        this._jDS.contorlLoading(true);
        this._api.saveMortgagee(toBeSaved).subscribe({
          next: (response: any) => {
            this._securityService.checkRequestKeyResponse(response, () => {
              this._securityService.hasValidCsrfToken(response, () => {
                this._jDS.contorlLoading(false);
                if (response.status === 'SUCCESS') this.onComplete.mortgagee();
                else this.onComplete.mortgageeError();
              });
            });
          },
          error: (data: any) => {

            this._jDS.contorlLoading(false);
            this.onComplete.mortgageeError();
          },
        });
      }
      else
        this.onComplete.mortgagee();
      // setTimeout(() => {
      //   this.onComplete.mortgagee();
      // }, 3000);
    }
    catch (e) {
    console.error(e);

    }
  }

  private onComplete = {
    mortgagee: () => {
      this.getData.mortgagee();
      this.btns.mortgagee.saveDisabled = true;
      this.showForm = true;
      this._formService.hideFormLoader("mortgagee-maintenance-loading");
      this.openDialog('SUCCESS', 'Mortgagee', 'saved');
    },
    mortgageeError: () => {
      this.showForm = true;
      this.btns.mortgagee.saveDisabled = false;
      this.openDialog('FAILED', 'Mortgagee', 'saving');
      this._formService.hideFormLoader("mortgagee-maintenance-loading");
    },
  }

  openDialog(title: string, contentTitle: string, contentDetail: string) {
    try {
      this._matDialog.open(DialogMsgComponent, {
        disableClose: true,
        width: '512px',
        data: {
          title: title,
          content:
            title === 'SUCCESS'
              ? `${contentTitle} details successfully ${contentDetail}!`
              : `${contentTitle} details ${contentDetail} failed!`,
        },
      });
    }
    catch (e) {
    console.error(e);

    }
  }

  /* Validator Stuffs Down here */

  checkDuplicateBankName(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.trim()?.toUpperCase();
      if (!value) return null;
      let hasDuplicate: boolean = this.mortgageeTblConfig.tblData.filter((d: any) => {
        if (!this.rowData && this.btns.mortgagee.updateDisabled)
          return d.BANK_NAME.toUpperCase() === value;
        else
          return this.rowData.BANK_NAME.trim().toUpperCase() !== value && d.BANK_NAME.trim().toUpperCase() === value;
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupeBankName: true } : null;
    }
  }

  checkDuplicateBmCode(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.trim()?.toUpperCase();
      if (!value) return null;
      let hasDuplicate: boolean = this.mortgageeTblConfig.tblData.filter((d: any) => {
        const trimLeadingZeros = (str: string) => str?.replace(/^0+/, '');
        if (!this.rowData && this.btns.mortgagee.updateDisabled)
          return trimLeadingZeros(d.BM_CODE)?.toUpperCase() === trimLeadingZeros(value);
        else
          return this.rowData.BM_CODE?.trim().toUpperCase() !== value && trimLeadingZeros(d.BM_CODE)?.trim().toUpperCase() === trimLeadingZeros(value);
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupeBmCode: true } : null;
    }
  }

  public showErrorValidator = {
    mortgagee: () => {
      try {
        Object.keys(this.forms.mortgageeForm.controls).forEach(key => {
          const controlErrors = this.forms.mortgageeForm.get(key)?.errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              if (keyError == 'hasDupeBankName' && controlErrors[keyError] === true)
                this.errorMessage = "Bank Name already exists. Please add a unique Bank Name.";
              if (keyError == 'hasDupeBmCode' && controlErrors[keyError] === true)
                this.errorMessage = "RPA Code already exists. Please add a unique RPA Code.";
              else if (keyError == 'required' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
            });
          }
        });
        this._formService.showFormMsg("mortgagee-error-message", this.errorMessage, "E");
      }
      catch (e) {
    console.error(e);

      }
    },
  }

}
