import { Component, Inject, OnInit } from '@angular/core';
import { APICallService } from 'src/app/services/api-call.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContentObserver } from '@angular/cdk/observers';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';


@Component({
  selector: 'contract-type-lov',
  templateUrl: './contract-type-lov.component.html',
  styleUrls: ['./contract-type-lov.component.css']
})
export class ContractTypeLovDialogComponent implements OnInit {

  contractTypeModuleId: string = 'BMM051';
  selectedTable: any;

  constructor(private apiCallService: APICallService,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<ContractTypeLovDialogComponent>) {
      this.getContractType();
    }
  
  ngOnInit(): void {
    if(this.data.moduleId === "BMM178") {
      this.selectedTable = this.openPolicyTableConfig;
    } else {
      this.selectedTable = this.contractTypeTableConfig;
    }
  }


  // Variable Initializer
  issueSourceData: any = [];

  // Issue Source Initializer
  contractTypeTableConfig: any = {
    cols: [
      {
        key: "CONTRACT_TYPE_CD",
        header: "Contract Type Code",
        dataType: "string"
      },
      {
        key: "CONTRACT_TYPE_DESC",
        header: "Description",
        dataType: "string"
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15
  }
  
  openPolicyTableConfig: any = {
    cols: [
      {
        key: "CONTRACT_TYPE_CD",
        header: "Contract Type Code",
        dataType: "string"
      },
      {
        key: "CONTRACT_TYPE_DESC",
        header: "Description",
        dataType: "string"
      },
      {
        key: "BM_CODE",
        header: "BM Code",
        dataType: "string"
      },
      {
        key: "BM_SHORT_DESC",
        header: "BM Desc",
        dataType: "string"
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15
  }

  // Gets data for Issue Source Table
  getContractType() {
    try {
      /*
      this.contractTypeTableConfig.loading = true;
      this.issueSourceData = await this.apiCallService.getAllIssueSource().toPromise();
      this.issueSourceData = this.issueSourceData.content;
      this.contractTypeTableConfig.loading = false;
      this.contractTypeTableConfig.tblData = this.issueSourceData;
      */
      this.contractTypeTableConfig.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.apiCallService.getAllContractType({
        moduleId: this.data.moduleId,
        userId: this.userDetailService.userId,
        type: "LOV"
      }).subscribe((data: any) => {
            this.securityService.checkRequestKeyResponse(data, () => {
              this.securityService.hasValidCsrfToken(data, ()=>{
                if(data.status === 'SUCCESS') {
                  data = JSON.parse(this.jsonDataService.decrypt(data.response));
                  if (data.moduleId === this.contractTypeModuleId) {
                    this.jsonDataService.contorlLoading(false);
                    this.contractTypeTableConfig.loading = false;
                    this.contractTypeTableConfig.tblData = data.data.filter((e: any) => e.ACTIVE_TAG == 'A');

                    if(this.data.moduleId === "BMM178") {
                      this.openPolicyTableConfig.tblData = data.data.filter((e: any) => e.ACTIVE_TAG == 'A').map((e: any) => {return {...e, CONTRACT_TYPE_CD: e.CONTRACT_TYPE_CD.padStart(3, '0')}});
                    }
                  } else {
                      this.jsonDataService.contorlLoading(false);
                      this.contractTypeTableConfig.loading = false;
                      this.appMessageService.showAppMessage("You do not have the correct privilege to access the data. Kindly contact your administrator for assistance.", "error");
                  }
                } else {
                  this.jsonDataService.contorlLoading(false);
                  this.contractTypeTableConfig.loading = false;
                  this.appMessageService.showAppMessage(data.message, "error");
                }
              });
            });
      });
    } 
    catch(e) {
      
    }
  }

  onRowClick(ev: any) {
    try {
      this.data = ev;
    }
    catch(e) {
      
    }
  }

  onClose() {
    try {
      if(this.data=='' || this.data==null){
        this._dialogRef.close({content: ''}); 
      } else {
        this._dialogRef.close({content: this.data, moduleId: this.data.moduleId});
      }
    }
    catch(e) {
      
    }
  }

  onBack() {
    try {
      this._dialogRef.close({content: '', option: 'back'});
    }
    catch(e) {
      
    }
  }

}

