<ng-template #uploadDialog>
    <h3 mat-dialog-title>{{ uploadName }}</h3>
    <mat-dialog-content>
        <div content id="upload-overlay" [ngStyle]="{'width': (data.width) ? data.width : '45vw' }">
            <osp-table #tbl [tblConfig]="data.tblConfig" (onRowClick)="rowClick($event)" (onLazyLoad)="lazyLoad($event)"></osp-table>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div id="upload-form-container">
            <form [formGroup]="fileUpload">
                <div class="upload-wrap">
                    <div class="field required">
                        <label>File</label>
                    </div>
                    <label for="file-upload" class="file-upload-label">
                        <ng-container *ngIf="fileUpload.value.file == ''"><span class="material-icons md-36">note_add</span></ng-container>
                        <ng-container *ngIf="fileUpload.value.file !== ''">{{ fileUpload.value.file?.name }}</ng-container>
                    </label>
                    <input #fileInput id="file-upload" type="file" (change)="fileHandlerFunction($event)">
                    <button class="btn btn2" (click)="onClickUpload()">Upload</button>
                </div>
                <div id="uploadFormMsg"></div>
            </form>
        </div>
        <div id="upload-btn-container">
            <button mat-button class="btn btn2" [mat-dialog-close]="true">Return</button>
            <button mat-button class="btn btn2" (click)="onClickErrorLogs()">Error Logs</button>
        </div>
    </mat-dialog-actions>
</ng-template>