import { Component, OnInit } from '@angular/core';
import { AbstractControl, AbstractControlOptions, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { CustomValidators } from 'src/app/utils/custom-validator';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';
import { LineWarrClauseLovComponent } from '../../common/line-warr-clause-lov/line-warr-clause-lov.component';

@Component({
  selector: 'rpa-clause-mapping-maintenance',
  templateUrl: './rpa-clause-mapping-maintenance.component.html',
  styleUrls: ['./rpa-clause-mapping-maintenance.component.css']
})
export class RpaClauseMappingMaintenanceComponent implements OnInit, LeaveConfirmation {

  private moduleId: string = 'BMM089';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc ?? "";

  constructor(
    public _formService: FormService,
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService,
    private _appMessageService: AppMessageService,
  ) {
    this.userIdLoggedIn = this._userDetailService.userId ?? "";
  }

  canDeactivate(): boolean | Observable<boolean> {
    return !(this.rpaClauseMappingTblConfig.tblData.filter((row: any) => ['I', 'U'].includes(row.operation)).length > 0 || this.rowsToDel.length > 0);
  }

  public btns = {
    rpaClauseMapping: {
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      addDisabled: true,
      lineSearchDisabled: false,
      sublineSearchDisabled: true,
      wcSearchDisabled: true,
    },
  };

  public showForm: boolean = true;

  //* Data Stuffs */
  private rowData: any;
  private rowsToDel: any[] = [];
  private errorMessage: string = "";
  private userIdLoggedIn: string;
  //? set selectedRow to [] to unselect the table
  public selectedRow!: any[];

  public forms = {
    lineSublineClgLovForm: this.fb.group({
      lineCd: [null],
      lineName: [null],
      sublineCd: [null],
      sublineName: [null],
      lineCdDisp: [{ value: '', disabled: 'true' }],
      sublineCdDisp: [{ value: '', disabled: 'true' }],
      clauseGrp: [null],
    }),
    rpaClauseMappingForm: this.fb.group({
      lineCd: [null],
      sublineCd: [null],
      clauseGrp: [null],
      seqNo: [null, [Validators.min(1), Validators.required]],
      wcCd: [null, [Validators.required]],
      wcTitle: [null, [Validators.required]],
      wcCdDisp: [null],
      bmCode: [null, [Validators.required, Validators.maxLength(8), CustomValidators.requiredTrim]],
      lastUpdateUser: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }],
    }, {
      validators: [this.checkDupes()],
    }),
    resetRpaClauseMappingForm: () => {
      this.forms.rpaClauseMappingForm.reset();
      this.btns.rpaClauseMapping.updateDisabled = true;
      this.btns.rpaClauseMapping.deleteDisabled = true;
      this.btns.rpaClauseMapping.lineSearchDisabled = this.btns.rpaClauseMapping.sublineSearchDisabled = this.btns.rpaClauseMapping.wcSearchDisabled = false;
      this.forms.lineSublineClgLovForm.get('clauseGrp')?.enable({ emitEvent: false });
      this._formService.hideFormMsg("rpa-clause-mapping-error-message");
      this.rowData = null;
      this.forms.rpaClauseMappingForm.get('seqNo')?.enable();
    },
    resetLineSublineClgLovForm: () => {
      this.forms.lineSublineClgLovForm.reset();
    },
    resetAllForms: () => {
      this.forms.resetLineSublineClgLovForm();
      this.forms.resetRpaClauseMappingForm();
    }
  }

  private rpaClauseMappingFormManipulate = {
    dbToForm: (data: any) => {
      return {
        lineCd: data.LINE_CD,
        sublineCd: data.SUBLINE_CD,
        clauseGrp: data.CLAUSE_GRP,
        seqNo: data.SEQ_NO,
        wcCd: data.WC_CD,
        wcTitle: data.WC_TITLE,
        wcCdDisp: `${data.WC_CD ?? ''} - ${data.WC_TITLE ?? ''}`,
        bmCode: data.BM_CODE,
        lastUpdateUser: data.USER_ID,
        lastUpdate: data.LAST_UPDATE,
      };
    },
    formToDb: (data: any) => {
      return {
        LINE_CD: data.lineCd,
        SUBLINE_CD: data.sublineCd,
        CLAUSE_GRP: data.clauseGrp,
        SEQ_NO: data.seqNo?.toString().padStart(2, 0),
        WC_CD: data.wcCd,
        WC_TITLE: data.wcTitle,
        BM_CODE: data.bmCode,
        USER_ID: null,
        LAST_UPDATE: null,
      };
    },
  }

  public rpaClauseMappingTblConfig = {
    cols: [
      {
        key: "SEQ_NO",
        header: "Sequence No.",
        dataType: "string",
        width: "128px"
      },
      {
        key: "WC_CD",
        header: "Warranties and Clause Code",
        dataType: "string",
      },
      {
        key: "WC_TITLE",
        header: "Warranties and Clause Title",
        dataType: "string",
      },
      {
        key: "BM_CODE",
        header: "BPI MS Code",
        dataType: "string",
        width: "128px"
      },
    ],
    tblData: [] as any[],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  ngOnInit(): void {
    this.forms.lineSublineClgLovForm.get('clauseGrp')?.valueChanges.subscribe((value: any) => {
      let btnSet = !(value && this.forms.lineSublineClgLovForm.get('sublineCd')?.value && this.forms.lineSublineClgLovForm.get('lineCd')?.value);
      this.forms.resetRpaClauseMappingForm();
      this.btns.rpaClauseMapping.addDisabled = this.btns.rpaClauseMapping.wcSearchDisabled = btnSet;
      this.getData.rpaClauseMapping();
    });
  }

  public openLov = {
    line: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'LINE',
          moduleId: this.moduleId
        },
      }
      this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button === 'OK') {
          this.forms.resetRpaClauseMappingForm();
          if (output) {
            this.forms.lineSublineClgLovForm.patchValue({
              lineCd: output.LINE_CD,
              lineName: output.LINE_NAME,
              lineCdDisp: `${output.LINE_CD} - ${output.LINE_NAME}`,
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null,
            });
            this.btns.rpaClauseMapping.sublineSearchDisabled = false;
            this.btns.rpaClauseMapping.addDisabled = this.btns.rpaClauseMapping.wcSearchDisabled = true;
          }
          else {
            this.forms.lineSublineClgLovForm.patchValue({
              lineCd: null,
              lineName: null,
              lineCdDisp: null,
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null,
            });
            this.btns.rpaClauseMapping.sublineSearchDisabled = true;
            this.btns.rpaClauseMapping.addDisabled = this.btns.rpaClauseMapping.wcSearchDisabled = true;
          }
          this.getData.rpaClauseMapping();
        }
      });
    },
    subline: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          lineCd: this.forms.lineSublineClgLovForm.get('lineCd')?.value,
          table: 'SUBLINE',
          moduleId: this.moduleId
        },
      }
      this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button === 'OK') {
          this.forms.resetRpaClauseMappingForm();
          if (output) {
            this.forms.lineSublineClgLovForm.patchValue({
              lineCd: output.LINE_CD,
              lineName: output.LINE_NAME,
              lineCdDisp: `${output.LINE_CD} - ${output.LINE_NAME}`,
              sublineCd: output.SUBLINE_CD,
              sublineName: output.SUBLINE_NAME,
              sublineCdDisp: `${output.SUBLINE_CD} - ${output.SUBLINE_NAME}`,
            });
          }
          else {
            this.forms.lineSublineClgLovForm.patchValue({
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null,
            });
            this.btns.rpaClauseMapping.addDisabled = this.btns.rpaClauseMapping.wcSearchDisabled = true;
          }
          this.getData.rpaClauseMapping();
        }
      });
    },
    wc: () => {
      // let wcCdExists: any[] = this.rpaClauseMappingTblConfig.tblData.map((row: any) => { return row.WC_CD; }).filter(Boolean);
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          lineCd: this.forms.lineSublineClgLovForm.get('lineCd')?.value,
          // wcCdExclude: wcCdExists,
          moduleId: this.moduleId
        },
      };
      this._matDialog.open(LineWarrClauseLovComponent, LOVOPTIONS).afterClosed().subscribe((lovData: any) => {
        if (lovData.button === 'OK') {
          if (lovData?.content?.WC_CD) {
            const data = lovData?.content;
            this.forms.rpaClauseMappingForm.patchValue({
              wcCdDisp: `${data.WC_CD} - ${data.WC_TITLE}`,
              wcCd: data.WC_CD,
              wcTitle: data.WC_TITLE,
            });
          }
          else {
            this.forms.rpaClauseMappingForm.patchValue({
              wcCdDisp: null,
              wcCd: null,
              wcTitle: null,
            });
          }
        }
      });
    },
  };

  private getData = {
    rpaClauseMapping: () => {
      try {
        this.rpaClauseMappingTblConfig.tblData = [];
        if (this.forms.lineSublineClgLovForm.get('lineCd')?.value
          && this.forms.lineSublineClgLovForm.get('sublineCd')?.value
          && this.forms.lineSublineClgLovForm.get('clauseGrp')?.value) {
          this._jDS.contorlLoading(true);
          this.rpaClauseMappingTblConfig.loading = true;
          this._api.getRpaClauseMapping({
            moduleId: this.moduleId,
            userId: this._userDetailService.userId,
            type: "MODULE",
          }).subscribe({
            next: (response: any) => {
              this._securityService.checkRequestKeyResponse(response, () => {
                this._securityService.hasValidCsrfToken(response, () => {
                  if (response.status === 'SUCCESS') {
                    let content = JSON.parse(this._jDS.decrypt(response?.response));
                    this.rpaClauseMappingTblConfig.tblData = content.data?.filter((row: any) => {
                      return (row.LINE_CD === this.forms.lineSublineClgLovForm.get('lineCd')?.value
                        && row.SUBLINE_CD === this.forms.lineSublineClgLovForm.get('sublineCd')?.value
                        && row.CLAUSE_GRP === this.forms.lineSublineClgLovForm.get('clauseGrp')?.value);
                    });
                    this.btns.rpaClauseMapping.addDisabled = this.btns.rpaClauseMapping.wcSearchDisabled = false;
                  }
                  else {
                    this._appMessageService.showAppMessage(response.message, "error");
                  }
                  this._jDS.contorlLoading(false);
                  this.rpaClauseMappingTblConfig.loading = false;
                });
              });
            },
            error: (e: any) => {
              this._securityService.checkRequestKeyResponse(e, () => {

                this._jDS.contorlLoading(false);
                this.rpaClauseMappingTblConfig.loading = false;
                this.btns.rpaClauseMapping.addDisabled = this.btns.rpaClauseMapping.wcSearchDisabled = true;
              });
            }
          });
        }
      }
      catch (e) {
    console.error(e);

      }
    },
    clauseGrp: () => {
      const filteredCg = this._jDS.data.refCds.filter((data: any) => data.rvDomain === 'CLAUSE_GRP').map((data: any) => { return { cd: data.rvLowValue, name: `${data.rvLowValue} - ${data.rvMeaning}` } });
      return filteredCg;
    },
  };

  public selections = {
    clauseGrps: [
      { cd: '', name: '' },
      ...this.getData.clauseGrp()
    ],
  };

  public onRowClick(ev: any): void {
    try {
      if (ev) {
        this.rowData = ev;
        this.rowData?.operation !== 'I' ? this.forms.rpaClauseMappingForm.get('seqNo')?.disable() : this.forms.rpaClauseMappingForm.get('seqNo')?.enable();
        this.populateFormFromTable();
      }
      else {
        this.rowData = null;
        this.forms.resetRpaClauseMappingForm();
      }
    }
    catch (e) {
    console.error(e);
      this.rowData = null;
      this.forms.resetRpaClauseMappingForm();
    }
  }

  private populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.rpaClauseMapping.updateDisabled = this.btns.rpaClauseMapping.deleteDisabled = false;
      // this.btns.rpaClauseMapping.deleteDisabled = !(this.rowData?.operation === 'I');
      // this.btns.rpaClauseMapping.lineSearchDisabled = this.btns.rpaClauseMapping.sublineSearchDisabled = true;
      this.btns.rpaClauseMapping.wcSearchDisabled = true;
      // this.forms.lineSublineClgLovForm.get('clauseGrp')?.disable({ emitEvent: false });
      this.forms.rpaClauseMappingForm.patchValue(this.rpaClauseMappingFormManipulate.dbToForm(data));
    }
    catch (e) {
    console.error(e);

    }
  }

  public onAdd(): void {
    try {
      this._formService.hideFormMsg("rpa-clause-mapping-error-message");
      if (this.forms.rpaClauseMappingForm.valid) {
        this.forms.rpaClauseMappingForm.patchValue({
          lineCd: this.forms.lineSublineClgLovForm.get('lineCd')?.value,
          sublineCd: this.forms.lineSublineClgLovForm.get('sublineCd')?.value,
          clauseGrp: this.forms.lineSublineClgLovForm.get('clauseGrp')?.value,
        });
        let rowToBeAdded: { [key: string]: any } = this.rpaClauseMappingFormManipulate.formToDb(this.forms.rpaClauseMappingForm.getRawValue());
        rowToBeAdded.operation = 'I';
        this.rpaClauseMappingTblConfig.tblData = [rowToBeAdded, ...this.rpaClauseMappingTblConfig.tblData];
        this.forms.resetRpaClauseMappingForm();
        this.btns.rpaClauseMapping.saveDisabled = false;
      }
      else {
        this.showErrorValidator.rpaClauseMapping();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onUpdate(): void {
    try {
      this._formService.hideFormMsg("rpa-clause-mapping-error-message");
      if (this.forms.rpaClauseMappingForm.valid) {
        const indexOfRow = this.rpaClauseMappingTblConfig.tblData.indexOf(this.rowData);
        let rowToBeUpdated: { [key: string]: any } = this.rpaClauseMappingFormManipulate.formToDb(this.forms.rpaClauseMappingForm.getRawValue());
        rowToBeUpdated.operation = (this.rowData.operation === 'I') ? 'I' : 'U';
        this.forms.resetRpaClauseMappingForm();
        this.rpaClauseMappingTblConfig.tblData[indexOfRow] = rowToBeUpdated;
        this.selectedRow = [];
        this.btns.rpaClauseMapping.saveDisabled = false;
        this.rpaClauseMappingTblConfig.tblData = [...this.rpaClauseMappingTblConfig.tblData];
      }
      else {
        this.showErrorValidator.rpaClauseMapping();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onDelete(): void {
    try {
      if (this.rowData.operation !== 'I') {
        this.rowsToDel = [...this.rowsToDel, this.rowData];
      }
      this.rpaClauseMappingTblConfig.tblData = this.rpaClauseMappingTblConfig.tblData.filter((row: any) => {
        return row !== this.rowData;
      });
      this.forms.resetRpaClauseMappingForm();
      this.btns.rpaClauseMapping.saveDisabled = false;
    }
    catch (e) {
    console.error(e);

    }
  }

  public onSave(): void {
    try {
      this._jDS.contorlLoading(true);
      this.showForm = false;
      this._formService.showFormLoader(null, "rpa-clause-mapping-maintenance-loading", "Saving.<br>Please wait ...", null, null, true);
      let toBeSaved = this.rpaClauseMappingTblConfig.tblData.filter((row: any) => ['I', 'U'].includes(row.operation));
      if (this.rowsToDel.length > 0) {
        let toBeDel = this.rowsToDel.map((row: any) => {
          row.operation = 'D'
          return row;
        });
        toBeSaved = [...toBeDel, ...toBeSaved];
      }
      if (toBeSaved.length > 0) {
        this._api.saveRpaClauseMapping(toBeSaved).subscribe({
          next: (response: any) => {
            this._securityService.checkRequestKeyResponse(response, () => {
              this._securityService.hasValidCsrfToken(response, () => {
                if (response.status === 'SUCCESS') this.onComplete.rpaClauseMapping();
                else this.onComplete.rpaClauseMappingError();
              });
            });
          },
          error: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {

              this.onComplete.rpaClauseMappingError();
            });
          }
        });
      }
      else {
        this.onComplete.rpaClauseMapping();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  private onComplete = {
    rpaClauseMapping: () => {
      this._jDS.contorlLoading(false);
      this._formService.hideFormLoader("rpa-clause-mapping-maintenance-loading");
      this.openDialog('SUCCESS', this.moduleName.replace('Maintenance', ''), 'saved');
      this.getData.rpaClauseMapping();
      this.rowsToDel = [];
      this.btns.rpaClauseMapping.saveDisabled = true;
      this.showForm = true;
      this.forms.resetRpaClauseMappingForm();
    },
    rpaClauseMappingError: () => {
      this._jDS.contorlLoading(false);
      this.openDialog('FAILED', this.moduleName.replace('Maintenance', ''), 'saving');
      this._formService.hideFormLoader("rpa-clause-mapping-maintenance-loading");
      this.showForm = true;
      this.forms.resetRpaClauseMappingForm();
    },
  }

  openDialog(title: string, contentTitle: string, contentDetail: string) {
    try {
      this._matDialog.open(DialogMsgComponent, {
        disableClose: true,
        width: '512px',
        data: {
          title: title,
          content:
            title === 'SUCCESS'
              ? `${contentTitle} details successfully ${contentDetail}!`
              : `${contentTitle} details ${contentDetail} failed!`,
        },
      });
    }
    catch (e) {
    console.error(e);

    }
  }

  //* Validator Stuffs Down here */

  private checkDupes(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const wcCdValue = control.get('wcCd')?.value;
      const seqNoValue = parseInt(control.get('seqNo')?.value);
      if (!wcCdValue || !seqNoValue) return null;
      let hasDuplicate: boolean = this.rpaClauseMappingTblConfig.tblData.filter((d: any) => this.checkBool(parseInt(d.SEQ_NO), seqNoValue, d.WC_CD, wcCdValue)).length > 0 ? true : false;
      return hasDuplicate ? { hasDupe: true } : null;
    }
  }

  private checkBool(dataSeqNo: number, seqNoValue: number, dataWcCd: string, wcCdValue: string): boolean {
    if (!this.rowData)
      return seqNoValue === dataSeqNo && wcCdValue == dataWcCd;
    return !(parseInt(this.rowData.SEQ_NO) === dataSeqNo && this.rowData.WC_CD == dataWcCd) && (wcCdValue == dataWcCd && seqNoValue === dataSeqNo);
  }

  public showErrorValidator = {
    rpaClauseMapping: () => {
      try {
        if (this.forms.rpaClauseMappingForm.errors) {
          const controlError = this.forms.rpaClauseMappingForm.errors;
          Object.keys(controlError).forEach(keyError => {
            if (keyError == 'hasDupe' && controlError[keyError] === true)
              this.errorMessage = "A record with the same combination of Line, Subline, Clause Group, Sequence No, and Warranties and Clause Code already exists. Please add a unique combination.";
          });
        }
        else {
          Object.keys(this.forms.rpaClauseMappingForm.controls).forEach(key => {
            const controlErrors = this.forms.rpaClauseMappingForm.get(key)?.errors;
            if (controlErrors != null) {
              Object.keys(controlErrors).forEach(keyError => {
                if (keyError == 'required' && controlErrors[keyError] === true)
                  this.errorMessage = "There are missing information. Please provide necessary information needed.";
                else if (keyError == 'hasDupe' && controlErrors[keyError] === true)
                  this.errorMessage = "A record with the same combination of Line, Subline, Clause Group, Sequence No, and Warranties and Clause Code already exists. Please add a unique combination.";
                else if (keyError == 'min')
                  this.errorMessage = `Sequence No. must be greater than ${controlErrors[keyError]['min']}. Please choose a different Sequence No.`;
              });
            }
          });
        }
        this._formService.showFormMsg("rpa-clause-mapping-error-message", this.errorMessage, "E");
      }
      catch (e) {
    console.error(e);

      }
    },
  }

}
