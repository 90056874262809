import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { APICallService } from 'src/app/services/api-call.service';
import { DialogBoxService } from 'src/app/services/dialog-box.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';

import * as XLSX from 'xlsx';

@Component({
	selector: 'upload-voucher-dialog',
	templateUrl: './upload-voucher-dialog.component.html',
	styleUrls: ['./upload-voucher-dialog.component.css']
})
export class UploadVoucherDialogComponent implements OnInit {

	private moduleId: string = 'BMM158';
	public dialogTitle: string = 'UPLOAD LIST FOR UPDATE';

	constructor(
		// PUBLIC
		@Inject(MAT_DIALOG_DATA) public dataIn: any,

		// PRIVATE
		private formBuilder: FormBuilder,
		private matDialogRef: MatDialogRef<UploadVoucherDialogComponent>,
		private _matDialog: MatDialog,
		private apiCallService: APICallService,
		private jsonDataService: JsonDataService,
		private securityService: SecurityService,
		private dialogBoxService: DialogBoxService,
		private formService: FormService,
	) { }

	private excelRows: any = [];

	dataTableConfig: any = {
		cols: [
			{
				key: "CLIENT_TYPE_GROUP",
				header: "Client Type Group",
				dataType: "string"
			},
			{
				key: "CLIENT_TYPE_GROUP_DESC",
				header: "Client Type Group Description",
				dataType: "string",
				disabled: false
			},
			{
				key: "BATCH_NO",
				header: "Batch No.",
				dataType: "string",
				disabled: false
			},
			{
				key: "VOUCHER_CODE",
				header: "Voucher Code",
				dataType: "string",
				disabled: false
			},
			{
				key: "EMAIL_ADDRESS",
				header: "Email Address",
				dataType: "string",
				disabled: false
			},
			{
				key: "EFFECTIVE_DATE",
				header: "Effective Date",
				dataType: "date",
				disabled: false
			},
			{
				key: "EXPIRY_DATE",
				header: "Expiry Date",
				dataType: "date",
				disabled: false
			},
		],
		tblData: [],
		selection: "single",
		paginator: true,
		rowsPerPage: 10,
		lazy: false,
	}

	private refreshTable: boolean = false;

	public fileForm: FormGroup = this.formBuilder.group({
		file: ["", [Validators.required]],
	});

	ngOnInit(): void {
	}

	fileHandlerFunction(event: any) {
		const excel: File = event.target.files[0];
		this.fileForm.patchValue({
			file: excel
		});
	}

	public onRead(): void {
		this.formService.hideFormMsg("upload-voucher-code-error-message");
		this.processExcel(this.fileForm.value.file);
	}

	private processExcel(data: File) {
		this.dataTableConfig.tblData = [];
		try {
			const reader: FileReader = new FileReader();
			reader.readAsBinaryString(data);
			reader.onload = (e: any) => {
				try {
					const binarystr: string = e.target.result;
					const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
					const wsname: string = wb.SheetNames[0];
					const ws: XLSX.WorkSheet = wb.Sheets[wsname];
					const excelData: any = XLSX.utils.sheet_to_json(ws, { blankrows: true, defval: "" });
					let data = excelData.map((row: any) => {
						if (row.hasOwnProperty("VOUCHER_CODE") && row.hasOwnProperty("EMAIL_ADDRESS")) {
							return {
								VOUCHER_CODE: row.VOUCHER_CODE,
								CLIENT_TYPE_GROUP: row.CLIENT_TYPE_GROUP,
								CLIENT_TYPE_GROUP_DESC: row.CLIENT_TYPE_GROUP_DESC,
								BATCH_NO: row.BATCH_NO,
								EMAIL_ADDRESS: row.EMAIL_ADDRESS.trim(),
								EFFECTIVE_DATE: this.dateToDateString(this.serialNumberToDate(row.EFFECTIVE_DATE || null)),
								EXPIRY_DATE: this.dateToDateString(this.serialNumberToDate(row.EXPIRY_DATE || null)),
							};
						}
						else {
							throw Error();
						}
					});
					this.dataTableConfig.tblData = data;
				}
				catch (e) {
    				console.error(e);
					this.formService.showFormMsg("upload-voucher-code-error-message", "Unable to upload your file. Please use valid uploading template.", "E");
					setTimeout(() => {
						this.formService.hideFormMsg("upload-voucher-code-error-message");
					}, 5000);
					this.fileForm.patchValue({
						file: ""
					});
				}
			};
		}
		catch (e) {
    console.error(e);
			this.fileForm.patchValue({
				file: ""
			});
			this.formService.showFormMsg("upload-voucher-code-error-message", "No file selected. Please select a file.", "E");
			setTimeout(() => {
				this.formService.hideFormMsg("upload-voucher-code-error-message");
			}, 5000);
		}
	}

	private serialNumberToDate(serial: number) {
		// ? Converts Serial Number from excel to Date
		if (!serial) {
			return null;
		}
		let utcDays = Math.floor(serial - 25569);
		let utcValue = utcDays * 86400;
		let dateInfo = new Date(utcValue * 1000);
		let fractionalDay = serial - Math.floor(serial) + 0.0000001;
		let totalSeconds = Math.floor(86400 * fractionalDay);
		let seconds = totalSeconds % 60;
		totalSeconds -= seconds;
		let hours = Math.floor(totalSeconds / (60 * 60));
		let minutes = Math.floor(totalSeconds / 60) % 60;

		return new Date(dateInfo.getFullYear(), dateInfo.getMonth(), dateInfo.getDate(), hours, minutes, seconds);
	}

	private dateToDateString(date: Date | null) {
		// ? Returns date string with MM/DD/YYYY format
		if (!date) {
			return null;
		}
		let newDate = ((date.getMonth() + 1).toString().padStart(2, '0') + '/' + date.getDate().toString().padStart(2, '0') + '/' + date.getFullYear());
		return newDate;
	}

	public onCancel(): void {
		this.matDialogRef.close({ refreshTable: this.refreshTable });
	}

	public onProceed(): void {
		this.formService.hideFormMsg("upload-voucher-code-error-message");
		if (this.fileForm.valid && this.dataTableConfig.tblData?.length > 0) {
			let toBeSaved = [...this.dataTableConfig.tblData];
			this.jsonDataService.contorlLoading(true);
			this.apiCallService.saveVouchers(toBeSaved).subscribe({
				next: (response: any) => {
					this.securityService.checkRequestKeyResponse(response, () => {
						this.securityService.hasValidCsrfToken(response, () => {
							if (response.status === "SUCCESS") {
								let content = JSON.parse(this.jsonDataService.decrypt(response.response)).result;
								this.jsonDataService.contorlLoading(false);
								this.dialogBoxService.open({
									messageType: "I",
									content: `Successful : ${content.success} Failed : ${content.failed}`,
									buttons: [{
										text: "OK",
										action: () => { this.refreshTable = true; }
									}]
								}, true);
							}
							else {
								this.jsonDataService.contorlLoading(false);
							}
						});
					});
				},
				error: (data: any) => {
					this.securityService.checkRequestKeyResponse(data, () => {
						this.jsonDataService.contorlLoading(false);
					});
				}
			});
		}
		else {
			this.formService.showFormMsg("upload-voucher-code-error-message", "There are missing information / errors. Please provide necessary information needed.", "E");
			setTimeout(() => {
				this.formService.hideFormMsg("upload-voucher-code-error-message");
			}, 5000);
		}
	}

}
