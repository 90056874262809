<div class="mtn-container">
    <div class="mtn-title">
        <p>{{ moduleName }}</p>
    </div>
    <osp-table [tblConfig]="bmaTblConfig" (onRowClick)="onRowClick($event)" ></osp-table>
    <div id="bma-maintenance-loading">
        <div class="form-loader"></div>
    </div>
    <div *ngIf="showForm" class="form-content">
        <form [formGroup]="forms.bmaForm" class="form">
            <div id="bma-error-message"></div>
            <div class="form-container-grid">
                <div class="field">
                    <label>BMA Code</label>
                </div>
                <div class="">
                    <input formControlName="bmaCdDisp" >
                </div>
                <div class="field required">
                    <label>Active</label>
                </div>
                <div class="">
                    <select formControlName="active">
                        <option *ngFor="let option of activeTags" [value]="option.value" [disabled]="option.value == 'L' || option.value == 'T'">
                            {{option.text}}
                        </option>
                    </select>
                </div>
                <div class="field required">
                    <label>BMA</label>
                </div>
                <div class="">
                    <input formControlName="bmaDesc" maxlength="45" >
                </div>
                <div class="field">
                    <label>Designation</label>
                </div>
                <div class="">
                    <input formControlName="designation" maxlength="50" >
                </div>
                <div class="field required">
                    <label>BPIMS Code</label>
                </div>
                <div class="">
                    <input formControlName="bmCode" maxlength="8" >
                </div>
                <div class="field">
                    <label>BPIMS Short Description</label>
                </div>
                <div class="">
                    <input formControlName="bmDesc" maxlength="10" >
                </div>
                <div class="field">
                    <label>Remarks</label>
                </div>
                <div class="span">
                    <input formControlName="remarks" maxlength="4000" >
                </div>
                <div class="field">
                    <label>Last Update User</label>
                </div>
                <div class="">
                    <input formControlName="lastUpdateUser" >
                </div>
                <div class="field">
                    <label>Last Update</label>
                </div>
                <div class="">
                    <input formControlName="lastUpdate" >
                </div>
            </div>
        </form>
        <div class="btn-grp">
            <button *ngIf="btns.bma.updateDisabled" class="btn btn2" type="button" (click)="onAdd()" >ADD</button>
            <button *ngIf="!btns.bma.updateDisabled" class="btn btn2" type="button" (click)="onUpdate()" >UPDATE</button>
            <button class="btn btn2" type="button" [disabled]='btns.bma.deleteDisabled' (click)="onDelete()" >DELETE</button>
        </div>
        <div class="mtn-title"></div>
        <div class="btn-grp">
            <button class="btn btn2" type="button" [disabled]='btns.bma.saveDisabled' (click)="onSave()">SAVE</button>
        </div>
    </div>
</div>
