import { Component, Inject, OnInit } from '@angular/core';
import { APICallService } from 'src/app/services/api-call.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

@Component({
  selector: 'src-cd-lov',
  templateUrl: './src-cd-lov.component.html',
  styleUrls: ['./src-cd-lov.component.css']
})
export class SrcCdLovComponent implements OnInit {

  srcCdModuleId: string = 'BMM055';

  constructor(
    private apiCallService: APICallService,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<SrcCdLovComponent>
  ) {
    this.srcCodeTableConfig.cols = (this.data.col === 'CD') ? this.srcCdColsCd : this.srcCdColsBm;
    this.getSrcCode();
  }

  ngOnInit(): void {
  }

  // Variable Initializer
  srcCodeData: any = [];
  srcCdColsCd: any[] = [
    {
      key: "SRC_CD",
      header: "Source Code",
      dataType: "string"
    },
    {
      key: "SRC_CD_DESC",
      header: "Source Code Description",
      dataType: "string"
    }
  ];

  srcCdColsBm: any[] = [
    {
      key: "BM_CODE",
      header: "Source Code",
      dataType: "string"
    },
    {
      key: "SRC_CD_DESC",
      header: "Source Code Description",
      dataType: "string"
    }
  ];
  // Source Code Initializer
  srcCodeTableConfig: any = {
    cols: [],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15
  }

  // Gets data for Source Code Table
  async getSrcCode() {
    try {
      /*
      this.srcCodeTableConfig.loading = true;
      this.srcCodeData = await this.apiCallService.getAllSourceCode().toPromise();
      this.srcCodeData = this.srcCodeData.content;
      this.srcCodeTableConfig.loading = false;
      this.srcCodeTableConfig.tblData = this.srcCodeData;
      */
      this.srcCodeTableConfig.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.apiCallService.getAllSourceCode({
        moduleId: this.data.moduleId,
        userId: this.userDetailService.userId,
        type: "LOV"
      }).subscribe((response: any) => {
        this.securityService.checkRequestKeyResponse(response, () => {
          this.securityService.hasValidCsrfToken(response, () => {
            if (response.status === 'SUCCESS') {
              let data = JSON.parse(this.jsonDataService.decrypt(response.response));
              if (data.moduleId === this.srcCdModuleId) {
                this.jsonDataService.contorlLoading(false);
                this.srcCodeTableConfig.loading = false;
                if (this.data.col === 'CD') {
                  this.srcCodeTableConfig.tblData = data.data?.sort((a: any, b: any) => (a.SRC_CD > b.SRC_CD) ? 1 : -1).filter((a: any) => a.ACTIVE_TAG === 'A');
                }
                else {
                  this.srcCodeTableConfig.tblData = data.data?.sort((a: any, b: any) => (a.BM_CODE > b.BM_CODE) ? 1 : -1).filter((a: any) => a.ACTIVE_TAG === 'A');
                }
                if (this.data?.lpad) {
                  this.srcCodeTableConfig.tblData = this.srcCodeTableConfig.tblData?.map((c: any) => { return { ...c, SRC_CD: parseInt(c.SRC_CD)?.toString().padStart(this.data?.lpad, "0") } })
                }
                if (this.data?.srcCdExclude) {
                  this.srcCodeTableConfig.tblData = this.srcCodeTableConfig.tblData.filter((d: any) => (!((this.data.srcCdExclude ?? []).includes(+d.SRC_CD))));
                }
              } else {
                this.srcCodeTableConfig.loading = false;
                this.jsonDataService.contorlLoading(false);
                this.appMessageService.showAppMessage("You do not have the correct privilege to access the data. Kindly contact your administrator for assistance.", "error");
              }
            } else {
              this.srcCodeTableConfig.loading = false;
              this.jsonDataService.contorlLoading(false);
              this.appMessageService.showAppMessage(response.message, "error");
            }
          });
        });
      });
    }
    catch (e) {
    console.error(e);

    }
  }

  onRowClick(ev: any) {
    try {
      this.data = ev;
    }
    catch (e) {
    console.error(e);

    }
  }

  onClose() {
    try {
      if (this.data == '' || this.data == null) {
        this._dialogRef.close({ content: '' });
      } else {
        this._dialogRef.close({ content: this.data });
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  onBack() {
    try {
      this._dialogRef.close({ content: '', option: 'back' });
    }
    catch (e) {
    console.error(e);

    }
  }

}
