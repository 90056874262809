<section id="occupation">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <osp-table [tblConfig]="occupationTable" (onRowClick)="onRowClick($event)" [selectedRow]="selectedRow"></osp-table>
        <!-- <div id="occupation-form"><div class="form-loader"></div></div> -->
        <div class="form-content" [hidden]="formHider">
            <form [formGroup]="occupationForm" class="form">
                <div id="occupation-error-message"></div>
                <div class="form-container-grid">
                    <div class="field required">
                        <label>Occupation Code</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="OCCUPATION_CD">
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="ACTIVE_TAG">
                            <option value="A">Yes</option>
                            <option value="I">No</option>
                        </select>
                    </div>
                    <div class="field required">
                        <label>Occupation Description</label>
                    </div>
                    <div class="span">
                        <input formControlName="OCCUPATION_DESC" maxlength="50" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="field required">
                        <label>BPIMS Code</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="BM_CODE" oninput="this.value = this.value.toUpperCase()" maxlength="8">
                    </div>
                    <div class="field">
                        <label>BPIMS Short Description</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="BM_SHORT_DESC" maxlength="10">
                    </div>
                    <div class="field required">
                        <label>Occupation Class</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="OCCUPATION_CLASS_CD">
                        <button class="btn-search btn" (click)="openOccClassLovDialog()">
                            <mat-icon>
                                search
                            </mat-icon>
                        </button>
                    </div>
                    <div class="field required">
                        <label>Category</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="CATEGORY_CD">
                        <button class="btn-search btn" (click)="openCategoryLovDialog()">
                            <mat-icon>
                                search
                            </mat-icon>
                        </button>
                    </div>
                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="REMARKS" maxlength="4000">
                    </div>
                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input formControlName="USER_ID">
                    </div>
                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="LAST_UPDATE">
                    </div>
                </div>
            </form>
            <div class="btn-grp">
                <button type="button" class="btn btn2" [hidden]="!addUpdateFlag" (click)="addRow()">ADD</button>
                <button type="button" class="btn btn2" [hidden]="addUpdateFlag" (click)="onUpdate()">UPDATE</button>
                <button type="button" class="btn btn2" [disabled]="deleteDisabler" (click)="deleteRow()">DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-grp">
                <button type="button" class="btn btn2" [disabled]="saveDisabler" (click)="onSave()">SAVE</button>
            </div>
        </div>
    </div>
</section>
