<div class="mtn-container">
    <div class="mtn-title">
        <p>{{moduleName}}</p>
    </div>
    <div class="outer-wrapper p-datatable mtn-header">
        <div *ngIf="showForm.srcFunds" class="form-content"></div>
        <osp-table [tblConfig]="tblConfig.srcFunds" (onRowClick)="onRowClick.srcFunds($event)"></osp-table>
        <div id="srcFunds-maintenance-loading"><div class="form-loader"></div></div>

        <div class="form-content" [hidden]="!showForm.srcFunds">

            <form [formGroup]="forms.srcFundsForm" class="form">
                <div id="srcFunds-error-message"></div>
                <div class="form-container-grid">
                    <div class="field">
                        <label>Source of Fund Code</label>
                    </div>
                    <div class="">
                        <input formControlName="srcFundCdPadded" type="text">
                    </div>

                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag" >
                            <option *ngFor="let activeType of selections.activeTypes" [value]="activeType.cd">
                                {{activeType.name}}
                            </option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>Source of Fund</label>
                    </div>
                    <div class="">
                        <input formControlName="srcFundDesc" type="text" maxlength="50">
                    </div>

                    <div class="field">
                        <label>No Tin</label>
                    </div>
                    <div class="">
                        <select formControlName="noTin">
                            <option *ngFor="let noTinType of selections.noTinTypes" [value]="noTinType.cd">
                                {{noTinType.name}}
                            </option>
                        </select>
                    </div>
                    <div class="field">
                        <label>Regular Employee</label>
                    </div>
                    <div class="">
                        <select formControlName="regEmp">
                            <option *ngFor="let regEmpType of selections.regEmpTypes" [value]="regEmpType.cd">
                                {{regEmpType.name}}
                            </option>
                        </select>
                    </div>
                    
                    <div class="field">
                        <label>Donation</label>
                    </div>
                    <div class="">
                        <select formControlName="donation">
                            <option *ngFor="let donationType of selections.donationTypes" [value]="donationType.cd">
                                {{donationType.name}}
                            </option>
                        </select>
                    </div>
                    <div class="field">
                        <label>Politically Exposed Person</label>
                    </div>
                    <div class="">
                        <select formControlName="polExpPer">
                            <option *ngFor="let polExpPersonType of selections.polExpPersonTypes" [value]="polExpPersonType.cd">
                                {{polExpPersonType.name}}
                            </option>
                        </select>
                    </div>

                    <div class="field">
                        <label>Overseas</label>
                    </div>
                    <div class="">
                        <select formControlName="overseas">
                            <option *ngFor="let overseasType of selections.overseasTypes" [value]="overseasType.cd">
                                {{overseasType.name}}
                            </option>
                        </select>
                    </div>
                    <div class="field">
                        <label>BPIMS Code</label>
                    </div>
                    <div class="">
                        <input formControlName="bpimsCd" type="text" maxlength="8">
                    </div>

                    <div class="field">
                        <label>BPIMS Short Description</label>
                    </div>
                    <div class="">
                        <input formControlName="bpimsShortDesc" type="text" maxlength="10">
                    </div>

                    <div class="field">
                        <label>Remarks</label>
                        </div>
                    <div class="span">
                        <input formControlName="remarks" type="text" maxlength="4000">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="lastUpdateUser">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="lastUpdate">
                    </div>
                </div>    
            </form>

            <div class="btn-grp">
                <button *ngIf="btns.srcFunds.updateDisabled" class="btn btn2" type="button" (click)="onAdd.srcFunds()" [disabled]="btns.srcFunds.addDisabled" >ADD</button>
                <button *ngIf="!btns.srcFunds.updateDisabled" class="btn btn2" type="button" (click)="onUpdate.srcFunds()" >UPDATE</button>
                <button class="btn btn2" type="button" (click)="onDelete.srcFunds()" [disabled]='btns.srcFunds.deleteDisabled'>DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-grp">
                <button type="button" class="btn btn2" [disabled]="btns.srcFunds.saveDisabled" (click)="onSave.srcFunds()">SAVE</button>
            </div>
        </div>
    </div> 
</div>