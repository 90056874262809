<div class="mtn-wrapper">
    <div class="mtn-title">
        <p>{{ moduleName }}</p>
    </div>
    <section id="src-ext">
        <div class="tbl">
            <osp-table #tbl [tblConfig]="tblConfig" (onRowClick)="onRowClick($event)" (onLazyLoad)="lazyLoad($event)"></osp-table>
        </div>
        <div>
            <div class="p-grid">
                <div class="p-col-1"></div>
                <form class="p-col-10 form" [formGroup]="form">
                    <div id="src-ext-form-msg"></div>
                    <div id="src-ext-form">
                        <div class="form-loader"></div>
                        <div class="form-fields">
                            <div class="p-grid cust-margin">
                                <div class="p-col-6 p-grid">
                                    <div class="p-col-6 p-text-right field required">
                                        <label>Referror No.</label>
                                    </div>
                                    <div class="p-col-6">
                                        <input matInput type="text" formControlName="referrorNo" class="lov-inp">
                                        <button matSuffix class="btn btn-search" type="button" (click)="openLov('referror')" [disabled]="isUpdateRow">
                                            <mat-icon>search</mat-icon>
                                        </button>
                                    </div>
                                </div>
                                <div class="p-col-6 p-grid">
                                    <div class="p-col-6 p-text-right field">
                                        <label>Referror</label>
                                    </div>
                                    <div class="p-col-6">
                                        <input matInput type="text" formControlName="referror" class="lov-inp">
                                    </div>
                                </div>
                            </div>
                            <div class="p-grid cust-margin">
                                <div class="p-col-6 p-grid">
                                    <div class="p-col-6 p-text-right field required">
                                        <label>Source Extension</label>
                                    </div>
                                    <div class="p-col-6">
                                        <input matInput type="text" formControlName="sourceExtension" class="lov-inp">
                                        <button matSuffix class="btn btn-search" type="button" (click)="openLov('srcExt')" [disabled]="isUpdateRow">
                                            <mat-icon>search</mat-icon>
                                        </button>
                                    </div>
                                </div>
                                <div class="p-col-6 p-grid">
                                    <div class="p-col-6 p-text-right field">
                                        <label>Branch Code</label>
                                    </div>
                                    <div class="p-col-6">
                                        <input matInput type="text" formControlName="branchCode" class="lov-inp">
                                    </div>
                                </div>
                            </div>
                            <div class="p-grid cust-margin">
                                <div class="p-col-6 p-grid">
                                    <div class="p-col-6 p-text-right field required">
                                        <label>Segment</label>
                                    </div>
                                    <div class="p-col-6">
                                        <input matInput type="text" formControlName="segment" class="lov-inp">
                                        <button matSuffix class="btn btn-search" type="button" (click)="openLov('segment')" [disabled]="isUpdateRow">
                                            <mat-icon>search</mat-icon>
                                        </button>
                                    </div>
                                </div>
                                <div class="p-col-6 p-grid">
                                    <div class="p-col-6 p-text-right field">
                                        <label>Segment Code</label>
                                    </div>
                                    <div class="p-col-6">
                                        <input matInput type="text" formControlName="segmentCode" class="lov-inp">
                                    </div>
                                </div>
                            </div>
                            <div class="p-grid cust-margin">
                                <div class="p-col-6 p-grid">
                                    <div class="p-col-6 p-text-right field required">
                                        <label>Effective Date</label>
                                    </div>
                                    <div class="p-col-6 dp-cont">
                                        <input matInput formControlName="effDate" type="text" placeholder="MM/DD/YYYY" [matDatepicker]="effDatePicker" autocomplete="off" date-input>
                                        <mat-datepicker-toggle [for]="effDatePicker"></mat-datepicker-toggle>
                                        <mat-datepicker #effDatePicker></mat-datepicker>
                                    </div>
                                </div>
                                <div class="p-col-6 p-grid">
                                    <div class="p-col-6 p-text-right field">
                                        <label>Expiry Date</label>
                                    </div>
                                    <div class="p-col-6 dp-cont">
                                        <input matInput formControlName="expiryDate" type="text" placeholder="MM/DD/YYYY" [matDatepicker]="expiryDatePicker" autocomplete="off" date-input>
                                        <mat-datepicker-toggle [for]="expiryDatePicker"></mat-datepicker-toggle>
                                        <mat-datepicker #expiryDatePicker></mat-datepicker>
                                    </div>
                                </div>
                            </div>
                            <div class="p-grid cust-margin">
                                <div class="p-col-12 p-grid">
                                    <div class="p-col-12 p-grid">
                                        <div class="p-col-3 p-text-right field">
                                            <label>Remarks</label>
                                        </div>
                                        <div class="p-col-9">
                                            <input matInput type="text" formControlName="remarks" maxlength="4000">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-grid cust-margin">
                                <div class="p-col-6 p-grid">
                                    <div class="p-col-6 p-text-right field">
                                        <label>Create User</label>
                                    </div>
                                    <div class="p-col-6">
                                        <input matInput type="text" formControlName="createUser">
                                    </div>
                                </div>
                                <div class="p-col-6 p-grid">
                                    <div class="p-col-6 p-text-right field">
                                        <label>Create Date</label>
                                    </div>
                                    <div class="p-col-6">
                                        <input matInput type="text" formControlName="createDate">
                                    </div>
                                </div>
                            </div>
                            <div class="p-grid cust-margin">
                                <div class="p-col-6 p-grid">
                                    <div class="p-col-6 p-text-right field">
                                        <label>Last Update User</label>
                                    </div>
                                    <div class="p-col-6">
                                        <input matInput type="text" formControlName="lastUser">
                                    </div>
                                </div>
                                <div class="p-col-6 p-grid">
                                    <div class="p-col-6 p-text-right field">
                                        <label>Last Update</label>
                                    </div>
                                    <div class="p-col-6">
                                        <input matInput type="text" formControlName="lastUserUpdate">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-grid form-btns">
                        <div class="p-col p-text-center bot-form form-btns">
                            <button type="button" class="btn btn2" (click)="onClickAddUpdate()">{{ selectedRow == null ? 'ADD' : 'UPDATE' }}</button>
                            <button type="button" class="btn btn2" (click)="onClickAddUpdate(true)" [disabled]="selectedRow == null">DELETE</button>
                            <div class="mtn-title-2"></div>
                            <button type="button" class="btn btn2" (click)="upload.open()">UPLOAD</button>
                            <button type="button" class="btn btn2" (click)="onClickSave()" [disabled]="saveList.length == 0">SAVE</button>
                        </div>
                    </div>
                </form>
                <div class="p-col-1"></div>
            </div>
        </div>
    </section>
</div>

<lov #lov [data]="lovData" (selection)="selectedFromLov($event)"></lov>
<upload #upload [data]="uploadConfig"></upload>