import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
	selector: '[decimal]'
})
export class DecimalDirective implements AfterViewInit, OnInit {

	constructor(
		private control: NgControl,
		private el: ElementRef,
	) { }

	ngAfterViewInit(): void {
		setTimeout(() => this.onBlur(), 0);
	}

	ngOnInit(): void {
		this.regex = new RegExp(`^\\d{0,${this.digitsNum}}(\\.\\d{0,${this.decimalsNum}})?$`, 'g');
		this.maxAmt = +"".padEnd(this.digitsNum, '9') + +('.' + ''.padEnd(this.decimalsNum, '9'));
	}

	private navigationKeys = [
		'Backspace',
		'Delete',
		'Tab',
		'Escape',
		'Enter',
		'Home',
		'End',
		'ArrowLeft',
		'ArrowRight',
		'Clear',
		'Copy',
		'Paste'
	];

	@Input('decimals') decimalsNum: number = 9;
	@Input('digits') digitsNum: number = 3;
	private regex!: RegExp;
	private maxAmt!: number;

	@HostListener("keydown", ["$event"])
	onKeyDown(e: KeyboardEvent) {
		if (
			this.navigationKeys.indexOf(e.key) > -1      // Allow: navigation keys: backspace, delete, arrows etc.
			|| (e.key === "a" && e.ctrlKey === true)        // Allow: Ctrl+A
			|| (e.key === "c" && e.ctrlKey === true)        // Allow: Ctrl+C
			|| (e.key === "v" && e.ctrlKey === true)        // Allow: Ctrl+V
			|| (e.key === "x" && e.ctrlKey === true)        // Allow: Ctrl+X
			|| (e.key === "a" && e.metaKey === true)        // Allow: Cmd+A (Mac)
			|| (e.key === "c" && e.metaKey === true)        // Allow: Cmd+C (Mac)
			|| (e.key === "v" && e.metaKey === true)        // Allow: Cmd+V (Mac)
			|| (e.key === "x" && e.metaKey === true)        // Allow: Cmd+X (Mac)
			|| (e.key === ".")                              // Allow: period
		) {
			// let it happen, don't do anything
			return;
		}

		// Ensure that it is a number and stop the keypress
		if (e.shiftKey || (!parseInt(e.key) && parseInt(e.key) !== 0)) {
			e.preventDefault();
		}

		let current: string = this.el.nativeElement.value;
		const position = this.el.nativeElement.selectionStart;
		const positionEnd = this.el.nativeElement.selectionEnd;
		const next: string = [current.slice(0, position), e.key == 'Decimal' ? '.' : e.key, current.slice(positionEnd)].join('');

		if (next && !String(next).match(this.regex)) {
			e.preventDefault();
		}
	}

	@HostListener("focus", ["$event"])
	onFocus() {
		if (this.control.value) {
			const nm = (this.control.value + "").replace(/[^0-9\.]/g, "");
			this.control.valueAccessor?.writeValue(nm); // mask DOM element only
		}
	}

	@HostListener("blur", ["$event"])
	onBlur() {
		if (this.control.value) {
			const nm = (this.control.value + "").split(".");
			let wn = nm[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
			wn = wn.substr(0, 1) === "0" ? wn.substr(1) : wn;
			const dn = parseFloat("0." + (nm[1] || "00")).toFixed(this.decimalsNum).substr(1);
			const amount = (wn || "0") + dn;

			this.control.control?.setValue((amount + "").replace(/[^0-9\.]/g, ""), { emitEvent: false });
			this.control.valueAccessor?.writeValue(amount); // mask DOM element only
		}
	}

	@HostListener("paste", ["$event"])
	onPaste(event: ClipboardEvent) {
		event.preventDefault();

		// Remove all non-numeric and non-dot (.) value
		let pastedInput = event.clipboardData?.getData("text/plain").replace(/[^0-9\.]/g, "").replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2");
		pastedInput && document.execCommand("insertText", false, pastedInput);
	}

}
