<section id="agent-group">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <osp-table [tblConfig]="agentGroupTable" (onRowClick)="onRowClick($event)" [selectedRow]="selectedRowData"></osp-table>
        <!-- <div id="agent-group-form"><div class="form-loader"></div></div> -->
        <div class="form-content" [hidden]="formHider">
            <form [formGroup]="agentGroupForm" class="form">
                <div id="agent-group-error-message"></div>
                <div class="form-container-grid">

                    <div class="field required">
                        <label>Agent Group Code</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="AGENT_GRP" oninput="this.value = this.value.toUpperCase()" maxlength="10">
                    </div>

                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="ACTIVE_TAG">
                            <option value="A">Yes</option>
                            <option value="I">No</option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>Agent Group Description</label>
                    </div>
                    <div class="span">
                        <input type="text" formControlName="AGENT_GRP_DESC" maxlength="500">
                    </div>

                    <div class="field required">
                        <label>Edit Premium</label>
                    </div>
                    <div class="">
                        <select formControlName="EDIT_PREM_TAG">
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                        </select>
                    </div>

                    <div class="field">
                        <label>Hide Commission</label>
                    </div>
                    <div class="">
                        <select formControlName="HIDE_COMM_TAG">
                            <option value=""></option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                        </select>
                    </div>

                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input type="text" formControlName="REMARKS" maxlength="4000">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input formControlName="USER_ID">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="LAST_UPDATE">
                    </div>

                </div>
            </form>
            <div class="btn-grp">
                <button type="button" class="btn btn2" [hidden]="!addUpdateToggler" (click)="addRow()">ADD</button>
                <button type="button" class="btn btn2" [hidden]="addUpdateToggler" (click)="onUpdate()">UPDATE</button>
                <button type="button" class="btn btn2" [disabled]="deleteDisabler" (click)="deleteTableRow()">DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-grp">
                <button type="button" class="btn btn2" [disabled]="saveDisabler" (click)="onSave()">SAVE</button>
            </div>
        </div>
    </div>
</section>