import { Component, Inject, OnInit } from '@angular/core';
import { APICallService } from 'src/app/services/api-call.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { AppMessageService } from 'src/app/services/app-message.service';

@Component({
  selector: 'eq-zone-lov',
  templateUrl: './eq-zone-lov.component.html',
  styleUrls: ['./eq-zone-lov.component.css']
})
export class EQZoneLovComponent {

  moduleId: string = 'BMM141';
  private selectedData: any;
  public titleLov: string = "EQ ZONE";

  table: any = {
    cols: [],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  };


  private tableCol: any[] = [
    {
      key: "eqZoneCd",
      header: "EQ Zone Code",
      dataType: "number",
      width: '30%'
    },
    {
      key: "eqZoneDesc",
      header: "EQ Zone",
      dataType: "string"
    },
  ];
  private tablePlanCol: any[] = [
    {
      key: "EQ_ZONE_CD",
      header: "Code",
      dataType: "number",
      width: '30%'
    },
    {
      key: "EQ_ZONE_LONG_DESC",
      header: "Plan",
      dataType: "string"
    },
  ];
  private tableShortCol: any[] = [
    {
      key: "eqZoneCd",
      header: "EQ Zone Code",
      dataType: "number",
      width: '30%'
    },
    {
      key: "eqZoneDesc",
      header: "EQ Zone Short Description",
      dataType: "string"
    },
  ];
  constructor(
    private jsonDataService: JsonDataService,
    private dialogRef: MatDialogRef<EQZoneLovComponent>,
    private _api: APICallService,
    private securityService: SecurityService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    @Inject(MAT_DIALOG_DATA) public fromMainData: any,
  ) {
    if("title" in this.fromMainData){
      this.titleLov = this.fromMainData.title;
    } 
    if("tableData" in this.fromMainData){
      if(this.fromMainData.tableData == "SHORT"){
        this.table.cols = this.tableShortCol;
        this.getData();
      }
      else if(this.fromMainData.tableData == "PLAN"){
        this.table.cols = this.tablePlanCol;
        this.getData();
      }
      else{
        this.table.cols = this.tableCol;
        this.getData();
      }
    }
    else{
      this.table.cols = this.tableCol;
      this.getData();
    }
  }

  onRowClick(ev: any) {
    try {
      this.selectedData = ev;
    } catch (e) {
      console.error(e); 
    }
  }

  private getData(): void {
    try {
      this.table.loading = true;
      this.jsonDataService.contorlLoading(true);
      this._api.getEarthquakeZone({
        moduleId: this.fromMainData.moduleId,
        userId: this.userDetailService.userId,
        type: "LOV",
        stateCd: this.fromMainData.stateCd
      }).subscribe({
        next: (data: any) => {
          this.securityService.checkRequestKeyResponse(data, () => {
            this.securityService.hasValidCsrfToken(data, () => {
              let content = JSON.parse(this.jsonDataService.decrypt(data.response));
              if (content.moduleId === this.moduleId) {
                this.jsonDataService.contorlLoading(false);
                this.table.tblData = content.data.filter((d: any) =>{
                  if(d.ACTIVE_TAG === 'A'){
                    d.eqZoneCd = d.EQ_ZONE_CD;
                    
                    if(this.fromMainData.desc == "SHORT"){
                      d.eqZoneDesc = d.EQ_ZONE_SHORT_DESC;
                    }else{
                      d.eqZoneDesc = d.EQ_ZONE_LONG_DESC;
                    }

                    return true;
                  }else{
                    return false;
                  }
                });
                this.table.loading = false;
              } else {
                this.table.loading = false;
                this.jsonDataService.contorlLoading(false);
                this.appMessageService.showAppMessage("You do not have the correct privilege to access the data. Kindly contact your administrator for assistance.", "error");
              }
            });
          });
        },
        error: (e: any) => {
          this.securityService.checkRequestKeyResponse(e, () => {
            
            this.table.loading = false;
            this.jsonDataService.contorlLoading(false);
            this.appMessageService.showAppMessage(e, "error");
          });
        }
      });
    }
    catch (e) {
      console.error(e);
      this.table.loading = false;
      this.jsonDataService.contorlLoading(false);
    }
  }

  onClose(str: string) {
    try {
      if (this.selectedData == '' || this.selectedData == null) {
        this.dialogRef.close({ content: '', button: str });
      }
      else {
        this.dialogRef.close({ content: this.selectedData, button: str });
      }
    } catch (e) {
      console.error(e);
    }
  }

}
