<div class="mtn-container">
    <div class="mtn-title">
        <p>{{ moduleName }}</p>
    </div>
    <osp-table [tblConfig]="projectTblConfig" (onRowClick)="onRowClick($event)"></osp-table>
    <div id="project-maintenance-loading">
        <div class="form-loader"></div>
    </div>
    <div *ngIf="showForm" class="form-content">
        <form [formGroup]="forms.projectForm" class="form">
            <div id="project-error-message"></div>
            <div class="form-container-grid">
                <div class="field">
                    <label>Project Code</label>
                </div>
                <div class="">
                    <input formControlName="projectCdDisp" >
                </div>
                <div class="field required">
                    <label>Active</label>
                </div>
                <div class="">
                    <select formControlName="active">
                        <option *ngFor="let activeType of selections.activeTypes" [value]="activeType.cd">
                            {{activeType.name}}
                        </option>
                    </select>
                </div>
                <div class="field required">
                    <label>Project Description</label>
                </div>
                <div class="">
                    <input formControlName="projectDesc" maxlength="30" >
                </div>
                <div class="field">
                    <label>BPIMS Short Description</label>
                </div>
                <div class="">
                    <input formControlName="bmShortDesc" maxlength="10" >
                </div>
                <div class="field required">
                    <label>BPIMS Code</label>
                </div>
                <div class="">
                    <input formControlName="bmCode" maxlength="8" >
                </div>
                <div class="empty"></div>
                <div class="empty"></div>
                <div class="field">
                    <label>Remarks</label>
                </div>
                <div class="span">
                    <input formControlName="remarks" maxlength="4000" >
                </div>
                <div class="field">
                    <label>Last Update User</label>
                </div>
                <div class="">
                    <input formControlName="lastUpdateUser" >
                </div>
                <div class="field">
                    <label>Last Update</label>
                </div>
                <div class="">
                    <input formControlName="lastUpdate" >
                </div>
            </div>
        </form>
        <div class="btn-grp">
            <button *ngIf="btns.project.updateDisabled" class="btn btn2" type="button" (click)="onAdd()" >ADD</button>
            <button *ngIf="!btns.project.updateDisabled" class="btn btn2" type="button" (click)="onUpdate()" >UPDATE</button>
            <button class="btn btn2" type="button" [disabled]='btns.project.deleteDisabled' (click)="onDelete()" >DELETE</button>
        </div>
        <div class="mtn-title"></div>
        <div class="btn-grp">
            <button class="btn btn2" type="button" [disabled]='btns.project.saveDisabled' (click)="onSave()" >SAVE</button>
        </div>
    </div>
</div>