import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { APICallService } from 'src/app/services/api-call.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

@Component({
  selector: 'lov-dialog',
  templateUrl: './lov-dialog.component.html',
  styleUrls: ['./lov-dialog.component.css']
})
export class LovDialogComponent implements OnInit {

  selectedData: any;
  moduleId: string = 'BMM017';
  issCdmoduleId: string = 'BMM022';
  bmaModuleId: string = 'BMM057';
  srcCdModuleId: string = 'BMM055';
  scrExtModuleId: string = 'BMM056';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<LovDialogComponent>,
    private apiCallService: APICallService,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService
  ) { }

  ngOnInit(): void {
    if (this.data.origin == 'iss') {
      this.getIssueSource();
    }
    if (this.data.origin == 'bma') {
      this.getAgentBma();
    }
    if (this.data.origin == 'sourceCode') {
      this.getAgentSourceCode();
    }
    if (this.data.origin == 'sourceExtension') {
      this.getAgentSourceExtension();
    }
  }

  tableConfig: any = {
    cols: [
      {
        key: this.data.col1Key,
        header: this.data.col1Header,
        dataType: this.data.col1DataType
      },
      {
        key: this.data.col2Key,
        header: this.data.col2Header,
        dataType: this.data.col2DataType
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    // tblCaption: "ISSUE SOURCE",
    totalRecords: 15,
    loading: false,
  }

  getIssueSource() {
    try {
      this.tableConfig.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.apiCallService.getAllIssueSource({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "LOV"
      }).subscribe((data: any) => {
        this.securityService.checkRequestKeyResponse(data, () => {
          this.securityService.hasValidCsrfToken(data, () => {
            if (data.status === "SUCCESS") {
              let iss = JSON.parse(this.jsonDataService.decrypt(data.response));
              if (iss.moduleId === this.issCdmoduleId) {
                this.jsonDataService.contorlLoading(false);
                this.tableConfig.tblData = iss.data.filter((e: any) => {
                  this.tableConfig.loading = false;
                  return e.ACTIVE_TAG == 'A';
                });
              } else {
                this.jsonDataService.contorlLoading(false);
                this.tableConfig.loading = false;
                this.appMessageService.showAppMessage("You do not have the correct privilege to access the data. Kindly contact your administrator for assistance.", "error");
              }
            } else {
              this.jsonDataService.contorlLoading(false);
              this.tableConfig.loading = false;
              this.appMessageService.showAppMessage(data.message, "error");
            }
          });
        });
      });
    } catch (e) {
    console.error(e);
      console.log(e);
    }
  }

  getAgentBma() {
    try {
      this.tableConfig.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.apiCallService.getAgentBma({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "LOV"
      }).subscribe((response: any) => {
        this.securityService.checkRequestKeyResponse(response, () => {
          this.securityService.hasValidCsrfToken(response, () => {
            if (response.status === "SUCCESS") {
              let bma = JSON.parse(this.jsonDataService.decrypt(response.response));
              if (bma.moduleId === this.bmaModuleId) {
                this.jsonDataService.contorlLoading(false);
                this.tableConfig.tblData = bma.data.filter((e: any) => {
                  return e.ACTIVE_TAG == 'A';
                });
                this.tableConfig.loading = false;
              } else {
                this.jsonDataService.contorlLoading(false);
                this.tableConfig.loading = false;
                this.appMessageService.showAppMessage("You do not have the correct privilege to access the data. Kindly contact your administrator for assistance.", "error");
              }
            } else {
              this.jsonDataService.contorlLoading(false);
              this.tableConfig.loading = false;
              this.appMessageService.showAppMessage(response.message, "error");
            }
          });
        });
      });
    } catch (e) {
    console.error(e);
      console.log(e);
    }
  }

  getAgentSourceCode() {
    try {
      this.tableConfig.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.apiCallService.getAllSourceCode({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "LOV"
      }).subscribe((response: any) => {
        this.securityService.checkRequestKeyResponse(response, () => {
          this.securityService.hasValidCsrfToken(response, () => {
            if (response.status === "SUCCESS") {
              let asc = JSON.parse(this.jsonDataService.decrypt(response.response));
              if (asc.moduleId === this.srcCdModuleId) {
                this.jsonDataService.contorlLoading(false);
                this.tableConfig.loading = false;
                this.tableConfig.tblData = asc.data.filter((e: any) => {
                  return e.ACTIVE_TAG == 'A';
                });
              } else {
                this.appMessageService.showAppMessage("You do not have the correct privilege to access the data. Kindly contact your administrator for assistance.", "error");
                this.jsonDataService.contorlLoading(false);
                this.tableConfig.loading = false;
              }
            } else {
              this.jsonDataService.contorlLoading(false);
              this.tableConfig.loading = false;
              this.appMessageService.showAppMessage(response.message, "error");
            }
          });
        });
      });
    } catch (e) {
    console.error(e);
      console.log(e);
    }
  }

  getAgentSourceExtension() {
    try {
      this.tableConfig.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.apiCallService.getAllSourceExtension({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "LOV"
      }).subscribe((response: any) => {
        this.securityService.checkRequestKeyResponse(response, () => {
          this.securityService.hasValidCsrfToken(response, () => {
            if (response.status === 'SUCCESS') {
              let ase = JSON.parse(this.jsonDataService.decrypt(response.response));
              if (ase.moduleId === this.scrExtModuleId) {
                this.jsonDataService.contorlLoading(false);
                this.tableConfig.tblData = ase.data.filter((e: any) => {
                  return e.ACTIVE_TAG == 'A';
                }).sort((a: any, b: any) => (a.BM_CODE > b.BM_CODE) ? 1 : -1);
                this.tableConfig.loading = false;
              } else {
                this.tableConfig.loading = false;
                this.jsonDataService.contorlLoading(false);
                this.appMessageService.showAppMessage("You do not have the correct privilege to access the data. Kindly contact your administrator for assistance.", "error");
              }
            } else {
              this.tableConfig.loading = false;
              this.jsonDataService.contorlLoading(false);
              this.appMessageService.showAppMessage(response.message, "error");
            }

          });
        });
      });
    } catch (e) {
    console.error(e);
      console.log(e);
    }
  }

  onRowClick(ev: any) {
    this.selectedData = ev;
  }

  onClose() {
    if (this.selectedData == '' || this.selectedData == null) {
      this.dialogRef.close({ content: '' });
    } else {
      this.dialogRef.close({ content: this.selectedData, origin: this.data.origin });
    }
  }

}
