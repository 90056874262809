<section id="warr-clause-per-plan-maint">    
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <div class="form-content">
            <form [formGroup]="forms.lineSublinePlanLovForm" class="form">
                <div class="form-container-grid top-form">
                    <div class="field required">
                        <label>Line</label>
                    </div>
                    <div class="">
                        <input formControlName="lineCdDisp" >
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['line']()" 
                        [disabled]='btns.lov.lineDisabled'>
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                    <div class="field required">
                        <label>Subline</label>
                    </div>
                    <div class="">
                        <input formControlName="sublineCdDisp" >
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['subline']()" 
                        [disabled]='btns.lov.sublineDisabled'>
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                    <div class="field required">
                        <label>Plan</label>
                    </div>
                    <div class="">
                        <input formControlName="planCdDisp" >
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['plan']()" 
                        [disabled]='btns.lov.planDisabled'>
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <!-- Container for Warranties and Clauses per Plan Table using prime ng -->
        <div class = 'table-user'>
            <osp-table [tblConfig]="warrClausePerPlanTblConfig" (onRowClick)="onRowClick($event)"></osp-table>
        </div>
        <!-- Loading Animation -->
        <div id="warr-clause-per-plan-maintenance-loading">
            <div class="form-loader"></div>
        </div>
        <div class="form-content" *ngIf="showForm">
            <form [formGroup]="forms.warrClausePerPlanForm" class="form" *ngIf="showForm">
                <div id="warr-clause-per-plan-error-message"></div>
                <div class="form-container-grid">
                    <div class="field required">
                        <label>Warranties and Clauses</label>
                    </div>
                    <div class="lov-search">
                        <input type="text" readonly formControlName="wcField">
                        <button type="button" class="btn-search btn" (click)="openLov['wc']()" [disabled]='btns.warrClausePerPlan.wcDisabled'>
                            <mat-icon>
                                search
                            </mat-icon>
                        </button>
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag">
                            <option *ngFor="let activeType of selections.activeTypes" [value]="activeType.cd">
                                {{activeType.name}}
                            </option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>Effective Date</label>
                    </div>
                    <div class="">
                        <input formControlName="effDateFrom" type="text" placeholder="MM/DD/YYYY" [matDatepicker]="effDateFromPicker" (keypress)="allowNumericAndSlashOnly($event)">
                        <mat-datepicker-toggle matSuffix [for]="effDateFromPicker"></mat-datepicker-toggle>
                        <mat-datepicker #effDateFromPicker></mat-datepicker>
                    </div>
                    <div class="field">
                        <label>Expiry Date</label>
                    </div>
                    <div class="">
                        <input formControlName="effDateTo" type="text" placeholder="MM/DD/YYYY" [matDatepicker]="effDateToPicker" (keypress)="allowNumericAndSlashOnly($event)">
                        <mat-datepicker-toggle matSuffix [for]="effDateToPicker"></mat-datepicker-toggle>
                        <mat-datepicker #effDateToPicker></mat-datepicker>
                    </div>

                    <div class="field">
                        <label>Sequence No.</label>
                    </div>
                    <div class="">
                        <input formControlName="seqNo" type="text" (keypress)="allowNumericDigitsOnlyWODecimal($event)" (blur)="integerFormat('seqNo')">
                    </div>
                    <div class="field">
                        <label>Group Code</label>
                    </div>
                    <div class="">
                        <select formControlName="groupCd">
                            <option *ngFor="let groupCd of selections.groupCodes" [value]="groupCd.cd">
                                {{groupCd.name}}
                            </option>
                        </select>
                    </div>

                    <div class="field">
                        <label>Client Code</label>
                    </div>
                    <div class="lov-search">
                        <input type="text" readonly formControlName="clientField">
                        <button type="button" class="btn-search btn" (click)="openLov['client']()">
                            <mat-icon>
                                search
                            </mat-icon>
                        </button>
                    </div>
                    <div class="field">
                        <label>Mortgage Account Group</label>
                    </div>
                    <div class="">
                        <select formControlName="mortAccGrp">
                            <option *ngFor="let mortAccGrp of selections.mortAccGrps" [value]="mortAccGrp.cd">
                                {{mortAccGrp.name}}
                            </option>
                        </select>
                    </div>

                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" type="text" maxlength="4000">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="userID">
                    </div>
                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUpdate">
                    </div>
                </div>
            </form>
            <div class="btn-row">
                <button *ngIf="btns.warrClausePerPlan.updateDisabled" class="btn btn2" type="button" [disabled]='btns.warrClausePerPlan.addDisabled' (click)="onAdd()">ADD</button>
                <button *ngIf="!btns.warrClausePerPlan.updateDisabled" class="btn btn2" type="button" (click)="onUpdate()">UPDATE</button>
                <button class="btn btn2" type="button" [disabled]='btns.warrClausePerPlan.deleteDisabled' (click)="onDelete()"> DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-row">
                <button class="btn btn2" type="button" [disabled]='btns.warrClausePerPlan.saveDisabled' (click)="onSave()">SAVE</button>
            </div>
        </div>
    </div>
</section>