import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { CustomValidators } from 'src/app/utils/custom-validator';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';

@Component({
  selector: 'risk-profile-maintenance',
  templateUrl: './risk-profile-maintenance.component.html',
  styleUrls: ['./risk-profile-maintenance.component.css']
})
export class RiskProfileMaintenanceComponent implements OnInit, LeaveConfirmation {
  
  selectedRow: any;
  datePipe = new DatePipe('en-ph');
  errorMessage: string = "";
  userId = this.userDetailService.userId;
  formHider: boolean = false;
  moduleId: string = 'BMM021';
  moduleName = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId === 'BMM021' })[0].moduleDesc.toUpperCase();

  addUpdateFlag: boolean = true;
  deleteDisabler: boolean = true;
  saveDisabler: boolean = true;

  tempAddData: any = [];
  tempUpdateData: any = [];

  riskProfileTable: any = {
    cols: [
      {
        key: "RISK_PROF_CD",
        header: "Risk Profile Code",
        dataType: "string"
      },
      {
        key: "RISK_PROF_DESC",
        header: "Risk Profile",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "Active",
        dataType: "checkbox"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  riskProfileForm = this.fb.group({
    RISK_PROF_CD: [{value: null, disabled: true}],
    RISK_PROF_DESC: [null, [Validators.required, this.duplicateRiskProfDesc(), CustomValidators.requiredTrim]],
    RISK_RATING_SCORE: [null, Validators.required],
    ACTIVE_TAG: ['A', Validators.required],
    BM_CODE: [null, [Validators.required, CustomValidators.requiredTrim]],
    BM_SHORT_DESC: [null, [Validators.required, CustomValidators.requiredTrim]],
    REMARKS: [null],
    USER_ID: [{value: null, disabled: true}],
    LAST_UPDATE: [{value: null, disabled: true}],
  });

  constructor(
    private fb: FormBuilder,
    private api: APICallService,
    private jsonDataService: JsonDataService,
    private formService: FormService,
    private dialog: MatDialog,
    private userDetailService: UserDetailsService,
    private securityService: SecurityService,
    private appMessageService: AppMessageService,
  ) { }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempAddData!='' || this.tempUpdateData!='') {
      return false;
    } else {
      return true;
    }
  }

  ngOnInit(): void {
    try {
      this.getRiskProfile();
    } catch(e) {
    }
  }

  getRiskProfile() {
    try {
      this.riskProfileTable.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.api.getRiskProfile({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE"
      }).subscribe({
        next: (data: any) => {
        this.securityService.checkRequestKeyResponse(data, () => {
          this.securityService.hasValidCsrfToken(data, () => {
          if (data.status === "SUCCESS") {
              data = JSON.parse(this.jsonDataService.decrypt(data.response));
              this.riskProfileTable.loading = false;
              this.jsonDataService.contorlLoading(false);
              this.riskProfileTable.tblData = data;
          } else {
              this.jsonDataService.contorlLoading(false);
              this.riskProfileTable.loading = false;
              this.appMessageService.showAppMessage(data.message, "error");
          }
          });
        });
      },
      error: (e: any) => {
          this.riskProfileTable.loading = false;
          this.jsonDataService.contorlLoading(false);
      }
      });
    } catch(e) {
    }
  }

  onRowClick(ev: any) {
    try {
      if(ev!=null) {
        this.selectedRow = ev;
        this.addUpdateFlag = false;
        this.formLoader();
      } else {
        this.selectedRow = '';
        this.addUpdateFlag = true;
        this.formClearer();
      }
      this.deleteEnabler();
    } catch(e) {
    }
  }

  formLoader() {
    try {
      this.riskProfileForm.patchValue({
        RISK_PROF_CD:        this.selectedRow.RISK_PROF_CD,
        RISK_PROF_DESC:      this.selectedRow.RISK_PROF_DESC,
        RISK_RATING_SCORE:   this.selectedRow.RISK_RATING_SCORE,
        ACTIVE_TAG:           this.selectedRow.ACTIVE_TAG,
        BM_CODE:              this.selectedRow.BM_CODE,
        BM_SHORT_DESC:        this.selectedRow.BM_SHORT_DESC,
        REMARKS:              this.selectedRow.REMARKS,
        USER_ID:              this.selectedRow.USER_ID,
        LAST_UPDATE:          this.datePipe.transform(this.selectedRow.LAST_UPDATE, 'MM/dd/YYYY h:mm:ss a', '+0000'),
      });
    } catch(e) {
    }
  }

  formClearer() {
    try {
      this.riskProfileForm.patchValue({
        RISK_PROF_CD:        '',
        RISK_PROF_DESC:      '',
        RISK_RATING_SCORE:   '',
        ACTIVE_TAG:           'A',
        BM_CODE:              '',
        BM_SHORT_DESC:        '',
        REMARKS:              '',
        USER_ID:              '',
        LAST_UPDATE:          '',
      });
    } catch(e) {
    }
  }

  errorMessageSetter() {
    try {
      if(this.riskProfileForm.invalid) {
        for(const field in this.riskProfileForm.controls) {
          const controlName = this.riskProfileForm.get(field);
          if(controlName?.errors?.required) {
            this.errorMessage = 'There are missing information. Please provide necessary information needed.';
          }
          if(controlName?.errors?.riskProfDescDuplicate) {
            this.errorMessage = 'Risk Profile Description already exists. Please add a unique Risk Profile Description.';
          }
          this.formService.showFormMsg("risk-profile-error-message", this.errorMessage, "E");
        }
      }
    } catch(e) {
    }
  }

  tableInserter() {
    try {
      this.riskProfileTable.tblData.unshift({
        RISK_PROF_DESC:         this.riskProfileForm.get('RISK_PROF_DESC')?.value?.trim(),
        RISK_RATING_SCORE:      this.riskProfileForm.get('RISK_RATING_SCORE')?.value,
        ACTIVE_TAG:             this.riskProfileForm.get('ACTIVE_TAG')?.value,
        BM_CODE:                this.riskProfileForm.get('BM_CODE')?.value?.trim(),
        BM_SHORT_DESC:          this.riskProfileForm.get('BM_SHORT_DESC')?.value?.trim(),
        REMARKS:                this.riskProfileForm.get('REMARKS')?.value,
      });
      this.riskProfileTable.tblData = [...this.riskProfileTable.tblData];
    } catch(e) {
    }
  }

  tempAddInserter() {
    try {
      this.tempAddData.push({
        RISK_PROF_DESC:       this.riskProfileForm.get('RISK_PROF_DESC')?.value?.trim(),
        RISK_RATING_SCORE:    this.riskProfileForm.get('RISK_RATING_SCORE')?.value,
        ACTIVE_TAG:           this.riskProfileForm.get('ACTIVE_TAG')?.value,
        BM_CODE:              this.riskProfileForm.get('BM_CODE')?.value?.trim(),
        BM_SHORT_DESC:        this.riskProfileForm.get('BM_SHORT_DESC')?.value?.trim(),
        REMARKS:              this.riskProfileForm.get('REMARKS')?.value,
        USER_ID:              this.userId,
      });
    } catch(e) {
    }
  }

  tempUpdateInserter() {
    try {
      this.tempUpdateData.push({
        RISK_PROF_CD:         this.riskProfileForm.get('RISK_PROF_CD')?.value,
        RISK_PROF_DESC:       this.riskProfileForm.get('RISK_PROF_DESC')?.value?.trim(),
        RISK_RATING_SCORE:    this.riskProfileForm.get('RISK_RATING_SCORE')?.value,
        ACTIVE_TAG:           this.riskProfileForm.get('ACTIVE_TAG')?.value,
        BM_CODE:              this.riskProfileForm.get('BM_CODE')?.value?.trim(),
        BM_SHORT_DESC:        this.riskProfileForm.get('BM_SHORT_DESC')?.value?.trim(),
        REMARKS:              this.riskProfileForm.get('REMARKS')?.value,
        USER_ID:              this.userId,
      });
    } catch(e) {
    }
  }

  addRow() {
    try {
      if(this.riskProfileForm.valid) {
        this.formService.hideFormMsg("risk-profile-error-message");
        this.tableInserter();
        this.tempAddInserter();
        this.formClearer();
        this.saveDisabler = false;
      } else {
        this.errorMessageSetter();
      }
    } catch(e) {
    }
  }

  updateTable() {
    try {
      let updateIndex = this.riskProfileTable.tblData.indexOf(this.selectedRow);
      const rowData = this.riskProfileTable.tblData[updateIndex];
      this.riskProfileTable.tblData[updateIndex] = {
        RISK_PROF_CD:          this.riskProfileForm.get('RISK_PROF_CD')?.value,
        RISK_PROF_DESC:        this.riskProfileForm.get('RISK_PROF_DESC')?.value?.trim(),
        RISK_RATING_SCORE:      this.riskProfileForm.get('RISK_RATING_SCORE')?.value,
        ACTIVE_TAG:             this.riskProfileForm.get('ACTIVE_TAG')?.value,
        BM_CODE:                this.riskProfileForm.get('BM_CODE')?.value?.trim(),
        BM_SHORT_DESC:          this.riskProfileForm.get('BM_SHORT_DESC')?.value?.trim(),
        REMARKS:                this.riskProfileForm.get('REMARKS')?.value,
        USER_ID:                this.selectedRow.USER_ID,
        LAST_UPDATE:            this.selectedRow.LAST_UPDATE,
      }
      this.riskProfileTable.tblData = [...this.riskProfileTable.tblData];
    } catch(e) {
    }
  }

  updateNewData() {
    try {
      let tempNewConfigdata = this.tempAddData.find(
        (riskProfile: { RISK_PROF_DESC: any }) => riskProfile.RISK_PROF_DESC?.trim() == this.selectedRow.RISK_PROF_DESC?.trim()
      );

      tempNewConfigdata.RISK_PROF_CD         = this.riskProfileForm.get('RISK_PROF_CD')?.value;
      tempNewConfigdata.RISK_PROF_DESC       = this.riskProfileForm.get('RISK_PROF_DESC')?.value?.trim();
      tempNewConfigdata.RISK_RATING_SCORE     = this.riskProfileForm.get('RISK_RATING_SCORE')?.value;
      tempNewConfigdata.ACTIVE_TAG            = this.riskProfileForm.get('ACTIVE_TAG')?.value;
      tempNewConfigdata.BM_CODE               = this.riskProfileForm.get('BM_CODE')?.value?.trim();
      tempNewConfigdata.BM_SHORT_DESC         = this.riskProfileForm.get('BM_SHORT_DESC')?.value?.trim();
      tempNewConfigdata.REMARKS               = this.riskProfileForm.get('REMARKS')?.value;
    } catch(e) {
    }
  }

  updateDbData() {
    try {
      let tempDbData = this.tempUpdateData.find(
        (riskProfile: { RISK_PROF_DESC: any }) => riskProfile.RISK_PROF_DESC == this.selectedRow.RISK_PROF_DESC?.trim()
      );
      if(this.tempUpdateData == '' || this.tempUpdateData == null) {
        this.tempUpdateInserter();
      } else if(tempDbData=='' || tempDbData==null) {
        this.tempUpdateInserter();
      } else {
        tempDbData.RISK_PROF_CD           = this.riskProfileForm.get('RISK_PROF_CD')?.value;
        tempDbData.RISK_PROF_DESC         = this.riskProfileForm.get('RISK_PROF_DESC')?.value?.trim();
        tempDbData.RISK_RATING_SCORE      = this.riskProfileForm.get('RISK_RATING_SCORE')?.value;
        tempDbData.ACTIVE_TAG             = this.riskProfileForm.get('ACTIVE_TAG')?.value;
        tempDbData.BM_CODE                = this.riskProfileForm.get('BM_CODE')?.value?.trim();
        tempDbData.BM_SHORT_DESC          = this.riskProfileForm.get('BM_SHORT_DESC')?.value?.trim();
        tempDbData.REMARKS                = this.riskProfileForm.get('REMARKS')?.value;
        tempDbData.USER_ID                = this.selectedRow.USER_ID;
      }
    } catch(e) {
    }
  }

  onUpdate() {
    try {
      if(this.selectedRow.RISK_PROF_DESC?.trim() == this.riskProfileForm.get('RISK_PROF_DESC')?.value?.trim()) {
        this.riskProfileForm.get('RISK_PROF_DESC')?.clearValidators();
        this.riskProfileForm.get('RISK_PROF_DESC')?.setValidators([Validators.required,CustomValidators.requiredTrim]);
        this.riskProfileForm.get('RISK_PROF_DESC')?.updateValueAndValidity();
      }
      if(this.riskProfileForm.valid) {
        this.updateTable();
        if(this.deleteDisabler) {
          this.updateDbData();
        } else {
          this.updateNewData();
        }
        this.selectedRow = '';
        this.addUpdateFlag = true;
        this.formClearer();
        this.saveDisabler = false;
        this.deleteEnabler();
        this.formService.hideFormMsg('risk-profile-error-message');
      } else {
        this.errorMessageSetter();
      }
      this.riskProfileForm.get('RISK_PROF_DESC')?.setValidators([Validators.required, CustomValidators.requiredTrim, this.duplicateRiskProfDesc()]);
      this.riskProfileForm.get('RISK_PROF_DESC')?.updateValueAndValidity();
    } catch(e) {
    }
  }

  deleteEnabler() {
    try {
      let tempDeleteFlag = this.tempAddData.filter((e: any) => {
        return e.RISK_PROF_DESC == this.selectedRow.RISK_PROF_DESC?.trim()
      });
      if(tempDeleteFlag=='' || tempDeleteFlag==null) {
        this.deleteDisabler = true;
      } else {
        this.deleteDisabler = false;
      }
    } catch(e) {
    }
  }

  deleteRow() {
    try {
      let clickedData = this.selectedRow;
      this.riskProfileTable.tblData = this.riskProfileTable.tblData.filter(function(e: any) {
        return e.RISK_PROF_DESC != clickedData.RISK_PROF_DESC;
      });
      this.tempAddData = this.tempAddData.filter(function(e: any) {
        return e.RISK_PROF_DESC != clickedData.RISK_PROF_DESC;
      });
      this.selectedRow = '';
      this.formClearer();
      this.deleteDisabler = true;
      this.addUpdateFlag = true;
      if((this.tempAddData=='' || this.tempAddData==null) && (this.tempUpdateData=='' || this.tempUpdateData==null)) {
        this.saveDisabler = true;
      }
    } catch(e) {
    }
  }

  onSave() {
    try {
      this.removePrefixZero();
      this.formHider = true;
      this.formService.showFormLoader(null, "risk-profile-form", "Saving.<br>Please wait ...", null, null);
      this.api.saveRiskProfile(this.tempAddData).subscribe({
        next: (data: any) => {
          this.securityService.checkRequestKeyResponse(data, () => {
            this.securityService.hasValidCsrfToken(data, () => {
            if(data.status == 'SUCCESS') {
              this.api.saveRiskProfile(this.tempUpdateData).subscribe({
                next: (res: any) => {
                  this.securityService.checkRequestKeyResponse(res, () => {
                    if(res.status == 'SUCCESS') {
                      this.onComplete();
                      this.openSaveDialog();
                    } else {
                      this.jsonDataService.contorlLoading(false);
                      this.openErrorDialog('Saving risk profile details failed.');
                    }
                  });
                },
                error: () => {
                  this.jsonDataService.contorlLoading(false);
                  this.openErrorDialog('Saving risk profile details failed.');
                }
              });
            } else {
              this.jsonDataService.contorlLoading(false);
              this.openErrorDialog('Saving risk profile details failed.');
            }
            });
          });
        },
        error: () => {
          this.jsonDataService.contorlLoading(false);
          this.openErrorDialog('Saving risk profile details failed.');
        }
      });
    } catch(e) {
    }
  }

  onComplete() {
    try {
      this.formService.hideFormLoader("risk-profile-form");
      this.formHider = false;
      this.tempAddData = [];
      this.tempUpdateData = [];
      this.formClearer();
      this.saveDisabler = true;
      this.getRiskProfile();
      this.formService.hideFormMsg("risk-profile-error-message");
    } catch(e) {
    }
  }

  openErrorDialog(message: string) {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'ERROR',
          content: message,
        }
      });
    } catch(e) {
    }
  }

  onFail() {
    try {
      this.tempAddData = [];
      this.tempUpdateData = [];
      this.getRiskProfile();
      this.formService.hideFormLoader("risk-profile-form");
      this.formHider = false;
      this.openErrorDialog('Saving risk profile details failed.');
    } catch(e) {
    }
  }

  openSaveDialog() {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'SUCCESS',
          content: 'Risk Profile details Successfully saved!',
        }
      });
    } catch(e) {
    }
  }

  duplicateRiskProfDesc(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      let riskProfDescDuplicate = this.riskProfileTable.tblData.filter(function(e: any) {
        return e.RISK_PROF_DESC.trim().toUpperCase()==control.value.trim().toUpperCase();
      });
      if(riskProfDescDuplicate!='') {
        return { "riskProfDescDuplicate": { value: control.value } }
      }
      return null;
    }
  }

  removePrefixZero() {
    this.tempUpdateData = this.tempUpdateData.map((data: any) => {
      return { ...data, RISK_PROF_CD: parseInt(data.RISK_PROF_CD, 10) };
    });
  }
  
  allowNumericDigitsOnly(event: any) {
    try {
      if (/[0-9.]/.test(String.fromCharCode(event.keyCode))) {
        return true;
      }
      else {
        event.preventDefault();
        return false;
      }
    }
    catch (e) {
    console.error(e);
      return undefined;
    }
  }

}
