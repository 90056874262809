import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';
import { PerilLovComponent } from '../../common/peril-lov/peril-lov.component';

@Component({
  selector: 'rpa-peril-mapping-maintenance',
  templateUrl: './rpa-peril-mapping-maintenance.component.html',
  styleUrls: ['./rpa-peril-mapping-maintenance.component.css']
})
export class RpaPerilMappingMaintenanceComponent implements OnInit, LeaveConfirmation {

  private moduleId: string = 'BMM062';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc ?? "";

  constructor(
    public _formService: FormService,
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService,
    private _appMessageService: AppMessageService,
  ) {
    this.userIdLoggedIn = this._userDetailService.userId ?? "";
  }

  canDeactivate(): boolean | Observable<boolean> {
    return !(this.rpaPerilMappingTblConfig.tblData.filter((row: any) => ['I', 'U'].includes(row.operation)).length > 0);
  }

  public btns = {
    rpaPerilMapping: {
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      addDisabled: true,
      lineSearchDisabled: false,
      sublineSearchDisabled: true,
      perilSearchDisabled: true,
    },
  };

  public showForm: boolean = true;

  //* Data Stuffs */
  private rowData: any;
  private errorMessage: string = "";
  private userIdLoggedIn: string;
  //? set selectedRow to [] to unselect the table
  public selectedRow!: any[];

  public forms = {
    lineSublineLovForm: this.fb.group({
      lineCd: [null],
      lineName: [null],
      sublineCd: [null],
      sublineName: [null],
      lineCdDisp: [{ value: '', disabled: 'true' }],
      sublineCdDisp: [{ value: '', disabled: 'true' }],
    }),
    rpaPerilMappingForm: this.fb.group({
      lineCd: [null],
      sublineCd: [null],
      seqNo: [null, [Validators.min(1), Validators.required, this.checkDuplicateSeqNo()]],
      perilCd: [null, [Validators.required]],
      perilName: [null, [Validators.required]],
      perilCdDisp: [null],
      bmCode: [null, [Validators.maxLength(8)]],
      lastUpdateUser: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }],
    }),
    resetRpaPerilMappingForm: () => {
      this.forms.rpaPerilMappingForm.reset();
      this.btns.rpaPerilMapping.updateDisabled = true;
      this.btns.rpaPerilMapping.deleteDisabled = true;
      this.btns.rpaPerilMapping.lineSearchDisabled = this.btns.rpaPerilMapping.sublineSearchDisabled = false;
      this._formService.hideFormMsg("rpa-peril-mapping-error-message");
      this.rowData = null;
      this.forms.rpaPerilMappingForm.get('seqNo')?.enable();
    },
    resetLineSublineForm: () => {
      this.forms.lineSublineLovForm.reset();
    },
    resetAllForms: () => {
      this.forms.resetLineSublineForm();
      this.forms.resetRpaPerilMappingForm();
    }
  }

  private rpaPerilMappingFormManipulate = {
    dbToForm: (data: any) => {
      return {
        lineCd: data.LINE_CD,
        sublineCd: data.SUBLINE_CD,
        seqNo: data.SEQ_NO,
        perilCd: data.PERIL_CD,
        perilName: data.PERIL_LNAME,
        perilCdDisp: `${data.PERIL_CD ?? ''} - ${data.PERIL_LNAME ?? ''}`,
        bmCode: data.BM_CODE,
        lastUpdateUser: data.USER_ID,
        lastUpdate: data.LAST_UPDATE,
      };
    },
    formToDb: (data: any) => {
      return {
        LINE_CD: data.lineCd,
        SUBLINE_CD: data.sublineCd,
        SEQ_NO: data.seqNo?.toString().padStart(2, 0),
        PERIL_CD: data.perilCd?.toString().padStart(2, 0),
        PERIL_LNAME: data.perilName,
        BM_CODE: data.bmCode,
        USER_ID: null,
        LAST_UPDATE: null,
      };
    },
  }

  public rpaPerilMappingTblConfig = {
    cols: [
      {
        key: "SEQ_NO",
        header: "Sequence No",
        dataType: "string",
        width: "128px"
      },
      {
        key: "PERIL_CD",
        header: "Peril Code",
        dataType: "string",
      },
      {
        key: "PERIL_LNAME",
        header: "Peril",
        dataType: "string",
      },
      {
        key: "BM_CODE",
        header: "BPI MS Code",
        dataType: "string",
      },
    ],
    tblData: [] as any[],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  ngOnInit(): void {
  }

  public openLov = {
    line: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'LINE',
          moduleId: this.moduleId
        },
      }
      this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button === 'OK') {
          this.forms.resetRpaPerilMappingForm();
          if (output) {
            this.forms.lineSublineLovForm.patchValue({
              lineCd: output.LINE_CD,
              lineName: output.LINE_NAME,
              lineCdDisp: `${output.LINE_CD} - ${output.LINE_NAME}`,
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null,
            });
            this.btns.rpaPerilMapping.sublineSearchDisabled = false;
            this.btns.rpaPerilMapping.addDisabled = this.btns.rpaPerilMapping.perilSearchDisabled = true;
          }
          else {
            this.forms.lineSublineLovForm.patchValue({
              lineCd: null,
              lineName: null,
              lineCdDisp: null,
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null,
            });
            this.btns.rpaPerilMapping.sublineSearchDisabled = true;
            this.btns.rpaPerilMapping.addDisabled = this.btns.rpaPerilMapping.perilSearchDisabled = true;
          }
          this.getData.rpaPerilMapping();
        }
      });
    },
    subline: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          lineCd: this.forms.lineSublineLovForm.get('lineCd')?.value,
          table: 'SUBLINE',
          moduleId: this.moduleId
        },
      }
      this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button === 'OK') {
          this.forms.resetRpaPerilMappingForm();
          if (output) {
            this.forms.lineSublineLovForm.patchValue({
              lineCd: output.LINE_CD,
              lineName: output.LINE_NAME,
              lineCdDisp: `${output.LINE_CD} - ${output.LINE_NAME}`,
              sublineCd: output.SUBLINE_CD,
              sublineName: output.SUBLINE_NAME,
              sublineCdDisp: `${output.SUBLINE_CD} - ${output.SUBLINE_NAME}`,
            });
          }
          else {
            this.forms.lineSublineLovForm.patchValue({
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null,
            });
            this.btns.rpaPerilMapping.addDisabled = this.btns.rpaPerilMapping.perilSearchDisabled = true;
          }
          this.getData.rpaPerilMapping();
        }
      });
    },
    peril: () => {
      let perilCdExists: any[] = this.rpaPerilMappingTblConfig.tblData.map((row: any) => { return +row.PERIL_CD; }).filter(Boolean);
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          lineCd: this.forms.lineSublineLovForm.get('lineCd')?.value,
          perilCdExclude: perilCdExists,
          moduleId: this.moduleId
        },
      };
      this._matDialog.open(PerilLovComponent, LOVOPTIONS).afterClosed().subscribe((lovData: any) => {
        if (lovData?.content?.PERIL_CD) {
          const data = lovData?.content;
          this.forms.rpaPerilMappingForm.patchValue({
            perilCdDisp: `${data.PERIL_CD?.toString().padStart(2, 0)} - ${data.PERIL_LNAME}`,
            perilCd: data.PERIL_CD?.toString().padStart(2, 0),
            perilName: data.PERIL_LNAME,
          });
        }
        else {
          this.forms.rpaPerilMappingForm.patchValue({
            perilCdDisp: null,
            perilCd: null,
            perilName: null,
          });
        }
      });
    }
  };

  private getData = {
    rpaPerilMapping: () => {
      try {
        this.rpaPerilMappingTblConfig.tblData = [];
        if (this.forms.lineSublineLovForm.get('lineCd')?.value && this.forms.lineSublineLovForm.get('sublineCd')?.value) {
          this._jDS.contorlLoading(true);
          this.rpaPerilMappingTblConfig.loading = true;
          this._api.getRpaPerilMapping({
            moduleId: this.moduleId,
            userId: this._userDetailService.userId,
            type: "MODULE",
          }).subscribe({
            next: (response: any) => {
              this._securityService.checkRequestKeyResponse(response, () => {
                this._securityService.hasValidCsrfToken(response, () => {
                  if (response.status === 'SUCCESS') {
                    let content = JSON.parse(this._jDS.decrypt(response?.response));
                    this.rpaPerilMappingTblConfig.tblData = content.data.filter((row: any) => {
                      return (row.LINE_CD === this.forms.lineSublineLovForm.get('lineCd')?.value && row.SUBLINE_CD === this.forms.lineSublineLovForm.get('sublineCd')?.value);
                    });
                    this.btns.rpaPerilMapping.addDisabled = this.btns.rpaPerilMapping.perilSearchDisabled = false;
                  }
                  else {
                    this._appMessageService.showAppMessage(response.message, "error");
                  }
                  this._jDS.contorlLoading(false);
                  this.rpaPerilMappingTblConfig.loading = false;
                });
              });
            },
            error: (e: any) => {
              this._securityService.checkRequestKeyResponse(e, () => {

                this._jDS.contorlLoading(false);
                this.rpaPerilMappingTblConfig.loading = false;
                this.btns.rpaPerilMapping.addDisabled = this.btns.rpaPerilMapping.perilSearchDisabled = true;
              });
            }
          });
        }
      }
      catch (e) {
    console.error(e);

      }
    }
  };

  public onRowClick(ev: any) {
    try {
      if (ev) {
        this.rowData = ev;
        this.rowData?.operation !== 'I' ? this.forms.rpaPerilMappingForm.get('seqNo')?.disable() : this.forms.rpaPerilMappingForm.get('seqNo')?.enable();
        this.populateFormFromTable();
      }
      else {
        this.rowData = null;
        this.forms.resetRpaPerilMappingForm();
      }
    }
    catch (e) {
    console.error(e);
      this.forms.resetRpaPerilMappingForm();
      this.rowData = null;
    }
  }

  private populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.rpaPerilMapping.updateDisabled = false;
      this.btns.rpaPerilMapping.deleteDisabled = !(this.rowData?.operation === 'I');
      // this.btns.rpaPerilMapping.lineSearchDisabled = this.btns.rpaPerilMapping.sublineSearchDisabled = true;
      this.forms.rpaPerilMappingForm.patchValue(this.rpaPerilMappingFormManipulate.dbToForm(data));
    }
    catch (e) {
    console.error(e);

    }
  }

  public onAdd(): void {
    try {
      this._formService.hideFormMsg("rpa-peril-mapping-error-message");
      if (this.forms.rpaPerilMappingForm.valid) {
        this.forms.rpaPerilMappingForm.patchValue({
          lineCd: this.forms.lineSublineLovForm.get('lineCd')?.value,
          sublineCd: this.forms.lineSublineLovForm.get('sublineCd')?.value,
        });
        let rowToBeAdded: { [key: string]: any } = this.rpaPerilMappingFormManipulate.formToDb(this.forms.rpaPerilMappingForm.value);
        rowToBeAdded.operation = 'I';
        this.rpaPerilMappingTblConfig.tblData = [rowToBeAdded, ...this.rpaPerilMappingTblConfig.tblData];
        this.forms.resetRpaPerilMappingForm();
        this.btns.rpaPerilMapping.saveDisabled = false;
      }
      else {
        this.showErrorValidator.rpaPerilMapping();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onUpdate(): void {
    try {
      this._formService.hideFormMsg("rpa-peril-mapping-error-message");
      if (this.forms.rpaPerilMappingForm.valid) {
        const indexOfRow = this.rpaPerilMappingTblConfig.tblData.indexOf(this.rowData);
        let rowToBeUpdated: { [key: string]: any } = this.rpaPerilMappingFormManipulate.formToDb(this.forms.rpaPerilMappingForm.getRawValue());
        rowToBeUpdated.operation = (this.rowData.operation === 'I') ? 'I' : 'U';
        this.forms.resetRpaPerilMappingForm();
        this.rpaPerilMappingTblConfig.tblData[indexOfRow] = rowToBeUpdated;
        this.selectedRow = [];
        this.btns.rpaPerilMapping.saveDisabled = false;
        this.rpaPerilMappingTblConfig.tblData = [...this.rpaPerilMappingTblConfig.tblData];
      }
      else {
        this.showErrorValidator.rpaPerilMapping();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onDelete(): void {
    try {
      this.rpaPerilMappingTblConfig.tblData = this.rpaPerilMappingTblConfig.tblData.filter((row: any) => {
        return row !== this.rowData;
      });
      this.forms.resetRpaPerilMappingForm();
      this.btns.rpaPerilMapping.saveDisabled = false;
    }
    catch (e) {
    console.error(e);

    }
  }

  public onSave(): void {
    try {
      this._jDS.contorlLoading(true);
      this.showForm = false;
      this._formService.showFormLoader(null, "rpa-peril-mapping-maintenance-loading", "Saving.<br>Please wait ...", null, null, true);
      let toBeSaved = this.rpaPerilMappingTblConfig.tblData.filter((row: any) => ['I', 'U'].includes(row.operation));
      if (toBeSaved.length > 0) {
        this._api.saveRpaPerilMapping(toBeSaved).subscribe({
          next: (response: any) => {
            this._securityService.checkRequestKeyResponse(response, () => {
              this._securityService.hasValidCsrfToken(response, () => {
                if (response.status === 'SUCCESS') this.onComplete.rpaPerilMapping();
                else this.onComplete.rpaPerilMappingError();
              });
            });
          },
          error: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {

              this.onComplete.rpaPerilMappingError();
            });
          }
        });
      }
      else {
        this.onComplete.rpaPerilMapping();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  private onComplete = {
    rpaPerilMapping: () => {
      this._jDS.contorlLoading(false);
      this._formService.hideFormLoader("rpa-peril-mapping-maintenance-loading");
      this.openDialog('SUCCESS', this.moduleName.replace('Maintenance', ''), 'saved');
      this.getData.rpaPerilMapping();
      this.btns.rpaPerilMapping.saveDisabled = true;
      this.showForm = true;
      this.forms.resetRpaPerilMappingForm();
    },
    rpaPerilMappingError: () => {
      this._jDS.contorlLoading(false);
      this.openDialog('FAILED', this.moduleName.replace('Maintenance', ''), 'saving');
      this._formService.hideFormLoader("rpa-peril-mapping-maintenance-loading");
      this.showForm = true;
      this.forms.resetRpaPerilMappingForm();
    },
  }

  openDialog(title: string, contentTitle: string, contentDetail: string) {
    try {
      this._matDialog.open(DialogMsgComponent, {
        disableClose: true,
        width: '512px',
        data: {
          title: title,
          content:
            title === 'SUCCESS'
              ? `${contentTitle} details successfully ${contentDetail}!`
              : `${contentTitle} details ${contentDetail} failed!`,
        },
      });
    }
    catch (e) {
    console.error(e);

    }
  }

  //* Validator Stuffs Down here */

  checkDuplicateSeqNo(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = parseInt(control.value);
      if (!value) return null;
      let hasDuplicate: boolean = this.rpaPerilMappingTblConfig.tblData.filter((d: any) => {
        if (!this.rowData)
          return parseInt(d.SEQ_NO) === value;
        else
          return parseInt(this.rowData.SEQ_NO) !== value && parseInt(d.SEQ_NO) === value;
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupe: true } : null;
    }
  }

  public showErrorValidator = {
    rpaPerilMapping: () => {
      try {
        Object.keys(this.forms.rpaPerilMappingForm.controls).forEach(key => {
          const controlErrors = this.forms.rpaPerilMappingForm.get(key)?.errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              if (keyError == 'required' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
              else if (keyError == 'hasDupe' && controlErrors[keyError] === true)
                this.errorMessage = "Sequence No. already exists. Please choose a different Sequence No.";
              else if (keyError == 'min')
                this.errorMessage = `Sequence No. must be greater than ${controlErrors[keyError]['min']}. Please choose a different Sequence No.`;
            });
          }
        });
        this._formService.showFormMsg("rpa-peril-mapping-error-message", this.errorMessage, "E");
      }
      catch (e) {
    console.error(e);

      }
    },
  }

}
