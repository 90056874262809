<div class="mtn-container">
    <div class="mtn-title">
        <p>{{ moduleName }}</p>
    </div>
    <osp-table [tblConfig]="srcCdTblConfig" (onRowClick)="onRowClick($event)" ></osp-table>
    <div id="src-cd-maintenance-loading">
        <div class="form-loader"></div>
    </div>
    <div *ngIf="showForm" class="form-content">
        <form [formGroup]="forms.srcCdForm" class="form">
            <div id="src-cd-error-message"></div>
            <div class="form-container-grid">
                <div class="field">
                    <label>Source Code</label>
                </div>
                <div class="">
                    <input formControlName="srcCdDisp" >
                </div>
                <div class="field required">
                    <label>Active</label>
                </div>
                <div class="">
                    <select formControlName="active">
                        <option *ngFor="let activeType of selections.activeTypes" [value]="activeType.cd">
                            {{activeType.name}}
                        </option>
                    </select>
                </div>
                <div class="field required">
                    <label>Description</label>
                </div>
                <div class="span">
                    <input formControlName="srcCdDesc" maxlength="100">
                </div>
                <div class="field required">
                    <label>BPIMS Code</label>
                </div>
                <div class="">
                    <input formControlName="bmCode" maxlength="8">
                </div>
                <div class="field">
                    <label>BPIMS Short Description</label>
                </div>
                <div class="">
                    <input formControlName="bmDesc" maxlength="50">
                </div>
                <div class="field">
                    <label>Remarks</label>
                </div>
                <div class="span">
                    <input formControlName="remarks" maxlength="4000">
                </div>
                <div class="field">
                    <label>Last Update User</label>
                </div>
                <div class="">
                    <input formControlName="lastUpdateUser" >
                </div>
                <div class="field">
                    <label>Last Update</label>
                </div>
                <div class="">
                    <input formControlName="lastUpdate" >
                </div>
            </div>
        </form>
        <div class="btn-grp">
            <button *ngIf="btns.srcCd.updateDisabled" class="btn btn2" type="button" (click)="onAdd()" >ADD</button>
            <button *ngIf="!btns.srcCd.updateDisabled" class="btn btn2" type="button" (click)="onUpdate()" >UPDATE</button>
            <button class="btn btn2" type="button" [disabled]='btns.srcCd.deleteDisabled' (click)="onDelete()" >DELETE</button>
        </div>
        <div class="mtn-title"></div>
        <div class="btn-grp">
            <button class="btn btn2" type="button" [disabled]='btns.srcCd.saveDisabled' (click)="onSave()">SAVE</button>
        </div>
    </div>
</div>