import { Component, OnInit } from '@angular/core';

import { APICallService } from 'src/app/services/api-call.service'
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';

import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';

@Component({
  selector: 'introduction-maintenance',
  templateUrl: './introduction-maintenance.component.html',
  styleUrls: ['./introduction-maintenance.component.css']
})
export class IntroductionMaintenanceComponent implements OnInit, LeaveConfirmation {

  datePipe = new DatePipe('en-us');

  addUpdDisabled: boolean = true;
  saveDisabled: boolean = true;
  deleteDisabled: boolean = true;
  sublineDisabled: boolean = true;
  lineDisabled: boolean = false;
  tranPosted: boolean = false;
  noLoadingInProcess: boolean = true;
  validated: boolean = false;
  submitted: boolean = false;
  isIntroSelected: boolean = false;
  moduleId: string = 'BMM053';
  public moduleName: string = this.jsonDataService.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc?.toUpperCase() ?? "";

  sublineField: string = '';
  currentUser: string = this.jsonDataService.retrieveFromStorage("userId");
  rowClickedData: any;

  effDate = new FormControl(new Date());

  selectedData: any = null;

  selectedLine: any = {
    LINE_CD: '',
    LINE_NAME: '',
    LINE_FIELD: ''
  };

  selectedSubline: any = {
    SUBLINE_CD: '',
    SUBLINE_NAME: '',
    SUBLINE_FIELD: ''
  };

  tempAddedData: any = [];
  tempUpdateData: any = [];

  paramForm = this.fb.group({
    lineCd: [null],
    lineName: [null],
    lineField: [null]
  });

  introForm = this.fb.group({
    sublineCode: [null],
    sublineName: [null],
    sublineField: [null],
    activeTag: ['A', [Validators.required]],
    introTitle: [null],
    introDesc: [null],
    imagePath: [null],
    remarks: [null],
    userID: [null],
    lastUserUpdate: [null],
    mode: [null]
  });

  constructor(
    private _APICallService: APICallService,
    public _Dialog: MatDialog,
    private fb: FormBuilder,
    private formService: FormService,
    private appMessageService: AppMessageService,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService,
    private userDetailService: UserDetailsService
  ) { }

  canDeactivate(): Observable<boolean> | boolean {
    try {
      if (this.tempAddedData != '' || this.tempUpdateData != '') {
        return false;
      } else {
        return true;
      }
    } catch (e) {
    console.error(e);
      return true;
    }
  }

  tblConfigIntro: any = {
    cols: [
      {
        key: "SUBLINE_NAME",
        header: "Subline Name",
        dataType: "string",
        width: '25%'
      },
      {
        key: "INTRO_TITLE",
        header: "Introduction Title",
        dataType: "string",
        disabled: false
      },
      {
        key: "INTRO_DESC",
        header: "Introduction Description",
        dataType: "string",
        disabled: false
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        disabled: true,
        width: '64px'
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15
  }

  ngOnInit(): void {

  }

  initOnGet(): void {
    try {
      this._APICallService.refreshNeeded$
        .subscribe(() => {
          this.getIntroduction();
        });
      this.getIntroduction();
    }
    catch (e) {
    console.error(e);
    }
  }

  getIntroduction() {
    try {
      if (this.selectedLine.LINE_CD != null && this.selectedLine.LINE_CD != '') {
        this.tblConfigIntro.loading = true;
        this.jsonDataService.contorlLoading(true);
        this._APICallService.getIntroduction({
          moduleId: this.moduleId,
          userId: this.userDetailService.userId,
          type: "MODULE"
        }).subscribe((response: any) => {
          this.securityService.checkRequestKeyResponse(response, () => {
            this.securityService.hasValidCsrfToken(response, () => {
              if (response.status === "SUCCESS") {
                let data = JSON.parse(this.jsonDataService.decrypt(response.response));
                this.jsonDataService.contorlLoading(false);
                this.tblConfigIntro.tblData = data.filter((data: any) => data.LINE_CD == this.selectedLine.LINE_CD);
                this.tblConfigIntro.loading = false;
                this.addUpdDisabled = false;
                this.sublineDisabled = false;
                this.tempAddedData = [];
                this.tempUpdateData = [];
                this.resetFormValues();
              } else {
                this.jsonDataService.contorlLoading(false);
                this.tblConfigIntro.loading = false;
                this.addUpdDisabled = false;
                this.sublineDisabled = false;
                this.appMessageService.showAppMessage(response.message, "error");
              }
            });
          });
        });
      }
    } catch (e) {
    console.error(e);
    }
  }

  openLineDialog() {
    try {
      this._Dialog.open(LineLovDialogComponent, {
        width: '512px',
        disableClose: true,
        data: {
          table: 'LINE',
          moduleId: this.moduleId
        }
      }).afterClosed().subscribe(data => {
        if (data.button == "OK") {
          if (data.content == '' || data.content == null) {
            this.selectedLine = this.selectedLine;
          } else {
            this.selectedLine = data.content;
            this.paramForm.get('lineCd')?.setValue(this.selectedLine.LINE_CD == null || this.selectedLine.LINE_CD == '' ? '' : this.selectedLine.LINE_CD);
            this.paramForm.get('lineName')?.setValue(this.selectedLine.LINE_CD == null || this.selectedLine.LINE_CD == '' ? '' : this.selectedLine.LINE_NAME);
            this.paramForm.get('lineField')?.setValue(this.selectedLine.LINE_CD == null || this.selectedLine.LINE_CD == '' ? '' : this.selectedLine.LINE_CD + " - " + this.selectedLine.LINE_NAME);
            this.initOnGet();
          }
        }
      });
    } catch (e) {
    console.error(e);
    }
  }

  openSublineDialog() {
    try {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'SUBLINE',
          moduleId: this.moduleId
        },
      }
      LOVOPTIONS.data.lineCd = this.paramForm.get('lineCd')?.value;
      this._Dialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe(data => {
        if (data.button == "OK") {
          let output = data.content;
          if (output) {
            this.selectedSubline = data.content;
            this.introForm.get('sublineCode')?.setValue(this.selectedSubline.SUBLINE_CD == null || this.selectedSubline.SUBLINE_CD == '' ? '' : this.selectedSubline.SUBLINE_CD);
            this.introForm.get('sublineName')?.setValue(this.selectedSubline.SUBLINE_CD == null || this.selectedSubline.SUBLINE_CD == '' ? '' : this.selectedSubline.SUBLINE_NAME);
            this.introForm.get('sublineField')?.setValue(this.selectedSubline.SUBLINE_CD == null || this.selectedSubline.SUBLINE_CD == '' ? '' : this.selectedSubline.SUBLINE_CD + " - " + this.selectedSubline.SUBLINE_NAME);
          }
        }
      });
    } catch (e) {
    console.error(e);
    }
  }

  insertIntro() {
    try {
      this.tranPosted = true;
      this.validateInsert();
      if (this.validated) {
        this.addFormToTable();
      }
      this.tblConfigIntro.tblData = [...this.tblConfigIntro.tblData];
    }
    catch (e) {
    console.error(e);
    }
  }

  addFormToTable() {
    try {
      this.introForm.patchValue({
        activeTag: this.introForm.get('activeTag')?.value,
        userID: this.currentUser,
        lastUserUpdate: new Date()
      });
      let formToData: any = {
        LINE_CD: this.paramForm.get('lineCd')?.value,
        SUBLINE_CD: this.introForm.get('sublineCode')?.value,
        ACTIVE_TAG: this.introForm.get('activeTag')?.value,
        INTRO_TITLE: this.introForm.get('introTitle')?.value,
        INTRO_DESC: this.introForm.get('introDesc')?.value,
        IMAGE_PATH: this.introForm.get('imagePath')?.value,
        REMARKS: this.introForm.get('remarks')?.value,
        USER_ID: this.introForm.get('userID')?.value,
        MODE: 'I'
      }
      let formToTable: any = {
        SUBLINE_CD: this.introForm.get('sublineCode')?.value,
        SUBLINE_NAME: this.introForm.get('sublineName')?.value,
        SUBLINE_FIELD: this.introForm.get('sublineField')?.value,
        ACTIVE_TAG: this.introForm.get('activeTag')?.value,
        INTRO_TITLE: this.introForm.get('introTitle')?.value,
        INTRO_DESC: this.introForm.get('introDesc')?.value,
        IMAGE_PATH: this.introForm.get('imagePath')?.value,
        REMARKS: this.introForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.introForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.introForm.get('userID')?.value,
        MODE: 'I'
      }
      this.tblConfigIntro.tblData.unshift(formToTable);
      this.tempAddedData.push(formToData);
      this.resetFormValues();
      this.appMessageService.showAppMessage("Introduction details added!", "success");
    }
    catch (e) {
    console.error(e);
    }
  }

  updateIntro() {
    try {
      this.tranPosted = true;
      this.validateUpdate();
      if (this.validated) {
        this.updateFormTable();
      }
      this.tblConfigIntro.tblData = [...this.tblConfigIntro.tblData];
    }
    catch (e) {
    console.error(e);
    }
  }

  updateFormTable() {
    try {
      let tempInsertChecker = this.tempAddedData.find(
        (intro: { SUBLINE_CD: any, LINE_CD: any }) =>
          intro.SUBLINE_CD == this.introForm.get('sublineCode')?.value
          && intro.LINE_CD == this.paramForm.get('lineCd')?.value);
      let tempUpdateChecker = this.tempUpdateData.find(
        (intro: { SUBLINE_CD: any, LINE_CD: any }) =>
          intro.SUBLINE_CD == this.introForm.get('sublineCode')?.value
          && intro.LINE_CD == this.paramForm.get('lineCd')?.value);
      this.tableDataUpdate();
      if (tempInsertChecker != null && tempInsertChecker != '') {
        //this.tempUpdateData.push(this.formValueToData());
        tempInsertChecker.LINE_CD = this.paramForm.get('lineCd')?.value;
        tempInsertChecker.SUBLINE_CD = this.introForm.get('sublineCode')?.value;
        tempInsertChecker.ACTIVE_TAG = this.introForm.get('activeTag')?.value;
        tempInsertChecker.INTRO_TITLE = this.introForm.get('introTitle')?.value;
        tempInsertChecker.INTRO_DESC = this.introForm.get('introDesc')?.value;
        tempInsertChecker.IMAGE_PATH = this.introForm.get('imagePath')?.value;
        tempInsertChecker.REMARKS = this.introForm.get('remarks')?.value;
        tempInsertChecker.USER_ID = this.introForm.get('userID')?.value;
        tempInsertChecker.MODE = 'I';
      }
      else if (tempUpdateChecker != null && tempUpdateChecker != '') {
        tempUpdateChecker.LINE_CD = this.paramForm.get('lineCd')?.value;
        tempUpdateChecker.SUBLINE_CD = this.introForm.get('sublineCode')?.value;
        tempUpdateChecker.ACTIVE_TAG = this.introForm.get('activeTag')?.value;
        tempUpdateChecker.INTRO_TITLE = this.introForm.get('introTitle')?.value;
        tempUpdateChecker.INTRO_DESC = this.introForm.get('introDesc')?.value;
        tempUpdateChecker.IMAGE_PATH = this.introForm.get('imagePath')?.value;
        tempUpdateChecker.REMARKS = this.introForm.get('remarks')?.value;
        tempUpdateChecker.USER_ID = this.introForm.get('userID')?.value;
        tempUpdateChecker.MODE = 'U';
      }
      else {
        this.tempUpdateData.push(this.formValueToData());
      }
      this.saveDisabled = false;
      this.resetFormValues();
      this.appMessageService.showAppMessage("Introduction details updated!", "success");
    }
    catch (e) {
    console.error(e);
    }
  }

  tableDataUpdate() {
    try {
      this.introForm.patchValue({
        activeTag: this.introForm.get('activeTag')?.value,
        userID: this.currentUser,
        lastUserUpdate: new Date()
      });
      let updateIndex = this.tblConfigIntro.tblData.indexOf(this.rowClickedData);
      this.tblConfigIntro.tblData[updateIndex] = {
        SUBLINE_CD: this.introForm.get('sublineCode')?.value,
        SUBLINE_NAME: this.introForm.get('sublineName')?.value,
        SUBLINE_FIELD: this.introForm.get('sublineField')?.value,
        ACTIVE_TAG: this.introForm.get('activeTag')?.value,
        INTRO_TITLE: this.introForm.get('introTitle')?.value,
        INTRO_DESC: this.introForm.get('introDesc')?.value,
        IMAGE_PATH: this.introForm.get('imagePath')?.value,
        REMARKS: this.introForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.introForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.introForm.get('userID')?.value,
        MODE: 'U'
      };
    }
    catch (e) {
    console.error(e);
    }
  }

  formValueToData() {
    try {
      let formToData: any = {
        LINE_CD: this.paramForm.get('lineCd')?.value,
        SUBLINE_CD: this.introForm.get('sublineCode')?.value,
        ACTIVE_TAG: this.introForm.get('activeTag')?.value,
        INTRO_TITLE: this.introForm.get('introTitle')?.value,
        INTRO_DESC: this.introForm.get('introDesc')?.value,
        IMAGE_PATH: this.introForm.get('imagePath')?.value,
        REMARKS: this.introForm.get('remarks')?.value,
        USER_ID: this.introForm.get('userID')?.value,
        MODE: 'U'
      }
      return formToData;
    }
    catch (e) {
    console.error(e);
    }
  }

  saveAddedData() {
    this._APICallService.saveIntroduction(this.tempAddedData)
      .subscribe({
        next: (response: any) => {
          this.securityService.checkRequestKeyResponse(response, () => {
            this.securityService.hasValidCsrfToken(response, () => {
              if (response.status == "SUCCESS" && this.tranPosted) {
                if (this.tempUpdateData.length != 0) {
                  this.saveUpdateData();
                } else {
                  this.jsonDataService.contorlLoading(false);
                  this.openDialog('SUCCESS', 'New introduction details Successfully saved!');
                  this.initOnGet();
                  this.tempAddedData = [];
                  this.tempUpdateData = [];
                  this.resetFormValues();
                }
              }
              else {
                this.jsonDataService.contorlLoading(false);
                this.openDialog('ERROR', 'An error has occured while saving details for new introduction');
              }
            });
          });
        },
        error: () => {
          this.jsonDataService.contorlLoading(false);
          this.openDialog('ERROR', 'An error has occured while saving details for new introduction');
        }
      });
  }

  saveUpdateData() {
    this._APICallService.saveIntroduction(this.tempUpdateData)
      .subscribe({
        next: (response: any) => {
          this.securityService.checkRequestKeyResponse(response, () => {
            this.securityService.hasValidCsrfToken(response, () => {
              if (response.status == "SUCCESS" && this.tranPosted) {
                this.jsonDataService.contorlLoading(false);
                this.openDialog('SUCCESS', 'Existing introduction details successfully saved!');
                this.initOnGet();
                this.tempAddedData = [];
                this.tempUpdateData = [];
                this.resetFormValues();

              } else {
                this.jsonDataService.contorlLoading(false);
                this.openDialog('ERROR', 'An error has occured while saving details for existing introduction');
              }
            });
          });
        },
        error: () => {
          this.jsonDataService.contorlLoading(false);
          this.openDialog('ERROR', 'An error has occured while saving details for existing introduction');
        }
      });
  }

  toSave() {
    try {
      this.noLoadingInProcess = false;
      this.formService.showFormLoader(null, "generate-intro-form", "Please wait ...", null, null, true);
      this.jsonDataService.contorlLoading(true);
      if (this.tempAddedData.length != 0) {
        this.saveAddedData();
      } else {
        if (this.tempUpdateData.length != 0) {
          this.saveUpdateData();
        } else {
          this.jsonDataService.contorlLoading(false);
          this.initOnGet();
          this.tempAddedData = [];
          this.tempUpdateData = [];
          this.resetFormValues();
        }
      }
    }
    catch (e) {
    console.error(e);
    }
  }

  deleteIntro() {
    try {
      let clickData = this.rowClickedData;
      this.tblConfigIntro.tblData = this.tblConfigIntro.tblData.filter(
        function (e: any) {
          return e.SUBLINE_CD != clickData.SUBLINE_CD;
        }
      );
      this.tempAddedData = this.tempAddedData.filter(
        function (e: any) {
          return e.SUBLINE_CD != clickData.SUBLINE_CD;
        }
      );
      this.resetFormValues();
    }
    catch (e) {
    console.error(e);
    }
  }

  saveChecker() {
    try {
      if ((this.tempAddedData == null || this.tempAddedData == '') && (this.tempUpdateData == null || this.tempUpdateData == '')) {
        return true;
      }
      else {
        return false;
      }
    }
    catch (e) {
    console.error(e);
      return true;
    }
  }

  deleteChecker(ev: any) {
    try {
      let tempDataFlag;
      tempDataFlag = this.tempAddedData.filter(function (e: any) {
        return e.SUBLINE_CD == ev.SUBLINE_CD;
      });
      if (tempDataFlag == '' || tempDataFlag == null) {
        return true;
      } else {
        return false;
      }
    }
    catch (e) {
    console.error(e);
      return true;
    }
  }

  onRowClick(ev: any) {
    try {
      if (ev != null) {
        this.rowClickedData = ev;
        this.loadFormValues();
        this.saveDisabled = this.saveChecker();
        this.deleteDisabled = this.deleteChecker(ev);
      }
      else {
        this.resetFormValues();
      }
    }
    catch (e) {
    console.error(e);
    }
  }

  loadFormValues() {
    try {
      this.introForm.patchValue({
        sublineCode: this.rowClickedData.SUBLINE_CD,
        sublineName: this.rowClickedData.SUBLINE_NAME,
        sublineField: this.rowClickedData.SUBLINE_FIELD,
        activeTag: this.rowClickedData.ACTIVE_TAG,
        introTitle: this.rowClickedData.INTRO_TITLE,
        introDesc: this.rowClickedData.INTRO_DESC,
        imagePath: this.rowClickedData.IMAGE_PATH,
        remarks: this.rowClickedData.REMARKS,
        userID: this.rowClickedData.USER_ID,
        lastUserUpdate: this.rowClickedData.LAST_USER_UPDATE
      });
      this.sublineDisabled = true;
      this.isIntroSelected = true;
    }
    catch (e) {
    console.error(e);
    }
  }

  resetFormValues() {
    try {
      this.selectedData = null;
      this.introForm.patchValue({
        sublineCode: null,
        sublineName: null,
        sublineField: null,
        activeTag: 'A',
        introTitle: null,
        introDesc: null,
        imagePath: null,
        remarks: null,
        userID: null,
        lastUserUpdate: null
      });

      this.saveDisabled = this.saveChecker();
      this.deleteDisabled = true;
      this.isIntroSelected = false;
      this.sublineDisabled = false;
      this.rowClickedData = '' || null;
    }
    catch (e) {
    console.error(e);
    }
  }

  openDialog(title: any, message: any) {
    try {
      this.noLoadingInProcess = true;
      this.formService.hideFormLoader("generate-intro-form");
      this._Dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        }
      });
    } catch (e) {
    console.error(e);
    }
  }

  validateInsert() {
    try {
      this.validated = false;
      if (!this.requiredFieldCheck()) {
        // this.openDialog('ERROR', 'There are missing information. Please provide the necessary information needed.');
        setTimeout(() => {
          this.formService.hideFormMsg("introduction-error-message");
        }, 10000);
      }
      else if (!this.sublineDupCheck()) {
        // this.openDialog('ERROR', 'Unable to add record. Introduction for this Line and Subline already exists.');
        setTimeout(() => {
          this.formService.hideFormMsg("introduction-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
    }
  }

  validateUpdate() {
    try {
      this.validated = false;
      if (!this.requiredFieldCheck()) {
        // this.openDialog('ERROR', 'There are missing information. Please provide the necessary information needed.');
        setTimeout(() => {
          this.formService.hideFormMsg("introduction-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
    }
  }

  sublineDupCheck() {
    try {
      let sublineForm = this.introForm.get('sublineCode')?.value?.toUpperCase();
      this.sublineField = this.tblConfigIntro.tblData.filter(function (e: any) {
        return e.SUBLINE_CD?.toUpperCase() == sublineForm?.toUpperCase();
      });
      if (this.sublineField == '' || this.sublineField == null) {
        return true;
      } else {
        this.formService.showFormMsg("introduction-error-message", 'Unable to add record. Introduction for this Line and Subline already exists.', "E");
        return false;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }

  requiredFieldCheck() {
    try {
      if (this.introForm.get('sublineField')?.value == '' || this.introForm.get('sublineField')?.value == null
        || this.introForm.get('activeTag')?.value == '' || this.introForm.get('activeTag')?.value == null
        || this.onlyWhitespace(this.introForm.get('sublineField')?.value ?? '')) {
        this.formService.showFormMsg("introduction-error-message", 'There are missing information. Please provide the necessary information needed.', "E");
        return false;
      }
      else {
        return true;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }

  private onlyWhitespace(val: string) {
    return (val ?? '').trim().length === 0;
  }
}