<section id="curr-maint">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>APPLICABLE CLIENT GROUP PER USER ID MAINTENANCE</p>
        </div>
        <div class="form-content">
            <form [formGroup]="paramForm" class="form">
                <div class="form-container-grid top-form">
                    <div class="field required">
                        <label>User</label>
                    </div>
                    <div class="lov-search">
                        <input readonly formControlName="userField">
                        <button matSuffix class="btn-search btn" type="button" (click)="openLov('USER')">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                    <div class="field required">
                        <label>Item Level 1</label>
                    </div>
                    <div class="">
                        <select formControlName="itemLevel1Group" (change)="initOnGet()">
                            <option *ngFor="let itemLevel1 of selections.itemLevel1Group" [value]="itemLevel1.cd"
                                [disabled]="itemLevel1Disabled">
                                {{itemLevel1.cd}} - {{itemLevel1.name}}
                            </option>
                        </select>
                    </div>
                    <div class="field required">
                        <label>Client Group</label>
                    </div>
                    <div class="lov-search">
                        <input readonly formControlName="clientGroupField">
                        <button matSuffix class="btn-search btn" type="button" (click)="openLov('CLIENT_GROUP')">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                    <div class="field required">
                        <label>Item Level 2</label>
                    </div>
                    <div class="">
                        <select formControlName="itemLevel2Group" (change)="initOnGet()">
                            <option *ngFor="let itemLevel2 of selections.itemLevel2Group" [value]="itemLevel2.cd">
                                {{itemLevel2.cd}} - {{itemLevel2.name}}
                            </option>
                        </select>
                    </div>
                </div>
            </form>
        </div>
        <div class='table-line'>
            <osp-table [tblConfig]="currTblConfig" (onRowClick)="onRowClick($event)"></osp-table>
        </div>
        <div id="generate-line-form">
            <div class="form-loader"></div>
        </div>
        <div class="form-content" [hidden]="formHider">
            <form [formGroup]="currForm" class="form">
                <div id="error-message"></div>
                <div class="form-container-grid">
                    <div class="">
                    </div>
                    <div class="">
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag">
                            <option *ngFor="let activeTag of selections.activeTag" [value]="activeTag.cd">
                                {{activeTag.name}}
                            </option>
                        </select>
                    </div>
                    <div class="field required">
                        <label>Effective Date</label>
                    </div>
                    <div class="">
                        <input formControlName="effDate" type="text" placeholder="MM/DD/YYYY"
                            [readonly]="isLineSelected" date-input [matDatepicker]="effDatePicker">
                        <mat-datepicker-toggle matSuffix [for]="effDatePicker" *ngIf="!isLineSelected">
                        </mat-datepicker-toggle>
                        <mat-datepicker #effDatePicker></mat-datepicker>
                    </div>
                    <div class="field">
                        <label>Expiry Date</label>
                    </div>
                    <div class="">
                        <input formControlName="expDate" type="text" placeholder="MM/DD/YYYY"
                            date-input [matDatepicker]="expDatePicker">
                        <mat-datepicker-toggle matSuffix [for]="expDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #expDatePicker></mat-datepicker>
                    </div>
                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" type="text">
                    </div>
                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input formControlName="lastUpdateUser" type="text" readonly>
                    </div>
                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="lastUpdate" type="text" placeholder="MM/DD/YYYY hh:mm:ss" readonly>
                    </div>
                </div>
                <div class='btn-row'>
                    <button type="button" id='btn-add' *ngIf="!isLineSelected" title='Register a new line'
                        class="btn btn2" value='ADD' type="submit" (click)="insertLine()">ADD</button>
                    <button type="button" id='btn-update' *ngIf="isLineSelected" title='Update a line' class="btn btn2"
                        value='UPDATE' type="submit" (click)="updateLine()">UPDATE</button>
                    <button type="button" id='btn-delete' title='Remove a line' class="btn btn2" value='DELETE'
                        type="submit" [disabled]='deleteDisabled' (click)="deleteLine()">DELETE</button>
                </div>
                <div class="mtn-title"></div>
                <div class='btn-row'>
                    <button type="button" id='btn-save' title='Save changes on line details' class="btn btn2"
                        value='SAVE' type="submit" (click)="saveChanges()" [disabled]="saveDisabled">SAVE</button>
                </div>
            </form>
        </div>
    </div>
</section>