<section id='curr-maint'>
    <div class="p-datatable mtn-container">
        <div class="mtn-title">
            <p>{{moduleName}}</p>
        </div>
        <!-- Red Title Border -->
        <div class="p-datatable-header"><span class="header-title">ASSURED</span></div>
        <!-- Container for BPIMS Table using prime ng -->
        <div class='table-user'>
            <osp-table [tblConfig]="tblConfigAssured" (onRowClick)='onAssuredRowClick($event)'></osp-table>
        </div>
        <!-- Loading Animation -->
        <div id="generate-assured-form">
            <div class="form-loader"></div>
        </div>

        <!-- Container for BPIMS Fields -->
        <div class="form-content">
            <form [formGroup]="assuredForm" class="form">
                <div id="bpims-error-message"></div>
                <div class="form-container-grid">

                    <div class="field">
                        <label>Assured No.</label>
                    </div>
                    <div class="">
                        <input formControlName="assuredNo" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <input formControlName="activeTag" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Client Type</label>
                    </div>
                    <div class="">
                        <input formControlName="clientType" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Birth Date</label>
                    </div>
                    <div class="">
                        <input formControlName="birthDate" readonly type="text" placeholder="MM/DD/YYYY">
                    </div>

                    <div class="field">
                        <label>First Name</label>
                    </div>
                    <div class="">
                        <input formControlName="firstName" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Citizenship/Nationality</label>
                    </div>
                    <div class="">
                        <input formControlName="nationality" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Last Name</label>
                    </div>
                    <div class="">
                        <input formControlName="lastName" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Gender</label>
                    </div>
                    <div class="">
                        <input formControlName="gender" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Middle Name</label>
                    </div>
                    <div class="">
                        <input formControlName="middleName" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Marital Status</label>
                    </div>
                    <div class="">
                        <input formControlName="maritalStatus" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Suffix</label>
                    </div>
                    <div class="">
                        <input formControlName="suffix" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Company Name</label>
                    </div>
                    <div class="">
                        <input formControlName="compName" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Display Name</label>
                    </div>
                    <div class="">
                        <input formControlName="displayName" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Registration No.</label>
                    </div>
                    <div class="">
                        <input formControlName="regNo" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Permanent Address</label>
                    </div>
                    <div class="span">
                        <input formControlName="permanentAdd1" readonly type="text">
                    </div>

                    <div class="field">
                        <label></label>
                    </div>
                    <div class="span">
                        <input formControlName="permanentAdd2" readonly type="text">
                    </div>

                    <div class="field">
                        <label></label>
                    </div>
                    <div class="span">
                        <input formControlName="permanentAdd3" readonly type="text">
                    </div>

                    <div class="field">
                        <label></label>
                    </div>
                    <div class="span">
                        <input formControlName="permanentAdd4" readonly type="text">
                    </div>

                    <div class="field">
                        <label>Permanent Country</label>
                    </div>
                    <div class="">
                        <input formControlName="permanentCountry" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Risk Rating Score</label>
                    </div>
                    <div class="">
                        <input formControlName="riskRatingScore" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Permanent City</label>
                    </div>
                    <div class="">
                        <input formControlName="permanentCity" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Risk Profile</label>
                    </div>
                    <div class="">
                        <input formControlName="riskProfile" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Permanent Province</label>
                    </div>
                    <div class="">
                        <input formControlName="permanentProvince" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Mobile No.</label>
                    </div>
                    <div class="">
                        <input formControlName="mobileNo" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Permanent Postal Code</label>
                    </div>
                    <div class="">
                        <input formControlName="permanentPostalCd" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Telephone No.</label>
                    </div>
                    <div class="">
                        <input formControlName="telNo" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Source of Fund</label>
                    </div>
                    <div class="">
                        <input formControlName="fundSource" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Country of Birth</label>
                    </div>
                    <div class="">
                        <input formControlName="birthCountry" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>No Tin Tag</label>
                    </div>
                    <div class="">
                        <input formControlName="noTinTag" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>City of Birth</label>
                    </div>
                    <div class="">
                        <input formControlName="birthCity" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Tin No.</label>
                    </div>
                    <div class="">
                        <input formControlName="tinNo" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Province of Birth</label>
                    </div>
                    <div class="">
                        <input formControlName="birthProvince" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Email Address</label>
                    </div>
                    <div class="">
                        <input formControlName="emailAdd" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Occupation</label>
                    </div>
                    <div class="">
                        <input formControlName="occupation" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Same as Permanent Address</label>
                    </div>
                    <div class="">
                        <input formControlName="sameAsPermAdd" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Job Category</label>
                    </div>
                    <div class="">
                        <input formControlName="jobCategory" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Present Address</label>
                    </div>
                    <div class="span">
                        <input formControlName="presentAdd1" readonly type="text">
                    </div>

                    <div class="field">
                        <label></label>
                    </div>
                    <div class="span">
                        <input formControlName="presentAdd2" readonly type="text">
                    </div>

                    <div class="field">
                        <label></label>
                    </div>
                    <div class="span">
                        <input formControlName="presentAdd3" readonly type="text">
                    </div>

                    <div class="field">
                        <label></label>
                    </div>
                    <div class="span">
                        <input formControlName="presentAdd4" readonly type="text">
                    </div>

                    <div class="field">
                        <label>Present Country</label>
                    </div>
                    <div class="">
                        <input formControlName="presentCountry" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label></label>
                    </div>
                    <div class="">
                    </div>

                    <div class="field">
                        <label>Present City</label>
                    </div>
                    <div class="">
                        <input formControlName="presentCity" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Name of Employee/Business</label>
                    </div>
                    <div class="">
                        <input formControlName="nameOfEmployer" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Present Province</label>
                    </div>
                    <div class="">
                        <input formControlName="presentProvince" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Nature of Employment/Business</label>
                    </div>
                    <div class="">
                        <input formControlName="natureOfEmployment" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Present Postal Code</label>
                    </div>
                    <div class="">
                        <input formControlName="presentPostalCd" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>RM No.</label>
                    </div>
                    <div class="">
                        <input formControlName="rmNo" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>BPI MS Code</label>
                    </div>
                    <div class="">
                        <input formControlName="bpimsCd" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>BPI MS Short Description</label>
                    </div>
                    <div class="">
                        <input formControlName="bpimsShortDesc" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Create User</label>
                    </div>
                    <div class="">
                        <input formControlName="createUser" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Create Date</label>
                    </div>
                    <div class="">
                        <input formControlName="createDate" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input formControlName="lastUser" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="lastUserUpdate" readonly type="text" maxlength="60">
                    </div>

                </div>
            </form>
        </div>

        <!-- Red Title Border -->
        <div class="p-datatable-header"><span class="header-title">ASSURED ID</span></div>
        <div class='table-user'>
            <osp-table [tblConfig]="tblConfigAssuredId" (onRowClick)='onAssuredIdRowClick($event)'></osp-table>
        </div>
        <!-- Loading Animation -->
        <div id="generate-assured-form">
            <div class="form-loader"></div>
        </div>
        <!-- Container for BPIMS Fields -->
        <div class="form-content">
            <form [formGroup]="assuredIdForm" class="form">
                <div id="bpims-error-message"></div>
                <div class="form-container-grid">

                    <div class="field">
                        <label>ID Type</label>
                    </div>
                    <div class="">
                        <input formControlName="idType" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>ID No.</label>
                    </div>
                    <div class="">
                        <input formControlName="idNo" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Image File</label>
                    </div>
                    <div class="">
                        <input formControlName="imgFile" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Expiry Date</label>
                    </div>
                    <div class="">
                        <input formControlName="expDate" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Upload User</label>
                    </div>
                    <div class="">
                        <input formControlName="uploadUser" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Upload Date</label>
                    </div>
                    <div class="">
                        <input formControlName="uploadDate" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input formControlName="lastUpdateUser" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="lastUpdate" readonly type="text" maxlength="60">
                    </div>

                </div>
            </form>
        </div>

        <!-- Red Title Border -->
        <div class="p-datatable-header"><span class="header-title">ASSURED ATTACHMENT</span></div>
        <div class='table-user'>
            <osp-table [tblConfig]="tblConfigAssuredAtt" (onRowClick)='onAssuredAttRowClick($event)'></osp-table>
        </div>
        <!-- Loading Animation -->
        <div id="generate-assured-form">
            <div class="form-loader"></div>
        </div>
        <div class="form-content">
            <form [formGroup]="assuredAttForm" class="form">
                <div id="bpims-error-message"></div>
                <div class="form-container-grid">

                    <div class="field">
                        <label>File ID</label>
                    </div>
                    <div class="">
                        <input formControlName="fileId" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Delete Tag</label>
                    </div>
                    <div class="">
                        <input formControlName="deleteTag" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>File</label>
                    </div>
                    <div class="">
                        <input formControlName="file" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>File Size</label>
                    </div>
                    <div class="">
                        <input formControlName="fileSize" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Upload User</label>
                    </div>
                    <div class="">
                        <input formControlName="uploadUser" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Upload Date</label>
                    </div>
                    <div class="">
                        <input formControlName="uploadDate" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input formControlName="lastUpdateUser" readonly type="text" maxlength="60">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="lastUpdate" readonly type="text" maxlength="60">
                    </div>

                </div>
            </form>
        </div>
    </div>
</section>