<div class="mtn-container">
    <div class="mtn-title">
        <p>{{ moduleName?.toUpperCase() }}</p>
    </div>
    <div *ngIf="showForm" class="form-content">
        <form [formGroup]="forms.filterLovForm" class="form">
            <div class="form-container-grid top-form triple">
                <div class="field required">
                    <label>Client Group</label>
                </div>
                <div class="">
                    <input formControlName="clientCdDisp">
                    <button matSuffix class="btn btn-search" type="button" (click)="openLov['clientType']()"
                        [disabled]="btns.filterLov.clientGrpSearchDisabled" >
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
                <div class="field required">
                    <label>Subline</label>
                </div>
                <div class="">
                    <input formControlName="sublineCdDisp">
                    <button matSuffix class="btn btn-search" type="button" (click)="openLov['subline']()"
                        [disabled]="btns.filterLov.sublineSearchDisabled" >
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
                <div class="field required">
                    <label>Car Company Group</label>
                </div>
                <div class="">
                    <select formControlName="carCompGrp">
                        <option *ngFor="let carCompGrp of selections.carCompGrps" [ngValue]="carCompGrp.cd">
                            {{carCompGrp.name}}
                        </option>
                    </select>
                </div>
            </div>
        </form>
    </div>
    <osp-table [tblConfig]="defaultTowingLimitPerVehicleTblConfig" (onRowClick)="onRowClick($event)" [selectedRow]="selectedRow"></osp-table>
    <div id="default-towing-limit-per-vehicle-maintenance-loading">
        <div class="form-loader"></div>
    </div>
    <div *ngIf="showForm" class="form-content">
        <form [formGroup]="forms.defaultTowingLimitPerVehicleForm" class="form">
            <div id="default-towing-limit-per-vehicle-error-message"></div>
            <div class="form-container-grid">
                <div class="field required">
                    <label>Towing Amount</label>
                </div>
                <div class="">
                    <input class="currency" formControlName="towingAmt" (focus)="_formService.setFieldToNumberWithDecimal(forms.defaultTowingLimitPerVehicleForm, 'towingAmt', $event)"
                    (keypress)="allowNumericDigitsOnly($event)" amount >
                </div>
                <div class="field required">
                    <label>Active</label>
                </div>
                <div class="">
                    <select formControlName="active">
                        <option *ngFor="let activeType of selections.activeTypes" [value]="activeType.cd">
                            {{activeType.name}}
                        </option>
                    </select>
                </div>
                <div class="field required">
                    <label>Effective Date</label>
                </div>
                <div class="">
                    <input date-input [matDatepicker]="effDatePicker" [max]="maxEffDate" formControlName="effDateFrom" placeholder="MM/DD/YYYY"> 
                    <mat-datepicker-toggle class="mtn-datepicker" matSuffix [for]="effDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #effDatePicker></mat-datepicker>
                </div>
                <div class="field">
                    <label>Expiry Date</label>
                </div>
                <div class="">
                    <input date-input [matDatepicker]="exDatePicker" [min]="minExpDate" formControlName="effDateTo" placeholder="MM/DD/YYYY">
                    <mat-datepicker-toggle matSuffix [for]="exDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #exDatePicker></mat-datepicker>
                </div>
                <div class="field">
                    <label>Remarks</label>
                </div>
                <div class="span">
                    <input formControlName="remarks" >
                </div>
                <div class="field">
                    <label>Last Update User</label>
                </div>
                <div class="">
                    <input formControlName="lastUpdateUser" >
                </div>
                <div class="field">
                    <label>Last Update</label>
                </div>
                <div class="">
                    <input formControlName="lastUpdate" >
                </div>
            </div>
        </form>
        <div class="btn-grp">
            <button *ngIf="btns.defaultTowingLimitPerVehicle.updateDisabled" class="btn btn2" type="button" (click)="onAdd()" [disabled]="btns.defaultTowingLimitPerVehicle.addDisabled" >ADD</button>
            <button *ngIf="!btns.defaultTowingLimitPerVehicle.updateDisabled" class="btn btn2" type="button" (click)="onUpdate()" >UPDATE</button>
            <button class="btn btn2" type="button" [disabled]='btns.defaultTowingLimitPerVehicle.deleteDisabled' (click)="onDelete()" >DELETE</button>
        </div>
        <div class="mtn-title"></div>
        <div class="btn-grp">
            <button class="btn btn2" type="button" [disabled]='btns.defaultTowingLimitPerVehicle.saveDisabled' (click)="onSave()" >SAVE</button>
        </div>
    </div>
</div>
