<div class="mtn-title"><p>{{ moduleName }}</p></div>
<section id="fmv">
    <div class="top-tbl">
        <osp-table #vehicleTbl [tblConfig]="tblConfig1" (onRowClick)="onRowClickVehicle($event)"></osp-table>
    </div>
    <div class="mid-tbl">
        <osp-table #fmvTbl [tblConfig]="tblConfig2" (onRowClick)="onRowClickFmv($event)" (onLazyLoad)="lazyLoad($event)"></osp-table>
    </div>
    <div *ngIf="selectedVehicle !== null">
        <div class="p-grid">
            <div class="p-col-1"></div>
            <form class="p-col-10 form" [formGroup]="fmvForm">
                <div id="fmv-dtl-form-msg"></div>
                <div id="fmv-dtl-form">
                    <div class="form-loader"></div>
                    <div class="form-fields">
                        <div class="p-grid cust-margin">
                            <div class="p-col-6 p-grid">
                                <div class="p-col-6 p-text-right field required">
                                    <label>Year Model</label>
                                </div>
                                <div class="p-col-6">
                                    <input matInput type="text" formControlName="modelYear" value="" numbersOnly>
                                </div>
                            </div>
                            <div class="p-col-6 p-grid">
                                <div class="p-col-6 p-text-right field">
                                    <label>Active</label>
                                </div>
                                <div class="p-col-6">
                                    <input type="checkbox" formControlName="activeTag">
                                </div>
                            </div>
                        </div>
                        <div class="p-grid cust-margin">
                            <div class="p-col-6 p-grid">
                                <div class="p-col-6 p-text-right field required">
                                    <label>Latest FMV</label>
                                </div>
                                <div class="p-col-6">
                                    <input matInput class="p-text-right" type="text" formControlName="fmvValue" amount (focus)="fs.setFieldToNumberWithDecimal(fmvForm, 'fmvValue', $event)"
                                    (blur)="computeMinMax($event)"> 
                                </div>
                            </div>
                            <div class="p-col-6"></div>
                        </div>
                        <div class="p-grid cust-margin">
                            <div class="p-col-6 p-grid field required">
                                <div class="p-col-6 p-text-right">
                                    <label>Minimum FMV</label>
                                </div>
                                <div class="p-col-6">
                                    <input matInput class="p-text-right" type="text" formControlName="fmvValueMin" amount (focus)="fs.setFieldToNumberWithDecimal(fmvForm, 'fmvValueMin', $event)"
                                    (blur)="fs.setFieldToAmountFormat(fmvForm, 'fmvValueMin', {
                                        target: {
                                          value: fmvForm.controls['fmvValueMin'].value
                                        }
                                      })">
                                </div>
                            </div>
                            <div class="p-col-6 p-grid">
                                <div class="p-col-6 p-text-right field required">
                                    <label>Maximum FMV</label>
                                </div>
                                <div class="p-col-6">
                                    <input matInput class="p-text-right" type="text" formControlName="fmvValueMax" amount (focus)="fs.setFieldToNumberWithDecimal(fmvForm, 'fmvValueMax', $event)"
                                    (blur)="fs.setFieldToAmountFormat(fmvForm, 'fmvValueMax', {
                                        target: {
                                          value: fmvForm.controls['fmvValueMax'].value
                                        }
                                      })">
                                </div>
                            </div>
                        </div>
                        <div class="p-grid cust-margin">
                            <div class="p-col-6 p-grid">
                                <div class="p-col-6 p-text-right field required">
                                    <label>Eff Date From</label>
                                </div>
                                <div class="p-col-5">
                                    <input matInput formControlName="effDateFrom" type="text" placeholder="MM/DD/YYYY" [matDatepicker]="effDateFromPicker" date-input>
                                </div>
                                <mat-datepicker-toggle matSuffix [for]="effDateFromPicker" class="p-col-1"></mat-datepicker-toggle>
                                <mat-datepicker #effDateFromPicker></mat-datepicker>
                            </div>
                            <div class="p-col-6 p-grid">
                                <div class="p-col-6 p-text-right field required">
                                    <label>Eff Date To</label>
                                </div>
                                <div class="p-col-5">
                                    <input matInput formControlName="effDateTo" type="text" placeholder="MM/DD/YYYY" [matDatepicker]="effDateToPicker" date-input>
                                </div>
                                <mat-datepicker-toggle matSuffix [for]="effDateToPicker" class="p-col-1"></mat-datepicker-toggle>
                                <mat-datepicker #effDateToPicker></mat-datepicker>
                            </div>
                        </div>
                        <div class="p-grid cust-margin">
                            <div class="p-col-6 p-grid">
                                <div class="p-col-6 p-text-right field">
                                    <label>User ID</label>
                                </div>
                                <div class="p-col-6">
                                    <input matInput type="text" formControlName="userId">
                                </div>
                            </div>
                            <div class="p-col-6 p-grid">
                                <div class="p-col-6 p-text-right field">
                                    <label>Last Update</label>
                                </div>
                                <div class="p-col-6">
                                    <input matInput type="text" formControlName="lastUpdate" [value]="fmvForm.get('lastUpdate')?.value | date: 'MM/dd/yyyy hh:mm:ss a'">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mtn-title"></div>
                <div class="p-grid form-btns">
                    <div class="p-col p-text-center bot-form form-btns">
                        <button class="btn btn2" (click)="onClickAdd()">{{ selectedFmv == null ? 'ADD' : 'UPDATE' }}</button>
                        <button class="btn btn2" (click)="onClickDelete()" [disabled]="selectedFmv == null || !selectedFmv.newRow">DELETE</button>
                        <button class="btn btn2" (click)="onClickSave()" [disabled]="saveFmvDtlList.length == 0">SAVE</button>
                        <button class="btn btn2" (click)="openUploadOverlay()">UPLOAD</button>
                        <button class="btn btn2" (click)="openRevHist()" [disabled]="selectedFmv == null || selectedFmv.newRow">REVISION HISTORY</button>
                    </div>
                </div>
            </form>
            <div class="p-col-1"></div>
        </div>
    </div>
</section>

<revision-history #rh [data]="fmvRevHist" (onLazyLoad)="rhLazy($event)"></revision-history>

<!-- <ng-template #deleteDialog>
    <h3 class="overlay-header" mat-dialog-title>Confirm Delete</h3>
    <mat-dialog-content>
        Do you want to delete this record?
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-button [mat-dialog-close]="true">Yes</button>
        <button mat-button [mat-dialog-close]="false">No</button>
    </mat-dialog-actions>
</ng-template> -->