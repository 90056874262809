import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { SecurityService } from 'src/app/services/security.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { CustomValidators } from 'src/app/utils/custom-validator';

@Component({
  selector: 'bma-maintenance',
  templateUrl: './bma-maintenance.component.html',
  styleUrls: ['./bma-maintenance.component.css']
})
export class BmaMaintenanceComponent implements OnInit, LeaveConfirmation {

  moduleId: string = 'BMM057';
  activeTags: any = [];
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc?.toUpperCase() ?? "";

  constructor(
    public _formService: FormService,
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    private _jDS: JsonDataService,
    private _securityService: SecurityService,
    private _appMessageService: AppMessageService,
    private _userDetailService: UserDetailsService
  ) {
    for (const refCd of this._jDS.data.refCds.filter((a: any) => a.rvDomain === "BMM013.ACTIVE_TAG")) {
      this.activeTags.push({
        value: refCd.rvLowValue,
        text: refCd.rvMeaning
      });
    }
  }

  ngOnInit(): void {
    this.getData.bma();
  }

  canDeactivate(): boolean | Observable<boolean> {
    return this.bmaTblConfig.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true)).length > 0 ? false : true;
  }

  public btns = {
    bma: {
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      addDisabled: true,
    },
  }

  public showForm: boolean = true;

  /* Data Stuffs */

  private rowData: any;
  private tempDataRow: any;
  private errorMessage: string = "";

  public forms = {
    bmaForm: this.fb.group({
      bmaCd: [null],
      bmaCdDisp: [{ value: '', disabled: 'true' }],
      active: ['A', [Validators.required]],
      bmaDesc: [null, [Validators.required, Validators.maxLength(45), this.checkDuplicateBmaDesc(), CustomValidators.requiredTrim]],
      designation: [null, [Validators.maxLength(50)]],
      bmCode: [null, [Validators.required, Validators.maxLength(8), CustomValidators.requiredTrim, this.checkDuplicateBmCode()]],
      bmDesc: [null, [Validators.maxLength(10)]],
      remarks: [null, [Validators.maxLength(4000)]],
      lastUpdateUser: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }],
    }),
    resetBmaForm: () => {
      this.forms.bmaForm.reset();
      this.forms.bmaForm.patchValue({
        active: 'A'
      });
      this.btns.bma.updateDisabled = this.btns.bma.deleteDisabled = true;
      this._formService.hideFormMsg("bma-error-message");
    }
  }

  private bmaFormManipulate = {
    dbToForm: (data: any) => {
      return {
        bmaCd: data.BMA_CD,
        bmaCdDisp: data.BMA_CD ? data.BMA_CD.toString().padStart(3, 0) : null,
        bmaDesc: data.BMA_DESC,
        designation: data.DESIGNATION,
        bmCode: data.BM_CODE,
        bmDesc: data.BM_SHORT_DESC,
        active: data.ACTIVE_TAG,
        remarks: data.REMARKS,
        lastUpdateUser: data.USER_ID,
        lastUpdate: data.LAST_UPDATE,
      };
    },
    formToDb: (data: any) => {
      return {
        BMA_CD: data.bmaCd,
        BMA_DESC: data.bmaDesc.trim(),
        DESIGNATION: data.designation,
        BM_CODE: data.bmCode,
        BM_SHORT_DESC: data.bmDesc,
        ACTIVE_TAG: data.active,
        REMARKS: data.remarks,
        USER_ID: null,
        LAST_UPDATE: null,
      };
    },
  }

  public bmaTblConfig = {
    cols: [
      {
        key: "BMA_CD",
        header: "BMA Code",
        dataType: "string",
        width: '128px'
      },
      {
        key: "BMA_DESC",
        header: "BMA",
        dataType: "string"
      },
      {
        key: "BM_CODE",
        header: "BPIMS Code",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "Active",
        dataType: "checkbox",
        width: '64px'
      }
    ],
    tblData: [] as any[],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  private getData = {
    bma: () => {
      try {
        this.bmaTblConfig.tblData = [];
        this.bmaTblConfig.loading = true;
        this._jDS.contorlLoading(true);
        this._api.getAgentBma({
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          type: "MODULE"
        }).subscribe({
          next: (response: any) => {
            this._securityService.checkRequestKeyResponse(response, () => {
              this._securityService.hasValidCsrfToken(response, () => {
                if (response.status === 'SUCCESS') {
                  let content = JSON.parse(this._jDS.decrypt(response.response));
                  this.bmaTblConfig.tblData = content.data;
                  this.btns.bma.addDisabled = false;
                }
                else {
                  this._appMessageService.showAppMessage(response.message, "error");
                }
                this._jDS.contorlLoading(false);
                this.bmaTblConfig.loading = false;
              });
            });
          },
          error: (error: any) => {
            this.bmaTblConfig.loading = false;
            this._jDS.contorlLoading(false);
            this._appMessageService.showAppMessage(error, "error");
          }
        });
      }
      catch (e) {
    console.error(e);

      }
    },
  }

  public onRowClick(ev: any) {
    try {
      ev.update = true;
      this.rowData = ev;
      this.populateFormFromTable();
    }
    catch (e) {
    console.error(e);
      this.forms.resetBmaForm();
      this.rowData = null;
      //this.indexRow = null;
    }
  }

  private populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.bma.updateDisabled = !data.update;
      this.btns.bma.deleteDisabled = !data.temp;
      this.tempDataRow = !data.temp;
      this.forms.bmaForm.patchValue(this.bmaFormManipulate.dbToForm(data));
    }
    catch (e) {
    console.error(e);

    }
  }

  public onAdd() {
    try {
      this._formService.hideFormMsg("bma-error-message");
      if (this.forms.bmaForm.valid) {
        let rowToBeAdded: { [key: string]: any } = this.bmaFormManipulate.formToDb(this.forms.bmaForm.value);
        rowToBeAdded.temp = true;
        this.bmaTblConfig.tblData = [rowToBeAdded, ...this.bmaTblConfig.tblData];
        this.forms.resetBmaForm();
        this.btns.bma.saveDisabled = false;
      }
      else {
        this.showErrorValidator.bma();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onDelete() {
    try {
      this.bmaTblConfig.tblData = this.bmaTblConfig.tblData.filter((d: any) => {
        return d !== this.rowData;
      });
      this.forms.resetBmaForm();
    }
    catch (e) {
    console.error(e);

    }
  }

  public onUpdate() {
    try {
      this._formService.hideFormMsg("bma-error-message");
      if (this.forms.bmaForm.valid) {
        let toBeUpdatedIndex = this.bmaTblConfig.tblData.indexOf(this.rowData);
        let bmaCdRowValue = this.rowData.BMA_CD;
        let rowToBeUpdated: { [key: string]: any } = this.bmaFormManipulate.formToDb(this.forms.bmaForm.value);
        if (this.rowData.temp) {
          rowToBeUpdated.temp = true;
        }
        else {
          rowToBeUpdated.onDbButUpdatedTemp = true;
          rowToBeUpdated.BMA_CD = bmaCdRowValue;
        }
        this.forms.resetBmaForm();
        this.bmaTblConfig.tblData[toBeUpdatedIndex] = rowToBeUpdated;
        this.btns.bma.saveDisabled = false;
        this.bmaTblConfig.tblData = [... this.bmaTblConfig.tblData];
      }
      else {
        this.showErrorValidator.bma();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onSave() {
    try {
      this.showForm = false;
      this._formService.showFormLoader(null, "bma-maintenance-loading", "Saving.<br>Please wait ...", null, null, true);
      let toBeSaved = this.bmaTblConfig.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true));
      if (toBeSaved.length > 0) {
        // toBeSaved.map((d: any) => {
        //   d.USER_ID = this.userIdLoggedIn;
        // });
        //? To Be Uncomment later if chronological is needed ?//
        // let toBeAdded = toBeSaved.filter((d: any) => d.temp).reverse();
        // let toBeUpdated = toBeSaved.filter((d: any) => d.onDbButUpdatedTemp);
        // toBeSaved = [...toBeAdded, ...toBeUpdated];
        this._jDS.contorlLoading(true);
        this._api.saveBma(toBeSaved).subscribe({
          next: (response: any) => {
            this._securityService.checkRequestKeyResponse(response, () => {
              this._securityService.hasValidCsrfToken(response, () => {
                this._jDS.contorlLoading(false);
                if (response.status === 'SUCCESS') this.onComplete.bma();
                else this.onComplete.bmaError();
              });
            });
          },
          error: (data: any) => {

            this._jDS.contorlLoading(false);
            this.onComplete.bmaError();
          },
        });
      }
      else
        this.onComplete.bma();
      // setTimeout(() => {
      //   this.onComplete.bma();
      // }, 3000);
    }
    catch (e) {
    console.error(e);

    }
  }

  private onComplete = {
    bma: () => {
      this.getData.bma();
      this.btns.bma.saveDisabled = true;
      this.showForm = true;
      this._formService.hideFormLoader("bma-maintenance-loading");
      this.openDialog('SUCCESS', 'BMA', 'saved');
    },
    bmaError: () => {
      this.showForm = true;
      this.btns.bma.saveDisabled = false;
      this.openDialog('FAILED', 'BMA', 'saving');
      this._formService.hideFormLoader("bma-maintenance-loading");
    },
  }

  openDialog(title: string, contentTitle: string, contentDetail: string) {
    try {
      this._matDialog.open(DialogMsgComponent, {
        disableClose: true,
        width: '512px',
        data: {
          title: title,
          content:
            title === 'SUCCESS'
              ? `${contentTitle} details successfully ${contentDetail}!`
              : `${contentTitle} details ${contentDetail} failed!`,
        },
      });
    }
    catch (e) {
    console.error(e);

    }
  }

  /* Validator Stuffs Down here */

  checkDuplicateBmaDesc(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.trim()?.toUpperCase();
      if (!value) return null;
      let hasDuplicate: boolean = this.bmaTblConfig.tblData.filter((d: any) => {
        if (!this.rowData && this.btns.bma.updateDisabled)
          return d.BMA_DESC.trim().toUpperCase() === value;
        else
          return this.rowData.BMA_DESC.trim().toUpperCase() !== value && d.BMA_DESC.trim().toUpperCase() === value;
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupeBmaDesc: true } : null;
    }
  }

  checkDuplicateBmCode(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.trim()?.toUpperCase();
      if (!value) return null;
      let hasDuplicate: boolean = this.bmaTblConfig.tblData.filter((d: any) => {
        const trimLeadingZeros = (str: string) => str?.replace(/^0+/, '');
        if (!this.rowData && this.btns.bma.updateDisabled)
          return trimLeadingZeros(d.BM_CODE)?.toUpperCase() === trimLeadingZeros(value);
        else
          return this.rowData.BM_CODE?.trim().toUpperCase() !== value && trimLeadingZeros(d.BM_CODE)?.trim().toUpperCase() === trimLeadingZeros(value);
      }).length > 0 ? true : false;
      console.log(hasDuplicate);
      return hasDuplicate ? { hasDupeBmCode: true } : null;
    }
  }

  public showErrorValidator = {
    bma: () => {
      try {
        Object.keys(this.forms.bmaForm.controls).forEach(key => {
          const controlErrors = this.forms.bmaForm.get(key)?.errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              if (keyError == 'hasDupeBmaDesc' && controlErrors[keyError] === true)
                this.errorMessage = "BMA already exists. Please add a unique BMA.";
              if (keyError == 'hasDupeBmCode' && controlErrors[keyError] === true)
                this.errorMessage = "BPIMS Code already exists. Please add a unique BPIMS Code.";
              else if (keyError == 'required' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
            });
          }
        });
        this._formService.showFormMsg("bma-error-message", this.errorMessage, "E");
      }
      catch (e) {
    console.error(e);

      }
    },
  }

}
