<section id="rep-sign-maint">    
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <!-- Container for Report Signatory Table using prime ng -->
        <div class = 'table-user'>
            <osp-table [tblConfig]="repSignTblConfig" (onRowClick)="onRowClick($event)"></osp-table>
        </div>
        <!-- Loading Animation -->
        <div id="rep-sign-maintenance-loading">
            <div class="form-loader"></div>
        </div>
        <div class="form-content" *ngIf="showForm">
            <form [formGroup]="forms.repSignForm" class="form">
                <div id="rep-sign-error-message"></div>
                <div class="form-container-grid">
                    <div class="field required">
                        <label>Report</label>
                    </div>
                    <div class="lov-search">
                        <input type="text" readonly formControlName="reportField">
                        <button type="button" class="btn-search btn" (click)="openLov['report']()" [disabled]='btns.repSign.reportDisabled'>
                            <mat-icon>
                                search
                            </mat-icon>
                        </button>
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag">
                            <option *ngFor="let activeType of selections.activeTypes" [value]="activeType.cd">
                                {{activeType.name}}
                            </option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>Effective Date From</label>
                    </div>
                    <div class="">
                        <input formControlName="effDateFrom" type="text" placeholder="MM/DD/YYYY" [matDatepicker]="effDateFromPicker" (keypress)="allowNumericAndSlashOnly($event)">
                        <mat-datepicker-toggle matSuffix [for]="effDateFromPicker"></mat-datepicker-toggle>
                        <mat-datepicker #effDateFromPicker></mat-datepicker>
                    </div>
                    <div class="field">
                        <label>Effective Date To</label>
                    </div>
                    <div class="">
                        <input formControlName="effDateTo" type="text" placeholder="MM/DD/YYYY" [matDatepicker]="effDateToPicker" (keypress)="allowNumericAndSlashOnly($event)">
                        <mat-datepicker-toggle matSuffix [for]="effDateToPicker"></mat-datepicker-toggle>
                        <mat-datepicker #effDateToPicker></mat-datepicker>
                    </div>

                    <div class="field required">
                        <label>Signatory Name</label>
                    </div>
                    <div class="">
                        <input formControlName="signatoryName" type="text" maxlength="50" (input)="autoUppercase($event)">
                    </div>
                    <div class="field">
                        <label>Designation</label>
                    </div>
                    <div class="">
                        <input formControlName="designation" type="text" maxlength="100">
                    </div>

                    <div class="field">
                        <label>Electronic Signature</label>
                    </div>
                    <div class="span">
                        <input formControlName="eSignature" type="text" maxlength="500">
                    </div>
                    
                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" type="text" maxlength="4000">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="userID">
                    </div>
                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUserUpdate">
                    </div>
                </div>
            </form>
            <div class="btn-row">
                <button *ngIf="btns.repSign.updateDisabled" class="btn btn2" type="button" (click)="onAdd()">ADD</button>
                <button *ngIf="!btns.repSign.updateDisabled" class="btn btn2" type="button" (click)="onUpdate()">UPDATE</button>
                <button class="btn btn2" type="button" [disabled]='btns.repSign.deleteDisabled' (click)="onDelete()"> DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-row">
                <button class="btn btn2" type="button" [disabled]='btns.repSign.saveDisabled' (click)="onSave()">SAVE</button>
            </div>
        </div>
    </div>
</section>