<div class="loading" *ngIf="!doneCheckingAuth">
    <div class="spinner">
        <div class=""></div>
        <div class=""></div>
    </div>
</div>
<ng-container *ngIf="doneCheckingAuth && !jsonDataService.onMaintenance">
    <app-message></app-message>
    <loading></loading>
    <message-box></message-box>
    <header></header>
    <div id="main-content">
        <div class="form-loader"></div>
        <router-outlet></router-outlet>
    </div>
    <footer></footer>
</ng-container>
<maintenance *ngIf="jsonDataService.onMaintenance"></maintenance>