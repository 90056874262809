import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, AbstractControl, ValidatorFn } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { CustomValidators } from 'src/app/utils/custom-validator';
import { ContractTypeLovDialogComponent } from '../../common/contract-type-lov/contract-type-lov.component';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { ViewPoliciesLovComponent } from '../../common/view-policies-lov/view-policies-lov.component';

@Component({
  selector: 'open-policy-sequence-no-maintenance',
  templateUrl: './open-policy-sequence-no-maintenance.component.html',
  styleUrls: ['./open-policy-sequence-no-maintenance.component.css']
})
export class OpenPolicySequenceNoMaintenanceComponent implements OnInit {

  addUpdateFlag: boolean = true;
  datePipe = new DatePipe('en-us');
  selectedRow: any;
  tempNewData: any = [];
  tempUpdateData: any = [];
  userId: string = this.userDetailService.userId;
  errorMessage: string = '';
  saveDisabler: boolean = true;
  deleteDisabler: boolean = true;
  formHider: boolean = false;
  moduleId: string = 'BMM178';
  moduleName = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId === 'BMM130' })[0].moduleDesc.toUpperCase();
  recordType = this.jsonDataService.data.refCds.filter((data: any) => data.rvDomain === 'POL_RECORD_TYPE');
  opStatCd = this.jsonDataService.data.refCds.filter((data: any) => data.rvDomain === 'BMM130.OP_STAT_CD');
  subPol: any;
  
  table: any = {
    cols: [
      {
        key: "OPEN_POLICY_ID",
        header: "Open Policy ID",
        dataType: "string"
      },
      {
        key: "BM_POL_SEQ_NO",
        header: "Policy Sequence",
        dataType: "string"
      },
      {
        key: "PAYT_REF_NO",
        header: "Payt. Ref. No.",
        dataType: "string"
      },
      {
        key: "EFF_DATE_FROM",
        header: "Effective Date",
        dataType: "date"
      },
      {
        key: "EFF_DATE_TO",
        header: "Expiry Date",
        dataType: "date"
      },
      {
        key: "OP_STAT_CD_DESC",
        header: "Status",
        dataType: "string"
      },
      {
        key: "OPEN_POLICY_HOLDER",
        header: "Policy Holder",
        dataType: "string"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  paramForm = this.fb.group({
    CONTRACT_TYPE_CD:   ['', [Validators.required]],
    POL_RECORD_TYPE:    ['A', [Validators.required]],
  });

  form = this.fb.group({
    OPEN_POLICY_ID      : [{value: '', disabled: true}],
    CONTRACT_TYPE_CD    : ['', [Validators.required]],
    BM_POL_SEQ_NO       : ['', {validators: [Validators.required, this.existingPolicySeq()], updateOn: 'blur'}],
    PAYT_REF_NO         : ['', {validators: [Validators.required, this.existingPaymentRefNo()], updateOn: 'blur'}],
    EFF_DATE_FROM       : ['', {validators: [Validators.required, this.effDatesValidator(), this.concurrentValidator()], updateOn: 'blur'}],
    EFF_DATE_TO         : ['', {validators: [Validators.required, this.effDatesValidator(), this.concurrentValidator()], updateOn: 'blur'}],
    OP_STAT_CD          : ['', [Validators.required]],
    OPEN_POLICY_HOLDER  : ['', [Validators.required, CustomValidators.requiredTrim]],
    REMARKS             : [''],
    USER_ID             : [{value: '', disabled: true}],
    LAST_UPDATE         : [{value: '', disabled: true}],
  });

  constructor(
    private fb: FormBuilder,
    private api: APICallService,
    private dialog: MatDialog,
    private jsonDataService: JsonDataService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    private formService: FormService,
    private securityService: SecurityService
  ) { }

  ngOnInit(): void {

  }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempNewData!='' || this.tempUpdateData!='') {
      return false;
    } else {
      return true;
    }
  }

  existingPolicySeq(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const dupe = this.table.tblData.filter((e: any) => +e.BM_POL_SEQ_NO === +control.value && this.selectedRow?.BM_POL_SEQ_NO != control.value);
      return dupe.length > 0 ? {"polSeqDupe": "Policy Sequence already exist."} : null;
    }
  }

  existingPaymentRefNo(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const dupe = this.table.tblData.filter((e: any) => +e.PAYT_REF_NO === +control.value && this.selectedRow?.PAYT_REF_NO != control.value);
      return dupe.length > 0 ? {"paymentRefDupe": "Payment Reference No. already exist."} : null;
    }
  }

  effDatesValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const effDateFrom = this.form?.get("EFF_DATE_FROM")?.value;
      const effDateTo = this.form?.get("EFF_DATE_TO")?.value;
      if(effDateFrom && effDateTo && effDateTo.isBefore(effDateFrom)) {
        return { "effDate": "Expiry Date must not be earlier than Effective Date." }
      }
      return null;
    }
  }

  concurrentValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const effDateFrom = this.form?.get("EFF_DATE_FROM")?.value;
      const effDateTo = this.form?.get("EFF_DATE_TO")?.value;
      const formData = this.paramForm?.getRawValue();
      let isConcurrent: boolean = false;
      if(formData?.CONTRACT_TYPE_CD && formData?.POL_RECORD_TYPE) {
        this.table.tblData.filter((e: any) => e.OP_STAT_CD === "O").map((a: any) => {
          const existEffDateFrom = moment(a?.EFF_DATE_FROM, "MM/DD/YYYY");
          const existEffDateTo = moment(a?.EFF_DATE_TO, "MM/DD/YYYY");

          // existing yung eff at expiry date dapat between na
          if(a?.EFF_DATE_FROM && a?.EFF_DATE_TO) {

            if(effDateFrom && effDateFrom.isBetween(existEffDateFrom, existEffDateTo) && !(effDateFrom.isSame(moment(this.selectedRow?.EFF_DATE_FROM, "MM/DD/YYYY")) && effDateTo.isSame(moment(this.selectedRow?.EFF_DATE_TO, "MM/DD/YYYY")))) {
              isConcurrent = true;
            }

            if(effDateFrom && effDateTo && existEffDateFrom.isBetween(effDateFrom, effDateTo) && !(effDateFrom.isSame(moment(this.selectedRow?.EFF_DATE_FROM, "MM/DD/YYYY")) && effDateTo.isSame(moment(this.selectedRow?.EFF_DATE_TO, "MM/DD/YYYY")))) {
              isConcurrent = true;
            }

            if(effDateTo && effDateTo.isBetween(existEffDateFrom, existEffDateTo) && !(effDateFrom.isSame(moment(this.selectedRow?.EFF_DATE_FROM, "MM/DD/YYYY")) && effDateTo.isSame(moment(this.selectedRow?.EFF_DATE_TO, "MM/DD/YYYY")))) {
              isConcurrent = true;
            }

            if(effDateFrom && effDateTo && existEffDateTo.isBetween(effDateFrom, effDateTo) && !(effDateFrom.isSame(moment(this.selectedRow?.EFF_DATE_FROM, "MM/DD/YYYY")) && effDateTo.isSame(moment(this.selectedRow?.EFF_DATE_TO, "MM/DD/YYYY")))) {
              isConcurrent = true;
            }
          }

          // same effDate
          if(effDateFrom && existEffDateFrom && effDateFrom.isSame(existEffDateFrom) && !effDateFrom.isSame(moment(this.selectedRow?.EFF_DATE_FROM, "MM/DD/YYYY"))) {
            isConcurrent = true;
          }

          // same expDate
          if(effDateTo && existEffDateTo && effDateTo.isSame(existEffDateFrom) && !effDateTo.isSame(moment(this.selectedRow?.EFF_DATE_TO, "MM/DD/YYYY"))) {
            isConcurrent = true;
          }
          
        });
      }
      if(isConcurrent) {
        return { "concurrent": "Unable to add or update record. Date of Effectivity or Date of Expiry is concurrent with another record. Please enter a different range of Date of Effectivity or Date of Expiry." }
      }
      return null;
    }
  }

  validationOnBtnClick() {
    try {
      this.form.get("BM_POL_SEQ_NO")?.updateValueAndValidity();
      this.form.get("PAYT_REF_NO")?.updateValueAndValidity();
      this.form.get("EFF_DATE_FROM")?.updateValueAndValidity();
      this.form.get("EFF_DATE_TO")?.updateValueAndValidity();
    } catch(e) {

    }
  }
  
  openContractDialog() {
    try {
      this.dialog.open(ContractTypeLovDialogComponent, {
        width: '512px',
        disableClose: true,
        data: {
          moduleId: this.moduleId,
          userId: this.userDetailService.userId,
        }
      }).afterClosed().subscribe(data => {
        if (data.content?.CONTRACT_TYPE_CD && data.content?.CONTRACT_TYPE_CD) {
          this.formClearer();
          this.saveDisabler = true;
          this.addUpdateFlag = true;
          this.tempNewData = [];
          this.tempUpdateData = [];
          this.deleteEnabler();
          this.paramForm.get("CONTRACT_TYPE_CD")?.setValue(data.content.CONTRACT_TYPE_CD + " - " + data.content.CONTRACT_TYPE_DESC);
          this.paramForm.get("CONTRACT_TYPE_CD")?.setValue(+data.content.CONTRACT_TYPE_CD, {emitModelToViewChange: false});
          this.form.get('CONTRACT_TYPE_CD')?.setValue(+data.content.CONTRACT_TYPE_CD);

          if(this.paramForm.get("POL_RECORD_TYPE")?.value && this.paramForm.get('CONTRACT_TYPE_CD')?.value) {
            this.getDbData();
          }
        }
      });
    } catch(e) {
      
    }
  }

  openViewPolicies() {
    try {
      this.dialog.open(ViewPoliciesLovComponent, {
        width: '736px',
        disableClose: true,
        data: {
          moduleId: this.moduleId,
          userId: this.userDetailService.userId,
          subPol: this.subPol,
          selectedRow: this.selectedRow
        }
      });
    } catch(e) {
      
    }
  }

  onRecordTypeChange(): void {
    try {
      this.formClearer();
      if(this.paramForm.get("POL_RECORD_TYPE")?.value && this.paramForm.get('CONTRACT_TYPE_CD')?.value) {
        this.saveDisabler = true;
        this.addUpdateFlag = true;
        this.tempNewData = [];
        this.tempUpdateData = [];
        this.deleteEnabler();
        this.getDbData();
      }
    } catch(e) {

    }
  }

  getDbData(): void {
    try {
      this.table.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.api.getOpenPolicySeqNo({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE"
      }).subscribe((data: any) => {
        this.securityService.checkRequestKeyResponse(data, () => {
          this.securityService.hasValidCsrfToken(data, () => {
            if (data.status === "SUCCESS") {
              data = JSON.parse(this.jsonDataService.decrypt(data.response));
              const pol = data?.data?.pol;
              this.subPol = data?.data?.subpol;
              const params = this.paramForm.getRawValue();
              if(params?.POL_RECORD_TYPE === 'A') {
                this.table.tblData = pol.filter((e: any) => e.CONTRACT_TYPE_CD === params?.CONTRACT_TYPE_CD).map((e: any) => {
                  return {...e, 
                    OP_STAT_CD_DESC: this.opStatCd.filter((f: any) => e.OP_STAT_CD === f.rvLowValue)[0]?.rvMeaning,
                    EFF_DATE_FROM: new Date(e.EFF_DATE_FROM),
                    EFF_DATE_TO: new Date(e.EFF_DATE_TO)
                  }
                });
              } else if(params?.POL_RECORD_TYPE === 'U') {
                this.table.tblData = pol.filter((e: any) => e.CONTRACT_TYPE_CD === params?.CONTRACT_TYPE_CD && this.subPol.filter((f: any) => f.OPEN_POLICY_ID === +e.OPEN_POLICY_ID).length === 0).map((e: any) => {
                  return {...e, 
                    OP_STAT_CD_DESC: this.opStatCd.filter((f: any) => e.OP_STAT_CD === f.rvLowValue)[0]?.rvMeaning,
                    EFF_DATE_FROM: new Date(e.EFF_DATE_FROM),
                    EFF_DATE_TO: new Date(e.EFF_DATE_TO)
                  }
                });
              } else if(params?.POL_RECORD_TYPE === 'X') {
                this.table.tblData = pol.filter((e: any) => e.CONTRACT_TYPE_CD === params?.CONTRACT_TYPE_CD && this.subPol.filter((f: any) => f.OPEN_POLICY_ID === +e.OPEN_POLICY_ID).length > 0).map((e: any) => {
                  return {...e, 
                    OP_STAT_CD_DESC: this.opStatCd.filter((f: any) => e.OP_STAT_CD === f.rvLowValue)[0]?.rvMeaning,
                    EFF_DATE_FROM: new Date(e.EFF_DATE_FROM),
                    EFF_DATE_TO: new Date(e.EFF_DATE_TO)
                  }
                });
              }
              this.table.loading = false;
              this.jsonDataService.contorlLoading(false);
            } else {
              this.jsonDataService.contorlLoading(false);
              this.table.loading = false;
              this.appMessageService.showAppMessage(data.message, "error");
            }
          });
        });
      });
    } catch(e) {
      
    }
  }

  onRowClick(ev: any): void {
    try {
      if(ev!=null) {
        this.selectedRow = ev;
        this.addUpdateFlag = false;
        this.formLoader();
        this.onUpdateDisabler();
      } else {
        this.selectedRow = '';
        this.addUpdateFlag = true;
        this.formClearer();
      }
      this.deleteEnabler();
    } catch(e) {
      
    }
  }

  onUpdateDisabler(): void {
    try {
      if(this.subPol.filter((e: any) => e.OPEN_POLICY_ID === +this.form.get("OPEN_POLICY_ID")?.value).length === 0) {
        this.form.get("BM_POL_SEQ_NO")?.enable();
        this.form.get("PAYT_REF_NO")?.enable();
        this.form.get("EFF_DATE_FROM")?.enable();
      } else {
        this.form.get("BM_POL_SEQ_NO")?.disable();
        this.form.get("PAYT_REF_NO")?.disable();
        this.form.get("EFF_DATE_FROM")?.disable();
      }
    } catch(e) {

    }
  }

  formLoader() {
    try {
      this.form.patchValue({
        OPEN_POLICY_ID      : this.selectedRow.OPEN_POLICY_ID,
        CONTRACT_TYPE_CD    : this.selectedRow.CONTRACT_TYPE_CD,
        BM_POL_SEQ_NO       : this.selectedRow.BM_POL_SEQ_NO,
        PAYT_REF_NO         : this.selectedRow.PAYT_REF_NO,
        EFF_DATE_FROM       : moment(this.selectedRow.EFF_DATE_FROM, "MM/DD/YYYY"),
        EFF_DATE_TO         : moment.isMoment(this.selectedRow.EFF_DATE_TO) ? this.selectedRow.EFF_DATE_TO : !this.selectedRow.EFF_DATE_TO ? "" : moment(this.selectedRow.EFF_DATE_TO, "MM/DD/YYYY"),
        OP_STAT_CD          : this.selectedRow.OP_STAT_CD,
        OPEN_POLICY_HOLDER  : this.selectedRow.OPEN_POLICY_HOLDER,
        REMARKS             : this.selectedRow.REMARKS,
        USER_ID             : this.selectedRow.USER_ID,
        LAST_UPDATE         : this.selectedRow.LAST_UPDATE,
      });
    } catch(e) {
      
    }
  }
  
  formClearer() {
    try {
      this.formService.hideFormMsg("open-policy-sequence-no-error-message");
      this.form.get("BM_POL_SEQ_NO")?.enable();
      this.form.get("PAYT_REF_NO")?.enable();
      this.form.get("EFF_DATE_FROM")?.enable();
      this.form.patchValue({
        OPEN_POLICY_ID      : "",
        CONTRACT_TYPE_CD    : this.paramForm.get("CONTRACT_TYPE_CD")?.value,
        BM_POL_SEQ_NO       : "",
        PAYT_REF_NO         : "",
        EFF_DATE_FROM       : "",
        EFF_DATE_TO         : "",
        OP_STAT_CD          : "",
        OPEN_POLICY_HOLDER  : "",
        REMARKS             : "",
        USER_ID             : "",
        LAST_UPDATE         : "",
      });
      this.selectedRow = [];
    } catch(e) {
      
    }
  }

  deleteEnabler() {
    try {
      let tempDeleteFlag = this.tempNewData.filter((e: any) => {
        return e.CONTRACT_TYPE_CD == this.selectedRow.CONTRACT_TYPE_CD &&
               e.BM_POL_SEQ_NO  == this.selectedRow.BM_POL_SEQ_NO &&
               e.PAYT_REF_NO == this.selectedRow.PAYT_REF_NO
      });
      if(tempDeleteFlag=='' || tempDeleteFlag==null) {
        this.deleteDisabler = true;
      } else {
        this.deleteDisabler = false;
      }
    } catch(e) {
      
    }
  }

  addRow(): void {
    try {
      this.validationOnBtnClick();
      if(this.form.valid) {
        this.formService.hideFormMsg("open-policy-sequence-no-error-message");
        this.tableInserter();
        this.tempAddInserter();
        this.formClearer();
        this.saveDisabler = false;
      } else {
        this.errorMessageSetter();
      }
    } catch(e) {
      
    }
  }

  tableInserter(): void {
    try {
      const values = this.form.getRawValue();
      this.table.tblData.unshift({
        OPEN_POLICY_ID      : values?.OPEN_POLICY_ID,
        CONTRACT_TYPE_CD    : values?.CONTRACT_TYPE_CD,
        BM_POL_SEQ_NO       : values?.BM_POL_SEQ_NO,
        PAYT_REF_NO         : values?.PAYT_REF_NO,
        EFF_DATE_FROM       : new Date(values?.EFF_DATE_FROM),
        EFF_DATE_TO         : moment.isMoment(values?.EFF_DATE_TO) ? new Date(values?.EFF_DATE_TO) : "",
        OP_STAT_CD          : values?.OP_STAT_CD,
        OP_STAT_CD_DESC     : this.opStatCd.filter((f: any) => values?.OP_STAT_CD === f.rvLowValue)[0]?.rvMeaning,
        OPEN_POLICY_HOLDER  : values?.OPEN_POLICY_HOLDER,
        REMARKS             : values?.REMARKS,
      });
      this.table.tblData = [...this.table.tblData];
    } catch(e) {
      
    }
  }

  tempAddInserter(): void {
    try {
      this.tempNewData.push(this.formValues());
    } catch(e) {
      
    }
  }

  formValues(): any {
    try {
      const values = this.form.getRawValue();
      return {
        OPEN_POLICY_ID      : values?.OPEN_POLICY_ID,
        CONTRACT_TYPE_CD    : values?.CONTRACT_TYPE_CD,
        BM_POL_SEQ_NO       : values?.BM_POL_SEQ_NO,
        PAYT_REF_NO         : values?.PAYT_REF_NO,
        EFF_DATE_FROM       : values?.EFF_DATE_FROM.format("YYYY-MM-DD"),
        EFF_DATE_TO         : moment.isMoment(values?.EFF_DATE_TO) ? values?.EFF_DATE_TO.format("YYYY-MM-DD") : "",
        OP_STAT_CD          : values?.OP_STAT_CD,
        OP_STAT_CD_DESC     : this.opStatCd.filter((f: any) => values?.OP_STAT_CD === f.rvLowValue)[0]?.rvMeaning,
        OPEN_POLICY_HOLDER  : values?.OPEN_POLICY_HOLDER,
        REMARKS             : values?.REMARKS,
        USER_ID             : this.userId
      }
    } catch(e) {
      
    }
  }

  errorMessageSetter() {
    try {
      if(this.form.invalid) {
        for(const field in this.form.controls) {
          const controlName = this.form.get(field);
          if(controlName?.errors?.required) {
            this.errorMessage = 'There are missing information. Please provide necessary information needed.';
          }
          if(controlName?.errors?.effDate) {
            this.errorMessage = controlName?.errors?.effDate;
          }
          if(controlName?.errors?.concurrent) {
            this.errorMessage = controlName?.errors?.concurrent;
          }
          if(controlName?.errors?.polSeqDupe) {
            this.errorMessage = controlName?.errors?.polSeqDupe;
          }
          if(controlName?.errors?.paymentRefDupe) {
            this.errorMessage = controlName?.errors?.paymentRefDupe;
          }
          this.formService.showFormMsg("open-policy-sequence-no-error-message", this.errorMessage, "E");
        }
      }
    } catch(e) {
      
    }
  }

  onUpdate(): void {
    try {
      this.validationOnBtnClick();
      if(this.form.valid) {
        this.updateTable();
        if(this.deleteDisabler) {
          this.updateDbData();
        } else {
          this.updateNewData();
        }
        this.selectedRow = '';
        this.addUpdateFlag = true;
        this.formClearer();
        this.saveDisabler = false;
        this.deleteEnabler();
        this.formService.hideFormMsg('open-policy-sequence-no-error-message');
      } else {
        this.errorMessageSetter();
      }
    } catch(e) {
      
    }
  }

  updateTable() {
    try {
      const values = this.form.getRawValue();
      let updateIndex = this.table.tblData.indexOf(this.selectedRow);
      this.table.tblData[updateIndex] = {
        OPEN_POLICY_ID      : values?.OPEN_POLICY_ID,
        CONTRACT_TYPE_CD    : values?.CONTRACT_TYPE_CD,
        BM_POL_SEQ_NO       : values?.BM_POL_SEQ_NO,
        PAYT_REF_NO         : values?.PAYT_REF_NO,
        EFF_DATE_FROM       : new Date(values?.EFF_DATE_FROM),
        EFF_DATE_TO         : moment.isMoment(values?.EFF_DATE_TO) ? new Date(values?.EFF_DATE_TO) : "",
        OP_STAT_CD          : values?.OP_STAT_CD,
        OP_STAT_CD_DESC     : this.opStatCd.filter((f: any) => values?.OP_STAT_CD === f.rvLowValue)[0]?.rvMeaning,
        OPEN_POLICY_HOLDER  : values?.OPEN_POLICY_HOLDER,
        REMARKS             : values?.REMARKS,
        USER_ID             : this.userDetailService.userId,
      };
      this.table.tblData = [...this.table.tblData];
    } catch(e) {
      
    }
  }

  updateDbData() {
    try {
      let tempDbData = this.tempUpdateData.find(
        (data: { CONTRACT_TYPE_CD: any, BM_POL_SEQ_NO: any, PAYT_REF_NO: any }) => data.CONTRACT_TYPE_CD == this.selectedRow.CONTRACT_TYPE_CD &&
                                                                                   data.BM_POL_SEQ_NO == this.selectedRow.BM_POL_SEQ_NO &&
                                                                                   data.PAYT_REF_NO == this.selectedRow.PAYT_REF_NO
      );
      if((this.tempUpdateData == '' || this.tempUpdateData == null) || (tempDbData=='' || tempDbData==null)) {
        this.tempUpdateData.push(this.formValues());
      } else {
        tempDbData.OPEN_POLICY_ID      = this.form.get("OPEN_POLICY_ID")?.value;
        tempDbData.CONTRACT_TYPE_CD    = this.form.get("CONTRACT_TYPE_CD")?.value;
        tempDbData.BM_POL_SEQ_NO       = this.form.get("BM_POL_SEQ_NO")?.value;
        tempDbData.PAYT_REF_NO         = this.form.get("PAYT_REF_NO")?.value;
        tempDbData.EFF_DATE_FROM       = this.form.get("EFF_DATE_FROM")?.value.format("YYYY-MM-DD");
        tempDbData.EFF_DATE_TO         = moment.isMoment(this.form.get("EFF_DATE_TO")?.value) ? this.form.get("EFF_DATE_TO")?.value.format("YYYY-MM-DD") : "";
        tempDbData.OP_STAT_CD          = this.form.get("OP_STAT_CD")?.value;
        tempDbData.OP_STAT_CD_DESC     = this.opStatCd.filter((f: any) => this.form.get("OP_STAT_CD")?.value === f.rvLowValue)[0]?.rvMeaning;
        tempDbData.OPEN_POLICY_HOLDER  = this.form.get("OPEN_POLICY_HOLDER")?.value;
        tempDbData.REMARKS             = this.form.get("REMARKS")?.value;
        tempDbData.USER_ID             = this.userId;
      }
    } catch(e) {
      
    }
  }

  updateNewData() {
    try {
      let tempNewConfigdata = this.tempNewData.find(
        (data: { CONTRACT_TYPE_CD: any, BM_POL_SEQ_NO: any, PAYT_REF_NO: any }) => data.CONTRACT_TYPE_CD == this.selectedRow.CONTRACT_TYPE_CD &&
                                                                                   data.BM_POL_SEQ_NO == this.selectedRow.BM_POL_SEQ_NO &&
                                                                                   data.PAYT_REF_NO == this.selectedRow.PAYT_REF_NO
      );
      tempNewConfigdata.OPEN_POLICY_ID      = this.form.get("OPEN_POLICY_ID")?.value;
      tempNewConfigdata.CONTRACT_TYPE_CD    = this.form.get("CONTRACT_TYPE_CD")?.value;
      tempNewConfigdata.BM_POL_SEQ_NO       = this.form.get("BM_POL_SEQ_NO")?.value;
      tempNewConfigdata.PAYT_REF_NO         = this.form.get("PAYT_REF_NO")?.value;
      tempNewConfigdata.EFF_DATE_FROM       = this.form.get("EFF_DATE_FROM")?.value.format("YYYY-MM-DD");
      tempNewConfigdata.EFF_DATE_TO         = moment.isMoment(this.form.get("EFF_DATE_TO")?.value) ? this.form.get("EFF_DATE_TO")?.value.format("YYYY-MM-DD") : "";
      tempNewConfigdata.OP_STAT_CD          = this.form.get("OP_STAT_CD")?.value;
      tempNewConfigdata.OP_STAT_CD_DESC     = this.opStatCd.filter((f: any) => this.form.get("OP_STAT_CD")?.value === f.rvLowValue)[0]?.rvMeaning;
      tempNewConfigdata.OPEN_POLICY_HOLDER  = this.form.get("OPEN_POLICY_HOLDER")?.value;
      tempNewConfigdata.REMARKS             = this.form.get("REMARKS")?.value;
      tempNewConfigdata.USER_ID             = this.userId;
    } catch(e) {
      
    }
  }

  deleteRow(): void {
    try {
      let clickedData = this.selectedRow;
      this.table.tblData = this.table.tblData.filter((e: any) => e != clickedData);
      this.tempNewData = this.tempNewData.filter((e: any) => e.BM_POL_SEQ_NO != clickedData.BM_POL_SEQ_NO &&
                                                             e.CONTRACT_TYPE_CD != clickedData.CONTRACT_TYPE_CD &&
                                                             e.PAYT_REF_NO != clickedData.PAYT_REF_NO);
      this.selectedRow = '';
      this.formClearer();
      this.deleteDisabler = true;
      this.addUpdateFlag = true;
      if((this.tempNewData=='' || this.tempNewData==null) && (this.tempUpdateData=='' || this.tempUpdateData==null)) {
        this.saveDisabler = true;
      }
    } catch(e) {
      
    }
  }

  onSave(): void {
    try {
      this.formHider = true;
      this.jsonDataService.contorlLoading(true);
      this.api.saveOpenPolicySeqNo(this.tempNewData).subscribe({
        next: (data: any) => {
          this.securityService.checkRequestKeyResponse(data, () => {
            this.securityService.hasValidCsrfToken(data, () => {
              if(data.status == 'SUCCESS') {
                this.api.saveOpenPolicySeqNo(this.tempUpdateData).subscribe({
                  next: (res: any) => {
                    this.securityService.checkRequestKeyResponse(res, () => {
                      this.securityService.hasValidCsrfToken(res, () => {
                        if(res.status == 'SUCCESS') {
                          this.onComplete();
                        } else {
                          this.onFail();
                        }
                      });
                    });
                  },
                  error: () => {
                    this.onFail();
                  }
                });
              } else {
                this.onFail();
              }
            });
          });
        },
        error: () => {
          this.onFail();
        }
      });
    } catch(e) {
      
    }
  }
  
  onComplete() {
    try {
      this.formHider = false;
      this.tempNewData = [];
      this.tempUpdateData = [];
      this.saveDisabler = true;
      this.deleteEnabler();
      this.formClearer();
      this.getDbData();
      this.formService.hideFormMsg("open-policy-sequence-no-error-message");
      this.openSaveDialog();
    } catch(e) {
      
    }
  }

  onFail() {
    try {
      this.formService.hideFormLoader("client-group-per-user-type-form");
      this.formHider = false;
      this.tempNewData = [];
      this.tempUpdateData = [];
      this.deleteEnabler();
      this.formClearer();
      this.getDbData();
      this.formService.hideFormMsg("open-policy-sequence-no-error-message");
      this.openErrorDialog("Saving Client Group Per User Type Failed");
    } catch(e) {
      
    }
  }

  openErrorDialog(message: string) {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'ERROR',
          content: message,
        }
      });
    } catch(e) {
      
    }
  }

  openSaveDialog() {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'SUCCESS',
          content: 'Open Policy Sequence No. Details Successfully Saved!',
        }
      });
    } catch(e) {
      
    }
  }
}
