import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { APICallService } from 'src/app/services/api-call.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

@Component({
	selector: 'cyber-prem-freq-lov',
	templateUrl: './cyber-prem-freq-lov.component.html',
	styleUrls: ['./cyber-prem-freq-lov.component.css']
})
export class CyberPremiumFrequencyLov implements OnInit {
	private selectedData: any;
	public tableSelected: any;
	public titleLov: string = "Available Frequencies";

  moduleId: string = 'BMM194';

  cyberFreqTable: any = {
    cols: [
      {
          key: "FREQ_CD",
          header: "Code",
          dataType: "number",
          width: '15%'
      },
      {
          key: "FREQ_DESC",
          header: "Frequency",
          dataType: "string",
          width: '84%'
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  };

  constructor(
    private dialogRef: MatDialogRef<CyberPremiumFrequencyLov>,
    private _api: APICallService,
    private securityService: SecurityService,
    private jsonDataService: JsonDataService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    @Inject(MAT_DIALOG_DATA) public fromMainData: any
  ) {
    try {
      this.tableSelected = this.cyberFreqTable;
      this.getCyberFrequencies();
    } catch (e) {
      console.error(e);
    }
  }

  ngOnInit(): void {
  }

  onRowClick(ev: any) {
    try {
      this.selectedData = ev;
    } catch (e) {
      console.error(e);
    }
  }

  getCyberFrequencies() {
    try {
      this.cyberFreqTable.loading = true;
      this.jsonDataService.contorlLoading(true);
      this._api.getAllCyberPremiumAvailableFrequencies({
        moduleId: this.fromMainData.moduleId,
        planCd: this.fromMainData.planCd,
        type: "LOV"
      }).subscribe({
        next: (response: any) => {
          this.securityService.checkRequestKeyResponse(response, () => {
            this.securityService.hasValidCsrfToken(response, () => {
              let resContent = JSON.parse(this.jsonDataService.decrypt(response.response));
              this.jsonDataService.contorlLoading(false);
              //  TODO [WIP] highlight selected data on update
              // this.tableSelected.tblData = resContent.filter((d: any) => (d.LINE_CD === this.fromMainData.lineCd && d.SUBLINE_CD === this.fromMainData.sublineCd && d.ACTIVE_TAG === 'A' && !((this.fromMainData.planCdExclude ?? []).includes(+d.PLAN_CD))));
              // if (this.fromMainData?.lpad) {
              //   this.tableSelected.tblData = this.tableSelected.tblData.map((c: any) => { return { ...c, PLAN_CD: parseInt(c.PLAN_CD)?.toString().padStart(this.fromMainData?.lpad, "0") } })
              // }
              this.cyberFreqTable.tblData = resContent
              this.cyberFreqTable.loading = false;
            });
          });
        },
        error: (e: any) => {
          this.cyberFreqTable.loading = false;
          this.jsonDataService.contorlLoading(false);
          this.appMessageService.showAppMessage(e, "error");
        }
      });
    } catch (e) {
      console.error(e);
      this.cyberFreqTable.loading = false;
      this.jsonDataService.contorlLoading(false);
    }
  }

  onClose(str: string) {
    try {
      if (this.selectedData == '' || this.selectedData == null) {
        this.dialogRef.close({ content: '', button: str });
      } else {
        this.dialogRef.close({ content: this.selectedData, button: str });
      }
    } catch (e) {
      console.error(e);
    }
  }
}
