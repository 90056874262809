<section id="bfb-std-rates">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{this.moduleName}}</p>
        </div>
        
        <div class = 'table-line'>
            <osp-table [tblConfig]="premiumsConfig" (onRowClick)="onRowClick($event)"></osp-table>
        </div>
        
        <div class="form-content">
            <form [formGroup]="paramForm" class="form">
                <div class="form-container-grid top-form form-container-change-grid">
                    <div class="field required">
                        <label>Plan</label>
                    </div>
                    <div class="lov-search">
                        <input readonly [(ngModel)]="dPlan" [ngModelOptions]="{standalone: true}">
                        <button matSuffix class="btn-search btn" type="button" (click)="openPlanDialog()" [disabled]="planDisabled"><mat-icon>search</mat-icon></button>
                    </div>

                    <div class="field required">
                        <label>Frequency</label>
                    </div>
                    <div class="lov-search">
                        <input readonly [(ngModel)]="dFrequency" [ngModelOptions]="{standalone: true}">
                        <button matSuffix class="btn-search btn" type="button" (click)="openPlanFrequenciesDialog()" [disabled]="freqDisabled"><mat-icon>search</mat-icon></button>
                    </div>
                </div>
            </form>
        </div>

        <!-- Loading Animation -->
        <div id="generate-line-form">
            <div class="form-loader"></div>
        </div>

        <!-- Container for Line Fields -->
        <div class="form-content">
            <form [formGroup]="paramForm" class="form" *ngIf="noLoadingInProcess">
                <div id="bfb-standard-rates-error-message"></div>

                <div class="form-container-grid">
                    <div class="field required">
                        <label>Premium Description</label>
                    </div>
                    <div class="">
                        <input formControlName="premDesc" type="text">
                    </div>

                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag">
                            <option value="Y" selected>Yes</option>
                            <option value="N">No</option>
                        </select>
                    </div>
                </div>

                <div class="form-container-grid">
                    <div class="field required">
                        <label>Premium Amount</label>
                    </div>
                    <div class="">
                        <input
                            formControlName="premAmt"
                            type="text"
                            (keypress)="allowNumericDigitsOnly($event)"
                        >
                    </div>

                    <div class="field required">
                        <label>Document Stamp Tax</label>
                    </div>
                    <div class="">
                        <input
                            formControlName="dstAmt"
                            type="text"
                            (keypress)="allowNumericDigitsOnly($event)"
                        >
                    </div>
                </div>

                <div class="form-container-grid">
                    <div class="field required">
                        <label>Coverage Per Incident</label>
                    </div>
                    <div class="">
                        <input
                            formControlName="covPerInc"
                            type="text"
                            (keypress)="allowNumericDigitsOnly($event)"
                        >
                    </div>

                    <div class="field required">
                        <label>Value Added Tax (VAT)</label>
                    </div>
                    <div class="">
                        <input
                            formControlName="vatAmt"
                            type="text"
                            (keypress)="allowNumericDigitsOnly($event)"
                        >
                    </div>
                </div>

                <div class="form-container-grid">
                    <div class="field required">
                        <label>Maximum Coverage</label>
                    </div>
                    <div class="">
                        <input
                            formControlName="maxCov"
                            type="text"
                            (keypress)="allowNumericDigitsOnly($event)"
                        >
                    </div>

                    <div class="field required">
                        <label>Local Government Tax</label>
                    </div>
                    <div class="">
                        <input
                            formControlName="lgtAmt"
                            type="text"
                            (keypress)="allowNumericDigitsOnly($event)"
                        >
                    </div>
                </div>
                
                <div class="form-container-grid">
                    <div class="field required">
                        <label>Total Premium</label>
                    </div>
                    <div class="">
                        <input
                            formControlName="totPrem"
                            type="text"
                            (keypress)="allowNumericDigitsOnly($event)"
                        >
                    </div>
                </div>

                <div class="form-container-grid">
                    <div class="field">
                        <label>BM Code</label>
                    </div>
                    <div class="">
                        <input formControlName="bmCode" type="text">
                    </div>

                    <div class="field">
                        <label>BM Short Description</label>
                    </div>
                    <div class="">
                        <input formControlName="bmShortDesc" type="text">
                    </div>
                </div>

                <div class="form-container-grid">
                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" type="text">
                    </div>
                </div>

                <div class="form-container-grid">
                    <div class="field">
                        <label>User ID</label>
                    </div>
                    <div class="">
                        <input readonly formControlName="userId" type="text">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input readonly formControlName="lastUpdate" type="text">
                    </div>
                </div>
                <div class='btn-row'>
                    <button type="button" id='btn-add' *ngIf="!isLineSelected" title='Register a new line' class="btn btn2" value='ADD' type="submit" (click)="insertLine()">ADD</button>
                    <button type="button" id='btn-update' *ngIf="isLineSelected" title='Update a line' class="btn btn2" value='UPDATE' type="submit" (click)="updateLine()">UPDATE</button>
                    <button type="button" id='btn-delete' title='Remove a line' class="btn btn2" value='DELETE' type="submit" [disabled]='deleteDisabled' (click)="deleteLine()">DELETE</button>
                </div> 

                <div class="mtn-title"></div>

                <div class='btn-row'>
                    <button type="button" id='btn-save' title='Save changes on line details' class="btn btn2" value='SAVE' type="submit" (click)="toSave()" [disabled]='saveDisabled'>SAVE</button>
                </div>
            </form>
        </div>
    </div>
</section>