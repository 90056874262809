import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { CustomValidators } from 'src/app/utils/custom-validator';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';

@Component({
  selector: 'mv-prem-type-maintenance',
  templateUrl: './mv-prem-type-maintenance.component.html',
  styleUrls: ['./mv-prem-type-maintenance.component.css']
})
export class MvPremTypeMaintenanceComponent implements OnInit {


  addUpdateFlag: boolean = true;
  datePipe = new DatePipe('en-us');
  selectedRow: any;
  tempNewData: any = [];
  tempUpdateData: any = [];
  userId: string = this.userDetailService.userId;
  errorMessage: string = '';
  saveDisabler: boolean = true;
  deleteDisabler: boolean = true;
  formHider: boolean = false;
  moduleId: string = 'BMM091';
  moduleName = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId === 'BMM091' })[0].moduleDesc.toUpperCase();
  
  mvPremTypeTable: any = {
    cols: [
      {
        key: "MV_PREM_TYPE",
        header: "MV Premium Type Code",
        dataType: "string"
      },
      {
        key: "MV_PREM_TYPE_DESC",
        header: "MV Premium Type Description",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  mvPremTypeForm = this.fb.group({
    MV_PREM_TYPE:       [{value: '', disabled: true}],
    ACTIVE_TAG:         ['A', [Validators.required]],
    MV_PREM_TYPE_DESC:  ['', [Validators.required, CustomValidators.requiredTrim]],
    NO_OF_YEARS:        ['', [Validators.required]],
    CURRENCY_CD:        ['', [Validators.required, CustomValidators.requiredTrim]],
    GROSS_AMT:          ['', [Validators.required, this.checkDecimalsExceeded(10, 2)]],
    REMARKS:            [''],
    LAST_USER:          [{value: '', disabled: true}],
    LAST_USER_UPDATE:   [{value: '', disabled: true}],
  });

  constructor(
    private fb: FormBuilder,
    private api: APICallService,
    private dialog: MatDialog,
    private jsonDataService: JsonDataService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    private formService: FormService,
    private securityService: SecurityService
  ) { }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempNewData!='' || this.tempUpdateData!='') {
      return false;
    } else {
      return true;
    }
  }

  duplicateShortDesc(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const selectedRow = this.selectedRow;
      let shortDescDuplicate = this.mvPremTypeTable.tblData.filter(function(e: any) {
        return e.MV_PREM_TYPE_DESC.trim().toLowerCase()==control.value.trim().toLowerCase() && control.value!=selectedRow.MV_PREM_TYPE_DESC;
      });
      if(shortDescDuplicate!='') {
        return { "shortDescDuplicate": "MV Premium Type already exists. Please add a unique MV Premium Type." }
      }
      return null;
    }
  }

  private checkDecimalsExceeded(digits: number, decimals: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value && parseFloat(this.formService.setToNumberWithDecimal(control.value));
      if (!value) return null;
      let isExceeded: boolean = value > +"".padEnd(digits, '9') + +('.' + ''.padEnd(decimals, '9'))
      return isExceeded ? { hasDecimalsExceeded: true } : null;
    }
  }

  grossAmountValidator(ev: any): void {
    try {
      const grossAmt = this.mvPremTypeForm.get("GROSS_AMT")?.value;
      if(grossAmt === 0) {
        this.mvPremTypeForm.get("GROSS_AMT")?.setValue(Number(grossAmt).toFixed(2));
      } else if(grossAmt > 0) {
        this.mvPremTypeForm.get("GROSS_AMT")?.setValue(Number(grossAmt).toFixed(2));
      } else {
        this.mvPremTypeForm.get("GROSS_AMT")?.setValue("");
      }
    } catch (e) {
    console.error(e);
      
    }
  }

  ngOnInit(): void {
    this.getMvPremType();
  }

  getMvPremType(): void {
    try {
      this.mvPremTypeTable.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.api.getMvPremType({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE"
      }).subscribe((data: any) => {
        this.securityService.checkRequestKeyResponse(data, () => {
          this.securityService.hasValidCsrfToken(data, () => {
            if (data.status === "SUCCESS") {
              data = JSON.parse(this.jsonDataService.decrypt(data.response));
              this.mvPremTypeTable.loading = false;
              this.jsonDataService.contorlLoading(false);
              this.mvPremTypeTable.tblData = data;
            } else {
                this.jsonDataService.contorlLoading(false);
                this.mvPremTypeTable.loading = false;
                this.appMessageService.showAppMessage(data.message, "error");
            }
          });
        });
      });
    } catch(e) {
      
    }
  }

  onRowClick(ev: any): void {
    try {
      if(ev!=null) {
        this.selectedRow = ev;
        this.addUpdateFlag = false;
        this.formLoader();
      } else {
        this.selectedRow = '';
        this.addUpdateFlag = true;
        this.formClearer();
      }
      this.deleteEnabler();
    } catch(e) {
      
    }
  }

  formLoader() {
    try {
      this.mvPremTypeForm.patchValue({
        MV_PREM_TYPE:       this.selectedRow.MV_PREM_TYPE,
        ACTIVE_TAG:         this.selectedRow.ACTIVE_TAG,      
        MV_PREM_TYPE_DESC:  this.selectedRow.MV_PREM_TYPE_DESC,
        NO_OF_YEARS:        this.selectedRow.NO_OF_YEARS,     
        CURRENCY_CD:        this.selectedRow.CURRENCY_CD,    
        GROSS_AMT:          Number(this.selectedRow.GROSS_AMT).toFixed(2),       
        REMARKS:            this.selectedRow.REMARKS,         
        LAST_USER:          this.selectedRow.LAST_USER,       
        LAST_USER_UPDATE:   this.selectedRow.LAST_USER_UPDATE,     
      });
    } catch(e) {
      
    }
  }
  
  formClearer() {
    try {
      this.mvPremTypeForm.get("TARIFF_CD")?.enable();
      this.mvPremTypeForm.patchValue({
        MV_PREM_TYPE:       "",
        ACTIVE_TAG:         "A",      
        MV_PREM_TYPE_DESC:  "",
        NO_OF_YEARS:        "",     
        CURRENCY_CD:        "",    
        GROSS_AMT:          "",       
        REMARKS:            "",         
        LAST_USER:          "",       
        LAST_USER_UPDATE:   "",     
      });
    } catch(e) {
      
    }
  }

  deleteEnabler() {
    try {
      let tempDeleteFlag = this.tempNewData.filter((e: any) => {
        return e.MV_PREM_TYPE_DESC == this.selectedRow.MV_PREM_TYPE_DESC
      });
      if(tempDeleteFlag=='' || tempDeleteFlag==null) {
        this.deleteDisabler = true;
      } else {
        this.deleteDisabler = false;
      }
    } catch(e) {
      
    }
  }

  addRow(): void {
    try {
      if(this.mvPremTypeForm.valid) {
        this.formService.hideFormMsg("mv-prem-type-error-message");
        this.tableInserter();
        this.tempAddInserter();
        this.formClearer();
        this.saveDisabler = false;
      } else {
        this.errorMessageSetter();
      }
    } catch(e) {
      
    }
  }

  tableInserter(): void {
    try {
      this.mvPremTypeTable.tblData.unshift(this.formValues());
      this.mvPremTypeTable.tblData = [...this.mvPremTypeTable.tblData];
    } catch(e) {
      
    }
  }

  tempAddInserter(): void {
    try {
      this.tempNewData.push(this.formValues());
    } catch(e) {
      
    }
  }

  formValues(): any {
    try {
      return {
        MV_PREM_TYPE:       isNaN(parseInt(this.mvPremTypeForm.get('MV_PREM_TYPE')?.value)) ? "" : parseInt(this.mvPremTypeForm.get('MV_PREM_TYPE')?.value),
        ACTIVE_TAG:         this.mvPremTypeForm.get('ACTIVE_TAG')?.value,      
        MV_PREM_TYPE_DESC:  this.mvPremTypeForm.get('MV_PREM_TYPE_DESC')?.value,
        NO_OF_YEARS:        this.mvPremTypeForm.get('NO_OF_YEARS')?.value,     
        CURRENCY_CD:        this.mvPremTypeForm.get('CURRENCY_CD')?.value,    
        GROSS_AMT:          this.mvPremTypeForm.get('GROSS_AMT')?.value,       
        REMARKS:            this.mvPremTypeForm.get('REMARKS')?.value,         
        LAST_USER:          this.userId,       
        LAST_USER_UPDATE:   this.mvPremTypeForm.get('LAST_USER_UPDATE')?.value,   
      }
    } catch(e) {
      
    }
  }

  errorMessageSetter() {
    try {
      if(this.mvPremTypeForm.invalid) {
        for(const field in this.mvPremTypeForm.controls) {
          const controlName = this.mvPremTypeForm.get(field);
          if(controlName?.errors?.required) {
            this.errorMessage = 'There are missing information. Please provide necessary information needed.';
          }
          if(controlName?.errors?.hasDecimalsExceeded) {
            this.errorMessage = 'Invalid value in Gross Amount. The value entered exceeded the maximum limit.';
          }
          // if(controlName?.errors?.duplicateShortDesc) {
          //   this.errorMessage = controlName?.errors?.duplicateShortDesc;
          // }
          this.formService.showFormMsg("mv-prem-type-error-message", this.errorMessage, "E");
        }
      }
    } catch(e) {
      
    }
  }

  onUpdate(): void {
    try {
      if(this.mvPremTypeForm.valid) {
        this.updateTable();
        if(this.deleteDisabler) {
          this.updateDbData();
        } else {
          this.updateNewData();
        }
        this.selectedRow = '';
        this.addUpdateFlag = true;
        this.formClearer();
        this.saveDisabler = false;
        this.deleteEnabler();
        this.formService.hideFormMsg('mv-prem-type-error-message');
      } else {
        this.errorMessageSetter();
      }
    } catch(e) {
      
    }
  }

  updateTable() {
    try {
      let updateIndex = this.mvPremTypeTable.tblData.indexOf(this.selectedRow);
      this.mvPremTypeTable.tblData[updateIndex] = this.formValues();
      this.mvPremTypeTable.tblData[updateIndex].MV_PREM_TYPE = this.mvPremTypeTable.tblData[updateIndex].MV_PREM_TYPE.toString().padStart(2, 0);
      this.mvPremTypeTable.tblData = [...this.mvPremTypeTable.tblData];
    } catch(e) {
      
    }
  }

  updateDbData() {
    try {
      let tempDbData = this.tempUpdateData.find(
        (mvPremType: { MV_PREM_TYPE: any }) => mvPremType.MV_PREM_TYPE == this.selectedRow.MV_PREM_TYPE
      );
      if((this.tempUpdateData == '' || this.tempUpdateData == null) || (tempDbData=='' || tempDbData==null)) {
        this.tempUpdateData.push(this.formValues());
      } else {
        tempDbData.MV_PREM_TYPE       = parseInt(this.mvPremTypeForm.get('MV_PREM_TYPE')?.value);
        tempDbData.ACTIVE_TAG         = this.mvPremTypeForm.get('ACTIVE_TAG')?.value;      
        tempDbData.MV_PREM_TYPE_DESC  = this.mvPremTypeForm.get('MV_PREM_TYPE_DESC')?.value;
        tempDbData.NO_OF_YEARS        = this.mvPremTypeForm.get('NO_OF_YEARS')?.value;     
        tempDbData.CURRENCY_CD        = this.mvPremTypeForm.get('CURRENCY_CD')?.value;    
        tempDbData.GROSS_AMT          = this.mvPremTypeForm.get('GROSS_AMT')?.value;       
        tempDbData.REMARKS            = this.mvPremTypeForm.get('REMARKS')?.value;         
        tempDbData.LAST_USER          = this.userId;       
        tempDbData.LAST_USER_UPDATE   = this.mvPremTypeForm.get('LAST_USER_UPDATE')?.value;
      }
    } catch(e) {
      
    }
  }

  updateNewData() {
    try {
      let tempNewConfigdata = this.tempNewData.find(
        (mvPremType: { MV_PREM_TYPE_DESC: any }) => mvPremType.MV_PREM_TYPE_DESC == this.selectedRow.MV_PREM_TYPE_DESC
      );
      tempNewConfigdata.MV_PREM_TYPE       = this.mvPremTypeForm.get('MV_PREM_TYPE')?.value;
      tempNewConfigdata.ACTIVE_TAG         = this.mvPremTypeForm.get('ACTIVE_TAG')?.value;      
      tempNewConfigdata.MV_PREM_TYPE_DESC  = this.mvPremTypeForm.get('MV_PREM_TYPE_DESC')?.value;
      tempNewConfigdata.NO_OF_YEARS        = this.mvPremTypeForm.get('NO_OF_YEARS')?.value;     
      tempNewConfigdata.CURRENCY_CD        = this.mvPremTypeForm.get('CURRENCY_CD')?.value;    
      tempNewConfigdata.GROSS_AMT          = this.mvPremTypeForm.get('GROSS_AMT')?.value;       
      tempNewConfigdata.REMARKS            = this.mvPremTypeForm.get('REMARKS')?.value;         
      tempNewConfigdata.LAST_USER          = this.userId;       
      tempNewConfigdata.LAST_USER_UPDATE   = this.mvPremTypeForm.get('LAST_USER_UPDATE')?.value;
    } catch(e) {
      
    }
  }

  deleteRow(): void {
    try {
      let clickedData = this.selectedRow;
      this.mvPremTypeTable.tblData = this.mvPremTypeTable.tblData.filter(function(e: any) {
        return e.MV_PREM_TYPE_DESC != clickedData.MV_PREM_TYPE_DESC;
      });
      this.tempNewData = this.tempNewData.filter(function(e: any) {
        return e.MV_PREM_TYPE_DESC != clickedData.MV_PREM_TYPE_DESC;
      });
      this.selectedRow = '';
      this.formClearer();
      this.deleteDisabler = true;
      this.addUpdateFlag = true;
      if((this.tempNewData=='' || this.tempNewData==null) && (this.tempUpdateData=='' || this.tempUpdateData==null)) {
        this.saveDisabler = true;
      }
    } catch(e) {
      
    }
  }

  onSave(): void {
    try {
      this.formHider = true;
      this.formService.showFormLoader(null, "mv-prem-type-form", "Saving.<br>Please wait ...", null, null);
      this.jsonDataService.contorlLoading(true);
      this.api.saveMvPremType(this.tempNewData).subscribe({
        next: (data: any) => {
          this.securityService.checkRequestKeyResponse(data, () => {
            this.securityService.hasValidCsrfToken(data, () => {
              if(data.status == 'SUCCESS') {
                this.api.saveMvPremType(this.tempUpdateData).subscribe({
                  next: (res: any) => {
                    this.securityService.checkRequestKeyResponse(res, () => {
                      this.securityService.hasValidCsrfToken(res, () => {
                        if(res.status == 'SUCCESS') {
                          this.onComplete();
                        } else {
                          this.onFail();
                        }
                      });
                    });
                  },
                  error: () => {
                    this.onFail();
                  }
                });
              } else {
                this.onFail();
              }
            });
          });
        },
        error: () => {
          this.onFail();
        }
      });
    } catch(e) {
      
    }
  }
  
  onComplete() {
    try {
      this.formService.hideFormLoader("mv-prem-type-form");
      this.formHider = false;
      this.tempNewData = [];
      this.tempUpdateData = [];
      this.saveDisabler = true;
      this.deleteEnabler();
      this.formClearer();
      this.getMvPremType();
      this.formService.hideFormMsg("mv-prem-type-error-message");
      this.openSaveDialog();
    } catch(e) {
      
    }
  }

  onFail() {
    try {
      this.formService.hideFormLoader("mv-prem-type-form");
      this.formHider = false;
      this.tempNewData = [];
      this.tempUpdateData = [];
      this.deleteEnabler();
      this.formClearer();
      this.getMvPremType();
      this.formService.hideFormMsg("mv-prem-type-error-message");
      this.openErrorDialog("Saving MV Premium Type Failed");
    } catch(e) {
      
    }
  }

  openErrorDialog(message: string) {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'ERROR',
          content: message,
        }
      });
    } catch(e) {
      
    }
  }

  openSaveDialog() {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'SUCCESS',
          content: 'MV Premium Type Details Successfully Saved!',
        }
      });
    } catch(e) {
      
    }
  }
}
