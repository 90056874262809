import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { APICallService } from '../../../services/api-call.service';
import { OspTableComponent } from '../../common/osp-table/osp-table.component';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { MatDialog } from '@angular/material/dialog';
import { JsonDataService } from '../../../services/json-data.service';
import { UploadFmvComponent } from './upload-fmv/upload-fmv.component';
import { FormService } from 'src/app/services/form.service';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { Observable } from 'rxjs';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { SecurityService } from 'src/app/services/security.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { LazyLoadEvent } from 'primeng/api';
import { RevisionHistoryComponent } from '../../common/revision-history/revision-history.component';

@Component({
  selector: 'fmv-maintenance',
  templateUrl: './fmv-maintenance.component.html',
  styleUrls: ['./fmv-maintenance.component.css'],
  providers: [DecimalPipe]
})
export class FmvMaintenanceComponent implements OnInit, LeaveConfirmation {

  @ViewChild('vehicleTbl') vehicleTbl!: OspTableComponent;
  @ViewChild('fmvTbl') fmvTbl!: OspTableComponent;
  @ViewChild('deleteDialog') deleteDialog!: TemplateRef<any>;
  @ViewChild('rh') rh!: RevisionHistoryComponent;

  tblConfig1: any = {
    cols: [
      {
        key: "CAR_COMPANY",
        header: "Make",
        dataType: "string",
        width: "15%"
      },
      {
        key: "MAKE_DESC",
        header: "Model",
        dataType: "string",
        width: "20%"
      },
      {
        key: "ENGINE_SERIES",
        header: "Sub Model",
        dataType: "string",
        width: "35%"
      },
      {
        key: "MAKE_TYPE_CD",
        header: "Make Type Code",
        dataType: "string",
        width: "20%"
      },
      {
        key: "GAS_TYPE",
        header: "Gas Type",
        dataType: "string",
        width: "10%"
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    tblCaption: "Vehicle Details",
    totalRecords: 15
  }

  tblConfig2: any = {
    cols: [
      {
        key: "ACTIVE_TAG",
        header: "Active",
        dataType: "checkbox"
      },
      {
        key: "MODEL_YEAR",
        header: "Model Year",
        dataType: "string"
      },
      {
        key: "FMV_VALUE",
        header: "Latest FMV",
        dataType: "currency"
      },
      {
        key: "FMV_VALUE_MIN",
        header: "Minimum FMV",
        dataType: "currency"
      },
      {
        key: "FMV_VALUE_MAX",
        header: "Maximum FMV",
        dataType: "currency"
      },
      {
        key: "EFF_DATE_FROM",
        header: "Effective Date From",
        dataType: "date"
      },
      {
        key: "EFF_DATE_TO",
        header: "Effective Date To",
        dataType: "date"
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 5,
    lazy: false,
    tblCaption: "FMV Details",
    totalRecords: 5,
    visible: false,
    pageLinks: 1
  }

  fmvForm: FormGroup = this.fb.group ({
    carCompanyCd: [""],
    makeCd: [""],
    seriesCd: [""],
    modelYear: ["", [Validators.required]],
    fmvValue: ["", [Validators.required]],
    fmvValueMin: ["", [Validators.required]],
    fmvValueMax: ["", [Validators.required]],
    effDateFrom: ["", [Validators.required]],
    effDateTo: ["", [Validators.required]],
    activeTag: [true],
    userId: [""],
    lastUpdate: [""]
  });

  private isVehicleDoneLoading: boolean = false;
  selectedVehicle: any = null;
  selectedFmv: any = null;
  decimalPipeFmt: string = '1.2-2';
  saveFmvDtlList: any[] = [];
  moduleId: string = "BMM028";
  fmvMinMaxMtn: number = 0;
  moduleName: string = '';

  fmvRevHist: any = {
    tblConfig: {
      cols: [
        {
          key: "histNo",
          header: "Hist No",
          dataType: "string",
          width: "14%"
        },
        {
          key: "userId",
          header: "User",
          dataType: "string",
          width: "22%"
        },
        {
          key: "lastUpdate",
          header: "Date",
          dataType: "datetime",
          width: "28%"
        },
        {
          key: "remarks",
          header: "Remarks",
          dataType: "string",
          width: "36%",
          align: 'text-l'
        }
      ],
      tblData: [],
      selection: "single",
      paginator: true,
      rowsPerPage: 10,
      lazy: true,
      totalRecords: 5,
      pageLinks: 1
    }
  }

  constructor(
    private api: APICallService,
    private userDetailService: UserDetailsService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private jds: JsonDataService,
    private securityService: SecurityService,
    private appMessageService: AppMessageService,
    public fs: FormService) {
      this.fmvForm.controls["userId"].disable();
      this.fmvForm.controls["lastUpdate"].disable();
      this.getDataJson();
  }

  ngOnInit(): void {
    this.moduleName = this.jds.data.moduleList.filter((data: any) => { return data.moduleId === this.moduleId })[0].moduleDesc.toUpperCase();
    this.getVehicles();
  }

  getDataJson():void {
    this.fmvMinMaxMtn = Number((this.jds.data.params.filter((a: any) => a.paramName == 'FMV_MIN_MAX_MTN')[0]['paramValueN']/100).toFixed(2));
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.saveFmvDtlList.length == 0;
  }

  getVehicles() {
    try {
      this.tblConfig1.loading = true;
      this.jds.contorlLoading(true);
      this.isVehicleDoneLoading = false;
      this.api.getAllVehicles({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE",
      }).subscribe({
        next: (data: any) => {
          this.securityService.checkRequestKeyResponse(data, () => {
            this.securityService.hasValidCsrfToken(data, () => {
              if (data.status === "SUCCESS") {
                let content = JSON.parse(this.jds.decrypt(data?.response));
                let gasTypes:any[] = this.jds.data.refCds.filter((a:any)=>a.rvDomain == 'BMM027.GAS_TYPE');

                this.tblConfig1.tblData = content.data.filter((e:any, i:number,arr:any) => {
                  e.GAS_TYPE = gasTypes.find(a=>a.rvLowValue == e.GAS_TYPE)?.rvMeaning;
                  return e;
                });;
              }
              this.isVehicleDoneLoading = true;
              this.tblConfig1.loading = false;
              this.jds.contorlLoading(false);
            });
          });
        },
        error: (data: any) => {
          console.error(data);
          this.securityService.checkRequestKeyResponse(data, () => {
            this.isVehicleDoneLoading = true;
            this.tblConfig1.loading = false;
            this.jds.contorlLoading(false);
          });
        }
      });
    }
    catch (e) {
      console.error(e);
    }
  }

  onRowClickVehicle(ev: any) {
    this.selectedVehicle = ev;

    if(this.fmvTbl) {
      this.tblConfig2.visible = ev !== null;
      this.fmvTbl.selectedRow = null;
      this.selectedFmv = null;
    }

    if(this.selectedVehicle !== null) {
      // this.getFmvList();
      this.lazyLoad({
        first: 0,
        globalFilter: ''
      });
    }

    this.fs.hideFormMsg("fmv-dtl-form-msg");
    this.updateForm(null);
  }

  onRowClickFmv(ev: any) {
    this.selectedFmv = ev;
    
    this.fs.hideFormMsg("fmv-dtl-form-msg");
    this.updateForm(ev);
  }

  getFmvList(prm?: any) {
    let qryPrm: any = {};

    if(prm) {
      qryPrm = {
        carCompanyCd: prm.carCompanyCd,
        makeCd: prm.makeCd,
        seriesCd: prm.seriesCd,
        first: prm.first,
        rows: prm.rows,
        search: prm.search,
        sortBy: prm.sortBy,
        sortDir: prm.sortDir
      }
    }

    this.tblConfig1.loading = true;
    this.tblConfig2.loading = true;
    this.api.getFmvList({
      moduleId: this.moduleId,
      userId: this.userDetailService.userId,
      type: "MODULE",
      carCompanyCd: qryPrm.carCompanyCd,
      makeCd: qryPrm.makeCd,
      seriesCd: qryPrm.seriesCd,
      first: qryPrm.first,
      rows: qryPrm.rows,
      search: qryPrm.search,
      sortBy: qryPrm.sortBy,
      sortDir: qryPrm.sortDir
    }).subscribe((data: any) => {
      this.securityService.checkRequestKeyResponse(data, () => {
          if (data.status === "SUCCESS") {
              data = JSON.parse(this.jds.decrypt(data.content));
              this.tblConfig2.totalRecords = data['count'];
              this.tblConfig2.pageLinks = 10;
              this.tblConfig1.loading = false;
              this.tblConfig2.loading = false;
              this.tblConfig2.tblData = data['data'];
          } else {
              this.jds.contorlLoading(false);
              this.tblConfig1.loading = false;
              this.tblConfig2.loading = false;
              this.appMessageService.showAppMessage(data.message, "error");
          }
      });
    });
  }

  updateForm(ev: any) {
    var date = new Date();

    this.fmvForm.patchValue({
      carCompanyCd: ev == null ? "" : ev.CAR_COMPANY_CD,
      makeCd: ev == null ? "" : ev.MAKE_CD,
      seriesCd: ev == null ? "" : ev.SERIES_CD,
      modelYear: ev == null ? "" : ev.MODEL_YEAR,
      fmvValue: ev == null ? "" : this.fs.setToAmountFormat(ev.FMV_VALUE),
      fmvValueMin: ev == null ? "" : this.fs.setToAmountFormat(ev.FMV_VALUE_MIN),
      fmvValueMax: ev == null ? "" : this.fs.setToAmountFormat(ev.FMV_VALUE_MAX),
      effDateFrom: ev == null ? "" : ev.EFF_DATE_FROM,
      effDateTo: ev == null ? "" : ev.EFF_DATE_TO,
      activeTag: ev == null ? true : ev.ACTIVE_TAG == 'A',
      userId: ev == null ? "" : ev.USER_ID,
      lastUpdate: ev == null ? "" : ev.LAST_UPDATE
    });

    if(ev !== null) {
      this.fmvForm.controls["modelYear"].disable();
      this.fmvForm.controls["effDateFrom"].disable();
    } else {
      this.fmvForm.controls["modelYear"].enable();
      this.fmvForm.controls["effDateFrom"].enable();
    }
  }

  onClickAdd() {
    if(this.fmvForm.valid) {
      const form = this.fmvForm.getRawValue();

      if(form.effDateFrom && typeof form.effDateFrom != 'string'){
        form.effDateFrom = form.effDateFrom.format('YYYY-MM-DD');
      }

      if(form.effDateTo && typeof form.effDateTo != 'string'){
        form.effDateTo = form.effDateTo.format('YYYY-MM-DD');
      }

      var val = {
        carCompanyCd: form.carCompanyCd == "" ? this.selectedVehicle.CAR_COMPANY_CD : form.carCompanyCd,
        makeCd: form.makeCd == "" ? this.selectedVehicle.MAKE_CD : form.makeCd,
        seriesCd: form.seriesCd == "" ? this.selectedVehicle.SERIES_CD : form.seriesCd,
        modelYear: form.modelYear,
        fmvValue: this.fs.setToNumberWithDecimal(form.fmvValue),
        fmvValueMin: this.fs.setToNumberWithDecimal(form.fmvValueMin),
        fmvValueMax: this.fs.setToNumberWithDecimal(form.fmvValueMax),
        effDateFrom: form.effDateFrom,
        effDateTo: form.effDateTo,
        activeTag: form.activeTag ? 'A' : 'I',
        userId: this.jds.retrieveFromStorage("userId")
      }

      var valTbl2 = {
        CAR_COMPANY_CD: form.carCompanyCd == "" ? this.selectedVehicle.CAR_COMPANY_CD : form.carCompanyCd,
        MAKE_CD: form.makeCd == "" ? this.selectedVehicle.MAKE_CD : form.makeCd,
        SERIES_CD: form.seriesCd == "" ? this.selectedVehicle.SERIES_CD : form.seriesCd,
        MODEL_YEAR: form.modelYear,
        FMV_VALUE: this.fs.setToNumberWithDecimal(form.fmvValue),
        FMV_VALUE_MIN: this.fs.setToNumberWithDecimal(form.fmvValueMin),
        FMV_VALUE_MAX: this.fs.setToNumberWithDecimal(form.fmvValueMax),
        EFF_DATE_FROM: form.effDateFrom,
        EFF_DATE_TO: form.effDateTo,
        ACTIVE_TAG: form.activeTag ? 'A' : 'I',
        USER_ID: this.jds.retrieveFromStorage("userId"),
        newRow: true
      }

      for(var i = 0; i < this.tblConfig2.tblData.length; i++) {
        let x = this.tblConfig2.tblData[i];

        let minEffDate = new Date(x.EFF_DATE_FROM).getTime();
        let maxEffDate = new Date(x.EFF_DATE_TO).getTime();

        let from = new Date(form.effDateFrom).getTime();
        let to = new Date(form.effDateTo).getTime();

        if(form.modelYear == x.MODEL_YEAR
            && this.selectedFmv == null
            && ((from >= minEffDate && from <= maxEffDate) || (to >= minEffDate && to <= maxEffDate))) {
          this.fs.showFormMsg("fmv-dtl-form-msg", "Effective Date overlaps with an existing record.", "E");
          return;
        }
      }

      if(val.effDateTo < val.effDateFrom) {
        this.fs.showFormMsg("fmv-dtl-form-msg", "Effective Date To cannot be earlier than Effective Date From.", "E");
        return;
      }

      if(parseFloat(val.fmvValueMax) < parseFloat(val.fmvValueMin)) {
        this.fs.showFormMsg("fmv-dtl-form-msg", "Minimum FMV cannot be greater than Maximum FMV.", "E");
        return;
      }

      var existing = false;

      if(this.selectedFmv !== null) {
        existing = true;

        this.selectedFmv.FMV_VALUE = val.fmvValue,
        this.selectedFmv.FMV_VALUE_MIN = val.fmvValueMin,
        this.selectedFmv.FMV_VALUE_MAX = val.fmvValueMax,
        this.selectedFmv.EFF_DATE_FROM = val.effDateFrom,
        this.selectedFmv.EFF_DATE_TO = val.effDateTo,
        this.selectedFmv.ACTIVE_TAG = val.activeTag,
        this.selectedFmv.USER_ID = val.userId,
        this.selectedFmv.newRow = this.selectedFmv.newRow
      }

      this.saveFmvDtlList.push(val);

      // for (let i = 0; i < this.tblConfig2.tblData.length; i++) {
      //   const el = this.tblConfig2.tblData[i];
        
      //   if(el.CAR_COMPANY_CD == val.carCompanyCd
      //       && el.MAKE_CD == val.makeCd
      //       && el.SERIES_CD == val.seriesCd
      //       // &&
      //       ) {
      //     this.tblConfig2.tblData[i] = {
      //       CAR_COMPANY_CD: val.carCompanyCd,
      //       MAKE_CD: val.makeCd,
      //       SERIES_CD: val.seriesCd,
      //       MODEL_YEAR: val.modelYear,
      //       FMV_VALUE: val.fmvValue,
      //       FMV_VALUE_MIN: val.fmvValueMin,
      //       FMV_VALUE_MAX: val.fmvValueMax,
      //       EFF_DATE_FROM: val.effDateFrom,
      //       EFF_DATE_TO: val.effDateTo,
      //       ACTIVE_TAG: val.activeTag,
      //       USER_ID: val.userId,
      //       newRow: this.tblConfig2.tblData[i].newRow
      //     };
      //     existing = true;
      //     break;
      //   }
      // }
      
      if(!existing) {
        /*
        this.tblConfig2.tblData.unshift({
          CAR_COMPANY_CD: val.carCompanyCd,
          MAKE_CD: val.makeCd,
          SERIES_CD: val.seriesCd,
          MODEL_YEAR: val.modelYear,
          FMV_VALUE: val.fmvValue,
          FMV_VALUE_MIN: val.fmvValueMin,
          FMV_VALUE_MAX: val.fmvValueMax,
          EFF_DATE_FROM: val.effDateFrom,
          EFF_DATE_TO: val.effDateTo,
          ACTIVE_TAG: val.activeTag,
          USER_ID: val.userId,
          newRow: true
        });
        */
        this.tblConfig2.tblData = [valTbl2, ...this.tblConfig2.tblData];
      }
      this.fs.hideFormMsg("fmv-dtl-form-msg");
      this.updateForm(null);
      this.selectedFmv = null;
    } else {
      this.fs.showFormMsg("fmv-dtl-form-msg", "There are missing information. Please provide necessary information needed.", "E");
    }
  }

  onClickSave() {
    if(this.saveFmvDtlList.length > 0) {
      this.fs.showFormLoader(
        this,
        "fmv-dtl-form",
        "Processing your request.<br>Please wait ...",
        "save",
        this.saveFmvDtlList
      );
    } else {
      this.openDialog('info');
    }
  }

  save(params: any) {
    this.api.saveFmvDetails(params).subscribe((res: any) => {
      this.securityService.checkRequestKeyResponse(res, async () => {

        if(res.status == 'SUCCESS') {
          this.lazyLoad({
            first: this.tblConfig2.tblData ? 0 : this.tblConfig2.tblData[0]['rownum_'] - 1,
            globalFilter: this.fmvTbl.filterText
          });
          this.updateForm(null);
          this.selectedFmv = null;
          this.openDialog('success');
          this.saveFmvDtlList = [];
          this.fs.hideFormLoader("fmv-dtl-form");
        } else {
          this.openDialog('error');
        }
      });
    });
  }

  openDialog(type: string) {
    var dlgTitle;
    var dlgContent;

    if(type == 'success') {
      dlgTitle = 'SUCCESS';
      dlgContent = 'FMV Details Successfully saved!';
    } else if(type == 'error') {
      dlgTitle = 'ERROR';
      dlgContent = 'An error occured while saving. Please check field values.';
    } else if(type == 'required') {
      dlgTitle = 'WARNING';
      dlgContent = 'Please supply all the required fields.';
    } else if(type == 'info') {
      dlgTitle = 'INFO';
      dlgContent = 'Nothing to save';
    }

    const dialogRole = this.dialog.open(DialogMsgComponent, { 
      disableClose: true,
      width: "500px",
      data: {
        title: dlgTitle,
        content: dlgContent
      }
    });
  }

  openUploadOverlay() {
    this.dialog.open(UploadFmvComponent, {
      disableClose: true,
      width: '1024px',
      data: {

      },
    });
  }

  onClickDelete() {
    // const dialogRole = this.dialog.open(this.deleteDialog, { 
    //   width: "500px"
    // });

    this.tblConfig2.tblData = this.tblConfig2.tblData.filter((a: any) => {
      return (Number(a.MODEL_YEAR) !== Number(this.selectedFmv.MODEL_YEAR)
        || (Number(a.MODEL_YEAR) == Number(this.selectedFmv.MODEL_YEAR) && (a.EFF_DATE_FROM.split('T')[0]) !== this.selectedFmv.EFF_DATE_FROM));
    });

    this.saveFmvDtlList = this.saveFmvDtlList.filter((a: any) => {
      return (Number(a.modelYear) !== Number(this.selectedFmv.MODEL_YEAR)
        || (Number(a.modelYear) == Number(this.selectedFmv.MODEL_YEAR) && a.effDateFrom !== this.selectedFmv.EFF_DATE_FROM));
    });

    this.updateForm(null);
    this.selectedFmv = null;
  }

  lazyLoad(ev: LazyLoadEvent) {
      setTimeout(() => {
        if (this.isVehicleDoneLoading)
          this.getFmvList({
            carCompanyCd: !this.selectedVehicle ? 1 : this.selectedVehicle?.CAR_COMPANY_CD,
            makeCd: !this.selectedVehicle ? 1 : this.selectedVehicle?.MAKE_CD,
            seriesCd: !this.selectedVehicle ? 1 : this.selectedVehicle?.SERIES_CD,
            first: ev.first,
            rows: this.tblConfig2.rowsPerPage, 
            search: ev.globalFilter,
            sortBy: ev.sortField == undefined ? 'MODEL_YEAR' : ev.sortField,
            sortDir: ev.sortOrder == undefined ? -1 : ev.sortOrder
          });
      }, 0);
  }

  computeMinMax(ev: any) {
    var val = ev.target.value;

    const fmvVal = Number(val.replaceAll(",",""));
    this.fmvForm.controls['fmvValue'].setValue((fmvVal).toFixed(2));
    this.fmvForm.controls['fmvValueMin'].setValue((fmvVal - (fmvVal * this.fmvMinMaxMtn)).toFixed(2));
    this.fmvForm.controls['fmvValueMax'].setValue((fmvVal + (fmvVal * this.fmvMinMaxMtn)).toFixed(2));

    //this.fs.setFieldToAmountFormat(this.fmvForm, 'fmvValue', ev);
    this.fs.setFieldToAmountFormat(this.fmvForm, 'fmvValue', {
      target: {
        value: this.fmvForm.controls['fmvValue'].value
      }
    });
    this.fs.setFieldToAmountFormat(this.fmvForm, 'fmvValueMin', {
      target: {
        value: this.fmvForm.controls['fmvValueMin'].value
      }
    });
    this.fs.setFieldToAmountFormat(this.fmvForm, 'fmvValueMax', {
      target: {
        value: this.fmvForm.controls['fmvValueMax'].value
      }
    });
  }

  openRevHist() {
    this.rh.open();
  }

  getFmvHistoryList(prm?: any) {
    let qryPrm: any = {};

    if(prm) {
      qryPrm = {
        carCompanyCd: prm.carCompanyCd,
        makeCd: prm.makeCd,
        seriesCd: prm.seriesCd,
        modelYear: prm.modelYear,
        first: prm.first,
        rows: prm.rows,
        search: prm.search,
        sortBy: prm.sortBy,
        sortDir: prm.sortDir
      }
    }

    this.jds.contorlLoading(true);

    this.api.getFmvHistoryList({
      moduleId: this.moduleId,
      userId: this.userDetailService.userId,
      type: "MODULE",
      carCompanyCd: qryPrm.carCompanyCd,
      makeCd: qryPrm.makeCd,
      seriesCd: qryPrm.seriesCd,
      modelYear: qryPrm.modelYear,
      first: qryPrm.first,
      rows: qryPrm.rows,
      search: qryPrm.search,
      sortBy: qryPrm.sortBy,
      sortDir: qryPrm.sortDir
    }).subscribe((data: any) => {
      this.securityService.checkRequestKeyResponse(data, () => {
        this.jds.contorlLoading(false);
          if (data.status === "SUCCESS") {
              data = JSON.parse(this.jds.decrypt(data.content));
              this.fmvRevHist.tblConfig.totalRecords = data['count'];
              this.fmvRevHist.tblConfig.pageLinks = 10;
              this.fmvRevHist.tblConfig.tblData = data['data'];
          } else {
              this.appMessageService.showAppMessage(data.message, "error");
          }
      });
    });
  }

  rhLazy(ev: any) {
    setTimeout(() => {
      this.getFmvHistoryList({
        carCompanyCd: !this.selectedVehicle ? 1 : this.selectedVehicle?.CAR_COMPANY_CD,
        makeCd: !this.selectedVehicle ? 1 : this.selectedVehicle?.MAKE_CD,
        seriesCd: !this.selectedVehicle ? 1 : this.selectedVehicle?.SERIES_CD,
        modelYear: !this.selectedFmv ? 1 : this.selectedFmv.MODEL_YEAR,
        first: ev.first,
        rows: this.fmvRevHist.tblConfig.rowsPerPage, 
        search: ev.globalFilter,
        sortBy: ev.sortField == undefined ? 'histNo' : ev.sortField,
        sortDir: ev.sortField == undefined ? -1 : ev.sortOrder
      });
    }, 0);
  }

}
