import { AfterViewInit, Component, OnInit, ViewChild, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { OspTableComponent } from '../../common/osp-table/osp-table.component';
import { MatDialog } from '@angular/material/dialog';
import { APICallService } from '../../../services/api-call.service';
import { JsonDataService } from '../../../services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { FormService } from 'src/app/services/form.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {

  @Input() data: any;

  @Output() selection = new EventEmitter<any>();

  @ViewChild('tbl') tbl!: OspTableComponent;
  @ViewChild('uploadDialog') uploadDialog!: TemplateRef<any>;

  uploadName: string = '';

  fileUpload: FormGroup = this.fb.group({
		file: [File, Validators.required]
	});

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    public fs: FormService,
    private jds: JsonDataService
  ) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    
  }

  open(title?: string) {
    this.data.tblConfig.tblData = [];
    this.uploadName = title ? title : 'Upload';
    this.fileUpload.patchValue({
      file: ""
    });

    const dialogRole = this.dialog.open(this.uploadDialog, {
      disableClose: true
    });
  }

  rowClick(ev: any) {

  }

  lazyLoad(ev: any) {

  }

  onClickUpload() {
    this.data.onClickUpload(this.data.tblConfig.tblData);
  }

  fileHandlerFunction(event: any) {
		this.fs.hideFormMsg("uploadFormMsg")
		const excel: File = event.target.files[0];
    var regex = /^([a-zA-Z0-9\s_\\.\-:])+(.xls|.xlsx)$/;

		if (excel == undefined) {
      this.fileUpload.patchValue({
				file: ""
			});
    } else if(regex.test(excel.name)) {
      this.fileUpload.patchValue({
				file: excel
			});

      this.jds.contorlLoading(true);

      const reader: FileReader = new FileReader();
      reader.readAsBinaryString(this.fileUpload.value.file);
      reader.onload = (e: any) => {
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, {type: 'binary'});

        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        const excelData: any = XLSX.utils.sheet_to_json(ws);

        let headerObj: any = XLSX.utils.sheet_to_json(ws, {defval:null})[0];
        var headers = [];

        if(excelData.length > 0) {
          headers = Object.keys(headerObj);
          var reqHeaders = this.data.uploadFileKeys;

          for(var i = 0; i < reqHeaders.length; i++) {
            if(!headers.includes(reqHeaders[i])) {
              this.fs.showFormMsg("uploadFormMsg", "Invalid uploading file. Please use the correct Excel Upload File Template.", "E");
              this.data.tblConfig.tblData = [];
              this.jds.contorlLoading(false);

              return;
            }
          }

          for(var i = 0; i < excelData.length; i++) {
            var x = excelData[i];
            x.EFF_DATE = x.EFF_DATE ? this.dateToDateString(this.serialNumberToDate(excelData[i].EFF_DATE)) : null;
            x.EXP_DATE = x.EXP_DATE ? this.dateToDateString(this.serialNumberToDate(excelData[i].EXP_DATE)) : null;
          }

          this.data.tblConfig.totalRecords = excelData.length;

          this.data.tblConfig.tblData = excelData;
          this.jds.contorlLoading(false);
        } else {
          this.jds.contorlLoading(false);
          this.fs.showFormMsg("uploadFormMsg", "No row/s found in Excel file.", "E");
        }
        
      }

    } else {
      this.fs.showFormMsg("uploadFormMsg", "Invalid file type. Upload file should be in Excel file.", "E");
			this.fileUpload.patchValue({
				file: ""
			});
    }
	}

  onClickErrorLogs() {
    this.data.tblConfig.tblData = this.data.tblConfig.tblData.filter((a: any) => a.failed);
    this.data.tblConfig.totalRecords = this.data.tblConfig.tblData.length;
  }

  serialNumberToDate(serial: any) {
    if(typeof(serial) == "string") {
      // mm/dd/yyyy
      let d = serial.split('/');

      return new Date(d[0] + '/' + d[1] + '/' + d[2]);
    }

		// Converts Serial Number from excel to Date
		var utcDays  = Math.floor(serial - 25569);
        var utcValue = utcDays * 86400;                                        
        var dateInfo = new Date(utcValue * 1000);
    
        var fractionalDay = serial - Math.floor(serial) + 0.0000001;
    
        var totalSeconds = Math.floor(86400 * fractionalDay);
    
        var seconds = totalSeconds % 60;
    
        totalSeconds -= seconds;
    
        var hours = Math.floor(totalSeconds / (60 * 60));
        var minutes = Math.floor(totalSeconds / 60) % 60;
    
        return new Date(dateInfo.getFullYear(), dateInfo.getMonth(), dateInfo.getDate(), hours, minutes, seconds);
	}

	dateToDateString(date: Date) {
		// Returns date string with MM/DD/YYYY format
        let newDate = ((date.getMonth() + 1).toString().padStart(2, '0') + '/' + date.getDate().toString().padStart(2, '0') + '/' + date.getFullYear());
        return newDate;
	}

}
