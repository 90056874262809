import { Component, Inject, OnInit } from '@angular/core';
import { APICallService } from 'src/app/services/api-call.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContentObserver } from '@angular/cdk/observers';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';


@Component({
  selector: 'city-lov',
  templateUrl: './city-lov.component.html',
  styleUrls: ['./city-lov.component.css']
})
export class CityLovDialogComponent implements OnInit {

  userTypeModuleId: string = 'BMM047';

  constructor(private apiCallService: APICallService,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<CityLovDialogComponent>) { }
  
  ngOnInit(): void {
    this.getCity();
  }


  // Variable Initializer
  issueSourceData: any = [];

  // Issue Source Initializer
  cityTableConfig: any = {
    cols: [
      {
        key: "cityCd",
        header: "Code",
        dataType: "number"
      },
      {
        key: "cityName",
        header: "City",
        dataType: "string"
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15
  }

  // Gets data for Issue Source Table
  getCity() {
    try {
      this.cityTableConfig.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.jsonDataService.contorlLoading(false);
      this.cityTableConfig.loading = false;
      let cityList = this.jsonDataService.data.city.filter((e: any) => e.countryCd==this.data.countryCd&&e.provinceCd==this.data.provinceCd);
      this.cityTableConfig.tblData = cityList.sort(function(a:any, b:any){
        let aCityCd = Number(a.cityCd);
        let bCityCd = Number(b.cityCd);
        return aCityCd - bCityCd;
      });
    } 
    catch(e) {
      
    }
  }

  onRowClick(ev: any) {
    try {
      this.data = ev;
    }
    catch(e) {
      
    }
  }

  onClose() {
    try {
      if(this.data=='' || this.data==null){
        this._dialogRef.close({content: ''}); 
      } else {
        this._dialogRef.close({content: this.data, moduleId: this.data.moduleId});
      }
    }
    catch(e) {
      
    }
  }

  onBack() {
    try {
      this._dialogRef.close({content: '', option: 'back'});
    }
    catch(e) {
      
    }
  }

}

