<section id="plan-amt-limits-maint">    
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <div class="form-content">
            <form [formGroup]="forms.lineSublineLovForm" class="form">
                <div class="form-container-grid top-form">
                    <div class="field required">
                        <label>Line</label>
                    </div>
                    <div class="">
                        <input formControlName="lineCdDisp" >
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['line']()" 
                        [disabled]='btns.lov.lineDisabled'>
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                    <div class="field required">
                        <label>Subline</label>
                    </div>
                    <div class="">
                        <input formControlName="sublineCdDisp" >
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['subline']()" 
                        [disabled]='btns.lov.sublineDisabled'>
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <!-- Container for Plan Amount Limits Table using prime ng -->
        <div class = 'table-user'>
            <osp-table [tblConfig]="planAmtLimitsTblConfig" (onRowClick)="onRowClick($event)"></osp-table>
        </div>
        <!-- Loading Animation -->
        <div id="plan-amt-limits-maintenance-loading">
            <div class="form-loader"></div>
        </div>
        <div class="form-content" *ngIf="showForm">
            <form [formGroup]="forms.planAmtLimitsForm" class="form">
                <div id="plan-amt-limits-error-message"></div>
                <div class="form-container-grid">
                    <div class="field required">
                        <label>Plan</label>
                    </div>
                    <div class="span lov-search">
                        <input type="text" readonly formControlName="planField">
                        <button type="button" class="btn-search btn" (click)="openLov['plan']()" [disabled]='btns.planAmtLimits.planDisabled'>
                            <mat-icon>
                                search
                            </mat-icon>
                        </button>
                    </div>

                    <div class="field required">
                        <label>Minimum Premium Amount</label>
                    </div>
                    <div class="">
                        <input class="currency" formControlName="minPremAmt" (focus)="_formService.setFieldToNumberWithDecimal(forms.planAmtLimitsForm, 'minPremAmt', $event)"
                        (blur)="_formService.setFieldToAmountFormat(forms.planAmtLimitsForm, 'minPremAmt', $event)" (keypress)="allowNumericDigitsOnly($event)">
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag">
                            <option *ngFor="let activeType of selections.activeTypes" [value]="activeType.cd">
                                {{activeType.name}}
                            </option>
                        </select>
                    </div>

                    <div class="field">
                        <label>Minimum Sum Insured</label>
                    </div>
                    <div class="">
                        <input class="currency" formControlName="siAmtMin" (focus)="_formService.setFieldToNumberWithDecimal(forms.planAmtLimitsForm, 'siAmtMin', $event)"
                        (blur)="_formService.setFieldToAmountFormat(forms.planAmtLimitsForm, 'siAmtMin', $event)" (keypress)="allowNumericDigitsOnly($event)">
                    </div>
                    <div class="field">
                        <label>Maximum Sum Insured</label>
                    </div>
                    <div class="">
                        <input class="currency" formControlName="siAmtMax" (focus)="_formService.setFieldToNumberWithDecimal(forms.planAmtLimitsForm, 'siAmtMax', $event)"
                        (blur)="_formService.setFieldToAmountFormat(forms.planAmtLimitsForm, 'siAmtMax', $event)" (keypress)="allowNumericDigitsOnly($event)">
                    </div>

                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" type="text" maxlength="1000">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="userID">
                    </div>
                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUpdate">
                    </div>
                </div>
            </form>
            <div class="btn-row">
                <button *ngIf="btns.planAmtLimits.updateDisabled" class="btn btn2" type="button" [disabled]='btns.planAmtLimits.addDisabled' (click)="onAdd()">ADD</button>
                <button *ngIf="!btns.planAmtLimits.updateDisabled" class="btn btn2" type="button" (click)="onUpdate()">UPDATE</button>
                <button class="btn btn2" type="button" [disabled]='btns.planAmtLimits.deleteDisabled' (click)="onDelete()"> DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-row">
                <button class="btn btn2" type="button" [disabled]='btns.planAmtLimits.saveDisabled' (click)="onSave()">SAVE</button>
            </div>
        </div>
    </div>
</section>