import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe, formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';

@Component({
  selector: 'report-parameter-maintenance',
  templateUrl: './report-parameter-maintenance.component.html',
  styleUrls: ['./report-parameter-maintenance.component.css']
})
export class ReportParameterMaintenanceComponent implements OnInit, LeaveConfirmation {

  private moduleId: string = 'BMM069';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc?.toUpperCase() ?? "";

  datePipe = new DatePipe('en-us');

  constructor(
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    public _formService: FormService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService
  ) { 
    this.userIdLoggedIn = this._userDetailService.userId ?? "";
  }

  ngOnInit(): void {
    this.getData.reportParameter();
  }

  canDeactivate(): boolean | Observable<boolean> {
    if(this.tempData!='') {
      return false;
    } else {
      return true;
    }
  }

  public btns = {
    repParam: {
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      addDisabled: true
    },
  }

  public showForm: boolean = true;
  public paramTypeEnabled:string = '';

  private rowData: any;
  private tempDataRow: any;
  private errorMessage: string = "";
  private userIdLoggedIn: string;
  private tempData: any = [];

  public forms = {
    repParamForm: this.fb.group({
      reportID: [null, [Validators.required]],
      paramType: [null, [Validators.required]],
      paramTypeDisp: [null],
      paramName: [null, [Validators.required]],
      paramValueV: [{ value: '', disabled: 'true' }],
      paramValueN: [{ value: '', disabled: 'true' }],
      paramValueD: [{ value: '', disabled: 'true' }],
      remarks: [null, [Validators.maxLength(4000)]],
      userID: [{ value: '', disabled: 'true' }],
      lastUserUpdate: [{ value: '', disabled: 'true' }],
    }),
    resetRepParamForm: () => {
      this.forms.repParamForm.reset();
      this.forms.repParamForm.patchValue({
        activeTag: 'A'
      });
      this.btns.repParam.updateDisabled = this.btns.repParam.deleteDisabled = true;
      //this.forms.repParamForm.get('effDateFrom')?.enable();
      this.forms.repParamForm.get('reportID')?.enable();
      this.forms.repParamForm.get('paramType')?.enable();
      this.forms.repParamForm.get('paramName')?.enable();
      this.checkParamFieldDisable(null);
      this._formService.hideFormMsg("rep-param-error-message");
    }
  }

  private repParamFormManipulate = {
    dbToForm: (data: any) => {
      return {
        reportID: data.REPORT_ID,
        paramType: data.PARAM_TYPE,
        paramTypeDisp: data.PARAM_TYPE_DISP,
        paramName: data.PARAM_NAME,
        paramValueV: data.PARAM_VALUE_V,
        paramValueN: data.PARAM_VALUE_N=='' || data.PARAM_VALUE_N==null ? null : this._formService.setToAmountFormat(data.PARAM_VALUE_N),
        paramValueD: this.dateFilter(data.PARAM_VALUE_D),
        remarks: data.REMARKS,
        userID: data.USER_ID,
        lastUserUpdate: data.LAST_USER_UPDATE,
      };
    },
    formToDb: (data: any, mode: any) => {
      return {
        REPORT_ID: data.reportID.trim().toUpperCase(),
        PARAM_TYPE: data.paramType,
        PARAM_TYPE_DISP: data.paramTypeDisp,
        PARAM_NAME: data.paramName.trim().toUpperCase(),
        PARAM_VALUE_V: data.paramValueV,
        PARAM_VALUE_N: data.paramValueN=='' || data.paramValueN==null ? null : parseFloat(this._formService.setToNumberWithDecimal(data.paramValueN)).toFixed(2),
        PARAM_VALUE_D: data.paramValueD=='' || data.paramValueD==null ? null : this.datePipe.transform(new Date(data.paramValueD), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
        REMARKS: data.remarks,
        USER_ID: this.userIdLoggedIn,
        LAST_UPDATE: null,
        MODE: mode
      };
    },
    formToTemp: (data: any, mode: any) => {
      return {
        REPORT_ID: data.reportID.trim().toUpperCase(),
        PARAM_TYPE: data.paramType,
        PARAM_TYPE_DISP: data.paramTypeDisp,
        PARAM_NAME: data.paramName.trim().toUpperCase(),
        PARAM_VALUE_V: data.paramValueV,
        PARAM_VALUE_N: data.paramValueN=='' || data.paramValueN==null ? null : parseFloat(this._formService.setToNumberWithDecimal(data.paramValueN)).toFixed(2),
        PARAM_VALUE_D: data.paramValueD=='' || data.paramValueD==null ? null : this.datePipe.transform(new Date(data.paramValueD), 'yyyy-MM-dd'),
        REMARKS: data.remarks,
        USER_ID: this.userIdLoggedIn,
        LAST_UPDATE: null,
        MODE: mode
      };
    }
  }

  public repParamTblConfig = {
    cols: [
      {
        key: "REPORT_ID",
        header: "Report ID",
        dataType: "string",
      },
      {
        key: "PARAM_TYPE_DISP",
        header: "Parameter Type",
        dataType: "string"
      },
      {
        key: "PARAM_NAME",
        header: "Parameter Name",
        dataType: "string"
      },
      {
        key: "PARAM_VALUE_N",
        header: "Parameter (Number)",
        dataType: "string",
      },
      {
        key: "PARAM_VALUE_V",
        header: "Parameter (Characters)",
        dataType: "string",
      },
      {
        key: "PARAM_VALUE_D",
        header: "Parameter (Date)",
        dataType: "date",
      },
    ],
    tblData: [] as any[],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  private getData = {
    reportParameter: () => {
      try {
        this.repParamTblConfig.tblData = [];
        this.repParamTblConfig.loading = true;
        this._jDS.contorlLoading(true);
        this._api.getReportParameter({
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          type: "MODULE"
        }).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                let content = JSON.parse(this._jDS.decrypt(data.response));
                this._jDS.contorlLoading(false);
                this.repParamTblConfig.tblData = content.data;
                this.btns.repParam.addDisabled = false;
                //this.forms.repParamForm.get('effDateFrom')?.enable();
                this.repParamTblConfig.loading = false;
              });
            });
          },
          error: (error: any) => {
            this.repParamTblConfig.loading = false;
            this._jDS.contorlLoading(false);
          }
        });
      }
      catch (e) {
        console.error(e);
      }
    },
    paramTypes: () => {
      const filteredMag = this._jDS.data.refCds.filter((data: any) => data.rvDomain === 'BMM011.PARAM_TYPE').map((data: any) => { return { cd: data.rvLowValue, name: data.rvMeaning } });
      return filteredMag;
    }
  }

  public selections = {
    paramTypes: [
      ...this.getData.paramTypes()
    ],
  }

  public onRowClick(ev: any) {
    try {
      ev.update = true;
      this.rowData = ev;
      this.populateFormFromTable();
    }
    catch (e) {
      this.forms.resetRepParamForm();
      this.rowData = null;
      //this.indexRow = null;
    }
  }

  private populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.repParam.updateDisabled = !data.update;
      this.btns.repParam.deleteDisabled = !data.temp;
      this.tempDataRow = !data.temp;
      this.forms.repParamForm.patchValue(this.repParamFormManipulate.dbToForm(data));
      //this.forms.repParamForm.get('effDateFrom')?.disable();
      this.checkParamFieldDisable(this.forms.repParamForm.get('paramType')?.value);
      this.forms.repParamForm.get('reportID')?.disable();
      this.forms.repParamForm.get('paramType')?.disable();
      this.forms.repParamForm.get('paramName')?.disable();
    }
    catch (e) {
      console.error(e);
    }
  }

  public onAdd() {
    try {
      this._formService.hideFormMsg("rep-param-error-message");
      if (!this.paramValCheck()) {
        this._formService.showFormMsg("rep-param-error-message", "There are missing information. Please provide necessary information needed.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("rep-param-error-message");
        }, 10000);
      }
      else if (!this.paramNameDupCheck()) {
        this._formService.showFormMsg("rep-param-error-message", "Parameter Name already exists. Please add a unique Parameter Name.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("rep-param-error-message");
        }, 10000);
      }
      else if (!this.paramValueReqCheck()) {
        this._formService.showFormMsg("rep-param-error-message", "There are missing information. Please provide necessary information needed.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("rep-param-error-message");
        }, 10000);
      }
      else if (!this.paramNumCheck()) {
        this._formService.showFormMsg("rep-param-error-message", "Invalid value in Parameter (Number). The value entered exceeded the maximum limit.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("rep-param-error-message");
        }, 10000);
      }
      else if (this.forms.repParamForm.valid) {
        this.forms.repParamForm.get('paramValueV')?.enable();
        this.forms.repParamForm.get('paramValueN')?.enable();
        this.forms.repParamForm.get('paramValueD')?.enable();
        this.forms.repParamForm.patchValue({
          paramTypeDisp: this.selections.paramTypes.filter((d: any) => d.cd == this.forms.repParamForm.get('paramType')?.value)[0].name
        });
        let rowToBeAdded: { [key: string]: any } = this.repParamFormManipulate.formToDb(this.forms.repParamForm.value, 'I');
        let rowToBeAddedTemp: { [key: string]: any } = this.repParamFormManipulate.formToTemp(this.forms.repParamForm.value, 'I');
        rowToBeAdded.temp = true;
        this.tempData.push(rowToBeAddedTemp);
        this.repParamTblConfig.tblData = [rowToBeAdded, ...this.repParamTblConfig.tblData];
        this.forms.resetRepParamForm();
        this.btns.repParam.saveDisabled = false;
      }
      else {
        this.showErrorValidator.repParam();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public onDelete() {
    try {
      let clickData = this.rowData;
      this.repParamTblConfig.tblData = this.repParamTblConfig.tblData.filter((d: any) => {
        return d !== this.rowData;
      });
      this.tempData = this.tempData.filter(
        function(e: any) {
          return e.PARAM_NAME != clickData.PARAM_NAME || e.REPORT_ID != clickData.REPORT_ID || e.PARAM_TYPE != clickData.PARAM_TYPE;
        }
      );
      if (this.tempData==null || this.tempData=='') {
        this.btns.repParam.saveDisabled = true;
      }
      this.forms.resetRepParamForm();
    }
    catch (e) {
      console.error(e);
    }
  }

  public onUpdate() {
    try {
      this._formService.hideFormMsg("rep-param-error-message");
      if (!this.paramValueReqCheck()) {
        this._formService.showFormMsg("rep-param-error-message", "There are missing information. Please provide necessary information needed.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("rep-param-error-message");
        }, 10000);
      }
      else if (!this.paramNumCheck()) {
        this._formService.showFormMsg("rep-param-error-message", "Invalid value in Parameter (Number). The value entered exceeded the maximum limit.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("rep-param-error-message");
        }, 10000);
      }
      else if (this.forms.repParamForm.valid) {
        this.forms.repParamForm.get('reportID')?.enable();
        this.forms.repParamForm.get('paramType')?.enable();
        this.forms.repParamForm.get('paramName')?.enable();
        this.forms.repParamForm.get('paramValueV')?.enable();
        this.forms.repParamForm.get('paramValueN')?.enable();
        this.forms.repParamForm.get('paramValueD')?.enable();
        let clickData = this.rowData;
        clickData.PARAM_VALUE_D = clickData.PARAM_VALUE_D=='' || clickData.PARAM_VALUE_D==null ? null : this.datePipe.transform(new Date(clickData.PARAM_VALUE_D), 'yyyy-MM-dd');
        let toBeUpdatedIndex = this.repParamTblConfig.tblData.indexOf(this.rowData);
        let repParamCdRowValue = this.rowData.REPORT_ID;
        let rowToBeUpdated: { [key: string]: any } = this.repParamFormManipulate.formToDb(this.forms.repParamForm.value, 'U');
        let rowToBeUpdatedTemp: { [key: string]: any } = this.repParamFormManipulate.formToTemp(this.forms.repParamForm.value, 'U');
        let tempInsertChecker = this.tempData.find(
          (p: { PARAM_NAME: any, REPORT_ID: any, PARAM_TYPE: any; }) => 
          p.PARAM_NAME == clickData.PARAM_NAME && p.REPORT_ID == clickData.REPORT_ID && p.PARAM_TYPE == clickData.PARAM_TYPE && clickData.MODE == 'I');
        let tempUpdateChecker = this.tempData.find(
          (p: { PARAM_NAME: any, REPORT_ID: any, PARAM_TYPE: any; }) => 
          p.PARAM_NAME == clickData.PARAM_NAME && p.REPORT_ID == clickData.REPORT_ID && p.PARAM_TYPE == clickData.PARAM_TYPE && clickData.MODE == 'U');
        if (this.rowData.temp) {
          rowToBeUpdated.temp = true;
        }
        else {
          rowToBeUpdated.onDbButUpdatedTemp = true;
          rowToBeUpdated.REPORT_ID = repParamCdRowValue;
        }
        if (tempInsertChecker!=null && tempInsertChecker!='') {
          //tempInsertChecker = rowToBeUpdated;
          tempInsertChecker.PARAM_VALUE_V = rowToBeUpdatedTemp.PARAM_VALUE_V;
          tempInsertChecker.PARAM_VALUE_N = rowToBeUpdatedTemp.PARAM_VALUE_N;
          tempInsertChecker.PARAM_VALUE_D = rowToBeUpdatedTemp.PARAM_VALUE_D;
          tempInsertChecker.REMARKS = rowToBeUpdatedTemp.REMARKS;
          tempInsertChecker.USER_ID = rowToBeUpdatedTemp.USER_ID;
          tempInsertChecker.MODE = 'I';
          rowToBeUpdated.MODE = 'I';
          rowToBeUpdatedTemp.MODE = 'I';
        }
        else if (tempUpdateChecker!=null && tempUpdateChecker!='') {
          tempUpdateChecker.PARAM_VALUE_V = rowToBeUpdatedTemp.PARAM_VALUE_V;
          tempUpdateChecker.PARAM_VALUE_N = rowToBeUpdatedTemp.PARAM_VALUE_N;
          tempUpdateChecker.PARAM_VALUE_D = rowToBeUpdatedTemp.PARAM_VALUE_D;
          tempUpdateChecker.REMARKS = rowToBeUpdatedTemp.REMARKS;
          tempUpdateChecker.USER_ID = rowToBeUpdatedTemp.USER_ID;
          tempUpdateChecker.MODE = 'U';
        }
        else { 
          this.tempData.push(rowToBeUpdatedTemp);
        }
        this.forms.resetRepParamForm();
        this.repParamTblConfig.tblData[toBeUpdatedIndex] = rowToBeUpdated;
        this.btns.repParam.saveDisabled = false;
        this.repParamTblConfig.tblData = [... this.repParamTblConfig.tblData];
      }
      else {
        this.showErrorValidator.repParam();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public onSave() {
    try {
      this.btns.repParam.saveDisabled = true;
      this.showForm = false;
      this._formService.showFormLoader(null, "rep-param-maintenance-loading", "Saving.<br>Please wait ...", null, null);
      if (this.tempData.length > 0) {
        this._api.saveReportParameter(this.tempData).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                if (data.status === 'SUCCESS') {
                  this.onComplete.repParam();
                }
                else {
                  this.onComplete.repParamError();
                }
              });
            });
          },
          error: (data: any) => {
            this.onComplete.repParamError();
          },
        });
      }
      else
        this.onComplete.repParam();
    }
    catch (e) {
      console.error(e);
    }
  }

  private onComplete = {
    repParam: () => {
      this.tempData = [];
      this.getData.reportParameter();
      this.btns.repParam.saveDisabled = true;
      this.showForm = true;
      this._formService.hideFormLoader("rep-param-maintenance-loading");
      this.forms.resetRepParamForm();
      this.openDialog('SUCCESS', 'Report Parameter details successfully saved');
    },
    repParamError: () => {
      this.showForm = true;
      this.btns.repParam.saveDisabled = false;
      this.openDialog('FAILED', 'Report Parameter details saving failed');
      this._formService.hideFormLoader("rep-param-maintenance-loading");
    },
  }

  openDialog(title: any, message: any) {
    try {
      this.showForm = true;
      this._formService.hideFormLoader("rep-param-maintenance-loading");
      this._matDialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        } 
      });
    } catch(e) {
      console.error(e);
    }
  }

  checkDuplicateParamName(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.trim()?.toUpperCase();
      if (!value) return null;
      let hasDuplicate: boolean = this.repParamTblConfig.tblData.filter((d: any) => {
        if (!this.rowData && this.btns.repParam.updateDisabled){
          return d.PARAM_NAME.trim().toUpperCase() === value 
          && d.REPORT_ID === this.forms.repParamForm.get('reportID')?.value
          && d.PARAM_TYPE === this.forms.repParamForm.get('paramType')?.value;
        }
        else {
          return this.rowData.PARAM_NAME.trim().toUpperCase() !== value && d.PARAM_NAME.trim().toUpperCase() === value
          && d.REPORT_ID === this.forms.repParamForm.get('reportID')?.value
          && d.PARAM_TYPE === this.forms.repParamForm.get('paramType')?.value;
        }
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupe: true } : null;
    }
  }

  paramValCheck() {
    if((this.forms.repParamForm.get('paramName')?.value?.trim()=='' || this.forms.repParamForm.get('paramName')?.value==null)
      || (this.forms.repParamForm.get('reportID')?.value?.trim()=='' || this.forms.repParamForm.get('reportID')?.value==null)
      || (this.forms.repParamForm.get('paramType')?.value=='' || this.forms.repParamForm.get('paramType')?.value==null)) {
      return false;
    } else {
      return true;
    }
  }

  paramNameDupCheck() {
    let hasDuplicate: boolean = this.repParamTblConfig.tblData.filter((d: any) => {
      if (!this.rowData && this.btns.repParam.updateDisabled){
        return d.PARAM_NAME.trim().toUpperCase() === this.forms.repParamForm.get('paramName')?.value.trim().toUpperCase()
        && d.REPORT_ID.trim().toUpperCase() === this.forms.repParamForm.get('reportID')?.value.trim().toUpperCase()
        && d.PARAM_TYPE.trim().toUpperCase() === this.forms.repParamForm.get('paramType')?.value.trim().toUpperCase();
      }
      else {
        return this.rowData.PARAM_NAME.trim().toUpperCase() !== this.forms.repParamForm.get('paramName')?.value.trim().toUpperCase() && d.PARAM_NAME.trim().toUpperCase() === this.forms.repParamForm.get('paramName')?.value.trim().toUpperCase()
        && d.REPORT_ID === this.forms.repParamForm.get('reportID')?.value
        && d.PARAM_TYPE === this.forms.repParamForm.get('paramType')?.value;
      }
    }).length > 0 ? true : false;
  
    if(hasDuplicate) {
      return false;
    } else {
      return true;
    }
  }

  paramValueReqCheck() {
    let hasRequiredFilled: boolean = false;

    if (this.forms.repParamForm.get('paramType')?.value=='' || this.forms.repParamForm.get('paramType')?.value==null) {
      hasRequiredFilled = true;
    }
    else if (this.forms.repParamForm.get('paramType')?.value === 'V' && this.forms.repParamForm.get('paramValueV')?.value?.trim()) {
      hasRequiredFilled = true;
    }
    else if (this.forms.repParamForm.get('paramType')?.value === 'N' && this.forms.repParamForm.get('paramValueN')?.value) {
      hasRequiredFilled = true;
    }
    else if (this.forms.repParamForm.get('paramType')?.value === 'D' && this.forms.repParamForm.get('paramValueD')?.value) {
      hasRequiredFilled = true;
    }
  
    return hasRequiredFilled;
  }

  paramNumCheck() {
    let resultCheck: boolean = true;
    if (parseFloat(this._formService.setToNumberWithDecimal(this.forms.repParamForm.get('paramValueN')?.value)) > 99999999999999.99) {
      resultCheck = false;
    }
    else {
      resultCheck = true;
    }
    return resultCheck;
  }

  public showErrorValidator = {
    repParam: () => {
      try {
        Object.keys(this.forms.repParamForm.controls).forEach(key => {
          const controlErrors = this.forms.repParamForm.get(key)?.errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              if (keyError == 'required' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
            });
          }
        });
        this._formService.showFormMsg("rep-param-error-message", this.errorMessage, "E");
        //this.openDialog('ERROR', this.errorMessage);
        setTimeout(()=>{                           
          this._formService.hideFormMsg("rep-param-error-message");
        }, 10000);
      }
      catch (e) {
        console.error(e);
      }
    },
  }

  onParamTypeChange(ev: any) {
    this.forms.repParamForm.patchValue({
      paramType: ev.target.value,
      paramValueV: null,
      paramValueN: null,
      paramValueD: null
    });
    this.checkParamFieldDisable(ev.target.value);
  }

  checkParamFieldDisable(paramType: any) {
    this.paramTypeEnabled = paramType;
    if (paramType === 'N') {
      this.forms.repParamForm.get('paramValueN')?.enable();
      this.forms.repParamForm.get('paramValueV')?.disable();
      this.forms.repParamForm.get('paramValueD')?.disable();
    }
    else if (paramType === 'V') {
      this.forms.repParamForm.get('paramValueN')?.disable();
      this.forms.repParamForm.get('paramValueV')?.enable();
      this.forms.repParamForm.get('paramValueD')?.disable();
    }
    else if (paramType === 'D') {
      this.forms.repParamForm.get('paramValueN')?.disable();
      this.forms.repParamForm.get('paramValueV')?.disable();
      this.forms.repParamForm.get('paramValueD')?.enable();
    }
    else {
      this.forms.repParamForm.get('paramValueN')?.disable();
      this.forms.repParamForm.get('paramValueV')?.disable();
      this.forms.repParamForm.get('paramValueD')?.disable();
    }
  }

  dateFilter(dateString: any) {
    try {
      if (dateString=='' || dateString==null) {
        return '';
      }
      else if (dateString=='0000-00-00' || dateString=='0000-00-00 12:00:00 AM' || dateString=='1970-01-01') {
        return '';
      }
      else {
        return formatDate(dateString, 'yyyy-MM-dd', 'en');
      }
    }
    catch(e) {
      return '';
    }
  }

  autoUppercase(ev: any) {
    try{
      ev.target.value = ev.target.value.toUpperCase();
    }
    catch(e) {
      console.error(e);
    }
  }

  allowNumericDigitsOnly(event: any) {
    try {
      if (/[0-9.]/.test(String.fromCharCode(event.keyCode))) {
        return true;
      }
      else {
        event.preventDefault();
        return false;
      }
    }
    catch (e) {
      console.error(e);
      return undefined;
    }
  }

  allowNumericAndSlashOnly(event: any) {
    try {
      if (/^([0-9/]+)$/.test(String.fromCharCode(event.keyCode))) {
        return true;
      }
      else {
        event.preventDefault();
        return false;
      }
    }
    catch (e) {
      console.error(e);
      return undefined; 
    }
  }

}
