import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe, formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';

import { ReportLovComponent } from '../../common/report-lov/report-lov.component';

@Component({
  selector: 'report-signatory-maintenance',
  templateUrl: './report-signatory-maintenance.component.html',
  styleUrls: ['./report-signatory-maintenance.component.css']
})
export class ReportSignatoryMaintenanceComponent implements OnInit, LeaveConfirmation {

  private moduleId: string = 'BMM034';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc?.toUpperCase() ?? "";

  datePipe = new DatePipe('en-us');
  minEffDate: Date = new Date();

  constructor(
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    public _formService: FormService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService
  ) { 
    this.userIdLoggedIn = this._userDetailService.userId ?? "";
  }

  ngOnInit(): void {
    this.getData.reportSignatory();
  }

  canDeactivate(): boolean | Observable<boolean> {
    if(this.tempData!='') {
      return false;
    } else {
      return true;
    }
  }

  public btns = {
    repSign: {
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      addDisabled: true,
      reportDisabled: true
    },
  }

  public selections = {
    activeTypes: [
      { cd: 'A', name: 'Yes' },
      { cd: 'I', name: 'No' },
    ]
  }

  public showForm: boolean = true;

  private rowData: any;
  private tempDataRow: any;
  private errorMessage: string = "";
  private userIdLoggedIn: string;
  private tempData: any = [];

  public forms = {
    repSignForm: this.fb.group({
      reportID: [null],
      reportName: [null],
      reportField: [{ value: '', disabled: 'true' }, [Validators.required]],
      effDateFrom: [{ value: '', disabled: 'true' }, [Validators.required]],
      effDateTo: [null],
      signatoryName: [null, [Validators.required, Validators.maxLength(50), this.noWhiteSpaceCheck()]],
      eSignature: [null, [Validators.maxLength(500)]],
      designation: [null, [Validators.maxLength(100)]],
      activeTag: ['A', [Validators.required]],
      remarks: [null, [Validators.maxLength(4000)]],
      userID: [{ value: '', disabled: 'true' }],
      lastUserUpdate: [{ value: '', disabled: 'true' }],
    }),
    resetRepSignForm: () => {
      this.forms.repSignForm.reset();
      this.forms.repSignForm.patchValue({
        activeTag: 'A'
      });
      this.btns.repSign.updateDisabled = this.btns.repSign.deleteDisabled = true;
      this.btns.repSign.reportDisabled = false;
      this.forms.repSignForm.get('effDateFrom')?.enable();
      this._formService.hideFormMsg("rep-sign-error-message");
    }
  }

  private repSignFormManipulate = {
    dbToForm: (data: any) => {
      return {
        reportID: data.REPORT_ID,
        reportName: data.REPORT_NAME,
        reportField: data.REPORT_ID + " - " + data.REPORT_NAME,
        activeTag: data.ACTIVE_TAG,
        effDateFrom: this.dateFilter(data.EFF_DATE_FROM),
        effDateTo: this.dateFilter(data.EFF_DATE_TO),
        signatoryName: data.SIGNATORY_NAME,
        eSignature: data.E_SIGNATURE,
        designation: data.DESIGNATION,
        remarks: data.REMARKS,
        userID: data.USER_ID,
        lastUserUpdate: data.LAST_USER_UPDATE,
      };
    },
    formToDb: (data: any, mode: any) => {
      return {
        REPORT_ID: data.reportID,
        REPORT_NAME: data.reportName,
        REPORT_FIELD: data.reportID + " - " + data.reportName,
        ACTIVE_TAG: data.activeTag,
        EFF_DATE_FROM: this.datePipe.transform(new Date(data.effDateFrom), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
        EFF_DATE_TO: data.effDateTo=='' || data.effDateTo==null ? null : this.datePipe.transform(new Date(data.effDateTo), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
        SIGNATORY_NAME: data.signatoryName.toUpperCase(),
        E_SIGNATURE: data.eSignature,
        DESIGNATION: data.designation,
        REMARKS: data.remarks,
        USER_ID: this.userIdLoggedIn,
        LAST_UPDATE: null,
        MODE: mode
      };
    },
    formToTemp: (data: any, mode: any) => {
      return {
        REPORT_ID: data.reportID,
        REPORT_NAME: data.reportName,
        REPORT_FIELD: data.reportID + " - " + data.reportName,
        ACTIVE_TAG: data.activeTag,
        EFF_DATE_FROM: this.datePipe.transform(new Date(data.effDateFrom), 'yyyy-MM-dd'),
        EFF_DATE_TO: data.effDateTo=='' || data.effDateTo==null ? null : this.datePipe.transform(new Date(data.effDateTo), 'yyyy-MM-dd'),
        SIGNATORY_NAME: data.signatoryName.toUpperCase(),
        E_SIGNATURE: data.eSignature,
        DESIGNATION: data.designation,
        REMARKS: data.remarks,
        USER_ID: this.userIdLoggedIn,
        LAST_UPDATE: null,
        MODE: mode
      };
    }
  }

  public repSignTblConfig = {
    cols: [
      {
        key: "REPORT_NAME",
        header: "Report",
        dataType: "string",
        width: '30%'
      },
      {
        key: "EFF_DATE_FROM",
        header: "Effective Date From",
        dataType: "date"
      },
      {
        key: "EFF_DATE_TO",
        header: "Effective Date To",
        dataType: "date"
      },
      {
        key: "SIGNATORY_NAME",
        header: "Signatory Name",
        dataType: "string",
        width: '26%'
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        width: '64px'
      }
    ],
    tblData: [] as any[],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  private getData = {
    reportSignatory: () => {
      try {
        this.repSignTblConfig.tblData = [];
        this.repSignTblConfig.loading = true;
        this._jDS.contorlLoading(true);
        this._api.getReportSignatory({
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          type: "MODULE"
        }).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                let content = JSON.parse(this._jDS.decrypt(data.response));
                this._jDS.contorlLoading(false);
                this.repSignTblConfig.tblData = content.data;
                this.btns.repSign.addDisabled = false;
                this.btns.repSign.reportDisabled = false;
                this.forms.repSignForm.get('effDateFrom')?.enable();

                this.repSignTblConfig.loading = false;
              });
            });
          },
          error: (error: any) => {
            this.repSignTblConfig.loading = false;
            this._jDS.contorlLoading(false);
          }
        });
      }
      catch (e) {
        console.error(e);
      }
    }
  }

  public onRowClick(ev: any) {
    try {
      ev.update = true;
      this.rowData = ev;
      this.populateFormFromTable();
    }
    catch (e) {
      this.forms.resetRepSignForm();
      this.rowData = null;
      //this.indexRow = null;
    }
  }

  private populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.repSign.updateDisabled = !data.update;
      this.btns.repSign.deleteDisabled = !data.temp;
      this.btns.repSign.reportDisabled = true;
      this.tempDataRow = !data.temp;
      this.forms.repSignForm.patchValue(this.repSignFormManipulate.dbToForm(data));
      this.forms.repSignForm.get('effDateFrom')?.disable();
    }
    catch (e) {
      console.error(e);
    }
  }

  public openLov = {
    report: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'REPORT',
          moduleId: this.moduleId
        },
      }
      this._matDialog.open(ReportLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button === 'OK') {
          if (output) {
            this.forms.repSignForm.patchValue({
              reportID: output.REPORT_ID,
              reportName: output.REPORT_NAME,
              reportField: `${output.REPORT_ID} - ${output.REPORT_NAME}`
            });
          }
          else {
            this.forms.repSignForm.patchValue({
              reportID: null,
              reportName: null,
              reportField: null
            });
          }
        }
      });
    }
  };

  public onAdd() {
    try {
      this._formService.hideFormMsg("rep-sign-error-message");
      if (!this.effDateToCheck()) {
        this._formService.showFormMsg("rep-sign-error-message", "Effectivity Date To cannot be earlier than Effective Date From. Please enter a different Date of Effectivity.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("rep-sign-error-message");
        }, 10000);
      }
      else if (!this.effDateFromCheck('I')) {
        this._formService.showFormMsg("rep-sign-error-message", "Date of Effectivity cannot be concurrent with another record under the same report. Please enter a different Date of Effectivity.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("rep-sign-error-message");
        }, 10000);
      }
      else if (this.forms.repSignForm.valid) {
        let rowToBeAdded: { [key: string]: any } = this.repSignFormManipulate.formToDb(this.forms.repSignForm.value, 'I');
        let rowToBeAddedTemp: { [key: string]: any } = this.repSignFormManipulate.formToTemp(this.forms.repSignForm.value, 'I');
        rowToBeAdded.temp = true;
        this.tempData.push(rowToBeAddedTemp);
        this.repSignTblConfig.tblData = [rowToBeAdded, ...this.repSignTblConfig.tblData];
        this.forms.resetRepSignForm();
        this.btns.repSign.saveDisabled = false;
      }
      else {
        this.showErrorValidator.repSign();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public onDelete() {
    try {
      let clickData = this.rowData;
      clickData.EFF_DATE_FROM = this.datePipe.transform(new Date(clickData.EFF_DATE_FROM), 'yyyy-MM-dd');
      clickData.EFF_DATE_TO = clickData.EFF_DATE_TO=='' || clickData.EFF_DATE_TO==null ? null : this.datePipe.transform(new Date(clickData.EFF_DATE_TO), 'yyyy-MM-dd');
      this.repSignTblConfig.tblData = this.repSignTblConfig.tblData.filter((d: any) => {
        return d !== this.rowData;
      });
      this.tempData = this.tempData.filter(
        function(e: any) {
          return e.REPORT_ID != clickData.REPORT_ID || e.EFF_DATE_FROM != clickData.EFF_DATE_FROM || e.EFF_DATE_TO != clickData.EFF_DATE_TO;
        }
      );
      this.forms.resetRepSignForm();
    }
    catch (e) {
      console.error(e);
    }
  }

  public onUpdate() {
    try {
      this._formService.hideFormMsg("rep-sign-error-message");
      if (!this.effDateToCheck()) {
        this._formService.showFormMsg("rep-sign-error-message", "Effectivity Date To cannot be earlier than Effective Date From. Please enter a different Date of Effectivity.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("rep-sign-error-message");
        }, 10000);
      }
      else if (!this.effDateFromCheck('U')) {
        this._formService.showFormMsg("rep-sign-error-message", "Date of Effectivity cannot be concurrent with another record under the same report. Please enter a different Date of Effectivity.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("rep-sign-error-message");
        }, 10000);
      }
      else if (this.forms.repSignForm.valid) {
        this.forms.repSignForm.get('effDateFrom')?.enable();
        let clickData = this.rowData;
        clickData.EFF_DATE_FROM = this.datePipe.transform(new Date(clickData.EFF_DATE_FROM), 'yyyy-MM-dd');
        clickData.EFF_DATE_TO = clickData.EFF_DATE_TO=='' || clickData.EFF_DATE_TO==null ? null : this.datePipe.transform(new Date(clickData.EFF_DATE_TO), 'yyyy-MM-dd');
        let toBeUpdatedIndex = this.repSignTblConfig.tblData.indexOf(this.rowData);
        let repSignCdRowValue = this.rowData.REPORT_ID;
        let rowToBeUpdated: { [key: string]: any } = this.repSignFormManipulate.formToDb(this.forms.repSignForm.value, 'U');
        let rowToBeUpdatedTemp: { [key: string]: any } = this.repSignFormManipulate.formToTemp(this.forms.repSignForm.value, 'U');
        this.forms.repSignForm.get('effDateFrom')?.disable();
        let tempInsertChecker = this.tempData.find(
          (m: { REPORT_ID: any, EFF_DATE_FROM: any, EFF_DATE_TO: any }) => 
            m.REPORT_ID == clickData.REPORT_ID && m.EFF_DATE_FROM == clickData.EFF_DATE_FROM && clickData.MODE == 'I');
        let tempUpdateChecker = this.tempData.find(
          (m: { REPORT_ID: any, EFF_DATE_FROM: any, EFF_DATE_TO: any }) => 
            m.REPORT_ID == clickData.REPORT_ID && m.EFF_DATE_FROM == clickData.EFF_DATE_FROM && clickData.MODE == 'U');
        if (this.rowData.temp) {
          rowToBeUpdated.temp = true;
        }
        else {
          rowToBeUpdated.onDbButUpdatedTemp = true;
          rowToBeUpdated.REPORT_ID = repSignCdRowValue;
          rowToBeUpdated.REPORT_FIELD = repSignCdRowValue + " - " + rowToBeUpdated.REPORT_NAME;
          rowToBeUpdatedTemp.REPORT_ID = repSignCdRowValue;
          rowToBeUpdatedTemp.REPORT_FIELD = repSignCdRowValue + " - " + rowToBeUpdatedTemp.REPORT_NAME;
        }
        if (tempInsertChecker!=null && tempInsertChecker!='') {
          //tempInsertChecker = rowToBeUpdated;
          tempInsertChecker.ACTIVE_TAG = rowToBeUpdatedTemp.ACTIVE_TAG;
          tempInsertChecker.EFF_DATE_TO = rowToBeUpdatedTemp.EFF_DATE_TO;
          tempInsertChecker.SIGNATORY_NAME = rowToBeUpdatedTemp.SIGNATORY_NAME;
          tempInsertChecker.E_SIGNATURE = rowToBeUpdatedTemp.E_SIGNATURE;
          tempInsertChecker.DESIGNATION = rowToBeUpdatedTemp.DESIGNATION;
          tempInsertChecker.REMARKS = rowToBeUpdatedTemp.REMARKS;
          tempInsertChecker.USER_ID = rowToBeUpdatedTemp.USER_ID;
          tempInsertChecker.MODE = 'I';
          rowToBeUpdated.MODE = 'I';
          rowToBeUpdatedTemp.MODE = 'I';
        }
        else if (tempUpdateChecker!=null && tempUpdateChecker!='') {
          tempUpdateChecker.ACTIVE_TAG = rowToBeUpdatedTemp.ACTIVE_TAG;
          tempUpdateChecker.EFF_DATE_TO = rowToBeUpdatedTemp.EFF_DATE_TO;
          tempUpdateChecker.SIGNATORY_NAME = rowToBeUpdatedTemp.SIGNATORY_NAME;
          tempUpdateChecker.E_SIGNATURE = rowToBeUpdatedTemp.E_SIGNATURE;
          tempUpdateChecker.DESIGNATION = rowToBeUpdatedTemp.DESIGNATION;
          tempUpdateChecker.REMARKS = rowToBeUpdatedTemp.REMARKS;
          tempUpdateChecker.USER_ID = rowToBeUpdatedTemp.USER_ID;
          tempUpdateChecker.MODE = 'U';
        }
        else { 
          this.tempData.push(rowToBeUpdatedTemp);
        }
        this.forms.resetRepSignForm();
        this.repSignTblConfig.tblData[toBeUpdatedIndex] = rowToBeUpdated;
        this.btns.repSign.saveDisabled = false;
        this.repSignTblConfig.tblData = [... this.repSignTblConfig.tblData];
      }
      else {
        this.showErrorValidator.repSign();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public onSave() {
    try {
      this.btns.repSign.saveDisabled = true;
      this.showForm = false;
      this._formService.showFormLoader(null, "rep-sign-maintenance-loading", "Saving.<br>Please wait ...", null, null);
      if (this.tempData.length > 0) {
        this._api.saveReportSignatory(this.tempData).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                if (data.status === 'SUCCESS') {
                  this.onComplete.repSign();
                }
                else {
                  this.onComplete.repSignError();
                }
              });
            });
          },
          error: (data: any) => {
            this.onComplete.repSignError();
          },
        });
      }
      else
        this.onComplete.repSign();
    }
    catch (e) {
      console.error(e);
    }
  }

  private onComplete = {
    repSign: () => {
      this.tempData = [];
      this.getData.reportSignatory();
      this.btns.repSign.saveDisabled = true;
      this.showForm = true;
      this._formService.hideFormLoader("rep-sign-maintenance-loading");
      this.forms.resetRepSignForm();
      this.openDialog('SUCCESS', 'Report Signatory details successfully saved');
    },
    repSignError: () => {
      this.showForm = true;
      this.btns.repSign.saveDisabled = false;
      this.openDialog('FAILED', 'Report Signatory details saving failed');
      this._formService.hideFormLoader("rep-sign-maintenance-loading");
    },
  }

  openDialog(title: any, message: any) {
    try {
      this.showForm = true;
      this._formService.hideFormLoader("rep-sign-maintenance-loading");
      this._matDialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        } 
      });
    } catch(e) {
      console.error(e);
    }
  }

  dateFilter(dateString: any) {
    try {
      if (dateString=='' || dateString==null) {
        return '';
      }
      else if (dateString=='0000-00-00' || dateString=='0000-00-00 12:00:00 AM' || dateString=='1970-01-01') {
        return '';
      }
      else {
        return formatDate(dateString, 'yyyy-MM-dd', 'en');
      }
    }
    catch(e) {
      return '';
    }
  }

  autoUppercase(ev: any) {
    try{
      ev.target.value = ev.target.value.toUpperCase();
    }
    catch(e) {
      console.error(e);
    }
  }

  effDateToCheck() {
    let effDateFromTemp = this.datePipe.transform(new Date(this.forms.repSignForm.get('effDateFrom')?.value), 'yyyy-MM-dd');
    let effDateToTemp = this.forms.repSignForm.get('effDateTo')?.value=='' || this.forms.repSignForm.get('effDateTo')?.value==null ? null : this.datePipe.transform(new Date(this.forms.repSignForm.get('effDateTo')?.value), 'yyyy-MM-dd');
  
    if(effDateToTemp!=null && effDateFromTemp!=null) {
      if(effDateToTemp>effDateFromTemp) {
        return true;
      }
      else {
        return false;
      }
    } else {
      return true;
    }
  }

  effDateFromCheck(mode: any) {
    if (mode === 'U') {
      this.forms.repSignForm.get('effDateFrom')?.enable();
    }
    let reportIDTemp = this.forms.repSignForm.get('reportID')?.value;
    let effDateFromTemp = this.datePipe.transform(new Date(this.forms.repSignForm.get('effDateFrom')?.value), 'yyyy-MM-dd');
    let effDateToTemp = this.forms.repSignForm.get('effDateTo')?.value=='' || this.forms.repSignForm.get('effDateTo')?.value==null ? null : this.datePipe.transform(new Date(this.forms.repSignForm.get('effDateTo')?.value), 'yyyy-MM-dd');
    let effDateFromField: any;
    let resultCheck: boolean = true;
    if (mode === 'I') {
      effDateFromField = this.repSignTblConfig.tblData.filter(function(e: any) {
        return e.REPORT_ID==reportIDTemp;
      });
    }
    else if (mode === 'U') {
      let effDateFromTemp2 = new Date(this.forms.repSignForm.get('effDateFrom')?.value);
      effDateFromField = this.repSignTblConfig.tblData.filter(function(e: any) {
        return e.REPORT_ID==reportIDTemp && (new Date(e.EFF_DATE_FROM).getMonth()!==effDateFromTemp2.getMonth() || new Date(e.EFF_DATE_FROM).getDate()!==effDateFromTemp2.getDate() || new Date(e.EFF_DATE_FROM).getFullYear()!==effDateFromTemp2.getFullYear());
      });
    }
    for (let r of effDateFromField) {
      let effDateFromTbl = this.datePipe.transform(new Date(r.EFF_DATE_FROM), 'yyyy-MM-dd');
      let effDateToTbl = r.EFF_DATE_TO=='' || r.EFF_DATE_TO==null ? null : this.datePipe.transform(new Date(r.EFF_DATE_TO), 'yyyy-MM-dd');
      //nope is return false
      if (!effDateToTbl && !effDateToTemp) {
        resultCheck = false;
        break;
      } 
      else if (!effDateToTbl && effDateToTemp && effDateFromTemp && effDateFromTbl) {
        if (effDateFromTemp >= effDateFromTbl) {
          resultCheck = false;
          break;
        } 
        else if (effDateFromTemp < effDateFromTbl && effDateToTemp >= effDateFromTbl) {
          resultCheck = false;
          break;
        }
      }
      else if (!effDateToTemp && effDateFromTemp && effDateFromTbl && effDateToTbl) {
        if (effDateFromTemp <= effDateToTbl) {
          resultCheck = false;
          break;
        }
      }
      else if (effDateToTemp && effDateFromTemp && effDateFromTbl && effDateToTbl) {
        if (effDateToTemp >= effDateFromTbl && effDateToTemp <= effDateToTbl) {
          resultCheck = false;
          break;
        }
        else if (effDateFromTemp >= effDateFromTbl && effDateFromTemp <= effDateToTbl) {
          resultCheck = false;
          break;
        }
        else if (effDateFromTemp >= effDateFromTbl && effDateToTemp <= effDateToTbl) {
          resultCheck = false;
          break;
        }
        else if (effDateFromTemp <= effDateFromTbl && effDateToTemp >= effDateToTbl) {
          resultCheck = false;
          break;
        }
      }
      else {
        resultCheck = false;
      }
    }
    if (mode === 'U') {
      this.forms.repSignForm.get('effDateFrom')?.disable();
    }
    return resultCheck;
  }

  allowNumericAndSlashOnly(event: any) {
    try {
      if (/^([0-9/]+)$/.test(String.fromCharCode(event.keyCode))) {
        return true;
      }
      else {
        event.preventDefault();
        return false;
      }
    }
    catch (e) {
      console.error(e);
      return undefined; 
    }
  }

  noWhiteSpaceCheck(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: true };
    }
  }

  public showErrorValidator = {
    repSign: () => {
      try {
        Object.keys(this.forms.repSignForm.controls).forEach(key => {
          const controlErrors = this.forms.repSignForm.get(key)?.errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              if (keyError == 'whitespace' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
              else if (keyError == 'matDatepickerParse')
                this.errorMessage = "Invalid date format. Kindly input as MM/DD/YYYY";
              else if (keyError == 'hasDupe' && controlErrors[keyError] === true)
                this.errorMessage = "Date of Effectivity cannot be concurrent with another record under the same report. Please enter a different Date of Effectivity.";
              else if (keyError == 'required' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
            });
          }
        });
        this._formService.showFormMsg("rep-sign-error-message", this.errorMessage, "E");
        //this.openDialog('ERROR', this.errorMessage);
        setTimeout(()=>{                           
          this._formService.hideFormMsg("rep-sign-error-message");
        }, 10000);
      }
      catch (e) {
        console.error(e);
      }
    },
  }

}
