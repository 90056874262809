import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { CustomValidators } from 'src/app/utils/custom-validator';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';
import { PlanLovComponent } from '../../common/plan-lov/plan-lov.component';

@Component({
  selector: 'plan-notification-maintenance',
  templateUrl: './plan-notification-maintenance.component.html',
  styleUrls: ['./plan-notification-maintenance.component.css']
})
export class PlanNotificationMaintenanceComponent implements OnInit, LeaveConfirmation {

  private moduleId: string = 'BMM159';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc ?? "";

  constructor(
    public _formService: FormService,
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService,
    private _appMessageService: AppMessageService,
  ) {
    this.userIdLoggedIn = this._userDetailService.userId ?? "";
  }

  public canDeactivate(): boolean | Observable<boolean> {
    return !(this.planNotifTblConfig.tblData?.filter((row: any) => ['I', 'U'].includes(row.operation)).length > 0);
  }

  public btns = {
    planNotif: {
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      addDisabled: true,
      formLovSearchDisabled: true,
      screenLovSearchDisabled: true,
    },
    filterLov: {
      lineLovSearchDisabled: false,
      sublineLovSearchDisabled: true,
      planLovSearchDisabled: true,
    }
  };

  public showForm: boolean = true;

  //* Data Stuffs */
  private rowData: any;
  private errorMessage: string = "";
  private userIdLoggedIn: string;
  //? set selectedRow to [] to unselect the table
  public selectedRow!: any[];

  public forms = {
    filterLovForm: this.fb.group({
      lineCd: [null],
      lineName: [null],
      sublineCd: [null],
      sublineName: [null],
      planCd: [null],
      planName: [null],
      lineCdDisp: [{ value: '', disabled: 'true' }],
      sublineCdDisp: [{ value: '', disabled: 'true' }],
      planDisp: [{ value: '', disabled: 'true' }],
    }),
    planNotifForm: this.fb.group({
      lineCd: [null],
      sublineCd: [null],
      planCd: [null],
      screenCd: [null, [Validators.required, this.checkDuplicateScreenCd()]],
      screenDesc: [null],
      notifPath: [null, [Validators.required, CustomValidators.requiredTrim]],
      notifTitle: [null, [Validators.required, CustomValidators.requiredTrim]],
      active: ["A", [Validators.required]],
      remarks: [null],
      lastUpdateUser: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }],
    }),
    resetPlanNotifForm: () => {
      this.forms.planNotifForm.reset();
      this.btns.planNotif.updateDisabled = true;
      this.btns.planNotif.deleteDisabled = true;

      this.forms.planNotifForm.patchValue({
        active: 'A',
      });

      this._formService.hideFormMsg("plan-notification-error-message");
      this.rowData = null;
      this.forms.planNotifForm.get('screenCd')?.enable();
    },
    resetFilterLovForm: () => {
      this.forms.filterLovForm.reset();
    },
    resetAllForms: () => {
      this.forms.resetFilterLovForm();
      this.forms.resetPlanNotifForm();
    }
  }

  private planNotifFormManipulate = {
    dbToForm: (data: any) => {
      return {
        lineCd: data.LINE_CD,
        sublineCd: data.SUBLINE_CD,
        planCd: data.PLAN_CD,
        screenCd: data.SCREEN_CD,
        screenDesc: data.SCREEN_DESC,
        active: data.ACTIVE_TAG,
        notifPath: data.NOTIF_PATH,
        notifTitle: data.NOTIF_TITLE,
        remarks: data.REMARKS,
        lastUpdateUser: data.USER_ID,
        lastUpdate: data.LAST_UPDATE,
      };
    },
    formToDb: (data: any) => {
      return {
        LINE_CD: data.lineCd,
        SUBLINE_CD: data.sublineCd,
        PLAN_CD: data.planCd,
        SCREEN_CD: data.screenCd,
        SCREEN_DESC: this.selections.screenCds.find((a: any) => a.cd === data.screenCd)?.desc,
        ACTIVE_TAG: data.active,
        NOTIF_PATH: data.notifPath,
        NOTIF_TITLE: data.notifTitle,
        REMARKS: data.remarks,
        USER_ID: null,
        LAST_UPDATE: null,
      };
    },
  }

  public planNotifTblConfig: any = {
    cols: [
      {
        key: "SCREEN_CD",
        header: "Screen Code",
        dataType: "string",
      },
      {
        key: "SCREEN_DESC",
        header: "Screen",
        dataType: "string"
      },
      {
        key: "NOTIF_PATH",
        header: "Notification Path",
        dataType: "string"
      },
      {
        key: "NOTIF_TITLE",
        header: "Notification Title",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        width: '48px',
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  ngOnInit(): void {
  }

  public openLov = {
    line: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'LINE',
          moduleId: this.moduleId
        },
      }
      this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button?.toUpperCase() === 'OK') {
          this.forms.resetPlanNotifForm();
          if (output) {
            this.forms.filterLovForm.patchValue({
              lineCd: output.LINE_CD,
              lineName: output.LINE_NAME,
              lineCdDisp: `${output.LINE_CD} - ${output.LINE_NAME}`,
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null,
              planCd: null,
              planName: null,
              planDisp: null,
            });
            this.btns.planNotif.addDisabled = true;
            this.btns.filterLov.sublineLovSearchDisabled = false;
            this.btns.filterLov.planLovSearchDisabled = true;
          }
          else {
            this.forms.filterLovForm.patchValue({
              lineCd: null,
              lineName: null,
              lineCdDisp: null,
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null,
              planCd: null,
              planName: null,
              planDisp: null,
            });
            this.btns.planNotif.addDisabled = this.btns.planNotif.formLovSearchDisabled = true;
            this.btns.filterLov.sublineLovSearchDisabled = true;
            this.btns.filterLov.planLovSearchDisabled = true;
          }
          this.getData.planNotif();
        }
      });
    },
    subline: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          lineCd: this.forms.filterLovForm.get('lineCd')?.value,
          table: 'SUBLINE',
          moduleId: this.moduleId
        },
      }
      this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button?.toUpperCase() === 'OK') {
          this.forms.resetPlanNotifForm();
          if (output) {
            this.forms.filterLovForm.patchValue({
              lineCd: output.LINE_CD,
              lineName: output.LINE_NAME,
              lineCdDisp: `${output.LINE_CD} - ${output.LINE_NAME}`,
              sublineCd: output.SUBLINE_CD,
              sublineName: output.SUBLINE_NAME,
              sublineCdDisp: `${output.SUBLINE_CD} - ${output.SUBLINE_NAME}`,
              planCd: null,
              planName: null,
              planDisp: null,
            });
            this.btns.filterLov.planLovSearchDisabled = false;
          }
          else {
            this.forms.filterLovForm.patchValue({
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null,
              planCd: null,
              planName: null,
              planDisp: null,
            });
            this.btns.planNotif.addDisabled = this.btns.planNotif.formLovSearchDisabled = true;
            this.btns.filterLov.planLovSearchDisabled = true;
          }
          this.getData.planNotif();
        }
      });
    },
    plan: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'PLAN',
          moduleId: this.moduleId,
          lineCd: this.forms.filterLovForm.get('lineCd')?.value,
          sublineCd: this.forms.filterLovForm.get('sublineCd')?.value,
          lpad: 2,
        },
      };
      this._matDialog.open(PlanLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button?.toUpperCase() === 'OK') {
          this.forms.resetPlanNotifForm();
          if (output.PLAN_CD) {
            this.forms.filterLovForm.patchValue({
              planCd: output.PLAN_CD,
              planName: output.PLAN_NAME,
              planDisp: `${output.PLAN_CD} - ${output.PLAN_NAME}`,
            });
          }
          else {
            this.forms.filterLovForm.patchValue({
              planCd: null,
              planName: null,
              planDisp: null,
            });
            this.btns.planNotif.addDisabled = this.btns.planNotif.formLovSearchDisabled = true;
          }
          this.getData.planNotif();
        }
      });
    },
  }

  private getData = {
    planNotif: () => {
      try {
        this.planNotifTblConfig.tblData = [];
        if (this.forms.filterLovForm.get('lineCd')?.value
          && this.forms.filterLovForm.get('sublineCd')?.value
          && this.forms.filterLovForm.get('planCd')?.value) {
          this._jDS.contorlLoading(true);
          this.planNotifTblConfig.loading = true;
          this._api.getPlanNotification({
            lineCd: this.forms.filterLovForm.get('lineCd')?.value,
            sublineCd: this.forms.filterLovForm.get('sublineCd')?.value,
            planCd: this.forms.filterLovForm.get('planCd')?.value,
            moduleId: this.moduleId,
            userId: this._userDetailService.userId,
            type: "MODULE",
          }).subscribe({
            next: (data: any) => {
              this._securityService.checkRequestKeyResponse(data, () => {
                this._securityService.hasValidCsrfToken(data, () => {
                  if (data.status === "SUCCESS") {
                    let content = JSON.parse(this._jDS.decrypt(data?.response));
                    this.planNotifTblConfig.tblData = content.data;
                    this._jDS.contorlLoading(false);
                    this.planNotifTblConfig.loading = false;
                    this.btns.planNotif.addDisabled = this.btns.planNotif.formLovSearchDisabled = false;
                  }
                  else {

                    this._jDS.contorlLoading(false);
                    this.planNotifTblConfig.loading = false;
                    this.btns.planNotif.addDisabled = this.btns.planNotif.formLovSearchDisabled = true;
                  }
                });
              });
            },
            error: (e: any) => {
              this._securityService.checkRequestKeyResponse(e, () => {

                this._jDS.contorlLoading(false);
                this.planNotifTblConfig.loading = false;
                this.btns.planNotif.addDisabled = this.btns.planNotif.formLovSearchDisabled = true;
              });
            }
          });
        }
        else {
          this.planNotifTblConfig.loading = false;
          this.btns.planNotif.addDisabled = this.btns.planNotif.formLovSearchDisabled = true;
        }
      }
      catch (e) {
    console.error(e);

      }
    },
    screen: () => {
      const filteredScreen = this._jDS.data.refCds.filter((data: any) => data.rvDomain === 'SCREEN_CD').map((data: any) => {
        return {
          cd: data.rvLowValue,
          disp: `${data.rvLowValue} - ${data.rvMeaning}`,
          desc: data.rvMeaning
        };
      });
      return filteredScreen;
    },
  }

  public selections = {
    screenCds: [
      { cd: null, disp: '', desc: '' },
      ...this.getData.screen()
    ],
    activeTypes: [
      { cd: 'A', name: 'Yes' },
      { cd: 'I', name: 'No' },
    ],
  };

  public onRowClick(ev: any) {
    try {
      if (ev) {
        this.rowData = ev;
        this.forms.planNotifForm.get('screenCd')?.disable();
        this.populateFormFromTable();
      }
      else {
        this.rowData = null;
        this.forms.resetPlanNotifForm();
      }
    }
    catch (e) {
    console.error(e);
      this.forms.resetPlanNotifForm();
      this.rowData = null;
    }
  }

  private populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.planNotif.updateDisabled = false;
      this.btns.planNotif.deleteDisabled = !(this.rowData?.operation === 'I');
      this.forms.planNotifForm.patchValue(this.planNotifFormManipulate.dbToForm(data));
    }
    catch (e) {
    console.error(e);

    }
  }

  public onAdd(): void {
    try {
      this._formService.hideFormMsg("plan-notification-error-message");
      if (this.forms.planNotifForm.valid) {
        this.forms.planNotifForm.patchValue({
          lineCd: this.forms.filterLovForm.get('lineCd')?.value,
          sublineCd: this.forms.filterLovForm.get('sublineCd')?.value,
          planCd: this.forms.filterLovForm.get('planCd')?.value,
        });
        let rowToBeAdded: { [key: string]: any } = this.planNotifFormManipulate.formToDb(this.forms.planNotifForm.getRawValue());
        rowToBeAdded.operation = 'I';
        this.planNotifTblConfig.tblData = [rowToBeAdded, ...this.planNotifTblConfig.tblData];
        this.forms.resetPlanNotifForm();
        this.btns.planNotif.saveDisabled = false;
      }
      else {
        this.showErrorValidator.planNotif();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onUpdate(): void {
    try {
      this._formService.hideFormMsg("plan-notification-error-message");
      if (this.forms.planNotifForm.valid) {
        const indexOfRow = this.planNotifTblConfig.tblData.indexOf(this.rowData);
        let rowToBeUpdated: { [key: string]: any } = this.planNotifFormManipulate.formToDb(this.forms.planNotifForm.getRawValue());
        rowToBeUpdated.operation = (this.rowData.operation === 'I') ? 'I' : 'U';
        this.forms.resetPlanNotifForm();
        this.planNotifTblConfig.tblData[indexOfRow] = rowToBeUpdated;
        this.selectedRow = [];
        this.btns.planNotif.saveDisabled = false;
        this.planNotifTblConfig.tblData = [...this.planNotifTblConfig.tblData];
      }
      else {
        this.showErrorValidator.planNotif();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onDelete(): void {
    try {
      this.planNotifTblConfig.tblData = this.planNotifTblConfig.tblData.filter((row: any) => {
        return row !== this.rowData;
      });
      this.forms.resetPlanNotifForm();
      this.btns.planNotif.saveDisabled = false;
    }
    catch (e) {
    console.error(e);

    }
  }

  public onSave(): void {
    try {
      this._jDS.contorlLoading(true);
      this.showForm = false;
      this._formService.showFormLoader(null, "plan-notification-maintenance-loading", "Saving.<br>Please wait ...", null, null, true);
      let toBeSaved = this.planNotifTblConfig.tblData.filter((row: any) => ['I', 'U'].includes(row.operation));
      if (toBeSaved.length > 0) {
        this._api.savePlanNotification(toBeSaved).subscribe({
          next: (response: any) => {
            this._securityService.checkRequestKeyResponse(response, () => {
              this._securityService.hasValidCsrfToken(response, () => {
                if (response.status === 'SUCCESS') {
                  this.onComplete.planNotif();
                }
                else {
                  this.onComplete.planNotifError();
                }
              });
            });
          },
          error: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {

              this.onComplete.planNotifError();
            });
          }
        });
      }
      else {
        this.onComplete.planNotif();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  private onComplete = {
    planNotif: () => {
      this._jDS.contorlLoading(false);
      this._formService.hideFormLoader("plan-notification-maintenance-loading");
      this.openDialog('SUCCESS', this.moduleName.replace('Maintenance', ''), 'saved');
      this.btns.planNotif.saveDisabled = true;
      this.showForm = true;
      this.forms.resetPlanNotifForm();
    },
    planNotifError: () => {
      this._jDS.contorlLoading(false);
      this.openDialog('FAILED', this.moduleName.replace('Maintenance', ''), 'saving');
      this._formService.hideFormLoader("plan-notification-maintenance-loading");
      this.showForm = true;
      this.forms.resetPlanNotifForm();
    },
  }

  openDialog(title: string, contentTitle: string, contentDetail: string) {
    try {
      this._matDialog.open(DialogMsgComponent, {
        disableClose: true,
        width: '512px',
        data: {
          title: title,
          content:
            title === 'SUCCESS'
              ? `${contentTitle} details successfully ${contentDetail}!`
              : `${contentTitle} details ${contentDetail} failed!`,
        },
      }).afterClosed().subscribe(() => {
        if (title === 'SUCCESS') {
          this.getData.planNotif();
        }
      });
    }
    catch (e) {
    console.error(e);

    }
  }

  //* Validator Stuffs Down here */

  private checkDuplicateScreenCd(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.trim()?.toUpperCase();
      if (!value) return null;
      let hasDuplicate: boolean = this.planNotifTblConfig?.tblData.filter((d: any) => {
        if (!this.rowData)
          return d['SCREEN_CD']?.trim().toUpperCase() === value;
        else
          return this.rowData['SCREEN_CD']?.trim()?.toUpperCase() !== value && d['SCREEN_CD']?.trim()?.toUpperCase() === value;
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupe: true } : null;
    }
  }

  public showErrorValidator = {
    planNotif: () => {
      try {
        Object.keys(this.forms.planNotifForm.controls).forEach(key => {
          const controlErrors = this.forms.planNotifForm.get(key)?.errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              if (keyError == 'hasDupe' && controlErrors[keyError] === true)
                this.errorMessage = "Screen already exists. Please use a unique Screen.";
              else if (keyError == 'required' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
            });
          }
        });
        this._formService.showFormMsg("plan-notification-error-message", this.errorMessage, "E");
      }
      catch (e) {
    console.error(e);

      }
    },
  }
}
