import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

@Component({
  selector: 'view-policies-lov',
  templateUrl: './view-policies-lov.component.html',
  styleUrls: ['./view-policies-lov.component.css']
})
export class ViewPoliciesLovComponent implements OnInit {

  public tableSelected: any;

  public titleLov: string = "VIEW POLICIES";

  table: any = {
      cols: [
          {
              key: "policy_no",
              header: "Policy No.",
              dataType: "string",
              // width: '15%'
          },
          {
              key: "cert_no",
              header: "Certificate No.",
              dataType: "string",
              // width: '85%'
          },
          {
              key: "insured_name",
              header: "Insured Name",
              dataType: "string",
              // width: '85%'
          },
          {
              key: "eff_date",
              header: "Effective Date",
              dataType: "string",
              // width: '85%'
          },
          {
              key: "status",
              header: "Status",
              dataType: "string",
              // width: '85%'
          },
      ],
      tblData: [],
      selection: "single",
      paginator: true,
      rowsPerPage: 10,
      lazy: false,
      totalRecords: 15,
      loading: false,
  };

  constructor(
    private dialogRef: MatDialogRef<ViewPoliciesLovComponent>,
    private _api: APICallService,
    private securityService: SecurityService,
    private jsonDataService: JsonDataService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    @Inject(MAT_DIALOG_DATA) public fromMainData: any,
  ) { 
    this.getData();
  }

  ngOnInit(): void {

  }

  getData() {
      try {
          this.table.loading = true;
          this.jsonDataService.contorlLoading(true);
          this._api.getOpenPolicySeqNoViewPolicies({
              moduleId: this.fromMainData.moduleId,
              userId: this.userDetailService.userId,
              type: "LOV",
              param: +this.fromMainData.selectedRow?.OPEN_POLICY_ID
          }).subscribe({
              next: (response: any) => {
                  this.securityService.checkRequestKeyResponse(response, () => {
                      this.securityService.hasValidCsrfToken(response, () => {
                          let resContent = JSON.parse(this.jsonDataService.decrypt(response.response));
                          this.jsonDataService.contorlLoading(false);
                          this.table.tblData = resContent.data;
                          this.table.loading = false;
                          
                      });
                  });
              },
              error: (e: any) => {

                  this.table.loading = false;
                  this.jsonDataService.contorlLoading(false);
                  this.appMessageService.showAppMessage(e, "error");

              }
          });
      } catch (e) {
    console.error(e);

          this.table.loading = false;
          this.jsonDataService.contorlLoading(false);

      }
  }

  onClose() {
    try {
      this.dialogRef.close();
    } catch (e) {
    console.error(e);

    }
  }
}
