import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { ClientGroupLovDialog } from '../../common/client-group-lov-dialog/client-group-lov-dialog.component';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { UserLovDialog } from '../../common/user-lov-dialog/user-lov-dialog.component';

@Component({
    selector: 'applicable-client-group-per-user-id',
    templateUrl: './applicable-client-group-per-user-id.component.html',
    styleUrls: ['./applicable-client-group-per-user-id.component.css']
})
export class ApplicableClientGroupPerUserIDComponent implements OnInit, LeaveConfirmation {

    datePipe = new DatePipe('en-us');

    moduleId: string = "BMM153";

    currentUser: string = this.userDetailsService.userId || "";

    itemLevel1Disabled: Boolean = true;
    saveDisabled: Boolean = true;
    deleteDisabled: Boolean = true;
    isLineSelected: Boolean = false;
    validated: Boolean = false;
    formHider: Boolean = false;

    rowClickedData: any;

    currTblData: any = [];
    tempAddedData: any = [];
    tempUpdatedData: any = [];

    paramForm = this.fb.group({
        userCd: [null],
        userName: [null],
        userField: [null],
        clientGroupCd: [null],
        clientGroupName: [null],
        clientGroupField: [null],
        itemLevel1Group: [null],
        itemLevel2Group: [null]
    });

    currForm = this.fb.group({
        activeTag: ['A'],
        effDate: [null],
        expDate: [null],
        remarks: [null],
        lastUpdateUser: [null],
        lastUpdate: [null]
    });

    currTblConfig: any = {
        cols: [
            {
                key: "EFF_DATE_FROM",
                header: "Effective Date",
                dataType: "date",
            },
            {
                key: "EFF_DATE_TO",
                header: "Expiry Date",
                dataType: "date",
            },
            {
                key: "ACTIVE_TAG",
                header: "A",
                dataType: "checkbox",
                disabled: true,
                width: '64px'
            },
        ],
        tblData: [] as any[],
        selection: "single",
        paginator: true,
        rowsPerPage: 10,
        lazy: false,
        totalRecords: 15,
        loading: false
    }

    constructor(
        private fb: FormBuilder,
        private jsonDataService: JsonDataService,
        private apiCallService: APICallService,
        private userDetailsService: UserDetailsService,
        private appMessageService: AppMessageService,
        private formService: FormService,
        private _Dialog: MatDialog,
        private securityService: SecurityService
    ) { }

    ngOnInit(): void {
        try {
            this.getApplicableClientGroupPerUserID();
        } catch (e) {
    console.error(e);
        }
    }

    canDeactivate(): Observable<boolean> | boolean {
        if (this.tempAddedData != '' || this.tempUpdatedData != '') {
            return false;
        } else {
            return true;
        }
    }

    initOnGet(): void {
        try {
            this.onRowClick(null);
            this.saveDisabled = this.saveChecker();
            this.filterTblData();
        } catch (e) {
    console.error(e);
        }
    }

    public selections = {
        activeTag: [
            { cd: 'A', name: 'Yes' },
            { cd: 'I', name: 'No' },
        ],
        itemLevel1Group: [
            ...this.getItemLevel1()
        ],
        itemLevel2Group: [
            ...this.getItemLevel2()
        ]
    }

    async getApplicableClientGroupPerUserID() {
        try {
            this.currTblConfig.tblData = [];
            this.currTblConfig.loading = true;
            this.jsonDataService.contorlLoading(true);
            this.apiCallService.getApplicableClientGroupPerUserID({
                moduleId: this.moduleId,
                userId: this.userDetailsService.userId,
                type: "MODULE"
            }).subscribe((response: any) => {
                this.securityService.checkRequestKeyResponse(response, () => {
                    this.securityService.hasValidCsrfToken(response, () => {
                        if (response.status === "SUCCESS") {
                            response = JSON.parse(this.jsonDataService.decrypt(response.response));
                            this.jsonDataService.contorlLoading(false);
                            this.currTblData = response;
                            this.filterTblData();
                            this.currTblConfig.loading = false;
                        } else {
                            this.jsonDataService.contorlLoading(false);
                            this.currTblConfig.loading = false;
                            this.appMessageService.showAppMessage(response.message, "error");
                        }
                    });
                });
            });
        } catch (e) {
    console.error(e);
        }
    }

    filterTblData() {
        try {
            if (this.paramForm.get('userCd')?.value && this.paramForm.get('clientGroupCd')?.value
                && this.paramForm.get('itemLevel1Group')?.value && this.paramForm.get('itemLevel2Group')?.value) {
                this.currTblConfig.tblData = this.currTblData.data.filter((d: any) =>
                (d.USER_ID == this.paramForm.get('userCd')?.value
                    && d.CLIENT_CD == this.paramForm.get('clientGroupCd')?.value
                    && d.ITEM_LEVEL1 == this.paramForm.get('itemLevel1Group')?.value
                    && d.ITEM_LEVEL2 == this.paramForm.get('itemLevel2Group')?.value));
                this.insertTempAddedData(this.currTblConfig.tblData);
                this.changeTempUpdatedData(this.currTblConfig.tblData);
                let dataNo = 0;
                this.currTblConfig.tblData.forEach((data: any) => {
                    data.DATA_NO = dataNo;
                    dataNo++;
                });
            }
            else {
                this.currTblConfig.tblData = [];
            }
        } catch (e) {
    console.error(e);
        }
    }

    getItemLevel1() {
        try {
            const itemLevel1 = this.jsonDataService.data.refCds.filter((data: any) => data.rvDomain === 'ITEM_LEVEL1').map((data: any) => { return { cd: data.rvLowValue, name: data.rvMeaning } });
            return itemLevel1;
        } catch (e) {
    console.error(e);
        }
    }

    getItemLevel2() {
        try {
            const itemLevel2 = this.jsonDataService.data.refCds.filter((data: any) => data.rvDomain === 'ITEM_LEVEL2').map((data: any) => { return { cd: data.rvLowValue, name: data.rvMeaning } });
            return itemLevel2;
        } catch (e) {
    console.error(e);
        }
    }

    public openLov(lovToOpen: string) {
        try {
            const LOVOPTIONS: { [key: string]: any } = {
                disableClose: true,
                width: '512px',
                data: {
                    table: lovToOpen,
                    moduleId: this.moduleId
                },
            }
            switch (lovToOpen.toUpperCase()) {
                case "USER":
                    this._Dialog.open(UserLovDialog, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
                        let output = dataOutput.content;
                        this.paramForm.get('userCd')?.setValue(output.USER_ID == null || output.USER_ID == '' ? '' : output.USER_ID);
                        this.paramForm.get('userName')?.setValue(output.USER_ID == null || output.USER_ID == '' ? '' : output.USER_NAME);
                        this.paramForm.get('userField')?.setValue(output.USER_ID == null || output.USER_ID == '' ? '' : output.USER_ID + " - " + output.USER_NAME);
                        if (output.USER_TYPE == 'A') {
                            this.itemLevel1Disabled = false;
                        } else if (output.USER_TYPE != '' && output.USER_TYPE != null) {
                            this.itemLevel1Disabled = true;
                            this.paramForm.get('itemLevel1Group')?.setValue('0');
                        } else {
                            this.itemLevel1Disabled = true;
                            this.paramForm.get('itemLevel1Group')?.setValue('');
                        }
                        this.discardTempData();
                        this.initOnGet();
                    });
                    break;
                case "CLIENT_GROUP":
                    this._Dialog.open(ClientGroupLovDialog, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
                        let output = dataOutput.content;
                        this.paramForm.get('clientGroupCd')?.setValue(output.CLIENT_CD == null || output.CLIENT_CD == '' ? '' : output.CLIENT_CD);
                        this.paramForm.get('clientGroupName')?.setValue(output.CLIENT_CD == null || output.CLIENT_CD == '' ? '' : output.CLIENT_DESC);
                        this.paramForm.get('clientGroupField')?.setValue(output.CLIENT_CD == null || output.CLIENT_CD == '' ? '' : output.CLIENT_CD + " - " + output.CLIENT_DESC);
                        this.discardTempData();
                        this.initOnGet();
                    });
                    break;
            }
        } catch (e) {
    console.error(e);
        }
    }

    onRowClick(ev: any) {
        try {
            if (ev != null) {
                this.rowClickedData = ev;
                this.isLineSelected = true;
                this.deleteDisabled = this.deleteChecker(ev);
                this.fieldDataLoader(this.rowClickedData);
            } else {
                this.rowClickedData = null;
                this.isLineSelected = false;
                this.deleteDisabled = true;
                this.fieldDataClearer();
            }
        } catch (e) {
    console.error(e);
        }
    }

    fieldDataLoader(ev: any) {
        try {
            this.currForm.get('activeTag')?.setValue(ev.ACTIVE_TAG);
            this.currForm.get('effDate')?.setValue(ev.EFF_DATE_FROM);
            this.currForm.get('expDate')?.setValue(ev.EFF_DATE_TO);
            this.currForm.get('remarks')?.setValue(ev.REMARKS);
            this.currForm.get('lastUpdateUser')?.setValue(ev.LAST_USER);
            this.currForm.get('lastUpdate')?.setValue(this.datePipe.transform(ev.LAST_USER_UPDATE, 'MM/dd/yyyy hh:mm:ss a'));
        } catch (e) {
    console.error(e);
        }
    }

    fieldDataClearer() {
        try {
            this.currForm.get('activeTag')?.setValue('A');
            this.currForm.get('effDate')?.setValue(null);
            this.currForm.get('expDate')?.setValue(null);
            this.currForm.get('remarks')?.setValue(null);
            this.currForm.get('lastUpdateUser')?.setValue(null);
            this.currForm.get('lastUpdate')?.setValue(null);
        } catch (e) {
    console.error(e);
        }
    }

    insertLine() {
        try {
            this.validateInsert();
            if (this.validated) {
                this.addFormToTable();
                this.initOnGet();
            }
        } catch (e) {
    console.error(e);
        }
    }

    validateInsert() {
        try {
            this.validated = false;
            if (!this.requiredFieldCheck()) {
                setTimeout(() => {
                    this.formService.hideFormMsg("error-message");
                }, 10000);
            } else if (this.concurrentDateCheckerInsert()) {
                setTimeout(() => {
                    this.formService.hideFormMsg("error-message");
                }, 10000);
            } else if (!this.expDateChecker()) {
                setTimeout(() => {
                    this.formService.hideFormMsg("error-message");
                }, 10000);
            } else {
                this.validated = true;
            }
        } catch (e) {
    console.error(e);
        }
    }

    addFormToTable() {
        try {
            let formToData: any = {
                USER_ID: this.paramForm.get('userCd')?.value,
                CLIENT_CD: this.paramForm.get('clientGroupCd')?.value,
                ITEM_LEVEL1: this.paramForm.get('itemLevel1Group')?.value,
                ITEM_LEVEL2: this.paramForm.get('itemLevel2Group')?.value,
                ACTIVE_TAG: this.currForm.get('activeTag')?.value,
                EFF_DATE_FROM: this.currForm.get('effDate')?.value && this.datePipe.transform(this.currForm.get('effDate')?.value, 'yyyy-MM-dd'),
                EFF_DATE_TO: this.currForm.get('expDate')?.value && this.datePipe.transform(this.currForm.get('expDate')?.value, 'yyyy-MM-dd'),
                REMARKS: this.currForm.get('remarks')?.value,
                LAST_USER: null,
                LAST_USER_UPDATE: null,
                MODE: 'I'
            }
            this.currTblConfig.tblData.unshift(formToData);
            this.tempAddedData.push(formToData);
            this.appMessageService.showAppMessage("Details added successfully!", "success");
        } catch (e) {
    console.error(e);
        }
    }

    updateLine() {
        try {
            this.validateUpdate();
            if (this.validated) {
                this.updateFormTable();
                this.initOnGet();
            }
        } catch (e) {
    console.error(e);
        }
    }

    validateUpdate() {
        try {
            this.validated = false;
            if (!this.requiredFieldCheck()) {
                setTimeout(() => {
                    this.formService.hideFormMsg("error-message");
                }, 10000);
            } else if (this.concurrentDateCheckerUpdate()) {
                setTimeout(() => {
                    this.formService.hideFormMsg("error-message");
                }, 10000);
            } else if (!this.expDateChecker()) {
                setTimeout(() => {
                    this.formService.hideFormMsg("error-message");
                }, 10000);
            } else {
                this.validated = true;
            }
        } catch (e) {
    console.error(e);
        }
    }

    updateFormTable() {
        try {
            let rowIndex = this.currTblConfig.tblData.indexOf(this.rowClickedData);
            this.currTblConfig.tblData[rowIndex] = {
                USER_ID: this.paramForm.get('userCd')?.value,
                CLIENT_CD: this.paramForm.get('clientGroupCd')?.value,
                ITEM_LEVEL1: this.paramForm.get('itemLevel1Group')?.value,
                ITEM_LEVEL2: this.paramForm.get('itemLevel2Group')?.value,
                ACTIVE_TAG: this.currForm.get('activeTag')?.value,
                EFF_DATE_FROM: this.currForm.get('effDate')?.value && this.datePipe.transform(this.currForm.get('effDate')?.value, 'yyyy-MM-dd'),
                EFF_DATE_TO: this.currForm.get('expDate')?.value && this.datePipe.transform(this.currForm.get('expDate')?.value, 'yyyy-MM-dd'),
                REMARKS: this.currForm.get('remarks')?.value,
                LAST_USER: null,
                LAST_USER_UPDATE: null,
                MODE: 'U'
            }
            this.tempUpdatedData.push(this.currTblConfig.tblData[rowIndex]);
            this.appMessageService.showAppMessage("Details updated!", "success");
        } catch (e) {
    console.error(e);
        }
    }

    deleteLine() {
        try {
            let clickData = this.rowClickedData;
            this.currTblConfig.tblData = this.currTblConfig.tblData.filter(
                function (e: any) {
                    return e.EFF_DATE_FROM != clickData.EFF_DATE_FROM;
                }
            );
            this.tempAddedData = this.tempAddedData.filter(
                function (e: any) {
                    return e.USER_ID != clickData.USER_ID
                        || e.CLIENT_CD != clickData.CLIENT_CD
                        || e.ITEM_LEVEL1 != clickData.ITEM_LEVEL1
                        || e.ITEM_LEVEL2 != clickData.ITEM_LEVEL2
                        || e.EFF_DATE_FROM != clickData.EFF_DATE_FROM;
                }
            );
            this.tempUpdatedData = this.tempUpdatedData.filter(
                function (e: any) {
                    return e.USER_ID != clickData.USER_ID
                        || e.CLIENT_CD != clickData.CLIENT_CD
                        || e.ITEM_LEVEL1 != clickData.ITEM_LEVEL1
                        || e.ITEM_LEVEL2 != clickData.ITEM_LEVEL2
                        || e.EFF_DATE_FROM != clickData.EFF_DATE_FROM;
                }
            );
            this.isLineSelected = false;
            this.deleteDisabled = true;
            this.saveDisabled = this.saveChecker();
            this.fieldDataClearer();
        } catch (e) {
    console.error(e);
        }
    }

    deleteChecker(ev: any) {
        try {
            let tempDataFlag = this.tempAddedData.filter(function (e: any) {
                let returnValue = e.USER_ID == ev.USER_ID
                    && e.CLIENT_CD == ev.CLIENT_CD
                    && e.ITEM_LEVEL1 == ev.ITEM_LEVEL1
                    && e.ITEM_LEVEL2 == ev.ITEM_LEVEL2
                    && e.EFF_DATE_FROM == ev.EFF_DATE_FROM;
                return returnValue;
            });
            if (tempDataFlag == '' || tempDataFlag == null) {
                return true;
            } else {
                return false;
            }
        } catch (e) {
    console.error(e);
            return true;
        }
    }

    saveChanges() {
        try {
            this.formHider = true;
            this.formService.showFormLoader(null, "generate-line-form", "Please wait ...", null, null);
            let tempChangeData = [...this.tempAddedData, ...this.tempUpdatedData];
            if (tempChangeData.length != 0) {
                this.saveData(tempChangeData);
                this.initOnGet();
            }
        } catch (e) {
    console.error(e);
        }
    }

    saveData(tempChangeData: any[]) {
        try {
            this.apiCallService.saveApplicableClientGroupPerUserID(tempChangeData).subscribe({
                next: (response: any) => {
                    this.securityService.checkRequestKeyResponse(response, () => {
                        this.securityService.hasValidCsrfToken(response, () => {
                            if (response.status == "SUCCESS") {
                                this.openDialog('SUCCESS', 'Details Successfully saved!');
                                this.discardTempData();
                                this.saveDisabled = this.saveChecker();
                                this.formHider = false;
                                this.getApplicableClientGroupPerUserID();
                            } else {
                                this.openDialog('ERROR', 'An error has occured while saving details');
                            }
                        });
                    });
                }, error: () => {
                    this.openDialog('ERROR', 'An error has occured while saving details');
                }
            });
        } catch (e) {
    console.error(e);
        }
    }

    saveChecker() {
        try {
            if ((this.tempAddedData == null || this.tempAddedData == '')
                && (this.tempUpdatedData == null || this.tempUpdatedData == '')) {
                return true;
            } else {
                return false;
            }
        } catch (e) {
    console.error(e);
            return true;
        }
    }

    insertTempAddedData(data: any) {
        try {
            if (this.tempAddedData.length > 0) {
                for (let addedData = 0; addedData < this.tempAddedData.length; addedData++) {
                    if (this.tempAddedData[addedData].USER_ID == this.paramForm.get('userCd')?.value
                        && this.tempAddedData[addedData].CLIENT_CD == this.paramForm.get('clientGroupCd')?.value
                        && this.tempAddedData[addedData].ITEM_LEVEL1 == this.paramForm.get('itemLevel1Group')?.value
                        && this.tempAddedData[addedData].ITEM_LEVEL2 == this.paramForm.get('itemLevel2Group')?.value) {
                        data.unshift(this.tempAddedData[addedData]);
                    }
                }
            }
            return data;
        } catch (e) {
    console.error(e);
        }
    }

    changeTempUpdatedData(data: any) {
        try {
            if (this.tempUpdatedData.length > 0) {
                for (let updatedData = 0; updatedData < this.tempUpdatedData.length; updatedData++) {
                    if (this.tempUpdatedData[updatedData].USER_ID == this.paramForm.get('userCd')?.value
                        && this.tempUpdatedData[updatedData].CLIENT_CD == this.paramForm.get('clientGroupCd')?.value
                        && this.tempUpdatedData[updatedData].ITEM_LEVEL1 == this.paramForm.get('itemLevel1Group')?.value
                        && this.tempUpdatedData[updatedData].ITEM_LEVEL2 == this.paramForm.get('itemLevel2Group')?.value) {
                        for (let d = 0; d < data.length; d++) {
                            if (this.tempUpdatedData[updatedData].EFF_DATE_FROM == data[d].EFF_DATE_FROM) {
                                data[d] = this.tempUpdatedData[updatedData];
                            }
                        }
                    }
                }
            }
            return data;
        } catch (e) {
    console.error(e);
        }
    }

    openDialog(title: any, message: any) {
        try {
            this.formService.hideFormLoader("generate-line-form");
            this._Dialog.open(DialogMsgComponent, {
                disableClose: true,
                width: "500px",
                data: {
                    title: title,
                    content: message
                }
            });
        } catch (e) {
    console.error(e);
        }
    }

    requiredFieldCheck() {
        try {
            if (this.paramForm.get('userCd')?.value == '' || this.paramForm.get('userCd')?.value == null
                || this.paramForm.get('clientGroupCd')?.value == '' || this.paramForm.get('clientGroupCd')?.value == null
                || this.paramForm.get('itemLevel1Group')?.value == '' || this.paramForm.get('itemLevel1Group')?.value == null
                || this.paramForm.get('itemLevel2Group')?.value == '' || this.paramForm.get('itemLevel2Group')?.value == null
                || this.currForm.get('activeTag')?.value == '' || this.currForm.get('activeTag')?.value == null
                || this.currForm.get('effDate')?.value == '' || this.currForm.get('effDate')?.value == null) {
                this.formService.showFormMsg("error-message", 'There are missing information. Please provide the necessary information needed.', "E");
                return false;
            } else {
                return true;
            }
        } catch (e) {
    console.error(e);
            return false;
        }
    }

    expDateChecker() {
        try {
            let effDate = this.currForm.get('effDate')?.value;
            let expDate = this.currForm.get('expDate')?.value;
            if ((expDate != null && expDate != '') && effDate > expDate) {
                this.formService.showFormMsg("error-message", 'Expiry Date must not be earlier than Effective Date.', "E");
                return false;
            } else {
                return true;
            }
        } catch (e) {
    console.error(e);
            return false;
        }
    }

    concurrentDateCheckerInsert() {
        try {
            let isConcurrent = false;
            let effDate = this.dateConvert(this.currForm.get('effDate')?.value);
            let expDate = this.dateConvert(this.currForm.get('expDate')?.value);
            let tempTblData = this.currTblConfig.tblData;
            for (let data = 0; data < tempTblData.length; data++) {
                let effDateFrom = this.dateConvert(tempTblData[data].EFF_DATE_FROM);
                let effDateTo = this.dateConvert(tempTblData[data].EFF_DATE_TO);
                if (effDate != null && effDateFrom != null) {
                    let dateOverlapBool = this.dateOverlapBool1(effDateFrom, effDateTo, effDate, expDate);
                    let dateOverlapBool2 = this.dateOverlapBool2(effDateFrom, effDateTo, effDate, expDate);
                    if (dateOverlapBool || dateOverlapBool2) {
                        this.formService.showFormMsg("error-message", 'Unable to add record. Date of Effectivity is concurrent with another record. Please enter a different range of date of effectivity.', "E");
                        isConcurrent = true;
                        return isConcurrent;
                    }
                    // * OLD Date Check
                    // if (effDateTo == null && expDate == null) {
                    //     this.formService.showFormMsg("error-message", 'Unable to add record. Date of Effectivity is concurrent with another record. Please enter a different range of date of effectivity.', "E");
                    //     isConcurrent = true;
                    //     return isConcurrent;
                    // }
                    // if (effDateTo != null && expDate == null) {
                    //     if (effDateTo > effDate) {
                    //         this.formService.showFormMsg("error-message", 'Unable to add record. Date of Effectivity is concurrent with another record. Please enter a different range of date of effectivity.', "E");
                    //         isConcurrent = true;
                    //         return isConcurrent;
                    //     }
                    // }
                    // if (effDateTo == null && expDate != null) {
                    //     if (effDateFrom < expDate) {
                    //         this.formService.showFormMsg("error-message", 'Unable to add record. Date of Effectivity is concurrent with another record. Please enter a different range of date of effectivity.', "E");
                    //         isConcurrent = true;
                    //         return isConcurrent;
                    //     }
                    // }
                    // if (effDateTo != null && expDate != null) {
                    //     if ((effDate >= effDateFrom && effDate < effDateTo)
                    //         || (expDate > effDateFrom && expDate <= effDateTo)
                    //         || (effDate < effDateFrom && expDate > effDateTo)) {
                    //         this.formService.showFormMsg("error-message", 'Unable to add record. Date of Effectivity is concurrent with another record. Please enter a different range of date of effectivity.', "E");
                    //         isConcurrent = true;
                    //         return isConcurrent;
                    //     }
                    // }
                }
            }
            return isConcurrent;
        } catch (e) {
    console.error(e);
            return true;
        }
    }

    concurrentDateCheckerUpdate() {
        try {
            let isConcurrent = false;
            let effDate = this.dateConvert(this.currForm.get('effDate')?.value);
            let expDate = this.dateConvert(this.currForm.get('expDate')?.value);
            let tempTblData = this.currTblConfig.tblData;
            for (let data = 0; data < tempTblData.length; data++) {
                let effDateFrom = this.dateConvert(tempTblData[data].EFF_DATE_FROM);
                let effDateTo = this.dateConvert(tempTblData[data].EFF_DATE_TO);
                if (this.rowClickedData.DATA_NO != tempTblData[data].DATA_NO) {
                    if (effDate != null && effDateFrom != null) {
                        if (effDateTo == null && expDate == null) {
                            this.formService.showFormMsg("error-message", 'Unable to update record. Date of Effectivity is concurrent with another record. Please enter a different range of date of effectivity.', "E");
                            isConcurrent = true;
                            return isConcurrent;
                        }
                        if (effDateTo != null && expDate == null) {
                            if (effDateTo > effDate) {
                                this.formService.showFormMsg("error-message", 'Unable to update record. Date of Effectivity is concurrent with another record. Please enter a different range of date of effectivity.', "E");
                                isConcurrent = true;
                                return isConcurrent;
                            }
                        }
                        if (effDateTo == null && expDate != null) {
                            if (effDateFrom < expDate) {
                                this.formService.showFormMsg("error-message", 'Unable to update record. Date of Effectivity is concurrent with another record. Please enter a different range of date of effectivity.', "E");
                                isConcurrent = true;
                                return isConcurrent;
                            }
                        }
                        if (effDateTo != null && expDate != null) {
                            if ((effDate >= effDateFrom && effDate < effDateTo)
                                || (expDate > effDateFrom && expDate <= effDateTo)
                                || (effDate < effDateFrom && expDate > effDateTo)) {
                                this.formService.showFormMsg("error-message", 'Unable to update record. Date of Effectivity is concurrent with another record. Please enter a different range of date of effectivity.', "E");
                                isConcurrent = true;
                                return isConcurrent;
                            }
                        }
                    }
                }
            }
            return isConcurrent;
        } catch (e) {
    console.error(e);
            return true;
        }
    }

    private dateOverlapBool1(dataEffDate: string, dataExpDate: string | null, effDateVal: string, expDateVal: string | null): boolean {
        return (new Date(effDateVal) >= new Date(dataEffDate) && new Date(effDateVal) <= new Date(dataExpDate ?? effDateVal));
    }

    private dateOverlapBool2(dataEffDate: string, dataExpDate: string | null, effDateVal: string, expDateVal: string | null): boolean {
        return (new Date(dataEffDate) <= new Date(expDateVal ?? dataEffDate) && new Date(effDateVal) <= new Date(dataExpDate ?? dataEffDate));
    }

    dateConvert(date: any) {
        if (date != null && date != '') {
            return this.datePipe.transform(date, 'yyyy/MM/dd hh:mm:ss');
        } else {
            return null;
        }
    }

    stringToDate(ev: any) {
        ev.EFF_DATE_FROM = this.dateConvert(ev.EFF_DATE_FROM);
        ev.EFF_DATE_TO = this.dateConvert(ev.EFF_DATE_TO);
        ev.LAST_USER_UPDATE = this.dateConvert(ev.LAST_USER_UPDATE);
    }

    private discardTempData() {
        this.tempAddedData = [];
        this.tempUpdatedData = [];
    }

}