<section id='reason-cancellation-maint'>
    <div class="mtn-container">
        <div class="mtn-title">
            <p>REASON FOR CANCELLATION MAINTENANCE</p>
        </div>
        <!-- Container for Reason for Cancellation Table using prime ng -->
        <div class = 'table-reason-cancellation'>
            <osp-table [tblConfig]="tblConfigReasonCancellation" (onRowClick)="onRowClick($event)"></osp-table>
        </div>
        <!-- Loading Animation -->
        <!-- <div id="generate-reason-cancellation-form">
            <div class="form-loader"></div>
        </div> -->
        
        <!-- Container for Reason Cancellation Fields -->
        <div class="form-content">
            
            <form [formGroup]="reasonCancellationForm" class="form" *ngIf="noLoadingInProcess">
                <div id="reason-cancellation-error-message"></div>
                <div class="form-container-grid">
                    <div class="field">
                        <label>Reason Code</label>
                    </div>
                    <div class="">
                        <input formControlName="reasonCode" type="text" readonly>
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag">
                            <option value="A" selected>Yes</option>
                            <option value="I">No</option>
                        </select>
                    </div>
                    
                    <div class="field required">
                        <label>Reason</label>
                    </div>
                    <div class="span">
                        <input formControlName="reason" type="text">
                    </div>

                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" type="text">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUser">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUpdate">
                    </div>

                </div>
                <div class='btn-row'>
                    <button type="button" id='btn-add' *ngIf="!isReasonSelected" title='Register a Reason for Cancellation' class="btn btn2" value='ADD' type="submit" (click)="insertReasonCancellation()">ADD</button>
                    <button type="button" id='btn-update' *ngIf="isReasonSelected" title='Update a Reason for Cancellation' class="btn btn2" value='UPDATE' type="submit" (click)='updateReasonCancellation()'>UPDATE</button>
                    <button type="button" id='btn-delete' title='Remove a Reason for Cancellation' class="btn btn2" value='DELETE' type="submit" [disabled]='deleteDisabled' (click)='deleteReasonCancellation()'>DELETE</button>
                </div> 

                <div class="mtn-title"></div>

                <div class='btn-row'>
                    <button type="button" id='btn-save' title='Save changes on Reason for Cancellation' class="btn btn2" value='SAVE' type="submit" [disabled]='saveDisabled' (click)='toSave()'>SAVE</button>
                </div>
            </form>
        </div>  
    </div>
</section>