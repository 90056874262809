import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { CustomValidators } from 'src/app/utils/custom-validator';
import { CategoryLovComponent } from '../../common/category-lov/category-lov.component';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { OccClassLovComponent } from '../../common/occ-class-lov/occ-class-lov.component';

@Component({
  selector: 'occupation-maintenance',
  templateUrl: './occupation-maintenance.component.html',
  styleUrls: ['./occupation-maintenance.component.css']
})
export class OccupationMaintenanceComponent implements OnInit, LeaveConfirmation {
  
  selectedRow: any;
  datePipe = new DatePipe('en-ph');
  errorMessage: string = "";
  userId = this.userDetailService.userId;
  formHider: boolean = false;
  moduleId: string = 'BMM049';
  moduleName = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId === 'BMM049' })[0].moduleDesc.toUpperCase();

  addUpdateFlag: boolean = true;
  deleteDisabler: boolean = true;
  saveDisabler: boolean = true;

  tempAddData: any = [];
  tempUpdateData: any = [];

  occupationTable: any = {
    cols: [
      {
        key: "OCCUPATION_CD",
        header: "Occupation Code",
        dataType: "string"
      },
      {
        key: "OCCUPATION_DESC",
        header: "Occupation Description",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "Active",
        dataType: "checkbox"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  occupationForm = this.fb.group({
    OCCUPATION_CD: [{value: '', disabled: true}],
    OCCUPATION_DESC: ['', [Validators.required, this.duplicateOccupationDesc(), CustomValidators.requiredTrim]],
    ACTIVE_TAG: ['A', Validators.required],
    BM_CODE: ['', [Validators.required, CustomValidators.requiredTrim]],
    BM_SHORT_DESC: [''],
    OCCUPATION_CLASS_CD: [{value: '', disabled: true}, Validators.required],
    OCCUPATION_CLASS_DESC: [''],
    CATEGORY_CD: [{value: '', disabled: true}, Validators.required],
    CATEGORY_DESC: [''],
    REMARKS: [''],
    USER_ID: [{value: '', disabled: true}],
    LAST_UPDATE: [{value: '', disabled: true}],
  });

  constructor(
    private fb: FormBuilder,
    private api: APICallService,
    private jsonDataService: JsonDataService,
    private formService: FormService,
    private dialog: MatDialog,
    private userDetailService: UserDetailsService,
    private securityService: SecurityService,
    private appMessageService: AppMessageService,
  ) { }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempAddData!='' || this.tempUpdateData!='') {
      return false;
    } else {
      return true;
    }
  }

  ngOnInit(): void {
    try {
      this.getOccupation();
    } catch(e) {
      
    }
  }

  getOccupation() {
    try {
      this.occupationTable.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.api.getOccupation({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE"
      }).subscribe((data: any) => {
        this.securityService.checkRequestKeyResponse(data, () => {
          this.securityService.hasValidCsrfToken(data, () => {
            if (data.status === "SUCCESS") {
              data = JSON.parse(this.jsonDataService.decrypt(data.response));
              this.occupationTable.tblData = data;
              this.occupationTable.loading = false;
              this.jsonDataService.contorlLoading(false);
            } else {
                this.jsonDataService.contorlLoading(false);
                this.occupationTable.loading = false;
                this.appMessageService.showAppMessage(data.message, "error");
            }
          });
        });
      });
    } catch(e) {
      
    }
  }

  onRowClick(ev: any) {
    try {
      if(ev!=null) {
        this.selectedRow = ev;
        this.addUpdateFlag = false;
        this.formLoader();
      } else {
        this.selectedRow = '';
        this.addUpdateFlag = true;
        this.formClearer();
      }
      this.deleteEnabler();
    } catch(e) {
      
    }
  }

  formLoader() {
    try {
      this.occupationForm.patchValue({
        OCCUPATION_CD:        this.selectedRow.OCCUPATION_CD,
        OCCUPATION_DESC:      this.selectedRow.OCCUPATION_DESC,
        ACTIVE_TAG:           this.selectedRow.ACTIVE_TAG,
        BM_CODE:              this.selectedRow.BM_CODE,
        BM_SHORT_DESC:        this.selectedRow.BM_SHORT_DESC,
        OCCUPATION_CLASS_CD:  this.selectedRow.OCCUPATION_CLASS_CD + " - " + this.selectedRow.OCCUPATION_CLASS_DESC,
        CATEGORY_CD:          this.selectedRow.CATEGORY_CD + " - " + this.selectedRow.CATEGORY_DESC,
        REMARKS:              this.selectedRow.REMARKS,
        USER_ID:              this.selectedRow.USER_ID,
        LAST_UPDATE:          this.datePipe.transform(this.selectedRow.LAST_UPDATE, 'MM/dd/YYYY h:mm:ss a', '+0000'),
      });
      this.occupationForm.get('OCCUPATION_CLASS_CD')?.setValue(this.selectedRow.OCCUPATION_CLASS_CD, {emitModelToViewChange: false});
      this.occupationForm.get('CATEGORY_CD')?.setValue(this.selectedRow.CATEGORY_CD, {emitModelToViewChange: false});
    } catch(e) {
      
    }
  }

  formClearer() {
    try {
      this.occupationForm.patchValue({
        OCCUPATION_CD:        '',
        OCCUPATION_DESC:      '',
        ACTIVE_TAG:           'A',
        BM_CODE:              '',
        BM_SHORT_DESC:        '',
        OCCUPATION_CLASS_CD:  '',
        OCCUPATION_CLASS_DESC:'',
        CATEGORY_CD:          '',
        CATEGORY_DESC:        '',
        REMARKS:              '',
        USER_ID:              '',
        LAST_UPDATE:          '',
      });
    } catch(e) {
      
    }
  }

  errorMessageSetter() {
    try {
      if(this.occupationForm.invalid) {
        for(const field in this.occupationForm.controls) {
          const controlName = this.occupationForm.get(field);
          if(controlName?.errors?.required) {
            this.errorMessage = 'There are missing information. Please provide necessary information needed.';
          }
          if(controlName?.errors?.occupationDuplicateDesc) {
            this.errorMessage = 'Occupation Description already exists. Please add a unique Occupation Description.';
          }
          this.formService.showFormMsg("occupation-error-message", this.errorMessage, "E");
        }
      }
    } catch(e) {
      
    }
  }

  tableInserter() {
    try {
      this.occupationTable.tblData.unshift({
        OCCUPATION_DESC:        this.occupationForm.get('OCCUPATION_DESC')?.value,
        ACTIVE_TAG:             this.occupationForm.get('ACTIVE_TAG')?.value,
        BM_CODE:                this.occupationForm.get('BM_CODE')?.value,
        BM_SHORT_DESC:          this.occupationForm.get('BM_SHORT_DESC')?.value,
        OCCUPATION_CLASS_CD:    this.occupationForm.get('OCCUPATION_CLASS_CD')?.value,
        OCCUPATION_CLASS_DESC:  this.occupationForm.get('OCCUPATION_CLASS_DESC')?.value,
        CATEGORY_CD:            this.occupationForm.get('CATEGORY_CD')?.value,
        CATEGORY_DESC:          this.occupationForm.get('CATEGORY_DESC')?.value,
        REMARKS:                this.occupationForm.get('REMARKS')?.value,
      });
      this.occupationTable.tblData = [...this.occupationTable.tblData];
    } catch(e) {
      
    }
  }

  tempAddInserter() {
    try {
      this.tempAddData.push({
        OCCUPATION_DESC:      this.occupationForm.get('OCCUPATION_DESC')?.value,
        ACTIVE_TAG:           this.occupationForm.get('ACTIVE_TAG')?.value,
        BM_CODE:              this.occupationForm.get('BM_CODE')?.value,
        BM_SHORT_DESC:        this.occupationForm.get('BM_SHORT_DESC')?.value,
        OCCUPATION_CLASS_CD:  this.occupationForm.get('OCCUPATION_CLASS_CD')?.value,
        CATEGORY_CD:          this.occupationForm.get('CATEGORY_CD')?.value,
        REMARKS:              this.occupationForm.get('REMARKS')?.value,
        USER_ID:              this.userId,
      });
    } catch(e) {
      
    }
  }

  tempUpdateInserter() {
    try {
      this.tempUpdateData.push({
        OCCUPATION_CD:        this.occupationForm.get('OCCUPATION_CD')?.value,
        OCCUPATION_DESC:      this.occupationForm.get('OCCUPATION_DESC')?.value,
        ACTIVE_TAG:           this.occupationForm.get('ACTIVE_TAG')?.value,
        BM_CODE:              this.occupationForm.get('BM_CODE')?.value,
        BM_SHORT_DESC:        this.occupationForm.get('BM_SHORT_DESC')?.value,
        OCCUPATION_CLASS_CD:  this.occupationForm.get('OCCUPATION_CLASS_CD')?.value,
        CATEGORY_CD:          this.occupationForm.get('CATEGORY_CD')?.value,
        REMARKS:              this.occupationForm.get('REMARKS')?.value,
        USER_ID:              this.userId,
      });
    } catch(e) {
      
    }
  }

  addRow() {
    try {
      this.occupationForm.get("OCCUPATION_CLASS_CD")?.enable();
      this.occupationForm.get("CATEGORY_CD")?.enable();
      if(this.occupationForm.valid) {
        this.formService.hideFormMsg("occupation-error-message");
        this.tableInserter();
        this.tempAddInserter();
        this.formClearer();
        this.saveDisabler = false;
      } else {
        this.errorMessageSetter();
      }
      this.occupationForm.get("OCCUPATION_CLASS_CD")?.disable();
      this.occupationForm.get("CATEGORY_CD")?.disable();
    } catch(e) {
      
    }
  }

  updateTable() {
    try {
      let updateIndex = this.occupationTable.tblData.indexOf(this.selectedRow);
      const rowData = this.occupationTable.tblData[updateIndex];
      this.occupationTable.tblData[updateIndex] = {
        OCCUPATION_CD:          this.occupationForm.get('OCCUPATION_CD')?.value,
        OCCUPATION_DESC:        this.occupationForm.get('OCCUPATION_DESC')?.value,
        ACTIVE_TAG:             this.occupationForm.get('ACTIVE_TAG')?.value,
        BM_CODE:                this.occupationForm.get('BM_CODE')?.value,
        BM_SHORT_DESC:          this.occupationForm.get('BM_SHORT_DESC')?.value,
        OCCUPATION_CLASS_CD:    this.occupationForm.get('OCCUPATION_CLASS_CD')?.value,
        OCCUPATION_CLASS_DESC:  this.occupationForm.get('OCCUPATION_CLASS_DESC')?.value !== "" ? this.occupationForm.get('OCCUPATION_CLASS_DESC')?.value : rowData.OCCUPATION_CLASS_DESC,
        CATEGORY_CD:            this.occupationForm.get('CATEGORY_CD')?.value,
        CATEGORY_DESC:          this.occupationForm.get('CATEGORY_DESC')?.value !== "" ? this.occupationForm.get('CATEGORY_DESC')?.value : rowData.CATEGORY_DESC,
        REMARKS:                this.occupationForm.get('REMARKS')?.value,
        USER_ID:                this.selectedRow.USER_ID,
        LAST_UPDATE:            this.selectedRow.LAST_UPDATE,
      }
      this.occupationTable.tblData = [...this.occupationTable.tblData];
    } catch(e) {
      
    }
  }

  updateNewData() {
    try {
      let tempNewConfigdata = this.tempAddData.find(
        (occupation: { OCCUPATION_DESC: any }) => occupation.OCCUPATION_DESC == this.selectedRow.OCCUPATION_DESC
      );

      tempNewConfigdata.OCCUPATION_CD         = this.occupationForm.get('OCCUPATION_CD')?.value;
      tempNewConfigdata.OCCUPATION_DESC       = this.occupationForm.get('OCCUPATION_DESC')?.value;
      tempNewConfigdata.ACTIVE_TAG            = this.occupationForm.get('ACTIVE_TAG')?.value;
      tempNewConfigdata.BM_CODE               = this.occupationForm.get('BM_CODE')?.value;
      tempNewConfigdata.BM_SHORT_DESC         = this.occupationForm.get('BM_SHORT_DESC')?.value;
      tempNewConfigdata.OCCUPATION_CLASS_CD   = this.occupationForm.get('OCCUPATION_CLASS_CD')?.value;
      tempNewConfigdata.OCCUPATION_CLASS_DESC = this.occupationForm.get('OCCUPATION_CLASS_DESC')?.value;
      tempNewConfigdata.CATEGORY_CD           = this.occupationForm.get('CATEGORY_CD')?.value;
      tempNewConfigdata.CATEGORY_DESC         = this.occupationForm.get('CATEGORY_DESC')?.value;
      tempNewConfigdata.REMARKS               = this.occupationForm.get('REMARKS')?.value;
    } catch(e) {
      
    }
  }

  updateDbData() {
    try {
      let tempDbData = this.tempUpdateData.find(
        (occupation: { OCCUPATION_DESC: any }) => occupation.OCCUPATION_DESC == this.selectedRow.OCCUPATION_DESC
      );
      if(this.tempUpdateData == '' || this.tempUpdateData == null) {
        this.tempUpdateInserter();
      } else if(tempDbData=='' || tempDbData==null) {
        this.tempUpdateInserter();
      } else {
        tempDbData.OCCUPATION_CD          = this.occupationForm.get('OCCUPATION_CD')?.value;
        tempDbData.OCCUPATION_DESC        = this.occupationForm.get('OCCUPATION_DESC')?.value;
        tempDbData.ACTIVE_TAG             = this.occupationForm.get('ACTIVE_TAG')?.value;
        tempDbData.BM_CODE                = this.occupationForm.get('BM_CODE')?.value;
        tempDbData.BM_SHORT_DESC          = this.occupationForm.get('BM_SHORT_DESC')?.value;
        tempDbData.OCCUPATION_CLASS_CD    = this.occupationForm.get('OCCUPATION_CLASS_CD')?.value;
        tempDbData.OCCUPATION_CLASS_DESC  = this.occupationForm.get('OCCUPATION_CLASS_DESC')?.value;
        tempDbData.CATEGORY_CD            = this.occupationForm.get('CATEGORY_CD')?.value;
        tempDbData.CATEGORY_DESC          = this.occupationForm.get('CATEGORY_DESC')?.value;
        tempDbData.REMARKS                = this.occupationForm.get('REMARKS')?.value;
        tempDbData.USER_ID                = this.selectedRow.USER_ID;
      }
    } catch(e) {
      
    }
  }

  onUpdate() {
    try {
      if(this.occupationForm.valid) {
        this.updateTable();
        if(this.deleteDisabler) {
          this.updateDbData();
        } else {
          this.updateNewData();
        }
        this.selectedRow = '';
        this.addUpdateFlag = true;
        this.formClearer();
        this.saveDisabler = false;
        this.deleteEnabler();
        this.formService.hideFormMsg('occupation-error-message');
      } else {
        this.errorMessageSetter();
      }
    } catch(e) {
      
    }
  }

  deleteEnabler() {
    try {
      let tempDeleteFlag = this.tempAddData.filter((e: any) => {
        return e.OCCUPATION_DESC == this.selectedRow.OCCUPATION_DESC
      });
      if(tempDeleteFlag=='' || tempDeleteFlag==null) {
        this.deleteDisabler = true;
      } else {
        this.deleteDisabler = false;
      }
    } catch(e) {
      
    }
  }

  deleteRow() {
    try {
      let clickedData = this.selectedRow;
      this.occupationTable.tblData = this.occupationTable.tblData.filter(function(e: any) {
        return e.OCCUPATION_DESC != clickedData.OCCUPATION_DESC;
      });
      this.tempAddData = this.tempAddData.filter(function(e: any) {
        return e.OCCUPATION_DESC != clickedData.OCCUPATION_DESC;
      });
      this.selectedRow = '';
      this.formClearer();
      this.deleteDisabler = true;
      this.addUpdateFlag = true;
      if((this.tempAddData=='' || this.tempAddData==null) && (this.tempUpdateData=='' || this.tempUpdateData==null)) {
        this.saveDisabler = true;
      }
    } catch(e) {
      
    }
  }

  onSave() {
    try {
      this.removePrefixZero();
      this.formHider = true;
      this.formService.showFormLoader(null, "occupation-form", "Saving.<br>Please wait ...", null, null);
      this.jsonDataService.contorlLoading(true);
      this.api.saveOccupation(this.tempAddData).subscribe({
        next: (data: any) => {
          this.securityService.checkRequestKeyResponse(data, () => {
            this.securityService.hasValidCsrfToken(data, () => {
              if(data.status == 'SUCCESS') {
                this.api.saveOccupation(this.tempUpdateData).subscribe({
                  next: (res: any) => {
                    this.securityService.checkRequestKeyResponse(res, () => {
                      this.securityService.hasValidCsrfToken(res, () => {
                        if(res.status == 'SUCCESS') {
                          this.onComplete();
                          this.openSaveDialog();
                        } else {
                          this.jsonDataService.contorlLoading(false);
                          this.openErrorDialog('Saving occupation details failed.');
                          this.saveDisabler = true
                        }
                      });
                    });
                  },
                  error: () => {
                    this.jsonDataService.contorlLoading(false);
                    this.openErrorDialog('Saving occupation details failed.');
                  }
                });
              } else {
                this.jsonDataService.contorlLoading(false);
                this.openErrorDialog('Saving occupation details failed.');
              }
            });
          });
        },
        error: () => {
          this.jsonDataService.contorlLoading(false);
          this.openErrorDialog('Saving occupation details failed.');
        }
      });
    } catch(e) {
      
    }
  }

  onComplete() {
    try {
      this.formHider = false;
      this.tempAddData = [];
      this.tempUpdateData = [];
      this.saveDisabler = true
      this.deleteEnabler();
      this.formClearer();
      this.getOccupation();
      this.formService.hideFormLoader("occupation-form");
      this.jsonDataService.contorlLoading(false);
      this.formService.hideFormMsg("occupation-error-message");
    } catch(e) {
      
    }
  }

  openErrorDialog(message: string) {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'ERROR',
          content: message,
        }
      });
    } catch(e) {
      
    }
  }

  onFail() {
    try {
      this.tempAddData = [];
      this.tempUpdateData = [];
      this.deleteEnabler();
      this.getOccupation();
      this.formService.hideFormLoader("occupation-form");
      this.formHider = false;
      this.openErrorDialog('Saving occupation details failed.');
    } catch(e) {
      
    }
  }

  openSaveDialog() {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'SUCCESS',
          content: 'Occupation details Successfully saved!',
        }
      });
    } catch(e) {
      
    }
  }

  openOccClassLovDialog() {
    this.dialog.open(OccClassLovComponent, { 
      disableClose: true, 
      width: '700px', 
      data: {moduleId: this.moduleId}
    }).afterClosed().subscribe((data: any) => {
      if(!(data.content === "" || data === "")) {
        this.occupationForm.get("OCCUPATION_CLASS_CD")?.setValue(data.content.OCCUPATION_CLASS_CD + " - " + data.content.OCCUPATION_CLASS_DESC);
        this.occupationForm.get("OCCUPATION_CLASS_DESC")?.setValue(data.content.OCCUPATION_CLASS_DESC);
        this.occupationForm.get("OCCUPATION_CLASS_CD")?.setValue(data.content.OCCUPATION_CLASS_CD, {emitModelToViewChange: false});
      }
    });
  }

  openCategoryLovDialog() {
    this.dialog.open(CategoryLovComponent, {
      disableClose: true,
      width: '700px',
      data: {moduleId: this.moduleId}
    }).afterClosed().subscribe((data: any) => {
      if(!(data.content === "" || data === "")) {
        this.occupationForm.get("CATEGORY_CD")?.setValue(data.content.CATEGORY_CD + " - " + data.content.CATEGORY_DESC);
        this.occupationForm.get("CATEGORY_DESC")?.setValue(data.content.CATEGORY_DESC);
        this.occupationForm.get("CATEGORY_CD")?.setValue(data.content.CATEGORY_CD, {emitModelToViewChange: false});
      }
    });
  }

  duplicateOccupationDesc(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const selectedRow = this.selectedRow;
      let occupationDescDuplicate = this.occupationTable.tblData.filter(function(e: any) {
        return e.OCCUPATION_DESC.trim()==control.value.trim() &&
               control.value != selectedRow?.OCCUPATION_DESC;
      });
      if(occupationDescDuplicate!='') {
        return { "occupationDuplicateDesc": { value: control.value } }
      }
      return null;
    }
  }

  removePrefixZero() {
    this.tempUpdateData = this.tempUpdateData.map((data: any) => {
      return { ...data, OCCUPATION_CD: parseInt(data.OCCUPATION_CD, 10) };
    });
  }

}
