import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { ClientGroupLovDialog } from '../../common/client-group-lov-dialog/client-group-lov-dialog.component';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';

@Component({
  selector: 'towing-amt-per-client-grp-vehicle-maintenance',
  templateUrl: './towing-amt-per-client-grp-vehicle-maintenance.component.html',
  styleUrls: ['./towing-amt-per-client-grp-vehicle-maintenance.component.css']
})
export class TowingAmtPerClientGrpVehicleMaintenanceComponent implements OnInit, LeaveConfirmation, OnDestroy {

  private moduleId: string = 'BMM156';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc ?? "";
  private datePipe = new DatePipe('en-us');
  private valueChangesSubscriptions: Subscription = new Subscription();

  constructor(
    public _formService: FormService,
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService,
    private _appMessageService: AppMessageService,
  ) {
    this.userIdLoggedIn = this._userDetailService.userId ?? "";
  }

  public canDeactivate(): boolean | Observable<boolean> {
    return !(this.defaultTowingLimitPerVehicleTblConfig.tblData?.filter((row: any) => ['I', 'U'].includes(row.operation)).length > 0);
  }

  public btns = {
    defaultTowingLimitPerVehicle: {
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      addDisabled: true,
    },
    filterLov: {
      sublineSearchDisabled: false,
      clientGrpSearchDisabled: false,
    }
  };

  public showForm: boolean = true;

  public maxEffDate!: Date | undefined;
  public minExpDate!: Date | undefined;

  //* Data Stuffs */
  private rowData: any;
  private errorMessage: string = "";
  private userIdLoggedIn: string;
  //? set selectedRow to [] to unselect the table
  public selectedRow!: any[];

  public forms = {
    filterLovForm: this.fb.group({
      lineCd: [null],
      sublineCd: [null],
      sublineName: [null],
      clientCd: [null],
      clientName: [null],
      carCompGrp: [null],
      sublineCdDisp: [{ value: '', disabled: 'true' }],
      clientCdDisp: [{ value: '', disabled: 'true' }],
    }),
    defaultTowingLimitPerVehicleForm: this.fb.group({
      lineCd: [null],
      sublineCd: [null],
      clientCd: [null],
      carCompGrp: [null],
      towingAmt: [null, [Validators.required, this.checkDecimalsExceeded(14, 2)]],
      active: ["A", [Validators.required]],
      effDateFrom: [new Date(), [Validators.required]],
      effDateTo: [null],
      remarks: [null],
      lastUpdateUser: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }],
    }, {
      validators: [this.checkDateValid()],
    }),
    resetDefaultTowingLimitPerVehicleForm: () => {
      this.forms.defaultTowingLimitPerVehicleForm.reset();
      this.btns.defaultTowingLimitPerVehicle.updateDisabled = true;
      this.btns.defaultTowingLimitPerVehicle.deleteDisabled = true;

      this.forms.defaultTowingLimitPerVehicleForm.patchValue({
        active: 'A',
        effDateFrom: new Date()
      });

      this._formService.hideFormMsg("default-towing-limit-per-vehicle-error-message");
      this.rowData = null;
      this.forms.defaultTowingLimitPerVehicleForm.get('effDateFrom')?.enable();
    },
    resetFilterLovForm: () => {
      this.forms.filterLovForm.reset();
    },
    resetAllForms: () => {
      this.forms.resetFilterLovForm();
      this.forms.resetDefaultTowingLimitPerVehicleForm();
    }
  }

  private defaultTowingLimitPerVehicleFormManipulate = {
    dbToForm: (data: any) => {
      return {
        lineCd: data.LINE_CD || null,
        sublineCd: data.SUBLINE_CD || null,
        clientCd: data.CLIENT_CD || null,
        carCompGrp: data.CAR_COMP_GRP || null,
        towingAmt: parseFloat(data.TOWING_AMT).toFixed(2) || null,
        active: data.ACTIVE_TAG || null,
        effDateFrom: data.EFF_DATE_FROM || null,
        effDateTo: data.EFF_DATE_TO || null,
        remarks: data.REMARKS || null,
        lastUpdateUser: data.USER_ID || null,
        lastUpdate: data.LAST_UPDATE || null,
      };
    },
    formToDb: (data: any) => {
      return {
        LINE_CD: data.lineCd,
        SUBLINE_CD: data.sublineCd,
        CLIENT_CD: data.clientCd,
        CAR_COMP_GRP: data.carCompGrp,
        TOWING_AMT: parseFloat(this._formService.setToNumberWithDecimal(data.towingAmt)),
        ACTIVE_TAG: data.active,
        EFF_DATE_FROM: data.effDateFrom ? this.datePipe.transform(data.effDateFrom, 'yyyy-MM-dd') : null,
        EFF_DATE_TO: data.effDateTo ? this.datePipe.transform(data.effDateTo, 'yyyy-MM-dd') : null,
        REMARKS: data.remarks,
        USER_ID: null,
        LAST_UPDATE: null,
      };
    },
  }

  public defaultTowingLimitPerVehicleTblConfig: any = {
    cols: [
      {
        key: "TOWING_AMT",
        header: "Towing Amount",
        dataType: "currency",
      },
      {
        key: "EFF_DATE_FROM",
        header: "Effective Date",
        dataType: "date"
      },
      {
        key: "EFF_DATE_TO",
        header: "Expiry Date",
        dataType: "date"
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        width: '48px',
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  ngOnInit(): void {
    this.minExpDate = new Date();
    this.valueChangesSubscriptions.add(this.forms.defaultTowingLimitPerVehicleForm.get('effDateFrom')?.valueChanges.subscribe((res) => {
      this.minExpDate = res ? new Date(res) : undefined;
    }));
    this.valueChangesSubscriptions.add(this.forms.defaultTowingLimitPerVehicleForm.get('effDateTo')?.valueChanges.subscribe((res) => {
      this.maxEffDate = res ? new Date(res) : undefined;
    }));
    this.valueChangesSubscriptions.add(this.forms.filterLovForm.get('carCompGrp')?.valueChanges.subscribe((value: any) => {
      this.btns.defaultTowingLimitPerVehicle.addDisabled = !(value && this.forms.filterLovForm.get('sublineCd')?.value && this.forms.filterLovForm.get('lineCd')?.value);
      this.getData.defaultTowingLimitPerVehicle();
    }));
  }

  ngOnDestroy(): void {
    this.valueChangesSubscriptions.unsubscribe();
  }

  public openLov = {
    subline: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          lineCd: 'MC',
          table: 'SUBLINE',
          moduleId: this.moduleId
        },
      }
      this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button === 'OK') {
          this.forms.resetDefaultTowingLimitPerVehicleForm();
          if (output) {
            this.forms.filterLovForm.patchValue({
              lineCd: output.LINE_CD,
              sublineCd: output.SUBLINE_CD,
              sublineName: output.SUBLINE_NAME,
              sublineCdDisp: `${output.SUBLINE_CD} - ${output.SUBLINE_NAME}`,
            });
          }
          else {
            this.forms.filterLovForm.patchValue({
              lineCd: null,
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null,
            });
            this.btns.defaultTowingLimitPerVehicle.addDisabled = true;
          }
          this.getData.defaultTowingLimitPerVehicle();
        }
      });
    },
    clientType: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'CLIENT_GROUP',
          moduleId: this.moduleId,
        },
      };
      this._matDialog.open(ClientGroupLovDialog, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
        let output = dataOutput.content;
        if (dataOutput.option?.toUpperCase() !== "BACK") {
          this.forms.resetDefaultTowingLimitPerVehicleForm();
          if (output.CLIENT_CD) {
            this.forms.filterLovForm.patchValue({
              clientCd: output.CLIENT_CD,
              clientName: output.CLIENT_DESC,
              clientCdDisp: `${output.CLIENT_CD} - ${output.CLIENT_DESC}`,
            });
          }
          else {
            this.forms.filterLovForm.patchValue({
              clientCd: null,
              clientName: null,
              clientCdDisp: null,
            });
            this.btns.defaultTowingLimitPerVehicle.addDisabled = true;
          }
          this.getData.defaultTowingLimitPerVehicle();
        }
      });
    }
  };

  private getData = {
    defaultTowingLimitPerVehicle: () => {
      try {
        this.defaultTowingLimitPerVehicleTblConfig.tblData = [];
        if (this.forms.filterLovForm.get('lineCd')?.value
          && this.forms.filterLovForm.get('sublineCd')?.value
          && this.forms.filterLovForm.get('clientCd')?.value
          && this.forms.filterLovForm.get('carCompGrp')?.value) {
          this._jDS.contorlLoading(true);
          this.defaultTowingLimitPerVehicleTblConfig.loading = true;
          this._api.getTowingAmtPerClientGrpVehicle({
            lineCd: this.forms.filterLovForm.get('lineCd')?.value,
            sublineCd: this.forms.filterLovForm.get('sublineCd')?.value,
            clientCd: this.forms.filterLovForm.get('clientCd')?.value,
            carCompGrp: this.forms.filterLovForm.get('carCompGrp')?.value,
            moduleId: this.moduleId,
            userId: this._userDetailService.userId,
            type: "MODULE",
          }).subscribe({
            next: (response: any) => {
              this._securityService.checkRequestKeyResponse(response, () => {
                this._securityService.hasValidCsrfToken(response, () => {
                  if (response.status === "SUCCESS") {
                    let content = JSON.parse(this._jDS.decrypt(response?.response));
                    this.defaultTowingLimitPerVehicleTblConfig.tblData = content.data;
                    this._jDS.contorlLoading(false);
                    this.defaultTowingLimitPerVehicleTblConfig.loading = false;
                    this.btns.defaultTowingLimitPerVehicle.addDisabled = false;
                  }
                  else {

                    this._jDS.contorlLoading(false);
                    this.defaultTowingLimitPerVehicleTblConfig.loading = false;
                    this.btns.defaultTowingLimitPerVehicle.addDisabled = true;
                  }
                });
              });
            },
            error: (e: any) => {
              this._securityService.checkRequestKeyResponse(e, () => {

                this._jDS.contorlLoading(false);
                this.defaultTowingLimitPerVehicleTblConfig.loading = false;
                this.btns.defaultTowingLimitPerVehicle.addDisabled = true;
              });
            }
          });
        }
        else {
          this.defaultTowingLimitPerVehicleTblConfig.loading = false;
          this.btns.defaultTowingLimitPerVehicle.addDisabled = true;
        }
      }
      catch (e) {
    console.error(e);

      }
    },
    carCompGrps: () => {
      const filteredCarCompGrps = this._jDS.data.refCds.filter((data: any) => data.rvDomain === 'CAR_COMP_GRP').map((data: any) => { return { cd: data.rvLowValue, name: data.rvMeaning } });
      return filteredCarCompGrps;
    },
  };

  private addDay(date: Date, day: number): Date {
    date = new Date(date);
    date.setDate(date.getDate() + day);
    return date;
  }

  public selections = {
    activeTypes: [
      { cd: 'A', name: 'Yes' },
      { cd: 'I', name: 'No' },
    ],
    carCompGrps: [
      { cd: null, name: '' },
      ...this.getData.carCompGrps()
    ],
  };

  public onRowClick(ev: any) {
    try {
      if (ev) {
        this.rowData = ev;
        this.forms.defaultTowingLimitPerVehicleForm.get('effDateFrom')?.disable();
        this.populateFormFromTable();
      }
      else {
        this.rowData = null;
        this.forms.resetDefaultTowingLimitPerVehicleForm();
      }
    }
    catch (e) {
    console.error(e);
      this.rowData = null;
      this.forms.resetDefaultTowingLimitPerVehicleForm();
    }
  }

  private populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.defaultTowingLimitPerVehicle.updateDisabled = false;
      this.btns.defaultTowingLimitPerVehicle.deleteDisabled = !(this.rowData?.operation === 'I');
      this.forms.defaultTowingLimitPerVehicleForm.patchValue(this.defaultTowingLimitPerVehicleFormManipulate.dbToForm(data));
    }
    catch (e) {
    console.error(e);

    }
  }

  public onAdd(): void {
    try {
      this._formService.hideFormMsg("default-towing-limit-per-vehicle-error-message");
      if (this.forms.defaultTowingLimitPerVehicleForm.valid) {
        this.forms.defaultTowingLimitPerVehicleForm.patchValue({
          lineCd: this.forms.filterLovForm.get('lineCd')?.value,
          sublineCd: this.forms.filterLovForm.get('sublineCd')?.value,
          clientCd: this.forms.filterLovForm.get('clientCd')?.value,
          carCompGrp: this.forms.filterLovForm.get('carCompGrp')?.value,
        });
        let rowToBeAdded: { [key: string]: any } = this.defaultTowingLimitPerVehicleFormManipulate.formToDb(this.forms.defaultTowingLimitPerVehicleForm.getRawValue());
        rowToBeAdded.operation = 'I';
        this.defaultTowingLimitPerVehicleTblConfig.tblData = [rowToBeAdded, ...this.defaultTowingLimitPerVehicleTblConfig.tblData];
        this.forms.resetDefaultTowingLimitPerVehicleForm();
        this.btns.defaultTowingLimitPerVehicle.saveDisabled = false;
      }
      else {
        this.showErrorValidator.defaultTowingLimitPerVehicle();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onUpdate(): void {
    try {
      this._formService.hideFormMsg("default-towing-limit-per-vehicle-error-message");
      if (this.forms.defaultTowingLimitPerVehicleForm.valid) {
        const indexOfRow = this.defaultTowingLimitPerVehicleTblConfig.tblData.indexOf(this.rowData);
        let rowToBeUpdated: { [key: string]: any } = this.defaultTowingLimitPerVehicleFormManipulate.formToDb(this.forms.defaultTowingLimitPerVehicleForm.getRawValue());
        rowToBeUpdated.operation = (this.rowData.operation === 'I') ? 'I' : 'U';
        this.forms.resetDefaultTowingLimitPerVehicleForm();
        this.defaultTowingLimitPerVehicleTblConfig.tblData[indexOfRow] = rowToBeUpdated;
        this.selectedRow = [];
        this.btns.defaultTowingLimitPerVehicle.saveDisabled = false;
        this.defaultTowingLimitPerVehicleTblConfig.tblData = [...this.defaultTowingLimitPerVehicleTblConfig.tblData];
      }
      else {
        this.showErrorValidator.defaultTowingLimitPerVehicle();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onDelete(): void {
    try {
      this.defaultTowingLimitPerVehicleTblConfig.tblData = this.defaultTowingLimitPerVehicleTblConfig.tblData.filter((row: any) => {
        return row !== this.rowData;
      });
      this.forms.resetDefaultTowingLimitPerVehicleForm();
      this.btns.defaultTowingLimitPerVehicle.saveDisabled = false;
    }
    catch (e) {
    console.error(e);

    }
  }

  public onSave(): void {
    try {
      this._jDS.contorlLoading(true);
      this.showForm = false;
      this._formService.showFormLoader(null, "default-towing-limit-per-vehicle-maintenance-loading", "Saving.<br>Please wait ...", null, null, true);
      let toBeSaved = this.defaultTowingLimitPerVehicleTblConfig.tblData.filter((row: any) => ['I', 'U'].includes(row.operation));
      if (toBeSaved.length > 0) {
        this._api.saveTowingAmtPerClientGrpVehicle(toBeSaved).subscribe({
          next: (response: any) => {
            this._securityService.checkRequestKeyResponse(response, () => {
              this._securityService.hasValidCsrfToken(response, () => {
                if (response.status === 'SUCCESS') {
                  this.onComplete.defaultTowingLimitPerVehicle();
                }
                else {
                  this.onComplete.defaultTowingLimitPerVehicleError();
                }
              });
            });
          },
          error: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {

              this.onComplete.defaultTowingLimitPerVehicleError();
            });
          }
        });
      }
      else {
        this.onComplete.defaultTowingLimitPerVehicle();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  private onComplete = {
    defaultTowingLimitPerVehicle: () => {
      this._jDS.contorlLoading(false);
      this._formService.hideFormLoader("default-towing-limit-per-vehicle-maintenance-loading");
      this.openDialog('SUCCESS', this.moduleName.replace('Maintenance', ''), 'saved');
      this.btns.defaultTowingLimitPerVehicle.saveDisabled = true;
      this.showForm = true;
      this.forms.resetDefaultTowingLimitPerVehicleForm();
    },
    defaultTowingLimitPerVehicleError: () => {
      this._jDS.contorlLoading(false);
      this.openDialog('FAILED', this.moduleName.replace('Maintenance', ''), 'saving');
      this._formService.hideFormLoader("default-towing-limit-per-vehicle-maintenance-loading");
      this.showForm = true;
      this.forms.resetDefaultTowingLimitPerVehicleForm();
    },
  }

  private openDialog(title: string, contentTitle: string, contentDetail: string) {
    try {
      this._matDialog.open(DialogMsgComponent, {
        disableClose: true,
        width: '512px',
        data: {
          title: title,
          content:
            title === 'SUCCESS'
              ? `${contentTitle} details successfully ${contentDetail}!`
              : `${contentTitle} details ${contentDetail} failed!`,
        },
      }).afterClosed().subscribe(() => {
        if (title === 'SUCCESS') {
          this.getData.defaultTowingLimitPerVehicle();
        }
      });
    }
    catch (e) {
    console.error(e);

    }
  }

  //* Validator Stuffs Down here */

  private checkDateValid(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const effDateValue = control.get('effDateFrom')?.value ? this.datePipe.transform(control.get('effDateFrom')?.value, 'yyyy-MM-dd') : null;
      const expDateValue = control.get('effDateTo')?.value ? this.datePipe.transform(control.get('effDateTo')?.value, 'yyyy-MM-dd') : null;
      if (!effDateValue) {
        return null;
      }
      let hasConcurrent: boolean = this.defaultTowingLimitPerVehicleTblConfig?.tblData.filter((d: any) => {
        return this.checkBool(d.EFF_DATE_FROM, d.EFF_DATE_TO, effDateValue, expDateValue);
      }).length > 0 ? true : false;
      return hasConcurrent ? { hasConcurrent: true } : null;
    }
  }

  private checkDecimalsExceeded(digits: number, decimals: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value && parseFloat(this._formService.setToNumberWithDecimal(control.value));
      if (!value) return null;
      let isExceeded: boolean = value > +"".padEnd(digits, '9') + +('.' + ''.padEnd(decimals, '9'))
      return isExceeded ? { hasDecimalsExceeded: true } : null;
    }
  }

  private checkBool(dataEffDate: string, dataExpDate: string | null, effDateVal: string, expDateVal: string | null): boolean {
    if (!this.rowData) {
      let dateOverlapBool = this.dateOverlapBool1(dataEffDate, dataExpDate, effDateVal, expDateVal);
      let dateOverlapBool2 = this.dateOverlapBool2(dataEffDate, dataExpDate, effDateVal, expDateVal);
      return dateOverlapBool || dateOverlapBool2;
    }
    let dateOverlapBool1 = this.dateOverlapBool1(dataEffDate, dataExpDate, this.rowData.EFF_DATE_FROM, this.rowData.EFF_DATE_FROM);
    let dateOverlapBool2 = this.dateOverlapBool2(dataEffDate, dataExpDate, this.rowData.EFF_DATE_FROM, this.rowData.EFF_DATE_FROM);
    let dateOverlapBool3 = this.dateOverlapBool1(dataEffDate, dataExpDate, effDateVal, expDateVal);
    let dateOverlapBool4 = this.dateOverlapBool2(dataEffDate, dataExpDate, effDateVal, expDateVal);
    return !(dateOverlapBool1 || dateOverlapBool2) && (dateOverlapBool3 || dateOverlapBool4);
  }

  private dateOverlapBool1(dataEffDate: string, dataExpDate: string | null, effDateVal: string, expDateVal: string | null): boolean {
    let res = (new Date(effDateVal) >= new Date(dataEffDate) && new Date(effDateVal) <= new Date(dataExpDate ?? effDateVal))
    return res;
  }

  private dateOverlapBool2(dataEffDate: string, dataExpDate: string | null, effDateVal: string, expDateVal: string | null): boolean {
    let res = (new Date(dataEffDate) <= new Date(expDateVal ?? dataEffDate) && new Date(effDateVal) <= new Date(dataExpDate ?? dataEffDate))
    return res;
  }

  public showErrorValidator = {
    defaultTowingLimitPerVehicle: () => {
      try {
        if (this.forms.defaultTowingLimitPerVehicleForm.errors) {
          const controlError = this.forms.defaultTowingLimitPerVehicleForm.errors;
          Object.keys(controlError).forEach(keyError => {
            if (keyError == 'hasConcurrent' && controlError[keyError] === true)
              this.errorMessage = "Unable to add record. Date of Effectivity is concurrent with another record. Please choose a different range of Date of Effectivity.";
          });
        }
        else if (this.forms.defaultTowingLimitPerVehicleForm.get('effDateTo')?.errors || this.forms.defaultTowingLimitPerVehicleForm.get('effDateFrom')?.errors) {
          this.errorMessage = "Invalid Effective/Expiry Date. Please input valid dates.";
        }
        else {
          Object.keys(this.forms.defaultTowingLimitPerVehicleForm.controls).forEach(key => {
            const controlErrors = this.forms.defaultTowingLimitPerVehicleForm.get(key)?.errors;
            if (controlErrors != null) {
              Object.keys(controlErrors).forEach(keyError => {
                if (keyError == 'matDatepickerMin' && key == 'effDateTo')
                  this.errorMessage = "Expiry Date must not be earlier than Effective Date.";
                else if (keyError == 'hasDecimalsExceeded' && controlErrors[keyError] === true)
                  this.errorMessage = "Invalid value in Towing Amount. The value entered exceeded the maximum limit.";
                else if (keyError == 'required' && controlErrors[keyError] === true)
                  this.errorMessage = "There are missing information. Please provide necessary information needed.";
              });
            }
          });
        }
        this._formService.showFormMsg("default-towing-limit-per-vehicle-error-message", this.errorMessage, "E");
      }
      catch (e) {
    console.error(e);

      }
    },
  }

  public allowNumericDigitsOnly(event: any) {
    if (/[0-9.]/.test(String.fromCharCode(event.keyCode))) {
      return true;
    }
    else {
      event.preventDefault();
      return false;
    }
  }

}
