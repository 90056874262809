<section id="contract-type">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <osp-table [tblConfig]="contractTypeTable" (onRowClick)="onRowClick($event)" [selectedRow]='selectedRow'></osp-table>
        <!-- <div id="contract-type-form"><div class="form-loader"></div></div> -->
        <div class="form-content" [hidden]="formHider">
            <form [formGroup]="contractTypeForm" class="form">
                <div id="contract-type-error-message"></div>
                <div class="form-container-grid">
                    <div class="field required">
                        <label>Contract Type Code</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="CONTRACT_TYPE_CD">
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="ACTIVE_TAG">
                            <option value="A">Yes</option>
                            <option value="I">No</option>
                        </select>
                    </div>
                    <div class="field required">
                        <label>Contract Type Description</label>
                    </div>
                    <div class="span">
                        <input formControlName="CONTRACT_TYPE_DESC" maxlength="250">
                    </div>
                    <div class="field required">
                        <label>BPIMS Code</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="BM_CODE" oninput="this.value = this.value.toUpperCase()" maxlength="8">
                    </div>
                    <div class="field required">
                        <label>BPIMS Short Description</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="BM_SHORT_DESC" maxlength="10">
                    </div>
                    <div class="field">
                        <label>COC Letter</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="COC_LETTER" maxlength="1">
                    </div>
                    <div class="field required">
                        <label>RENEWAL</label>
                    </div>
                    <div class="">
                        <select formControlName="RENEW_TAG">
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                        </select>
                    </div>
                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="REMARKS" maxlength="4000">
                    </div>
                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input formControlName="USER_ID">
                    </div>
                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="LAST_UPDATE">
                    </div>
                </div>
            </form>
            <div class="btn-grp">
                <button type="button" class="btn btn2" [hidden]="!addUpdateFlag" (click)="addRow()">ADD</button>
                <button type="button" class="btn btn2" [hidden]="addUpdateFlag" (click)="onUpdate()">UPDATE</button>
                <button type="button" class="btn btn2" [disabled]="deleteDisabler" (click)="deleteRow()">DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-grp">
                <button type="button" class="btn btn2" [disabled]="saveDisabler" (click)="onSave()">SAVE</button>
            </div>
        </div>
    </div>
</section>
