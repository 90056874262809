import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { CustomValidators } from 'src/app/utils/custom-validator';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';

@Component({
  selector: 'typhoon-flood-zoning-maintenance',
  templateUrl: './typhoon-flood-zoning-maintenance.component.html',
  styleUrls: ['./typhoon-flood-zoning-maintenance.component.css']
})
export class TyphoonFloodZoningMaintenanceComponent implements OnInit {

  addUpdateFlag: boolean = true;
  datePipe = new DatePipe('en-us');
  selectedRow: any = [];
  tempNewData: any = [];
  tempUpdateData: any = [];
  userId: string = this.userDetailService.userId;
  errorMessage: string = '';
  saveDisabler: boolean = true;
  deleteDisabler: boolean = true;
  formHider: boolean = false;
  moduleId: string = 'BMM142';
  moduleName = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId === 'BMM142' })[0]?.moduleDesc.toUpperCase();
  
  typhoonFloodZoningTable: any = {
    cols: [
      {
        key: "TF_ZONE_CD",
        header: "TF Zone Code",
        dataType: "string"
      },
      {
        key: "TF_ZONE_SHORT_DESC",
        header: "TF Zone Short Description",
        dataType: "string"
      },
      {
        key: "TF_ZONE_LONG_DESC",
        header: "TF Zone Long Description",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  typhoonFloodZoningForm = this.fb.group({
    TF_ZONE_CD:         [{value: '', disabled: true}],
    TF_ZONE_SHORT_DESC: ['', [Validators.required, this.duplicateShortDesc(), CustomValidators.requiredTrim]],
    TF_ZONE_LONG_DESC:  ['', [Validators.required, CustomValidators.requiredTrim]],
    ACTIVE_TAG:         ['A', [Validators.required]],
    BM_CODE:            ['', [Validators.required, CustomValidators.requiredTrim]],
    REMARKS:            [''],
    USER_ID:            [{value: '', disabled: true}],
    LAST_UPDATE:        [{value: '', disabled: true}],
  });

  constructor(
    private fb: FormBuilder,
    private api: APICallService,
    private dialog: MatDialog,
    private jsonDataService: JsonDataService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    private formService: FormService,
    private securityService: SecurityService
  ) { }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempNewData!='' || this.tempUpdateData!='') {
      return false;
    } else {
      return true;
    }
  }

  duplicateShortDesc(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const selectedRow = this.selectedRow;
      let shortDescDuplicate = this.typhoonFloodZoningTable.tblData.filter(function(e: any) {
        return e.TF_ZONE_SHORT_DESC.trim().toLowerCase()==control.value.trim().toLowerCase() && control.value!=selectedRow.TF_ZONE_SHORT_DESC;
      });
      if(shortDescDuplicate!='') {
        return { "shortDescDuplicate": "TF Zone Short Description already exists. Please add a unique TF Zone Short Description." }
      }
      return null;
    }
  }

  ngOnInit(): void {
    this.getTyphoonFloodZoning();
  }

  getTyphoonFloodZoning(): void {
    try {
      this.typhoonFloodZoningTable.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.api.getTyphoonFloodZoning({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE"
      }).subscribe((data: any) => {
        this.securityService.checkRequestKeyResponse(data, () => {
          this.securityService.hasValidCsrfToken(data, () => {
            if (data.status === "SUCCESS") {
              data = JSON.parse(this.jsonDataService.decrypt(data.response));
              this.typhoonFloodZoningTable.loading = false;
              this.jsonDataService.contorlLoading(false);
              this.typhoonFloodZoningTable.tblData = data;
              this.typhoonFloodZoningTable.tblData = [
                ...this.typhoonFloodZoningTable.tblData.filter((e: any) => e.ACTIVE_TAG === 'A'),
                ...this.typhoonFloodZoningTable.tblData.filter((e: any) => e.ACTIVE_TAG === 'I')
              ];
            } else {
                this.jsonDataService.contorlLoading(false);
                this.typhoonFloodZoningTable.loading = false;
                this.appMessageService.showAppMessage(data.message, "error");
            }
          });
        });
      });
    } catch(e) {
      
    }
  }

  onRowClick(ev: any): void {
    try {
      if(ev!=null) {
        this.selectedRow = ev;
        this.addUpdateFlag = false;
        this.formLoader();
      } else {
        this.selectedRow = '';
        this.addUpdateFlag = true;
        this.formClearer();
      }
      this.deleteEnabler();
    } catch(e) {
      
    }
  }

  formLoader() {
    try {
      this.typhoonFloodZoningForm.patchValue({
        TF_ZONE_CD:         this.selectedRow.TF_ZONE_CD,
        TF_ZONE_SHORT_DESC: this.selectedRow.TF_ZONE_SHORT_DESC,
        TF_ZONE_LONG_DESC:  this.selectedRow.TF_ZONE_LONG_DESC,
        ACTIVE_TAG:         this.selectedRow.ACTIVE_TAG,
        BM_CODE:            this.selectedRow.BM_CODE,
        REMARKS:            this.selectedRow.REMARKS,
        USER_ID:            this.selectedRow.USER_ID,
        LAST_UPDATE:        this.selectedRow.LAST_UPDATE,
      });
    } catch(e) {
      
    }
  }
  
  formClearer() {
    try {
      this.typhoonFloodZoningForm.patchValue({
        TF_ZONE_CD:         "",
        TF_ZONE_SHORT_DESC: "",
        TF_ZONE_LONG_DESC:  "",
        ACTIVE_TAG:         "A",
        BM_CODE:            "",
        REMARKS:            "",
        USER_ID:            "",
        LAST_UPDATE:        "",
      });
      this.selectedRow = [];
    } catch(e) {
      
    }
  }

  deleteEnabler() {
    try {
      let tempDeleteFlag = this.tempNewData.filter((e: any) => {
        return e.TF_ZONE_SHORT_DESC == this.selectedRow.TF_ZONE_SHORT_DESC
      });
      if(tempDeleteFlag=='' || tempDeleteFlag==null) {
        this.deleteDisabler = true;
      } else {
        this.deleteDisabler = false;
      }
    } catch(e) {
      
    }
  }

  addRow(): void {
    try {
      if(this.typhoonFloodZoningForm.valid) {
        this.formService.hideFormMsg("typhoon-flood-zoning-error-message");
        this.typhoonFloodZoningTable.tblData.unshift(this.formValues());
        this.typhoonFloodZoningTable.tblData = [...this.typhoonFloodZoningTable.tblData];
        this.tempNewData.push(this.formValues());
        this.formClearer();
        this.saveDisabler = false;
      } else {
        this.errorMessageSetter();
      }
    } catch(e) {
      
    }
  }

  formValues(): any {
    try {
      return {
        TF_ZONE_CD:         isNaN(parseInt(this.typhoonFloodZoningForm.get('TF_ZONE_CD')?.value)) ? "" : parseInt(this.typhoonFloodZoningForm.get('TF_ZONE_CD')?.value),
        TF_ZONE_SHORT_DESC: this.typhoonFloodZoningForm.get('TF_ZONE_SHORT_DESC')?.value,
        TF_ZONE_LONG_DESC:  this.typhoonFloodZoningForm.get('TF_ZONE_LONG_DESC')?.value,
        ACTIVE_TAG:         this.typhoonFloodZoningForm.get('ACTIVE_TAG')?.value,
        BM_CODE:            this.typhoonFloodZoningForm.get('BM_CODE')?.value,
        REMARKS:            this.typhoonFloodZoningForm.get('REMARKS')?.value,
        USER_ID:            this.userId,
        LAST_UPDATE:        this.typhoonFloodZoningForm.get('LAST_UPDATE')?.value,
      }
    } catch(e) {
      
    }
  }

  errorMessageSetter() {
    try {
      if(this.typhoonFloodZoningForm.invalid) {
        for(const field in this.typhoonFloodZoningForm.controls) {
          const controlName = this.typhoonFloodZoningForm.get(field);
          if(controlName?.errors?.required) {
            this.errorMessage = 'There are missing information. Please provide necessary information needed.';
          }
          if(controlName?.errors?.shortDescDuplicate) {
            this.errorMessage = controlName?.errors?.shortDescDuplicate;
          }
          this.formService.showFormMsg("typhoon-flood-zoning-error-message", this.errorMessage, "E");
        }
      }
    } catch(e) {
      
    }
  }

  onUpdate(): void {
    try {
      if(this.typhoonFloodZoningForm.valid) {
        this.updateTable();
        if(this.deleteDisabler) {
          this.updateDbData();
        } else {
          this.updateNewData();
        }
        this.selectedRow = '';
        this.addUpdateFlag = true;
        this.formClearer();
        this.saveDisabler = false;
        this.deleteEnabler();
        this.formService.hideFormMsg('typhoon-flood-zoning-error-message');
      } else {
        this.errorMessageSetter();
      }
    } catch(e) {
      
    }
  }

  updateTable() {
    try {
      let updateIndex = this.typhoonFloodZoningTable.tblData.indexOf(this.selectedRow);
      this.typhoonFloodZoningTable.tblData[updateIndex] = this.formValues();
      if(this.deleteDisabler) {
        this.typhoonFloodZoningTable.tblData[updateIndex].TF_ZONE_CD = this.typhoonFloodZoningTable.tblData[updateIndex].TF_ZONE_CD.toString().padStart(2, 0);
      }
      this.typhoonFloodZoningTable.tblData = [...this.typhoonFloodZoningTable.tblData];
    } catch(e) {
      
    }
  }

  updateDbData() {
    try {
      let tempDbData = this.tempUpdateData.find(
        (dbData: { TF_ZONE_CD: any }) => dbData.TF_ZONE_CD == this.selectedRow.TF_ZONE_CD
      );
      if((this.tempUpdateData == '' || this.tempUpdateData == null) || (tempDbData=='' || tempDbData==null)) {
        this.tempUpdateData.push(this.formValues());
      } else {
        tempDbData.TF_ZONE_CD         = parseInt(this.typhoonFloodZoningForm.get('TF_ZONE_CD')?.value);
        tempDbData.TF_ZONE_SHORT_DESC = this.typhoonFloodZoningForm.get('TF_ZONE_SHORT_DESC')?.value;
        tempDbData.TF_ZONE_LONG_DESC  = this.typhoonFloodZoningForm.get('TF_ZONE_LONG_DESC')?.value;
        tempDbData.ACTIVE_TAG         = this.typhoonFloodZoningForm.get('ACTIVE_TAG')?.value;
        tempDbData.BM_CODE            = this.typhoonFloodZoningForm.get('BM_CODE')?.value;
        tempDbData.REMARKS            = this.typhoonFloodZoningForm.get('REMARKS')?.value;
        tempDbData.USER_ID            = this.userId;
        tempDbData.LAST_UPDATE        = this.typhoonFloodZoningForm.get('LAST_UPDATE')?.value;
      }
    } catch(e) {
      
    }
  }

  updateNewData() {
    try {
      let tempNewConfigdata = this.tempNewData.find(
        (e: { TF_ZONE_SHORT_DESC: any }) => e.TF_ZONE_SHORT_DESC == this.selectedRow.TF_ZONE_SHORT_DESC
      );
      tempNewConfigdata.TF_ZONE_CD         = this.typhoonFloodZoningForm.get('TF_ZONE_CD')?.value;
      tempNewConfigdata.TF_ZONE_SHORT_DESC = this.typhoonFloodZoningForm.get('TF_ZONE_SHORT_DESC')?.value;
      tempNewConfigdata.TF_ZONE_LONG_DESC  = this.typhoonFloodZoningForm.get('TF_ZONE_LONG_DESC')?.value;
      tempNewConfigdata.ACTIVE_TAG         = this.typhoonFloodZoningForm.get('ACTIVE_TAG')?.value;
      tempNewConfigdata.BM_CODE            = this.typhoonFloodZoningForm.get('BM_CODE')?.value;
      tempNewConfigdata.REMARKS            = this.typhoonFloodZoningForm.get('REMARKS')?.value;
      tempNewConfigdata.USER_ID            = this.userId;
      tempNewConfigdata.LAST_UPDATE        = this.typhoonFloodZoningForm.get('LAST_UPDATE')?.value;
    } catch(e) {
      
    }
  }

  deleteRow(): void {
    try {
      let clickedData = this.selectedRow;
      this.typhoonFloodZoningTable.tblData = this.typhoonFloodZoningTable.tblData.filter(function(e: any) {
        return e.TF_ZONE_SHORT_DESC != clickedData.TF_ZONE_SHORT_DESC;
      });
      this.tempNewData = this.tempNewData.filter(function(e: any) {
        return e.TF_ZONE_SHORT_DESC != clickedData.TF_ZONE_SHORT_DESC;
      });
      this.selectedRow = '';
      this.formClearer();
      this.deleteDisabler = true;
      this.addUpdateFlag = true;
      if((this.tempNewData=='' || this.tempNewData==null) && (this.tempUpdateData=='' || this.tempUpdateData==null)) {
        this.saveDisabler = true;
      }
    } catch(e) {
      
    }
  }

  onSave(): void {
    try {
      this.formHider = true;
      this.formService.showFormLoader(null, "typhoon-flood-zoning-form", "Saving.<br>Please wait ...", null, null);
      this.jsonDataService.contorlLoading(true);
      this.api.saveTyphoonFloodZoning(this.tempNewData).subscribe({
        next: (data: any) => {
          this.securityService.checkRequestKeyResponse(data, () => {
            this.securityService.hasValidCsrfToken(data, () => {
              if(data.status == 'SUCCESS') {
                this.api.saveTyphoonFloodZoning(this.tempUpdateData).subscribe({
                  next: (res: any) => {
                    this.securityService.checkRequestKeyResponse(res, () => {
                      this.securityService.hasValidCsrfToken(data, () => {
                        if(res.status == 'SUCCESS') {
                          this.onComplete();
                        } else {
                          this.onFail();
                        }
                      });
                    });
                  },
                  error: () => {
                    this.onFail();
                  }
                });
              } else {
                this.onFail();
              }
            });
          });
        },
        error: () => {
          this.onFail();
        }
      });
    } catch(e) {
      
    }
  }
  
  onComplete() {
    try {
      this.formService.hideFormLoader("typhoon-flood-zoning-form");
      this.formHider = false;
      this.tempNewData = [];
      this.tempUpdateData = [];
      this.saveDisabler = true;
      this.deleteEnabler();
      this.formClearer();
      this.getTyphoonFloodZoning();
      this.formService.hideFormMsg("typhoon-flood-zoning-error-message");
      this.openSaveDialog();
    } catch(e) {
      
    }
  }

  onFail() {
    try {
      this.formService.hideFormLoader("typhoon-flood-zoning-form");
      this.formHider = false;
      this.tempNewData = [];
      this.tempUpdateData = [];
      this.deleteEnabler();
      this.formClearer();
      this.getTyphoonFloodZoning();
      this.formService.hideFormMsg("typhoon-flood-zoning-error-message");
      this.openErrorDialog("Saving Flood and Typhoon Zone Failed");
    } catch(e) {
      
    }
  }

  openErrorDialog(message: string) {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'ERROR',
          content: message,
        }
      });
    } catch(e) {
      
    }
  }

  openSaveDialog() {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'SUCCESS',
          content: 'Flood and Typhoon Zone Details Successfully Saved!',
        }
      });
    } catch(e) {
      
    }
  }

}
