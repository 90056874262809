import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { JsonDataService } from './json-data.service';
import { MessageBoxService } from './message-box.service';

@Injectable({
    providedIn: 'root'
})
export class UserDetailsService {

    private headers: HttpHeaders = new HttpHeaders;
    private apiUrl: string = this.jsonDataService.apiUrl || "/api";
    private apiAdminUrl: string = this.apiUrl || "/admin";

    public lastActionTime: any = new Date();
    private lastActionSavedTime: any = new Date();

    accountType: string = "";
    userId: string = "";
    name: string = "";
    email: string = "";
    expiry: string = "";
    windowId: number = 0;

    constructor (
        private router: Router,
        private httpClient: HttpClient,
        private jsonDataService: JsonDataService,
        private messageBoxService: MessageBoxService,
        private matDialog: MatDialog,
    ) { }

    public sessionExists(): boolean {
        return !!this.userId;
    }

    public setUserDetails(accountType: string, userId: string, name: string, email: string, expiry: number, windowId: number): void {
        this.accountType = accountType;
        this.userId = userId;
        this.name = name;
        this.email = email;
        this.windowId = windowId || 0;

        this.startSessionChecker(expiry);
    }

    public resetUserDetails(): void {
        this.accountType = "";
        this.userId = "";
        this.name = "";
        this.email = "";
        this.expiry = "";
        this.windowId = 0;
    }

    public setUserId(userId: string) {
        this.userId = userId;
    }

    // expiry - Value in seconds. If session timeout is 5 minutes, value of this variable should be 300. [5 * 60 = 300]
    private startSessionChecker(expiry: number): void {
        const bssid = sessionStorage.getItem("bssid") || "";
        if ((expiry || 0) > 0 && bssid) {
            const now: any = new Date();
            if (this.lastActionTime - this.lastActionSavedTime === 0 && now - this.lastActionSavedTime >= (expiry * 1000)) {
                this.logout().subscribe();
                sessionStorage.removeItem("bssid");
                sessionStorage.removeItem("rkc");
                this.resetUserDetails();
                this.matDialog.closeAll();
                this.router.navigate(["/"]);
                this.messageBoxService.show1("Session Timeout", "Your session has expired. Please login again.", "Ok", "");
            } else if (this.lastActionTime - this.lastActionSavedTime > 0 && now - this.lastActionSavedTime >= 60000) {
                const now = new Date();
                this.lastActionTime = now;
                this.lastActionSavedTime = now;
                this.updateActionDate().subscribe();
                setTimeout(() => this.startSessionChecker(expiry), 500);
            } else {
                setTimeout(() => this.startSessionChecker(expiry), 500);
            }
        }
    }

    private generateBssidRequestKey(): string {
        const bssid = sessionStorage.getItem("bssid") || "1";
        const rkc = sessionStorage.getItem("rkc") || "0";

        if (bssid !== "1" && rkc !== "0") {
            let token1 = this.createToken(bssid);
            let token2 = this.createToken(token1);
            let token3 = this.createToken(token2);

            token1 = token1.substr(0,  8) + rkc + token1.substr( 8);
            token2 = token2.substr(0, 16) + rkc + token2.substr(16);
            token3 = token3.substr(0, 24) + rkc + token3.substr(24);

            return `${token1}.${token2}.${token3}`;
        }

        return "1";
    }

    private createToken(data: string): string {
        return this.jsonDataService.encrypt(
            data.substr(30, 1) + data.substr(10, 1) + data.substr(40, 1) +
            data.substr(15, 1) + data.substr(35, 1) + data.substr( 5, 1) +
            data.substr(25, 1) + data.substr(20, 1));
    }

    private baseRequest(): void {
        this.headers = this.headers
            .set("uid", sessionStorage.getItem("bssid") || "1")
            .set("rk", this.generateBssidRequestKey())
            .set("ct", "1");
    }

    private updateActionDate(): any {
        this.baseRequest();
        return this.httpClient.post(this.apiUrl + "/update-action-date", {}, { headers: this.headers });
    }

    private logout(): any {
        this.baseRequest();
        return this.httpClient.post(this.apiAdminUrl + "/logout", {}, { headers: this.headers });
    }

}
