import { AfterViewInit, Component, OnInit, ViewChild, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { OverlayComponent } from '../../common/overlay/overlay';
import { OspTableComponent } from '../../common/osp-table/osp-table.component';
import { MatDialog, DialogRole } from '@angular/material/dialog';
import { APICallService } from '../../../services/api-call.service';
import { JsonDataService } from '../../../services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';

@Component({
  selector: 'lov',
  templateUrl: './lov.component.html',
  styleUrls: ['./lov.component.css']
})
export class LovComponent implements OnInit {

  @Input() data: any;

  @Output() selection = new EventEmitter<any>();

  @ViewChild('tbl') tbl!: OspTableComponent;
  @ViewChild('mdl') mdl!: OverlayComponent;
  @ViewChild('lovDialog') lovDialog!: TemplateRef<any>;

  selectedRow: any = null;
  selected: any[] = [];
  tblFilterText: any = '';

  tblConfig: any = {
      cols: [],
      tblData: [],
      selection: "single",
      paginator: true,
      rowsPerPage: 10,
      lazy: true,
      totalRecords: 5,
      pageLinks: 1
  }

  lovName: string = '';

  constructor(
    public dialog: MatDialog,
    private api: APICallService,
    private jds: JsonDataService,
    private securityService: SecurityService
  ) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    
  }

  open(title?: string) {
    this.lovName = title ? title : 'List of Values';
    this.selectedRow = null;
    this.selected = [];
    this.tblConfig.tblData = [];
    this.selectAllTag = this.data.selectAllTag;

    if(this.data.type == 'referror') {
      this.tblConfig.cols = [
        {
          key: "REFERROR_CD",
          header: "Code",
          dataType: "string",
          width: "20%"
        },
        {
          key: "REFERROR_DESC",
          header: "Description",
          dataType: "string",
          width: "50%",
          align: 'text-l'
        },
        {
          key: "BM_CODE",
          header: "BM Code",
          dataType: "string",
          width: "30%"
        }
      ]
    } else if(this.data.type == 'srcExt') {
      this.tblConfig.cols = [
        {
          key: "SRC_EXT_CD",
          header: "Code",
          dataType: "string",
          width: "20%"
        },
        {
          key: "SRC_EXT_DESC",
          header: "Description",
          dataType: "string",
          width: "50%",
          align: 'text-l'
        },
        {
          key: "BR_CODE",
          header: "Branch Code",
          dataType: "string",
          width: "30%"
        }
      ]
    } else if(this.data.type == 'segment') {
      this.tblConfig.cols = [
        {
          key: "SEGMENT_CD",
          header: "Code",
          dataType: "string",
          width: "20%"
        },
        {
          key: "SEGMENT_DESC",
          header: "Description",
          dataType: "string",
          width: "50%",
          align: 'text-l'
        },
        {
          key: "BM_CODE",
          header: "BM Code",
          dataType: "string",
          width: "30%"
        }
      ]
    } else if(this.data.type == 'contractType') {
      this.tblConfig.cols = [
        {
          key: "CONTRACT_TYPE_CD",
          header: "Code",
          dataType: "string",
          width: "15%"
        },
        {
          key: "CONTRACT_TYPE_DESC",
          header: "Description",
          dataType: "string",
          width: "45%",
          align: 'text-l'
        },
        {
          key: "BM_CODE",
          header: "BM Code",
          dataType: "string",
          width: "20%"
        },
        {
          key: "BM_SHORT_DESC",
          header: "BM Desc",
          dataType: "string",
          width: "20%"
        }
      ]
    } else if(this.data.type == 'BMM082_userLevel1'
        || this.data.type == 'BMM083_userLevel2'
        || this.data.type == 'BMM084_userLevel3'
        || this.data.type == 'BMM085_userLevel4') {
      this.tblConfig.cols = [
        {
          key: "selected",
          header: "",
          dataType: "checkboxWithVal",
          width: "10%",
          vals: ['Y', 'N'],
          disabled: false,
          selectAll: true,
          checked: this.data.selectAllTag,
          confirmation: true
        },
        {
          key: "USER_ID",
          header: "User ID",
          dataType: "string",
          width: "45%"
        },
        {
          key: "USER_NAME",
          header: "Name",
          dataType: "string",
          width: "45%"
        }
      ];

      this.selected = this.data.selected ? this.data.selected.slice() : [];
    } else if(this.data.type == 'BMM152_userLevel4') {
      this.tblConfig.cols = [
        {
          key: "selected",
          header: "",
          dataType: "checkboxWithVal",
          width: "10%",
          vals: ['Y', 'N'],
          disabled: false,
          selectAll: true,
          checked: this.data.selectAllTag,
          confirmation: true
        },
        {
          key: "SRC_EXT_CD",
          header: "Code",
          dataType: "string",
          width: "20%"
        },
        {
          key: "SRC_EXT_DESC",
          header: "Description",
          dataType: "string",
          width: "40%",
          align: 'text-l'
        },
        {
          key: "BR_CODE",
          header: "Branch Code",
          dataType: "string",
          width: "30%"
        }
      ];

      console.log('hereeeeeeeeeee');
      console.log(this.data.selected);

      this.selected = this.data.selected ? this.data.selected.slice() : [];
    }

    const dialogRole = this.dialog.open(this.lovDialog, {
      disableClose: true
    });
  }

  rowClick(ev: any) {
    this.selectedRow = ev;
  }

  getAdminLov(prm?: any) {
    let qryPrm: any = {};

    if(prm) {
      qryPrm = {
        first: prm.first,
        headUserId: prm.headUserId,
        officerUserId: prm.officerUserId,
        assistantUserId: prm.assistantUserId,
        rows: prm.rows,
        search: prm.search,
        sortBy: prm.sortBy,
        sortDir: prm.sortDir
      }
    }

    this.jds.contorlLoading(true);

    this.api.getAdminLov({
      type: this.data.type,
      first: qryPrm.first,
      headUserId: qryPrm.headUserId,
      officerUserId: qryPrm.officerUserId,
      assistantUserId: qryPrm.assistantUserId,
      rows: qryPrm.rows,
      search: qryPrm.search,
      sortBy: qryPrm.sortBy,
      sortDir: qryPrm.sortDir
    }).subscribe((data: any) => {
      this.securityService.checkRequestKeyResponse(data, () => {
        this.jds.contorlLoading(false);
          if (data.status === "SUCCESS") {
            data = JSON.parse(this.jds.decrypt(data.content));
            this.tblConfig.totalRecords = data['count'];
            this.tblConfig.pageLinks = 5;
            this.tblConfig.tblData = data['data'];

            if(this.selected && this.selected.length > 0) {
              
              for(var i = 0; i < this.selected.length; i++) {
                for(var j = 0; j < this.tblConfig.tblData.length; j++) {

                  if(this.data.type == 'BMM082_userLevel1'
                    || this.data.type == 'BMM083_userLevel2'
                    || this.data.type == 'BMM084_userLevel3'
                    || this.data.type == 'BMM085_userLevel4') {
                    if(this.tblConfig.tblData[j].USER_ID == this.selected[i].USER_ID) {
                      this.tblConfig.tblData[j].selected = 'Y';
                    }
                  }

                  if(this.data.type == 'BMM152_userLevel4') {
                    if(this.tblConfig.tblData[j].SRC_EXT_CD == this.selected[i].SRC_EXT_CD) {
                      this.tblConfig.tblData[j].selected = 'Y';
                    }
                  }
                }
              }

              // this.selected = this.data.selected;
            }
          }
      });
    });
  }

  lazyLoad(ev: any) {
    var sortField = this.tblConfig.cols[0].key;

    if(this.data.selection && this.data.selection == 'multi') {
      this.selected.concat(this.tblConfig.tblData.filter((a: any) => a.selected));
      sortField = this.tblConfig.cols[1].key;
    }

    this.tblFilterText = ev.globalFilter;

    setTimeout(() => {
      this.getAdminLov({
        first: ev.first,
        headUserId: this.data?.params?.headUserId,
        officerUserId: this.data?.params?.officerUserId,
        assistantUserId: this.data?.params?.assistantUserId,
        rows: this.tblConfig.rowsPerPage, 
        search: ev.globalFilter,
        sortBy: ev.sortField == undefined ? sortField : ev.sortField,
        sortDir: ev.sortField == undefined ? 1 : ev.sortOrder
      });
    }, 0);
  }

  onClickOk() {
    if(!this.data.selection) {
      this.selected.push(this.selectedRow);
    }

    if(this.selectAllTag) {
      this.jds.contorlLoading(true);
      this.bulkRetriever({first: 0});
    } else {
      this.selection.emit({
        selected: this.selected,
        selectAllTag: this.selectAllTag
      });
    }
  }

  rowEvent(data: any) {
    console.log(data);
    if(data.src == 'selected') {
      if(data.rowData.selected !== 'Y') { // check
        this.selected.push(data.rowData);

        if(this.data.type == 'BMM082_userLevel1'
          || this.data.type == 'BMM083_userLevel2'
          || this.data.type == 'BMM084_userLevel3'
          || this.data.type == 'BMM085_userLevel4') {
          if(this.selectAllTag) {
            this.excludeSelectList = this.excludeSelectList.filter((a: any) => a.USER_ID != data['rowData'].USER_ID);
          }
        }

        if(this.data.type == 'BMM152_userLevel4') {
          if(this.selectAllTag) {
            this.excludeSelectList = this.excludeSelectList.filter((a: any) => a.SRC_EXT_CD != data['rowData'].SRC_EXT_CD);
          }
        }
      } else if(data.rowData.selected == 'Y') { // uncheck
        if(this.data.type == 'BMM082_userLevel1'
          || this.data.type == 'BMM083_userLevel2'
          || this.data.type == 'BMM084_userLevel3'
          || this.data.type == 'BMM085_userLevel4') {
          this.selected = this.selected.filter((a: any) => a.USER_ID !== data.rowData.USER_ID);

          if(this.selectAllTag) {
            this.excludeSelectList = this.excludeSelectList.filter((a: any) => a.USER_ID != data['rowData'].USER_ID);
          }
        }

        if(this.data.type == 'BMM152_userLevel4') {
          this.selected = this.selected.filter((a: any) => a.SRC_EXT_CD !== data.rowData.SRC_EXT_CD);

          if(this.selectAllTag) {
            this.excludeSelectList = this.excludeSelectList.filter((a: any) => a.SRC_EXT_CD != data['rowData'].SRC_EXT_CD);
          }
        }

        this.excludeSelectList.push(data['rowData']);
      }
    }

  }

  // batch

  totalBulkRecords: number = 0;
  selectAllTag: boolean = false;
  selectList: any[] = [];
  excludeSelectList: any[] = [];

  toggleSelectAll(ev: any) {
    console.log(ev);

    if(this.data.selection && this.data.selection == 'multi') {
      if(ev.src == 'selected') {
        this.tblConfig.tblData.forEach((a: any) => {
          a['selected'] = ev.val;
        });
  
        this.selectAllTag = ev.val == 'Y';
        this.tblConfig.cols[0].checked = ev.val == 'Y';
        this.selectList = [];
        this.excludeSelectList = [];

        if(!this.selectAllTag) {
          this.selected = [];
        }
      }
    }

    console.log(this.selectAllTag);
  }

  bulkRetriever(param: any) {
    this.api.getAdminLov({
      type: this.data.type,
      first: param.first,
      headUserId: this.data?.params?.headUserId,
      officerUserId: this.data?.params?.officerUserId,
      assistantUserId: this.data?.params?.assistantUserId,
      rows: 100,
      search: this.tblFilterText,
      sortBy: null,
      sortDir: 1
    }).subscribe((data: any) => {
      this.securityService.checkRequestKeyResponse(data, () => {
        data['content'] = JSON.parse(this.jds.decrypt(data.content));
        this.totalBulkRecords += data['content']['data'].length;

        if(this.selectList.length == 0) {
          this.selectList = data['content']['data'];
        } else if(this.selectList.length > 0) {
          this.selectList = this.selectList.concat(data['content']['data']);
        }

        if(this.totalBulkRecords < data['content']['count']) {
          this.bulkRetriever({first: this.selectList.length});
        } else {
          this.jds.contorlLoading(false);

          if(this.data.type == 'BMM082_userLevel1'
            || this.data.type == 'BMM083_userLevel2'
            || this.data.type == 'BMM084_userLevel3'
            || this.data.type == 'BMM085_userLevel4') {
            this.selectList = this.selectList.filter((p: any) => !this.excludeSelectList.some((e: any) => e.USER_ID == p.USER_ID));
          }

          if(this.data.type == 'BMM152_userLevel4') {
            this.selectList = this.selectList.filter((p: any) => !this.excludeSelectList.some((e: any) => e.SRC_EXT_CD == p.SRC_EXT_CD));
          }

          console.log(this.excludeSelectList);
          console.log(this.selectList);

          this.selected = this.selectList.slice();

          this.totalBulkRecords = 0;
          this.selectList = [];
          this.excludeSelectList = [];

          this.selection.emit({
            selected: this.selected,
            selectAllTag: this.selectAllTag
          });
        }
      });
    });
  }

}
