import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { CustomValidators } from 'src/app/utils/custom-validator';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';

@Component({
  selector: 'relationship-maintenance',
  templateUrl: './relationship-maintenance.component.html',
  styleUrls: ['./relationship-maintenance.component.css']
})
export class RelationshipMaintenanceComponent implements OnInit {


  addUpdateFlag: boolean = true;
  datePipe = new DatePipe('en-us');
  selectedRow: any;
  tempNewData: any = [];
  tempUpdateData: any = [];
  userId: string = this.userDetailService.userId;
  errorMessage: string = '';
  saveDisabler: boolean = true;
  deleteDisabler: boolean = true;
  formHider: boolean = false;
  moduleId: string = 'BMM112';
  moduleName = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId === 'BMM112' })[0].moduleDesc.toUpperCase();
  
  relationshipTable: any = {
    cols: [
      {
        key: "RELATIONSHIP_CD",
        header: "Relationship Code",
        dataType: "string"
      },
      {
        key: "RELATIONSHIP_DESC",
        header: "Relationship",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  relationshipForm = this.fb.group({
    RELATIONSHIP_CD:    [{value: '', disabled: true}],
    ACTIVE_TAG:         ['A', [Validators.required]],
    RELATIONSHIP_DESC:  ['', [Validators.required, this.duplicateRelationshipDesc(), CustomValidators.requiredTrim]],
    PS_TAG:             ['', [Validators.required]],
    MULTIPLE_TAG:       ['', [Validators.required]],
    BM_CODE:            ['', [Validators.required, CustomValidators.requiredTrim]],
    REMARKS:            [''],
    USER_ID:            [{value: '', disabled: true}],
    LAST_UPDATE:        [{value: '', disabled: true}],
  });

  constructor(
    private fb: FormBuilder,
    private api: APICallService,
    private dialog: MatDialog,
    private jsonDataService: JsonDataService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    private formService: FormService,
    private securityService: SecurityService
  ) { }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempNewData!='' || this.tempUpdateData!='') {
      return false;
    } else {
      return true;
    }
  }

  duplicateRelationshipDesc(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const selected = this.selectedRow;
      let relationshipDesc = this.relationshipTable.tblData.filter(function(e: any) {
        return e.RELATIONSHIP_DESC.trim()==control.value.trim() && control.value.trim() !== selected?.RELATIONSHIP_DESC?.trim();
      });
      if(relationshipDesc!='') {
        return { "relationshipDuplicateDesc": { value: control.value } }
      }
      return null;
    }
  }

  ngOnInit(): void {
    this.getRelationship();
  }

  getRelationship(): void {
    try {
      this.relationshipTable.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.api.getRelationship({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE"
      }).subscribe((data: any) => {
        this.securityService.checkRequestKeyResponse(data, () => {
          this.securityService.hasValidCsrfToken(data, () => {
            if (data.status === "SUCCESS") {
                data = JSON.parse(this.jsonDataService.decrypt(data.response));
                this.relationshipTable.loading = false;
                this.jsonDataService.contorlLoading(false);
                this.relationshipTable.tblData = data;
            } else {
                this.jsonDataService.contorlLoading(false);
                this.relationshipTable.loading = false;
                this.appMessageService.showAppMessage(data.message, "error");
            }
          });
        });
      });
    } catch(e) {
      
    }
  }

  onRowClick(ev: any): void {
    try {
      if(ev!=null) {
        this.selectedRow = ev;
        this.addUpdateFlag = false;
        this.formLoader();
      } else {
        this.selectedRow = '';
        this.addUpdateFlag = true;
        this.formClearer();
      }
      this.deleteEnabler();
    } catch(e) {
      
    }
  }

  formLoader() {
    try {
      this.relationshipForm.patchValue({
        RELATIONSHIP_CD:    this.selectedRow.RELATIONSHIP_CD,
        ACTIVE_TAG:         this.selectedRow.ACTIVE_TAG,
        RELATIONSHIP_DESC:  this.selectedRow.RELATIONSHIP_DESC,
        PS_TAG:             this.selectedRow.PS_TAG,
        MULTIPLE_TAG:       this.selectedRow.MULTIPLE_TAG,
        BM_CODE:            this.selectedRow.BM_CODE,
        REMARKS:            this.selectedRow.REMARKS,
        USER_ID:            this.selectedRow.USER_ID,
        LAST_UPDATE:        this.selectedRow.LAST_UPDATE,
      });
    } catch(e) {
      
    }
  }
  
  formClearer() {
    try {
      this.relationshipForm.patchValue({
        RELATIONSHIP_CD:    "",
        ACTIVE_TAG:         "A",
        RELATIONSHIP_DESC:  "",
        PS_TAG:             "",
        MULTIPLE_TAG:       "",
        BM_CODE:            "",
        REMARKS:            "",
        USER_ID:            "",
        LAST_UPDATE:        ""
      });
    } catch(e) {
      
    }
  }

  deleteEnabler() {
    try {
      let tempDeleteFlag = this.tempNewData.filter((e: any) => {
        return e.RELATIONSHIP_DESC == this.selectedRow.RELATIONSHIP_DESC
      });
      if(tempDeleteFlag=='' || tempDeleteFlag==null) {
        this.deleteDisabler = true;
      } else {
        this.deleteDisabler = false;
      }
    } catch(e) {
      
    }
  }

  addRow(): void {
    try {
      if(this.relationshipForm.valid) {
        this.formService.hideFormMsg("relationship-error-message");
        this.tableInserter();
        this.tempAddInserter();
        this.formClearer();
        this.saveDisabler = false;
      } else {
        this.errorMessageSetter();
      }
    } catch(e) {
      
    }
  }

  tableInserter(): void {
    try {
      this.relationshipTable.tblData.unshift(this.formValues());
      this.relationshipTable.tblData = [...this.relationshipTable.tblData];
    } catch(e) {
      
    }
  }

  tempAddInserter(): void {
    try {
      this.tempNewData.push(this.formValues());
    } catch(e) {
      
    }
  }

  formValues(): any {
    try {
      return {
        RELATIONSHIP_CD:    isNaN(parseInt(this.relationshipForm.get('RELATIONSHIP_CD')?.value)) ? "" : parseInt(this.relationshipForm.get('RELATIONSHIP_CD')?.value),
        ACTIVE_TAG:         this.relationshipForm.get('ACTIVE_TAG')?.value,
        RELATIONSHIP_DESC:  this.relationshipForm.get('RELATIONSHIP_DESC')?.value,
        PS_TAG:             this.relationshipForm.get('PS_TAG')?.value,
        MULTIPLE_TAG:       this.relationshipForm.get('MULTIPLE_TAG')?.value,
        BM_CODE:            this.relationshipForm.get('BM_CODE')?.value,
        REMARKS:            this.relationshipForm.get('REMARKS')?.value,
        USER_ID:            this.userId,
        LAST_UPDATE:        this.relationshipForm.get('LAST_UPDATE')?.value
      }
    } catch(e) {
      
    }
  }

  errorMessageSetter() {
    try {
      if(this.relationshipForm.invalid) {
        for(const field in this.relationshipForm.controls) {
          const controlName = this.relationshipForm.get(field);
          if(controlName?.errors?.required) {
            this.errorMessage = 'There are missing information. Please provide necessary information needed.';
          }
          if(controlName?.errors?.relationshipDuplicateDesc) {
            this.errorMessage = 'Relationship already exists. Please add a unique Relationship.';
          }
          this.formService.showFormMsg("relationship-error-message", this.errorMessage, "E");
        }
      }
    } catch(e) {
      
    }
  }

  onUpdate(): void {
    try {
      if(this.relationshipForm.valid) {
        this.updateTable();
        if(this.deleteDisabler) {
          this.updateDbData();
        } else {
          this.updateNewData();
        }
        this.selectedRow = '';
        this.addUpdateFlag = true;
        this.formClearer();
        this.saveDisabler = false;
        this.deleteEnabler();
        this.formService.hideFormMsg('relationship-error-message');
      } else {
        this.errorMessageSetter();
      }
    } catch(e) {
      
    }
  }

  updateTable() {
    try {
      let updateIndex = this.relationshipTable.tblData.indexOf(this.selectedRow);
      this.relationshipTable.tblData[updateIndex] = this.formValues();
      this.relationshipTable.tblData[updateIndex].RELATIONSHIP_DESC = this.relationshipTable.tblData[updateIndex].RELATIONSHIP_DESC.toString().padStart(2, 0);
      this.relationshipTable.tblData = [...this.relationshipTable.tblData];
    } catch(e) {
      
    }
  }

  updateDbData() {
    try {
      let tempDbData = this.tempUpdateData.find(
        (relationship: { RELATIONSHIP_DESC: any }) => relationship.RELATIONSHIP_DESC == this.selectedRow.RELATIONSHIP_DESC
      );
      if((this.tempUpdateData == '' || this.tempUpdateData == null) || (tempDbData=='' || tempDbData==null)) {
        this.tempUpdateData.push(this.formValues());
      } else {
        tempDbData.RELATIONSHIP_CD    = parseInt(this.relationshipForm.get('RELATIONSHIP_CD')?.value);
        tempDbData.ACTIVE_TAG         = this.relationshipForm.get('ACTIVE_TAG')?.value;
        tempDbData.RELATIONSHIP_DESC  = this.relationshipForm.get('RELATIONSHIP_DESC')?.value;
        tempDbData.PS_TAG             = this.relationshipForm.get('PS_TAG')?.value;
        tempDbData.MULTIPLE_TAG       = this.relationshipForm.get('MULTIPLE_TAG')?.value;
        tempDbData.BM_CODE            = this.relationshipForm.get('BM_CODE')?.value;
        tempDbData.REMARKS            = this.relationshipForm.get('REMARKS')?.value;
        tempDbData.USER_ID            = this.userId;
        tempDbData.LAST_UPDATE        = this.relationshipForm.get('LAST_UPDATE')?.value;
      }
    } catch(e) {
      
    }
  }

  updateNewData() {
    try {
      let tempNewConfigdata = this.tempNewData.find(
        (relationship: { RELATIONSHIP_DESC: any }) => relationship.RELATIONSHIP_DESC == this.selectedRow.RELATIONSHIP_DESC
      );
      tempNewConfigdata.RELATIONSHIP_CD    = this.relationshipForm.get('RELATIONSHIP_CD')?.value;
      tempNewConfigdata.ACTIVE_TAG         = this.relationshipForm.get('ACTIVE_TAG')?.value;
      tempNewConfigdata.RELATIONSHIP_DESC  = this.relationshipForm.get('RELATIONSHIP_DESC')?.value;
      tempNewConfigdata.PS_TAG             = this.relationshipForm.get('PS_TAG')?.value;
      tempNewConfigdata.MULTIPLE_TAG       = this.relationshipForm.get('MULTIPLE_TAG')?.value;
      tempNewConfigdata.BM_CODE            = this.relationshipForm.get('BM_CODE')?.value;
      tempNewConfigdata.REMARKS            = this.relationshipForm.get('REMARKS')?.value;
      tempNewConfigdata.USER_ID            = this.userId;
      tempNewConfigdata.LAST_UPDATE        = this.relationshipForm.get('LAST_UPDATE')?.value;
    } catch(e) {
      
    }
  }

  deleteRow(): void {
    try {
      let clickedData = this.selectedRow;
      this.relationshipTable.tblData = this.relationshipTable.tblData.filter(function(e: any) {
        return e.RELATIONSHIP_DESC != clickedData.RELATIONSHIP_DESC;
      });
      this.tempNewData = this.tempNewData.filter(function(e: any) {
        return e.RELATIONSHIP_DESC != clickedData.RELATIONSHIP_DESC;
      });
      this.selectedRow = '';
      this.formClearer();
      this.deleteDisabler = true;
      this.addUpdateFlag = true;
      if((this.tempNewData=='' || this.tempNewData==null) && (this.tempUpdateData=='' || this.tempUpdateData==null)) {
        this.saveDisabler = true;
      }
    } catch(e) {
      
    }
  }

  onSave(): void {
    try {
      this.formHider = true;
      this.formService.showFormLoader(null, "mv-prem-type-form", "Saving.<br>Please wait ...", null, null);
      this.jsonDataService.contorlLoading(true);
      this.api.saveRelationship(this.tempNewData).subscribe({
        next: (data: any) => {
          this.securityService.checkRequestKeyResponse(data, () => {
            this.securityService.hasValidCsrfToken(data, () => {
              if(data.status == 'SUCCESS') {
                this.api.saveRelationship(this.tempUpdateData).subscribe({
                  next: (res: any) => {
                    this.securityService.checkRequestKeyResponse(res, () => {
                      this.securityService.hasValidCsrfToken(res, () => {
                        if(res.status == 'SUCCESS') {
                          this.onComplete();
                        } else {
                          this.onFail();
                        }
                      });
                    });
                  },
                  error: () => {
                    this.onFail();
                  }
                });
              } else {
                this.onFail();
              }
            });
          });
        },
        error: () => {
          this.onFail();
        }
      });
    } catch(e) {
      
    }
  }
  
  onComplete() {
    try {
      this.formService.hideFormLoader("mv-prem-type-form");
      this.formHider = false;
      this.tempNewData = [];
      this.tempUpdateData = [];
      this.saveDisabler = true;
      this.formClearer();
      this.getRelationship();
      this.deleteEnabler();
      this.formService.hideFormMsg("relationship-error-message");
      this.openSaveDialog();
      this.addUpdateFlag = true;
    } catch(e) {
      
    }
  }

  onFail() {
    try {
      this.formService.hideFormLoader("mv-prem-type-form");
      this.formHider = false;
      this.tempNewData = [];
      this.tempUpdateData = [];
      this.formClearer();
      this.getRelationship();
      this.deleteEnabler();
      this.addUpdateFlag = true;
      this.formService.hideFormMsg("relationship-error-message");
      this.openErrorDialog("Saving Relationship Failed");
    } catch(e) {
      
    }
  }

  openErrorDialog(message: string) {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'ERROR',
          content: message,
        }
      });
    } catch(e) {
      
    }
  }

  openSaveDialog() {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'SUCCESS',
          content: 'Relationship Details Successfully Saved!',
        }
      });
    } catch(e) {
      
    }
  }
}
