import { AfterViewInit, Component, OnInit, ViewChild, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { OverlayComponent } from '../../common/overlay/overlay';
import { OspTableComponent } from '../../common/osp-table/osp-table.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'revision-history',
  templateUrl: './revision-history.component.html',
  styleUrls: ['./revision-history.component.css']
})
export class RevisionHistoryComponent implements OnInit, AfterViewInit {

  @Input() data: any;

  @Output() onLazyLoad = new EventEmitter<any>();

  @ViewChild('tbl') tbl!: OspTableComponent;
  @ViewChild('mdl') mdl!: OverlayComponent;
  @ViewChild('rhDialog') rhDialog!: TemplateRef<any>;

  historyRemarks: String = '';

  constructor(
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    
  }

  open() {
    this.historyRemarks = '';
    const dialogRole = this.dialog.open(this.rhDialog, {
      disableClose: true
    });
  }

  rowClick(ev: any) {
    this.historyRemarks = ev !== null ? ev.remarks.split('. ').join('.\n') : '';
  }

  lazyLoad(ev: any) {
    this.onLazyLoad.emit(ev);
  }

}
