import { Component, Inject, OnInit } from '@angular/core';
import { APICallService } from 'src/app/services/api-call.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

@Component({
  selector: 'ref-lov',
  templateUrl: './ref-lov.component.html',
  styleUrls: ['./ref-lov.component.css'],
})
export class RefLovComponent implements OnInit {
  referrorModuleId: string = 'BMM058';

  constructor(
    private apiCallService: APICallService,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<RefLovComponent>
  ) {
    this.refTableConfig.cols =
      this.data.col === 'CD' ? this.refColsCd : this.refColsBm;
    this.getReferror();
  }

  ngOnInit(): void {}

  // Variable Initializer
  refData: any = [];
  pages: any = [];
  refColsCd: any[] = [
    {
      key: 'REFERROR_CD',
      header: 'Referror Code',
      dataType: 'string',
    },
    {
      key: 'REFERROR_DESC',
      header: 'Referror Description',
      dataType: 'string',
    },
  ];
  recordsPerPage: number = 10000;

  refColsBm: any[] = [
    {
      key: 'BM_CODE',
      header: 'Referror Code',
      dataType: 'string',
    },
    {
      key: 'REFERROR_DESC',
      header: 'Referror Description',
      dataType: 'string',
    },
  ];

  // Source Code Initializer
  refTableConfig: any = {
    cols: [],
    tblData: [],
    selection: 'single',
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
  };

  getReferror() {
    try {
      this.refTableConfig.tblData = [];
      this.refTableConfig.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.apiCallService
        .getReferrorPageCount({
          moduleId: this.referrorModuleId,
          userId: this.userDetailService.userId,
          type: 'MODULE',
          recordsCount: this.recordsPerPage,
        })
        .subscribe((data: any) => {
          this.securityService.checkRequestKeyResponse(data, () => {
            this.securityService.hasValidCsrfToken(data, () => {
              if (data.status == 'SUCCESS') {
                data = JSON.parse(this.jsonDataService.decrypt(data.response));
                let pageCount = data;
                for (let i = 1; i <= pageCount; i++) {
                  let offSetValue = (i - 1) * this.recordsPerPage;
                  this.pages.push(offSetValue);
                }
                this.getReferrorData();
              } else {
              }
            });
          });
        });
    } catch (e) {
      console.error(e);
    }
  }

  async getReferrorData() {
    try {
      const offSetValue = this.pages[0];
      this.apiCallService
        .getReferror({
          offSetValue: offSetValue,
          moduleId: this.data.moduleId,
          userId: this.userDetailService.userId,
          type: 'LOV',
        })
        .subscribe((data: any) => {
          this.securityService.checkRequestKeyResponse(data, () => {
            this.securityService.hasValidCsrfToken(data, () => {
              if (data.status === 'SUCCESS') {
                const content = JSON.parse(
                  this.jsonDataService.decrypt(data.response)
                );
                if (content.moduleId === this.referrorModuleId) {
                  this.pages.splice(0, 1);
                  this.refTableConfig.tblData = this.refTableConfig.tblData
                    .concat(content.data)
                    .sort((a: any, b: any) =>
                      a.REFERROR_CD > b.REFERROR_CD ? 1 : -1
                    );
                  if (this.pages.length !== 0) {
                    this.getReferrorData();
                  } else {
                    this.refTableConfig.tblData =
                      this.refTableConfig.tblData.filter(
                        (e: any) => e.ACTIVE_TAG == 'A'
                      );
                    if (this.data.lpad) {
                      this.refTableConfig.tblData =
                        this.refTableConfig.tblData.map((c: any) => {
                          return {
                            ...c,
                            REFERROR_CD: parseInt(c.REFERROR_CD)
                              ?.toString()
                              .padStart(this.data?.lpad, '0'),
                          };
                        });
                    }
                    this.refTableConfig.loading = false;
                    this.jsonDataService.contorlLoading(false);
                  }
                } else {
                  this.refTableConfig.loading = false;
                  this.jsonDataService.contorlLoading(false);
                  this.appMessageService.showAppMessage(
                    'You do not have the correct privilege to access the data. Kindly contact your administrator for assistance.',
                    'error'
                  );
                }
              } else {
                this.refTableConfig.loading = false;
                this.jsonDataService.contorlLoading(false);
                this.appMessageService.showAppMessage(data.message, 'error');
              }
            });
          });
        });
    } catch (e) {
      console.error(e);
    }
  }

  onRowClick(ev: any) {
    try {
      this.data = ev;
    } catch (e) {
      console.error(e);
    }
  }

  onClose() {
    try {
      if (this.data == '' || this.data == null) {
        this._dialogRef.close({ content: '' });
      } else {
        this._dialogRef.close({ content: this.data });
      }
    } catch (e) {
      console.error(e);
    }
  }

  onBack() {
    try {
      this._dialogRef.close({ content: '', option: 'back' });
    } catch (e) {
      console.error(e);
    }
  }
}
