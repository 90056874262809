import { Component, Inject, OnInit } from '@angular/core';
import { APICallService } from 'src/app/services/api-call.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { AppMessageService } from 'src/app/services/app-message.service';

@Component({
  selector: 'tf-zone-lov',
  templateUrl: './tf-zone-lov.component.html',
  styleUrls: ['./tf-zone-lov.component.css']
})
export class TFZoneLovComponent {

  moduleId: string = 'BMM142';
  isShortDesc: boolean = false;
  private selectedData: any;
  public titleLov: string = "TF ZONE";

  table: any = {
    cols: [
      {
        key: "tfZoneCd",
        header: "TF Zone Code",
        dataType: "string",
        width: '30%'
      },
      {
        key: "tfZoneDesc",
        header: "TF Zone",
        dataType: "string"
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  };

  tableShortDesc: any = {
    cols: [
      {
        key: "tfZoneCd",
        header: "TF Zone Code",
        dataType: "string",
        width: '30%'
      },
      {
        key: "tfZoneDesc",
        header: "TF Zone Short Description",
        dataType: "string"
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  };

  constructor(
    private jsonDataService: JsonDataService,
    private dialogRef: MatDialogRef<TFZoneLovComponent>,
    private _api: APICallService,
    private securityService: SecurityService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    @Inject(MAT_DIALOG_DATA) public fromMainData: any,
  ) { 
    if("desc" in this.fromMainData){
      if(this.fromMainData.desc == "SHORT"){
        this.isShortDesc= true;
        this.getShortData();
      }
      else{
        this.isShortDesc= false;
        this.getData();
      }
    }
    else{
      this.isShortDesc= false;
      this.getData();
    }
  }

  onRowClick(ev: any) {
    try {
      this.selectedData = ev;
    } catch (e) {
    console.error(e);
      
    }
  }

  private getData(): void {
    try {
      this.table.loading = true;
      this.jsonDataService.contorlLoading(true);
      this._api.getTyphoonFloodZoning({
        moduleId: this.fromMainData.moduleId,
        userId: this.userDetailService.userId,
        type: "LOV",
        stateCd: this.fromMainData.stateCd
      }).subscribe({
        next: (data: any) => {
          this.securityService.checkRequestKeyResponse(data, () => {
            this.securityService.hasValidCsrfToken(data, () => {
              if (data.status === "SUCCESS") {
                let content = JSON.parse(this.jsonDataService.decrypt(data.response));
                this.jsonDataService.contorlLoading(false);
                this.table.tblData = content.filter((d: any) =>{
                  if(d.ACTIVE_TAG === 'A'){
                    d.tfZoneCd = d.TF_ZONE_CD;
                    d.tfZoneDesc = d.TF_ZONE_LONG_DESC;
                    return true;
                  }else{
                    return false;
                  }
                });
                this.table.loading = false;
              } else {
                  this.jsonDataService.contorlLoading(false);
                  this.table.loading = false;
                  this.appMessageService.showAppMessage(data.message, "error");
              }
            });
          });
        },
        error: (e: any) => {
          this.securityService.checkRequestKeyResponse(e, () => {
            
            this.table.loading = false;
            this.jsonDataService.contorlLoading(false);
            this.appMessageService.showAppMessage(e, "error");
          });
        }
      });
    }
    catch (e) {
    console.error(e);
      
      this.table.loading = false;
      this.jsonDataService.contorlLoading(false);
    }
  }

  private getShortData(): void {
    try {
      this.tableShortDesc.loading = true;
      this.jsonDataService.contorlLoading(true);
      this._api.getTyphoonFloodZoning({
        moduleId: this.fromMainData.moduleId,
        userId: this.userDetailService.userId,
        type: "LOV",
        stateCd: this.fromMainData.stateCd
      }).subscribe({
        next: (data: any) => {
          this.securityService.checkRequestKeyResponse(data, () => {
            this.securityService.hasValidCsrfToken(data, () => {
              if (data.status === "SUCCESS") {
                let content = JSON.parse(this.jsonDataService.decrypt(data.response));
                this.jsonDataService.contorlLoading(false);
                this.tableShortDesc.tblData = content.filter((d: any) =>{
                  if(d.ACTIVE_TAG === 'A'){
                    d.tfZoneCd = d.TF_ZONE_CD;
                    d.tfZoneDesc = d.TF_ZONE_SHORT_DESC;
                    return true;
                  }else{
                    return false;
                  }
                });
                this.tableShortDesc.loading = false;
              } else {
                  this.jsonDataService.contorlLoading(false);
                  this.tableShortDesc.loading = false;
                  this.appMessageService.showAppMessage(data.message, "error");
              }
            });
          });
        },
        error: (e: any) => {
          this.securityService.checkRequestKeyResponse(e, () => {
            
            this.tableShortDesc.loading = false;
            this.jsonDataService.contorlLoading(false);
            this.appMessageService.showAppMessage(e, "error");
          });
        }
      });
    }
    catch (e) {
    console.error(e);
      
      this.tableShortDesc.loading = false;
      this.jsonDataService.contorlLoading(false);
    }
  }
  onClose(str: string) {
    try {
      if (this.selectedData == '' || this.selectedData == null) {
        this.dialogRef.close({ content: '', button: str });
      }
      else {
        this.dialogRef.close({ content: this.selectedData, button: str });
      }
    } catch (e) {
    console.error(e);
      
    }
  }

}
