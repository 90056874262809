import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { AgentGroupLovDialogComponent } from '../../common/agent-group-lov/agent-group-lov.component';
import { ClientGroupLovDialog } from '../../common/client-group-lov-dialog/client-group-lov-dialog.component';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';

@Component({
  selector: 'client-group-per-agent-group-maintenance',
  templateUrl: './client-group-per-agent-group-maintenance.component.html',
  styleUrls: ['./client-group-per-agent-group-maintenance.component.css']
})
export class ClientGroupPerAgentGroupMaintenanceComponent implements OnInit {

  addUpdateFlag: boolean = true;
  datePipe = new DatePipe('en-us');
  selectedRow: any = [];
  tempNewData: any = [];
  tempUpdateData: any = [];
  userId: string = this.userDetailService.userId;
  errorMessage: string = '';
  saveDisabler: boolean = true;
  deleteDisabler: boolean = true;
  formHider: boolean = false;
  moduleId: string = 'BMM184';
  moduleName = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId === 'BMM184' })[0]?.moduleDesc.toUpperCase();
  itemLevel1DropDown: any = this.jsonDataService.data.refCds.filter((data: any) => { return data?.rvDomain === 'ITEM_LEVEL1' });
  itemLevel2DropDown: any = this.jsonDataService.data.refCds.filter((data: any) => { return data?.rvDomain === 'ITEM_LEVEL2' });
  
  table: any = {
    cols: [
      {
        key: "AGENT_GRP",
        header: "Agent Group",
        dataType: "string"
      },
      {
        key: "ITEM_LEVEL1_DESC",
        header: "Item Level 1",
        dataType: "string"
      },
      {
        key: "ITEM_LEVEL2_DESC",
        header: "Item Level 2",
        dataType: "string"
      },
      {
        key: "CLIENT_CD",
        header: "Client Group",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  form = this.fb.group({
    AGENT_GRP         : ['', [Validators.required, this.duplicate("AGENT_GRP")]],
    AGENT_GRP_DESC    : [''],
    ITEM_LEVEL1       : ['', [Validators.required, this.duplicate("ITEM_LEVEL1")]],
    ITEM_LEVEL1_DESC  : [''],
    ITEM_LEVEL2       : ['', [Validators.required, this.duplicate("ITEM_LEVEL2")]],
    ITEM_LEVEL2_DESC  : [''],
    CLIENT_CD         : ['', [Validators.required, this.duplicate("CLIENT_CD")]],
    CLIENT_CD_DESC    : [''],
    EFF_DATE_FROM     : ['', [Validators.required, this.lateEffDate()]],
    EFF_DATE_TO       : ['', [this.earlyExpDate()]],
    ACTIVE_TAG        : ['A'],
    REMARKS           : [''],
    LAST_USER         : [{value: '', disabled: true}],
    LAST_USER_UPDATE  : [{value: '', disabled: true}],
  });

  constructor(
    private fb: FormBuilder,
    private api: APICallService,
    private dialog: MatDialog,
    private jsonDataService: JsonDataService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    private formService: FormService,
    private securityService: SecurityService
  ) { }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempNewData!='' || this.tempUpdateData!='') {
      return false;
    } else {
      return true;
    }
  }

  duplicate(field: string): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const selectedRow = this.selectedRow;
      let duplicate = this.table.tblData.filter((e: any) => {
        return e.AGENT_GRP === this.form.get("AGENT_GRP")?.value &&
               e.ITEM_LEVEL1 === this.form.get("ITEM_LEVEL1")?.value &&
               e.ITEM_LEVEL2 === this.form.get("ITEM_LEVEL2")?.value &&
               e.CLIENT_CD === this.form.get("CLIENT_CD")?.value &&
               control.value!=selectedRow[field];
      });
      if(duplicate!='') {
        return { "duplicate": "Agent Group, Item Level 1, Item Level 2, and Client Group already exists. Please add a unique Agent Group, Item Level 1, Item Level 2, and Client Group." }
      }
      return null;
    }
  }

  lateEffDate(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if(control.value && this.form.get("EFF_DATE_TO")?.value && control.value.isAfter(this.form.get("EFF_DATE_TO")?.value)) {
        return { "effDate" : "Effective Date cannot be later than Expiry Date" }
      }
      return null;
    }
  }

  earlyExpDate(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if(control.value && control.value.isBefore(this.form.get("EFF_DATE_FROM")?.value)) {
        return { "expDate" : "Expiry Date cannot be earlier than Effective Date" }
      }
      return null;
    }
  }

  ngOnInit(): void {
    this.getClientGrpPerAgentGrp();
  }

  getClientGrpPerAgentGrp(): void {
    try {
      this.table.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.api.getClientGrpPerAgentGrp({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE"
      }).subscribe((data: any) => {
        this.securityService.checkRequestKeyResponse(data, () => {
          this.securityService.hasValidCsrfToken(data, () => {
            if (data.status === "SUCCESS") {
              data = JSON.parse(this.jsonDataService.decrypt(data.response));
              this.table.loading = false;
              this.jsonDataService.contorlLoading(false);
              this.table.tblData = data.data;
              this.table.tblData = [
                ...this.table.tblData.filter((e: any) => e.ACTIVE_TAG === 'A'),
                ...this.table.tblData.filter((e: any) => e.ACTIVE_TAG === 'I')
              ];
            } else {
                this.jsonDataService.contorlLoading(false);
                this.table.loading = false;
                this.appMessageService.showAppMessage(data.message, "error");
            }
          });
        });
      });
    } catch(e) {
      
    }
  }

  onRowClick(ev: any): void {
    try {
      if(ev!=null) {
        this.selectedRow = ev;
        this.addUpdateFlag = false;
        this.formLoader();
      } else {
        this.selectedRow = '';
        this.addUpdateFlag = true;
        this.formClearer();
      }
      this.deleteEnabler();
    } catch(e) {
      
    }
  }

  formLoader() {
    try {
      this.form.get("AGENT_GRP")?.disable();
      this.form.get("ITEM_LEVEL1")?.disable();
      this.form.get("ITEM_LEVEL2")?.disable();
      this.form.get("CLIENT_CD")?.disable();
      this.form.patchValue({
        AGENT_GRP         : this.selectedRow.AGENT_GRP + " - " + this.selectedRow.AGENT_GRP_DESC,
        AGENT_GRP_DESC    : this.selectedRow.AGENT_GRP_DESC  ,
        ITEM_LEVEL1       : this.selectedRow.ITEM_LEVEL1     ,
        ITEM_LEVEL1_DESC  : this.selectedRow.ITEM_LEVEL1_DESC,
        ITEM_LEVEL2       : this.selectedRow.ITEM_LEVEL2     ,
        ITEM_LEVEL2_DESC  : this.selectedRow.ITEM_LEVEL2_DESC,
        CLIENT_CD         : this.selectedRow.CLIENT_CD + " - " + this.selectedRow.CLIENT_CD_DESC,
        CLIENT_CD_DESC    : this.selectedRow.CLIENT_CD_DESC  ,
        EFF_DATE_FROM     : moment(this.selectedRow.EFF_DATE_FROM, "MM/DD/YYYY"),
        EFF_DATE_TO       : moment.isMoment(this.selectedRow.EFF_DATE_TO) ? this.selectedRow.EFF_DATE_TO : !this.selectedRow.EFF_DATE_TO ? "" : moment(this.selectedRow.EFF_DATE_TO, "MM/DD/YYYY"),
        ACTIVE_TAG        : this.selectedRow.ACTIVE_TAG      ,
        REMARKS           : this.selectedRow.REMARKS         ,
        LAST_USER         : this.selectedRow.LAST_USER       ,
        LAST_USER_UPDATE  : this.selectedRow.LAST_USER_UPDATE,
      });
      this.form.get("AGENT_GRP")?.setValue(this.selectedRow.AGENT_GRP, {emitModelToViewChange: false});
      this.form.get("CLIENT_CD")?.setValue(this.selectedRow.CLIENT_CD, {emitModelToViewChange: false});
    } catch(e) {
      
    }
  }
  
  formClearer() {
    try {
      this.form.get("AGENT_GRP")?.enable();
      this.form.get("ITEM_LEVEL1")?.enable();
      this.form.get("ITEM_LEVEL2")?.enable();
      this.form.get("CLIENT_CD")?.enable();
      this.form.patchValue({
        AGENT_GRP         : "",
        AGENT_GRP_DESC    : "",
        ITEM_LEVEL1       : "",
        ITEM_LEVEL1_DESC  : "",
        ITEM_LEVEL2       : "",
        ITEM_LEVEL2_DESC  : "",
        CLIENT_CD         : "",
        CLIENT_CD_DESC    : "",
        EFF_DATE_FROM     : "",
        EFF_DATE_TO       : "",
        ACTIVE_TAG        : "A",
        REMARKS           : "",
        LAST_USER         : "",
        LAST_USER_UPDATE  : "",
      });
      this.selectedRow = [];
    } catch(e) {
      
    }
  }

  deleteEnabler() {
    try {
      let tempDeleteFlag = this.tempNewData.filter((e: any) => {
        return e.AGENT_GRP    == this.selectedRow.AGENT_GRP &&
               e.ITEM_LEVEL1  == this.selectedRow.ITEM_LEVEL1 &&
               e.ITEM_LEVEL2  == this.selectedRow.ITEM_LEVEL2 &&
               e.CLIENT_CD    == this.selectedRow.CLIENT_CD
      });
      if(tempDeleteFlag=='' || tempDeleteFlag==null) {
        this.deleteDisabler = true;
      } else {
        this.deleteDisabler = false;
      }
    } catch(e) {
      
    }
  }
  
  openAgentGrpDialog() {
    try {
      if(this.selectedRow.length === 0) {
        this.dialog.open(AgentGroupLovDialogComponent, {
          width: '512px',
          disableClose: true,
          data: {
            userId: this.userDetailService.userId,
            moduleId: this.moduleId
          }
        }).afterClosed().subscribe(data => {
          if (data.content != '' && data.content != null) {
            this.form.patchValue({
              AGENT_GRP         : data?.content?.AGENT_GRP + " - " + data?.content?.AGENT_GRP_DESC,
              AGENT_GRP_DESC    : data?.content?.AGENT_GRP_DESC,
            });
            this.form.get("AGENT_GRP")?.setValue(data?.content?.AGENT_GRP, {emitModelToViewChange: false});
          }
        });
      }
    } catch(e) {

    }
  }

  openClientGroupDialog() {
    try {
      if(this.selectedRow.length === 0) {
        this.dialog.open(ClientGroupLovDialog, {
          width: '512px',
          disableClose: true,
          data: {
            table: 'CLIENT_GROUP',
            userId: this.userDetailService.userId,
            moduleId: this.moduleId
          }
        }).afterClosed().subscribe(data => {
          if (data.content != '' && data.content != null) {
            this.form.patchValue({
              CLIENT_CD         : data?.content?.CLIENT_CD + " - " + data?.content?.CLIENT_DESC,
              CLIENT_CD_DESC    : data?.content?.CLIENT_DESC,
            });
            this.form.get("CLIENT_CD")?.setValue(data?.content?.CLIENT_CD, {emitModelToViewChange: false});
          }
        });
      }
    } catch(e) {

    }
  }

  addRow(): void {
    try {
      this.updateValidity();
      if(this.form.valid) {
        this.formService.hideFormMsg("client-group-per-agent-group-error-message");
        const values = this.form.getRawValue();
        const itemLevel1Desc = this.itemLevel1DropDown.filter((e: any) => e?.rvLowValue === values?.ITEM_LEVEL1)[0]?.rvMeaning;
        const itemLevel2Desc = this.itemLevel2DropDown.filter((e: any) => e?.rvLowValue === values?.ITEM_LEVEL2)[0]?.rvMeaning;
        this.table.tblData.unshift({
          AGENT_GRP         : values?.AGENT_GRP,
          AGENT_GRP_DESC    : values?.AGENT_GRP_DESC,
          ITEM_LEVEL1       : values?.ITEM_LEVEL1,
          ITEM_LEVEL1_DESC  : itemLevel1Desc,
          ITEM_LEVEL2       : values?.ITEM_LEVEL2,
          ITEM_LEVEL2_DESC  : itemLevel2Desc,
          CLIENT_CD         : values?.CLIENT_CD,
          CLIENT_CD_DESC    : values?.CLIENT_CD_DESC,
          EFF_DATE_FROM     : values?.EFF_DATE_FROM.format("MM/DD/YYYY"),
          EFF_DATE_TO       : moment.isMoment(values?.EFF_DATE_TO) ? values?.EFF_DATE_TO.format("MM/DD/YYYY") : "",
          ACTIVE_TAG        : values?.ACTIVE_TAG,
          REMARKS           : values?.REMARKS,
          LAST_USER         : this.userDetailService.userId,
        });
        this.table.tblData = [...this.table.tblData];
        this.tempNewData.push(this.formValues());
        this.formClearer();
        this.saveDisabler = false;
      } else {
        this.errorMessageSetter();
      }
    } catch(e) {
      
    }
  }

  updateValidity(): void {
    this.form.get("AGENT_GRP")?.enable();
    this.form.get("ITEM_LEVEL1")?.enable();
    this.form.get("ITEM_LEVEL2")?.enable();
    this.form.get("CLIENT_CD")?.enable();

    this.form.get("AGENT_GRP")?.updateValueAndValidity();
    this.form.get("ITEM_LEVEL1")?.updateValueAndValidity();
    this.form.get("ITEM_LEVEL2")?.updateValueAndValidity();
    this.form.get("CLIENT_CD")?.updateValueAndValidity();
    this.form.get("EFF_DATE_FROM")?.updateValueAndValidity();
    this.form.get("EFF_DATE_TO")?.updateValueAndValidity();
  }

  formValues(): any {
    try {
      const values = this.form.getRawValue();
      const itemLevel1Desc = this.itemLevel1DropDown.filter((e: any) => e?.rvLowValue === values?.ITEM_LEVEL1)[0]?.rvMeaning;
      const itemLevel2Desc = this.itemLevel2DropDown.filter((e: any) => e?.rvLowValue === values?.ITEM_LEVEL2)[0]?.rvMeaning;
      return {
        AGENT_GRP         : values?.AGENT_GRP,
        AGENT_GRP_DESC    : values?.AGENT_GRP_DESC,
        ITEM_LEVEL1       : values?.ITEM_LEVEL1,
        ITEM_LEVEL1_DESC  : itemLevel1Desc,
        ITEM_LEVEL2       : values?.ITEM_LEVEL2,
        ITEM_LEVEL2_DESC  : itemLevel2Desc,
        CLIENT_CD         : values?.CLIENT_CD,
        CLIENT_CD_DESC    : values?.CLIENT_CD_DESC,
        EFF_DATE_FROM     : values?.EFF_DATE_FROM.format("YYYY-MM-DD"),
        EFF_DATE_TO       : moment.isMoment(values?.EFF_DATE_TO) ? values?.EFF_DATE_TO.format("YYYY-MM-DD") : "",
        ACTIVE_TAG        : values?.ACTIVE_TAG,
        REMARKS           : values?.REMARKS,
        LAST_USER         : this.userDetailService.userId,
      }
    } catch(e) {
      
    }
  }

  errorMessageSetter() {
    try {
      if(this.form.invalid) {
        for(const field in this.form.controls) {
          const controlName = this.form.get(field);
          if(controlName?.errors?.required) {
            this.errorMessage = 'There are missing information. Please provide necessary information needed.';
          }
          if(controlName?.errors?.duplicate) {
            this.errorMessage = controlName?.errors?.duplicate;
          }
          if(controlName?.errors?.effDate) {
            this.errorMessage = controlName?.errors?.effDate;
          }
          if(controlName?.errors?.expDate) {
            this.errorMessage = controlName?.errors?.expDate;
          }
          this.formService.showFormMsg("client-group-per-agent-group-error-message", this.errorMessage, "E");
        }
      }
    } catch(e) {
      
    }
  }

  onUpdate(): void {
    try {
      this.updateValidity();
      if(this.form.valid) {
        this.updateTable();
        if(this.deleteDisabler) {
          this.updateDbData();
        } else {
          this.updateNewData();
        }
        this.selectedRow = '';
        this.addUpdateFlag = true;
        this.formClearer();
        this.saveDisabler = false;
        this.deleteEnabler();
        this.formService.hideFormMsg('client-group-per-agent-group-error-message');
      } else {
        this.form.get("AGENT_GRP")?.disable();
        this.form.get("ITEM_LEVEL1")?.disable();
        this.form.get("ITEM_LEVEL2")?.disable();
        this.form.get("CLIENT_CD")?.disable();
        this.errorMessageSetter();
      }
    } catch(e) {
      
    }
  }

  updateTable() {
    try {
      const values = this.form.getRawValue();
      let updateIndex = this.table.tblData.indexOf(this.selectedRow);
      const itemLevel1Desc = this.itemLevel1DropDown.filter((e: any) => e?.rvLowValue === values?.ITEM_LEVEL1)[0]?.rvMeaning;
      const itemLevel2Desc = this.itemLevel2DropDown.filter((e: any) => e?.rvLowValue === values?.ITEM_LEVEL2)[0]?.rvMeaning;
      this.table.tblData[updateIndex] = {
        AGENT_GRP         : values?.AGENT_GRP,
        AGENT_GRP_DESC    : values?.AGENT_GRP_DESC,
        ITEM_LEVEL1       : values?.ITEM_LEVEL1,
        ITEM_LEVEL1_DESC  : itemLevel1Desc,
        ITEM_LEVEL2       : values?.ITEM_LEVEL2,
        ITEM_LEVEL2_DESC  : itemLevel2Desc,
        CLIENT_CD         : values?.CLIENT_CD,
        CLIENT_CD_DESC    : values?.CLIENT_CD_DESC,
        EFF_DATE_FROM     : values?.EFF_DATE_FROM.format("MM/DD/YYYY"),
        EFF_DATE_TO       : moment.isMoment(values?.EFF_DATE_TO) ? values?.EFF_DATE_TO.format("MM/DD/YYYY") : "",
        ACTIVE_TAG        : values?.ACTIVE_TAG,
        REMARKS           : values?.REMARKS,
        LAST_USER         : this.userDetailService.userId,
      };
      this.table.tblData = [...this.table.tblData];
    } catch(e) {
      
    }
  }

  updateDbData() {
    try {
      let tempDbData = this.tempUpdateData.find(
        (data: { 
          AGENT_GRP: any, 
          ITEM_LEVEL1: any, 
          ITEM_LEVEL2: any, 
          CLIENT_CD: any 
        }) => data.AGENT_GRP == this.selectedRow.AGENT_GRP && 
              data.ITEM_LEVEL1 == this.selectedRow.ITEM_LEVEL1 &&
              data.ITEM_LEVEL2 == this.selectedRow.ITEM_LEVEL2 &&
              data.CLIENT_CD == this.selectedRow.CLIENT_CD
      );
      if((this.tempUpdateData == '' || this.tempUpdateData == null) || (tempDbData=='' || tempDbData==null)) {
        this.tempUpdateData.push(this.formValues());
      } else {
        tempDbData.AGENT_GRP         = this.form.get("AGENT_GRP")?.value;
        tempDbData.AGENT_GRP_DESC    = this.form.get("AGENT_GRP_DESC")?.value;
        tempDbData.ITEM_LEVEL1       = this.form.get("ITEM_LEVEL1")?.value;
        tempDbData.ITEM_LEVEL1_DESC  = this.form.get("ITEM_LEVEL1_DESC")?.value;
        tempDbData.ITEM_LEVEL2       = this.form.get("ITEM_LEVEL2")?.value;
        tempDbData.ITEM_LEVEL2_DESC  = this.form.get("ITEM_LEVEL2_DESC")?.value;
        tempDbData.CLIENT_CD         = this.form.get("CLIENT_CD")?.value;
        tempDbData.CLIENT_CD_DESC    = this.form.get("CLIENT_CD_DESC")?.value;
        tempDbData.EFF_DATE_FROM     = this.form.get("EFF_DATE_FROM")?.value.format("YYYY-MM-DD");
        tempDbData.EFF_DATE_TO       = moment.isMoment(this.form.get("EFF_DATE_TO")?.value) ? this.form.get("EFF_DATE_TO")?.value.format("YYYY-MM-DD") : "";
        tempDbData.ACTIVE_TAG        = this.form.get("ACTIVE_TAG")?.value;
        tempDbData.REMARKS           = this.form.get("REMARKS")?.value;
        tempDbData.LAST_USER         = this.userId;
        tempDbData.LAST_USER_UPDATE  = this.form.get("LAST_USER_UPDATE")?.value;
      }
    } catch(e) {
      
    }
  }

  updateNewData() {
    try {
      let tempNewConfigdata = this.tempNewData.find((data: { 
        AGENT_GRP: any, 
        ITEM_LEVEL1: any, 
        ITEM_LEVEL2: any, 
        CLIENT_CD: any 
      }) => data.AGENT_GRP == this.selectedRow.AGENT_GRP && 
            data.ITEM_LEVEL1 == this.selectedRow.ITEM_LEVEL1 &&
            data.ITEM_LEVEL2 == this.selectedRow.ITEM_LEVEL2 &&
            data.CLIENT_CD == this.selectedRow.CLIENT_CD
      );
      tempNewConfigdata.AGENT_GRP         = this.form.get("AGENT_GRP")?.value;
      tempNewConfigdata.AGENT_GRP_DESC    = this.form.get("AGENT_GRP_DESC")?.value;
      tempNewConfigdata.ITEM_LEVEL1       = this.form.get("ITEM_LEVEL1")?.value;
      tempNewConfigdata.ITEM_LEVEL1_DESC  = this.form.get("ITEM_LEVEL1_DESC")?.value;
      tempNewConfigdata.ITEM_LEVEL2       = this.form.get("ITEM_LEVEL2")?.value;
      tempNewConfigdata.ITEM_LEVEL2_DESC  = this.form.get("ITEM_LEVEL2_DESC")?.value;
      tempNewConfigdata.CLIENT_CD         = this.form.get("CLIENT_CD")?.value;
      tempNewConfigdata.CLIENT_CD_DESC    = this.form.get("CLIENT_CD_DESC")?.value;
      tempNewConfigdata.EFF_DATE_FROM     = this.form.get("EFF_DATE_FROM")?.value.format("YYYY-MM-DD");
      tempNewConfigdata.EFF_DATE_TO       = moment.isMoment(this.form.get("EFF_DATE_TO")?.value) ? this.form.get("EFF_DATE_TO")?.value.format("YYYY-MM-DD") : "";
      tempNewConfigdata.ACTIVE_TAG        = this.form.get("ACTIVE_TAG")?.value;
      tempNewConfigdata.REMARKS           = this.form.get("REMARKS")?.value;
      tempNewConfigdata.LAST_USER         = this.userId;
      tempNewConfigdata.LAST_USER_UPDATE  = this.form.get("LAST_USER_UPDATE")?.value;
    } catch(e) {
      
    }
  }

  deleteRow(): void {
    try {
      let clickedData = this.selectedRow;
      this.table.tblData = this.table.tblData.filter((e: any) => {
        return e != clickedData
      });
      this.tempNewData = this.tempNewData.filter((e: any) => {
        return e != clickedData
      });
      this.selectedRow = '';
      this.formClearer();
      this.deleteDisabler = true;
      this.addUpdateFlag = true;
      if((this.tempNewData=='' || this.tempNewData==null) && (this.tempUpdateData=='' || this.tempUpdateData==null)) {
        this.saveDisabler = true;
      }
    } catch(e) {
      
    }
  }

  onSave(): void {
    try {
      this.formHider = true;
      this.jsonDataService.contorlLoading(true);
      this.api.saveClientGrpPerAgentGrp(this.tempNewData).subscribe({
        next: (data: any) => {
          this.securityService.checkRequestKeyResponse(data, () => {
            this.securityService.hasValidCsrfToken(data, () => {
              if(data.status == 'SUCCESS') {
                this.api.saveClientGrpPerAgentGrp(this.tempUpdateData).subscribe({
                  next: (res: any) => {
                    this.securityService.checkRequestKeyResponse(res, () => {
                      this.securityService.hasValidCsrfToken(data, () => {
                        if(res.status == 'SUCCESS') {
                          this.onComplete();
                        } else {
                          this.onFail();
                        }
                      });
                    });
                  },
                  error: () => {
                    this.onFail();
                  }
                });
              } else {
                this.onFail();
              }
            });
          });
        },
        error: () => {
          this.onFail();
        }
      });
    } catch(e) {
      
    }
  }
  
  onComplete() {
    try {
      this.formHider = false;
      this.tempNewData = [];
      this.tempUpdateData = [];
      this.saveDisabler = true;
      this.deleteEnabler();
      this.formClearer();
      this.getClientGrpPerAgentGrp();
      this.formService.hideFormMsg("client-group-per-agent-group-error-message");
      this.openSaveDialog();
    } catch(e) {
      
    }
  }

  onFail() {
    try {
      this.formHider = false;
      this.tempNewData = [];
      this.tempUpdateData = [];
      this.deleteEnabler();
      this.formClearer();
      this.getClientGrpPerAgentGrp();
      this.formService.hideFormMsg("client-group-per-agent-group-error-message");
      this.openErrorDialog("Saving Client Group Per Agent Group Failed");
    } catch(e) {
      
    }
  }

  openErrorDialog(message: string) {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'ERROR',
          content: message,
        }
      });
    } catch(e) {
      
    }
  }

  openSaveDialog() {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'SUCCESS',
          content: 'Client Group Per Agent Group Details Successfully Saved!',
        }
      });
    } catch(e) {
      
    }
  }

}
