import { DatePipe } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ViewChild, TemplateRef, ElementRef, AfterViewInit } from '@angular/core';
import { FilterService } from 'primeng/api';

@Component({
  selector: 'osp-table',
  templateUrl: './osp-table.component.html',
  styleUrls: ['./osp-table.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class OspTableComponent implements OnInit, AfterViewInit {

  constructor(
    private filterService: FilterService
  ) {}

  private datePipe: DatePipe = new DatePipe('en-US');

  @Input() tblConfig: any = {
    cols: [],
    tblData: [],
    selection: "single",
    paginator: false,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 10,
    visible: true,
    loading: false,
    pageLinks: 1
  }

  @Output() onRowClick = new EventEmitter<any>();
  @Output() rowEvent = new EventEmitter<any>();
  @Output() selectAllConfirmation = new EventEmitter<any>();
  @Output() afterFilter = new EventEmitter<any>();
  @Output() onLazyLoad = new EventEmitter<any>();

  @ViewChild('tbl') tbl!: any;
  @ViewChild('searchBox') searchBox!: ElementRef;
  @ViewChild('searchBoxDrop') searchBoxDrop!: ElementRef;
  @ViewChild('dropDown') dropDown!: ElementRef;

  @Input() selectedRow: any = null;
  //@Output() selectedRowChange = new EventEmitter<any>();

  filterFields: any[] = [];
  filterText: string = '';
  filterTextDrop: string = '';
  filterDropDown: string = 'all';
  first: number = 0;
  searchDisabled: boolean = false;

  ngOnInit(): void {
    if (this.tblConfig.visible == undefined) {
      this.tblConfig.visible = true;
    }
    this.configureFilterService();
    // this.addFiller();
  }

  ngAfterViewInit(): void {
    if(this.tblConfig.lazy) {
      this.tbl.filterDelay = 800;
    }
  }

  private configureFilterService() {
    let containsFilter = this.filterService.filters['contains'];
    this.filterService.register('containsWithDateFormat', (value: any, filter: any): boolean => {
      if (filter === undefined || filter === null || filter.trim() === '') {
        return true;
      }
      if (value === undefined || value === null) {
        return false;
      }
      
      try {
        let formattedDate = this.datePipe.transform(value, 'MM/dd/yyyy');
        return formattedDate?.toString().includes(filter.toString()) || containsFilter(value, filter);
      }
      catch {
        return containsFilter(value, filter);
      }
    });
  }

  emitSelected() {
    this.onRowClick.emit(this.selectedRow);
    // this.selectedRowChange.emit(this.selectedRow);
  }

  emitRowEvent(data: any, src?: any) {
    this.rowEvent.emit({
      rowData: data,
      src: src
    });
  }

  emitLazyLoad(event: any) {
    this.toggleSearch();
    this.onLazyLoad.emit(event);

    setTimeout(() => {
      this.toggleSearch();
    });
  }

  emitSelectAllConfirmation(ev?: any, src?: any) {
    this.selectAllConfirmation.emit({
      val: ev.target.checked ? 'Y' : 'N',
      src: src
    });
  }

  emitAfterFilter() {
    this.afterFilter.emit();
  }

  extractFilterFields() {
    if(this.filterDropDown !== "all") {
      return [this.tblConfig.cols.find((a: any) => a.key === this.filterDropDown)?.key];
    }
    return this.tblConfig.cols.map((a: any) => { return a.key });
  }

  checkboxToggle(data: any, key: any, vals: any[]) {
    data[key] = data[key] == vals[0] ? vals[1] : vals[0];
  }

  toggleAll(ev: any, key: string) {
    this.tblConfig.tblData.forEach((el: any) => {
      if (el.disabledFields == undefined || el.disabledFields.indexOf(key) == -1)
        el[key] = ev.target.checked ? 'Y' : 'N';
      el['edited'] = true;
    });
  }

  filter() {
    this.tbl.filterGlobal(this.filterText, 'contains');

    this.tbl.filterGlobal(this.filterTextDrop, 'contains');
  }

  clearFilter() {
    this.filterText = '';
    this.filterTextDrop = '';
    this.filter();
  }

  toggleSearch() {
    this.searchDisabled = !this.searchDisabled;
  }

  // addFiller() {
  //   var filler = {};

  //   var rows = this.tblConfig.rowsPerPage - (this.tblConfig.tblData.length % this.tblConfig.rowsPerPage);

  //   for (let i = 0; i < rows; i++) {
  //     this.tblConfig.tblData.push(filler);
  //   }

  // }

  // next() {
  //   this.first = this.first + this.tblConfig.rowsPerPage;
  // }

  // prev() {
  //   this.first = this.first - this.tblConfig.rowsPerPage;
  // }

  // reset() {
  //   this.first = 0;
  // }

  // isLastPage(): boolean {
  //   return this.tblConfig.tblData ? this.first === (this.tblConfig.totalRecords - this.tblConfig.rowsPerPage): true;
  // }

  // isFirstPage(): boolean {
  //   return this.tblConfig.tblData ? this.first === 0 : true;
  // }

}
