<section id="fire-accumulation-register">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <div class="form-content">
            <form [formGroup]="paramForm" class="form">
                <div class="form-container-grid top-form form-container-change-grid">

                    <div class="field required">
                        <label>Filter By</label>
                    </div>

                    <div class="">
                        <select formControlName="filterBy">
                            <option value="R">Register only</option>
                            <option value="RS">Region and State</option>
                            <option value="D">District Code</option>
                        </select>
                    </div>
                    <div class="field required" *ngIf="showRegisterParam">
                        <label>Register</label>
                    </div>
                    <div class="lov-search" *ngIf="showRegisterParam">
                        <input type="text" formControlName="register" oninput="this.value = this.value.toUpperCase()">
                        <button class="btn-search btn not-lov" [disabled]="isSearchDisabled || !paramForm.controls['register'].value" type="button" (click)="getFireAccumulationReg()">Search</button>
                    </div>

                    <div class="field required" *ngIf="!showRegisterParam">
                        <label>District Code</label>
                    </div>
                    <div class="lov-search" *ngIf="!showRegisterParam">
                        <input type="text" formControlName="bmDistrictCd" oninput="this.value = this.value.toUpperCase()">
                        <button class="btn-search btn not-lov" [disabled]="isSearchDisabled || !paramForm.controls['bmDistrictCd'].value" type="button" (click)="getFireAccumulationReg()">Search</button>
                    </div>

                    <div class="field required">
                        <label>Region</label>
                    </div>
                    <div class="lov-search">
                        <input readonly formControlName="regionDesc">
                        <button matSuffix class="btn-search btn" type="button" (click)="openRegionDialog()" [disabled]="isRegionDisabled"><mat-icon>search</mat-icon></button>
                    </div>
                    <div class="field required">
                        <label>State</label>
                    </div>
                    <div class="lov-search">
                        <input readonly formControlName="stateDesc">
                        <button matSuffix class="btn-search btn" type="button" (click)="openStatesDialog()" [disabled]="isStateDisabled"><mat-icon>search</mat-icon></button>
                    </div>
                </div>
            </form>
        </div>
        <osp-table [tblConfig]="fireAccumulationRegTable" (onRowClick)="onRowClick($event)"></osp-table>

        <div id="fire-accumulation-register-form">
            <div class="form-loader"></div>
        </div>

        <div class="form-content" [hidden]="formHider">
            <form [formGroup]="fireAccumulationRegisterForm" class="form">
                <div id="fire-accumulation-register-error-message"></div>
                <div class="form-container-grid">

                    <div class="field">
                        <label>Block Code</label>
                    </div>
                    <div class="">
                        <input readonly type="text" formControlName="blockCd">
                    </div>

                    <div class="field required">
                        <label>Active</label>
                    </div>

                    <div class="">
                        <select formControlName="activeTag">
                            <option value="A">Yes</option>
                            <option value="I">No</option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>Register</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="bmFregCd" oninput="this.value = this.value.toUpperCase().trim()" maxlength="25">
                    </div>

                    <div class="field required">
                        <label>State</label>
                    </div>
                    <div class="">
                        <input readonly formControlName="stateDesc">
                        <button matSuffix class="btn-search btn" type="button" (click)="openStatesDialog(true)" [disabled]="isStateFormDisabled">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>

                    <div class="field required">
                        <label>District</label>
                    </div>
                    <div class="lov-search">
                        <input readonly formControlName="distLongDesc">
                        <button matSuffix class="btn-search btn" type="button" (click)="openDistrictDialog()" [disabled]="isDistrictDisabled || !fireAccumulationRegisterForm.controls['stateDesc'].value">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>

                    <div class="field required">
                        <label>Occupancy Code</label>
                    </div>
                    <div class="lov-search">
                        <input readonly formControlName="occupancyDesc">
                        <button matSuffix class="btn-search btn" type="button" (click)="openOccupancyDialog()" [disabled]="isOccupancyDisabled">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>

                    <div class="field required">
                        <label>Latitude</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="latitude" oninput="this.value = this.value.toUpperCase().trim()"  maxlength="100">
                    </div>

                    <div class="field required">
                        <label>Longitude</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="longitude" oninput="this.value = this.value.toUpperCase().trim()"  maxlength="100">
                    </div>

                    <div class="field">
                        <label>Street No. / Lot</label>
                    </div>
                    <div class="">
                        <input formControlName="streetNo" maxlength="250">
                    </div>

                    <div class="field required">
                        <label>Fire Protection Code</label>
                    </div>
                    <div class="">
                        <select formControlName="fireProCd">
                            <option *ngFor="let fpCd of fireProCds" [value]="fpCd.fireProdCd">{{fpCd.bmCode + ' - '+fpCd.fpLongDesc}}</option>
                        </select>
                    </div>

                    <div class="field">
                        <label>Street / Estate</label>
                    </div>
                    <div class="span">
                        <input formControlName="streetEstate" maxlength="250">
                    </div>

                    <div class="field">
                        <label>Situation</label>
                    </div>
                    <div class="span">
                        <input formControlName="situation" maxlength="500">
                    </div>

                    <!-- <div class="field required">
                        <label>Construction</label>
                    </div>
                    <div class="lov-search">
                        <input readonly formControlName="constructionDesc">
                        <button matSuffix class="btn-search btn" type="button" (click)="openConstructionDialog()" [disabled]="isConstructionDisabled">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div> -->

                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" maxlength="1000">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input readonly formControlName="userId">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input readonly formControlName="lastUpdate">
                    </div>
                </div>
            </form>

             <div class="btn-grp">
              <button type="button" class="btn btn2" [disabled]="fireAccumulationRegisterForm.pristine" (click)="onSave()">SAVE</button>
            </div>
        </div>
    </div>
</section>