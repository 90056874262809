import { Component, OnInit, ViewChild } from '@angular/core';
import { APICallService } from '../../../services/api-call.service';
import { OspTableComponent } from '../../common/osp-table/osp-table.component';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { MatDialog } from '@angular/material/dialog';
import { JsonDataService } from '../../../services/json-data.service';
import { FormService } from 'src/app/services/form.service';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { Observable } from 'rxjs';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { SecurityService } from 'src/app/services/security.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { LazyLoadEvent } from 'primeng/api';
import { LovComponent } from '../../common/lov/lov.component';
import { UploadComponent } from '../../common/upload/upload.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'src-ext-for-rela-code-mtn',
  templateUrl: './src-ext-for-rela-code-mtn.component.html',
  styleUrls: ['./src-ext-for-rela-code-mtn.component.css']
})
export class SrcExtForRelaCodeMtnComponent implements OnInit {

  @ViewChild('tbl') tbl!: OspTableComponent;
  @ViewChild('lov') lov!: LovComponent;
  @ViewChild('upload') upload!: UploadComponent;

  private datePipe: DatePipe = new DatePipe('en-us');

  tblConfig: any = {
    cols: [
      {
        key: "referrorNo",
        header: "Referror No.",
        dataType: "string",
        width: "12%"
      },
      {
        key: "referror",
        header: "Referror",
        dataType: "string",
        width: "23%",
        align: 'text-l'
      },
      {
        key: "sourceExtension",
        header: "Source Extension",
        dataType: "string",
        width: "23%",
        align: 'text-l'
      },
      {
        key: "branchCode",
        header: "Branch Code",
        dataType: "string",
        width: "12%"
      },
      {
        key: "segmentCode",
        header: "Segment",
        dataType: "string",
        width: "10%"
      },
      {
        key: "effDate",
        header: "Eff. Date",
        dataType: "date",
        width: "10%"
      },
      {
        key: "expiryDate",
        header: "Exp. Date",
        dataType: "date",
        width: "10%"
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: true,
    totalRecords: 5,
    pageLinks: 1
  }

  form: FormGroup = this.fb.group({
    referrorCd: [""],
    referrorNo: ["", [Validators.required]],
    referror: [""],
    sourceExtensionCode: [""],
    sourceExtension: ["", [Validators.required]],
    branchCode: [""],
    segment: ["", [Validators.required]],
    segmentCode: [""],
    segmentCodeDb: [""],
    effDate: ["", [Validators.required]],
    expiryDate: [null],
    remarks: [""],
    createUser: [""],
    createDate: [""],
    lastUser: [""],
    lastUserUpdate: [""]
  });

  moduleId: string = "BMM149";
  moduleName: string = '';

  selectedRow: any = null;
  saveList: any[] = [];

  lovData: any = {
    type: ''
  };

  isUpdateRow: boolean = false;

  uploadConfig: any = {
    uploadFileKeys: ['REFERROR_NO', 'BR_CODE', 'SEGMENT', 'EFF_DATE', 'EXP_DATE'],
    tblConfig: {
      cols: [
        {
          key: "REFERROR_NO",
          header: "REFERROR_NO",
          dataType: "string",
          width: "18%"
        },
        {
          key: "BR_CODE",
          header: "BR_CODE",
          dataType: "string",
          width: "15%",
        },
        {
          key: "SEGMENT",
          header: "SEGMENT",
          dataType: "string",
          width: "12%"
        },
        {
          key: "EFF_DATE",
          header: "EFF_DATE",
          dataType: "date",
          width: "15%"
        },
        {
          key: "EXP_DATE",
          header: "EXP_DATE",
          dataType: "date",
          width: "15%"
        },
        {
          key: "STATUS",
          header: "STATUS",
          dataType: "string",
          width: "40%",
          align: 'text-l'
        },
      ],
      tblData: [],
      selection: "single",
      paginator: true,
      rowsPerPage: 10,
      lazy: true,
      totalRecords: 5,
      pageLinks: 1
    },
    onClickUpload: (data: any) => {
      return this.onClickUpload(data);
    },
    width: '55vw'
  }

  constructor(
    private api: APICallService,
    private userDetailService: UserDetailsService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private jds: JsonDataService,
    private securityService: SecurityService,
    private appMessageService: AppMessageService,
    public fs: FormService) {
    this.form.controls["referrorNo"].disable();
    this.form.controls["referror"].disable();
    this.form.controls["sourceExtension"].disable();
    this.form.controls["branchCode"].disable();
    this.form.controls["segment"].disable();
    this.form.controls["segmentCode"].disable();
    this.form.controls["createUser"].disable();
    this.form.controls["createDate"].disable();
    this.form.controls["lastUser"].disable();
    this.form.controls["lastUserUpdate"].disable();
    // this.getDataJson();
  }

  ngOnInit(): void {
    this.moduleName = this.jds.data.moduleList.filter((data: any) => { return data.moduleId === this.moduleId })[0].moduleDesc.toUpperCase();
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.saveList.length == 0;
  }

  onRowClick(ev: any) {
    this.selectedRow = ev;
    this.updateForm(ev);
  }

  getSrcExtforRelaCode(prm?: any) {
    let qryPrm: any = {};

    if (prm) {
      qryPrm = {
        first: prm.first,
        rows: prm.rows,
        search: prm.search,
        sortBy: prm.sortBy,
        sortDir: prm.sortDir
      }
    }

    this.jds.contorlLoading(true);

    this.api.getSrcExtforRelaCode({
      moduleId: this.moduleId,
      userId: this.userDetailService.userId,
      type: "MODULE",
      first: qryPrm.first,
      rows: qryPrm.rows,
      search: qryPrm.search,
      sortBy: qryPrm.sortBy,
      sortDir: qryPrm.sortDir
    }).subscribe((data: any) => {
      this.securityService.checkRequestKeyResponse(data, () => {
        this.jds.contorlLoading(false);
        if (data.status === "SUCCESS") {
          data = JSON.parse(this.jds.decrypt(data.content));
          this.tblConfig.totalRecords = data['count'];
          this.tblConfig.pageLinks = 10;
          this.tblConfig.tblData = data['data'];
        } else {
          this.appMessageService.showAppMessage(data.message, "error");
        }
      });
    });
  }

  lazyLoad(ev: any) {
    setTimeout(() => {
      this.getSrcExtforRelaCode({
        first: ev.first,
        rows: this.tblConfig.rowsPerPage,
        search: ev.globalFilter,
        sortBy: ev.sortField == undefined ? 'referrorNo' : ev.sortField,
        sortDir: ev.sortField == undefined ? 1 : ev.sortOrder
      });
    }, 0);
  }

  updateForm(ev: any) {
    this.disableFieldsForUpdate(ev);
    var date = new Date();
    this.form.patchValue({
      referrorCd: ev == null ? "" : ev.referrorCd,
      referrorNo: ev == null ? "" : ev.referrorNo,
      referror: ev == null ? "" : ev.referror,
      sourceExtensionCode: ev == null ? "" : ev.sourceExtensionCode,
      sourceExtension: ev == null ? "" : ev.sourceExtension,
      branchCode: ev == null ? "" : ev.branchCode,
      segment: ev == null ? "" : ev.segment,
      segmentCode: ev == null ? "" : ev.segmentCode,
      segmentCodeDb: ev == null ? "" : ev.segmentCodeDb,
      effDate: ev == null ? "" : ev.effDate,
      expiryDate: ev == null ? "" : ev.expiryDate,
      remarks: ev == null ? "" : ev.remarks,
      createUser: ev == null ? "" : ev.createUser,
      createDate: ev == null ? "" : this.datePipe.transform(ev.createDate, 'MM/dd/yyyy hh:mm:ss a', 'UTC'),
      lastUser: ev == null ? "" : ev.lastUser,
      lastUserUpdate: ev == null ? "" : this.datePipe.transform(ev.lastUserUpdate, 'MM/dd/yyyy hh:mm:ss a', 'UTC'),
    });
  }

  private disableFieldsForUpdate(ev: any): void {
    if (!ev) {
      this.isUpdateRow = false;
      this.form.controls['effDate'].enable();
      return;
    }
    this.isUpdateRow = true;
    this.form.controls['effDate'].disable();
  }

  onClickAddUpdate(del?: any) {
    if (this.form.valid) {
      this.fs.hideFormMsg("src-ext-form-msg");

      const form = this.form.getRawValue();

      if (form.effDate && typeof form.effDate != 'string') {
        form.effDate = form.effDate.format('YYYY-MM-DD');
      }

      if (form.expiryDate && typeof form.expiryDate != 'string') {
        form.expiryDate = form.expiryDate.format('YYYY-MM-DD');
      }

      var val = {
        delete: del == undefined ? 'N' : 'Y',
        referrorCd: form.referrorCd,
        srcExtCd: form.sourceExtensionCode,
        segmentCd: form.segmentCodeDb,
        effDate: form.effDate.split('T')[0],
        expiryDate: form.expiryDate == null || form.expiryDate == '' ? null : form.expiryDate.split('T')[0],
        remarks: form.remarks,
        userId: this.jds.retrieveFromStorage("userId")
      };

      // if(this.selectedRow == null) {
      this.fs.showFormLoader(
        null,
        "src-ext-form",
        "Validating record.<br>Please wait ...",
        "",
        null
      );
      this.api.saveSrcExtforRelaCode({
        validate: true,
        data: [val],
        validateOpt: this.selectedRow ? 'update' : 'add',
      }).subscribe((res: any) => {
        this.securityService.checkRequestKeyResponse(res, async () => {
          this.fs.hideFormLoader("src-ext-form");
          var data = JSON.parse(this.jds.decrypt(res.content));
          if (res.status == 'SUCCESS') {
            if (data.existing) {
              this.fs.showFormMsg("src-ext-form-msg", "An active setup still exists for the entered values.", "E");

              return;
            } else {
              if (this.selectedRow) {
                this.selectedRow.effDate = val.effDate;
                this.selectedRow.expiryDate = val.expiryDate;
                this.selectedRow.remarks = val.remarks;
              }
              this.saveList.push(val);

              this.fs.hideFormMsg("src-ext-form-msg");
              this.updateForm(null);
              this.selectedRow = null;
            }
          } else {
            this.fs.showFormMsg("src-ext-form-msg", "An error occured while validating.", "E");
          }
        });
      });
      // } else {
      //   this.selectedRow.effDate = val.effDate;
      //   this.selectedRow.expiryDate = val.expiryDate;
      //   this.selectedRow.remarks = val.remarks;

      //   this.saveList.push(val);

      //   this.fs.hideFormMsg("src-ext-form-msg");
      //   this.updateForm(null);
      //   this.selectedRow = null;
      // }
    } else {
      this.fs.showFormMsg("src-ext-form-msg", "Please fill out all the required fields.", "E");
    }
  }

  onClickSave() {
    if (this.saveList.length > 0) {
      this.fs.showFormLoader(
        this,
        "src-ext-form",
        "Processing your request.<br>Please wait ...",
        "save",
        this.saveList
      );
    } else {
      this.openDialog('info');
    }
  }

  save(params: any) {
    this.api.saveSrcExtforRelaCode(params).subscribe((res: any) => {
      this.securityService.checkRequestKeyResponse(res, async () => {
        var data = JSON.parse(this.jds.decrypt(res.content));

        if (res.status == 'SUCCESS') {
          this.lazyLoad({
            first: this.tblConfig.tblData ? 0 : this.tblConfig.tblData[0]['rownum_'] - 1,
            globalFilter: this.tbl.filterText
          });

          this.updateForm(null);
          this.selectedRow = null;
          this.openDialog('success');
          this.saveList = [];
          this.fs.hideFormLoader("src-ext-form");
        } else {
          this.openDialog('error');
        }
      });
    });
  }

  openDialog(type: string) {
    var dlgTitle;
    var dlgContent;

    if (type == 'success') {
      dlgTitle = 'SUCCESS';
      dlgContent = 'Successfully saved!';
    } else if (type == 'error') {
      dlgTitle = 'ERROR';
      dlgContent = 'An error occured while saving. Please check field values.';
    } else if (type == 'required') {
      dlgTitle = 'WARNING';
      dlgContent = 'Please supply all the required fields.';
    } else if (type == 'info') {
      dlgTitle = 'INFO';
      dlgContent = 'Nothing to save';
    }

    const dialogRole = this.dialog.open(DialogMsgComponent, {
      disableClose: true,
      width: "500px",
      data: {
        title: dlgTitle,
        content: dlgContent
      }
    });
  }

  openLov(type: string) {
    var title = '';
    if (type == 'referror') {
      title = 'Referror';
    } else if (type == 'srcExt') {
      title = 'Source Extension';
    } else if (type == 'segment') {
      title = 'Segment';
    }

    this.lovData.type = type;

    this.lov.open(title);
  }

  selectedFromLov(ev: any) {
    var sel = ev.selected[0];

    if (this.lovData.type == 'referror') {
      this.form.patchValue({
        referrorCd: sel.REFERROR_CD,
        referrorNo: sel.BM_CODE,
        referror: sel.REFERROR_DESC
      });
    } else if (this.lovData.type == 'srcExt') {
      this.form.patchValue({
        sourceExtensionCode: sel.SRC_EXT_CD,
        sourceExtension: sel.SRC_EXT_DESC,
        branchCode: sel.BR_CODE
      });
    } else if (this.lovData.type == 'segment') {
      this.form.patchValue({
        segment: sel.SEGMENT_DESC,
        segmentCode: sel.BM_CODE,
        segmentCodeDb: sel.SEGMENT_CD
      });
    }
  }

  bulkCount: number = 100;
  lastUploadIndex: number = 0;
  excelRows: any[] = [];
  excelLength: number = 0;
  uploadExcelRows: any[] = [];

  onClickUpload(data: any) {
    this.lastUploadIndex = 0;
    this.excelRows = data;
    this.excelLength = data.length;

    this.proceedUpload();
  }

  proceedUpload() {
    var batchCount = this.bulkCount;
    var limit = (this.lastUploadIndex + batchCount) > this.excelLength ? this.excelLength : (this.lastUploadIndex + batchCount);
    this.uploadExcelRows = [];

    for (var i = this.lastUploadIndex; i < limit; i++) {
      this.uploadExcelRows.push(this.excelRows[i]);
    }

    this.lastUploadIndex = limit;
    this.uploadSrcExtForRelaCode();
  }

  uploadSrcExtForRelaCode() {
    this.jds.contorlLoading(true);
    this.api.saveUploadSrcExtForRelaCode(this.uploadExcelRows).subscribe((res: any) => {
      this.securityService.checkRequestKeyResponse(res, async () => {
        var data = JSON.parse(this.jds.decrypt(res.content));

        var rs = data.results;

        for (var i = 0; i < rs.length; i++) {
          if (rs[i] !== 'Successful') {
            this.excelRows[(this.lastUploadIndex - this.uploadExcelRows.length) + i]['failed'] = true;
          }

          this.excelRows[(this.lastUploadIndex - this.uploadExcelRows.length) + i]['STATUS'] = rs[i];
        }

        if (this.lastUploadIndex !== this.excelLength) {
          this.proceedUpload();
        } else {
          this.lastUploadIndex = 0;
          this.jds.contorlLoading(false);

          this.lazyLoad({
            first: this.tblConfig.tblData ? 0 : this.tblConfig.tblData[0]['rownum_'] - 1,
            globalFilter: this.tbl.filterText
          });
        }
      });
    });
  }

}
