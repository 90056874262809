import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

@Component({
    selector: 'customer-user-account-maintenance',
    templateUrl: './customer-user-account-maintenance.component.html',
    styleUrls: ['./customer-user-account-maintenance.component.css']
})
export class CustomerUserAccountMaintenanceComponent implements OnInit {

    moduleId: string = 'BMM043';
    public moduleName: string = '';

    datePipe = new DatePipe('en-us');

    currentUser: string = this.userDetailService.userId || "";

    selectedCustomer: any = null;

    customersForm = this.fb.group({
        userId: [null],
        assuredNo: [null],
        clientType: [null],
        activeTag: [null],
        verificationTag: [null],
        acceptTag: [null],
        firstName: [null],
        lastName: [null],
        middleName: [null],
        suffix: [null],
        displayName: [null],
        emailAdd: [null],
        birthDate: [null],
        nationality: [null],
        gender: [null],
        maritalStatus: [null],
        compName: [null],
        regNo: [null],
        occupation: [null],
        jobCategory: [null],
        permanentAdd1: [null],
        permanentAdd2: [null],
        permanentAdd3: [null],
        permanentAdd4: [null],
        permanentCountry: [null],
        permanentCity: [null],
        permanentProvince: [null],
        permanentPostalCd: [null],
        riskProfile: [null],
        idType: [null],
        idNo: [null],
        tinNo: [null],
        mobileNo: [null],
        telNo: [null],
        birthCountry: [null],
        birthCity: [null],
        birthProvince: [null],
        sameAsPermanentAdd: [null],
        presentAdd1: [null],
        presentAdd2: [null],
        presentAdd3: [null],
        presentAdd4: [null],
        presentCountry: [null],
        presentCity: [null],
        presentProvince: [null],
        presentPostalCd: [null],
        createUser: [null],
        createDate: [null],
        lastUserUpdate: [null],
        lastUser: [null]
    })

    tblConfigCustomer: any = {
        cols: [
            {
                key: "USER_ID",
                header: "User ID",
                dataType: "string",
                width: '20%'
            },
            {
                key: "DISPLAY_NAME",
                header: "Name",
                dataType: "string",
                disabled: false
            },
            {
                key: "COMPANY_NAME",
                header: "Company Name",
                dataType: "string",
                disabled: false,
                width: '17%'
            },
            {
                key: "EMAIL_ADDRESS",
                header: "Email Address",
                dataType: "string",
                disabled: false,
                width: '18%'
            },
            {
                key: "ACTIVE_TAG",
                header: "A",
                dataType: "checkbox",
                disabled: true,
                width: '64px'
            }
        ],
        tblData: [],
        selection: "single",
        paginator: true,
        rowsPerPage: 10,
        lazy: false,
        totalRecords: 15,
        loading: false
    }

    constructor(
        private fb: FormBuilder,
        private jsonDataService: JsonDataService,
        private apiCallService: APICallService,
        private userDetailService: UserDetailsService,
        private appMessageService: AppMessageService,
        private securityService: SecurityService
    ) { }

    ngOnInit(): void {
        try {
            this.moduleName = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId === this.moduleId })[0].moduleDesc.toUpperCase();
            this.getCustomerUserAccountList();
        } catch (e) {
    console.error(e);
        }
    }

    async getCustomerUserAccountList() {
        try {
            this.tblConfigCustomer.loading = true;
            this.jsonDataService.contorlLoading(true);
            this.apiCallService.getCustomerUserAccount({
                moduleId: this.moduleId,
                userId: this.userDetailService.userId,
                type: "MODULE"
            }).subscribe((response: any) => {
                this.securityService.checkRequestKeyResponse(response, () => {
                    this.securityService.hasValidCsrfToken(response, () => {
                        if (response.status === "SUCCESS") {
                            response = JSON.parse(this.jsonDataService.decrypt(response.response));
                            this.jsonDataService.contorlLoading(false);
                            this.tblConfigCustomer.tblData = response.data;
                            this.tblConfigCustomer.loading = false;
                        } else {
                            this.jsonDataService.contorlLoading(false);
                            this.tblConfigCustomer.loading = false;
                            this.appMessageService.showAppMessage(response.message, "error");
                        }
                    });   
                });
            });
        } catch (e) {
    console.error(e);
        }
    }

    getClientType(brTag: String) {
        try {
            const clientType = this.jsonDataService.data.refCds.filter((data: any) => data.rvDomain === 'BMM043.BR_TAG' && data.rvLowValue === brTag).map((data: any) => { return data.rvMeaning });
            return clientType;
        } catch (e) {
    console.error(e);
        }
    }

    onRowClick(ev: any) {
        try {
            if (ev != null) {
                this.fieldDataLoader(ev);
            } else {
                this.fieldDataClearer();
            }
        } catch (e) {
    console.error(e);
        }
    }

    fieldDataLoader(ev: any) {
        try {
            this.selectedCustomer = ev;
            this.customersForm.patchValue({
                userId: this.replaceNull(ev.USER_ID),
                assuredNo: this.replaceNull(ev.ASSD_NO),
                clientType: this.replaceNull(this.concatCdAndDesc(ev.BR_TAG, this.getClientType(ev.BR_TAG))),
                activeTag: this.replaceNull(ev.ACTIVE_TAG),
                verificationTag: this.replaceNull(ev.VERIFICATION_TAG),
                acceptTag: this.replaceNull(ev.ACCEPT_TAG),
                firstName: this.replaceNull(ev.FIRST_NAME),
                lastName: this.replaceNull(ev.LAST_NAME),
                middleName: this.replaceNull(ev.MIDDLE_NAME),
                suffix: this.replaceNull(ev.SUFFIX),
                displayName: this.replaceNull(ev.DISPLAY_NAME),
                emailAdd: this.replaceNull(ev.EMAIL_ADDRESS),
                birthDate: this.replaceNull(this.datePipe.transform(new Date(ev.BIRTH_DATE), 'MM/dd/yyyy')),
                nationality: this.replaceNull(this.concatCdAndDesc(ev.NATIONALITY_CD, ev.NATIONALITY_DESC)),
                gender: this.replaceNull(this.concatCdAndDesc(ev.GENDER_CD, ev.GENDER_DESC)),
                maritalStatus: this.replaceNull(this.concatCdAndDesc(ev.MARITAL_STAT_CD, ev.MARITAL_DESC)),
                compName: this.replaceNull(ev.COMPANY_NAME),
                regNo: this.replaceNull(ev.REGISTRATION_NO),
                occupation: this.replaceNull(this.concatCdAndDesc(ev.OCCUPATION_CD, ev.OCCUPATION_DESC)),
                jobCategory: this.replaceNull(this.concatCdAndDesc(ev.CATEGORY_CD, ev.CATEGORY_DESC)),
                permanentAdd1: this.replaceNull(ev.ADDRESS_1),
                permanentAdd2: this.replaceNull(ev.ADDRESS_2),
                permanentAdd3: this.replaceNull(ev.ADDRESS_3),
                permanentAdd4: this.replaceNull(ev.ADDRESS_4),
                permanentCountry: this.replaceNull(this.concatCdAndDesc(ev.COUNTRY_CD, ev.COUNTRY_NAME)),
                permanentCity: this.replaceNull(this.concatCdAndDesc(ev.CITY_CD, ev.CITY_NAME)),
                permanentProvince: this.replaceNull(this.concatCdAndDesc(ev.PROVINCE_CD, ev.PROVINCE_NAME)),
                permanentPostalCd: this.replaceNull(this.concatCdAndDesc(ev.POSTAL_CD, ev.POSTAL_DESC)),
                riskProfile: this.replaceNull(this.concatCdAndDesc(ev.RISK_PROF_CD, ev.RISK_PROF_DESC)),
                idType: this.replaceNull(this.concatCdAndDesc(ev.ID_TYPE_CD, ev.ID_TYPE_DESC)),
                idNo: this.replaceNull(ev.ID_NO),
                tinNo: this.replaceNull(ev.TIN_NO),
                mobileNo: this.replaceNull(ev.MOBILE_NO),
                telNo: this.replaceNull(ev.TEL_NO),
                birthCountry: this.replaceNull(this.concatCdAndDesc(ev.BIRTH_COUNTRY_CD, ev.BIRTH_COUNTRY_NAME)),
                birthCity: this.replaceNull(this.concatCdAndDesc(ev.BIRTH_CITY_CD, ev.BIRTH_CITY_NAME)),
                birthProvince: this.replaceNull(this.concatCdAndDesc(ev.BIRTH_PROVINCE_CD, ev.BIRTH_PROVINCE_NAME)),
                sameAsPermanentAdd: this.replaceNull(ev.SAME_ADDR_TAG),
                presentAdd1: this.replaceNull(ev.PRE_ADDRESS_1),
                presentAdd2: this.replaceNull(ev.PRE_ADDRESS_2),
                presentAdd3: this.replaceNull(ev.PRE_ADDRESS_3),
                presentAdd4: this.replaceNull(ev.PRE_ADDRESS_4),
                presentCountry: this.replaceNull(this.concatCdAndDesc(ev.PRE_COUNTRY_CD, ev.PRE_COUNTRY_NAME)),
                presentCity: this.replaceNull(this.concatCdAndDesc(ev.PRE_CITY_CD, ev.PRE_CITY_NAME)),
                presentProvince: this.replaceNull(this.concatCdAndDesc(ev.PRE_PROVINCE_CD, ev.PRE_PROVINCE_NAME)),
                presentPostalCd: this.replaceNull(this.concatCdAndDesc(ev.PRE_POSTAL_CD, ev.PRE_POSTAL_DESC)),
                createUser: this.replaceNull(ev.CREATE_USER),
                createDate: this.replaceNull(this.datePipe.transform(new Date(ev.CREATE_DATE), 'MM/dd/yyyy')),
                lastUserUpdate: this.replaceNull(this.datePipe.transform(new Date(ev.LAST_USER_UPDATE), 'MM/dd/yyyy')),
                lastUser: this.replaceNull(ev.LAST_USER)
            });
        } catch (e) {
    console.error(e);
        }
    }

    fieldDataClearer() {
        try {
            this.customersForm.patchValue({
                userId: [null],
                assuredNo: [null],
                clientType: [null],
                activeTag: [null],
                verificationTag: [null],
                acceptTag: [null],
                firstName: [null],
                lastName: [null],
                middleName: [null],
                suffix: [null],
                displayName: [null],
                emailAdd: [null],
                birthDate: [null],
                nationality: [null],
                gender: [null],
                maritalStatus: [null],
                compName: [null],
                regNo: [null],
                occupation: [null],
                jobCategory: [null],
                permanentAdd1: [null],
                permanentAdd2: [null],
                permanentAdd3: [null],
                permanentAdd4: [null],
                permanentCountry: [null],
                permanentCity: [null],
                permanentProvince: [null],
                permanentPostalCd: [null],
                riskProfile: [null],
                idType: [null],
                idNo: [null],
                tinNo: [null],
                mobileNo: [null],
                telNo: [null],
                birthCountry: [null],
                birthCity: [null],
                birthProvince: [null],
                sameAsPermanentAdd: [null],
                presentAdd1: [null],
                presentAdd2: [null],
                presentAdd3: [null],
                presentAdd4: [null],
                presentCountry: [null],
                presentCity: [null],
                presentProvince: [null],
                presentPostalCd: [null],
                createUser: [null],
                createDate: [null],
                lastUserUpdate: [null],
                lastUser: [null]
            });
        } catch (e) {
    console.error(e);
        }
    }

    replaceNull(ev: any) {
        if ((ev == null) || (ev == '')) {
            return '-';
        } else {
            return ev;
        }
    }

    concatCdAndDesc(cd: any, desc: any) {
        if ((cd == null) || (desc == null)) {
            return null;
        } else {
            return cd + ' - ' + desc;
        }
    }
}