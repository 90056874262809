import { Component, OnInit } from '@angular/core';

import { APICallService } from 'src/app/services/api-call.service'
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

import { FormBuilder, Validators, FormControl, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe, formatDate } from '@angular/common';

import { Observable } from 'rxjs';

import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';
import { IssSrcComponent } from '../../common/iss-src/iss-src.component';
import { TaxLovComponent } from '../../common/tax-lov/tax-lov.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { CheckLovComponent } from '../../common/guard/check-lov/check-lov.component';

@Component({
  selector: 'tax-charges-maintenance',
  templateUrl: './tax-charges-maintenance.component.html',
  styleUrls: ['./tax-charges-maintenance.component.css']
})
export class TaxChargesMaintenanceComponent implements OnInit, LeaveConfirmation {

  datePipe = new DatePipe('en-us');

  addUpdDisabled : boolean = true;
  saveDisabled : boolean = true;
  deleteDisabled : boolean = true;
  taxNameDisabled : boolean = true;
  tranPosted : boolean = false;
  noLoadingInProcess : boolean = true;
  validated : boolean = false;
  submitted : boolean = false;
  isTaxChargeSelected : boolean = false;
  isSuccessSaved : boolean = false;
  taxNameField: string = '';
  currentUser: string = this.userDetailService.userId;
  rowClickedData: any;
  effDate = new FormControl(new Date());
  moduleId: string = 'BMM024';
  moduleName: string = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId === 'BMM024' })[0].moduleDesc.toUpperCase();
  taxTypeList: any = this.jsonDataService.data.refCds.filter((e: any) => e.rvDomain=="TAX_TYPE");

  selectedTax: any;

  selectedLine: any = {
    LINE_CD: '',
    LINE_NAME: '',
    LINE_FIELD: ''
  };

  selectedIss: any = {
    ISS_CD: '',
    ISS_NAME: '',
    ISS_FIELD: ''
  };

  tempAddedData: any = [];
  tempUpdateData: any = [];

  paramForm = this.fb.group({
    lineCd: [null],
    lineName: [null],
    lineField: [null],
    issueCode: [null],
    issueName: [null],
    issueField: [null]
  });

  taxChargeForm = this.fb.group({
    taxCd: [null],
    taxCode: [null],
    taxName: [null],
    taxField: [null],
    activeTag: ['A', [Validators.required]],
    rate: [null, [Validators.required]],
    taxType: ['R', [Validators.required]],
    taxAmt: [''],
    rateRounded: [null],
    remarks: [null],
    userID: [null],
    lastUserUpdate: [null],
    mode: [null]
  });
  
  constructor(
    private _APICallService: APICallService, 
    public _Dialog: MatDialog, 
    private fb: FormBuilder, 
    public formService: FormService, 
    private appMessageService: AppMessageService,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService,
    private userDetailService: UserDetailsService
  ) { }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempAddedData!='' || this.tempUpdateData!='') {
      return false;
    } else {
      return true;
    }
  }

  tblConfigTaxCharges: any = {
    cols: [
      {
        key: "TAX_CODE",
        header: "Tax Code",
        dataType: "string",
        width: '11%'
      },
      {
        key: "TAX_NAME",
        header: "Tax Name",
        dataType: "string",
        disabled: false
      },
      {
        key: "RATE",
        header: "Rate",
        dataType: "rateDecimal",
        disabled: false,
        width: '20%'
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        disabled: true,
        width: '64px'
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15
  }

  ngOnInit(): void {
    
  }

  initOnGet(): void {
    try {
      this._APICallService.refreshNeeded$
            .subscribe(() => {
              this.getTaxCharges();
          });
      this.getTaxCharges();
    }
    catch(e) {
      
    }
  }

  getTaxCharges() {
    try {
      if (this.selectedLine.LINE_CD!=null && this.selectedLine.LINE_CD!=''
        && this.selectedIss.ISS_CD!=null && this.selectedIss.ISS_CD!='')
        {
          this.tblConfigTaxCharges.loading = true;
          this.jsonDataService.contorlLoading(true);
          this._APICallService.getTaxCharges({
            moduleId: this.moduleId,
            userId: this.userDetailService.userId,
            type: "MODULE"
          }).subscribe((data: any) => {
            this.securityService.checkRequestKeyResponse(data, () => {
              this.securityService.hasValidCsrfToken(data, () => {
                if (data.status === 'SUCCESS') {
                  data = JSON.parse(this.jsonDataService.decrypt(data.response));
                  this.tblConfigTaxCharges.loading = false;
                  this.tblConfigTaxCharges.tblData = data.data.filter((data: any) => data.LINE_CD == this.selectedLine.LINE_CD && data.ISS_CD == this.selectedIss.ISS_CD);
                  this.jsonDataService.contorlLoading(false);
                  //this.sublineForm.get('SUBLINE_CD')?.updateValueAndValidity();
                  this.addUpdDisabled = false;
                  this.taxNameDisabled = false;
                  this.resetFormValues();
                } else {
                    this.tblConfigTaxCharges.loading = false;
                    this.jsonDataService.contorlLoading(false);
                    this.appMessageService.showAppMessage(data.message, "error");
                }
              });
            });
          });
        }
    } catch(e) {
      
    }
  }

  insertTaxCharge() {
    try {
      this.tranPosted = true;
      this.validateInsert();
      if (this.validated) {
        this.addFormToTable();
      }
    }
    catch(e) {
    }
  }

  addFormToTable() {
    try {
      this.taxChargeForm.patchValue({
        activeTag: this.taxChargeForm.get('activeTag')?.value,
        rate: parseFloat(this.taxChargeForm.get('rate')?.value).toFixed(9),
        taxAmt: this.taxChargeForm.get('taxAmt')?.value == "" || this.taxChargeForm.get('taxAmt')?.value == null ? '' : parseFloat(this.taxChargeForm.get('taxAmt')?.value).toFixed(2),
        userID: this.currentUser,
        lastUserUpdate: new Date()
      });
      let formToData: any = { // this.datePipe.transform(new Date(), 'MM/dd/yyyy')
        LINE_CD: this.paramForm.get('lineCd')?.value,
        ISS_CD: this.paramForm.get('issueCode')?.value,
        TAX_CD: this.taxChargeForm.get('taxCd')?.value,
        ACTIVE_TAG: this.taxChargeForm.get('activeTag')?.value,
        RATE: this.taxChargeForm.get('rate')?.value,
        REMARKS: this.taxChargeForm.get('remarks')?.value,
        USER_ID: this.taxChargeForm.get('userID')?.value,
        MODE: 'I',
        TAX_TYPE: this.taxChargeForm.get('taxType')?.value,
        TAX_AMT: this.taxChargeForm.get('taxAmt')?.value,
      }
      let formToTable: any = {
        TAX_CD: this.taxChargeForm.get('taxCd')?.value,
        TAX_CODE: this.taxChargeForm.get('taxCode')?.value,
        TAX_NAME: this.taxChargeForm.get('taxName')?.value,
        TAX_FIELD: this.taxChargeForm.get('taxField')?.value,
        ACTIVE_TAG: this.taxChargeForm.get('activeTag')?.value,
        RATE: this.taxChargeForm.get('rate')?.value,
        RATE_ROUNDED: this.taxChargeForm.get('rate')?.value,
        REMARKS: this.taxChargeForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.taxChargeForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.taxChargeForm.get('userID')?.value,
        MODE: 'I',
        TAX_TYPE: this.taxChargeForm.get('taxType')?.value,
        TAX_AMT: this.taxChargeForm.get('taxAmt')?.value,
      }
      this.tblConfigTaxCharges.tblData = [...this.tblConfigTaxCharges.tblData];
      this.tblConfigTaxCharges.tblData.unshift(formToTable);
      this.tempAddedData.push(formToData);
      this.resetFormValues();
      this.appMessageService.showAppMessage("Tax charge details added!", "success");
    }
    catch(e) {
    }
  }

  updateTaxCharge() {
    try {
      this.tranPosted = true;
      this.validateUpdate();
      if (this.validated) {
        this.updateFormTable();
      }
    }
    catch(e) {
      
    }
    
  }

  updateFormTable() {
    try {
      let tempInsertChecker = this.tempAddedData.find(
        (tax: { TAX_CD: any, LINE_CD: any, ISS_CD: any }) => 
          tax.TAX_CD == this.taxChargeForm.get('taxCd')?.value
          && tax.LINE_CD == this.paramForm.get('lineCd')?.value
          && tax.ISS_CD == this.paramForm.get('issueCode')?.value);
      let tempUpdateChecker = this.tempUpdateData.find(
        (tax: { TAX_CD: any, LINE_CD: any, ISS_CD: any }) => 
          tax.TAX_CD == this.taxChargeForm.get('taxCd')?.value
          && tax.LINE_CD == this.paramForm.get('lineCd')?.value
          && tax.ISS_CD == this.paramForm.get('issueCode')?.value);
      this.tableDataUpdate();
      if(tempInsertChecker!=null && tempInsertChecker!='') {
        tempInsertChecker.LINE_CD = this.paramForm.get('lineCd')?.value;
        tempInsertChecker.ISS_CD = this.paramForm.get('issueCode')?.value;
        tempInsertChecker.TAX_CD = this.taxChargeForm.get('taxCd')?.value;
        tempInsertChecker.ACTIVE_TAG = this.taxChargeForm.get('activeTag')?.value;
        tempInsertChecker.RATE = this.taxChargeForm.get('rate')?.value;
        tempInsertChecker.REMARKS = this.taxChargeForm.get('remarks')?.value;
        tempInsertChecker.USER_ID = this.taxChargeForm.get('userID')?.value;
        tempInsertChecker.MODE = 'I';
        tempInsertChecker.TAX_TYPE = this.taxChargeForm.get('taxType')?.value;
        tempInsertChecker.TAX_AMT = this.taxChargeForm.get('taxAmt')?.value;
      } 
      else if(tempUpdateChecker!=null && tempUpdateChecker!='') {
        tempUpdateChecker.LINE_CD = this.paramForm.get('lineCd')?.value;
        tempUpdateChecker.ISS_CD = this.paramForm.get('issueCode')?.value;
        tempUpdateChecker.TAX_CD = this.taxChargeForm.get('taxCd')?.value;
        tempUpdateChecker.ACTIVE_TAG = this.taxChargeForm.get('activeTag')?.value;
        tempUpdateChecker.RATE = this.taxChargeForm.get('rate')?.value;
        tempUpdateChecker.REMARKS = this.taxChargeForm.get('remarks')?.value;
        tempUpdateChecker.USER_ID = this.taxChargeForm.get('userID')?.value;
        tempUpdateChecker.MODE = 'U';
        tempUpdateChecker.TAX_TYPE = this.taxChargeForm.get('taxType')?.value;
        tempUpdateChecker.TAX_AMT = this.taxChargeForm.get('taxAmt')?.value;
      }
      else {
        this.tempUpdateData.push(this.formValueToData());
      }
      this.tblConfigTaxCharges.tblData = [...this.tblConfigTaxCharges.tblData];
      
      this.saveDisabled = false;
      this.resetFormValues();
      this.appMessageService.showAppMessage("Tax charge details updated!", "success");
    }
    catch(e) {
      
    }
  }

  tableDataUpdate() {
    try {
      this.taxChargeForm.patchValue({
        activeTag: this.taxChargeForm.get('activeTag')?.value,
        rate: parseFloat(this.taxChargeForm.get('rate')?.value).toFixed(9),
        taxAmt: this.taxChargeForm.get('taxAmt')?.value == "" || this.taxChargeForm.get('taxAmt')?.value == null ? '' : parseFloat(this.taxChargeForm.get('taxAmt')?.value).toFixed(2),
        userID: this.currentUser,
        lastUserUpdate: new Date()
      });
      let updateIndex = this.tblConfigTaxCharges.tblData.indexOf(this.rowClickedData);
      this.tblConfigTaxCharges.tblData[updateIndex] = {
        TAX_CD: this.taxChargeForm.get('taxCd')?.value,
        TAX_CODE: this.taxChargeForm.get('taxCode')?.value,
        TAX_NAME: this.taxChargeForm.get('taxName')?.value,
        TAX_FIELD: this.taxChargeForm.get('taxField')?.value,
        ACTIVE_TAG: this.taxChargeForm.get('activeTag')?.value,
        RATE: this.taxChargeForm.get('rate')?.value,
        RATE_ROUNDED: this.taxChargeForm.get('rate')?.value,
        REMARKS: this.taxChargeForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.taxChargeForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.taxChargeForm.get('userID')?.value,
        MODE: 'U',
        TAX_TYPE: this.taxChargeForm.get('taxType')?.value,
        TAX_AMT: this.taxChargeForm.get('taxAmt')?.value,
      };
    }
    catch(e) {
      
    }
  }

  formValueToData() {
    try {
      let formToData: any = { // this.datePipe.transform(new Date(), 'MM/dd/yyyy')
        LINE_CD: this.paramForm.get('lineCd')?.value,
        ISS_CD: this.paramForm.get('issueCode')?.value,
        TAX_CD: this.taxChargeForm.get('taxCd')?.value,
        ACTIVE_TAG: this.taxChargeForm.get('activeTag')?.value,
        RATE: this.taxChargeForm.get('rate')?.value,
        REMARKS: this.taxChargeForm.get('remarks')?.value,
        USER_ID: this.taxChargeForm.get('userID')?.value,
        MODE: 'U',
        TAX_TYPE: this.taxChargeForm.get('taxType')?.value,
        TAX_AMT: this.taxChargeForm.get('taxAmt')?.value,

      }
      return formToData;
    }
    catch(e) {
      
    }
  }
    
    saveAddedData() {
        this._APICallService.saveTaxCharges(this.tempAddedData)
            .subscribe({
                next: (response : any) => {
                  this.securityService.checkRequestKeyResponse(response, () => {
                    this.securityService.hasValidCsrfToken(response, () => {
                      if (response.status == "SUCCESS" && this.tranPosted) {
                        if (this.tempUpdateData.length !== 0) {
                            this.saveUpdateData();
                        } else {
                            this.jsonDataService.contorlLoading(false);
                            this.openDialog('SUCCESS', 'New tax charges details Successfully saved!');
                            this.initOnGet();
                            this.resetFormValues();
                            this.tempAddedData = [];
                            this.tempUpdateData = [];
                        }
                      }   
                      else {
                          this.openDialog('ERROR', 'An error has occured while saving details for new tax charge');
                      }
                    });
                  });
                }, 
                error: () => {
                  this.openDialog('ERROR', 'An error has occured while saving details for new tax charge');
                }
            });
    }

    saveUpdateData() {
        this._APICallService.saveTaxCharges(this.tempUpdateData)
            .subscribe({
                next: (response : any) => {
                  this.securityService.checkRequestKeyResponse(response, () => {
                    this.securityService.hasValidCsrfToken(response, () => {
                      if (response.status == "SUCCESS" && this.tranPosted) {
                        this.jsonDataService.contorlLoading(false);
                          this.openDialog('SUCCESS', 'Existing tax charges details successfully saved!');
                          this.initOnGet();
                          this.resetFormValues();
                          this.tempAddedData = [];
                          this.tempUpdateData = [];
                      }  
                      else {
                          this.jsonDataService.contorlLoading(false);
                          this.openDialog('ERROR', 'An error has occured while saving details for existing tax charge');
                      }
                    });
                  });
                }, 
                error: () => {
                  this.jsonDataService.contorlLoading(false);
                  this.openDialog('ERROR', 'An error has occured while saving details for existing tax charge');
                }
            });
    }

    toSave() {
        try {
            this.noLoadingInProcess = false;
            this.jsonDataService.contorlLoading(true);
            this.formService.showFormLoader(null, "generate-tax-charges-form", "Please wait ...", null, null);
            if(this.tempAddedData.length!=0) {
                this.saveAddedData();
            } else {
                if (this.tempUpdateData.length !== 0) {
                    this.saveUpdateData();
                } else {
                    this.initOnGet();
                    this.resetFormValues();
                    this.tempAddedData = [];
                    this.tempUpdateData = [];
                }
            }
        } catch(e) {
          
        }
    }

  deleteTaxCharge() {
    try {
      let clickData = this.rowClickedData;
      this.tblConfigTaxCharges.tblData = this.tblConfigTaxCharges.tblData.filter(
        function(e: any) {
          return e.TAX_CD != clickData.TAX_CD;
        }
      );
      this.tempAddedData = this.tempAddedData.filter(
        function(e: any) {
          return e.TAX_CD != clickData.TAX_CD;
        }
      );
      this.resetFormValues();
    }
    catch(e) {
      
    }
  }

  checkOpenLineDialog() {
    try {
      if(this.tempAddedData!='' || this.tempUpdateData!='') {
        this._Dialog.open(CheckLovComponent, {
          disableClose: true,
          width: '512px',
          data: {
              title: "Confirmation Message",
              content:
                  "You have unsaved changes. Do you want to continue?"
          }
        }).afterClosed().subscribe((a:any) => {
            if (a) {
              this.tempAddedData = [];
              this.tempUpdateData = [];
              this.tblConfigTaxCharges.tblData = [];
              this.openLineDialog();
            }
        });
      } else {
        this.openLineDialog();
      }
    } catch(e) {
      
    }
  }

  openLineDialog() {
    try {
      this._Dialog.open(LineLovDialogComponent, {
        width: '512px',
        disableClose: true,
        data: {
          table: 'LINE',
          moduleId: this.moduleId
        }
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedLine = data.content;
          this.paramForm.get('lineCd')?.setValue(this.selectedLine.LINE_CD==null || this.selectedLine.LINE_CD=='' ? '' : this.selectedLine.LINE_CD);
          this.paramForm.get('lineName')?.setValue(this.selectedLine.LINE_CD==null || this.selectedLine.LINE_CD=='' ? '' : this.selectedLine.LINE_NAME);
          this.paramForm.get('lineField')?.setValue(this.selectedLine.LINE_CD==null || this.selectedLine.LINE_CD=='' ? '' : this.selectedLine.LINE_CD + " - " + this.selectedLine.LINE_NAME);
          this.initOnGet();
        }
      });
    } catch(e) {
      
    }
  }

  checkOpenIssDialog() {
    try {
      if(this.tempAddedData!='' || this.tempUpdateData!='') {
        this._Dialog.open(CheckLovComponent, {
          disableClose: true,
          width: '512px',
          data: {
              title: "Confirmation Message",
              content:
                  "You have unsaved changes. Do you want to continue?"
          }
        }).afterClosed().subscribe((a:any) => {
            if (a) {
              this.tempAddedData = [];
              this.tempUpdateData = [];
              this.tblConfigTaxCharges.tblData = [];
              this.openIssDialog();
            }
        });
      } else {
        this.openIssDialog();
      }
    } catch(e) {
      
    }
  }

  openIssDialog() {
    try {
      this._Dialog.open(IssSrcComponent, {
        width: '512px',
        disableClose: true,
        data: {moduleId: this.moduleId}
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedIss = data.content;
          this.paramForm.get('issueCode')?.setValue(this.selectedIss.ISS_CD==null || this.selectedIss.ISS_CD=='' ? '' : this.selectedIss.ISS_CD);
          this.paramForm.get('issueName')?.setValue(this.selectedIss.ISS_CD==null || this.selectedIss.ISS_CD=='' ? '' : this.selectedIss.ISS_NAME);
          this.paramForm.get('issueField')?.setValue(this.selectedIss.ISS_CD==null || this.selectedIss.ISS_CD=='' ? '' : this.selectedIss.ISS_CD + " - " + this.selectedIss.ISS_NAME);
          this.initOnGet();
        }
      });
    } catch(e) {
      
    }
  }

  openTaxDialog() {
    try {
      this._Dialog.open(TaxLovComponent, {
        width: '512px',
        disableClose: true,
        data: {moduleId: this.moduleId}
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedTax = data.content;
          this.taxChargeForm.get('taxCd')?.setValue(this.selectedTax.TAX_CD==null || this.selectedTax.TAX_CD=='' ? '' : this.selectedTax.TAX_CD);
          this.taxChargeForm.get('taxCode')?.setValue(this.selectedTax.TAX_CD==null || this.selectedTax.TAX_CD=='' ? '' : this.selectedTax.TAX_CODE);
          this.taxChargeForm.get('taxName')?.setValue(this.selectedTax.TAX_CD==null || this.selectedTax.TAX_CD=='' ? '' : this.selectedTax.TAX_NAME);
          this.taxChargeForm.get('taxField')?.setValue(this.selectedTax.TAX_CD==null || this.selectedTax.TAX_CD=='' ? '' : this.selectedTax.TAX_CODE + " - " + this.selectedTax.TAX_NAME);
        }
      });
    } catch(e) {
      
    }
  }

  saveChecker() {
    try {
      if((this.tempAddedData==null || this.tempAddedData=='') && (this.tempUpdateData==null || this.tempUpdateData=='')) {
        return true;
      }
      else {
        return false;
      }
    }
    catch(e) {
      return true;
    }
  }

  deleteChecker(ev: any) {
    try {
      let tempDataFlag;
      tempDataFlag = this.tempAddedData.filter(function(e: any) {
        return e.TAX_CD==ev.TAX_CD;
      });
      if(tempDataFlag==''||tempDataFlag==null) {
        return true;
      } else {
        return false;
      }
    }
    catch(e) {
      return true;
    }
  }

  onRowClick(ev: any) {
    try {    
      if(ev!=null) {
        this.rowClickedData = ev;
        this.loadFormValues();
        this.saveDisabled = this.saveChecker();
        this.deleteDisabled = this.deleteChecker(ev);
      }
      else {
        this.resetFormValues();
      }
    }
    catch(e) {
      
    }
  }

  loadFormValues() {
    try {
      this.taxChargeForm.patchValue({
        taxCd: this.rowClickedData.TAX_CD,
        taxCode: this.rowClickedData.TAX_CODE,
        taxName: this.rowClickedData.TAX_NAME,
        taxField: this.rowClickedData.TAX_FIELD,
        activeTag: this.rowClickedData.ACTIVE_TAG,
        rate: this.rowClickedData.RATE_ROUNDED,
        remarks: this.rowClickedData.REMARKS,
        userID: this.rowClickedData.USER_ID,   
        lastUserUpdate: this.rowClickedData.LAST_USER_UPDATE,
        taxType: this.rowClickedData.TAX_TYPE,
        taxAmt: this.rowClickedData.TAX_AMT === "" || this.rowClickedData.TAX_AMT === null ? "" : parseFloat(this.rowClickedData.TAX_AMT).toFixed(2),
      });
      this.taxNameDisabled = true;
      this.isTaxChargeSelected = true;
    }
    catch(e) {
      
    }
  }

  resetFormValues() {
    try {
      this.taxChargeForm.patchValue({
        taxCd: null,
        taxCode: null,
        taxName: null,
        taxField: null,
        activeTag: 'A',
        rate: null,
        remarks: null,
        userID: null,
        lastUserUpdate: null,
        taxType: 'R',
        taxAmt: '',
      });
      this.saveDisabled = this.saveChecker();
      this.deleteDisabled = true;
      this.isTaxChargeSelected = false;
      this.taxNameDisabled = false;
      this.rowClickedData = '' || null;
    }
    catch (e) {
    console.error(e);
      
    }
  }

  openDialog(title: any, message: any) {
    try {
      this.noLoadingInProcess = true;
      this.formService.hideFormLoader("generate-tax-charges-form");
      this._Dialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        } 
      });
    } catch(e) {
      
    }
  }

  validateInsert() {
    try {
      this.validated = false;
      if (!this.requiredFieldCheck()){
        setTimeout(()=>{
          this.formService.hideFormMsg("tax-error-message");
        }, 10000);
      }
      else if (!this.taxNameDupCheck()) {
        setTimeout(()=>{                           
          this.formService.hideFormMsg("tax-error-message");
        }, 10000);
      }
      else if (!this.checkDecimalsExceededRate(3, 9)) {
        setTimeout(()=>{                           
          this.formService.hideFormMsg("tax-error-message");
        }, 10000);
      }
      else if (!this.checkDecimalsExceededTaxAmount(10, 2)) {
        setTimeout(()=>{                           
          this.formService.hideFormMsg("tax-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
      
    }
    
  }

  validateUpdate() {
    try {
      this.validated = false;
      if (!this.requiredFieldCheck()){
        // this.openDialog('ERROR', 'There are missing information. Please provide the necessary information needed.');
        setTimeout(()=>{                          
          this.formService.hideFormMsg("tax-error-message");
        }, 10000);
      }
      else if (!this.checkDecimalsExceededRate(3, 9)) {
        setTimeout(()=>{                           
          this.formService.hideFormMsg("tax-error-message");
        }, 10000);
      }
      else if (!this.checkDecimalsExceededTaxAmount(10, 2)) {
        setTimeout(()=>{                           
          this.formService.hideFormMsg("tax-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
      
    }
  }

  taxNameDupCheck() {
    try {
      let taxNameForm = this.taxChargeForm.get('taxName')?.value.toUpperCase();
      this.taxNameField = this.tblConfigTaxCharges.tblData.filter(function(e: any) {
        return e.TAX_NAME.toUpperCase()==taxNameForm.toUpperCase();
      });
      if(this.taxNameField=='' || this.taxNameField==null) {
        return true;
      } else {
        this.formService.showFormMsg("tax-error-message", 'Unable to add record. Tax for this Line and Issue already exists.', "E");
        return false;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }  

  rateNegativeCheck() {
    try {
      let rateForm = this.taxChargeForm.get('rate')?.value;
      if(rateForm > 0) {
        return true;
      } else {
        this.formService.showFormMsg("tax-error-message", 'Rate does not accept negative numbers.', "E");
        return false;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }  

  taxAmtNegativeCheck() {
    try {
      let taxAmtForm = this.taxChargeForm.get('taxAmt')?.value;
      if(taxAmtForm === null || taxAmtForm === "" || isNaN(taxAmtForm)) {
        return true;
      } else if(taxAmtForm >= 0) {
        return true;
      } else {
        this.formService.showFormMsg("tax-error-message", 'Tax amount does not accept negative numbers.', "E");
        return false;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }  

  requiredFieldCheck() {
    try {
      if( this.taxChargeForm.get('taxField')?.value=='' || this.taxChargeForm.get('taxField')?.value==null
       || this.taxChargeForm.get('activeTag')?.value=='' || this.taxChargeForm.get('activeTag')?.value==null 
       || this.taxChargeForm.get('rate')?.value=='' || this.taxChargeForm.get('rate')?.value==null) {
        this.formService.showFormMsg("tax-error-message", 'There are missing information. Please provide the necessary information needed.', "E");
        return false;
      }
      else {
        return true;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }
  
  private checkDecimalsExceededRate(digits: number, decimals: number) {
    const rate = this.taxChargeForm.get("rate")?.value;
    const value = rate && parseFloat(this.formService.setToNumberWithDecimal(rate));
    if (!value) return true;
    let isExceeded: boolean = value > +"".padEnd(digits, '9') + +('.' + ''.padEnd(decimals, '9'))
    if(isExceeded) {
      this.formService.showFormMsg("tax-error-message", 'Invalid value in Rate field. The value entered exceeded the maximum limit.', "E");
      return false;
    } else {
      return true;
    }
  }
  
  private checkDecimalsExceededTaxAmount(digits: number, decimals: number) {
    const taxAmount = this.taxChargeForm.get("taxAmt")?.value;
    const value = taxAmount && parseFloat(this.formService.setToNumberWithDecimal(taxAmount));
    if (!value) return true;
    let isExceeded: boolean = value > +"".padEnd(digits, '9') + +('.' + ''.padEnd(decimals, '9'))
    if(isExceeded) {
      this.formService.showFormMsg("tax-error-message", 'Invalid value in Tax Amount field. The value entered exceeded the maximum limit.', "E");
      return false;
    } else {
      return true;
    }
  }

  rateValidation(ev: any) {
    try {
      const rate = ev.value;
      if(rate < 0 && !(rate === null || rate === "")) {
        this.taxChargeForm.get('rate')?.setValue("");
      }
    } catch(e) {
      
    }
  }

  taxAmtValidation(ev: any) {
    try {
      const taxAmt = ev.value;
      if(taxAmt < 0 && !(taxAmt === null || taxAmt === "")) {
        this.taxChargeForm.get('taxAmt')?.setValue("");
      }
    } catch(e) {
      
    }
  }
}