<section id="client-group">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <osp-table [tblConfig]="clientGroupTable" (onRowClick)="onRowClick($event)"></osp-table>
        <div id="client-group-form"><div class="form-loader"></div></div>
        <div class="form-content" [hidden]="formHider">
            <form [formGroup]="clientGroupForm" class="form">
                <div id="client-group-error-message"></div>
                <div class="form-container-grid">

                    <div class="field required">
                        <label>Client Code</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="CLIENT_CD" oninput="this.value = this.value.toUpperCase()" maxlength="20">
                    </div>

                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="ACTIVE_TAG">
                            <option value="A">Yes</option>
                            <option value="I">No</option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>Client Description</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="CLIENT_DESC" maxlength="250">
                    </div>

                    <div class="field required">
                        <label>BPI MS Code</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="BM_CODE" maxlength="8">
                    </div>

                    <div class="field">
                        <label>Ambulance Charge</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="AMB_CHARGE" (focus)="formService.setFieldToNumberWithDecimal(clientGroupForm, 'AMB_CHARGE', $event)"
                        (blur)="formService.setFieldToAmountFormat(clientGroupForm, 'AMB_CHARGE', $event)" (keypress)="allowNumericDigitsOnly($event)" amount >
                    </div>

                    <div class="field">
                        <label>Non-Casa Period</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="NON_CASA_PD" maxlength="50">
                    </div>

                    <div class="field">
                        <label>Auto Personal Accident Text</label>
                    </div>
                    <div class="span">
                        <textarea formControlName="UPPA_TEXT" maxlength="250"></textarea>
                    </div>

                    <div class="field">
                        <label>Other Risk Details</label>
                    </div>
                    <div class="span">
                        <textarea formControlName="OTHER_RISK_DTL" ></textarea>
                    </div>

                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input type="text" formControlName="REMARKS" maxlength="4000">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input formControlName="USER_ID">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="LAST_UPDATE">
                    </div>

                </div>
            </form>
            <div class="btn-grp">
                <button type="button" class="btn btn2" [hidden]="!addUpdateToggler" (click)="addRow()">ADD</button>
                <button type="button" class="btn btn2" [hidden]="addUpdateToggler" (click)="onUpdate()">UPDATE</button>
                <button type="button" class="btn btn2" [disabled]="deleteDisabler" (click)="deleteRow()">DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-grp">
                <button type="button" class="btn btn2" [disabled]="saveDisabler" (click)="onSave()">SAVE</button>
            </div>
        </div>
    </div>
</section>