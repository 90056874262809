import { Component, Inject, OnInit } from '@angular/core';
import { APICallService } from 'src/app/services/api-call.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContentObserver } from '@angular/cdk/observers';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';


@Component({
  selector: 'agent-group-lov',
  templateUrl: './agent-group-lov.component.html',
  styleUrls: ['./agent-group-lov.component.css']
})
export class AgentGroupLovDialogComponent implements OnInit {

  agentGroupModuleId: string = 'BMM072';

  constructor(private apiCallService: APICallService,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<AgentGroupLovDialogComponent>) { }
  
  ngOnInit(): void {
    this.getAgentGroup();
  }


  // Variable Initializer
  issueSourceData: any = [];

  // Issue Source Initializer
  agentGroupTableConfig: any = {
    cols: [
      {
        key: "AGENT_GRP",
        header: "Agent Group",
        dataType: "string"
      },
      {
        key: "AGENT_GRP_DESC",
        header: "Description",
        dataType: "string"
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15
  }

  // Gets data for Agent Group Table
  getAgentGroup() {
    try {
      /*
      this.agentGroupTableConfig.loading = true;
      this.issueSourceData = await this.apiCallService.getAllIssueSource().toPromise();
      this.issueSourceData = this.issueSourceData.content;
      this.agentGroupTableConfig.loading = false;
      this.agentGroupTableConfig.tblData = this.issueSourceData;
      */
      this.agentGroupTableConfig.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.apiCallService.getAgentGroup({
        moduleId: this.data.moduleId,
        userId: this.userDetailService.userId,
        type: "LOV"
      }).subscribe((data: any) => {
            this.securityService.checkRequestKeyResponse(data, () => {
              this.securityService.hasValidCsrfToken(data, () => {
                if (data.status === "SUCCESS") {
                  data = JSON.parse(this.jsonDataService.decrypt(data.response));
                  this.jsonDataService.contorlLoading(false);
                  this.agentGroupTableConfig.loading = false;
                  this.agentGroupTableConfig.tblData = data.filter((e: any) => e.ACTIVE_TAG == 'A');
                } else {
                    this.jsonDataService.contorlLoading(false);
                    this.agentGroupTableConfig.loading = false;
                    this.appMessageService.showAppMessage(data.message, "error");
                }
              });
            });
      });
    } catch(e) {
      
    }
  }

  onRowClick(ev: any) {
    try {
      this.data = ev;
    } catch(e) {
      
    }
  }

  onClose() {
    try {
      if(this.data=='' || this.data==null){
        this._dialogRef.close({content: ''}); 
      } else {
        this._dialogRef.close({content: this.data, moduleId: this.data.moduleId});
      }
    } catch(e) {
      
    }
  }

  onBack() {
    try {
      this._dialogRef.close({content: '', option: 'back'});
    } catch(e) {
      
    }
  }

}

