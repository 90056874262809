<!-- Contents -->
<section id="dialog">
    <div class="mtn-title">
        {{titleLov}}
    </div>
</section>
<!-- Table Contents -->
<div>
    <osp-table [tblConfig]="table"></osp-table>

    <div class="btn-grp-2 btn-grp-2-l">
        <button class="btn btn2" type="button" (click)="onClose()">RETURN</button>
    </div>
</div>