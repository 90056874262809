<section id="frequency">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <osp-table [tblConfig]="frequencyTable" (onRowClick)="onRowClick($event)" [selectedRow]="selectedRowComponent"></osp-table>

        <div id="frequency-form"><div class="form-loader"></div></div>

        <div class="form-content" [hidden]="formHider">

            <form [formGroup]="frequencyForm" class="form">
                <div id="frequency-error-message"></div>
                <div class="form-container-grid">

                    <div class="field required">
                        <label>Frequency Description</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="freqDesc" maxlength="1000">
                    </div>

                    <div class="field required">
                        <label>Month Count</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="monthCount" (keypress)="allowNumericDigitsOnly($event)">
                    </div>
                    
                    <div class="">
                        <label>Frequency Unit</label>
                    </div>
                    <div class="">
                        <input formControlName="freqUnit" type="text" maxlength="250">
                    </div>

                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag">
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                        </select>
                    </div>

                    <div class="">
                        <label>BM Code</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="bmCode" oninput="this.value = this.value.toUpperCase()" maxlength="10">
                    </div>
                    <div class="">
                        <label>BM Description</label>
                    </div>
                    <div class="">
                        <input formControlName="bmDesc" maxlength="10" id="bmDesc">
                    </div>
                    
                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" maxlength="4000">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input formControlName="lastUpdateUser" type="text" readonly>
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="lastUpdate">
                    </div>
                    
                </div>
                <!-- <div class="">
                    <label hidden="hidden">Frequency Code</label>
                </div>
                <div class="">
                    <input type="text" formControlName="freqCode" oninput="this.value = this.value.toUpperCase()" maxlength="10" hidden>
                </div> -->
            </form>

            <div class="btn-grp">
                <button type="button" class="btn btn2" [hidden]="!addUpdateFlag" (click)="addRow()">ADD</button>
                <button type="button" class="btn btn2" [hidden]="addUpdateFlag" (click)="onUpdate()">UPDATE</button>
                <button type="button" class="btn btn2" [disabled]="deleteDisabler" (click)="deleteRow()">DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-grp">
                <button type="button" class="btn btn2" [disabled]="saveDisabler" (click)="onSave()">SAVE</button>
            </div>
        </div>
    </div>
</section>