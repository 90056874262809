<div class="mtn-container">
    <div class="mtn-title">
        <p>{{ dialogTitle }}</p>
    </div>
    <form [formGroup]="downloadVoucherCodeForm">
        <div class="form">
            <p id="download-voucher-code-error-message" class="form-error-msg"></p>
            <div class="row">
                <div class="field required">
                    <label>Line</label>
                    <input formControlName="lineDisplay" class="no-color" readonly />
                </div>
            </div>
            <div class="row">
                <div class="field required">
                    <label>Subline</label>
                    <input formControlName="sublineDisplay" class="no-color" readonly />
                </div>
            </div>
            <div class="row">
                <div class="field required">
                    <label>Plan</label>
                    <input formControlName="planDisplay" class="no-color" readonly />
                </div>
            </div>
            <div class="row">
                <div class="field">
                    <label>Client Type Group</label>
                    <div class="with-button">
                        <input formControlName="clientTypeGroupDisplay" class="no-color" readonly />
                        <button matSuffix class="btn btn-search" type="button" (click)="openLineLOV('clientTypeGroup')">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="field">
                    <label>Batch No.</label>
                    <input type="number" min="0" posNumInput formControlName="batchNo" />
                </div>
            </div>
            <div class="row">
                <div class="field">
                    <label>Voucher Code</label>
                    <input formControlName="voucherCode" />
                </div>
            </div>
        </div>
        <div class="form">
            <div class="row">
                <div class="field">
                    <label>Date Parameter</label>
                    <select formControlName="dateParameter">
                        <option *ngFor="let dateParameter of selections.dateParameters" [ngValue]="dateParameter.cd">
                            {{ dateParameter.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="field" [ngClass]="{'required' : datesRequired}">
                    <label>From Date</label>
                    <div class="with-button">
                        <input matInput date-input formControlName="fromDate" type="text" placeholder="MM/DD/YYYY" [matDatepicker]="fromDatePicker" />
                        <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #fromDatePicker></mat-datepicker>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="field" [ngClass]="{'required' : datesRequired}">
                    <label>To Date</label>
                    <div class="with-button">
                        <input matInput date-input formControlName="toDate" type="text" placeholder="MM/DD/YYYY" [matDatepicker]="toDatePicker" />
                        <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #toDatePicker></mat-datepicker>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-btns">
            <button class="btn btn2" type="button" (click)="onCancel()">Cancel</button>
            <button class="btn btn2" type="button" (click)="onProceed()">Proceed</button>
        </div>
    </form>
</div>
