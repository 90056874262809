<section id="property-si-limit-maint">    
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <div class="form-content">
            <form [formGroup]="forms.userTypeLovForm" class="form">
                <div class="form-container-grid top-form">
                    <div class="field required">
                        <label>User Type</label>
                    </div>
                    <div class="">
                        <input formControlName="userTypeField" >
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['userType']()">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <!-- Container for Property SI Limit Table using prime ng -->
        <div class = 'table-user'>
            <osp-table [tblConfig]="propertySiLimitTblConfig" (onRowClick)="onRowClick($event)"></osp-table>
        </div>
        <!-- Loading Animation -->
        <div id="property-si-limit-maintenance-loading">
            <div class="form-loader"></div>
        </div>
        <div class="form-content" *ngIf="showForm">
            <form [formGroup]="forms.propertySiLimitForm" class="form">
                <div id="property-si-limit-error-message"></div>
                <div class="form-container-grid">
                    <div class="field required">
                        <label>User Level</label>
                    </div>
                    <div class="lov-search">
                        <input type="text" readonly formControlName="userLevelField">
                        <button type="button" class="btn-search btn" (click)="openLov['userLevel']()" [disabled]='btns.propertySiLimit.userLevelDisabled'>
                            <mat-icon>
                                search
                            </mat-icon>
                        </button>
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag">
                            <option *ngFor="let activeType of selections.activeTypes" [value]="activeType.cd">
                                {{activeType.name}}
                            </option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>Minimum Sum Insured</label>
                    </div>
                    <div class="">
                        <input class="currency" formControlName="siAmtMin" (focus)="_formService.setFieldToNumberWithDecimal(forms.propertySiLimitForm, 'siAmtMin', $event)"
                        (blur)="_formService.setFieldToAmountFormat(forms.propertySiLimitForm, 'siAmtMin', $event)" (keypress)="allowNumericDigitsOnly($event)">
                    </div>
                    <div class="field required">
                        <label>Maximum Sum Insured</label>
                    </div>
                    <div class="">
                        <input class="currency" formControlName="siAmtMax" (focus)="_formService.setFieldToNumberWithDecimal(forms.propertySiLimitForm, 'siAmtMax', $event)"
                        (blur)="_formService.setFieldToAmountFormat(forms.propertySiLimitForm, 'siAmtMax', $event)" (keypress)="allowNumericDigitsOnly($event)">
                    </div>

                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" type="text" maxlength="1000">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="userID">
                    </div>
                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUpdate">
                    </div>
                </div>
            </form>
            <div class="btn-row">
                <button *ngIf="btns.propertySiLimit.updateDisabled" class="btn btn2" type="button" [disabled]='btns.propertySiLimit.addDisabled' (click)="onAdd()">ADD</button>
                <button *ngIf="!btns.propertySiLimit.updateDisabled" class="btn btn2" type="button" (click)="onUpdate()">UPDATE</button>
                <button class="btn btn2" type="button" [disabled]='btns.propertySiLimit.deleteDisabled' (click)="onDelete()"> DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-row">
                <button class="btn btn2" type="button" [disabled]='btns.propertySiLimit.saveDisabled' (click)="onSave()">SAVE</button>
            </div>
        </div>
    </div>
</section>
