import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs/internal/Observable';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { AutocompleteService } from 'src/app/services/autocomplete.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { OverlayComponent } from '../../common/overlay/overlay';

@Component({
  selector: 'policy-cancellation',
  templateUrl: './policy-cancellation.component.html',
  styleUrls: ['./policy-cancellation.component.css']
})
export class PolicyCancellationComponent implements OnInit {
  
  //Cancellation Overlay
  @ViewChild('cancelPol')cancelPol!: OverlayComponent;

  datePipe = new DatePipe('en-us');
  selectedRow: any;
  userId: string = this.userDetailService.userId;
  errorMessage: string = '';
  formHider: boolean = false;
  cancelDisabler: boolean = true;
  moduleId: string = 'BMU001';
  moduleName = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId === 'BMU001' })[0].moduleDesc.toUpperCase();
  filteredReasonOptions:Observable<any>; // para sa cancellation ng policy
  savedFlag: boolean = false;

  policyCancellationTable: any = {
    cols: [
      {
        key: "policyNo",
        header: "Policy No.",
        dataType: "string"
      },
      {
        key: "planName",
        header: "Plan Name",
        dataType: "string"
      },
      {
        key: "assuredLongName",
        header: "Assured Long Name",
        dataType: "string"
      },
      {
        key: "issueDate",
        header: "Issue Date",
        dataType: "date"
      },
      {
        key: "effectiveDate",
        header: "Effective Date",
        dataType: "date"
      },
      {
        key: "expiryDate",
        header: "Expiry Date",
        dataType: "date"
      },
      {
        key: "userID",
        header: "User ID",
        dataType: "string"
      },
      {
        key: "status",
        header: "Status",
        dataType: "string"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  policyCancellationForm = this.fb.group({
    policyNo:         [{value: '', disabled: true}],
    planName:         [{value: '', disabled: true}],
    assuredLongName:  [{value: '', disabled: true}],
    issueDate:        [{value: '', disabled: true}],
    effectiveDate:    [{value: '', disabled: true}],
    expiryDate:       [{value: '', disabled: true}],
    userId:           [{value: '', disabled: true}],
    status:           [{value: '', disabled: true}],
    agentCode:        [{value: '', disabled: true}],
    agentName:        [{value: '', disabled: true}],
    issueCode:        [{value: '', disabled: true}],
    sourceCode:       [{value: '', disabled: true}],
    sourceExtCode:    [{value: '', disabled: true}],
    sourceExtName:    [{value: '', disabled: true}],
    referrorCode:     [{value: '', disabled: true}],
    referrorName:     [{value: '', disabled: true}],
    coverage:         [{value: '', disabled: true}],
    netPremium:       [{value: '', disabled: true}],
    totalPremium:     [{value: '', disabled: true}],
    reason:           [''],
    cancelApprovedBy: [''],
    cancelRemarks:    [''],
  });

  constructor(
    private fb: FormBuilder,
    private api: APICallService,
    private dialog: MatDialog,
    private jsonDataService: JsonDataService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    private formService: FormService,
    private securityService: SecurityService,
    public autocompleteService: AutocompleteService,
  ) { 
    this.filteredReasonOptions = this.autocompleteService.set(this.policyCancellationForm, "reason", this.getReasons()); // para sa cancellation ng policy
  }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.selectedRow) {
      return false
    }
    return true;
  }

  ngOnInit(): void {
    try {
      this.getPolicyCancellation();
    } catch(e) {
      console.log("Error in policy cancellation: ngOnInit");
    }
  }

  getPolicyCancellation(): void {
    try {
      this.policyCancellationTable.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.api.getCancellationPolicy({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE"
      }).subscribe((data: any) => {
        this.securityService.checkRequestKeyResponse(data, () => {
          if (data.status === "SUCCESS") {
              data = JSON.parse(this.jsonDataService.decrypt(data.content));
              this.policyCancellationTable.loading = false;
              this.jsonDataService.contorlLoading(false);
              this.policyCancellationTable.tblData = data.map((e: any) => {
                return {...e, effectiveDate: e.effectiveDate ? new Date(e.effectiveDate) : "",
                              expiryDate: e.expiryDate ? new Date(e.expiryDate) : "",
                              issueDate: e.issueDate ? new Date(e.issueDate) : ""}
              });
              if(this.savedFlag) {
                this.openSaveDialog();
                this.savedFlag = false;
              }
          } else {
              this.jsonDataService.contorlLoading(false);
              this.policyCancellationTable.loading = false;
              this.appMessageService.showAppMessage(data.message, "error");
          }
        });
      });
    } catch(e) {
      console.log("Error in policy cancellation: getPolicyCancellation");
    }
  }

  onRowClick(ev: any): void {
    try {
      this.selectedRow = ev;
      this.formLoader();
      if(ev) {
        this.cancelDisabler = false;
      } else {
        this.cancelDisabler = true;
      }
    } catch(e) {
      console.log("Error in policy cancellation: onRowClick");
    }
  }

  formLoader(): void {
    try {
      this.policyCancellationForm.patchValue({
        policyNo:         this.selectedRow?.policyNo,
        planName:         this.selectedRow?.planName,
        assuredLongName:  this.selectedRow?.assuredLongName,
        issueDate:        this.selectedRow?.issueDate,
        effectiveDate:    this.selectedRow?.effectiveDate,
        expiryDate:       this.selectedRow?.expiryDate,
        userId:           this.selectedRow?.userID,
        status:           this.selectedRow?.status,
        agentCode:        this.selectedRow?.agentCode,
        agentName:        this.selectedRow?.agentName,
        issueCode:        this.selectedRow?.issueCode,
        sourceCode:       this.selectedRow?.sourceCode,
        sourceExtCode:    this.selectedRow?.sourceExtensionCode,
        sourceExtName:    this.selectedRow?.sourceExtensionName,
        referrorCode:     this.selectedRow?.referrorCode,
        referrorName:     this.selectedRow?.referrorName,
        coverage:         this.selectedRow?.coverage,
        netPremium:       this.selectedRow?.netPremium,
        totalPremium:     this.selectedRow?.totalPremium,
      });
    } catch(e) {
      console.log(e);
      console.log("Error in policy cancellation: formLoader");
    }
  }

  //Para sa Cancellation ng Policy
  getReasons():any[] {
      let reasonOptions = [];
      for (let reason of this.jsonDataService.data.cancelReason) {
          reasonOptions.push({
              value: reason.reasonCd,
              text: reason.reasonDesc
          });
      }
      reasonOptions.sort((a:any, b:any) => a.text.localeCompare(b.text));
      return reasonOptions;
  }

  //Para sa Cancellation ng Policy
  setReasonViaInput(event:any):void {
      this.resetReason();
      for (const reason of this.getReasons()) {
          if (reason.text === this.policyCancellationForm.controls["reason"].value) {
              this.policyCancellationForm.controls["reason"].setValue(reason);
          }
      }
  }
  
  //Para sa Cancellation ng Policy
  resetReason():void {
      this.policyCancellationForm.controls["reason"].setValue("");
  }

  //Para sa Cancellation ng Policy
  cancelPolicy(form:any) {
    if (!form.reason?.value) {
        this.appMessageService.showAppMessage("Please pick a valid reason for your cancellation", "error");
    } else {
        this.proceedCancelPolicy(form);
    }
  }

  //Para sa Cancellation ng Policy
  proceedCancelPolicy(form: any) {
    this.jsonDataService.contorlLoading(true);
    this.api.proceedCancellation({
        reasonCd: parseInt(form.reason?.value),
        userId: this.userId,
        cancelApprovedBy: form.cancelApprovedBy,
        cancelRemarks: form.cancelRemarks,
        policyNo: form.policyNo
    }).subscribe((response:any) => {
        this.jsonDataService.contorlLoading(false);
        this.securityService.checkRequestKeyResponse(response, () => {
            if (response.status === "SUCCESS") {
                this.policyCancellationForm.markAsPristine();
                this.savedFlag = true;
                // this.openSaveDialog();
            } else {
                this.openErrorDialog("Policy cancellation Failed");
            }
            this.afterSave();
        });
    });
  }

  afterSave(): void {
    try {
      this.cancelDisabler = true;
      this.resetReason();
      this.policyCancellationForm.patchValue({
        policyNo:         '',
        planName:         '',
        assuredLongName:  '',
        issueDate:        '',
        effectiveDate:    '',
        expiryDate:       '',
        userId:           '',
        status:           '',
        agentCode:        '',
        agentName:        '',
        issueCode:        '',
        sourceCode:       '',
        sourceExtCode:    '',
        sourceExtName:    '',
        referrorCode:     '',
        referrorName:     '',
        coverage:         '',
        netPremium:       '',
        totalPremium:     '',
        reason:           '',
        cancelApprovedBy: '',
        cancelRemarks:    '',
      });
      this.selectedRow = null;
      this.getPolicyCancellation();
    } catch(e) {
      console.log(e);
      console.log("Error in policy cancellation: afterSave");
    }
  }

  openErrorDialog(message: string) {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'ERROR',
          content: message,
        }
      });
    } catch(e) {
      console.log("Error in policy cancellation: openErrorDialog");
    }
  }

  openSaveDialog() {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'SUCCESS',
          content: 'Your policy is now cancelled!',
        }
      });
    } catch(e) {
      console.log("Error in policy cancellation: openSaveDialog");
    }
  }
}
