import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { RefLovComponent } from '../../common/ref-lov/ref-lov.component';
import { SrcExtLovComponent } from '../../common/src-ext-lov/src-ext-lov.component';

@Component({
  selector: 'bfb-src-ext-maintenance',
  templateUrl: './bfb-src-ext-maintenance.component.html',
  styleUrls: ['./bfb-src-ext-maintenance.component.css']
})
export class BfbSrcExtMaintenanceComponent implements OnInit, LeaveConfirmation {

  private moduleId: string = 'BMM088';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc ?? "";
  datePipe = new DatePipe('en-ph');

  constructor(
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    public _formService: FormService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService
  ) {
    this.userIdLoggedIn = this._userDetailService.userId ?? "";
  }

  canDeactivate(): boolean | Observable<boolean> {
    return !(this.bfbSrcExtTblConfig.tblData.filter((row: any) => ['I', 'U'].includes(row.operation)).length > 0 || this.rowsToDel.length > 0);
  }

  public btns = {
    bfbSrcExt: {
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      addDisabled: true,
      referrorSearchDisabled: false,
      srcExtSearchDisabled: true,
    },
  };

  public showForm: boolean = true;

  //* Data Stuffs */
  private rowData: any;
  private rowsToDel: any[] = [];
  private errorMessage: string = "";
  private userIdLoggedIn: string;
  //public tempDataRow: boolean = false;
  public selectedRow!: any[];

  //* lambda data */
  private lambdaLimitData: number = 10000;
  private offsetPages: any[] = [];
  private tempDataFromGet: any[] = [];

  public forms = {
    bfbSrcExtForm: this.fb.group({
      referrorCd: [null, [Validators.required]],
      referrorBmCode: [null],
      srcExtCd: [null, [Validators.required]],
      srcExtBmCode: [null],
      referrorDesc: [null],
      srcExtDesc: [null],
      brCode: [null],
      referrorCdDisp: [{ value: '', disabled: 'true' }],
      srcExtCdDisp: [{ value: '', disabled: 'true' }],
      effectiveDate: [null, [Validators.required]],
      expiryDate: [null],
      active: ['A', [Validators.required]],
      remarks: [null, [Validators.maxLength(4000)]],
      lastUser: [{ value: '', disabled: 'true' }],
      lastUserUpdate: [{ value: '', disabled: 'true' }],
    }),
    resetBfbSrcExtForm: () => {
      this.forms.bfbSrcExtForm.reset();
      this.forms.bfbSrcExtForm.patchValue({
        active: 'A'
      });
      this.btns.bfbSrcExt.updateDisabled = this.btns.bfbSrcExt.deleteDisabled = true;
      this.btns.bfbSrcExt.srcExtSearchDisabled = true;
      this.btns.bfbSrcExt.referrorSearchDisabled = false;
      this._formService.hideFormMsg("bfb-src-ext-error-message");
    }
  };

  private bfbSrcExtFormManipulate = {
    dbToForm: (data: any) => {
      return {
        referrorCd: data.REFERROR_CD,
        referrorBmCode: data.REFERROR_BM_CODE,
        srcExtCd: data.SRC_EXT_CD,
        srcExtBmCode: data.SRC_EXT_BM_CODE,
        referrorDesc: data.REFERROR_DESC,
        srcExtDesc: data.SRC_EXT_DESC,
        brCode: data.BR_CODE,
        referrorCdDisp: `${data.REFERROR_BM_CODE} - ${data.REFERROR_DESC}`,
        srcExtCdDisp: `${data.SRC_EXT_BM_CODE} - ${data.SRC_EXT_DESC}`,
        effectiveDate: data.EFF_DATE,
        expiryDate: data.EXPIRY_DATE,
        lastUser: data.LAST_USER,
        lastUserUpdate: data.LAST_USER_UPDATE,
      };
    },
    formToDb: (data: any) => {
      return {
        REFERROR_CD: data.referrorCd?.toString().padStart(4, 0),
        REFERROR_BM_CODE: data.referrorBmCode, 
        SRC_EXT_CD: data.srcExtCd?.toString().padStart(3, 0),
        SRC_EXT_BM_CODE: data.srcExtBmCode,
        REFERROR_DESC: data.referrorDesc,
        SRC_EXT_DESC: data.srcExtDesc,
        BR_CODE: data.brCode,
        EFF_DATE: this.dateConvert(data.effectiveDate),
        EXPIRY_DATE: this.dateConvert(data.expiryDate),
        LAST_USER: null,
        LAST_USER_UPDATE: null,
      };
    },
  };

  private dateConvert(date: any) {
    return date ? this.datePipe.transform(date, 'yyyy-MM-dd') : null;
  }

  public bfbSrcExtTblConfig = {
    cols: [
      {
        key: "REFERROR_BM_CODE",
        header: "Referror Code",
        dataType: "string",
        width: "128px"
      },
      {
        key: "REFERROR_DESC",
        header: "Referror Name",
        dataType: "string",
      },
      {
        key: "SRC_EXT_BM_CODE",
        header: "Source Ext Code",
        dataType: "string",
      },
      {
        key: "SRC_EXT_DESC",
        header: "Source Extension",
        dataType: "string",
      },
      {
        key: "BR_CODE",
        header: "Branch Code",
        dataType: "string",
      },
      {
        key: "EFF_DATE",
        header: "Effective Date",
        dataType: "date",
        width: "96px"
      },
      {
        key: "EXPIRY_DATE",
        header: "Expiry Date",
        dataType: "date",
        width: "96px"
      },
    ],
    tblData: [] as any[],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  ngOnInit(): void {
    this.getData.bfbSrcExt();
  }

  private getData = {
    bfbSrcExt: () => {
      try {
        this.bfbSrcExtTblConfig.tblData = [];
        this.bfbSrcExtTblConfig.loading = true;
        this._jDS.contorlLoading(true);
        this._api.getBfbSrcExtPageCount({
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          type: "MODULE",
          limitNum: this.lambdaLimitData,
        }).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, ()=>{
                this._jDS.contorlLoading(false);
                let pages = JSON.parse(this._jDS.decrypt(data?.response));
                for (let i = 0; i < pages; i++) {
                  this.offsetPages = [...this.offsetPages, i * this.lambdaLimitData];
                }
                this.getData.bfbSrcExtAllData(this.offsetPages[0]);
              });
            });
          },
          error: (e: any) => {
            this._securityService.checkRequestKeyResponse(e, () => {
              this._securityService.hasValidCsrfToken(e, ()=>{
                this._jDS.contorlLoading(false);
                this.bfbSrcExtTblConfig.loading = false;
              });
            });
          }
        })
      }
      catch (e) {
    console.error(e);
      }
    },
    bfbSrcExtAllData: (offsetPage: number) => {
      this._jDS.contorlLoading(true);
      this._api.getBfbSrcExt({
        moduleId: this.moduleId,
        userId: this._userDetailService.userId,
        type: "MODULE",
        offSetNum: offsetPage,
        limitNum: this.lambdaLimitData,
      }).subscribe({
        next: (data: any) => {
          this._securityService.checkRequestKeyResponse(data, () => {
            this._securityService.hasValidCsrfToken(data, ()=>{
              this._jDS.contorlLoading(false);
              let content = JSON.parse(this._jDS.decrypt(data?.response));
              this.tempDataFromGet = [...this.tempDataFromGet, ...content.data];
              if (this.offsetPages[this.offsetPages.indexOf(offsetPage) + 1]) {
                this.getData.bfbSrcExtAllData(this.offsetPages[this.offsetPages.indexOf(offsetPage) + 1]);
              }
              else {
                this.bfbSrcExtTblConfig.tblData = [...this.tempDataFromGet];
                this.tempDataFromGet = [];
                this.btns.bfbSrcExt.addDisabled = false;
                this._jDS.contorlLoading(false);
                this.bfbSrcExtTblConfig.loading = false;
              }
            });
          });
        },
        error: (e: any) => {
          this._securityService.checkRequestKeyResponse(e, () => {
            this._securityService.hasValidCsrfToken(e, ()=>{
              this._jDS.contorlLoading(false);
            });
          });
        }
      });
    },
  };

  public openLov = {
    referror: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          col: 'BM',
          moduleId: this.moduleId
        }
      };
      this._matDialog.open(RefLovComponent, LOVOPTIONS).afterClosed().subscribe((lovData: any) => {
        if (lovData?.content?.REFERROR_CD) {
          const data = lovData?.content;
          this.forms.bfbSrcExtForm.patchValue({
            referrorCd: data.REFERROR_CD,
            referrorBmCode: data.BM_CODE,
            referrorDesc: data.REFERROR_DESC,
            referrorCdDisp: `${data.BM_CODE?.toString()} - ${data.REFERROR_DESC}`,
          });
          this.btns.bfbSrcExt.srcExtSearchDisabled = false;
        }
        else {
          this.forms.bfbSrcExtForm.patchValue({
            referrorCd: null,
            referrorBmCode: null,
            referrorDesc: null,
            referrorCdDisp: null,
          });
          this.btns.bfbSrcExt.srcExtSearchDisabled = true;
        }
      });
    },
    srcExt: () => {
      let srcExtsExist: any[] = this.bfbSrcExtTblConfig.tblData.filter((row: any) => +row.REFERROR_CD === +this.forms.bfbSrcExtForm.get('referrorCd')?.value).map((row: any) => { return row.SRC_EXT_CD; });
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          col: 'BM',
          srcExtsExclude: srcExtsExist,
          moduleId: this.moduleId
        }
      };
      this._matDialog.open(SrcExtLovComponent, LOVOPTIONS).afterClosed().subscribe((lovData: any) => {
        if (lovData?.content?.SRC_EXT_CD) {
          const data = lovData?.content;
          this.forms.bfbSrcExtForm.patchValue({
            srcExtCd: data.SRC_EXT_CD,
            srcExtBmCode: data.BM_CODE,
            srcExtDesc: data.SRC_EXT_DESC,
            brCode: data.BR_CODE,
            srcExtCdDisp: `${data.BM_CODE?.toString()} - ${data.SRC_EXT_DESC}`,
          });
        }
        else {
          this.forms.bfbSrcExtForm.patchValue({
            srcExtCd: null,
            srcExtBmCode: null,
            srcExtDesc: null,
            brCode: null,
            srcExtCdDisp: null,
          });
        }
      });
    }
  };

  public onRowClick(ev: any) {
    try {
      if (ev) {
        this.rowData = ev;
        this.populateFormFromTable();
      }
      else {
        this.rowData = null;
        this.forms.resetBfbSrcExtForm();
      }
    }
    catch (e) {
    console.error(e);
      this.forms.resetBfbSrcExtForm();
      this.rowData = null;
    }
  }

  private populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.bfbSrcExt.updateDisabled = this.btns.bfbSrcExt.deleteDisabled = false;
      this.btns.bfbSrcExt.srcExtSearchDisabled = this.btns.bfbSrcExt.referrorSearchDisabled = true;
      this.forms.bfbSrcExtForm.patchValue(this.bfbSrcExtFormManipulate.dbToForm(data));
    }
    catch (e) {
    console.error(e);
    }
  }

  public onAdd(): void {
    try {
      this._formService.hideFormMsg("bfb-src-ext-error-message");
      if (this.forms.bfbSrcExtForm.valid) {
        let rowToBeAdded: { [key: string]: any } = this.bfbSrcExtFormManipulate.formToDb(this.forms.bfbSrcExtForm.value);
        rowToBeAdded.operation = 'I';
        this.bfbSrcExtTblConfig.tblData = [rowToBeAdded, ...this.bfbSrcExtTblConfig.tblData];
        this.forms.resetBfbSrcExtForm();
        this.btns.bfbSrcExt.saveDisabled = false;
      }
      else {
        this.showErrorValidator.bfbSrcExt();
      }
    }
    catch (e) {
    console.error(e);
    }
  }

  public onUpdate(): void {
    try {
      this._formService.hideFormMsg("bfb-src-ext-error-message");
      if (this.forms.bfbSrcExtForm.valid) {
        const indexOfRow = this.bfbSrcExtTblConfig.tblData.indexOf(this.rowData);
        let rowToBeUpdated: { [key: string]: any } = this.bfbSrcExtFormManipulate.formToDb(this.forms.bfbSrcExtForm.value);
        rowToBeUpdated.operation = (this.rowData.operation === 'I') ? 'I' : 'U';
        this.forms.resetBfbSrcExtForm();
        this.selectedRow = [];
        this.bfbSrcExtTblConfig.tblData[indexOfRow] = { ...rowToBeUpdated };
        this.btns.bfbSrcExt.saveDisabled = false;
        this.bfbSrcExtTblConfig.tblData = [...this.bfbSrcExtTblConfig.tblData];
      }
      else {
        this.showErrorValidator.bfbSrcExt();
      }
    }
    catch (e) {
    console.error(e);
    }
  }

  public onDelete(): void {
    try {
      if (this.rowData.operation !== 'I') {
        this.rowsToDel = [...this.rowsToDel, this.rowData];
      }
      this.bfbSrcExtTblConfig.tblData = this.bfbSrcExtTblConfig.tblData.filter((row: any) => {
        return row !== this.rowData;
      });
      this.forms.resetBfbSrcExtForm();
      this.btns.bfbSrcExt.saveDisabled = false;
    }
    catch (e) {
    console.error(e);
    }
  }

  public onSave(): void {
    try {
      this.showForm = false;
      this._formService.showFormLoader(null, "bfb-src-ext-maintenance-loading", "Saving.<br>Please wait ...", null, null);
      let toBeSaved = this.bfbSrcExtTblConfig.tblData.filter((row: any) => ['I', 'U'].includes(row.operation));
      if (this.rowsToDel.length > 0) {
        let toBeDel = this.rowsToDel.map((row: any) => {
          row.operation = 'D'
          return row;
        });
        toBeSaved = [...toBeDel, ...toBeSaved];
      }
      if (toBeSaved.length > 0) {
        this._jDS.contorlLoading(true);
        this._api.saveBfbSrcExt(toBeSaved).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, ()=>{
                this._jDS.contorlLoading(true);
                if (data.status === 'SUCCESS') this.onComplete.bfbSrcExt();
                else this.onComplete.bfbSrcExtError();
              });
            });
          },
          error: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, ()=>{
                this.onComplete.bfbSrcExtError();
              });
            });
          }
        });
      }
      else {
        this.onComplete.bfbSrcExt();
      }
    }
    catch (e) {
    console.error(e);
    }
  }

  private onComplete = {
    bfbSrcExt: () => {
      this._jDS.contorlLoading(false);
      this._formService.hideFormLoader("bfb-src-ext-maintenance-loading");
      this.openDialog('SUCCESS', this.moduleName.replace('Maintenance', ''), 'saved');
      this.getData.bfbSrcExt();
      this.rowsToDel = [];
      this.btns.bfbSrcExt.saveDisabled = true;
      this.showForm = true;
    },
    bfbSrcExtError: () => {
      this._jDS.contorlLoading(false);
      this.openDialog('FAILED', this.moduleName.replace('Maintenance', ''), 'saving');
      this._formService.hideFormLoader("bfb-src-ext-maintenance-loading");
      this.showForm = true;
    },
  }

  openDialog(title: string, contentTitle: string, contentDetail: string) {
    try {
      this._matDialog.open(DialogMsgComponent, {
        disableClose: true,
        width: '512px',
        data: {
          title: title,
          content:
            title === 'SUCCESS'
              ? `${contentTitle} details successfully ${contentDetail}!`
              : `${contentTitle} details ${contentDetail} failed!`,
        },
      });
    }
    catch (e) {
    console.error(e);
    }
  }

  //* Validator Stuffs Down here */

  public showErrorValidator = {
    bfbSrcExt: () => {
      try {
        Object.keys(this.forms.bfbSrcExtForm.controls).forEach(key => {
          const controlErrors = this.forms.bfbSrcExtForm.get(key)?.errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              if (keyError == 'required' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
            });
          }
        });
        this._formService.showFormMsg("bfb-src-ext-error-message", this.errorMessage, "E");
      }
      catch (e) {
    console.error(e);
      }
    },
  }

  datePattern(ev: any) {
    try{
      //let inputChar = String.fromCharCode(event.charCode)
      if (ev.target.value.length<4) {
        let firstPattern = /^\d{2}\/$/;
        if(!ev.target.value.match(firstPattern)){
          ev.target.value = ev.target.value.substring(0,2);
        }
      } else if(ev.target.value.length<7){
        let firstPattern = /^\d{2}\/\d{2}\/$/;
        if(!ev.target.value.match(firstPattern)){
          ev.target.value = ev.target.value.substring(0,5);
        }
      } else if(ev.target.value.length>6&&ev.target.value.length<11){
        let firstPattern = /^\d{2}\/\d{2}\/\d+$/;
        if(!ev.target.value.match(firstPattern)){
          ev.target.value = ev.target.value.substring(0,ev.target.value.length-1);
        }
      } else if(ev.target.value.length==10){
        let firstPattern = /^\d{2}\/\d{2}\/\d{4}$/;
        if(!ev.target.value.match(firstPattern)){
          ev.target.value = ev.target.value.substring(0,ev.target.value.length-1);
        }
      } else{
        ev.target.value = ev.target.value.substring(0,ev.target.value.length-1);
      }
    }
    catch(e) {
    }
  }
}

