import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { CustomValidators } from 'src/app/utils/custom-validator';

@Component({
  selector: 'src-cd-maintenance',
  templateUrl: './src-cd-maintenance.component.html',
  styleUrls: ['./src-cd-maintenance.component.css']
})
export class SrcCdMaintenanceComponent implements OnInit, LeaveConfirmation {

  moduleId: string = 'BMM055';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc?.toUpperCase() ?? "";

  constructor(
    public _formService: FormService,
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    private _jDS: JsonDataService,
    private _securityService: SecurityService,
    private userDetailService: UserDetailsService,
    private _appMessageService: AppMessageService
  ) { }

  ngOnInit(): void {
    this.getData.srcCd();
  }

  canDeactivate(): boolean | Observable<boolean> {
    return this.srcCdTblConfig.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true)).length > 0 ? false : true;
  }

  public btns = {
    srcCd: {
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      addDisabled: true,
    },
  }

  public showForm: boolean = true;

  /* Data Stuffs */

  private rowData: any;
  private tempDataRow: any;
  private errorMessage: string = "";

  public forms = {
    srcCdForm: this.fb.group({
      srcCd: [null],
      srcCdDisp: [{ value: '', disabled: 'true' }],
      active: ['A', [Validators.required]],
      srcCdDesc: [null, [Validators.required, Validators.maxLength(100), this.checkDuplicateSrcDesc(), CustomValidators.requiredTrim]],
      bmCode: [null, [Validators.required, Validators.maxLength(8), CustomValidators.requiredTrim, this.checkDuplicateBmCode()]],
      bmDesc: [null, [Validators.maxLength(50)]],
      remarks: [null, [Validators.maxLength(4000)]],
      lastUpdateUser: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }],
    }),
    resetSrcCdForm: () => {
      this.forms.srcCdForm.reset();
      this.forms.srcCdForm.patchValue({
        active: 'A'
      });
      this.btns.srcCd.updateDisabled = this.btns.srcCd.deleteDisabled = true;
      this._formService.hideFormMsg("src-cd-error-message");
    }
  }

  private srcCdFormManipulate = {
    dbToForm: (data: any) => {
      return {
        srcCd: data.SRC_CD,
        srcCdDisp: data.SRC_CD ? data.SRC_CD.toString().padStart(3, 0) : null,
        srcCdDesc: data.SRC_CD_DESC,
        bmCode: data.BM_CODE,
        bmDesc: data.BM_SHORT_DESC,
        active: data.ACTIVE_TAG,
        remarks: data.REMARKS,
        lastUpdateUser: data.USER_ID,
        lastUpdate: data.LAST_UPDATE,
      };
    },
    formToDb: (data: any) => {
      return {
        SRC_CD: data.srcCd,
        SRC_CD_DESC: data.srcCdDesc.trim(),
        BM_CODE: data.bmCode,
        BM_SHORT_DESC: data.bmDesc,
        ACTIVE_TAG: data.active,
        REMARKS: data.remarks,
        USER_ID: null,
        LAST_UPDATE: null,
      };
    },
  }

  public selections = {
    activeTypes: [
      { cd: 'A', name: 'Yes' },
      { cd: 'I', name: 'No' },
    ],
  }

  public srcCdTblConfig = {
    cols: [
      {
        key: "SRC_CD",
        header: "Source Code",
        dataType: "string",
        width: '128px'
      },
      {
        key: "SRC_CD_DESC",
        header: "Description",
        dataType: "string"
      },
      {
        key: "BM_CODE",
        header: "BPIMS Code",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "Active",
        dataType: "checkbox",
        width: '64px'
      }
    ],
    tblData: [] as any[],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  private getData = {
    srcCd: () => {
      try {
        this.srcCdTblConfig.tblData = [];
        this.srcCdTblConfig.loading = true;
        this._jDS.contorlLoading(true);
        this._api.getAllSourceCode({
          moduleId: this.moduleId,
          userId: this.userDetailService.userId,
          type: "MODULE"
        }).subscribe({
          next: (response: any) => {
            this._securityService.checkRequestKeyResponse(response, () => {
              this._securityService.hasValidCsrfToken(response, () => {
                if (response.status === 'SUCCESS') {
                  let content = JSON.parse(this._jDS.decrypt(response.response));
                  this.srcCdTblConfig.tblData = content.data;
                  this.btns.srcCd.addDisabled = false;
                }
                else {
                  this._appMessageService.showAppMessage(response.message, "error");
                }
                this.srcCdTblConfig.loading = false;
                this._jDS.contorlLoading(false);
              });
            });
          },
          error: (error: any) => {
            this.srcCdTblConfig.loading = false;
            this._jDS.contorlLoading(false);
            this._appMessageService.showAppMessage(error.message, "error");
          }
        });
      }
      catch (e) {
    console.error(e);

      }
    },
  }

  public onRowClick(ev: any) {
    try {
      ev.update = true;
      this.rowData = ev;
      this.populateFormFromTable();
    }
    catch (e) {
    console.error(e);
      this.forms.resetSrcCdForm();
      this.rowData = null;
      //this.indexRow = null;
    }
  }

  private populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.srcCd.updateDisabled = !data.update;
      this.btns.srcCd.deleteDisabled = !data.temp;
      this.tempDataRow = !data.temp;
      this.forms.srcCdForm.patchValue(this.srcCdFormManipulate.dbToForm(data));
    }
    catch (e) {
    console.error(e);

    }
  }

  public onAdd() {
    try {
      this._formService.hideFormMsg("src-cd-error-message");
      if (this.forms.srcCdForm.valid) {
        let rowToBeAdded: { [key: string]: any } = this.srcCdFormManipulate.formToDb(this.forms.srcCdForm.value);
        rowToBeAdded.temp = true;
        this.srcCdTblConfig.tblData = [rowToBeAdded, ...this.srcCdTblConfig.tblData];
        this.forms.resetSrcCdForm();
        this.btns.srcCd.saveDisabled = false;
      }
      else {
        this.showErrorValidator.srcCd();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onDelete() {
    try {
      this.srcCdTblConfig.tblData = this.srcCdTblConfig.tblData.filter((d: any) => {
        return d !== this.rowData;
      });
      this.forms.resetSrcCdForm();
    }
    catch (e) {
    console.error(e);

    }
  }

  public onUpdate() {
    try {
      this._formService.hideFormMsg("src-cd-error-message");
      if (this.forms.srcCdForm.valid) {
        let toBeUpdatedIndex = this.srcCdTblConfig.tblData.indexOf(this.rowData);
        let srcCdRowValue = this.rowData.SRC_CD;
        let rowToBeUpdated: { [key: string]: any } = this.srcCdFormManipulate.formToDb(this.forms.srcCdForm.value);
        if (this.rowData.temp) {
          rowToBeUpdated.temp = true;
        }
        else {
          rowToBeUpdated.onDbButUpdatedTemp = true;
          rowToBeUpdated.SRC_CD = srcCdRowValue;
        }
        this.forms.resetSrcCdForm();
        this.srcCdTblConfig.tblData[toBeUpdatedIndex] = rowToBeUpdated;
        this.btns.srcCd.saveDisabled = false;
        this.srcCdTblConfig.tblData = [... this.srcCdTblConfig.tblData];
      }
      else {
        this.showErrorValidator.srcCd();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onSave() {
    try {
      this.showForm = false;
      this._formService.showFormLoader(null, "src-cd-maintenance-loading", "Saving.<br>Please wait ...", null, null, true);
      let toBeSaved = this.srcCdTblConfig.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true));
      if (toBeSaved.length > 0) {
        // toBeSaved.map((d: any) => {
        //   d.USER_ID = this.userIdLoggedIn;
        // });
        //? To Be Uncomment later if chronological is needed ?//
        // let toBeAdded = toBeSaved.filter((d: any) => d.temp).reverse();
        // let toBeUpdated = toBeSaved.filter((d: any) => d.onDbButUpdatedTemp);
        // toBeSaved = [...toBeAdded, ...toBeUpdated];
        this._jDS.contorlLoading(true);
        this._api.saveSourceCode(toBeSaved).subscribe({
          next: (response: any) => {
            this._securityService.checkRequestKeyResponse(response, () => {
              this._securityService.hasValidCsrfToken(response, () => {
                this._jDS.contorlLoading(false);
                if (response.status === 'SUCCESS') this.onComplete.srcCd();
                else this.onComplete.srcCdError();
              });
            });
          },
          error: (data: any) => {

            this._jDS.contorlLoading(false);
            this.onComplete.srcCdError();
          },
        });
      }
      else
        this.onComplete.srcCd();
      // setTimeout(() => {
      //   this.onComplete.srcCd();
      // }, 3000);
    }
    catch (e) {
    console.error(e);

    }
  }

  private onComplete = {
    srcCd: () => {
      this.getData.srcCd();
      this.btns.srcCd.saveDisabled = true;
      this.showForm = true;
      this._formService.hideFormLoader("src-cd-maintenance-loading");
      this.openDialog('SUCCESS', 'Source Code', 'saved');
    },
    srcCdError: () => {
      this.showForm = true;
      this.btns.srcCd.saveDisabled = false;
      this.openDialog('FAILED', 'Source Code', 'saving');
      this._formService.hideFormLoader("src-cd-maintenance-loading");
    },
  }

  openDialog(title: string, contentTitle: string, contentDetail: string) {
    try {
      this._matDialog.open(DialogMsgComponent, {
        disableClose: true,
        width: '512px',
        data: {
          title: title,
          content:
            title === 'SUCCESS'
              ? `${contentTitle} details successfully ${contentDetail}!`
              : `${contentTitle} details ${contentDetail} failed!`,
        },
      });
    }
    catch (e) {
    console.error(e);

    }
  }

  /* Validator Stuffs Down here */

  checkDuplicateSrcDesc(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.trim()?.toUpperCase();
      if (!value) return null;
      let hasDuplicate: boolean = this.srcCdTblConfig.tblData.filter((d: any) => {
        if (!this.rowData && this.btns.srcCd.updateDisabled)
          return d.SRC_CD_DESC.toUpperCase() === value;
        else
          return this.rowData.SRC_CD_DESC.trim().toUpperCase() !== value && d.SRC_CD_DESC.trim().toUpperCase() === value;
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupeSrcDescSrcDesc: true } : null;
    }
  }

  checkDuplicateBmCode(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.trim()?.toUpperCase();
      if (!value) return null;
      let hasDuplicate: boolean = this.srcCdTblConfig.tblData.filter((d: any) => {
        const trimLeadingZeros = (str: string) => str?.replace(/^0+/, '');
        if (!this.rowData && this.btns.srcCd.updateDisabled)
          return trimLeadingZeros(d.BM_CODE)?.toUpperCase() === trimLeadingZeros(value);
        else
          return this.rowData.BM_CODE?.trim().toUpperCase() !== value && trimLeadingZeros(d.BM_CODE)?.trim().toUpperCase() === trimLeadingZeros(value);
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupeBmCode: true } : null;
    }
  }

  public showErrorValidator = {
    srcCd: () => {
      try {
        Object.keys(this.forms.srcCdForm.controls).forEach(key => {
          const controlErrors = this.forms.srcCdForm.get(key)?.errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              if (keyError == 'hasDupeSrcDesc' && controlErrors[keyError] === true)
                this.errorMessage = "Source Code Description already exists. Please add a unique Source Code Description.";
              else if (keyError == 'hasDupeBmCode' && controlErrors[keyError] === true)
              this.errorMessage = "BPIMS Code already exists. Please add a unique Source Code Description.";
              else if (keyError == 'required' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
            });
          }
        });
        this._formService.showFormMsg("src-cd-error-message", this.errorMessage, "E");
      }
      catch (e) {
    console.error(e);

      }
    },
  }
}
