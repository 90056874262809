import { Component, Inject, OnInit } from '@angular/core';
import { APICallService } from 'src/app/services/api-call.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { DialogMsgComponent } from 'src/app/components/common/dialog-msg/dialog-msg.component';
import { FormService } from 'src/app/services/form.service';
import { forkJoin } from 'rxjs';
import { JsonDataService } from 'src/app/services/json-data.service';
import { CheckLovComponent } from 'src/app/components/common/guard/check-lov/check-lov.component';
import { SecurityService } from 'src/app/services/security.service';

@Component({
  selector: 'sys-usrs-mtn-user-access',
  templateUrl: './sys-usrs-mtn-user-access.component.html',
  styleUrls: ['../system-users-maintentance.component.css']
})
export class SysUsrsMtnUserAccessComponent implements OnInit {

  constructor(
    private _api: APICallService,
    private _matDialog: MatDialog,
    private fb: FormBuilder,
    private formService: FormService,
    private _jDS: JsonDataService,
    private securityService: SecurityService,
    private _dialogRef : MatDialogRef<SysUsrsMtnUserAccessComponent>,
    @Inject(MAT_DIALOG_DATA) public fromMainData: any
  ) { 
    this.userIdLoggedIn = this._jDS.retrieveFromStorage('userId') || "";
  }

  ngOnInit(): void {
    this.MdlsTblConfig.visible = false;
    this.getModuleGrp();
  }

  private userIdLoggedIn: string;

  private userId = this.fromMainData.userId;

  private rowDataMdlGrp: any;
  private rowDataMdls: any;

  public showForm: boolean = true;

  private mdlsTempData: any = [];
  private mdlGrpTempData: any = [];

  public mdlGrpForm = this.fb.group({
    userId: [null],
    moduleGrp: [null],
    groupDesc: [{ value: null, disabled: true }],
    allAccess: ['N'],
  });

  public mdlsForm = this.fb.group({
    userId: [null],
    moduleId: [{ value: null, disabled: true }],
    moduleGrp: [null],
    moduleName: [{ value: null, disabled: true }],
    access: ['N'],
  });

  private dbToFormMdlGrp(data: any) {
    this.mdlGrpForm.get('groupDesc')?.disable();
    return {
      userId: data.USER_ID,
      moduleGrp: data.MODULE_GRP,
      groupDesc: data.RV_MEANING,
      allAccess: data.ALL_ACCESS_TAG
    }
  }

  private dbToFormMdls(data: any) {
    this.mdlsForm.get('moduleId')?.disable();
    this.mdlsForm.get('moduleName')?.disable();
    return {
      userId: data.USER_ID,
      moduleId: data.MODULE_ID,
      moduleGrp: data.MODULE_GRP,
      moduleName: data.MODULE_DESC,
      access: data.ACCESS,
    }
  }

  private formToDbMdlGrp(data: any) {
    return {
      USER_ID: this.userId,
      MODULE_GRP: data.moduleGrp,
      RV_MEANING: data.groupDesc,
      RV_LOW_VALUE: data.moduleGrp,
      ALL_ACCESS_TAG: data.allAccess
    }
  }

  private formToDbMdls(data: any) {
    return {
      USER_ID: this.userId,
      MODULE_ID: data.moduleId,
      MODULE_GRP: data.moduleGrp,
      MODULE_DESC: data.moduleName,
      ACCESS: data.access
    }
  }

  MdlGrpTblConfig: any = {
    cols: [
      {
        key: "RV_MEANING",
        header: "Group Description",
        dataType: "string"
      },
      {
        key: "ALL_ACCESS_TAG",
        header: "All Access",
        dataType: "checkbox",
        width: '128px',
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 5,
    lazy: false,
    totalRecords: 15,
    tblCaption: "Module Group",
    loading: false,
  }

  MdlsTblConfig: any = {
    cols: [
      {
        key: "MODULE_ID",
        header: "Module ID",
        dataType: "string",
        width: '96px',
      },
      {
        key: "MODULE_DESC",
        header: "Module Name",
        dataType: "string"
      },
      {
        key: "ACCESS",
        header: "Access",
        dataType: "checkbox",
        width: '72px',
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 5,
    lazy: false,
    totalRecords: 15,
    tblCaption: "Modules",
    visible: false,
    loading: false,
  }

  public accessTypes = [
    { cd: 'N', name: 'No' },
    { cd: 'Y', name: 'Yes' },
  ];

  private getModuleGrp() {
    this.MdlGrpTblConfig.loading = true;
    this._api.getUserAccessMtnModuleGrp({
      moduleId: 'BMM078',
      userId: this.userId,
      type: "LOV"
  }).subscribe({
      next: (data: any) => {
            this.securityService.checkRequestKeyResponse(data, () => {
                
                let d = JSON.parse(this._jDS.decrypt(data.content));
                d.map((f: any) => f.ALL_ACCESS_TAG = f.USER_ID !== null ? f.ALL_ACCESS_TAG : 'N');
                
                this.MdlGrpTblConfig.tblData = d;
                this.MdlGrpTblConfig.loading = false;
            });
      },
      error: (e: any) => {
        console.error(e);
        this.MdlGrpTblConfig.loading = false;
      },
    });
  }

  private populateFormFromTableMdlGrp() {
    try {
      let data = this.rowDataMdlGrp;
      this.mdlGrpForm.patchValue(this.dbToFormMdlGrp(data));
    }
    catch (e) {
    console.error(e);
      console.log("Error @ populateFormFromTableMdlGrp() user-access-mtn");
    }
  }

  private getModules(mdlGrp: any) {
    let params = {
      userId: this.userId,
      moduleGrp: mdlGrp,
      type: "MODULE",
      moduleId:  'BMM078',
    }
    this.MdlsTblConfig.loading = true;
    this._jDS.contorlLoading(true);
    this._api.getUserAccessMtnModules(params).subscribe({
      next: (data: any) => {
            this.securityService.checkRequestKeyResponse(data, () => {
                
                let d = JSON.parse(this._jDS.decrypt(data.content));
                d.map((f: any) => f.ACCESS = f.USER_ID !== null ? 'Y' : 'N');
                d.forEach((e: any) => {
                let tempRow = this.mdlsTempData.filter((c: any) => e.MODULE_ID === c.MODULE_ID);
                if (tempRow.length > 0)
                    e.ACCESS = tempRow[0].ACCESS;
                });
                this.MdlsTblConfig.tblData = d;
                this.MdlsTblConfig.loading = false;
                this._jDS.contorlLoading(false);
            });
      },
      error: (e: any) => {
        console.error(e);
        this.MdlsTblConfig.loading = false;
        this._jDS.contorlLoading(false);
      }
    });
  }

  private populateFormFromTableMdls() {
    try {
      let data = this.rowDataMdls;
      this.mdlsForm.patchValue(this.dbToFormMdls(data));
    }
    catch (e) {
    console.error(e);
      console.log("Error @ populateFormFromTableMdls() user-access-mtn");
    }
  }

  onRowClickMdlGrp(ev: any) {
    
    try {
      if (ev.temp === null || ev.temp === undefined || ev.onDbButUpdatedTemp === true) {
        this.MdlsTblConfig.visible = true;
        this.rowDataMdlGrp = ev;
        this.populateFormFromTableMdlGrp();
        this.MdlsTblConfig.tblData = [];
        this.getModules(ev.RV_LOW_VALUE);
      }
    }
    catch {
      
      this.MdlsTblConfig.visible = false;
      this.MdlsTblConfig.tblData = [];
      this.formResetMdlGrp();
    }
  }

  onRowClickMdls(ev: any) {
    try {
      if (ev.temp === null || ev.temp === undefined || ev.onDbButUpdatedTemp === true) {
        this.rowDataMdls = ev;
        this.populateFormFromTableMdls();
      }
    }
    catch {
      
      this.formResetMdls();
    }
  }

  private formResetMdls() {
    this.mdlsForm.reset({
      access: ['N']
    });
  }

  private formResetMdlGrp() {
    this.mdlGrpForm.reset({
      allAccess: ['N']
    });
  }

  onUpdateMdlGrp() {
    
    try {
      let rowToBeUpdate: { [key: string]: any } = this.rowDataMdlGrp;
      let indexOfRowToBeUpdated = this.MdlGrpTblConfig.tblData.indexOf(rowToBeUpdate);
      this.mdlGrpForm.patchValue({
        moduleGrp: rowToBeUpdate.RV_LOW_VALUE
      });
      let newRow: { [key: string]: any } = this.formToDbMdlGrp(this.mdlGrpForm.value);
      newRow.RV_MEANING = rowToBeUpdate.RV_MEANING;
      this.MdlGrpTblConfig.tblData[indexOfRowToBeUpdated] = newRow;
      if (this.mdlGrpTempData) {
        let indexOfTempData = this.mdlGrpTempData.findIndex((d: any) => d.MODULE_GRP === newRow.MODULE_GRP);
        if (indexOfTempData === -1) {
          this.mdlGrpTempData = [...this.mdlGrpTempData, newRow];
        }
        else {
          this.mdlGrpTempData[indexOfTempData] = newRow;
        }
      }
      else
        this.mdlGrpTempData = [...this.mdlGrpTempData, newRow];
      
      this.MdlsTblConfig.visible = false;
      this.formResetMdlGrp();
      this.MdlGrpTblConfig.tblData = [...this.MdlGrpTblConfig.tblData];
    }
    catch (e) {
    console.error(e);
      console.log('Error @ onUpdateMdlGrp() user-access-mtn');
    }
  }

  onUpdateMdls() {
    
    try {
      let rowToBeUpdate: { [key: string]: any } = this.rowDataMdls;
      let indexOfRowToBeUpdated = this.MdlsTblConfig.tblData.indexOf(rowToBeUpdate);
      let newRow: { [key: string]: any } = this.formToDbMdls(this.mdlsForm.value);
      newRow.MODULE_ID = rowToBeUpdate.MODULE_ID;
      newRow.MODULE_DESC = rowToBeUpdate.MODULE_DESC;
      this.MdlsTblConfig.tblData[indexOfRowToBeUpdated] = newRow;
      if (this.mdlsTempData) {
        let indexOfTempData = this.mdlsTempData.findIndex((d: any) => d.MODULE_ID === newRow.MODULE_ID);
        if (indexOfTempData === -1)
          this.mdlsTempData = [...this.mdlsTempData, newRow];
        else
          this.mdlsTempData[indexOfTempData] = newRow;
      }
      else
        this.mdlsTempData = [...this.mdlsTempData, newRow];
      
      this.formResetMdls();
      this.MdlsTblConfig.tblData = [...this.MdlsTblConfig.tblData];
    }
    catch (e) {
    console.error(e);
      console.log('Error @ onUpdateMdls() user-access-mtn');
    }
  }

  onSave() {
    try {
        this.showForm = false;
        this.formService.showFormLoader(null, "user-access-mtn-loading", "Saving.<br>Please wait ...", null, null);
        
        
        let toBeSaved = {
            mdlGrp: this.mdlGrpTempData,
            mdls: this.mdlsTempData
        }
        if (toBeSaved.mdlGrp.length > 0) {
            toBeSaved.mdlGrp.map((d: any) => {
                d.LAST_USER = this.userIdLoggedIn;
            });
        }
        if (toBeSaved.mdls.length > 0) {
            toBeSaved.mdls.map((d: any) => {
                d.LAST_USER = this.userIdLoggedIn;
            });
        }
        this._api.saveUserAccessMtnMdlGrp(toBeSaved.mdlGrp).subscribe({
            next: (data: any) => {
                this.securityService.checkRequestKeyResponse(data, () => {
                    if(data.status == 'SUCCESS') {
                        this._api.saveUserAccessMtnMdls(toBeSaved.mdls).subscribe({
                            next: (res: any) => {
                                this.securityService.checkRequestKeyResponse(res, () => {
                                    if(res.status == 'SUCCESS') {
                                        this.onComplete();
                                    } else {
                                        this.onCompleteError();
                                    }
                                });
                            },
                            error: () => {
                                this.onCompleteError();
                            }
                        });
                    } else {
                        this.onCompleteError();
                    }
                });
            },
            error: () => {
                this.onCompleteError();
            }
        });
      
      
      /*forkJoin({
        mdlGrpApi: this._api.saveUserAccessMtnMdlGrp(toBeSaved.mdlGrp),
        mdlsApi: this._api.saveUserAccessMtnMdls(toBeSaved.mdls)
      }).subscribe({
        next: (data: any) => {
            this.securityService.checkRequestKeyResponse(data, () => {
                
                if (data.mdlGrpApi.status === 'SUCCESS' && data.mdlsApi.status === 'SUCCESS') {
                    this.onComplete();
                } else {
                    this.onCompleteError();
                }
            });
        },
        error: (data: any) => {
          console.error(data);
          this.onCompleteError();
        },
      });*/
    }
    catch (e) {
    console.error(e);
      console.log("Error @ onSave() user-access-mtn");
    }
  }

  onComplete() {
    try {
      this.getModuleGrp();
      
      this.showForm = true;
      this.mdlGrpTempData = [];
      this.mdlsTempData = [];
      this.formService.hideFormLoader("user-access-mtn-loading");
      this.openDialog('SUCCESS', 'saved');
    }
    catch (e) {
    console.error(e);
      console.log("Error @ onComplete() user-access-mtn");
    }
  }

  onCompleteError() {
    
    try {
      this.showForm = true;
      this.formService.hideFormLoader("user-access-mtn-loading");
      this.openDialog('FAILED', 'saving');
    }
    catch (e) {
    console.error(e);
      console.log("Error @ onCompleteError() user-access-mtn");
    }
  }

  openDialog(title: string, contentDetail: string) {
    try {
      this._matDialog.open(DialogMsgComponent, {
        disableClose: true,
        width: '512px',
        data: {
          title: title,
          content:
            title === 'SUCCESS'
              ? `System User details successfully ${contentDetail}!`
              : `System User details ${contentDetail} failed!`,
        },
      });
    }
    catch (e) {
    console.error(e);
      console.log("Error @ openDialog() user-access-mtn");
    }
  }

  onClose(){
    if (this.mdlGrpTempData.length > 0 || this.mdlsTempData.length > 0){
      this._matDialog.open(CheckLovComponent, {
        width: '512px',
      }).afterClosed().subscribe((dOut: any) => {
        if (dOut) {
          this._dialogRef.close();
        }
      });
    }
    else
      this._dialogRef.close();
  }

}
