<section id="mv-prem-type">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <osp-table [tblConfig]="mvPremTypeTable" (onRowClick)="onRowClick($event)" [selectedRow]="selectedRow"></osp-table>

        <!-- <div id="mv-prem-type-form"><div class="form-loader"></div></div> -->

        <div class="form-content" [hidden]="formHider">

            <form [formGroup]="mvPremTypeForm" class="form">
                <div id="mv-prem-type-error-message"></div>
                <div class="form-container-grid">
                    
                    <div class="field">
                        <label>MV Premium Type Code</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="MV_PREM_TYPE">
                    </div>

                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="ACTIVE_TAG">
                            <option value="A">Yes</option>
                            <option value="I">No</option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>MV Premium Type</label>
                    </div>
                    <div class="">
                        <input formControlName="MV_PREM_TYPE_DESC">
                    </div>

                    <div class="field required">
                        <label>No. of Years</label>
                    </div>
                    <div class="">
                        <input type="number" formControlName="NO_OF_YEARS">
                    </div>

                    <div class="field required">
                        <label>Currency Code</label>
                    </div>
                    <div class="">
                        <input formControlName="CURRENCY_CD" maxlength="3">
                    </div>

                    <div class="field required">
                        <label>Gross Amount</label>
                    </div>
                    <div class="">
                        <input formControlName="GROSS_AMT" [digits]="10" [decimals]="2" decimal>
                    </div>

                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="REMARKS" maxlength="4000">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input formControlName="LAST_USER">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="LAST_USER_UPDATE">
                    </div>
                </div>
            </form>

            <div class="btn-grp">
                <button type="button" class="btn btn2" [hidden]="!addUpdateFlag" (click)="addRow()">ADD</button>
                <button type="button" class="btn btn2" [hidden]="addUpdateFlag" (click)="onUpdate()">UPDATE</button>
                <button type="button" class="btn btn2" [disabled]="deleteDisabler" (click)="deleteRow()">DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-grp">
                <button type="button" class="btn btn2" [disabled]="saveDisabler" (click)="onSave()">SAVE</button>
            </div>
        </div>
    </div>
</section>