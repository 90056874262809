import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { MatDialog } from '@angular/material/dialog';

import { GenerateVoucherDialogComponent } from './generate-voucher-dialog/generate-voucher-dialog.component';
import { DeactivateVoucherDialogComponent } from './deactivate-voucher-dialog/deactivate-voucher-dialog.component';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';
import { PlanLovComponent } from '../../common/plan-lov/plan-lov.component';
import { ClientGroupLovDialog } from '../../common/client-group-lov-dialog/client-group-lov-dialog.component';
import { DownloadVoucherDialogComponent } from './download-voucher-dialog/download-voucher-dialog.component';
import { UploadVoucherDialogComponent } from './upload-voucher-dialog/upload-voucher-dialog.component';

import { APICallService } from 'src/app/services/api-call.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { DialogBoxService } from 'src/app/services/dialog-box.service';
import { FormService } from 'src/app/services/form.service';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';

@Component({
    templateUrl: './voucher-code-maintenance.component.html',
    styleUrls: ['./voucher-code-maintenance.component.css']
})
export class VoucherCodeMaintenanceComponent implements OnInit, LeaveConfirmation {

    public moduleName: string = "";
    public moduleId: string = 'BMM158';

    private datePipe = new DatePipe('en-us');

    public btns = {
        lineSearchDisabled: false,
        sublineSearchDisabled: true,
        planSearchDisabled: true,
        clientTypeSearchDisabled: false,
    }

    public tblConfig: any = {
        cols: [
            {
                key: "BATCH_NO",
                header: "Batch No",
                dataType: "string",
                width: "9%"
            }, {
                key: "VOUCHER_CODE",
                header: "Voucher Code",
                dataType: "string",
                width: "12%"
            }, {
                key: "CLIENT_TYPE_GROUP",
                header: "Client Type Group",
                dataType: "string",
                width: "19%"
            }, {
                key: "EFFECTIVE_DATE",
                header: "Effective Date",
                dataType: "date",
                width: "10%"
            }, {
                key: "EXPIRY_DATE",
                header: "Expiry Date",
                dataType: "date",
                width: "10%"
            }, {
                key: "USED_BY",
                header: "Used By",
                dataType: "string",
                width: "10%"
            }, {
                key: "USED_DATE",
                header: "Used Date",
                dataType: "date",
                width: "10%"
            }, {
                key: "EMAIL_ADDRESS",
                header: "Email Address",
                dataType: "string",
                width: "16%"
            }, {
                key: "ACTIVE_TAG",
                header: "A",
                dataType: "checkbox",
                width: "4%"
            }
        ],
        tblData: [],
        selection: "single",
        paginator: true,
        rowsPerPage: 10,
        lazy: false,
        totalRecords: 15,
        loading: false
    };

    public voucherCodeFilterForm = this.formBuilder.group({
        lineCd: [""],
        lineDisplay: [""],
        sublineCd: [""],
        sublineDisplay: [""],
        planCd: [""],
        planDisplay: [""],
        clientTypeGroup: [""],
        clientTypeGroupDisplay: [""]
    });

    public voucherCodeForm = this.formBuilder.group({
        batchNo: [""],
        voucherCode: [""],
        active: [""],
        clientTypeGroup: [""],
        withPayment: [""],
        paymentMethod: [""],
        effectiveDate: [""],
        expiryDate: [""],
        usedBy: [""],
        usedDate: [""],
        projectCode: [""],
        agent: [""],
        bma: [""],
        referrer: [""],
        sourceCode: [""],
        sourceExtension: [""],
        remarks: [""],
        createUser: [""],
        createDate: [""],
        lastUpdateUser: [""],
        lastUpdate: [""]
    });

    public canDeactivate(): boolean | Observable<boolean> {
        return !this.voucherEmailForm.get('email')?.dirty;
    }

    public voucherEmailForm = this.formBuilder.group({
        voucherCode: [""],
        email: ["", [Validators.email]],
    });

    private selectedLine: any;
    private selectedSubline: any;
    private selectedPlan: any;
    private selectedClientTypeGroup: any;

    constructor(
        private formBuilder: FormBuilder,
        private matDialog: MatDialog,
        private apiCallService: APICallService,
        private jsonDataService: JsonDataService,
        private securityService: SecurityService,
        private userDetailService: UserDetailsService,
        private dialogBoxService: DialogBoxService,
        private formService: FormService,
    ) {
        this.moduleName = this.jsonDataService.data.moduleList.find((a: any) =>
            a.moduleId === "BMM158"
        )?.moduleDesc?.toUpperCase() ?? "";
    }

    public ngOnInit(): void {
        this.getVoucherCodes();
    }

    private resetFormThenGetVouchers(): void {
        this.getVoucherCodes();
        this.onVoucherCodeFormRowClick(null);
    }

    public openLovDialog(selected: string): void {
        if (selected === "line") {
            const LOVOPTIONS: { [key: string]: any } = {
                disableClose: true,
                width: '512px',
                data: {
                    table: 'LINE',
                    moduleId: this.moduleId
                },
            };
            this.matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
                let output = dataOut.content;
                if (dataOut.button === 'OK') {
                    if (output.LINE_CD) {
                        this.voucherCodeFilterForm.patchValue({
                            lineCd: output.LINE_CD,
                            lineDisplay: `${output.LINE_CD} - ${output.LINE_NAME}`,
                            sublineCd: null,
                            sublineDisplay: null,
                            planCd: null,
                            planDisplay: null,
                        });
                        this.btns.sublineSearchDisabled = false;
                    }
                    else {
                        this.voucherCodeFilterForm.patchValue({
                            lineCd: null,
                            lineDisplay: null,
                            sublineCd: null,
                            sublineDisplay: null,
                            planCd: null,
                            planDisplay: null,
                        });
                        this.btns.sublineSearchDisabled = true;
                        this.btns.planSearchDisabled = true;
                    }
                    this.resetFormThenGetVouchers();
                }
            });
        } else if (selected === "subline") {
            const LOVOPTIONS: { [key: string]: any } = {
                disableClose: true,
                width: '512px',
                data: {
                    table: 'SUBLINE',
                    moduleId: this.moduleId,
                    lineCd: this.voucherCodeFilterForm.get('lineCd')?.value
                },
            };
            this.matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
                let output = dataOut.content;
                if (dataOut.button === 'OK') {
                    if (output.SUBLINE_CD) {
                        this.voucherCodeFilterForm.patchValue({
                            sublineCd: output.SUBLINE_CD,
                            sublineDisplay: `${output.SUBLINE_CD} - ${output.SUBLINE_NAME}`,
                            planCd: null,
                            planDisplay: null,
                        });
                        this.btns.planSearchDisabled = false;
                    }
                    else {
                        this.voucherCodeFilterForm.patchValue({
                            sublineCd: null,
                            sublineDisplay: null,
                            planCd: null,
                            planDisplay: null,
                        });
                        this.btns.planSearchDisabled = true;
                    }
                    this.resetFormThenGetVouchers();
                }
            });
        } else if (selected === "plan") {
            const LOVOPTIONS: { [key: string]: any } = {
                disableClose: true,
                width: '512px',
                data: {
                    table: 'PLAN',
                    moduleId: this.moduleId,
                    lineCd: this.voucherCodeFilterForm.get('lineCd')?.value,
                    sublineCd: this.voucherCodeFilterForm.get('sublineCd')?.value,
                    lpad: 2,
                },
            };
            this.matDialog.open(PlanLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
                let output = dataOut.content;
                if (dataOut.button?.toUpperCase() === 'OK') {
                    if (output.PLAN_CD) {
                        this.voucherCodeFilterForm.patchValue({
                            planCd: output.PLAN_CD,
                            planDisplay: `${output.PLAN_CD} - ${output.PLAN_NAME}`,
                        });
                    }
                    else {
                        this.voucherCodeFilterForm.patchValue({
                            planCd: null,
                            planDisplay: null,
                        });
                    }
                    this.resetFormThenGetVouchers();
                }
            });
        } else if (selected === "clientTypeGroup") {
            const LOVOPTIONS: { [key: string]: any } = {
                disableClose: true,
                width: '512px',
                data: {
                    table: 'CLIENT_GROUP',
                    moduleId: this.moduleId,
                },
            };
            this.matDialog.open(ClientGroupLovDialog, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
                let output = dataOut.content;
                if (dataOut.option?.toUpperCase() !== "BACK") {
                    if (output.CLIENT_CD) {
                        this.voucherCodeFilterForm.patchValue({
                            clientTypeGroup: output.CLIENT_CD,
                            clientTypeGroupDisplay: `${output.CLIENT_CD} - ${output.CLIENT_DESC}`,
                        });
                    }
                    else {
                        this.voucherCodeFilterForm.patchValue({
                            clientTypeGroup: null,
                            clientTypeGroupDisplay: null,
                        });
                    }
                    this.resetFormThenGetVouchers();
                }
            });
        }
    }

    public onVoucherCodeFormRowClick(event: any): void {
        this.voucherCodeForm.controls["batchNo"].setValue(event ? event.BATCH_NO : "");
        this.voucherCodeForm.controls["voucherCode"].setValue(event ? event.VOUCHER_CODE : "");
        this.voucherCodeForm.controls["active"].setValue(event ? (event.ACTIVE_TAG === "A" ? "Yes" : "No") : "");
        this.voucherCodeForm.controls["clientTypeGroup"].setValue(event ? event.CLIENT_TYPE_GROUP : "");
        this.voucherCodeForm.controls["withPayment"].setValue(event ? (event.WITH_PAYMENT === "Y" ? "Yes" : "No") : "");
        this.voucherCodeForm.controls["paymentMethod"].setValue(event ? event.PAYMENT_METHOD : "");
        this.voucherCodeForm.controls["effectiveDate"].setValue(event ? (event.EFFECTIVE_DATE ? this.datePipe.transform(new Date(event.EFFECTIVE_DATE), 'MM/dd/yyyy') : "") : "");
        this.voucherCodeForm.controls["expiryDate"].setValue(event ? (event.EXPIRY_DATE ? this.datePipe.transform(new Date(event.EXPIRY_DATE), 'MM/dd/yyyy') : "") : "");
        this.voucherCodeForm.controls["usedBy"].setValue(event ? event.USED_BY : "");
        this.voucherCodeForm.controls["usedDate"].setValue(event ? (event.USED_DATE ? this.datePipe.transform(new Date(event.USED_DATE), 'MM/dd/yyyy') : "") : "");
        this.voucherCodeForm.controls["projectCode"].setValue(event ? event.PROJECT_CODE : "");
        this.voucherCodeForm.controls["agent"].setValue(event ? event.AGENT : "");
        this.voucherCodeForm.controls["bma"].setValue(event ? event.BMA : "");
        this.voucherCodeForm.controls["referrer"].setValue(event ? event.REFERRER : "");
        this.voucherCodeForm.controls["sourceCode"].setValue(event ? event.SOURCE_CODE : "");
        this.voucherCodeForm.controls["sourceExtension"].setValue(event ? event.SOURCE_EXTENSION : "");
        this.voucherCodeForm.controls["remarks"].setValue(event ? event.REMARKS : "");
        this.voucherCodeForm.controls["createUser"].setValue(event ? event.CREATE_USER : "");
        this.voucherCodeForm.controls["createDate"].setValue(event ? event.CREATE_DATE : "");
        this.voucherCodeForm.controls["lastUpdateUser"].setValue(event ? event.LAST_UPDATE_USER : "");
        this.voucherCodeForm.controls["lastUpdate"].setValue(event ? event.LAST_UPDATE : "");
        this.voucherEmailForm.controls["voucherCode"].setValue(event ? event.VOUCHER_CODE : "");
        this.voucherEmailForm.controls["email"].setValue(event ? event.EMAIL_ADDRESS : "");
        event?.USED_TAG === 'N' ? this.voucherEmailForm.controls["email"].enable() : this.voucherEmailForm.controls["email"].disable();
        this.voucherEmailForm.get('email')?.markAsPristine();
    }

    public disableSave(): boolean {
        return !this.voucherEmailForm.controls["voucherCode"]?.value || this.voucherEmailForm.controls["email"].disabled
    }

    public onGenerate(): void {
        this.matDialog.open(GenerateVoucherDialogComponent, {
            disableClose: true,
            width: "450px",
            maxHeight: "90vh",
            data: {
                voucherCodeFilterForm: { ...this.voucherCodeFilterForm.getRawValue() }
            }
        }).afterClosed().subscribe((dataOut: any) => {
            if (dataOut?.refreshTable) {
                this.resetFormThenGetVouchers();
            }
        });
    }

    public onDeactivate(): void {
        this.matDialog.open(DeactivateVoucherDialogComponent, {
            disableClose: true,
            width: "450px",
            maxHeight: "90vh",
            data: {
                voucherCodeFilterForm: { ...this.voucherCodeFilterForm.getRawValue() }
            }
        }).afterClosed().subscribe((dataOut: any) => {
            if (dataOut?.refreshTable) {
                this.resetFormThenGetVouchers();
            }
        });
    }

    public onDownload(): void {
        this.matDialog.open(DownloadVoucherDialogComponent, {
            disableClose: true,
            width: "450px",
            maxHeight: "90vh",
            data: {
                voucherCodeFilterForm: { ...this.voucherCodeFilterForm.getRawValue() }
            }
        });
    }

    public onUpload(): void {
        this.matDialog.open(UploadVoucherDialogComponent, {
            disableClose: true,
            width: "900px",
            maxHeight: "90vh",
            data: {}
        }).afterClosed().subscribe((dataOut: any) => {
            if (dataOut?.refreshTable) {
                this.resetFormThenGetVouchers();
            }
        });
    }

    public onSave(): void {
        this.formService.hideFormMsg("save-email-voucher-code-error-message");
        if (this.voucherEmailForm.valid) {
            let toBeSaved = [{
                VOUCHER_CODE: this.voucherEmailForm.controls["voucherCode"].value,
                EMAIL_ADDRESS: this.voucherEmailForm.controls["email"].value,
            }];
            this.jsonDataService.contorlLoading(true);
            this.apiCallService.saveVouchers(toBeSaved).subscribe({
                next: (response: any) => {
                    this.securityService.checkRequestKeyResponse(response, () => {
                        this.securityService.hasValidCsrfToken(response, () => {
                            if (response.status === "SUCCESS") {
                                let content = JSON.parse(this.jsonDataService.decrypt(response.response)).result;
                                this.jsonDataService.contorlLoading(false);
                                if (content.success) {
                                    this.dialogBoxService.open({
                                        messageType: "S",
                                        content: `Email saved successfully.`,
                                        buttons: [{
                                            text: "OK",
                                            action: () => {
                                                this.resetFormThenGetVouchers();
                                            }
                                        }]
                                    }, true);
                                    this.voucherEmailForm.get('email')?.markAsPristine();
                                }
                                else {
                                    this.dialogBoxService.open({
                                        messageType: "E",
                                        content: `Saving email failed.`,
                                        buttons: [{
                                            text: "OK",
                                            action: () => {
                                                this.resetFormThenGetVouchers();
                                            }
                                        }]
                                    }, true);
                                }
                            }
                            else {
                                this.jsonDataService.contorlLoading(false);
                            }
                        });
                    });
                },
                error: (data: any) => {
                    this.securityService.checkRequestKeyResponse(data, () => {
                        this.jsonDataService.contorlLoading(false);
                    });
                }
            });
        }
        else {
            this.formService.showFormMsg("save-email-voucher-code-error-message", "Wrong email address pattern.", "E");
            setTimeout(() => {
                this.formService.hideFormMsg("save-email-voucher-code-error-message");
            }, 5000);
        }
    }

    private getVoucherCodes(): void {
        this.tblConfig.loading = true;
        this.tblConfig.tblData = [];
        if (this.voucherCodeFilterForm.controls["lineCd"].value
            && this.voucherCodeFilterForm.controls["sublineCd"].value
            && this.voucherCodeFilterForm.controls["planCd"].value) {
            this.jsonDataService.contorlLoading(true);
            this.apiCallService.getVoucherCodes({
                lineCd: this.voucherCodeFilterForm.controls["lineCd"].value,
                sublineCd: this.voucherCodeFilterForm.controls["sublineCd"].value,
                planCd: this.voucherCodeFilterForm.controls["planCd"].value,
                clientTypeGroup: this.voucherCodeFilterForm.controls["clientTypeGroup"].value,
                moduleId: this.moduleId,
                userId: this.userDetailService.userId,
                type: "MODULE",
            }).subscribe({
                next: (response: any) => {
                    this.securityService.checkRequestKeyResponse(response, () => {
                        this.securityService.hasValidCsrfToken(response, () => {
                            if (response.status === "SUCCESS") {
                                let content = JSON.parse(this.jsonDataService.decrypt(response.response));
                                this.tblConfig.tblData = content.data;
                                this.tblConfig.loading = false;
                                this.jsonDataService.contorlLoading(false);
                            }
                            else {
                                this.jsonDataService.contorlLoading(false);
                            }
                        });
                    });
                },
                error: (data: any) => {
                    this.securityService.checkRequestKeyResponse(data, () => {
                        this.tblConfig.loading = false;
                        this.jsonDataService.contorlLoading(false);
                    });
                }
            });
        }
        else {
            this.tblConfig.loading = false;
        }
    }

}


