<section id="relationship">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <osp-table [tblConfig]="relationshipTable" (onRowClick)="onRowClick($event)"></osp-table>

        <!-- <div id="relationship-form"><div class="form-loader"></div></div> -->

        <div class="form-content" [hidden]="formHider">

            <form [formGroup]="relationshipForm" class="form">
                <div id="relationship-error-message"></div>
                <div class="form-container-grid">
                    
                    <div class="field">
                        <label>Relationship Code</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="RELATIONSHIP_CD">
                    </div>

                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="ACTIVE_TAG">
                            <option value="A">Yes</option>
                            <option value="I">No</option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>Relationship</label>
                    </div>
                    <div class="">
                        <input formControlName="RELATIONSHIP_DESC" maxlength="50" uppercase>
                    </div>

                    <div class="field required">
                        <label>Primary/Secondary</label>
                    </div>
                    <div class="">
                        <select formControlName="PS_TAG">
                            <option value=""></option>
                            <option value="P">Primary</option>
                            <option value="S">Secondary</option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>Multiple Selection</label>
                    </div>
                    <div class="">
                        <select formControlName="MULTIPLE_TAG">
                            <option value=""></option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>BPIMS Code</label>
                    </div>
                    <div class="">
                        <input formControlName="BM_CODE" maxlength="8">
                    </div>

                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="REMARKS" maxlength="4000">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input formControlName="USER_ID">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="LAST_UPDATE">
                    </div>
                </div>
            </form>

            <div class="btn-grp">
                <button type="button" class="btn btn2" [hidden]="!addUpdateFlag" (click)="addRow()">ADD</button>
                <button type="button" class="btn btn2" [hidden]="addUpdateFlag" (click)="onUpdate()">UPDATE</button>
                <button type="button" class="btn btn2" [disabled]="deleteDisabler" (click)="deleteRow()">DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-grp">
                <button type="button" class="btn btn2" [disabled]="saveDisabler" (click)="onSave()">SAVE</button>
            </div>
        </div>
    </div>
</section>