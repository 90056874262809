<section id='faq-subline-maint'>
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <div class="form-content">
            <form [formGroup]="paramForm" class="form">
                <div class="form-container-grid top-form">
                    <div class="field required">
                        <label>Line</label>
                    </div>
                    <div class="lov-search">
                        <input readonly formControlName="lineField">
                        <button matSuffix class="btn-search btn" type="button" (click)="checkOpenLineDialog()"><mat-icon>search</mat-icon></button>
                    </div>
                    <div class="field required">
                        <label>Subline</label>
                    </div>
                    <div class="lov-search">
                        <input readonly formControlName="sublineField">
                        <button matSuffix class="btn-search btn" type="button" (click)="checkOpenSublineDialog()"><mat-icon>search</mat-icon></button>
                    </div>
                </div>
            </form>
        </div>
        <!-- Container for FAQ Subline Table using prime ng -->
        <div class = 'table-faq-subline'>
            <osp-table [tblConfig]="tblConfigFAQSubline" (onRowClick)="onRowClick($event)" [selectedRow]="rowClickedData"></osp-table>
        </div>
        <!-- Loading Animation -->
        <!-- <div id="generate-faq-subline-form">
            <div class="form-loader"></div>
        </div> -->
        
        <!-- Container for FAQ Subline Fields -->
        <div class="form-content">
            
            <form [formGroup]="faqSublineForm" class="form" *ngIf="noLoadingInProcess">
                <div id="faq-subline-error-message"></div>
                <div class="form-container-grid">
                    <div class="field">
                        <label>Question No.</label>
                    </div>
                    <div class="">
                        <input formControlName="questionNum" type="text" readonly>
                    </div>
                    <div>
                    </div>
                    <div>
                    </div>

                    <div class="field">
                        <label>Sequence No.</label>
                    </div>
                    <div class="">
                        <input formControlName="sortSeq" type="number" min="0" step="1" posNumInput>
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag">
                            <option value="A" selected>Yes</option>
                            <option value="I">No</option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>Question</label>
                    </div>
                    <div class="span">
                        <textarea formControlName="question"></textarea>
                    </div>

                    <div class="field required">
                        <label>Answer</label>
                    </div>
                    <div class="span">
                        <textarea formControlName="answer"></textarea>
                    </div>

                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" type="text">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="userID">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUserUpdate">
                    </div>

                </div>
                <div class='btn-row'>
                    <button type="button" id='btn-add' *ngIf="!isFAQSelected" title='Register a subline FAQ' class="btn btn2" value='ADD' type="submit" [disabled]='addUpdDisabled' (click)='insertFAQSubline()'>ADD</button>
                    <button type="button" id='btn-update' *ngIf="isFAQSelected" title='Update a subline FAQ' class="btn btn2" value='UPDATE' type="submit" [disabled]='addUpdDisabled' (click)='updateFAQSubline()'>UPDATE</button>
                    <button type="button" id='btn-delete' title='Remove a subline FAQ' class="btn btn2" value='DELETE' type="submit" [disabled]='deleteDisabled' (click)='deleteFAQSubline()'>DELETE</button>
                </div> 

                <div class="mtn-title"></div>

                <div class='btn-row'>
                    <button type="button" id='btn-save' title='Save changes on FAQ Subline' class="btn btn2" value='SAVE' type="submit" [disabled]='saveDisabled' (click)='toSave()'>SAVE</button>
                </div>
            </form>
        </div>  
    </div>
</section>