<section id="upload-fmv">
    <div class="mtn-title">
        <p>FMV UPLOAD</p>
    </div>
    <form [formGroup]="files">
        <div id="upload-fmv-form">
            <div class="form-loader"></div>
            <div class="form-fields">
                <osp-table [tblConfig]="dataTableConfig"></osp-table>
                <div class="form">
                    <div class="col-case">
                        <div class="field required">
                            <label for="">File Name</label>
                            <input #excelFile type='file' class='xlfile required' id='fileUpload' formControlName="file" (change)="fileHandlerFunction($event)" [attr.disabled]="!addButtonState ? '' : null">
                        </div>
                    </div>
                </div>
            </div>
            <div id="uploadFMVFormMsg"></div>
        </div>
        <div class="form-btns">
            <div class="form-btns">
                <button mat-button class="btn btn2" type="button" [disabled]="!addButtonState" (click)="validateFile()">Add</button>
                <button mat-button class="btn btn2" type="button" [disabled]="!saveButtonState" (click)="proceedUpload()">Save</button>
                <button mat-button class="btn btn2" type="button" *ngIf="!showAllButtonState" [disabled]="!showErrorButtonState" (click)="getErrorFMVData()">Error Logs</button>
                <button mat-button class="btn btn2" type="button" *ngIf="showAllButtonState" [disabled]="!showErrorButtonState" (click)="getAllFMVData()">Show All</button>
            </div>
            <div class="form-btns">
                <button mat-button class="btn btn2" type="button" mat-dialog-close>Back</button>
            </div>
        </div>
    </form>
</section>
