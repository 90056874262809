import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { FireItemCodeLovComponent } from '../../common/fire-item-code-lov/fire-item-code-lov.component';
import { CheckLovComponent } from '../../common/guard/check-lov/check-lov.component';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';
import { PerilLovComponent } from '../../common/peril-lov/peril-lov.component';
import { PlanLovComponent } from '../../common/plan-lov/plan-lov.component';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'applicable-item-code-per-plan-maintenance',
  templateUrl: './applicable-item-code-per-plan-maintenance.component.html',
  styleUrls: ['./applicable-item-code-per-plan-maintenance.component.css'],
  providers: [DecimalPipe],
})
export class ApplicableItemCodePerPlanMaintenanceComponent implements OnInit {

  addUpdateFlag1: boolean = true;
  addUpdateFlag2: boolean = true;
  datePipe = new DatePipe('en-us');
  selectedRow1: any = [];
  selectedRow2: any = [];
  tempNewData1: any = [];
  tempNewData2: any = [];
  tempUpdateData1: any = [];
  tempUpdateData2: any = [];
  userId: string = this.userDetailService.userId;
  errorMessage1: string = '';
  errorMessage2: string = '';
  saveDisabler1: boolean = true;
  saveDisabler2: boolean = true;
  deleteDisabler1: boolean = true;
  deleteDisabler2: boolean = true;
  openItemCodeDisabler: boolean = true;
  sublineDisabler: boolean = true;
  planDisabler: boolean = true;
  formHider: boolean = false;
  moduleId: string = 'BMM108';
  moduleName = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId === 'BMM108' })[0]?.moduleDesc.toUpperCase();
  retrievedData: any;
  perilDisabler: boolean = true;
  columnGrp = this.jsonDataService.data.refCds.filter((e: any) => e.rvDomain === 'COLUMN_GRP');
  
  table1: any = {
    cols: [
      {
        key: "ITEM_CD",
        header: "Item Code",
        dataType: "string"
      },
      {
        key: "ITEM_LONG_DESC",
        header: "Item Description",
        dataType: "string"
      },
      {
        key: "ITEM_LABEL",
        header: "Item Label",
        dataType: "string"
      },
      {
        key: "ITEM_CD_DTL",
        header: "Item Code Detail",
        dataType: "string"
      },
      {
        key: "SEQ_NO",
        header: "Sequence No..",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }
  
  table2: any = {
    cols: [
      {
        key: "PERIL_CD",
        header: "Peril Code",
        dataType: "string"
      },
      {
        key: "PERIL_LNAME",
        header: "Peril Name",
        dataType: "string"
      },
      {
        key: "PERIL_DESC",
        header: "Peril Description",
        dataType: "string"
      },
      {
        key: "DEFAULT_RATE",
        header: "Default Rate",
        dataType: "rateDecimal"
      },
      {
        key: "DEFAULT_TSI",
        header: "Default Amount Covered",
        dataType: "currency"
      },
      {
        key: "MULTIPLIER_RATE",
        header: "Multiplier Rate",
        dataType: "rateDecimal"
      },
      {
        key: "SEQ_NO",
        header: "Sequence No.",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  paramForm = this.fb.group({
    LINE_CD         : ['', [Validators.required]],
    LINE_NAME       : [''],
    SUBLINE_CD      : ['', [Validators.required]],
    SUBLINE_NAME    : [''],
    PLAN_CD         : ['', [Validators.required]],
    PLAN_NAME       : ['']
  });

  form1 = this.fb.group({
    LINE_CD         : [''],
    LINE_NAME       : [''],
    SUBLINE_CD      : [''],
    SUBLINE_NAME    : [''],
    PLAN_CD         : [''],
    PLAN_NAME       : [''],
    ITEM_CD         : ['', [Validators.required]],
    ITEM_LONG_DESC  : [''],
    ACTIVE_TAG      : ['A'],
    ITEM_LABEL      : [''],
    ITEM_CD_DTL     : [''],
    COLUMN_GRP      : [''],
    SEQ_NO          : [''],
    REMARKS         : [''],
    USER_ID         : [{value: '', disabled: true}],
    LAST_UPDATE     : [{value: '', disabled: true}],
  });

  form2 = this.fb.group({
    LINE_CD         : [''],
    LINE_NAME       : [''],
    SUBLINE_CD      : [''],
    SUBLINE_NAME    : [''],
    PLAN_CD         : [''],
    PLAN_NAME       : [''],
    ITEM_CD         : [''],
    PERIL_CD        : [{value: '', disabled: true}, [Validators.required]],
    PERIL_LNAME     : [{value: '', disabled: true}],
    PERIL_DESC      : [{value: '', disabled: true}],
    DEFAULT_RATE    : [{value: '', disabled: true}, [this.checkDecimalsExceeded(3, 9, 'Default Rate')]],
    DEFAULT_TSI     : [{value: '', disabled: true}, [this.checkDecimalsExceeded(14, 2, 'Default Amount Covered')]],
    FMV_SW          : [{value: '', disabled: true}],
    RATING_TBL_SW   : [{value: '', disabled: true}],
    MULTIPLIER_RATE : [{value: '', disabled: true}, [this.checkDecimalsExceeded(3, 9, 'Multiplier Rate')]],
    SEQ_NO          : [{value: '', disabled: true}],
    ACTIVE_TAG      : [{value: 'A', disabled: true}, [Validators.required]],
    HL_TAG          : [{value: '', disabled: true}],
    REMARKS         : [{value: '', disabled: true}],
    BM_CODE         : [{value: '', disabled: true}],
    USER_ID         : [{value: '', disabled: true}],
    LAST_UPDATE     : [{value: '', disabled: true}],
  });

  constructor(
    private fb: FormBuilder,
    private api: APICallService,
    private dialog: MatDialog,
    private jsonDataService: JsonDataService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    private formService: FormService,
    private securityService: SecurityService,
    private decimalPipe: DecimalPipe,
  ) { }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempNewData1!='' || this.tempUpdateData1!='' || this.tempNewData2!='' || this.tempUpdateData2!='' ) {
      return false;
    } else {
      return true;
    }
  }

  private checkDecimalsExceeded(digits: number, decimals: number, field: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value && parseFloat(this.formService.setToNumberWithDecimal(control.value));
      if (!value) return null;
      let isExceeded: boolean = value > +"".padEnd(digits, '9') + +('.' + ''.padEnd(decimals, '9'))
      return isExceeded ? { hasDecimalsExceeded: "Invalid value in " + field + ". The value entered exceeded the maximum limit." } : null;
    }
  }

  resetValues(): void {
    try {
      this.tempNewData1 = [];
      this.tempNewData2 = [];
      this.tempUpdateData1 = [];
      this.tempUpdateData2 = [];
      this.table1.tblData = [];
      this.table2.tblData = [];
      this.selectedRow1 = [];
      this.selectedRow2 = [];
      this.addUpdateFlag1 = true;
      this.saveDisabler1 = true;
      this.addUpdateFlag2 = true;
      this.saveDisabler2 = true;
    } catch(e) {

    }
  }

  // checkOpenLineDialog() {
  //   try {
  //     if(this.tempNewData1!='' || this.tempUpdateData1!='') {
  //       this.dialog.open(CheckLovComponent, {
  //         disableClose: true,
  //         width: '512px',
  //         data: {
  //             title: "Confirmation Message",
  //             content:
  //                 "You have unsaved changes. Do you want to continue?"
  //         }
  //       }).afterClosed().subscribe((a:any) => {
  //           if (a) {
  //             this.resetValues();
  //             this.openLineDialog();
  //           }
  //       });
  //     } else {
  //       this.openLineDialog();
  //     }
  //   } catch(e) {
      
  //   }
  // }

  openLineDialog() {
    try {
      this.dialog.open(LineLovDialogComponent, {
        width: '512px',
        disableClose: true,
        data: {
          table: 'LINE',
          userId: this.userDetailService.userId,
          moduleId: this.moduleId
        }
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null && data.button === "OK") {
          this.resetValues();
          this.formClearer1();
          this.formClearer2();
          this.perilFieldDisabler();
          this.sublineDisabler = false;
          this.planDisabler = true;
          this.paramForm.patchValue({
            LINE_CD     : data.content.LINE_CD + " - " + data.content.LINE_NAME,
            SUBLINE_CD  : "",
            PLAN_CD     : ""
          });
          this.paramForm.get("LINE_CD")?.setValue(data.content.LINE_CD, {emitModelToViewChange: false});
          this.table1.tblData = [];
          this.table2.tblData = [];
        }
      });
    } catch(e) {
      
    }
  }

  // checkOpenSublineDialog() {
  //   try {
  //     if(this.tempNewData1!='' || this.tempUpdateData1!='') {
  //       this.dialog.open(CheckLovComponent, {
  //         disableClose: true,
  //         width: '512px',
  //         data: {
  //             title: "Confirmation Message",
  //             content:
  //                 "You have unsaved changes. Do you want to continue?"
  //         }
  //       }).afterClosed().subscribe((a:any) => {
  //           if (a) {
  //             this.resetValues();
  //             this.openSublineDialog();
  //           }
  //       });
  //     } else {
  //       this.openSublineDialog();
  //     }
  //   } catch(e) {
      
  //   }
  // }

  openSublineDialog() {
    try {
      this.dialog.open(LineLovDialogComponent, {
        width: '512px',
        disableClose: true,
        data: {
          table: 'SUBLINE',
          lineCd: this.paramForm.get('LINE_CD')?.value,
          userId: this.userDetailService.userId,
          moduleId: this.moduleId
        }
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null && data.button === "OK") {
          this.resetValues();
          this.formClearer1();
          this.formClearer2();
          this.perilFieldDisabler();
          this.planDisabler = false;
          this.paramForm.patchValue({
            SUBLINE_CD  : data.content.SUBLINE_CD + " - " + data.content.SUBLINE_NAME,
            PLAN_CD     : ""
          });
          this.paramForm.get("SUBLINE_CD")?.setValue(data.content.SUBLINE_CD, {emitModelToViewChange: false});
          this.table1.tblData = [];
          this.table2.tblData = [];
        }
      });
    } catch(e) {
      
    }
  }

  // checkOpenPlanDialog() {
  //   try {
  //     if(this.tempNewData1!='' || this.tempUpdateData1!='') {
  //       this.dialog.open(CheckLovComponent, {
  //         disableClose: true,
  //         width: '512px',
  //         data: {
  //             title: "Confirmation Message",
  //             content:
  //                 "You have unsaved changes. Do you want to continue?"
  //         }
  //       }).afterClosed().subscribe((a:any) => {
  //           if (a) {
  //             this.resetValues();
  //             this.openPlanDialog();
  //             this.openItemCodeDisabler = false;
  //           }
  //       });
  //     } else {
  //       this.openPlanDialog();
  //     }
  //   } catch(e) {
      
  //   }
  // }

  openPlanDialog() {
    try {
      this.dialog.open(PlanLovComponent, {
        width: '512px',
        disableClose: true,
        data: {
          table: 'PLAN',
          lineCd: this.paramForm.get('LINE_CD')?.value,
          sublineCd: this.paramForm.get('SUBLINE_CD')?.value,
          userId: this.userDetailService.userId,
          moduleId: this.moduleId
        }
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.resetValues();
          this.formClearer1();
          this.formClearer2();
          this.perilFieldDisabler();
          this.paramForm.get("PLAN_CD")?.setValue(data.content.PLAN_CD + " - " + data.content.PLAN_NAME);
          this.paramForm.get("PLAN_CD")?.setValue(data.content.PLAN_CD, {emitModelToViewChange: false});
          this.getApplicableItemCodePerPlan();
          this.openItemCodeDisabler = false;
        }
      });
    } catch(e) {
      
    }
  }

  openItemCode(): void {
    try {
      if(this.paramForm.get("LINE_CD")?.value &&
         this.paramForm.get("SUBLINE_CD")?.value &&
         this.paramForm.get("PLAN_CD")?.value &&
         this.selectedRow1.length == 0
      ) {
        const excludedItemCds = this.table1.tblData.map((e: any) => {
          return e.ITEM_CD
        });
        this.dialog.open(FireItemCodeLovComponent, {
          width: '512px',
          disableClose: true,
          data: {
            lineCd: this.paramForm.get('LINE_CD')?.value,
            sublineCd: this.paramForm.get('SUBLINE_CD')?.value,
            planCd: this.paramForm.get("PLAN_CD")?.value,
            userId: this.userDetailService.userId,
            moduleId: this.moduleId,
            itemCdExclude: excludedItemCds
          }
        }).afterClosed().subscribe(data => {
          if (data.content != '' && data.content != null && data.button === "OK") {
            this.form1.patchValue({
              ITEM_CD         : data.content.ITEM_CD + " - " + data.content.ITEM_LONG_DESC,
              ITEM_LONG_DESC  : data.content.ITEM_LONG_DESC,
            });
            this.form1.get("ITEM_CD")?.setValue(data.content.ITEM_CD, {emitModelToViewChange: false});
          } else {
            this.form1.patchValue({
              ITEM_CD         : "",
              ITEM_LONG_DESC  : "",
            });
          }
        });
      }
    } catch(e) {

    }
  }

  openPerilCode(): void {
    try {
      if(this.paramForm.get("LINE_CD")?.value &&
         this.paramForm.get("SUBLINE_CD")?.value &&
         this.paramForm.get("PLAN_CD")?.value &&
         this.form1.get("ITEM_CD")?.value &&
         this.selectedRow2.length == 0
      ) {
        const excludedPerilCds = this.table2.tblData.map((e: any) => {
          return +e.PERIL_CD
        });
        this.dialog.open(PerilLovComponent, {
          width: '512px',
          disableClose: true,
          data: {
            lineCd: this.paramForm.get('LINE_CD')?.value,
            sublineCd: this.paramForm.get('SUBLINE_CD')?.value,
            planCd: this.paramForm.get("PLAN_CD")?.value,
            userId: this.userDetailService.userId,
            moduleId: this.moduleId,
            perilCdExclude: excludedPerilCds
          }
        }).afterClosed().subscribe(data => {
          if (data.content != '' && data.content != null && data.button === "OK") {
            this.form2.patchValue({
              PERIL_CD     : data.content.PERIL_CD_DISP + " - " + data.content.PERIL_LNAME,
              PERIL_LNAME  : data.content.PERIL_LNAME,
            });
            this.form2.get("PERIL_CD")?.setValue(data.content.PERIL_CD_DISP, {emitModelToViewChange: false});
          } else {
            this.form2.patchValue({
              PERIL_CD     : "",
              PERIL_LNAME  : "",
            });
          }
        });
      }
    } catch(e) {

    }
  }

  ngOnInit(): void {

  }

  getApplicableItemCodePerPlan(): void {
    try {
      const paramFormValues = this.paramForm.getRawValue();
      this.table1.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.api.getApplicableItemCodePerPlan({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE"
      }).subscribe((data: any) => {
        this.securityService.checkRequestKeyResponse(data, () => {
          this.securityService.hasValidCsrfToken(data, () => {
            if (data.status === "SUCCESS") {
              data = JSON.parse(this.jsonDataService.decrypt(data.response));
              this.table1.loading = false;
              this.jsonDataService.contorlLoading(false);
              this.retrievedData = data;
              this.table1.tblData = data.fireItem.filter((e: any) => {
                return e.LINE_CD === paramFormValues?.LINE_CD && e.SUBLINE_CD === paramFormValues?.SUBLINE_CD && e.PLAN_CD === +paramFormValues?.PLAN_CD
              });
              this.table1.tblData = [
                ...this.table1.tblData.filter((e: any) => e.ACTIVE_TAG === 'A'),
                ...this.table1.tblData.filter((e: any) => e.ACTIVE_TAG === 'I')
              ];
              this.table2Loader();
            } else {
                this.jsonDataService.contorlLoading(false);
                this.table1.loading = false;
                this.appMessageService.showAppMessage(data.message, "error");
            }
          });
        });
      });
    } catch(e) {

    }
  }

  table2Loader(): any {
    try {
      const selectedFireItemCode = this.selectedRow1;
      this.table2.tblData = this.retrievedData.peril
                            .filter((e: any) => e.LINE_CD === selectedFireItemCode.LINE_CD &&
                                                e.SUBLINE_CD === selectedFireItemCode.SUBLINE_CD &&
                                                e.PLAN_CD === selectedFireItemCode.PLAN_CD &&
                                                e.ITEM_CD === selectedFireItemCode.ITEM_CD);
                            // .map((e: any) => {
                            //   return {
                            //     ...e,
                            //     DEFAULT_RATE: e.DEFAULT_RATE,
                            //     DEFAULT_TSI: e.DEFAULT_TSI,
                            //     MULTIPLIER_RATE: e.MULTIPLIER_RATE,
                            //   }
                            //   return {
                            //     ...e,
                            //     DEFAULT_RATE: !isNaN(parseFloat(e.DEFAULT_RATE)) ? parseFloat(e.DEFAULT_RATE).toFixed(9) : null,
                            //     DEFAULT_TSI: !isNaN(parseFloat(e.DEFAULT_TSI)) ? parseFloat(e.DEFAULT_TSI).toFixed(2) : null,
                            //     MULTIPLIER_RATE: !isNaN(parseFloat(e.MULTIPLIER_RATE)) ? parseFloat(e.MULTIPLIER_RATE).toFixed(9) : null,
                            //   }
                            // });
      
      this.table2.tblData = [
        ...this.table2.tblData.filter((e: any) => e.ACTIVE_TAG === 'A'),
        ...this.table2.tblData.filter((e: any) => e.ACTIVE_TAG === 'I')
      ];
    } catch(e) {

    }
  }

  onRowClick1(ev: any): void {
    try {
      if(ev!=null) {
        this.selectedRow1 = ev;
        this.selectedRow2 = [];
        this.addUpdateFlag1 = false;
        this.addUpdateFlag2 = true;
        this.formLoader1();
        this.formClearer2();
      } else {
        this.selectedRow1 = [];
        this.addUpdateFlag1 = true;
        this.perilResetter();
        this.formClearer1();
      }
      this.deleteEnabler1();
      if(this.deleteDisabler1 == true && ev!=null) {
        this.table2Loader();
        this.perilEnabler();
      } else {
        this.perilResetter();
        this.perilFieldDisabler();
      }
    } catch(e) {
      
    }
  }

  onRowClick2(ev: any): void {
    try {
      if(ev!=null) {
        this.selectedRow2 = ev;
        this.addUpdateFlag2 = false;
        this.formLoader2();
      } else {
        this.selectedRow2 = [];
        this.addUpdateFlag2 = true;
        this.formClearer2();
      }
      this.deleteEnabler2();
    } catch(e) {

    }
  }

  formLoader1() {
    try {
      this.form1.patchValue({
        LINE_CD         : this.selectedRow1.LINE_CD,
        LINE_NAME       : this.selectedRow1.LINE_NAME,
        SUBLINE_CD      : this.selectedRow1.SUBLINE_CD,
        SUBLINE_NAME    : this.selectedRow1.SUBLINE_NAME,
        PLAN_CD         : this.selectedRow1.PLAN_CD,
        PLAN_NAME       : this.selectedRow1.PLAN_NAME,
        ITEM_CD         : this.selectedRow1.ITEM_CD + " - " + this.selectedRow1.ITEM_LONG_DESC,
        ITEM_LONG_DESC  : this.selectedRow1.ITEM_LONG_DESC  ,
        ACTIVE_TAG      : this.selectedRow1.ACTIVE_TAG      ,
        ITEM_LABEL      : this.selectedRow1.ITEM_LABEL      ,
        ITEM_CD_DTL     : this.selectedRow1.ITEM_CD_DTL     ,
        COLUMN_GRP      : this.selectedRow1.COLUMN_GRP      ,
        SEQ_NO          : this.selectedRow1.SEQ_NO          ,
        REMARKS         : this.selectedRow1.REMARKS         ,
        USER_ID         : this.selectedRow1.USER_ID         ,
        LAST_UPDATE     : this.selectedRow1.LAST_UPDATE     ,
      });
      this.form1.get("ITEM_CD")?.setValue(this.selectedRow1.ITEM_CD, {emitModelToViewChange: false});
    } catch(e) {
      
    }
  }

  formLoader2() {
    try {
      this.form2.patchValue({
        LINE_CD         : this.selectedRow2.LINE_CD,
        LINE_NAME       : this.selectedRow2.LINE_NAME,
        SUBLINE_CD      : this.selectedRow2.SUBLINE_CD,
        SUBLINE_NAME    : this.selectedRow2.SUBLINE_NAME,
        PLAN_CD         : this.selectedRow2.PLAN_CD,
        PLAN_NAME       : this.selectedRow2.PLAN_NAME,
        ITEM_CD         : this.selectedRow2.ITEM_CD,
        PERIL_CD        : this.selectedRow2.PERIL_CD + " - " + this.selectedRow2.PERIL_LNAME,
        PERIL_LNAME     : this.selectedRow2.PERIL_LNAME,
        PERIL_DESC      : this.selectedRow2.PERIL_DESC,
        DEFAULT_RATE    : this.decimalPipe.transform(this.selectedRow2.DEFAULT_RATE, '1.9'),
        DEFAULT_TSI     : this.decimalPipe.transform(this.selectedRow2.DEFAULT_TSI, '1.2-2'),
        FMV_SW          : this.selectedRow2.FMV_SW,
        RATING_TBL_SW   : this.selectedRow2.RATING_TBL_SW,
        MULTIPLIER_RATE : this.decimalPipe.transform(this.selectedRow2.MULTIPLIER_RATE, '1.9'),
        SEQ_NO          : this.selectedRow2.SEQ_NO,
        ACTIVE_TAG      : this.selectedRow2.ACTIVE_TAG,
        HL_TAG          : this.selectedRow2.HL_TAG,
        REMARKS         : this.selectedRow2.REMARKS,
        BM_CODE         : this.selectedRow2.BM_CODE,
        USER_ID         : this.selectedRow2.USER_ID,
        LAST_UPDATE     : this.selectedRow2.LAST_UPDATE,
      });
      this.form2.get("PERIL_CD")?.setValue(this.selectedRow2.PERIL_CD, {emitModelToViewChange: false});
    } catch(e) {
      
    }
  }
  
  formClearer1() {
    try {
      this.form1.patchValue({
        LINE_CD         : "",
        LINE_NAME       : "",
        SUBLINE_CD      : "",
        SUBLINE_NAME    : "",
        PLAN_CD         : "",
        PLAN_NAME       : "",
        ITEM_CD         : "",
        ITEM_LONG_DESC  : "",
        ACTIVE_TAG      : "A",
        ITEM_LABEL      : "",
        ITEM_CD_DTL     : "",
        COLUMN_GRP      : "",
        SEQ_NO          : "",
        REMARKS         : "",
        USER_ID         : "",
        LAST_UPDATE     : "",
      });
    } catch(e) {
      
    }
  }

  formClearer2() {
    try {
      this.form2.patchValue({
        LINE_CD         : "",
        LINE_NAME       : "",
        SUBLINE_CD      : "",
        SUBLINE_NAME    : "",
        PLAN_CD         : "",
        PLAN_NAME       : "",
        ITEM_CD         : "",
        PERIL_CD        : "",
        PERIL_LNAME     : "",
        PERIL_DESC      : "",
        DEFAULT_RATE    : "",
        DEFAULT_TSI     : "",
        FMV_SW          : "",
        RATING_TBL_SW   : "",
        MULTIPLIER_RATE : "",
        SEQ_NO          : "",
        ACTIVE_TAG      : "A",
        HL_TAG          : "",
        REMARKS         : "",
        BM_CODE         : "",
        USER_ID         : "",
        LAST_UPDATE     : "",
      });
    } catch(e) {

    }
  }

  deleteEnabler1() {
    try {
      let tempDeleteFlag = this.tempNewData1.filter((e: any) => {
        return e.LINE_CD    == this.selectedRow1.LINE_CD &&
               e.SUBLINE_CD == this.selectedRow1.SUBLINE_CD &&
               e.PLAN_CD    == this.selectedRow1.PLAN_CD &&
               e.ITEM_CD    == this.selectedRow1.ITEM_CD
      });
      if(tempDeleteFlag=='' || tempDeleteFlag==null) {
        this.deleteDisabler1 = true;
      } else {
        this.deleteDisabler1 = false;
      }
    } catch(e) {
      
    }
  }

  deleteEnabler2() {
    try {
      let tempDeleteFlag = this.tempNewData2.filter((e: any) => {
        return e.LINE_CD    == this.selectedRow2.LINE_CD &&
               e.SUBLINE_CD == this.selectedRow2.SUBLINE_CD &&
               e.PLAN_CD    == this.selectedRow2.PLAN_CD &&
               e.ITEM_CD    == this.selectedRow2.ITEM_CD &&
               e.PERIL_CD   == this.selectedRow2.PERIL_CD
      });
      if(tempDeleteFlag=='' || tempDeleteFlag==null) {
        this.deleteDisabler2 = true;
      } else {
        this.deleteDisabler2 = false;
      }
    } catch(e) {
      
    }
  }

  addRow1(): void {
    try {
      if(this.form1.valid && this.paramForm.valid) {
        this.formService.hideFormMsg("applicable-item-code-per-plan-error-message-1");
        
        // para sa table
        this.table1.tblData.unshift({
          LINE_CD         : this.paramForm.get("LINE_CD")?.value,
          LINE_NAME       : this.paramForm.get("LINE_NAME")?.value,
          SUBLINE_CD      : this.paramForm.get("SUBLINE_CD")?.value,
          SUBLINE_NAME    : this.paramForm.get("SUBLINE_NAME")?.value,
          PLAN_CD         : this.paramForm.get("PLAN_CD")?.value,
          PLAN_NAME       : this.paramForm.get("PLAN_NAME")?.value,
          ITEM_CD         : this.form1.get('ITEM_CD')?.value,
          ITEM_LONG_DESC  : this.form1.get("ITEM_LONG_DESC")?.value,
          ACTIVE_TAG      : this.form1.get("ACTIVE_TAG")?.value,
          ITEM_LABEL      : this.form1.get("ITEM_LABEL")?.value,
          ITEM_CD_DTL     : this.form1.get("ITEM_CD_DTL")?.value,
          COLUMN_GRP      : this.form1.get("COLUMN_GRP")?.value,
          SEQ_NO          : this.form1.get("SEQ_NO")?.value == "" ? "" : this.form1.get("SEQ_NO")?.value.toString().padStart(2, '0'),
          REMARKS         : this.form1.get("REMARKS")?.value,
        });
        this.table1.tblData = [...this.table1.tblData];

        // para sa db
        this.tempNewData1.push(this.formValues1());

        this.formClearer1();
        this.saveDisabler1 = false;
        this.perilResetter();
        this.perilFieldDisabler();
      } else {
        this.errorMessageSetter1();
      }
    } catch(e) {
      
    }
  }

  addRow2(): void {
    try {
      if(this.form2.valid && this.paramForm.valid) {
        this.formService.hideFormMsg("applicable-item-code-per-plan-error-message-2");
        
        // para sa table
        this.table2.tblData.unshift({
          LINE_CD         : this.form1.get("LINE_CD")?.value,
          LINE_NAME       : this.form1.get("LINE_NAME")?.value,
          SUBLINE_CD      : this.form1.get("SUBLINE_CD")?.value,
          SUBLINE_NAME    : this.form1.get("SUBLINE_NAME")?.value,
          PLAN_CD         : this.form1.get("PLAN_CD")?.value,
          PLAN_NAME       : this.form1.get("PLAN_NAME")?.value,
          ITEM_CD         : this.form1.get("ITEM_CD")?.value,
          PERIL_CD        : this.form2.get("PERIL_CD")?.value,
          PERIL_LNAME     : this.form2.get("PERIL_LNAME")?.value,
          PERIL_DESC      : this.form2.get("PERIL_DESC")?.value,
          DEFAULT_RATE    : this.form2.get("DEFAULT_RATE")?.value ? +this.form2.get("DEFAULT_RATE")?.value : "",
          DEFAULT_TSI     : this.form2.get("DEFAULT_TSI")?.value ? +this.form2.get("DEFAULT_TSI")?.value : "",
          FMV_SW          : this.form2.get("FMV_SW")?.value,
          RATING_TBL_SW   : this.form2.get("RATING_TBL_SW")?.value,
          MULTIPLIER_RATE : this.form2.get("MULTIPLIER_RATE")?.value ? +this.form2.get("MULTIPLIER_RATE")?.value : "",
          SEQ_NO          : this.form2.get("SEQ_NO")?.value == "" ? "" : this.form2.get("SEQ_NO")?.value.toString().padStart(3, '0'),
          ACTIVE_TAG      : this.form2.get("ACTIVE_TAG")?.value,
          HL_TAG          : this.form2.get("HL_TAG")?.value,
          REMARKS         : this.form2.get("REMARKS")?.value,
          BM_CODE         : this.form2.get("BM_CODE")?.value,
        });
        this.table2.tblData = [...this.table2.tblData];

        // para sa db
        this.tempNewData2.push(this.formValues2());

        this.formClearer2();
        this.saveDisabler2 = false;
      } else {
        this.errorMessageSetter2();
      }
    } catch(e) {
      
    }
  }

  formValues1(): any {
    try {
      return {
        LINE_CD         : this.paramForm.get("LINE_CD")?.value,
        LINE_NAME       : this.paramForm.get("LINE_NAME")?.value,
        SUBLINE_CD      : this.paramForm.get("SUBLINE_CD")?.value,
        SUBLINE_NAME    : this.paramForm.get("SUBLINE_NAME")?.value,
        PLAN_CD         : this.paramForm.get("PLAN_CD")?.value,
        PLAN_NAME       : this.paramForm.get("PLAN_NAME")?.value,
        ITEM_CD         : isNaN(parseInt(this.form1.get('ITEM_CD')?.value)) ? "" : parseInt(this.form1.get('ITEM_CD')?.value),
        ITEM_LONG_DESC  : this.form1.get("ITEM_LONG_DESC")?.value,
        ACTIVE_TAG      : this.form1.get("ACTIVE_TAG")?.value,
        ITEM_LABEL      : this.form1.get("ITEM_LABEL")?.value,
        ITEM_CD_DTL     : this.form1.get("ITEM_CD_DTL")?.value,
        COLUMN_GRP      : this.form1.get("COLUMN_GRP")?.value,
        SEQ_NO          : this.form1.get("SEQ_NO")?.value,
        REMARKS         : this.form1.get("REMARKS")?.value,
        USER_ID         : this.userId,       
        LAST_UPDATE     : this.form1.get('LAST_UPDATE')?.value,  
      }
    } catch(e) {
      
    }
  }

  formValues2(): any {
    try {
      return {
        LINE_CD         : this.paramForm.get("LINE_CD")?.value,
        LINE_NAME       : this.paramForm.get("LINE_NAME")?.value,
        SUBLINE_CD      : this.paramForm.get("SUBLINE_CD")?.value,
        SUBLINE_NAME    : this.paramForm.get("SUBLINE_NAME")?.value,
        PLAN_CD         : this.paramForm.get("PLAN_CD")?.value,
        PLAN_NAME       : this.paramForm.get("PLAN_NAME")?.value,
        ITEM_CD         : this.form1.get("ITEM_CD")?.value,
        PERIL_CD        : isNaN(parseInt(this.form2.get("PERIL_CD")?.value)) ? "" : parseInt(this.form2.get("PERIL_CD")?.value),
        PERIL_LNAME     : this.form2.get("PERIL_LNAME")?.value,
        PERIL_DESC      : this.form2.get("PERIL_DESC")?.value,
        DEFAULT_RATE    : this.form2.get("DEFAULT_RATE")?.value,
        DEFAULT_TSI     : this.form2.get("DEFAULT_TSI")?.value,
        FMV_SW          : this.form2.get("FMV_SW")?.value,
        RATING_TBL_SW   : this.form2.get("RATING_TBL_SW")?.value,
        MULTIPLIER_RATE : this.form2.get("MULTIPLIER_RATE")?.value,
        SEQ_NO          : this.form2.get("SEQ_NO")?.value,
        ACTIVE_TAG      : this.form2.get("ACTIVE_TAG")?.value,
        HL_TAG          : this.form2.get("HL_TAG")?.value,
        REMARKS         : this.form2.get("REMARKS")?.value,
        BM_CODE         : this.form2.get("BM_CODE")?.value,
        USER_ID         : this.userId,       
        LAST_UPDATE     : this.form1.get('LAST_UPDATE')?.value,  
      }
    } catch(e) {
      
    }
  }

  errorMessageSetter1() {
    try {
      if(this.form1.invalid) {
        for(const field in this.form1.controls) {
          const controlName = this.form1.get(field);
          if(controlName?.errors?.required) {
            this.errorMessage1 = 'There are missing information. Please provide necessary information needed.';
          }
          if(controlName?.errors?.hasDecimalsExceeded) {
            this.errorMessage1 = 'Invalid value in Gross Amount. The value entered exceeded the maximum limit.';
          }
          // if(controlName?.errors?.duplicateShortDesc) {
          //   this.errorMessage = controlName?.errors?.duplicateShortDesc;
          // }
          this.formService.showFormMsg("applicable-item-code-per-plan-error-message-1", this.errorMessage1, "E");
        }
      }
    } catch(e) {
      
    }
  }

  errorMessageSetter2() {
    try {
      if(this.form2.invalid) {
        for(const field in this.form2.controls) {
          const controlName = this.form2.get(field);
          if(controlName?.errors?.required) {
            this.errorMessage2 = 'There are missing information. Please provide necessary information needed.';
          }
          if(controlName?.errors?.hasDecimalsExceeded) {
            this.errorMessage2 = controlName?.errors?.hasDecimalsExceeded;
          }
          // if(controlName?.errors?.duplicateShortDesc) {
          //   this.errorMessage = controlName?.errors?.duplicateShortDesc;
          // }
          this.formService.showFormMsg("applicable-item-code-per-plan-error-message-2", this.errorMessage2, "E");
        }
      }
    } catch(e) {
      
    }
  }

  onUpdate1(): void {
    try {
      if(this.form1.valid && this.paramForm.valid) {
        this.updateTable1();
        if(this.deleteDisabler1) {
          this.updateDbData1();
        } else {
          this.updateNewData1();
        }
        this.selectedRow1 = '';
        this.addUpdateFlag1 = true;
        this.formClearer1();
        this.saveDisabler1 = false;
        this.deleteEnabler1();
        this.formService.hideFormMsg('property-details-error-message-1');
        this.perilResetter();
        this.perilFieldDisabler();
      } else {
        this.errorMessageSetter1();
      }
    } catch(e) {
      
    }
  }

  onUpdate2(): void {
    try {
      if(this.form2.valid && this.paramForm.valid) {
        this.updateTable2();
        if(this.deleteDisabler2) {
          this.updateDbData2();
        } else {
          this.updateNewData2();
        }
        this.selectedRow2 = '';
        this.addUpdateFlag2 = true;
        this.formClearer2();
        this.saveDisabler2 = false;
        this.deleteEnabler2();
        this.formService.hideFormMsg('property-details-error-message-2');
      } else {
        this.errorMessageSetter2();
      }
    } catch(e) {
      
    }
  }

  updateTable1() {
    try {
      let updateIndex = this.table1.tblData.indexOf(this.selectedRow1);
      this.table1.tblData[updateIndex] = {
        LINE_CD         : this.form1.get("LINE_CD")?.value,
        LINE_NAME       : this.form1.get("LINE_NAME")?.value,
        SUBLINE_CD      : this.form1.get("SUBLINE_CD")?.value,
        SUBLINE_NAME    : this.form1.get("SUBLINE_NAME")?.value,
        PLAN_CD         : this.form1.get("PLAN_CD")?.value,
        PLAN_NAME       : this.form1.get("PLAN_NAME")?.value,
        ITEM_CD         : this.form1.get('ITEM_CD')?.value,
        ITEM_LONG_DESC  : this.form1.get("ITEM_LONG_DESC")?.value,
        ACTIVE_TAG      : this.form1.get("ACTIVE_TAG")?.value,
        ITEM_LABEL      : this.form1.get("ITEM_LABEL")?.value,
        ITEM_CD_DTL     : this.form1.get("ITEM_CD_DTL")?.value,
        COLUMN_GRP      : this.form1.get("COLUMN_GRP")?.value,
        SEQ_NO          : this.form1.get("SEQ_NO")?.value == "" ? "" : this.form1.get("SEQ_NO")?.value?.toString().padStart(2, '0'),
        REMARKS         : this.form1.get("REMARKS")?.value,
      };
      this.table1.tblData = [...this.table1.tblData];
    } catch(e) {
      
    }
  }

  updateTable2() {
    try {
      let updateIndex = this.table2.tblData.indexOf(this.selectedRow2);
      this.table2.tblData[updateIndex] = {
        LINE_CD         : this.form2.get("LINE_CD")?.value,
        LINE_NAME       : this.form2.get("LINE_NAME")?.value,
        SUBLINE_CD      : this.form2.get("SUBLINE_CD")?.value,
        SUBLINE_NAME    : this.form2.get("SUBLINE_NAME")?.value,
        PLAN_CD         : this.form2.get("PLAN_CD")?.value,
        PLAN_NAME       : this.form2.get("PLAN_NAME")?.value,
        ITEM_CD         : this.form2.get("ITEM_CD")?.value,
        PERIL_CD        : this.form2.get("PERIL_CD")?.value,
        PERIL_LNAME     : this.form2.get("PERIL_LNAME")?.value,
        PERIL_DESC      : this.form2.get("PERIL_DESC")?.value,
        DEFAULT_RATE    : this.form2.get("DEFAULT_RATE")?.value !== "" && this.form2.get("DEFAULT_RATE")?.value !== null ? +this.form2.get("DEFAULT_RATE")?.value : "",
        DEFAULT_TSI     : this.form2.get("DEFAULT_TSI")?.value !== "" && this.form2.get("DEFAULT_TSI")?.value !== null ? +this.form2.get("DEFAULT_TSI")?.value : "",
        FMV_SW          : this.form2.get("FMV_SW")?.value,
        RATING_TBL_SW   : this.form2.get("RATING_TBL_SW")?.value,
        MULTIPLIER_RATE : this.form2.get("MULTIPLIER_RATE")?.value !== "" && this.form2.get("MULTIPLIER_RATE")?.value !== null ? +this.form2.get("MULTIPLIER_RATE")?.value : "",
        SEQ_NO          : this.form2.get("SEQ_NO")?.value === "" ? "" : this.form2.get("SEQ_NO")?.value?.toString().padStart(3, '0'),
        ACTIVE_TAG      : this.form2.get("ACTIVE_TAG")?.value,
        HL_TAG          : this.form2.get("HL_TAG")?.value,
        REMARKS         : this.form2.get("REMARKS")?.value,
        BM_CODE         : this.form2.get("BM_CODE")?.value,
      };
      this.table2.tblData = [...this.table2.tblData];
    } catch(e) {
      
    }
  }

  updateDbData1() {
    try {
      let tempDbData = this.tempUpdateData1.find(
        (dbData: { ITEM_CD: any }) => dbData.ITEM_CD == this.selectedRow1.ITEM_CD
      );
      if((this.tempUpdateData1 == '' || this.tempUpdateData1 == null) || (tempDbData=='' || tempDbData==null)) {
        this.tempUpdateData1.push(this.formValues1());
      } else {
        tempDbData.LINE_CD         = this.form1.get("LINE_CD")?.value;
        tempDbData.LINE_NAME       = this.form1.get("LINE_NAME")?.value;
        tempDbData.SUBLINE_CD      = this.form1.get("SUBLINE_CD")?.value;
        tempDbData.SUBLINE_NAME    = this.form1.get("SUBLINE_NAME")?.value;
        tempDbData.PLAN_CD         = this.form1.get("PLAN_CD")?.value;
        tempDbData.PLAN_NAME       = this.form1.get("PLAN_NAME")?.value;
        tempDbData.ITEM_CD         = +this.form1.get('ITEM_CD')?.value;
        tempDbData.ITEM_LONG_DESC  = this.form1.get("ITEM_LONG_DESC")?.value;
        tempDbData.ACTIVE_TAG      = this.form1.get("ACTIVE_TAG")?.value;
        tempDbData.ITEM_LABEL      = this.form1.get("ITEM_LABEL")?.value;
        tempDbData.ITEM_CD_DTL     = this.form1.get("ITEM_CD_DTL")?.value;
        tempDbData.COLUMN_GRP      = this.form1.get("COLUMN_GRP")?.value;
        tempDbData.SEQ_NO          = this.form1.get("SEQ_NO")?.value;
        tempDbData.REMARKS         = this.form1.get("REMARKS")?.value;
        tempDbData.USER_ID         = this.userId;
        tempDbData.LAST_UPDATE     = this.form1.get('LAST_UPDATE')?.value;
      }
    } catch(e) {
      
    }
  }

  updateDbData2() {
    try {
      let tempDbData = this.tempUpdateData2.find(
        (dbData: { PERIL_CD: any }) => dbData.PERIL_CD == this.selectedRow2.PERIL_CD
      );
      if((this.tempUpdateData2 == '' || this.tempUpdateData2 == null) || (tempDbData=='' || tempDbData==null)) {
        this.tempUpdateData2.push(this.formValues2());
      } else {
        tempDbData.LINE_CD         = this.form2.get("LINE_CD")?.value,
        tempDbData.LINE_NAME       = this.form2.get("LINE_NAME")?.value,
        tempDbData.SUBLINE_CD      = this.form2.get("SUBLINE_CD")?.value,
        tempDbData.SUBLINE_NAME    = this.form2.get("SUBLINE_NAME")?.value,
        tempDbData.PLAN_CD         = this.form2.get("PLAN_CD")?.value,
        tempDbData.PLAN_NAME       = this.form2.get("PLAN_NAME")?.value,
        tempDbData.ITEM_CD         = this.form2.get("ITEM_CD")?.value,
        tempDbData.PERIL_CD        = +this.form2.get("PERIL_CD")?.value,
        tempDbData.PERIL_LNAME     = this.form2.get("PERIL_LNAME")?.value,
        tempDbData.PERIL_DESC      = this.form2.get("PERIL_DESC")?.value,
        tempDbData.DEFAULT_RATE    = this.form2.get("DEFAULT_RATE")?.value,
        tempDbData.DEFAULT_TSI     = this.form2.get("DEFAULT_TSI")?.value,
        tempDbData.FMV_SW          = this.form2.get("FMV_SW")?.value,
        tempDbData.RATING_TBL_SW   = this.form2.get("RATING_TBL_SW")?.value,
        tempDbData.MULTIPLIER_RATE = this.form2.get("MULTIPLIER_RATE")?.value,
        tempDbData.SEQ_NO          = this.form2.get("SEQ_NO")?.value,
        tempDbData.ACTIVE_TAG      = this.form2.get("ACTIVE_TAG")?.value,
        tempDbData.HL_TAG          = this.form2.get("HL_TAG")?.value,
        tempDbData.REMARKS         = this.form2.get("REMARKS")?.value,
        tempDbData.BM_CODE         = this.form2.get("BM_CODE")?.value,
        tempDbData.USER_ID         = this.userId;
        tempDbData.LAST_UPDATE     = this.form2.get('LAST_UPDATE')?.value;
      }
    } catch(e) {
      
    }
  }

  updateNewData1() {
    try {
      let tempNewConfigdata = this.tempNewData1.find(
        (e: { ITEM_CD: any }) => e.ITEM_CD == this.selectedRow1.ITEM_CD
      );
      tempNewConfigdata.LINE_CD         = this.form1.get("LINE_CD")?.value;
      tempNewConfigdata.LINE_NAME       = this.form1.get("LINE_NAME")?.value;
      tempNewConfigdata.SUBLINE_CD      = this.form1.get("SUBLINE_CD")?.value;
      tempNewConfigdata.SUBLINE_NAME    = this.form1.get("SUBLINE_NAME")?.value;
      tempNewConfigdata.PLAN_CD         = this.form1.get("PLAN_CD")?.value;
      tempNewConfigdata.PLAN_NAME       = this.form1.get("PLAN_NAME")?.value;
      tempNewConfigdata.ITEM_CD         = +this.form1.get('ITEM_CD')?.value;
      tempNewConfigdata.ITEM_LONG_DESC  = this.form1.get("ITEM_LONG_DESC")?.value;
      tempNewConfigdata.ACTIVE_TAG      = this.form1.get("ACTIVE_TAG")?.value;
      tempNewConfigdata.ITEM_LABEL      = this.form1.get("ITEM_LABEL")?.value;
      tempNewConfigdata.ITEM_CD_DTL     = this.form1.get("ITEM_CD_DTL")?.value;
      tempNewConfigdata.COLUMN_GRP      = this.form1.get("COLUMN_GRP")?.value;
      tempNewConfigdata.SEQ_NO          = this.form1.get("SEQ_NO")?.value;
      tempNewConfigdata.REMARKS         = this.form1.get("REMARKS")?.value;
      tempNewConfigdata.USER_ID         = this.userId;
      tempNewConfigdata.LAST_UPDATE     = this.form1.get('LAST_UPDATE')?.value;
    } catch(e) {
      
    }
  }

  updateNewData2() {
    try {
      let tempNewConfigdata = this.tempNewData2.find(
        (e: { PERIL_CD: any }) => e.PERIL_CD == this.selectedRow2.PERIL_CD
      );
      tempNewConfigdata.LINE_CD         = this.form2.get("LINE_CD")?.value,
      tempNewConfigdata.LINE_NAME       = this.form2.get("LINE_NAME")?.value,
      tempNewConfigdata.SUBLINE_CD      = this.form2.get("SUBLINE_CD")?.value,
      tempNewConfigdata.SUBLINE_NAME    = this.form2.get("SUBLINE_NAME")?.value,
      tempNewConfigdata.PLAN_CD         = this.form2.get("PLAN_CD")?.value,
      tempNewConfigdata.PLAN_NAME       = this.form2.get("PLAN_NAME")?.value,
      tempNewConfigdata.ITEM_CD         = this.form2.get("ITEM_CD")?.value,
      tempNewConfigdata.PERIL_CD        = +this.form2.get("PERIL_CD")?.value,
      tempNewConfigdata.PERIL_LNAME     = this.form2.get("PERIL_LNAME")?.value,
      tempNewConfigdata.PERIL_DESC      = this.form2.get("PERIL_DESC")?.value,
      tempNewConfigdata.DEFAULT_RATE    = this.form2.get("DEFAULT_RATE")?.value,
      tempNewConfigdata.DEFAULT_TSI     = this.form2.get("DEFAULT_TSI")?.value,
      tempNewConfigdata.FMV_SW          = this.form2.get("FMV_SW")?.value,
      tempNewConfigdata.RATING_TBL_SW   = this.form2.get("RATING_TBL_SW")?.value,
      tempNewConfigdata.MULTIPLIER_RATE = this.form2.get("MULTIPLIER_RATE")?.value,
      tempNewConfigdata.SEQ_NO          = this.form2.get("SEQ_NO")?.value,
      tempNewConfigdata.ACTIVE_TAG      = this.form2.get("ACTIVE_TAG")?.value,
      tempNewConfigdata.HL_TAG          = this.form2.get("HL_TAG")?.value,
      tempNewConfigdata.REMARKS         = this.form2.get("REMARKS")?.value,
      tempNewConfigdata.BM_CODE         = this.form2.get("BM_CODE")?.value,
      tempNewConfigdata.USER_ID         = this.userId;
      tempNewConfigdata.LAST_UPDATE     = this.form2.get('LAST_UPDATE')?.value;
    } catch(e) {
      
    }
  }

  deleteRow1(): void {
    try {
      let clickedData = this.selectedRow1;
      this.table1.tblData = this.table1.tblData.filter(function(e: any) {
        return e.ITEM_CD != clickedData.ITEM_CD;
      });
      this.tempNewData1 = this.tempNewData1.filter(function(e: any) {
        return e.ITEM_CD != clickedData.ITEM_CD;
      });
      this.selectedRow1 = '';
      this.formClearer1();
      this.deleteDisabler1 = true;
      this.addUpdateFlag1 = true;
      if((this.tempNewData1=='' || this.tempNewData1==null) && (this.tempUpdateData1=='' || this.tempUpdateData1==null)) {
        this.saveDisabler1 = true;
      }
    } catch(e) {
      
    }
  }

  deleteRow2(): void {
    try {
      let clickedData = this.selectedRow2;
      this.table2.tblData = this.table2.tblData.filter(function(e: any) {
        return e.PERIL_CD != clickedData.PERIL_CD;
      });
      this.tempNewData1 = this.tempNewData2.filter(function(e: any) {
        return e.PERIL_CD != clickedData.PERIL_CD;
      });
      this.selectedRow2 = '';
      this.formClearer2();
      this.deleteDisabler2 = true;
      this.addUpdateFlag2 = true;
      if((this.tempNewData2=='' || this.tempNewData2==null) && (this.tempUpdateData2=='' || this.tempUpdateData2==null)) {
        this.saveDisabler2 = true;
      }
    } catch(e) {
      
    }
  }

  perilResetter(): void {
    try {
      this.selectedRow2 = '';
      this.addUpdateFlag2 = true;
      this.formClearer2();
      this.saveDisabler2 = true;
      this.deleteEnabler2();
      this.table2.tblData = [];
      this.formService.hideFormMsg('property-details-error-message-2');
    } catch(e) {

    }
  }

  perilEnabler(): void {
    try {
      this.perilDisabler = false;
      for(const field in this.form2.controls) {
        if(field !== "USER_ID" && field !== "LAST_UPDATE") {
          const controlName = this.form2.get(field);
          controlName?.enable();
        }
      }
    } catch(e) {

    }
  }

  perilFieldDisabler(): void {
    try {
      this.perilDisabler = true;
      for(const field in this.form2.controls) {
        const controlName = this.form2.get(field);
        controlName?.disable();
      }
    } catch(e) {

    }
  }

  onSave1(): void {
    try {
      this.tempNewData1 = this.tempNewData1.map((e: any) => {
        return {...e, PLAN_CD: +e.PLAN_CD, SEQ_NO: e.SEQ_NO === "" ? "" : +e.SEQ_NO}
      });
      this.tempUpdateData1 = this.tempUpdateData1.map((e: any) => {
        return {...e, PLAN_CD: +e.PLAN_CD, SEQ_NO: e.SEQ_NO === "" ? "" : +e.SEQ_NO}
      });

      this.formHider = true;
      this.jsonDataService.contorlLoading(true);
      this.api.saveFireItemCodePerPlan(this.tempNewData1).subscribe({
        next: (data: any) => {
          this.securityService.checkRequestKeyResponse(data, () => {
            this.securityService.hasValidCsrfToken(data, () => {
              if(data.status == 'SUCCESS') {
                this.api.saveFireItemCodePerPlan(this.tempUpdateData1).subscribe({
                  next: (res: any) => {
                    this.securityService.checkRequestKeyResponse(res, () => {
                      this.securityService.hasValidCsrfToken(data, () => {
                        if(res.status == 'SUCCESS') {
                          this.onComplete1();
                        } else {
                          this.onFail1();
                        }
                      });
                    });
                  },
                  error: () => {
                    this.onFail1();
                  }
                });
              } else {
                this.onFail1();
              }
            });
          });
        },
        error: () => {
          this.onFail1();
        }
      });
    } catch(e) {

    }
  }

  onSave2(): void {
    try {
      this.tempNewData2 = this.tempNewData2.map((e: any) => {
        return {...e, ITEM_CD: +e.ITEM_CD, SEQ_NO: e.SEQ_NO === "" ? "" : +e.SEQ_NO}
      });
      this.tempUpdateData2 = this.tempUpdateData2.map((e: any) => {
        return {...e, ITEM_CD: +e.ITEM_CD, SEQ_NO: e.SEQ_NO === "" ? "" : +e.SEQ_NO}
      });

      this.formHider = true;
      this.jsonDataService.contorlLoading(true);
      this.api.savePerilPerFireItemCode(this.tempNewData2).subscribe({
        next: (data: any) => {
          this.securityService.checkRequestKeyResponse(data, () => {
            this.securityService.hasValidCsrfToken(data, () => {
              if(data.status == 'SUCCESS') {
                this.api.savePerilPerFireItemCode(this.tempUpdateData2).subscribe({
                  next: (res: any) => {
                    this.securityService.checkRequestKeyResponse(res, () => {
                      this.securityService.hasValidCsrfToken(data, () => {
                        if(res.status == 'SUCCESS') {
                          this.onComplete2();
                        } else {
                          this.onFail2();
                        }
                      });
                    });
                  },
                  error: () => {
                    this.onFail2();
                  }
                });
              } else {
                this.onFail2();
              }
            });
          });
        },
        error: () => {
          this.onFail2();
        }
      });
      
    } catch(e) {

    }
  }
  
  onComplete1() {
    try {
      this.formHider = false;
      this.tempNewData1 = [];
      this.tempUpdateData1 = [];
      this.saveDisabler1 = true;
      this.deleteEnabler1();
      this.formClearer1();
      this.perilResetter();
      this.getApplicableItemCodePerPlan();
      this.formService.hideFormMsg("applicable-item-code-per-plan-error-message-1");
      this.openSaveDialog1();
    } catch(e) {
      
    }
  }

  onFail1() {
    try {
      this.formHider = false;
      this.tempNewData1 = [];
      this.tempUpdateData1 = [];
      this.deleteEnabler1();
      this.formClearer1();
      this.perilResetter();
      this.getApplicableItemCodePerPlan();
      this.formService.hideFormMsg("applicable-item-code-per-plan-error-message-1");
      this.openErrorDialog1("Saving Fire Item Code Per Plan Failed");
    } catch(e) {
      
    }
  }

  openErrorDialog1(message: string) {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'ERROR',
          content: message,
        }
      });
    } catch(e) {
      
    }
  }

  openSaveDialog1() {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'SUCCESS',
          content: 'Fire Item Code Per Plan Details Successfully Saved!',
        }
      });
    } catch(e) {
      
    }
  }
  
  onComplete2() {
    try {
      this.formHider = false;
      this.tempNewData2 = [];
      this.tempUpdateData2 = [];
      this.saveDisabler2 = true;
      this.deleteEnabler2();
      this.formClearer2();
      this.getApplicableItemCodePerPlan();
      this.formService.hideFormMsg("applicable-item-code-per-plan-error-message-2");
      this.openSaveDialog2();
    } catch(e) {
      
    }
  }

  onFail2() {
    try {
      this.formHider = false;
      this.tempNewData2 = [];
      this.tempUpdateData2 = [];
      this.deleteEnabler2();
      this.formClearer2();
      this.table2Loader();
      this.getApplicableItemCodePerPlan();
      this.formService.hideFormMsg("applicable-item-code-per-plan-error-message-2");
      this.openErrorDialog1("Saving Peril Per Fire Item Code Failed");
    } catch(e) {
      
    }
  }

  openErrorDialog2(message: string) {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'ERROR',
          content: message,
        }
      });
    } catch(e) {
      
    }
  }

  openSaveDialog2() {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'SUCCESS',
          content: 'Peril Per Fire Item Code Details Successfully Saved!',
        }
      });
    } catch(e) {
      
    }
  }

}
