import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { forkJoin, from, Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { CustomValidators } from 'src/app/utils/custom-validator';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';

@Component({
  selector: 'risk-rating-questions-maintenance',
  templateUrl: './risk-rating-questions-maintenance.component.html',
  styleUrls: ['./risk-rating-questions-maintenance.component.css']
})
export class RiskRatingQuestionsMaintenanceComponent implements OnInit, LeaveConfirmation {
  
  selectedRow: any=[];
  datePipe = new DatePipe('en-ph');
  errorMessage: string = "";
  userId = this.userDetailService.userId;
  formHider: boolean = false;
  moduleId: string = 'BMM102';
  moduleName = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId === 'BMM102' })[0].moduleDesc.toUpperCase();

  addUpdateFlag: boolean = true;
  deleteDisabler: boolean = true;
  saveDisabler: boolean = true;

  tempAddData: any = [];
  tempUpdateData: any = [];
  minEffDate: Date = new Date();

  
  riskRatingQuestionsTable: any = {
    cols: [
      {
        key: "QUESTION_NO",
        header: "Question No.",
        dataType: "string"
      },
      {
        key: "QUESTION",
        header: "Question",
        dataType: "string",
        width: '128px'
      },
      {
        key: "FIXED_SCORE",
        header: "Fixed Score",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  riskRatingQuestionsForm = this.fb.group({
    QUESTION_NO: [{value: '', disabled: true}],
    QUESTION: [null, [Validators.required, CustomValidators.requiredTrim]],
    FIXED_SCORE_TAG: ['N'],
    FIXED_SCORE: [null],
    YES_SCORE: [null, Validators.required],
    NO_SCORE: [null, Validators.required],
    NA_SCORE: [null, Validators.required],
    COLUMN_FIELD: [''],
    EFF_DATE_FROM: [null, [Validators.required]],
    EFF_DATE_TO: [null],
    ACTIVE_TAG: ['A', Validators.required],
    REMARKS: [''],
    USER_ID: [{value: '', disabled: true}],
    LAST_UPDATE: [{value: '', disabled: true}],
  });

  constructor(
    private fb: FormBuilder,
    private api: APICallService,
    private jsonDataService: JsonDataService,
    private formService: FormService,
    private dialog: MatDialog,
    private userDetailService: UserDetailsService,
    private securityService: SecurityService,
    private appMessageService: AppMessageService,
  ) { }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempAddData!='' || this.tempUpdateData!='') {
      return false;
    } else {
      return true;
    }
  }

  ngOnInit(): void {
    try {
      this.getRiskRatingQuestions();
      this.checkFixedScoreTag();
    } catch(e) {
    }
  }

  getRiskRatingQuestions() {
    try {
      this.riskRatingQuestionsTable.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.api.getRiskRatingQuestions({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE"
      }).subscribe({
        next: (data: any) => {
        this.securityService.checkRequestKeyResponse(data, () => {
          this.securityService.hasValidCsrfToken(data, () => {
          if (data.status === "SUCCESS") {
              data = JSON.parse(this.jsonDataService.decrypt(data.response));
              this.riskRatingQuestionsTable.loading = false;
              this.jsonDataService.contorlLoading(false);
              this.riskRatingQuestionsTable.tblData = data;
          } else {
              this.jsonDataService.contorlLoading(false);
              this.riskRatingQuestionsTable.loading = false;
              this.appMessageService.showAppMessage(data.message, "error");
          }
        });
        });
      },
        error: () => {
          this.riskRatingQuestionsTable.loading = false;
          this.jsonDataService.contorlLoading(false);
        },
      });
    } catch(e) {
    }
  }

  onRowClick(ev: any) {
    this.formService.hideFormMsg("risk-rating-questions-error-message");
    try {
      if(ev!=null) {
        this.selectedRow = ev;
        this.addUpdateFlag = false;
        this.formLoader();
        this.checkFixedScoreTagOnRowClick();
      } else {
        this.selectedRow = '';
        this.addUpdateFlag = true;
        this.formClearer();
        this.checkFixedScoreTag();
      }
      this.deleteEnabler();
    } catch(e) {
    }
  }

  formLoader() {
    try {
      this.riskRatingQuestionsForm.patchValue({
        QUESTION_NO:          this.selectedRow.QUESTION_NO,
        QUESTION:             this.selectedRow.QUESTION,
        FIXED_SCORE_TAG:      this.selectedRow.FIXED_SCORE_TAG,
        FIXED_SCORE:          this.selectedRow.FIXED_SCORE,
        YES_SCORE:            this.selectedRow.YES_SCORE,
        NO_SCORE:             this.selectedRow.NO_SCORE,
        NA_SCORE:             this.selectedRow.NA_SCORE,
        COLUMN_FIELD:         this.selectedRow.COLUMN_FIELD,
        EFF_DATE_FROM:        new Date(this.selectedRow.EFF_DATE_FROM),
        EFF_DATE_TO:          this.selectedRow.EFF_DATE_TO && new Date(this.selectedRow.EFF_DATE_TO),
        ACTIVE_TAG:           this.selectedRow.ACTIVE_TAG,
        REMARKS:              this.selectedRow.REMARKS,
        USER_ID:              this.selectedRow.USER_ID,
        LAST_UPDATE:          this.selectedRow.LAST_UPDATE == '0000-00-00 00:00:00' ? '0000-00-00 00:00:00' : this.datePipe.transform(this.selectedRow.LAST_UPDATE, 'MM/dd/YYYY h:mm:ss a', '+0000'),
      });
    } catch(e) {
    }
  }

  formClearer() {
    try {
      this.riskRatingQuestionsForm.patchValue({
        QUESTION_NO:          '',
        QUESTION:             '',
        FIXED_SCORE_TAG:      'N',
        FIXED_SCORE:          null,
        YES_SCORE:            null,
        NO_SCORE:             null,
        NA_SCORE:             null,
        COLUMN_FIELD:         '',
        EFF_DATE_FROM:        '',
        EFF_DATE_TO:          '',
        ACTIVE_TAG:           'A',
        REMARKS:              '',
        USER_ID:              '',
        LAST_UPDATE:          '',
      });
    } catch(e) {
    }
  }

  validateInsert(){
    try {
      if (this.requiredFieldCheck()){      
          this.errorMessageSetter();
          return false;
      }
      /*
      else if(this.questionDupCheck()){
          return false;
      }
      */
      else if (this.effDateToCheck()){
          this.errorMessageSetter();
          return false;
      }
      else {
        return true;
      }
    }
    catch (e) {
    console.error(e);
    }
    return false;
  }


  requiredFieldCheck() {
    try {
      const form = this.riskRatingQuestionsForm.getRawValue();
      if(form.QUESTION?.trim()!=''&&form.QUESTION&&form.EFF_DATE_FROM&&form.ACTIVE_TAG&&
        (((form.FIXED_SCORE_TAG=='Y'&&form.FIXED_SCORE>=0)||
         (form.FIXED_SCORE_TAG=='N'&&form.YES_SCORE>=0&&form.NO_SCORE>=0&&form.NA_SCORE>=0)))) {
        return false;
      }
      else {
        return true;
      }
   }
    catch (e) {
    console.error(e);
      return false;
    }
  }

  errorMessageSetter() {
    try {
      if(this.riskRatingQuestionsForm.invalid||this.effDateToCheck()) {
        this.errorMessage = '';
        for(const field in this.riskRatingQuestionsForm.controls) {
          const controlName = this.riskRatingQuestionsForm.get(field);
          if(controlName?.errors?.required) {
            this.errorMessage = 'There are missing information. Please provide necessary information needed.';
          }
          if(controlName?.errors?.riskRatingQuestionDuplicate) {
            this.errorMessage = 'Risk Rating already exists. Please add a unique Risk Rating Description.';
          }
          if(this.effDateToCheck()){
            this.errorMessage = 'Unable to add record. Expiry Date must not be earlier than Effective Date';
          }
          else{
            this.errorMessage = "There are missing information. Please provide necessary information needed.";
          }
          this.formService.showFormMsg("risk-rating-questions-error-message", this.errorMessage, "E");
        }
      }
    } catch(e) {
    }
  }

  tableInserter() {
    try {
      this.riskRatingQuestionsTable.tblData.unshift({
        QUESTION_NO:             this.riskRatingQuestionsForm.get('QUESTION_NO')?.value,
        QUESTION:                this.riskRatingQuestionsForm.get('QUESTION')?.value,
        FIXED_SCORE_TAG:         this.riskRatingQuestionsForm.get('FIXED_SCORE_TAG')?.value,
        FIXED_SCORE:             this.riskRatingQuestionsForm.get('FIXED_SCORE')?.value || 0,
        YES_SCORE:               this.riskRatingQuestionsForm.get('YES_SCORE')?.value || 0,
        NO_SCORE:                this.riskRatingQuestionsForm.get('NO_SCORE')?.value || 0,
        NA_SCORE:                this.riskRatingQuestionsForm.get('NA_SCORE')?.value || 0,
        COLUMN_FIELD:            this.riskRatingQuestionsForm.get('COLUMN_FIELD')?.value,
        EFF_DATE_FROM:           this.riskRatingQuestionsForm.get('EFF_DATE_FROM')?.value,
        EFF_DATE_TO:             this.riskRatingQuestionsForm.get('EFF_DATE_TO')?.value,
        ACTIVE_TAG:              this.riskRatingQuestionsForm.get('ACTIVE_TAG')?.value,
        REMARKS:                 this.riskRatingQuestionsForm.get('REMARKS')?.value,
      });
      this.riskRatingQuestionsTable.tblData = [...this.riskRatingQuestionsTable.tblData];
    } catch(e) {
    }
  }

  tempAddInserter() {
    try {
      this.tempAddData.push({
        QUESTION_NO:             this.riskRatingQuestionsForm.get('QUESTION_NO')?.value,
        QUESTION:                this.riskRatingQuestionsForm.get('QUESTION')?.value,
        FIXED_SCORE_TAG:         this.riskRatingQuestionsForm.get('FIXED_SCORE_TAG')?.value,
        FIXED_SCORE:             this.riskRatingQuestionsForm.get('FIXED_SCORE')?.value || 0,
        YES_SCORE:               this.riskRatingQuestionsForm.get('YES_SCORE')?.value || 0,
        NO_SCORE:                this.riskRatingQuestionsForm.get('NO_SCORE')?.value || 0,
        NA_SCORE:                this.riskRatingQuestionsForm.get('NA_SCORE')?.value || 0,
        COLUMN_FIELD:            this.riskRatingQuestionsForm.get('COLUMN_FIELD')?.value,
        EFF_DATE_FROM:           this.riskRatingQuestionsForm.get('EFF_DATE_FROM')?.value,
        EFF_DATE_TO:             this.riskRatingQuestionsForm.get('EFF_DATE_TO')?.value,
        ACTIVE_TAG:              this.riskRatingQuestionsForm.get('ACTIVE_TAG')?.value,
        REMARKS:                 this.riskRatingQuestionsForm.get('REMARKS')?.value,
        USER_ID:              this.userId,
      });
    } catch(e) {
    }
  }

  tempUpdateInserter() {
    try {
      this.tempUpdateData.push({
        QUESTION_NO:             this.riskRatingQuestionsForm.get('QUESTION_NO')?.value,
        QUESTION:                this.riskRatingQuestionsForm.get('QUESTION')?.value,
        FIXED_SCORE_TAG:         this.riskRatingQuestionsForm.get('FIXED_SCORE_TAG')?.value,
        FIXED_SCORE:             this.riskRatingQuestionsForm.get('FIXED_SCORE')?.value,
        YES_SCORE:               this.riskRatingQuestionsForm.get('YES_SCORE')?.value,
        NO_SCORE:                this.riskRatingQuestionsForm.get('NO_SCORE')?.value,
        NA_SCORE:                this.riskRatingQuestionsForm.get('NA_SCORE')?.value,
        COLUMN_FIELD:            this.riskRatingQuestionsForm.get('COLUMN_FIELD')?.value,
        EFF_DATE_FROM:           this.riskRatingQuestionsForm.get('EFF_DATE_FROM')?.value,
        EFF_DATE_TO:             this.riskRatingQuestionsForm.get('EFF_DATE_TO')?.value,
        ACTIVE_TAG:              this.riskRatingQuestionsForm.get('ACTIVE_TAG')?.value,
        REMARKS:                 this.riskRatingQuestionsForm.get('REMARKS')?.value,
        USER_ID:              this.userId,
      });
    } catch(e) {
    }
  }

  addRow() {
    try {
      if(this.validateInsert()) {
        this.formService.hideFormMsg("risk-rating-questions-error-message");
        this.tableInserter();
        this.tempAddInserter();
        this.formClearer();
        this.checkFixedScoreTag();
        this.saveDisabler = false;
      } else {
        this.errorMessageSetter();
      }
    } catch(e) {
    }
  }

  updateTable() {
    try {
      let updateIndex = this.riskRatingQuestionsTable.tblData.indexOf(this.selectedRow);
      const rowData = this.riskRatingQuestionsTable.tblData[updateIndex];
      this.riskRatingQuestionsTable.tblData[updateIndex] = {
        QUESTION_NO:             this.riskRatingQuestionsForm.get('QUESTION_NO')?.value,
        QUESTION:                this.riskRatingQuestionsForm.get('QUESTION')?.value,
        FIXED_SCORE_TAG:         this.riskRatingQuestionsForm.get('FIXED_SCORE_TAG')?.value,
        FIXED_SCORE:             this.riskRatingQuestionsForm.get('FIXED_SCORE')?.value,
        YES_SCORE:               this.riskRatingQuestionsForm.get('YES_SCORE')?.value,
        NO_SCORE:                this.riskRatingQuestionsForm.get('NO_SCORE')?.value,
        NA_SCORE:                this.riskRatingQuestionsForm.get('NA_SCORE')?.value,
        COLUMN_FIELD:            this.riskRatingQuestionsForm.get('COLUMN_FIELD')?.value,
        EFF_DATE_FROM:           this.riskRatingQuestionsForm.get('EFF_DATE_FROM')?.value,
        EFF_DATE_TO:             this.riskRatingQuestionsForm.get('EFF_DATE_TO')?.value,
        ACTIVE_TAG:              this.riskRatingQuestionsForm.get('ACTIVE_TAG')?.value,
        REMARKS:                 this.riskRatingQuestionsForm.get('REMARKS')?.value,
        USER_ID:                this.selectedRow.USER_ID,
        LAST_UPDATE:            this.selectedRow.LAST_UPDATE,
      }
      this.riskRatingQuestionsTable.tblData = [...this.riskRatingQuestionsTable.tblData];
    } catch(e) {
    }
  }

  updateNewData() {
    try {
      let tempNewConfigdata = this.tempAddData.find(
        (riskRatingQuestions: { QUESTION_NO: any }) => riskRatingQuestions.QUESTION_NO == this.selectedRow.QUESTION_NO
      );

      tempNewConfigdata.QUESTION_NO         = this.riskRatingQuestionsForm.get('QUESTION_NO')?.value;
      tempNewConfigdata.QUESTION            = this.riskRatingQuestionsForm.get('QUESTION')?.value;
      tempNewConfigdata.FIXED_SCORE_TAG     = this.riskRatingQuestionsForm.get('FIXED_SCORE_TAG')?.value;
      tempNewConfigdata.FIXED_SCORE         = this.riskRatingQuestionsForm.get('FIXED_SCORE')?.value || 0;
      tempNewConfigdata.YES_SCORE           = this.riskRatingQuestionsForm.get('YES_SCORE')?.value || 0;
      tempNewConfigdata.NO_SCORE            = this.riskRatingQuestionsForm.get('NO_SCORE')?.value || 0;
      tempNewConfigdata.NA_SCORE            = this.riskRatingQuestionsForm.get('NA_SCORE')?.value || 0;
      tempNewConfigdata.COLUMN_FIELD        = this.riskRatingQuestionsForm.get('COLUMN_FIELD')?.value;
      tempNewConfigdata.EFF_DATE_FROM       = this.riskRatingQuestionsForm.get('EFF_DATE_FROM')?.value;
      tempNewConfigdata.EFF_DATE_TO         = this.riskRatingQuestionsForm.get('EFF_DATE_TO')?.value;
      tempNewConfigdata.ACTIVE_TAG          = this.riskRatingQuestionsForm.get('ACTIVE_TAG')?.value;
      tempNewConfigdata.REMARKS             = this.riskRatingQuestionsForm.get('REMARKS')?.value;
    } catch(e) {
    }
  }

  updateDbData() {
    try {
      let tempDbData = this.tempUpdateData.find(
        (riskProfile: { QUESTION_NO: any }) => riskProfile.QUESTION_NO == this.selectedRow.QUESTION_NO
      );
      if(this.tempUpdateData == '' || this.tempUpdateData == null) {
        this.tempUpdateInserter();
      } else if(tempDbData=='' || tempDbData==null) {
        this.tempUpdateInserter();
      } else {
        tempDbData.QUESTION_NO         = this.riskRatingQuestionsForm.get('QUESTION_NO')?.value;
        tempDbData.QUESTION            = this.riskRatingQuestionsForm.get('QUESTION')?.value;
        tempDbData.FIXED_SCORE_TAG     = this.riskRatingQuestionsForm.get('FIXED_SCORE_TAG')?.value;
        tempDbData.FIXED_SCORE         = this.riskRatingQuestionsForm.get('FIXED_SCORE')?.value || 0;
        tempDbData.YES_SCORE           = this.riskRatingQuestionsForm.get('YES_SCORE')?.value || 0;
        tempDbData.NO_SCORE            = this.riskRatingQuestionsForm.get('NO_SCORE')?.value || 0;
        tempDbData.NA_SCORE            = this.riskRatingQuestionsForm.get('NA_SCORE')?.value || 0;
        tempDbData.COLUMN_FIELD        = this.riskRatingQuestionsForm.get('COLUMN_FIELD')?.value;
        tempDbData.EFF_DATE_FROM       = this.riskRatingQuestionsForm.get('EFF_DATE_FROM')?.value;
        tempDbData.EFF_DATE_TO         = this.riskRatingQuestionsForm.get('EFF_DATE_TO')?.value;
        tempDbData.ACTIVE_TAG          = this.riskRatingQuestionsForm.get('ACTIVE_TAG')?.value;
        tempDbData.REMARKS             = this.riskRatingQuestionsForm.get('REMARKS')?.value;
        tempDbData.USER_ID             = this.selectedRow.USER_ID;
      }
    } catch(e) {
    }
  }

  onUpdate() {
    try {
      if(this.selectedRow.QUESTION == this.riskRatingQuestionsForm.get('QUESTION')?.value) {
        this.riskRatingQuestionsForm.get('QUESTION')?.clearValidators();
        this.riskRatingQuestionsForm.get('QUESTION')?.setValidators(Validators.required);
        this.riskRatingQuestionsForm.get('QUESTION')?.updateValueAndValidity();
      }
      if(this.validateInsert()) {
        this.updateTable();
        if(this.deleteDisabler) {
          this.updateDbData();
        } else {
          this.updateNewData();
        }
        this.selectedRow = '';
        this.addUpdateFlag = true;
        this.formClearer();
        this.checkFixedScoreTag();
        this.saveDisabler = false;
        this.deleteEnabler();
        this.formService.hideFormMsg('risk-rating-questions-error-message');
      } else {
        this.errorMessageSetter();
      }
      this.riskRatingQuestionsForm.get('QUESTION')?.setValidators([Validators.required, this.duplicateRiskRatingQuestion()]);
      this.riskRatingQuestionsForm.get('QUESTION')?.updateValueAndValidity();
    } catch(e) {
    }
  }

  deleteEnabler() {
    try {
      let tempDeleteFlag = this.tempAddData.filter((e: any) => {
        return e.QUESTION == this.selectedRow.QUESTION
      });
      if(tempDeleteFlag=='' || tempDeleteFlag==null) {
        this.deleteDisabler = true;
      } else {
        this.deleteDisabler = false;
      }
    } catch(e) {
    }
  }

  deleteRow() {
    try {
      let clickedData = this.selectedRow;
      this.riskRatingQuestionsTable.tblData = this.riskRatingQuestionsTable.tblData.filter(function(e: any) {
        return e.QUESTION != clickedData.QUESTION;
      });
      this.tempAddData = this.tempAddData.filter(function(e: any) {
        return e.QUESTION != clickedData.QUESTION;
      });
      this.selectedRow = '';
      this.formClearer();
      this.deleteDisabler = true;
      this.addUpdateFlag = true;
      if((this.tempAddData=='' || this.tempAddData==null) && (this.tempUpdateData=='' || this.tempUpdateData==null)) {
        this.saveDisabler = true;
      }
    } catch(e) {
    }
  }

  onSave() {
    try {
      this.removePrefixZero();
      this.formHider = true;
      this.formService.showFormLoader(null, "risk-rating-questions-form", "Saving.<br>Please wait ...", null, null);
      let saveData = this.tempAddData.concat(this.tempUpdateData);
      saveData.forEach((e:any)=>{
        e.EFF_DATE_FROM = this.datePipe.transform(e.EFF_DATE_FROM, 'MM/dd/YYYY');
        e.EFF_DATE_TO = this.datePipe.transform(e.EFF_DATE_TO, 'MM/dd/YYYY');
      });
      this.api.saveRiskRatingQuestions(saveData).subscribe({
        next: (res: any) => {
          this.securityService.checkRequestKeyResponse(res, () => {
            this.securityService.hasValidCsrfToken(res, () => {
              if(res.status == 'SUCCESS') {
                this.onComplete();
                this.openSaveDialog();
              } else {
                this.openErrorDialog('Saving risk profile details failed.');
              }
            });
          });
        },
        error: () => {
          this.jsonDataService.contorlLoading(false);
          this.openErrorDialog('Saving risk profile details failed.');
        }
      });
    } catch(e) {
    }
  }

  onComplete() {
    try {
      this.formService.hideFormLoader("risk-rating-questions-form");
      this.formHider = false;
      this.tempAddData = [];
      this.tempUpdateData = [];
      this.formClearer();
      this.getRiskRatingQuestions();
      this.saveDisabler = true;
      this.checkFixedScoreTag();
      this.formService.hideFormMsg("risk-rating-questions-error-message");
    } catch(e) {
    }
  }

  openErrorDialog(message: string) {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'ERROR',
          content: message,
        }
      });
    } catch(e) {
    }
  }

  onFail() {
    try {
      this.tempAddData = [];
      this.tempUpdateData = [];
      this.getRiskRatingQuestions();
      this.formService.hideFormLoader("risk-rating-questions-form");
      this.formHider = false;
      this.openErrorDialog('Saving risk rating details failed.');
    } catch(e) {
    }
  }

  openSaveDialog() {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'SUCCESS',
          content: 'Risk Rating details Successfully saved!',
        }
      });
    } catch(e) {
    }
  }

  duplicateRiskRatingQuestion(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      let riskRatingQuestionDuplicate = this.riskRatingQuestionsTable.tblData.filter(function(e: any) {
        return e.QUESTION.trim()==control.value.trim();
      });
      if(riskRatingQuestionDuplicate!='') {
        return { "riskRatingQuestionDuplicate": { value: control.value } }
      }
      return null;
    }
  }

  currCdField: string = '';
  questionDupCheck() {
    try {
      let currCdForm = this.riskRatingQuestionsForm.get('QUESTION')?.value.trim().toUpperCase() ?? "";
      let currCdForm2 = this.riskRatingQuestionsForm.get('QUESTION_NO')?.value.trim().toUpperCase() ?? "";
      this.currCdField = this.riskRatingQuestionsTable.tblData.filter(function(e: any) {
        return e.QUESTION.trim().toUpperCase()==currCdForm&&(currCdForm2==""||e.QUESTION_NO!=currCdForm2);
      })
      if(this.currCdField=='' || this.currCdField==null||this.currCdField.length==0) {
        return false;
      } else {
        this.formService.showFormMsg("risk-rating-questions-error-message", 'There are missing information. Please provide necessary information needed', "E");
        return true;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }

  removePrefixZero() {
    this.tempUpdateData = this.tempUpdateData.map((data: any) => {
      return { ...data, RISK_PROF_CD: parseInt(data.RISK_PROF_CD, 10) };
    });
  }

  allowNumericDigitsOnlyWODecimal(event: any) {
    try {
      if (/[0-9]/.test(String.fromCharCode(event.keyCode))) {
        return true;
      }
      else {
        event.preventDefault();
        return false;
      }
    }
    catch (e) {
    console.error(e);
      return undefined; 
    }
  }
  
  effDateToCheck(){
    try{
      let pubUserStdRatesEffDateFrom=new Date(this.riskRatingQuestionsForm.get('EFF_DATE_FROM')?.value).getTime();
      if(this.riskRatingQuestionsForm.get('EFF_DATE_TO')?.value!==''&&this.riskRatingQuestionsForm.get('EFF_DATE_TO')?.value!==null){
        let pubUserStdRatesEffDateTo= new Date(this.riskRatingQuestionsForm.get('EFF_DATE_TO')?.value).getTime()
        if(pubUserStdRatesEffDateTo<pubUserStdRatesEffDateFrom){
          return true
        }
        else{
          return false
        }
      }
      else{
        return false;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }

  allScoreRequired:boolean = true;
  
  checkFixedScoreTagOnRowClick(){
    if (this.selectedRow.FIXED_SCORE_TAG=="Y") {
      this.riskRatingQuestionsForm.controls['FIXED_SCORE'].setValidators([Validators.required]);
      this.riskRatingQuestionsForm.controls['YES_SCORE'].clearValidators();
      this.riskRatingQuestionsForm.controls['NO_SCORE'].clearValidators();
      this.riskRatingQuestionsForm.controls['NA_SCORE'].clearValidators();
        this.riskRatingQuestionsForm.patchValue({
          YES_SCORE: 0,
          NO_SCORE: 0,
          NA_SCORE: 0
        });
      this.allScoreRequired = true;
    }
    else if((this.selectedRow.FIXED_SCORE_TAG=="N")){
      this.riskRatingQuestionsForm.controls['FIXED_SCORE'].clearValidators();
      this.riskRatingQuestionsForm.controls['YES_SCORE'].setValidators([Validators.required]);
      this.riskRatingQuestionsForm.controls['NO_SCORE'].setValidators([Validators.required]);
      this.riskRatingQuestionsForm.controls['NA_SCORE'].setValidators([Validators.required]);
      this.riskRatingQuestionsForm.patchValue({
        FIXED_SCORE: 0
      });
      this.allScoreRequired = false;
    }
    else{
      this.riskRatingQuestionsForm.controls['FIXED_SCORE'].clearValidators();
      this.riskRatingQuestionsForm.controls['YES_SCORE'].setValidators([Validators.required]);
      this.riskRatingQuestionsForm.controls['NO_SCORE'].setValidators([Validators.required]);
      this.riskRatingQuestionsForm.controls['NA_SCORE'].setValidators([Validators.required]);
      this.riskRatingQuestionsForm.patchValue({
        FIXED_SCORE: 0
      });
      this.allScoreRequired = false;
    }
    
    this.riskRatingQuestionsForm.controls['FIXED_SCORE'].updateValueAndValidity();
    this.riskRatingQuestionsForm.controls['YES_SCORE'].updateValueAndValidity();
    this.riskRatingQuestionsForm.controls['NO_SCORE'].updateValueAndValidity();
    this.riskRatingQuestionsForm.controls['NA_SCORE'].updateValueAndValidity();
  }

  checkFixedScoreTag() {
    if (this.riskRatingQuestionsForm.get('FIXED_SCORE_TAG')?.value=="Y") {
      this.riskRatingQuestionsForm.controls['FIXED_SCORE'].setValidators([Validators.required]);
      this.riskRatingQuestionsForm.controls['YES_SCORE'].clearValidators();
      this.riskRatingQuestionsForm.controls['NO_SCORE'].clearValidators();
      this.riskRatingQuestionsForm.controls['NA_SCORE'].clearValidators();
      this.riskRatingQuestionsForm.patchValue({
        FIXED_SCORE: null,
        YES_SCORE: 0,
        NO_SCORE: 0,
        NA_SCORE: 0
      });
      
      this.allScoreRequired = true;
    }
    else if((this.riskRatingQuestionsForm.get('FIXED_SCORE_TAG')?.value=="N")){
      this.riskRatingQuestionsForm.controls['FIXED_SCORE'].clearValidators();
      this.riskRatingQuestionsForm.controls['YES_SCORE'].setValidators([Validators.required]);
      this.riskRatingQuestionsForm.controls['NO_SCORE'].setValidators([Validators.required]);
      this.riskRatingQuestionsForm.controls['NA_SCORE'].setValidators([Validators.required]);
      this.riskRatingQuestionsForm.patchValue({
        FIXED_SCORE: 0,
        YES_SCORE: null,
        NO_SCORE: null,
        NA_SCORE: null
      });
      this.allScoreRequired = false;
    }
    else{
      this.riskRatingQuestionsForm.controls['FIXED_SCORE'].clearValidators();
      this.riskRatingQuestionsForm.controls['YES_SCORE'].setValidators([Validators.required]);
      this.riskRatingQuestionsForm.controls['NO_SCORE'].setValidators([Validators.required]);
      this.riskRatingQuestionsForm.controls['NA_SCORE'].setValidators([Validators.required]);
      this.riskRatingQuestionsForm.patchValue({
        FIXED_SCORE: 0,
        YES_SCORE: null,
        NO_SCORE: null,
        NA_SCORE: null
      });
      this.allScoreRequired = false;
    }
    
    this.riskRatingQuestionsForm.controls['FIXED_SCORE'].updateValueAndValidity();
    this.riskRatingQuestionsForm.controls['YES_SCORE'].updateValueAndValidity();
    this.riskRatingQuestionsForm.controls['NO_SCORE'].updateValueAndValidity();
    this.riskRatingQuestionsForm.controls['NA_SCORE'].updateValueAndValidity();
  }
  
  effDatesValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const effDateFrom = this.riskRatingQuestionsForm?.get("EFF_DATE_FROM")?.value;
      const effDateTo = this.riskRatingQuestionsForm?.get("EFF_DATE_TO")?.value;
      if(effDateFrom && effDateTo && effDateTo.isBefore(effDateFrom)) {
        return { "effDate": "Expiry Date must not be earlier than Effective Date." }
      }
      return null;
    }
  }

  
getDateNum(dateInput:Date){
  if(dateInput==null){
    return 0;
  }
  let year = dateInput.getFullYear();
  let month = dateInput.getMonth()+1;
  let day = dateInput.getDate();
  let resultString = year+""+this.padLeadingZeros(month, 2)+""+this.padLeadingZeros(day, 2);
  
  let result = parseInt(resultString);
  return result;
}

padLeadingZeros(num:number, size:number) {
  let sNum = num+"";
  while (sNum.length < size) sNum = "0" + sNum;
  return sNum;
}
}
