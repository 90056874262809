import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

@Component({
  selector: 'risk-profile-lov',
  templateUrl: './risk-profile-lov.component.html',
  styleUrls: ['./risk-profile-lov.component.css']
})
export class RiskProfileLovComponent implements OnInit {

  private projectModuleId: string = 'BMM021';
  private selectedData: any;
  public titleLov: string = "Risk Profile".toUpperCase();

  riskProfTable: any = {
    cols: [
      {
        key: "RISK_PROF_CD",
        header: "Risk Profile Code",
        dataType: "string"
      },
      {
        key: "RISK_PROF_DESC",
        header: "Risk Profile Name",
        dataType: "string"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  };

  constructor(
    private jsonDataService: JsonDataService,
    private dialogRef: MatDialogRef<RiskProfileLovComponent>,
    private _api: APICallService,
    private securityService: SecurityService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    @Inject(MAT_DIALOG_DATA) public fromMainData: any,
  ) {
    this.getRiskProfileData();
  }

  ngOnInit(): void {
  }

  onRowClick(ev: any) {
    try {
      this.selectedData = ev;
    } catch (e) {
    console.error(e);
      
    }
  }

  private getRiskProfileData(): void {
    try {
      this.riskProfTable.loading = true;
      this.jsonDataService.contorlLoading(true);
      this._api.getRiskProfile({
        lineCd: this.fromMainData.lineCd,
        moduleId: this.fromMainData.moduleId || this.projectModuleId,
        userId: this.userDetailService.userId,
        type: "LOV"
      }).subscribe({
        next: (data: any) => {
          this.securityService.checkRequestKeyResponse(data, () => {
            this.securityService.hasValidCsrfToken(data, () => {
            let content = JSON.parse(this.jsonDataService.decrypt(data.response));
              this.jsonDataService.contorlLoading(false);
              this.riskProfTable.tblData = content.filter((e: any) => e.ACTIVE_TAG == 'A');
              this.riskProfTable.loading = false;
            });
          });
        },
        error: (e: any) => {
          this.securityService.checkRequestKeyResponse(e, () => {
            
            this.riskProfTable.loading = false;
            this.jsonDataService.contorlLoading(false);
            this.appMessageService.showAppMessage(e, "error");
          });
        }
      });
    }
    catch (e) {
    console.error(e);
      
      this.riskProfTable.loading = false;
      this.jsonDataService.contorlLoading(false);
    }
  }

  onClose(str: string) {
    try {
      if (this.selectedData == '' || this.selectedData == null) {
        this.dialogRef.close({ content: '', button: str });
      }
      else {
        this.dialogRef.close({ content: this.selectedData, button: str });
      }
    } catch (e) {
    console.error(e);
      
    }
  }

}
