<div class="mtn-title">
    <p>{{data.title}}</p>
</div>
<div id="deploy-changes-loading">
    <div class="form-loader"></div>
</div>
<ng-container *ngIf="showStatus">
    {{data.content}}
    <div class='button'>
        <button mat-button class="btn2" type="button" mat-dialog-close>OK</button>
    </div> 
</ng-container>
