import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { CustomValidators } from 'src/app/utils/custom-validator';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';

@Component({
  selector: 'client-group-maintenance',
  templateUrl: './client-group-maintenance.component.html',
  styleUrls: ['./client-group-maintenance.component.css']
})
export class ClientGroupMaintenanceComponent implements OnInit, LeaveConfirmation {

  public moduleId: string = 'BMM061';
  public moduleName: string = this.jsonDataService.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc?.toUpperCase() ?? "";
  selectedRowData: any;
  errorMessage: string = "";
  userId = this.jsonDataService.retrieveFromStorage('userId');
  formHider: boolean = false;
  datePipe = new DatePipe('en-ph');

  //Button Togglers
  addUpdateToggler: boolean = true;
  deleteDisabler: boolean = true;
  saveDisabler: boolean = true;

  //Temp Data
  tempAddData: any = [];
  tempUpdateData: any = [];

  clientGroupTable: any = {
    cols: [
      {
        key: "CLIENT_CD",
        header: "Client Code",
        dataType: "string"
      },
      {
        key: "CLIENT_DESC",
        header: "Client Description",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "Active",
        dataType: "checkbox"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  clientGroupForm = this.fb.group({
    ACTIVE_TAG: ['A', Validators.required],
    CLIENT_CD: ['', [Validators.required, this.duplicateClientGroup(), CustomValidators.requiredTrim]],
    CLIENT_DESC: ['', [Validators.required, CustomValidators.requiredTrim]],
    BM_CODE: ['', [Validators.required, CustomValidators.requiredTrim, this.duplicateBmCode()]],
    AMB_CHARGE: ['', [this.checkDecimalsExceeded(14, 2)]],
    NON_CASA_PD: [''],
    UPPA_TEXT: [''],
    OTHER_RISK_DTL: [''],
    REMARKS: [''],
    USER_ID: [{ value: '', disabled: true }],
    LAST_UPDATE: [{ value: '', disabled: true }],
  });

  constructor(
    public formService: FormService,
    private jsonDataService: JsonDataService,
    private fb: FormBuilder,
    private api: APICallService,
    private dialog: MatDialog,
    private _securityService: SecurityService,
    private _userDetailService: UserDetailsService,
  ) { }

  canDeactivate(): Observable<boolean> | boolean {
    if (this.tempAddData != '' || this.tempUpdateData != '') {
      return false;
    } else {
      return true;
    }
  }

  ngOnInit(): void {
    try {
      this.getClientGroup();
    } catch (e) {
    console.error(e);
    }
  }

  onRowClick(ev: any) {
    try {
      this.selectedRowData = ev;
      if (ev != null) {
        this.formLoader();
        this.addUpdateToggler = false;
      } else {
        this.formClearer();
        this.addUpdateToggler = true;
      }
      this.deleteEnabler();
    } catch (e) {
    console.error(e);
    }
  }

  addRow() {
    try {
      if (this.clientGroupForm.valid) {
        this.formService.hideFormMsg("client-group-error-message");
        this.tableInserter();
        this.tempAddInserter();
        this.formClearer();
        this.saveDisabler = false;
      } else {
        this.errorMessageSetter();
      }
    } catch (e) {
    console.error(e);
    }
  }

  onUpdate() {
    try {
      if (this.clientGroupForm.valid) {
        this.updateTable();
        if (this.deleteDisabler) {
          this.updateDbData();
        } else {
          this.updateNewData();
        }
        this.selectedRowData = '';
        this.addUpdateToggler = true;
        this.formClearer();
        this.saveDisabler = false;
        this.deleteEnabler();
        this.formService.hideFormMsg('client-group-error-message');
      } else {
        this.errorMessageSetter();
      }
    } catch (e) {
    console.error(e);
    }
  }

  deleteRow() {
    try {
      let clickedData = this.selectedRowData;
      this.clientGroupTable.tblData = this.clientGroupTable.tblData.filter(function (e: any) {
        return e.CLIENT_CD != clickedData.CLIENT_CD;
      });
      this.tempAddData = this.tempAddData.filter(function (e: any) {
        return e.CLIENT_CD != clickedData.CLIENT_CD;
      });
      this.selectedRowData = '';
      this.formClearer();
      this.deleteDisabler = true;
      this.addUpdateToggler = true;
      if ((this.tempAddData == '' || this.tempAddData == null) && (this.tempUpdateData == '' || this.tempUpdateData == null)) {
        this.saveDisabler = true;
      }
    } catch (e) {
    console.error(e);
    }
  }

  onSave() {
    try {
      this.formHider = true;
      this.jsonDataService.contorlLoading(true);
      this.formService.showFormLoader(null, "client-group-form", "Saving.<br>Please wait ...", null, null, true);
      let toBeSaved = [...this.tempAddData, ...this.tempUpdateData];
      this.api.saveClientGroup(toBeSaved).subscribe({
        next: (response: any) => {
          this._securityService.checkRequestKeyResponse(response, () => {
            this._securityService.hasValidCsrfToken(response, () => {
              this.jsonDataService.contorlLoading(false);
              if (response.status == 'SUCCESS') {
                this.onComplete();
                this.openSaveDialog();
              } else {
                this.openErrorDialog('Saving client group details failed.');
                this.onComplete();
              }
            });
          });
        },
        error: (data: any) => {
          this._securityService.checkRequestKeyResponse(data, () => {
            this.openErrorDialog('Saving client group details failed.');
          });
        }
      });
      this.tempAddData = [];
      this.tempUpdateData = [];
    } catch (e) {
    console.error(e);
    }
  }

  duplicateClientGroup(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let clientGroupDuplicate = this.clientGroupTable.tblData.filter(function (e: any) {
        return e.CLIENT_CD?.toUpperCase()?.trim() == control.value?.toUpperCase()?.trim();
      });
      if (clientGroupDuplicate != '') {
        return { "clientGroupDuplicateCode": { value: control.value } }
      }
      return null;
    }
  }

  duplicateBmCode(): ValidatorFn {
    const trimLeadingZeros = (str: string) => str?.replace(/^0+/, '');
    return (control: AbstractControl): { [key: string]: any } | null => {
      let bmDuplicate = this.clientGroupTable.tblData.filter( (e: any) => {
        return trimLeadingZeros(e.BM_CODE)?.toUpperCase()?.trim() === trimLeadingZeros(control.value)?.toUpperCase()?.trim() && control.value !== this.selectedRowData?.BM_CODE;
      });
      if (bmDuplicate != '') {
        return { "bmDuplicateCode": { value: control.value } }
      }
      return null;
    }
  }

  private checkDecimalsExceeded(digits: number, decimals: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value && parseFloat(this.formService.setToNumberWithDecimal(control.value));
      if (!value) return null;
      let isExceeded: boolean = value > +"".padEnd(digits, '9') + +('.' + ''.padEnd(decimals, '9'))
      return isExceeded ? { hasDecimalsExceeded: true } : null;
    }
  }

  getClientGroup() {
    try {
      this.clientGroupTable.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.api.getClientGroup(
        {
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          type: "MODULE",
        }
      ).subscribe({
        next: (response: any) => {
          this._securityService.checkRequestKeyResponse(response, () => {
            this._securityService.hasValidCsrfToken(response, () => {
              if (response.status === "SUCCESS") {
                let content = JSON.parse(this.jsonDataService.decrypt(response?.response));
                this.clientGroupTable.tblData = content.data;
                this.jsonDataService.contorlLoading(false);
                this.clientGroupTable.loading = false;
              }
              else {
                this.jsonDataService.contorlLoading(false);
                this.clientGroupTable.loading = false;
              }
            });
          });
        },
        error: (e: any) => {
          this._securityService.checkRequestKeyResponse(e, () => {
            this.jsonDataService.contorlLoading(false);
            this.clientGroupTable.loading = false;
          });
        }
      });
    } catch (e) {
    console.error(e);
    }
  }

  formLoader() {
    try {
      this.clientGroupForm.get('CLIENT_CD')?.disable();
      this.clientGroupForm.patchValue({
        ACTIVE_TAG: this.selectedRowData.ACTIVE_TAG,
        CLIENT_CD: this.selectedRowData.CLIENT_CD,
        CLIENT_DESC: this.selectedRowData.CLIENT_DESC,
        AMB_CHARGE: !isNaN(parseFloat(this.selectedRowData.AMB_CHARGE)) ? this.formService.setToAmountFormat(this.selectedRowData.AMB_CHARGE) : this.selectedRowData.AMB_CHARGE,
        NON_CASA_PD: this.selectedRowData.NON_CASA_PD,
        UPPA_TEXT: this.selectedRowData.UPPA_TEXT,
        OTHER_RISK_DTL: this.selectedRowData.OTHER_RISK_DTL,
        BM_CODE: this.selectedRowData.BM_CODE,
        LAST_UPDATE: this.selectedRowData.LAST_UPDATE == "0000-00-00 00:00:00" ? "00-00-0000 00:00:00" : this.datePipe.transform(this.selectedRowData.LAST_UPDATE, 'MM/dd/YYYY h:mm:ss a', '+0000'),
        REMARKS: this.selectedRowData.REMARKS,
        USER_ID: this.selectedRowData.USER_ID,
      });
    } catch (e) {
    console.error(e);
    }
  }

  formClearer() {
    try {
      this.clientGroupForm.get('CLIENT_CD')?.enable();
      this.clientGroupForm.patchValue({
        ACTIVE_TAG: 'A',
        CLIENT_CD: '',
        CLIENT_DESC: '',
        AMB_CHARGE: '',
        NON_CASA_PD: '',
        UPPA_TEXT: '',
        OTHER_RISK_DTL: '',
        BM_CODE: '',
        LAST_UPDATE: '',
        REMARKS: '',
        USER_ID: '',
      });
    } catch (e) {
    console.error(e);
    }
  }

  deleteEnabler() {
    try {
      if (this.selectedRowData != null) {
        let tempDeleteFlag = this.tempAddData.filter((e: any) => {
          return e.CLIENT_CD == this.selectedRowData.CLIENT_CD
        });
        if (tempDeleteFlag == '' || tempDeleteFlag == null) {
          this.deleteDisabler = true;
        } else {
          this.deleteDisabler = false;
        }
      } else {
        this.deleteDisabler = true;
      }

    } catch (e) {
    console.error(e);
    }
  }

  tableInserter() {
    try {
      this.clientGroupTable.tblData.unshift({
        ACTIVE_TAG: this.clientGroupForm.get('ACTIVE_TAG')?.value,
        CLIENT_CD: this.clientGroupForm.get('CLIENT_CD')?.value && this.clientGroupForm.get('CLIENT_CD')?.value.trim(),
        CLIENT_DESC: this.clientGroupForm.get('CLIENT_DESC')?.value,
        AMB_CHARGE: this.clientGroupForm.get('AMB_CHARGE')?.value && parseFloat(this.formService.setToNumberWithDecimal(this.clientGroupForm.get('AMB_CHARGE')?.value)),
        NON_CASA_PD: this.clientGroupForm.get('NON_CASA_PD')?.value,
        UPPA_TEXT: this.clientGroupForm.get('UPPA_TEXT')?.value,
        OTHER_RISK_DTL: this.clientGroupForm.get('OTHER_RISK_DTL')?.value,
        BM_CODE: this.clientGroupForm.get('BM_CODE')?.value,
        LAST_UPDATE: this.clientGroupForm.get('LAST_UPDATE')?.value,
        REMARKS: this.clientGroupForm.get('REMARKS')?.value,
        USER_ID: this.clientGroupForm.get('USER_ID')?.value,
      });
      this.clientGroupTable.tblData = [...this.clientGroupTable.tblData];
    } catch (e) {
    console.error(e);
    }
  }

  tempAddInserter() {
    try {
      this.tempAddData.push({
        ACTIVE_TAG: this.clientGroupForm.get('ACTIVE_TAG')?.value,
        CLIENT_CD: this.clientGroupForm.get('CLIENT_CD')?.value.trim(),
        BM_CODE: this.clientGroupForm.get('BM_CODE')?.value,
        CLIENT_DESC: this.clientGroupForm.get('CLIENT_DESC')?.value,
        AMB_CHARGE: this.clientGroupForm.get('AMB_CHARGE')?.value && parseFloat(this.formService.setToNumberWithDecimal(this.clientGroupForm.get('AMB_CHARGE')?.value)),
        NON_CASA_PD: this.clientGroupForm.get('NON_CASA_PD')?.value,
        UPPA_TEXT: this.clientGroupForm.get('UPPA_TEXT')?.value,
        OTHER_RISK_DTL: this.clientGroupForm.get('OTHER_RISK_DTL')?.value,
        REMARKS: this.clientGroupForm.get('REMARKS')?.value,
      });
    } catch (e) {
    console.error(e);
    }
  }

  errorMessageSetter() {
    try {
      if (this.clientGroupForm.invalid) {
        for (const field in this.clientGroupForm.controls) {
          const controlName = this.clientGroupForm.get(field);
          if (controlName?.errors?.hasDecimalsExceeded) {
            this.errorMessage = 'Invalid value in Ambulance Charge. The value entered exceeded the maximum limit.';
          }
          if (controlName?.errors?.required) {
            this.errorMessage = 'There are missing information. Please provide necessary information needed.';
          }
          if (controlName?.errors?.clientGroupDuplicateCode) {
            this.errorMessage = 'Client Code already exists. Please add a unique Client Code.';
          }
          if (controlName?.errors?.bmDuplicateCode) {
            this.errorMessage = 'BPI MS Code already exists. Please add a unique BPI MS Code.';
          }
          this.formService.showFormMsg("client-group-error-message", this.errorMessage, "E");
        }
      }
    } catch (e) {
    console.error(e);
    }
  }

  updateTable() {
    try {
      let updateIndex = this.clientGroupTable.tblData.indexOf(this.selectedRowData);
      this.clientGroupTable.tblData[updateIndex] = {
        ACTIVE_TAG: this.clientGroupForm.get('ACTIVE_TAG')?.value,
        CLIENT_CD: this.clientGroupForm.get('CLIENT_CD')?.value,
        CLIENT_DESC: this.clientGroupForm.get('CLIENT_DESC')?.value,
        AMB_CHARGE: this.clientGroupForm.get('AMB_CHARGE')?.value && parseFloat(this.formService.setToNumberWithDecimal(this.clientGroupForm.get('AMB_CHARGE')?.value)),
        NON_CASA_PD: this.clientGroupForm.get('NON_CASA_PD')?.value,
        UPPA_TEXT: this.clientGroupForm.get('UPPA_TEXT')?.value,
        OTHER_RISK_DTL: this.clientGroupForm.get('OTHER_RISK_DTL')?.value,
        BM_CODE: this.clientGroupForm.get('BM_CODE')?.value,
        LAST_UPDATE: this.clientGroupForm.get('LAST_UPDATE')?.value,
        REMARKS: this.clientGroupForm.get('REMARKS')?.value,
        USER_ID: this.clientGroupForm.get('USER_ID')?.value,
      }
      this.clientGroupTable.tblData = [...this.clientGroupTable.tblData];
    } catch (e) {
    console.error(e);
    }
  }

  updateDbData() {
    try {
      let tempDbData = this.tempUpdateData.find(
        (clientGroup: { CLIENT_CD: any }) => clientGroup.CLIENT_CD == this.clientGroupForm.get('CLIENT_CD')?.value
      );
      if (this.tempUpdateData == '' || this.tempUpdateData == null) {
        this.tempUpdateInserter();
      } else if (tempDbData == '' || tempDbData == null) {
        this.tempUpdateInserter();
      } else {
        tempDbData.ACTIVE_TAG = this.clientGroupForm.get('ACTIVE_TAG')?.value;
        tempDbData.CLIENT_CD = this.clientGroupForm.get('CLIENT_CD')?.value;
        tempDbData.CLIENT_DESC = this.clientGroupForm.get('CLIENT_DESC')?.value;
        tempDbData.AMB_CHARGE = this.clientGroupForm.get('AMB_CHARGE')?.value && parseFloat(this.formService.setToNumberWithDecimal(this.clientGroupForm.get('AMB_CHARGE')?.value));
        tempDbData.NON_CASA_PD = this.clientGroupForm.get('NON_CASA_PD')?.value;
        tempDbData.UPPA_TEXT = this.clientGroupForm.get('UPPA_TEXT')?.value;
        tempDbData.OTHER_RISK_DTL = this.clientGroupForm.get('OTHER_RISK_DTL')?.value;
        tempDbData.BM_CODE = this.clientGroupForm.get('BM_CODE')?.value;
        tempDbData.REMARKS = this.clientGroupForm.get('REMARKS')?.value;
      }
    } catch (e) {
    console.error(e);
    }
  }

  tempUpdateInserter() {
    try {
      this.tempUpdateData.push({
        ACTIVE_TAG: this.clientGroupForm.get('ACTIVE_TAG')?.value,
        CLIENT_CD: this.clientGroupForm.get('CLIENT_CD')?.value,
        CLIENT_DESC: this.clientGroupForm.get('CLIENT_DESC')?.value,
        BM_CODE: this.clientGroupForm.get('BM_CODE')?.value,
        AMB_CHARGE: this.clientGroupForm.get('AMB_CHARGE')?.value && parseFloat(this.formService.setToNumberWithDecimal(this.clientGroupForm.get('AMB_CHARGE')?.value)),
        NON_CASA_PD: this.clientGroupForm.get('NON_CASA_PD')?.value,
        UPPA_TEXT: this.clientGroupForm.get('UPPA_TEXT')?.value,
        OTHER_RISK_DTL: this.clientGroupForm.get('OTHER_RISK_DTL')?.value,
        REMARKS: this.clientGroupForm.get('REMARKS')?.value,
      });
    } catch (e) {
    console.error(e);
    }
  }

  updateNewData() {
    try {
      let tempNewConfigdata = this.tempAddData.find(
        (clientGroup: { CLIENT_CD: any }) => clientGroup.CLIENT_CD == this.clientGroupForm.get('CLIENT_CD')?.value
      );
      tempNewConfigdata.ACTIVE_TAG = this.clientGroupForm.get('ACTIVE_TAG')?.value;
      tempNewConfigdata.CLIENT_CD = this.clientGroupForm.get('CLIENT_CD')?.value;
      tempNewConfigdata.CLIENT_DESC = this.clientGroupForm.get('CLIENT_DESC')?.value;
      tempNewConfigdata.AMB_CHARGE = this.clientGroupForm.get('AMB_CHARGE')?.value && parseFloat(this.formService.setToNumberWithDecimal(this.clientGroupForm.get('AMB_CHARGE')?.value));
      tempNewConfigdata.NON_CASA_PD = this.clientGroupForm.get('NON_CASA_PD')?.value;
      tempNewConfigdata.UPPA_TEXT = this.clientGroupForm.get('UPPA_TEXT')?.value;
      tempNewConfigdata.OTHER_RISK_DTL = this.clientGroupForm.get('OTHER_RISK_DTL')?.value;
      tempNewConfigdata.BM_CODE = this.clientGroupForm.get('BM_CODE')?.value;
      tempNewConfigdata.REMARKS = this.clientGroupForm.get('REMARKS')?.value;
    } catch (e) {
    console.error(e);
    }
  }

  onComplete() {
    try {
      this.formService.hideFormLoader("client-group-form");
      this.formHider = false;
      this.tempAddData = [];
      this.tempUpdateData = [];
      this.formClearer();
      this.getClientGroup();
    } catch (e) {
    console.error(e);
    }
  }

  openSaveDialog() {
    try {
      this.saveDisabler = true;
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'SUCCESS',
          content: 'Client group details Successfully saved!',
        }
      });
    } catch (e) {
    console.error(e);
    }
  }

  openErrorDialog(message: string) {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'ERROR',
          content: message,
        }
      });
    } catch (e) {
    console.error(e);
    }
  }

  public allowNumericDigitsOnly(event: any) {
    if (/[0-9.]/.test(String.fromCharCode(event.keyCode))) {
      return true;
    }
    else {
      event.preventDefault();
      return false;
    }
  }
}
