import { Component, OnInit } from '@angular/core';
import { JsonDataService } from 'src/app/services/json-data.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

@Component({
    selector: 'claim-record-upload',
    templateUrl: './claim-record-upload.component.html',
    styleUrls: ['./claim-record-upload.component.css']
})
export class ClaimRecordUpload implements OnInit {

    userId: string = this.userDetailService.userId;
    moduleId: string = 'BMA009';
    moduleName = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId === 'BMA009' })[0].moduleDesc.toUpperCase();

    constructor(
        private jsonDataService: JsonDataService,
        private userDetailService: UserDetailsService,
    ) { }

    ngOnInit(): void {
        try {
            
        } catch (e) {
            console.error('Error initializing component:', e);
        }
    }

    triggerUrl(): string {
        const hostname = window.location.hostname;
        let baseUrl = '';

        if (hostname === 'admin.osp-devt.com') {
            baseUrl = 'https://api-uat.bpims.com/uploaders/hcc.php';
        } else if (hostname === 'admin.osp-test.com') {
            baseUrl = 'https://api-uat.bpims.com/uploaders/hcc-uat.php';
        } else if (hostname === 'admin.express-protect.com') {
            baseUrl = 'https://api-uat.bpims.com/uploaders/hcc-uat.php'; // Must change the URL in production
        } else {
            console.error('Unknown hostname:', hostname);
            return '';
        }

        return baseUrl;
    }

    onButtonClick(): void {
        const url = this.triggerUrl();
        if (url) {
            window.open(url, '_blank');
        } else {
            console.error('Failed to determine the URL.');
        }
    }
}
