<section id="assured-id-type">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <osp-table [tblConfig]="assuredIdTypeTable" (onRowClick)="onRowClick($event)"></osp-table>
        <!-- <div id="assured-id-type-form"><div class="form-loader"></div></div> -->
        <div class="form-content" [hidden]="formHider">
            <form [formGroup]="assuredIdTypeForm" class="form">
                <div id="assure-id-type-error-message"></div>
                <div class="form-container-grid">

                    <div class="field">
                        <label>ID Type Code</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="ID_TYPE_CD" oninput="this.value = this.value.toUpperCase()">
                    </div>

                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="ACTIVE_TAG">
                            <option value="A">Yes</option>
                            <option value="I">No</option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>ID Type Description</label>
                    </div>
                    <div class="">
                        <input formControlName="ID_TYPE_DESC" maxlength="50">
                    </div>

                    <div class="field">
                        <label>Client Type</label>
                    </div>
                    <div class="">
                        <select formControlName="BR_TAG">
                            <option *ngFor="let clientType of clientTypeList" [value]="clientType.rvLowValue">{{clientType.rvMeaning}}</option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>RPA Code</label>
                    </div>
                    <div class="">
                        <input formControlName="BM_CODE" maxlength="8">
                    </div>

                    <div class="field required">
                        <label>Primary</label>
                    </div>
                    <div class="">
                        <select formControlName="PRIMARY_TAG">
                            <option value=""></option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>Optical Character Recognition</label>
                    </div>
                    <div class="">
                        <select formControlName="OCR_TAG">
                            <option value=""></option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>With Expiry Date</label>
                    </div>
                    <div class="">
                        <select formControlName="WITH_EXP_DATE">
                            <option value=""></option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                        </select>
                    </div>

                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="REMARKS" maxlength="4000">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input formControlName="USER_ID">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="LAST_UPDATE">
                    </div>
                </div>
            </form>
            <div class="btn-grp">
                <button type="button" class="btn btn2" [hidden]="!addUpdateToggler" (click)="addRow()">ADD</button>
                <button type="button" class="btn btn2" [hidden]="addUpdateToggler" (click)="onUpdate()">UPDATE</button>
                <button type="button" class="btn btn2" [disabled]="deleteDisabler" (click)="deleteTableRow()">DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-grp">
                <button type="button" class="btn btn2" [disabled]="saveDisabler" (click)="onSave()">SAVE</button>
            </div>
        </div>
    </div>
</section>