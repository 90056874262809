
<section id="change-password-dialog">
    <div class="mtn-title">
        USER INFORMATION
    </div>
    <div id="change-password-form"><div class="form-loader"></div></div>
    <div class="form-content" [hidden]="formHider">
        <form [formGroup]="changePasswordForm" class="form">
            <div class="form-container-grid">
                <div class="field">
                    <label>User ID</label>
                </div>
                <div class="">
                    <input type="text" formControlName="userId" readonly>
                </div>
                <div class="field">
                    <label>Name</label>
                </div>
                <div class="">
                    <input type="text" formControlName="name" readonly>
                </div>
                <div class="field">
                    <label>Email Address</label>
                </div>
                <div class="">
                    <input type="text" formControlName="email" readonly>
                </div>
                <div class="field required">
                    <label>Old Password</label>
                </div>
                <div class="">
                    <input [type]="showOldPasswordValue ? 'text' : 'password'" formControlName="oldPassword">
                    <button class="btn-search btn" mat-icon-button matSuffix (click)="showOldPasswordValue = !showOldPasswordValue">
                        <mat-icon>{{showOldPasswordValue ? 'visibility' : 'visibility_off'}}</mat-icon>
                    </button>
                </div>
                <div class="field required">
                    <label>New Password</label>
                </div>
                <div class="">
                    <input [type]="showNewPasswordValue ? 'text' : 'password'" formControlName="newPassword">
                    <button class="btn-search btn" mat-icon-button matSuffix (click)="showNewPasswordValue = !showNewPasswordValue">
                        <mat-icon>{{showNewPasswordValue ? 'visibility' : 'visibility_off'}}</mat-icon>
                    </button>
                </div>
                <div class="field required">
                    <label>Repeat Password</label>
                </div>
                <div class="">
                    <input [type]="showRepPasswordValue ? 'text' : 'password'" formControlName="repeatPassword">
                    <button class="btn-search btn" mat-icon-button matSuffix (click)="showRepPasswordValue = !showRepPasswordValue">
                        <mat-icon>{{showRepPasswordValue ? 'visibility' : 'visibility_off'}}</mat-icon>
                    </button>
                </div>
            </div>
            <div id="change-password-error-message"></div>
        </form>
        <div class="button">
            <button mat-raised-button class="btn2" type="button" mat-dialog-close>RETURN</button>
            <button mat-raised-button class="btn2" type="button" (click)="onSave()">SAVE</button>
        </div>
    </div>
</section>

