<section id="pre-approved-subdivision">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{this.moduleName}}</p>
        </div>
        <div class="form-content">
            <form [formGroup]="paramForm" class="form">
                <div class="form-container-grid top-form">
                    <div class="field required">
                        <label>Country</label>
                    </div>
                    <div class="lov-search">
                        <input readonly formControlName="countryField">
                        <button matSuffix class="btn-search btn" type="button" (click)="openCountryDialog()"><mat-icon>search</mat-icon></button>
                    </div>
                    <div class="field required">
                        <label>Province</label>
                    </div>
                    <div class="lov-search">
                        <input readonly formControlName="provinceField">
                        <button matSuffix class="btn-search btn" type="button" (click)="openProvinceDialog()" [disabled]="provinceDisabled"><mat-icon>search</mat-icon></button>
                    </div>
                    <div class="field required">
                        <label>City/Municipality</label>
                    </div>
                    <div class="lov-search">
                        <input readonly formControlName="cityField">
                        <button matSuffix class="btn-search btn" type="button" (click)="openCityDialog()" [disabled]="cityDisabled"><mat-icon>search</mat-icon></button>
                    </div>
                </div>
            </form>
        </div>
        <div class = 'table-line'>
            <osp-table [tblConfig]="preApprovedSubdivisionTblConfig" (onRowClick)="onRowClick($event)"></osp-table>
        </div>
        <!-- Loading Animation -->
        <div id="generate-line-form">
            <div class="form-loader"></div>
        </div>
        <!-- Container for Line Fields -->
        <div class="form-content">
            <form [formGroup]="preApprovedSubdivisionForm" class="form" *ngIf="noLoadingInProcess">
                <div id="pre-approved-subdivision-error-message"></div>
                <div class="form-container-grid">
                    <div class="field required">
                        <label>Code</label>
                    </div>
                    <div class="">
                        <input formControlName="subdivCode" type="text" readonly>
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag">
                            <option value="A" selected>Yes</option>
                            <option value="I">No</option>
                        </select>
                    </div>
                    <div class="field required">
                        <label>Subdivision Name</label>
                    </div>
                    <div class="span">
                        <input formControlName="subdivName" type="text" maxlength="1000">
                    </div>
                    <div class="field">
                        <label>BPI/MS Code</label>
                    </div>
                    <div class="">
                        <input formControlName="bpimsCode" type="text" maxlength="8" alphanumeric>
                    </div>
                    <div class="field">
                        <label>BPI/MS Short Description</label>
                    </div>
                    <div class="">
                        <input formControlName="bpimsShortDesc" type="text" maxlength="10" alphanumeric>
                    </div>
                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" type="text">
                    </div>
                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="userID">
                    </div>
                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUserUpdate">
                    </div>
                </div>
                <div class='btn-row'>
                    <button type="button" id='btn-add' *ngIf="!isLineSelected" title='Register a new line' class="btn btn2" value='ADD' type="submit" [disabled]='addDisabled' (click)="insertLine()">ADD</button>
                    <button type="button" id='btn-update' *ngIf="isLineSelected" title='Update a line' class="btn btn2" value='UPDATE' type="submit" (click)="updateLine()">UPDATE</button>
                    <button type="button" id='btn-delete' title='Remove a line' class="btn btn2" value='DELETE' type="submit" [disabled]='deleteDisabled' (click)="deleteLine()">DELETE</button>
                </div> 

                <div class="mtn-title"></div>

                <div class='btn-row'>
                    <button type="button" id='btn-save' title='Save changes on line details' class="btn btn2" value='SAVE' type="submit" (click)="toSave()" [disabled]='saveDisabled'>SAVE</button>
                </div>
            </form>
        </div>
    </div>
</section>