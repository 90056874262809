<section id="pol-renewal-wrap">
    <ng-container *ngIf="!view">
        <section id="pol-renewal-file-upload">
            <form [formGroup]="fileUpload">
                <div class="upload-wrap">
                    <div class="field required">
                        <label>File</label>
                    </div>
                    <label for="file-upload" class="file-upload-label">
                        <!-- {{ fileUpload.value.file == '' ? 'Choose file' :  }} -->
                        <ng-container *ngIf="fileUpload.value.file == ''"><span class="material-icons md-36">note_add</span></ng-container>
                        <ng-container *ngIf="fileUpload.value.file !== ''">{{ fileUpload.value.file?.name }}</ng-container>
                    </label>
                    <input #fileInput id="file-upload" type="file" (change)="fileHandlerFunction($event)">
                    <button class="btn btn2" (click)="newOnClickUpload()">Upload</button>
                </div>
                <div id="polRenewalFormMsg"></div>
            </form>
        </section>
        <section id="pol-renewal">
            <div>
                <div class="non-sp pad-bot">
                    <div class="card top-border border2" (click)="setSelectedStatus('all')" [class.selected]="selectedStatus === 'all'">
                        <h3>All Records</h3>
                    </div>
                    <div class="card top-border border2" (click)="setSelectedStatus('forReview')" [class.selected]="selectedStatus === 'forReview'">
                        <h3>For Review</h3>
                    </div>
                    <div class="card top-border border2" (click)="setSelectedStatus('forProcessing')" [class.selected]="selectedStatus === 'forProcessing'">
                        <h3>For Sending</h3>
                    </div>
                    <div class="card top-border border2" (click)="setSelectedStatus('sentRenewalNotice')" [class.selected]="selectedStatus === 'sentRenewalNotice'">
                        <h3>Sent to Client</h3>
                    </div>
                    <div class="card top-border border2" (click)="setSelectedStatus('forwardedToAgent')" [class.selected]="selectedStatus === 'forwardedToAgent'">
                        <h3>Sent to Agent/BFB/MS</h3>
                    </div>
                </div>
                <div class="non-sp pad-top">
                    <div class="card top-border border1" (click)="setSelectedStatus('failedSending')" [class.selected]="selectedStatus === 'failedSending'">
                        <h3>Failed Sending</h3>
                    </div>
                    <div class="card top-border border1" (click)="setSelectedStatus('blocked')" [class.selected]="selectedStatus === 'blocked'">
                        <h3>Blocked</h3>
                    </div>
                    <div class="card top-border border1" (click)="setSelectedStatus('cancelled')" [class.selected]="selectedStatus === 'cancelled'">
                        <h3>Cancelled</h3>
                    </div>
                    <div class="card top-border border1" (click)="setSelectedStatus('expired')" [class.selected]="selectedStatus === 'expired'">
                        <h3>Expired</h3>
                    </div>
                    <div class="card top-border border3" (click)="setSelectedStatus('renewed')" [class.selected]="selectedStatus === 'renewed'">
                        <h3>Renewed</h3>
                    </div>
                </div>
                <osp-table #tbl [tblConfig]="tblConfig" (onRowClick)="onRowClick($event)" (rowEvent)="rowEvent($event)" (selectAllConfirmation)="toggleSelectAll($event)" (onLazyLoad)="lazyLoad($event)"></osp-table>
            </div>
            <div class="legend-btn-container">
                <div class="legend-container">
                    <label><b>Legend:</b></label>
                    <br />
                    <label>&nbsp;&nbsp;<b>P</b> - Process for Renewal & Send Notice</label>
                    <label>&nbsp;&nbsp;<b>B</b> - With Premium Balance</label>
                    <label>&nbsp;&nbsp;<b>TL</b> - With Total Loss Claim</label>
                    <label>&nbsp;&nbsp;<b>BK</b> - Blocked for Renewal</label>
                </div>
                <div class="btn-container" *ngIf="showSendButton">
                    <!-- <button class="btn btn2" (click)="onClickSave()">Save</button> -->
                    <button class="btn btn2" (click)="onClickSendNotice()">Send</button>
                </div>
            </div>            
            <form [formGroup]="updateRenewal">
                <div class="form-wrapper">
                    <div class="field-wrapper-2">
                        <div class="box">
                            <div class="field">
                                <label>Email Address</label>
                            </div>
                            <div>
                                <input matInput type="text" formControlName="email">
                            </div>
                        </div>
                        <div class="box">
                            <div class="field">
                                <label>Mobile No.</label>
                            </div>
                            <div>
                                <input matInput type="text" formControlName="mobileNo">
                            </div>
                        </div>
                    </div>
                    <div class="field-wrapper-2">
                        <div class="box">
                            <div class="field">
                                <label>Telephone No.</label>
                            </div>
                            <div>
                                <input matInput type="text" formControlName="telNo">
                            </div>
                        </div>
                        <div class="box">
                            <div class="field">
                                <label>Marital Status</label>
                            </div>
                            <div>
                                <!-- <input matInput type="text" formControlName="maritalStatus"> -->
                                <!-- <select name="" formControlName="maritalStatus">
                                    <option *ngFor="let x of options.maritalStat" value="{{ x.bmCode }}">
                                        {{ x.maritalDesc }}
                                    </option>
                                </select> -->
                                <mat-select formControlName="maritalStatus">
                                    <mat-option *ngFor="let x of options.maritalStat" [value]="x.bmCode">
                                        {{ x.maritalDesc }}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
                    </div>
                    <div class="field-wrapper-2">
                        <div class="box">
                            <div class="field">
                                <label>Address Line 1</label>
                            </div>
                            <div>
                                <input matInput type="text" formControlName="address01">
                            </div>
                        </div>
                        <div class="box">
                            <div class="field">
                                <label>Address Line 2</label>
                            </div>
                            <div>
                                <input matInput type="text" formControlName="address02">
                            </div>
                        </div>
                    </div>
                    <div class="field-wrapper-2">
                        <div class="box">
                            <div class="field">
                                <label>Address Line 3</label>
                            </div>
                            <div>
                                <input matInput type="text" formControlName="address03">
                            </div>
                        </div>
                        <div class="box">
                            <div class="field">
                                <label>Address Line 4</label>
                            </div>
                            <div>
                                <input matInput type="text" formControlName="address04">
                            </div>
                        </div>
                    </div>
                    <div class="field-wrapper-2">
                        <div class="box">
                            <div class="field">
                                <label>Country</label>
                            </div>
                            <div>
                                <!-- <input matInput type="text" formControlName="birthCountry"> -->
                                <mat-select formControlName="birthCountry" (selectionChange)="updateProvCityOpts(1)">
                                    <mat-option *ngFor="let x of options.country" [value]="x.countryCd">
                                        {{ x.countryName }}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div class="box">
                            <div class="field">
                                <label>Province / City</label>
                            </div>
                            <div>
                                <mat-select formControlName="provCity" (selectionChange)="enablePostalCd()">
                                    <mat-option *ngFor="let x of options.provCity" [value]="x">
                                        {{ x.text }}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
                    </div>
                    <div class="field-wrapper-2">
                        <div class="box">
                            <div class="field">
                                <label>Postal Code</label>
                            </div>
                            <div>
                                <input matInput type="text" formControlName="postalCd">
                            </div>
                        </div>
                        <div class="box">
                            <div class="field">
                                <label>Balance</label>
                            </div>
                            <div>
                                <input matInput type="text" formControlName="balanceAmt" class="text-r" (focus)="formService.setFieldToNumberWithDecimal(updateRenewal, 'balanceAmt', $event)"
                                (blur)="formService.setFieldToAmountFormat(updateRenewal, 'balanceAmt', $event)">
                            </div>
                        </div>
                    </div>
                    <div class="field-wrapper-2">
                        <div class="box">
                            <div class="field">
                                <label>Agent</label>
                            </div>
                            <div>
                                <!-- (selectionChange)="updateProvCityOpts(1)" -->
                                <mat-select formControlName="agent" (selectionChange)="onFieldChange($event, 'agent')">
                                    <mat-option *ngFor="let x of options.agent" [value]="x.bmCode">
                                        {{ x.agentName }}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div class="box">
                            <div class="field">
                                <label>BMA</label>
                            </div>
                            <div>
                                <mat-select formControlName="bma">
                                    <mat-option *ngFor="let x of options.bma" [value]="x.bmCode">
                                        {{ x.bmaDesc }}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
                    </div>
                    <div class="field-wrapper-2">
                        <div class="box">
                            <div class="field">
                                <label>Source Code</label>
                            </div>
                            <div>
                                <!-- (selectionChange)="updateProvCityOpts(1)" -->
                                <mat-select formControlName="src">
                                    <mat-option *ngFor="let x of options.src" [value]="x.bmCode">
                                        {{ x.srcCdDesc }}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div class="box">
                            <div class="field">
                                <label>Source Extension</label>
                            </div>
                            <div>
                                <mat-select formControlName="srcExt" (selectionChange)="onFieldChange($event, 'srcExt')">
                                    <mat-option *ngFor="let x of options.srcExt" [value]="x.bmCode">
                                        {{ x.srcExtDesc }}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
                    </div>
                    <div class="field-wrapper-2">
                        <div class="box">
                            <div class="field">
                                <label>Referror Code</label>
                            </div>
                            <div>
                                <!-- (selectionChange)="updateProvCityOpts(1)" -->
                                <mat-select formControlName="referror">
                                    <mat-option *ngFor="let x of options.referror" [value]="x.bmCode">
                                        {{ x.referrorDesc }}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div class="box">
                            <div class="field">
                                <label>Project Code</label>
                            </div>
                            <div>
                                <mat-select formControlName="project">
                                    <mat-option *ngFor="let x of options.project" [value]="x.bmCode">
                                        {{ x.projectDesc }}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
                    </div>
                    <div class="field-wrapper-2">
                        <div class="box">
                            <div class="field">
                                <label>Client Type Group</label>
                            </div>
                            <div>
                                <!-- (selectionChange)="updateProvCityOpts(1)" -->
                                <mat-select formControlName="client">
                                    <mat-option *ngFor="let x of options.client" [value]="x.bmCode">
                                        {{ x.clientDesc }}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
                    </div>
                    <div class="field-wrapper-2">
                        <div class="box">
                            <div class="field">
                                <label>Blocking Reason</label>
                            </div>
                            <div>
                                <textarea matInput formControlName="blockedReason"></textarea>
                            </div>
                        </div>
                        <div class="box">
                            <div class="field">
                                <label>Remarks</label>
                            </div>
                            <div>
                                <textarea matInput formControlName="remarks"></textarea>
                            </div>
                        </div>
                    </div>

                    <div class="form-btn-container" *ngIf="showUpdateButton">
                        <div>
                            <button class="btn btn2" (click)="onClickUpdate()" [disabled]="selectedRow == null">Update</button>
                        </div>
                    </div>
                </div>
            </form>
        </section>
    </ng-container>

    <ng-template #purgeDialog>
        <h3 class="overlay-header" mat-dialog-title>Confirm Purge</h3>
        <mat-dialog-content>
            Do you want to cancel {{ singlePurge ? 'this record' : 'all records' }}?
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-button class="btn btn2" [mat-dialog-close]="true" (click)="confirmTagInfoDialog(true)">Yes</button>
            <button mat-button class="btn" [mat-dialog-close]="false" (click)="confirmDialog(false)">No</button>
        </mat-dialog-actions>
    </ng-template>

    <ng-template #tagDialog>
        <h3 class="overlay-header" mat-dialog-title>Confirm Action</h3>
        <mat-dialog-content>
            Do you want to {{ tagVal ? 'untag' : 'tag' }} this record {{ tagSrc == 'BALANCE_TAG' ? 'with balance?' : tagSrc == 'TOTAL_LOSS_TAG' ? 'with total loss?' : 'as blocked?' }}
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-button class="btn btn2" [mat-dialog-close]="true" (click)="confirmTagDialog(true)">Yes</button>
            <button mat-button class="btn" [mat-dialog-close]="false" (click)="confirmTagDialog(false)">No</button>
        </mat-dialog-actions>
    </ng-template>

    <ng-template #tagInfoDialog>
        <h3 class="overlay-header" mat-dialog-title>Additional Information</h3>
        <div class="dlg-wrap">
            <mat-dialog-content *ngIf="tagSrc=='BALANCE_TAG'">
                <div class="field">
                    <label>Balance</label>
                </div>
                <div>
                    <input matInput type="text" [ngModel]="dlgBalanceAmt" class="text-r" (focus)="numDec($event)" (blur)="amtFm($event)">
                </div>
            </mat-dialog-content>
            <mat-dialog-content *ngIf="tagSrc=='BLOCKED_TAG'">
                <div class="field">
                    <label>Blocking Reason</label>
                </div>
                <div>
                    <textarea matInput [(ngModel)]="dlgBlockedReason"></textarea>
                </div>
            </mat-dialog-content>
            <mat-dialog-actions>
                <button mat-button class="btn btn2" [mat-dialog-close]="true" (click)="confirmTagInfoDialog(true)">Save</button>
                <button mat-button class="btn" [mat-dialog-close]="false" (click)="confirmTagDialog(false)">Cancel</button>
            </mat-dialog-actions>
        </div>
    </ng-template>

    <ng-template #logsDialog>
        <h3 class="overlay-header" mat-dialog-title>Logs</h3>
        <mat-dialog-content>
            <textarea id="logs-text-area" [ngModel]="dialogContent" readonly></textarea>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-button class="btn btn2" (click)="downloadLogsContent()">Download</button>
            <button mat-button class="btn" mat-dialog-close>Close</button>
        </mat-dialog-actions>
    </ng-template>

    <ng-container *ngIf="view">
        <section id="pol-renewal-view">
            <div class="container">
                <div class="btn-container">
                    <button class="btn btn4" (click)="onClickBack()">Back</button>
                    <!-- <button class="btn btn2">Print</button> -->
                    <div matTooltip="{{tooltip.viewDisabledSendNotice}}" #tooltip1="matTooltip" [matTooltipDisabled]="!disableViewSendNotice">
                        <button class="btn btn2" (click)="onClickSendNotice()" [disabled]="disableViewSendNotice">Send Notice</button>
                    </div>
                </div>
                <div class="quote-policy-details montserrat">
                    <div class="detail">
                        <div class="label">Policy No.</div>
                        <div class="value">{{ renewalData.policyNo || '-'}}</div>
                    </div>
                    <div class="detail">
                        <div class="label">Contract Type</div>
                        <div class="value">{{ renewalData.contractTypeDesc || '-'}}</div>
                    </div>
                    <div class="detail">
                        <div class="label">{{ 'Coverage Type' }}</div>
                        <div class="value">{{renewalData.planName || '-'}}</div>
                    </div>
                    <div class="detail">
                        <div class="label">Policy Start Date</div>
                        <div class="value">{{renewalData.oldEffDate | date:'MM/dd/yyyy'}}</div>
                    </div>
                    <div class="detail">
                        <div class="label">Policy End Date</div>
                        <div class="value">{{renewalData.oldExpiryDate | date:'MM/dd/yyyy'}}</div>
                    </div>
                    <div class="detail">
                    </div>
                    <div class="detail">
                        <div class="label">New Policy Start Date</div>
                        <div class="value">{{renewalData.effDate | date:'MM/dd/yyyy'}}</div>
                    </div>
                    <div class="detail">
                        <div class="label">New Policy End Date</div>
                        <div class="value">{{renewalData.expiryDate | date:'MM/dd/yyyy'}}</div>
                    </div>
                    <div class="detail">
                        <div class="label">Status</div>
                        <div class="value cust-color">{{renewalData.renStatDesc}}</div>
                    </div>
                </div>
                <div class="quote-policy-summary">
                    <div class="summary">
                        <h2>Policy Holder Information</h2>
                        <div class="box-1">
                            <div class="box-holder style-2">
                                <div class="detail">
                                    <div class="label">Name of Policy Holder</div>
                                    <div class="value">{{renewalData.displayName || '-'}}</div>
                                </div>
                                <div class="detail">
                                    <div class="label">Complete Mailing Address</div>
                                    <div class="value">{{renewalData.completeAddress || '-'}}</div>
                                </div>
                            </div>
                            <!-- <div class="box-holder" [class.style-2]="productInfo.customerInfo.clientTypeDetail.value === 'B'" [class.style-4]="productInfo.customerInfo.clientTypeDetail.value === 'R'"> -->
                            <div class="box-holder style-4">
                                <ng-container>
                                    <div class="detail">
                                        <div class="label">Last Name</div>
                                        <div class="value">{{renewalData.assdLastName || '-'}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">First Name</div>
                                        <div class="value">{{renewalData.assdFirstName || '-'}}</div>
                                    </div>
                                    <div class="detail">
                                        <div class="label">Middle Name</div>
                                        <div class="value">{{renewalData.assdMiddleName || '-'}}</div>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="box-holder style-4">
                                <div class="detail">
                                    <div class="label">Email Address</div>
                                    <div class="value">{{renewalData.email || '-'}}</div>
                                </div>
                                <div class="detail">
                                    <div class="label">Mobile No</div>
                                    <div class="value">{{renewalData.mobileNo || '-'}}</div>
                                </div>
                                <div class="detail">
                                    <div class="label">Telephone No</div>
                                    <div class="value">{{renewalData.telNo || '-'}}</div>
                                </div>
                                <div class="detail">
                                    <div class="label">Birth date</div>
                                    <div class="value">{{renewalData.birthDate ? (renewalData.birthDate | date:'MM/dd/yyyy') : '-'}}</div>
                                </div>
                            </div>
                            <div class="box-holder style-4">
                                <div class="detail">
                                    <div class="label">Gender</div>
                                    <div class="value">{{renewalData.genderDesc || '-'}}</div>
                                </div>
                                <div class="detail">
                                    <div class="label">Marital Status</div>
                                    <div class="value">{{renewalData.maritalDesc || '-'}}</div>
                                </div>
                                <div class="detail">
                                    <div class="label">Province / City</div>
                                    <div class="value">{{renewalData.provCityDesc|| '-'}}</div>
                                </div>
                                <div class="detail">
                                    <div class="label">Postal Code</div>
                                    <div class="value">{{renewalData.postalDesc || '-'}}</div>
                                </div>
                            </div>
                            <div class="box-holder style-4">
                                <div class="detail">
                                    <div class="label">Country</div>
                                    <div class="value">{{renewalData.countryName || '-'}}</div>
                                </div>
                                <div class="detail">
                                    <div class="label">Nationality</div>
                                    <div class="value">{{renewalData.nationalityDesc || '-'}}</div>
                                </div>
                                <div class="detail">
                                    <div class="label">Tax Identification No.</div>
                                    <div class="value">{{renewalData.tin || '-'}}</div>
                                </div>
                                <div class="detail">
                                    <div class="label">Category</div>
                                    <div class="value">{{renewalData.categoryDesc || '-'}}</div>
                                </div>
                                <!-- <div class="detail">
                                    <div class="label">Risk Profiling</div>
                                    <div class="value">{{renewalData.riskProfDesc || '-'}}</div>
                                </div> -->
                            </div>
                            <div class="box-holder style-4">
                                <div class="detail">
                                    <div class="label">Type of ID</div>
                                    <div class="value">{{renewalData.idType01Desc || '-'}}</div>
                                    <br />
                                    <div class="label">ID No.</div>
                                    <div class="value">{{renewalData.idNo01 || '-'}}</div>
                                </div>
                                <div class="detail">
                                    <div class="label">Type of ID</div>
                                    <div class="value">{{renewalData.idType02Desc || '-'}}</div>
                                    <br />
                                    <div class="label">ID No.</div>
                                    <div class="value">{{renewalData.idNo02 || '-'}}</div>
                                </div>
                                <div class="detail">
                                    <div class="label">Risk Profiling</div>
                                    <div class="value">{{renewalData.riskProfDesc || '-'}}</div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div *ngIf="renewalData.menuLineCd=='MC'" class="summary vehicle-information">
                    <h2>Vehicle Information</h2>
                    <div class="box-1">
                        <div class="box-holder style-4">
                            <div class="detail">
                                <div class="label">Vehicle Type</div>
                                <div class="value">{{renewalData.veTypeDesc}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">Year Model</div>
                                <div class="value">{{renewalData.yearModel}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">Make & Model</div>
                                <div class="value">{{renewalData.makeModel}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">Sub-Model</div>
                                <div class="value">{{renewalData.subModel}}</div>
                            </div>
                        </div>
                        <div class="box-holder style-4">
                            <div class="detail">
                                <div class="label">Plate No.</div>
                                <div class="value">{{renewalData.plateNo || '-'}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">Engine No.</div>
                                <div class="value">{{renewalData.engineNo || '-'}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">Chassis No.</div>
                                <div class="value">{{renewalData.chassisNo || '-'}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">Make Type Code</div>
                                <div class="value">{{renewalData.makeTypeCd || '-'}}</div>
                            </div>
                            
                        </div>
                        <div class="box-holder style-4">
                            <div class="detail">
                                <div class="label">No. of Seats</div>
                                <div class="value">{{renewalData.noOfSeats || '-'}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">CC/WT</div>
                                <div class="value">{{renewalData.ccWt || '-'}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">Fuel Type</div>
                                <div class="value">{{renewalData.fuelDesc || '-'}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">Seats Covered</div>
                                <div class="value">{{renewalData.seatsCovered || '-'}}</div>
                            </div>
                        </div>
                        <div class="box-holder style-4">
                            <div class="detail">
                                <div class="label">Exterior Color</div>
                                <div class="value">{{renewalData.color01 || '-'}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">Remarks</div>
                                <div class="value">{{renewalData.remarks || '-'}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="renewalData.menuLineCd=='FI'" class="summary vehicle-information">
                    <h2>Property Information</h2>
                    <div class="box-1 see-more">
                        <div class="box-holder style-2-r-1-3">
                            <div class="detail">
                                <div class="label">Risk No.</div>
                                <div class="value">{{renewalData.riskNo}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">Location of Risk</div>
                                <div class="value">{{renewalData.riskName}}</div>
                            </div>
                        </div>
                        <ng-container *ngIf="seeMorePropertyInfo">
                            <div class="box-holder style-4">
                                <div class="detail">
                                    <div class="label">City/Municipality</div>
                                    <div class="value">{{renewalData.cityDesc}}</div>
                                </div>
                                <div class="detail">
                                    <div class="label">District</div>
                                    <div class="value">{{renewalData.districtDesc}}</div>
                                </div>
                                <div class="detail">
                                    <div class="label">Block</div>
                                    <div class="value">{{renewalData.blockDesc}}</div>
                                </div>
                                <div class="detail">
                                    <div class="label">Tariff Interpretation Risk Code</div>
                                    <div class="value">{{renewalData.tariffCode}}</div>
                                </div>
                            </div>
                            <div class="box-holder style-1">
                                <div class="detail">
                                    <div class="label">Construction</div>
                                    <div class="value" [ngClass]="!seeMoreConstruction ? 'expand' : 'pre'">{{renewalData.constructionDesc}}</div>
                                </div>
                                <div class="see-more-2">
                                    <div class="button">
                                        <button *ngIf="!seeMoreConstruction" (click)="seeMoreConstruction = true">See More</button>
                                        <button *ngIf="seeMoreConstruction" (click)="seeMoreConstruction = false">See Less</button>
                                    </div>
                                </div>
                            </div>
                            <div class="box-holder style-1">
                                <div class="detail">
                                    <div class="label">Occupancy</div>
                                    <div class="value" [ngClass]="!seeMoreOccupancy ? 'expand' : 'pre'">{{renewalData.occupancyDesc}}</div>
                                </div>
                                <div class="see-more-2">
                                    <div class="button">
                                        <button *ngIf="!seeMoreOccupancy" (click)="seeMoreOccupancy = true">See More</button>
                                        <button *ngIf="seeMoreOccupancy" (click)="seeMoreOccupancy = false">See Less</button>
                                    </div>
                                </div>
                            </div>
                            <div class="box-holder style-1">
                                <div class="detail">
                                    <div class="label">Other Risk Detail</div>
                                    <div class="value" [ngClass]="!seeMoreOtherRiskDtl ? 'expand' : 'pre'">{{renewalData.fireOtherRiskDtl}}</div>
                                </div>
                                <div class="see-more-2">
                                    <div class="button">
                                        <button *ngIf="!seeMoreOtherRiskDtl" (click)="seeMoreOtherRiskDtl = true">See More</button>
                                        <button *ngIf="seeMoreOtherRiskDtl" (click)="seeMoreOtherRiskDtl = false">See Less</button>
                                    </div>
                                </div>
                            </div>
                            <div class="box-holder style-1">
                                <div class="detail">
                                    <div class="label">Interested Party</div>
                                    <div class="value" [ngClass]="!seeMoreInterestedParty ? 'expand' : 'pre'">{{renewalData.bankName || '-'}}</div>
                                </div>
                                <div class="see-more-2">
                                    <div class="button">
                                        <button *ngIf="!seeMoreInterestedParty" (click)="seeMoreInterestedParty = true">See More</button>
                                        <button *ngIf="seeMoreInterestedParty" (click)="seeMoreInterestedParty = false">See Less</button>
                                    </div>
                                </div>
                            </div>
                            <div class="box-holder style-1">
                                <div class="detail">
                                    <div class="label">Risk Endorsements/Warranties/Clauses</div>
                                    <div class="value" [ngClass]="!seeMoreRiskEndtWc ? 'expand' : 'pre'">
                                        {{renewalData.riskEndtWc}}
                                        <span class="wc" [innerHTML]="renewalData.wcLink"></span>
                                    </div>
                                </div>
                                <div class="see-more-2">
                                    <div class="button">
                                        <button *ngIf="!seeMoreRiskEndtWc" (click)="seeMoreRiskEndtWc = true">See More</button>
                                        <button *ngIf="seeMoreRiskEndtWc" (click)="seeMoreRiskEndtWc = false">See Less</button>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <div class="button">
                            <button class="btn btn2" *ngIf="!seeMorePropertyInfo" (click)="seeMorePropertyInfo = true">See More</button>
                            <button class="btn btn2" *ngIf="seeMorePropertyInfo" (click)="seeMorePropertyInfo = false">See Less</button>
                        </div>
                    </div>
                </div>
                <div *ngIf="renewalData.menuLineCd=='PA'" class="summary vehicle-information">
                    <h2>Information of Covered Person/s</h2>
                    <div class="box-1 see-more">
                        <div class="box-holder style-4">
                            <div class="detail">
                                <div class="label">Age</div>
                                <div class="value">{{renewalData.assdAge}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">Occupation</div>
                                <div class="value">{{renewalData.occupationDesc}}</div>
                            </div>
                        </div>
                        <ng-container *ngIf="seeMorePaInfo">
                            <div class="detail">
                                <div class="label">Covered Members</div>
                            </div>
                            <div class="dep-ben-list">
                                <div class="box-1">
                                    <div class="custom-table">
                                        <ul>
                                            <li class="header">
                                                <p>Name</p>
                                                <p>Relationship</p>
                                                <p>Nationality</p>
                                                <p class="ralign">Date of Birth</p>
                                                <p class="ralign">Age</p>
                                            </li>
                                            <ng-container *ngIf="renewalData.renewalPaData.renewalPaDepData.length==0">
                                                <li [class.body]="true">
                                                    <div>
                                                        <p>-</p>
                                                    </div>
                                                    <div>
                                                        <p>-</p>
                                                    </div>
                                                    <div>
                                                        <p>-</p>
                                                    </div>
                                                    <div>
                                                        <p class="ralign">
                                                            -
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p class="ralign">
                                                            -
                                                        </p>
                                                    </div>
                                                </li>
                                            </ng-container>
                                            <ng-container *ngIf="renewalData.renewalPaData.renewalPaDepData.length>0">
                                                <li *ngFor="let dep of renewalData.renewalPaData.renewalPaDepData; last as l" [class.body]="!l">
                                                    <div>
                                                        <p>{{dep.depName}}</p>
                                                    </div>
                                                    <div>
                                                        <p>{{dep.depRelationshipDesc}}</p>
                                                    </div>
                                                    <div>
                                                        <p>{{dep.depNationalityDesc}}</p>
                                                    </div>
                                                    <div>
                                                        <p class="ralign">
                                                            {{dep.depBirthdate | date:'MM/dd/yyyy'}}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p class="ralign">
                                                            {{dep.depAge}}
                                                        </p>
                                                    </div>
                                                </li>
                                            </ng-container>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="detail">
                                <div class="label">Beneficiaries</div>
                            </div>
                            <div class="dep-ben-list">
                                <div class="box-1">
                                    <div class="custom-table">
                                        <ul>
                                            <li class="header">
                                                <p>Name</p>
                                                <p>Relationship</p>
                                                <p>Nationality</p>
                                                <p class="ralign">Date of Birth</p>
                                                <p class="ralign">Age</p>
                                            </li>
                                            <ng-container *ngIf="renewalData.renewalPaData.renewalPaBenData.length==0">
                                                <li [class.body]="true">
                                                    <div>
                                                        <p>-</p>
                                                    </div>
                                                    <div>
                                                        <p>-</p>
                                                    </div>
                                                    <div>
                                                        <p>-</p>
                                                    </div>
                                                    <div>
                                                        <p class="ralign">
                                                            -
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p class="ralign">
                                                            -
                                                        </p>
                                                    </div>
                                                </li>
                                            </ng-container>
                                            <ng-container *ngIf="renewalData.renewalPaData.renewalPaBenData.length>0">
                                                <li *ngFor="let ben of renewalData.renewalPaData.renewalPaBenData; last as l" [class.body]="!l">
                                                    <div>
                                                        <p>{{ben.benName}}</p>
                                                    </div>
                                                    <div>
                                                        <p>{{ben.benRelationshipDesc}}</p>
                                                    </div>
                                                    <div>
                                                        <p>{{ben.benNationalityDesc}}</p>
                                                    </div>
                                                    <div>
                                                        <p class="ralign">
                                                            {{ben.benBirthdate | date:'MM/dd/yyyy'}}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p class="ralign">
                                                            {{ben.benAge}}
                                                        </p>
                                                    </div>
                                                </li>
                                            </ng-container>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="box-holder style-1">
                                <div class="detail">
                                    <div class="label">Other Risk Detail</div>
                                    <div class="value" [ngClass]="!seeMoreOtherRiskDtl ? 'expand' : 'pre'">{{renewalData.paOtherRiskDtl}}</div>
                                </div>
                                <div class="see-more-2">
                                    <div class="button">
                                        <button *ngIf="!seeMoreOtherRiskDtl" (click)="seeMoreOtherRiskDtl = true">See More</button>
                                        <button *ngIf="seeMoreOtherRiskDtl" (click)="seeMoreOtherRiskDtl = false">See Less</button>
                                    </div>
                                </div>
                            </div>
                            <div class="box-holder style-1">
                                <div class="detail">
                                    <div class="label">Interested Party</div>
                                    <div class="value" [ngClass]="!seeMoreInterestedParty ? 'expand' : 'pre'">{{renewalData.interestedParty || '-'}}</div>
                                </div>
                                <div class="see-more-2">
                                    <div class="button">
                                        <button *ngIf="!seeMoreInterestedParty" (click)="seeMoreInterestedParty = true">See More</button>
                                        <button *ngIf="seeMoreInterestedParty" (click)="seeMoreInterestedParty = false">See Less</button>
                                    </div>
                                </div>
                            </div>
                            <div class="box-holder style-1">
                                <div class="detail">
                                    <div class="label">Risk Endorsements/Warranties/Clauses</div>
                                    <div class="value" [ngClass]="!seeMoreRiskEndtWc ? 'expand' : 'pre'">
                                        {{renewalData.riskEndtWc}}
                                        <span class="wc" [innerHTML]="renewalData.wcLink"></span>
                                    </div>
                                </div>
                                <div class="see-more-2">
                                    <div class="button">
                                        <button *ngIf="!seeMoreRiskEndtWc" (click)="seeMoreRiskEndtWc = true">See More</button>
                                        <button *ngIf="seeMoreRiskEndtWc" (click)="seeMoreRiskEndtWc = false">See Less</button>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <div class="button">
                            <button class="btn btn2" *ngIf="!seeMorePaInfo" (click)="seeMorePaInfo = true">See More</button>
                            <button class="btn btn2" *ngIf="seeMorePaInfo" (click)="seeMorePaInfo = false">See Less</button>
                        </div>
                    </div>
                </div>
                <div class="summary coverage">
                    <h2>Coverage</h2>
                    <ng-container *ngIf="renewalData.menuLineCd=='FI'">
                        <div class="custom-table fi-tbl" *ngFor="let coverages of fiCoverages; index as i; last as x">
                            <span>{{ 'Item No. ' + renewalData.renewalPerilFiData[i].itemNo + ': ' + renewalData.renewalPerilFiData[i].itemTitle }}</span>
                            <ul>
                                <li class="header">
                                    <p (click)="setCoverages('coverage', 'perilLname')" [class.sort]="lastSortedColumn === 'coverage'" [class.asc]="sorting === 'ASC'" [class.desc]="sorting === 'DESC'">
                                        Coverage
                                    </p>
                                    <p (click)="setCoverages('amt-covered', 'amountCovered')" [class.sort]="lastSortedColumn === 'amt-covered'" [class.asc]="sorting === 'ASC'" [class.desc]="sorting === 'DESC'" class="ralign">
                                        Amount Covered
                                    </p>
                                    <p (click)="setCoverages('premium', 'grossPrem')" [class.sort]="lastSortedColumn === 'premium'" [class.asc]="sorting === 'ASC'" [class.desc]="sorting === 'DESC'" class="ralign">
                                        Premium
                                    </p>
                                </li>
                                <li *ngFor="let coverage of coverages; last as l" [class.body]="!l" [class.footer]="l">
                                    <div>
                                        <p>{{coverage.perilLname}}</p>
                                        <ng-container *ngIf="!l">
                                            <div>
                                                <p>Coverage</p>
                                                <p>{{coverage.perilLname}}</p>
                                            </div>
                                            <div>
                                                <p>Amount Covered</p>
                                                <p>{{coverage.amountCovered != null ? (coverage.amountCovered | currency: 'PHP': '₱ ') : '-'}}</p>
                                            </div>
                                            <div>
                                                <p>Premium</p>
                                                <p>
                                                    <ng-container *ngIf="coverage.grossPrem === 0 else paidPrem">
                                                        FREE
                                                    </ng-container>
                                                    <ng-template #paidPrem>
                                                        {{coverage.grossPrem !== null ? (coverage.grossPrem | currency: 'PHP' : '₱ ') : '-'}}
                                                    </ng-template>
                                                </p>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div>
                                        <ng-container *ngIf="!l">
                                            <p class="ralign">{{coverage.amountCovered != null ? (coverage.amountCovered | currency: 'PHP': '₱ ') : '-'}}</p>
                                        </ng-container>
    
                                        <!-- TOTAL PREMIUM BELOW AMOUNT COVERED -->
                                        <ng-container *ngIf="l">
                                            <p class="ralign prem-total-1">
                                                <ng-container *ngIf="coverage.grossPrem === 0 else paidPrem">
                                                    FREE
                                                </ng-container>
                                                <ng-template #paidPrem>
                                                    {{coverage.grossPrem !== null ? (coverage.grossPrem | currency: 'PHP' : '₱ ') : '-'}}
                                                </ng-template>
                                            </p>
                                        </ng-container>
                                    </div>
                                    <div>
                                        <p class="ralign">
                                            <ng-container *ngIf="coverage.grossPrem === 0 else paidPrem">
                                                FREE
                                            </ng-container>
                                            <ng-template #paidPrem>
                                                {{coverage.grossPrem !== null ? (coverage.grossPrem | currency: 'PHP' : '₱ ') : '-'}}
                                            </ng-template>
                                        </p>
                                    </div>
                                </li>
                                <li *ngIf="x" class="footer">
                                    <div>
                                        <p>{{'Total Premium'}}</p>
                                    </div>
                                    <div>
                                        <p class="ralign prem-total-1">
                                            <ng-container *ngIf="renewalData.totalGrossPrem === 0 else paidPrem2">
                                                FREE
                                            </ng-container>
                                            <ng-template #paidPrem2>
                                                {{renewalData.totalGrossPrem !== null ? (renewalData.totalGrossPrem | currency: 'PHP' : '₱ ') : '-'}}
                                            </ng-template>
                                        </p>
                                    </div>
                                    <div>
                                        <p class="ralign">
                                            <ng-container *ngIf="renewalData.totalGrossPrem === 0 else paidPrem2">
                                                FREE
                                            </ng-container>
                                            <ng-template #paidPrem2>
                                                {{renewalData.totalGrossPrem !== null ? (renewalData.totalGrossPrem | currency: 'PHP' : '₱ ') : '-'}}
                                            </ng-template>
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </ng-container>
                    <div class="box-1" *ngIf="renewalData.menuLineCd!=='FI'">
                        <div class="custom-table">
                            <ul>
                                <li class="header">
                                    <p (click)="setCoverages('coverage', 'perilLname')" [class.sort]="lastSortedColumn === 'coverage'" [class.asc]="sorting === 'ASC'" [class.desc]="sorting === 'DESC'">
                                        Coverage
                                    </p>
                                    <p (click)="setCoverages('amt-covered', 'amountCovered')" [class.sort]="lastSortedColumn === 'amt-covered'" [class.asc]="sorting === 'ASC'" [class.desc]="sorting === 'DESC'" class="ralign">
                                        Amount Covered
                                    </p>
                                    <p (click)="setCoverages('premium', 'grossPrem')" [class.sort]="lastSortedColumn === 'premium'" [class.asc]="sorting === 'ASC'" [class.desc]="sorting === 'DESC'" class="ralign">
                                        Premium
                                    </p>
                                </li>
                                <li *ngFor="let coverage of coverages; last as l" [class.body]="!l" [class.footer]="l">
                                    <div>
                                        <p>{{coverage.perilLname}}</p>
                                        <ng-container *ngIf="!l">
                                            <div>
                                                <p>Coverage</p>
                                                <p>{{coverage.perilLname}}</p>
                                            </div>
                                            <div>
                                                <p>Amount Covered</p>
                                                <p>{{coverage.amountCovered != null ? (coverage.amountCovered | currency: 'PHP': '₱ ') : '-'}}</p>
                                            </div>
                                            <div>
                                                <p>Premium</p>
                                                <p>
                                                    <ng-container *ngIf="coverage.grossPrem === 0 else paidPrem">
                                                        FREE
                                                    </ng-container>
                                                    <ng-template #paidPrem>
                                                        {{coverage.grossPrem !== null ? (coverage.grossPrem | currency: 'PHP' : '₱ ') : '-'}}
                                                    </ng-template>
                                                </p>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div>
                                        <ng-container *ngIf="!l">
                                            <p class="ralign">{{coverage.amountCovered != null ? (coverage.amountCovered | currency: 'PHP': '₱ ') : '-'}}</p>
                                        </ng-container>
    
                                        <!-- TOTAL PREMIUM BELOW AMOUNT COVERED -->
                                        <ng-container *ngIf="l">
                                            <p class="ralign prem-total-1">
                                                <ng-container *ngIf="coverage.grossPrem === 0 else paidPrem">
                                                    FREE
                                                </ng-container>
                                                <ng-template #paidPrem>
                                                    {{coverage.grossPrem !== null ? (coverage.grossPrem | currency: 'PHP' : '₱ ') : '-'}}
                                                </ng-template>
                                            </p>
                                        </ng-container>
                                    </div>
                                    <div>
                                        <p class="ralign">
                                            <ng-container *ngIf="coverage.grossPrem === 0 else paidPrem">
                                                FREE
                                            </ng-container>
                                            <ng-template #paidPrem>
                                                {{coverage.grossPrem !== null ? (coverage.grossPrem | currency: 'PHP' : '₱ ') : '-'}}
                                            </ng-template>
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="summary other-info" *ngIf="renewalData.menuLineCd=='MC'">
                    <h2>Other Information</h2>
                    <div class="box-1">
                        <div class="detail">
                            <div class="label">Deductible Amount</div>
                            <!-- <div class="label">Deductible Amount<div class="help-icon"><mat-icon matTooltip="{{dedAmtComment}}" #tooltip="matTooltip" (click)="tooltip.toggle()" matTooltipPosition="right" matTooltipClass="tooltip mat-elevation-z8">help_outline</mat-icon></div></div> -->
                            <div class="value">{{renewalData.deductibleAmt ? (renewalData.deductibleAmt | currency: 'PHP': '₱ ') : '-'}}</div>
                        </div>
                        <div class="detail">
                            <div class="label">Towing Limit</div>
                            <!-- <div class="label">Towing Limit<div class="help-icon"><mat-icon matTooltip="{{towingAmtComment}}" #tooltip2="matTooltip" (click)="tooltip2.toggle()" matTooltipPosition="right" matTooltipClass="tooltip mat-elevation-z8">help_outline</mat-icon></div></div> -->
                            <div class="value">{{renewalData.towingLimit ? (renewalData.towingLimit | currency: 'PHP': '₱ ') : '-'}}</div>
                        </div>
                        <div class="detail">
                            <div class="label">Authorized Repair Limit</div>
                            <!-- <div class="label">Authorized Repair Limit<div class="help-icon"><mat-icon matTooltip="{{repairAmtComment}}" #tooltip3="matTooltip" (click)="tooltip3.toggle()" matTooltipPosition="right" matTooltipClass="tooltip mat-elevation-z8">help_outline</mat-icon></div></div> -->
                            <div class="value">{{renewalData.repairLimit ? (renewalData.repairLimit | currency: 'PHP': '₱ ') : '-'}}</div>
                        </div>
                    </div>
                </div>
                <div class="summary premium-charges">
                    <h2>Premium Charges</h2>
                    <div class="box-2">
                        <div class="box-holder">
                            <div class="detail">
                                <div class="label">Premium</div>
                                <div class="value">{{renewalData.totalGrossPrem | currency: 'PHP': '₱ '}}</div>
                            </div>
                            <div class="details">
                                <div class="detail" *ngFor="let tax of renewalData.taxes">
                                    <div class="label">{{tax.taxName}}</div>
                                    <div class="value">{{tax.value | currency: 'PHP': '₱ '}}</div>
                                </div>
                            </div>
                            <!-- <div class="detail">
                                <div class="label">Document Stamps</div>
                                <div class="value">{{renewalData.totalDocStamp | currency: 'PHP': '₱ '}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">Value Added Tax</div>
                                <div class="value">{{renewalData.totalVat | currency: 'PHP': '₱ '}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">Local Govt Tax</div>
                                <div class="value">{{renewalData.totalLgt | currency: 'PHP': '₱ '}}</div>
                            </div> -->
                            <div class="detail total-amt">
                                <div class="label">Total Amount</div>
                                <div class="value">{{renewalData.totalAmt | currency: 'PHP': '₱ '}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="box-1">
                        <!-- <div class="box-holder" [class.style-5]="productInfo.sublineCd !== 'CTPL' && userDetails.userId && userDetails.accountType !== 'P'" [class.style-4]="!(productInfo.sublineCd !== 'CTPL') && userDetails.userId && userDetails.accountType !== 'P'" [class.style-3]="productInfo.sublineCd !== 'CTPL' && !(userDetails.userId && userDetails.accountType !== 'P')"
                            [class.style-2]="!(productInfo.sublineCd !== 'CTPL') && !(userDetails.userId && userDetails.accountType !== 'P')"> -->
                        <div class="box-holder">
                            <!-- <div class="detail">
                                <div class="label">Deductible Amount</div>
                                <div class="value">{{renewalData.deductibleAmt ? (renewalData.deductibleAmt | currency: 'PHP': '₱ ') : '-'}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">Towing Limit</div>
                                <div class="value">{{renewalData.towingLimit ? (renewalData.towingLimit | currency: 'PHP': '₱ ') : '-'}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">Authorized Repair Limit</div>
                                <div class="value">{{renewalData.repairLimit ? (renewalData.repairLimit | currency: 'PHP': '₱ ') : '-'}}</div>
                            </div> -->
                            <ng-container>
                                <div class="detail">
                                    <div class="label">Commission Amount</div>
                                    <div class="value">{{renewalData.totalCommAmt | currency: 'PHP': '₱ '}}</div>
                                </div>
                                <div class="detail total-amt">
                                    <div class="label">Net Due</div>
                                    <div class="value">{{renewalData.totalNetPrem | currency: 'PHP': '₱ '}}</div>
                                </div>
                                <div class="detail">
                                    <div class="label">Payment Reference No.</div>
                                    <div class="value">{{renewalData.paytRefNo || '-'}}</div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <!-- <div class="summary payment">
                    <h2>Payment Details</h2>
                    <div class="box-1">
                        <div class="box-holder style-4">
                            
                            <div class="detail">
                                <div class="label">Transaction No.</div>
                                <div class="value">{{renewalData.paytTranNo || '-'}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">Mode of Payment</div>
                                <div class="value">{{renewalData.paytMode || '-'}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">Payment Date</div>
                                <div class="value">{{renewalData.paytDate ? (renewalData.paytDate | date : 'MM/dd/YYYY') : '-'}}</div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="summary policy-header">
                    <h2>Policy Header Details</h2>
                    <div class="box-1">
                        <div class="box-holder style-3">
                            <div class="detail">
                                <div class="label">Agent</div>
                                <div class="value">{{renewalData.agentName || '-'}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">Source Code</div>
                                <div class="value">{{renewalData.srcCdDesc || '-'}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">Source Extension</div>
                                <div class="value">{{renewalData.srcExtDesc || '-'}}</div>
                            </div>
                        </div>
                        <div class="box-holder style-3">
                            <div class="detail">
                                <div class="label">BMA</div>
                                <div class="value">{{renewalData.bmaDesc || '-'}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">Referror Code</div>
                                <div class="value">{{renewalData.referrorDesc || '-'}}</div>
                            </div>
                            <div class="detail">
                                <div class="label">Project Code</div>
                                <div class="value">{{renewalData.projectDesc || '-'}}</div>
                            </div>
                        </div>
                        <div class="box-holder style-3">
                            <div class="detail">
                                <div class="label">Client Type Group</div>
                                <div class="value">{{renewalData.clientDesc || '-'}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="btn-container">
                    <button class="btn btn4" (click)="onClickBack()">Back</button>
                    <!-- <button class="btn btn2">Print</button> -->
                    <div matTooltip="{{tooltip.viewDisabledSendNotice}}" #tooltip2="matTooltip" [matTooltipDisabled]="!disableViewSendNotice">
                        <button class="btn btn2" (click)="onClickSendNotice()" [disabled]="disableViewSendNotice">Send Notice</button>
                    </div>
                </div>
            </div>
        </section>
    </ng-container>
</section>