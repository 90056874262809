<section id="fire-prem-rt-for-bpims-maint">    
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <div class="form-content">
            <form [formGroup]="forms.paramLovForm" class="form">
                <div class="form-container-grid top-form">
                    <div class="field required">
                        <label>Property Class Code</label>
                    </div>
                    <div class="">
                        <input formControlName="classCdDisp" >
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['class']()" 
                        [disabled]='btns.lov.classDisabled'>
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                    <div class="field required">
                        <label>Line</label>
                    </div>
                    <div class="">
                        <input formControlName="lineCdDisp" >
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['line']()" 
                        [disabled]='btns.lov.lineDisabled'>
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                    <div class="field required">
                        <label>Subline</label>
                    </div>
                    <div class="">
                        <input formControlName="sublineCdDisp" >
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['subline']()" 
                        [disabled]='btns.lov.sublineDisabled'>
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                    <div class="field required">
                        <label>Plan</label>
                    </div>
                    <div class="">
                        <input formControlName="planCdDisp" >
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['plan']()" 
                        [disabled]='btns.lov.planDisabled'>
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                    <div class="field required">
                        <label>Peril</label>
                    </div>
                    <div class="">
                        <input formControlName="perilCdDisp" >
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['peril']()" 
                        [disabled]='btns.lov.perilDisabled'>
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                    <div class="field required">
                        <label>Zone</label>
                    </div>
                    <div class="">
                        <input formControlName="zoneCdDisp" >
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['zone']()" 
                        [disabled]='btns.lov.zoneDisabled'>
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <!-- Container for Fire Premium Rate For BPIMS Table using prime ng -->
        <div class = 'table-user'>
            <osp-table [tblConfig]="firePremRtForBpimsTblConfig" (onRowClick)="onRowClick($event)"></osp-table>
        </div>
        <!-- Loading Animation -->
        <div id="fire-prem-rt-for-bpims-maintenance-loading">
            <div class="form-loader"></div>
        </div>
        <div class="form-content" *ngIf="showForm">
            <form [formGroup]="forms.firePremRtForBpimsForm" class="form" *ngIf="showForm">
                <div id="fire-prem-rt-for-bpims-error-message"></div>
                <div class="form-container-grid">
                    <div class="field required">
                        <label>Premium Rate</label>
                    </div>
                    <div class="">
                        <input class="currency" formControlName="premRt" (focus)="_formService.setFieldToNumberWithDecimal(forms.firePremRtForBpimsForm, 'premRt', $event)"
                        (blur)="_formService.setFieldToNumberWithDecimal(forms.firePremRtForBpimsForm, 'premRt', $event)" (keypress)="allowNumericDigitsOnly($event)" (focusout)="rateFormat('premRt')">
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag">
                            <option *ngFor="let activeType of selections.activeTypes" [value]="activeType.cd">
                                {{activeType.name}}
                            </option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>Commission Rate</label>
                    </div>
                    <div class="">
                        <input class="currency" formControlName="commRt" (focus)="_formService.setFieldToNumberWithDecimal(forms.firePremRtForBpimsForm, 'commRt', $event)"
                        (blur)="_formService.setFieldToNumberWithDecimal(forms.firePremRtForBpimsForm, 'commRt', $event)" (keypress)="allowNumericDigitsOnly($event)" (focusout)="rateFormat('commRt')">
                    </div>
                    <div class="field required">
                        <label>Default</label>
                    </div>
                    <div class="">
                        <select formControlName="defaultTag">
                            <option *ngFor="let defaultTag of selections.defaultTags" [value]="defaultTag.cd">
                                {{defaultTag.name}}
                            </option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>Effective Date</label>
                    </div>
                    <div class="">
                        <input formControlName="effDateFrom" type="text" placeholder="MM/DD/YYYY" [matDatepicker]="effDateFromPicker" (keypress)="allowNumericAndSlashOnly($event)">
                        <mat-datepicker-toggle matSuffix [for]="effDateFromPicker"></mat-datepicker-toggle>
                        <mat-datepicker #effDateFromPicker></mat-datepicker>
                    </div>
                    <div class="field">
                        <label>Expiry Date</label>
                    </div>
                    <div class="">
                        <input formControlName="effDateTo" type="text" placeholder="MM/DD/YYYY" [matDatepicker]="effDateToPicker" (keypress)="allowNumericAndSlashOnly($event)">
                        <mat-datepicker-toggle matSuffix [for]="effDateToPicker"></mat-datepicker-toggle>
                        <mat-datepicker #effDateToPicker></mat-datepicker>
                    </div>

                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" type="text" maxlength="4000">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="userID">
                    </div>
                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUpdate">
                    </div>
                </div>
            </form>
            <div class="btn-row">
                <button *ngIf="btns.firePremRtForBpims.updateDisabled" class="btn btn2" type="button" [disabled]='btns.firePremRtForBpims.addDisabled' (click)="onAdd()">ADD</button>
                <button *ngIf="!btns.firePremRtForBpims.updateDisabled" class="btn btn2" type="button" (click)="onUpdate()">UPDATE</button>
                <button class="btn btn2" type="button" [disabled]='btns.firePremRtForBpims.deleteDisabled' (click)="onDelete()">DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-row">
                <button class="btn btn2" type="button" [disabled]='btns.firePremRtForBpims.saveDisabled' (click)="onSave()">SAVE</button>
            </div>
        </div>
    </div>
</section>