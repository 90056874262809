<section id="update-pol-renewal-details-wrap">
    <ng-container>
        <section id="pol-renewal">
            <div class="filter-container">
                <form [formGroup]="filterRenewal">
                    <div class="form-wrapper">
                        <div class="field-wrapper-4">
                            <div class="box">
                                <div class="field">
                                    <label>Contract Type</label>
                                </div>
                                <div>
                                    <mat-select formControlName="contractTypeFilter">
                                        <mat-option *ngFor="let x of options.contractType" [value]="x.bmShortDesc">
                                            {{ x.bmShortDesc }}
                                        </mat-option>
                                    </mat-select>
                                </div>
                            </div>
                            <div class="box">
                                
                                <div>
                                    <div class="field">
                                        <label>Upload User</label>
                                    </div>
                                    <mat-select formControlName="uploadUserFilter">
                                        <mat-option *ngFor="let x of options.uploadUser" [value]="x.userId">
                                            {{ x.userId }}
                                        </mat-option>
                                    </mat-select>
                                </div>
                            </div>
                            <div class="box">
                                
                                <div class="dp-cont">
                                    <div class="field">
                                        <label>Upload Date From</label>
                                    </div>
                                    <input matInput formControlName="uploadDateFrom" type="text" placeholder="MM/DD/YYYY" [matDatepicker]="uploadDateFromPicker" autocomplete="off">
                                    <mat-datepicker-toggle [for]="uploadDateFromPicker"></mat-datepicker-toggle>
                                    <mat-datepicker #uploadDateFromPicker></mat-datepicker>
                                </div>
                            </div>
                            <div class="box">
                                <div class="field">
                                    <label>Upload Date To</label>
                                </div>
                                <div class="dp-cont">
                                    <input matInput formControlName="uploadDateTo" type="text" placeholder="MM/DD/YYYY" [matDatepicker]="uploadDateToPicker" autocomplete="off">
                                    <mat-datepicker-toggle [for]="uploadDateToPicker"></mat-datepicker-toggle>
                                    <mat-datepicker #uploadDateToPicker></mat-datepicker>
                                </div>
                            </div>
                        </div>
                        <div class="form-btn-container search-cont">
                            <div>
                                <button class="btn btn2" (click)="onClickSearch()">Search</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div>
                <osp-table #tbl [tblConfig]="tblConfig" (onRowClick)="onRowClick($event)" (rowEvent)="rowEvent($event)" (selectAllConfirmation)="toggleSelectAll($event)" (onLazyLoad)="lazyLoad($event)"></osp-table>
            </div>       
            <form [formGroup]="updateRenewal">
                <div class="form-wrapper">
                    <div class="field-wrapper-2">
                        <div class="box">
                            <div class="field">
                                <label>Agent</label>
                            </div>
                            <div>
                                <!-- (selectionChange)="updateProvCityOpts(1)" -->
                                <mat-select formControlName="agent">
                                    <mat-option *ngFor="let x of options.agent" [value]="x.bmCode">
                                        {{ x.agentName }}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div class="box">
                            <div class="field">
                                <label>BMA</label>
                            </div>
                            <div>
                                <mat-select formControlName="bma">
                                    <mat-option *ngFor="let x of options.bma" [value]="x.bmCode">
                                        {{ x.bmaDesc }}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
                    </div>
                    <div class="field-wrapper-2">
                        <div class="box">
                            <div class="field">
                                <label>Source Code</label>
                            </div>
                            <div>
                                <!-- (selectionChange)="updateProvCityOpts(1)" -->
                                <mat-select formControlName="src">
                                    <mat-option *ngFor="let x of options.src" [value]="x.bmCode">
                                        {{ x.srcCdDesc }}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div class="box">
                            <div class="field">
                                <label>Source Extension</label>
                            </div>
                            <div>
                                <mat-select formControlName="srcExt">
                                    <mat-option *ngFor="let x of options.srcExt" [value]="x.bmCode">
                                        {{ x.srcExtDesc }}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
                    </div>
                    <div class="field-wrapper-2">
                        <div class="box">
                            <div class="field">
                                <label>Referror Code</label>
                            </div>
                            <div>
                                <!-- (selectionChange)="updateProvCityOpts(1)" -->
                                <mat-select formControlName="referror">
                                    <mat-option *ngFor="let x of options.referror" [value]="x.bmCode">
                                        {{ x.referrorDesc }}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div class="box">
                            <div class="field">
                                <label>Project Code</label>
                            </div>
                            <div>
                                <mat-select formControlName="project">
                                    <mat-option *ngFor="let x of options.project" [value]="x.bmCode">
                                        {{ x.projectDesc }}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
                    </div>
                    <div class="field-wrapper-2">
                        <div class="box">
                            <div class="field">
                                <label>Client Type Group</label>
                            </div>
                            <div>
                                <!-- (selectionChange)="updateProvCityOpts(1)" -->
                                <mat-select formControlName="client">
                                    <mat-option *ngFor="let x of options.client" [value]="x.bmCode">
                                        {{ x.clientDesc }}
                                    </mat-option>
                                </mat-select>
                            </div>
                        </div>
                    </div>

                    <div class="form-btn-container">
                        <div>
                            <button class="btn btn2" (click)="onClickUpdate()">Update</button>
                        </div>
                    </div>
                </div>
            </form>
        </section>
    </ng-container>

    <ng-template #confirmationDialog>
        <h3 class="overlay-header" mat-dialog-title>Confirmation</h3>
        <mat-dialog-content>
            Are you sure you want to update the specified details of the selected record/s?
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-button class="btn btn2" [mat-dialog-close]="true" (click)="confirmDialog(true)">Yes</button>
            <button mat-button class="btn" [mat-dialog-close]="false" (click)="confirmDialog(false)">No</button>
        </mat-dialog-actions>
    </ng-template>

    <!--  <ng-template #tagDialog>
        <h3 class="overlay-header" mat-dialog-title>Confirm Action</h3>
        <mat-dialog-content>
            Do you want to {{ tagVal ? 'untag' : 'tag' }} this record {{ tagSrc == 'BALANCE_TAG' ? 'with balance?' : tagSrc == 'TOTAL_LOSS_TAG' ? 'with total loss?' : 'as blocked?' }}
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-button class="btn btn2" [mat-dialog-close]="true" (click)="confirmTagDialog(true)">Yes</button>
            <button mat-button class="btn" [mat-dialog-close]="false" (click)="confirmTagDialog(false)">No</button>
        </mat-dialog-actions>
    </ng-template>

    <ng-template #logsDialog>
        <h3 class="overlay-header" mat-dialog-title>Logs</h3>
        <mat-dialog-content>
            <textarea id="logs-text-area" [ngModel]="dialogContent" readonly></textarea>
        </mat-dialog-content>
        <mat-dialog-actions>
            <button mat-button class="btn btn2" (click)="downloadLogsContent()">Download</button>
            <button mat-button class="btn" mat-dialog-close>Close</button>
        </mat-dialog-actions>
    </ng-template> -->
</section>