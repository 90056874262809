<section id="coverage-maint">    
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <div class="form-content">
            <form [formGroup]="forms.lineLovForm" class="form">
                <div class="form-container-grid top-form">
                    <div class="field required">
                        <label>Line</label>
                    </div>
                    <div class="">
                        <input formControlName="lineCdDisp" >
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['line']()">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <!-- Container for Coverage Table using prime ng -->
        <div class = 'table-user'>
            <osp-table [tblConfig]="coverageTblConfig" (onRowClick)="onRowClick($event)"></osp-table>
        </div>
        <!-- Loading Animation -->
        <div id="coverage-maintenance-loading">
            <div class="form-loader"></div>
        </div>
        <div class="form-content" *ngIf="showForm">
            <form [formGroup]="forms.coverageForm" class="form">
                <div id="coverage-error-message"></div>
                <div class="form-container-grid">
                    <div class="field">
                        <label>Peril Code</label>
                    </div>
                    <div class="">
                        <input formControlName="perilCdDisp" type="text" readonly>
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag">
                            <option *ngFor="let activeType of selections.activeTypes" [value]="activeType.cd">
                                {{activeType.name}}
                            </option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>Peril Short Name</label>
                    </div>
                    <div class="">
                        <input formControlName="perilShortName" type="text" maxlength="10" (input)="autoUppercase($event)">
                    </div>
                    <div class="field required">
                        <label>Peril Type</label>
                    </div>
                    <div class="">
                        <select formControlName="perilType" (change)="onPerilTypeChange($event)">
                            <option *ngFor="let perilType of selections.perilTypes" [value]="perilType.cd">
                                {{perilType.name}}
                            </option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>Peril Name</label>
                    </div>
                    <div class="">
                        <input formControlName="perilLName" type="text" maxlength="500">
                    </div>
                    <div class="field required">
                        <label>Fixed Amount Covered</label>
                    </div>
                    <div class="">
                        <select formControlName="fixedSITag">
                            <option *ngFor="let fixedSITag of selections.fixedSITags" [value]="fixedSITag.cd">
                                {{fixedSITag.name}}
                            </option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>Fixed Premium</label>
                    </div>
                    <div class="">
                        <select formControlName="fixedPremTag">
                            <option *ngFor="let fixedPremTag of selections.fixedPremTags" [value]="fixedPremTag.cd">
                                {{fixedPremTag.name}}
                            </option>
                        </select>
                    </div>
                    <div class="field">
                        <label>Default Commission Rate</label>
                    </div>
                    <div class="">
                        <input class="currency" formControlName="defaultCommRate" (focus)="_formService.setFieldToNumberWithDecimal(forms.coverageForm, 'defaultCommRate', $event)"
                        (blur)="_formService.setFieldToNumberWithDecimal(forms.coverageForm, 'defaultCommRate', $event)" (keypress)="allowNumericDigitsOnly($event)" (focusout)="rateFormat('defaultCommRate')">
                    </div>

                    <div class="field">
                        <label>Default Premium Rate</label>
                    </div>
                    <div class="">
                        <input class="currency" formControlName="defaultRate" (focus)="_formService.setFieldToNumberWithDecimal(forms.coverageForm, 'defaultRate', $event)"
                        (blur)="_formService.setFieldToNumberWithDecimal(forms.coverageForm, 'defaultRate', $event)" (keypress)="allowNumericDigitsOnly($event)" (focusout)="rateFormat('defaultRate')">
                    </div>
                    <div class="field required">
                        <label>Default Amount Covered</label>
                    </div>
                    <div class="">
                        <input class="currency" formControlName="defaultTSI" (focus)="_formService.setFieldToNumberWithDecimal(forms.coverageForm, 'defaultTSI', $event)"
                        (blur)="_formService.setFieldToAmountFormat(forms.coverageForm, 'defaultTSI', $event)" (keypress)="allowNumericDigitsOnly($event)">
                    </div>

                    <div class="field required">
                        <label>Fair Market Value Switch</label>
                    </div>
                    <div class="">
                        <select formControlName="fmvSw">
                            <option *ngFor="let fmvSw of selections.fmvSws" [value]="fmvSw.cd">
                                {{fmvSw.name}}
                            </option>
                        </select>
                    </div>
                    <div class="field required">
                        <label>Multiplier Rate</label>
                    </div>
                    <div class="">
                        <input class="currency" formControlName="multiplierRate" (focus)="_formService.setFieldToNumberWithDecimal(forms.coverageForm, 'multiplierRate', $event)"
                        (blur)="_formService.setFieldToNumberWithDecimal(forms.coverageForm, 'multiplierRate', $event)" (keypress)="allowNumericDigitsOnly($event)" (focusout)="rateFormat('multiplierRate')">
                    </div>

                    <div class="field required">
                        <label>Rating Table Switch</label>
                    </div>
                    <div class="">
                        <select formControlName="ratingTblSw">
                            <option *ngFor="let ratingTblSw of selections.ratingTblSws" [value]="ratingTblSw.cd">
                                {{ratingTblSw.name}}
                            </option>
                        </select>
                    </div>
                    <div class="field required">
                        <label>RPA Code</label>
                    </div>
                    <div class="">
                        <input formControlName="bmCode" type="text" maxlength="8">
                    </div>

                    <div class="field">
                        <label>Basic Peril Code</label>
                    </div>
                    <div class="lov-search">
                        <input type="text" readonly formControlName="basicPerilCdDisp">
                        <button type="button" class="btn-search btn" (click)="openLov['basicPeril']()" [disabled]='btns.coverage.basicPerilDisabled'>
                            <mat-icon>
                                search
                            </mat-icon>
                        </button>
                    </div>
                    <div class="field">
                        <label>RPA Short Description</label>
                    </div>
                    <div class="">
                        <input formControlName="bmShortDesc" type="text" maxlength="10">
                    </div>

                    <div class="field">
                        <label>Group Code</label>
                    </div>
                    <div class="">
                        <input formControlName="groupCd" type="text" (keypress)="allowNumericDigitsOnlyWODecimal($event)" (blur)="integerFormat('groupCd')">
                    </div>
                    <div class="field">
                        <label>Sequence No.</label>
                    </div>
                    <div class="">
                        <input formControlName="seqNo" type="text" (keypress)="allowNumericDigitsOnlyWODecimal($event)" (blur)="integerFormat('seqNo')">
                    </div>

                    <div class="field">
                        <label>Premium Class Code</label>
                    </div>
                    <div class="">
                        <input formControlName="premClassCd" type="text" maxlength="8">
                    </div>
                    <div></div>
                    <div></div>

                    <div class="field">
                        <label>Short Description</label>
                    </div>
                    <div class="span">
                        <textarea maxlength="200" formControlName="shortDesc"></textarea>
                    </div>
                    
                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" type="text" maxlength="4000">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUpdateUser">
                    </div>
                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUpdate">
                    </div>
                </div>
            </form>
            <div class="btn-row">
                <button *ngIf="btns.coverage.updateDisabled" class="btn btn2" type="button" (click)="onAdd()" [disabled]='btns.coverage.addDisabled'>ADD</button>
                <button *ngIf="!btns.coverage.updateDisabled" class="btn btn2" type="button" (click)="onUpdate()">UPDATE</button>
                <button class="btn btn2" type="button" [disabled]='btns.coverage.deleteDisabled' (click)="onDelete()"> DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-row">
                <button class="btn btn2" type="button" [disabled]='btns.coverage.saveDisabled' (click)="onSave()">SAVE</button>
            </div>
        </div>
    </div>
</section>