import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

@Component({
  selector: 'peril-lov',
  templateUrl: './peril-lov.component.html',
  styleUrls: ['./peril-lov.component.css']
})
export class PerilLovComponent implements OnInit {

  private selectedData: any;
  public tableSelected: any;
  public titleLov: string = "PERIL";
  moduleId: string = 'BMM023';

  perilTable: any = {
    cols: [],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  };

  private perilTableCol: any[] = [
    {
      key: "PERIL_CD",
      header: "Code",
      dataType: "padNumber"
    },
    {
      key: "PERIL_LNAME",
      header: "Peril",
      dataType: "string"
    },
  ];
  private perilTableNameCol: any[] = [
    {
      key: "PERIL_CD",
      header: "Code",
      dataType: "padNumber"
    },
    {
      key: "PERIL_LNAME",
      header: "Peril Name",
      dataType: "string"
    },
  ];

  constructor(
    private jsonDataService: JsonDataService,
    private dialogRef: MatDialogRef<PerilLovComponent>,
    private _api: APICallService,
    private securityService: SecurityService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    @Inject(MAT_DIALOG_DATA) public fromMainData: any,
  ) {
    try {
      if("title" in this.fromMainData){
        this.titleLov = this.fromMainData.title;
      }
      if(this.fromMainData.col === 'PERIL'){
        this.perilTable.cols = this.perilTableNameCol;
      }
      else{
        this.perilTable.cols = this.perilTableCol;
      } 
      this.tableSelected = this.perilTable;
      this.getPerilData();
    }
    catch (e) {
      console.error(e);
    }
  }

  ngOnInit(): void {
  }

  onRowClick(ev: any) {
    try {
      this.selectedData = ev;
    } catch (e) {
      console.error(e);
    }
  }

  getPerilData() {
    try {
      this.perilTable.loading = true;
      this.jsonDataService.contorlLoading(true);
      this._api.getAllPeril({
        moduleId: this.fromMainData.moduleId,
        userId: this.userDetailService.userId,
        type: "LOV"
      }).subscribe({
        next: (data: any) => {
          this.securityService.checkRequestKeyResponse(data, () => {
            this.securityService.hasValidCsrfToken(data, () => {
              let peril = JSON.parse(this.jsonDataService.decrypt(data.response));
              if (peril.moduleId === this.moduleId) {
                if (this.fromMainData.moduleId === 'BMM023' || this.fromMainData.moduleId === 'BMM093') {
                  this.jsonDataService.contorlLoading(false);
                  this.tableSelected.tblData = peril.data.filter((d: any) => (d.LINE_CD === this.fromMainData.lineCd && d.PERIL_TYPE === 'B' && d.ACTIVE_TAG == 'A'));
                  this.perilTable.loading = false;
                }
                else {
                  this.jsonDataService.contorlLoading(false);
                  this.tableSelected.tblData = peril.data.filter((d: any) => (d.LINE_CD === this.fromMainData.lineCd && d.ACTIVE_TAG == 'A' && !((this.fromMainData.perilCdExclude ?? []).includes(+d.PERIL_CD))));
                  this.perilTable.loading = false;
                }
                if (this.fromMainData?.lpad) {
                  this.tableSelected.tblData = this.tableSelected.tblData?.map((c: any) => { return { ...c, PERIL_CD: parseInt(c.PERIL_CD)?.toString().padStart(this.fromMainData?.lpad, "0")}});
                }
                else if (!this.fromMainData.col) {
                  this.tableSelected.tblData = this.tableSelected.tblData?.map((c: any) => { return { ...c, PERIL_CD: parseInt(c.PERIL_CD)?.toString().padStart(3, "0")}});
                }
              } else {
                this.perilTable.loading = false;
                this.jsonDataService.contorlLoading(false);
                this.appMessageService.showAppMessage("You do not have the correct privilege to access the data. Kindly contact your administrator for assistance.", "error");
              }
            });
          });
        },
        error: (e: any) => {
          console.error(e);
          this.perilTable.loading = false;
          this.jsonDataService.contorlLoading(false);
          this.appMessageService.showAppMessage(e, "error");
        }
      });
    }
    catch (e) {
      console.error(e);
      this.perilTable.loading = false;
      this.jsonDataService.contorlLoading(false);
    }
  }

  onClose(str: string) {
    try {
      if (this.selectedData == '' || this.selectedData == null) {
        this.dialogRef.close({ content: '', button: str });
      }
      else {
        this.dialogRef.close({ content: this.selectedData, button: str });
      }
    } catch (e) {
      console.error(e);
    }
  }

}
