import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { APICallService } from '../../../services/api-call.service';
import { OspTableComponent } from '../../common/osp-table/osp-table.component';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { MatDialog } from '@angular/material/dialog';
import { JsonDataService } from '../../../services/json-data.service';
import { FormService } from 'src/app/services/form.service';
import { Observable } from 'rxjs';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { SecurityService } from 'src/app/services/security.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { LazyLoadEvent } from 'primeng/api';
import { LovComponent } from '../../common/lov/lov.component';
import { UploadComponent } from '../../common/upload/upload.component';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'user-level-mtn',
  templateUrl: './user-level-mtn.component.html',
  styleUrls: ['./user-level-mtn.component.css']
})
export class UserLevelMtnComponent implements OnInit {

  @ViewChild('tblViewAll') tblViewAll!: OspTableComponent;
  @ViewChild('tblL1') tblL1!: OspTableComponent;
  @ViewChild('tblL2') tblL2!: OspTableComponent;
  @ViewChild('tblL3') tblL3!: OspTableComponent;
  @ViewChild('tblL4') tblL4!: OspTableComponent;
  @ViewChild('lov') lov!: LovComponent;
  @ViewChild('deleteDialog') deleteDialog!: TemplateRef<any>;
  @ViewChild('upload') upload!: UploadComponent;
  @ViewChild('ep1') ep1!: MatExpansionPanel;

  tblConfigViewAll: any = {
    cols: [
      {
        key: "headUserName",
        header: "User Level 1",
        dataType: "string",
        width: "25%"
      },
      {
        key: "officerUserName",
        header: "User Level 2",
        dataType: "string",
        width: "25%"
      },
      {
        key: "assistantUserName",
        header: "User Level 3",
        dataType: "string",
        width: "25%"
      },
      {
        key: "staffUserName",
        header: "User Level 4",
        dataType: "string",
        width: "25%"
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: true,
    totalRecords: 1,
    pageLinks: 1
  };

  tblConfigL1: any = {
    cols: [
      {
        key: "headUserId",
        header: "User ID",
        dataType: "string",
        width: "25%"
      },
      {
        key: "headUserName",
        header: "Name",
        dataType: "string",
        width: "75%"
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 5,
    lazy: true,
    totalRecords: 1,
    pageLinks: 1
  };

  tblConfigL2: any = {
    cols: [
      {
        key: "officerUserId",
        header: "User ID",
        dataType: "string",
        width: "25%"
      },
      {
        key: "officerUserName",
        header: "Name",
        dataType: "string",
        width: "75%"
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 5,
    lazy: true,
    totalRecords: 1,
    pageLinks: 1
  };

  tblConfigL3: any = {
    cols: [
      {
        key: "assistantUserId",
        header: "User ID",
        dataType: "string",
        width: "25%"
      },
      {
        key: "assistantUserName",
        header: "Name",
        dataType: "string",
        width: "75%"
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 5,
    lazy: true,
    totalRecords: 1,
    pageLinks: 1
  };

  tblConfigL4: any = {
    cols: [
      {
        key: "staffUserId",
        header: "User ID",
        dataType: "string",
        width: "25%"
      },
      {
        key: "staffUserName",
        header: "Name",
        dataType: "string",
        width: "75%"
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 5,
    lazy: true,
    totalRecords: 1,
    pageLinks: 1
  };

  uploadConfig: any = {
    uploadFileKeys: ['USER_LEVEL1','USER_LEVEL2','USER_LEVEL3', 'USER_LEVEL4_USER_ID', 'USER_LEVEL4_BR_CODE'],
    tblConfig: {
      cols: [
        {
          key: "USER_LEVEL1",
          header: "USER_LEVEL1",
          dataType: "string",
          width: "12%"
        },
        {
          key: "USER_LEVEL2",
          header: "USER_LEVEL2",
          dataType: "string",
          width: "12%",
        },
        {
          key: "USER_LEVEL3",
          header: "USER_LEVEL3",
          dataType: "string",
          width: "12%"
        },
        {
          key: "USER_LEVEL4_USER_ID",
          header: "USER_LEVEL4_USER_ID",
          dataType: "string",
          width: "15%",
        },
        {
          key: "USER_LEVEL4_BR_CODE",
          header: "USER_LEVEL4_BR_CODE",
          dataType: "string",
          width: "16%"
        },
        {
          key: "STATUS",
          header: "STATUS",
          dataType: "string",
          width: "33%",
          align: 'text-l'
        },
      ],
      tblData: [],
      selection: "single",
      paginator: true,
      rowsPerPage: 10,
      lazy: true,
      totalRecords: 1,
      pageLinks: 1
    },
    onClickUpload: (data: any) => {
      return this.onClickUpload(data);
    },
    width: '65vw'
  };

  selectedRowL1: any = null;
  selectedRowL2: any = null;
  selectedRowL3: any = null;
  selectedRowL4: any = null;

  saveListL1: any[] = [];
  saveListL2: any[] = [];
  saveListL3: any[] = [];
  saveListL4: any[] = [];

  moduleId: string = "BMM082";
  moduleName: string = '';

  initL1: boolean = true;
  initL2: boolean = true;
  initL3: boolean = true;
  initL4: boolean = true;

  disableL2: boolean = true;
  disableL3: boolean = true;
  disableL4: boolean = true;

  l1Expanded: boolean = false;
  l2Expanded: boolean = false;
  l3Expanded: boolean = false;
  l4Expanded: boolean = false;

  l4Type: string = 'l4a';

  l1Form: FormGroup = this.fb.group ({
    headUserId: ["", [Validators.required]],
    headUserName: [""],
    remarks: [""],
    userId: [""],
    lastUpdate: [""]
  });

  l2Form: FormGroup = this.fb.group ({
    headUserId: [""],
    officerUserId: ["", [Validators.required]],
    officerUserName: ["", [Validators.required]],
    remarks: [""],
    userId: [""],
    lastUpdate: [""]
  });

  l3Form: FormGroup = this.fb.group ({
    headUserId: [""],
    officerUserId: [""],
    assistantUserId: ["", [Validators.required]],
    assistantUserName: ["", [Validators.required]],
    remarks: [""],
    userId: [""],
    lastUpdate: [""]
  });

  l4Form: FormGroup = this.fb.group ({
    headUserId: [""],
    officerUserId: [""],
    assistantUserId: [""],
    staffUserId: [""],
    staffUserName: [""],
    srcExtCd: [""],
    branchCode: [""],
    remarks: [""],
    userId: [""],
    lastUpdate: [""]
  });

  lovData: any = {
    type: '',
    selection: 'multi',
    selected: [],
    params: {},
    selectAllTag: false
  };

  l1ListFromLov: any[] = [];
  l2ListFromLov: any[] = [];
  l3ListFromLov: any[] = [];
  l4aListFromLov: any[] = [];
  l4bListFromLov: any[] = [];

  constructor(
    private api: APICallService,
    private fb: FormBuilder,
    private jds: JsonDataService,
    private uds: UserDetailsService,
    private ss: SecurityService,
    private ams: AppMessageService,
    public fs: FormService,
    public dialog: MatDialog
  ) {
    // this.l1Form.controls["headUserId"].;
    this.l1Form.controls["headUserName"].disable();
    this.l1Form.controls["userId"].disable();
    this.l1Form.controls["lastUpdate"].disable();

    // this.l2Form.controls["officerUserId"].disable();
    this.l2Form.controls["officerUserName"].disable();
    this.l2Form.controls["userId"].disable();
    this.l2Form.controls["lastUpdate"].disable();

    // this.l3Form.controls["assistantUserId"].disable();
    this.l3Form.controls["assistantUserName"].disable();
    this.l3Form.controls["userId"].disable();
    this.l3Form.controls["lastUpdate"].disable();
  }

  ngOnInit(): void {
    this.moduleName = this.jds.data.moduleList.filter((data: any) => { return data.moduleId === this.moduleId })[0].moduleDesc.toUpperCase();
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.saveListL1.length == 0;
  }

  onRowClick(ev: any, from?: any) {
    this.expansionPanelController(ev, from);
    // this.updateForm(ev);
  }

  lazyLoad(ev: any, from: any, save?: any) {
    var rows = 0;

    var vFrom = from;

    if(from == 'all') {
      rows = this.tblConfigViewAll.rowsPerPage;
    } else if(from == 'l1') {
      rows = this.tblConfigL1.rowsPerPage;
      this.initL1 = false;
    } else if(from == 'l2') {
      rows = this.tblConfigL2.rowsPerPage;
      this.initL2 = false;
    } else if(from == 'l3') {
      rows = this.tblConfigL3.rowsPerPage;
      this.initL3 = false;
    } else if(from == 'l4') {
      rows = this.tblConfigL4.rowsPerPage;
      if(this.initL4) {
        this.l4Form = this.fb.group ({
          headUserId: [""],
          officerUserId: [""],
          assistantUserId: [""],
          staffUserId: ["", [Validators.required]],
          staffUserName: [""],
          srcExtCd: [""],
          branchCode: [""],
          remarks: [""],
          userId: [""],
          lastUpdate: [""]
        });

        this.l4Form.controls["staffUserName"].disable();
        this.l4Form.controls["branchCode"].disable();
        this.l4Form.controls["userId"].disable();
        this.l4Form.controls["lastUpdate"].disable();
      }
      this.initL4 = false;
      vFrom = this.l4Type;
    }

    setTimeout(() => {
      this.getUserLevel({
        from: vFrom,
        l1UserId: this.selectedRowL1 == null ? null : this.selectedRowL1.headUserId,
        l2UserId: this.selectedRowL2 == null ? null : this.selectedRowL2.officerUserId,
        l3UserId: this.selectedRowL3 == null ? null : this.selectedRowL3.assistantUserId,
        first: ev.first,
        rows: rows, 
        search: ev.globalFilter,
        sortBy: ev.sortField == undefined ? null : ev.sortField,
        sortDir: ev.sortField == undefined ? 1 : ev.sortOrder
      }, save);
    }, 0);
  }

  getUserLevel(prm: any, save?: any) {
    let qryPrm: any = {};

    if(prm) {
      qryPrm = {
        from: prm.from,
        l1UserId: prm.l1UserId,
        l2UserId: prm.l2UserId,
        l3UserId: prm.l3UserId,
        first: prm.first,
        rows: prm.rows,
        search: prm.search,
        sortBy: prm.sortBy,
        sortDir: prm.sortDir
      }
    }

    this.jds.contorlLoading(true);
    this.api.getUserLevel({
      moduleId: this.moduleId,
      userId: this.uds.userId,
      type: "MODULE",
      from: qryPrm.from,
      l1UserId: qryPrm.l1UserId,
      l2UserId: qryPrm.l2UserId,
      l3UserId: qryPrm.l3UserId,
      first: qryPrm.first,
      rows: qryPrm.rows,
      search: qryPrm.search,
      sortBy: qryPrm.sortBy,
      sortDir: qryPrm.sortDir
    }).subscribe((data: any) => {
      this.ss.checkRequestKeyResponse(data, () => {
        this.jds.contorlLoading(false);
        if (data.status === "SUCCESS") {
          data = JSON.parse(this.jds.decrypt(data.content));

          if(prm.from == 'all') {
            this.tblConfigViewAll.totalRecords = data['count'];
            this.tblConfigViewAll.pageLinks = 10;
            this.tblConfigViewAll.tblData = data['data'];
          } else if(prm.from == 'l1') {
            this.tblConfigL1.totalRecords = data['count'];
            this.tblConfigL1.pageLinks = 10;
            this.tblConfigL1.tblData = data['data'];
          } else if(prm.from == 'l2') {
            this.tblConfigL2.totalRecords = data['count'];
            this.tblConfigL2.pageLinks = 10;
            this.tblConfigL2.tblData = data['data'];
          } else if(prm.from == 'l3') {
            this.tblConfigL3.totalRecords = data['count'];
            this.tblConfigL3.pageLinks = 10;
            this.tblConfigL3.tblData = data['data'];
          } else if(prm.from.substr(0, 2) == 'l4') {
            this.tblConfigL4.totalRecords = data['count'];
            this.tblConfigL4.pageLinks = 10;
            this.tblConfigL4.tblData = data['data'];
          }

          if(prm.from !== 'all' && save) {
            this.lazyLoad({
              first: this.tblConfigViewAll.tblData ? 0 : this.tblConfigViewAll.tblData[0]['rownum_'] - 1,
              globalFilter: this.tblViewAll.filterText
            }, 'all');
          }
          
        } else {
          this.ams.showAppMessage(data.message, "error");
        }
      });
    });
  }

  expansionPanelController(ev: any, from: any) {
    var prm = {
      first: 0,
      globalFilter: null
    };

    if(from && from == 'l1') {
      this.fs.hideFormMsg("user-level-1-form-msg");
      this.l1ListFromLov = [];
      this.updateForm('l1', ev);

      this.selectedRowL1 = ev;
      this.selectedRowL2 = null;
      this.selectedRowL3 = null;
      this.selectedRowL4 = null;
      this.disableL2 = ev == null;

      if(ev !== null) {
        this.l2Expanded = true;
      } else {
        this.l2Expanded = false;
        this.l3Expanded = false;
        this.l4Expanded = false;

        this.disableL3 = true;
        this.disableL4 = true;
      }

      if(ev !== null && !this.initL2) {
        this.lazyLoad(prm, 'l2');
        this.expansionPanelController(null, 'l2');
      }

      if(ev == null && !this.initL1) {
        this.tblL1.selectedRow = null;
      }

      if(ev == null && !this.initL2) {
        this.tblL2.selectedRow = null;
      }

      if(ev == null && !this.initL3) {
        this.tblL3.selectedRow = null;
      }
    } else if(from && from == 'l2') {
      this.fs.hideFormMsg("user-level-2-form-msg");
      this.l2ListFromLov = [];
      this.updateForm('l2', ev);

      this.selectedRowL2 = ev;
      this.selectedRowL3 = null;
      this.selectedRowL4 = null;
      this.disableL3 = ev == null;

      if(ev !== null) {
        this.l3Expanded = true;
      } else {
        this.l3Expanded = false;
        this.l4Expanded = false;

        this.disableL4 = true;
      }

      if(ev !== null && !this.initL3) {
        this.lazyLoad(prm, 'l3');
        this.expansionPanelController(null, 'l3');
      }

      if(ev == null && !this.initL2) {
        this.tblL2.selectedRow = null;
      }

      if(ev == null && !this.initL3) {
        this.tblL3.selectedRow = null;
      }
    } else if(from && from == 'l3') {
      this.fs.hideFormMsg("user-level-3-form-msg");
      this.l3ListFromLov = [];
      this.updateForm('l3', ev);

      this.selectedRowL3 = ev;
      this.selectedRowL4 = null;
      this.disableL4 = ev == null;

      if(ev !== null) {
        this.l4Expanded = true;
      } else {
        this.l4Expanded = false;

        this.disableL4 = true;
      }

      if(ev !== null && !this.initL4) {
        this.l4Type = 'l4a';
        this.onL4RadioChange();
        this.expansionPanelController(null, 'l4');
      }

      if(ev == null && !this.initL3) {
        this.tblL3.selectedRow = null;
      }

      if(ev == null && !this.initL4) {
        this.tblL4.selectedRow = null;
      }
    } else if(from && from == 'l4') {
      this.fs.hideFormMsg("user-level-4-form-msg");
      // this.l4ListFromLov = [];
      this.updateForm('l4', ev);

      this.selectedRowL4 = ev;

      if(ev == null && !this.initL4) {
        this.tblL4.selectedRow = null;
      }
    }
  }

  opened(name: any) {
    var prm = {
      first: 0,
      globalFilter: null
    };

    if(name == 'l1') {
      this.selectedRowL1 = null;

      if(!this.initL1) {
        this.lazyLoad(prm, name);
      }
    }
    
  }

  openLov(type: string) {
    var title = '';
    if(type == 'l1') {
      title = 'User Level 1';
      this.lovData.type = 'BMM082_userLevel1';
      this.lovData.selected = this.l1ListFromLov;
    } else if (type == 'l2') {
      title = 'User Level 2';
      this.lovData.type = 'BMM083_userLevel2';
      this.lovData.selected = this.l2ListFromLov;
      this.lovData.params = {
        headUserId: this.selectedRowL1.headUserId
      }
    } else if (type == 'l3') {
      title = 'User Level 3';
      this.lovData.type = 'BMM084_userLevel3';
      this.lovData.selected = this.l3ListFromLov;
      this.lovData.params = {
        headUserId: this.selectedRowL1.headUserId,
        officerUserId: this.selectedRowL2.officerUserId
      }
    } else if (type.substr(0, 2) == 'l4') {
      title = type == 'l4a' ? 'User Level 4 - User ID' : 'User Level 4 - Branch Code';
      this.lovData.type = type == 'l4a' ? 'BMM085_userLevel4' : 'BMM152_userLevel4' ;
      this.lovData.selected = type == 'l4a' ? this.l4aListFromLov : this.l4bListFromLov;
      this.lovData.params = {
        headUserId: this.selectedRowL1.headUserId,
        officerUserId: this.selectedRowL2.officerUserId,
        assistantUserId: this.selectedRowL3.assistantUserId
      }
    }

    this.lov.open(title);
  }

  updateForm(from: any, ev: any) {
    if(from == 'l1') {
      this.l1Form.patchValue({
        headUserId: ev == null ? "" : ev.headUserId,
        headUserName: ev == null ? "" : ev.headUserName,
        remarks: ev == null ? "" : ev.remarks,
        userId: ev == null ? "" : ev.userId,
        lastUpdate: ev == null ? "" : ev.lastUpdate
      });
    } else if(from == 'l2') {
      this.l2Form.patchValue({
        headUserId: ev == null ? "" : ev.headUserId,
        officerUserId: ev == null ? "" : ev.officerUserId,
        officerUserName: ev == null ? "" : ev.officerUserName,
        remarks: ev == null ? "" : ev.remarks,
        userId: ev == null ? "" : ev.userId,
        lastUpdate: ev == null ? "" : ev.lastUpdate
      });
    } else if(from == 'l3') {
      this.l3Form.patchValue({
        headUserId: ev == null ? "" : ev.headUserId,
        officerUserId: ev == null ? "" : ev.officerUserId,
        assistantUserId: ev == null ? "" : ev.assistantUserId,
        assistantUserName: ev == null ? "" : ev.assistantUserName,
        remarks: ev == null ? "" : ev.remarks,
        userId: ev == null ? "" : ev.userId,
        lastUpdate: ev == null ? "" : ev.lastUpdate
      });
    } else if(from == 'l4') {
      this.l4Form.patchValue({
        headUserId: ev == null ? "" : ev.headUserId,
        officerUserId: ev == null ? "" : ev.officerUserId,
        assistantUserId: ev == null ? "" : ev.assistantUserId,
        staffUserId: ev == null ? "" : ev.staffUserId,
        staffUserName: ev == null ? "" : ev.staffUserName,
        srcExtCd: ev == null ? "" : ev.srcExtCd,
        branchCode: ev == null ? "" : ev.branchCode,
        remarks: ev == null ? "" : ev.remarks,
        userId: ev == null ? "" : ev.userId,
        lastUpdate: ev == null ? "" : ev.lastUpdate
      });
    }
    
  }

  toDelete: any = '';

  onClickAddUpdate(from: any, del?: any, validate?: any) {
    if(from == 'l1') {      
      if(this.l1Form.valid) {
        if(validate) {
          var del1 = {
            from: 'l1',
            headUserId: this.selectedRowL1.headUserId,
            officerUserId: null,
            assistantUserId: null
          }

          this.jds.contorlLoading(true);          

          this.api.saveUserLevel({
            validate: true,
            data: [del1]
          }).subscribe((res: any) => {
            this.ss.checkRequestKeyResponse(res, async () => {
              var data = JSON.parse(this.jds.decrypt(res.content));

              this.jds.contorlLoading(false);

              if(res.status == 'SUCCESS') {
                if(data.existing) {
                  this.toDelete = 'l1';

                  const dialogRole = this.dialog.open(this.deleteDialog, { 
                    width: "500px",
                    disableClose: true
                  });
  
                  return;
                } else {
                  this.onClickAddUpdate('l1', true);
                }
              } else {
                this.fs.showFormMsg("user-level-1-form-msg", "An error occured while validating.", "E");
              }
            });
          });
        } else {
          this.fs.hideFormMsg("user-level-1-form-msg");
  
          const form = this.l1Form.getRawValue();
          var multiList = [];

          if(this.l1ListFromLov.length > 1) {
            multiList = this.l1ListFromLov.map((a: any) => {
              return {
                from: 'l1',
                delete: del == undefined ? 'N' : 'Y',
                headUserId: a.USER_ID,
                officerUserId: null,
                assistantUserId: null,
                staffUserId: null,
                srcExtCd: null,
                remarks: form.remarks,
                userId: this.jds.retrieveFromStorage("userId")
              }
            });

            this.saveListL1 = this.saveListL1.concat(multiList);
            this.fs.hideFormMsg("user-level-1-form-msg");
            this.expansionPanelController(null, 'l1');
            this.selectedRowL1 = null;
            this.lovData.selected = [];
            this.l1ListFromLov = [];
          } else {
            var val1 = {
              from: 'l1',
              delete: del == undefined ? 'N' : 'Y',
              headUserId: (del == undefined && this.selectedRowL1 == null) ? this.l1ListFromLov[0].USER_ID : form.headUserId,
              officerUserId: null,
              assistantUserId: null,
              staffUserId: null,
              srcExtCd: null,
              remarks: form.remarks,
              userId: this.jds.retrieveFromStorage("userId")
            };

            if(this.selectedRowL1 !== null) {
              this.selectedRowL1.remarks = val1.remarks;
            }

            this.saveListL1.push(val1);
            this.fs.hideFormMsg("user-level-1-form-msg");
            this.expansionPanelController(null, 'l1');
            this.selectedRowL1 = null;
          }
        }
      } else {
        this.fs.showFormMsg("user-level-1-form-msg", "Please fill out all the required fields.", "E");
      }
    } else if(from == 'l2') {
      if(this.l2Form.valid) {
        if(validate) {
          var del2 = {
            from: 'l2',
            headUserId: this.selectedRowL1.headUserId,
            officerUserId: this.selectedRowL2.officerUserId,
            assistantUserId: null
          }

          this.jds.contorlLoading(true);          

          this.api.saveUserLevel({
            validate: true,
            data: [del2]
          }).subscribe((res: any) => {
            this.ss.checkRequestKeyResponse(res, async () => {
              var data = JSON.parse(this.jds.decrypt(res.content));

              this.jds.contorlLoading(false);

              if(res.status == 'SUCCESS') {
                if(data.existing) {
                  this.toDelete = 'l2';

                  const dialogRole = this.dialog.open(this.deleteDialog, { 
                    width: "500px",
                    disableClose: true
                  });
  
                  return;
                } else {
                  this.onClickAddUpdate('l2', true);
                }
              } else {
                this.fs.showFormMsg("user-level-2-form-msg", "An error occured while validating.", "E");
              }
            });
          });
        } else {
          this.fs.hideFormMsg("user-level-2-form-msg");
    
          const form = this.l2Form.getRawValue();

          var multiList = [];

          if(this.l2ListFromLov.length > 1) {
            multiList = this.l2ListFromLov.map((a: any) => {
              return {
                from: 'l2',
                delete: del == undefined ? 'N' : 'Y',
                headUserId: this.selectedRowL1.headUserId,
                officerUserId: a.USER_ID,
                assistantUserId: null,
                staffUserId: null,
                srcExtCd: null,
                remarks: form.remarks,
                userId: this.jds.retrieveFromStorage("userId")
              }
            });

            this.saveListL2 = this.saveListL2.concat(multiList);
            this.fs.hideFormMsg("user-level-2-form-msg");
            this.expansionPanelController(null, 'l2');
            this.selectedRowL2 = null;
            this.lovData.selected = [];
            this.l2ListFromLov = [];
          } else {
            var val2 = {
              from: 'l2',
              delete: del == undefined ? 'N' : 'Y',
              headUserId: this.selectedRowL1.headUserId,
              officerUserId: (del == undefined && this.selectedRowL2 == null) ? this.l2ListFromLov[0].USER_ID : form.officerUserId,
              assistantUserId: null,
              staffUserId: null,
              srcExtCd: null,
              remarks: form.remarks,
              userId: this.jds.retrieveFromStorage("userId")
            };

            if(this.selectedRowL2 !== null) {
              this.selectedRowL2.remarks = val2.remarks;
            }

            this.saveListL2.push(val2);
            this.fs.hideFormMsg("user-level-2-form-msg");
            this.expansionPanelController(null, 'l2');
            this.selectedRowL2 = null;
          }
        }
      } else {
        this.fs.showFormMsg("user-level-2-form-msg", "Please fill out all the required fields.", "E");
      }
    } else if(from == 'l3') {
      if(this.l3Form.valid) {
        if(validate) {
          var del3 = {
            from: 'l3',
            headUserId: this.selectedRowL1.headUserId,
            officerUserId: this.selectedRowL2.officerUserId,
            assistantUserId: this.selectedRowL3.assistantUserId
          }

          this.jds.contorlLoading(true);          

          this.api.saveUserLevel({
            validate: true,
            data: [del3]
          }).subscribe((res: any) => {
            this.ss.checkRequestKeyResponse(res, async () => {
              var data = JSON.parse(this.jds.decrypt(res.content));

              this.jds.contorlLoading(false);

              if(res.status == 'SUCCESS') {
                if(data.existing) {
                  this.toDelete = 'l3';

                  const dialogRole = this.dialog.open(this.deleteDialog, { 
                    width: "500px",
                    disableClose: true
                  });
  
                  return;
                } else {
                  this.onClickAddUpdate('l3', true);
                }
              } else {
                this.fs.showFormMsg("user-level-3-form-msg", "An error occured while validating.", "E");
              }
            });
          });
        } else {
          this.fs.hideFormMsg("user-level-3-form-msg");
    
          const form = this.l3Form.getRawValue();

          var multiList = [];

          if(this.l3ListFromLov.length > 1) {
            multiList = this.l3ListFromLov.map((a: any) => {
              return {
                from: 'l3',
                delete: del == undefined ? 'N' : 'Y',
                headUserId: this.selectedRowL1.headUserId,
                officerUserId: this.selectedRowL2.officerUserId,
                assistantUserId: a.USER_ID,
                staffUserId: null,
                srcExtCd: null,
                remarks: form.remarks,
                userId: this.jds.retrieveFromStorage("userId")
              }
            });

            this.saveListL3 = this.saveListL3.concat(multiList);
            this.fs.hideFormMsg("user-level-3-form-msg");
            this.expansionPanelController(null, 'l3');
            this.selectedRowL3 = null;
            this.lovData.selected = [];
            this.l3ListFromLov = [];
          } else {
            var val3 = {
              from: 'l3',
              delete: del == undefined ? 'N' : 'Y',
              headUserId: this.selectedRowL1.headUserId,
              officerUserId: this.selectedRowL2.officerUserId,
              assistantUserId: (del == undefined && this.selectedRowL3 == null) ? this.l3ListFromLov[0].USER_ID : form.assistantUserId,
              staffUserId: null,
              srcExtCd: null,
              remarks: form.remarks,
              userId: this.jds.retrieveFromStorage("userId")
            };

            if(this.selectedRowL3 !== null) {
              this.selectedRowL3.remarks = val3.remarks;
            }
      
            this.saveListL3.push(val3);
            this.fs.hideFormMsg("user-level-3-form-msg");
            this.expansionPanelController(null, 'l3');
            this.selectedRowL3 = null;
          }
        }
      } else {
        this.fs.showFormMsg("user-level-3-form-msg", "Please fill out all the required fields.", "E");
      }
    } else if(from == 'l4') {
      if(this.l4Form.valid) {
        this.fs.hideFormMsg("user-level-4-form-msg");
  
        const form = this.l4Form.getRawValue();

        var multiList = [];

        if(this.l4aListFromLov.length > 1 || this.l4bListFromLov.length > 1) {
          if(this.l4Type == 'l4a') {
            multiList = this.l4aListFromLov.map((a: any) => {
              return {
                from: this.l4Type,
                delete: del == undefined ? 'N' : 'Y',
                headUserId: this.selectedRowL1.headUserId,
                officerUserId: this.selectedRowL2.officerUserId,
                assistantUserId: this.selectedRowL3.assistantUserId,
                staffUserId: a.USER_ID,
                srcExtCd: null,
                remarks: form.remarks,
                userId: this.jds.retrieveFromStorage("userId")
              }
            });
          } else {
            multiList = this.l4bListFromLov.map((a: any) => {
              return {
                from: this.l4Type,
                delete: del == undefined ? 'N' : 'Y',
                headUserId: this.selectedRowL1.headUserId,
                officerUserId: this.selectedRowL2.officerUserId,
                assistantUserId: this.selectedRowL3.assistantUserId,
                staffUserId: null,
                srcExtCd: a.SRC_EXT_CD,
                remarks: form.remarks,
                userId: this.jds.retrieveFromStorage("userId")
              }
            });
          }

          this.saveListL4 = this.saveListL4.concat(multiList);
          this.fs.hideFormMsg("user-level-4-form-msg");
          this.expansionPanelController(null, 'l4');
          this.selectedRowL4 = null;
          this.lovData.selected = [];
          this.l4aListFromLov = [];
          this.l4bListFromLov = [];
        } else {
          var val4 = {
            from: this.l4Type,
            delete: del == undefined ? 'N' : 'Y',
            headUserId: this.selectedRowL1.headUserId,
            officerUserId: this.selectedRowL2.officerUserId,
            assistantUserId: this.selectedRowL3.assistantUserId,
            staffUserId: (this.l4Type == 'l4a' && del == undefined && this.selectedRowL4 == null) ? this.l4aListFromLov[0].USER_ID : form.staffUserId,
            srcExtCd: (this.l4Type == 'l4b' && del == undefined && this.selectedRowL4 == null) ? this.l4bListFromLov[0].SRC_EXT_CD : form.srcExtCd,
            remarks: form.remarks,
            userId: this.jds.retrieveFromStorage("userId")
          };

          if(this.selectedRowL4 !== null) {
            this.selectedRowL4.remarks = val4.remarks;
          }

          this.saveListL4.push(val4);
          this.fs.hideFormMsg("user-level-4-form-msg");
          this.expansionPanelController(null, 'l4');
          this.selectedRowL4 = null;
        }
      } else {
        this.fs.showFormMsg("user-level-4-form-msg", "Please fill out all the required fields.", "E");
      }
    }
  }

  onClickSave(from: any) {

    if(from == 'l1') {
      this.fs.showFormLoader(
        this,
        "user-level-1-form",
        "Processing your request.<br>Please wait ...",
        "save",
        this.saveListL1
      );
    } else if(from == 'l2') {
      this.fs.showFormLoader(
        this,
        "user-level-2-form",
        "Processing your request.<br>Please wait ...",
        "save",
        this.saveListL2
      );
    } else if(from == 'l3') {
      this.fs.showFormLoader(
        this,
        "user-level-3-form",
        "Processing your request.<br>Please wait ...",
        "save",
        this.saveListL3
      );
    } else if(from == 'l4') {
      this.fs.showFormLoader(
        this,
        "user-level-4-form",
        "Processing your request.<br>Please wait ...",
        "save",
        this.saveListL4
      );
    }
  }

  save(params: any) {
    this.api.saveUserLevel(params).subscribe((res: any) => {
      this.ss.checkRequestKeyResponse(res, async () => {
        var data = JSON.parse(this.jds.decrypt(res.content));

        if(res.status == 'SUCCESS') {
          let x = params[0].from;

          if(x == 'l1') {
            this.lazyLoad({
              first: this.tblConfigL1.tblData ? 0 : this.tblConfigL1.tblData[0]['rownum_'] - 1,
              globalFilter: this.tblL1.filterText
            }, 'l1', true);
  
            this.expansionPanelController(null, 'l1');
            this.openDialog('success');
            this.saveListL1 = [];
            this.fs.hideFormLoader("user-level-1-form");
          } else if(x == 'l2') {
            this.lazyLoad({
              first: this.tblConfigL2.tblData ? 0 : this.tblConfigL2.tblData[0]['rownum_'] - 2,
              globalFilter: this.tblL2.filterText
            }, 'l2', true);
  
            this.expansionPanelController(null, 'l2');
            this.openDialog('success');
            this.saveListL2 = [];
            this.fs.hideFormLoader("user-level-2-form");
          } else if(x == 'l3') {
            this.lazyLoad({
              first: this.tblConfigL3.tblData ? 0 : this.tblConfigL3.tblData[0]['rownum_'] - 2,
              globalFilter: this.tblL3.filterText
            }, 'l3', true);
  
            this.expansionPanelController(null, 'l3');
            this.openDialog('success');
            this.saveListL3 = [];
            this.fs.hideFormLoader("user-level-3-form");
          } else if(x.substr(0, 2) == 'l4') {
            this.lazyLoad({
              first: this.tblConfigL4.tblData ? 0 : this.tblConfigL4.tblData[0]['rownum_'] - 2,
              globalFilter: this.tblL4.filterText
            }, 'l4', true);
  
            this.expansionPanelController(null, 'l4');
            this.openDialog('success');
            this.saveListL4 = [];
            this.fs.hideFormLoader("user-level-4-form");
          }
          
        } else {
          this.openDialog('error');
        }
      });
    });
  }

  openDialog(type: string) {
    var dlgTitle;
    var dlgContent;

    if(type == 'success') {
      dlgTitle = 'SUCCESS';
      dlgContent = 'Successfully saved!';
    } else if(type == 'error') {
      dlgTitle = 'ERROR';
      dlgContent = 'An error occured while saving. Please check field values.';
    } else if(type == 'required') {
      dlgTitle = 'WARNING';
      dlgContent = 'Please supply all the required fields.';
    } else if(type == 'info') {
      dlgTitle = 'INFO';
      dlgContent = 'Nothing to save';
    }

    const dialogRole = this.dialog.open(DialogMsgComponent, { 
      disableClose: true,
      width: "500px",
      data: {
        title: dlgTitle,
        content: dlgContent
      }
    });
  }

  onL4RadioChange() {
    this.fs.hideFormMsg("user-level-4-form-msg");
    if(this.l4Type == 'l4a') {
      this.l4Form = this.fb.group ({
        headUserId: [""],
        officerUserId: [""],
        assistantUserId: [""],
        staffUserId: ["", [Validators.required]],
        staffUserName: [""],
        srcExtCd: [""],
        branchCode: [""],
        remarks: [""],
        userId: [""],
        lastUpdate: [""]
      });

      this.tblConfigL4.cols = [
          {
            key: "staffUserId",
            header: "User ID",
            dataType: "string",
            width: "25%"
          },
          {
            key: "staffUserName",
            header: "Name",
            dataType: "string",
            width: "75%"
          }
        ];
    } else {
      this.l4Form = this.fb.group ({
        headUserId: [""],
        officerUserId: [""],
        assistantUserId: [""],
        staffUserId: [""],
        staffUserName: [""],
        srcExtCd: ["", [Validators.required]],
        branchCode: [""],
        remarks: [""],
        userId: [""],
        lastUpdate: [""]
      });

      this.tblConfigL4.cols = [
        {
          key: "srcExtCd",
          header: "Src. Ext. Code",
          dataType: "string",
          width: "25%"
        },
        {
          key: "description",
          header: "Description",
          dataType: "string",
          width: "50%"
        },
        {
          key: "branchCode",
          header: "Branch Code",
          dataType: "string",
          width: "25%"
        }
      ];
    }

    this.l4Form.controls["staffUserName"].disable();
    this.l4Form.controls["branchCode"].disable();
    this.l4Form.controls["userId"].disable();
    this.l4Form.controls["lastUpdate"].disable();

    var prm = {
      first: 0,
      globalFilter: null
    };

    this.lazyLoad(prm, 'l4');
  }

  selectedFromLov(ev: any) {
    var x = ev.selected.slice();

    this.lovData.selectAllTag = ev.selectAllTag;

    if(this.lovData.type == 'BMM082_userLevel1') {
      this.l1ListFromLov = [];

      if(ev.selected.length == 1) {
        this.l1Form.patchValue({
          headUserId:  ev.selected[0].USER_ID,
          headUserName: ev.selected[0].USER_NAME
        });
      } else if(ev.selected.length > 1) {
        this.l1Form.patchValue({
          headUserId:  'Multiple',
          headUserName: 'Multiple'
        });
      }

      this.l1ListFromLov = x;
    } else if(this.lovData.type == 'BMM083_userLevel2') {
      this.l2ListFromLov = [];

      if(ev.selected.length == 1) {
        this.l2Form.patchValue({
          headUserId:  this.selectedRowL1.headUserId,
          officerUserId: ev.selected[0].USER_ID,
          officerUserName: ev.selected[0].USER_NAME
        });
      } else if(ev.selected.length > 1) {
        this.l2Form.patchValue({
          headUserId:  this.selectedRowL1.headUserId,
          officerUserId: 'Multiple',
          officerUserName: 'Multiple'
        });
      }

      this.l2ListFromLov = x;
    } else if(this.lovData.type == 'BMM084_userLevel3') {
      this.l3ListFromLov = [];

      if(ev.selected.length == 1) {
        this.l3Form.patchValue({
          headUserId:  this.selectedRowL1.headUserId,
          officerUserId: this.selectedRowL2.officerUserId,
          assistantUserId: ev.selected[0].USER_ID,
          assistantUserName: ev.selected[0].USER_NAME
        });
      } else if(ev.selected.length > 1) {
        this.l3Form.patchValue({
          headUserId:  this.selectedRowL1.headUserId,
          officerUserId: this.selectedRowL2.officerUserId,
          assistantUserId: 'Multiple',
          assistantUserName: 'Multiple'
        });
      }

      this.l3ListFromLov = x;
    } else if(this.lovData.type == 'BMM085_userLevel4') {
      this.l4aListFromLov = [];

      if(ev.selected.length == 1) {
        this.l4Form.patchValue({
          headUserId:  this.selectedRowL1.headUserId,
          officerUserId: this.selectedRowL2.officerUserId,
          assistantUserId: this.selectedRowL3.assistantUserId,
          staffUserId: ev.selected[0].USER_ID,
          staffUserName: ev.selected[0].USER_NAME
        });
      } else if(ev.selected.length > 1) {
        this.l4Form.patchValue({
          headUserId:  this.selectedRowL1.headUserId,
          officerUserId: this.selectedRowL2.officerUserId,
          assistantUserId: this.selectedRowL3.assistantUserId,
          staffUserId: 'Multiple',
          staffUserName: 'Multiple'
        });
      }

      this.l4aListFromLov = x;
    } else if(this.lovData.type == 'BMM152_userLevel4') {
      this.l4bListFromLov = [];

      if(ev.selected.length == 1) {
        this.l4Form.patchValue({
          headUserId:  this.selectedRowL1.headUserId,
          officerUserId: this.selectedRowL2.officerUserId,
          assistantUserId: this.selectedRowL3.assistantUserId,
          srcExtCd: ev.selected[0].SRC_EXT_CD,
          branchCode: ev.selected[0].BR_CODE
        });
      } else if(ev.selected.length > 1) {
        this.l4Form.patchValue({
          headUserId:  this.selectedRowL1.headUserId,
          officerUserId: this.selectedRowL2.officerUserId,
          assistantUserId: this.selectedRowL3.assistantUserId,
          srcExtCd: 'Multiple',
          branchCode: 'Multiple'
        });
      }

      this.l4bListFromLov = x;
    }
  }

  confirmDelete() {
    this.onClickAddUpdate(this.toDelete, true);
  }

  bulkCount: number = 100;
  lastUploadIndex: number = 0;
  excelRows: any[] = [];
  excelLength: number = 0;
  uploadExcelRows: any[] = [];

  onClickUpload(data: any) {
    this.lastUploadIndex = 0;
    this.excelRows = data;
    this.excelLength = data.length;
    
    this.proceedUpload();
  }

  proceedUpload() {
    var batchCount = this.bulkCount;
		var limit = (this.lastUploadIndex + batchCount) > this.excelLength ? this.excelLength : (this.lastUploadIndex + batchCount);
		this.uploadExcelRows = [];

		for(var i = this.lastUploadIndex; i < limit; i++) {
      if(i == 0) {
        this.excelRows[i].INITIAL = 'Y';
      }

			this.uploadExcelRows.push(this.excelRows[i]);
		}

		this.lastUploadIndex = limit;
		this.uploadUserLevel();
  }

  uploadUserLevel() {
    this.jds.contorlLoading(true);
    this.api.saveUploadUserLevel(this.uploadExcelRows).subscribe((res: any) => {
      this.ss.checkRequestKeyResponse(res, async () => {
        var data = JSON.parse(this.jds.decrypt(res.content));

        var rs = data.results;

        for(var i = 0; i < rs.length; i++) {
          if(rs[i] !== 'Successful') {
            this.excelRows[(this.lastUploadIndex-this.uploadExcelRows.length)+i]['failed'] = true;
          }

          this.excelRows[(this.lastUploadIndex-this.uploadExcelRows.length)+i]['STATUS'] = rs[i];
        }

        if(this.lastUploadIndex !== this.excelLength) {
					this.proceedUpload();
				} else {
					this.lastUploadIndex = 0;
          this.jds.contorlLoading(false);

          // this.expansionPanelController(null, 'l1');
          this.ep1.close();
          this.l1Expanded = false;

          this.lazyLoad({
            first: this.tblConfigViewAll.tblData ? 0 : this.tblConfigViewAll.tblData[0]['rownum_'] - 1,
            globalFilter: this.tblViewAll.filterText
          }, 'all');
				}
      });
    });
  }

}
