import { Component, OnInit } from '@angular/core';
import { APICallService } from 'src/app/services/api-call.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { Observable } from 'rxjs';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { SecurityService } from 'src/app/services/security.service'; 
import { PlanLovComponent } from '../../common/plan-lov/plan-lov.component';
import { CyberPremiumFrequencyLov } from '../../common/cyber-prem-freq-lov/cyber-prem-freq-lov.component';

@Component({
  selector: 'cyber-premiums-maintenance',
  templateUrl: './cyber-premiums-maintenance.component.html',
  styleUrls: ['./cyber-premiums-maintenance.component.css']
})
export class CyberPremiumsMaintenanceComponent implements OnInit, LeaveConfirmation {
  dPlan: string = '';
  dFrequency: string = '';
  planDisabled:boolean = false;
  freqDisabled: boolean = true;
  saveDisabled : boolean = true;
  deleteDisabled : boolean = true;
  noLoadingInProcess : boolean = true;
  userIDReadOnly : boolean = false;
  isLineSelected : boolean = false;
  currentUser: string = this.jsonDataService.retrieveFromStorage('userId');
  rowClickedData: any;
  selectedData : any = null;
  moduleId: string = 'BMM194';
  moduleName: string = '';
  
  paramForm = this.formBuilder.group({
    lineCd: ['PC'],
    sublineCd: ['PCI'],
    planCd: [null,[Validators.required]],
    freqCd: [null,[Validators.required]],
    premDesc: [null,[Validators.required]],
    premAmt: [null,[Validators.required]],
    dstAmt: [null,[Validators.required]],
    vatAmt: [null,[Validators.required]],
    lgtAmt: [null,[Validators.required]],
    totPrem: [null,[Validators.required]],
    covPerInc: [null,[Validators.required]],
    maxCov: [null,[Validators.required]],
    activeTag: ['Y',[Validators.required]],
    bmCode: [null],
    bmShortDesc: [null],
    remarks: [null],
    userId: [this.userDetailService.userId,[Validators.required]],
    lastUpdate: [null]
  });

  constructor(
    private apiCallService: APICallService,
    public _Dialog: MatDialog, 
    private userDetailService: UserDetailsService,
    public formService: FormService, 
    private formBuilder: FormBuilder, 
    private appMessageService: AppMessageService,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService
  ) {  
    
    let moduleLists = JSON.parse(this.jsonDataService.decrypt(sessionStorage.getItem("mdls") || "")); 
    this.moduleName = moduleLists.filter((data: any) => { return data.moduleId === this.moduleId })[0].moduleDesc.toUpperCase();
  }

  canDeactivate(): Observable<boolean> | boolean {
    return false;
  }

  premiumsConfig: any = {
    cols: [
      {
        key: "PREM_DESC",
        header: "Premium Description",
        dataType: "string",
        width: '80%'
      },
      {
        key: "ACTIVE_TAG",
        header: "Active",
        dataType: "checkbox",
        width: '10%'
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 5,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }
  ngOnInit() {
    this.initOnGet();
  }

  initOnGet(): void {
    try {
      this.saveDisabled = true
      this.apiCallService.refreshNeeded$
            .subscribe(() => {
              this.getAllCyberPremiums();
          });
      this.getAllCyberPremiums();
    }
    catch(e) {
    }
  }

  async getAllCyberPremiums(){
    try{
      this.premiumsConfig.loading = true;
      this.apiCallService.getAllCyberPremiums().subscribe({
        next: (data: any) => {
          this.securityService.checkRequestKeyResponse(data, ()=>{
            this.securityService.hasValidCsrfToken(data, ()=>{
              data = JSON.parse(this.jsonDataService.decrypt(data.response));
              this.premiumsConfig.tblData = (data || []).map((p: { ACTIVE_TAG: boolean }, index: number) => ({ ...p, ACTIVE_TAG: p.ACTIVE_TAG ? 'Y' : 'N', index }))
              this.premiumsConfig.totalRecords = (data ?? []).length ?? 0
              this.premiumsConfig.loading = false
            });
          })
        },
        error: (e: any) => {
          this.premiumsConfig.loading = false
        }
      });
    }catch(e){
    }
  }

  updateLine() {
    try {
      const _allowed = this.requiredFieldCheck();
      if (_allowed) {
        this.addFormToTable({ new: false });
      }
    }
    catch(e) {
    }
  }

  deleteLine() {
    try {
      const _tableData = this.premiumsConfig.tblData.slice()
      _tableData.splice(this.rowClickedData.index, 1)

      this.premiumsConfig.tblData = _tableData.map((d: any, index: any) => ({ ...d, index }))
      this.premiumsConfig.totalRecords = _tableData.length
      this.resetFormValues()
    } catch(e) {}
  }

  insertLine() {
    try {
      const _allowed = this.requiredFieldCheck();
      if (_allowed) {
        this.addFormToTable();
      }
    }
    catch(e) {
    }
  }

  addFormToTable(additional: any = {
    new: true
  }) {
    try {
      const _row = {
        LINE_CD: 'PC',
        SUBLINE_CD: 'PCI',
        PLAN_CD: this.paramForm.get('planCd')?.value,
        FREQ_CD: this.paramForm.get('freqCd')?.value,
        PREM_DESC: this.paramForm.get('premDesc')?.value,
        PREM_AMT: this.paramForm.get('premAmt')?.value,
        DST_AMT: this.paramForm.get('dstAmt')?.value,
        VAT_AMT: this.paramForm.get('vatAmt')?.value,
        LGT_AMT: this.paramForm.get('lgtAmt')?.value,
        TOT_PREM: this.paramForm.get('totPrem')?.value,
        COV_PER_INC: this.paramForm.get('covPerInc')?.value,
        MAX_COV: this.paramForm.get('maxCov')?.value,
        ACTIVE_TAG: this.paramForm.get('activeTag')?.value,
        BM_CODE: this.paramForm.get('bmCode')?.value,
        BM_SHORT_DESC: this.paramForm.get('bmShortDesc')?.value,
        REMARKS: this.paramForm.get('remarks')?.value,
        USER_ID: this.userDetailService.userId,
        LAST_USER_UPDATE: [null],
        index: this.premiumsConfig.totalRecords ?? 0,
        local: true,
        ...(additional.new && additional)
      }

      const _table = this.premiumsConfig.tblData.slice()

      if (additional.new) {
        const _duplicates = (this.premiumsConfig.tblData || []).find((t: { PLAN_CD: any; FREQ_CD: any; }) => t.PLAN_CD === _row.PLAN_CD && t.FREQ_CD === _row.FREQ_CD)
  
        if (_duplicates) {
          this.appMessageService.showAppMessage("Premium already exists", "success");
          return
        }

        _table.push(_row)
  
        this.premiumsConfig.totalRecords = (this.premiumsConfig.totalRecords ?? 0) + 1
        this.premiumsConfig.tblData = _table
      } else {
        _row.index = this.rowClickedData.index
        delete _row.local
        _row.updated = true

        _table[this.rowClickedData.index] = _row
        this.premiumsConfig.tblData = _table
      }
      
      this.saveDisabled = false;
      this.resetFormValues();
      this.appMessageService.showAppMessage(`Cyber Premium details ${additional.nnew ? 'added' : 'updated'}!`, "success");
    }
    catch(e) {
    }
  }

  saveData(records:any[]){
    this.apiCallService.handleCyberPremiums({ records })
    .subscribe({
      next: (response : any) => {
        this.securityService.checkRequestKeyResponse(response, () => {
          this.securityService.hasValidCsrfToken(response, ()=>{
            if (response.status == "SUCCESS") {
              this.openDialog('SUCCESS', 'Details Successfully saved!');
              this.initOnGet();
              this.resetFormValues();
            }  
            else {
              this.initOnGet();
              this.openDialog('ERROR', 'An error has occured while saving details');
            }
          });
        });
      }, 
      error: () => {
      }
    });
  }

  toSave() {
    try {
      this.noLoadingInProcess = false;
      this.formService.showFormLoader(null, "generate-line-form", "Please wait ...", null, null);
      const _table = this.premiumsConfig.tblData.slice()
      const records = _table
        .filter(({ local, updated }: { local: boolean, updated: boolean}) => local === true || updated === true)
        .map((r: any) => ({ ...r, ACTIVE_TAG: r.ACTIVE_TAG === 'Y' ? true : false }))

      if (records.length) {
        this.saveData(records)
      } else {
        this.initOnGet();
        this.resetFormValues();
      }
    }
    catch(e) {
    }
  }

  onRowClick(ev: any) {
    try {    
      if(ev!=null) {
        this.rowClickedData = ev;
        this.loadFormValues();
      }
      else {
        this.resetFormValues();
      }
    }
    catch(e) {
    }
  }

  loadFormValues() {
    try {
      this.paramForm.patchValue({
        planCd: this.rowClickedData.PLAN_CD,
        freqCd: this.rowClickedData.FREQ_CD,
        premDesc: this.rowClickedData.PREM_DESC,
        premAmt: this.rowClickedData.PREM_AMT,
        dstAmt: this.rowClickedData.DST_AMT,
        vatAmt: this.rowClickedData.VAT_AMT,
        lgtAmt: this.rowClickedData.LGT_AMT,
        totPrem: this.rowClickedData.TOT_PREM,
        covPerInc: this.rowClickedData.COV_PER_INC,
        maxCov: this.rowClickedData.MAX_COV,
        activeTag: this.rowClickedData.ACTIVE_TAG,
        bmCode: this.rowClickedData.BM_CODE,
        bmShortDesc: this.rowClickedData.BM_SHORT_DESC,
        remarks: this.rowClickedData.REMARKS,
        userId: this.rowClickedData.USER_ID,
        lastUpdate: this.rowClickedData.LAST_USER_UPDATE
      });
      this.dPlan =  [this.rowClickedData.PLAN_CD, this.rowClickedData.PLAN_NAME].filter(value => {return value !== null && value !== undefined}).join(' - ')
      this.dFrequency =  [this.rowClickedData.FREQ_CD, this.rowClickedData.FREQ_DESC].filter(value => {return value !== null && value !== undefined}).join(' - ')
      this.isLineSelected = true;

      if (!this.rowClickedData.local! ?? false) {
        this.planDisabled = true;
        this.freqDisabled = true;
        this.deleteDisabled = true;
      } else {
        this.deleteDisabled = false
      }
    }
    catch(e) {
    }
  }

  resetFormValues() {
    try {
      this.planDisabled = false;
      this.freqDisabled = true;
      this.selectedData = null;
      this.paramForm.patchValue({
        activeTag: null,
        planCd: null,
        freqCd: null,
        premDesc: null,
        premAmt: null,
        dstAmt: null,
        vatAmt: null,
        lgtAmt: null,
        totPrem: null,
        covPerInc: null,
        maxCov: null,
        bmCode: null,
        bmShortDesc: null,
        remarks: null,
        userId: this.userDetailService.userId,
        lastUpdate: null
      });

      this.deleteDisabled = true;
      // TODO later validate needed vars
      this.userIDReadOnly = false;
      this.isLineSelected = false;
      this.rowClickedData = '' || null;
      this.dPlan = ''
      this.dFrequency = ''
    }
    catch (e) {
    console.error(e);
    }
  }

  openPlanDialog() {
    try {
      this._Dialog.open(PlanLovComponent, {
        width: '512px',
        disableClose: true,
        data: {
          table: 'CYBER PLAN',
          lineCd: this.paramForm.get('lineCd')?.value,
          sublineCd: this.paramForm.get('sublineCd')?.value,
          userId: this.userDetailService.userId,
          moduleId: this.moduleId
        }
      }).afterClosed().subscribe(data => {
        if (data?.content != '' && data?.content != null) {
          const { PLAN_CD, PLAN_NAME } = data.content
          this.dPlan = [PLAN_CD, PLAN_NAME].filter(value => {return value !== null && value !== undefined}).join(' - ')
          this.dFrequency = ''
          this.paramForm.get('planCd')?.setValue(parseInt(PLAN_CD) ?? null);
          this.paramForm.get('freqCd')?.setValue(null);
          this.freqDisabled = false;
        }
      });
    } catch(e) {
      console.error('Error on openPlanDialog', e)
    }
  }

  openPlanFrequenciesDialog() {
    try {
      this._Dialog.open(CyberPremiumFrequencyLov, {
        width: '512px',
        disableClose: true,
        data: {
          planCd: this.paramForm.get('planCd')?.value,
          userId: this.userDetailService.userId,
          moduleId: this.moduleId
        }
      }).afterClosed().subscribe(data => {
        if (data?.content != '' && data?.content != null) {
          const { FREQ_CD, FREQ_DESC } = data.content
          this.dFrequency = [FREQ_CD, FREQ_DESC].filter(value => {return value !== null && value !== undefined}).join(' - ')
          this.paramForm.get('freqCd')?.setValue(FREQ_CD ?? null);
        }
      });
    } catch(e) {
      console.error('Error on openPlanFrequenciesDialog', e)
    }
  }

  openDialog(title: any, message: any) {
    try {
      this.noLoadingInProcess = true;
      this.formService.hideFormLoader("generate-line-form");
      this._Dialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        } 
      });
    } catch(e) {
    }
  }

  requiredFieldCheck() {
    try {
      if(
        [null, '', undefined].includes(this.paramForm.get('lineCd')?.value) ||
        [null, '', undefined].includes(this.paramForm.get('sublineCd')?.value) ||
        [null, '', undefined].includes(this.paramForm.get('planCd')?.value) ||
        [null, '', undefined].includes(this.paramForm.get('freqCd')?.value) ||
        [null, '', undefined].includes(this.paramForm.get('premDesc')?.value) ||
        [null, '', undefined].includes(this.paramForm.get('premAmt')?.value) ||
        [null, '', undefined].includes(this.paramForm.get('dstAmt')?.value) ||
        [null, '', undefined].includes(this.paramForm.get('vatAmt')?.value) ||
        [null, '', undefined].includes(this.paramForm.get('lgtAmt')?.value) ||
        [null, '', undefined].includes(this.paramForm.get('totPrem')?.value) ||
        [null, '', undefined].includes(this.paramForm.get('covPerInc')?.value) ||
        [null, '', undefined].includes(this.paramForm.get('maxCov')?.value) ||
        [null, '', undefined].includes(this.paramForm.get('activeTag')?.value)
      ) {
        this.formService.showFormMsg("bfb-standard-rates-error-message", 'There are missing information. Please provide the necessary information needed.', "E");
        return false;
      }
      else {
        return true;
      }
   }
    catch (e) {
      console.error(e);
      return false;
    }
  }

  allowNumericDigitsOnly(event: any) {
    try {
      if (/[0-9.]/.test(String.fromCharCode(event.keyCode))) {
        return true;
      }
      else {
        event.preventDefault();
        return false;
      }
    }
    catch (e) {
      console.error(e);
      return undefined;
    }
  }
}
