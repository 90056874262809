<section id="tax-dialog">
    <div class="mtn-title">
        <p>TAX</p>
    </div>
    <div>
        <osp-table [tblConfig]="taxTableConfig" (onRowClick)="onRowClick($event)"></osp-table>
    </div>
    <div class='button'>
        <button mat-button class="btn4" type="button" mat-dialog-close>Back</button>
        <button mat-button class="btn2" type="button" mat-dialog-close (click)="onClose()">OK</button>
    </div>

</section>
