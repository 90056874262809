<div [ngClass]="tblConfig.visible ? 'osp-tbl-wrapper' : 'osp-tbl-wrapper-inv'">

    <ng-container *ngIf="tblConfig.lazy == undefined || tblConfig.lazy == false || tblConfig.lazy == null">
        <p-table #tbl [globalFilterFields]="extractFilterFields()" [value]="tblConfig.tblData"
                [paginator]="tblConfig.visible ? tblConfig.paginator : false" [rows]="tblConfig.rowsPerPage ? tblConfig.rowsPerPage : 10" [(first)]="first"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [selectionMode]="tblConfig.selection" [(selection)]="selectedRow" 
                (onRowSelect)="emitSelected()" (onRowUnselect)="emitSelected()"
                styleClass="p-datatable-custom p-datatable-striped p-datatable-gridlines p-datatable-sm"
                [loading]="tblConfig.loading"
                loadingIcon="cust-loading-icon"
                [scrollable]="tblConfig.scrollable" [style]="{width:!tblConfig.scrollableWidth ? 'auto' : tblConfig.scrollableWidth}"
                [columns]="tblConfig.frozen ? tblConfig.frozen.scrollableCols : tblConfig.cols" [frozenColumns]="tblConfig.frozen ? tblConfig.frozen.frozenCols : null" [frozenWidth]="tblConfig.frozen ? tblConfig.frozen.frozenWidth : null">
            <ng-template *ngIf="tblConfig.tblCaption !== undefined" pTemplate="caption">
                {{ tblConfig.tblCaption }}
            </ng-template>
            <ng-template pTemplate="colgroup" let-columns>
                <colgroup *ngIf="tblConfig.visible">
                    <col *ngFor="let i of tblConfig.cols" [ngStyle]="{'width': !i.width ? 'auto' : i.width}"> 
                </colgroup>
            </ng-template>
            <ng-template *ngIf="(!tblConfig.loading && tblConfig.visible) || !tblConfig.tblData?.length" pTemplate="emptymessage">
                <tr>
                    <td [attr.colspan]="tblConfig.cols.length">
                        No Records found.
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <div *ngIf="tblConfig.visible && !tblConfig.withDropDown" class="p-d-flex tbl-search-wrapper">
                    <span>Search</span><input type="text" [(ngModel)]="filterText" (input)="tbl.filterGlobal($any($event.target).value, 'containsWithDateFormat'); emitAfterFilter()" />
                </div>

                <!-- Filter drop down enhancement -->
                <div *ngIf="tblConfig.visible && tblConfig.withDropDown" class="p-d-flex tbl-search-wrapper">
                    <span>Search</span><input type="text" [(ngModel)]="filterTextDrop" (input)="tbl.filterGlobal($any($event.target).value, 'containsWithDateFormat'); emitAfterFilter()" />
                    <span>Filter By</span>
                    <select #dropDown [(ngModel)]="filterDropDown" (input)="tbl.filterGlobal(filterTextDrop, 'containsWithDateFormat'); emitAfterFilter()" >
                        <option value ="all" selected>All</option>
                        <ng-container *ngFor="let i of tblConfig.cols">
                            <option *ngIf="!i.excFilter" [value]="i.key">{{ i.header.replace("^", " ") }}</option>
                        </ng-container>
                    </select>
                </div>

                <tr *ngIf="tblConfig.visible">
                    <ng-container *ngFor="let i of columns">
                        <th *ngIf="i.dataType !== 'button'" pSortableColumn="{{ i.key }}" [pSortableColumnDisabled]="i.selectAll !== undefined">
                            <ng-container *ngIf="i.header.includes('^'); else tH2">
                                <ng-container *ngFor="let x of i.header.split('^'); let j = index">
                                    <b>{{ x }}</b>
                                    <br *ngIf="j != i.header.split('^').length-1" />
                                </ng-container>
                            </ng-container>
                            <ng-template #tH2>
                                <b>{{ i.header }}</b>
                            </ng-template>
                            <p-sortIcon *ngIf="i.selectAll == undefined" field="{{ i.key }}"></p-sortIcon>

                            <br *ngIf="i.selectAll" />
                            <input *ngIf="i.selectAll" type="checkbox" (click)="i.confirmation ? emitSelectAllConfirmation() : toggleAll($event, i.key)" [checked]="i?.checked">
                        </th>
                        <th *ngIf="i.dataType == 'button'">
                            <b>{{ i.header }}</b>
                        </th>
                    </ng-container>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data let-columns="columns">
                <tr *ngIf="tblConfig.visible" [pSelectableRow]="tblConfig.selection == 'single' ? data : null">
                    <ng-container *ngFor="let x of columns">
                        <td *ngIf="x.dataType == 'string' || x.dataType == undefined" [ngClass]="x.align ? x.align : ''"><span>{{ data[x.key] }}</span></td>
                        <td *ngIf="x.dataType == 'checkbox'">
                            <input type="checkbox" [checked]="data[x.key] == 'A' || data[x.key] == 'Y'" [disabled]="x.disabled == undefined ? true : x.disabled">
                        </td>
                        <td *ngIf="x.dataType == 'checkbox-boolean'">
                            <input type="checkbox" [checked]="data[x.key] == 1" [disabled]="x.disabled == undefined ? true : x.disabled">
                        </td>
                        <td *ngIf="x.dataType == 'checkboxWithVal'">
                            <input type="checkbox" (click)="emitRowEvent(data, x.key); checkboxToggle(data, x.key, x.vals)" [checked]="data[x.key] == x.vals[0]"
                            [disabled]="x.disabled == undefined ? true : x.disabled || (data.disabledFields !== undefined && data.disabledFields.indexOf(x.key) !== -1)">
                        </td>
                        <td *ngIf="x.dataType == 'currency'" [ngClass]="x.align ? x.align : ''">
                            <span>{{ data[x.key] | number: '1.2-2' }}</span>
                        </td>
                        <td *ngIf="x.dataType == 'currWithoutDecimal'" [ngClass]="x.align ? x.align : ''">
                            <span>{{ data[x.key] | number: '1.0-2' }}</span>
                        </td>
                        <td *ngIf="x.dataType == 'rateDecimal'" [ngClass]="x.align ? x.align : ''">
                            <span>{{ data[x.key] | number: '1.9' }}</span>
                        </td>
                        <td *ngIf="x.dataType == 'number'" [ngClass]="x.align ? x.align : ''">
                            <span>{{ data[x.key] | number: '2.0' }}</span>
                        </td>
                        <td *ngIf="x.dataType == 'padNumber'" [ngClass]="x.align ? x.align : ''">
                            <span>{{ data[x.key] | number: '3.0' }}</span>
                        </td>
                        <td *ngIf="x.dataType == 'integer'" [ngClass]="x.align ? x.align : ''">
                            <span>{{ data[x.key] | number: '1.0' }}</span>
                        </td>
                        <td *ngIf="x.dataType == 'date'" [ngClass]="x.align ? x.align : ''">
                            <span>{{ data[x.key] | date: 'MM/dd/yyyy' }}</span>
                        </td>
                        <td *ngIf="x.dataType == 'button'">
                            <button (click)="emitRowEvent(data)" class="btn btn6 cust-btn">{{ x.btnTitle }}</button>
                        </td>
                    </ng-container>
                </tr>
            </ng-template>
        </p-table>
    </ng-container>

    <ng-container *ngIf="tblConfig.lazy !== undefined && tblConfig.lazy == true">
        <p-table #tbl [globalFilterFields]="extractFilterFields()" [value]="tblConfig.tblData"
                [paginator]="tblConfig.visible ? tblConfig.paginator : false" [rows]="tblConfig.rowsPerPage ? tblConfig.rowsPerPage : 10" [(first)]="first"
                [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [selectionMode]="tblConfig.selection" [(selection)]="selectedRow" 
                (onRowSelect)="emitSelected()" (onRowUnselect)="emitSelected()"
                styleClass="p-datatable-custom p-datatable-striped p-datatable-gridlines p-datatable-sm"
                [loading]="tblConfig.loading"
                loadingIcon="cust-loading-icon"
                [scrollable]="tblConfig.scrollable" [style]="{width:!tblConfig.scrollableWidth ? 'auto' : tblConfig.scrollableWidth}"
                [columns]="tblConfig.frozen ? tblConfig.frozen.scrollableCols : tblConfig.cols" [frozenColumns]="tblConfig.frozen ? tblConfig.frozen.frozenCols : null" [frozenWidth]="tblConfig.frozen ? tblConfig.frozen.frozenWidth : null"
                [lazy]="true"
                (onLazyLoad)="emitLazyLoad($event)"
                [totalRecords]="tblConfig.totalRecords"
                [pageLinks]="tblConfig.pageLinks">
            <ng-template *ngIf="tblConfig.tblCaption !== undefined" pTemplate="caption">
                {{ tblConfig.tblCaption }}
            </ng-template>
            <ng-template pTemplate="colgroup" let-columns>
                <colgroup *ngIf="tblConfig.visible">
                    <col *ngFor="let i of tblConfig.cols" [ngStyle]="{'width': !i.width ? 'auto' : i.width}"> 
                </colgroup>
            </ng-template>
            <ng-template *ngIf="(!tblConfig.loading && tblConfig.visible) || (!tblConfig.tblData?.length && tblConfig.visible)" pTemplate="emptymessage">
                <tr>
                    <td [attr.colspan]="tblConfig.cols.length">
                        No Records found.
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <div *ngIf="tblConfig.visible && !tblConfig.withDropDown" class="p-d-flex tbl-search-wrapper">
                    <span>Search</span><input #searchBox type="text" [(ngModel)]="filterText" [disabled]="searchDisabled" (input)="tbl.filterGlobal($any($event.target).value, 'containsWithDateFormat'); emitAfterFilter()" />
                </div>

                <!-- Filter drop down enhancement lazy load -->
                <div *ngIf="tblConfig.visible && tblConfig.withDropDown" class="p-d-flex tbl-search-wrapper">
                    <span>Search</span><input #searchBoxDrop type="text" [(ngModel)]="filterTextDrop" [disabled]="searchDisabled" (input)="tbl.filterGlobal({search: $any($event.target).value, column: filterDropDown}, 'containsWithDateFormat'); emitAfterFilter()" />
                    <span>Filter By</span>
                    <select #dropDown [(ngModel)]="filterDropDown" (input)="tbl.filterGlobal({search: filterTextDrop, column: $any($event.target).value}, 'containsWithDateFormat'); emitAfterFilter()" >
                        <option value ="all" selected>All</option>
                        <ng-container *ngFor="let i of tblConfig.cols">
                            <option *ngIf="!i.excFilter" [value]="i.filterValue">{{ i.header.replace("^", " ") }}</option>
                        </ng-container>
                    </select>
                </div>

                <tr *ngIf="tblConfig.visible">
                    <ng-container *ngFor="let i of columns">
                        <th *ngIf="i.dataType !== 'button'" pSortableColumn="{{ i.key }}" [pSortableColumnDisabled]="i.selectAll !== undefined">
                            <ng-container *ngIf="i.header.includes('^'); else tH2">
                                <ng-container *ngFor="let x of i.header.split('^'); let j = index">
                                    <b>{{ x }}</b>
                                    <br *ngIf="j != i.header.split('^').length-1" />
                                </ng-container>
                            </ng-container>
                            <ng-template #tH2>
                                <b>{{ i.header }}</b>
                            </ng-template>
                            <p-sortIcon *ngIf="i.selectAll == undefined" field="{{ i.key }}"></p-sortIcon>

                            <br *ngIf="i.selectAll && i.header !== ''" />
                            <input *ngIf="i.selectAll" type="checkbox" (click)="i.confirmation ? emitSelectAllConfirmation($event, i.key) : toggleAll($event, i.key)" [checked]="i?.checked">
                        </th>
                        <th *ngIf="i.dataType == 'button'">
                            <b>{{ i.header }}</b>
                        </th>
                    </ng-container>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-data let-columns="columns">
                <tr *ngIf="tblConfig.visible" [pSelectableRow]="tblConfig.selection == 'single' ? data : null">
                    <ng-container *ngFor="let x of columns">
                        <td *ngIf="x.dataType == 'string' || x.dataType == undefined" [ngClass]="x.align ? x.align : ''"><span>{{ data[x.key] }}</span></td>
                        <td *ngIf="x.dataType == 'checkbox'">
                            <input type="checkbox" [checked]="data[x.key] == 'A' || data[x.key] == 'Y'" [disabled]="x.disabled == undefined ? true : x.disabled">
                        </td>
                        <td *ngIf="x.dataType == 'checkboxWithVal'">
                            <input type="checkbox" (click)="emitRowEvent(data, x.key); checkboxToggle(data, x.key, x.vals)" [checked]="data[x.key] == x.vals[0]"
                            [disabled]="x.disabled == undefined ? true : x.disabled || (data.disabledFields !== undefined && data.disabledFields.indexOf(x.key) !== -1)">
                        </td>
                        <td *ngIf="x.dataType == 'currency'" [ngClass]="x.align ? x.align : ''">
                            <span>{{ data[x.key] | number: '1.2-2' }}</span>
                        </td>
                        <td *ngIf="x.dataType == 'currWithoutDecimal'" [ngClass]="x.align ? x.align : ''">
                            <span>{{ data[x.key] | number: '1.0-2' }}</span>
                        </td>
                        <td *ngIf="x.dataType == 'rateDecimal'" [ngClass]="x.align ? x.align : ''">
                            <span>{{ data[x.key] | number: '1.9' }}</span>
                        </td>
                        <td *ngIf="x.dataType == 'number'" [ngClass]="x.align ? x.align : ''">
                            <span>{{ data[x.key] | number: '2.0' }}</span>
                        </td>
                        <td *ngIf="x.dataType == 'padNumber'" [ngClass]="x.align ? x.align : ''">
                            <span>{{ data[x.key] | number: '3.0' }}</span>
                        </td>
                        <td *ngIf="x.dataType == 'integer'" [ngClass]="x.align ? x.align : ''">
                            <span>{{ data[x.key] | number: '1.0' }}</span>
                        </td>
                        <td *ngIf="x.dataType == 'date'" [ngClass]="x.align ? x.align : ''">
                            <span>{{ data[x.key] | date: 'MM/dd/yyyy' }}</span>
                        </td>
                        <td *ngIf="x.dataType == 'datetime'" [ngClass]="x.align ? x.align : ''">
                            <span>{{ data[x.key] | date: 'MM/dd/yyyy h:mm:ss a' }}</span>
                        </td>
                        <td *ngIf="x.dataType == 'button'">
                            <button (click)="emitRowEvent(data)" class="btn btn6 cust-btn">{{ x.btnTitle }}</button>
                        </td>
                    </ng-container>
                </tr>
            </ng-template>
        </p-table>
    </ng-container>
</div>