<div class="mtn-container">
    <div class="mtn-title">
        <p>USER ACCESS</p>
    </div>
    <div id="user-access-mtn-loading"><div class="form-loader"></div></div>
    <div *ngIf="showForm">
        <osp-table [tblConfig]="MdlGrpTblConfig" (onRowClick)="onRowClickMdlGrp($event)"></osp-table>
        <div class="form-content">
            <form [formGroup]="mdlGrpForm" class="form">
                <div class="form-container-grid-2">
                    <div class="field">
                        <label>Group Description</label>
                    </div>
                    <div class="">
                        <input formControlName="groupDesc">
                    </div>
                    <div class="field">
                        <label>All Access</label>
                    </div>
                    <div class="">
                        <select formControlName="allAccess">
                            <option *ngFor="let accessType of accessTypes" [value]="accessType.cd">
                                {{accessType.name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="btn-grp-2">
                    <button class="btn btn2" type="button" (click)="onUpdateMdlGrp()">UPDATE</button>
                </div>
            </form>
        </div>
        <osp-table [tblConfig]="MdlsTblConfig" (onRowClick)="onRowClickMdls($event)"></osp-table>
        <!-- <button type="button" (click)="testFunc()">Test Show</button> -->
        <div *ngIf="MdlsTblConfig.visible" class="form-content">
            <form [formGroup]="mdlsForm" class="form">
                <div class="form-container-grid-2">
                    <div class="field">
                        <label>Module ID</label>
                    </div>
                    <div class="">
                        <input formControlName="moduleId">
                    </div>
                    <div class="field">
                        <label>Module Name</label>
                    </div>
                    <div class="">
                        <input formControlName="moduleName">
                    </div>
                    <div class="field">
                        <label>Access</label>
                    </div>
                    <div class="">
                        <select formControlName="access">
                            <option *ngFor="let accessType of accessTypes" [value]="accessType.cd">
                                {{accessType.name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="btn-grp-2">
                    <button class="btn btn2" type="button" (click)="onUpdateMdls()">UPDATE</button>
                </div>
            </form>
        </div>
        <div class="btn-grp-2 btn-grp-2-l">
            <button class="btn btn4" type="button" (click)="onClose()">BACK</button>
            <button class="btn btn2" type="button" (click)="onSave()">SAVE</button>
        </div>
    </div>
</div>
