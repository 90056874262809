import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { APICallService } from '../../../services/api-call.service';
import { OspTableComponent } from '../../common/osp-table/osp-table.component';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { MatDialog } from '@angular/material/dialog';
import { JsonDataService } from '../../../services/json-data.service';
import { FormService } from 'src/app/services/form.service';
import { Observable } from 'rxjs';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { SecurityService } from 'src/app/services/security.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { LazyLoadEvent } from 'primeng/api';
import { LovComponent } from '../../common/lov/lov.component';
import { UploadComponent } from '../../common/upload/upload.component';

@Component({
  selector: 'policy-sequence-number-mtn',
  templateUrl: './policy-sequence-number-mtn.component.html',
  styleUrls: ['./policy-sequence-number-mtn.component.css']
})
export class PolicySequenceNumberMtnComponent implements OnInit {

  @ViewChild('tbl') tbl!: OspTableComponent;
  @ViewChild('lov') lov!: LovComponent;
  @ViewChild('upload') upload!: UploadComponent;
  @ViewChild('deleteDialog') deleteDialog!: TemplateRef<any>;

  tblConfig: any = {
    cols: [
      {
        key: "bmPolSeqNo",
        header: "Policy Sequence",
        dataType: "string",
        width: "33%"
      },
      {
        key: "paytRefNo",
        header: "Payment Reference No.",
        dataType: "string",
        width: "33%"
      },
      {
        key: "policyNo",
        header: "Policy No.",
        dataType: "string",
        width: "33%"
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: true,
    totalRecords: 1,
    pageLinks: 1
  };

  lovData: any = {
    type: ''
  };

  uploadConfig: any = {
    uploadFileKeys: ['POL_PREFIX','POL_SEQ_NO','PAYT_REF_NO'],
    tblConfig: {
      cols: [
        {
          key: "POL_PREFIX",
          header: "POL_PREFIX",
          dataType: "string",
          width: "15%"
        },
        {
          key: "POL_SEQ_NO",
          header: "POL_SEQ_NO",
          dataType: "string",
          width: "15%",
        },
        {
          key: "PAYT_REF_NO",
          header: "PAYT_REF_NO",
          dataType: "string",
          width: "26%"
        },
        {
          key: "STATUS",
          header: "STATUS",
          dataType: "string",
          width: "44%",
          align: 'text-l'
        },
      ],
      tblData: [],
      selection: "single",
      paginator: true,
      rowsPerPage: 10,
      lazy: true,
      totalRecords: 1,
      pageLinks: 1
    },
    onClickUpload: (data: any) => {
      return this.onClickUpload(data);
    }
  };

  filterPolSeqNoForm: FormGroup = this.fb.group({
    contractTypeCdFilter: [""],
    contractTypeFilter: [""],
    minSeqNo: [""],
    maxSeqNo: [""],
    recordTypeFilter: ["A"]
	});

  options: any = {
    recordType: []
  };

  form: FormGroup = this.fb.group ({
    contractTypeCd: [""],
    bmPolSeqNo: [""],
    paytRefNo: [""],
    policyNo: [""],
    remarks: [""],
    userId: [""],
    lastUpdate: [""]
  });

  moduleId: string = "BMM032";
  moduleName: string = '';
  globalLazyLoadEv: any = {};

  saveList: any[] = [];
  selectedRow: any = null;

  minSeqNo: number = 0;
  maxSeqNo: number = 0;

  isSaving :boolean = false;

  dlgNote: string = "NOTE: Make sure that new set of Policy Sequence Numbers will be uploaded after this activity to prevent any error in the issuance of the policy in OSP."

  constructor(
    private api: APICallService,
    private fb: FormBuilder,
    private jds: JsonDataService,
    private uds: UserDetailsService,
    private ss: SecurityService,
    private ams: AppMessageService,
    public fs: FormService,
    public dialog: MatDialog
  ) {
    this.filterPolSeqNoForm.controls["contractTypeFilter"].disable();
    this.form.controls["bmPolSeqNo"].disable();
    this.form.controls["paytRefNo"].disable();
    this.form.controls["policyNo"].disable();
    this.form.controls["userId"].disable();
    this.form.controls["lastUpdate"].disable();
    this.form.controls["remarks"].disable();
  }

  ngOnInit(): void {
    this.options.recordType = this.jds.data.refCds.filter((a: any) => a.rvDomain == 'POL_RECORD_TYPE');
    this.moduleName = this.jds.data.moduleList.filter((data: any) => { return data.moduleId === this.moduleId })[0].moduleDesc.toUpperCase();
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.form.pristine;
  }

  lazyLoad(ev: any) {
    let x = this.filterPolSeqNoForm.getRawValue();

    this.globalLazyLoadEv = ev;

    if(x.contractTypeCdFilter !== "") {
      setTimeout(() => {
        this.getPolicySequenceNumber({
          contractTypeCd: x.contractTypeCdFilter == "" ? null : x.contractTypeCdFilter,
          polRecordType: x.recordTypeFilter,
          first: ev.first,
          rows: this.tblConfig.rowsPerPage, 
          search: ev.globalFilter,
          sortBy: ev.sortField == undefined ? 'bmPolSeqNo' : ev.sortField,
          sortDir: ev.sortField == undefined ? 1 : ev.sortOrder
        });
      }, 0);
    }
  }

  triggerLazy() {
    this.globalLazyLoadEv.first = 0;
    this.form.reset();

    this.form.markAsPristine();
    this.lazyLoad(this.globalLazyLoadEv);
  }

  getPolicySequenceNumber(prm?: any) {
    let qryPrm: any = {};

    if(prm) {
      qryPrm = {
        contractTypeCd: prm.contractTypeCd,
        polRecordType: prm.polRecordType,
        first: prm.first,
        rows: prm.rows,
        search: prm.search,
        sortBy: prm.sortBy,
        sortDir: prm.sortDir
      }
    }

    this.jds.contorlLoading(true);

    this.api.getPolicySequenceNumber({
      moduleId: this.moduleId,
      userId: this.uds.userId,
      type: "MODULE",
      minMax: 'N',
      contractTypeCd: qryPrm.contractTypeCd,
      polRecordType: qryPrm.polRecordType,
      first: qryPrm.first,
      rows: qryPrm.rows,
      search: qryPrm.search,
      sortBy: qryPrm.sortBy,
      sortDir: qryPrm.sortDir
    }).subscribe((data: any) => {
      this.ss.checkRequestKeyResponse(data, () => {
        this.jds.contorlLoading(false);
        if (data.status === "SUCCESS") {
          data = JSON.parse(this.jds.decrypt(data.content));
          this.tblConfig.totalRecords = data['count'];
          this.tblConfig.pageLinks = 10;
          this.tblConfig.tblData = data['data'];
        } else {
          this.ams.showAppMessage(data.message, "error");
        }
      });
    });
  }

  onRowClick(ev: any) {
    this.selectedRow = ev;
    this.selectedRow ? this.form.get('remarks')?.enable() : this.form.get('remarks')?.disable();
    this.form.markAsPristine();
    this.updateForm(ev);
  }

  updateForm(ev: any) {
    this.form.patchValue({
      contractTypeCd: ev == null ? "" : ev.contractTypeCd,
      bmPolSeqNo: ev == null ? "" : ev.bmPolSeqNo,
      paytRefNo: ev == null ? "" : ev.paytRefNo,
      policyNo: ev == null ? "" : ev.policyNo,
      remarks: ev == null ? "" : ev.remarks,
      userId: ev == null ? "" : ev.userId,
      lastUpdate: ev == null ? "" : ev.lastUpdate
    });
  }

  openLov(type: string) {
    var title = '';
    if(type == 'contractType') {
      title = 'Contract Type';
    }

    this.lovData.type = type;

    this.lov.open(title);
  }

  selectedFromLov(ev: any) {
    var sel = ev.selected[0];

    if(this.lovData.type == 'contractType') {
      this.filterPolSeqNoForm.patchValue({
        contractTypeCdFilter:  sel.CONTRACT_TYPE_CD,
        contractTypeFilter: sel.DISPLAY
      });

      this.triggerLazy();
    }
  }

  onClickSave() {
    let x = this.form.getRawValue();
    x.delete = 'N';

    this.saveList.push(this.form.getRawValue());
    this.isSaving = true;
    this.fs.showFormLoader(
      this,
      "src-ext-form",
      "Please wait ...",
      "save",
      this.saveList
    );
  }

  save(params: any, del?: any) {
    this.api.savePolicySequenceNumber(params).subscribe((res: any) => {
      this.ss.checkRequestKeyResponse(res, async () => {
        var data = JSON.parse(this.jds.decrypt(res.content));
        this.isSaving = false;
        if(res.status == 'SUCCESS') {
          this.lazyLoad({
            first: this.tblConfig.tblData ? 0 : this.tblConfig.tblData[0]['rownum_'] - 1,
            globalFilter: this.tbl.filterText
          });

          this.updateForm(null);
          this.selectedRow = null;
          this.openDialog('success');
          this.saveList = [];
          this.fs.hideFormLoader("src-ext-form");
          this.form.markAsPristine();
          if(del) {
            this.deleteDialogRef.close();
          }
        } else {
          this.openDialog('error');
        }
      });
    });
  }

  openDialog(type: string) {
    var dlgTitle;
    var dlgContent;

    if(type == 'success') {
      dlgTitle = 'SUCCESS';
      dlgContent = 'Successfully saved!';
    } else if(type == 'error') {
      dlgTitle = 'ERROR';
      dlgContent = 'An error occured while saving. Please check field values.';
    } else if(type == 'required') {
      dlgTitle = 'WARNING';
      dlgContent = 'Please supply all the required fields.';
    } else if(type == 'info') {
      dlgTitle = 'INFO';
      dlgContent = 'Nothing to save';
    } else if(type == 'deleteError') {
      dlgTitle = 'ERROR';
      dlgContent = 'Unable to delete sequence with existing policy record.';
    } else if(type == 'deleteError2') {
      dlgTitle = 'ERROR';
      dlgContent = 'Start Sequence required';
    } else if(type == 'deleteError3') {
      dlgTitle = 'ERROR';
      dlgContent = 'Start Sequence cannot be greater than End Sequence.';
    } else if(type == 'deleteError4') {
      dlgTitle = 'ERROR';
      dlgContent = 'Nothing to delete.';
    }

    const dialogRole = this.dialog.open(DialogMsgComponent, { 
      disableClose: true,
      width: "500px",
      data: {
        title: dlgTitle,
        content: dlgContent
      }
    });
  }

  bulkCount: number = 100;
  lastUploadIndex: number = 0;
  excelRows: any[] = [];
  excelLength: number = 0;
  uploadExcelRows: any[] = [];

  onClickUpload(data: any) {
    this.lastUploadIndex = 0;
    this.excelRows = data;
    this.excelLength = data.length;
    
    this.proceedUpload();
  }

  proceedUpload() {
    var batchCount = this.bulkCount;
		var limit = (this.lastUploadIndex + batchCount) > this.excelLength ? this.excelLength : (this.lastUploadIndex + batchCount);
		this.uploadExcelRows = [];

		for(var i = this.lastUploadIndex; i < limit; i++) {
			this.uploadExcelRows.push(this.excelRows[i]);
		}

		this.lastUploadIndex = limit;
		this.uploadPolicySequenceNumber();
  }

  uploadPolicySequenceNumber() {
    this.jds.contorlLoading(true);
    this.api.saveUploadPolicySequenceNumber(this.uploadExcelRows).subscribe((res: any) => {
      this.ss.checkRequestKeyResponse(res, async () => {
        var data = JSON.parse(this.jds.decrypt(res.content));

        var rs = data.results;

        for(var i = 0; i < rs.length; i++) {
          if(rs[i] !== 'Successful') {
            this.excelRows[(this.lastUploadIndex-this.uploadExcelRows.length)+i]['failed'] = true;
          }

          this.excelRows[(this.lastUploadIndex-this.uploadExcelRows.length)+i]['STATUS'] = rs[i];
        }

        if(this.lastUploadIndex !== this.excelLength) {
					this.proceedUpload();
				} else {
					this.lastUploadIndex = 0;
          this.jds.contorlLoading(false);

          this.lazyLoad({
            first: this.tblConfig.tblData ? 0 : this.tblConfig.tblData[0]['rownum_'] - 1,
            globalFilter: this.tbl.filterText
          });
				}
      });
    });
  }

  disableStartSeq: boolean = true;
  deleteDialogRef: any = null;

  openDeleteDialog() {
    let x = this.filterPolSeqNoForm.getRawValue();

    this.jds.contorlLoading(true);
    this.disableStartSeq = true;
    this.api.getPolicySequenceNumber({
      moduleId: this.moduleId,
      userId: this.uds.userId,
      type: "MODULE",
      minMax: 'Y',
      contractTypeCd: x.contractTypeCdFilter
    }).subscribe((data: any) => {
      this.ss.checkRequestKeyResponse(data, () => {
        this.jds.contorlLoading(false);
        this.disableStartSeq = false;
        if (data.status === "SUCCESS") {
          data = JSON.parse(this.jds.decrypt(data.content));

          this.filterPolSeqNoForm.patchValue({
            minSeqNo: data.data[0].minPolSeqNo == null ? 0 : data.data[0].minPolSeqNo,
            maxSeqNo: data.data[0].maxPolSeqNo == null ? 0 : data.data[0].maxPolSeqNo
          });

          this.minSeqNo = data.data[0].minPolSeqNo == null ? 0 : data.data[0].minPolSeqNo;
          this.maxSeqNo = data.data[0].maxPolSeqNo == null ? 0 : data.data[0].maxPolSeqNo;
        } else {
          this.ams.showAppMessage(data.message, "error");
        }
      });
    });

    this.deleteDialogRef = this.dialog.open(this.deleteDialog, { 
      width: "500px",
      disableClose: true
    });
  }

  onClickProceed() {
    let x = this.filterPolSeqNoForm.getRawValue();

    if(String(this.minSeqNo) == '') {
      this.openDialog('deleteError2');
    } else if(this.minSeqNo < x.minSeqNo) {
      this.openDialog('deleteError');
    } else if(this.minSeqNo > this.maxSeqNo) {
      this.openDialog('deleteError3');
    } else if(this.minSeqNo == 0 && this.maxSeqNo == 0) {
      this.openDialog('deleteError4');
    } else {
      this.jds.contorlLoading(true);
      let prm = {
        delete: 'Y',
        start: this.minSeqNo,
        end: this.maxSeqNo,
        contractTypeCd: x.contractTypeCdFilter
      };

      this.save([prm], true);
    }
  }

}
