<div class="mtn-container">
    <div class="mtn-title">
        <p>{{ moduleName }}</p>
    </div>
    <osp-table [tblConfig]="tblConfig" (onRowClick)="onRowClick($event)"></osp-table>
    <div class="form-container" id="vehicle-form">
        <div class="form-loader"></div>
    </div>
    <div *ngIf="showForm" class="form-content">
        <form class="form" [formGroup]="vehicleMainForm" novalidate>
            <div id="vehicle-error-message"></div>
            <div class="form-container-grid">
                <div class="field required">
                    <label>Make</label>
                </div>
                <div class="">
                    <input formControlName="make" readonly>
                    <button matSuffix class="btn btn-search" type="button" (click)="openModelorMakeSelection('MAKE')"
                        [disabled]="tempDataRow">
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
                <div class="empty"></div>
                <div class="">
                    <button class="btn btn2 btn-mm" type="button" (click)="openModelorMake('MAKE')">ADD/UPDATE
                        MAKE</button>
                </div>
                <div class="field required">
                    <label>Model</label>
                </div>
                <div class="">
                    <input formControlName="model" readonly>
                    <button matSuffix class="btn btn-search" type="button" (click)="openModelorMakeSelection('MODEL')"
                        [disabled]="tempDataRow">
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
                <div class="empty"></div>
                <div class="">
                    <button class="btn btn2 btn-mm" type="button" (click)="openModelorMake('MODEL')">ADD/UPDATE
                        MODEL</button>
                </div>
                <div class="field">
                    <label>Sub Model Code</label>
                </div>
                <div class="">
                    <input formControlName="subModelCD" readonly>
                    <input class="" type="checkbox" formControlName="active">
                </div>
                <div class="field required">
                    <label>Sub Model</label>
                </div>
                <div class="">
                    <input class="uppercase" formControlName="subModel">
                </div>
                <div class="field required">
                    <label>Make Type Code</label>
                </div>
                <div class="">
                    <input formControlName="makeTypeCode" >
                </div>
                <div class="field required">
                    <label>No. of Seats</label>
                </div>
                <div class="">
                    <input formControlName="seats" (keypress)="allowNumericDigitsOnly($event)">
                </div>
                <div class="field">
                    <label>CC/Wt</label>
                </div>
                <div class="">
                    <input formControlName="ccWt" >
                </div>
                <div class="field">
                    <label>Diesel/Gas</label>
                </div>
                <div class="">
                    <select formControlName="gasType">
                        <option *ngFor="let gasType of gasTypes" [value]="gasType.cd">
                            {{ gasType.name }}
                        </option>
                    </select>
                </div>
                <div class="field required">
                    <label>Allowed in Risk Types</label>
                </div>
                <div class="">
                    <select formControlName="contractTypeCd">
                        <option *ngFor="let contractTypes of contractTypes" [value]="contractTypes.contractTypeCd">
                            {{ contractTypes.contractTypeDesc.substring(0,1) + " - " + contractTypes.contractTypeDesc }}
                        </option>
                    </select>
                </div>
                <div class="field required">
                    <label>Motor High Risk Group</label>
                </div>
                <div class="">
                    <select formControlName="highRiskId">
                        <option *ngFor="let highRiskIds of highRiskIds" [value]="highRiskIds.highRiskId">
                            {{ highRiskIds.highRiskId + " - " + highRiskIds.highRiskDesc }}
                        </option>
                    </select>
                </div>
                <div class="field required">
                    <label>Maximum Age</label>
                </div>
                <div class="">
                    <input formControlName="maxAge" numbersOnlyWithoutDecimal>
                </div>
                <div></div><div></div>
                <div class="field">
                    <label>Remarks</label>
                </div>
                <div class="span">
                    <input formControlName="remarks" >
                </div>
                <div class="field">
                    <label>Last Update User</label>
                </div>
                <div class="">
                    <input formControlName="userId" readonly>
                </div>
                <div class="field">
                    <label>Last Update</label>
                </div>
                <div class="">
                    <input formControlName="lastUpdate" readonly>
                </div>
            </div>
        </form>
        <div class="btn-grp">
            <button *ngIf="updateBtnDisabled" class="btn btn2" type="button" (click)="onAdd()" >ADD</button>
            <button *ngIf="!updateBtnDisabled" class="btn btn2" type="button" (click)="onUpdate()" >UPDATE</button>
            <button class="btn btn2" type="button" [disabled]='deleteBtnDisabled' (click)="onDelete()" >DELETE</button>
        </div>
        <div class="mtn-title"></div>
        <div class="btn-grp">
            <button class="btn btn2" type="button" [disabled]='saveBtnDisabled' (click)="onSave()" >SAVE</button>
        </div>
    </div>
</div>