<section id="curr-maint">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>DEDUCTIBLE MAINTENANCE PER CLIENT GROUP</p>
        </div>
        <div class="form-content">
            <form [formGroup]="paramForm" class="form">
                <div class="form-container-grid top-form">
                    <div class="field required">
                        <label>Client Group</label>
                    </div>
                    <div class="lov-search">
                        <input readonly formControlName="clientGroupField">
                        <button matSuffix class="btn-search btn" type="button" (click)="openLov('CLIENT_GROUP')">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                    <div class="field required">
                        <label>Contract Type</label>
                    </div>
                    <div class="lov-search">
                        <input readonly formControlName="contractTypeField">
                        <button matSuffix class="btn-search btn" type="button" (click)="openLov('CONTRACT_TYPE')">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                    <div class="field required">
                        <label>Line</label>
                    </div>
                    <div class="lov-search">
                        <input readonly formControlName="lineField">
                        <button matSuffix class="btn-search btn" type="button" (click)="openLov('LINE')">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                    <div class="field required">
                        <label>Subline</label>
                    </div>
                    <div class="lov-search">
                        <input readonly formControlName="sublineField">
                        <button matSuffix class="btn-search btn" type="button" (click)="openLov('SUBLINE')"
                            [disabled]="sublineDisabled">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <div class='table-line'>
            <osp-table [tblConfig]="deductibleTblConfig" (onRowClick)="onRowClick($event)"></osp-table>
        </div>
        <div id="generate-line-form">
            <div class="form-loader"></div>
        </div>
        <div class="form-content" [hidden]="formHider">
            <form [formGroup]="deductibleForm" class="form">
                <div id="error-message"></div>
                <div class="form-container-grid">
                    <div class="field required">
                        <label>Deductible</label>
                    </div>
                    <div class="lov-search">
                        <input readonly formControlName="deductibleField">
                        <button matSuffix class="btn-search btn" type="button" (click)="openLov('DEDUCTIBLE')"
                            [disabled]="deductibleDisabled">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag">
                            <option *ngFor="let activeTag of selections.activeTag" [value]="activeTag.cd">
                                {{activeTag.name}}
                            </option>
                        </select>
                    </div>
                    <div class="field">
                        <label>Deductible Text</label>
                    </div>
                    <div class="span">
                        <input formControlName="deductibleText" type="text">
                    </div>
                    <div class="field">
                        <label>Deductible Type</label>
                    </div>
                    <div class="">
                        <select formControlName="deductibleType">
                            <option *ngFor="let dedType of selections.dedTypes" [value]="dedType.cd">
                                {{dedType.cd}} - {{dedType.name}}
                            </option>
                        </select>
                    </div>
                    <div class="field">
                        <label>Range Switch</label>
                    </div>
                    <div class="">
                        <select formControlName="rangeSwitch">
                            <option *ngFor="let rangeSw of selections.rangeSwitches" [value]="rangeSw.cd">
                                {{rangeSw.cd}} - {{rangeSw.name}}
                            </option>
                        </select>
                    </div>
                    <div class="field">
                        <label>Deductible Rate</label>
                    </div>
                    <div class="">
                        <input formControlName="deductibleRate" type="text" maxlength="13"
                            (focusout)="deductibleRateFormat()">
                    </div>
                    <div class="field">
                        <label>Deductible Amount</label>
                    </div>
                    <div class="">
                        <input formControlName="deductibleAmt" type="text" maxlength="17"
                            (focusout)="amtFormat('deductibleAmt')">
                    </div>
                    <div class="field">
                        <label>Minimum Amount</label>
                    </div>
                    <div class="">
                        <input formControlName="minAmt" type="text" maxlength="17" (focusout)="amtFormat('minAmt')">
                    </div>
                    <div class="field">
                        <label>Maximum Amount</label>
                    </div>
                    <div class="">
                        <input formControlName="maxAmt" type="text" maxlength="17" (focusout)="amtFormat('maxAmt')">
                    </div>
                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" type="text">
                    </div>
                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input formControlName="lastUpdateUser" type="text" readonly>
                    </div>
                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="lastUpdate" type="text" readonly>
                    </div>
                </div>
                <div class='btn-row'>
                    <button type="button" id='btn-add' *ngIf="!isLineSelected" title='Register a new line'
                        class="btn btn2" value='ADD' type="submit" (click)="insertLine()">ADD</button>
                    <button type="button" id='btn-update' *ngIf="isLineSelected" title='Update a line' class="btn btn2"
                        value='UPDATE' type="submit" (click)="updateLine()">UPDATE</button>
                    <button type="button" id='btn-delete' title='Remove a line' class="btn btn2" value='DELETE'
                        type="submit" [disabled]='deleteDisabled' (click)="deleteLine()">DELETE</button>
                </div>
                <div class="mtn-title"></div>
                <div class='btn-row'>
                    <button type="button" id='btn-save' title='Save changes on line details' class="btn btn2"
                        value='SAVE' type="submit" (click)="saveChanges()" [disabled]="saveDisabled">SAVE</button>
                </div>
            </form>
        </div>
    </div>
</section>