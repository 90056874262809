<section id='report-maint'>
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <!-- Container for Report Table using prime ng -->
        <div class = 'table-report'>
            <osp-table [tblConfig]="tblConfigReport" (onRowClick)="onRowClick($event)"></osp-table>
        </div>
        
        <!-- Loading Animation -->
        <div id="generate-report-form">
            <div class="form-loader"></div>
        </div>
        
        <!-- Container for Report Fields -->
        <div class="form-content">
            
            <form [formGroup]="reportForm" class="form" *ngIf="noLoadingInProcess">
                <div id="report-error-message"></div>
                <div class="form-container-grid">
                    <div class="field required">
                        <label>Report ID</label>
                    </div>
                    <div class="">
                        <input formControlName="reportID" type="text" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag">
                            <option value="A" selected>Yes</option>
                            <option value="I">No</option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>Report Name</label>
                    </div>
                    <div class="">
                        <input formControlName="reportName" type="text" maxlength="50">
                    </div>
                    <div class="field">
                        <label>Report Group</label>
                    </div>
                    <div class="">
                        <select [ngClass]="{'p-col user-select' : true}" formControlName="reportGrp">
                            <!-- <option value=""></option> -->
                            <option value=""></option>
                            <option *ngFor="let rep of reportGrpList" [value]="rep.rvLowValue">{{rep.rvMeaning}}</option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>File Name</label>
                    </div>
                    <div class="span">
                        <input formControlName="fileName" type="text">
                    </div>

                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" type="text">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input formControlName="userID" type="text" readonly >
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="lastUserUpdate" type="text" readonly >
                    </div>

                </div>
                <div class='btn-row'>
                    <button type="button" id='btn-add' *ngIf="!isReportSelected" title='Register a new report' class="btn btn2" value='ADD' type="submit" (click)='insertReport()'>ADD</button>
                    <button type="button" id='btn-update' *ngIf="isReportSelected" title='Update a report' class="btn btn2" value='UPDATE' type="submit" (click)='updateReport()'>UPDATE</button>
                    <button type="button" id='btn-delete' title='Remove a report' class="btn btn2" value='DELETE' type="submit" (click)='deleteReport()' [disabled]='deleteDisabled'>DELETE</button>
                </div> 

                <div class="mtn-title"></div>

                <div class='btn-row'>
                    <button type="button" id='btn-save' title='Save changes on report details' class="btn btn2" value='SAVE' type="submit" (click)='toSave()' [disabled]='saveDisabled'>SAVE</button>
                </div>
            </form>
        </div>  
    </div>
</section>

