import { Component, OnInit } from '@angular/core';
import { APICallService } from 'src/app/services/api-call.service';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { forkJoin, Observable } from 'rxjs';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { SecurityService } from 'src/app/services/security.service'; 
import { PlanLovComponent } from '../../common/plan-lov/plan-lov.component';
import { UserTypeLovDialogComponent } from '../../common/user-type-lov/user-type-lov.component';
import { IssSrcComponent } from '../../common/iss-src/iss-src.component';
@Component({
  selector: 'standard-rates-per-plan-based-issuing-office-maintenance',
  templateUrl: './standard-rates-per-plan-based-issuing-office-maintenance.component.html',
  styleUrls: ['./standard-rates-per-plan-based-issuing-office-maintenance.component.css']
})
export class StandardRatesPerPlanBasedIssuingOfficeMaintenanceComponent implements OnInit, LeaveConfirmation {
  
  datePipe = new DatePipe('en-us');

  addDisabled : boolean = false;
  saveDisabled : boolean = true;
  deleteDisabled : boolean = true;
  tranPosted : boolean = false;
  noLoadingInProcess : boolean = true;
  validated : boolean = false;
  userIDReadOnly : boolean = false;
  isLineSelected : boolean = false;
  pubUserStdRatesCodeField: string = '';
  currentUser: string = this.jsonDataService.retrieveFromStorage('userId');
  rowClickedData: any;
  selectedData : any = null;
  moduleId: string = 'BMM119';
  sublineDisabled: boolean = true;
  planDisabled:boolean = true;
  moduleName: string = '';
  
  selectedIss: any = {
    ISS_CD: '',
    ISS_NAME: '',
    ISS_FIELD: ''
  };
  selectedLine: any = {
    LINE_CD: '',
    LINE_NAME: '',
    LINE_FIELD: ''
  };

  selectedSubline: any = {
    SUBLINE_CD: '',
    SUBLINE_NAME: '',
    SUBLINE_FIELD: ''
  };

  selectedPlan: any = {
    PLAN_CD: '',
    PLAN_NAME: '',
    PLAN_FIELD: ''
  };

  selectedUser: any = {
    USER_TYPE:'',
    USER_TYPE_DESC:'',
    USER_TYPE_FIELD:''
  };
  tempAddedData: any = [];
  tempUpdateData: any = [];

  paramForm = this.formBuilder.group({
    issueCd: [null],
    issueName: [null],
    issueField: [null],
    lineCd: [null],
    lineName: [null],
    lineField: [null],
    sublineCd: [null],
    sublineName: [null],
    sublineField: [null],
    planCd:[null],
    planName:[null],
    planField:[null],
    userTypeCd:[null],
    userTypeDesc:[null],
    userTypeField:[null]
  });
  standardRatesPerPlanBasedIOForm = this.formBuilder.group({
    planAmt: [null, [Validators.required, Validators.maxLength(17)]],
    activeTag: ['A', [Validators.required]],
    commissionRate: [null, [Validators.required, Validators.maxLength(13)]],
    noOfDays: [null],
    effDateFrom: [new Date(), [Validators.required]],
    effDateTo: [null],
    remarks: [null],
    userID: [null],
    lastUserUpdate: [null]
  });

  constructor(
    private apiCallService: APICallService,
    public _Dialog: MatDialog, 
    private userDetailService: UserDetailsService,
    private formService: FormService, 
    private formBuilder: FormBuilder, 
    private appMessageService: AppMessageService,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService
  ) {  
    this.moduleName = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId === this.moduleId })[0].moduleDesc.toUpperCase();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempAddedData!='' || this.tempUpdateData!='') {
      return false;
    } else {
      return true;
    }
  }

  standardRatesPerPlanBasedIOTblConfig: any = {
    cols: [
      {
        key: "PLAN_AMT",
        header: "Plan Amount",
        dataType: "currency"
      },
      {
        key: "COMM_RT",
        header: "Comission Rate",
        dataType: "rateDecimal"
      },
      {
        key: "EFF_DATE_FROM",
        header: "Effective Date",
        dataType: "date"
      },
      {
        key: "EFF_DATE_TO",
        header: "Expiry Date",
        dataType: "date"
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        width: '10%'
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }
  ngOnInit() {
  }

  initOnGet(): void {
    try {
      this.apiCallService.refreshNeeded$
            .subscribe(() => {
              this.getAgencyStdRates();
          });
      this.getAgencyStdRates();
    }
    catch(e) {
    }
  }

  async getAgencyStdRates(){
    try{
      let issueCd = this.paramForm.get('issueCd')?.value;
      let lineCd = this.paramForm.get('lineCd')?.value;
      let sublineCd = this.paramForm.get('sublineCd')?.value;
      let planCd = this.paramForm.get('planCd')?.value;
      let userTypeCd = this.paramForm.get('userTypeCd')?.value;

      if (issueCd && lineCd && sublineCd&& planCd&& userTypeCd ) {
        const params = {
          queryParams:{
            userTypeCd: userTypeCd,
            issueCd: issueCd,
            sublineCd: sublineCd,
            lineCd: lineCd,
            planCd: planCd,
          },
          moduleId: this.moduleId,
          userId: this.userDetailService.userId,
          type: "MODULE"
        }
        this.standardRatesPerPlanBasedIOTblConfig.loading = true;
        this.apiCallService.getStandardRatesPerPlanBasedIssuingOffice(params).subscribe({
          next: (data: any) => {
            this.securityService.checkRequestKeyResponse(data, ()=>{
              this.securityService.hasValidCsrfToken(data, ()=>{
                data = JSON.parse(this.jsonDataService.decrypt(data.response));
                let rawData:any = [];
                let finalData:any = [];
                if(!Array.isArray(data)){
                  rawData.push(data);
                }
                else{
                  rawData = data;
                }
                // finalData = this.populateTempData(finalData, rawData);
                if(rawData.length>1){
                  rawData.sort(function(a:any, b:any){
                    let aPremRt = Number(a.PLAN_AMT);
                    let bPremRt = Number(b.PLAN_AMT);
                    return aPremRt - bPremRt;
                  });
                }
                this.standardRatesPerPlanBasedIOTblConfig.tblData = rawData;
                this.standardRatesPerPlanBasedIOTblConfig.loading = false;
                this.tempAddedData = [];
                this.tempUpdateData = [];
                this.saveDisabled = this.saveChecker();
              });
            })
          },
          error: (e: any) => {
            this.standardRatesPerPlanBasedIOTblConfig.loading = false;
          }
        });
      } else{
        this.standardRatesPerPlanBasedIOTblConfig.loading = true;
        this.standardRatesPerPlanBasedIOTblConfig.tblData = [];
        this.standardRatesPerPlanBasedIOTblConfig.loading = false;
        this.tempAddedData = [];
        this.tempUpdateData = [];
        this.saveDisabled = this.saveChecker();
      }
    }catch(e){
    }
  }

  updateLine() {
    try {
      this.tranPosted = true;
      this.validateUpdate();
      if (this.validated) {
        this.updateFormTable();
      }
    }
    catch(e) {
    }
  }
  updateFormTable() {
    try {
      let fieldPlanAmt = this.standardRatesPerPlanBasedIOForm.get('planAmt')?.value;
      let fieldEffDateFrom = new Date(this.standardRatesPerPlanBasedIOForm.get('effDateFrom')?.value);
      let fieldEffDateTo = this.standardRatesPerPlanBasedIOForm.get('effDateTo')?.value?this.datePipe.transform(new Date(this.standardRatesPerPlanBasedIOForm.get('effDateTo')?.value), 'yyyy-MM-dd'): null;  
      let tempInsertChecker = this.tempAddedData.find((line: { PLAN_AMT: any, EFF_DATE_FROM:any }) => 
        +line.PLAN_AMT == +fieldPlanAmt&&getDateNum(new Date(line.EFF_DATE_FROM))==getDateNum(fieldEffDateFrom)
      );
      let tempUpdateChecker = this.tempUpdateData.find((line: { PLAN_AMT: any, EFF_DATE_FROM:any }) => 
        +line.PLAN_AMT == +fieldPlanAmt&&getDateNum(new Date(line.EFF_DATE_FROM))==getDateNum(fieldEffDateFrom)
      );
      
      this.tableDataUpdate();
      // Adds Updated Data that exists in the database to an object array for updated datas
      if(tempInsertChecker!=null && tempInsertChecker!='') {
        //this.tempUpdateData.push(this.formValueToData());
        tempInsertChecker.ISS_CD= this.paramForm.get('issueCd')?.value;
        tempInsertChecker.LINE_CD= this.paramForm.get('lineCd')?.value;
        tempInsertChecker.SUBLINE_CD= this.paramForm.get('sublineCd')?.value;
        tempInsertChecker.PLAN_CD= this.paramForm.get('planCd')?.value;
        tempInsertChecker.USER_TYPE= this.paramForm.get('userTypeCd')?.value;
        tempInsertChecker.PLAN_AMT = +this.standardRatesPerPlanBasedIOForm.get('planAmt')?.value;
        tempInsertChecker.ACTIVE_TAG = this.standardRatesPerPlanBasedIOForm.get('activeTag')?.value;
        tempInsertChecker.COMM_RT = +this.standardRatesPerPlanBasedIOForm.get('commissionRate')?.value;
        tempInsertChecker.NO_OF_DAYS = this.standardRatesPerPlanBasedIOForm.get('noOfDays')?.value;
        tempInsertChecker.EFF_DATE_FROM = this.datePipe.transform(new Date(this.standardRatesPerPlanBasedIOForm.get('effDateFrom')?.value), 'yyyy-MM-dd');
        tempInsertChecker.EFF_DATE_TO = fieldEffDateTo;
        tempInsertChecker.REMARKS = this.standardRatesPerPlanBasedIOForm.get('remarks')?.value;
        tempInsertChecker.LAST_USER_UPDATE = this.datePipe.transform(new Date(this.standardRatesPerPlanBasedIOForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a');
        tempInsertChecker.USER_ID = this.standardRatesPerPlanBasedIOForm.get('userID')?.value;
        tempInsertChecker.MODE = 'I';
      } 
      else if(tempUpdateChecker!=null && tempUpdateChecker!='') {
        tempUpdateChecker.ISS_CD= this.paramForm.get('issueCd')?.value;
        tempUpdateChecker.SUBLINE_CD= this.paramForm.get('sublineCd')?.value;
        tempUpdateChecker.LINE_CD= this.paramForm.get('lineCd')?.value;
        tempUpdateChecker.PLAN_CD= this.paramForm.get('planCd')?.value;
        tempUpdateChecker.USER_TYPE= this.paramForm.get('userTypeCd')?.value;
        tempUpdateChecker.PLAN_AMT  = +this.standardRatesPerPlanBasedIOForm.get('planAmt')?.value;
        tempUpdateChecker.ACTIVE_TAG = this.standardRatesPerPlanBasedIOForm.get('activeTag')?.value;
        tempUpdateChecker.COMM_RT = +this.standardRatesPerPlanBasedIOForm.get('commissionRate')?.value;
        tempUpdateChecker.NO_OF_DAYS = this.standardRatesPerPlanBasedIOForm.get('noOfDays')?.value;
        tempUpdateChecker.EFF_DATE_FROM = this.datePipe.transform(new Date(this.standardRatesPerPlanBasedIOForm.get('effDateFrom')?.value), 'yyyy-MM-dd');
        tempUpdateChecker.EFF_DATE_TO = fieldEffDateTo;
        tempUpdateChecker.REMARKS = this.standardRatesPerPlanBasedIOForm.get('remarks')?.value;
        tempUpdateChecker.LAST_USER_UPDATE = this.datePipe.transform(new Date(this.standardRatesPerPlanBasedIOForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a');
        tempUpdateChecker.USER_ID = this.standardRatesPerPlanBasedIOForm.get('userID')?.value;
        tempUpdateChecker.MODE = 'U';
      }
      else {
        this.tempUpdateData.push(this.formValueToData());
      }
      this.saveDisabled = false;
      this.resetFormValues();
    }
    catch(e) {
    }
  }

  tableDataUpdate() {
    try {
      this.standardRatesPerPlanBasedIOForm.patchValue({
        activeTag: this.standardRatesPerPlanBasedIOForm.get('activeTag')?.value,
        userID: this.currentUser,
        lastUserUpdate: new Date()
      });
      let fieldEffDateTo = this.standardRatesPerPlanBasedIOForm.get('effDateTo')?.value?new Date(this.standardRatesPerPlanBasedIOForm.get('effDateTo')?.value): null;  
      let updateIndex = this.standardRatesPerPlanBasedIOTblConfig.tblData.indexOf(this.rowClickedData);
      
      this.standardRatesPerPlanBasedIOTblConfig.tblData[updateIndex] = {
        ISS_CD: this.paramForm.get('issueCd')?.value,
        SUBLINE_CD: this.paramForm.get('sublineCd')?.value,
        LINE_CD: this.paramForm.get('lineCd')?.value,
        PLAN_CD: this.paramForm.get('planCd')?.value,
        USER_TYPE: this.paramForm.get('userTypeCd')?.value,
        PLAN_AMT: +this.standardRatesPerPlanBasedIOForm.get('planAmt')?.value,
        ACTIVE_TAG: this.standardRatesPerPlanBasedIOForm.get('activeTag')?.value,
        COMM_RT: +this.standardRatesPerPlanBasedIOForm.get('commissionRate')?.value,
        NO_OF_DAYS: this.standardRatesPerPlanBasedIOForm.get('noOfDays')?.value,
        EFF_DATE_FROM: new Date(this.standardRatesPerPlanBasedIOForm.get('effDateFrom')?.value),
        EFF_DATE_TO: fieldEffDateTo,
        REMARKS: this.standardRatesPerPlanBasedIOForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.standardRatesPerPlanBasedIOForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.standardRatesPerPlanBasedIOForm.get('userID')?.value,
        MODE: 'U'
      };
      this.standardRatesPerPlanBasedIOTblConfig.tblData = [...this.standardRatesPerPlanBasedIOTblConfig.tblData];
    }
    catch(e) {
    }
  }

  formValueToData() {
    try {
      let fieldEffDateTo = this.standardRatesPerPlanBasedIOForm.get('effDateTo')?.value?this.datePipe.transform(new Date(this.standardRatesPerPlanBasedIOForm.get('effDateTo')?.value), 'yyyy-MM-dd'): null;  
      let formToData: any = { // this.datePipe.transform(new Date(), 'MM/dd/yyyy')
        ISS_CD: this.paramForm.get('issueCd')?.value,
        SUBLINE_CD: this.paramForm.get('sublineCd')?.value,
        LINE_CD: this.paramForm.get('lineCd')?.value,
        PLAN_CD: this.paramForm.get('planCd')?.value,
        USER_TYPE: this.paramForm.get('userTypeCd')?.value,
        PLAN_AMT: this.standardRatesPerPlanBasedIOForm.get('planAmt')?.value,
        ACTIVE_TAG: this.standardRatesPerPlanBasedIOForm.get('activeTag')?.value,
        COMM_RT: this.standardRatesPerPlanBasedIOForm.get('commissionRate')?.value,
        NO_OF_DAYS: this.standardRatesPerPlanBasedIOForm.get('noOfDays')?.value,
        EFF_DATE_FROM: this.datePipe.transform(new Date(this.standardRatesPerPlanBasedIOForm.get('effDateFrom')?.value), 'yyyy-MM-dd'),
        ORIG_EFF_DATE_FROM: this.standardRatesPerPlanBasedIOForm.get('effDateFrom')?.value,
        EFF_DATE_TO: fieldEffDateTo,
        REMARKS: this.standardRatesPerPlanBasedIOForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.standardRatesPerPlanBasedIOForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.standardRatesPerPlanBasedIOForm.get('userID')?.value,
        MODE: 'U'
      }
      return formToData;
    }
    catch(e) {
    }
  }
  deleteLine() {
    try {
      let clickData = this.rowClickedData;
      this.standardRatesPerPlanBasedIOTblConfig.tblData = this.standardRatesPerPlanBasedIOTblConfig.tblData.filter(
        function(e: any) {
          return +e.PLAN_CD !== +clickData.PLAN_CD||getDateNum(new Date(e.EFF_DATE_FROM))!==getDateNum(new Date(clickData.EFF_DATE_FROM));
        }
      );
      this.tempAddedData = this.tempAddedData.filter(
        function(e: any) {
          return +e.PLAN_CD !== +clickData.PLAN_CD||getDateNum(new Date(e.EFF_DATE_FROM))!==getDateNum(new Date(clickData.EFF_DATE_FROM));
        }
      );
      this.resetFormValues();
      this.appMessageService.showAppMessage("Standard Rates details deleted!", "success");
    }
    catch(e) {
    }
  }

  insertLine() {
    try {
      this.tranPosted = true;
      this.validateInsert();
      if (this.validated) {
        this.addFormToTable();
      }
    }
    catch(e) {
    }
  }
  addFormToTable() {
    try {
      this.standardRatesPerPlanBasedIOForm.patchValue({
        activeTag: this.standardRatesPerPlanBasedIOForm.get('activeTag')?.value,
        userID: this.currentUser,
        lastUserUpdate: new Date()
      });
      let fieldEffDateTo = this.standardRatesPerPlanBasedIOForm.get('effDateTo')?.value?this.datePipe.transform(new Date(this.standardRatesPerPlanBasedIOForm.get('effDateTo')?.value), 'yyyy-MM-dd'): null;  
      let formToData: any = { // this.datePipe.transform(new Date(), 'MM/dd/yyyy')
        ISS_CD: this.paramForm.get('issueCd')?.value,
        SUBLINE_CD: this.paramForm.get('sublineCd')?.value,
        LINE_CD: this.paramForm.get('lineCd')?.value,
        PLAN_CD: this.paramForm.get('planCd')?.value,
        USER_TYPE: this.paramForm.get('userTypeCd')?.value,
        PLAN_AMT: +this.standardRatesPerPlanBasedIOForm.get('planAmt')?.value.toUpperCase(),
        COMM_RT: +this.standardRatesPerPlanBasedIOForm.get('commissionRate')?.value,
        EFF_DATE_FROM: this.datePipe.transform(new Date(this.standardRatesPerPlanBasedIOForm.get('effDateFrom')?.value), 'yyyy-MM-dd'),
        EFF_DATE_TO: fieldEffDateTo,
        ACTIVE_TAG: this.standardRatesPerPlanBasedIOForm.get('activeTag')?.value,
        NO_OF_DAYS: this.standardRatesPerPlanBasedIOForm.get('noOfDays')?.value,
        REMARKS: this.standardRatesPerPlanBasedIOForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.standardRatesPerPlanBasedIOForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.standardRatesPerPlanBasedIOForm.get('userID')?.value,
        MODE: 'I'
      }
      this.standardRatesPerPlanBasedIOTblConfig.tblData.unshift(formToData);
      this.standardRatesPerPlanBasedIOTblConfig.tblData = [...this.standardRatesPerPlanBasedIOTblConfig.tblData];
      this.tempAddedData.push(formToData);
      this.resetFormValues();
    }
    catch(e) {
    }
  }

  saveData(tempChangeData:any[]){
    this.apiCallService.saveStandardRatesPerPlanBasedIssuingOffice(tempChangeData)
    .subscribe({
      next: (response : any) => {
        this.securityService.checkRequestKeyResponse(response, () => {
          this.securityService.hasValidCsrfToken(response, ()=>{
            if (response.status == "SUCCESS" && this.tranPosted) {
              this.openDialog('SUCCESS', 'Details Successfully saved!');
              this.initOnGet();
              this.resetFormValues();
              this.tempAddedData = [];
              this.tempUpdateData = [];
              this.saveDisabled = this.saveChecker();
            }  
            else {
              this.initOnGet();
              this.saveDisabled = this.saveChecker();
              this.openDialog('ERROR', 'An error has occured while saving details');
            }
          });
        });
      }, 
      error: () => {
        this.openDialog('ERROR', 'An error has occured while saving details');
      }
    });
  }

  toSave() {
    try {
      this.noLoadingInProcess = false;
      this.formService.showFormLoader(null, "generate-line-form", "Please wait ...", null, null);
      let tempChangeData= this.tempAddedData.concat(this.tempUpdateData);
      if(tempChangeData.length!=0) {
        for(let item of tempChangeData){
          item.PLAN_AMT = item.PLAN_AMT.toString();
          item.COMM_RT = item.COMM_RT.toString();
        }
        this.saveData(tempChangeData);
      } else {
        this.initOnGet();
        this.resetFormValues();
        this.tempAddedData = [];
        this.tempUpdateData = [];
        this.saveDisabled = this.saveChecker();
      }
    }
    catch(e) {
    }
  }
  saveChecker() {
    try {
      if((this.tempAddedData==null || this.tempAddedData=='') && (this.tempUpdateData==null || this.tempUpdateData=='')) {
        return true;
      }
      else {
        return false;
      }
    }
    catch(e) {
      return true;
    }
  }

  deleteChecker(ev: any) {
    try {
      let tempDataFlag;
      tempDataFlag = this.tempAddedData.filter(function(e: any) {
        let effDateFrom = getDateNum(new Date(e.EFF_DATE_FROM));
        let evEffDateFrom = getDateNum(new Date(ev.EFF_DATE_FROM));
        return +e.PLAN_CD==+ev.PLAN_CD&&effDateFrom==evEffDateFrom;
      });
      if(tempDataFlag==''||tempDataFlag==null) {
        return true;
      } else {
        return false;
      }
    }
    catch(e) {
      return true;
    }
  }
  sublineChecker(){
    try{
      let lineCd = this.paramForm.get('lineCd')?.value;
      if(lineCd!==''&&lineCd!==null){
        return false;
      }
      else{ 
        return true;
      }
    }catch(e){
      return true;
    }
  }
  planChecker(){
    try{
      let lineCd = this.paramForm.get('lineCd')?.value;
      let sublineCd = this.paramForm.get('sublineCd')?.value;
      if(lineCd==''||lineCd==null){
        return true;
      }
      if(sublineCd==''||sublineCd==null){
        return true;
      }
      return false;
    }catch(e){
      return true;
    }
  }
  onRowClick(ev: any) {
    try {    
      if(ev!=null) {
        this.rowClickedData = ev;
        this.standardRatesPerPlanBasedIOForm.get('lineCode')?.disable();
        this.loadFormValues();
        this.saveDisabled = this.saveChecker();
        this.deleteDisabled = this.deleteChecker(ev);
      }
      else {
        this.resetFormValues();
      }
    }
    catch(e) {
    }
  }

  loadFormValues() {
    try {
      let planAmt: string = '';
      let commisionRate: string= '';
      if(this.rowClickedData.PLAN_AMT!==''&&this.rowClickedData.PLAN_AMT!==null){
        planAmt = parseFloat(this.rowClickedData.PLAN_AMT).toFixed(2);
      }
      if(this.rowClickedData.COMM_RT!==''&&this.rowClickedData.COMM_RT!==null){
        commisionRate = parseFloat(this.rowClickedData.COMM_RT).toFixed(9);
      }
      let fieldEffDateTo = this.rowClickedData.EFF_DATE_TO?new Date(this.rowClickedData.EFF_DATE_TO):null;
      this.standardRatesPerPlanBasedIOForm.patchValue({
        planAmt: planAmt,
        activeTag: this.rowClickedData.ACTIVE_TAG,
        commissionRate: commisionRate,
        noOfDays: this.rowClickedData.NO_OF_DAYS,
        effDateFrom: new Date(this.rowClickedData.EFF_DATE_FROM),
        effDateTo: fieldEffDateTo,
        remarks: this.rowClickedData.REMARKS,
        userID: this.rowClickedData.USER_ID,
        lastUserUpdate: this.rowClickedData.LAST_USER_UPDATE
      });
      this.addDisabled = true;
      this.userIDReadOnly = true;
      this.isLineSelected = true;
    }
    catch(e) {
    }
  }

  resetFormValues() {
    try {
      this.selectedData = null;
      // this.paramForm.get('lineCD')?.enable();
      this.standardRatesPerPlanBasedIOForm.patchValue({
        planAmt: null,
        activeTag: 'A',
        commissionRate: null,
        noOfDays: null,
        effDateFrom: new Date(),
        effDateTo: null,
        remarks: null,
        userID: null,
        lastUserUpdate: null
      });

      this.addDisabled = false;
      this.saveDisabled = this.saveChecker();
      this.deleteDisabled = true;
      this.userIDReadOnly = false;
      this.isLineSelected = false;

      this.rowClickedData = '' || null;
    }
    catch (e) {
    console.error(e);
    }
  }
  openUserTypeDialog() {
    try {
      this._Dialog.open(UserTypeLovDialogComponent, {
        width: '512px',
        disableClose: true,
        data: { 
          userId: this.userDetailService.userId,
          moduleId: this.moduleId
        }
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedUser = data.content;
          this.paramForm.get('userTypeCd')?.setValue(this.selectedUser.USER_TYPE==null || this.selectedUser.USER_TYPE=='' ? '' : this.selectedUser.USER_TYPE);
          this.paramForm.get('userTypeDesc')?.setValue(this.selectedUser.USER_TYPE==null || this.selectedUser.USER_TYPE=='' ? '' : this.selectedUser.USER_TYPE_DESC);
          this.paramForm.get('userTypeField')?.setValue(this.selectedUser.USER_TYPE==null || this.selectedUser.USER_TYPE=='' ? '' : this.selectedUser.USER_TYPE + " - " + this.selectedUser.USER_TYPE_DESC);
          
          this.initOnGet();
        }
        this.resetFormValues();
      });
    } catch(e) {
    }
  }
  openIssDialog() {
    try {
      this._Dialog.open(IssSrcComponent, {
        width: '512px',
        disableClose: true,
        data: {
          userId: this.userDetailService.userId,
          moduleId: this.moduleId
        }
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedIss = data.content;
          this.paramForm.get('issueCd')?.setValue(this.selectedIss.ISS_CD==null || this.selectedIss.ISS_CD=='' ? '' : this.selectedIss.ISS_CD);
          this.paramForm.get('issueName')?.setValue(this.selectedIss.ISS_CD==null || this.selectedIss.ISS_CD=='' ? '' : this.selectedIss.ISS_NAME);
          this.paramForm.get('issueField')?.setValue(this.selectedIss.ISS_CD==null || this.selectedIss.ISS_CD=='' ? '' : this.selectedIss.ISS_CD + " - " + this.selectedIss.ISS_NAME);
          
          this.initOnGet();
        }
        this.resetFormValues();
      });
    } catch(e) {
    }
  }
  openLineDialog() {
    try {
      this._Dialog.open(LineLovDialogComponent, {
        width: '512px',
        disableClose: true,
        data: {
          table: 'LINE',
          userId: this.userDetailService.userId,
          moduleId: this.moduleId
        }
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedLine = data.content;
          this.paramForm.get('lineCd')?.setValue(this.selectedLine.LINE_CD==null || this.selectedLine.LINE_CD=='' ? '' : this.selectedLine.LINE_CD);
          this.paramForm.get('lineName')?.setValue(this.selectedLine.LINE_CD==null || this.selectedLine.LINE_CD=='' ? '' : this.selectedLine.LINE_NAME);
          this.paramForm.get('lineField')?.setValue(this.selectedLine.LINE_CD==null || this.selectedLine.LINE_CD=='' ? '' : this.selectedLine.LINE_CD + " - " + this.selectedLine.LINE_NAME);
        }
        this.paramForm.get('sublineCd')?.setValue(null);
        this.paramForm.get('sublineName')?.setValue(null);
        this.paramForm.get('sublineField')?.setValue(null);
        this.resetFormValues();
        this.initOnGet();
        this.sublineDisabled = this.sublineChecker();
      });
    } catch(e) {
    }
  }
  openSublineDialog() {
    try {
      this._Dialog.open(LineLovDialogComponent, {
        width: '512px',
        disableClose: true,
        data: {
          table: 'SUBLINE',
          lineCd: this.paramForm.get('lineCd')?.value,
          userId: this.userDetailService.userId,
          moduleId: this.moduleId
        }
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedSubline = data.content;
          this.paramForm.get('sublineCd')?.setValue(this.selectedSubline.SUBLINE_CD==null || this.selectedSubline.SUBLINE_CD=='' ? '' : this.selectedSubline.SUBLINE_CD);
          this.paramForm.get('sublineName')?.setValue(this.selectedSubline.SUBLINE_CD==null || this.selectedSubline.SUBLINE_CD=='' ? '' : this.selectedSubline.SUBLINE_NAME);
          this.paramForm.get('sublineField')?.setValue(this.selectedSubline.SUBLINE_CD==null || this.selectedSubline.SUBLINE_CD=='' ? '' : this.selectedSubline.SUBLINE_CD+ " - " + this.selectedSubline.SUBLINE_NAME);
        }
        this.paramForm.get('planCd')?.setValue(null);
        this.paramForm.get('planName')?.setValue(null);
        this.paramForm.get('planField')?.setValue(null);
        this.resetFormValues();
        this.initOnGet();
        this.planDisabled = this.planChecker();
      });
    } catch(e) {
    }
  }
  openPlanDialog() {
    try {
      this._Dialog.open(PlanLovComponent, {
        width: '512px',
        disableClose: true,
        data: {
          table: 'PLAN',
          lineCd: this.paramForm.get('lineCd')?.value,
          sublineCd: this.paramForm.get('sublineCd')?.value,
          userId: this.userDetailService.userId,
          moduleId: this.moduleId
        }
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedPlan = data.content;
          this.paramForm.get('planCd')?.setValue(this.selectedPlan.PLAN_CD==null || this.selectedPlan.PLAN_CD=='' ? '' : this.selectedPlan.PLAN_CD);
          this.paramForm.get('planName')?.setValue(this.selectedPlan.PLAN_CD==null || this.selectedPlan.PLAN_CD=='' ? '' : this.selectedPlan.PLAN_NAME);
          this.paramForm.get('planField')?.setValue(this.selectedPlan.PLAN_CD==null || this.selectedPlan.PLAN_CD=='' ? '' : this.selectedPlan.PLAN_CD+ " - " + this.selectedPlan.PLAN_NAME);
          
          this.initOnGet();
        }
        this.resetFormValues();
      });
    } catch(e) {
    }
  }
  openDialog(title: any, message: any) {
    try {
      this.noLoadingInProcess = true;
      this.formService.hideFormLoader("generate-line-form");
      this._Dialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        } 
      });
    } catch(e) {
    }
  }

  populateTempData(finalData:any[], rawData:any[]){
    let issueCd = this.paramForm.get('issueCd')?.value;
    let lineCd = this.paramForm.get('lineCd')?.value;
    let sublineCd = this.paramForm.get('sublineCd')?.value;
    let planCd = this.paramForm.get('planCd')?.value;
    let userTypeCd = this.paramForm.get('userTypeCd')?.value;

    let filteredAddedData: any=[];
    let filteredUpdatedData: any=[];
    if(this.tempUpdateData!=null&&this.tempUpdateData.length!=0){
      filteredUpdatedData = this.tempUpdateData.filter(function(item:any){
        if(item.ISS_CD==issueCd&&item.SUBLINE_CD==sublineCd&&item.LINE_CD==lineCd&&item.USER_TYPE==userTypeCd&&item.PLAN_CD==planCd){
          finalData = rawData.filter(function(rawDataItem:any){
            if(rawDataItem.ISS_CD==issueCd&&rawDataItem.SUBLINE_CD==sublineCd&&rawDataItem.LINE_CD==lineCd&&rawDataItem.USER_TYPE==userTypeCd&&rawDataItem.PLAN_CD==planCd&&rawDataItem.EFF_DATE_FROM==item.EFF_DATE_FROM){
              return false;
            }
            else{
              return true;
            }
          })
          return true;
        }
        else{
          return false;
        }
      })
      if(filteredUpdatedData.length!=0&&filteredUpdatedData!=null){
        finalData = finalData.concat(filteredUpdatedData);
      }
      else{
        finalData = rawData;
      }
    }
    else{
      finalData = rawData;
    }
    
    if(this.tempAddedData!=null&&this.tempAddedData.length!=0){
      filteredAddedData = this.tempAddedData.filter(function(item:any){
        if(item.ISS_CD==issueCd&&item.SUBLINE_CD==sublineCd&&item.LINE_CD==lineCd&&item.USER_TYPE==userTypeCd&&item.PLAN_CD==planCd){
          return true;
        }
        else{
          return false;
        }
      })
      if(filteredAddedData.length!=0&&filteredAddedData!=null){
        finalData = finalData.concat(filteredAddedData);
      }
    }
    if(finalData.length>1){
      finalData.sort(function(a, b){
        let aPremRt = Number(a.PLAN_AMT);
        let bPremRt = Number(b.PLAN_AMT);
        return aPremRt - bPremRt;
      });
    }
    return finalData;
  }
  validateInsert() {
    try {
      this.validated = false;
      if (!this.requiredFieldCheck()){
        setTimeout(()=>{                           //10 secs countdown
          this.formService.hideFormMsg("standard-rates-per-plan-based-issuing-office-error-message");
        }, 10000);
      }
      else if (!this.duplicateCheck(this.standardRatesPerPlanBasedIOTblConfig.tblData)) {
        setTimeout(()=>{                           
          this.formService.hideFormMsg("standard-rates-per-plan-based-issuing-office-error-message");
        }, 10000);
      }
      else if (this.effDateToCheck()) {
       setTimeout(()=>{                           
          this.formService.hideFormMsg("standard-rates-per-plan-based-issuing-office-error-message");
        }, 10000);
      }
      else if(this.rateCheck('commissionRate')){
        setTimeout(()=>{                           
          this.formService.hideFormMsg("standard-rates-per-plan-based-issuing-office-error-message");
        }, 10000);
      }
      else if(this.numberCheck('planAmt')||this.numberCheck('noOfDays')){
        setTimeout(()=>{                           
          this.formService.hideFormMsg("standard-rates-per-plan-based-issuing-office-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
    }
  }

  requiredFieldCheck() {
    try {
      if(this.paramForm.get('lineCd')?.value==='' || this.paramForm.get('lineCd')?.value===null
        ||this.paramForm.get('sublineCd')?.value==='' || this.paramForm.get('sublineCd')?.value===null
        ||this.paramForm.get('userTypeCd')?.value==='' || this.paramForm.get('userTypeCd')?.value===null
        ||this.paramForm.get('planCd')?.value==='' || this.paramForm.get('planCd')?.value===null
        || this.standardRatesPerPlanBasedIOForm.get('planAmt')?.value==='' || this.standardRatesPerPlanBasedIOForm.get('planAmt')?.value===null 
        || this.standardRatesPerPlanBasedIOForm.get('activeTag')?.value==='' || this.standardRatesPerPlanBasedIOForm.get('activeTag')?.value===null 
        || this.standardRatesPerPlanBasedIOForm.get('commissionRate')?.value==='' || this.standardRatesPerPlanBasedIOForm.get('commissionRate')?.value===null
        || this.standardRatesPerPlanBasedIOForm.get('effDateFrom')?.value==='' || this.standardRatesPerPlanBasedIOForm.get('effDateFrom')?.value===null) {
        this.formService.showFormMsg("standard-rates-per-plan-based-issuing-office-error-message", 'There are missing information. Please provide the necessary information needed.', "E");
        return false;
      }
      else {
        return true;
      }
   }
    catch (e) {
    console.error(e);
      return false;
    }
  }
  validateUpdate() {
    try {
      this.validated = false;
      let pubUserStdRatesEffDateFrom= getDateNum(new Date(this.standardRatesPerPlanBasedIOForm.get('effDateFrom')?.value));
      let filteredTblData = this.standardRatesPerPlanBasedIOTblConfig.tblData.filter(function(e:any){
        let effDateFrom = getDateNum(new Date(e.EFF_DATE_FROM));
        if(effDateFrom!=pubUserStdRatesEffDateFrom){
          return true
        }
        else{
          return false
        }
      })
      if (!this.requiredFieldCheck()){
        setTimeout(()=>{                          
          this.formService.hideFormMsg("standard-rates-per-plan-based-issuing-office-error-message");
        }, 10000);
      }
      else if (!this.duplicateCheck(filteredTblData)) {
        setTimeout(()=>{                           
          this.formService.hideFormMsg("standard-rates-per-plan-based-issuing-office-error-message");
        }, 10000);
      }
      else if (this.effDateToCheck()) {
        setTimeout(()=>{                           
          this.formService.hideFormMsg("standard-rates-per-plan-based-issuing-office-error-message");
        }, 10000);
      }
      else if(this.rateCheck('commissionRate')){
        setTimeout(()=>{                           
          this.formService.hideFormMsg("standard-rates-per-plan-based-issuing-office-error-message");
        }, 10000);
      }
      else if(this.numberCheck('planAmt')||this.numberCheck('noOfDays')){
        setTimeout(()=>{                           
          this.formService.hideFormMsg("standard-rates-per-plan-based-issuing-office-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
    }
  }
  duplicateCheck(tblData:any) {
    try {
      let fieldPlanCd = parseInt(this.paramForm.get('planCd')?.value);
      let fieldEffDateToValue = this.standardRatesPerPlanBasedIOForm.get('effDateTo')?.value;
      let fieldEffDateFrom= getDateNum(new Date(this.standardRatesPerPlanBasedIOForm.get('effDateFrom')?.value));
      let fieldEffDateTo= getDateNum(new Date(fieldEffDateToValue));
      this.pubUserStdRatesCodeField = tblData.filter(function(e: any) {
        let effDateFrom = getDateNum(new Date(e.EFF_DATE_FROM));
        let effDateTo = getDateNum(new Date(e.EFF_DATE_TO));
        let ePlanCd = parseInt(e.PLAN_CD);
        if(ePlanCd!==fieldPlanCd){
          return false
        }
        if(fieldEffDateToValue==''||fieldEffDateToValue==null){
          if(e.EFF_DATE_TO==''||e.EFF_DATE_TO==null){
            return true
          }
          return fieldEffDateFrom<=effDateTo;
        }
        if(e.EFF_DATE_TO==''||e.EFF_DATE_TO==null){
          return effDateFrom<=fieldEffDateTo;
        }
        if(fieldEffDateFrom>=effDateFrom&&fieldEffDateTo<=effDateTo){
            return true;
        }
        if(fieldEffDateFrom<=effDateFrom&&fieldEffDateTo>=effDateTo){
          return true;
        }
        if(fieldEffDateFrom<=effDateFrom&&fieldEffDateTo>=effDateFrom){
          return true;
        }
        if(fieldEffDateFrom<=effDateTo&&fieldEffDateTo>=effDateTo){
          return true;
        }
        return false;
      });
      if(this.pubUserStdRatesCodeField=='' || this.pubUserStdRatesCodeField==null) {
        return true;
      } else {
        this.formService.showFormMsg("standard-rates-per-plan-based-issuing-office-error-message", 'Unable to add record. Date of Effectivity is concurrent with another record. Please enter a different range of Date of Effectivity.', "E");
        return false;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  } 
  effDateToCheck(){
    try{
      let pubUserStdRatesEffDateFrom= new Date(this.standardRatesPerPlanBasedIOForm.get('effDateFrom')?.value).getTime();
      if(this.standardRatesPerPlanBasedIOForm.get('effDateTo')?.value!==''&&this.standardRatesPerPlanBasedIOForm.get('effDateTo')?.value!==null){
        let pubUserStdRatesEffDateTo= new Date(this.standardRatesPerPlanBasedIOForm.get('effDateTo')?.value).getTime();
        if(pubUserStdRatesEffDateTo<pubUserStdRatesEffDateFrom){
          this.formService.showFormMsg("standard-rates-per-plan-based-issuing-office-error-message", 'Unable to add record. Expiry Date must not be earlier than Effective Date', "E");
          return true
        }
        else{
          return false
        }
      }
      else{
        return false;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }
  rateCheck(fieldName:string){
    let rate = parseFloat(this.standardRatesPerPlanBasedIOForm.get(fieldName)?.value);
    let fieldRate = this.standardRatesPerPlanBasedIOForm.get(fieldName)?.value;
    let fieldLabel = '';
    if(fieldName=='commissionRate'){
      fieldLabel = 'Commission Rate'
    }
    if(fieldRate===''||fieldRate===null){
      return false
    }
    if(rate<0){
      this.formService.showFormMsg("standard-rates-per-plan-based-issuing-office-error-message", 'Invalid value in '+fieldLabel+' field. The value entered is a negative number', "E");
      return true;
    }
    if(rate<1000){
      return false
    }
    else{
      this.formService.showFormMsg("standard-rates-per-plan-based-issuing-office-error-message", 'Invalid value in '+fieldLabel+' field. The value entered exceeded the maximum limit', "E");
      return true
    }
  }
  numberCheck(fieldName:string){
    let fieldFloat = parseFloat(this.standardRatesPerPlanBasedIOForm.get(fieldName)?.value);
    let fieldAmt = this.standardRatesPerPlanBasedIOForm.get(fieldName)?.value;
    let fieldLabel = '';
    if(fieldName=='planAmt'){
      fieldLabel = 'Plan Amount'
    }
    else if(fieldName=='noOfDays'){
      fieldLabel = 'No. of Days'
    }
    if(fieldAmt===''||fieldAmt===null){
      return false
    }
    if(fieldName!='noOfDays'){
      if(fieldFloat<0){
        this.formService.showFormMsg("standard-rates-per-plan-based-issuing-office-error-message", 'Invalid value in '+fieldLabel+' field. The value entered is a negative number', "E");
        return true;
      }
      if(fieldAmt.length>17){
        this.formService.showFormMsg("standard-rates-per-plan-based-issuing-office-error-message", 'Invalid value in '+fieldLabel+' field. The value entered exceeded the maximum limit', "E");
        return true
      }
    }
    if(fieldFloat<0){
      this.formService.showFormMsg("standard-rates-per-plan-based-issuing-office-error-message", 'Invalid value in '+fieldLabel+' field. The value entered is a negative number', "E");
      return true;
    }
    else{
      return false
    }
  }
  rateFormat(fieldName:string){
    if(this.standardRatesPerPlanBasedIOForm.get(fieldName)?.value!==''&&this.standardRatesPerPlanBasedIOForm.get(fieldName)?.value!==null){
      let fieldFloat = parseFloat(this.standardRatesPerPlanBasedIOForm.get(fieldName)?.value.replace(/,/g, ''))
      let fieldRate = fieldFloat.toFixed(9);
      if(isNaN(fieldFloat)||fieldRate=='NaN'){
        this.standardRatesPerPlanBasedIOForm.get(fieldName)?.setValue('');
      }
      else{
        this.standardRatesPerPlanBasedIOForm.get(fieldName)?.setValue(fieldRate);
      }
    }
  }
  amtFormat(fieldName:string){
    if(this.standardRatesPerPlanBasedIOForm.get(fieldName)?.value!==''&&this.standardRatesPerPlanBasedIOForm.get(fieldName)?.value!==null){
      let fieldFloat = parseFloat(this.standardRatesPerPlanBasedIOForm.get(fieldName)?.value.replace(/,/g, ''))
      let fieldAmt = fieldFloat.toFixed(2);
      if(isNaN(fieldFloat)||fieldAmt=='NaN'){
        this.standardRatesPerPlanBasedIOForm.get(fieldName)?.setValue('');
      }
      else{
        this.standardRatesPerPlanBasedIOForm.get(fieldName)?.setValue(fieldAmt);
      }
    }
  }
  datePattern(ev: any) {
    try{
      //let inputChar = String.fromCharCode(event.charCode)
      if (ev.target.value.length<4) {
        let firstPattern = /^\d{2}\/$/;
        if(!ev.target.value.match(firstPattern)){
          ev.target.value = ev.target.value.substring(0,2);
        }
      } else if(ev.target.value.length<7){
        let firstPattern = /^\d{2}\/\d{2}\/$/;
        if(!ev.target.value.match(firstPattern)){
          ev.target.value = ev.target.value.substring(0,5);
        }
      } else if(ev.target.value.length>6&&ev.target.value.length<11){
        let firstPattern = /^\d{2}\/\d{2}\/\d+$/;
        if(!ev.target.value.match(firstPattern)){
          ev.target.value = ev.target.value.substring(0,ev.target.value.length-1);
        }
      } else if(ev.target.value.length==10){
        let firstPattern = /^\d{2}\/\d{2}\/\d{4}$/;
        if(!ev.target.value.match(firstPattern)){
          ev.target.value = ev.target.value.substring(0,ev.target.value.length-1);
        }
      } else{
        ev.target.value = ev.target.value.substring(0,ev.target.value.length-1);
      }
    }
    catch(e) {
    }
  }
}
function getDateNum(dateInput:Date){
  if(dateInput==null){
    return 0;
  }
  let year = dateInput.getFullYear();
  let month = dateInput.getMonth()+1;
  let day = dateInput.getDate();
  let resultString = year+""+padLeadingZeros(month, 2)+""+padLeadingZeros(day, 2);
  
  let result = parseInt(resultString);
  return result;
}
function padLeadingZeros(num:number, size:number) {
  let sNum = num+"";
  while (sNum.length < size) sNum = "0" + sNum;
  return sNum;
}