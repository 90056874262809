import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { CustomValidators } from 'src/app/utils/custom-validator';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { StateLovComponent } from '../../common/state-lov/state-lov.component';

@Component({
  selector: 'fire-district-maintenance',
  templateUrl: './fire-district-maintenance.component.html',
  styleUrls: ['./fire-district-maintenance.component.css']
})
export class FireDistrictMaintenanceComponent implements OnInit, LeaveConfirmation {

  private moduleId: string = 'BMM146';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc ?? "";

  constructor(
    public _formService: FormService,
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService,
    private _appMessageService: AppMessageService,
  ) {
    this.userIdLoggedIn = this._userDetailService.userId ?? "";
  }

  public canDeactivate(): boolean | Observable<boolean> {
    return !(this.fireDistrictTblConfig.tblData?.filter((row: any) => ['I', 'U'].includes(row.operation)).length > 0);
  }

  public btns = {
    fireDistrict: {
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      addDisabled: false,
    },
  };

  public showForm: boolean = true;

  //* Data Stuffs */
  private rowData: any;
  private errorMessage: string = "";
  private userIdLoggedIn: string;
  //? set selectedRow to [] to unselect the table
  public selectedRow!: any[];

  //* lambda data */
  private lambdaLimitData: number = 10000;
  private offsetPages: any[] = [];
  private tempDataFromGet: any[] = [];

  public forms = {
    fireDistrictForm: this.fb.group({
      districtCd: [null, [Validators.required, Validators.maxLength(25), this.checkDuplicateDistrict(), CustomValidators.requiredTrim]],
      distShortDesc: [null, [Validators.required, Validators.maxLength(50), this.checkDuplicateDistShortDesc(), CustomValidators.requiredTrim]],
      distLongDesc: [null, [Validators.required, Validators.maxLength(1000), CustomValidators.requiredTrim]],
      active: ['A', [Validators.required]],
      stateCd: [null],
      stateDesc: [null],
      stateDisp: [{ value: '', disabled: 'true' }],
      bmCode: [null, [Validators.required, Validators.maxLength(8), CustomValidators.requiredTrim]],
      remarks: [null, [Validators.maxLength(1000)]],
      lastUpdateUser: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }],
    }),
    resetFireDistrictForm: () => {
      this.forms.fireDistrictForm.reset();
      this.forms.fireDistrictForm.patchValue({
        active: 'A',
      });
      this.btns.fireDistrict.updateDisabled = this.btns.fireDistrict.deleteDisabled = true;
      this._formService.hideFormMsg("fire-district-error-message");
      this.rowData = null;
      this.forms.fireDistrictForm.get('districtCd')?.enable();
    },
  };

  private fireDistrictFormManipulate = {
    dbToForm: (data: any) => {
      return {
        districtCd: data.DISTRICT_CD,
        distShortDesc: data.DIST_SHORT_DESC,
        distLongDesc: data.DIST_LONG_DESC,
        active: data.ACTIVE_TAG,
        stateCd: data.STATE_CD,
        stateDesc: data.STATE_SHORT_DESC,
        stateDisp: data.STATE_SHORT_DESC,
        bmCode: data.BM_CODE,
        remarks: data.REMARKS,
        lastUpdateUser: data.USER_ID,
        lastUpdate: data.LAST_UPDATE,
      };
    },
    formToDb: (data: any) => {
      return {
        DISTRICT_CD: data.districtCd?.trim(),
        DIST_SHORT_DESC: data.distShortDesc,
        DIST_LONG_DESC: data.distLongDesc,
        ACTIVE_TAG: data.active,
        STATE_CD: data.stateCd?.toString(),
        STATE_SHORT_DESC: data.stateDesc,
        BM_CODE: data.bmCode?.trim(),
        REMARKS: data.remarks,
        USER_ID: null,
        LAST_UPDATE: null,
      };
    },
  }

  public fireDistrictTblConfig: any = {
    cols: [
      {
        key: "DISTRICT_CD",
        header: "District Code",
        dataType: "string",
        width: '144px',
      },
      {
        key: "DIST_SHORT_DESC",
        header: "Short Description",
        dataType: "string",
        width: '196px',
      },
      {
        key: "DIST_LONG_DESC",
        header: "Long Description",
        dataType: "string",
      },
      {
        key: "STATE_SHORT_DESC",
        header: "State",
        dataType: "string",
        width: '128px',
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        width: '48px',
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  ngOnInit(): void {
    this.getData.fireDistrict();
  }

  public openLov = {
    state: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          moduleId: this.moduleId
        },
      }
      this._matDialog.open(StateLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
        let output = dataOutput.content;
        if (dataOutput?.button?.toUpperCase() !== 'BACK') {
          if (output?.stateCd) {
            this.forms.fireDistrictForm.patchValue({
              stateCd: output.stateCd,
              stateDesc: output.stateShortDesc,
              stateDisp: output.stateShortDesc,
            });
          }
          else {
            this.forms.fireDistrictForm.patchValue({
              stateCd: null,
              stateDesc: null,
              stateDisp: null,
            });
          }
        }
      });
    }
  };

  private getData = {
    fireDistrict: () => {
      try {
        this.fireDistrictTblConfig.tblData = [];
        this._jDS.contorlLoading(true);
        this.fireDistrictTblConfig.loading = true;
        this._api.getFireDistrictCount({
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          type: "MODULE",
          limitNum: this.lambdaLimitData,
        }).subscribe({
          next: (response: any) => {
            this._securityService.checkRequestKeyResponse(response, () => {
              this._securityService.hasValidCsrfToken(response, () => {
                if (response.status === "SUCCESS") {
                  let pages = JSON.parse(this._jDS.decrypt(response.response));
                  for (let i = 0; i < pages; i++) {
                    this.offsetPages = [...this.offsetPages, i * this.lambdaLimitData];
                  }
                  this.getData.fireDistrictAllData(this.offsetPages[0]);
                }
                else {

                  this._jDS.contorlLoading(false);
                  this.fireDistrictTblConfig.loading = false;
                }
              });
            });
          },
          error: (e: any) => {
            this._securityService.checkRequestKeyResponse(e, () => {

              this._jDS.contorlLoading(false);
              this.fireDistrictTblConfig.loading = false;
            });
          }
        });
      }
      catch (e) {
    console.error(e);

      }
    },
    fireDistrictAllData: (offsetPage: number) => {
      try {
        this._api.getFireDistrict({
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          type: "MODULE",
          offSetNum: offsetPage?.toString(),
          limitNum: this.lambdaLimitData,
        }).subscribe({
          next: (response: any) => {
            this._securityService.checkRequestKeyResponse(response, () => {
              this._securityService.hasValidCsrfToken(response, () => {
                if (response.status === "SUCCESS") {
                  let content = JSON.parse(this._jDS.decrypt(response?.response));
                  this.tempDataFromGet = [...this.tempDataFromGet, ...(content.data ?? [])];
                  if (this.offsetPages[this.offsetPages.indexOf(offsetPage) + 1]) {
                    this.getData.fireDistrictAllData(this.offsetPages[this.offsetPages.indexOf(offsetPage) + 1]);
                  }
                  else {
                    this.fireDistrictTblConfig.tblData = [...this.tempDataFromGet];
                    this.tempDataFromGet = [];
                    this._jDS.contorlLoading(false);
                    this.fireDistrictTblConfig.loading = false;
                  }
                }
                else {
                  this.fireDistrictTblConfig.tblData = [];
                  this.tempDataFromGet = [];
                  this._jDS.contorlLoading(false);
                  this.fireDistrictTblConfig.loading = false;
                }
              });
            });
          },
          error: (e: any) => {
            this._securityService.checkRequestKeyResponse(e, () => {

              this._jDS.contorlLoading(false);
              this.fireDistrictTblConfig.loading = false;
            });
          }
        });
      }
      catch (e) {
    console.error(e);

      }
    },
  };

  public selections = {
    activeTypes: [
      { cd: 'A', name: 'Yes' },
      { cd: 'I', name: 'No' },
    ],
  };

  public onRowClick(ev: any) {
    try {
      if (ev) {
        this.rowData = ev;
        this.forms.fireDistrictForm.get('districtCd')?.disable();
        this.populateFormFromTable();
      }
      else {
        this.rowData = null;
        this.forms.resetFireDistrictForm();
      }
    }
    catch (e) {
      console.error(e);
      this.rowData = null;
      this.forms.resetFireDistrictForm();
    }
  }

  private populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.fireDistrict.updateDisabled = false;
      this.btns.fireDistrict.deleteDisabled = !(this.rowData?.operation === 'I');
      this.forms.fireDistrictForm.patchValue(this.fireDistrictFormManipulate.dbToForm(data));
    }
    catch (e) {
    console.error(e);

    }
  }

  public onAdd(): void {
    try {
      this._formService.hideFormMsg("fire-district-error-message");
      if (this.forms.fireDistrictForm.valid) {
        let rowToBeAdded: { [key: string]: any } = this.fireDistrictFormManipulate.formToDb(this.forms.fireDistrictForm.getRawValue());
        rowToBeAdded.operation = 'I';
        this.fireDistrictTblConfig.tblData = [rowToBeAdded, ...this.fireDistrictTblConfig.tblData];
        this.forms.resetFireDistrictForm();
        this.btns.fireDistrict.saveDisabled = false;
      }
      else {
        this.showErrorValidator.fireDistrict();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onUpdate(): void {
    try {
      this._formService.hideFormMsg("fire-district-error-message");
      if (this.forms.fireDistrictForm.valid) {
        const indexOfRow = this.fireDistrictTblConfig.tblData.indexOf(this.rowData);
        let rowToBeUpdated: { [key: string]: any } = this.fireDistrictFormManipulate.formToDb(this.forms.fireDistrictForm.getRawValue());
        rowToBeUpdated.operation = (this.rowData.operation === 'I') ? 'I' : 'U';
        rowToBeUpdated.DISTRICT_CD = this.rowData?.DISTRICT_CD;
        this.forms.resetFireDistrictForm();
        this.fireDistrictTblConfig.tblData[indexOfRow] = rowToBeUpdated;
        this.selectedRow = [];
        this.btns.fireDistrict.saveDisabled = false;
        this.fireDistrictTblConfig.tblData = [...this.fireDistrictTblConfig.tblData];
      }
      else {
        this.showErrorValidator.fireDistrict();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onDelete(): void {
    try {
      this.fireDistrictTblConfig.tblData = this.fireDistrictTblConfig.tblData.filter((row: any) => {
        return row !== this.rowData;
      });
      this.forms.resetFireDistrictForm();
      this.btns.fireDistrict.saveDisabled = false;
    }
    catch (e) {
    console.error(e);

    }
  }

  public onSave(): void {
    try {
      this._jDS.contorlLoading(true);
      this.showForm = false;
      this._formService.showFormLoader(null, "fire-district-maintenance-loading", "Saving.<br>Please wait ...", null, null, true);
      let toBeSaved = this.fireDistrictTblConfig.tblData.filter((row: any) => ['I', 'U'].includes(row.operation));
      if (toBeSaved.length > 0) {
        this._api.saveFireDistrict(toBeSaved).subscribe({
          next: (response: any) => {
            this._securityService.checkRequestKeyResponse(response, () => {
              this._securityService.hasValidCsrfToken(response, () => {
                if (response.status === 'SUCCESS') {
                  this.onComplete.fireDistrict();
                }
                else {
                  this.onComplete.fireDistrictError();
                }
              });
            });
          },
          error: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {

              this.onComplete.fireDistrictError();
            });
          }
        });
      }
      else {
        this.onComplete.fireDistrict();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  private onComplete = {
    fireDistrict: () => {
      this._jDS.contorlLoading(false);
      this._formService.hideFormLoader("fire-district-maintenance-loading");
      this.openDialog('SUCCESS', this.moduleName.replace('Maintenance', ''), 'saved');
      this.btns.fireDistrict.saveDisabled = true;
      this.showForm = true;
      this.forms.resetFireDistrictForm();
    },
    fireDistrictError: () => {
      this._jDS.contorlLoading(false);
      this.openDialog('FAILED', this.moduleName.replace('Maintenance', ''), 'saving');
      this._formService.hideFormLoader("fire-district-maintenance-loading");
      this.showForm = true;
      this.forms.resetFireDistrictForm();
    },
  }

  private openDialog(title: string, contentTitle: string, contentDetail: string) {
    try {
      this._matDialog.open(DialogMsgComponent, {
        disableClose: true,
        width: '512px',
        data: {
          title: title,
          content:
            title === 'SUCCESS'
              ? `${contentTitle} details successfully ${contentDetail}!`
              : `${contentTitle} details ${contentDetail} failed!`,
        },
      }).afterClosed().subscribe(() => {
        if (title === 'SUCCESS') {
          this.getData.fireDistrict();
        }
      });
    }
    catch (e) {
    console.error(e);

    }
  }

  //* Validator Stuffs Down here */

  private checkDuplicateDistrict(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.trim()?.toUpperCase();
      if (!value) return null;
      let hasDuplicate: boolean = this.fireDistrictTblConfig?.tblData.filter((d: any) => {
        if (!this.rowData)
          return d['DISTRICT_CD']?.trim().toUpperCase() === value;
        else
          return this.rowData['DISTRICT_CD']?.trim()?.toUpperCase() !== value && d['DISTRICT_CD']?.trim()?.toUpperCase() === value;
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupe: true } : null;
    }
  }

  private checkDuplicateDistShortDesc(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.trim()?.toUpperCase();
      if (!value) return null;
      let hasDuplicate: boolean = this.fireDistrictTblConfig?.tblData.filter((d: any) => {
        if (!this.rowData)
          return d['DIST_SHORT_DESC']?.trim().toUpperCase() === value;
        else
          return this.rowData['DIST_SHORT_DESC']?.trim()?.toUpperCase() !== value && d['DIST_SHORT_DESC']?.trim()?.toUpperCase() === value;
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupe: true } : null;
    }
  }

  public showErrorValidator = {
    fireDistrict: () => {
      try {
        Object.keys(this.forms.fireDistrictForm.controls).forEach(key => {
          const controlErrors = this.forms.fireDistrictForm.get(key)?.errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              if (keyError == 'hasDupe' && controlErrors[keyError] === true && key === 'districtCd')
                this.errorMessage = "District Code already exists. Please use a unique District Code.";
              else if (keyError == 'hasDupe' && controlErrors[keyError] === true && key === 'distShortDesc')
                this.errorMessage = "District Short Description already exists. Please use a unique District Short Description.";
              else if (keyError == 'required' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
            });
          }
        });
        this._formService.showFormMsg("fire-district-error-message", this.errorMessage, "E");
      }
      catch (e) {
    console.error(e);

      }
    },
  }

}
