<section id="forgot-tab">
    <form [formGroup]="forgotDetails">
        <div id="forgot-form">
            <div class="form-loader"></div>
            <div class="form-fields">
                <div class="forgot-title">
                    <p>RESET YOUR PASSWORD</p>
                </div>
                <div class="form">
                    <div class="col-case">
                        <div class="field">
                            <label>Please enter your User ID</label>
                            <mat-form-field appearance="fill">
                                <input matInput formControlName="userId">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div id="forgotFormMsg"></div>
        </div>
        <div class="form-btns">
            <button class="btn btn4" [routerLink]="['/home/login']">Back</button>
            <button class="btn btn2" (click)="sendResetEmail()">SUBMIT</button>
        </div>
    </form>
</section>