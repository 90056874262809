import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';

@Component({
  selector: 'property-assessment-wordings-maintenance',
  templateUrl: './property-assessment-wordings-maintenance.component.html',
  styleUrls: ['./property-assessment-wordings-maintenance.component.css']
})
export class PropertyAssessmentWordingsMaintenanceComponent implements OnInit, LeaveConfirmation {

  moduleId: string = 'BMM164';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc?.toUpperCase() ?? "";

  constructor(
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    public _formService: FormService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService
  ) { 
    this.userIdLoggedIn = this._userDetailService.userId ?? "";
  }

  ngOnInit(): void {
    this.getData.propertyAssessmentWordings();
  }

  canDeactivate(): boolean | Observable<boolean> {
    if(this.tempData!='') {
      return false;
    } else {
      return true;
    }
  }

  /* Boolean Stuffs */

  public btns = {
    propertyAssessmentWordings: {
      addDisabled: true,
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true
    },
    lov: {
      lineDisabled: false,
      sublineDisabled: true
    }
  }

  public showForm: boolean = true;

  private rowData: any;
  private tempDataRow: any;
  private errorMessage: string = "";
  private userIdLoggedIn: string;
  private tempData: any = [];

  public forms = {
    propertyAssessmentWordingsForm: this.fb.group({
      assessmentCd: [null, [Validators.required, Validators.maxLength(10), this.noWhiteSpaceCheck()]],
      language: [null, [Validators.required]],
      title: [null, [Validators.maxLength(500)]],
      wordings: [null, [Validators.required, this.noWhiteSpaceCheck()]],
      answerYes: [null, [Validators.maxLength(250)]],
      answerNo: [null, [Validators.maxLength(250)]],
      imagePath: [null, [Validators.maxLength(500)]],
      remarks: [null, [Validators.maxLength(4000)]],
      activeTag: ['A', [Validators.required]],
      userID: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }]
    }),
    resetPropertyAssessmentWordingsForm: () => {
      this.forms.propertyAssessmentWordingsForm.reset();
      this.forms.propertyAssessmentWordingsForm.patchValue({
        activeTag: 'A',
      });
      this.forms.propertyAssessmentWordingsForm.get('assessmentCd')?.enable();
      this.forms.propertyAssessmentWordingsForm.get('language')?.enable();
      this.btns.propertyAssessmentWordings.updateDisabled = true;
      this.btns.propertyAssessmentWordings.deleteDisabled = true;
      this._formService.hideFormMsg("property-assessment-wordings-error-message");
      this.rowData = null;
    }
  }

  private propertyAssessmentWordingsFormManipulate = {
    dbToForm: (data: any) => {
      return {
        assessmentCd: data.ASSESSMENT_CD?.trim()?.toUpperCase(),
        language: data.LANGUAGE,
        title: data.TITLE,
        wordings: data.WORDINGS,
        answerYes: data.ANSWER_YES,
        answerNo: data.ANSWER_NO,
        imagePath: data.IMAGE_PATH,
        activeTag: data.ACTIVE_TAG,
        remarks: data.REMARKS,
        userID: data.USER_ID,
        lastUpdate: data.LAST_UPDATE,
      };
    },
    formToDb: (data: any, mode: any) => {
      return {
        ASSESSMENT_CD: data.assessmentCd?.trim()?.toUpperCase(),
        LANGUAGE: data.language,
        TITLE: data.title,
        WORDINGS: data.wordings,
        ANSWER_YES: data.answerYes,
        ANSWER_NO: data.answerNo,
        IMAGE_PATH: data.imagePath,
        ACTIVE_TAG: data.activeTag,
        REMARKS: data.remarks,
        USER_ID: this.userIdLoggedIn,
        LAST_UPDATE: null,
        MODE: mode
      };
    }
  }

  public propertyAssessmentWordingsTblConfig = {
    cols: [
      {
        key: "ASSESSMENT_CD",
        header: "Code",
        dataType: "string",
        width: '10%'
      },
      {
        key: "LANGUAGE",
        header: "Language",
        dataType: "string",
        width: '12%'
      },
      {
        key: "TITLE",
        header: "Title",
        dataType: "string",
        width: '20%'
      },
      {
        key: "WORDINGS",
        header: "Wordings",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        width: '64px'
      }
    ],
    tblData: [] as any[],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  private getData = {
    propertyAssessmentWordings: () => {
      try {
        this.propertyAssessmentWordingsTblConfig.tblData = [];
        this.propertyAssessmentWordingsTblConfig.loading = true;
        this._jDS.contorlLoading(true);
        this._api.getPropertyAssessmentWordings({
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          type: "MODULE"
        }).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                let content = JSON.parse(this._jDS.decrypt(data.response));
                this._jDS.contorlLoading(false);
                this.propertyAssessmentWordingsTblConfig.tblData = content.data;
                this.btns.propertyAssessmentWordings.addDisabled = false;
                this.propertyAssessmentWordingsTblConfig.loading = false;
              });
            });
          },
          error: (error: any) => {
            this.propertyAssessmentWordingsTblConfig.loading = false;
            this._jDS.contorlLoading(false);
          }
        });
      }
      catch (e) {
        console.error(e);
      }
    },
    languages: () => {
      const filteredMag = this._jDS.data.refCds.filter((data: any) => data.rvDomain === 'LANGUAGE').map((data: any) => { return { cd: data.rvLowValue, name: data.rvMeaning } });
      return filteredMag;
    }
  }

  public selections = {
    activeTypes: [
      { cd: 'A', name: 'Yes' },
      { cd: 'I', name: 'No' },
    ],
    languages: [
      ...this.getData.languages()
    ],
  }

  onRowClick(ev: any) {
    try {
      ev.update = true;
      this.rowData = ev;
      this.populateFormFromTable();
    }
    catch (e) {
      this.forms.resetPropertyAssessmentWordingsForm();
      this.rowData = null;
      //this.indexRow = null;
    }
  }

  populateFormFromTable() {
    try {
      let data = this.rowData;
      this.forms.propertyAssessmentWordingsForm.get('assessmentCd')?.disable();
      this.forms.propertyAssessmentWordingsForm.get('language')?.disable();
      this.btns.propertyAssessmentWordings.updateDisabled = !data.update;
      this.btns.propertyAssessmentWordings.deleteDisabled = !data.temp;
      this.tempDataRow = !data.temp;
      this.forms.propertyAssessmentWordingsForm.patchValue(this.propertyAssessmentWordingsFormManipulate.dbToForm(data));
    }
    catch (e) {
      console.error(e);
    }
  }

  public onAdd() {
    try {
      this._formService.hideFormMsg("property-assessment-wordings-error-message");
      if (!this.assessmentCdDupCheck()) {
        this._formService.showFormMsg("property-assessment-wordings-error-message", "Assessment Code already exists for selected language. Please add a unique Assessment Code.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("property-assessment-wordings-error-message");
        }, 10000);
      }
      else if (!this.assessmentCdCharCheck()) {
        this._formService.showFormMsg("property-assessment-wordings-error-message", "Invalid value for Assessment Code. Please use alphanumeric characters only.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("property-assessment-wordings-error-message");
        }, 10000);
      }
      else if (this.forms.propertyAssessmentWordingsForm.valid) {
        let rowToBeAdded: { [key: string]: any } = this.propertyAssessmentWordingsFormManipulate.formToDb(this.forms.propertyAssessmentWordingsForm.value, 'I');
        rowToBeAdded.temp = true;
        this.tempData.push(rowToBeAdded);
        this.propertyAssessmentWordingsTblConfig.tblData = [rowToBeAdded, ...this.propertyAssessmentWordingsTblConfig.tblData];
        this.forms.resetPropertyAssessmentWordingsForm();
        this.btns.propertyAssessmentWordings.saveDisabled = false;
      }
      else {
        this.showErrorValidator.propertyAssessmentWordings();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public onDelete() {
    try {
      let clickData = this.rowData;
      this.propertyAssessmentWordingsTblConfig.tblData = this.propertyAssessmentWordingsTblConfig.tblData.filter((d: any) => {
        return d !== this.rowData;
      });
      this.tempData = this.tempData.filter(
        function(e: any) {
          return e.ASSESSMENT_CD != clickData.ASSESSMENT_CD || e.LANGUAGE != clickData.LANGUAGE;
        }
      );
      if (this.tempData==null || this.tempData=='') {
        this.btns.propertyAssessmentWordings.saveDisabled = true;
      }
      this.forms.resetPropertyAssessmentWordingsForm();
    }
    catch (e) {
      console.error(e);
    }
  }

  public onUpdate() {
    try {
      this._formService.hideFormMsg("property-assessment-wordings-error-message");
      if (this.forms.propertyAssessmentWordingsForm.valid) {
        this.forms.propertyAssessmentWordingsForm.get('assessmentCd')?.enable();
        this.forms.propertyAssessmentWordingsForm.get('language')?.enable();
        let clickData = this.rowData;
        let toBeUpdatedIndex = this.propertyAssessmentWordingsTblConfig.tblData.indexOf(this.rowData);
        let propertyAssessmentWordingsCdRowValue = this.rowData.ASSESSMENT_CD;
        let rowToBeUpdated: { [key: string]: any } = this.propertyAssessmentWordingsFormManipulate.formToDb(this.forms.propertyAssessmentWordingsForm.value, 'U');
        this.forms.propertyAssessmentWordingsForm.get('assessmentCd')?.disable();
        this.forms.propertyAssessmentWordingsForm.get('language')?.disable();
        let tempInsertChecker = this.tempData.find(
          (m: { ASSESSMENT_CD: any }) => 
            m.ASSESSMENT_CD == clickData.ASSESSMENT_CD && clickData.MODE == 'I');
        let tempUpdateChecker = this.tempData.find(
          (m: { ASSESSMENT_CD: any }) => 
            m.ASSESSMENT_CD == clickData.ASSESSMENT_CD && clickData.MODE == 'U');
        if (this.rowData.temp) {
          rowToBeUpdated.temp = true;
        }
        else {
          rowToBeUpdated.onDbButUpdatedTemp = true;
          rowToBeUpdated.ASSESSMENT_CD = propertyAssessmentWordingsCdRowValue;
        }
        if (tempInsertChecker!=null && tempInsertChecker!='') {
          //tempInsertChecker = rowToBeUpdated;
          tempInsertChecker.TITLE = rowToBeUpdated.TITLE;
          tempInsertChecker.WORDINGS = rowToBeUpdated.WORDINGS;
          tempInsertChecker.ANSWER_YES = rowToBeUpdated.ANSWER_YES;
          tempInsertChecker.ANSWER_NO = rowToBeUpdated.ANSWER_NO;
          tempInsertChecker.IMAGE_PATH = rowToBeUpdated.IMAGE_PATH;
          tempInsertChecker.ACTIVE_TAG = rowToBeUpdated.ACTIVE_TAG;
          tempInsertChecker.REMARKS = rowToBeUpdated.REMARKS;
          tempInsertChecker.USER_ID = rowToBeUpdated.USER_ID;
          tempInsertChecker.MODE = 'I';
          rowToBeUpdated.MODE = 'I';
        }
        else if (tempUpdateChecker!=null && tempUpdateChecker!='') {
          tempUpdateChecker.TITLE = rowToBeUpdated.TITLE;
          tempUpdateChecker.WORDINGS = rowToBeUpdated.WORDINGS;
          tempUpdateChecker.ANSWER_YES = rowToBeUpdated.ANSWER_YES;
          tempUpdateChecker.ANSWER_NO = rowToBeUpdated.ANSWER_NO;
          tempUpdateChecker.IMAGE_PATH = rowToBeUpdated.IMAGE_PATH;
          tempUpdateChecker.ACTIVE_TAG = rowToBeUpdated.ACTIVE_TAG;
          tempUpdateChecker.REMARKS = rowToBeUpdated.REMARKS;
          tempUpdateChecker.USER_ID = rowToBeUpdated.USER_ID;
          tempUpdateChecker.MODE = 'U';
        }
        else { 
          this.tempData.push(rowToBeUpdated);
        }
        this.forms.resetPropertyAssessmentWordingsForm();
        this.propertyAssessmentWordingsTblConfig.tblData[toBeUpdatedIndex] = rowToBeUpdated;
        this.btns.propertyAssessmentWordings.saveDisabled = false;
        this.propertyAssessmentWordingsTblConfig.tblData = [... this.propertyAssessmentWordingsTblConfig.tblData];
      }
      else {
        this.showErrorValidator.propertyAssessmentWordings();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public onSave() {
    try {
      this.btns.propertyAssessmentWordings.saveDisabled = true;
      this.showForm = false;
      this._formService.showFormLoader(null, "property-assessment-wordings-maintenance-loading", "Saving.<br>Please wait ...", null, null);
      if (this.tempData.length > 0) {
        this._api.savePropertyAssessmentWordings(this.tempData).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                if (data.status === 'SUCCESS') {
                  this.onComplete.propertyAssessmentWordings();
                }
                else {
                  this.onComplete.propertyAssessmentWordingsError();
                }
              });
            });
          },
          error: (data: any) => {
            this.onComplete.propertyAssessmentWordingsError();
          },
        });
      }
      else
        this.onComplete.propertyAssessmentWordings();
    }
    catch (e) {
      console.error(e);
    }
  }

  private onComplete = {
    propertyAssessmentWordings: () => {
      this.tempData = [];
      this.getData.propertyAssessmentWordings();
      this.btns.propertyAssessmentWordings.saveDisabled = true;
      this.showForm = true;
      this._formService.hideFormLoader("property-assessment-wordings-maintenance-loading");
      this.forms.resetPropertyAssessmentWordingsForm();
      this.openDialog('SUCCESS', 'Property Assessment Wordings successfully saved');
    },
    propertyAssessmentWordingsError: () => {
      this.showForm = true;
      this.btns.propertyAssessmentWordings.saveDisabled = false;
      this.openDialog('FAILED', 'Property Assessment Wordings saving failed');
      this._formService.hideFormLoader("property-assessment-wordings-maintenance-loading");
    },
  }

  openDialog(title: any, message: any) {
    try {
      this.showForm = true;
      this._formService.hideFormLoader("property-assessment-wordings-maintenance-loading");
      this._matDialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        } 
      });
    } catch(e) {
      console.error(e);
    }
  }

  autoUppercase(ev: any) {
    try{
      ev.target.value = ev.target.value?.toUpperCase();
    }
    catch(e) {
      console.error(e);
    }
  }

  allowAlphanumericOnly(event: any) {
    try {
      if (/^([a-zA-Z0-9]+)$/.test(String.fromCharCode(event.keyCode))) {
        return true;
      }
      else {
        event.preventDefault();
        return false;
      }
    }
    catch (e) {
      console.error(e);
      return undefined; 
    }
  }

  assessmentCdDupCheck() {
    try {
      let hasDuplicate: any = this.propertyAssessmentWordingsTblConfig.tblData.filter((d: any) => {
        if (!this.rowData && this.btns.propertyAssessmentWordings.updateDisabled){
          return d.ASSESSMENT_CD == this.forms.propertyAssessmentWordingsForm.get('assessmentCd')?.value?.trim()?.toUpperCase() 
          && d.LANGUAGE == this.forms.propertyAssessmentWordingsForm.get('language')?.value;
        }
        else {
          return this.rowData.ASSESSMENT_CD !== this.forms.propertyAssessmentWordingsForm.get('assessmentCd')?.value?.trim()?.toUpperCase() 
          && d.ASSESSMENT_CD == this.forms.propertyAssessmentWordingsForm.get('assessmentCd')?.value?.trim()?.toUpperCase()
          && d.LANGUAGE == this.forms.propertyAssessmentWordingsForm.get('language')?.value;
        }
      }).length > 0 ? true : false;
      
      if(hasDuplicate) {
        return false;
      } else {
        return true;
      }
    }
    catch (e) {
      console.error(e);
      return false; 
    }
  }

  assessmentCdCharCheck() {
    try {
      if (/^([a-zA-Z0-9]+)$/.test(this.forms.propertyAssessmentWordingsForm.get('assessmentCd')?.value?.trim()?.toUpperCase())) {
        return true;
      } else {
        return false;
      }
    }
    catch (e) {
      console.error(e);
      return false; 
    }
  }

  noWhiteSpaceCheck(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: true };
    }
  }

  public showErrorValidator = {
    propertyAssessmentWordings: () => {
      try {
        Object.keys(this.forms.propertyAssessmentWordingsForm.controls).forEach(key => {
          const controlErrors = this.forms.propertyAssessmentWordingsForm.get(key)?.errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              if (keyError == 'whitespace' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
              else if (keyError == 'required' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
            });
          }
        });
        this._formService.showFormMsg("property-assessment-wordings-error-message", this.errorMessage, "E");
        //this.openDialog('ERROR', this.errorMessage);
        setTimeout(()=>{                           
          this._formService.hideFormMsg("property-assessment-wordings-error-message");
        }, 10000);
      }
      catch (e) {
        console.error(e);
      }
    },
  }

}
