import { Component, OnInit } from '@angular/core';
import { APICallService } from 'src/app/services/api-call.service';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { forkJoin, Observable } from 'rxjs';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { SecurityService } from 'src/app/services/security.service'; 
@Component({
  selector: 'construction-type-maintenance',
  templateUrl: './construction-type-maintenance.component.html',
  styleUrls: ['./construction-type-maintenance.component.css']
})
export class ConstructionTypeMaintenanceComponent implements OnInit, LeaveConfirmation {
  
  datePipe = new DatePipe('en-us');

  addDisabled : boolean = false;
  saveDisabled : boolean = true;
  deleteDisabled : boolean = true;
  tranPosted : boolean = false;
  noLoadingInProcess : boolean = true;
  validated : boolean = false;
  userIDReadOnly : boolean = false;
  isLineSelected : boolean = false;
  pubUserStdRatesCodeField: string = '';
  currentUser: string = this.jsonDataService.retrieveFromStorage('userId');
  rowClickedData: any;
  selectedData : any = null;
  moduleId: string = 'BMM125';
  perilDisabled: boolean = true;
  moduleName: string = '';
  tempAddedData: any = [];
  tempUpdateData: any = [];

  constructionTypeForm = this.formBuilder.group({
    constructionCode: [null, [Validators.required]],
    activeTag: ['A', [Validators.required]],
    constructionDesc: [null, [Validators.required, Validators.maxLength(2000)]],
    origConstructionDesc: [null],
    bpimsCode: [null, [Validators.required, Validators.maxLength(8)]],
    remarks: [null, [Validators.maxLength(1000)]],
    userID: [null],
    lastUserUpdate: [null]
  });

  constructor(
    private apiCallService: APICallService,
    public _Dialog: MatDialog, 
    private userDetailService: UserDetailsService,
    private formService: FormService, 
    private formBuilder: FormBuilder, 
    private appMessageService: AppMessageService,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService
  ) {
    let moduleLists = JSON.parse(this.jsonDataService.decrypt(sessionStorage.getItem("mdls") || "")); 
    this.moduleName = moduleLists.filter((data: any) => { return data.moduleId === this.moduleId })[0].moduleDesc.toUpperCase();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempAddedData!='' || this.tempUpdateData!='') {
      return false;
    } else {
      return true;
    }
  }

  constructionTypeTblConfig: any = {
    cols: [
      {
        key: "CONSTRUCTION_CD",
        header: "Construction Code",
        dataType: "number"
      },
      {
        key: "CONSTRUCTION_DESC",
        header: "Construction Description",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }
  ngOnInit() {
    try {
      this.apiCallService.refreshNeeded$
            .subscribe(() => {
              this.getAllSegmentCode();
          });
      this.getAllSegmentCode();
    }
    catch(e) {
    }
  }

  async getAllSegmentCode(){
    try{
      const params = {
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE"
      }
      this.constructionTypeTblConfig.loading = true;
      this.apiCallService.getConstructionType(params).subscribe({
        next: (data: any) => {
          this.securityService.checkRequestKeyResponse(data, ()=>{
            this.securityService.hasValidCsrfToken(data, ()=>{
              if (data.status === "SUCCESS") {
                let content = JSON.parse(this.jsonDataService.decrypt(data?.response));
                this.constructionTypeTblConfig.tblData = content.data;
                this.constructionTypeTblConfig.loading = false;
              }
              else {
                this.jsonDataService.contorlLoading(false);
                this.constructionTypeTblConfig.loading = false;
              }
            });
          })
        },
        error: (e: any) => {
          this.constructionTypeTblConfig.loading = false;
        }
      });
    }catch(e){
    }
  }

  updateLine() {
    try {
      this.tranPosted = true;
      this.validateUpdate();
      if (this.validated) {
        this.updateFormTable();
      }
    }
    catch(e) {
    }
  }

  updateFormTable() {
    try {
      let tempInsertChecker = this.tempAddedData.find((line: { CONSTRUCTION_DESC: any }) => 
        line.CONSTRUCTION_DESC.trim().toUpperCase() == this.constructionTypeForm.get('origConstructionDesc')?.value.trim().toUpperCase()
      );
      let tempUpdateChecker = this.tempUpdateData.find((line: { CONSTRUCTION_DESC: any }) => 
        line.CONSTRUCTION_DESC.trim().toUpperCase() == this.constructionTypeForm.get('origConstructionDesc')?.value.trim().toUpperCase()
      );
      
      this.tableDataUpdate();
        
      // Adds Updated Data that exists in the database to an object array for updated datas
      if(tempInsertChecker!=null && tempInsertChecker!='') {
        //this.tempUpdateData.push(this.formValueToData());
        tempInsertChecker.CONSTRUCTION_CD = this.constructionTypeForm.get('constructionCode')?.value;
        tempInsertChecker.ACTIVE_TAG = this.constructionTypeForm.get('activeTag')?.value;
        tempInsertChecker.CONSTRUCTION_DESC = this.constructionTypeForm.get('constructionDesc')?.value;
        tempInsertChecker.BM_CODE = this.constructionTypeForm.get('bpimsCode')?.value;
        tempInsertChecker.REMARKS = this.constructionTypeForm.get('remarks')?.value;
        tempInsertChecker.LAST_USER_UPDATE = this.datePipe.transform(new Date(this.constructionTypeForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a');
        tempInsertChecker.USER_ID = this.constructionTypeForm.get('userID')?.value;
        tempInsertChecker.MODE = 'I';
      } 
      else if(tempUpdateChecker!=null && tempUpdateChecker!='') {
        tempUpdateChecker.CONSTRUCTION_CD  = this.constructionTypeForm.get('constructionCode')?.value;
        tempUpdateChecker.ACTIVE_TAG = this.constructionTypeForm.get('activeTag')?.value;
        tempUpdateChecker.CONSTRUCTION_DESC = this.constructionTypeForm.get('constructionDesc')?.value;
        tempUpdateChecker.BM_CODE = this.constructionTypeForm.get('bpimsCode')?.value;
        tempUpdateChecker.REMARKS = this.constructionTypeForm.get('remarks')?.value;
        tempUpdateChecker.LAST_USER_UPDATE = this.datePipe.transform(new Date(this.constructionTypeForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a');
        tempUpdateChecker.USER_ID = this.constructionTypeForm.get('userID')?.value;
        tempUpdateChecker.MODE = 'U';
      }
      else {
        this.tempUpdateData.push(this.formValueToData());
      }
      this.saveDisabled = false;
      this.resetFormValues();
      this.appMessageService.showAppMessage("Construction Type details updated!", "success");
    }
    catch(e) {
    }
  }

  tableDataUpdate() {
    try {
      this.constructionTypeForm.patchValue({
        activeTag: this.constructionTypeForm.get('activeTag')?.value,
        userID: this.currentUser,
        lastUserUpdate: new Date()
      });
      let updateIndex = this.constructionTypeTblConfig.tblData.indexOf(this.rowClickedData);
      
      this.constructionTypeTblConfig.tblData[updateIndex] = {
        CONSTRUCTION_CD: this.constructionTypeForm.get('constructionCode')?.value,
        ACTIVE_TAG: this.constructionTypeForm.get('activeTag')?.value,
        CONSTRUCTION_DESC: this.constructionTypeForm.get('constructionDesc')?.value,
        BM_CODE: this.constructionTypeForm.get('bpimsCode')?.value,
        REMARKS: this.constructionTypeForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.constructionTypeForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.constructionTypeForm.get('userID')?.value,
        MODE: 'U'
      };
      this.constructionTypeTblConfig.tblData = [...this.constructionTypeTblConfig.tblData];
    }
    catch(e) {
    }
  }

  formValueToData() {
    try {
      let formToData: any = { // this.datePipe.transform(new Date(), 'MM/dd/yyyy')
        CONSTRUCTION_CD: this.constructionTypeForm.get('constructionCode')?.value,
        ACTIVE_TAG: this.constructionTypeForm.get('activeTag')?.value,
        CONSTRUCTION_DESC: this.constructionTypeForm.get('constructionDesc')?.value,
        BM_CODE: this.constructionTypeForm.get('bpimsCode')?.value,
        REMARKS: this.constructionTypeForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.constructionTypeForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.constructionTypeForm.get('userID')?.value,
        MODE: 'U'
      }
      return formToData;
    }
    catch(e) {
    }
  }
  deleteLine() {
    try {
      let clickData = this.rowClickedData;
      this.constructionTypeTblConfig.tblData = this.constructionTypeTblConfig.tblData.filter(
        function(e: any) {
          return e.CONSTRUCTION_DESC.trim().toUpperCase() !== clickData.CONSTRUCTION_DESC.trim().toUpperCase();
        }
      );
      this.tempAddedData = this.tempAddedData.filter(
        function(e: any) {

          return e.CONSTRUCTION_DESC.trim().toUpperCase() !== clickData.CONSTRUCTION_DESC.trim().toUpperCase();
        }
      );
      this.resetFormValues();
    }
    catch(e) {
    }
  }

  insertLine() {
    try {
      this.tranPosted = true;
      this.validateInsert();
      if (this.validated) {
        this.addFormToTable();
      }
    }
    catch(e) {
    }
  }
  addFormToTable() {
    try {
      this.constructionTypeForm.patchValue({
        activeTag: this.constructionTypeForm.get('activeTag')?.value,
        userID: this.currentUser,
        lastUserUpdate: new Date()
      });
      let formToData: any = { // this.datePipe.transform(new Date(), 'MM/dd/yyyy')
        CONSTRUCTION_CD: this.constructionTypeForm.get('constructionCode')?.value,
        CONSTRUCTION_DESC: this.constructionTypeForm.get('constructionDesc')?.value,
        ACTIVE_TAG: this.constructionTypeForm.get('activeTag')?.value,
        BM_CODE: this.constructionTypeForm.get('bpimsCode')?.value,
        REMARKS: this.constructionTypeForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.constructionTypeForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.constructionTypeForm.get('userID')?.value,
        MODE: 'I'
      }
      this.constructionTypeTblConfig.tblData.unshift(formToData);
      this.constructionTypeTblConfig.tblData = [...this.constructionTypeTblConfig.tblData];
      this.tempAddedData.push(formToData);
      this.resetFormValues();
      this.appMessageService.showAppMessage("Construction Type details added!", "success");
    }
    catch(e) {
    }
  }

  saveData(tempChangeData:any[]){
    this.apiCallService.saveConstructionType(tempChangeData)
    .subscribe({
      next: (response : any) => {
        this.securityService.checkRequestKeyResponse(response, () => {
          this.securityService.hasValidCsrfToken(response, ()=>{
            if (response.status == "SUCCESS" && this.tranPosted) {
              this.openDialog('SUCCESS', 'Details Successfully saved!');
              this.getAllSegmentCode();
              this.resetFormValues();
              this.tempAddedData = [];
              this.tempUpdateData = [];
              this.saveDisabled = this.saveChecker();
            }  
            else {
              this.getAllSegmentCode();
              this.saveDisabled = this.saveChecker();
              this.openDialog('ERROR', 'An error has occured while saving details');
            }
          });
        });
      }, 
      error: () => {
        this.openDialog('ERROR', 'An error has occured while saving details');
      }
    });
  }

  toSave() {
    try {
      this.noLoadingInProcess = false;
      this.formService.showFormLoader(null, "generate-line-form", "Please wait ...", null, null);
      let tempChangeData= this.tempAddedData.concat(this.tempUpdateData);
      if(tempChangeData.length!=0) {
        this.saveData(tempChangeData);
      } else {
        this.getAllSegmentCode();
        this.resetFormValues();
        this.tempAddedData = [];
        this.tempUpdateData = [];
        this.saveDisabled = this.saveChecker();
      }
    }
    catch(e) {
    }
  }
  saveChecker() {
    try {
      if((this.tempAddedData==null || this.tempAddedData=='') && (this.tempUpdateData==null || this.tempUpdateData=='')) {
        return true;
      }
      else {
        return false;
      }
    }
    catch(e) {
      return true;
    }
  }

  deleteChecker(ev: any) {
    try {
      let tempDataFlag;
      tempDataFlag = this.tempAddedData.filter(function(e: any) {
        let returnValue = e.CONSTRUCTION_DESC.trim().toUpperCase()==ev.CONSTRUCTION_DESC.trim().toUpperCase();
        return returnValue;
      });
      if(tempDataFlag==''||tempDataFlag==null) {
        return true;
      } else {
        return false;
      }
    }
    catch(e) {
      return true;
    }
  }

  onRowClick(ev: any) {
    try {    
      if(ev!=null) {
        this.rowClickedData = ev;
        this.loadFormValues();
        this.saveDisabled = this.saveChecker();
        this.deleteDisabled = this.deleteChecker(ev);
      }
      else {
        this.resetFormValues();
      }
    }
    catch(e) {
    }
  }

  loadFormValues() {
    try {
      this.constructionTypeForm.patchValue({
        constructionCode: this.rowClickedData.CONSTRUCTION_CD,
        activeTag: this.rowClickedData.ACTIVE_TAG,
        constructionDesc: this.rowClickedData.CONSTRUCTION_DESC,
        origConstructionDesc: this.rowClickedData.CONSTRUCTION_DESC,
        bpimsCode: this.rowClickedData.BM_CODE,
        remarks: this.rowClickedData.REMARKS,
        userID: this.rowClickedData.USER_ID,
        lastUserUpdate: this.rowClickedData.LAST_USER_UPDATE
      });
      this.addDisabled = true;
      this.userIDReadOnly = true;
      this.isLineSelected = true;
    }
    catch(e) {
    }
  }

  resetFormValues() {
    try {
      this.selectedData = null;
      // this.paramForm.get('lineCD')?.enable();
      this.constructionTypeForm.patchValue({
        constructionCode: null,
        activeTag: 'A',
        constructionDesc: null,
        origConstructionDesc: null,
        bpimsCode: null,
        remarks: null,
        userID: null,
        lastUserUpdate: null
      });

      this.addDisabled = false;
      this.saveDisabled = this.saveChecker();
      this.deleteDisabled = true;
      this.userIDReadOnly = false;
      this.isLineSelected = false;

      this.rowClickedData = '' || null;
    }
    catch (e) {
    console.error(e);
    }
  }

  openDialog(title: any, message: any) {
    try {
      this.noLoadingInProcess = true;
      this.formService.hideFormLoader("generate-line-form");
      this._Dialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        } 
      });
    } catch(e) {
    }
  }

  validateInsert() {
    try {
      this.validated = false;
      if (!this.requiredFieldCheck()){
        setTimeout(()=>{                           //10 secs countdown
          this.formService.hideFormMsg("construction-type-error-message");
        }, 10000);
      }
      else if (!this.duplicateCheck(this.constructionTypeTblConfig.tblData)) {
        setTimeout(()=>{                           
          this.formService.hideFormMsg("construction-type-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
    }
  }

  requiredFieldCheck() {
    try {
      if(this.constructionTypeForm.get('activeTag')?.value==='' || this.constructionTypeForm.get('activeTag')?.value===null 
        || this.constructionTypeForm.get('constructionDesc')?.value.trim()==='' || this.constructionTypeForm.get('constructionDesc')?.value===null
        || this.constructionTypeForm.get('bpimsCode')?.value.trim()===''||this.constructionTypeForm.get('bpimsCode')?.value===null) {
        this.formService.showFormMsg("construction-type-error-message", 'There are missing information. Please provide the necessary information needed.', "E");
        return false;
      }
      else {
        return true;
      }
   }
    catch (e) {
    console.error(e);
      return false;
    }
  }

  duplicateCheck(tblData:any) {
    try {
      let fieldConstructionDesc = this.constructionTypeForm.get('constructionDesc')?.value;
      this.pubUserStdRatesCodeField = tblData.filter(function(e: any) {
        if(e.CONSTRUCTION_DESC.trim().toUpperCase()==fieldConstructionDesc.trim().toUpperCase()){
          return true
        }
        else{
          return false
        }
      });
      if(this.pubUserStdRatesCodeField=='' || this.pubUserStdRatesCodeField==null) {
        return true;
      } else {
        this.formService.showFormMsg("construction-type-error-message", 'Construction Description already exists. Please add a unique Construction Description', "E");
        return false;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }

  validateUpdate() {
    try {
      this.validated = false;
      let fieldConstructionDesc = this.constructionTypeForm.get('origConstructionDesc')?.value;
      let filteredTblData = this.constructionTypeTblConfig.tblData.filter(function(e:any){
        if(e.CONSTRUCTION_DESC.trim().toUpperCase()==fieldConstructionDesc.trim().toUpperCase()){
          return false;
        }
        else{
          return true;
        }
      });
      if (!this.requiredFieldCheck()){
        setTimeout(()=>{                          
          this.formService.hideFormMsg("construction-type-error-message");
        }, 10000);
      }
      else if (!this.duplicateCheck(filteredTblData)) {
        setTimeout(()=>{                           
          this.formService.hideFormMsg("construction-type-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
    }
  }
  
}
