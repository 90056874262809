import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

@Component({
  selector: 'line-lov-dialog',
  templateUrl: './line-lov-dialog.component.html',
  styleUrls: ['./line-lov-dialog.component.css']
})
export class LineLovDialogComponent implements OnInit {

  selectedData: any;
  lineModuleId: string = 'BMM029';
  sublineModuleId: string = 'BMM030';

  public tableSelected: any;

  public titleLov: string = "LINE";

  lineTable: any = {
    cols: [],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  subLineTable: any = {
    cols: [
      {
        key: "SUBLINE_CD",
        header: "Code",
        dataType: "string"
      },
      {
        key: "SUBLINE_NAME",
        header: "Subline Name",
        dataType: "string"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15
  };

  private lineTableCol: any[] = [
    {
      key: "LINE_CD",
      header: "Code",
      dataType: "string"
    },
    {
      key: "LINE_NAME",
      header: "Line",
      dataType: "string"
    },
  ];

  private lineTableNameCol: any[] = [
    {
      key: "LINE_CD",
      header: "Code",
      dataType: "string"
    },
    {
      key: "LINE_NAME",
      header: "Line Name",
      dataType: "string"
    },
  ];
  constructor(
    private jsonDataService: JsonDataService,
    private securityService: SecurityService,
    private dialogRef: MatDialogRef<LineLovDialogComponent>,
    private apiCallService: APICallService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    @Inject(MAT_DIALOG_DATA) public fromMainData: any,
  ) {
    try {
      if (this.fromMainData) {
        this.titleLov = this.fromMainData.table;
        if (this.fromMainData.table === "SUBLINE") {
          this.tableSelected = this.subLineTable;
          this.getSubLineData();
        }
        else if (this.fromMainData.table === "LINE") {
          this.tableSelected = this.lineTable;
          if(this.fromMainData.col === 'LINE'){
            this.lineTable.cols = this.lineTableCol;
          }
          else{
            this.lineTable.cols = this.lineTableNameCol;
          } 
          this.getLineData();
        }
        if("title" in this.fromMainData){
          this.titleLov = this.fromMainData.title;
        }
      }
      else {
        this.tableSelected = this.lineTable;
        this.getLineData();
      }
    } catch (e) {
    console.error(e);
      
    }
  }

  ngOnInit(): void {
  }

  getLineData() {
    try {
      this.lineTable.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.apiCallService.getAllLine({
        moduleId: this.fromMainData.moduleId,
        userId: this.userDetailService.userId,
        type: "LOV"
      }).subscribe((data: any) => {
        this.securityService.checkRequestKeyResponse(data, () => {
          this.securityService.hasValidCsrfToken(data, () => {
            if (data.status === "SUCCESS") {
              data = JSON.parse(this.jsonDataService.decrypt(data.response));
              if (data.moduleId === this.lineModuleId) {
                this.jsonDataService.contorlLoading(false);
                this.tableSelected.tblData = data.data.filter((e: any) => e.ACTIVE_TAG == 'A');
                this.lineTable.loading = false;
              } else {
                this.jsonDataService.contorlLoading(false);
                this.lineTable.loading = false;
                this.appMessageService.showAppMessage("You do not have the correct privilege to access the data. Kindly contact your administrator for assistance.", "error");
              }
            } else {
              this.jsonDataService.contorlLoading(false);
              this.lineTable.loading = false;
              this.appMessageService.showAppMessage(data.message, "error");
            }
          });
        });
      });
    }
    catch (e) {
    console.error(e);
      
    }
  }

  getSubLineData() {
    try {
      this.subLineTable.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.apiCallService.getAllLine({
        moduleId: this.fromMainData.moduleId,
        userId: this.userDetailService.userId,
        type: "LOV"
      }).subscribe({
        next: (data: any) => {
          this.securityService.checkRequestKeyResponse(data, () => {
            this.securityService.hasValidCsrfToken(data, () => {
              if (data.status === 'SUCCESS') {
                data = JSON.parse(this.jsonDataService.decrypt(data.response));
                if (data.moduleId === this.lineModuleId) {
                  this.apiCallService.getAllSubline({
                    moduleId: this.fromMainData.moduleId,
                    userId: this.userDetailService.userId,
                    type: "LOV"
                  }).subscribe({
                    next: (res: any) => {
                      this.securityService.checkRequestKeyResponse(res, () => {
                        this.securityService.hasValidCsrfToken(res, () => {
                          if (res.status === 'SUCCESS') {
                            let subLine = JSON.parse(this.jsonDataService.decrypt(res.response));
                            if (subLine.moduleId === this.sublineModuleId) {
                              let line = data.data;
                              this.subLineTable.loading = false;
                              this.jsonDataService.contorlLoading(false);
                              let merged = subLine.data.map((item: any) => ({ ...item, 'LINE_NAME': line.find((object: any) => object.LINE_CD === item.LINE_CD).LINE_NAME }));
                              this.tableSelected.tblData = merged.filter((e: any) => {
                                return e.ACTIVE_TAG === 'A' && (e.LINE_CD === (this.fromMainData.lineCd || e.LINE_CD));
                              });
                            } else {
                              this.subLineTable.loading = false;
                              this.jsonDataService.contorlLoading(false);
                              this.appMessageService.showAppMessage("You do not have the correct privilege to access the data. Kindly contact your administrator for assistance.", "error");
                            }
                          } else {
                            this.subLineTable.loading = false;
                            this.jsonDataService.contorlLoading(false);
                            this.appMessageService.showAppMessage(res.message, "error");
                            
                          }
                        });
                      });
                    },
                    error: (e: any) => {
                      
                    }
                  });
                } else {
                  this.subLineTable.loading = false;
                  this.jsonDataService.contorlLoading(false);
                  this.lineTable.loading = false;
                  this.appMessageService.showAppMessage("You do not have the correct privilege to access the data. Kindly contact your administrator for assistance.", "error");
                }
              } else {
                this.jsonDataService.contorlLoading(false);
                this.subLineTable.loading = false;
                this.appMessageService.showAppMessage(data.message, "error");
                
              }
            });
          });
        },
        error: (e: any) => {
          this.jsonDataService.contorlLoading(false);
          this.subLineTable.loading = false;
          this.appMessageService.showAppMessage(e, "error");
          
        }
      });

      /*forkJoin({
        line: this.apiCallService.getAllLine(),
        subLine: this.apiCallService.getAllSubline(),
      }).subscribe({
        next: (data: any) => {
            this.securityService.checkRequestKeyResponse(data, () => {
                let line = data.line.content;
                let subLine = data.subLine.content;
                let merged = subLine.map((item: any) => ({ ...item, 'LINE_NAME': line.find((object: any) => object.LINE_CD === item.LINE_CD).LINE_NAME }));
                this.tableSelected.tblData = merged.filter((e: any) => {
                    return e.ACTIVE_TAG === 'A' && (e.LINE_CD === (this.fromMainData.lineCd || e.LINE_CD));
                });
            });
        },
        error: (e: any) => {
          
        }
      });*/
    }
    catch (e) {
    console.error(e);
      
    }
  }

  onRowClick(ev: any) {
    try {
      this.selectedData = ev;
    } catch (e) {
    console.error(e);
      
    }
  }

  onClose(btn: string) {
    try {
      if (this.selectedData == '' || this.selectedData == null) {
        this.dialogRef.close({ content: '', button: btn });
      } else {
        this.dialogRef.close({ content: this.selectedData, moduleId: this.fromMainData.moduleId, button: btn });
      }
    } catch (e) {
    console.error(e);
      
    }
  }

}
