import { Component, Inject } from '@angular/core';
import { APICallService } from 'src/app/services/api-call.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { AppMessageService } from 'src/app/services/app-message.service';

@Component({
  selector: 'fire-register-lov',
  templateUrl: './fire-register-lov.component.html',
  styleUrls: ['./fire-register-lov.component.css']
})
export class FireRegisterLovComponent {

  moduleId: string = 'BMM147';
  private selectedData: any;
  public titleLov: string = "FIRE REGISTER";

  table: any = {
    cols: [
      {
        key: "blockCd",
        header: "Block Code",
        dataType: "string",
        width: '100px'
      },
      {
        key: "bmFregCd",
        header: "Register",
        dataType: "string",
        width: '100px'
      },
      {
        key: "stateBmCd",
        header: "State Code",
        dataType: "string",
        width: '100px'
      },
      {
        key: "stateDesc",
        header: "State",
        dataType: "string",
        width: '250px'
      },
      {
        key: "districtBmCd",
        header: "District Code",
        dataType: "string",
        width: '120px'
      },
      {
        key: "distLongDesc",
        header: "District",
        dataType: "string",
        width: '250px'
      },
      {
        key: "streetEstate",
        header: "Street/Estate",
        dataType: "string",
        width: '250px'
      },

      // {
      //   key: "tariffCd",
      //   header: "TariFf Code",
      //   dataType: "string"
      // },
      // {
      //   key: "occupancyDesc",
      //   header: "Occupancy",
      //   dataType: "string"
      // },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: true,
    totalRecords: 15,
    scrollable: true,
    scrollableWidth: "100%",
    loading: false,
  };

  constructor(
    private jsonDataService: JsonDataService,
    private dialogRef: MatDialogRef<FireRegisterLovComponent>,
    private _api: APICallService,
    private securityService: SecurityService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    @Inject(MAT_DIALOG_DATA) public fromMainData: any,
  ) { 
    // this.getData();
  }

  lazyLoad(ev: any) {
    setTimeout(() => {
      this.getData({
        first: ev.first,
        rows: this.table.rowsPerPage,
        search: ev.globalFilter,
        sortBy: ev.sortField == undefined ? 'blockCd' : ev.sortField,
        sortDir: ev.sortField == undefined ? 1 : ev.sortOrder
      });
    }, 0);
  }

  onRowClick(ev: any) {
    try {
      this.selectedData = ev;
    } catch (e) {
      console.error(e);
    }
  }

  private getData(prm?: any): void {
    try {
      let qryPrm: any = {};

      if (prm) {
        qryPrm = {
          first: prm.first,
          rows: prm.rows,
          search: prm.search,
          sortBy: prm.sortBy,
          sortDir: prm.sortDir
        }
      }

      this.jsonDataService.contorlLoading(true);

      this._api.getFireAccumulationRegister({
        moduleId: this.fromMainData.moduleId,
        stateCd: this.fromMainData.stateCd,
        districtCd: this.fromMainData.districtCd,
        regionCd: this.fromMainData.regionCd,
        userId: this.userDetailService.userId,
        type: "LOV",
        first: qryPrm.first,
        rows: qryPrm.rows,
        search: qryPrm.search,
        sortBy: qryPrm.sortBy,
        sortDir: qryPrm.sortDir
      }).subscribe({
        next: (data: any) => {
          this.securityService.checkRequestKeyResponse(data, () => {
            this.securityService.hasValidCsrfToken(data, () => {
              let content = JSON.parse(this.jsonDataService.decrypt(data.response));
              if (content.moduleId === this.moduleId) {
                this.table.totalRecords = content?.count;
                this.table.pageLinks = 10;
                this.table.tblData = content?.data;
                this.jsonDataService.contorlLoading(false);
                this.table.tblData = content?.data.filter((a:any)=>a.activeTag == 'A');
                this.table.loading = false;
              } else {
                this.table.loading = false;
                this.jsonDataService.contorlLoading(false);
                this.appMessageService.showAppMessage("You do not have the correct privilege to access the data. Kindly contact your administrator for assistance.", "error");
              }
            });
          });
        },
        error: (e: any) => {
          this.securityService.checkRequestKeyResponse(e, () => {
            this.securityService.hasValidCsrfToken(e, () => {
              this.table.loading = false;
              this.jsonDataService.contorlLoading(false);
              this.appMessageService.showAppMessage(e, "error");
            });
          });
        }
      });
    }
    catch (e) {
      console.error(e);
      this.table.loading = false;
      this.jsonDataService.contorlLoading(false);
    }
  }

  onClose(str: string) {
    try {
      if (this.selectedData == '' || this.selectedData == null) {
        this.dialogRef.close({ content: '', button: str });
      }
      else {
        this.dialogRef.close({ content: this.selectedData, button: str });
      }
    } catch (e) {
      console.error(e);
    }
  }

}
