<div class="mtn-container">
    <div class="mtn-title">
        <p>{{ moduleName }}</p>
    </div>
    <div class="outer-wrapper p-datatable mtn-header">
        <div class="p-datatable-header"><span class="header-title">PLAN</span></div>
        <div *ngIf="showForm.plan" class="form-content">
            <form [formGroup]="forms.lineSubLineLovForm" class="form">
                <div class="form-container-grid top-form">
                    <div class="field required">
                        <label>Line</label>
                    </div>
                    <div class="">
                        <input formControlName="lineCdWithDescDisplay">
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov('LINE')"><mat-icon>search</mat-icon></button>
                    </div>
                    <div class="field required">
                        <label>Subline</label>
                    </div>
                    <div class="">
                        <input formControlName="subLineCdWithDescDisplay">
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov('SUBLINE')" [disabled]="btns.plan.sublineSearchDisabled"><mat-icon>search</mat-icon></button>
                    </div>
                </div>
            </form>
        </div>
        <osp-table [tblConfig]="tblConfig.plan" (onRowClick)="onRowClick.plan($event)"></osp-table>
        <div id="plan-maintenance-loading"><div class="form-loader"></div></div>
        <div *ngIf="showForm.plan" class="form-content">
            <form [formGroup]="forms.planForm" class="form">
                <div id="plan-error-message"></div>
                <div class="form-container-grid top-form">
                    <div class="field">
                        <label>Plan Code</label>
                    </div>
                    <div class="">
                        <input formControlName="planCdDisplay">
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="active">
                            <option *ngFor="let activeType of selections.activeTypes" [value]="activeType.cd">
                                {{activeType.name}}
                            </option>
                        </select>
                    </div>
                    <div class="field required">
                        <label>Plan Name</label>
                    </div>
                    <div class="">
                        <input formControlName="planName">
                    </div>
                    <div class="field">
                        <label>Plan Short Name</label>
                    </div>
                    <div class="">
                        <input formControlName="planShortName" maxlength="15">
                    </div>
                    <div class="field">
                        <label>Plan Name (GCTPL)</label>
                    </div>
                    <div class="">
                        <input formControlName="planName2">
                    </div>
                    <div class="field">
                        <label>Plan Subtitle</label>
                    </div>
                    <div class="">
                        <input formControlName="planSubTitle">
                    </div>
                    <div class="field">
                        <label>Plan Long Description</label>
                    </div>
                    <div class="span">
                        <input formControlName="planLongDesc" >
                    </div>
                    <div class="field">
                        <label>Terms Path</label>
                    </div>
                    <div class="span">
                        <input formControlName="termsPath" >
                    </div>
                    <div class="field">
                        <label>Opening Text</label>
                    </div>
                    <div class="span">
                        <textarea formControlName="openingText" ></textarea>
                    </div>
                    <div class="field">
                        <label>Closing Text</label>
                    </div>
                    <div class="span">
                        <textarea formControlName="closingText" ></textarea>
                    </div>
                    <div class="field required">
                        <label>Effective Date</label>
                    </div>
                    <div class="">
                        <input date-input [matDatepicker]="effDatePicker" [max]="maxEffDate" placeholder="MM/DD/YYYY" formControlName="effDateFrom" >
                        <mat-datepicker-toggle matSuffix [for]="effDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #effDatePicker></mat-datepicker>
                    </div>
                    <div class="field">
                        <label>Expiry Date</label>
                    </div>
                    <div class="">
                        <input date-input [matDatepicker]="exDatePicker" [min]="minExpDate" placeholder="MM/DD/YYYY" formControlName="effDateTo" >
                        <mat-datepicker-toggle matSuffix [for]="exDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #exDatePicker></mat-datepicker>
                    </div>
                    <div class="field required">
                        <label>Display Coverage</label>
                    </div>
                    <div class="">
                        <select formControlName="dispCoverage" >
                            <option *ngFor="let coverageType of selections.coverageTypes" [value]="coverageType.cd">
                                {{coverageType.name}}
                            </option>
                        </select>
                    </div>
                    <div class="field">
                        <label>Sequence No</label>
                    </div>
                    <div class="">
                        <input formControlName="seqNo" posNumInput type="number" min="1">
                    </div>
                    <div class="field">
                        <label>Contract Type</label>
                    </div>
                    <div class="">
                        <input formControlName="contractTypeDisp">
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov('CONTRACT_TYPE')" [disabled]='btns.plan.lovDisabled'><mat-icon>search</mat-icon></button>
                    </div>
                    <div class="field">
                        <label>Coverage Type</label>
                    </div>
                    <div class="">
                        <input formControlName="coverageTypeDisp">
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov('COVERAGE_TYPE')" [disabled]='btns.plan.lovDisabled'><mat-icon>search</mat-icon></button>
                    </div>
                    <div class="field">
                        <label>RPA Rate Flag</label>
                    </div>
                    <div class="">
                        <select formControlName="rpaRateFlag" >
                            <option *ngFor="let rpaRateFlag of selections.rpaRateFlags" [ngValue]="rpaRateFlag.cd">
                                {{rpaRateFlag.name}}
                            </option>
                        </select>
                    </div>
                    <div class="field required">
                        <label>BPIMS Code</label>
                    </div>
                    <div class="">
                        <input formControlName="bmCode" maxlength="8">
                    </div>
                    <div class="field required">
                        <label>Currency Rate</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="currencyRt" decimal [digits]="3" [decimals]="9" >
                    </div>
                    <div class="field required">
                        <label>Currency</label>
                    </div>
                    <div class="">
                        <input formControlName="currencyDisp">
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov('CURRENCY')" [disabled]='btns.plan.lovDisabled'><mat-icon>search</mat-icon></button>
                    </div>
                    <div class="field required">
                        <label>With Referral</label>
                    </div>
                    <div class="">
                        <select formControlName="withReferral" >
                            <option *ngFor="let referralTag of selections.referralTags" [ngValue]="referralTag.cd">
                                {{referralTag.name}}
                            </option>
                        </select>
                    </div>
                    <div class="field required">
                        <label>Summarized Premium</label>
                    </div>
                    <div class="">
                        <select formControlName="summarizedPrem" >
                            <option *ngFor="let summarizedPrem of selections.summarizedPrems" [ngValue]="summarizedPrem.cd">
                                {{summarizedPrem.name}}
                            </option>
                        </select>
                    </div>
                    <div class="field required">
                        <label>Supporting Documents</label>
                    </div>
                    <div class="">
                        <select formControlName="supportingDocs" >
                            <option *ngFor="let supportingDoc of selections.supportingDocs" [ngValue]="supportingDoc.cd">
                                {{supportingDoc.name}}
                            </option>
                        </select>
                    </div>
                    <div class="field">
                        <label>Premium Text</label>
                    </div>
                    <div class="">
                        <input formControlName="premiumText" >
                    </div>
                    <div class="field">
                        <label>Effective Date Range</label>
                    </div>
                    <div class="">
                        <input formControlName="effDateRange" posNumInput type="number">
                    </div>
                    <div class="field required">
                        <label>Renewal</label>
                    </div>
                    <div class="">
                        <select formControlName="renewal" >
                            <option *ngFor="let renewTag of selections.renewTags" [ngValue]="renewTag.cd">
                                {{renewTag.name}}
                            </option>
                        </select>
                    </div>
                    <div class="field">
                        <label>Same Day User</label>
                    </div>
                    <div class="">
                        <mat-select multiple formControlName="sameDayUser">
                            <mat-option *ngFor="let sameDayUser of selections.sameDayUsers" [value]="sameDayUser.cd">
                                {{sameDayUser.name}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="field">
                        <label>Source Code</label>
                    </div>
                    <div class="">
                        <input formControlName="srcDisp">
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov('SRC_CD')" [disabled]='btns.plan.lovDisabled'><mat-icon>search</mat-icon></button>
                    </div>
                    <div class="field">
                        <label>Source Extension Code</label>
                    </div>
                    <div class="">
                        <input formControlName="srcExtDisp">
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov('SRC_EXT_CD')" [disabled]='btns.plan.lovDisabled'><mat-icon>search</mat-icon></button>
                    </div>
                    <div class="field">
                    </div>
                    <div class="">
                    </div>
                    <div class="field">
                        <label>Other Risk Details</label>
                    </div>
                    <div class="span">
                        <textarea formControlName="otherRiskDtls" ></textarea>
                    </div>
                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" >
                    </div>
                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input formControlName="lastUpdateUser" >
                    </div>
                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="lastUpdate" >
                    </div>
                </div>
            </form>
            <div class="btn-grp">
                <button *ngIf="btns.plan.updateDisabled"class="btn btn2" type="button" (click)="onAdd.plan()" [disabled]="btns.plan.addDisabled" >ADD</button>
                <button *ngIf="!btns.plan.updateDisabled" class="btn btn2" type="button" (click)="onUpdate.plan()" >UPDATE</button>
                <button class="btn btn2" type="button" (click)="onDelete.plan()" [disabled]='btns.plan.deleteDisabled'>DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-grp">
                <button class="btn btn2" type="button" (click)="onSave.plan()" [disabled]='btns.plan.saveDisabled'>SAVE</button>
            </div>
        </div>
    </div>
    <div class="outer-wrapper p-datatable mtn-header">
        <div class="p-datatable-header"><span class="header-title">PLAN PERIL</span></div>
        <osp-table [tblConfig]="tblConfig.planPeril" (onRowClick)="onRowClick.planPeril($event)"></osp-table>
        <div id="plan-peril-maintenance-loading"><div class="form-loader"></div></div>
        <div *ngIf="showForm.planPeril" class="form-content">
            <form [formGroup]="forms.planPerilForm" class="form">
                <div id="plan-peril-error-message"></div>
                <div class="form-container-grid top-form">
                    <div class="field required">
                        <label>Peril</label>
                    </div>
                    <div class="">
                        <input formControlName="perilCdDisplay" >
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov('PERIL')" [disabled]='btns.planPeril.lovDisabled'><mat-icon>search</mat-icon></button>
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="active" >
                            <option *ngFor="let activeType of selections.activeTypes" [value]="activeType.cd">
                                {{activeType.name}}
                            </option>
                        </select>
                    </div>
                    <div class="field">
                        <label>Sequence No</label>
                    </div>
                    <div class="">
                        <input formControlName="seqNo" posNumInput type="number" min="1">
                    </div>
                    <div class="field required">
                        <label>Highlight Coverage</label>
                    </div>
                    <div class="">
                        <select formControlName="hlCoverage" >
                            <option *ngFor="let coverageType of selections.coverageTypes" [value]="coverageType.cd">
                                {{coverageType.name}}
                            </option>
                        </select>
                    </div>
                    <div class="field">
                        <label>Peril Description</label>
                    </div>
                    <div class="">
                        <input formControlName="perilDesc" >
                    </div>
                    <div class="field">
                        <label>Multiplier Rate</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="multiplierRt" decimal [digits]="3" [decimals]="9" >
                    </div>
                    <div class="field">
                        <label>Default Rate</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="defaultRate" decimal [digits]="3" [decimals]="9" >
                    </div>
                    <div class="field">
                        <label>Default Amount Covered</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="defaultTsi" decimal [digits]="14" [decimals]="2" >
                    </div>
                    <div class="field required">
                        <label>Fair Market Value Switch</label>
                    </div>
                    <div class="">
                        <select formControlName="fmvSw">
                            <option *ngFor="let fmvSw of selections.fmvSw" [value]="fmvSw.cd">
                                {{fmvSw.name}}
                            </option>
                        </select>
                    </div>
                    <div class="field required">
                        <label>Rating Table Switch</label>
                    </div>
                    <div class="">
                        <select formControlName="ratingTblSw">
                            <option *ngFor="let rtTableSw of selections.rtTableSw" [value]="rtTableSw.cd">
                                {{rtTableSw.name}}
                            </option>
                        </select>
                    </div>
                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" >
                    </div>
                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input formControlName="lastUpdateUser" >
                    </div>
                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="lastUpdate" >
                    </div>
                </div>
            </form>
            <div class="btn-grp">
                <button *ngIf="btns.planPeril.updateDisabled"class="btn btn2" type="button" (click)="onAdd.planPeril()" [disabled]="btns.planPeril.addDisabled">ADD</button>
                <button *ngIf="!btns.planPeril.updateDisabled" class="btn btn2" type="button" (click)="onUpdate.planPeril()" >UPDATE</button>
                <button class="btn btn2" type="button" (click)="onDelete.planPeril()" [disabled]="btns.planPeril.deleteDisabled">DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-grp">
                <button class="btn btn2" type="button" (click)="onSave.planPeril()" [disabled]="btns.planPeril.saveDisabled">SAVE</button>
            </div>
        </div>
    </div>
</div>
