<section id="risk-rating-questions">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <osp-table [tblConfig]="riskRatingQuestionsTable" (onRowClick)="onRowClick($event)"></osp-table>
        <div id="risk-rating-questions-form"><div class="form-loader"></div></div>
        <div class="form-content" [hidden]="formHider">
            <form [formGroup]="riskRatingQuestionsForm" class="form">
                <div id="risk-rating-questions-error-message">{{errorMessage ? errorMessage : ""}}</div>
                <div class="form-container-grid">
                    <div class="field required">
                        <label>Question No.</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="QUESTION_NO">
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="ACTIVE_TAG">
                            <option value="A">Yes</option>
                            <option value="I">No</option>
                        </select>
                    </div>
                    <div class="field required">
                        <label>Question</label>
                    </div>
                    <div class="span">
                        <textarea maxlength="200" formControlName="QUESTION"></textarea>
                    </div>
                    <div class="field required">
                        <label>Fixed Score Tag</label>
                    </div>
                    <div class="">
                        <select formControlName="FIXED_SCORE_TAG" (change)="checkFixedScoreTag()">
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                        </select>
                    </div>
                    <div class="field" [class.required]="allScoreRequired">
                        <label>Fixed Score</label>
                    </div>
                    <div class="">
                        <input formControlName="FIXED_SCORE" type="text" (keypress)="allowNumericDigitsOnlyWODecimal($event)" [readonly]="!allScoreRequired">
                    </div>
                    <div class="field" [class.required]="!allScoreRequired">
                        <label>Yes Score</label>
                    </div>
                    <div class="">
                        <input formControlName="YES_SCORE" type="text" (keypress)="allowNumericDigitsOnlyWODecimal($event)" [readonly]="allScoreRequired">
                    </div>
                    <div class="field" [class.required]="!allScoreRequired">
                        <label>No Score</label>
                    </div>
                    <div class="">
                        <input formControlName="NO_SCORE" type="text" (keypress)="allowNumericDigitsOnlyWODecimal($event)" [readonly]="allScoreRequired">
                    </div>
                    <div class="field" [class.required]="!allScoreRequired">
                        <label>N/A Score</label>
                    </div>
                    <div class="">
                        <input formControlName="NA_SCORE" type="text" (keypress)="allowNumericDigitsOnlyWODecimal($event)" [readonly]="allScoreRequired">
                    </div>
                    <div class="field">
                        <label>Column Field</label>
                    </div>
                    <div class="">
                        <input formControlName="COLUMN_FIELD" type="text" maxlength="20">
                    </div>
                    <div class="field required">
                        <label>Effective Date</label>
                    </div>
                    <div class="">
                        <input formControlName="EFF_DATE_FROM" type="text" placeholder="MM/DD/YYYY" [matDatepicker]="effDateFromPicker" numFslashOnly>
                        <mat-datepicker-toggle matSuffix [for]="effDateFromPicker"></mat-datepicker-toggle>
                        <mat-datepicker #effDateFromPicker></mat-datepicker>
                    </div>
                    <div class="field">
                        <label>Expiry Date</label>
                    </div>
                    <div class="">
                        <input formControlName="EFF_DATE_TO" type="text" placeholder="MM/DD/YYYY" [matDatepicker]="effDateToPicker" numFslashOnly>
                        <mat-datepicker-toggle matSuffix [for]="effDateToPicker"></mat-datepicker-toggle>
                        <mat-datepicker #effDateToPicker></mat-datepicker>
                    </div>
                    <div class="field">
                        <label>Remarks</label>
                    </div>

                    <div class="span">
                        <input formControlName="REMARKS" maxlength="4000">
                    </div>
                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input formControlName="USER_ID">
                    </div>
                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="LAST_UPDATE">
                    </div>
                </div>
            </form>
            <div class="btn-grp">
                <button type="button" class="btn btn2" [hidden]="!addUpdateFlag" (click)="addRow()">ADD</button>
                <button type="button" class="btn btn2" [hidden]="addUpdateFlag" (click)="onUpdate()">UPDATE</button>
                <button type="button" class="btn btn2" [disabled]="deleteDisabler" (click)="deleteRow()">DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-grp">
                <button type="button" class="btn btn2" [disabled]="saveDisabler" (click)="onSave()">SAVE</button>
            </div>
        </div>
    </div>
</section>
