import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { CarTypeLovComponent } from '../../common/car-type-lov/car-type-lov.component';
import { ClientGroupLovDialog } from '../../common/client-group-lov-dialog/client-group-lov-dialog.component';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { PerilLovComponent } from '../../common/peril-lov/peril-lov.component';

@Component({
  selector: 'standard-amount-covered-maintenance',
  templateUrl: './standard-amount-covered-maintenance.component.html',
  styleUrls: ['./standard-amount-covered-maintenance.component.css']
})
export class StandardAmountCoveredMaintenanceComponent implements OnInit, LeaveConfirmation {

  moduleId: string = 'BMM037';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc?.toUpperCase() ?? "";

  constructor(
    public _formService: FormService,
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService,
    private _appMessageService: AppMessageService,
  ) {
    this.userIdLoggedIn = this._userDetailService.userId ?? "";
  }

  ngOnInit(): void {
  }

  canDeactivate(): boolean | Observable<boolean> {
    return this.sacTblConfig.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true)).length > 0 ? false : true;
  }

  /* Boolean Stuffs */

  public btns = {
    sac: {
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      addDisabled: true,
    },
  }

  public showForm: boolean = true;

  /* Data Stuffs */

  private rowData: any;
  private tempDataRow: any;
  private errorMessage: string = "";
  private userIdLoggedIn: string;

  /* Form Object  */

  public forms = {
    carTypePerilLovForm: this.fb.group({
      carTypeCd: [null],
      carTypeDesc: [null],
      perilCd: [null],
      perilName: [null],
      clientGrpCd: [null],
      clientGrpCdDesc: [null],
      carTypeCdDisp: [{ value: '', disabled: 'true' }],
      perilCdDisp: [{ value: '', disabled: 'true' }],
      clientGrpCdDisp: [{ value: '', disabled: 'true' }],
    }),
    sacForm: this.fb.group({
      lineCd: [null],
      perilCd: [null],
      carTypeCd: [null],
      clientGrpCd: [null],
      tsiAmt: [null, [Validators.required, this.checkDuplicateTsiAmt(), this.checkDecimalsExceeded(14, 2)]],
      premAmt: [null, [Validators.required, this.checkDecimalsExceeded(14, 2)]],
      default: ['N', [Validators.required, this.checkHasDefault()]],
      maxNoSeats: [null],
      active: ['A', [Validators.required]],
      remarks: [null, [Validators.maxLength(4000)]],
      lastUpdateUser: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }],
    }),
    resetSacForm: () => {
      this.forms.sacForm.reset();
      this.forms.sacForm.patchValue({
        active: 'A',
        default: 'N',
      });
      this.btns.sac.updateDisabled = true;
      this.btns.sac.deleteDisabled = true;
      this._formService.hideFormMsg("sac-error-message");
      this.rowData = null;
      this.forms.sacForm.get('tsiAmt')?.enable();
    },
    resetCarTypePerilLovForm: () => {
      this.forms.carTypePerilLovForm.reset();
    },
    resetAllForms: () => {
      this.forms.resetCarTypePerilLovForm();
      this.forms.resetSacForm();
    }
  }

  private sacFormManipulate = {
    dbToForm: (data: any) => {
      return {
        lineCd: data.LINE_CD || null,
        perilCd: data.PERIL_CD || null,
        carTypeCd: data.CAR_TYPE_CD || null,
        clientGrpCd: data.CLIENT_CD || null,
        tsiAmt: this._formService.setToAmountFormat(data.TSI_AMT) || null,
        premAmt: this._formService.setToAmountFormat(data.PREM_AMT) || null,
        default: data.DEFAULT_TAG || null,
        maxNoSeats: data.MAX_SEATS || null,
        active: data.ACTIVE_TAG || null,
        remarks: data.REMARKS || null,
        lastUpdateUser: data.USER_ID || null,
        lastUpdate: data.LAST_UPDATE || null,
      };
    },
    formToDb: (data: any) => {
      return {
        LINE_CD: data.lineCd,
        PERIL_CD: data.perilCd,
        CAR_TYPE_CD: data.carTypeCd,
        CLIENT_CD: data.clientGrpCd,
        TSI_AMT: parseFloat(this._formService.setToNumberWithDecimal(data.tsiAmt)),
        PREM_AMT: parseFloat(this._formService.setToNumberWithDecimal(data.premAmt)),
        DEFAULT_TAG: data.default,
        MAX_SEATS: data.maxNoSeats,
        ACTIVE_TAG: data.active,
        REMARKS: data.remarks,
        USER_ID: null,
        LAST_UPDATE: null,
      };
    },
  }

  public selections = {
    activeTypes: [
      { cd: 'A', name: 'Yes' },
      { cd: 'I', name: 'No' },
    ],
    defaultTypes: [
      { cd: 'Y', name: 'Yes' },
      { cd: 'N', name: 'No' },
    ],
  }

  public openLov(lovToOpen: string) {
    try {
      this.btns.sac.addDisabled = true;
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: lovToOpen,
          moduleId: this.moduleId
        },
      }
      switch (lovToOpen.toUpperCase()) {
        case "PERIL":
          LOVOPTIONS.data.lineCd = "MC";
          this._matDialog.open(PerilLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
            let output = dataOutput.content;
            if (dataOutput.button === 'OK') {
              if (output) {
                this.forms.carTypePerilLovForm.patchValue({
                  perilCd: output.PERIL_CD,
                  perilName: output.PERIL_LNAME,
                  perilCdDisp: `${output.PERIL_CD.toString().padStart(2, 0)} - ${output.PERIL_LNAME}`,
                });
              }
              else {
                this.forms.carTypePerilLovForm.patchValue({
                  perilCd: null,
                  perilName: null,
                  perilCdDisp: null,
                });
                this.btns.sac.addDisabled = true;
              }
              this.getData.sac();
              this.forms.resetSacForm();
            }
            else {
              if (this.forms.carTypePerilLovForm.get('perilCd')?.value
                && this.forms.carTypePerilLovForm.get('carTypeCd')?.value
                && this.forms.carTypePerilLovForm.get('clientGrpCd')?.value) {
                this.btns.sac.addDisabled = false;
              }
            }
          });
          break;
        case "CARTYPE":
          this._matDialog.open(CarTypeLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
            let output = dataOutput.content;
            if (dataOutput.button === 'OK') {
              if (output) {
                this.forms.carTypePerilLovForm.patchValue({
                  carTypeCd: parseInt(output.CAR_TYPE_CD),
                  carTypeDesc: output.CAR_TYPE_DESC,
                  carTypeCdDisp: `${output.CAR_TYPE_CD.toString().padStart(2, 0)} - ${output.CAR_TYPE_DESC}`,
                });
              }
              else {
                this.forms.carTypePerilLovForm.patchValue({
                  carTypeCd: null,
                  carTypeDesc: null,
                  carTypeCdDisp: null,
                });
                this.btns.sac.addDisabled = true;
              }
              this.getData.sac();
              this.forms.resetSacForm();
            }
            else {
              if (this.forms.carTypePerilLovForm.get('perilCd')?.value
                && this.forms.carTypePerilLovForm.get('carTypeCd')?.value
                && this.forms.carTypePerilLovForm.get('clientGrpCd')?.value) {
                this.btns.sac.addDisabled = false;
              }
            }
          });
          break;
        case "CLIENTTYPE":
          LOVOPTIONS.data.table = 'CLIENT_GROUP';
          this._matDialog.open(ClientGroupLovDialog, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
            let output = dataOut.content;
            if (dataOut.option?.toUpperCase() !== "BACK") {
              if (output.CLIENT_CD) {
                this.forms.carTypePerilLovForm.patchValue({
                  clientGrpCd: output.CLIENT_CD,
                  clientGrpCdName: output.CLIENT_DESC,
                  clientGrpCdDisp: `${output.CLIENT_CD} - ${output.CLIENT_DESC}`,
                });
              }
              else {
                this.forms.carTypePerilLovForm.patchValue({
                  clientGrpCd: null,
                  clientGrpCdName: null,
                  clientGrpCdDisp: null,
                });
                this.btns.sac.addDisabled = true;
              }
              this.getData.sac();
              this.forms.resetSacForm();
            }
            else {
              if (this.forms.carTypePerilLovForm.get('perilCd')?.value
                && this.forms.carTypePerilLovForm.get('carTypeCd')?.value
                && this.forms.carTypePerilLovForm.get('clientGrpCd')?.value) {
                this.btns.sac.addDisabled = false;
              }
            }
          });
          break;
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  private getData = {
    sac: () => {
      try {
        this.sacTblConfig.tblData = [];
        if (this.forms.carTypePerilLovForm.get('perilCd')?.value
          && this.forms.carTypePerilLovForm.get('carTypeCd')?.value
          && this.forms.carTypePerilLovForm.get('clientGrpCd')?.value) {
          const params = {
            lineCd: "MC",
            perilCd: this.forms.carTypePerilLovForm.get('perilCd')?.value,
            carTypeCd: this.forms.carTypePerilLovForm.get('carTypeCd')?.value,
            clientGrpCd: this.forms.carTypePerilLovForm.get('clientGrpCd')?.value,
            moduleId: this.moduleId,
            userId: this._userDetailService.userId,
            type: "MODULE"
          }
          this.sacTblConfig.loading = true;
          this._jDS.contorlLoading(true);
          this._api.getStandardAmtCovered(params).subscribe({
            next: (response: any) => {
              this._securityService.checkRequestKeyResponse(response, () => {
                this._securityService.hasValidCsrfToken(response, () => {
                  if (response.status === 'SUCCESS') {
                    let content = JSON.parse(this._jDS.decrypt(response.response));
                    let filteredData = content.data.filter((d: any) =>
                    (d.LINE_CD == 'MC'
                      && d.PERIL_CD == this.forms.carTypePerilLovForm.get('perilCd')?.value
                      && d.CAR_TYPE_CD == this.forms.carTypePerilLovForm.get('carTypeCd')?.value
                      && d.CLIENT_CD == this.forms.carTypePerilLovForm.get('clientGrpCd')?.value));
                    this.sacTblConfig.tblData = filteredData;
                    this.btns.sac.addDisabled = false;
                  }
                  else {
                    this._appMessageService.showAppMessage(response.message, "error");
                  }
                  this.sacTblConfig.loading = false;
                  this._jDS.contorlLoading(false);
                });
              });
            },
            error: (e: any) => {

              this.sacTblConfig.loading = false;
              this._jDS.contorlLoading(false);
            }
          });
        }
      }
      catch (e) {
    console.error(e);

      }
    }
  }

  sacTblConfig = {
    cols: [
      {
        key: "TSI_AMT",
        header: "Amount Covered",
        dataType: "currency"
      },
      {
        key: "PREM_AMT",
        header: "Premium Amount",
        dataType: "currency"
      },
      {
        key: "ACTIVE_TAG",
        header: "Active",
        dataType: "checkbox",
      }
    ],
    tblData: [] as any[],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  onRowClick(ev: any) {
    try {
      if(ev) {
        ev.update = true;
        this.forms.sacForm.get('tsiAmt')?.disable();
        this.rowData = ev;
        this.populateFormFromTable();
      } else {
        this.forms.resetSacForm();
        this.rowData = null;
      }
    }
    catch (e) {
      console.error(e);
      this.forms.resetSacForm();
      this.rowData = null;
    }
  }

  populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.sac.updateDisabled = !data?.update;
      this.btns.sac.deleteDisabled = !data?.temp;
      this.tempDataRow = !data?.temp;
      this.forms.sacForm.patchValue(this.sacFormManipulate.dbToForm(data));
    }
    catch (e) {
    console.error(e);

    }
  }

  onAdd() {
    try {
      this._formService.hideFormMsg("sac-error-message");
      if (this.forms.sacForm.valid) {
        this.forms.sacForm.patchValue({
          lineCd: "MC",
          perilCd: this.forms.carTypePerilLovForm.get('perilCd')?.value,
          carTypeCd: this.forms.carTypePerilLovForm.get('carTypeCd')?.value,
          clientGrpCd: this.forms.carTypePerilLovForm.get('clientGrpCd')?.value,
        });
        let rowToBeAdded: { [key: string]: any } = this.sacFormManipulate.formToDb(this.forms.sacForm.value);
        rowToBeAdded.temp = true;
        this.sacTblConfig.tblData = [rowToBeAdded, ...this.sacTblConfig.tblData];
        this.forms.resetSacForm();
        this.btns.sac.saveDisabled = false;
      }
      else {
        this.showErrorValidator.sac();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  onDelete() {
    try {
      this.sacTblConfig.tblData = this.sacTblConfig.tblData.filter((d: any) => {
        return d !== this.rowData;
      });
      this.forms.resetSacForm();
    }
    catch (e) {
    console.error(e);

    }
  }

  onUpdate() {
    try {
      this._formService.hideFormMsg("sac-error-message");
      if (this.forms.sacForm.valid) {
        let toBeUpdatedIndex = this.sacTblConfig.tblData.indexOf(this.rowData);
        let tsiAmtRowValue = this.rowData.TSI_AMT;
        let rowToBeUpdated: { [key: string]: any } = this.sacFormManipulate.formToDb(this.forms.sacForm.value);
        rowToBeUpdated.TSI_AMT = tsiAmtRowValue;
        if (this.rowData.temp) {
          rowToBeUpdated.temp = true;
        }
        else {
          rowToBeUpdated.onDbButUpdatedTemp = true;
        }
        this.forms.resetSacForm();
        this.sacTblConfig.tblData[toBeUpdatedIndex] = rowToBeUpdated;
        this.btns.sac.saveDisabled = false;
        this.sacTblConfig.tblData = [... this.sacTblConfig.tblData];
      }
      else {
        this.showErrorValidator.sac();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  onSave() {
    try {
      this.showForm = false;
      this._formService.showFormLoader(null, "sac-maintenance-loading", "Saving.<br>Please wait ...", null, null, true);
      let toBeSaved = this.sacTblConfig.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true));
      if (toBeSaved.length > 0) {
        toBeSaved.map((d: any) => {
          d.USER_ID = this.userIdLoggedIn;
        });
        this._jDS.contorlLoading(true);
        this._api.saveStandardAmtCovered(toBeSaved).subscribe({
          next: (response: any) => {
            this._securityService.checkRequestKeyResponse(response, () => {
              this._securityService.hasValidCsrfToken(response, () => {
                this._jDS.contorlLoading(false);
                if (response.status === 'SUCCESS') this.onComplete.sac();
                else this.onComplete.sacError();
              });
            });
          },
          error: (data: any) => {
            this._jDS.contorlLoading(false);

            this.onComplete.sacError();
          },
        });
      }
      else
        this.onComplete.sac();
    }
    catch (e) {
    console.error(e);

    }
  }

  private onComplete = {
    sac: () => {
      this.getData.sac();
      this.btns.sac.saveDisabled = true;
      this.showForm = true;
      this._formService.hideFormLoader("sac-maintenance-loading");
      this.openDialog('SUCCESS', 'Standard Amount Covered', 'saved');
    },
    sacError: () => {
      this.showForm = true;
      this.btns.sac.saveDisabled = false;
      this.openDialog('FAILED', 'Standard Amount Covered', 'saving');
      this._formService.hideFormLoader("sac-maintenance-loading");
    },
  }

  openDialog(title: string, contentTitle: string, contentDetail: string) {
    try {
      this._matDialog.open(DialogMsgComponent, {
        disableClose: true,
        width: '512px',
        data: {
          title: title,
          content:
            title === 'SUCCESS'
              ? `${contentTitle} details successfully ${contentDetail}!`
              : `${contentTitle} details ${contentDetail} failed!`,
        },
      });
    }
    catch (e) {
    console.error(e);

    }
  }

  /* Validator Stuffs Down here */

  checkDuplicateTsiAmt(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = parseInt(this._formService.setToNumberWithDecimal(control.value));
      if (!value) return null;
      let hasDuplicate: boolean = this.sacTblConfig.tblData.filter((d: any) => {
        if (!this.rowData)
          return parseInt(d.TSI_AMT) === value; //fix this
        else
          return parseInt(this.rowData.TSI_AMT) !== value && parseInt(d.TSI_AMT) === value;
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupe: true } : null;
    }
  }

  checkHasDefault(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      let hasDefault: boolean = this.sacTblConfig?.tblData.filter((d: any) => {
        if (!this.rowData)
          return d.DEFAULT_TAG === 'Y';
        else
          return parseFloat(this.rowData.TSI_AMT) !== parseFloat(d.TSI_AMT) && d.DEFAULT_TAG === 'Y';
      }).length > 0 ? true : false;
      if (value === 'N' || !hasDefault)
        return null;
      return { hasDefault: true };
    }
  }

  private checkDecimalsExceeded(digits: number, decimals: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value && parseFloat(this._formService.setToNumberWithDecimal(control.value));
      if (!value) return null;
      let isExceeded: boolean = value > +"".padEnd(digits, '9') + +('.' + ''.padEnd(decimals, '9'))
      return isExceeded ? { hasDecimalsExceeded: true } : null;
    }
  }

  public showErrorValidator = {
    sac: () => {
      try {
        Object.keys(this.forms.sacForm.controls).forEach(key => {
          const controlErrors = this.forms.sacForm.get(key)?.errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              if (keyError == 'hasDupe' && controlErrors[keyError] === true)
                this.errorMessage = "Unable to add record. Amount Covered for this Car Type and Peril already exists.";
              else if (keyError == 'hasDefault' && controlErrors[keyError] === true)
                this.errorMessage = "Unable to add record. Default amount already exists. Only one record should be tagged as default.";
              else if (keyError == 'hasDecimalsExceeded' && controlErrors[keyError] === true && key === 'premAmt')
                this.errorMessage = "Invalid value in Premium Amount. The value entered exceeded the maximum limit.";
              else if (keyError == 'hasDecimalsExceeded' && controlErrors[keyError] === true && key === 'tsiAmt')
                this.errorMessage = "Invalid value in Amount Covered. The value entered exceeded the maximum limit.";
              else if (keyError == 'required' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
            });
          }
        });
        this._formService.showFormMsg("sac-error-message", this.errorMessage, "E");
      }
      catch (e) {
    console.error(e);

      }
    },
  }

  allowNumericDigitsOnly(event: any) {
    try {
      if (/[0-9.]/.test(String.fromCharCode(event.keyCode))) {
        return true;
      }
      else {
        event.preventDefault();
        return false;
      }
    }
    catch (e) {
    console.error(e);

      return undefined;
    }
  }

}
