import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogData {
    title:string;
    content:string;
    buttons:any[]
}

@Component({
    templateUrl: './dialog-box.html',
    styleUrls: ['./dialog-box.css']
})
export class DialogBoxComponent {

    constructor (
        public dialogRef: MatDialogRef<DialogBoxComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) { }
}