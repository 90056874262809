<section id="introduction-maint">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>

        <div class="form-content">
            <form [formGroup]="paramForm" class="form">
                <div class="form-container-grid top-form">
                    <div class="field required">
                        <label>Line</label>
                    </div>
                    <div class="lov-search">
                        <input readonly formControlName="lineField">
                        <button matSuffix class="btn btn-search" type="button" (click)="openLineDialog()" [disabled]='lineDisabled' ><mat-icon>search</mat-icon></button>
                    </div>
                </div>
            </form>
        </div>

        <div class='table-intro'>
            <osp-table [tblConfig]="tblConfigIntro" (onRowClick)="onRowClick($event)"></osp-table>
        </div>

        <!-- Loading Animation -->
        <div id="generate-intro-form">
            <div class="form-loader"></div>
        </div>

        <div class="form-content">

            <form [formGroup]="introForm" class="form" *ngIf="noLoadingInProcess">
                <div id="introduction-error-message"></div>
                <div class="form-container-grid">

                    <div class="field required">
                        <label>Subline</label>
                    </div>
                    <div class="lov-search">
                        <input type="text" readonly formControlName="sublineField">
                        <button type="button" class="btn-search btn" (click)="openSublineDialog()" [disabled]='sublineDisabled'>
                            <mat-icon>
                                search
                            </mat-icon>
                        </button>
                    </div>

                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag">
                            <option value="A">Yes</option>
                            <option value="I">No</option>
                        </select>
                    </div>

                    <div class="field">
                        <label>Introduction Title</label>
                    </div>
                    <div class="span">
                        <input type="text" maxlength="100" formControlName="introTitle">
                    </div>

                    <div class="field">
                        <label>Introduction Description</label>
                    </div>
                    <div class="span">
                        <textarea maxlength="4000" formControlName="introDesc"></textarea>
                    </div>

                    <div class="field">
                        <label>Image Path</label>
                    </div>
                    <div class="span">
                        <input type="text" maxlength="500" formControlName="imagePath">
                    </div>

                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input type="text" maxlength="4000" formControlName="remarks"> 
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="userID">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUserUpdate">
                    </div>
                </div>
            </form>

            <div class='btn-row'>
                <button type="button" id='btn-add' *ngIf="!isIntroSelected" title='Register a new introduction' class="btn btn2" value='ADD' type="submit" [disabled]='addUpdDisabled' (click)="insertIntro()">ADD</button>
                <button type="button" id='btn-update' *ngIf="isIntroSelected" title='Update an introduction' class="btn btn2" value='UPDATE' type="submit" [disabled]='addUpdDisabled' (click)='updateIntro()'>UPDATE</button>
                <button type="button" id='btn-delete' title='Remove selected introduction' class="btn btn2" value='DELETE' type="submit" [disabled]='deleteDisabled' (click)='deleteIntro()'>DELETE</button>
            </div> 

            <div class="mtn-title"></div>

            <div class='btn-row'>
                <button type="button" id='btn-save' title='Save changes on introduction' class="btn btn2" value='SAVE' type="submit" [disabled]='saveDisabled' (click)='toSave()'>SAVE</button>
            </div>

        </div>
    </div>

</section>
