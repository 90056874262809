<div class="mtn-container">
    <div class="mtn-title">
        <p>{{ moduleName }}</p>
    </div>
    <div class="outer-wrapper p-datatable mtn-header">
        <!--<div class="p-datatable-header"><span class="header-title">PLAN</span></div> -->
        <div *ngIf="showForm.purpose" class="form-content">
            <form [formGroup]="forms.lineSubLineLovForm" class="form">
                <div class="form-container-grid top-form">
                    <div class="field required">
                        <label>Line</label>
                    </div>
                    <div class="">
                        <input formControlName="lineCdWithDescDisplay">
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov('LINE')" [disabled]='btns.lov.lineDisabled'><mat-icon>search</mat-icon></button>
                    </div>
                    <div class="field required">
                        <label>Subline</label>
                    </div>
                    <div class="">
                        <input formControlName="subLineCdWithDescDisplay">
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov('SUBLINE')" [disabled]='btns.lov.sublineDisabled'><mat-icon>search</mat-icon></button>
                    </div>
                </div>
            </form>
        </div>
        <osp-table [tblConfig]="tblConfig.purpose" (onRowClick)="onRowClick.purpose($event)"></osp-table>
        <div id="purpose-maintenance-loading"><div class="form-loader"></div></div>
        <div *ngIf="showForm.purpose" class="form-content">
            <form [formGroup]="forms.purposeForm" class="form">
                <div id="purpose-error-message"></div>
                <div class="form-container-grid">
                    <div class="field required">
                        <label>Purpose</label>
                    </div>
                    <div class="">
                        <select formControlName="purposeDesc">
                            <option *ngFor="let option of purpose" [value]="option.cd">
                                <pre *ngIf="option.cd != ''">{{option.cd}} &nbsp; - &nbsp; {{option.name}}</pre>
                                <pre *ngIf="option.cd == ''">{{option.cd}}</pre>
                            </option>
                        </select>
                    </div>
                    <!-- <div class="" *ngIf="this.selectedRow">
                        <input formControlName="purposeDesc">
                    </div> -->
                    <div class="field">
                        <label></label>
                    </div>
                    <div class="field">
                    </div>
                    
                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input formControlName="lastUpdateUser" >
                    </div>
                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="lastUpdate" >
                    </div>
                </div>
            </form>
            <div class="btn-grp">
                <button class="btn btn2" type="button" (click)="onAdd.purpose()" [disabled]="btns.purpose.addDisabled" >ADD</button>
                <!-- <button *ngIf="!btns.paytOpt.updateDisabled" class="btn btn2" type="button" (click)="onUpdate.paytOpt()" >UPDATE</button> -->
                <button class="btn btn2" type="button" (click)="onDelete.purpose()" [disabled]='btns.purpose.deleteDisabled'>DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-grp">
                <button class="btn btn2" type="button" (click)="onSave.purpose()" [disabled]='btns.purpose.saveDisabled'>SAVE</button>
            </div>
        </div>
    </div>
</div>
