import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { AgentLovComponent } from '../../common/agent-lov/agent-lov.component';
import { SrcCdLovComponent } from '../../common/src-cd-lov/src-cd-lov.component';

@Component({
  selector: 'src-cd-per-dealership-maintenance',
  templateUrl: './src-cd-per-dealership-maintenance.component.html',
  styleUrls: ['./src-cd-per-dealership-maintenance.component.css']
})
export class SrcCdPerDealershipMaintenanceComponent implements OnInit, LeaveConfirmation {

  moduleId: string = 'BMM185';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc?.toUpperCase() ?? "";

  constructor(
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    public _formService: FormService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService
  ) { 
    this.userIdLoggedIn = this._userDetailService.userId ?? "";
  }

  ngOnInit(): void {
    this.btns.srcCdPerDealership.addDisabled = true;
  }

  canDeactivate(): boolean | Observable<boolean> {
    if(this.tempData!='') {
      return false;
    } else {
      return true;
    }
  }

  /* Boolean Stuffs */

  public btns = {
    srcCdPerDealership: {
      addDisabled: true,
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      srcCdDisabled: true
    }
  }

  public selections = {
    activeTypes: [
      { cd: 'A', name: 'Yes' },
      { cd: 'I', name: 'No' },
    ]
  }

  public showForm: boolean = true;

  private rowData: any;
  private tempDataRow: any;
  private errorMessage: string = "";
  private userIdLoggedIn: string;
  private tempData: any = [];

  public forms = {
    agentLovForm: this.fb.group({
      agentCd: [null],
      agentName: [null],
      agentCdDisp: [{ value: '', disabled: 'true' }],
    }),
    srcCdPerDealershipForm: this.fb.group({
      agentCd: [null],
      srcCd: [null, [Validators.required]],
      srcCdDesc: [null],
      srcCdField: [{ value: '', disabled: 'true' }, [Validators.required]],
      remarks: [null, [Validators.maxLength(4000)]],
      activeTag: ['A', [Validators.required]],
      lastUser: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }]
    }),
    resetSrcCdPerDealershipForm: () => {
      this.forms.srcCdPerDealershipForm.reset();
      this.forms.srcCdPerDealershipForm.patchValue({
        activeTag: 'A',
        agentCd: this.forms.agentLovForm.get('agentCd')?.value
      });
      this.btns.srcCdPerDealership.updateDisabled = true;
      this.btns.srcCdPerDealership.deleteDisabled = true;
      this.btns.srcCdPerDealership.srcCdDisabled = false;
      this._formService.hideFormMsg("src-cd-per-dealership-error-message");
      this.rowData = null;
    },
    resetAgentLovForm: () => {
      this.forms.agentLovForm.reset();
    },
    resetAllForms: () => {
      this.forms.resetAgentLovForm();
      this.forms.resetSrcCdPerDealershipForm();
    }
  }

  private srcCdPerDealershipFormManipulate = {
    dbToForm: (data: any) => {
      return {
        srcCd: data.SRC_CD,
        srcCdDisp: data.SRC_CD_DISP,
        srcCdDesc: data.SRC_CD_DESC,
        srcCdField: data.SRC_CD?.toString().padStart(2, 0) + " - " + data.SRC_CD_DESC,
        activeTag: data.ACTIVE_TAG,
        remarks: data.REMARKS,
        lastUser: data.LAST_USER,
        lastUpdate: data.LAST_UPDATE,
      };
    },
    formToDb: (data: any, mode: any) => {
      return {
        AGENT_CD: data.agentCd,
        SRC_CD: data.srcCd,
        SRC_CD_DISP: data.srcCd?.toString().padStart(2, 0),
        SRC_CD_DESC: data.srcCdDesc,
        SRC_CD_FIELD: data.srcCd + " - " + data.srcCdDesc,
        ACTIVE_TAG: data.activeTag,
        REMARKS: data.remarks,
        LAST_USER: this.userIdLoggedIn,
        LAST_UPDATE: null,
        MODE: mode
      };
    }
  }

  public srcCdPerDealershipTblConfig = {
    cols: [
      {
        key: "SRC_CD_DISP",
        header: "Source Code",
        dataType: "string",
        width: '20%'
      },
      {
        key: "SRC_CD_DESC",
        header: "Source Code Description",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        width: '64px'
      }
    ],
    tblData: [] as any[],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  public openLov = {
    agent: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          moduleId: this.moduleId
        },
      };
      this._matDialog.open(AgentLovComponent, LOVOPTIONS).afterClosed().subscribe((lovData: any) => {
        if (lovData?.button === 'OK') {
          if (lovData?.content?.AGENT_CD) {
            let data = lovData?.content;
            this.forms.agentLovForm.patchValue({
              agentCd: data.AGENT_CD,
              agentName: data.AGENT_NAME,
              agentCdDisp: `${data.AGENT_CD} - ${data.AGENT_NAME}`,
            });
            this.forms.resetSrcCdPerDealershipForm();
            this.getData.srcCdPerDealership();
          }
          else {
            this.forms.agentLovForm.patchValue({
              agentCd: null,
              agentName: null,
              agentCdDisp: null,
            });
          }
        }
      });
    },
    srcCd: () => {
      let srcCdExists: any[] = this.srcCdPerDealershipTblConfig.tblData.map((row: any) => { return +row.SRC_CD; }).filter(Boolean);
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          col: 'CD',
          lpad: 2,
          moduleId: this.moduleId,
          srcCdExclude: srcCdExists
        }
      };
      this._matDialog.open(SrcCdLovComponent, LOVOPTIONS).afterClosed().subscribe((lovData: any) => {
        if (!lovData.option) {
          if (lovData?.content?.SRC_CD) {
            const data = lovData?.content;
            this.forms.srcCdPerDealershipForm.patchValue({
              srcCd: data.SRC_CD,
              srcCdDesc: data.SRC_CD_DESC,
              srcCdField: `${data.SRC_CD?.toString().padStart(2, 0)} - ${data.SRC_CD_DESC}`,
            });
          }
          else {
            this.forms.srcCdPerDealershipForm.patchValue({
              srcCd: null,
              srcCdDesc: null,
              srcCdField: null,
            });
          }
        }
      });
    },
  };

  private getData = {
    srcCdPerDealership: () => {
      try {
        this.srcCdPerDealershipTblConfig.tblData = [];
        this.srcCdPerDealershipTblConfig.loading = true;
        this._jDS.contorlLoading(true);
        this._api.getSrcCdPerDealership({
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          type: "MODULE"
        }).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                let content = JSON.parse(this._jDS.decrypt(data.response));
                this._jDS.contorlLoading(false);
                this.srcCdPerDealershipTblConfig.tblData = content.data.filter((d: any) => 
                d.AGENT_CD == this.forms.agentLovForm.get('agentCd')?.value);
                this.btns.srcCdPerDealership.addDisabled = false;
                this.tempData = [];
                this.btns.srcCdPerDealership.saveDisabled = true;
                this.srcCdPerDealershipTblConfig.loading = false;
              });
            });
          },
          error: (error: any) => {
            this.srcCdPerDealershipTblConfig.loading = false;
            this._jDS.contorlLoading(false);
          }
        });
      }
      catch (e) {
        console.error(e);
      }
    }
  }

  onRowClick(ev: any) {
    try {
      ev.update = true;
      this.rowData = ev;
      this.populateFormFromTable();
    }
    catch (e) {
      this.forms.resetSrcCdPerDealershipForm();
      this.rowData = null;
      //this.indexRow = null;
    }
  }

  populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.srcCdPerDealership.srcCdDisabled = true;
      this.btns.srcCdPerDealership.updateDisabled = !data.update;
      this.btns.srcCdPerDealership.deleteDisabled = !data.temp;
      this.tempDataRow = !data.temp;
      this.forms.srcCdPerDealershipForm.patchValue(this.srcCdPerDealershipFormManipulate.dbToForm(data));
    }
    catch (e) {
      console.error(e);
    }
  }

  public onAdd() {
    try {
      this._formService.hideFormMsg("src-cd-per-dealership-error-message");
      if (this.forms.srcCdPerDealershipForm.valid) {
        let rowToBeAdded: { [key: string]: any } = this.srcCdPerDealershipFormManipulate.formToDb(this.forms.srcCdPerDealershipForm.value, 'I');
        rowToBeAdded.temp = true;
        this.tempData.push(rowToBeAdded);
        this.srcCdPerDealershipTblConfig.tblData = [rowToBeAdded, ...this.srcCdPerDealershipTblConfig.tblData];
        this.forms.resetSrcCdPerDealershipForm();
        this.btns.srcCdPerDealership.saveDisabled = false;
      }
      else {
        this.showErrorValidator.srcCdPerDealership();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public onDelete() {
    try {
      let clickData = this.rowData;
      this.srcCdPerDealershipTblConfig.tblData = this.srcCdPerDealershipTblConfig.tblData.filter((d: any) => {
        return d !== this.rowData;
      });
      this.tempData = this.tempData.filter(
        function(e: any) {
          return e.SRC_CD != clickData.SRC_CD;
        }
      );
      if (this.tempData==null || this.tempData=='') {
        this.btns.srcCdPerDealership.saveDisabled = true;
      }
      this.forms.resetSrcCdPerDealershipForm();
    }
    catch (e) {
      console.error(e);
    }
  }

  public onUpdate() {
    try {
      this._formService.hideFormMsg("src-cd-per-dealership-error-message");
      if (this.forms.srcCdPerDealershipForm.valid) {
        this.forms.srcCdPerDealershipForm.get('srcCdField')?.enable();
        let clickData = this.rowData;
        let toBeUpdatedIndex = this.srcCdPerDealershipTblConfig.tblData.indexOf(this.rowData);
        let srcCdPerDealershipCdRowValue = this.rowData.SRC_CD;
        let rowToBeUpdated: { [key: string]: any } = this.srcCdPerDealershipFormManipulate.formToDb(this.forms.srcCdPerDealershipForm.value, 'U');
        this.forms.srcCdPerDealershipForm.get('srcCdField')?.disable();
        let tempInsertChecker = this.tempData.find(
          (m: { SRC_CD: any }) => 
            m.SRC_CD == clickData.SRC_CD && clickData.MODE == 'I');
        let tempUpdateChecker = this.tempData.find(
          (m: { SRC_CD: any }) => 
            m.SRC_CD == clickData.SRC_CD && clickData.MODE == 'U');
        if (this.rowData.temp) {
          rowToBeUpdated.temp = true;
        }
        else {
          rowToBeUpdated.onDbButUpdatedTemp = true;
          rowToBeUpdated.SRC_CD = srcCdPerDealershipCdRowValue;
        }
        if (tempInsertChecker!=null && tempInsertChecker!='') {
          //tempInsertChecker = rowToBeUpdated;
          tempInsertChecker.ACTIVE_TAG = rowToBeUpdated.ACTIVE_TAG;
          tempInsertChecker.REMARKS = rowToBeUpdated.REMARKS;
          tempInsertChecker.USER_ID = rowToBeUpdated.USER_ID;
          tempInsertChecker.MODE = 'I';
          rowToBeUpdated.MODE = 'I';
        }
        else if (tempUpdateChecker!=null && tempUpdateChecker!='') {
          tempUpdateChecker.ACTIVE_TAG = rowToBeUpdated.ACTIVE_TAG;
          tempUpdateChecker.REMARKS = rowToBeUpdated.REMARKS;
          tempUpdateChecker.USER_ID = rowToBeUpdated.USER_ID;
          tempUpdateChecker.MODE = 'U';
        }
        else { 
          this.tempData.push(rowToBeUpdated);
        }
        this.forms.resetSrcCdPerDealershipForm();
        this.srcCdPerDealershipTblConfig.tblData[toBeUpdatedIndex] = rowToBeUpdated;
        this.btns.srcCdPerDealership.saveDisabled = false;
        this.srcCdPerDealershipTblConfig.tblData = [... this.srcCdPerDealershipTblConfig.tblData];
      }
      else {
        this.showErrorValidator.srcCdPerDealership();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public onSave() {
    try {
      this.btns.srcCdPerDealership.saveDisabled = true;
      this.showForm = false;
      this._formService.showFormLoader(null, "src-cd-per-dealership-maintenance-loading", "Saving.<br>Please wait ...", null, null);
      if (this.tempData.length > 0) {
        this._api.saveSrcCdPerDealership(this.tempData).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                if (data.status === 'SUCCESS') {
                  this.onComplete.srcCdPerDealership();
                }
                else {
                  this.onComplete.srcCdPerDealershipError();
                }
              });
            });
          },
          error: (data: any) => {
            this.onComplete.srcCdPerDealershipError();
          },
        });
      }
      else
        this.onComplete.srcCdPerDealership();
    }
    catch (e) {
      console.error(e);
    }
  }

  private onComplete = {
    srcCdPerDealership: () => {
      this.getData.srcCdPerDealership();
      this.showForm = true;
      this._formService.hideFormLoader("src-cd-per-dealership-maintenance-loading");
      this.forms.resetSrcCdPerDealershipForm();
      this.openDialog('SUCCESS', 'Source Code Per Dealership successfully saved');
    },
    srcCdPerDealershipError: () => {
      this.showForm = true;
      this.btns.srcCdPerDealership.saveDisabled = false;
      this.openDialog('FAILED', 'Source Code Per Dealership saving failed');
      this._formService.hideFormLoader("src-cd-per-dealership-maintenance-loading");
    },
  }

  openDialog(title: any, message: any) {
    try {
      this.showForm = true;
      this._formService.hideFormLoader("src-cd-per-dealership-maintenance-loading");
      this._matDialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        } 
      });
    } catch(e) {
      console.error(e);
    }
  }

  public showErrorValidator = {
    srcCdPerDealership: () => {
      try {
        Object.keys(this.forms.srcCdPerDealershipForm.controls).forEach(key => {
          const controlErrors = this.forms.srcCdPerDealershipForm.get(key)?.errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              if (keyError == 'required' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
            });
          }
        });
        this._formService.showFormMsg("src-cd-per-dealership-error-message", this.errorMessage, "E");
        //this.openDialog('ERROR', this.errorMessage);
        setTimeout(()=>{                           
          this._formService.hideFormMsg("src-cd-per-dealership-error-message");
        }, 10000);
      }
      catch (e) {
        console.error(e);
      }
    },
  }

}
