<section id="dialog">
    <div class="mtn-title">
        {{titleLov}}
    </div>
</section>
<!-- Table Contents -->
<div>
    <osp-table [tblConfig]="classTable" (onRowClick)="onRowClick($event)"></osp-table>

    <div class="btn-grp-2 btn-grp-2-l">
        <button class="btn btn4" type="button" (click)="onClose('BACK')">BACK</button>
        <button class="btn btn2" type="button" (click)="onClose('OK')">OK</button>
    </div>
</div>