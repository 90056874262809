<section id="agency">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <osp-table [tblConfig]="agencyTable" (onRowClick)="onRowClick($event)" [selectedRow]="selectedRowComponent"></osp-table>

        <div id="agency-form"><div class="form-loader"></div></div>

        <div class="form-content" [hidden]="formHider">

            <form [formGroup]="agencyForm" class="form">
                <div id="agency-error-message"></div>
                <div class="form-container-grid">
                    
                    <div class="field required">
                        <label>Agency Code</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="agencyCode" oninput="this.value = this.value.toUpperCase()" maxlength="10">
                    </div>

                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag">
                            <option *ngFor="let option of activeTags" [value]="option.value" [disabled]="option.value == 'L' || option.value == 'T'">
                                {{option.text}}
                            </option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>Agency Name</label>
                    </div>
                    <div class="span">
                        <input formControlName="agencyName" maxlength="10000">
                    </div>

                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" maxlength="4000">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input formControlName="lastUpdateUser">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="lastUpdate">
                    </div>
                </div>
            </form>

            <div class="btn-grp">
                <button type="button" class="btn btn2" [hidden]="!addUpdateFlag" (click)="addRow()">ADD</button>
                <button type="button" class="btn btn2" [hidden]="addUpdateFlag" (click)="onUpdate()">UPDATE</button>
                <button type="button" class="btn btn2" [disabled]="deleteDisabler" (click)="deleteRow()">DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-grp">
                <button type="button" class="btn btn2" [disabled]="saveDisabler" (click)="onSave()">SAVE</button>
            </div>
        </div>
    </div>
</section>