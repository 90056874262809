import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { JsonDataService } from 'src/app/services/json-data.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

@Component({
    selector: 'claim-document-review',
    templateUrl: './claim-document-review.component.html',
    styleUrls: ['./claim-document-review.component.css']
})
export class ClaimDocumentReview implements OnInit {

    userId: string = this.userDetailService.userId;
    moduleId: string = 'BMA006';
    moduleName = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId === 'BMA006' })[0].moduleDesc.toUpperCase();

    constructor(
        private jsonDataService: JsonDataService,
        private userDetailService: UserDetailsService,
    ) { }

    datePipe = new DatePipe('en-us');
    encryptedUrl: string = '';

    ngOnInit(): void {
        try {
            this.encryptedUrl = this.generateEncryptedDate();
        }
        catch (e){

        }
    }

    generateEncryptedDate(): string {
        const currentDate = new Date();
        const datePipe = new DatePipe('en-US');
        const formattedDate = datePipe.transform(currentDate, 'yyyy-MM-dd HH:mm:ss');
        const userName = this.userDetailService.name;
        const hostname = window.location.hostname;
        let baseUrl = '';
    
        // Define base URLs based on hostname
        if (hostname === 'admin.osp-devt.com') {
            baseUrl = 'https://hcc.dev.bpimsapps.com/#/claim-review?session=';
        } else if (hostname === 'osp-test.com') {
            baseUrl = 'https://hcc.uat.bpimsapps.com/#/claim-review?session=';
        } else if (hostname === 'express-protect.com') {
            baseUrl = 'https://homeprotectionclaims.bpims.com/#/claim-review?session=';
        } else {
            console.error('Unknown hostname:', hostname);
            return '';
        }
    
        if (formattedDate && userName) {
            const dataObject = {
                time: formattedDate,
                name: userName
            };
    
            // Encrypt the JSON string
            const encryptedData = this.jsonDataService.encrypt(JSON.stringify(dataObject));
    
            // Create the final URL with the encrypted data
            const url = `${baseUrl}${encryptedData}`;
    
            return url;
        }
    
        return '';
    }

    onButtonClick(): void {
        this.encryptedUrl = this.generateEncryptedDate();
        window.open(this.encryptedUrl, '_blank'); // Optional: directly open the URL in a new tab
    }
    
    
}
