import { Component, Inject, OnInit } from '@angular/core';
import { APICallService } from 'src/app/services/api-call.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContentObserver } from '@angular/cdk/observers';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';


@Component({
  selector: 'country-lov',
  templateUrl: './country-lov.component.html',
  styleUrls: ['./country-lov.component.css']
})
export class CountryLovDialogComponent implements OnInit {

  userTypeModuleId: string = 'BMM046';

  constructor(private apiCallService: APICallService,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<CountryLovDialogComponent>) { }
  
  ngOnInit(): void {
    this.getCountry();
  }


  // Variable Initializer
  issueSourceData: any = [];

  // Issue Source Initializer
  countryTableConfig: any = {
    cols: [
      {
        key: "countryCd",
        header: "Code",
        dataType: "number"
      },
      {
        key: "countryName",
        header: "Country",
        dataType: "string"
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15
  }

  // Gets data for Issue Source Table
  getCountry() {
    try {
      this.countryTableConfig.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.jsonDataService.contorlLoading(false);
      this.countryTableConfig.loading = false;
      let countryList = this.jsonDataService.data.country;
      this.countryTableConfig.tblData = countryList.sort(function(a:any, b:any){
        let aCountryCd = Number(a.countryCd);
        let bCountryCd = Number(b.countryCd);
        return aCountryCd - bCountryCd;
      });
    } 
    catch(e) {
      
    }
  }

  onRowClick(ev: any) {
    try {
      this.data = ev;
    }
    catch(e) {
      
    }
  }

  onClose() {
    try {
      if(this.data=='' || this.data==null){
        this._dialogRef.close({content: ''}); 
      } else {
        this._dialogRef.close({content: this.data, moduleId: this.data.moduleId});
      }
    }
    catch(e) {
      
    }
  }

  onBack() {
    try {
      this._dialogRef.close({content: '', option: 'back'});
    }
    catch(e) {
      
    }
  }

}

