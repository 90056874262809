import { Component, Inject, OnInit } from '@angular/core';
import { APICallService } from 'src/app/services/api-call.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContentObserver } from '@angular/cdk/observers';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';


@Component({
  selector: 'user-level-lov',
  templateUrl: './user-level-lov.component.html',
  styleUrls: ['./user-level-lov.component.css']
})
export class UserLevelLovDialogComponent implements OnInit {

  userTypeModuleId: string = 'BMM182';

  constructor(private apiCallService: APICallService,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<UserLevelLovDialogComponent>) {

    }
  
  ngOnInit(): void {
    this.getUserType();
  }


  // Variable Initializer
  issueSourceData: any = [];

  // Issue Source Initializer
  userTypeTableConfig: any = {
    cols: [
      {
        key: "USER_LEVEL_CD",
        header: "Code",
        dataType: "string"
      },
      {
        key: "USER_LEVEL_DESC",
        header: "Description",
        dataType: "string"
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15
  }

  // Gets data for Issue Source Table
  getUserType() {
    try {
      this.userTypeTableConfig.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.jsonDataService.contorlLoading(false);
      this.userTypeTableConfig.loading = false;
      let userLevelList = this.jsonDataService.data.refCds.filter((e: any) => e.rvDomain=="USER_LEVEL" && !((this.data.userLevelExclude ?? []).includes(e.rvLowValue)));
      this.userTypeTableConfig.tblData = userLevelList.map((d: any) =>{
        d.USER_LEVEL_CD = d.rvLowValue;
        d.USER_LEVEL_DESC = d.rvMeaning;
        return d;
      });
    } 
    catch(e) {
      
    }
  }

  onRowClick(ev: any) {
    try {
      this.data = ev;
    }
    catch(e) {
      
    }
  }

  onClose() {
    try {
      if(this.data=='' || this.data==null){
        this._dialogRef.close({content: ''}); 
      } else {
        this._dialogRef.close({content: this.data, moduleId: this.data.moduleId});
      }
    }
    catch(e) {
      
    }
  }

  onBack() {
    try {
      this._dialogRef.close({content: '', option: 'back'});
    }
    catch(e) {
      
    }
  }

}

