<div class="mtn-container">
    <div class="mtn-title">
        <p>{{ moduleName?.toUpperCase() }}</p>
    </div>
    <osp-table [tblConfig]="blockedVehiclesTblConfig" (onRowClick)="onRowClick($event)" [selectedRow]="selectedRow"></osp-table>
    <div id="blocked-vehicles-maintenance-loading">
        <div class="form-loader"></div>
    </div>
    <div *ngIf="showForm" class="form-content">
        <form [formGroup]="forms.blockedVehiclesForm" class="form">
            <div id="blocked-vehicles-error-message"></div>
            <div class="form-container-grid">
                <div class="field">
                    <label>Vehicle ID</label>
                </div>
                <div class="">
                    <input formControlName="vehicleIdDisp" >
                </div>
                <div class="field required">
                    <label>Active</label>
                </div>
                <div class="">
                    <select formControlName="active">
                        <option *ngFor="let activeType of selections.activeTypes" [value]="activeType.cd">
                            {{activeType.name}}
                        </option>
                    </select>
                </div>
                <div class="field">
                    <label>Plate No.</label>
                </div>
                <div class="">
                    <input formControlName="plateNo" maxlength="10" >
                </div>
                <div class="field">
                    <label>Engine No.</label>
                </div>
                <div class="">
                    <input formControlName="engineNo" maxlength="60" >
                </div>
                <div class="field">
                    <label>Chassis No.</label>
                </div>
                <div class="">
                    <input formControlName="chassisNo" maxlength="60" >
                </div>
                <div class="field">
                    <label>MV File No.</label>
                </div>
                <div class="">
                    <input formControlName="mvFileNo" maxlength="100" >
                </div>
                <div class="field">
                    <label>Policy No.</label>
                </div>
                <div class="">
                    <input formControlName="policyNo" maxlength="50" >
                </div>
                <div class="field">
                    <label>Claim No.</label>
                </div>
                <div class="">
                    <input formControlName="claimNo" maxlength="50" >
                </div>
                <div class="field">
                    <label>Year</label>
                </div>
                <div class="">
                    <input posNumInput (keypress)="allowNumericDigitsOnly($event)" formControlName="year" maxlength="4" >
                </div>
                <div class="field">
                    <label>Make</label>
                </div>
                <div class="">
                    <input formControlName="make" maxlength="50" >
                </div>
                <div class="field">
                    <label>Model</label>
                </div>
                <div class="">
                    <input formControlName="model" maxlength="50" >
                </div>
                <div class="field">
                    <label>Sub-model</label>
                </div>
                <div class="">
                    <input formControlName="subModel" maxlength="50" >
                </div>
                <div class="field">
                    <label>Remarks</label>
                </div>
                <div class="span">
                    <input formControlName="remarks" maxlength="4000" >
                </div>
                <div class="field">
                    <label>Last Update User</label>
                </div>
                <div class="">
                    <input formControlName="lastUpdateUser" >
                </div>
                <div class="field">
                    <label>Last Update</label>
                </div>
                <div class="">
                    <input formControlName="lastUpdate" >
                </div>
            </div>
        </form>
        <div class="btn-grp">
            <button *ngIf="btns.blockedVehicles.updateDisabled" class="btn btn2" type="button" (click)="onAdd()" [disabled]="btns.blockedVehicles.addDisabled" >ADD</button>
            <button *ngIf="!btns.blockedVehicles.updateDisabled" class="btn btn2" type="button" (click)="onUpdate()" >UPDATE</button>
            <button class="btn btn2" type="button" [disabled]='btns.blockedVehicles.deleteDisabled' (click)="onDelete()" >DELETE</button>
        </div>
        <div class="mtn-title"></div>
        <div class="btn-grp">
            <button class="btn btn2" type="button" [disabled]='btns.blockedVehicles.saveDisabled' (click)="onSave()" >SAVE</button>
        </div>
    </div>
</div>
