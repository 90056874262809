import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PageValidationService } from 'src/app/services/page-validation.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

    constructor(
        private userDetailService: UserDetailsService,
        private pageValidationService: PageValidationService,
        private router: Router
    ) {
        this.pageValidationService.validatePageAccessibility();
        // ! old check session
        // if (this.userDetailService.sessionExists()) {
        //     this.router.navigate(['dashboard']);
        // } else {
        //     if (["/home/login", "/home/reset"].indexOf(this.router.url) !== -1) {
        //         //do nothing
        //     } else {
        //         // this.router.navigate(["/home/login"]);
        //     }
        // }
    }

    ngOnInit(): void {
    }

}
