import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe, formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';
import { PlanLovComponent } from '../../common/plan-lov/plan-lov.component';
import { LineWarrClauseLovComponent } from '../../common/line-warr-clause-lov/line-warr-clause-lov.component';
import { ClientGroupLovDialog } from '../../common/client-group-lov-dialog/client-group-lov-dialog.component';

@Component({
  selector: 'warr-clause-per-plan-maintenance',
  templateUrl: './warr-clause-per-plan-maintenance.component.html',
  styleUrls: ['./warr-clause-per-plan-maintenance.component.css']
})
export class WarrClausePerPlanMaintenanceComponent implements OnInit, LeaveConfirmation {

  moduleId: string = 'BMM067';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc?.toUpperCase() ?? "";
  datePipe = new DatePipe('en-us');
  minEffDate: Date = new Date();

  constructor(
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    public _formService: FormService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService
  ) {
    this.userIdLoggedIn = this._userDetailService.userId ?? "";
   }

  ngOnInit(): void {
    this.btns.warrClausePerPlan.addDisabled = true;
    this.forms.warrClausePerPlanForm.disable();
  }

  canDeactivate(): boolean | Observable<boolean> {
    if(this.tempData!='') {
      return false;
    } else {
      return true;
    }
  }

  /* Boolean Stuffs */

  public btns = {
    warrClausePerPlan: {
      addDisabled: true,
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      wcDisabled: false
    },
    lov: {
      lineDisabled: false,
      sublineDisabled: true,
      planDisabled: true
    }
  }

  public showForm: boolean = true;

  private rowData: any;
  private tempDataRow: any;
  private errorMessage: string = "";
  private userIdLoggedIn: string;
  private tempData: any = [];

  public forms = {
    lineSublinePlanLovForm: this.fb.group({
      lineCd: [null],
      lineName: [null],
      lineCdDisp: [{ value: '', disabled: 'true' }],
      sublineCd: [null],
      sublineName: [null],
      sublineCdDisp: [{ value: '', disabled: 'true' }],
      planCd: [null],
      planName: [null],
      planCdDisp: [{ value: '', disabled: 'true' }]
    }),
    warrClausePerPlanForm: this.fb.group({
      lineCd: [null],
      sublineCd: [null],
      planCd: [null],
      wcCd: [null],
      wcTitle: [null],
      wcField: [{ value: '', disabled: 'true' }, [Validators.required]],
      effDateFrom: [{ value: '', disabled: 'true' }, [Validators.required]],
      effDateTo: [null],
      seqNo: [null],
      activeTag: ['A', [Validators.required]],
      groupCd: [null],
      clientCd: [null],
      clientDesc: [null],
      clientField: [null],
      mortAccGrp: [null],
      remarks: [null, [Validators.maxLength(4000)]],
      userID: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }]
    }),
    resetWarrClausePerPlanForm: () => {
      this.forms.warrClausePerPlanForm.reset();
      this.forms.warrClausePerPlanForm.patchValue({
        activeTag: 'A',
        lineCd: this.forms.lineSublinePlanLovForm.get('lineCd')?.value,
        sublineCd: this.forms.lineSublinePlanLovForm.get('sublineCd')?.value,
        planCd: this.forms.lineSublinePlanLovForm.get('planCd')?.value
      });
      this.btns.warrClausePerPlan.updateDisabled = this.btns.warrClausePerPlan.deleteDisabled = true;
      this.btns.warrClausePerPlan.wcDisabled = false;
      this.forms.warrClausePerPlanForm.get('effDateFrom')?.enable();
      this._formService.hideFormMsg("warr-clause-per-plan-error-message");
    },
    resetLineSublineLovForm: () => {
      this.forms.lineSublinePlanLovForm.reset();
    },
    resetAllForms: () => {
      this.forms.resetLineSublineLovForm();
      this.forms.resetWarrClausePerPlanForm();
    }
  }

  private warrClausePerPlanFormManipulate = {
    dbToForm: (data: any) => {
      return {
        lineCd: data.LINE_CD,
        sublineCd: data.SUBLINE_CD,
        planCd: data.PLAN_CD,
        wcCd: data.WC_CD,
        wcTitle: data.WC_TITLE,
        wcField: data.WC_FIELD,
        activeTag: data.ACTIVE_TAG,
        effDateFrom: this.dateFilter(data.EFF_DATE_FROM),
        effDateTo: this.dateFilter(data.EFF_DATE_TO),
        seqNo: data.SEQ_NO,
        groupCd: data.GROUP_CD,
        clientCd: data.CLIENT_CD,
        clientDesc: data.CLIENT_DESC,
        clientField: data.CLIENT_FIELD,
        mortAccGrp: data.MORT_ACC_GRP,
        remarks: data.REMARKS,
        userID: data.USER_ID,
        lastUpdate: data.LAST_UPDATE,
      };
    },
    formToDb: (data: any, mode: any) => {
      return {
        LINE_CD: data.lineCd,
        SUBLINE_CD: data.sublineCd,
        PLAN_CD: data.planCd,
        WC_CD: data.wcCd,
        WC_TITLE: data.wcTitle,
        WC_FIELD: data.wcCd + " - " + data.wcTitle,
        ACTIVE_TAG: data.activeTag,
        EFF_DATE_FROM: this.datePipe.transform(new Date(data.effDateFrom), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
        EFF_DATE_TO: data.effDateTo=='' || data.effDateTo==null ? null : this.datePipe.transform(new Date(data.effDateTo), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
        SEQ_NO: data.seqNo,
        GROUP_CD: data.groupCd,
        CLIENT_CD: data.clientCd,
        CLIENT_DESC: data.clientDesc,
        CLIENT_FIELD: data.clientField,
        MORT_ACC_GRP: data.mortAccGrp,
        REMARKS: data.remarks,
        USER_ID: this.userIdLoggedIn,
        LAST_UPDATE: null,
        MODE: mode
      };
    },
    formToTemp: (data: any, mode: any) => {
      return {
        LINE_CD: data.lineCd,
        SUBLINE_CD: data.sublineCd,
        PLAN_CD: data.planCd,
        WC_CD: data.wcCd,
        WC_TITLE: data.wcTitle,
        WC_FIELD: data.wcCd + " - " + data.wcTitle,
        ACTIVE_TAG: data.activeTag,
        EFF_DATE_FROM: this.datePipe.transform(new Date(data.effDateFrom), 'yyyy-MM-dd'),
        EFF_DATE_TO: data.effDateTo=='' || data.effDateTo==null ? null : this.datePipe.transform(new Date(data.effDateTo), 'yyyy-MM-dd'),
        SEQ_NO: data.seqNo,
        GROUP_CD: data.groupCd,
        CLIENT_CD: data.clientCd,
        CLIENT_DESC: data.clientDesc,
        CLIENT_FIELD: data.clientField,
        MORT_ACC_GRP: data.mortAccGrp,
        REMARKS: data.remarks,
        USER_ID: this.userIdLoggedIn,
        LAST_UPDATE: null,
        MODE: mode
      };
    }
  }

  public warrClausePerPlanTblConfig = {
    cols: [
      {
        key: "WC_CD",
        header: "Code",
        dataType: "string",
        width: '10%'
      },
      {
        key: "WC_TITLE",
        header: "Warranties and Clauses",
        dataType: "string",
        width: '35%'
      },
      {
        key: "EFF_DATE_FROM",
        header: "Effective Date",
        dataType: "date"
      },
      {
        key: "EFF_DATE_TO",
        header: "Expiry Date",
        dataType: "date"
      },
      {
        key: "SEQ_NO",
        header: "Sequence No.",
        dataType: "string",
        width: '15%'
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        width: '64px'
      }
    ],
    tblData: [] as any[],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  public openLov = {
    line: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'LINE',
          moduleId: this.moduleId
        },
      }
      this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button === 'OK') {
          this.forms.resetWarrClausePerPlanForm();
          if (output) {
            this.forms.lineSublinePlanLovForm.patchValue({
              lineCd: output.LINE_CD,
              lineName: output.LINE_NAME,
              lineCdDisp: `${output.LINE_CD} - ${output.LINE_NAME}`,
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null,
              planCd: null,
              planName: null,
              planCdDisp: null,
            });
            this.forms.warrClausePerPlanForm.patchValue({
              lineCd: output.LINE_CD,
              sublineCd: null,
              planCd: null
            });
            this.btns.lov.sublineDisabled = false;
            this.btns.warrClausePerPlan.addDisabled = true;
            this.tempData = [];
          }
          else {
            this.forms.lineSublinePlanLovForm.patchValue({
              lineCd: null,
              lineName: null,
              lineCdDisp: null,
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null,
              planCd: null,
              planName: null,
              planCdDisp: null,
            });
            this.forms.warrClausePerPlanForm.patchValue({
              lineCd: output.LINE_CD,
              sublineCd: null,
              planCd: null
            });
            this.btns.lov.sublineDisabled = true;
            this.btns.warrClausePerPlan.addDisabled = true;
          }
          this.warrClausePerPlanTblConfig.tblData = [];
          this.btns.warrClausePerPlan.saveDisabled = true;
        }
      });
    },
    subline: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'SUBLINE',
          moduleId: this.moduleId
        },
      }
      LOVOPTIONS.data.lineCd = this.forms.lineSublinePlanLovForm.get('lineCd')?.value;
      this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
        let output = dataOutput.content;
        if (output) {
          this.forms.resetWarrClausePerPlanForm();
          this.forms.lineSublinePlanLovForm.patchValue({
            lineCd: output.LINE_CD,
            lineName: output.LINE_NAME,
            lineCdDisp: `${output.LINE_CD} - ${output.LINE_NAME}`,
            sublineCd: output.SUBLINE_CD,
            subLineName: output.SUBLINE_NAME,
            sublineCdDisp: `${output.SUBLINE_CD} - ${output.SUBLINE_NAME}`,
            planCd: null,
            planName: null,
            planCdDisp: null,
          });
          this.forms.warrClausePerPlanForm.patchValue({
            lineCd: output.LINE_CD,
            sublineCd: output.SUBLINE_CD,
            planCd: null,
          });
          this.tempData = [];
          this.btns.lov.planDisabled = false;
        } 
        else {
          this.forms.lineSublinePlanLovForm.patchValue({
            sublineCd: null,
            sublineName: null,
            sublineCdDisp: null,
            planCd: null,
            planName: null,
            planCdDisp: null,
          });
          this.forms.warrClausePerPlanForm.patchValue({
            lineCd: null,
            sublineCd: null,
            planCd: null
          });
          this.btns.warrClausePerPlan.addDisabled = true;
        }
        this.warrClausePerPlanTblConfig.tblData = [];
        this.btns.warrClausePerPlan.saveDisabled = true;
      });
    },
    plan: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'PLAN',
          moduleId: this.moduleId,
          lineCd: this.forms.lineSublinePlanLovForm.get('lineCd')?.value,
          sublineCd: this.forms.lineSublinePlanLovForm.get('sublineCd')?.value,
        },
      };
      this._matDialog.open(PlanLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button?.toUpperCase() === 'OK') {
          this.forms.resetWarrClausePerPlanForm();
          if (output.PLAN_CD) {
            this.forms.lineSublinePlanLovForm.patchValue({
              planCd: output.PLAN_CD,
              planName: output.PLAN_NAME,
              planCdDisp: `${output.PLAN_CD} - ${output.PLAN_NAME}`,
            });
            this.forms.warrClausePerPlanForm.patchValue({
              planCd: output.PLAN_CD
            });
            this.tempData = [];
            this.btns.warrClausePerPlan.addDisabled = false;
          }
          else {
            this.forms.lineSublinePlanLovForm.patchValue({
              planCd: null,
              planName: null,
              planCdDisp: null,
            });
            this.btns.warrClausePerPlan.addDisabled = true;
          }
          this.forms.warrClausePerPlanForm.enable();
          this.getData.warrClausePerPlan();
        }
      });
    },
    wc: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          lineCd: this.forms.lineSublinePlanLovForm.get('lineCd')?.value, 
          moduleId: this.moduleId
        },
      };
      this._matDialog.open(LineWarrClauseLovComponent, LOVOPTIONS).afterClosed().subscribe((lovData: any) => {
        if (lovData.button === 'OK') {
          if (lovData?.content?.WC_CD) {
            const data = lovData?.content;
            this.forms.warrClausePerPlanForm.patchValue({
              wcField: `${data.WC_CD} - ${data.WC_TITLE}`,
              wcCd: data.WC_CD,
              wcTitle: data.WC_TITLE,
            });
          }
          else {
            this.forms.warrClausePerPlanForm.patchValue({
              wcField: null,
              wcCd: null,
              wcTitle: null,
            });
          }
        }
      });
    },
    client: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'CLIENT_GROUP',
          moduleId: this.moduleId,
        },
      };
      this._matDialog.open(ClientGroupLovDialog, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.content) {
          if (output) {
            const data = dataOut?.content;
            this.forms.warrClausePerPlanForm.patchValue({
              clientCd: output.CLIENT_CD,
              clientDesc: output.CLIENT_DESC,
              clientField: `${output.CLIENT_CD} - ${output.CLIENT_DESC}`,
            });
          }
          else {
            this.forms.warrClausePerPlanForm.patchValue({
              clientCd: null,
              clientDesc: null,
              clientField: null,
            });
          }
        }
      });
    },
  };

  private getData = {
    warrClausePerPlan: () => {
      try {
        this.warrClausePerPlanTblConfig.tblData = [];
        this.warrClausePerPlanTblConfig.loading = true;
        this._jDS.contorlLoading(true);
        this._api.getWarrClausePerPlan({
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          type: "MODULE"
        }).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                let content = JSON.parse(this._jDS.decrypt(data.response));
                this._jDS.contorlLoading(false);
                this.warrClausePerPlanTblConfig.tblData = content.data.filter((d: any) => 
                  d.LINE_CD == this.forms.lineSublinePlanLovForm.get('lineCd')?.value 
                  && d.SUBLINE_CD == this.forms.lineSublinePlanLovForm.get('sublineCd')?.value
                  && d.PLAN_CD == this.forms.lineSublinePlanLovForm.get('planCd')?.value);
                this.btns.warrClausePerPlan.addDisabled = false;
                this.warrClausePerPlanTblConfig.loading = false;
              });
            });
          },
          error: (error: any) => {
            this.warrClausePerPlanTblConfig.loading = false;
            this._jDS.contorlLoading(false);
          }
        });
      }
      catch (e) {
        console.error(e);
      }
    },
    groupCodes: () => {
      const filteredMag = this._jDS.data.refCds.filter((data: any) => data.rvDomain === 'BMM067.GROUP_CD').map((data: any) => { return { cd: data.rvLowValue, name: data.rvMeaning } });
      return filteredMag;
    },
    mortAccGrps: () => {
      const filteredMag = this._jDS.data.refCds.filter((data: any) => data.rvDomain === 'MORT_ACC_GRP').map((data: any) => { return { cd: data.rvLowValue, name: data.rvMeaning } });
      return filteredMag;
    }
  }

  public selections = {
    activeTypes: [
      { cd: 'A', name: 'Yes' },
      { cd: 'I', name: 'No' },
    ],
    groupCodes: [
      { cd: null, name: '' },
      ...this.getData.groupCodes()
    ],
    mortAccGrps: [
      { cd: null, name: '' },
      ...this.getData.mortAccGrps()
    ],
  }

  onRowClick(ev: any) {
    try {
      ev.update = true;
      this.rowData = ev;
      this.populateFormFromTable();
    }
    catch (e) {
      this.forms.resetWarrClausePerPlanForm();
      this.rowData = null;
    }
  }

  populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.warrClausePerPlan.wcDisabled = true;
      this.forms.warrClausePerPlanForm.get('effDateFrom')?.disable();
      this.btns.warrClausePerPlan.updateDisabled = !data.update;
      this.btns.warrClausePerPlan.deleteDisabled = !data.temp;
      this.tempDataRow = !data.temp;
      this.forms.warrClausePerPlanForm.patchValue(this.warrClausePerPlanFormManipulate.dbToForm(data));
    }
    catch (e) {
      console.error(e);
    }
  }

  public onAdd() {
    try {
      this._formService.hideFormMsg("warr-clause-per-plan-error-message");
      if (!this.effDateToCheck()) {
        this._formService.showFormMsg("warr-clause-per-plan-error-message", "Effectivity Date To cannot be earlier than Effective Date From. Please enter a different Date of Effectivity.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("warr-clause-per-plan-error-message");
        }, 10000);
      }
      else if (!this.effDateFromCheck('I')) {
        this._formService.showFormMsg("warr-clause-per-plan-error-message", "Date of Effectivity cannot be concurrent with another record under the same warranties and clauses. Please enter a different Date of Effectivity.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("warr-clause-per-plan-error-message");
        }, 10000);
      }
      else if (this.forms.warrClausePerPlanForm.valid) {
        let rowToBeAdded: { [key: string]: any } = this.warrClausePerPlanFormManipulate.formToDb(this.forms.warrClausePerPlanForm.value, 'I');
        let rowToBeAddedTemp: { [key: string]: any } = this.warrClausePerPlanFormManipulate.formToTemp(this.forms.warrClausePerPlanForm.value, 'I');
        rowToBeAdded.temp = true;
        this.tempData.push(rowToBeAddedTemp);
        this.warrClausePerPlanTblConfig.tblData = [rowToBeAdded, ...this.warrClausePerPlanTblConfig.tblData];
        this.forms.resetWarrClausePerPlanForm();
        this.btns.warrClausePerPlan.saveDisabled = false;
      }
      else {
        this.showErrorValidator.warrClausePerPlan();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public onDelete() {
    try {
      let clickData = this.rowData;
      clickData.EFF_DATE_FROM = this.datePipe.transform(new Date(clickData.EFF_DATE_FROM), 'yyyy-MM-dd');
      clickData.EFF_DATE_TO = clickData.EFF_DATE_TO=='' || clickData.EFF_DATE_TO==null ? null : this.datePipe.transform(new Date(clickData.EFF_DATE_TO), 'yyyy-MM-dd');
      this.warrClausePerPlanTblConfig.tblData = this.warrClausePerPlanTblConfig.tblData.filter((d: any) => {
        return d !== this.rowData;
      });
      this.tempData = this.tempData.filter(
        function(e: any) {
          return e.WC_CD != clickData.WC_CD || e.EFF_DATE_FROM != clickData.EFF_DATE_FROM || e.EFF_DATE_TO != clickData.EFF_DATE_TO;
        }
      );
      this.forms.resetWarrClausePerPlanForm();
    }
    catch (e) {
      console.error(e);
    }
  }

  public onUpdate() {
    try {
      this._formService.hideFormMsg("warr-clause-per-plan-error-message");
      if (!this.effDateToCheck()) {
        this._formService.showFormMsg("warr-clause-per-plan-error-message", "Effectivity Date To cannot be earlier than Effective Date From. Please enter a different Date of Effectivity.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("warr-clause-per-plan-error-message");
        }, 10000);
      }
      else if (!this.effDateFromCheck('U')) {
        this._formService.showFormMsg("warr-clause-per-plan-error-message", "Date of Effectivity cannot be concurrent with another record under the same warranties and clauses. Please enter a different Date of Effectivity.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("warr-clause-per-plan-error-message");
        }, 10000);
      }
      else if (this.forms.warrClausePerPlanForm.valid) {
        this.forms.warrClausePerPlanForm.get('effDateFrom')?.enable();
        let clickData = this.rowData;
        clickData.EFF_DATE_FROM = this.datePipe.transform(new Date(clickData.EFF_DATE_FROM), 'yyyy-MM-dd');
        clickData.EFF_DATE_TO = clickData.EFF_DATE_TO=='' || clickData.EFF_DATE_TO==null ? null : this.datePipe.transform(new Date(clickData.EFF_DATE_TO), 'yyyy-MM-dd');
        let toBeUpdatedIndex = this.warrClausePerPlanTblConfig.tblData.indexOf(this.rowData);
        let warrClausePerPlanCdRowValue = this.rowData.WC_CD;
        let rowToBeUpdated: { [key: string]: any } = this.warrClausePerPlanFormManipulate.formToDb(this.forms.warrClausePerPlanForm.value, 'U');
        let rowToBeUpdatedTemp: { [key: string]: any } = this.warrClausePerPlanFormManipulate.formToTemp(this.forms.warrClausePerPlanForm.value, 'U');
        this.forms.warrClausePerPlanForm.get('effDateFrom')?.disable();
        let tempInsertChecker = this.tempData.find(
          (m: { WC_CD: any, EFF_DATE_FROM: any, EFF_DATE_TO: any }) => 
            m.WC_CD == clickData.WC_CD && m.EFF_DATE_FROM == clickData.EFF_DATE_FROM && clickData.MODE == 'I');
        let tempUpdateChecker = this.tempData.find(
          (m: { WC_CD: any, EFF_DATE_FROM: any, EFF_DATE_TO: any }) => 
            m.WC_CD == clickData.WC_CD && m.EFF_DATE_FROM == clickData.EFF_DATE_FROM && clickData.MODE == 'U');
        if (this.rowData.temp) {
          rowToBeUpdated.temp = true;
        }
        else {
          rowToBeUpdated.onDbButUpdatedTemp = true;
          rowToBeUpdated.WC_CD = warrClausePerPlanCdRowValue;
        }
        if (tempInsertChecker!=null && tempInsertChecker!='') {
          //tempInsertChecker = rowToBeUpdated;
          tempInsertChecker.ACTIVE_TAG = rowToBeUpdatedTemp.ACTIVE_TAG;
          tempInsertChecker.EFF_DATE_TO = rowToBeUpdatedTemp.EFF_DATE_TO;
          tempInsertChecker.SEQ_NO = rowToBeUpdatedTemp.SEQ_NO;
          tempInsertChecker.GROUP_CD = rowToBeUpdatedTemp.GROUP_CD;
          tempInsertChecker.CLIENT_CD = rowToBeUpdatedTemp.CLIENT_CD;
          tempInsertChecker.CLIENT_DESC = rowToBeUpdatedTemp.CLIENT_DESC;
          tempInsertChecker.CLIENT_FIELD = rowToBeUpdatedTemp.CLIENT_FIELD;
          tempInsertChecker.MORT_ACC_GRP = rowToBeUpdatedTemp.MORT_ACC_GRP;
          tempInsertChecker.REMARKS = rowToBeUpdatedTemp.REMARKS;
          tempInsertChecker.USER_ID = rowToBeUpdatedTemp.USER_ID;
          tempInsertChecker.MODE = 'I';
          rowToBeUpdated.MODE = 'I';
          rowToBeUpdatedTemp.MODE = 'I';
        }
        else if (tempUpdateChecker!=null && tempUpdateChecker!='') {
          tempUpdateChecker.ACTIVE_TAG = rowToBeUpdatedTemp.ACTIVE_TAG;
          tempUpdateChecker.EFF_DATE_TO = rowToBeUpdatedTemp.EFF_DATE_TO;
          tempUpdateChecker.SEQ_NO = rowToBeUpdatedTemp.SEQ_NO;
          tempUpdateChecker.GROUP_CD = rowToBeUpdatedTemp.GROUP_CD;
          tempUpdateChecker.CLIENT_CD = rowToBeUpdatedTemp.CLIENT_CD;
          tempUpdateChecker.CLIENT_DESC = rowToBeUpdatedTemp.CLIENT_DESC;
          tempUpdateChecker.CLIENT_FIELD = rowToBeUpdatedTemp.CLIENT_FIELD;
          tempUpdateChecker.MORT_ACC_GRP = rowToBeUpdatedTemp.MORT_ACC_GRP;
          tempUpdateChecker.REMARKS = rowToBeUpdatedTemp.REMARKS;
          tempUpdateChecker.USER_ID = rowToBeUpdatedTemp.USER_ID;
          tempUpdateChecker.MODE = 'U';
        }
        else { 
          this.tempData.push(rowToBeUpdatedTemp);
        }
        this.forms.resetWarrClausePerPlanForm();
        this.warrClausePerPlanTblConfig.tblData[toBeUpdatedIndex] = rowToBeUpdated;
        this.btns.warrClausePerPlan.saveDisabled = false;
        this.warrClausePerPlanTblConfig.tblData = [... this.warrClausePerPlanTblConfig.tblData];
      }
      else {
        this.showErrorValidator.warrClausePerPlan();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public onSave() {
    try {
      this.btns.warrClausePerPlan.saveDisabled = true;
      this.showForm = false;
      this._formService.showFormLoader(null, "warr-clause-per-plan-maintenance-loading", "Saving.<br>Please wait ...", null, null);
      if (this.tempData.length > 0) {
        this._api.saveWarrClausePerPlan(this.tempData).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                if (data.status === 'SUCCESS') {
                  this.onComplete.warrClausePerPlan();
                }
                else {
                  this.onComplete.warrClausePerPlanError();
                }
              });
            });
          },
          error: (data: any) => {
            this.onComplete.warrClausePerPlanError();
          },
        });
      }
      else
        this.onComplete.warrClausePerPlan();
    }
    catch (e) {
      console.error(e);
    }
  }

  private onComplete = {
    warrClausePerPlan: () => {
      this.tempData = [];
      this.getData.warrClausePerPlan();
      this.btns.warrClausePerPlan.saveDisabled = true;
      this.showForm = true;
      this._formService.hideFormLoader("warr-clause-per-plan-maintenance-loading");
      this.forms.resetWarrClausePerPlanForm();
      this.openDialog('SUCCESS', 'Warranties and Clauses per Plan details successfully saved');
    },
    warrClausePerPlanError: () => {
      this.showForm = true;
      this.btns.warrClausePerPlan.saveDisabled = false;
      this.openDialog('FAILED', 'Warranties and Clauses per Plan details saving failed');
      this._formService.hideFormLoader("warr-clause-per-plan-maintenance-loading");
    },
  }

  openDialog(title: any, message: any) {
    try {
      this.showForm = true;
      this._formService.hideFormLoader("warr-clause-per-plan-maintenance-loading");
      this._matDialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        } 
      });
    } catch(e) {
      console.error(e);
    }
  }

  effDateToCheck() {
    let effDateFromTemp = this.datePipe.transform(new Date(this.forms.warrClausePerPlanForm.get('effDateFrom')?.value), 'yyyy-MM-dd');
    let effDateToTemp = this.forms.warrClausePerPlanForm.get('effDateTo')?.value=='' || this.forms.warrClausePerPlanForm.get('effDateTo')?.value==null ? null : this.datePipe.transform(new Date(this.forms.warrClausePerPlanForm.get('effDateTo')?.value), 'yyyy-MM-dd');
  
    if(effDateToTemp!=null && effDateFromTemp!=null) {
      if(effDateToTemp>effDateFromTemp) {
        return true;
      }
      else {
        return false;
      }
    } else {
      return true;
    }
  }

  effDateFromCheck(mode: any) {
    if (mode === 'U') {
      this.forms.warrClausePerPlanForm.get('effDateFrom')?.enable();
    }
    let wcCdTemp = this.forms.warrClausePerPlanForm.get('wcCd')?.value;
    let effDateFromTemp = this.datePipe.transform(new Date(this.forms.warrClausePerPlanForm.get('effDateFrom')?.value), 'yyyy-MM-dd');
    let effDateToTemp = this.forms.warrClausePerPlanForm.get('effDateTo')?.value=='' || this.forms.warrClausePerPlanForm.get('effDateTo')?.value==null ? null : this.datePipe.transform(new Date(this.forms.warrClausePerPlanForm.get('effDateTo')?.value), 'yyyy-MM-dd');
    let effDateFromField: any;
    let resultCheck: boolean = true;
    if (mode === 'I') {
      effDateFromField = this.warrClausePerPlanTblConfig.tblData.filter(function(e: any) {
        return e.WC_CD==wcCdTemp;
      });
    }
    else if (mode === 'U') {
      let effDateFromTemp2 = new Date(this.forms.warrClausePerPlanForm.get('effDateFrom')?.value);
      effDateFromField = this.warrClausePerPlanTblConfig.tblData.filter(function(e: any) {
        return e.WC_CD==wcCdTemp && (new Date(e.EFF_DATE_FROM).getMonth()!==effDateFromTemp2.getMonth() || new Date(e.EFF_DATE_FROM).getDate()!==effDateFromTemp2.getDate() || new Date(e.EFF_DATE_FROM).getFullYear()!==effDateFromTemp2.getFullYear());
      });
    }
    for (let r of effDateFromField) {
      let effDateFromTbl = this.datePipe.transform(new Date(r.EFF_DATE_FROM), 'yyyy-MM-dd');
      let effDateToTbl = r.EFF_DATE_TO=='' || r.EFF_DATE_TO==null ? null : this.datePipe.transform(new Date(r.EFF_DATE_TO), 'yyyy-MM-dd');
      //nope is return false
      if (!effDateToTbl && !effDateToTemp) {
        resultCheck = false;
        break;
      } 
      else if (!effDateToTbl && effDateToTemp && effDateFromTemp && effDateFromTbl) {
        if (effDateFromTemp >= effDateFromTbl) {
          resultCheck = false;
          break;
        } 
        else if (effDateFromTemp < effDateFromTbl && effDateToTemp >= effDateFromTbl) {
          resultCheck = false;
          break;
        }
      }
      else if (!effDateToTemp && effDateFromTemp && effDateFromTbl && effDateToTbl) {
        if (effDateFromTemp <= effDateToTbl) {
          resultCheck = false;
          break;
        }
      }
      else if (effDateToTemp && effDateFromTemp && effDateFromTbl && effDateToTbl) {
        if (effDateToTemp >= effDateFromTbl && effDateToTemp <= effDateToTbl) {
          resultCheck = false;
          break;
        }
        else if (effDateFromTemp >= effDateFromTbl && effDateFromTemp <= effDateToTbl) {
          resultCheck = false;
          break;
        }
        else if (effDateFromTemp >= effDateFromTbl && effDateToTemp <= effDateToTbl) {
          resultCheck = false;
          break;
        }
        else if (effDateFromTemp <= effDateFromTbl && effDateToTemp >= effDateToTbl) {
          resultCheck = false;
          break;
        }
      }
      else {
        resultCheck = false;
      }
    }
    if (mode === 'U') {
      this.forms.warrClausePerPlanForm.get('effDateFrom')?.disable();
    }
    return resultCheck;
  }

  public showErrorValidator = {
    warrClausePerPlan: () => {
      try {
        Object.keys(this.forms.warrClausePerPlanForm.controls).forEach(key => {
          const controlErrors = this.forms.warrClausePerPlanForm.get(key)?.errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              if (keyError == 'hasDupe' && controlErrors[keyError] === true)
                this.errorMessage = "Date of Effectivity cannot be concurrent with another record under the same warranties and clauses. Please enter a different Date of Effectivity.";
              else if (keyError == 'matDatepickerParse')
                this.errorMessage = "Invalid date format. Kindly input as MM/DD/YYYY";
              else if (keyError == 'required' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
            });
          }
        });
        this._formService.showFormMsg("warr-clause-per-plan-error-message", this.errorMessage, "E");
        //this.openDialog('ERROR', this.errorMessage);
        setTimeout(()=>{                           
          this._formService.hideFormMsg("warr-clause-per-plan-error-message");
        }, 10000);
      }
      catch (e) {
        console.error(e);
      }
    },
  }

  dateFilter(dateString: any) {
    try {
      if (dateString=='' || dateString==null) {
        return '';
      }
      else if (dateString=='0000-00-00' || dateString=='0000-00-00 12:00:00 AM' || dateString=='1970-01-01') {
        return '';
      }
      else {
        return formatDate(dateString, 'yyyy-MM-dd', 'en');
      }
    }
    catch(e) {
      return '';
    }
  }

  integerFormat(fieldName:string){
    try {
      if(this.forms.warrClausePerPlanForm.get(fieldName)?.value!==''&&this.forms.warrClausePerPlanForm.get(fieldName)?.value!==null){
        let fieldFloat = parseFloat(this.forms.warrClausePerPlanForm.get(fieldName)?.value)
        if(isNaN(fieldFloat)){
          this.forms.warrClausePerPlanForm.get(fieldName)?.setValue('');
        }
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  allowNumericDigitsOnlyWODecimal(event: any) {
    try {
      if (/[0-9]/.test(String.fromCharCode(event.keyCode))) {
        return true;
      }
      else {
        event.preventDefault();
        return false;
      }
    }
    catch (e) {
      console.error(e);
      return undefined; 
    }
  }

  allowNumericAndSlashOnly(event: any) {
    try {
      if (/^([0-9/]+)$/.test(String.fromCharCode(event.keyCode))) {
        return true;
      }
      else {
        event.preventDefault();
        return false;
      }
    }
    catch (e) {
      console.error(e);
      return undefined; 
    }
  }
}
