<section id="email-content-maint">    
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <div class="form-content">
            <form [formGroup]="forms.lineSublineLovForm" class="form">
                <div class="form-container-grid top-form">
                    <div class="field required">
                        <label>Line</label>
                    </div>
                    <div class="">
                        <input formControlName="lineCdDisp" >
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['line']()" 
                        [disabled]='btns.lov.lineDisabled'>
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                    <div class="field required">
                        <label>Subline</label>
                    </div>
                    <div class="">
                        <input formControlName="sublineCdDisp" >
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['subline']()" 
                        [disabled]='btns.lov.sublineDisabled'>
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <!-- Container for Email Content Table using prime ng -->
        <div class = 'table-user'>
            <osp-table [tblConfig]="emailContentTblConfig" (onRowClick)="onRowClick($event)"></osp-table>
        </div>
        <!-- Loading Animation -->
        <div id="email-content-maintenance-loading">
            <div class="form-loader"></div>
        </div>
        <div class="form-content" *ngIf="showForm">
            <form [formGroup]="forms.emailContentForm" class="form">
                <div id="email-content-error-message"></div>
                <div class="form-container-grid">
                    <div class="field required">
                        <label>Email Type</label>
                    </div>
                    <div class="">
                        <select formControlName="emailType">
                            <option *ngFor="let email of emailTypeList" [value]="email.cd">
                                {{email.name}}
                            </option>
                        </select>
                    </div>
                    <div></div>
                    <div></div>

                    <div class="field">
                        <label>Email Subject</label>
                    </div>
                    <div class="">
                        <input formControlName="emailSubject" type="text">
                    </div>
                    <div class="field">
                        <label>Header Image Path</label>
                    </div>
                    <div class="">
                        <input formControlName="headerImgPath" type="text">
                    </div>

                    <div class="field">
                        <label>Email Body</label>
                    </div>
                    <div class="span">
                        <textarea maxlength="4000" formControlName="emailBody"></textarea>
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUpdateUser">
                    </div>
                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUpdate">
                    </div>
                </div>
            </form>
            <div class="btn-row">
                <button *ngIf="btns.emailContent.updateDisabled" class="btn btn2" type="button" [disabled]='btns.emailContent.addDisabled' (click)="onAdd()">ADD</button>
                <button *ngIf="!btns.emailContent.updateDisabled" class="btn btn2" type="button" (click)="onUpdate()">UPDATE</button>
                <button class="btn btn2" type="button" [disabled]='btns.emailContent.updateDisabled' (click)="onDelete()"> DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-row">
                <button class="btn btn2" type="button" [disabled]='btns.emailContent.saveDisabled' (click)="onSave()">SAVE</button>
            </div>
        </div>
    </div>
</section>