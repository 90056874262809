import { Component, HostListener } from '@angular/core';

@Component({
    selector: 'app-message',
    templateUrl: './app-message.html'
})
export class AppMessageComponent {

    isSticky:boolean = false;

    @HostListener("document:scroll")
    scrollFunction() {
        if (document.documentElement.scrollTop >= 60) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }
}