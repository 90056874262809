import { Component, OnInit } from '@angular/core';

import { APICallService } from 'src/app/services/api-call.service'
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { AppMessageService } from 'src/app/services/app-message.service';

import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';

import { Observable } from 'rxjs';

import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { CheckLovComponent } from '../../common/guard/check-lov/check-lov.component';

@Component({
  selector: 'faq-subline-maintenance',
  templateUrl: './faq-subline-maintenance.component.html',
  styleUrls: ['./faq-subline-maintenance.component.css']
})
export class FaqSublineMaintenanceComponent implements OnInit, LeaveConfirmation {

  datePipe = new DatePipe('en-us');

  addUpdDisabled : boolean = true;
  saveDisabled : boolean = true;
  deleteDisabled : boolean = true;
  sublineDisabled : boolean = true;
  tranPosted : boolean = false;
  noLoadingInProcess : boolean = true;
  validated : boolean = false;
  submitted : boolean = false;
  isFAQSelected : boolean = false;
  isSuccessSaved : boolean = false;
  moduleId: string = 'BMM075';
  moduleName = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId === 'BMM075' })[0].moduleDesc.toUpperCase();
  tableDataBeforeSaving: any;

  sublineField: string = '';
  currentUser: string = this.userDetailService.userId;
  rowClickedData: any;

  effDate = new FormControl(new Date());

  selectedLine: any = {
    LINE_CD: '',
    LINE_NAME: '',
    LINE_FIELD: ''
  };

  selectedSubline: any = {
    LINE_CD: '',
    LINE_NAME: '',
    LINE_FIELD: '',
    SUBLINE_CD: '',
    SUBLINE_NAME: '',
    SUBLINE_FIELD: ''
  };

  tempAddedData: any = [];
  tempUpdateData: any = [];

  paramForm = this.fb.group({
    lineCd: [null],
    lineName: [null],
    lineField: [null],
    sublineCd: [null],
    sublineName: [null],
    sublineField: [null]
  });

  faqSublineForm = this.fb.group({
    lineCd: [null],
    sublineCd: [null],
    questionNo: [null],
    questionNum: [null],
    sortSeq: [null],
    activeTag: ['A', [Validators.required]],
    question: [null, [Validators.required]],
    answer: [null, [Validators.required]],
    remarks: [null],
    userID: [null],
    lastUserUpdate: [null],
    mode: [null]
  });

  constructor(
    private _APICallService: APICallService, 
    public _Dialog: MatDialog, 
    private fb: FormBuilder, 
    private formService: FormService, 
    private appMessageService: AppMessageService,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService,
    private userDetailService: UserDetailsService
  ) { }

  canDeactivate(): Observable<boolean> | boolean {
    try {
      if(this.tempAddedData!='' || this.tempUpdateData!='') {
        return false;
      } else {
        return true;
      }
    } catch(e) {
      return true;
    }
  }

  tblConfigFAQSubline: any = {
    cols: [
      {
        key: "QUESTION_NUM",
        header: "Question No.",
        dataType: "string",
        width: '14%'
      },
      {
        key: "QUESTION",
        header: "Question",
        dataType: "string",
        disabled: false
      },
      {
        key: "ANSWER",
        header: "Answer",
        dataType: "string",
        disabled: false,
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        disabled: true,
        width: '55px'
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15
  }

  ngOnInit(): void {
  }

  initOnGet(): void {
    try {
      this._APICallService.refreshNeeded$
              .subscribe(() => {
                this.getAllFAQSubline();
            });
      this.getAllFAQSubline();
    }
    catch(e) {
      
    }
  }

  checkOpenLineDialog() {
    try {
      if(this.tempAddedData!='' || this.tempUpdateData!='') {
        this._Dialog.open(CheckLovComponent, {
          disableClose: true,
          width: '512px',
          data: {
              title: "Confirmation Message",
              content:
                  "You have unsaved changes. Do you want to continue?"
          }
        }).afterClosed().subscribe((a:any) => {
            if (a) {
              this.tempAddedData = [];
              this.tempUpdateData = [];
              this.tblConfigFAQSubline.tblData = [];
              this.openLineDialog();
            }
        });
      } else {
        this.openLineDialog();
      }
    } catch(e) {
      
    }
  }

  openLineDialog() {
    try {
      this._Dialog.open(LineLovDialogComponent, {
        width: '512px',
        disableClose: true,
        data: {
          table: 'LINE',
          moduleId: this.moduleId
        }
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedLine = data.content;
          this.paramForm.get('lineCd')?.setValue(this.selectedLine.LINE_CD==null || this.selectedLine.LINE_CD=='' ? '' : this.selectedLine.LINE_CD);
          this.paramForm.get('lineName')?.setValue(this.selectedLine.LINE_CD==null || this.selectedLine.LINE_CD=='' ? '' : this.selectedLine.LINE_NAME);
          this.paramForm.get('lineField')?.setValue(this.selectedLine.LINE_CD==null || this.selectedLine.LINE_CD=='' ? '' : this.selectedLine.LINE_CD + " - " + this.selectedLine.LINE_NAME);
          this.paramForm.get('sublineCd')?.setValue(null);
          this.paramForm.get('sublineName')?.setValue(null);
          this.paramForm.get('sublineField')?.setValue(null);
          this.resetFormValues();
        }
      });
    } catch(e) {
      
    }
  }

  checkOpenSublineDialog() {
    try {
      if(this.tempAddedData!='' || this.tempUpdateData!='') {
        this._Dialog.open(CheckLovComponent, {
          disableClose: true,
          width: '512px',
          data: {
              title: "Confirmation Message",
              content:
                  "You have unsaved changes. Do you want to continue?"
          }
        }).afterClosed().subscribe((a:any) => {
            if (a) {
              this.tempAddedData = [];
              this.tempUpdateData = [];
              this.tblConfigFAQSubline.tblData = [];
              this.openSublineDialog();
            }
        });
      } else {
        this.openSublineDialog();
      }
    } catch(e) {
      
    }
  }

  openSublineDialog() {
    try {
      this._Dialog.open(LineLovDialogComponent, {
        width: '512px',
        disableClose: true,
        data: {
          table: 'SUBLINE',
          lineCd: this.paramForm.get('lineCd')?.value,
          moduleId: this.moduleId
        }
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedSubline = data.content;
          this.paramForm.get('lineCd')?.setValue(this.selectedSubline.LINE_CD==null || this.selectedSubline.LINE_CD=='' ? '' : this.selectedSubline.LINE_CD);
          this.paramForm.get('lineName')?.setValue(this.selectedSubline.LINE_CD==null || this.selectedSubline.LINE_CD=='' ? '' : this.selectedSubline.LINE_NAME);
          this.paramForm.get('lineField')?.setValue(this.selectedSubline.LINE_CD==null || this.selectedSubline.LINE_CD=='' ? '' : this.selectedSubline.LINE_CD + " - " + this.selectedSubline.LINE_NAME);
          this.paramForm.get('sublineCd')?.setValue(this.selectedSubline.SUBLINE_CD==null || this.selectedSubline.SUBLINE_CD=='' ? '' : this.selectedSubline.SUBLINE_CD);
          this.paramForm.get('sublineName')?.setValue(this.selectedSubline.SUBLINE_CD==null || this.selectedSubline.SUBLINE_CD=='' ? '' : this.selectedSubline.SUBLINE_NAME);
          this.paramForm.get('sublineField')?.setValue(this.selectedSubline.SUBLINE_CD==null || this.selectedSubline.SUBLINE_CD=='' ? '' : this.selectedSubline.SUBLINE_CD + " - " + this.selectedSubline.SUBLINE_NAME);
          this.initOnGet();
        }
      });
    } catch(e) {
      
    }
  }

  getAllFAQSubline() {
    try {
      if (this.selectedSubline.LINE_CD!=null && this.selectedSubline.LINE_CD!=''
        && this.selectedSubline.SUBLINE_CD!=null && this.selectedSubline.SUBLINE_CD!='')
        {
          this.tblConfigFAQSubline.loading = true;
          this.jsonDataService.contorlLoading(true);
          this._APICallService.getFAQSubline({
            moduleId: this.moduleId,
            userId: this.userDetailService.userId,
            type: "MODULE"
          }).subscribe((data: any) => {
            this.securityService.checkRequestKeyResponse(data, () => {
              this.securityService.hasValidCsrfToken(data, () => {
                if (data.status === 'SUCCESS') {
                  data = JSON.parse(this.jsonDataService.decrypt(data.response));
                  this.tblConfigFAQSubline.loading = false;
                  this.jsonDataService.contorlLoading(false);
                  this.tblConfigFAQSubline.tblData = [...data.data.filter((data: any) => data.LINE_CD == this.selectedSubline.LINE_CD && data.SUBLINE_CD == this.selectedSubline.SUBLINE_CD)];
                  this.tableDataBeforeSaving = this.tblConfigFAQSubline.tblData;
                  this.addUpdDisabled = false;
                  this.resetFormValues();
                } else {
                    this.jsonDataService.contorlLoading(false);
                    this.tblConfigFAQSubline.loading = false;
                    this.appMessageService.showAppMessage(data.message, "error");
                }
              });
            });
          });
        }
    }
    catch (e) {
    console.error(e);
      
    }
  }

  insertFAQSubline() {
    try {
      this.tranPosted = true;
      this.validateInsert();
      if (this.validated) {
        this.addFormToTable();
      }
    }
    catch(e) {
      
    }
  }

  addFormToTable() {
    try {
      this.faqSublineForm.patchValue({
        activeTag: this.faqSublineForm.get('activeTag')?.value,
        userID: this.currentUser,
        lastUserUpdate: new Date(),
      });
      let formToData: any = { // this.datePipe.transform(new Date(), 'MM/dd/yyyy')
        QUESTION_NO: this.faqSublineForm.get('questionNo')?.value,
        LINE_CD: this.paramForm.get('lineCd')?.value,
        SUBLINE_CD: this.paramForm.get('sublineCd')?.value,
        SORT_SEQ: this.faqSublineForm.get('sortSeq')?.value,
        ACTIVE_TAG: this.faqSublineForm.get('activeTag')?.value,
        QUESTION: this.faqSublineForm.get('question')?.value,
        ANSWER: this.faqSublineForm.get('answer')?.value,
        REMARKS: this.faqSublineForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.faqSublineForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.faqSublineForm.get('userID')?.value,
        MODE: 'I'
      }
      this.tblConfigFAQSubline.tblData.unshift(formToData);
      this.tblConfigFAQSubline.tblData = [...this.tblConfigFAQSubline.tblData];
      this.tempAddedData.push(formToData);
      this.resetFormValues();
      this.appMessageService.showAppMessage("FAQ Subline details added!", "success");
    }
    catch(e) {
      
    }
  }

  updateFAQSubline() {
    try {
      this.tranPosted = true;
      this.validateUpdate();
      if (this.validated) {
        this.updateFormTable();
      }
    }
    catch(e) {
      
    }
  }

  updateFormTable() {
    try {
      let tempInsertChecker = this.tempAddedData.find(
        (faq: { QUESTION: any; }) => faq.QUESTION == this.faqSublineForm.get('question')?.value);
      let tempUpdateChecker = this.tempUpdateData.find(
        (faq: { QUESTION_NO: any; }) => faq.QUESTION_NO == this.faqSublineForm.get('questionNo')?.value);
      
      this.tableDataUpdate();
      if(tempInsertChecker!=null && tempInsertChecker!='') {
        tempInsertChecker.QUESTION_NO = this.faqSublineForm.get('questionNo')?.value;
        tempInsertChecker.LINE_CD = this.paramForm.get('lineCd')?.value;
        tempInsertChecker.SUBLINE_CD = this.paramForm.get('sublineCd')?.value;
        tempInsertChecker.SORT_SEQ = this.faqSublineForm.get('sortSeq')?.value;
        tempInsertChecker.ACTIVE_TAG = this.faqSublineForm.get('activeTag')?.value;
        tempInsertChecker.QUESTION = this.faqSublineForm.get('question')?.value;
        tempInsertChecker.ANSWER = this.faqSublineForm.get('answer')?.value;
        tempInsertChecker.REMARKS = this.faqSublineForm.get('remarks')?.value;
        tempInsertChecker.USER_ID = this.faqSublineForm.get('userID')?.value;
        tempInsertChecker.MODE = 'I';
      } 
      else if(tempUpdateChecker!=null && tempUpdateChecker!='') {
        tempUpdateChecker.QUESTION_NO = this.faqSublineForm.get('questionNo')?.value;
        tempUpdateChecker.LINE_CD = this.paramForm.get('lineCd')?.value;
        tempUpdateChecker.SUBLINE_CD = this.paramForm.get('sublineCd')?.value;
        tempUpdateChecker.SORT_SEQ = this.faqSublineForm.get('sortSeq')?.value;
        tempUpdateChecker.ACTIVE_TAG = this.faqSublineForm.get('activeTag')?.value;
        tempUpdateChecker.QUESTION = this.faqSublineForm.get('question')?.value;
        tempUpdateChecker.ANSWER = this.faqSublineForm.get('answer')?.value;
        tempUpdateChecker.REMARKS = this.faqSublineForm.get('remarks')?.value;
        tempUpdateChecker.USER_ID = this.faqSublineForm.get('userID')?.value;
        tempUpdateChecker.MODE = 'U';
      }
      else {
        this.tempUpdateData.push(this.formValueToData());
      }
      this.saveDisabled = false;
      this.resetFormValues();
      this.appMessageService.showAppMessage("FAQ Subline updated!", "success");
    }
    catch(e) {
      
    }
  }

  tableDataUpdate() {
    try {
      this.faqSublineForm.patchValue({
        activeTag: this.faqSublineForm.get('activeTag')?.value,
        userID: this.currentUser,
        lastUserUpdate: new Date()
      });
      let updateIndex = this.tblConfigFAQSubline.tblData.indexOf(this.rowClickedData);
      this.tblConfigFAQSubline.tblData[updateIndex] = {
        QUESTION_NO: this.faqSublineForm.get('questionNo')?.value,
        QUESTION_NUM: this.faqSublineForm.get('questionNum')?.value,
        LINE_CD: this.paramForm.get('lineCd')?.value,
        SUBLINE_CD: this.paramForm.get('sublineCd')?.value,
        SORT_SEQ: this.faqSublineForm.get('sortSeq')?.value,
        ACTIVE_TAG: this.faqSublineForm.get('activeTag')?.value,
        QUESTION: this.faqSublineForm.get('question')?.value,
        ANSWER: this.faqSublineForm.get('answer')?.value,
        REMARKS: this.faqSublineForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.faqSublineForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.faqSublineForm.get('userID')?.value,
        MODE: 'U'
      };
      this.tblConfigFAQSubline.tblData = [...this.tblConfigFAQSubline.tblData];
    }
    catch(e) {
      
    }
  }

  formValueToData() {
    try {
      let formToData: any = { 
        QUESTION_NO: this.faqSublineForm.get('questionNo')?.value,
        LINE_CD: this.paramForm.get('lineCd')?.value,
        SUBLINE_CD: this.paramForm.get('sublineCd')?.value,
        SORT_SEQ: this.faqSublineForm.get('sortSeq')?.value,
        ACTIVE_TAG: this.faqSublineForm.get('activeTag')?.value,
        QUESTION: this.faqSublineForm.get('question')?.value,
        ANSWER: this.faqSublineForm.get('answer')?.value,
        REMARKS: this.faqSublineForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.faqSublineForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.faqSublineForm.get('userID')?.value,
        MODE: 'U'
      }
      return formToData;
    }
    catch(e) {
      
    }
  }

  toSave() {
    try {
      this.noLoadingInProcess = false;
      this.jsonDataService.contorlLoading(true);
      this.formService.showFormLoader(null, "generate-faq-subline-form", "Saving.<br>Please wait ...", null, null);
      this._APICallService.saveFAQSubline(this.tempAddedData).subscribe({
          next: (data: any) => {
              this.securityService.checkRequestKeyResponse(data, () => {
                this.securityService.hasValidCsrfToken(data, () => {
                  if(data.status == 'SUCCESS') {
                    this._APICallService.saveFAQSubline(this.tempUpdateData).subscribe({
                        next: (res: any) => {
                            this.securityService.checkRequestKeyResponse(res, () => {
                              this.securityService.hasValidCsrfToken(res, () => {
                                if(res.status == 'SUCCESS') {
                                  this.onComplete();
                                } else {
                                    this.onFail();
                                }
                              });
                            });
                        },
                        error: () => {
                            this.onFail();
                        }
                    });
                  } else {
                      this.onFail();
                  }
                });
              });
          },
          error: () => {
              this.onFail();
          }
      });
    }
    catch(e) {
      
    }
  }

  onComplete() {
    try {
      this.tempAddedData = [];
      this.tempUpdateData = [];
      this.jsonDataService.contorlLoading(false);
      this.openDialog('SUCCESS', 'FAQ details Successfully saved!');
      this.resetTableData();
      this.resetFormValues();
      this.initOnGet();
    } catch(e) {
      
    }
  }

  onFail() {
    try {
      this.tempAddedData = [];
      this.tempUpdateData = [];
      this.jsonDataService.contorlLoading(false);
      this.openDialog('ERROR', 'An error has occured while saving details for new FAQ');
      // this.initOnGet();
      this.resetTableData();
      this.resetFormValues();
    } catch(e) {
      
    }
  }

  resetTableData() {
    this.tblConfigFAQSubline.tblData = [];
    // this.paramForm.patchValue({
    //   lineCd:       null,
    //   lineName:     null,
    //   lineField:    null,
    //   sublineCd:    null,
    //   sublineName:  null,
    //   sublineField: null
    // });
  }

  deleteFAQSubline() {
    try {
      let clickData = this.rowClickedData;
      this.tblConfigFAQSubline.tblData = this.tblConfigFAQSubline.tblData.filter(
        function(e: any) {
          return e.QUESTION != clickData.QUESTION;
        }
      );
      this.tempAddedData = this.tempAddedData.filter(
        function(e: any) {
          return e.QUESTION != clickData.QUESTION;
        }
      );
      this.resetFormValues();
    }
    catch(e) {
      
    }
  }

  onRowClick(ev: any) {
    try {    
      if(ev!=null) {
        this.rowClickedData = ev;
        this.faqSublineForm.get('questionNum')?.disable();
        this.loadFormValues();
        this.saveDisabled = this.saveChecker();
        this.deleteDisabled = this.deleteChecker(ev);
        
      }
      else {
        this.resetFormValues();
      }
    }
    catch(e) {
      
    }
  }

  loadFormValues() {
    try {
      this.faqSublineForm.patchValue({
        questionNo: this.rowClickedData.QUESTION_NO,
        questionNum: this.rowClickedData.QUESTION_NUM,
        sortSeq: this.rowClickedData.SORT_SEQ,
        activeTag: this.rowClickedData.ACTIVE_TAG,
        question: this.rowClickedData.QUESTION,
        answer: this.rowClickedData.ANSWER,
        remarks: this.rowClickedData.REMARKS,
        userID: this.rowClickedData.USER_ID,   
        lastUserUpdate: this.rowClickedData.LAST_USER_UPDATE
      });
      this.addUpdDisabled = false;
      this.isFAQSelected = true;
    }
    catch(e) {
      
    }
  }

  resetFormValues() {
    try {
      //this.faqSublineForm.get('questionNo')?.enable();
      this.faqSublineForm.patchValue({
        questionNo: null,
        questionNum: null,
        sortSeq: null,
        activeTag: 'A',
        question: null,
        answer: null,
        remarks: null,
        userID: null,   
        lastUserUpdate: null
      });

      this.addUpdDisabled = false;
      this.saveDisabled = this.saveChecker();
      this.deleteDisabled = true;
      this.isFAQSelected = false;

      this.rowClickedData = '' || null;
    }
    catch (e) {
    console.error(e);
      
    }
  }

  saveChecker() {
    try {
      if((this.tempAddedData==null || this.tempAddedData=='') && (this.tempUpdateData==null || this.tempUpdateData=='')) {
        return true;
      }
      else {
        return false;
      }
    }
    catch(e) {
      return true;
    }
  }

  deleteChecker(ev: any) {
    try {
      let tempDataFlag;
      tempDataFlag = this.tempAddedData.filter(function(e: any) {
        return e.TAX_CD==ev.TAX_CD;
      });
      if(tempDataFlag==''||tempDataFlag==null) {
        return true;
      } else {
        return false;
      }
    }
    catch(e) {
      return true;
    }
  }

  openDialog(title: any, message: any) {
    try {
      this.noLoadingInProcess = true;
      this.formService.hideFormLoader("generate-faq-subline-form");
      this._Dialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        } 
      });
    } catch(e) {
      
    }
  }

  openErrorDialog(content : string) {
    try {
      this.noLoadingInProcess = true;
      this.formService.hideFormLoader("generate-faq-subline-form");
      const dialogRole = this._Dialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: 'ERROR',
          content: content
        } 
      });
    }
    catch (e) {
    console.error(e);
      
    }  
  }

  validateInsert() {
    try {
      this.validated = false;
      if (!this.requiredFieldCheck()){
        // this.openDialog('ERROR', 'Kindly fill-out required fields.');
        setTimeout(()=>{                           //10 secs countdown
          this.formService.hideFormMsg("faq-subline-error-message");
        }, 10000);
      }
      else if (!this.seqNoDuplicateCheck()) {
        setTimeout(()=>{                           //10 secs countdown
          this.formService.hideFormMsg("faq-subline-error-message");
        }, 10000);
      }
      else if (!this.seqNoNegativeCheck) {
        setTimeout(()=>{                           //10 secs countdown
          this.formService.hideFormMsg("faq-subline-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
      
    }
  } 

  validateUpdate() {
    try {
      this.validated = false;
      if (!this.requiredFieldCheck()){
        // this.openDialog('ERROR', 'There are missing information. Please provide the necessary information needed.');
        setTimeout(()=>{                          
          this.formService.hideFormMsg("faq-subline-error-message");
        }, 10000);
      }
      else if (!this.seqNoDuplicateCheck()) {
        setTimeout(()=>{                           //10 secs countdown
          this.formService.hideFormMsg("faq-subline-error-message");
        }, 10000);
      }
      else if (!this.seqNoNegativeCheck) {
        setTimeout(()=>{                           //10 secs countdown
          this.formService.hideFormMsg("faq-subline-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
      
    }
  }

  requiredFieldCheck() {
    try {
      if( (this.faqSublineForm.get('activeTag')?.value ?? '').trim().length === 0 || 
        (this.faqSublineForm.get('question')?.value ?? '').trim().length === 0 || 
        (this.faqSublineForm.get('answer')?.value ?? '').trim().length === 0) {
        this.formService.showFormMsg("faq-subline-error-message", 'There are missing information. Please provide the necessary information needed.', "E");
        return false;
      }
      else {
        return true;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }

  seqNoDuplicateCheck(): boolean {
    try {
      const seqNo = this.faqSublineForm.get("sortSeq")?.value;
      const currentData = this.tblConfigFAQSubline.tblData;
      let duplicateSeqNo = currentData.filter((data: any) => {
        return data.SORT_SEQ === seqNo && data.SORT_SEQ !== this.rowClickedData?.SORT_SEQ;
      });
      if((duplicateSeqNo.length > 0) && !(seqNo === null || seqNo === "")) {
        this.formService.showFormMsg("faq-subline-error-message", 'Sequence No. already exists. Please choose a different Sequence No.', "E");
        return false;
      } else {
        return true;
      }
    } catch(e) {
      return false;
    }
  }

  seqNoNegativeCheck(): boolean {
    try {
      const seqNo = this.faqSublineForm.get("sortSeq")?.value;
      if(seqNo < 0) {
        this.formService.showFormMsg("faq-subline-error-message", 'Sequence No. does not accept negative numbers', "E");
        return false;
      } else {
        return true;
      }
    } catch(e) {
      return false;
    }
  }

  seqNoValidateFront(ev: any) {
    try {
      const rawSeqNo = ev.value;
      const seqNo = +ev.value;
      if((seqNo < 1 || !Number.isInteger(seqNo)) && !(rawSeqNo === "" || rawSeqNo === null)) {
        this.faqSublineForm.get("sortSeq")?.setValue("");
      } else {
        this.faqSublineForm.get("sortSeq")?.setValue(parseInt(rawSeqNo));
      }
    } catch(e) {
    }
  }
}
