import { Component, Inject, OnInit } from '@angular/core';
import { APICallService } from 'src/app/services/api-call.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { JsonDataService } from 'src/app/services/json-data.service';

@Component({
  selector: 'tax-lov',
  templateUrl: './tax-lov.component.html',
  styleUrls: ['./tax-lov.component.css']
})
export class TaxLovComponent implements OnInit {

  taxModuleId: string = 'BMM031';

  constructor(private apiCallService: APICallService,
    private securityService: SecurityService,
    private jsonDataService: JsonDataService,
    private userDetailService: UserDetailsService,
    private appMessageService: AppMessageService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<TaxLovComponent>) { }

  ngOnInit(): void {
    this.getTax();
  }

  taxData: any = [];

  // Source Code Initializer
  taxTableConfig: any = {
    cols: [
      {
        key: "TAX_CODE",
        header: "Code",
        dataType: "string"
      },
      {
        key: "TAX_NAME",
        header: "Tax Name",
        dataType: "string"
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15
  }

  async getTax() {
    try {
      this.taxTableConfig.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.apiCallService.getAllTax({
        moduleId: this.data.moduleId,
        userId: this.userDetailService.userId,
        type: "LOV"
      }).subscribe((response: any) => {
        this.securityService.checkRequestKeyResponse(response, () => {
          this.securityService.hasValidCsrfToken(response, () => {
            if (response.status === 'SUCCESS') {
              let data = JSON.parse(this.jsonDataService.decrypt(response.response));
              if (data.moduleId === this.taxModuleId) {
                this.jsonDataService.contorlLoading(false);
                this.taxTableConfig.loading = false;
                this.taxTableConfig.tblData = data.data.filter((e: any) => e.ACTIVE_TAG == 'A').sort((a: any, b: any) => (a.TAX_CD > b.TAX_CD) ? 1 : -1);
              } else {
                this.jsonDataService.contorlLoading(false);
                this.taxTableConfig.loading = false;
                this.appMessageService.showAppMessage("You do not have the correct privilege to access the data. Kindly contact your administrator for assistance.", "error");
              }
            } else {
              this.jsonDataService.contorlLoading(false);
              this.taxTableConfig.loading = false;
              this.appMessageService.showAppMessage(response.message, "error");
            }
          });
        });
      });
    }
    catch (e) {
    console.error(e);

    }
  }

  onRowClick(ev: any) {
    try {
      this.data = ev;
    }
    catch (e) {
    console.error(e);

    }
  }

  onClose() {
    try {
      if (this.data == '' || this.data == null) {
        this._dialogRef.close({ content: '' });
      } else {
        this._dialogRef.close({ content: this.data });
      }
    }
    catch (e) {
    console.error(e);

    }
  }

}
