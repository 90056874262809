<section id="policy-accumulation-register">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <osp-table [tblConfig]="polAccumulationRegTable" (onRowClick)="onRowClick($event)"></osp-table>

        <div id="policy-accumulation-register-form"><div class="form-loader"></div></div>

        <div class="form-content" [hidden]="formHider">

            <form [formGroup]="polAccumulationRegForm" class="form">
                <div id="policy-accumulation-register-error-message"></div>
                <div class="form-container-grid">
                    
                    <div class="field">
                        <label>Policy No</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="policyNo">
                    </div>

                    <div class="field">
                        <label>Fire Register</label>
                    </div>
                    <div class="lov-search">
                        <input readonly formControlName="bmFregCd">
                        <button matSuffix class="btn-search btn" type="button" (click)="openFireRegisterDialog()" [disabled]="!selectedRow">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>

                    <div class="field">
                        <label>Policy Owner</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="assuredLongName">
                    </div>

                    <div class="field">
                        <label>Status</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="status">
                    </div>

                    <div class="field">
                        <label>Effective Date</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="effectiveDate">
                    </div>

                    <div class="field">
                        <label>Expiry Date</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="expiryDate">
                    </div>

                    <div class="field">
                        <label>Coverage</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="coverage" class="amount">
                    </div>

                    <div class="field">
                        <label>Net Premium</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="netPremium" class="amount">
                    </div>

                    <div class="field">
                        <label>Address</label>
                    </div>
                    <div class="span">
                        <input type="text" formControlName="address">
                    </div>

                    <div class="field">
                        <label>Latitude</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="latitude">
                    </div>
                    <div class="field">
                        <label>Longitude</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="longitude">
                    </div>

                    <div class="field">
                        <label>Construction</label>
                    </div>
                    <div class="span">
                        <input type="text" formControlName="constructionDesc">
                    </div>

                    <div class="field">
                        <label>Occupancy</label>
                    </div>
                    <div class="span">
                        <input type="text" formControlName="occupancyDesc">
                    </div>

                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input type="text" formControlName="remarks">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="lastUser">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="lastUserUpdate">
                    </div>
                </div>

                <div class="btn-grp">
                    <button type="button" class="btn btn2" [disabled]="polAccumulationRegForm.pristine" (click)="onUpdate()">UPDATE</button>
                </div>
                <div class="mtn-title">
                </div>
            </form>
        </div>
        <div class="btn-grp">
            <button type="button" class="btn btn2" [disabled]="updateData.length == 0" (click)="onSave()">SAVE</button>
        </div>
    </div>
</section>