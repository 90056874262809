<ng-template #lovDialog>
    <h3 mat-dialog-title>{{ lovName }}</h3>
    <mat-dialog-content>
        <div content id="lov-overlay">
            <osp-table #tbl [tblConfig]="tblConfig" (onRowClick)="rowClick($event)" (selectAllConfirmation)="toggleSelectAll($event)" (onLazyLoad)="lazyLoad($event)" (rowEvent)="rowEvent($event)"></osp-table>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div id="lov-btn-container">
            <button mat-button class="btn btn2" [mat-dialog-close]="true">Back</button>
            <button mat-button class="btn btn2" [mat-dialog-close]="true" (click)="onClickOk()" [disabled]="data?.selection && data?.selection == 'multi' ? ((selected.length == 0) && !selectAllTag) : (selectedRow == null)">OK</button>
        </div>
    </mat-dialog-actions>
</ng-template>