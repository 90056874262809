import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';

@Component({
  selector: 'rpa-gen-page-mapping-maintenance',
  templateUrl: './rpa-gen-page-mapping-maintenance.component.html',
  styleUrls: ['./rpa-gen-page-mapping-maintenance.component.css']
})
export class RpaGenPageMappingMaintenanceComponent implements OnInit, LeaveConfirmation {

  private moduleId: string = 'BMM041';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc ?? "";

  constructor(
    public _formService: FormService,
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService,
    private _appMessageService: AppMessageService,
  ) {
    this.userIdLoggedIn = this._userDetailService.userId ?? "";
  }

  canDeactivate(): boolean | Observable<boolean> {
    return !(this.rpaGenPageMappingTblConfig.tblData.filter((row: any) => ['I', 'U'].includes(row.operation)).length > 0);
  }

  public btns = {
    rpaGenPageMapping: {
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      addDisabled: true,
      lineSearchDisabled: false,
      sublineSearchDisabled: true,
    },
  };

  public showForm: boolean = true;

  //* Data Stuffs */
  private rowData: any;
  private errorMessage: string = "";
  private userIdLoggedIn: string;
  //? set selectedRow to [] to unselect the table
  public selectedRow!: any[];

  public forms = {
    lineSublineGpgLovForm: this.fb.group({
      lineCd: [null],
      lineName: [null],
      sublineCd: [null],
      sublineName: [null],
      lineCdDisp: [{ value: '', disabled: 'true' }],
      sublineCdDisp: [{ value: '', disabled: 'true' }],
      genPageGrp: [null],
    }),
    rpaGenPageMappingForm: this.fb.group({
      lineCd: [null],
      sublineCd: [null],
      genPageGrp: [null],
      genPageNo: [null, [Validators.min(1), Validators.required, this.checkDuplicateGenPageNo()]],
      genPageLabel: [null],
      genPageDesc: [null],
      paramTag: [null, [Validators.required]],
      lastUpdateUser: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }],
    }),
    resetRpaGenPageMappingForm: () => {
      this.forms.rpaGenPageMappingForm.reset();
      this.btns.rpaGenPageMapping.updateDisabled = true;
      this.btns.rpaGenPageMapping.deleteDisabled = true;
      this.btns.rpaGenPageMapping.lineSearchDisabled = this.btns.rpaGenPageMapping.sublineSearchDisabled = false;
      this.forms.lineSublineGpgLovForm.get('genPageGrp')?.enable({ emitEvent: false });
      this._formService.hideFormMsg("rpa-gen-page-mapping-error-message");
      // this.forms.rpaGenPageMappingForm.patchValue({
      //   paramTag: 'Y'
      // });
      this.rowData = null;
      this.forms.rpaGenPageMappingForm.get('genPageNo')?.enable();
    },
    resetLineSublineGpgLovForm: () => {
      this.forms.lineSublineGpgLovForm.reset();
    },
    resetAllForms: () => {
      this.forms.resetLineSublineGpgLovForm();
      this.forms.resetRpaGenPageMappingForm();
    }
  }

  private rpaGenPageMappingFormManipulate = {
    dbToForm: (data: any) => {
      return {
        lineCd: data.LINE_CD,
        sublineCd: data.SUBLINE_CD,
        genPageGrp: data.GEN_PAGE_GRP,
        genPageNo: data.GEN_PAGE_NO,
        genPageLabel: data.GEN_PAGE_LABEL,
        genPageDesc: data.GEN_PAGE_DESC,
        paramTag: data.PARAM_TAG,
        lastUpdateUser: data.USER_ID,
        lastUpdate: data.LAST_UPDATE,
      };
    },
    formToDb: (data: any) => {
      return {
        LINE_CD: data.lineCd,
        SUBLINE_CD: data.sublineCd,
        GEN_PAGE_GRP: data.genPageGrp,
        GEN_PAGE_NO: data.genPageNo?.toString().padStart(2, 0),
        GEN_PAGE_LABEL: data.genPageLabel,
        GEN_PAGE_DESC: data.genPageDesc,
        PARAM_TAG: data.paramTag,
        USER_ID: null,
        LAST_UPDATE: null,
      };
    },
  }

  public rpaGenPageMappingTblConfig = {
    cols: [
      {
        key: "GEN_PAGE_NO",
        header: "Gen Page No",
        dataType: "string",
        width: "128px"
      },
      {
        key: "GEN_PAGE_LABEL",
        header: "Gen Page Label",
        dataType: "string",
        width: "192px"
      },
      {
        key: "GEN_PAGE_DESC",
        header: "Gen Page Description",
        dataType: "string",
      },
      {
        key: "PARAM_TAG",
        header: "Parameter",
        dataType: "checkbox",
        width: "128px"
      },
    ],
    tblData: [] as any[],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  ngOnInit(): void {
    this.forms.lineSublineGpgLovForm.get('genPageGrp')?.valueChanges.subscribe((value: any) => {
      this.btns.rpaGenPageMapping.addDisabled = !(value && this.forms.lineSublineGpgLovForm.get('sublineCd')?.value && this.forms.lineSublineGpgLovForm.get('lineCd')?.value);
      this.forms.resetRpaGenPageMappingForm();
      this.getData.rpaGenPageMapping();
    });
  }

  public openLov = {
    line: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'LINE',
          moduleId: this.moduleId
        },
      }
      this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button === 'OK') {
          this.forms.resetRpaGenPageMappingForm();
          if (output) {
            this.forms.lineSublineGpgLovForm.patchValue({
              lineCd: output.LINE_CD,
              lineName: output.LINE_NAME,
              lineCdDisp: `${output.LINE_CD} - ${output.LINE_NAME}`,
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null,
            });
            this.btns.rpaGenPageMapping.sublineSearchDisabled = false;
            this.btns.rpaGenPageMapping.addDisabled = true;
          }
          else {
            this.forms.lineSublineGpgLovForm.patchValue({
              lineCd: null,
              lineName: null,
              lineCdDisp: null,
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null,
            });
            this.btns.rpaGenPageMapping.sublineSearchDisabled = true;
            this.btns.rpaGenPageMapping.addDisabled = true;
          }
          this.getData.rpaGenPageMapping();
        }
      });
    },
    subline: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          lineCd: this.forms.lineSublineGpgLovForm.get('lineCd')?.value,
          table: 'SUBLINE',
          moduleId: this.moduleId
        },
      }
      this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button === 'OK') {
          this.forms.resetRpaGenPageMappingForm();
          if (output) {
            this.forms.lineSublineGpgLovForm.patchValue({
              lineCd: output.LINE_CD,
              lineName: output.LINE_NAME,
              lineCdDisp: `${output.LINE_CD} - ${output.LINE_NAME}`,
              sublineCd: output.SUBLINE_CD,
              sublineName: output.SUBLINE_NAME,
              sublineCdDisp: `${output.SUBLINE_CD} - ${output.SUBLINE_NAME}`,
            });
          }
          else {
            this.forms.lineSublineGpgLovForm.patchValue({
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null,
            });
            this.btns.rpaGenPageMapping.addDisabled = true;
          }
          this.getData.rpaGenPageMapping();
        }
      });
    },
  };

  private getData = {
    rpaGenPageMapping: () => {
      try {
        this.rpaGenPageMappingTblConfig.tblData = [];
        if (this.forms.lineSublineGpgLovForm.get('lineCd')?.value
          && this.forms.lineSublineGpgLovForm.get('sublineCd')?.value
          && this.forms.lineSublineGpgLovForm.get('genPageGrp')?.value) {
          this._jDS.contorlLoading(true);
          this.rpaGenPageMappingTblConfig.loading = true;
          this._api.getRpaGenPageMapping({
            moduleId: this.moduleId,
            userId: this._userDetailService.userId,
            type: "MODULE",
          }).subscribe({
            next: (response: any) => {
              this._securityService.checkRequestKeyResponse(response, () => {
                this._securityService.hasValidCsrfToken(response, () => {
                  if (response.status === 'SUCCESS') {
                    let content = JSON.parse(this._jDS.decrypt(response?.response));
                    this.rpaGenPageMappingTblConfig.tblData = content.data?.filter((row: any) => {
                      return (row.LINE_CD === this.forms.lineSublineGpgLovForm.get('lineCd')?.value
                        && row.SUBLINE_CD === this.forms.lineSublineGpgLovForm.get('sublineCd')?.value
                        && row.GEN_PAGE_GRP === this.forms.lineSublineGpgLovForm.get('genPageGrp')?.value);
                    });
                    this.btns.rpaGenPageMapping.addDisabled = false;
                  }
                  else {
                    this._appMessageService.showAppMessage(response.message, "error");
                  }
                  this._jDS.contorlLoading(false);
                  this.rpaGenPageMappingTblConfig.loading = false;
                });
              });
            },
            error: (e: any) => {
              this._securityService.checkRequestKeyResponse(e, () => {

                this._jDS.contorlLoading(false);
                this.rpaGenPageMappingTblConfig.loading = false;
                this.btns.rpaGenPageMapping.addDisabled = true;
              });
            }
          });
        }
      }
      catch (e) {
    console.error(e);

      }
    },
    genPageGrp: () => {
      const filteredGpg = this._jDS.data.refCds.filter((data: any) => data.rvDomain === 'GEN_PAGE_GRP').map((data: any) => { return { cd: data.rvLowValue, name: `${data.rvLowValue} - ${data.rvMeaning}` } });
      return filteredGpg;
    },
  };

  public selections = {
    parameterTypes: [
      { cd: null, name: '' },
      { cd: 'Y', name: 'Yes' },
      { cd: 'N', name: 'No' },
    ],
    genPageGrps: [
      { cd: '', name: '' },
      ...this.getData.genPageGrp()
    ],
  };

  public onRowClick(ev: any): void {
    try {
      if (ev) {
        this.rowData = ev;
        this.rowData?.operation !== 'I' ? this.forms.rpaGenPageMappingForm.get('genPageNo')?.disable() : this.forms.rpaGenPageMappingForm.get('genPageNo')?.enable();
        this.populateFormFromTable();
      }
      else {
        this.rowData = null;
        this.forms.resetRpaGenPageMappingForm();
      }
    }
    catch (e) {
    console.error(e);
      this.rowData = null;
      this.forms.resetRpaGenPageMappingForm();
    }
  }

  private populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.rpaGenPageMapping.updateDisabled = false;
      this.btns.rpaGenPageMapping.deleteDisabled = !(this.rowData?.operation === 'I');
      // this.btns.rpaGenPageMapping.lineSearchDisabled = this.btns.rpaGenPageMapping.sublineSearchDisabled = true;
      // this.forms.lineSublineGpgLovForm.get('genPageGrp')?.disable({ emitEvent: false });
      this.forms.rpaGenPageMappingForm.patchValue(this.rpaGenPageMappingFormManipulate.dbToForm(data));
    }
    catch (e) {
    console.error(e);

    }
  }

  public onAdd(): void {
    try {
      this._formService.hideFormMsg("rpa-gen-page-mapping-error-message");
      if (this.forms.rpaGenPageMappingForm.valid) {
        this.forms.rpaGenPageMappingForm.patchValue({
          lineCd: this.forms.lineSublineGpgLovForm.get('lineCd')?.value,
          sublineCd: this.forms.lineSublineGpgLovForm.get('sublineCd')?.value,
          genPageGrp: this.forms.lineSublineGpgLovForm.get('genPageGrp')?.value,
        });
        let rowToBeAdded: { [key: string]: any } = this.rpaGenPageMappingFormManipulate.formToDb(this.forms.rpaGenPageMappingForm.getRawValue());
        rowToBeAdded.operation = 'I';
        this.rpaGenPageMappingTblConfig.tblData = [rowToBeAdded, ...this.rpaGenPageMappingTblConfig.tblData];
        this.forms.resetRpaGenPageMappingForm();
        this.btns.rpaGenPageMapping.saveDisabled = false;
      }
      else {
        this.showErrorValidator.rpaGenPageMapping();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onUpdate(): void {
    try {
      this._formService.hideFormMsg("rpa-gen-page-mapping-error-message");
      if (this.forms.rpaGenPageMappingForm.valid) {
        const indexOfRow = this.rpaGenPageMappingTblConfig.tblData.indexOf(this.rowData);
        let rowToBeUpdated: { [key: string]: any } = this.rpaGenPageMappingFormManipulate.formToDb(this.forms.rpaGenPageMappingForm.getRawValue());
        rowToBeUpdated.operation = (this.rowData.operation === 'I') ? 'I' : 'U';
        this.forms.resetRpaGenPageMappingForm();
        this.rpaGenPageMappingTblConfig.tblData[indexOfRow] = rowToBeUpdated;
        this.selectedRow = [];
        this.btns.rpaGenPageMapping.saveDisabled = false;
        this.rpaGenPageMappingTblConfig.tblData = [...this.rpaGenPageMappingTblConfig.tblData];
      }
      else {
        this.showErrorValidator.rpaGenPageMapping();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onDelete(): void {
    try {
      this.rpaGenPageMappingTblConfig.tblData = this.rpaGenPageMappingTblConfig.tblData.filter((row: any) => {
        return row !== this.rowData;
      });
      this.forms.resetRpaGenPageMappingForm();
      this.btns.rpaGenPageMapping.saveDisabled = false;
    }
    catch (e) {
    console.error(e);

    }
  }

  public onSave(): void {
    try {
      this._jDS.contorlLoading(true);
      this.showForm = false;
      this._formService.showFormLoader(null, "rpa-gen-page-mapping-maintenance-loading", "Saving.<br>Please wait ...", null, null, true);
      let toBeSaved = this.rpaGenPageMappingTblConfig.tblData.filter((row: any) => ['I', 'U'].includes(row.operation));
      if (toBeSaved.length > 0) {
        this._api.saveRpaGenPageMapping(toBeSaved).subscribe({
          next: (response: any) => {
            this._securityService.checkRequestKeyResponse(response, () => {
              this._securityService.hasValidCsrfToken(response, () => {
                if (response.status === 'SUCCESS') this.onComplete.rpaGenPageMapping();
                else this.onComplete.rpaGenPageMappingError();
              });
            });
          },
          error: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {

              this.onComplete.rpaGenPageMappingError();
            });
          }
        });
      }
      else {
        this.onComplete.rpaGenPageMapping();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  private onComplete = {
    rpaGenPageMapping: () => {
      this._jDS.contorlLoading(false);
      this._formService.hideFormLoader("rpa-gen-page-mapping-maintenance-loading");
      this.openDialog('SUCCESS', this.moduleName.replace('Maintenance', ''), 'saved');
      this.getData.rpaGenPageMapping();
      this.btns.rpaGenPageMapping.saveDisabled = true;
      this.showForm = true;
      this.forms.resetRpaGenPageMappingForm();
    },
    rpaGenPageMappingError: () => {
      this._jDS.contorlLoading(false);
      this.openDialog('FAILED', this.moduleName.replace('Maintenance', ''), 'saving');
      this._formService.hideFormLoader("rpa-gen-page-mapping-maintenance-loading");
      this.showForm = true;
      this.forms.resetRpaGenPageMappingForm();
    },
  }

  openDialog(title: string, contentTitle: string, contentDetail: string) {
    try {
      this._matDialog.open(DialogMsgComponent, {
        disableClose: true,
        width: '512px',
        data: {
          title: title,
          content:
            title === 'SUCCESS'
              ? `${contentTitle} details successfully ${contentDetail}!`
              : `${contentTitle} details ${contentDetail} failed!`,
        },
      });
    }
    catch (e) {
    console.error(e);

    }
  }

  //* Validator Stuffs Down here */

  checkDuplicateGenPageNo(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = parseInt(control.value);
      if (!value) return null;
      let hasDuplicate: boolean = this.rpaGenPageMappingTblConfig.tblData.filter((d: any) => {
        if (!this.rowData)
          return parseInt(d.GEN_PAGE_NO) === value;
        else
          return parseInt(this.rowData.GEN_PAGE_NO) !== value && parseInt(d.GEN_PAGE_NO) === value;
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupe: true } : null;
    }
  }

  public showErrorValidator = {
    rpaGenPageMapping: () => {
      try {
        Object.keys(this.forms.rpaGenPageMappingForm.controls).forEach(key => {
          const controlErrors = this.forms.rpaGenPageMappingForm.get(key)?.errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              if (keyError == 'required' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
              else if (keyError == 'hasDupe' && controlErrors[keyError] === true)
                this.errorMessage = "A record with the same combination of Line, Subline, Gen Page Group, and Gen Page No already exists. Please add a unique combination.";
              else if (keyError == 'min')
                this.errorMessage = `Gen Page No. must be greater than ${controlErrors[keyError]['min']}. Please choose a different Gen Page No.`;
            });
          }
        });
        this._formService.showFormMsg("rpa-gen-page-mapping-error-message", this.errorMessage, "E");
      }
      catch (e) {
    console.error(e);

      }
    },
  }

}
