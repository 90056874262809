import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { CustomValidators } from 'src/app/utils/custom-validator';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';
import { PlanLovComponent } from '../../common/plan-lov/plan-lov.component';
import { PropAssessmentLovComponent } from '../../common/prop-assessment-lov/prop-assessment-lov.component';

@Component({
  selector: 'determine-hca-plan-maintenance',
  templateUrl: './determine-hca-plan-maintenance.component.html',
  styleUrls: ['./determine-hca-plan-maintenance.component.css']
})
export class DetermineHcaPlanMaintenanceComponent implements OnInit, LeaveConfirmation {

  private moduleId: string = 'BMM166';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc ?? "";

  constructor(
    public _formService: FormService,
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService,
    private _appMessageService: AppMessageService,
  ) {
    this.userIdLoggedIn = this._userDetailService.userId ?? "";
  }

  public canDeactivate(): boolean | Observable<boolean> {
    return !(this.determineHcaPlanTblConfig.tblData?.filter((row: any) => ['I', 'U'].includes(row.operation)).length > 0);
  }

  public btns = {
    determineHcaPlan: {
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      addDisabled: true,
      formLovSearchDisabled: true,
      assessmentLovSearchDisabled: true,
    },
    filterLov: {
      lineLovSearchDisabled: false,
      sublineLovSearchDisabled: true,
      planLovSearchDisabled: true,
    }
  };

  public showForm: boolean = true;

  //* Data Stuffs */
  private rowData: any;
  private errorMessage: string = "";
  private userIdLoggedIn: string;
  //? set selectedRow to [] to unselect the table
  public selectedRow!: any[];

  public forms = {
    filterLovForm: this.fb.group({
      lineCd: [null],
      lineName: [null],
      sublineCd: [null],
      sublineName: [null],
      planCd: [null],
      planName: [null],
      lineCdDisp: [{ value: '', disabled: 'true' }],
      sublineCdDisp: [{ value: '', disabled: 'true' }],
      planDisp: [{ value: '', disabled: 'true' }],
    }),
    determineHcaPlanForm: this.fb.group({
      lineCd: [null],
      sublineCd: [null],
      planCd: [null],
      assessmentCd: [null, [Validators.required, this.checkDuplicateAssessment()]],
      assessmentDisp: [{ value: '', disabled: 'true' }],
      acceptedAns: [null, [Validators.required, CustomValidators.requiredTrim]],
      validationDesc: [null, [Validators.required, CustomValidators.requiredTrim]],
      active: ["A", [Validators.required]],
      remarks: [null],
      lastUpdateUser: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }],
    }),
    resetDetermineHcaPlanForm: () => {
      this.forms.determineHcaPlanForm.reset();
      this.btns.determineHcaPlan.updateDisabled = true;
      this.btns.determineHcaPlan.deleteDisabled = true;

      this.forms.determineHcaPlanForm.patchValue({
        active: 'A',
      });

      this._formService.hideFormMsg("determine-hca-plan-error-message");
      this.rowData = null;
      this.btns.determineHcaPlan.assessmentLovSearchDisabled = false;
    },
    resetFilterLovForm: () => {
      this.forms.filterLovForm.reset();
    },
    resetAllForms: () => {
      this.forms.resetFilterLovForm();
      this.forms.resetDetermineHcaPlanForm();
    }
  }

  private determineHcaPlanFormManipulate = {
    dbToForm: (data: any) => {
      return {
        lineCd: data.LINE_CD,
        sublineCd: data.SUBLINE_CD,
        planCd: data.PLAN_CD,
        assessmentCd: data.ASSESSMENT_CD,
        assessmentDisp: data.ASSESSMENT_CD,
        active: data.ACTIVE_TAG,
        acceptedAns: data.ACCEPTED_ANSWER,
        validationDesc: data.VALIDATION_DESCRIPTION,
        remarks: data.REMARKS,
        lastUpdateUser: data.USER_ID,
        lastUpdate: data.LAST_UPDATE,
      };
    },
    formToDb: (data: any) => {
      return {
        LINE_CD: data.lineCd,
        SUBLINE_CD: data.sublineCd,
        PLAN_CD: data.planCd,
        ASSESSMENT_CD: data.assessmentCd,
        ACTIVE_TAG: data.active,
        ACCEPTED_ANSWER: data.acceptedAns,
        VALIDATION_DESCRIPTION: data.validationDesc,
        REMARKS: data.remarks,
        USER_ID: null,
        LAST_UPDATE: null,
      };
    },
  }

  public determineHcaPlanTblConfig: any = {
    cols: [
      {
        key: "ASSESSMENT_CD",
        header: "Assessment Code",
        dataType: "string",
        width: '196px',
      },
      {
        key: "ACCEPTED_ANSWER",
        header: "Accepted Answer",
        dataType: "string",
        width: '196px',
      },
      {
        key: "VALIDATION_DESCRIPTION",
        header: "Validation Description",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        width: '48px',
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  ngOnInit(): void {
  }

  public openLov = {
    line: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'LINE',
          moduleId: this.moduleId
        },
      }
      this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut?.content;
        if (dataOut.button?.toUpperCase() === 'OK') {
          this.forms.resetDetermineHcaPlanForm();
          if (output) {
            this.forms.filterLovForm.patchValue({
              lineCd: output.LINE_CD,
              lineName: output.LINE_NAME,
              lineCdDisp: `${output.LINE_CD} - ${output.LINE_NAME}`,
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null,
              planCd: null,
              planName: null,
              planDisp: null,
            });
            this.btns.determineHcaPlan.addDisabled = true;
            this.btns.filterLov.sublineLovSearchDisabled = false;
            this.btns.filterLov.planLovSearchDisabled = true;
          }
          else {
            this.forms.filterLovForm.patchValue({
              lineCd: null,
              lineName: null,
              lineCdDisp: null,
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null,
              planCd: null,
              planName: null,
              planDisp: null,
            });
            this.btns.determineHcaPlan.addDisabled = this.btns.determineHcaPlan.formLovSearchDisabled = true;
            this.btns.filterLov.sublineLovSearchDisabled = true;
            this.btns.filterLov.planLovSearchDisabled = true;
          }
          this.getData.determineHcaPlan();
        }
      });
    },
    subline: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          lineCd: this.forms.filterLovForm.get('lineCd')?.value,
          table: 'SUBLINE',
          moduleId: this.moduleId
        },
      }
      this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut?.content;
        if (dataOut.button?.toUpperCase() === 'OK') {
          this.forms.resetDetermineHcaPlanForm();
          if (output) {
            this.forms.filterLovForm.patchValue({
              lineCd: output.LINE_CD,
              lineName: output.LINE_NAME,
              lineCdDisp: `${output.LINE_CD} - ${output.LINE_NAME}`,
              sublineCd: output.SUBLINE_CD,
              sublineName: output.SUBLINE_NAME,
              sublineCdDisp: `${output.SUBLINE_CD} - ${output.SUBLINE_NAME}`,
              planCd: null,
              planName: null,
              planDisp: null,
            });
            this.btns.filterLov.planLovSearchDisabled = false;
          }
          else {
            this.forms.filterLovForm.patchValue({
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null,
              planCd: null,
              planName: null,
              planDisp: null,
            });
            this.btns.determineHcaPlan.addDisabled = this.btns.determineHcaPlan.formLovSearchDisabled = true;
            this.btns.filterLov.planLovSearchDisabled = true;
          }
          this.getData.determineHcaPlan();
        }
      });
    },
    plan: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'PLAN',
          moduleId: this.moduleId,
          lineCd: this.forms.filterLovForm.get('lineCd')?.value,
          sublineCd: this.forms.filterLovForm.get('sublineCd')?.value,
          lpad: 2,
        },
      };
      this._matDialog.open(PlanLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut?.content;
        if (dataOut.button?.toUpperCase() === 'OK') {
          this.forms.resetDetermineHcaPlanForm();
          if (output.PLAN_CD) {
            this.forms.filterLovForm.patchValue({
              planCd: output.PLAN_CD,
              planName: output.PLAN_NAME,
              planDisp: `${output.PLAN_CD} - ${output.PLAN_NAME}`,
            });
          }
          else {
            this.forms.filterLovForm.patchValue({
              planCd: null,
              planName: null,
              planDisp: null,
            });
            this.btns.determineHcaPlan.addDisabled = this.btns.determineHcaPlan.formLovSearchDisabled = true;
          }
          this.getData.determineHcaPlan();
        }
      });
    },
    assessment: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          moduleId: this.moduleId
        },
      }
      this._matDialog.open(PropAssessmentLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
        let output = dataOutput?.content;
        if (dataOutput?.button?.toUpperCase() !== 'BACK') {
          if (output?.ASSESSMENT_CD) {
            this.forms.determineHcaPlanForm.patchValue({
              assessmentCd: output.ASSESSMENT_CD,
              assessmentDisp: output.ASSESSMENT_CD,
            });
          }
          else {
            this.forms.determineHcaPlanForm.patchValue({
              assessmentCd: null,
              assessmentDisp: null,
            });
          }
        }
      });
    },
  }

  private getData = {
    determineHcaPlan: () => {
      try {
        this.determineHcaPlanTblConfig.tblData = [];
        if (this.forms.filterLovForm.get('lineCd')?.value
          && this.forms.filterLovForm.get('sublineCd')?.value
          && this.forms.filterLovForm.get('planCd')?.value) {
          this._jDS.contorlLoading(true);
          this.determineHcaPlanTblConfig.loading = true;
          this._api.getDetermineHcaPlan({
            lineCd: this.forms.filterLovForm.get('lineCd')?.value,
            sublineCd: this.forms.filterLovForm.get('sublineCd')?.value,
            planCd: this.forms.filterLovForm.get('planCd')?.value,
            moduleId: this.moduleId,
            userId: this._userDetailService.userId,
            type: "MODULE",
          }).subscribe({
            next: (data: any) => {
              this._securityService.checkRequestKeyResponse(data, () => {
                this._securityService.hasValidCsrfToken(data, () => {
                  if (data.status === "SUCCESS") {
                    let content = JSON.parse(this._jDS.decrypt(data?.response));
                    this.determineHcaPlanTblConfig.tblData = content.data;
                    this._jDS.contorlLoading(false);
                    this.determineHcaPlanTblConfig.loading = false;
                    this.btns.determineHcaPlan.addDisabled = this.btns.determineHcaPlan.formLovSearchDisabled = false;
                  }
                  else {

                    this._jDS.contorlLoading(false);
                    this.determineHcaPlanTblConfig.loading = false;
                    this.btns.determineHcaPlan.addDisabled = this.btns.determineHcaPlan.formLovSearchDisabled = true;
                  }
                });
              });
            },
            error: (e: any) => {
              this._securityService.checkRequestKeyResponse(e, () => {

                this._jDS.contorlLoading(false);
                this.determineHcaPlanTblConfig.loading = false;
                this.btns.determineHcaPlan.addDisabled = this.btns.determineHcaPlan.formLovSearchDisabled = true;
              });
            }
          });
        }
        else {
          this.determineHcaPlanTblConfig.loading = false;
          this.btns.determineHcaPlan.addDisabled = this.btns.determineHcaPlan.formLovSearchDisabled = true;
        }
      }
      catch (e) {
    console.error(e);

      }
    },
  }

  public selections = {
    activeTypes: [
      { cd: 'A', name: 'Yes' },
      { cd: 'I', name: 'No' },
    ],
  };

  public onRowClick(ev: any) {
    try {
      if (ev) {
        this.rowData = ev;
        this.btns.determineHcaPlan.assessmentLovSearchDisabled = true;
        this.populateFormFromTable();
      }
      else {
        this.rowData = null;
        this.forms.resetDetermineHcaPlanForm();
      }
    }
    catch (e) {
    console.error(e);
      this.forms.resetDetermineHcaPlanForm();
      this.rowData = null;
    }
  }

  private populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.determineHcaPlan.updateDisabled = false;
      this.btns.determineHcaPlan.deleteDisabled = !(this.rowData?.operation === 'I');
      this.forms.determineHcaPlanForm.patchValue(this.determineHcaPlanFormManipulate.dbToForm(data));
    }
    catch (e) {
    console.error(e);

    }
  }

  public onAdd(): void {
    try {
      this._formService.hideFormMsg("determine-hca-plan-error-message");
      if (this.forms.determineHcaPlanForm.valid) {
        this.forms.determineHcaPlanForm.patchValue({
          lineCd: this.forms.filterLovForm.get('lineCd')?.value,
          sublineCd: this.forms.filterLovForm.get('sublineCd')?.value,
          planCd: this.forms.filterLovForm.get('planCd')?.value,
        });
        let rowToBeAdded: { [key: string]: any } = this.determineHcaPlanFormManipulate.formToDb(this.forms.determineHcaPlanForm.getRawValue());
        rowToBeAdded.operation = 'I';
        this.determineHcaPlanTblConfig.tblData = [rowToBeAdded, ...this.determineHcaPlanTblConfig.tblData];
        this.forms.resetDetermineHcaPlanForm();
        this.btns.determineHcaPlan.saveDisabled = false;
      }
      else {
        this.showErrorValidator.determineHcaPlan();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onUpdate(): void {
    try {
      this._formService.hideFormMsg("determine-hca-plan-error-message");
      if (this.forms.determineHcaPlanForm.valid) {
        const indexOfRow = this.determineHcaPlanTblConfig.tblData.indexOf(this.rowData);
        let rowToBeUpdated: { [key: string]: any } = this.determineHcaPlanFormManipulate.formToDb(this.forms.determineHcaPlanForm.getRawValue());
        rowToBeUpdated.operation = (this.rowData.operation === 'I') ? 'I' : 'U';
        this.forms.resetDetermineHcaPlanForm();
        this.determineHcaPlanTblConfig.tblData[indexOfRow] = rowToBeUpdated;
        this.selectedRow = [];
        this.btns.determineHcaPlan.saveDisabled = false;
        this.determineHcaPlanTblConfig.tblData = [...this.determineHcaPlanTblConfig.tblData];
      }
      else {
        this.showErrorValidator.determineHcaPlan();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onDelete(): void {
    try {
      this.determineHcaPlanTblConfig.tblData = this.determineHcaPlanTblConfig.tblData.filter((row: any) => {
        return row !== this.rowData;
      });
      this.forms.resetDetermineHcaPlanForm();
      this.btns.determineHcaPlan.saveDisabled = false;
    }
    catch (e) {
    console.error(e);

    }
  }

  public onSave(): void {
    try {
      this._jDS.contorlLoading(true);
      this.showForm = false;
      this._formService.showFormLoader(null, "determine-hca-plan-maintenance-loading", "Saving.<br>Please wait ...", null, null, true);
      let toBeSaved = this.determineHcaPlanTblConfig.tblData.filter((row: any) => ['I', 'U'].includes(row.operation));
      if (toBeSaved.length > 0) {
        this._api.saveDetermineHcaPlan(toBeSaved).subscribe({
          next: (response: any) => {
            this._securityService.checkRequestKeyResponse(response, () => {
              this._securityService.hasValidCsrfToken(response, () => {
                if (response.status === 'SUCCESS') {
                  this.onComplete.determineHcaPlan();
                }
                else {
                  this.onComplete.determineHcaPlanError();
                }
              });
            });
          },
          error: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {

              this.onComplete.determineHcaPlanError();
            });
          }
        });
      }
      else {
        this.onComplete.determineHcaPlan();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  private onComplete = {
    determineHcaPlan: () => {
      this._jDS.contorlLoading(false);
      this._formService.hideFormLoader("determine-hca-plan-maintenance-loading");
      this.openDialog('SUCCESS', this.moduleName.replace('Maintenance', ''), 'saved');
      this.btns.determineHcaPlan.saveDisabled = true;
      this.showForm = true;
      this.forms.resetDetermineHcaPlanForm();
    },
    determineHcaPlanError: () => {
      this._jDS.contorlLoading(false);
      this.openDialog('FAILED', this.moduleName.replace('Maintenance', ''), 'saving');
      this._formService.hideFormLoader("determine-hca-plan-maintenance-loading");
      this.showForm = true;
      this.forms.resetDetermineHcaPlanForm();
    },
  }

  openDialog(title: string, contentTitle: string, contentDetail: string) {
    try {
      this._matDialog.open(DialogMsgComponent, {
        disableClose: true,
        width: '512px',
        data: {
          title: title,
          content:
            title === 'SUCCESS'
              ? `${contentTitle} details successfully ${contentDetail}!`
              : `${contentTitle} details ${contentDetail} failed!`,
        },
      }).afterClosed().subscribe(() => {
        if (title === 'SUCCESS') {
          this.getData.determineHcaPlan();
        }
      });
    }
    catch (e) {
    console.error(e);

    }
  }

  //* Validator Stuffs Down here */

  private checkDuplicateAssessment(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.trim()?.toUpperCase();
      if (!value) return null;
      let hasDuplicate: boolean = this.determineHcaPlanTblConfig?.tblData.filter((d: any) => {
        if (!this.rowData)
          return d['ASSESSMENT_CD']?.trim().toUpperCase() === value;
        else
          return this.rowData['ASSESSMENT_CD']?.trim()?.toUpperCase() !== value && d['ASSESSMENT_CD']?.trim()?.toUpperCase() === value;
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupe: true } : null;
    }
  }

  public showErrorValidator = {
    determineHcaPlan: () => {
      try {
        Object.keys(this.forms.determineHcaPlanForm.controls).forEach(key => {
          const controlErrors = this.forms.determineHcaPlanForm.get(key)?.errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              if (keyError == 'hasDupe' && controlErrors[keyError] === true)
                this.errorMessage = "Assessment Code already exists. Please use a unique Assessment Code.";
              else if (keyError == 'required' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
            });
          }
        });
        this._formService.showFormMsg("determine-hca-plan-error-message", this.errorMessage, "E");
      }
      catch (e) {
    console.error(e);

      }
    },
  }
}
