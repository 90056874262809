import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, ValidatorFn, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { OspTableComponent } from '../../common/osp-table/osp-table.component';
import { SecurityService } from 'src/app/services/security.service'

// declare let gtag: Function; Uncomment to use custom events

@Component({
  selector: 'payment-frequency-maintenance',
  templateUrl: './payment-frequency-maintenance.component.html',
  styleUrls: ['./payment-frequency-maintenance.component.css']
})
export class PaymentFrequencyMaintenanceComponent implements OnInit, LeaveConfirmation {

  // Event push sample.
  // gtag('event', 'TRACK_ME_BUTTON_CLICKED', {
  //   'event_category': 'BUTTON_CLICK',
  //   'event_label': 'Track Me Click',
  //   'value': 'Put a value here that is meaningful with respect to the click event'   });

  @ViewChild('frequencyTbl') frequencyTbl!: OspTableComponent;

  addUpdateFlag: boolean = true;
  datePipe = new DatePipe('en-us');
  selectedRowComponent: any;
  tempNewData: any = [];
  tempUpdateData: any = [];
  userId: string = this.userDetailService.userId;
  errorMessage: string = '';
  saveDisabler: boolean = true;
  deleteDisabler: boolean = true;
  formHider: boolean = false;
  moduleId: string = 'BMM193';
  public moduleName: string = "";
  activeTags: any = [];

  frequencyTable: any = {
    cols: [
      {
        key: "FREQ_DESC",
        header: "Frequency Description",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "Active",
        dataType: "checkbox-boolean"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 5,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  frequencyForm = this.fb.group({
    bmCode: [''],
    monthCount: ['', [ Validators.required]],
    freqDesc: ['', [Validators.maxLength(1000)]],
    freqUnit: ['', [Validators.maxLength(250)]],
    activeTag: ['', [Validators.required]],
    bmDesc:  [''],
    remarks: ['', [Validators.maxLength(4000)]],
    lastUpdateUser: [{value: '', disabled: true}],
    lastUpdate: [{value: '', disabled: true}],
  });

  constructor(
    private fb: FormBuilder,
    private api: APICallService,
    private dialog: MatDialog,
    private jsonDataService: JsonDataService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    private formService: FormService,
    private securityService: SecurityService
  ) { 
  }

  ngOnInit(): void {
        try {
            this.moduleName = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId === this.moduleId })[0].moduleDesc.toUpperCase();
            this.getFrequency();
        } catch(e) {
          
        }
  }

  canDeactivate(): Observable<boolean> | boolean {
    return false
  }

  getFrequency() {
    try {
      this.frequencyTable.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.api.getFreqList({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE"
      }).subscribe({
        next: (data: any) => {
            this.securityService.checkRequestKeyResponse(data, () => {
              this.securityService.hasValidCsrfToken(data, () => {
                if (data.status === "SUCCESS") {
                  data = JSON.parse(this.jsonDataService.decrypt(data.response));
                  this.jsonDataService.contorlLoading(false);
                  this.frequencyTable.tblData = data;
                  this.frequencyTable.loading = false;
                } else {
                    this.jsonDataService.contorlLoading(false);
                    this.frequencyTable.loading = false;
                    this.appMessageService.showAppMessage(data.message, "error");
                }
              });
            });
        },
        error: () => {
          this.frequencyTable.loading = false;
          this.jsonDataService.contorlLoading(false);
        },
      });
    } catch(e) {
      
    }
  }

  onRowClick(ev: any) {
    try {
      this.selectedRowComponent = ev;
      this.buttonToggler('onRowClick');
      if(ev==null) {
        this.formClearer();
      } else {
        this.formLoader(ev);
      }
    } catch(e) {
      
    }
  }

  formLoader(ev: any) {
    try {
      this.frequencyForm.get('freqCode')?.disable();
      this.frequencyForm.patchValue({
        freqCode: ev.FREQ_CD,
        freqDesc: ev.FREQ_DESC,
        monthCount: ev.MONTH_COUNT,
        freqUnit: ev.FREQ_UNIT,
        activeTag: ev.ACTIVE_TAG,
        bmDesc:  ev.BM_SHORT_DESC,
        remarks: ev.REMARKS,
        lastUpdateUser: ev.USER_ID,
        lastUpdate: this.datePipe.transform(ev.LAST_UPDATE, 'MM/dd/YYYY h:mm:ss a', '+0000'),
        bmCode: ev.BM_CODE,
        
      });
    } catch(e) {
      
    }
  }

  formClearer() {
    try {
      this.frequencyForm.get('freqCode')?.enable();
      this.frequencyForm.patchValue({
        freqCode: '',
        freqDesc: '',
        monthCount: '',
        freqUnit: '',
        activeTag: 0,
        bmDesc: '',
        remarks: '',
        lastUpdateUser: this.userDetailService.userId,
        lastUpdate: '',
        bmCode: ''
      });
      this.selectedRowComponent = '';
    } catch(e) {
    }
  }

  buttonToggler(from: any) {
    try {
      if(from=='onRowClick') {
        this.formService.hideFormMsg("frequency-error-message");
        if(this.selectedRowComponent==null) {
          this.addUpdateFlag = true;
        } else {
          this.addUpdateFlag = false;
          this.deleteDisabler = this.deleteEnabler(this.selectedRowComponent);
        }
      }
      if(from=='addRow') {
        this.addUpdateFlag = true;
        if((this.tempNewData=='' || this.tempNewData==null) && (this.tempUpdateData=='' || this.tempUpdateData==null)) {
          this.saveDisabler = true;
        } else {
          this.saveDisabler = false;
        }
      }
      if(from=='deleteRow') {
        this.deleteDisabler = true;
        this.addUpdateFlag = true;
        if(this.tempNewData=='' || this.tempNewData==null) {
          this.saveDisabler = true;
        } else {
          this.saveDisabler = false;
        }
      }
    } catch(e) {
      
    }
  }

  fieldValidator() {
    try {
      if(this.frequencyForm.invalid) {
        for(const field in this.frequencyForm.controls) {
          const controlName = this.frequencyForm.get(field); //Use to use specific field
          if(controlName?.errors?.required) {
            this.errorMessage = 'There are missing information. Please provide necessary information needed';
          }
          if(controlName?.errors?.frequencyCodeDuplicate) {
            this.errorMessage = 'Frequency already exists. Please add a unique Frequency Code.';
          }
        }
        return false;
      } else {
        this.errorMessage = '';
        return true;
      }
    } catch(e) {
      return true;
    }
  }

  addRow() {
    try {
      if(this.fieldValidator()) {
        this.frequencyTable.tblData.unshift(this.tableAdder());
        this.frequencyTable.tblData = [...this.frequencyTable.tblData];
        this.tempNewData.push({...this.tempFormValue(), userId: this.userDetailService.userId });
        this.formClearer();
        this.buttonToggler('addRow');
        this.formService.hideFormMsg("frequency-error-message");
      } else {
        this.formService.showFormMsg("frequency-error-message", this.errorMessage, "E");
      }
    } catch(e) {
      
    }
  }

  tableAdder() {
    try {
      return {
        FREQ_DESC: this.frequencyForm.get('freqDesc')?.value,
        FREQ_UNIT: this.frequencyForm.get('freqUnit')?.value,
        MONTH_COUNT: this.frequencyForm.get('monthCount')?.value,
        ACTIVE_TAG: this.frequencyForm.get('activeTag')?.value,
        BM_SHORT_DESC: this.frequencyForm.get('bmDesc')?.value,
        REMARKS: this.frequencyForm.get('remarks')?.value,
        BM_CODE: this.frequencyForm.get('bmCode')?.value,
      }
    } catch(e) {
      return {
        FREQ_DESC: this.frequencyForm.get('freqDesc')?.value,
        FREQ_UNIT: this.frequencyForm.get('freqUnit')?.value,
        MONTH_COUNT: this.frequencyForm.get('monthCount')?.value,
        ACTIVE_TAG: this.frequencyForm.get('activeTag')?.value,
        BM_SHORT_DESC: this.frequencyForm.get('bmDesc')?.value,
        REMARKS: this.frequencyForm.get('remarks')?.value,
        BM_CODE: this.frequencyForm.get('bmCode')?.value,
      }
    }
  }

  tempFormValue() {
    try {
      return {
        FREQ_DESC: this.frequencyForm.get('freqDesc')?.value,
        FREQ_UNIT: this.frequencyForm.get('freqUnit')?.value,
        MONTH_COUNT: this.frequencyForm.get('monthCount')?.value,
        ACTIVE_TAG: this.frequencyForm.get('activeTag')?.value,
        BM_SHORT_DESC: this.frequencyForm.get('bmDesc')?.value,
        REMARKS: this.frequencyForm.get('remarks')?.value,
        BM_CODE: this.frequencyForm.get('bmCode')?.value,
        USER_ID: this.userId,
      }
    } catch(e) {
      return {
        FREQ_DESC: this.frequencyForm.get('freqDesc')?.value,
        FREQ_UNIT: this.frequencyForm.get('freqUnit')?.value,
        MONTH_COUNT: this.frequencyForm.get('monthCount')?.value,
        ACTIVE_TAG: this.frequencyForm.get('activeTag')?.value,
        BM_SHORT_DESC: this.frequencyForm.get('bmDesc')?.value,
        REMARKS: this.frequencyForm.get('remarks')?.value,
        BM_CODE: this.frequencyForm.get('bmCode')?.value,
        USER_ID: this.userId,
      }
    }
  }

  deleteEnabler(ev: any) {
    try {
      let tempDataFlag = this.tempNewData.filter(function(e: any) {
        return e.FREQ_CD==ev.FREQ_CD;
      });
      if(tempDataFlag==''||tempDataFlag==null) {
        return true;
      } else {
        return false;
      }
    } catch(e) {
      return false;
    }
  }

  deleteRow() {
    try {
      let clickData = this.selectedRowComponent;
      this.frequencyTable.tblData = this.frequencyTable.tblData.filter(function(e: any) {
        return e.FREQ_DESC != clickData.FREQ_DESC;
      });
      this.tempNewData = this.tempNewData.filter(function(e: any) {
        return e.FREQ_DESC != clickData.FREQ_DESC;
      });
      this.buttonToggler('deleteRow');
      this.formClearer();
    } catch(e) {
      
    }
  }

  onUpdate() {
    try {
      if(this.fieldValidator()) {
        this.updateTable();
        if(this.deleteDisabler) {
          this.updateDbData();
        } else {
          this.updateNewData();
        }
        this.formService.hideFormMsg("frequency-error-message");
        this.buttonToggler('addRow');
        this.formClearer();
        this.deleteDisabler = true;
      } else {
        this.formService.showFormMsg("frequency-error-message", this.errorMessage, "E");
      }
    } catch(e) {
     
    }
  }

  updateTable() {
    try {
      let updateIndex = this.frequencyTable.tblData.indexOf(this.selectedRowComponent);
      this.frequencyTable.tblData[updateIndex] = {
        FREQ_DESC: this.frequencyForm.get('freqDesc')?.value,
        FREQ_UNIT: this.frequencyForm.get('freqUnit')?.value,
        MONTH_COUNT: this.frequencyForm.get('monthCount')?.value,
        ACTIVE_TAG: this.frequencyForm.get('activeTag')?.value,
        BM_SHORT_DESC: this.frequencyForm.get('bmDesc')?.value,
        REMARKS: this.frequencyForm.get('remarks')?.value,
        USER_ID: this.selectedRowComponent.USER_ID,
        LAST_UPDATE: this.selectedRowComponent.LAST_UPDATE,
        BM_CODE: this.frequencyForm.get('bmCode')?.value,
      };
      this.frequencyTable.tblData = [...this.frequencyTable.tblData];
    } catch(e) {
    }
  }

  updateNewData() {
    try {
      let tempNewConfigData = this.tempNewData.find(
        (frequency: { FREQ_DESC: any; }) => frequency.FREQ_DESC == this.frequencyForm.get('freqDesc')?.value
      );
  
      //UPDATES TEMP DATA
      tempNewConfigData.FREQ_DESC = this.frequencyForm.get('freqDesc')?.value;
      tempNewConfigData.FREQ_UNIT = this.frequencyForm.get('freqUnit')?.value;
      tempNewConfigData.MONTH_COUNT = this.frequencyForm.get('monthCount')?.value;
      tempNewConfigData.ACTIVE_TAG = this.frequencyForm.get('activeTag')?.value;
      tempNewConfigData.BM_SHORT_DESC = this.frequencyForm.get('bmDesc')?.value;
      tempNewConfigData.REMARKS = this.frequencyForm.get('remarks')?.value;
      tempNewConfigData.BM_CODE = this.frequencyForm.get('bmCode')?.value;
      tempNewConfigData.USER_ID = this.userId;
    } catch(e) {
    }
  }

  updateDbData() {
    try {
      if(this.tempUpdateData == '' || this.tempUpdateData == null) {
        this.tempUpdateData.push(this.tempFormValue());
      } else {
        let tempDbData = this.tempUpdateData.find(
          (frequency: { FREQ_DESC: any }) => frequency.FREQ_DESC == this.frequencyForm.get('freqDesc')?.value
        );
        if(tempDbData=='' || tempDbData==null) {
          this.tempUpdateData.push(this.tempFormValue());
        } else {
          //UPDATES TEMP DATA
          tempDbData.FREQ_DESC = this.frequencyForm.get('freqDesc')?.value;
          tempDbData.FREQ_UNIT = this.frequencyForm.get('freqUnit')?.value;
          tempDbData.MONTH_COUNT = this.frequencyForm.get('monthCount')?.value;
          tempDbData.ACTIVE_TAG = this.frequencyForm.get('activeTag')?.value;
          tempDbData.BM_SHORT_DESC = this.frequencyForm.get('bmDesc')?.value;
          tempDbData.REMARKS = this.frequencyForm.get('remarks')?.value;
          tempDbData.BM_CODE = this.frequencyForm.get('bmCode')?.value;
          tempDbData.USER_ID = this.userId;
        }
      } 
    } catch(e) {
    }
  }

  onSave() {
    try {
      this.formHider = true;
      this.jsonDataService.contorlLoading(true);
      this.api.savePaymentFrequency(this.tempNewData).subscribe({
            next: (data: any) => {
                this.securityService.checkRequestKeyResponse(data, () => {
                  this.securityService.hasValidCsrfToken(data, () => {
                    if(data.status == 'SUCCESS') {
                      this.api.savePaymentFrequency(this.tempUpdateData).subscribe({
                          next: (res: any) => {
                              this.securityService.checkRequestKeyResponse(res, () => {
                                this.securityService.hasValidCsrfToken(res, () => {
                                  if(res.status == 'SUCCESS') {
                                    this.onComplete('Payment frequency details Successfully saved!');
                                    this.getFrequency();
                                    this.jsonDataService.contorlLoading(false);
                                  } else {
                                      this.openErrorDialog('Saving payment frequency details failed.');
                                      this.jsonDataService.contorlLoading(false);
                                  }
                                });
                              });
                          },
                          error: () => {
                              this.openErrorDialog('Saving payment frequency details failed.');
                          }
                      });
                    } else {
                        this.openErrorDialog('Saving payment frequency details failed.');
                    }
                  });
                });
            },
            error: () => {
                this.openErrorDialog('Saving payment frequency details failed.');
            }
      });
    } catch(e) {
      
    }
  }

  onComplete(message: string) {
    try {
      this.formService.hideFormLoader("frequency-form");
      this.formHider = false;
      this.openSaveDialog(message);
      this.tempUpdateData = [];
      this.tempNewData = [];
      this.buttonToggler('addRow');
    } catch(e) {
      
    }
  }

  openErrorDialog(message: string) {
    try {
      this.formHider = false;
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'ERROR',
          content: message,
        }
      });
    } catch(e) {
      
    }
  }

  openSaveDialog(message: string) {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'SUCCESS',
          content: message,
        }
      });
    } catch(e) {
      
    }
  }

  duplicateFrequencyCode(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
        let frequencyCodeDuplicate = this.frequencyTable.tblData.filter(function(e: any) {
          return e.BM_CODE.trim()==control.value.trim();
        });
        if(frequencyCodeDuplicate!='') {
            return { "frequencyCodeDuplicate": { value: control.value } }
        }
        return null;
    }
  }

  autoUppercase(ev: any) {
    try{
      ev.target.value = ev.target.value.toUpperCase();
    }
    catch(e) {
      
    }
  }

  allowNumericDigitsOnly(event: any) {
    try {
      if (/[0-9.]/.test(String.fromCharCode(event.keyCode))) {
        return true;
      }
      else {
        event.preventDefault();
        return false;
      }
    }
    catch (e) {
      console.error(e);
      return undefined;
    }
  }

}
