import { Component, OnInit } from '@angular/core';

import { APICallService } from 'src/app/services/api-call.service'
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe, formatDate } from '@angular/common';

import { Observable } from 'rxjs';

import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';

@Component({
  selector: 'line-maintenance',
  templateUrl: './line-maintenance.component.html',
  styleUrls: ['./line-maintenance.component.css']
})
export class LineMaintenanceComponent implements OnInit, LeaveConfirmation {

  datePipe = new DatePipe('en-us');

  addDisabled : boolean = false;
  saveDisabled : boolean = true;
  deleteDisabled : boolean = true;
  userIDReadOnly : boolean = false;
  tranPosted : boolean = false;
  noLoadingInProcess : boolean = true;
  validated : boolean = false;
  submitted : boolean = false;
  isLineSelected : boolean = false;
  isSuccessSaved : boolean = false;
  lineCodeField: string = '';
  seqNoField: string = '';
  currentUser: string = this.userDetailService.userId;
  rowClickedData: any;
  effDate = new FormControl(new Date());
  selectedData : any = null;
  moduleId: string = 'BMM029';
  userTypeViewList: any = this.jsonDataService.data.refCds.filter((e: any) => e.rvDomain=="BMM013.USER_TYPE");
  moduleName: string = this.jsonDataService.data.moduleList.filter((e: any) => e.moduleId === this.moduleId)[0]?.moduleDesc?.toUpperCase();

  tempAddedData: any = [];
  tempUpdateData: any = [];

  lineForm = this.fb.group({
    lineCode: [null, [Validators.required, Validators.maxLength(2)]],
    activeTag: ['A', [Validators.required]],
    lineName: [null, [Validators.required]],
    defaultTag: [null, [Validators.required]],
    menuLineCode: [null, [Validators.required, Validators.maxLength(2)]],
    seqNo: [null],
    imagePath: [null],
    userTypeView: [null],
    remarks: [null],
    userID: [null],
    lastUserUpdate: [null],
    mode: [null]
  });

  constructor(
    private _APICallService: APICallService, 
    public _Dialog: MatDialog, 
    private fb: FormBuilder, 
    private formService: FormService, 
    private appMessageService: AppMessageService,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService,
    private userDetailService: UserDetailsService
  ) { }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempAddedData!='' || this.tempUpdateData!='') {
      return false;
    } else {
      return true;
    }
  }

  tblConfigLine: any = {
    cols: [
      {
        key: "LINE_CD",
        header: "Line Code",
        dataType: "string",
        width: '12%'
      },
      {
        key: "LINE_NAME",
        header: "Line Name",
        dataType: "string",
        disabled: false
      },
      {
        key: "SEQ_NO",
        header: "Sequence No.",
        dataType: "string",
        disabled: false
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        disabled: true,
        width: '64px'
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15
  }

  ngOnInit(): void {
    try {
      this._APICallService.refreshNeeded$
      .subscribe(() => {
        this.getAllLines();
      })
      this.getAllLines();
    }
    catch(e) {
      
    }
  }

  initOnGet(): void {
    try {
      this._APICallService.refreshNeeded$
      .subscribe(() => {
        this.getAllLines();
      })
      this.getAllLines();
    }
    catch(e) {
      
    }
  }

  getAllLines() {
    try {
      this.tblConfigLine.loading = true;
      this.jsonDataService.contorlLoading(true);
      this._APICallService.getAllLine({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE"
      }).subscribe((data: any) => {
        this.securityService.checkRequestKeyResponse(data, () => {
          this.securityService.hasValidCsrfToken(data, () => {
            if (data.status === 'SUCCESS') {
                data = JSON.parse(this.jsonDataService.decrypt(data.response));
                data.data = data.data.map((data: any) => {
                  return { ...data, SEQ_NO: data.SEQ_NO === null || data.SEQ_NO === "" ? null : data.SEQ_NO.toString().padStart(2, '0')}
                });
                this.jsonDataService.contorlLoading(false);
                this.tblConfigLine.loading = false;
                this.tblConfigLine.tblData = data.data;
            } else {
                this.jsonDataService.contorlLoading(false);
                this.tblConfigLine.loading = false;
                this.appMessageService.showAppMessage(data.message, "error");
            }
          });
        });
      });
    }
    catch (e) {
    console.error(e);
      
    }
  }

  insertLine() {
    try {
      this.tranPosted = true;
      this.validateInsert();
      if (this.validated) {
        this.addFormToTable();
      }
    }
    catch(e) {
      
    }
  }

  addFormToTable() {
    try {
      this.lineForm.patchValue({
        activeTag: this.lineForm.get('activeTag')?.value,
        userID: this.currentUser,
        lastUserUpdate: new Date()
      });
      let formToData: any = { // this.datePipe.transform(new Date(), 'MM/dd/yyyy')
        LINE_CD: this.lineForm.get('lineCode')?.value.toUpperCase(),
        ACTIVE_TAG: this.lineForm.get('activeTag')?.value,
        LINE_NAME: this.lineForm.get('lineName')?.value,
        DEFAULT_TAG: this.lineForm.get('defaultTag')?.value,
        MENU_LINE_CD: this.lineForm.get('menuLineCode')?.value.toUpperCase(),
        SEQ_NO: this.lineForm.get('seqNo')?.value === null || this.lineForm.get('seqNo')?.value === "" || isNaN(this.lineForm.get('seqNo')?.value) ? null || "" : this.lineForm.get('seqNo')?.value.toString().padStart(2, '0'),
        IMAGE_PATH: this.lineForm.get('imagePath')?.value,
        USER_TYPE_VIEW: this.lineForm.get('userTypeView')?.value,
        REMARKS: this.lineForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.lineForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.lineForm.get('userID')?.value,
        MODE: 'I'
      }
      this.tblConfigLine.tblData.unshift(formToData);
      this.tblConfigLine.tblData = [...this.tblConfigLine.tblData];
      this.tempAddedData.push(formToData);
      this.resetFormValues();
      this.appMessageService.showAppMessage("Line details added!", "success");
    }
    catch(e) {
      
    }
  }

  updateLine() {
    try {
      this.tranPosted = true;
      this.validateUpdate();
      if (this.validated) {
        this.updateFormTable();
      }
    }
    catch(e) {
      
    }
  }

  updateFormTable() {
    try {
      let tempInsertChecker = this.tempAddedData.find(
        (line: { LINE_CD: any; }) => line.LINE_CD == this.lineForm.get('lineCode')?.value);
      let tempUpdateChecker = this.tempUpdateData.find(
        (line: { LINE_CD: any; }) => line.LINE_CD == this.lineForm.get('lineCode')?.value);
      
      this.tableDataUpdate();
        
      // Adds Updated Data that exists in the database to an object array for updated datas
      if(tempInsertChecker!=null && tempInsertChecker!='') {
        //this.tempUpdateData.push(this.formValueToData());
        tempInsertChecker.LINE_CD = this.lineForm.get('lineCode')?.value.toUpperCase();
        tempInsertChecker.ACTIVE_TAG = this.lineForm.get('activeTag')?.value;
        tempInsertChecker.LINE_NAME = this.lineForm.get('lineName')?.value;
        tempInsertChecker.DEFAULT_TAG = this.lineForm.get('defaultTag')?.value;
        tempInsertChecker.MENU_LINE_CD = this.lineForm.get('menuLineCode')?.value.toUpperCase();
        tempInsertChecker.SEQ_NO = this.lineForm.get('seqNo')?.value;
        tempInsertChecker.IMAGE_PATH = this.lineForm.get('imagePath')?.value;
        tempInsertChecker.USER_TYPE_VIEW = this.lineForm.get('userTypeView')?.value;
        tempInsertChecker.REMARKS = this.lineForm.get('remarks')?.value;
        tempInsertChecker.LAST_USER_UPDATE = this.datePipe.transform(new Date(this.lineForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a');
        tempInsertChecker.USER_ID = this.lineForm.get('userID')?.value;
        tempInsertChecker.MODE = 'I';
      } 
      else if(tempUpdateChecker!=null && tempUpdateChecker!='') {
        tempUpdateChecker.LINE_CD = this.lineForm.get('lineCode')?.value.toUpperCase();
        tempUpdateChecker.ACTIVE_TAG = this.lineForm.get('activeTag')?.value;
        tempUpdateChecker.LINE_NAME = this.lineForm.get('lineName')?.value;
        tempUpdateChecker.DEFAULT_TAG = this.lineForm.get('defaultTag')?.value;
        tempUpdateChecker.MENU_LINE_CD = this.lineForm.get('menuLineCode')?.value.toUpperCase();
        tempUpdateChecker.SEQ_NO = this.lineForm.get('seqNo')?.value;
        tempUpdateChecker.IMAGE_PATH = this.lineForm.get('imagePath')?.value;
        tempUpdateChecker.USER_TYPE_VIEW = this.lineForm.get('userTypeView')?.value;
        tempUpdateChecker.REMARKS = this.lineForm.get('remarks')?.value;
        tempUpdateChecker.LAST_USER_UPDATE = this.datePipe.transform(new Date(this.lineForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a');
        tempUpdateChecker.USER_ID = this.lineForm.get('userID')?.value;
        tempUpdateChecker.MODE = 'U';
      }
      else {
        this.tempUpdateData.push(this.formValueToData());
      }
      this.saveDisabled = false;
      this.resetFormValues();
      this.appMessageService.showAppMessage("Line details updated!", "success");
    }
    catch(e) {
      
    }
  }

  tableDataUpdate() {
    try {
      this.lineForm.patchValue({
        activeTag: this.lineForm.get('activeTag')?.value,
        userID: this.currentUser,
        lastUserUpdate: new Date()
      });
      let updateIndex = this.tblConfigLine.tblData.indexOf(this.rowClickedData);
      this.tblConfigLine.tblData[updateIndex] = {
        LINE_CD: this.lineForm.get('lineCode')?.value.toUpperCase(),
        ACTIVE_TAG: this.lineForm.get('activeTag')?.value,
        LINE_NAME: this.lineForm.get('lineName')?.value,
        DEFAULT_TAG: this.lineForm.get('defaultTag')?.value,
        MENU_LINE_CD: this.lineForm.get('menuLineCode')?.value.toUpperCase(),
        SEQ_NO: this.lineForm.get('seqNo')?.value === null || this.lineForm.get('seqNo')?.value === "" || isNaN(this.lineForm.get('seqNo')?.value) ? null || "" : this.lineForm.get('seqNo')?.value.toString().padStart(2, '0'),
        IMAGE_PATH: this.lineForm.get('imagePath')?.value,
        USER_TYPE_VIEW: this.lineForm.get('userTypeView')?.value,
        REMARKS: this.lineForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.lineForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.lineForm.get('userID')?.value,
        MODE: 'U'
      };
      this.tblConfigLine.tblData = [...this.tblConfigLine.tblData];
    }
    catch(e) {
      
    }
  }

  formValueToData() {
    try {
      let formToData: any = { // this.datePipe.transform(new Date(), 'MM/dd/yyyy')
        LINE_CD: this.lineForm.get('lineCode')?.value.toUpperCase(),
        ACTIVE_TAG: this.lineForm.get('activeTag')?.value,
        LINE_NAME: this.lineForm.get('lineName')?.value,
        DEFAULT_TAG: this.lineForm.get('defaultTag')?.value,
        MENU_LINE_CD: this.lineForm.get('menuLineCode')?.value.toUpperCase(),
        SEQ_NO: this.lineForm.get('seqNo')?.value,
        IMAGE_PATH: this.lineForm.get('imagePath')?.value,
        USER_TYPE_VIEW: this.lineForm.get('userTypeView')?.value,
        REMARKS: this.lineForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.lineForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.lineForm.get('userID')?.value,
        MODE: 'U'
      }
      return formToData;
    }
    catch(e) {
      
    }
  }

  saveAddedData() {
    this._APICallService.saveLine(this.tempAddedData)
        .subscribe({
          next: (response : any) => {
            this.securityService.checkRequestKeyResponse(response, () => {
              this.securityService.hasValidCsrfToken(response, () => {
                if (response.status == "SUCCESS" && this.tranPosted) {
                      if(this.tempUpdateData.length!=0) {
                          this.saveUpdateData();
                      } else {
                          this.openDialog('SUCCESS', 'New line details Successfully saved!');
                          this.jsonDataService.contorlLoading(false);
                          this.initOnGet();
                          this.tempAddedData = [];
                          this.tempUpdateData = [];
                          this.resetFormValues();
                      }
                }   
                else {
                  this.openDialog('ERROR', 'An error has occured while saving details for new line');
                }
              });
            });
          }, 
          error: () => {
            this.openDialog('ERROR', 'An error has occured while saving details for new line');
          }
        });
  }

  saveUpdateData() {
    this._APICallService.saveLine(this.tempUpdateData)
        .subscribe({
          next: (response : any) => {
            this.securityService.checkRequestKeyResponse(response, () => {
              this.securityService.hasValidCsrfToken(response, () => {
                if (response.status == "SUCCESS" && this.tranPosted) {
                  this.jsonDataService.contorlLoading(false);
                  this.openDialog('SUCCESS', 'Existing line details successfully saved!');
                  this.initOnGet();
                  this.tempAddedData = [];
                  this.tempUpdateData = [];
                  this.resetFormValues();
                }  
                else {
                  this.openDialog('ERROR', 'An error has occured while saving details for existing line');
                }
              });
            });
          }, 
          error: () => {
            this.openDialog('ERROR', 'An error has occured while saving details for existing line');
          }
        });
  }

  toSave() {
    try {
      this.noLoadingInProcess = false;
      this.jsonDataService.contorlLoading(true);
      this.formService.showFormLoader(null, "generate-line-form", "Please wait ...", null, null);
      if(this.tempAddedData.length!=0) {
            this.saveAddedData();
      } else {
        if(this.tempUpdateData.length!=0) {
            this.saveUpdateData();
        } else {
            this.initOnGet();
            this.resetFormValues();
            this.tempAddedData = [];
            this.tempUpdateData = [];
        }
      }
    }
    catch(e) {
      
    }
  }

  deleteLine() {
    try {
      let clickData = this.rowClickedData;
      this.tblConfigLine.tblData = this.tblConfigLine.tblData.filter(
        function(e: any) {
          return e.LINE_CD != clickData.LINE_CD;
        }
      );
      this.tempAddedData = this.tempAddedData.filter(
        function(e: any) {
          return e.LINE_CD != clickData.LINE_CD;
        }
      );
      this.resetFormValues();
    }
    catch(e) {
      
    }
  }

  saveChecker() {
    try {
      if((this.tempAddedData==null || this.tempAddedData=='') && (this.tempUpdateData==null || this.tempUpdateData=='')) {
        return true;
      }
      else {
        return false;
      }
    }
    catch(e) {
      return true;
    }
  }

  deleteChecker(ev: any) {
    try {
      let tempDataFlag;
      tempDataFlag = this.tempAddedData.filter(function(e: any) {
        return e.LINE_CD==ev.LINE_CD;
      });
      if(tempDataFlag==''||tempDataFlag==null) {
        return true;
      } else {
        return false;
      }
    }
    catch(e) {
      return true;
    }
  }

  onRowClick(ev: any) {
    try {    
      if(ev!=null) {
        this.rowClickedData = ev;
        this.lineForm.get('lineCode')?.disable();
        this.loadFormValues();
        this.saveDisabled = this.saveChecker();
        this.deleteDisabled = this.deleteChecker(ev);
      }
      else {
        this.resetFormValues();
      }
    }
    catch(e) {
      
    }

  }

  loadFormValues() {
    try {
      this.lineForm.patchValue({
        lineCode: this.rowClickedData.LINE_CD,
        activeTag: this.rowClickedData.ACTIVE_TAG,
        lineName: this.rowClickedData.LINE_NAME,
        defaultTag: this.rowClickedData.DEFAULT_TAG,
        menuLineCode: this.rowClickedData.MENU_LINE_CD,
        seqNo: this.rowClickedData.SEQ_NO,
        imagePath: this.rowClickedData.IMAGE_PATH,
        userTypeView: this.rowClickedData.USER_TYPE_VIEW,
        remarks: this.rowClickedData.REMARKS,
        userID: this.rowClickedData.USER_ID,   
        lastUserUpdate: this.rowClickedData.LAST_USER_UPDATE
      });
      this.addDisabled = true;
      this.userIDReadOnly = true;
      this.isLineSelected = true;
    }
    catch(e) {
      
    }
  }

  resetFormValues() {
    try {
      this.selectedData = null;
      this.lineForm.get('lineCode')?.enable();
      this.lineForm.patchValue({
        lineCode: null,
        activeTag: 'A',
        lineName: null,
        defaultTag: null,
        menuLineCode: null,
        seqNo: null,
        imagePath: null,
        userTypeView: null,
        remarks: null,
        userID: null,   
        lastUserUpdate: null
      });

      this.addDisabled = false;
      this.saveDisabled = this.saveChecker();
      this.deleteDisabled = true;
      this.userIDReadOnly = false;
      this.isLineSelected = false;

      this.rowClickedData = '' || null;
    }
    catch (e) {
    console.error(e);
      
    }
  }

  openDialog(title: any, message: any) {
    try {
      this.noLoadingInProcess = true;
      this.formService.hideFormLoader("generate-line-form");
      this._Dialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        } 
      });
    } catch(e) {
      
    }
  }

  validateInsert() {
    try {
      this.validated = false;
      if (!this.requiredFieldCheck()){
        // this.openDialog('ERROR', 'Kindly fill-out required fields.');
        setTimeout(()=>{                           //10 secs countdown
          this.formService.hideFormMsg("line-error-message");
        }, 10000);
      }
      else if (!this.lineCodeDupCheck()) {
        // this.openDialog('ERROR', 'Line code already exists. Please add a unique Line Code.');
        setTimeout(()=>{                           
          this.formService.hideFormMsg("line-error-message");
        }, 10000);
      }
      else if (!this.sequenceNoDupCheck()) {
        // this.openDialog('ERROR', 'Sequence number already exists. Please add a unique Sequence Number.');
        setTimeout(()=>{                           
          this.formService.hideFormMsg("line-error-message");
        }, 10000);
      }
      else if (!this.sequenceNoPositiveCheck()) {
        setTimeout(()=>{                           
          this.formService.hideFormMsg("line-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
      
    }
    
  }

  validateUpdate() {
    try {
      this.validated = false;
      if (!this.requiredFieldCheck()){
        // this.openDialog('ERROR', 'There are missing information. Please provide the necessary information needed.');
        setTimeout(()=>{                          
          this.formService.hideFormMsg("line-error-message");
        }, 10000);
      }
      else if (!this.sequenceNoDupCheck()) {
        // this.openDialog('ERROR', 'Sequence number already exists. Please add a unique Sequence Number.');
        setTimeout(()=>{                           
          this.formService.hideFormMsg("line-error-message");
        }, 10000);
      }
      else if (!this.sequenceNoPositiveCheck()) {
        setTimeout(()=>{                           
          this.formService.hideFormMsg("line-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
      
    }
  }

  lineCodeDupCheck() {
    try {
      let lineCodeForm = this.lineForm.get('lineCode')?.value.toUpperCase();
      this.lineCodeField = this.tblConfigLine.tblData.filter(function(e: any) {
        return e.LINE_CD==lineCodeForm;
      });
      if(this.lineCodeField=='' || this.lineCodeField==null) {
        return true;
      } else {
        this.formService.showFormMsg("line-error-message", 'Line Code already exists. Please add a unique Line Code.', "E");
        return false;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }  

  sequenceNoDupCheck() {
    try {
      let lineCodeForm = this.lineForm.get("lineCode")?.value.toUpperCase()
      let seqNoForm = this.lineForm.get("seqNo")?.value;
      this.seqNoField = this.tblConfigLine.tblData.filter((data: any) => {
        return data.SEQ_NO == seqNoForm && lineCodeForm != data.LINE_CD && seqNoForm !== null;
      });
      if(this.seqNoField=='' || this.seqNoField==null) {
        return true
      } else {
        this.formService.showFormMsg("line-error-message", 'Sequence No. already exists. Please choose a different Sequence No.', "E");
        return false;
      }
    } catch(e) {
      return false;
    }
  }

  sequenceNoPositiveCheck() {
    try {
      let seqNoForm = this.lineForm.get("seqNo")?.value;
      if(!(seqNoForm < 0)) {
        return true
      } else {
        this.formService.showFormMsg("line-error-message", 'Sequence No. only accepts posivite number. Please choose positive number only', "E");
        return false;
      }
    } catch(e) {
      return false;
    }
  }

  requiredFieldCheck() {
    try {
      if(
        (this.lineForm.get('lineCode')?.value ?? '').trim().length === 0 || 
        (this.lineForm.get('activeTag')?.value ?? '').trim().length === 0 || 
        (this.lineForm.get('lineName')?.value ?? '').trim().length === 0 || 
        (this.lineForm.get('menuLineCode')?.value ?? '').trim().length === 0 || 
        (this.lineForm.get('defaultTag')?.value ?? '').trim().length === 0
      ) {
        this.formService.showFormMsg("line-error-message", 'There are missing information. Please provide the necessary information needed.', "E");
        return false;
      }
      else {
        return true;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }

  seqNoValidation(ev: any) {
    try {
      const rawSeqNo = ev.value;
      const seqNo = +ev.value;
      if((seqNo < 1 || !Number.isInteger(seqNo)) && !(rawSeqNo === "" || rawSeqNo === null)) {
        this.lineForm.get("seqNo")?.setValue("");
      } else {
        this.lineForm.get("seqNo")?.setValue(parseInt(rawSeqNo));
      }
    } catch(e) {
      
    }
  }
}