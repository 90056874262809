import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { SecurityService } from 'src/app/services/security.service';
import { CustomValidators } from 'src/app/utils/custom-validator';

@Component({
  selector: 'assure-id-type-maintenance',
  templateUrl: './assure-id-type-maintenance.component.html',
  styleUrls: ['./assure-id-type-maintenance.component.css']
})
export class AssureIdTypeMaintenanceComponent implements OnInit, LeaveConfirmation {

  clientTypeList: any = this.jsonDataService.data.refCds.filter((e: any) => e.rvDomain == 'BMM043.BR_TAG'); //Drop Down Values
  selectedRowData: any;
  errorMessage: string = "";
  userId = this.jsonDataService.retrieveFromStorage('userId');
  formHider: boolean = false;
  datePipe = new DatePipe('en-ph');
  moduleId = 'BMM050';
  moduleName = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId === 'BMM050' })[0].moduleDesc.toUpperCase();

  //Button Togglers
  addUpdateToggler: boolean = true;
  deleteDisabler: boolean = true;
  saveDisabler: boolean = true;

  //Temp Data
  tempAddData: any = [];
  tempUpdateData: any = [];

  assuredIdTypeTable: any = {
    cols: [
      {
        key: "ID_TYPE_CD",
        header: "ID Type Code",
        dataType: "string"
      },
      {
        key: "ID_TYPE_DESC",
        header: "ID Type Description",
        dataType: "string"
      },
      {
        key: "RV_MEANING",
        header: "Client Type",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "Active",
        dataType: "checkbox"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  constructor(
    private jsonDataService: JsonDataService,
    private fb: FormBuilder,
    private api: APICallService,
    private formService: FormService,
    private userDetailService: UserDetailsService,
    private appMessageService: AppMessageService,
    private dialog: MatDialog,
    private securityService: SecurityService
  ) { }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempAddData!='' || this.tempUpdateData!='') {
      return false;
    } else {
      return true;
    }
  }

  assuredIdTypeForm = this.fb.group({
    ACTIVE_TAG    : ['A', [Validators.required]],
    BM_CODE       : ['', [Validators.required, CustomValidators.requiredTrim]],
    BR_TAG        : [''],
    ID_TYPE_CD    : [{ value: '', disabled: true }],
    ID_TYPE_DESC  : ['', [Validators.required, this.duplicateIdTypeDesc(), CustomValidators.requiredTrim]],
    LAST_UPDATE   : [{ value: '', disabled: true }],
    PRIMARY_TAG   : ['N', [Validators.required]],
    OCR_TAG       : ['', [Validators.required]],
    WITH_EXP_DATE : ['', [Validators.required]],
    REMARKS       : [''],
    USER_ID       : [{ value: '', disabled: true }],
  });

  ngOnInit(): void {
    try {
      this.getAssuredIdType();
    } catch(e) {
      
    }
  }

  getAssuredIdType() {
    try{
      this.assuredIdTypeTable.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.api.getAllAssuredIdType({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE"
      }).subscribe((data: any) => {
            this.securityService.checkRequestKeyResponse(data, () => {
              this.securityService.hasValidCsrfToken(data, () => {
                if (data.status === "SUCCESS") {
                  data = JSON.parse(this.jsonDataService.decrypt(data.response));
                  this.jsonDataService.contorlLoading(false);
                  data = data.map((data: any) => ({ ...data, RV_MEANING: this.jsonDataService.data.refCds.filter((e: any) => e.rvDomain == 'BMM043.BR_TAG' && e.rvLowValue == data.BR_TAG)[0]?.rvMeaning }));
                  this.assuredIdTypeTable.tblData = data;
                  this.assuredIdTypeTable.loading = false;
                } else {
                    this.jsonDataService.contorlLoading(false);
                    this.assuredIdTypeTable.loading = false;
                    this.appMessageService.showAppMessage(data.message, "error");
                }
              });
            });
      });
    } catch(e) {
      
    }
  }

  onRowClick(ev: any) {
    try {
      if(ev!=null) {
        this.selectedRowData = ev;
        this.formLoader();
        this.addUpdateToggler = false;
      } else {
        this.selectedRowData = null;
        this.formClearer();
        this.addUpdateToggler = true;
      }
      this.deleteEnabler();
    } catch(e) {
      
    }
  }

  formLoader() {
    try {
      this.assuredIdTypeForm.patchValue({
        ACTIVE_TAG    : this.selectedRowData.ACTIVE_TAG,
        BM_CODE       : this.selectedRowData.BM_CODE,
        BR_TAG        : this.selectedRowData.BR_TAG,
        ID_TYPE_CD    : this.selectedRowData.ID_TYPE_CD,
        ID_TYPE_DESC  : this.selectedRowData.ID_TYPE_DESC,
        LAST_UPDATE   : this.datePipe.transform(this.selectedRowData.LAST_UPDATE, 'MM/dd/YYYY h:mm:ss a', '+0000'),
        PRIMARY_TAG   : this.selectedRowData.PRIMARY_TAG,
        OCR_TAG       : this.selectedRowData.OCR_TAG,
        WITH_EXP_DATE : this.selectedRowData.WITH_EXP_DATE,
        REMARKS       : this.selectedRowData.REMARKS,
        USER_ID       : this.selectedRowData.USER_ID,
      });
    } catch(e) {
      
    }
  }

  formClearer() {
    try {
      this.assuredIdTypeForm.patchValue({
        ACTIVE_TAG    : 'A',
        BM_CODE       : '',
        BR_TAG        : '',
        ID_TYPE_CD    : '',
        ID_TYPE_DESC  : '',
        LAST_UPDATE   : '',
        PRIMARY_TAG   : 'N',
        OCR_TAG       : '',
        WITH_EXP_DATE : '',
        REMARKS       : '',
        USER_ID       : '',
      });
    } catch(e) {
      
    }
  }

  addRow() {
    try {
      if(this.formValidator()) {
        this.tableInserter();
        this.tempAddInserted();
        this.formClearer();
        this.saveDisabler = false;
      }
    } catch(e) {
      
    }
  }

  tableInserter() {
    try {
      let clientType:any = this.jsonDataService.data.refCds.filter((e: any) => e.rvDomain == 'BMM043.BR_TAG' && e.rvLowValue == this.assuredIdTypeForm.get('BR_TAG')?.value);
      this.assuredIdTypeTable.tblData.unshift({
        ACTIVE_TAG    : this.assuredIdTypeForm.get('ACTIVE_TAG')?.value,
        BM_CODE       : this.assuredIdTypeForm.get('BM_CODE')?.value,
        BR_TAG        : this.assuredIdTypeForm.get('BR_TAG')?.value,
        ID_TYPE_CD    : this.assuredIdTypeForm.get('ID_TYPE_CD')?.value,
        ID_TYPE_DESC  : this.assuredIdTypeForm.get('ID_TYPE_DESC')?.value,
        PRIMARY_TAG   : this.assuredIdTypeForm.get('PRIMARY_TAG')?.value,
        OCR_TAG       : this.assuredIdTypeForm.get('OCR_TAG')?.value,
        WITH_EXP_DATE : this.assuredIdTypeForm.get('WITH_EXP_DATE')?.value,
        REMARKS       : this.assuredIdTypeForm.get('REMARKS')?.value,
        RV_MEANING    : clientType[0] == null ? '' : clientType[0].rvMeaning,
      });
      this.assuredIdTypeTable.tblData = [...this.assuredIdTypeTable.tblData];
    } catch(e) {
      
    }
  }

  tempAddInserted() {
    try {
      this.tempAddData.push({
        ACTIVE_TAG   : this.assuredIdTypeForm.get('ACTIVE_TAG')?.value,
        BM_CODE      : this.assuredIdTypeForm.get('BM_CODE')?.value,
        BR_TAG       : this.assuredIdTypeForm.get('BR_TAG')?.value,
        ID_TYPE_CD   : this.assuredIdTypeForm.get('ID_TYPE_CD')?.value,
        ID_TYPE_DESC : this.assuredIdTypeForm.get('ID_TYPE_DESC')?.value,
        PRIMARY_TAG  : this.assuredIdTypeForm.get('PRIMARY_TAG')?.value,
        OCR_TAG      : this.assuredIdTypeForm.get('OCR_TAG')?.value,
        WITH_EXP_DATE: this.assuredIdTypeForm.get('WITH_EXP_DATE')?.value,
        REMARKS      : this.assuredIdTypeForm.get('REMARKS')?.value,
        USER_ID      : this.userId,
      });
    } catch(e) {
      
    }
  }

  tempUpdateInserter() {
    try {
      this.tempUpdateData.push({
        ACTIVE_TAG  : this.assuredIdTypeForm.get('ACTIVE_TAG')?.value,
        BM_CODE     : this.assuredIdTypeForm.get('BM_CODE')?.value,
        BR_TAG      : this.assuredIdTypeForm.get('BR_TAG')?.value,
        ID_TYPE_CD    : this.assuredIdTypeForm.get('ID_TYPE_CD')?.value,
        ID_TYPE_DESC: this.assuredIdTypeForm.get('ID_TYPE_DESC')?.value,
        PRIMARY_TAG : this.assuredIdTypeForm.get('PRIMARY_TAG')?.value,
        OCR_TAG      : this.assuredIdTypeForm.get('OCR_TAG')?.value,
        WITH_EXP_DATE: this.assuredIdTypeForm.get('WITH_EXP_DATE')?.value,
        REMARKS     : this.assuredIdTypeForm.get('REMARKS')?.value,
        USER_ID     : this.userId,
      });
    } catch(e) {
      
    }
  }

  onUpdate() {
    try {
      if(this.formValidator()) {
        if(this.deleteDisabler) {
          this.updateDbData();
          this.saveDisabler = false;
        } else {
          this.updateNewData();
        }
        this.updateTable();
        this.selectedRowData = '';
        this.addUpdateToggler = true;
        this.deleteEnabler();
        this.formClearer();
      }
    } catch(e) {
      
    }
  }

  updateTable() {
    try {
      let clientType:any = this.jsonDataService.data.refCds.filter((e: any) => e.rvDomain == 'BMM043.BR_TAG' && e.rvLowValue == this.assuredIdTypeForm.get('BR_TAG')?.value);
      let updateIndex = this.assuredIdTypeTable.tblData.indexOf(this.selectedRowData);
      this.assuredIdTypeTable.tblData[updateIndex] = {
        ACTIVE_TAG   : this.assuredIdTypeForm.get('ACTIVE_TAG')?.value,
        BM_CODE      : this.assuredIdTypeForm.get('BM_CODE')?.value,
        BR_TAG       : this.assuredIdTypeForm.get('BR_TAG')?.value,
        ID_TYPE_CD   : this.assuredIdTypeForm.get('ID_TYPE_CD')?.value,
        ID_TYPE_DESC : this.assuredIdTypeForm.get('ID_TYPE_DESC')?.value,
        PRIMARY_TAG  : this.assuredIdTypeForm.get('PRIMARY_TAG')?.value,
        OCR_TAG      : this.assuredIdTypeForm.get('OCR_TAG')?.value,
        WITH_EXP_DATE: this.assuredIdTypeForm.get('WITH_EXP_DATE')?.value,
        REMARKS      : this.assuredIdTypeForm.get('REMARKS')?.value,
        RV_MEANING   : clientType[0] == null ? '' : clientType[0].rvMeaning,
      }
      this.assuredIdTypeTable.tblData = [...this.assuredIdTypeTable.tblData];
    } catch(e) {
      
    }
  }

  updateNewData() {
    try {
      let tempNewConfigData = this.tempAddData.find(
        (assuredIdType: { ID_TYPE_DESC: any }) => assuredIdType.ID_TYPE_DESC == this.selectedRowData.ID_TYPE_DESC
      );
      tempNewConfigData.ACTIVE_TAG   = this.assuredIdTypeForm.get('ACTIVE_TAG')?.value;
      tempNewConfigData.BM_CODE      = this.assuredIdTypeForm.get('BM_CODE')?.value;
      tempNewConfigData.BR_TAG       = this.assuredIdTypeForm.get('BR_TAG')?.value;
      tempNewConfigData.ID_TYPE_DESC = this.assuredIdTypeForm.get('ID_TYPE_DESC')?.value;
      tempNewConfigData.PRIMARY_TAG  = this.assuredIdTypeForm.get('PRIMARY_TAG')?.value;
      tempNewConfigData.OCR_TAG      = this.assuredIdTypeForm.get('OCR_TAG')?.value;
      tempNewConfigData.WITH_EXP_DATE= this.assuredIdTypeForm.get('WITH_EXP_DATE')?.value
      tempNewConfigData.REMARKS      = this.assuredIdTypeForm.get('REMARKS')?.value;
      tempNewConfigData.USER_ID      = this.userId;
    } catch(e) {
      
    }
  }

  updateDbData() {
    try {
      if(this.tempUpdateData=='' || this.tempUpdateData==null) {
        this.tempUpdateInserter();
      } else {
        let tempDbData = this.tempUpdateData.find(
          (assuredIdType: { ID_TYPE_CD: any }) => assuredIdType.ID_TYPE_CD == this.assuredIdTypeForm.get('ID_TYPE_CD')?.value
        );
        if(tempDbData=='' || tempDbData==null) {
          this.tempUpdateInserter();
        } else {
          tempDbData.ACTIVE_TAG   = this.assuredIdTypeForm.get('ACTIVE_TAG')?.value;
          tempDbData.BM_CODE      = this.assuredIdTypeForm.get('BM_CODE')?.value;
          tempDbData.BR_TAG       = this.assuredIdTypeForm.get('BR_TAG')?.value;
          tempDbData.ID_TYPE_DESC = this.assuredIdTypeForm.get('ID_TYPE_DESC')?.value;
          tempDbData.PRIMARY_TAG  = this.assuredIdTypeForm.get('PRIMARY_TAG')?.value;
          tempDbData.OCR_TAG      = this.assuredIdTypeForm.get('OCR_TAG')?.value;
          tempDbData.WITH_EXP_DATE= this.assuredIdTypeForm.get('WITH_EXP_DATE')?.value
          tempDbData.REMARKS      = this.assuredIdTypeForm.get('REMARKS')?.value;
          tempDbData.USER_ID      = this.userId;
        }
      }
    } catch(e) {
      
    }
  }

  deleteTableRow() {
    try {
      this.assuredIdTypeTable.tblData = this.assuredIdTypeTable.tblData.filter((e: any) => {
        return e.ID_TYPE_DESC != this.selectedRowData.ID_TYPE_DESC
      });
      this.tempAddData = this.tempAddData.filter((e: any) => {
        return e.ID_TYPE_DESC != this.selectedRowData.ID_TYPE_DESC
      });
      this.selectedRowData = '';
      this.formClearer();
      this.deleteDisabler = true;
      this.addUpdateToggler = true;
      if((this.tempAddData=='' || this.tempAddData==null) && (this.tempUpdateData=='' || this.tempUpdateData==null)) {
        this.saveDisabler = true;
      }
    } catch(e) {
      
    }
  }

  onSave() {
    try {
        this.formHider = true;
        this.jsonDataService.contorlLoading(true);
        this.formService.showFormLoader(null, "assured-id-type-form", "Saving.<br>Please wait ...", null, null);
        
        this.api.saveAssuredIdType(this.tempAddData).subscribe({
            next: (data: any) => {
                this.securityService.checkRequestKeyResponse(data, () => {
                  this.securityService.hasValidCsrfToken(data, () => {
                    if(data.status == 'SUCCESS') {
                      this.api.saveAssuredIdType(this.tempUpdateData).subscribe({
                          next: (res: any) => {
                              this.securityService.checkRequestKeyResponse(res, () => {
                                this.securityService.hasValidCsrfToken(res, () => {
                                  if(res.status == 'SUCCESS') {
                                    this.onComplete();
                                    this.getAssuredIdType();
                                  } else {
                                      this.jsonDataService.contorlLoading(false);
                                      this.openErrorDialog('Saving assured ID type details failed.');
                                  }
                                });
                              });
                          },
                          error: () => {
                              this.openErrorDialog('Saving assured ID type details failed.');
                              this.jsonDataService.contorlLoading(false);
                          }
                      });
                    } else {
                        this.openErrorDialog('Saving assured ID type details failed.');
                        this.jsonDataService.contorlLoading(false);
                    }
                  });
                });
            },
            error: () => {
                this.openErrorDialog('Saving assured ID type details failed.');
                this.jsonDataService.contorlLoading(false);
            }
        });
        this.saveDisabler = true;
      
      /*forkJoin({
        newData: this.api.saveAssuredIdType(this.tempAddData),
        dbData: this.api.saveAssuredIdType(this.tempUpdateData),
      }).subscribe({
        next: (data: any) => {
            this.securityService.checkRequestKeyResponse(data, () => {
                if(data.newData.status == 'SUCCESS' && data.dbData.status == 'SUCCESS') {
                    this.onComplete();
                    this.getAssuredIdType();
                } else {
                    this.openErrorDialog('Saving assured ID type details failed.');
                }
            });
        },
        error: () => {
          this.openErrorDialog('Saving assured ID type details failed.');
        }
      });*/
    } catch(e) {
      
    }
  }

  onComplete() {
    try {
      this.formService.hideFormLoader("assured-id-type-form");
      this.jsonDataService.contorlLoading(false);
      this.formHider = false;
      this.openSaveDialog();
      this.tempAddData = [];
      this.tempUpdateData = [];
      this.deleteEnabler();
      this.formClearer();
    } catch(e) {
      
    }
  }

  openErrorDialog(message: string) {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'ERROR',
          content: message,
        }
      });
    } catch(e) {
      
    }
  }

  openSaveDialog() {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'SUCCESS',
          content: 'Assured ID type details Successfully saved!',
        }
      });
    } catch(e) {
      
    }
  }

  deleteEnabler() {
    try {
      if(this.selectedRowData != null) {
        let tempDeleteFlag = this.tempAddData.filter((e: any) => {
          return e.ID_TYPE_DESC == this.selectedRowData.ID_TYPE_DESC
        });
        if(tempDeleteFlag=='' || tempDeleteFlag==null) {
          this.deleteDisabler = true;
        } else {
          this.deleteDisabler = false;
        }
      } else {
        this.deleteDisabler = true;
      }
    } catch(e) {
      
    }
  }

  formValidator() {
    try {
      if(this.assuredIdTypeForm.invalid){
        for(const field in this.assuredIdTypeForm.controls) {
          const controlName = this.assuredIdTypeForm.get(field);
          if(controlName?.errors?.required) {
            this.errorMessage = 'There are missing information. Please provide necessary information needed';
          }
          if(controlName?.errors?.idTypeDuplicateCode) {
            this.errorMessage = 'ID Type Description already exists. Please add a unique ID Type Description.';
          }
        }
        this.formService.showFormMsg("assure-id-type-error-message", this.errorMessage, "E");
        return false;
      } else {
        this.formService.hideFormMsg("assure-id-type-error-message");
        return true;
      }
    } catch(e) {
      return true;
    }
  }

  duplicateIdTypeDesc(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const selectedRow = this.selectedRowData;
      let idTypeDescDuplicate  = this.assuredIdTypeTable.tblData.filter(function(e: any) {
        return e.ID_TYPE_DESC.toLowerCase().trim() == control.value.toLowerCase().trim() &&
               control.value != selectedRow?.ID_TYPE_DESC;
      });
      if(idTypeDescDuplicate!='') {
        return { "idTypeDuplicateCode": { value: control.value } }
      }
      return null;
    }
  }

}
