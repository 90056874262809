import { DatePipe } from '@angular/common';
import { Component, OnInit, Type } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';
import { PlanLovComponent } from '../../common/plan-lov/plan-lov.component';
import { CheckLovComponent } from 'src/app/components/common/guard/check-lov/check-lov.component';
import { CustomValidators } from 'src/app/utils/custom-validator';

interface RowData {
    quickLinks: any
}

@Component({
    selector: 'quick-links-maintenance',
    templateUrl: './quick-links-maintenance.html',
    styleUrls: ['./quick-links-maintenance.css']
})
export class QuickLinksMaintenanceComponent implements OnInit, LeaveConfirmation {

    moduleId: string = 'BMM042';
    public moduleName: string = "";
    toBeDeleted: any = [];

    constructor(
        private fb: FormBuilder,
        private _matDialog: MatDialog,
        private _api: APICallService,
        private _formService: FormService,
        private _jsonDS: JsonDataService,
        private _securityService: SecurityService,
        private _userDetailService: UserDetailsService,
        private _appMessageService: AppMessageService
    ) {
        this.userIdLoggedIn = this._userDetailService.userId || "";
    }

    ngOnInit(): void {
        this.moduleName = this._jsonDS.data.moduleList.filter((data: any) => { return data.moduleId === this.moduleId })[0].moduleDesc.toUpperCase();
    }

    canDeactivate(): boolean | Observable<boolean> {
        return this.tblConfig.quickLinks.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true)).length > 0 ? false : true;
    }

    test() {
        console.table(this.forms.quickLinksForm.value);
    }

    /* Boolean Stuffs */

    public btns = {
        quickLinks: {
            updateDisabled: true,
            deleteDisabled: true,
            saveDisabled: true,
            addDisabled: true
        },
        lov: {
            lineDisabled: false,
            sublineDisabled: true,
            planDisabled: true
        }
    }

    public showForm = {
        quickLinks: true
    }

    /* Data Stuffs */

    private rowData: RowData = {
        quickLinks: null
    }

    private errorMessage: RowData = {
        quickLinks: null
    }

    private userIdLoggedIn: string;

    /* Form Object  */

    public forms = {
        lineSubLineLovForm: this.fb.group({
            lineCd: [null],
            sublineCd: [null],
            lineName: [null],
            subLineName: [null],
            planCd: [null],
            planDesc: [null],
            lineCdWithDescDisplay: [{ value: '', disabled: 'true' }],
            subLineCdWithDescDisplay: [{ value: '', disabled: 'true' }],
            planCdWithDescDisplay: [{ value: '', disabled: 'true' }]
        }),
        quickLinksForm: this.fb.group({
            lineCd: [null],
            sublineCd: [null],
            planCd: [null],
            linkNo: [{ value: null, disabled: 'true' }],
            linkName: [null, {validators:[Validators.required, this.checkDuplicateLinkName(), CustomValidators.requiredTrim], updateOn: 'blur'}],
            linkPath: [null],
            activeTag: ['A', [Validators.required, CustomValidators.requiredTrim]],
            remarks: [null],
            lastUpdateUser: [{ value: null, disabled: 'true' }],
            lastUpdate: [{ value: null, disabled: 'true' }]
        }),
        resetLineSubLineForm: function () {
            this.lineSubLineLovForm.reset();
        },
        resetquickLinksForm: () => {
            this.forms.quickLinksForm.reset();
            this.forms.quickLinksForm.patchValue({
                activeTag: 'A'
            });
            this.btns.quickLinks.addDisabled = true;
            this.btns.quickLinks.deleteDisabled = true;
            this._formService.hideFormMsg("quickLinks-error-message");
            this.rowData.quickLinks = null;
        },
        resetquickLinksFormOnClick: () => {
            this.forms.quickLinksForm.reset();
            this.forms.quickLinksForm.patchValue({
                activeTag: 'A'
            });
            this.btns.quickLinks.addDisabled = false;
            this.btns.quickLinks.updateDisabled = true;
            this.btns.quickLinks.deleteDisabled = true;
            this._formService.hideFormMsg("quickLinks-error-message");
            this.rowData.quickLinks = null;
        },
        resetAllForms: function () {
            this.resetLineSubLineForm();
            this.resetquickLinksForm();
        }
    };

    /* Table Object */

    public tblConfig: RowData = {
        quickLinks: {
            cols: [
                {
                    key: "LINK_NO",
                    header: "Link No",
                    dataType: "string",
                    width: "20%"
                },
                {
                    key: "LINK_NAME",
                    header: "Link Name",
                    dataType: "string",
                    width: "30%"
                },
                {
                    key: "LINK_PATH",
                    header: "Link Path",
                    dataType: "string",
                    width: "40%"
                },
                {
                  key: "ACTIVE_TAG",
                  header: "A",
                  dataType: "checkbox",
                  width: '10%'
                }
            ],
            tblData: [] as any[],
            selection: "single",
            paginator: true,
            rowsPerPage: 10,
            lazy: false,
            totalRecords: 15,
            loading: false,
        },
    }

    private formManipulation = {
        quickLinks: {
            dbToForm: function (data: any) {
                let datePipe = new DatePipe('en-us');
                return {
                    lineCd: data.LINE_CD,
                    sublineCd: data.SUBLINE_CD,
                    planCd: data.PLAN_CD,
                    linkNo: data.LINK_NO,
                    linkName: data.LINK_NAME,
                    linkPath: data.LINK_PATH,
                    activeTag: data.ACTIVE_TAG,
                    remarks: data.REMARKS,
                    lastUpdateUser: data.USER_ID,
                    lastUpdate: datePipe.transform(data.LAST_UPDATE, 'MM/dd/YYYY h:mm:ss a', '+0000')

                };
            },
            formToDb: function (data: any) {
                return {
                    LINE_CD: data.lineCd,
                    SUBLINE_CD: data.sublineCd,
                    PLAN_CD: data.planCd,
                    LINK_NO: data.linkNo,
                    LINK_NAME: data.linkName.trim(),
                    LINK_PATH: data.linkPath,
                    ACTIVE_TAG: data.activeTag,
                    REMARKS: data.remarks,
                    USER_ID: null,
                    LAST_UPDATE: null,
                };
            },
        }
    }

    /* Open Lov Time */

    public lovLine(LOVOPTIONS: any) {
        this.forms.resetquickLinksForm();
        this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
            let output = dataOutput.content;
            if (output) {
                this.forms.lineSubLineLovForm.patchValue({
                    lineCd: output.LINE_CD,
                    lineName: output.LINE_NAME,
                    lineCdWithDescDisplay: `${output.LINE_CD} - ${output.LINE_NAME}`,
                    sublineCd: null,
                    subLineName: null,
                    subLineCdWithDescDisplay: null,
                    planCd: null,
                    planDesc: null,
                    planCdWithDescDisplay: null
                    
                });
                this.forms.quickLinksForm.patchValue({
                    lineCd: output.LINE_CD,
                    sublineCd: null,
                    planCd: null
                });
                this.btns.lov.sublineDisabled = false;
                this.btns.lov.planDisabled = true;
                this.btns.quickLinks.saveDisabled = true;
                this.forms.quickLinksForm.disable();
            } else {
                this.forms.lineSubLineLovForm.patchValue({
                    lineCd: null,
                    lineName: null,
                    lineCdWithDescDisplay: null,
                    sublineCd: null,
                    subLineName: null,
                    subLineCdWithDescDisplay: null,
                    planCd: null,
                    planDesc: null,
                    planCdWithDescDisplay: null
                });
                this.forms.quickLinksForm.patchValue({
                    lineCd: null,
                    sublineCd: null,
                    planCd: null
                });
                this.btns.quickLinks.addDisabled = true;
                this.btns.lov.sublineDisabled = true;
                this.btns.lov.planDisabled = true;
                this.forms.quickLinksForm.disable();
            }
            this.tblConfig.quickLinks.tblData = [];
            this.toBeDeleted = [];
            this.btns.quickLinks.saveDisabled = false;
        });
    }

    public lovSubline(LOVOPTIONS: any) {
        this.forms.resetquickLinksForm();
        LOVOPTIONS.data.lineCd = this.forms.lineSubLineLovForm.get('lineCd')?.value;
        this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
            let output = dataOutput.content;
            if (output) {
                this.forms.lineSubLineLovForm.patchValue({
                    lineCd: output.LINE_CD,
                    lineName: output.LINE_NAME,
                    lineCdWithDescDisplay: `${output.LINE_CD} - ${output.LINE_NAME}`,
                    sublineCd: output.SUBLINE_CD,
                    subLineName: output.SUBLINE_NAME,
                    subLineCdWithDescDisplay: `${output.SUBLINE_CD} - ${output.SUBLINE_NAME}`,
                    planCd: null,
                    planDesc: null,
                    planCdWithDescDisplay: null
                });
                this.forms.quickLinksForm.patchValue({
                    lineCd: output.LINE_CD,
                    sublineCd: output.SUBLINE_CD,
                    planCd: null
                });
                this.btns.lov.planDisabled = false;
                this.btns.quickLinks.saveDisabled = true;
                this.forms.quickLinksForm.disable();
            } else {
                this.forms.lineSubLineLovForm.patchValue({
                    sublineCd: null,
                    subLineName: null,
                    subLineCdWithDescDisplay: null,
                    planCd: null,
                    planDesc: null,
                    planCdWithDescDisplay: null
                });
                this.forms.quickLinksForm.patchValue({
                    lineCd: null,
                    sublineCd: null,
                    planCd: null
                });
                //this.btns.quickLinks.addDisabled = true;
                this.btns.lov.planDisabled = true;
                this.forms.quickLinksForm.disable();
            }
            // this.getData.plan();
            this.tblConfig.quickLinks.tblData = [];
            this.toBeDeleted = [];
            this.btns.quickLinks.saveDisabled = false;
        });
    }

    public lovPlan(LOVOPTIONS: any) {
        LOVOPTIONS.data.lineCd = this.forms.lineSubLineLovForm.get('lineCd')?.value;
        LOVOPTIONS.data.sublineCd = this.forms.lineSubLineLovForm.get('sublineCd')?.value;
        this._matDialog.open(PlanLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
            let output = dataOutput.content;
            if (dataOutput.button === 'OK') {
                if (output) {
                    this.forms.resetquickLinksForm();
                    this.forms.lineSubLineLovForm.patchValue({
                        planCd: output.PLAN_CD,
                        planDesc: output.PLAN_NAME,
                        planCdWithDescDisplay: `${output.PLAN_CD.toString().padStart(2, 0)} - ${output.PLAN_NAME}`
                    });
                    this.forms.quickLinksForm.patchValue({
                        planCd: output.PLAN_CD
                    });
                    this.toBeDeleted = [];
                    this.getData.quickLinks();
                    this.forms.quickLinksForm.enable();
                    this.setDisable();
                    this.btns.quickLinks.addDisabled = false;
                    this.btns.quickLinks.saveDisabled = false;
                } else {
                    this.forms.lineSubLineLovForm.patchValue({
                        planCd: null,
                        planDesc: null,
                        planCdWithDescDisplay: null
                    });
                    this.forms.quickLinksForm.patchValue({
                        planCd: null
                    });
                    this.forms.quickLinksForm.disable();
                }
            }
            this.btns.quickLinks.saveDisabled = false;
        });
    }

    public openLov(lovToOpen: string) {
        try {
            let LOVOPTIONS: { [key: string]: any } = {
                disableClose: true,
                width: '512px',
                data: {
                table: lovToOpen,
                moduleId: this.moduleId
                },
            }
            switch (lovToOpen.toUpperCase()) {
                case "LINE":
                    if (this.forms.quickLinksForm.dirty) {
                        this._matDialog.open(CheckLovComponent, {
                            disableClose: true,
                            width: '512px',
                            data: {
                                title: "Confirmation Message",
                                content:
                                    "You have unsaved changes. Do you want to continue?"
                            }
                        }).afterClosed().subscribe((a:any) => {
                            if (a) {
                                this.forms.resetquickLinksFormOnClick();
                                this.forms.quickLinksForm.markAsPristine();
                                this.lovLine(LOVOPTIONS);
                            }
                        });
                    } else {
                        this.lovLine(LOVOPTIONS);
                    }
                    break;
                case "SUBLINE":
                    if (this.forms.quickLinksForm.dirty) {
                        this._matDialog.open(CheckLovComponent, {
                            disableClose: true,
                            width: '512px',
                            data: {
                                title: "Confirmation Message",
                                content:
                                    "You have unsaved changes. Do you want to continue?"
                            
                            }
                        }).afterClosed().subscribe((a:any) => {
                            if (a) {
                                this.forms.resetquickLinksFormOnClick();
                                this.forms.quickLinksForm.markAsPristine();
                                this.lovSubline(LOVOPTIONS);
                            }
                        });
                    } else {
                        this.lovSubline(LOVOPTIONS);
                    }
                    break;
                case "PLAN": // recode pa
                    if (this.forms.quickLinksForm.dirty) {
                        this._matDialog.open(CheckLovComponent, {
                            disableClose: true,
                            width: '512px',
                            data: {
                                title: "Confirmation Message",
                                content:
                                    "You have unsaved changes. Do you want to continue?"
                            
                            }
                        }).afterClosed().subscribe((a:any) => {
                            if (a) {
                                this.forms.resetquickLinksFormOnClick();
                                this.forms.quickLinksForm.markAsPristine();
                                this.forms.quickLinksForm.enable();
                                this.setDisable();
                                this.lovPlan(LOVOPTIONS);
                            }
                        });
                    } else {
                        this.lovPlan(LOVOPTIONS);
                    }
                    break;
            }
        } catch (e) {
            console.error(e);
        }
    }

    private getData = {
        quickLinks: () => { //recode pa
            try {
                this.tblConfig.quickLinks.tblData = [];
                if (this.forms.lineSubLineLovForm.get('lineCd')?.value && this.forms.lineSubLineLovForm.get('sublineCd')?.value) {
                    this.tblConfig.quickLinks.loading = true;
                    this._jsonDS.contorlLoading(true);
                    this._api.getQuickLinks({
                        moduleId: this.moduleId,
                        userId: this._userDetailService.userId,
                        type: "MODULE"
                    }).subscribe({
                        next: (data: any) => {
                            this._securityService.checkRequestKeyResponse(data, () => {
                                this._securityService.hasValidCsrfToken(data, () => {
                                try {
                                    if (data.status === 'SUCCESS') {
                                        data = JSON.parse(this._jsonDS.decrypt(data.response));
                                        this._jsonDS.contorlLoading(false);
                                        let filteredData = data.data.filter((d: any) => (d.LINE_CD == this.forms.lineSubLineLovForm.get('lineCd')?.value 
                                            && d.SUBLINE_CD == this.forms.lineSubLineLovForm.get('sublineCd')?.value 
                                            && d.PLAN_CD == this.forms.lineSubLineLovForm.get('planCd')?.value));
                                        this.tblConfig.quickLinks.tblData = filteredData;
                                        this.toBeDeleted = [];
                                    } else {
                                        this.tblConfig.quickLinks.loading = false;
                                        this._jsonDS.contorlLoading(false);
                                        this.toBeDeleted = [];
                                        this._appMessageService.showAppMessage(data.message, "error");
                                    }
                                } catch (e) {
                                    console.error(e);
                                }
                                this.tblConfig.quickLinks.loading = false;
                            });
                            });
                        },
                        error: (e: any) => {
                            this.tblConfig.quickLinks.loading = false;
                            this._jsonDS.contorlLoading(false);
                        }
                    });
                } else {
                    this.tblConfig.quickLinks.loading = false;
                    this._jsonDS.contorlLoading(false);
                }
            } catch (e) {
                console.error(e);
                this.tblConfig.quickLinks.loading = false;
                this._jsonDS.contorlLoading(false);
            }
        }
    }

    public onRowClick = {
        quickLinks: (ev: any) => {
            try {
                this._formService.hideFormMsg("quickLinks-error-message");
                this.rowData.quickLinks = ev;
                if (ev != null) {
                    ev.update = true;
                    this.populateFormFromTable.quickLinks();
                    if(ev.temp=='true'){
                        this.btns.quickLinks.deleteDisabled = false;
                    }
                    else if(ev.temp==true){
                        this.btns.quickLinks.deleteDisabled = false;
                    }
                    else{
                        this.btns.quickLinks.deleteDisabled = true;
                    }
                } else {
                    ev.update = false;
                    this.forms.resetquickLinksFormOnClick();    
                    this.forms.quickLinksForm.get('activeTag')?.setValue('A');
                    this.btns.quickLinks.deleteDisabled = true;
                }
                
            } catch (e) {
                console.error(e);
                this.forms.resetquickLinksFormOnClick();
                this.rowData.quickLinks = null;
            }
        }
    }

    private populateFormFromTable = {
        quickLinks: () => {
            try {
                let data = this.rowData.quickLinks;
                this.btns.quickLinks.updateDisabled = !data.update;
                this.btns.quickLinks.deleteDisabled = !data.temp;
                this.forms.quickLinksForm.patchValue(this.formManipulation.quickLinks.dbToForm(data));
            }
            catch (e) {
                console.error(e);
            }
        }
    }

    public onAdd = {
        quickLinks: () => {
            try {
                this._formService.hideFormMsg("quickLinks-error-message");
                if (this.forms.quickLinksForm.valid) {
                    this.forms.quickLinksForm.patchValue({
                        lineCd: this.forms.lineSubLineLovForm.get('lineCd')?.value,
                        sublineCd: this.forms.lineSubLineLovForm.get('sublineCd')?.value,
                        planCd : this.forms.lineSubLineLovForm.get('planCd')?.value
                    });
                    let rowToBeAdded: { [key: string]: any } = this.formManipulation.quickLinks.formToDb(this.forms.quickLinksForm.value);
                    var del = this.toBeDeleted.map(function (row:any) { return row.PAYT_CD; }).indexOf(rowToBeAdded.PAYT_CD);
                    if (del != -1) {
                        this.toBeDeleted.splice(del,1);
                    }
                    rowToBeAdded.temp = true;
                    this.tblConfig.quickLinks.tblData = [rowToBeAdded, ...this.tblConfig.quickLinks.tblData];
                    this.forms.resetquickLinksForm();
                    this.btns.quickLinks.saveDisabled = false;
                    this.btns.quickLinks.addDisabled = false;
                    this.forms.quickLinksForm.markAsDirty();
                    
                    
                } else {
                    this.showErrorValidator.quickLinks();
                }
            } catch (e) {
                console.error(e);
            }
        }
    }

    public onDelete = {
        quickLinks: () => {
            try {
                this.tblConfig.quickLinks.tblData = this.tblConfig.quickLinks.tblData.filter((d: any) => {
                    return d !== this.rowData.quickLinks;
                });

                this.toBeDeleted.push(this.rowData.quickLinks);
                this.forms.resetquickLinksFormOnClick();
                this.forms.quickLinksForm.get('activeTag')?.setValue('A');
                this.btns.quickLinks.updateDisabled = true;
                this.btns.quickLinks.saveDisabled = false;
                this.forms.quickLinksForm.markAsDirty();
            } catch (e) {
                console.error(e);
            }
        }
    }

    public onUpdate = {
        quickLinks: () => {
            try {
                this.forms.quickLinksForm.get('linkNo')?.enable();
                this.forms.quickLinksForm.get('lastUpdateUser')?.enable();
                this.forms.quickLinksForm.get('lastUpdate')?.enable();
                this._formService.hideFormMsg("quickLinks-error-message");
                if (this.forms.quickLinksForm.valid) {
                    let toBeUpdatedIndex = this.tblConfig.quickLinks.tblData.indexOf(this.rowData.quickLinks);
                    let rowToBeUpdated: { [key: string]: any } = this.formManipulation.quickLinks.formToDb(this.forms.quickLinksForm.value);
                    if (this.rowData.quickLinks.temp) {
                        rowToBeUpdated.temp = true;
                    } else {
                        rowToBeUpdated.onDbButUpdatedTemp = true;
                    }
                    this.forms.resetquickLinksForm();
                    this.setDisable();
                    this.tblConfig.quickLinks.tblData[toBeUpdatedIndex] = rowToBeUpdated;
                    this.btns.quickLinks.saveDisabled = false;
                    this.btns.quickLinks.updateDisabled = true;
                    this.btns.quickLinks.addDisabled = false;
                    this.tblConfig.quickLinks.tblData = [... this.tblConfig.quickLinks.tblData];
                } else {
                    this.setDisable();
                    this.showErrorValidator.quickLinks();
                }
            } catch (e) {
                console.error(e);
            }
        }
    }

    public onSave = { //recode pa
        quickLinks: () => {
            this.showForm.quickLinks = false;
            this._formService.showFormLoader(null, "quickLinks-maintenance-loading", "Saving.<br>Please wait ...", null, null);
            let toBeSaved = this.tblConfig.quickLinks.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true));
            if (toBeSaved.length > 0 || this.toBeDeleted.length > 0) {
                toBeSaved.map((d: any) => {
                    d.USER_ID = this.userIdLoggedIn;
                });
                this._api.saveQuickLinks({
                    tobeSaved: toBeSaved,
                    tobeDeleted: this.toBeDeleted
                }).subscribe({
                    next: (data: any) => {
                        this._securityService.checkRequestKeyResponse(data, () => {
                            this._securityService.hasValidCsrfToken(data, () => {
                            if (data.status === 'SUCCESS') {
                                this.onComplete.quickLinks();
                            } else { 
                                this.onComplete.quickLinksError();
                            }
                            });
                        });
                    },
                    error: (data: any) => {
                        this.onComplete.quickLinksError();
                    },
                });
            } else {
                this.onComplete.quickLinks();
            }
        }
    }

    openDialog(title: string, contentTitle: string, contentDetail: string) {
        try {
            this._matDialog.open(DialogMsgComponent, {
                disableClose: true,
                width: '512px',
                data: {
                title: title,
                content:
                    title === 'SUCCESS'
                    ? `${contentTitle} details successfully ${contentDetail}!`
                    : `${contentTitle} details ${contentDetail} failed!`,
                },
            });
        } catch (e) {
            console.error(e);
        }
    }

    private setDisable(){
        this.forms.quickLinksForm.get('linkNo')?.disable();
        this.forms.quickLinksForm.get('lastUpdateUser')?.disable();
        this.forms.quickLinksForm.get('lastUpdate')?.disable();
        this.forms.quickLinksForm.get('activeTag')?.setValue('A');
    }

    private onComplete = {
        quickLinks: () => {
            this.getData.quickLinks();
            this.setDisable();
            this.btns.quickLinks.saveDisabled = true;
            this.showForm.quickLinks = true;
            this.toBeDeleted = [];
            this._formService.hideFormLoader("quickLinks-maintenance-loading");
            this.openDialog('SUCCESS', 'Quick Link Access', 'saved');
            this.forms.quickLinksForm.markAsPristine();
        },
        quickLinksError: () => {
            this.showForm.quickLinks = true;
            this.btns.quickLinks.saveDisabled = false;
            this.openDialog('FAILED', 'Quick Links Access', 'saving');
            this._formService.hideFormLoader("quickLinks-maintenance-loading");
        }
    }

    /* Validator Stuffs Down here */

    checkDuplicateLinkName(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (!value) return null;
        let hasDuplicate: boolean = this.tblConfig.quickLinks.tblData.filter((d: any) => {
            if (!this.rowData.quickLinks)
            return d.LINK_NAME === value.trim();
            else
            return this.rowData.quickLinks.LINK_NAME !== value.trim() && d.LINK_NAME === value.trim();
        }).length > 0 ? true : false;
        return hasDuplicate ? { hasDupe: true } : null;
        }
    }
    /*
    checkDuplicatePeril(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (!value) return null;
        let hasDuplicate: boolean = this.tblConfig.planPeril.tblData.filter((d: any) => {
            if (!this.rowData.planPeril)
            return d.PERIL_CD === value;
            else
            return this.rowData.planPeril.PERIL_CD !== value && d.PERIL_CD === value;
        }).length > 0 ? true : false;
        return hasDuplicate ? { hasDupe: true } : null;
        }
    }

    checkDuplicateSeqNo(module: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (!value) return null;
        let hasDuplicate: boolean = this.tblConfig[module as keyof RowData].tblData.filter((d: any) => {
            if (!this.rowData[module as keyof RowData])
            return d.SEQ_NO == value;
            else
            return this.rowData[module as keyof RowData].SEQ_NO != value && d.SEQ_NO == value;
        }).length > 0 ? true : false;
        return hasDuplicate ? { hasDupeSeqNo: true } : null;
        }
    }*/

    public showErrorValidator = {
        quickLinks: () => {
            try {
                Object.keys(this.forms.quickLinksForm.controls).forEach(key => {
                    const controlErrors = this.forms.quickLinksForm.get(key)?.errors;
                    if (controlErrors != null) {
                        Object.keys(controlErrors).forEach(keyError => {
                        if (keyError == 'hasDupe' && controlErrors[keyError] === true)
                            this.errorMessage.quickLinks = "Unable to add record. Link Name already exists.";
                        else if (keyError == 'required' && controlErrors[keyError] === true)
                            this.errorMessage.quickLinks = "There are missing information. Please provide necessary information needed.";
                        else if (keyError == 'hasDupeSeqNo' && controlErrors[keyError] === true)
                            this.errorMessage.quickLinks = "Sequence No. already exists. Please choose a different Sequence No.";
                        });
                    }
                });
                this._formService.showFormMsg("quickLinks-error-message", this.errorMessage.quickLinks, "E");
            } catch (e) {
                console.error(e);
            }
        }
    }
}
