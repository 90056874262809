<section id="applicable-item-code-per-plan">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        
        <!-- START NG FIRE ITEM CODE PER PLAN -->
        <div class="outer-wrapper p-datatable mtn-header">
            <div class="p-datatable-header"><span class="header-title">Fire Item Code per Plan</span></div>

            <div class="form-content">
                <form [formGroup]="paramForm" class="form">
                    <div class="form-container-grid top-form form-container-change-grid">
                        <div class="field required">
                            <label>Line</label>
                        </div>
                        <div class="lov-search">
                            <input readonly formControlName="LINE_CD">
                            <button matSuffix class="btn-search btn" type="button" (click)="openLineDialog()"><mat-icon>search</mat-icon></button>
                        </div>
                        <div class="field required">
                            <label>Subline</label>
                        </div>
                        <div class="lov-search">
                            <input readonly formControlName="SUBLINE_CD">
                            <button matSuffix class="btn-search btn" type="button" (click)="openSublineDialog()" [disabled]="sublineDisabler"><mat-icon>search</mat-icon></button>
                        </div>
                        <div class="field required">
                            <label>Plan</label>
                        </div>
                        <div class="lov-search">
                            <input readonly formControlName="PLAN_CD">
                            <button matSuffix class="btn-search btn" type="button" (click)="openPlanDialog()" [disabled]="planDisabler"><mat-icon>search</mat-icon></button>
                        </div>
                    </div>
                </form>
            </div>

            <osp-table [tblConfig]="table1" (onRowClick)="onRowClick1($event)" [selectedRow]="selectedRow1"></osp-table>

            <div class="form-content" [hidden]="formHider">

                <form [formGroup]="form1" class="form">
                    <div id="applicable-item-code-per-plan-error-message-1"></div>
                    <div class="form-container-grid">
                        
                        <div class="field required">
                            <label>Item Code</label>
                        </div>
                        <div class="">
                            <input type="text" formControlName="ITEM_CD" readonly>
                            <button class="btn-search btn" (click)="openItemCode()">
                                <mat-icon>
                                    search
                                </mat-icon>
                            </button>
                        </div>

                        <div class="field required">
                            <label>Active</label>
                        </div>
                        <div class="">
                            <select formControlName="ACTIVE_TAG">
                                <option value="A">Yes</option>
                                <option value="I">No</option>
                            </select>
                        </div>

                        <div class="field">
                            <label>Item Label</label>
                        </div>
                        <div class="">
                            <input formControlName="ITEM_LABEL" maxlength="1000">
                        </div>

                        <div class="field">
                            <label>Item Code Detail</label>
                        </div>
                        <div class="">
                            <input formControlName="ITEM_CD_DTL" maxlength="2000">
                        </div>

                        <div class="field">
                            <label>Column Group</label>
                        </div>
                        <div class="">
                            <select formControlName="COLUMN_GRP">
                                <option *ngFor="let colGrp of columnGrp"
                                        [value]="colGrp.rvLowValue">
                                    {{ colGrp.rvLowValue + " - " + colGrp.rvMeaning }}
                                </option>
                            </select>
                        </div>

                        <div class="field">
                            <label>Sequence No.</label>
                        </div>
                        <div class="">
                            <input formControlName="SEQ_NO" posNumInput type="number" min="1">
                        </div>

                        <div class="field">
                            <label>Remarks</label>
                        </div>
                        <div class="span">
                            <input formControlName="REMARKS" maxlength="4000">
                        </div>

                        <div class="field">
                            <label>Last Update User</label>
                        </div>
                        <div class="">
                            <input formControlName="USER_ID">
                        </div>

                        <div class="field">
                            <label>Last Update</label>
                        </div>
                        <div class="">
                            <input formControlName="LAST_UPDATE">
                        </div>
                    </div>
                </form>

                <div class="btn-grp">
                    <button type="button" class="btn btn2" [hidden]="!addUpdateFlag1" (click)="addRow1()">ADD</button>
                    <button type="button" class="btn btn2" [hidden]="addUpdateFlag1" (click)="onUpdate1()">UPDATE</button>
                    <button type="button" class="btn btn2" [disabled]="deleteDisabler1" (click)="deleteRow1()">DELETE</button>
                </div>
                <div class="mtn-title"></div>
                <div class="btn-grp">
                    <button type="button" class="btn btn2" [disabled]="saveDisabler1" (click)="onSave1()">SAVE</button>
                </div>
            </div>
        </div>
        <!-- END NG FIRE CODE PER PLAN -->



        <!-- START NG PERIL PER FIRE ITEM CODE -->
        <div class="outer-wrapper p-datatable mtn-header">
            <div class="p-datatable-header"><span class="header-title">Peril per Fire Item Code</span></div>

            <osp-table [tblConfig]="table2" (onRowClick)="onRowClick2($event)" [selectedRow]="selectedRow2"></osp-table>

            <div class="form-content" [hidden]="formHider">

                <form [formGroup]="form2" class="form">
                    <div id="applicable-item-code-per-plan-error-message-2"></div>
                    <div class="form-container-grid">
                        
                        <div class="field required">
                            <label>Peril</label>
                        </div>
                        <div class="">
                            <input type="text" formControlName="PERIL_CD" readonly>
                            <button class="btn-search btn" (click)="openPerilCode()" [disabled]="perilDisabler">
                                <mat-icon>
                                    search
                                </mat-icon>
                            </button>
                        </div>

                        <div class="field required">
                            <label>Active</label>
                        </div>
                        <div class="">
                            <select formControlName="ACTIVE_TAG">
                                <option value="A">Yes</option>
                                <option value="I">No</option>
                            </select>
                        </div>

                        <div class="field">
                            <label>Peril Description</label>
                        </div>
                        <div class="">
                            <input formControlName="PERIL_DESC" maxlength="500">
                        </div>

                        <div class="field">
                            <label>Sequence No</label>
                        </div>
                        <div class="">
                            <input type="text" formControlName="SEQ_NO" posNumInput type="number" min="1">
                        </div>

                        <div class="field">
                            <label>Default Rate</label>
                        </div>
                        <div class="">
                            <input formControlName="DEFAULT_RATE" decimal [digits]="3" [decimals]="9">
                        </div>

                        <div class="field">
                            <label>Highlight Coverage</label>
                        </div>
                        <div class="">
                            <select formControlName="HL_TAG">
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                            </select>
                        </div>

                        <div class="field">
                            <label>Multipler Rate</label>
                        </div>
                        <div class="">
                            <input formControlName="MULTIPLIER_RATE" decimal [digits]="3" [decimals]="9">
                        </div>

                        <div class="field">
                            <label>Fair Market Values Switch</label>
                        </div>
                        <div class="">
                            <select formControlName="FMV_SW">
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                            </select>
                        </div>

                        <div class="field">
                            <label>Default Amount Covered</label>
                        </div>
                        <div class="">
                            <input formControlName="DEFAULT_TSI" decimal [digits]="14" [decimals]="2">
                        </div>

                        <div class="field">
                            <label>Rating Table Switch</label>
                        </div>
                        <div class="">
                            <select formControlName="RATING_TBL_SW">
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                            </select>
                        </div>

                        <div class="field">
                            <label>Remarks</label>
                        </div>
                        <div class="span">
                            <input formControlName="REMARKS" maxlength="1000">
                        </div>

                        <div class="field">
                            <label>Last Update User</label>
                        </div>
                        <div class="">
                            <input formControlName="USER_ID">
                        </div>

                        <div class="field">
                            <label>Last Update</label>
                        </div>
                        <div class="">
                            <input formControlName="LAST_UPDATE">
                        </div>
                    </div>
                </form>

                <div class="btn-grp">
                    <button type="button" class="btn btn2" [hidden]="!addUpdateFlag2" [disabled]="perilDisabler" (click)="addRow2()">ADD</button>
                    <button type="button" class="btn btn2" [hidden]="addUpdateFlag2" [disabled]="perilDisabler" (click)="onUpdate2()">UPDATE</button>
                    <button type="button" class="btn btn2" [disabled]="deleteDisabler2 || perilDisabler" (click)="deleteRow2()">DELETE</button>
                </div>
                <div class="mtn-title"></div>
                <div class="btn-grp">
                    <button type="button" class="btn btn2" [disabled]="saveDisabler2" (click)="onSave2()">SAVE</button>
                </div>
            </div>
        </div>
        <!-- END NG PERIL PER FIRE ITEM CODE -->

    </div>
        
</section>