import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

@Component({
  selector: 'car-type-lov',
  templateUrl: './car-type-lov.component.html',
  styleUrls: ['./car-type-lov.component.css']
})
export class CarTypeLovComponent implements OnInit {

  private selectedData: any;

  public tableSelected: any;

  public titleLov: string = "CAR TYPE";
  private moduleId: string = "BMM035";

  carTypeTable: any = {
    cols: [
      {
        key: "CAR_TYPE_CD",
        header: "Code",
        dataType: "string"
      },
      {
        key: "CAR_TYPE_DESC",
        header: "Car Type",
        dataType: "string"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  };

  constructor(
    private dialogRef: MatDialogRef<CarTypeLovComponent>,
    private _api: APICallService,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    @Inject(MAT_DIALOG_DATA) public fromMainData: any,
  ) { }

  ngOnInit(): void {
    try {
      this.tableSelected = this.carTypeTable;
      this.getCarTypeData();
    }
    catch (e) {
    console.error(e);

    }
  }

  onRowClick(ev: any) {
    try {
      this.selectedData = ev;
    } catch (e) {
    console.error(e);
      this.selectedData = null;
    }
  }

  getCarTypeData() {
    try {
      this.carTypeTable.loading = true;
      this.jsonDataService.contorlLoading(true);
      this._api.getAllCarTypes({
        moduleId: this.fromMainData.moduleId,
        userId: this.userDetailService.userId,
        type: "LOV"
      }).subscribe({
        next: (data: any) => {
          this.securityService.checkRequestKeyResponse(data, () => {
            this.securityService.hasValidCsrfToken(data, () => {
              if (data.status === 'SUCCESS') {
                let content = JSON.parse(this.jsonDataService.decrypt(data?.response));
                this.tableSelected.tblData = content.data.map((d: any) => { return { ...d, CAR_TYPE_CD: d.CAR_TYPE_CD?.toString()?.padStart(2, 0) } });
                this.carTypeTable.loading = false;
                this.jsonDataService.contorlLoading(false);
              } else {
                this.jsonDataService.contorlLoading(false);
                this.carTypeTable.loading = false;
                this.appMessageService.showAppMessage(data.message, "error");
              }
            });
          });
        },
        error: (e: any) => {
          this.securityService.checkRequestKeyResponse(e, () => {

            this.carTypeTable.loading = false;
            this.jsonDataService.contorlLoading(false);
          });
        }
      });
    }
    catch (e) {
    console.error(e);

      this.carTypeTable.loading = false;
      this.jsonDataService.contorlLoading(false);
    }
  }

  onClose(str: string) {
    try {
      if (this.selectedData == '' || this.selectedData == null) {
        this.dialogRef.close({ content: '', button: str });
      }
      else {
        this.dialogRef.close({ content: this.selectedData, button: str });
      }
    } catch (e) {
    console.error(e);

    }
  }

}
