<div class="mtn-container">
    <div class="mtn-title">
        <p>{{ moduleName }}</p>
        <!-- <p>MORTGAGEE MAINTENANCE</p> -->
    </div>
    <osp-table [tblConfig]="mortgageeTblConfig" (onRowClick)="onRowClick($event)"></osp-table>
    <div id="mortgagee-maintenance-loading">
        <div class="form-loader"></div>
    </div>
    <div *ngIf="showForm" class="form-content">
        <form [formGroup]="forms.mortgageeForm" class="form">
            <div id="mortgagee-error-message"></div>
            <div class="form-container-grid">
                <div class="field">
                    <label>Bank Code</label>
                </div>
                <div class="">
                    <input formControlName="bankCdDisp" >
                </div>
                <div class="field required">
                    <label>Active</label>
                </div>
                <div class="">
                    <select formControlName="active">
                        <option *ngFor="let activeType of selections.activeTypes" [value]="activeType.cd">
                            {{activeType.name}}
                        </option>
                    </select>
                </div>
                <div class="field required">
                    <label>Bank Name</label>
                </div>
                <div class="">
                    <input formControlName="bankName" maxlength="200">
                </div>
                <div class="field required">
                    <label>RPA Code</label>
                </div>
                <div class="">
                    <input formControlName="bmCode" maxlength="8">
                </div>
                <div class="field">
                    <label>ALFES</label>
                </div>
                <div class="">
                    <select formControlName="alfes">
                        <option *ngFor="let alfesType of selections.alfesTypes" [value]="alfesType.cd">
                            {{alfesType.name}}
                        </option>
                    </select>
                </div>
                <div class="field">
                    <label>Mortgagee Account Group</label>
                </div>
                <div class="">
                    <select formControlName="mortAccGrp">
                        <option *ngFor="let mortAccGrp of selections.mortAccGrps" [value]="mortAccGrp.cd">
                            {{mortAccGrp.name}}
                        </option>
                    </select>
                </div>
                <div class="field">
                    <label>Remarks</label>
                </div>
                <div class="span">
                    <input formControlName="remarks" maxlength="4000" >
                </div>
                <div class="field">
                    <label>Last Update User</label>
                </div>
                <div class="">
                    <input formControlName="lastUpdateUser" >
                </div>
                <div class="field">
                    <label>Last Update</label>
                </div>
                <div class="">
                    <input formControlName="lastUpdate" >
                </div>
            </div>
        </form>
        <div class="btn-grp">
            <button *ngIf="btns.mortgagee.updateDisabled" class="btn btn2" type="button" (click)="onAdd()" >ADD</button>
            <button *ngIf="!btns.mortgagee.updateDisabled" class="btn btn2" type="button" (click)="onUpdate()" >UPDATE</button>
            <button class="btn btn2" type="button" [disabled]='btns.mortgagee.deleteDisabled' (click)="onDelete()" >DELETE</button>
        </div>
        <div class="mtn-title"></div>
        <div class="btn-grp">
            <button class="btn btn2" type="button" [disabled]='btns.mortgagee.saveDisabled' (click)="onSave()" >SAVE</button>
        </div>
    </div>
</div>