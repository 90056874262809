import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { SecurityService } from 'src/app/services/security.service';

@Component({
  selector: 'client-group-lov-dialog',
  templateUrl: './client-group-lov-dialog.component.html',
  styleUrls: ['./client-group-lov-dialog.component.css']
})
export class ClientGroupLovDialog {

  moduleId: string = 'BMM061';
  public titleLov: string = "Client Group".toUpperCase();

  constructor(private apiCallService: APICallService,
    private jsonDataService: JsonDataService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    private securityService: SecurityService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<ClientGroupLovDialog>) {
    try {
      if("title" in this.data){
        this.titleLov = this.data.title;
      }
      this.getClientGroup();
    } catch (e) {
    console.error(e);

    }
  }

  clientGroupTblConfig: any = {
    cols: [
      {
        key: "CLIENT_CD",
        header: "Code",
        dataType: "string"
      },
      {
        key: "CLIENT_DESC",
        header: "Client Group",
        dataType: "string"
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15
  }

  getClientGroup() {
    try {
      this.clientGroupTblConfig.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.apiCallService.getClientGroup({
        moduleId: this.data.moduleId,
        userId: this.userDetailService.userId,
        type: "LOV"
      }).subscribe({
        next: (response: any) => {
          this.securityService.checkRequestKeyResponse(response, () => {
            this.securityService.hasValidCsrfToken(response, () => {
              let content = JSON.parse(this.jsonDataService.decrypt(response.response));
              if (content.moduleId === this.moduleId) {
                this.jsonDataService.contorlLoading(false);
                this.clientGroupTblConfig.tblData = content.data.filter((e: any) => e.ACTIVE_TAG == 'A');
                this.clientGroupTblConfig.loading = false;
              } else {
                this.clientGroupTblConfig.loading = false;
                this.jsonDataService.contorlLoading(false);
                this.appMessageService.showAppMessage("You do not have the correct privilege to access the data. Kindly contact your administrator for assistance.", "error");
              }
            });
          });
        },
        error: (e: any) => {
          this.securityService.checkRequestKeyResponse(e, () => {

            this.clientGroupTblConfig.loading = false;
            this.jsonDataService.contorlLoading(false);
            this.appMessageService.showAppMessage(e, "error");
          });
        }
      });
    } catch (e) {
    console.error(e);

    }
  }

  onRowClick(ev: any) {
    try {
      this.data = ev;
    } catch (e) {
    console.error(e);

    }
  }

  onClose() {
    try {
      if (this.data == '' || this.data == null) {
        this._dialogRef.close({ content: '' });
      } else {
        this._dialogRef.close({ content: this.data, moduleId: this.moduleId });
      }
    } catch (e) {
    console.error(e);

    }
  }

  onBack() {
    try {
      this._dialogRef.close({ content: '', option: 'back' });
    } catch (e) {
    console.error(e);

    }
  }
}