<section id="ben-cov-per-plan-peril-maint">    
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <div class="form-content">
            <form [formGroup]="forms.lineSublinePlanLovForm" class="form">
                <div class="form-container-grid top-form">
                    <div class="field required">
                        <label>Line</label>
                    </div>
                    <div class="">
                        <input formControlName="lineCdDisp" >
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['line']()" 
                        [disabled]='btns.lov.lineDisabled'>
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                    <div class="field required">
                        <label>Subline</label>
                    </div>
                    <div class="">
                        <input formControlName="sublineCdDisp" >
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['subline']()" 
                        [disabled]='btns.lov.sublineDisabled'>
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                    <div class="field required">
                        <label>Plan</label>
                    </div>
                    <div class="">
                        <input formControlName="planCdDisp" >
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['plan']()" 
                        [disabled]='btns.lov.planDisabled'>
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                    <div class="field required">
                        <label>Plan Subgroup</label>
                    </div>
                    <div class="">
                        <input formControlName="planSubgrpDisp" >
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['planSubgrp']()" 
                        [disabled]='btns.lov.planSubgrpDisabled'>
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <!-- Container for Benefits and Coverage Per Plan Peril Table using prime ng -->
        <div class = 'table-user'>
            <osp-table [tblConfig]="benCovPerPlanPerilTblConfig" (onRowClick)="onRowClick($event)"></osp-table>
        </div>
        <!-- Loading Animation -->
        <div id="ben-cov-per-plan-peril-maintenance-loading">
            <div class="form-loader"></div>
        </div>
        <div class="form-content" *ngIf="showForm">
            <form [formGroup]="forms.benCovPerPlanPerilForm" class="form">
                <div id="ben-cov-per-plan-peril-error-message"></div>
                <div class="form-container-grid">
                    <div class="field required">
                        <label>Peril</label>
                    </div>
                    <div class="span lov-search">
                        <input type="text" readonly formControlName="perilField">
                        <button type="button" class="btn-search btn" (click)="openLov['peril']()" [disabled]='btns.benCovPerPlanPeril.perilDisabled'>
                            <mat-icon>
                                search
                            </mat-icon>
                        </button>
                    </div>

                    <div class="field">
                        <label>Default Amount Covered</label>
                    </div>
                    <div class="">
                        <input class="currency" formControlName="defaultTSI" (focus)="_formService.setFieldToNumberWithDecimal(forms.benCovPerPlanPerilForm, 'defaultTSI', $event)"
                        (blur)="_formService.setFieldToAmountFormat(forms.benCovPerPlanPerilForm, 'defaultTSI', $event)" (keypress)="allowNumericDigitsOnly($event)">
                    </div>
                    <div class="field">
                        <label>No. of Days</label>
                    </div>
                    <div class="">
                        <input formControlName="noOfDays" type="text" (keypress)="allowNumericDigitsOnlyWODecimal($event)" (blur)="integerFormat('noOfDays')">
                    </div>

                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" type="text" maxlength="4000">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="userID">
                    </div>
                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUpdate">
                    </div>
                </div>
            </form>
            <div class="btn-row">
                <button *ngIf="btns.benCovPerPlanPeril.updateDisabled" class="btn btn2" type="button" [disabled]='btns.benCovPerPlanPeril.addDisabled' (click)="onAdd()">ADD</button>
                <button *ngIf="!btns.benCovPerPlanPeril.updateDisabled" class="btn btn2" type="button" (click)="onUpdate()">UPDATE</button>
                <button class="btn btn2" type="button" [disabled]='btns.benCovPerPlanPeril.updateDisabled' (click)="onDelete()"> DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-row">
                <button class="btn btn2" type="button" [disabled]='btns.benCovPerPlanPeril.saveDisabled' (click)="onSave()">SAVE</button>
            </div>
        </div>
    </div>
</section>