import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe, formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { ClassLovComponent } from '../../common/class-lov/class-lov.component';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';
import { PlanLovComponent } from '../../common/plan-lov/plan-lov.component';
import { PerilLovComponent } from '../../common/peril-lov/peril-lov.component';
import { ZoneLovDialogComponent } from '../../common/zone-lov/zone-lov.component';

@Component({
  selector: 'fire-prem-rt-for-public-maintenance',
  templateUrl: './fire-prem-rt-for-public-maintenance.component.html',
  styleUrls: ['./fire-prem-rt-for-public-maintenance.component.css']
})
export class FirePremRtForPublicMaintenanceComponent implements OnInit, LeaveConfirmation {

  moduleId: string = 'BMM176';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc?.toUpperCase() ?? "";
  datePipe = new DatePipe('en-us');

  constructor(
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    public _formService: FormService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService
  ) { 
    this.userIdLoggedIn = this._userDetailService.userId ?? "";
  }

  ngOnInit(): void {
    this.btns.firePremRtForPublic.addDisabled = true;
    this.forms.firePremRtForPublicForm.disable();
    this.assignDefaultTag();
  }

  canDeactivate(): boolean | Observable<boolean> {
    if(this.tempData!='') {
      return false;
    } else {
      return true;
    }
  }

  /* Boolean Stuffs */

  public btns = {
    firePremRtForPublic: {
      addDisabled: true,
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true
    },
    lov: {
      classDisabled: false,
      lineDisabled: false,
      sublineDisabled: true,
      planDisabled: true,
      perilDisabled: true,
      zoneDisabled: false
    }
  }

  public showForm: boolean = true;

  private rowData: any;
  private tempDataRow: any;
  private errorMessage: string = "";
  private userIdLoggedIn: string;
  private tempData: any = [];

  public forms = {
    paramLovForm: this.fb.group({
      classCd: [null],
      classDesc: [null],
      classCdDisp: [{ value: '', disabled: 'true' }],
      lineCd: [null],
      lineName: [null],
      lineCdDisp: [{ value: '', disabled: 'true' }],
      sublineCd: [null],
      sublineName: [null],
      sublineCdDisp: [{ value: '', disabled: 'true' }],
      planCd: [null],
      planName: [null],
      planCdDisp: [{ value: '', disabled: 'true' }],
      perilCd: [null],
      perilName: [null],
      perilCdDisp: [{ value: '', disabled: 'true' }],
      zoneCd: [null],
      zoneDesc: [null],
      zoneCdDisp: [{ value: '', disabled: 'true' }],
    }),
    firePremRtForPublicForm: this.fb.group({
      classCd: [null],
      lineCd: [null],
      sublineCd: [null],
      planCd: [null],
      perilCd: [null],
      zoneCd: [null],
      premRt: [null, [Validators.required]],
      commRt: [null, [Validators.required]],
      defaultTag: ['N', [Validators.required]],
      defaultTagDisp: [null],
      effDateFrom: [{ value: '', disabled: 'true' }, [Validators.required]],
      effDateTo: [null],
      activeTag: ['A', [Validators.required]],
      remarks: [null, [Validators.maxLength(4000)]],
      userID: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }]
    }),
    resetFirePremRtForPublicForm: () => {
      this.forms.firePremRtForPublicForm.reset();
      this.forms.firePremRtForPublicForm.patchValue({
        activeTag: 'A',
        defaultTag: 'N',
        classCd: this.forms.paramLovForm.get('classCd')?.value,
        lineCd: this.forms.paramLovForm.get('lineCd')?.value,
        sublineCd: this.forms.paramLovForm.get('sublineCd')?.value,
        planCd: this.forms.paramLovForm.get('planCd')?.value,
        perilCd: this.forms.paramLovForm.get('perilCd')?.value,
        zoneCd: this.forms.paramLovForm.get('zoneCd')?.value,
      });
      this.btns.firePremRtForPublic.updateDisabled = this.btns.firePremRtForPublic.deleteDisabled = true;
      this.forms.firePremRtForPublicForm.get('premRt')?.enable();
      this.forms.firePremRtForPublicForm.get('effDateFrom')?.enable();
      this.assignDefaultTag();
      this._formService.hideFormMsg("fire-prem-rt-for-public-error-message");
    },
    resetLineSublineLovForm: () => {
      this.forms.paramLovForm.reset();
    },
    resetAllForms: () => {
      this.forms.resetLineSublineLovForm();
      this.forms.resetFirePremRtForPublicForm();
    }
  }

  private firePremRtForPublicFormManipulate = {
    dbToForm: (data: any) => {
      return {
        classCd: data.CLASS_CD,
        lineCd: data.LINE_CD,
        sublineCd: data.SUBLINE_CD,
        planCd: data.PLAN_CD,
        perilCd: data.PERIL_CD,
        zoneCd: data.ZONE_CD,
        premRt: data.PREM_RT==null ? null : parseFloat(data.PREM_RT).toFixed(9),
        commRt: data.COMM_RT==null ? null : parseFloat(data.COMM_RT).toFixed(9),
        activeTag: data.ACTIVE_TAG,
        defaultTag: data.DEFAULT_TAG,
        defaultTagDisp: data.DEFAULT_TAG_DISP, 
        effDateFrom: this.dateFilter(data.EFF_DATE_FROM),
        effDateTo: this.dateFilter(data.EFF_DATE_TO),
        remarks: data.REMARKS,
        userID: data.USER_ID,
        lastUpdate: data.LAST_UPDATE,
      };
    },
    formToDb: (data: any, mode: any) => {
      return {
        CLASS_CD: data.classCd,
        LINE_CD: data.lineCd,
        SUBLINE_CD: data.sublineCd,
        PLAN_CD: data.planCd,
        PERIL_CD: data.perilCd, 
        ZONE_CD: data.zoneCd,
        PREM_RT: data.premRt=='' || data.premRt==null ? null : parseFloat(data.premRt),
        COMM_RT: data.commRt=='' || data.commRt==null ? null : parseFloat(data.commRt),
        ACTIVE_TAG: data.activeTag,
        DEFAULT_TAG: data.defaultTag,
        DEFAULT_TAG_DISP: data.defaultTagDisp,
        EFF_DATE_FROM: this.datePipe.transform(new Date(data.effDateFrom), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
        EFF_DATE_TO: data.effDateTo=='' || data.effDateTo==null ? null : this.datePipe.transform(new Date(data.effDateTo), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
        REMARKS: data.remarks,
        USER_ID: this.userIdLoggedIn,
        LAST_UPDATE: null,
        USER_TYPE: 'P',
        MODE: mode
      };
    },
    formToTemp: (data: any, mode: any) => {
      return {
        CLASS_CD: data.classCd,
        LINE_CD: data.lineCd,
        SUBLINE_CD: data.sublineCd,
        PLAN_CD: data.planCd,
        PERIL_CD: data.perilCd, 
        ZONE_CD: data.zoneCd,
        PREM_RT: data.premRt=='' || data.premRt==null ? null : parseFloat(data.premRt).toFixed(9),
        COMM_RT: data.commRt=='' || data.commRt==null ? null : parseFloat(data.commRt).toFixed(9),
        ACTIVE_TAG: data.activeTag,
        DEFAULT_TAG: data.defaultTag,
        DEFAULT_TAG_DISP: data.defaultTagDisp,
        EFF_DATE_FROM: this.datePipe.transform(new Date(data.effDateFrom), 'yyyy-MM-dd'),
        EFF_DATE_TO: data.effDateTo=='' || data.effDateTo==null ? null : this.datePipe.transform(new Date(data.effDateTo), 'yyyy-MM-dd'),
        REMARKS: data.remarks,
        USER_ID: this.userIdLoggedIn,
        LAST_UPDATE: null,
        USER_TYPE: 'P',
        MODE: mode
      };
    }
  }

  public firePremRtForPublicTblConfig = {
    cols: [
      {
        key: "PREM_RT",
        header: "Premium Rate",
        dataType: "rateDecimal",
        width: '20%'
      },
      {
        key: "EFF_DATE_FROM",
        header: "Effective Date",
        dataType: "date",
        width: '18%'
      },
      {
        key: "EFF_DATE_TO",
        header: "Expiry Date",
        dataType: "date",
        width: '17%'
      },
      {
        key: "COMM_RT",
        header: "Commission Rate",
        dataType: "rateDecimal",
        width: '20%'
      },
      {
        key: "DEFAULT_TAG_DISP",
        header: "Default",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        width: '64px'
      }
    ],
    tblData: [] as any[],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  public openLov = {
    class: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          moduleId: this.moduleId,
          col: 'DESC',
        },
      };
      this._matDialog.open(ClassLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
        if (dataOutput.button === 'OK') {
          this.forms.resetFirePremRtForPublicForm();
          let output = dataOutput.content;
          if (output.CLASS_CD) {
            this.forms.paramLovForm.patchValue({
              classCd: output.CLASS_CD,
              classDesc: output.CLASS_DESC,
              classCdDisp: `${output.CLASS_CD} - ${output.CLASS_DESC}`,
            });
            this.forms.firePremRtForPublicForm.patchValue({
              classCd: output.CLASS_CD,
            });
            this.getDataCheck();
          }
          else {
            this.forms.paramLovForm.patchValue({
              classCd: null,
              classDesc: null,
              classCdDisp: null,
            });
            this.forms.firePremRtForPublicForm.patchValue({
              classCd: null,
            });
            this.btns.firePremRtForPublic.addDisabled = true;
            this.forms.resetFirePremRtForPublicForm();
            this.getDataCheck();
          }
        }
      });
    },
    line: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'LINE',
          moduleId: this.moduleId
        },
      }
      this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button === 'OK') {
          this.forms.resetFirePremRtForPublicForm();
          if (output) {
            this.forms.paramLovForm.patchValue({
              lineCd: output.LINE_CD,
              lineName: output.LINE_NAME,
              lineCdDisp: `${output.LINE_CD} - ${output.LINE_NAME}`,
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null,
              planCd: null,
              planName: null,
              planCdDisp: null,
              perilCd: null,
              perilName: null,
              perilCdDisp: null,
            });
            this.forms.firePremRtForPublicForm.patchValue({
              lineCd: output.LINE_CD,
              sublineCd: null,
              planCd: null,
              perilCd: null
            });
            this.btns.lov.sublineDisabled = false;
            this.btns.lov.perilDisabled = false;
            this.btns.lov.planDisabled = true;
            this.btns.firePremRtForPublic.addDisabled = true;
            this.getDataCheck();
          }
          else {
            this.forms.paramLovForm.patchValue({
              lineCd: null,
              lineName: null,
              lineCdDisp: null,
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null,
              planCd: null,
              planName: null,
              planCdDisp: null,
            });
            this.forms.firePremRtForPublicForm.patchValue({
              lineCd: output.LINE_CD,
              sublineCd: null,
              planCd: null
            });
            this.forms.resetFirePremRtForPublicForm();
            this.getDataCheck();
          }
        }
      });
    },
    subline: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'SUBLINE',
          moduleId: this.moduleId
        },
      }
      LOVOPTIONS.data.lineCd = this.forms.paramLovForm.get('lineCd')?.value;
      this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
        let output = dataOutput.content;
        if (output) {
          this.forms.resetFirePremRtForPublicForm();
          this.forms.paramLovForm.patchValue({
            sublineCd: output.SUBLINE_CD,
            subLineName: output.SUBLINE_NAME,
            sublineCdDisp: `${output.SUBLINE_CD} - ${output.SUBLINE_NAME}`,
            planCd: null,
            planName: null,
            planCdDisp: null,
          });
          this.forms.firePremRtForPublicForm.patchValue({
            sublineCd: output.SUBLINE_CD,
            planCd: null,
          });
          this.btns.lov.planDisabled = false;
          this.getDataCheck();
        } 
        else {
          this.forms.paramLovForm.patchValue({
            sublineCd: null,
            sublineName: null,
            sublineCdDisp: null,
            planCd: null,
            planName: null,
            planCdDisp: null,
          });
          this.forms.firePremRtForPublicForm.patchValue({
            sublineCd: null,
            planCd: null
          });
          this.btns.firePremRtForPublic.addDisabled = true;
          this.getDataCheck();
        }
      });
    },
    plan: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'PLAN',
          moduleId: this.moduleId,
          lineCd: this.forms.paramLovForm.get('lineCd')?.value,
          sublineCd: this.forms.paramLovForm.get('sublineCd')?.value,
        },
      };
      this._matDialog.open(PlanLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button?.toUpperCase() === 'OK') {
          this.forms.resetFirePremRtForPublicForm();
          if (output.PLAN_CD) {
            this.forms.paramLovForm.patchValue({
              planCd: output.PLAN_CD,
              planName: output.PLAN_NAME,
              planCdDisp: `${output.PLAN_CD} - ${output.PLAN_NAME}`,
            });
            this.forms.firePremRtForPublicForm.patchValue({
              planCd: output.PLAN_CD
            });
            this.getDataCheck();
          }
          else {
            this.forms.paramLovForm.patchValue({
              planCd: null,
              planName: null,
              planCdDisp: null,
            });
            this.forms.firePremRtForPublicForm.patchValue({
              planCd: null,
            });
            this.btns.firePremRtForPublic.addDisabled = true;
            this.getDataCheck();
          }
        }
      });
    },
    peril: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'PERIL',
          lineCd: this.forms.paramLovForm.get('lineCd')?.value,
          moduleId: this.moduleId, 
          lpad: 3
        },
      };
      this._matDialog.open(PerilLovComponent, LOVOPTIONS).afterClosed().subscribe((lovData: any) => {
        if (lovData?.content?.PERIL_CD) {
          this.forms.resetFirePremRtForPublicForm();
          const data = lovData?.content;
          this.forms.paramLovForm.patchValue({
            perilCd: data.PERIL_CD?.toString().padStart(3, 0),
            perilName: data.PERIL_LNAME,
            perilCdDisp: `${data.PERIL_CD?.toString().padStart(3, 0)} - ${data.PERIL_LNAME}`
          });
          this.forms.firePremRtForPublicForm.patchValue({
            perilCd: data.PERIL_CD?.toString().padStart(3, 0),
          });
          this.getDataCheck();
        }
        else {
          this.forms.paramLovForm.patchValue({
            perilCd: null,
            perilName: null,
            perilCdDisp: null,
          });
          this.forms.firePremRtForPublicForm.patchValue({
            perilCd: null
          });
          this.forms.resetFirePremRtForPublicForm();
          this.getDataCheck();
        }
      });
    },
    zone: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'ZONE',
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
        },
      };
      this._matDialog.open(ZoneLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
        if (dataOutput.content) {
          let output = dataOutput.content;
          if (output.ZONE_CD) {
            this.forms.resetFirePremRtForPublicForm();
            this.forms.paramLovForm.patchValue({
              zoneCd: output.ZONE_CD,
              zoneDesc: output.ZONE_DESC,
              zoneCdDisp: `${output.ZONE_CD} - ${output.ZONE_DESC}`,
            });
            this.forms.firePremRtForPublicForm.patchValue({
              zoneCd: output.ZONE_CD,
            });
            this.getDataCheck();
          }
          else {
            this.forms.paramLovForm.patchValue({
              zoneCd: null,
              zoneDesc: null,
              zoneCdDisp: null,
            });
            this.forms.firePremRtForPublicForm.patchValue({
              zoneCd: null,
            });
            this.btns.firePremRtForPublic.addDisabled = true;
            this.forms.resetFirePremRtForPublicForm();
            this.getDataCheck();
          }
        }
      });
    },
  };

  private getData = {
    firePremRtForPublic: () => {
      try {
        this.firePremRtForPublicTblConfig.tblData = [];
        this.firePremRtForPublicTblConfig.loading = true;
        this._jDS.contorlLoading(true);
        this._api.getFirePremRates({
          userType: "P",
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          type: "MODULE"
        }).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                let content = JSON.parse(this._jDS.decrypt(data.response));
                this._jDS.contorlLoading(false);
                this.firePremRtForPublicTblConfig.tblData = content.data.filter((d: any) => 
                  d.CLASS_CD == this.forms.paramLovForm.get('classCd')?.value 
                  && d.LINE_CD == this.forms.paramLovForm.get('lineCd')?.value 
                  && d.SUBLINE_CD == this.forms.paramLovForm.get('sublineCd')?.value
                  && d.PLAN_CD == this.forms.paramLovForm.get('planCd')?.value
                  && d.PERIL_CD == this.forms.paramLovForm.get('perilCd')?.value
                  && d.ZONE_CD == this.forms.paramLovForm.get('zoneCd')?.value);
                this.btns.firePremRtForPublic.addDisabled = false;
                this.firePremRtForPublicTblConfig.loading = false;
              });
            });
          },
          error: (error: any) => {
            this.firePremRtForPublicTblConfig.loading = false;
            this._jDS.contorlLoading(false);
          }
        });
      }
      catch (e) {
        console.error(e);
      }
    }
  }

  public selections = {
    activeTypes: [
      { cd: 'A', name: 'Yes' },
      { cd: 'I', name: 'No' },
    ],
    defaultTags: [
      { cd: 'Y', name: 'Yes' },
      { cd: 'N', name: 'No' },
    ],
  }

  onRowClick(ev: any) {
    try {
      ev.update = true;
      this.rowData = ev;
      this.populateFormFromTable();
    }
    catch (e) {
      this.forms.resetFirePremRtForPublicForm();
      this.rowData = null;
    }
  }

  populateFormFromTable() {
    try {
      let data = this.rowData;
      this.forms.firePremRtForPublicForm.get('premRt')?.disable();
      this.forms.firePremRtForPublicForm.get('effDateFrom')?.disable();
      this.btns.firePremRtForPublic.updateDisabled = !data.update;
      this.btns.firePremRtForPublic.deleteDisabled = !data.temp;
      this.tempDataRow = !data.temp;
      this.forms.firePremRtForPublicForm.patchValue(this.firePremRtForPublicFormManipulate.dbToForm(data));
    }
    catch (e) {
      console.error(e);
    }
  }

  public onAdd() {
    try {
      this.assignDefaultTag();
      this._formService.hideFormMsg("fire-prem-rt-for-public-error-message");
      if (!this.premRtCheck()) {
        this._formService.showFormMsg("fire-prem-rt-for-public-error-message", "Invalid value in Premium Rate. The value entered exceeded the maximum limit.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("fire-prem-rt-for-public-error-message");
        }, 10000);
      } else if (!this.commRtCheck()) {
        this._formService.showFormMsg("fire-prem-rt-for-public-error-message", "Invalid value in Commission Rate. The value entered exceeded the maximum limit.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("fire-prem-rt-for-public-error-message");
        }, 10000);
      } else if (!this.defaultTagCheck('I')) {
        this._formService.showFormMsg("fire-prem-rt-for-public-error-message", "Unable to add record. Default rate for the effectivity period already exists. Only one record should be tagged as default under the same period of effectivity.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("fire-prem-rt-for-public-error-message");
        }, 10000);
      } else if (!this.effDateToCheck()) {
        this._formService.showFormMsg("fire-prem-rt-for-public-error-message", "Expiry Date cannot be earlier than Effective Date. Please enter a different Date of Effectivity.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("fire-prem-rt-for-public-error-message");
        }, 10000);
      }
      else if (!this.effDateFromCheck('I')) {
        this._formService.showFormMsg("fire-prem-rt-for-public-error-message", "Unable to add record. Premium rate within the entered effectivity period already exists.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("fire-prem-rt-for-public-error-message");
        }, 10000);
      }
      else if (this.forms.firePremRtForPublicForm.valid) {
        let rowToBeAdded: { [key: string]: any } = this.firePremRtForPublicFormManipulate.formToDb(this.forms.firePremRtForPublicForm.value, 'I');
        let rowToBeAddedTemp: { [key: string]: any } = this.firePremRtForPublicFormManipulate.formToTemp(this.forms.firePremRtForPublicForm.value, 'I');
        rowToBeAdded.temp = true;
        this.tempData.push(rowToBeAddedTemp);
        this.firePremRtForPublicTblConfig.tblData = [rowToBeAdded, ...this.firePremRtForPublicTblConfig.tblData];
        this.forms.resetFirePremRtForPublicForm();
        this.btns.firePremRtForPublic.saveDisabled = false;
      }
      else {
        this.showErrorValidator.firePremRtForPublic();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public onDelete() {
    try {
      this.forms.firePremRtForPublicForm.get('premRt')?.enable();
      this.forms.firePremRtForPublicForm.get('effDateFrom')?.enable();
      let clickData = this.rowData;
      clickData.PREM_RT = this.forms.firePremRtForPublicForm.get('premRt')?.value ? parseFloat(this.forms.firePremRtForPublicForm.get('premRt')?.value).toFixed(9) : null;
      clickData.EFF_DATE_FROM = this.datePipe.transform(new Date(clickData.EFF_DATE_FROM), 'yyyy-MM-dd');
      clickData.EFF_DATE_TO = clickData.EFF_DATE_TO=='' || clickData.EFF_DATE_TO==null ? null : this.datePipe.transform(new Date(clickData.EFF_DATE_TO), 'yyyy-MM-dd');
      this.firePremRtForPublicTblConfig.tblData = this.firePremRtForPublicTblConfig.tblData.filter((d: any) => {
        return d !== this.rowData;
      });
      this.tempData = this.tempData.filter(
        function(e: any) {
          return e.PREM_RT != clickData.PREM_RT || e.EFF_DATE_FROM != clickData.EFF_DATE_FROM || e.EFF_DATE_TO != clickData.EFF_DATE_TO;
        }
      );
      if (this.tempData==null || this.tempData=='') {
        this.btns.firePremRtForPublic.saveDisabled = true;
      }
      this.forms.resetFirePremRtForPublicForm();
    }
    catch (e) {
      console.error(e);
    }
  }

  public onUpdate() {
    try {
      this.assignDefaultTag();
      this.forms.firePremRtForPublicForm.get('premRt')?.enable();
      this.forms.firePremRtForPublicForm.get('effDateFrom')?.enable();
      this._formService.hideFormMsg("fire-prem-rt-for-public-error-message");
      if (!this.commRtCheck()) {
        this.forms.firePremRtForPublicForm.get('premRt')?.disable();
        this.forms.firePremRtForPublicForm.get('effDateFrom')?.disable();
        this._formService.showFormMsg("fire-prem-rt-for-public-error-message", "Invalid value in Commission Rate. The value entered exceeded the maximum limit.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("fire-prem-rt-for-public-error-message");
        }, 10000);
      } else if (!this.defaultTagCheck('U')) {
        this.forms.firePremRtForPublicForm.get('premRt')?.disable();
        this.forms.firePremRtForPublicForm.get('effDateFrom')?.disable();
        this._formService.showFormMsg("fire-prem-rt-for-public-error-message", "Unable to update record. Default rate for the effectivity period already exists. Only one record should be tagged as default under the same period of effectivity.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("fire-prem-rt-for-public-error-message");
        }, 10000);
      } else if (!this.effDateToCheck()) {
        this.forms.firePremRtForPublicForm.get('premRt')?.disable();
        this.forms.firePremRtForPublicForm.get('effDateFrom')?.disable();
        this._formService.showFormMsg("fire-prem-rt-for-public-error-message", "Expiry Date cannot be earlier than Effective Date. Please enter a different Date of Effectivity.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("fire-prem-rt-for-public-error-message");
        }, 10000);
      }
      else if (!this.effDateFromCheck('U')) {
        this.forms.firePremRtForPublicForm.get('premRt')?.disable();
        this.forms.firePremRtForPublicForm.get('effDateFrom')?.disable();
        this._formService.showFormMsg("fire-prem-rt-for-public-error-message", "Unable to update record. Premium rate within the entered effectivity period already exists.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("fire-prem-rt-for-public-error-message");
        }, 10000);
      }
      else if (this.forms.firePremRtForPublicForm.valid) {
        this.forms.firePremRtForPublicForm.get('premRt')?.enable();
        this.forms.firePremRtForPublicForm.get('effDateFrom')?.enable();
        let clickData = this.rowData;
        clickData.PREM_RT = this.forms.firePremRtForPublicForm.get('premRt')?.value ? parseFloat(this.forms.firePremRtForPublicForm.get('premRt')?.value).toFixed(9) : null;
        clickData.EFF_DATE_FROM = this.datePipe.transform(new Date(clickData.EFF_DATE_FROM), 'yyyy-MM-dd');
        clickData.EFF_DATE_TO = clickData.EFF_DATE_TO=='' || clickData.EFF_DATE_TO==null ? null : this.datePipe.transform(new Date(clickData.EFF_DATE_TO), 'yyyy-MM-dd');
        let toBeUpdatedIndex = this.firePremRtForPublicTblConfig.tblData.indexOf(this.rowData);
        let premRtRowValue = this.rowData.PREM_RT;
        let rowToBeUpdated: { [key: string]: any } = this.firePremRtForPublicFormManipulate.formToDb(this.forms.firePremRtForPublicForm.value, 'U');
        let rowToBeUpdatedTemp: { [key: string]: any } = this.firePremRtForPublicFormManipulate.formToTemp(this.forms.firePremRtForPublicForm.value, 'U');
        this.forms.firePremRtForPublicForm.get('premRt')?.disable();
        this.forms.firePremRtForPublicForm.get('effDateFrom')?.disable();
        let tempInsertChecker = this.tempData.find(
          (m: { PREM_RT: any, EFF_DATE_FROM: any, EFF_DATE_TO: any }) => 
            m.PREM_RT == clickData.PREM_RT && m.EFF_DATE_FROM == clickData.EFF_DATE_FROM && clickData.MODE == 'I');
        let tempUpdateChecker = this.tempData.find(
          (m: { PREM_RT: any, EFF_DATE_FROM: any, EFF_DATE_TO: any }) => 
            m.PREM_RT == clickData.PREM_RT && m.EFF_DATE_FROM == clickData.EFF_DATE_FROM && clickData.MODE == 'U');
        if (this.rowData.temp) {
          rowToBeUpdated.temp = true;
        }
        else {
          rowToBeUpdated.onDbButUpdatedTemp = true;
          rowToBeUpdated.PREM_RT = premRtRowValue;
        }
        if (tempInsertChecker!=null && tempInsertChecker!='') {
          //tempInsertChecker = rowToBeUpdated;
          tempInsertChecker.ACTIVE_TAG = rowToBeUpdatedTemp.ACTIVE_TAG;
          tempInsertChecker.EFF_DATE_TO = rowToBeUpdatedTemp.EFF_DATE_TO;
          tempInsertChecker.DEFAULT_TAG = rowToBeUpdatedTemp.DEFAULT_TAG;
          tempInsertChecker.COMM_RT = rowToBeUpdatedTemp.COMM_RT;
          tempInsertChecker.REMARKS = rowToBeUpdatedTemp.REMARKS;
          tempInsertChecker.USER_ID = rowToBeUpdatedTemp.USER_ID;
          tempInsertChecker.MODE = 'I';
          rowToBeUpdated.MODE = 'I';
          rowToBeUpdatedTemp.MODE = 'I';
        }
        else if (tempUpdateChecker!=null && tempUpdateChecker!='') {
          tempUpdateChecker.ACTIVE_TAG = rowToBeUpdatedTemp.ACTIVE_TAG;
          tempUpdateChecker.EFF_DATE_TO = rowToBeUpdatedTemp.EFF_DATE_TO;
          tempUpdateChecker.DEFAULT_TAG = rowToBeUpdatedTemp.DEFAULT_TAG;
          tempUpdateChecker.COMM_RT = rowToBeUpdatedTemp.COMM_RT;
          tempUpdateChecker.REMARKS = rowToBeUpdatedTemp.REMARKS;
          tempUpdateChecker.USER_ID = rowToBeUpdatedTemp.USER_ID;
          tempUpdateChecker.MODE = 'U';
        }
        else { 
          this.tempData.push(rowToBeUpdatedTemp);
        }
        this.forms.resetFirePremRtForPublicForm();
        this.firePremRtForPublicTblConfig.tblData[toBeUpdatedIndex] = rowToBeUpdated;
        this.btns.firePremRtForPublic.saveDisabled = false;
        this.firePremRtForPublicTblConfig.tblData = [... this.firePremRtForPublicTblConfig.tblData];
      }
      else {
        this.showErrorValidator.firePremRtForPublic();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public onSave() {
    try {
      this.btns.firePremRtForPublic.saveDisabled = true;
      this.showForm = false;
      this._formService.showFormLoader(null, "fire-prem-rt-for-public-maintenance-loading", "Saving.<br>Please wait ...", null, null);
      if (this.tempData.length > 0) {
        this._api.saveFirePremRates(this.tempData).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                if (data.status === 'SUCCESS') {
                  this.onComplete.firePremRtForPublic();
                }
                else {
                  this.onComplete.firePremRtForPublicError();
                }
              });
            });
          },
          error: (data: any) => {
            this.onComplete.firePremRtForPublicError();
          },
        });
      }
      else
        this.onComplete.firePremRtForPublic();
    }
    catch (e) {
      console.error(e);
    }
  }

  private onComplete = {
    firePremRtForPublic: () => {
      this.tempData = [];
      this.getData.firePremRtForPublic();
      this.btns.firePremRtForPublic.saveDisabled = true;
      this.showForm = true;
      this._formService.hideFormLoader("fire-prem-rt-for-public-maintenance-loading");
      this.forms.resetFirePremRtForPublicForm();
      this.openDialog('SUCCESS', 'Fire Premium Rates for Public User details successfully saved');
    },
    firePremRtForPublicError: () => {
      this.showForm = true;
      this.btns.firePremRtForPublic.saveDisabled = false;
      this.openDialog('FAILED', 'Fire Premium Rates for Public User details saving failed');
      this._formService.hideFormLoader("fire-prem-rt-for-public-maintenance-loading");
    },
  }

  openDialog(title: any, message: any) {
    try {
      this.showForm = true;
      this._formService.hideFormLoader("fire-prem-rt-for-public-maintenance-loading");
      this._matDialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        } 
      });
    } catch(e) {
      console.error(e);
    }
  }

  effDateToCheck() {
    try {
      let effDateFromTemp = this.datePipe.transform(new Date(this.forms.firePremRtForPublicForm.get('effDateFrom')?.value), 'yyyy-MM-dd');
      let effDateToTemp = this.forms.firePremRtForPublicForm.get('effDateTo')?.value=='' || this.forms.firePremRtForPublicForm.get('effDateTo')?.value==null ? null : this.datePipe.transform(new Date(this.forms.firePremRtForPublicForm.get('effDateTo')?.value), 'yyyy-MM-dd');
    
      if(effDateToTemp!=null && effDateFromTemp!=null) {
        if(effDateToTemp>effDateFromTemp) {
          return true;
        }
        else {
          return false;
        }
      } else {
        return true;
      }
    }
    catch(e) {
      console.error(e);
      return false;
    }
  }

  effDateFromCheck(mode: any) {
    try {
      if (mode === 'U') {
        this.forms.firePremRtForPublicForm.get('premRt')?.enable();
        this.forms.firePremRtForPublicForm.get('effDateFrom')?.enable();
      }
      let premRtTemp = this.forms.firePremRtForPublicForm.get('premRt')?.value ? parseFloat(this.forms.firePremRtForPublicForm.get('premRt')?.value).toFixed(9) : null;
      let effDateFromTemp = this.datePipe.transform(new Date(this.forms.firePremRtForPublicForm.get('effDateFrom')?.value), 'yyyy-MM-dd');
      let effDateToTemp = this.forms.firePremRtForPublicForm.get('effDateTo')?.value=='' || this.forms.firePremRtForPublicForm.get('effDateTo')?.value==null ? null : this.datePipe.transform(new Date(this.forms.firePremRtForPublicForm.get('effDateTo')?.value), 'yyyy-MM-dd');
      let effDateFromField: any;
      let resultCheck: boolean = true;
      if (mode === 'I') {
        effDateFromField = this.firePremRtForPublicTblConfig.tblData.filter(function(e: any) {
          return e.PREM_RT==premRtTemp;
        });;
      } else if (mode === 'U') {
        let effDateFromTemp2 = new Date(this.forms.firePremRtForPublicForm.get('effDateFrom')?.value);
        effDateFromField = this.firePremRtForPublicTblConfig.tblData.filter(function(e: any) {
          return e.PREM_RT==premRtTemp && (new Date(e.EFF_DATE_FROM).getMonth()!==effDateFromTemp2.getMonth() || new Date(e.EFF_DATE_FROM).getDate()!==effDateFromTemp2.getDate() || new Date(e.EFF_DATE_FROM).getFullYear()!==effDateFromTemp2.getFullYear());
        });
      }
      for (let r of effDateFromField) {
        let effDateFromTbl = this.datePipe.transform(new Date(r.EFF_DATE_FROM), 'yyyy-MM-dd');
        let effDateToTbl = r.EFF_DATE_TO=='' || r.EFF_DATE_TO==null ? null : this.datePipe.transform(new Date(r.EFF_DATE_TO), 'yyyy-MM-dd');
        //nope is return false
        if (!effDateToTbl && !effDateToTemp) {
          resultCheck = false;
          break;
        } 
        else if (!effDateToTbl && effDateToTemp && effDateFromTemp && effDateFromTbl) {
          if (effDateFromTemp >= effDateFromTbl) {
            resultCheck = false;
            break;
          } 
          else if (effDateFromTemp < effDateFromTbl && effDateToTemp >= effDateFromTbl) {
            resultCheck = false;
            break;
          }
        }
        else if (!effDateToTemp && effDateFromTemp && effDateFromTbl && effDateToTbl) {
          if (effDateFromTemp <= effDateToTbl) {
            resultCheck = false;
            break;
          }
        }
        else if (effDateToTemp && effDateFromTemp && effDateFromTbl && effDateToTbl) {
          if (effDateToTemp >= effDateFromTbl && effDateToTemp <= effDateToTbl) {
            resultCheck = false;
            break;
          }
          else if (effDateFromTemp >= effDateFromTbl && effDateFromTemp <= effDateToTbl) {
            resultCheck = false;
            break;
          }
          else if (effDateFromTemp >= effDateFromTbl && effDateToTemp <= effDateToTbl) {
            resultCheck = false;
            break;
          }
          else if (effDateFromTemp <= effDateFromTbl && effDateToTemp >= effDateToTbl) {
            resultCheck = false;
            break;
          }
        }
        else {
          resultCheck = false;
        }
      }
      if (mode === 'U') {
        this.forms.firePremRtForPublicForm.get('premRt')?.disable();
        this.forms.firePremRtForPublicForm.get('effDateFrom')?.disable();
      }
      return resultCheck;
    }
    catch(e) {
      console.error(e);
      return false;
    }
  }

  defaultTagCheck(mode: any) {
    try {
      if (mode === 'U') {
        this.forms.firePremRtForPublicForm.get('premRt')?.enable();
        this.forms.firePremRtForPublicForm.get('effDateFrom')?.enable();
      }
      let defaultTagYesField: any;
      let defaultTagTemp = this.forms.firePremRtForPublicForm.get('defaultTag')?.value;
      let effDateFromTemp = this.datePipe.transform(new Date(this.forms.firePremRtForPublicForm.get('effDateFrom')?.value), 'yyyy-MM-dd');
      let effDateToTemp = this.forms.firePremRtForPublicForm.get('effDateTo')?.value=='' || this.forms.firePremRtForPublicForm.get('effDateTo')?.value==null ? null : this.datePipe.transform(new Date(this.forms.firePremRtForPublicForm.get('effDateTo')?.value), 'yyyy-MM-dd');
      let premRtTemp = this.forms.firePremRtForPublicForm.get('premRt')?.value;
      let resultCheck: boolean = true;
      if (mode === 'I') {
        defaultTagYesField = this.firePremRtForPublicTblConfig.tblData.filter(function(e: any) {
          return e.DEFAULT_TAG == 'Y';
        });
      } else if (mode === 'U') {
        let effDateFromTemp2 = new Date(this.forms.firePremRtForPublicForm.get('effDateFrom')?.value);
        defaultTagYesField = this.firePremRtForPublicTblConfig.tblData.filter(function(e: any) {
          return e.DEFAULT_TAG == 'Y' && (e.PREM_RT != premRtTemp || new Date(e.EFF_DATE_FROM).getMonth()!==effDateFromTemp2.getMonth() || new Date(e.EFF_DATE_FROM).getDate()!==effDateFromTemp2.getDate() || new Date(e.EFF_DATE_FROM).getFullYear()!==effDateFromTemp2.getFullYear());
        });
      }
      if (defaultTagTemp == 'Y') {
        for (let r of defaultTagYesField) {
          let effDateFromTbl = this.datePipe.transform(new Date(r.EFF_DATE_FROM), 'yyyy-MM-dd');
          let effDateToTbl = r.EFF_DATE_TO=='' || r.EFF_DATE_TO==null ? null : this.datePipe.transform(new Date(r.EFF_DATE_TO), 'yyyy-MM-dd');
          if (!effDateToTbl && !effDateToTemp) {
            resultCheck = false;
            break;
          } 
          else if (!effDateToTbl && effDateToTemp && effDateFromTemp && effDateFromTbl) {
            if (effDateFromTemp >= effDateFromTbl) {
              resultCheck = false;
              break;
            } 
            else if (effDateFromTemp < effDateFromTbl && effDateToTemp >= effDateFromTbl) {
              resultCheck = false;
              break;
            }
          }
          else if (!effDateToTemp && effDateFromTemp && effDateFromTbl && effDateToTbl) {
            if (effDateFromTemp <= effDateToTbl) {
              resultCheck = false;
              break;
            }
          }
          else if (effDateToTemp && effDateFromTemp && effDateFromTbl && effDateToTbl) {
            if (effDateToTemp >= effDateFromTbl && effDateToTemp <= effDateToTbl) {
              resultCheck = false;
              break;
            }
            else if (effDateFromTemp >= effDateFromTbl && effDateFromTemp <= effDateToTbl) {
              resultCheck = false;
              break;
            }
            else if (effDateFromTemp >= effDateFromTbl && effDateToTemp <= effDateToTbl) {
              resultCheck = false;
              break;
            }
            else if (effDateFromTemp <= effDateFromTbl && effDateToTemp >= effDateToTbl) {
              resultCheck = false;
              break;
            }
          }
          else {
            resultCheck = false;
          }
        }
      }
      if (mode === 'U') {
        this.forms.firePremRtForPublicForm.get('premRt')?.disable();
        this.forms.firePremRtForPublicForm.get('effDateFrom')?.disable();
      }
      return resultCheck;
    }
    catch(e) {
      console.error(e);
      return false;
    }
  }

  premRtCheck() {
    try {
      let resultCheck: boolean = true;
      if (parseFloat(this._formService.setToNumberWithDecimal(this.forms.firePremRtForPublicForm.get('premRt')?.value)) > 999.999999999) {
        resultCheck = false;
      }
      else {
        resultCheck = true;
      }
      return resultCheck;
    }
    catch(e) {
      console.error(e);
      return false;
    }
  }

  commRtCheck() {
    try {
      let resultCheck: boolean = true;
      if (parseFloat(this._formService.setToNumberWithDecimal(this.forms.firePremRtForPublicForm.get('commRt')?.value)) > 999.999999999) {
        resultCheck = false;
      }
      else {
        resultCheck = true;
      }
      return resultCheck;
    }
    catch(e) {
      console.error(e);
      return false;
    }
  }

  public showErrorValidator = {
    firePremRtForPublic: () => {
      try {
        Object.keys(this.forms.firePremRtForPublicForm.controls).forEach(key => {
          const controlErrors = this.forms.firePremRtForPublicForm.get(key)?.errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              if (keyError == 'hasDupe' && controlErrors[keyError] === true)
                this.errorMessage = "Unable to add record. Premium rate within the entered effectivity period already exists.";
              else if (keyError == 'matDatepickerParse')
                this.errorMessage = "Invalid date format. Kindly input as MM/DD/YYYY";
              else if (keyError == 'required' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
            });
          }
        });
        this._formService.showFormMsg("fire-prem-rt-for-public-error-message", this.errorMessage, "E");
        //this.openDialog('ERROR', this.errorMessage);
        setTimeout(()=>{                           
          this._formService.hideFormMsg("fire-prem-rt-for-public-error-message");
        }, 10000);
      }
      catch (e) {
    console.error(e);

      }
    },
  }

  getDataCheck() {
    try {
      this.tempData = [];
      this.firePremRtForPublicTblConfig.tblData = [];
      this.forms.resetFirePremRtForPublicForm();
      this.btns.firePremRtForPublic.saveDisabled = true;
      this.btns.firePremRtForPublic.addDisabled = true;
      this.forms.firePremRtForPublicForm.disable();
      if (this.forms.paramLovForm.get('classCd')?.value && this.forms.paramLovForm.get('lineCd')?.value && this.forms.paramLovForm.get('sublineCd')?.value
      && this.forms.paramLovForm.get('planCd')?.value && this.forms.paramLovForm.get('perilCd')?.value && this.forms.paramLovForm.get('zoneCd')?.value) {
        this.getData.firePremRtForPublic();
        this.btns.firePremRtForPublic.addDisabled = false;
        this.forms.firePremRtForPublicForm.enable();
      }
    } catch (e) {
      console.error(e);
    }
  }

  assignDefaultTag() {
    try {
      if (this.forms.firePremRtForPublicForm.get('defaultTag')?.value == 'Y') {
        this.forms.firePremRtForPublicForm.patchValue({
          defaultTagDisp: 'Yes',
        });
      } else {
        this.forms.firePremRtForPublicForm.patchValue({
          defaultTagDisp: 'No',
        });
      }
    }
    catch (e) {
      console.error(e);
    }
  }


  dateFilter(dateString: any) {
    try {
      if (dateString=='' || dateString==null) {
        return '';
      }
      else if (dateString=='0000-00-00' || dateString=='0000-00-00 12:00:00 AM' || dateString=='1970-01-01') {
        return '';
      }
      else {
        return formatDate(dateString, 'yyyy-MM-dd', 'en');
      }
    }
    catch(e) {
      return '';
    }
  }

  allowNumericDigitsOnly(event: any) {
    try {
      if (/[0-9.]/.test(String.fromCharCode(event.keyCode))) {
        return true;
      }
      else {
        event.preventDefault();
        return false;
      }
    }
    catch (e) {
      console.error(e);
      return undefined;
    }
  }

  allowNumericAndSlashOnly(event: any) {
    try {
      if (/^([0-9/]+)$/.test(String.fromCharCode(event.keyCode))) {
        return true;
      }
      else {
        event.preventDefault();
        return false;
      }
    }
    catch (e) {
      console.error(e);
      return undefined; 
    }
  }

  rateFormat(fieldName:string){ 
    try {
      if(this.forms.firePremRtForPublicForm.get(fieldName)?.value!==''&&this.forms.firePremRtForPublicForm.get(fieldName)?.value!==null){
        let fieldFloat = parseFloat(this.forms.firePremRtForPublicForm.get(fieldName)?.value.replace(/,/g, ''))
        let fieldRate = fieldFloat.toFixed(9);
        if(isNaN(fieldFloat)||fieldRate=='NaN'){
          this.forms.firePremRtForPublicForm.get(fieldName)?.setValue('');
        }
        else{
          this.forms.firePremRtForPublicForm.get(fieldName)?.setValue(fieldRate);
        }
      }
    }
    catch (e) {
      console.error(e);
    }
  }

}
