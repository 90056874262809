<section id="dialog-box">
    <div class="mtn-title">
        <h3 class="overlay-header" mat-dialog-title>{{ data.title }}</h3>
    </div>
    <mat-dialog-content [innerHTML]="data.content"></mat-dialog-content>
    <mat-dialog-actions>
        <button mat-button *ngFor="let button of data.buttons" [mat-dialog-close]="button.action">
            {{button.text}}
        </button>
    </mat-dialog-actions>
</section>