<section id="generate-reports">
    <div class="mtn-title">
        <p>GENERATE REPORTS</p>
    </div>
    <form [formGroup]="reportParams">
        <div id="generate-report-form">
            <div class="form-loader"></div>
            <div class="form-fields"><div class="form">
                    <div class="col-case">
                        <div class="field required">
                            <label>Report Name</label>
                            <mat-form-field appearance="fill">
                                <mat-select placeholder="Report Name" formControlName="reportType" (selectionChange)="evaluateFields()">
                                    <mat-option *ngFor="let option of reportTypes" [value]="option.reportId">
                                        {{option.reportName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="field required">
                            <label>Product</label>
                            <mat-form-field appearance="fill">
                                <mat-select placeholder="Product Name" formControlName="lineCd" (selectionChange)="getSublines()">
                                    <mat-option *ngFor="let option of lines" [value]="option.lineCd">
                                        {{option.lineName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="field required">
                            <label>Product Type</label>
                            <mat-form-field appearance="fill" >
                                <mat-select placeholder="Type Name" formControlName="sublineCd">
                                    <mat-option *ngFor="let option of filteredSublineOptions" [value]="option.sublineCd">
                                        {{option.sublineName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="field required">
                            <label>Extract By</label>
                            <mat-form-field appearance="fill">
                                <mat-select placeholder="Extraction Method" formControlName="extractBy">
                                    <mat-option *ngFor="let option of extractMethod" [value]="option.methodId">
                                        {{option.methodName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="field">
                            <label>From</label>
                            <mat-form-field appearance="fill">
                                <input matInput [matDatepicker]="picker" formControlName="dateFrom" placeholder="MM/DD/YYYY">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="field">
                            <label>To</label>
                            <mat-form-field appearance="fill">
                                <input matInput [matDatepicker]="picker2" formControlName="dateTo" placeholder="MM/DD/YYYY">
                                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="field required">
                            <label>Destination</label>
                            <mat-form-field appearance="fill" >
                                <mat-select placeholder="Format" formControlName="format">
                                    <mat-option *ngFor="let option of formats" [value]="option.id">
                                        {{option.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div id="generate-report-form-msg"></div>
        </div>  
        <div class="form-btns">
            <button class="btn btn2" (click)="validateParams()">Print</button>
        </div>
    </form>
</section>