import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

@Component({
    selector: 'plan-lov',
    templateUrl: './plan-lov.component.html',
    styleUrls: ['./plan-lov.component.css']
})
export class PlanLovComponent implements OnInit {

    private selectedData: any;

    public tableSelected: any;

    public titleLov: string = "PLAN";

    moduleId: string = 'BMM038';

    planTable: any = {
        cols: [
            {
                key: "PLAN_CD",
                header: "Code",
                dataType: "number",
                width: '15%'
            },
            {
                key: "PLAN_NAME",
                header: "Plan Name",
                dataType: "string",
                width: '85%'
            },
        ],
        tblData: [],
        selection: "single",
        paginator: true,
        rowsPerPage: 10,
        lazy: false,
        totalRecords: 15,
        loading: false,
    };

    constructor(
        private dialogRef: MatDialogRef<PlanLovComponent>,
        private _api: APICallService,
        private securityService: SecurityService,
        private jsonDataService: JsonDataService,
        private appMessageService: AppMessageService,
        private userDetailService: UserDetailsService,
        @Inject(MAT_DIALOG_DATA) public fromMainData: any,
    ) {
        try {
            this.tableSelected = this.planTable;
            this.getPlanData();
        } catch (e) {
    console.error(e);

        }
    }

    ngOnInit(): void {
    }

    onRowClick(ev: any) {
        try {
            this.selectedData = ev;
        } catch (e) {
    console.error(e);

        }
    }

    getPlanData() {
        try {
            this.planTable.loading = true;
            this.jsonDataService.contorlLoading(true);
            this._api.getAllPlan({
                moduleId: this.fromMainData.moduleId,
                userId: this.userDetailService.userId,
                type: "LOV"
            }).subscribe({
                next: (response: any) => {
                    this.securityService.checkRequestKeyResponse(response, () => {
                        this.securityService.hasValidCsrfToken(response, () => {
                            let resContent = JSON.parse(this.jsonDataService.decrypt(response.response));
                            if (resContent.moduleId === this.moduleId) {
                                this.jsonDataService.contorlLoading(false);
                                this.tableSelected.tblData = resContent.data.filter((d: any) => (d.LINE_CD === this.fromMainData.lineCd && d.SUBLINE_CD === this.fromMainData.sublineCd && d.ACTIVE_TAG === 'A' && !((this.fromMainData.planCdExclude ?? []).includes(+d.PLAN_CD))));
                                if (this.fromMainData?.lpad) {
                                    this.tableSelected.tblData = this.tableSelected.tblData.map((c: any) => { return { ...c, PLAN_CD: parseInt(c.PLAN_CD)?.toString().padStart(this.fromMainData?.lpad, "0") } })
                                }
                                this.planTable.loading = false;
                            } else {
                                this.planTable.loading = false;
                                this.jsonDataService.contorlLoading(false);
                                this.appMessageService.showAppMessage("You do not have the correct privilege to access the data. Kindly contact your administrator for assistance.", "error");
                            }
                        });
                    });
                },
                error: (e: any) => {

                    this.planTable.loading = false;
                    this.jsonDataService.contorlLoading(false);
                    this.appMessageService.showAppMessage(e, "error");
                }
            });
        } catch (e) {
    console.error(e);

            this.planTable.loading = false;
            this.jsonDataService.contorlLoading(false);
        }
    }

    onClose(str: string) {
        try {
            if (this.selectedData == '' || this.selectedData == null) {
                this.dialogRef.close({ content: '', button: str });
            } else {
                this.dialogRef.close({ content: this.selectedData, button: str });
            }
        } catch (e) {
    console.error(e);

        }
    }
}
