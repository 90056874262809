import { Component, OnInit } from '@angular/core';
import { APICallService } from 'src/app/services/api-call.service';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { forkJoin, Observable } from 'rxjs';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { SecurityService } from 'src/app/services/security.service'; 
import { CountryLovDialogComponent } from '../../common/country-lov/country-lov.component';
import { ProvinceLovDialogComponent } from '../../common/province-lov/province-lov.component';
import { CityLovDialogComponent } from '../../common/city-lov/city-lov.component';
import { ZoneLovDialogComponent } from '../../common/zone-lov/zone-lov.component';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';
import { PlanLovComponent } from '../../common/plan-lov/plan-lov.component';
import { ClassLovComponent } from '../../common/class-lov/class-lov.component';
import { OccupancyLovComponent } from '../../common/occupancy-lov/occupancy-lov.component';
import { BusinessCodeLovDialogComponent } from '../../common/business-cd-lov/business-cd-lov.component';
@Component({
  selector: 'occupancy-business-code-per-plan-maintenance',
  templateUrl: './occupancy-business-code-per-plan-maintenance.component.html',
  styleUrls: ['./occupancy-business-code-per-plan-maintenance.component.css']
})
export class OccupancyBusinessCodePerPlanMaintenanceComponent implements OnInit, LeaveConfirmation {
  
  datePipe = new DatePipe('en-us');

  addDisabled : boolean = false;
  saveDisabled : boolean = true;
  deleteDisabled : boolean = true;
  tranPosted : boolean = false;
  noLoadingInProcess : boolean = true;
  validated : boolean = false;
  userIDReadOnly : boolean = false;
  isLineSelected : boolean = false;
  pubUserStdRatesCodeField: string = '';
  currentUser: string = this.jsonDataService.retrieveFromStorage('userId');
  rowClickedData: any;
  selectedData : any = null;
  moduleId: string = 'BMM110';
  moduleName: string = '';
  tempAddedData: any = [];
  tempUpdateData: any = [];
  sublineDisabled: boolean = true;
  planDisabled:boolean = true;
  
  selectedLine: any = {
    LINE_CD: '',
    LINE_NAME: '',
    LINE_FIELD: ''
  };

  selectedSubline: any = {
    SUBLINE_CD: '',
    SUBLINE_NAME: '',
    SUBLINE_FIELD: ''
  };

  selectedPlan: any = {
    PLAN_CD: '',
    PLAN_NAME: '',
    PLAN_FIELD: ''
  }; 

  selectedClass: any = {
    CLASS_CD: '',
    CLASS_NAME: '',
    CLASS_FIELD: ''
  };
  selectedZone: any = {
    ZONE_CD: '',
    ZONE_NAME: '',
    ZONE_FIELD: ''
  }; 
  selectedOccupancy: any = {
    OCCUPANCY_CD: '',
    OCCUPANCY_DESC: '',
    OCCUPANCY_BM_CODE: '',
    OCCUPANCY_FIELD: ''
  }; 
  selectedBusiness: any = {
    BUSINESS_CD: '',
    BUS_NAME: '',
    BUS_BM_CODE:'',
    BUSINESS_FIELD: ''
  }; 
  
  paramForm = this.formBuilder.group({
    lineCd: [null],
    lineName: [null],
    lineField: [null],
    sublineCd: [null],
    sublineName: [null],
    sublineField: [null],
    planCd: [null],
    planName: [null],
    planField: [null],
  });
  preApprovedSubdivisionForm = this.formBuilder.group({
    classCd: [null, [Validators.required]],
    className: [null],
    classField: [null],
    activeTag: ['A', [Validators.required]],
    zoneCd: [null],
    zoneName: [null],
    zoneField: [null],
    occupancyCd: [null],
    occupancyDesc: [null],
    occupancyBmCode: [null],
    occupancyField: [null],
    origBusinessCd: [null],
    businessCd: [null],
    businessName: [null],
    businessBmCode: [null],
    businessField: [null],
    remarks: [null],
    userID: [null],
    lastUserUpdate: [null]
  });

  constructor(
    private apiCallService: APICallService,
    public _Dialog: MatDialog, 
    private userDetailService: UserDetailsService,
    private formService: FormService, 
    private formBuilder: FormBuilder, 
    private appMessageService: AppMessageService,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService
  ) {
    let moduleLists = JSON.parse(this.jsonDataService.decrypt(sessionStorage.getItem("mdls") || "")); 
    this.moduleName = moduleLists.filter((data: any) => { return data.moduleId === this.moduleId })[0].moduleDesc.toUpperCase();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempAddedData!='' || this.tempUpdateData!='') {
      return false;
    } else {
      return true;
    }
  }

  preApprovedSubdivisionTblConfig: any = {
    cols: [
      {
        key: "CLASS_NAME",
        header: "Class",
        dataType: "string",
        width: '13%'
      },
      {
        key: "ZONE_NAME",
        header: "Geo. Zone",
        dataType: "string",
        width: '13%'
      },
      {
        key: "OCCUPANCY_DESC",
        header: "Occupancy",
        dataType: "string",
        width: '25%'
      },
      {
        key: "BUS_NAME",
        header: "Business",
        dataType: "string",
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        width: '10%'
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }
  ngOnInit() {
  }
  initOnGet(): void {
    try {
      this.apiCallService.refreshNeeded$
            .subscribe(() => {
              this.getAllPreApprovedSubdivision();
          });
      this.getAllPreApprovedSubdivision();
    }
    catch(e) {
    }
  }

  async getAllPreApprovedSubdivision(){
    try{
      let lineCd = this.paramForm.get('lineCd')?.value;
      let sublineCd = this.paramForm.get('sublineCd')?.value;
      let planCd = this.paramForm.get('planCd')?.value;

      if (lineCd && sublineCd&& planCd) {
        const params = {
          queryParams:{
            sublineCd: sublineCd,
            lineCd: lineCd,
            planCd: planCd,
          },
          moduleId: this.moduleId,
          userId: this.userDetailService.userId,
          type: "MODULE"
        }
        this.preApprovedSubdivisionTblConfig.loading = true;
        this.apiCallService.getOccupancyAndBusinessCodeperPlan(params).subscribe({
          next: (data: any) => {
            this.securityService.checkRequestKeyResponse(data, ()=>{
              this.securityService.hasValidCsrfToken(data, ()=>{
                data = JSON.parse(this.jsonDataService.decrypt(data.response));
                let rawData:any = [];
                if(!Array.isArray(data)){
                  rawData.push(data);
                }
                else{
                  rawData = data;
                }
                if(rawData.length>0){
                  for(let item of rawData){
                    item.ZONE_FIELD = item.ZONE_NAME +" - "+ item.ZONE_DESC;
                  }
                }
                this.preApprovedSubdivisionTblConfig.tblData = rawData;
                this.preApprovedSubdivisionTblConfig.loading = false;
                this.tempAddedData = [];
                this.tempUpdateData = [];
                this.saveDisabled = this.saveChecker();
              });
            });
          },
          error: (e: any) => {
            this.preApprovedSubdivisionTblConfig.loading = false;
          }
        });
      } else{
        this.preApprovedSubdivisionTblConfig.loading = true;
        this.preApprovedSubdivisionTblConfig.tblData = [];
        this.preApprovedSubdivisionTblConfig.loading = false;
        this.tempAddedData = [];
        this.tempUpdateData = [];
        this.saveDisabled = this.saveChecker();
      }
    }catch(e){
    }
  }

  updateLine() {
    try {
      this.tranPosted = true;
      this.validateUpdate();
      if (this.validated) {
        this.updateFormTable();
      }
    }
    catch(e) {
    }
  }

  updateFormTable() {
    try {
      let fieldClassCd = this.preApprovedSubdivisionForm.get('classCd')?.value;
      let fieldZoneCd = this.preApprovedSubdivisionForm.get('zoneCd')?.value;
      let fieldOccupancyCd = this.preApprovedSubdivisionForm.get('occupancyCd')?.value;
      let fieldBusinessCd = this.preApprovedSubdivisionForm.get('origBusinessCd')?.value;
      let tempInsertChecker = this.tempAddedData.find((line: { CLASS_CD: any, ZONE_CD: any, OCCUPANCY_CD: any, BUSINESS_CD: any}) => 
        line.CLASS_CD.toUpperCase()==fieldClassCd.toUpperCase()
        &&+line.ZONE_CD == +fieldZoneCd
        &&+line.OCCUPANCY_CD == +fieldOccupancyCd
        &&+line.BUSINESS_CD == +fieldBusinessCd
      );
      let tempUpdateChecker = this.tempUpdateData.find((line: { CLASS_CD: any, ZONE_CD: any, OCCUPANCY_CD: any, BUSINESS_CD: any }) => 
        line.CLASS_CD.toUpperCase()==fieldClassCd.toUpperCase()
        &&+line.ZONE_CD == +fieldZoneCd
        &&+line.OCCUPANCY_CD == +fieldOccupancyCd
        &&+line.BUSINESS_CD == +fieldBusinessCd
      );
      
      this.tableDataUpdate();
        
      // Adds Updated Data that exists in the database to an object array for updated datas
      if(tempInsertChecker!=null && tempInsertChecker!='') {
        //this.tempUpdateData.push(this.formValueToData());
        tempInsertChecker.CLASS_CD = this.preApprovedSubdivisionForm.get('classCd')?.value;
        tempInsertChecker.CLASS_NAME = this.preApprovedSubdivisionForm.get('className')?.value;
        tempInsertChecker.CLASS_FIELD = this.preApprovedSubdivisionForm.get('classField')?.value;
        tempInsertChecker.ZONE_CD = this.preApprovedSubdivisionForm.get('zoneCd')?.value;
        tempInsertChecker.ZONE_NAME = this.preApprovedSubdivisionForm.get('zoneName')?.value;
        tempInsertChecker.ZONE_FIELD = this.preApprovedSubdivisionForm.get('zoneField')?.value;
        tempInsertChecker.OCCUPANCY_CD = this.preApprovedSubdivisionForm.get('occupancyCd')?.value;
        tempInsertChecker.OCCUPANCY_DESC = this.preApprovedSubdivisionForm.get('occupancyDesc')?.value;
        tempInsertChecker.OCCUPANCY_FIELD = this.preApprovedSubdivisionForm.get('occupancyField')?.value;
        tempInsertChecker.BUSINESS_CD = this.preApprovedSubdivisionForm.get('businessCd')?.value;
        tempInsertChecker.BUS_NAME = this.preApprovedSubdivisionForm.get('businessName')?.value;
        tempInsertChecker.BUSINESS_FIELD = this.preApprovedSubdivisionForm.get('businessField')?.value;
        tempInsertChecker.OCCUPANCY_BM_CODE = this.preApprovedSubdivisionForm.get('occupancyBmCode')?.value;
        tempInsertChecker.BUS_BM_CODE = this.preApprovedSubdivisionForm.get('businessBmCode')?.value;
        tempInsertChecker.SUBLINE_CD = this.paramForm.get('sublineCd')?.value;
        tempInsertChecker.SUBLINE_NAME = this.paramForm.get('sublineName')?.value;
        tempInsertChecker.SUBLINE_FIELD = this.paramForm.get('sublineField')?.value;
        tempInsertChecker.PLAN_CD = this.paramForm.get('planCd')?.value;
        tempInsertChecker.PLAN_NAME = this.paramForm.get('planName')?.value;
        tempInsertChecker.PLAN_FIELD = this.paramForm.get('planField')?.value;
        tempInsertChecker.LINE_CD = this.paramForm.get('lineCd')?.value;
        tempInsertChecker.LINE_NAME = this.paramForm.get('lineName')?.value;
        tempInsertChecker.LINE_FIELD = this.paramForm.get('lineField')?.value;
        tempInsertChecker.ACTIVE_TAG = this.preApprovedSubdivisionForm.get('activeTag')?.value;
        tempInsertChecker.REMARKS = this.preApprovedSubdivisionForm.get('remarks')?.value;
        tempInsertChecker.LAST_USER_UPDATE = this.datePipe.transform(new Date(this.preApprovedSubdivisionForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a');
        tempInsertChecker.USER_ID = this.preApprovedSubdivisionForm.get('userID')?.value;
        tempInsertChecker.MODE = 'I';
      } 
      else if(tempUpdateChecker!=null && tempUpdateChecker!='') {
        tempUpdateChecker.CLASS_CD = this.preApprovedSubdivisionForm.get('classCd')?.value;
        tempUpdateChecker.CLASS_NAME = this.preApprovedSubdivisionForm.get('className')?.value;
        tempUpdateChecker.CLASS_FIELD = this.preApprovedSubdivisionForm.get('classField')?.value;
        tempUpdateChecker.ZONE_CD = this.preApprovedSubdivisionForm.get('zoneCd')?.value;
        tempUpdateChecker.ZONE_NAME = this.preApprovedSubdivisionForm.get('zoneName')?.value;
        tempUpdateChecker.ZONE_FIELD = this.preApprovedSubdivisionForm.get('zoneField')?.value;
        tempUpdateChecker.OCCUPANCY_CD = this.preApprovedSubdivisionForm.get('occupancyCd')?.value;
        tempUpdateChecker.OCCUPANCY_DESC = this.preApprovedSubdivisionForm.get('occupancyDesc')?.value;
        tempUpdateChecker.OCCUPANCY_FIELD = this.preApprovedSubdivisionForm.get('occupancyField')?.value;
        tempUpdateChecker.BUSINESS_CD = this.preApprovedSubdivisionForm.get('businessCd')?.value;
        tempUpdateChecker.BUS_NAME = this.preApprovedSubdivisionForm.get('businessName')?.value;
        tempUpdateChecker.BUSINESS_FIELD = this.preApprovedSubdivisionForm.get('businessField')?.value;
        tempUpdateChecker.OCCUPANCY_BM_CODE = this.preApprovedSubdivisionForm.get('occupancyBmCode')?.value;
        tempUpdateChecker.BUS_BM_CODE = this.preApprovedSubdivisionForm.get('businessBmCode')?.value;
        tempUpdateChecker.SUBLINE_CD = this.paramForm.get('sublineCd')?.value;
        tempUpdateChecker.SUBLINE_NAME = this.paramForm.get('sublineName')?.value;
        tempUpdateChecker.SUBLINE_FIELD = this.paramForm.get('sublineField')?.value;
        tempUpdateChecker.PLAN_CD = this.paramForm.get('planCd')?.value;
        tempUpdateChecker.PLAN_NAME = this.paramForm.get('planName')?.value;
        tempUpdateChecker.PLAN_FIELD = this.paramForm.get('planField')?.value;
        tempUpdateChecker.LINE_CD = this.paramForm.get('lineCd')?.value;
        tempUpdateChecker.LINE_NAME = this.paramForm.get('lineName')?.value;
        tempUpdateChecker.LINE_FIELD = this.paramForm.get('lineField')?.value;
        tempUpdateChecker.ACTIVE_TAG = this.preApprovedSubdivisionForm.get('activeTag')?.value;
        tempUpdateChecker.REMARKS = this.preApprovedSubdivisionForm.get('remarks')?.value;
        tempUpdateChecker.LAST_USER_UPDATE = this.datePipe.transform(new Date(this.preApprovedSubdivisionForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a');
        tempUpdateChecker.USER_ID = this.preApprovedSubdivisionForm.get('userID')?.value;
        tempUpdateChecker.MODE = 'U';
      }
      else {
        this.tempUpdateData.push(this.formValueToData());
      }
      this.saveDisabled = false;
      this.resetFormValues();
      this.appMessageService.showAppMessage("State details updated!", "success");
    }
    catch(e) {
    }
  }

  tableDataUpdate() {
    try {
      this.preApprovedSubdivisionForm.patchValue({
        activeTag: this.preApprovedSubdivisionForm.get('activeTag')?.value,
        userID: this.currentUser,
        lastUserUpdate: new Date()
      });
      let updateIndex = this.preApprovedSubdivisionTblConfig.tblData.indexOf(this.rowClickedData);
      
      this.preApprovedSubdivisionTblConfig.tblData[updateIndex] = {
        CLASS_CD: this.preApprovedSubdivisionForm.get('classCd')?.value,
        CLASS_NAME: this.preApprovedSubdivisionForm.get('className')?.value,
        CLASS_FIELD: this.preApprovedSubdivisionForm.get('classField')?.value,
        ZONE_CD: this.preApprovedSubdivisionForm.get('zoneCd')?.value,
        ZONE_NAME: this.preApprovedSubdivisionForm.get('zoneName')?.value,
        ZONE_FIELD: this.preApprovedSubdivisionForm.get('zoneField')?.value,
        OCCUPANCY_CD: this.preApprovedSubdivisionForm.get('occupancyCd')?.value,
        OCCUPANCY_DESC: this.preApprovedSubdivisionForm.get('occupancyDesc')?.value,
        OCCUPANCY_FIELD: this.preApprovedSubdivisionForm.get('occupancyField')?.value,
        BUSINESS_CD: this.preApprovedSubdivisionForm.get('businessCd')?.value,
        BUS_NAME: this.preApprovedSubdivisionForm.get('businessName')?.value,
        BUSINESS_FIELD: this.preApprovedSubdivisionForm.get('businessField')?.value,
        OCCUPANCY_BM_CODE: this.preApprovedSubdivisionForm.get('occupancyBmCode')?.value,
        BUS_BM_CODE: this.preApprovedSubdivisionForm.get('businessBmCode')?.value,
        SUBLINE_CD: this.paramForm.get('sublineCd')?.value,
        SUBLINE_NAME: this.paramForm.get('sublineName')?.value,
        SUBLINE_FIELD: this.paramForm.get('sublineField')?.value,
        PLAN_CD: this.paramForm.get('planCd')?.value,
        PLAN_NAME: this.paramForm.get('planName')?.value,
        PLAN_FIELD: this.paramForm.get('planField')?.value,
        LINE_CD: this.paramForm.get('lineCd')?.value,
        LINE_NAME: this.paramForm.get('lineName')?.value,
        LINE_FIELD: this.paramForm.get('lineField')?.value,
        ACTIVE_TAG: this.preApprovedSubdivisionForm.get('activeTag')?.value,
        REMARKS: this.preApprovedSubdivisionForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.preApprovedSubdivisionForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.preApprovedSubdivisionForm.get('userID')?.value,
        MODE: 'U'
      };
      this.preApprovedSubdivisionTblConfig.tblData = [...this.preApprovedSubdivisionTblConfig.tblData];
    }
    catch(e) {
    }
  }

  formValueToData() {
    try {
      let formToData: any = { // this.datePipe.transform(new Date(), 'MM/dd/yyyy')
        CLASS_CD: this.preApprovedSubdivisionForm.get('classCd')?.value,
        CLASS_NAME: this.preApprovedSubdivisionForm.get('className')?.value,
        CLASS_FIELD: this.preApprovedSubdivisionForm.get('classField')?.value,
        ZONE_CD: this.preApprovedSubdivisionForm.get('zoneCd')?.value,
        ZONE_NAME: this.preApprovedSubdivisionForm.get('zoneName')?.value,
        ZONE_FIELD: this.preApprovedSubdivisionForm.get('zoneField')?.value,
        OCCUPANCY_CD: this.preApprovedSubdivisionForm.get('occupancyCd')?.value,
        OCCUPANCY_DESC: this.preApprovedSubdivisionForm.get('occupancyDesc')?.value,
        OCCUPANCY_FIELD: this.preApprovedSubdivisionForm.get('occupancyField')?.value,
        BUSINESS_CD: this.preApprovedSubdivisionForm.get('businessCd')?.value,
        BUS_NAME: this.preApprovedSubdivisionForm.get('businessName')?.value,
        BUSINESS_FIELD: this.preApprovedSubdivisionForm.get('businessField')?.value,
        OCCUPANCY_BM_CODE: this.preApprovedSubdivisionForm.get('occupancyBmCode')?.value,
        BUS_BM_CODE: this.preApprovedSubdivisionForm.get('businessBmCode')?.value,
        SUBLINE_CD: this.paramForm.get('sublineCd')?.value,
        SUBLINE_NAME: this.paramForm.get('sublineName')?.value,
        SUBLINE_FIELD: this.paramForm.get('sublineField')?.value,
        PLAN_CD: this.paramForm.get('planCd')?.value,
        PLAN_NAME: this.paramForm.get('planName')?.value,
        PLAN_FIELD: this.paramForm.get('planField')?.value,
        LINE_CD: this.paramForm.get('lineCd')?.value,
        LINE_NAME: this.paramForm.get('lineName')?.value,
        LINE_FIELD: this.paramForm.get('lineField')?.value,
        ACTIVE_TAG: this.preApprovedSubdivisionForm.get('activeTag')?.value,
        REMARKS: this.preApprovedSubdivisionForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.preApprovedSubdivisionForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.preApprovedSubdivisionForm.get('userID')?.value,
        MODE: 'U'
      }
      return formToData;
    }
    catch(e) {
    }
  }
  deleteLine() {
    try {
      let clickData = this.rowClickedData;
      this.preApprovedSubdivisionTblConfig.tblData = this.preApprovedSubdivisionTblConfig.tblData.filter(
        function(e: any) {
          return e.CLASS_CD.toUpperCase()!==clickData.CLASS_CD.toUpperCase()
          ||+e.ZONE_CD !== +clickData.ZONE_CD 
          ||+e.OCCUPANCY_CD !== +clickData.OCCUPANCY_CD
          ||+e.BUSINESS_CD !== +clickData.BUSINESS_CD;
        }
      );
      this.tempAddedData = this.tempAddedData.filter(
        function(e: any) {
          return e.CLASS_CD.toUpperCase()!==clickData.CLASS_CD.toUpperCase()
          ||+e.ZONE_CD !== +clickData.ZONE_CD 
          ||+e.OCCUPANCY_CD !== +clickData.OCCUPANCY_CD
          ||+e.BUSINESS_CD !== +clickData.BUSINESS_CD;
        }
      );
      this.resetFormValues();
    }
    catch(e) {
    }
  }

  insertLine() {
    try {
      this.tranPosted = true;
      this.validateInsert();
      if (this.validated) {
        this.addFormToTable();
      }
    }
    catch(e) {
    }
  }
  addFormToTable() {
    try {
      this.preApprovedSubdivisionForm.patchValue({
        activeTag: this.preApprovedSubdivisionForm.get('activeTag')?.value,
        userID: this.currentUser,
        lastUserUpdate: new Date()
      });
      let formToData: any = { // this.datePipe.transform(new Date(), 'MM/dd/yyyy')
        CLASS_CD: this.preApprovedSubdivisionForm.get('classCd')?.value,
        CLASS_NAME: this.preApprovedSubdivisionForm.get('className')?.value,
        CLASS_FIELD: this.preApprovedSubdivisionForm.get('classField')?.value,
        ZONE_CD: this.preApprovedSubdivisionForm.get('zoneCd')?.value,
        ZONE_NAME: this.preApprovedSubdivisionForm.get('zoneName')?.value,
        ZONE_FIELD: this.preApprovedSubdivisionForm.get('zoneField')?.value,
        OCCUPANCY_CD: this.preApprovedSubdivisionForm.get('occupancyCd')?.value,
        OCCUPANCY_DESC: this.preApprovedSubdivisionForm.get('occupancyDesc')?.value,
        OCCUPANCY_FIELD: this.preApprovedSubdivisionForm.get('occupancyField')?.value,
        BUSINESS_CD: this.preApprovedSubdivisionForm.get('businessCd')?.value,
        BUS_NAME: this.preApprovedSubdivisionForm.get('businessName')?.value,
        BUSINESS_FIELD: this.preApprovedSubdivisionForm.get('businessField')?.value,
        OCCUPANCY_BM_CODE: this.preApprovedSubdivisionForm.get('occupancyBmCode')?.value,
        BUS_BM_CODE: this.preApprovedSubdivisionForm.get('businessBmCode')?.value,
        SUBLINE_CD: this.paramForm.get('sublineCd')?.value,
        SUBLINE_NAME: this.paramForm.get('sublineName')?.value,
        SUBLINE_FIELD: this.paramForm.get('sublineField')?.value,
        PLAN_CD: this.paramForm.get('planCd')?.value,
        PLAN_NAME: this.paramForm.get('planName')?.value,
        PLAN_FIELD: this.paramForm.get('planField')?.value,
        LINE_CD: this.paramForm.get('lineCd')?.value,
        LINE_NAME: this.paramForm.get('lineName')?.value,
        LINE_FIELD: this.paramForm.get('lineField')?.value,
        ACTIVE_TAG: this.preApprovedSubdivisionForm.get('activeTag')?.value,
        REMARKS: this.preApprovedSubdivisionForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.preApprovedSubdivisionForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.preApprovedSubdivisionForm.get('userID')?.value,
        MODE: 'I'
      }
      this.preApprovedSubdivisionTblConfig.tblData.unshift(formToData);
      this.preApprovedSubdivisionTblConfig.tblData = [...this.preApprovedSubdivisionTblConfig.tblData];
      this.tempAddedData.push(formToData);
      this.resetFormValues();
      this.appMessageService.showAppMessage("State details added!", "success");
    }
    catch(e) {
    }
  }

  saveData(tempChangeData:any[]){
    this.apiCallService.saveOccupancyAndBusinessCodeperPlan(tempChangeData)
    .subscribe({
      next: (response : any) => {
        this.securityService.checkRequestKeyResponse(response, () => {
          this.securityService.hasValidCsrfToken(response, ()=>{
            if (response.status == "SUCCESS" && this.tranPosted) {
              this.openDialog('SUCCESS', 'Details Successfully saved!');
              this.initOnGet();
              this.resetFormValues();
              this.tempAddedData = [];
              this.tempUpdateData = [];
              this.saveDisabled = this.saveChecker();
            }  
            else {
              this.initOnGet();
              this.saveDisabled = this.saveChecker();
              this.openDialog('ERROR', 'An error has occured while saving details');
            }
          });
        });
      }, 
      error: () => {
        this.openDialog('ERROR', 'An error has occured while saving details');
      }
    });
  }

  toSave() {
    try {
      this.noLoadingInProcess = false;
      this.formService.showFormLoader(null, "generate-line-form", "Please wait ...", null, null);
      let tempChangeData= this.tempAddedData.concat(this.tempUpdateData);
      if(tempChangeData.length!=0) {
        this.saveData(tempChangeData);
      } else {
        this.initOnGet();
        this.resetFormValues();
        this.tempAddedData = [];
        this.tempUpdateData = [];
        this.saveDisabled = this.saveChecker();
      }
    }
    catch(e) {
    }
  }
  saveChecker() {
    try {
      if((this.tempAddedData==null || this.tempAddedData=='') && (this.tempUpdateData==null || this.tempUpdateData=='')) {
        return true;
      }
      else {
        return false;
      }
    }
    catch(e) {
      return true;
    }
  }

  deleteChecker(ev: any) {
    try {
      let tempDataFlag;
      tempDataFlag = this.tempAddedData.filter(function(e: any) {
        let returnValue = e.CLASS_CD.toUpperCase()==ev.CLASS_CD.toUpperCase()
          &&+e.ZONE_CD == +ev.ZONE_CD 
          &&+e.OCCUPANCY_CD == +ev.OCCUPANCY_CD
          &&+e.BUSINESS_CD == +ev.BUSINESS_CD;
        return returnValue;
      });
      if(tempDataFlag==''||tempDataFlag==null) {
        return true;
      } else {
        return false;
      }
    }
    catch(e) {
      return true;
    }
  }

  sublineChecker(){
    try{
      let lineCd = this.paramForm.get('lineCd')?.value;
      if(lineCd!==''&&lineCd!==null){
        return false;
      }
      else{ 
        return true;
      }
    }catch(e){
      return true;
    }
  }

  planChecker(){
    try{
      let lineCd = this.paramForm.get('lineCd')?.value;
      let sublineCd = this.paramForm.get('sublineCd')?.value;
      if(lineCd==''||lineCd==null){
        return true;
      }
      if(sublineCd==''||sublineCd==null){
        return true;
      }
      return false;
    }catch(e){
      return true;
    }
  }
  onRowClick(ev: any) {
    try {    
      if(ev!=null) {
        this.rowClickedData = ev;
        this.loadFormValues();
        this.saveDisabled = this.saveChecker();
        this.deleteDisabled = this.deleteChecker(ev);
      }
      else {
        this.resetFormValues();
      }
    }
    catch(e) {
    }
  }

  loadFormValues() {
    try {
      this.preApprovedSubdivisionForm.patchValue({
        classCd: this.rowClickedData.CLASS_CD,
        className: this.rowClickedData.CLASS_NAME,
        classField: this.rowClickedData.CLASS_CD+" - "+this.rowClickedData.CLASS_NAME,
        zoneCd: this.rowClickedData.ZONE_CD,
        zoneName: this.rowClickedData.ZONE_NAME,
        zoneField: padLeadingZeros(this.rowClickedData.ZONE_CD, 2)+" - "+this.rowClickedData.ZONE_NAME,
        occupancyCd: this.rowClickedData.OCCUPANCY_CD,
        occupancyDesc: this.rowClickedData.OCCUPANCY_DESC,
        occupancyBmCode: this.rowClickedData.OCCUPANCY_BM_CODE,
        occupancyField: this.rowClickedData.OCCUPANCY_BM_CODE+" - "+this.rowClickedData.OCCUPANCY_DESC,
        origBusinessCd: this.rowClickedData.BUSINESS_CD,
        businessCd: this.rowClickedData.BUSINESS_CD,
        businessName: this.rowClickedData.BUS_NAME,
        businessBmCode: this.rowClickedData.BUS_BM_CODE,
        businessField: this.rowClickedData.BUS_BM_CODE+" - "+this.rowClickedData.BUS_NAME,
        sublineCd: this.rowClickedData.SUBLINE_CD,
        sublineName: this.rowClickedData.SUBLINE_NAME,
        sublineField: this.rowClickedData.SUBLINE_FIELD,
        planCd: this.rowClickedData.PLAN_CD,
        planName: this.rowClickedData.PLAN_NAME,
        planField: this.rowClickedData.PLAN_FIELD,
        lineCd: this.rowClickedData.LINE_CD,
        lineName: this.rowClickedData.LINE_NAME,
        lineField: this.rowClickedData.LINE_FIELD,
        activeTag: this.rowClickedData.ACTIVE_TAG,
        remarks: this.rowClickedData.REMARKS,
        userID: this.rowClickedData.USER_ID,
        lastUserUpdate: this.rowClickedData.LAST_USER_UPDATE
      });
      this.addDisabled = true;
      this.userIDReadOnly = true;
      this.isLineSelected = true;
    }
    catch(e) {
    }
  }

  resetFormValues() {
    try {
      this.selectedData = null;
      // this.paramForm.get('lineCD')?.enable();
      this.preApprovedSubdivisionForm.patchValue({
        classCd: null,
        className: null,
        classField: null,
        zoneCd: null,
        zoneName: null,
        zoneField: null,
        occupancyCd: null,
        occupancyDesc: null,
        occupancyBmCode:null,
        occupancyField: null,
        origBusinessCd: null,
        businessCd: null,
        businessName: null,
        businessBmCode:null,
        businessField: null,
        activeTag: 'A',
        remarks: null,
        userID: null,
        lastUserUpdate: null
      });

      this.addDisabled = false;
      this.saveDisabled = this.saveChecker();
      this.deleteDisabled = true;
      this.userIDReadOnly = false;
      this.isLineSelected = false;

      this.rowClickedData = '' || null;
    }
    catch (e) {
    console.error(e);
    }
  }

  openLineDialog() {
    try {
      this._Dialog.open(LineLovDialogComponent, {
        width: '512px',
        disableClose: true,
        data: {
          table: 'LINE',
          userId: this.userDetailService.userId,
          moduleId: this.moduleId
        }
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedLine = data.content;
          this.paramForm.get('lineCd')?.setValue(this.selectedLine.LINE_CD==null || this.selectedLine.LINE_CD=='' ? '' : this.selectedLine.LINE_CD);
          this.paramForm.get('lineName')?.setValue(this.selectedLine.LINE_CD==null || this.selectedLine.LINE_CD=='' ? '' : this.selectedLine.LINE_NAME);
          this.paramForm.get('lineField')?.setValue(this.selectedLine.LINE_CD==null || this.selectedLine.LINE_CD=='' ? '' : this.selectedLine.LINE_CD + " - " + this.selectedLine.LINE_NAME);
        }
        this.paramForm.get('sublineCd')?.setValue(null);
        this.paramForm.get('sublineName')?.setValue(null);
        this.paramForm.get('sublineField')?.setValue(null);
        this.resetFormValues();
        this.initOnGet();
        this.sublineDisabled = this.sublineChecker();
      });
    } catch(e) {
    }
  }
  openSublineDialog() {
    try {
      this._Dialog.open(LineLovDialogComponent, {
        width: '512px',
        disableClose: true,
        data: {
          table: 'SUBLINE',
          lineCd: this.paramForm.get('lineCd')?.value,
          userId: this.userDetailService.userId,
          moduleId: this.moduleId
        }
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedSubline = data.content;
          this.paramForm.get('sublineCd')?.setValue(this.selectedSubline.SUBLINE_CD==null || this.selectedSubline.SUBLINE_CD=='' ? '' : this.selectedSubline.SUBLINE_CD);
          this.paramForm.get('sublineName')?.setValue(this.selectedSubline.SUBLINE_CD==null || this.selectedSubline.SUBLINE_CD=='' ? '' : this.selectedSubline.SUBLINE_NAME);
          this.paramForm.get('sublineField')?.setValue(this.selectedSubline.SUBLINE_CD==null || this.selectedSubline.SUBLINE_CD=='' ? '' : this.selectedSubline.SUBLINE_CD+ " - " + this.selectedSubline.SUBLINE_NAME);
        }
        this.resetFormValues();
        this.paramForm.get('planCd')?.setValue(null);
        this.paramForm.get('planName')?.setValue(null);
        this.paramForm.get('planField')?.setValue(null);
        this.resetFormValues();
        this.initOnGet();
        this.planDisabled = this.planChecker();
      });
    } catch(e) {
    }
  }
  openPlanDialog() {
    try {
      this._Dialog.open(PlanLovComponent, {
        width: '512px',
        disableClose: true,
        data: {
          table: 'PLAN',
          lineCd: this.paramForm.get('lineCd')?.value,
          sublineCd: this.paramForm.get('sublineCd')?.value,
          userId: this.userDetailService.userId,
          moduleId: this.moduleId
        }
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedPlan = data.content;
          this.paramForm.get('planCd')?.setValue(this.selectedPlan.PLAN_CD==null || this.selectedPlan.PLAN_CD=='' ? '' : this.selectedPlan.PLAN_CD);
          this.paramForm.get('planName')?.setValue(this.selectedPlan.PLAN_CD==null || this.selectedPlan.PLAN_CD=='' ? '' : this.selectedPlan.PLAN_NAME);
          this.paramForm.get('planField')?.setValue(this.selectedPlan.PLAN_CD==null || this.selectedPlan.PLAN_CD=='' ? '' : this.selectedPlan.PLAN_CD+ " - " + this.selectedPlan.PLAN_NAME);
          this.initOnGet();
        }
        this.resetFormValues();
      });
    } catch(e) {
    }
  }
  openClassDialog() {
    try {
      this._Dialog.open(ClassLovComponent, {
        width: '512px',
        disableClose: true,
        data: {
          table: 'Class',
          moduleId: this.moduleId,
          userId: this.userDetailService.userId,
        }
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedClass = data.content;
          this.preApprovedSubdivisionForm.get('classCd')?.setValue(this.selectedClass.CLASS_CD==null || this.selectedClass.CLASS_CD=='' ? '' : this.selectedClass.CLASS_CD);
          this.preApprovedSubdivisionForm.get('className')?.setValue(this.selectedClass.CLASS_CD==null || this.selectedClass.CLASS_CD=='' ? '' : this.selectedClass.CLASS_NAME);
          this.preApprovedSubdivisionForm.get('classField')?.setValue(this.selectedClass.CLASS_CD==null || this.selectedClass.CLASS_CD=='' ? '' : this.selectedClass.CLASS_CD+" - "+this.selectedClass.CLASS_NAME);
        }
      });
    } catch(e) {
    }
  }
  
  openZoneDialog() {
    try {
      this._Dialog.open(ZoneLovDialogComponent, {
        width: '512px',
        disableClose: true,
        data: {
          table: 'Geographic Zone',
          moduleId: this.moduleId,
          userId: this.userDetailService.userId,
          tableData: "NAME"
        }
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedZone = data.content;
          this.preApprovedSubdivisionForm.get('zoneCd')?.setValue(this.selectedZone.ZONE_CD==null || this.selectedZone.ZONE_CD=='' ? '' : this.selectedZone.ZONE_CD);
          this.preApprovedSubdivisionForm.get('zoneName')?.setValue(this.selectedZone.ZONE_CD==null || this.selectedZone.ZONE_CD=='' ? '' : this.selectedZone.ZONE_NAME);
          this.preApprovedSubdivisionForm.get('zoneField')?.setValue(this.selectedZone.ZONE_CD==null || this.selectedZone.ZONE_CD=='' ? '' : padLeadingZeros(this.selectedZone.ZONE_CD, 2)+" - "+this.selectedZone.ZONE_NAME);
        }
      });
    } catch(e) {
    }
  }
  openOccupancyDialog() {
    try {
      this._Dialog.open(OccupancyLovComponent, {
        width: '512px',
        disableClose: true,
        data: {
          table: 'Occupancy',
          moduleId: this.moduleId,
          userId: this.userDetailService.userId,
          tableData: "BM_CODE"
        }
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedOccupancy = data.content;
          this.preApprovedSubdivisionForm.get('occupancyCd')?.setValue(this.selectedOccupancy.OCCUPANCY_CD==null || this.selectedOccupancy.OCCUPANCY_CD=='' ? '' : this.selectedOccupancy.OCCUPANCY_CD);
          this.preApprovedSubdivisionForm.get('occupancyDesc')?.setValue(this.selectedOccupancy.OCCUPANCY_CD==null || this.selectedOccupancy.OCCUPANCY_CD=='' ? '' : this.selectedOccupancy.OCCUPANCY_DESC);
          this.preApprovedSubdivisionForm.get('occupancyBmCode')?.setValue(this.selectedOccupancy.OCCUPANCY_CD==null || this.selectedOccupancy.OCCUPANCY_CD=='' ? '' : this.selectedOccupancy.BM_CODE);
          this.preApprovedSubdivisionForm.get('occupancyField')?.setValue(this.selectedOccupancy.OCCUPANCY_CD==null || this.selectedOccupancy.OCCUPANCY_CD=='' ? '' : this.selectedOccupancy.BM_CODE+" - "+this.selectedOccupancy.OCCUPANCY_DESC);
        }
      });
    } catch(e) {
    }
  }
  openBusinessDialog() {
    try {
      this._Dialog.open(BusinessCodeLovDialogComponent, {
        width: '512px',
        disableClose: true,
        data: {
          table: 'Business',
          moduleId: this.moduleId,
          userId: this.userDetailService.userId,
        }
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedBusiness = data.content;
          this.preApprovedSubdivisionForm.get('businessCd')?.setValue(this.selectedBusiness.BUSINESS_CD==null || this.selectedBusiness.BUSINESS_CD=='' ? '' : this.selectedBusiness.BUSINESS_CD);
          this.preApprovedSubdivisionForm.get('businessName')?.setValue(this.selectedBusiness.BUSINESS_CD==null || this.selectedBusiness.BUSINESS_CD=='' ? '' : this.selectedBusiness.BUS_NAME);
          this.preApprovedSubdivisionForm.get('businessBmCode')?.setValue(this.selectedBusiness.BUSINESS_CD==null || this.selectedBusiness.BUSINESS_CD=='' ? '' : this.selectedBusiness.BM_CODE);
          this.preApprovedSubdivisionForm.get('businessField')?.setValue(this.selectedBusiness.BUSINESS_CD==null || this.selectedBusiness.BUSINESS_CD=='' ? '' : this.selectedBusiness.BM_CODE+" - "+this.selectedBusiness.BUS_NAME);
        }
      });
    } catch(e) {
    }
  }
  openDialog(title: any, message: any) {
    try {
      this.noLoadingInProcess = true;
      this.formService.hideFormLoader("generate-line-form");
      this._Dialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        } 
      });
    } catch(e) {
    }
  }

  validateInsert() {
    try {
      this.validated = false;
      if (!this.requiredFieldCheck()){
        setTimeout(()=>{                           //10 secs countdown
          this.formService.hideFormMsg("occupancy-business-code-per-plan-error-message");
        }, 10000);
      }
      else if (!this.duplicateCheck(this.preApprovedSubdivisionTblConfig.tblData)) {
        setTimeout(()=>{                           
          this.formService.hideFormMsg("occupancy-business-code-per-plan-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
    }
  }

  requiredFieldCheck() {
    try {
      if(this.preApprovedSubdivisionForm.get('activeTag')?.value==='' || this.preApprovedSubdivisionForm.get('activeTag')?.value===null
        || this.preApprovedSubdivisionForm.get('classCd')?.value==='' || this.preApprovedSubdivisionForm.get('classCd')?.value===null 
        || this.preApprovedSubdivisionForm.get('zoneCd')?.value==='' || this.preApprovedSubdivisionForm.get('zoneCd')?.value===null
        || this.preApprovedSubdivisionForm.get('occupancyCd')?.value==='' || this.preApprovedSubdivisionForm.get('occupancyCd')?.value===null
        || this.preApprovedSubdivisionForm.get('businessCd')?.value==='' || this.preApprovedSubdivisionForm.get('businessCd')?.value===null
        || this.paramForm.get('lineCd')?.value==='' || this.paramForm.get('lineCd')?.value===null
        || this.paramForm.get('sublineCd')?.value==='' || this.paramForm.get('sublineCd')?.value===null
        || this.paramForm.get('planCd')?.value==='' || this.paramForm.get('planCd')?.value===null) {
        this.formService.showFormMsg("occupancy-business-code-per-plan-error-message", 'There are missing information. Please provide the necessary information needed.', "E");
        return false;
      }
      else {
        return true;
      }
   }
    catch (e) {
    console.error(e);
      return false;
    }
  }

  duplicateCheck(tblData:any) {
    try {
      let fieldClassCd = this.preApprovedSubdivisionForm.get('classCd')?.value;
      let fieldZoneCd = this.preApprovedSubdivisionForm.get('zoneCd')?.value;
      let fieldOccupancyCd = this.preApprovedSubdivisionForm.get('occupancyCd')?.value;
      let fieldBusinessCd = this.preApprovedSubdivisionForm.get('businessCd')?.value;
      this.pubUserStdRatesCodeField = tblData.filter(function(e: any) {
        if(e.CLASS_CD.toUpperCase()==fieldClassCd.toUpperCase()
        &&+e.ZONE_CD == +fieldZoneCd
        &&+e.OCCUPANCY_CD == +fieldOccupancyCd
        &&+e.BUSINESS_CD == +fieldBusinessCd){
          return true
        }
        else{
          return false
        }
      });
      if(this.pubUserStdRatesCodeField=='' || this.pubUserStdRatesCodeField==null) {
        return true;
      } else {
        this.formService.showFormMsg("occupancy-business-code-per-plan-error-message", 'Class, Geographic Zone, Occupancy and Business already exists under this plan. Please add a unique setup.', "E");
        return false;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }

  validateUpdate() {
    try {
      this.validated = false;
      let fieldClassCd = this.preApprovedSubdivisionForm.get('classCd')?.value;
      let fieldZoneCd = this.preApprovedSubdivisionForm.get('zoneCd')?.value;
      let fieldOccupancyCd = this.preApprovedSubdivisionForm.get('occupancyCd')?.value;
      let fieldBusinessCd = this.preApprovedSubdivisionForm.get('origBusinessCd')?.value;
      let filteredTblData = this.preApprovedSubdivisionTblConfig.tblData.filter(function(e:any){
        if(e.CLASS_CD.toUpperCase()==fieldClassCd.toUpperCase()
        &&+e.ZONE_CD == +fieldZoneCd
        &&+e.OCCUPANCY_CD == +fieldOccupancyCd
        &&+e.BUSINESS_CD == +fieldBusinessCd){
          return false;
        }
        else{
          return true;
        }
      });
      if (!this.requiredFieldCheck()){
        setTimeout(()=>{                          
          this.formService.hideFormMsg("occupancy-business-code-per-plan-error-message");
        }, 10000);
      }
      else if (!this.duplicateCheck(filteredTblData)) {
        setTimeout(()=>{                           
          this.formService.hideFormMsg("occupancy-business-code-per-plan-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
    }
  }
  
}
function padLeadingZeros(num:number, size:number) {
  let sNum = num+"";
  while (sNum.length < size) sNum = "0" + sNum;
  return sNum;
}