import { Component, OnInit } from '@angular/core';
import { APICallService } from 'src/app/services/api-call.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { DialogBoxService } from 'src/app/services/dialog-box.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { forkJoin, Observable } from 'rxjs';
import { LovDialogComponent } from './lov-dialog/lov-dialog.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { SecurityService } from 'src/app/services/security.service'; 

@Component({
  selector: 'agent-maintenance',
  templateUrl: './agent-maintenance.component.html',
  styleUrls: ['./agent-maintenance.component.css']
})
export class AgentMaintenanceComponent implements OnInit, LeaveConfirmation {
  
  // Variable initializers
  agentsData: any = [];
  selectedIss: any = {
    ISS_CD: '',
    ISS_NAME: '',
  };
  selectedBma: any = {
    BMA_CD: '',
    BMA_DESC: '',
  };
  selectedSourceCode: any = {
    SRC_CD: '',
    SRC_CD_DESC: '',
  };
  selectedSourceExtension: any = {
    SRC_EXT_CD: '',
    SRC_EXT_DESC: '',
  };
  selectedAgency: any = {
    AGENCY_CODE: '',
    AGENCY_NAME: '',
  };
  selectedAgent: any = null;
  moduleName: string = '';
  datePipe = new DatePipe('en-us');
  agentCodeDisabler: boolean = false;
  agentCodeDuplicate: boolean = false;
  agentCodeField: string = '';
  duplicateErrorEnabler: boolean = false;
  dateToday: string = this.datePipe.transform(new Date(), 'yyyy-MM-dd') || '';
  agentGroup: any = [];
  agentIssList: any = [];
  agencyList: any = [];
  rpaStaffList: any = [];
  agentTypeList: any = [];
  agentSourceCode: any = [];
  agentBma: any = [];
  agentSourceExtension: any = [];
  currentUser: string = this.userDetailService.userId || "";
  tempAddedData: any = [];
  tempUpdateData: any = [];
  deleteDisabler: boolean = true;
  rowClickedData: any;
  saveDisabler: boolean = true;
  savingProgressEnabler: boolean = false;
  saveMessage: boolean = false;
  formHider: boolean = false;
  errorMessage: string = '';
  moduleId: string = 'BMM017';

  // Form group instance that represents Agent CRUD form
  agentsForm = this.fb.group({
    agentCode: ['', [Validators.required, Validators.maxLength(10)]],
    // agentNumCode: ['', [Validators.required, Validators.pattern(/^[0-9]\d*$/), Validators.maxLength(8)]],
    agentNumCode: ['', [Validators.required, Validators.maxLength(8)]], //accept alphanumeric
    agentName: ['', [Validators.required, Validators.maxLength(50)]],
    emailAddress: ['', [Validators.required, Validators.pattern("^[a-zA-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    effDate: [new Date(), [Validators.required]],
    remarks: [''],
    lastUpdateUser: {value: '', disabled: true},
    activeTag: ['Yes', [Validators.required]],
    issueSourceCd: [{value: '', disabled: true}, Validators.required],
    issueSourceName: [''],
    mobileNumber: ['', [Validators.required, Validators.pattern("^09[0-9]{9}")]],
    expDate: [''],
    lastDate: {value: '', disabled: true},
    createDate: [''],
    createUser: [''],
    agentGroup: [''],
    editPremium: ['N', [Validators.required]],
    renNoticeTag: ['N', [Validators.required]],
    agencyCode: [''],
    agencyName: [''],
    agentTypeCd: ['A', [Validators.required]],
    agentSourceCode: [{value: '', disabled: true}],
    agentSourceDesc: [''],
    agentBmaCd: [{value: '', disabled: true}],
    agentBmaDesc: [''],
    agentSourceExtensionCd: [{value: '', disabled: true}],
    agentSourceExtensionDesc: [''],
    rpaStaff: [''],
    rpaDept: ['']
  });

  // Testing for initializing table using prime ng
  agentTableConfig: any = {
    cols: [
      {
        key: "AGENT_CD",
        header: "Agent User ID",
        dataType: "string"
      },
      {
        key: "AGENT_NAME",
        header: "Name",
        dataType: "string"
      },
      {
        key: "AGENCY_NAME",
        header: "Agency Name",
        dataType: "string"
      },
      {
        key: "ISS_NAME",
        header: "Issue Source",
        dataType: "string"
      },
      {
        key: "EMAIL_ADDRESS",
        header: "Email Address",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "Active",
        dataType: "checkbox"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  constructor(
              private apiCallService: APICallService,
              public issDialog: MatDialog,
              private fb: FormBuilder,
              private formService: FormService,
              private dialogBoxService: DialogBoxService,
              private userDetailService: UserDetailsService,
              private appMessageService: AppMessageService,
              private jsonDataService: JsonDataService,
              private securityService: SecurityService
  ) { 
    this.moduleName = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId === this.moduleId })[0].moduleDesc.toUpperCase();
    this.rpaStaffList = this.jsonDataService.data.rpaStaff;
   }

    ngOnInit() {
        try {
            // this.validateUserAccess(); 
            this.getAgentsData();
            this.getAgentGroup();
            this.getAgency();
            this.getAgentType();
        } catch (e) {
    console.error(e);
        }
    }

/*  validateUserAccess() {
      console.log(this.userDetailService.userId);
      this.apiCallService.validateUserAccess({
            moduleId: this.moduleId,
            userId: this.userDetailService.userId}).subscribe((response: any) => { 
                const res = JSON.parse(this.jsonDataService.decrypt(response.response));
                console.log(res);
      });
  }; */

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempAddedData!='' || this.tempUpdateData!='') {
      return false;
    } else {
      return true;
    }
  }

  //Gets data of Agents
  async getAgentsData() {
    try {
      this.agentTableConfig.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.apiCallService.getAllAgents({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE"
      }).subscribe((data: any) => {
            this.securityService.checkRequestKeyResponse(data, () => {
              this.securityService.hasValidCsrfToken(data, ()=>{
                if (data.status === "SUCCESS") {
                    data = JSON.parse(this.jsonDataService.decrypt(data.response));
                    this.jsonDataService.contorlLoading(false);
                    this.agentTableConfig.tblData = data;
                    this.agentTableConfig.loading = false;
                } else {
                    this.jsonDataService.contorlLoading(false);
                    this.agentTableConfig.loading = false;
                    this.appMessageService.showAppMessage(data.message, "error");
                }   
              });
              
            });
      });
    } catch(e) {
      console.error(e);
    }
  }

  async getAgentGroup() {
    try {
      this.agentGroup = this.jsonDataService.data.agentGrp.filter((e: any) => {
        return e.activeTag == 'A'
      });
    } catch(e) {
      console.error(e);
    }
  }

  async getAgency() {
    try {
      this.agencyList = this.jsonDataService.data.agencyCds.filter((e: any) => {
        return e.activeTag == 'A'
      });
    } catch(e) {
    }
  }

  async getAgentType() {
    try {
      this.agentTypeList = this.jsonDataService.data.refCds.filter((e: any) => e.rvDomain=="BMM017.AGENT_TYPE");
    } catch(e) {
    }
  }
  
  onRowClick(ev: any) {
    try {
      if(ev!=null) {
        this.fieldDataLoader(ev);
        this.deleteChecker(ev);
        this.deleteDisabler = this.deleteChecker(ev);
        this.rowClickedData = ev;
        this.selectedIss.ISS_CD = ev.ISS_CD;
        this.selectedIss.ISS_NAME = ev.ISS_NAME;
        this.selectedBma.BMA_CD = ev.BMA_CD;
        this.selectedBma.BMA_DESC = ev.BMA_DESC;
        this.selectedSourceCode.SRC_CD = ev.SRC_CD;
        this.selectedSourceCode.SRC_CD_DESC = ev.SRC_CD_DESC;
        this.selectedSourceExtension.SRC_EXT_CD = ev.SRC_EXT_CD;
        this.selectedSourceExtension.SRC_EXT_DESC = ev.SRC_EXT_DESC;
        this.selectedAgency.AGENCY_CODE = ev.AGENCY_CD;
        this.selectedAgency.AGENCY_NAME = ev.AGENCY_NAME;
        this.formService.hideFormMsg("agent-error-message");
      } else {
        this.fieldDataClearer();
        this.deleteDisabler = true;
        this.rowClickedData = '' || null;
        this.selectedIss.ISS_CD = '';
        this.selectedIss.ISS_NAME = '';
        this.selectedBma.BMA_CD = '';
        this.selectedBma.BMA_DESC = '';
        this.selectedSourceCode.SRC_CD = '';
        this.selectedSourceCode.SRC_CD_DESC = '';
        this.selectedSourceExtension.SRC_EXT_CD = '';
        this.selectedSourceExtension.SRC_EXT_DESC = '';
        this.selectedAgency.AGENCY_CODE = '';
        this.selectedAgency.AGENCY_NAME = '';
      }
    } catch(e) {
      console.error(e);
    }    
  }
 
  fieldDataLoader(ev: any) { // this.datePipe.transform(new Date(), 'MM/dd/yyyy')
    try {
      this.agentCodeDisabler = true;
      this.selectedAgent = ev;
      this.agentsForm.controls['agentCode'].disable();
      this.agentsForm.patchValue({
        agentCode: ev.AGENT_CD,
        agentNumCode: ev.AGENT_NUM_CD,
        agentName: ev.AGENT_NAME,
        emailAddress: ev.EMAIL_ADDRESS,
        activeTag: ev.ACTIVE_TAG=='A' ? 'Yes' : 'No',
        issueSourceCd: ev.ISS_CD + "-" + ev.ISS_NAME,
        mobileNumber: ev.MOBILE_NUMBER,
        effDate: this.dateFilter(ev.EFF_DATE),
        expDate: this.dateFilter(ev.EXPIRY_DATE),
        remarks: ev.REMARKS,
        lastUpdateUser: ev.LAST_USER,
        // lastDate: this.dateFilter(ev.LAST_USER_UPDATE) == '' ? '' : this.datePipe.transform(ev.LAST_USER_UPDATE, 'MM/dd/YYYY h:mm:ss a'), // moment(this.dateFilter(ev.LAST_USER_UPDATE)).format("MM/DD/YYYY h:mm:ss a"),
        lastDate: this.dateFilter(ev.LAST_USER_UPDATE) == '' ? '' : new DatePipe('en_PH').transform(ev.LAST_USER_UPDATE, 'MM/dd/YYYY h:mm:ss a'),
        agentGroup: ev.AGENT_GRP,
        editPremium: ev.EDIT_PREM_TAG,
        renNoticeTag: ev.REN_NOTICE_TAG,
        agencyCode: ev.AGENCY_CD,
        agencyName: ev.AGENCY_NAME,
        agentTypeCd: ev.AGENT_TYPE,
        agentSourceCode: ev.SRC_CD_DESC,
        agentBmaCd: ev.BMA_DESC,
        agentSourceExtensionCd: ev.SRC_EXT_DESC,
        rpaStaff: ev.RPA_STAFF,
        rpaDept: ev.RPA_DEPT
      });
    } catch(e) {
      console.error(e);
    }
  } 

  dateFilter(dateString: any) {
    try {
      if(dateString=='0000-00-00' || dateString=='0000-00-00 12:00:00 AM') {
        return '';
      } else if(dateString=='' || dateString==null) {
        return '';
      } else {
        return dateString;
      }
    } catch(e) {
      console.error(e);
    }
  }

  fieldDataClearer() {
    try {
      this.selectedAgent = null;
      this.agentCodeDisabler = false;
      this.agentsForm.controls['agentCode'].enable();
      this.agentsForm.patchValue({
        agentCode: '',
        agentNumCode: '',
        agentName: '',
        emailAddress: '',
        activeTag: 'Yes',
        issueSourceCd: '',
        mobileNumber: '',
        effDate: new Date(),
        expDate: '',
        remarks: '',
        lastUpdateUser: '',
        lastDate: '',
        agentGroup: '',
        editPremium: 'N',
        renNoticeTag: 'N',
        agencyCode: '',
        agencyName: '',
        agentTypeCd: 'A',
        agentSourceCode: '',
        agentBmaCd: '',
        agentSourceExtensionCd: '',
        rpaStaff: '',
        rpaDept: ''
      }); 
    } catch(e) {
      console.error(e);
    }
  }

  openLovDialog(selected: string) {
    try {
      if(selected=='iss') {
        this.issDialog.open(LovDialogComponent, {
          width: '700px',
          disableClose: true,
          data: {
            title: 'ISSUE SOURCE',
            col1Key: 'ISS_CD',
            col1Header: 'Issue Code',
            col1DataType: 'string',
            col2Key: 'ISS_NAME',
            col2Header: 'Issue Source',
            col2DataType: 'string',
            origin: 'iss',
          }
        }).afterClosed().subscribe((data: any) => {
          this.selectedIss = data.content == '' || data.content == null ? this.selectedIss : data.content;
          this.agentsForm.get('issueSourceCd')
            ?.setValue(this.selectedIss.ISS_CD==null || this.selectedIss.ISS_CD=='' ? '' : this.selectedIss.ISS_CD + "-" + this.selectedIss.ISS_NAME);
        });
      }
  
      if(selected=='bma') {
        this.issDialog.open(LovDialogComponent, {
          width: '700px',
          disableClose: true,
          data: {
            title: 'BMA',
            col1Key: 'BM_CODE',  //col1Key: 'BMA_CD',
            col1Header: 'BMA Code',
            col1DataType: 'string',
            col2Key: 'BMA_DESC',
            col2Header: 'BMA Description',
            col2DataType: 'string',
            origin: 'bma',
          }
        }).afterClosed().subscribe(data => {
          this.selectedBma = data.content == '' || data.content == null ? this.selectedBma : data.content;
          this.agentsForm.get('agentBmaCd')
              ?.setValue(this.selectedBma==null || this.selectedBma=='' ? '' : this.selectedBma.BMA_DESC);
        });
      }
  
      if(selected=='sourceCode') {
        this.issDialog.open(LovDialogComponent, {
          width: '700px',
          disableClose: true,
          data: {
            title: 'SOURCE CODE',
            col1Key: 'BM_CODE',
            col1Header: 'Source Code',
            col1DataType: 'string',
            col2Key: 'SRC_CD_DESC',
            col2Header: 'Source Code Description',
            col2DataType: 'string',
            origin: 'sourceCode',
          }
        }).afterClosed().subscribe(data => {
          this.selectedSourceCode = data.content == '' || data.content == null ? this.selectedSourceCode : data.content;
          this.agentsForm.get('agentSourceCode')
              ?.setValue(this.selectedSourceCode==null || this.selectedSourceCode=='' ? '' : this.selectedSourceCode.SRC_CD_DESC);
        });
      }
  
      if(selected=='sourceExtension') {
        this.issDialog.open(LovDialogComponent, {
          width: '700px',
          disableClose: true,
          data: {
            title: 'SOURCE EXTENSION',
            col1Key: 'BM_CODE',
            col1Header: 'Source Ext Code',
            col1DataType: 'string',
            col2Key: 'SRC_EXT_DESC',
            col2Header: 'Source Extension',
            col2DataType: 'string',
            origin: 'sourceExtension',
          }
        }).afterClosed().subscribe(data => {
          this.selectedSourceExtension = data.content == '' || data.content == null ? this.selectedSourceExtension : data.content;
          this.agentsForm.get('agentSourceExtensionCd')
              ?.setValue(this.selectedSourceExtension==null || this.selectedSourceExtension=='' ? '' : this.selectedSourceExtension.SRC_EXT_DESC);
        });
      }
    } catch(e) {
      console.error(e);
    }
  }

  // Add button event
  addRecord() {
    try {
      this.agentCdDuplicateChecker();
      this.fieldValidator();
      this.addFormToTable();
      this.saveEnabler();
    } catch(e) {
      console.error(e);
    }
  }

  // Form Adder in table view
  addFormToTable() {
    try {
      if(!this.agentCdDuplicateChecker() && !this.fieldValidator()) {
        if (this.agentsForm.get('agencyCode')?.value!=null && this.agentsForm.get('agencyCode')?.value!='') {
          this.selectedAgency.AGENCY_CODE = this.agencyList.find(
            (x: { agencyCd: any; }) => x.agencyCd === this.agentsForm.get('agencyCode')?.value).agencyCd;
          this.selectedAgency.AGENCY_NAME = this.agencyList.find(
            (x: { agencyCd: any; }) => x.agencyCd === this.agentsForm.get('agencyCode')?.value).agencyName;
          this.agentsForm.get('agencyCode')
            ?.setValue(this.selectedAgency==null || this.selectedAgency=='' ? '' : this.selectedAgency.AGENCY_CODE);
          this.agentsForm.get('agencyName')
            ?.setValue(this.selectedAgency==null || this.selectedAgency=='' ? '' : this.selectedAgency.AGENCY_NAME);
        }
        else {
          this.selectedAgency.AGENCY_CODE = null;
          this.selectedAgency.AGENCY_NAME = null;
          this.agentsForm.get('agencyCode')
            ?.setValue(this.selectedAgency==null || this.selectedAgency=='' ? '' : this.selectedAgency.AGENCY_CODE);
          this.agentsForm.get('agencyName')
            ?.setValue(this.selectedAgency==null || this.selectedAgency=='' ? '' : this.selectedAgency.AGENCY_NAME);
        }
        this.agentsForm.patchValue({
          activeTag: this.agentsForm.get('activeTag')?.value=='Yes' ? 'A' : 'I',
          lastUpdateUser: this.currentUser,
          lastDate: new Date(),
          issueSourceCd: this.selectedIss.ISS_CD,
          issueSourceName: this.selectedIss.ISS_NAME,
          agentSourceCode: this.selectedSourceCode.SRC_CD,
          agentSourceDesc: this.selectedSourceCode.SRC_CD_DESC,
          agentBmaCd: this.selectedBma.BMA_CD,
          agentBmaDesc: this.selectedBma.BMA_DESC,
          agentSourceExtensionCd: this.selectedSourceExtension.SRC_EXT_CD,
          agentSourceExtensionDesc: this.selectedSourceExtension.SRC_EXT_DESC,
          createDate: new Date(),
          createUser: this.currentUser,
        });
        let formToData: any = { // this.datePipe.transform(new Date(), 'MM/dd/yyyy')
          ACTIVE_TAG: this.agentsForm.get('activeTag')?.value,
          AGENT_CD: this.agentsForm.get('agentCode')?.value.toUpperCase(),
          AGENT_NUM_CD: this.agentsForm.get('agentNumCode')?.value,
          AGENT_NAME: this.agentsForm.get('agentName')?.value,
          CREATE_DATE: this.agentsForm.get('createDate')?.value,
          CREATE_USER: this.agentsForm.get('createUser')?.value,
          EFF_DATE: this.datePipe.transform(new Date(this.agentsForm.get('effDate')?.value), 'yyyy-MM-dd'),
          EMAIL_ADDRESS: this.agentsForm.get('emailAddress')?.value,
          EXPIRY_DATE: this.agentsForm.get('expDate')?.value=='' || this.agentsForm.get('expDate')?.value==null ? '' : this.datePipe.transform(new Date(this.agentsForm.get('expDate')?.value), 'yyyy-MM-dd'),
          ISS_CD: this.agentsForm.get('issueSourceCd')?.value,
          ISS_NAME: this.agentsForm.get('issueSourceName')?.value,
          LAST_USER: this.agentsForm.get('lastUpdateUser')?.value,
          LAST_USER_UPDATE: this.datePipe.transform(new Date(this.agentsForm.get('lastDate')?.value), 'yyyy-MM-dd'),
          MOBILE_NUMBER: this.agentsForm.get('mobileNumber')?.value,
          REMARKS: this.agentsForm.get('remarks')?.value,
          AGENT_GRP: this.agentsForm.get('agentGroup')?.value,
          EDIT_PREM_TAG: this.agentsForm.get('editPremium')?.value,
          REN_NOTICE_TAG: this.agentsForm.get('renNoticeTag')?.value,
          AGENCY_CD: this.agentsForm.get('agencyCode')?.value,
          AGENCY_NAME: this.agentsForm.get('agencyName')?.value,
          AGENT_TYPE: this.agentsForm.get('agentTypeCd')?.value,
          SRC_CD: this.agentsForm.get('agentSourceCode')?.value,
          SRC_CD_DESC: this.agentsForm.get('agentSourceDesc')?.value,
          BMA_CD: this.agentsForm.get('agentBmaCd')?.value,
          BMA_DESC: this.agentsForm.get('agentBmaDesc')?.value,
          SRC_EXT_CD: this.agentsForm.get('agentSourceExtensionCd')?.value,
          SRC_EXT_DESC: this.agentsForm.get('agentSourceExtensionDesc')?.value,
          RPA_STAFF: this.agentsForm.get('rpaStaff')?.value
        }
        let formToTable: any = {
          ACTIVE_TAG: this.agentsForm.get('activeTag')?.value,
          AGENT_CD: this.agentsForm.get('agentCode')?.value.toUpperCase(),
          AGENT_NUM_CD: this.agentsForm.get('agentNumCode')?.value,
          AGENT_NAME: this.agentsForm.get('agentName')?.value,
          EFF_DATE: this.datePipe.transform(new Date(this.agentsForm.get('effDate')?.value), 'yyyy-MM-dd'),
          EMAIL_ADDRESS: this.agentsForm.get('emailAddress')?.value,
          EXPIRY_DATE: this.agentsForm.get('expDate')?.value=='' || this.agentsForm.get('expDate')?.value==null ? '' : this.datePipe.transform(new Date(this.agentsForm.get('expDate')?.value), 'yyyy-MM-dd'),
          ISS_CD: this.agentsForm.get('issueSourceCd')?.value,
          ISS_NAME: this.agentsForm.get('issueSourceName')?.value,
          MOBILE_NUMBER: this.agentsForm.get('mobileNumber')?.value,
          REMARKS: this.agentsForm.get('remarks')?.value,
          AGENT_GRP: this.agentsForm.get('agentGroup')?.value,
          EDIT_PREM_TAG: this.agentsForm.get('editPremium')?.value,
          REN_NOTICE_TAG: this.agentsForm.get('renNoticeTag')?.value,
          AGENCY_CD: this.agentsForm.get('agencyCode')?.value,
          AGENCY_NAME: this.agentsForm.get('agencyName')?.value,
          AGENT_TYPE: this.agentsForm.get('agentTypeCd')?.value,
          SRC_CD: this.agentsForm.get('agentSourceCode')?.value,
          SRC_CD_DESC: this.agentsForm.get('agentSourceDesc')?.value,
          BMA_CD: this.agentsForm.get('agentBmaCd')?.value,
          BMA_DESC: this.agentsForm.get('agentBmaDesc')?.value,
          SRC_EXT_CD: this.agentsForm.get('agentSourceExtensionCd')?.value,
          SRC_EXT_DESC: this.agentsForm.get('agentSourceExtensionDesc')?.value,
          RPA_STAFF: this.agentsForm.get('rpaStaff')?.value,
          RPA_DEPT: this.agentsForm.get('rpaDept')?.value
        }
        this.formService.hideFormMsg("agent-error-message");
        this.agentTableConfig.tblData.unshift(formToTable);
        this.agentTableConfig.tblData = [...this.agentTableConfig.tblData];
        this.tempAddedData.push(formToData);
        this.fieldDataClearer();
      } else {
        this.formService.showFormMsg("agent-error-message", this.errorMessage, "E");
      }
    } catch(e) {
      console.error(e);
    }
  }

  // Validate Fields
  fieldValidator() {
    try {
      this.agentsForm.get('agentSourceCode')?.enable();
      this.agentsForm.get('agentBmaCd')?.enable();
      this.agentsForm.get('agentSourceExtensionCd')?.enable();

      if(this.agentsForm.invalid) {
        for(const field in this.agentsForm.controls) {
          const control = this.agentsForm.get(field)?.validator; //Checks if a field has a validator
          const controlName = this.agentsForm.get(field); //Use to use specific field
          // Validator checker
          if(control!=null) {
            // Required Validator
            if(controlName?.errors?.required) {
              this.errorMessage = "There are missing information. Please provide necessary information needed.";
            // Required Validator for ISS
            } else if(controlName?.errors?.pattern) {
              if(field=='emailAddress') {
                this.errorMessage = "Invalid email address. Please provide a valid email address.";
              } else if(field=='mobileNumber') {
                this.errorMessage = "Invalid mobile number. Please provide a valid mobile number.";
              } else if(field=='effDate') {
                this.errorMessage = "'Invalid date format. Kindly input as MM/DD/YYYY.";
              } else if(field=='expDate') {
                this.errorMessage = "'Invalid date format. Kindly input as MM/DD/YYYY.";
              }
            } else if(controlName?.errors?.matDatepickerParse) {
              if(field=='effDate') {
                this.errorMessage = "Invalid date format. Kindly input as MM/DD/YYYY.";
              } else if(field=='expDate') {
                this.errorMessage = "Invalid date format. Kindly input as MM/DD/YYYY.";
              }
            }
          }
        }
        this.agentsForm.get('agentSourceCode')?.disable();
        this.agentsForm.get('agentBmaCd')?.disable();
        this.agentsForm.get('agentSourceExtensionCd')?.disable();
        return true;
      } else if(this.checkRequired()){
        this.errorMessage = "There are missing information. Please provide necessary information needed.";
        this.agentsForm.get('agentSourceCode')?.disable();
        this.agentsForm.get('agentBmaCd')?.disable();
        this.agentsForm.get('agentSourceExtensionCd')?.disable();
        return true; 
      } else if (this.bmCodeDuplicateChecker()) {
        this.agentsForm.get('agentSourceCode')?.disable();
        this.agentsForm.get('agentBmaCd')?.disable();
        this.agentsForm.get('agentSourceExtensionCd')?.disable();
        return true; 
      } else {
        this.agentsForm.get('agentSourceCode')?.disable();
        this.agentsForm.get('agentBmaCd')?.disable();
        this.agentsForm.get('agentSourceExtensionCd')?.disable();
        this.errorMessage = '';
        return false;
      }
    } catch(e) {
      return false;
    }
  }

  checkRequired(){
    if(this.agentsForm.get('agentCode')?.value?.trim()=='' || this.agentsForm.get('agentCode')?.value==null
      ||this.agentsForm.get('agentName')?.value?.trim()=='' || this.agentsForm.get('agentName')?.value==null
      ||this.agentsForm.get('agentNumCode')?.value?.trim()=='' || this.agentsForm.get('agentNumCode')?.value==null
      ||this.agentsForm.get('emailAddress')?.value?.trim()=='' || this.agentsForm.get('emailAddress')?.value==null
      ||this.agentsForm.get('issueSourceCd')?.value?.trim()=='' || this.agentsForm.get('issueSourceCd')?.value==null) {
      return true;
    }else{
      return false;
    }
  }
  agentCdDuplicateChecker() {
    try {
      let agentCodeForm = this.agentsForm.get('agentCode')?.value;
      this.agentCodeField = this.agentTableConfig.tblData.filter(function(e: any) {
        return e.AGENT_CD?.trim().toUpperCase()==agentCodeForm?.trim().toUpperCase();
      });
      if(this.agentCodeField=='' || this.agentCodeField==null) {
        this.duplicateErrorEnabler = false;
        return false;
      } else {
        this.duplicateErrorEnabler = true;
        this.errorMessage = "Agent User ID already exists. Please add a unique Agent User ID.";
        return true;
      }
    } catch(e) {
      return false;
    }
  }

  bmCodeDuplicateChecker(): boolean {
    try {
      const dupeBmCode = this.agentTableConfig.tblData.find((e: any) => {
        const trimLeadingZeros = (str: string) => str?.replace(/^0+/, '');
        return trimLeadingZeros(e?.AGENT_NUM_CD) === trimLeadingZeros(this.agentsForm.get('agentNumCode')?.value) && this.rowClickedData?.AGENT_NUM_CD !== this.agentsForm.get('agentNumCode')?.value
      });
      if(dupeBmCode) {
        this.duplicateErrorEnabler = true;
        this.errorMessage = "Agent Code already exists. Please add a unique Agent Code.";
        return true;
      }
      this.duplicateErrorEnabler = false;
      return false;
    } catch {
      return false;
    }
  }

  deleteChecker(ev: any) {
    try {
      let tempDataFlag;
      tempDataFlag = this.tempAddedData.filter(function(e: any) {
        return e.AGENT_CD==ev.AGENT_CD;
      });
      if(tempDataFlag==''||tempDataFlag==null) {
        return true;
      } else {
        return false;
      }
    } catch(e) {
      return false;
    }
  }

  deleteRow() {
    try {
      let clickData = this.rowClickedData;
      this.agentTableConfig.tblData = this.agentTableConfig.tblData.filter(
        function(e: any) {
          return e.AGENT_CD != clickData.AGENT_CD;
        }
      );

      this.tempAddedData = this.tempAddedData.filter(
        function(e: any) {
          return e.AGENT_CD != clickData.AGENT_CD;
        }
      );

      this.deleteDisabler = true;
      this.saveEnabler();
      this.fieldDataClearer();
    } catch(e) {
      console.error(e);
    }
  }

  updateRow() {
    try {
      if(this.deleteDisabler) {
        if(!this.fieldValidator()) {
          this.tableDataUpdate();
          // Adds Updated Data that exists in the database to an object array for updated datas
          if(this.tempUpdateData==null || this.tempUpdateData=='') {
            this.tempUpdateData.push(this.formValuToData());
          } else {
            let tempUpdateChecker = this.tempUpdateData.find(
              (agent: { AGENT_CD: any; }) => agent.AGENT_CD == this.agentsForm.get('agentCode')?.value);
            if(tempUpdateChecker=='' || tempUpdateChecker==null) {
              this.tempUpdateData.push(this.formValuToData());
            } else { // this.datePipe.transform(new Date(), 'MM/dd/yyyy')
              tempUpdateChecker.AGENT_NAME = this.agentsForm.get('agentName')?.value;
              tempUpdateChecker.AGENT_NUM_CD = this.agentsForm.get('agentNumCode')?.value;
              tempUpdateChecker.EMAIL_ADDRESS = this.agentsForm.get('emailAddress')?.value;
              tempUpdateChecker.EFF_DATE = this.datePipe.transform(new Date(this.agentsForm.get('effDate')?.value), 'yyyy-MM-dd');
              tempUpdateChecker.REMARKS = this.agentsForm.get('remarks')?.value;
              tempUpdateChecker.LAST_USER = this.currentUser;
              tempUpdateChecker.ACTIVE_TAG = this.agentsForm.get('activeTag')?.value=='Yes' ? 'A' : 'I';
              tempUpdateChecker.ISS_CD = this.selectedIss.ISS_CD;
              tempUpdateChecker.ISS_NAME = this.selectedIss.ISS_NAME;
              tempUpdateChecker.MOBILE_NUMBER = this.agentsForm.get('mobileNumber')?.value;
              tempUpdateChecker.EXPIRY_DATE = this.agentsForm.get('expDate')?.value=='' || this.agentsForm.get('expDate')?.value==null ? '' : this.datePipe.transform(new Date(this.agentsForm.get('expDate')?.value), 'yyyy-MM-dd');
              tempUpdateChecker.LAST_USER_UPDATE = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
              tempUpdateChecker.AGENT_GRP = this.agentsForm.get('agentGroup')?.value;
              tempUpdateChecker.EDIT_PREM_TAG = this.agentsForm.get('editPremium')?.value;
              tempUpdateChecker.REN_NOTICE_TAG = this.agentsForm.get('renNoticeTag')?.value;
              tempUpdateChecker.AGENCY_CD = this.agentsForm.get('agencyCode')?.value;
              tempUpdateChecker.AGENCY_NAME = this.agentsForm.get('agencyName')?.value;
              tempUpdateChecker.AGENT_TYPE = this.agentsForm.get('agentTypeCd')?.value;
              tempUpdateChecker.SRC_CD = this.selectedSourceCode.SRC_CD;
              tempUpdateChecker.SRC_CD_DESC = this.selectedSourceCode.SRC_CD_DESC;
              tempUpdateChecker.BMA_CD = this.selectedBma.BMA_CD;
              tempUpdateChecker.BMA_DESC = this.selectedBma.BMA_DESC;
              tempUpdateChecker.SRC_EXT_CD = this.selectedSourceExtension.SRC_EXT_CD;
              tempUpdateChecker.SRC_EXT_DESC = this.selectedSourceExtension.SRC_EXT_DESC;
              tempUpdateChecker.RPA_STAFF = this.agentsForm.get('rpaStaff')?.value;
              tempUpdateChecker.RPA_DEPT = this.agentsForm.get('rpaDept')?.value;
            }
          }  

          this.fieldDataClearer();
          this.formService.hideFormMsg("agent-error-message");

        } else {
          this.formService.showFormMsg("agent-error-message", this.errorMessage, "E");
        }
      } else {
        if(!this.fieldValidator()) {
          
          this.tableDataUpdate();
          let tempInsertChecker = this.tempAddedData.find(
            (agent: { AGENT_CD: any; }) => agent.AGENT_CD == this.agentsForm.get('agentCode')?.value);
          // Adds Updated Data that exists in the database to an object array for added / inserted data
          if(tempInsertChecker!=null && tempInsertChecker!='') {
            tempInsertChecker.AGENT_NAME = this.agentsForm.get('agentName')?.value;
            tempInsertChecker.AGENT_NUM_CD = this.agentsForm.get('agentNumCode')?.value;
            tempInsertChecker.EMAIL_ADDRESS = this.agentsForm.get('emailAddress')?.value;
            tempInsertChecker.EFF_DATE = this.datePipe.transform(new Date(this.agentsForm.get('effDate')?.value), 'yyyy-MM-dd');
            tempInsertChecker.REMARKS = this.agentsForm.get('remarks')?.value;
            tempInsertChecker.LAST_USER = this.currentUser;
            tempInsertChecker.ACTIVE_TAG = this.agentsForm.get('activeTag')?.value=='Yes' ? 'A' : 'I';
            tempInsertChecker.ISS_CD = this.selectedIss.ISS_CD;
            tempInsertChecker.ISS_NAME = this.selectedIss.ISS_NAME;
            tempInsertChecker.MOBILE_NUMBER = this.agentsForm.get('mobileNumber')?.value;
            tempInsertChecker.EXPIRY_DATE = this.agentsForm.get('expDate')?.value=='' || this.agentsForm.get('expDate')?.value==null ? '' : this.datePipe.transform(new Date(this.agentsForm.get('expDate')?.value), 'yyyy-MM-dd');
            tempInsertChecker.LAST_USER_UPDATE = this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
            tempInsertChecker.AGENT_GRP = this.agentsForm.get('agentGroup')?.value,
            tempInsertChecker.EDIT_PREM_TAG = this.agentsForm.get('editPremium')?.value,
            tempInsertChecker.REN_NOTICE_TAG = this.agentsForm.get('renNoticeTag')?.value,
            tempInsertChecker.AGENCY_CD = this.agentsForm.get('agencyCode')?.value,
            tempInsertChecker.AGENCY_NAME = this.agentsForm.get('agencyName')?.value,
            tempInsertChecker.AGENT_TYPE = this.agentsForm.get('agentTypeCd')?.value,
            tempInsertChecker.SRC_CD = this.selectedSourceCode.SRC_CD,
            tempInsertChecker.SRC_CD_DESC = this.selectedSourceCode.SRC_CD_DESC,
            tempInsertChecker.BMA_CD = this.selectedBma.BMA_CD,
            tempInsertChecker.BMA_DESC = this.selectedBma.BMA_DESC,
            tempInsertChecker.SRC_EXT_CD = this.selectedSourceExtension.SRC_EXT_CD,
            tempInsertChecker.SRC_EXT_DESC = this.selectedSourceExtension.SRC_EXT_DESC;
            tempInsertChecker.RPA_STAFF = this.agentsForm.get('rpaStaff')?.value;
            tempInsertChecker.RPA_DEPT = this.agentsForm.get('rpaDept')?.value;
          }
          this.fieldDataClearer();
          this.formService.hideFormMsg("agent-error-message");
  
        } else {
          this.formService.showFormMsg("agent-error-message", this.errorMessage, "E");
        }
      }
      this.agentTableConfig.tblData = [...this.agentTableConfig.tblData];
      this.saveEnabler();
      this.deleteDisabler = true;
      
    } catch(e) {
      console.error(e);
    }
  }

  tableDataUpdate() {
    try {
      let updateIndex = this.agentTableConfig.tblData.indexOf(this.rowClickedData);
      if (this.agentsForm.get('agencyCode')?.value!=null && this.agentsForm.get('agencyCode')?.value!='') {
        this.selectedAgency.AGENCY_CODE = this.agencyList.find(
          (x: { agencyCd: any; }) => x.agencyCd === this.agentsForm.get('agencyCode')?.value).agencyCd;
        this.selectedAgency.AGENCY_NAME = this.agencyList.find(
          (x: { agencyCd: any; }) => x.agencyCd === this.agentsForm.get('agencyCode')?.value).agencyName;
        this.agentsForm.get('agencyCode')
          ?.setValue(this.selectedAgency==null || this.selectedAgency=='' ? '' : this.selectedAgency.AGENCY_CODE);
        this.agentsForm.get('agencyName')
          ?.setValue(this.selectedAgency==null || this.selectedAgency=='' ? '' : this.selectedAgency.AGENCY_NAME);
      }
      else {
        this.selectedAgency.AGENCY_CODE = null;
        this.selectedAgency.AGENCY_NAME = null;
        this.agentsForm.get('agencyCode')
          ?.setValue(this.selectedAgency==null || this.selectedAgency=='' ? '' : this.selectedAgency.AGENCY_CODE);
        this.agentsForm.get('agencyName')
          ?.setValue(this.selectedAgency==null || this.selectedAgency=='' ? '' : this.selectedAgency.AGENCY_NAME);
      }
      this.agentTableConfig.tblData[updateIndex] = {
      AGENT_CD: this.agentsForm.get('agentCode')?.value,
      AGENT_NUM_CD: this.agentsForm.get('agentNumCode')?.value,
      AGENT_NAME: this.agentsForm.get('agentName')?.value,
      EMAIL_ADDRESS: this.agentsForm.get('emailAddress')?.value,
      EFF_DATE: this.datePipe.transform(new Date(this.agentsForm.get('effDate')?.value), 'yyyy-MM-dd'),
      REMARKS: this.agentsForm.get('remarks')?.value,
      ACTIVE_TAG: this.agentsForm.get('activeTag')?.value=='Yes' ? 'A' : 'I',
      ISS_CD: this.selectedIss.ISS_CD,
      ISS_NAME: this.selectedIss.ISS_NAME,
      MOBILE_NUMBER: this.agentsForm.get('mobileNumber')?.value,
      EXPIRY_DATE: this.agentsForm.get('expDate')?.value=='' || this.agentsForm.get('expDate')?.value==null ? '' : this.datePipe.transform(new Date(this.agentsForm.get('expDate')?.value), 'yyyy-MM-dd'),
      AGENT_GRP: this.agentsForm.get('agentGroup')?.value,
      EDIT_PREM_TAG: this.agentsForm.get('editPremium')?.value,
      REN_NOTICE_TAG: this.agentsForm.get('renNoticeTag')?.value,
      AGENCY_CD: this.agentsForm.get('agencyCode')?.value,
      AGENCY_NAME: this.agentsForm.get('agencyName')?.value,
      AGENT_TYPE: this.agentsForm.get('agentTypeCd')?.value,
      SRC_CD: this.selectedSourceCode.SRC_CD,
      SRC_CD_DESC: this.selectedSourceCode.SRC_CD_DESC,
      BMA_CD: this.selectedBma.BMA_CD,
      BMA_DESC: this.selectedBma.BMA_DESC,
      SRC_EXT_CD: this.selectedSourceExtension.SRC_EXT_CD,
      SRC_EXT_DESC: this.selectedSourceExtension.SRC_EXT_DESC,
      LAST_USER: this.rowClickedData.LAST_USER,
      LAST_USER_UPDATE: this.rowClickedData.LAST_USER_UPDATE,
      RPA_STAFF: this.agentsForm.get('rpaStaff')?.value,
      RPA_DEPT: this.agentsForm.get('rpaDept')?.value
      };
    } catch(e) {
      console.error(e);
    }
  }

  formValuToData() {
    try {
      this.agentsForm.patchValue({
        activeTag: this.agentsForm.get('activeTag')?.value=='Yes' ? 'A' : 'I',
        lastUpdateUser: this.currentUser,
        lastDate: new Date(),
        issueSourceCd: this.selectedIss.ISS_CD,
        issueSourceName: this.selectedIss.ISS_NAME,
        agentSourceCode: this.selectedSourceCode.SRC_CD,
        agentSourceDesc: this.selectedSourceCode.SRC_CD_DESC,
        agentBmaCd: this.selectedBma.BMA_CD,
        agentBmaDesc: this.selectedBma.BMA_DESC,
        agentSourceExtensionCd: this.selectedSourceExtension.SRC_EXT_CD,
        agentSourceExtensionDesc: this.selectedSourceExtension.SRC_EXT_DESC,
        createDate: new Date(),
        createUser: this.currentUser,
      });
      let formToData: any = { // this.datePipe.transform(new Date(), 'MM/dd/yyyy')
        ACTIVE_TAG: this.agentsForm.get('activeTag')?.value,
        AGENT_CD: this.agentsForm.get('agentCode')?.value,
        AGENT_NUM_CD: this.agentsForm.get('agentNumCode')?.value,
        AGENT_NAME: this.agentsForm.get('agentName')?.value,
        CREATE_DATE: this.agentsForm.get('createDate')?.value,
        CREATE_USER: this.agentsForm.get('createUser')?.value,
        EFF_DATE: this.datePipe.transform(new Date(this.agentsForm.get('effDate')?.value), 'yyyy-MM-dd'),
        EMAIL_ADDRESS: this.agentsForm.get('emailAddress')?.value,
        EXPIRY_DATE: this.agentsForm.get('expDate')?.value=='' || this.agentsForm.get('expDate')?.value==null ? '' : this.datePipe.transform(new Date(this.agentsForm.get('expDate')?.value), 'yyyy-MM-dd'),
        ISS_CD: this.agentsForm.get('issueSourceCd')?.value,
        ISS_NAME: this.agentsForm.get('issueSourceName')?.value,
        LAST_USER: this.agentsForm.get('lastUpdateUser')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.agentsForm.get('lastDate')?.value), 'yyyy-MM-dd'),
        MOBILE_NUMBER: this.agentsForm.get('mobileNumber')?.value,
        REMARKS: this.agentsForm.get('remarks')?.value,
        AGENT_GRP: this.agentsForm.get('agentGroup')?.value,
        EDIT_PREM_TAG: this.agentsForm.get('editPremium')?.value,
        REN_NOTICE_TAG: this.agentsForm.get('renNoticeTag')?.value,
        AGENCY_CD: this.agentsForm.get('agencyCode')?.value,
        AGENCY_NAME: this.agentsForm.get('agencyName')?.value,
        AGENT_TYPE: this.agentsForm.get('agentTypeCd')?.value,
        SRC_CD: this.agentsForm.get('agentSourceCode')?.value,
        SRC_CD_DESC: this.agentsForm.get('agentSourceDesc')?.value,
        BMA_CD: this.agentsForm.get('agentBmaCd')?.value,
        BMA_DESC: this.agentsForm.get('agentBmaDesc')?.value,
        SRC_EXT_CD: this.agentsForm.get('agentSourceExtensionCd')?.value,
        SRC_EXT_DESC: this.agentsForm.get('agentSourceExtensionDesc')?.value,
        RPA_STAFF: this.agentsForm.get('rpaStaff')?.value,
        RPA_DEPT: this.agentsForm.get('rpaDept')?.value,
      }
      return formToData;
    } catch(e) {
      console.error(e);
    }
  }

  saveEnabler() {
    try {
      if(this.tempAddedData.length!=0 || this.tempUpdateData.length!=0) {
        this.saveDisabler = false;
      } else {
        this.saveDisabler = true;
      }
    } catch(e) {
      console.error(e);
    }
  }

  resetUserPw() {
    try {
      let resetPWAcct: any = {
        userId: '', 
        isAdmin: '',
        userIdAdmin: ''
      };
      resetPWAcct.userId = this.selectedAgent.AGENT_CD;
      resetPWAcct.isAdmin = 'N'
      resetPWAcct.userIdAdmin = this.currentUser;
      this.dialogBoxService.open({
        messageType: "C",
        content: "Are you sure you want to reset the password of Agent " + resetPWAcct.userId + "?",
        buttons: [
          { text: "No" },
          { text: "Yes", action: () => {
            this.apiCallService.resetUserPw(resetPWAcct).subscribe((res: any) => {
                this.securityService.checkRequestKeyResponse(res, () => {
                  this.securityService.hasValidCsrfToken(res, ()=>{
                    res = JSON.parse(this.jsonDataService.decrypt(res.response));
                    if (res.status == "SUCCESS") {
                      this.openDialog('SUCCESS', 'Password reset successful!');
                    } else {
                      this.openDialog('FAILED', 'Password reset was not successful. Please contact your administrator.');
                    }
                  });
                });
            });
          }}
        ]
      });
    }
    catch(e) {
      console.error(e);
    }
  }

  toSave() {
    try {
        this.formHider = true;
        this.formService.showFormLoader(null, "agent-form", "Saving.<br>Please wait ...", null, null);
        this.apiCallService.saveAgents(this.tempAddedData).subscribe({
            next: (data: any) => {
                this.securityService.checkRequestKeyResponse(data, () => {
                  this.securityService.hasValidCsrfToken(data, ()=>{
                    if(data.status == 'SUCCESS') {
                      this.apiCallService.updateAgents(this.tempUpdateData).subscribe({
                          next: (res: any) => {
                              this.securityService.checkRequestKeyResponse(res, () => {
                                this.securityService.hasValidCsrfToken(res, ()=>{
                                  if(res.status == 'SUCCESS') {
                                    this.onComplete();
                                  } else {
                                      this.onFail();
                                  }
                                });
                              });
                          },
                          error: () => {
                              this.onFail();
                          }
                      });
                    } else {
                        this.onFail();
                    }
                  });
                });
            },
            error: () => {
                this.onFail();
            }
        });
      
      /*forkJoin({
        addedData: this.apiCallService.saveAgents(this.tempAddedData),
        updateData: this.apiCallService.updateAgents(this.tempUpdateData),
      }).subscribe({
        next: (data: any) => {
            this.securityService.checkRequestKeyResponse(data, () => {
                if(data.addedData.status === 'SUCCESS' && data.updateData.status === 'SUCCESS') {
                    this.onComplete();
                } else {
                    this.onFail();
                }
            });
        },
        error: () => {
          console.log("Error in agent maintenance: toSave");
          this.onFail();
        }
      });*/
        this.fieldDataClearer();
        this.saveEnabler();
    } catch(e) {
      console.error(e);
    }
  }

  onComplete() {
    try {
      this.tempAddedData = [];
      this.tempUpdateData = [];
      this.getAgentsData();
      this.saveEnabler();
      this.formService.hideFormLoader("agent-form");
      this.formHider = false;
      
      this.openDialog('SUCCESS', 'Agent details Successfully saved!');
    } catch(e) {
      console.error(e);
    }
  }

  onFail() {
    try {
      this.tempAddedData = [];
      this.tempUpdateData = [];
      this.getAgentsData();
      this.saveEnabler();
      this.formService.hideFormLoader("agent-form");
      this.formHider = false;
      this.openDialog('FAILED', 'Agent details saving failed!');
    } catch(e) {
      console.error(e);
    }
  }

  openDialog(title: any, message: any) {
    try {
      this.issDialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        } 
      });
    } catch(e) {
      console.error(e);
    }
  }

  checkNameLength(fieldName:string){
    try {this.agentsForm.get('agentCode')?.value
      if(this.agentsForm.get(fieldName)?.value!=='' && this.agentsForm.get(fieldName)?.value!==null) {
        let trimmedName = this.agentsForm.get(fieldName)?.value?.trim();
        if (fieldName == 'agentName' && trimmedName.length > 50) {
          this.agentsForm.get(fieldName)?.setValue(trimmedName.substring(0, 20));
        } else {
          this.agentsForm.get(fieldName)?.setValue(trimmedName);
        }
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  numOnlyPattern(ev: any) {
    try{
      const pattern = /^[0-9]\d*$/;   /^[0-9]\d*$/
      //let inputChar = String.fromCharCode(event.charCode)
      if (!pattern.test(ev.target.value)) {
        ev.target.value = ev.target.value.replace(/[^0-9]/g, "");
      }
    }
    catch(e) {
      console.error(e);
    }
  }

  datePattern(ev: any) {
    try{
      //let inputChar = String.fromCharCode(event.charCode)
      if (ev.target.value.length<4) {
        let firstPattern = /^\d{2}\/$/;
        if(!ev.target.value.match(firstPattern)){
          ev.target.value = ev.target.value.substring(0,2);
        }
      } else if(ev.target.value.length<7){
        let firstPattern = /^\d{2}\/\d{2}\/$/;
        if(!ev.target.value.match(firstPattern)){
          ev.target.value = ev.target.value.substring(0,5);
        }
      } else if(ev.target.value.length>6&&ev.target.value.length<11){
        let firstPattern = /^\d{2}\/\d{2}\/\d+$/;
        if(!ev.target.value.match(firstPattern)){
          ev.target.value = ev.target.value.substring(0,ev.target.value.length-1);
        }
      } else if(ev.target.value.length==10){
        let firstPattern = /^\d{2}\/\d{2}\/\d{4}$/;
        if(!ev.target.value.match(firstPattern)){
          ev.target.value = ev.target.value.substring(0,ev.target.value.length-1);
        }
      } else{
        ev.target.value = ev.target.value.substring(0,ev.target.value.length-1);
      }
    }
    catch(e) {
      console.error(e);
    }
  }
  autoUppercase(ev: any) {
    try{
      ev.target.value = ev.target.value.toUpperCase();
    }
    catch(e) {
      console.error(e);
    }
  }

  rpaOnChange() {
    this.agentsForm.get("rpaDept")?.setValue(
      this.agentsForm.get("rpaStaff")?.value != null && this.agentsForm.get("rpaStaff")?.value != "" ? this.jsonDataService.data.rpaStaff.find((e: any) => +e.rpaStaffCd === +this.agentsForm.get("rpaStaff")?.value)?.rpaDept : ""
    );
  }
}
