import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

@Component({
  selector: 'prop-assessment-lov',
  templateUrl: './prop-assessment-lov.component.html',
  styleUrls: ['./prop-assessment-lov.component.css']
})
export class PropAssessmentLovComponent implements OnInit {

  private assessmentModuleId: string = 'BMM164';
  private selectedData: any;
  public titleLov: string = "Assessment Code".toUpperCase();

  assessmentTable: any = {
    cols: [
      {
        key: "ASSESSMENT_CD",
        header: "Assessment Code",
        dataType: "string"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  };

  constructor(
    private jsonDataService: JsonDataService,
    private dialogRef: MatDialogRef<PropAssessmentLovComponent>,
    private _api: APICallService,
    private securityService: SecurityService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    @Inject(MAT_DIALOG_DATA) public fromMainData: any,
  ) {
    this.getPropAssessmentData();
  }

  ngOnInit(): void {
  }

  onRowClick(ev: any) {
    try {
      this.selectedData = ev;
    } catch (e) {
      console.error(e);
    }
  }

  private getPropAssessmentData(): void {
    try {
      this.assessmentTable.loading = true;
      this.jsonDataService.contorlLoading(true);
      this._api.getPropertyAssessmentWordings({
        moduleId: this.fromMainData.moduleId,
        userId: this.userDetailService.userId,
        type: "LOV"
      }).subscribe({
        next: (data: any) => {
          this.securityService.checkRequestKeyResponse(data, () => {
            this.securityService.hasValidCsrfToken(data, () => {
              let content = JSON.parse(this.jsonDataService.decrypt(data?.response));
              if (content.moduleId === this.assessmentModuleId) {
                this.jsonDataService.contorlLoading(false);
                let onlyActive: any[] = content.data?.filter((e: any) => e.ACTIVE_TAG == 'A') ?? [];
                this.assessmentTable.tblData = onlyActive.filter((e: any, i: number) => onlyActive.findIndex((o: any) => o.ASSESSMENT_CD === e.ASSESSMENT_CD) === i);
                this.assessmentTable.loading = false;
              } else {
                this.assessmentTable.loading = false;
                this.jsonDataService.contorlLoading(false);
                this.appMessageService.showAppMessage("You do not have the correct privilege to access the data. Kindly contact your administrator for assistance.", "error");
              }
            });
          });
        },
        error: (e: any) => {
          this.securityService.checkRequestKeyResponse(e, () => {

            this.assessmentTable.loading = false;
            this.jsonDataService.contorlLoading(false);
            this.appMessageService.showAppMessage(e, "error");
          });
        }
      });
    }
    catch (e) {
      console.error(e);
      this.assessmentTable.loading = false;
      this.jsonDataService.contorlLoading(false);
    }
  }

  onClose(str: string) {
    try {
      if (this.selectedData == '' || this.selectedData == null) {
        this.dialogRef.close({ content: '', button: str });
      }
      else {
        this.dialogRef.close({ content: this.selectedData, button: str });
      }
    } catch (e) {
      console.error(e);
    }
  }

}
