<section id='faq-main-maint'>
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <!-- Container for FAQ MAIN Table using prime ng -->
        <div class = 'table-faq-main'>
            <osp-table [tblConfig]="tblConfigFAQMain" (onRowClick)="onRowClick($event)"></osp-table>
        </div>
        <!-- Loading Animation -->
        <div id="generate-faq-main-form">
            <div class="form-loader"></div>
        </div>
        
        <!-- Container for FAQ Main Fields -->
        <div class="form-content">
            
            <form [formGroup]="faqMainForm" class="form" *ngIf="noLoadingInProcess">
                <div id="faq-main-error-message"></div>
                <div class="form-container-grid">
                    <div class="field">
                        <label>Question No.</label>
                    </div>
                    <div class="">
                        <input formControlName="questionNum" type="text" readonly>
                    </div>
                    <div>
                    </div>
                    <div>
                    </div>

                    <div class="field">
                        <label>Sequence No.</label>
                    </div>
                    <div class="">
                        <input posNumInput formControlName="sortSeq" type="number" min="1">
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag">
                            <option value="A" selected>Yes</option>
                            <option value="I">No</option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>Question</label>
                    </div>
                    <div class="span">
                        <textarea formControlName="question"></textarea>
                    </div>

                    <div class="field required">
                        <label>Answer</label>
                    </div>
                    <div class="span">
                        <textarea formControlName="answer"></textarea>
                    </div>

                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" type="text">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="userID">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUserUpdate">
                    </div>

                </div>
                <div class='btn-row'>
                    <button type="button" id='btn-add' *ngIf="!isFAQSelected" title='Register a main FAQ' class="btn btn2" value='ADD' type="submit" (click)="insertFAQMain()">ADD</button>
                    <button type="button" id='btn-update' *ngIf="isFAQSelected" title='Update a main FAQ' class="btn btn2" value='UPDATE' type="submit" (click)="updateFAQMain()">UPDATE</button>
                    <button type="button" id='btn-delete' title='Remove a main FAQ' class="btn btn2" value='DELETE' type="submit" [disabled]='deleteDisabled' (click)='deleteFAQMain()'>DELETE</button>
                </div> 

                <div class="mtn-title"></div>

                <div class='btn-row'>
                    <button type="button" id='btn-save' title='Save changes on FAQ Main' class="btn btn2" value='SAVE' type="submit" [disabled]='saveDisabled' (click)='toSave()'>SAVE</button>
                </div>
            </form>
        </div>  
    </div>
</section>