<section id='line-maint'>
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <!-- Container for Line Table using prime ng -->
        <div class = 'table-line'>
            <osp-table [tblConfig]="tblConfigLine" (onRowClick)="onRowClick($event)"></osp-table>
        </div>
        <!-- Loading Animation -->
        <!-- <div id="generate-line-form">
            <div class="form-loader"></div>
        </div> -->
        
        <!-- Container for Line Fields -->
        <div class="form-content">
            
            <form [formGroup]="lineForm" class="form" *ngIf="noLoadingInProcess">
                <div id="line-error-message"></div>
                <div class="form-container-grid">
                    <div class="field required">
                        <label>Line Code</label>
                    </div>
                    <div class="">
                        <input formControlName="lineCode" type="text" maxlength="2" oninput="this.value = this.value.toUpperCase()">
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag">
                            <option value="A" selected>Yes</option>
                            <option value="I">No</option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>Line Name</label>
                    </div>
                    <div class="">
                        <input formControlName="lineName" type="text" maxlength="50">
                    </div>
                    <div class="field required">
                        <label>Default</label>
                    </div>
                    <div class="">
                        <select formControlName="defaultTag">
                            <option value=""></option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>Menu Line Code</label>
                    </div>
                    <div class="">
                        <input formControlName="menuLineCode" type="text" maxlength="2">
                    </div>
                    <div class="field">
                        <label>Sequence No.</label>
                    </div>
                    <div class="">
                        <input formControlName="seqNo" type="number" min="1" posNumInput>
                    </div>

                    <div class="field">
                        <label>Image Path</label>
                    </div>
                    <div class="field">
                        <input formControlName="imagePath" type="text">
                    </div>

                    <div class="field">
                        <label>User Type View</label>
                    </div>
                    <div class="">
                        <select formControlName="userTypeView">
                            <option value=""></option>
                            <option *ngFor="let userTypeView of userTypeViewList" [value]="userTypeView.rvLowValue">{{ userTypeView.rvMeaning }}</option>
                        </select>
                    </div>

                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" type="text">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="userID">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUserUpdate">
                    </div>

                </div>
                <div class='btn-row'>
                    <button type="button" id='btn-add' *ngIf="!isLineSelected" title='Register a new line' class="btn btn2" value='ADD' type="submit" (click)='insertLine()'>ADD</button>
                    <button type="button" id='btn-update' *ngIf="isLineSelected" title='Update a line' class="btn btn2" value='UPDATE' type="submit" (click)='updateLine()'>UPDATE</button>
                    <button type="button" id='btn-delete' title='Remove a line' class="btn btn2" value='DELETE' type="submit" (click)='deleteLine()' [disabled]='deleteDisabled'>DELETE</button>
                </div> 

                <div class="mtn-title"></div>

                <div class='btn-row'>
                    <button type="button" id='btn-save' title='Save changes on line details' class="btn btn2" value='SAVE' type="submit" (click)='toSave()' [disabled]='saveDisabled'>SAVE</button>
                </div>
            </form>
        </div>  
    </div>
</section>
