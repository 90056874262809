import { Component, ViewChild, TemplateRef, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'overlay',
  templateUrl: './overlay.html',
  styleUrls: ['./overlay.css']
})
export class OverlayComponent {

  page: String = '';

  constructor(public dialog: MatDialog) { }

  @ViewChild(TemplateRef) templateRef!: TemplateRef<any>;

  @Input() title: String = '';

  dialogRef!: MatDialogRef<any>;

  closeBtnText:String = 'Close'

  open(closeBtnText?:String, disableClose?: true):MatDialogRef<any>{
    if(closeBtnText === 'choosePlan'){
        this.page = closeBtnText;
        this.closeBtnText = 'Back';    
    } else {
        this.closeBtnText = closeBtnText ? closeBtnText : 'Close';
    }
    this.dialogRef = this.dialog.open(this.templateRef, {
      disableClose: disableClose ? disableClose : true,
      data: { animal: 'panda' }
    }).addPanelClass('osp-overlay');
    return  this.dialogRef;
  }

  close(){
    this.dialogRef.close();
  }
}
