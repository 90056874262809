<section id="bfb-std-rates">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{this.moduleName}}</p>
        </div>
        <div class="form-content">
            <form [formGroup]="paramForm" class="form">
                <div class="form-container-grid top-form">
                    <div class="field required">
                        <label>Line</label>
                    </div>
                    <div class="lov-search">
                        <input readonly formControlName="lineField">
                        <button matSuffix class="btn-search btn" type="button" (click)="openLineDialog()"><mat-icon>search</mat-icon></button>
                    </div>
                    <div class="field required">
                        <label>Subline</label>
                    </div>
                    <div class="lov-search">
                        <input readonly formControlName="sublineField">
                        <button matSuffix class="btn-search btn" type="button" (click)="openSublineDialog()" [disabled]="perilDisabled"><mat-icon>search</mat-icon></button>
                    </div>
                </div>
            </form>
        </div>
        <div class = 'table-line'>
            <osp-table [tblConfig]="deductibleTblConfig" (onRowClick)="onRowClick($event)"></osp-table>
        </div>

        <!-- Loading Animation -->
        <div id="generate-line-form">
            <div class="form-loader"></div>
        </div>

        <!-- Container for Line Fields -->
        <div class="form-content">
            <form [formGroup]="deductibleForm" class="form" *ngIf="noLoadingInProcess">
                <div id="bfb-standard-rates-error-message"></div>
                <div class="form-container-grid">
                    <div class="field required">
                        <label>Deductible Code</label>
                    </div>
                    <div class="">
                        <input formControlName="deductibleCd" type="text" oninput="this.value = this.value.toUpperCase()" maxlength="5" [readonly]="isLineSelected">
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag">
                            <option value="A" selected>Yes</option>
                            <option value="I">No</option>
                        </select>
                    </div>
                    <div class="field">
                        <label>Deductible</label>
                    </div>
                    <div class="">
                        <input formControlName="deductibleTitle" maxlength="30" type="text">
                    </div>
                    <div class="field">
                        <label></label>
                    </div>
                    <div class="">
                    </div>
                    <div class="field">
                        <label>Deductible Text</label>
                    </div>
                    <div class="span">
                        <input formControlName="deductibleText" type="text">
                    </div>
                    <div class="field">
                        <label>Deductible Type</label>
                    </div>
                    <div class="">
                        <select formControlName="deductibleType">
                            <option *ngFor="let deductibleType of deductibleTypeList" [value]="deductibleType.rvLowValue">{{deductibleType.rvLowValue}} - {{ deductibleType.rvMeaning }}</option>
                        </select>
                    </div>
                    <div class="field">
                        <label>Range Switch</label>
                    </div>
                    <div class="">
                        <select formControlName="rangeSwitch">
                            <option *ngFor="let rangeSwitch of rangeSwitchList" [value]="rangeSwitch.rvLowValue">{{rangeSwitch.rvLowValue}} - {{ rangeSwitch.rvMeaning }}</option>
                        </select>
                    </div>
                    <div class="field">
                        <label>Deductible Rate</label>
                    </div>
                    <div class="">
                        <input formControlName="deductibleRate" type="text" maxlength="13" (focusout)="deductibleRateFormat()">
                    </div>
                    <div class="field">
                        <label>Deductible Amount</label>
                    </div>
                    <div class="">
                        <input formControlName="deductibleAmt" type="text" maxlength="17" (focusout)="amtFormat('deductibleAmt')">
                    </div>
                    <div class="field">
                        <label>Minimum Amount</label>
                    </div>
                    <div class="">
                        <input formControlName="minAmt" type="text" maxlength="17" (focusout)="amtFormat('minAmt')">
                    </div>
                    <div class="field">
                        <label>Maximum Amount</label>
                    </div>
                    <div class="">
                        <input formControlName="maxAmt" type="text" maxlength="17" (focusout)="amtFormat('maxAmt')">
                    </div>
                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" type="text">
                    </div>
                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="userID">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUserUpdate">
                    </div>
                </div>
                <div class='btn-row'>
                    <button type="button" id='btn-add' *ngIf="!isLineSelected" title='Register a new line' class="btn btn2" value='ADD' type="submit" [disabled]='addDisabled' (click)="insertLine()">ADD</button>
                    <button type="button" id='btn-update' *ngIf="isLineSelected" title='Update a line' class="btn btn2" value='UPDATE' type="submit" (click)="updateLine()">UPDATE</button>
                    <button type="button" id='btn-delete' title='Remove a line' class="btn btn2" value='DELETE' type="submit" [disabled]='deleteDisabled' (click)="deleteLine()">DELETE</button>
                </div> 

                <div class="mtn-title"></div>

                <div class='btn-row'>
                    <button type="button" id='btn-save' title='Save changes on line details' class="btn btn2" value='SAVE' type="submit" (click)="toSave()" [disabled]='saveDisabled'>SAVE</button>
                </div>
            </form>
        </div>
    </div>
</section>