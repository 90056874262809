import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { CustomValidators } from 'src/app/utils/custom-validator';
import { ClassLovComponent } from '../../common/class-lov/class-lov.component';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';

@Component({
  selector: 'property-details-maintenance',
  templateUrl: './property-details-maintenance.component.html',
  styleUrls: ['./property-details-maintenance.component.css']
})
export class PropertyDetailsMaintenanceComponent implements OnInit {

  addUpdateFlag: boolean = true;
  datePipe = new DatePipe('en-us');
  selectedRow: any = [];
  tempNewData: any = [];
  tempUpdateData: any = [];
  userId: string = this.userDetailService.userId;
  errorMessage: string = '';
  saveDisabler: boolean = true;
  deleteDisabler: boolean = true;
  formHider: boolean = false;
  moduleId: string = 'BMM160';
  moduleName = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId === 'BMM160' })[0]?.moduleDesc.toUpperCase();
  propertyDetailType = this.jsonDataService.data.refCds.filter((e: any) => e.rvDomain === 'PROPERTY_DTL_TYPE');
  othersDescTag = this.jsonDataService.data.refCds.filter((e: any) => e.rvDomain === 'OTHERS_DESC_TAG');
  
  propertyDetailsTable: any = {
    cols: [
      {
        key: "PROPERTY_DTL_ID",
        header: "Property DTL ID",
        dataType: "string"
      },
      {
        key: "PROPERTY_TYPE_DESC",
        header: "Type",
        dataType: "string"
      },
      {
        key: "PROPERTY_DTL_DESC",
        header: "Description",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  propertyDetailsForm = this.fb.group({
    PROPERTY_DTL_ID     : [{value: '', disabled: true}],
    PROPERTY_DTL_TYPE   : ['', [Validators.required]],
    PROPERTY_DTL_DESC   : ['', [Validators.required, this.duplicateShortDesc(), CustomValidators.requiredTrim]],
    OTHERS_DESC_TAG     : [''],
    SEQ_NO              : ['', {validators: [this.duplicationSeqNo()], updateOn: 'blur'}],
    DESCRIPTION_FIL     : [''],
    DESCRIPTION_TAGLISH : [''],
    CLASS_CD            : ['', [Validators.required]],
    CLASS_NAME          : [''],
    ACTIVE_TAG          : ['A', [Validators.required]],
    REMARKS             : [''],
    USER_ID             : [{value: '', disabled: true}],
    LAST_UPDATE         : [{value: '', disabled: true}],
  });

  constructor(
    private fb: FormBuilder,
    private api: APICallService,
    private dialog: MatDialog,
    private jsonDataService: JsonDataService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    private formService: FormService,
    private securityService: SecurityService
  ) { }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempNewData!='' || this.tempUpdateData!='') {
      return false;
    } else {
      return true;
    }
  }

  duplicateShortDesc(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const selectedRow = this.selectedRow;
      let propDtlDescDuplicate = this.propertyDetailsTable.tblData
                                  .filter((e: any) => e.PROPERTY_DTL_TYPE === this.propertyDetailsForm.get("PROPERTY_DTL_TYPE")?.value &&
                                                      e.PROPERTY_DTL_DESC.trim().toLowerCase()==control.value.trim().toLowerCase() && 
                                                      // control.value?.trim().toLowerCase()!=selectedRow.PROPERTY_DTL_DESC?.trim().toLowerCase() &&
                                                      selectedRow?.PROPERTY_DTL_TYPE != this.propertyDetailsForm.get("PROPERTY_DTL_TYPE")?.value)
      if(propDtlDescDuplicate!='') {
        return { "propDtlDescDuplicate": "Description already exists. Please add a unique Description." }
      }
      return null;
    }
  }

  duplicationSeqNo(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const selectedRow = this.selectedRow;
      let seqNoDuplicate = '';
      if(control.value != "" && control.value != null) {
        if(this.propertyDetailsForm.get("PROPERTY_DTL_TYPE")?.value == selectedRow?.PROPERTY_DTL_TYPE) {
          seqNoDuplicate = this.propertyDetailsTable.tblData
                              .filter((e: any) =>  
                                e.SEQ_NO == control.value && 
                                e.PROPERTY_DTL_TYPE === this.propertyDetailsForm.get("PROPERTY_DTL_TYPE")?.value &&
                                control.value != selectedRow?.SEQ_NO
                              );
        } else {
          seqNoDuplicate = this.propertyDetailsTable.tblData
                              .filter((e: any) =>  
                                e.SEQ_NO == control.value && 
                                e.PROPERTY_DTL_TYPE === this.propertyDetailsForm.get("PROPERTY_DTL_TYPE")?.value
                              );
        }
      }
      if(seqNoDuplicate!='') {
        return { "seqNoDuplicate": "Sequence No already exists for current type. Please add a unique Sequence No." }
      }
      return null;
    }
  }

  ngOnInit(): void {
    this.getpropertyDetails();
  }

  getpropertyDetails(): void {
    try {
      this.propertyDetailsTable.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.api.getPropertyDetails({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE"
      }).subscribe((data: any) => {
        this.securityService.checkRequestKeyResponse(data, () => {
          this.securityService.hasValidCsrfToken(data, () => {
            if (data.status === "SUCCESS") {
              data = JSON.parse(this.jsonDataService.decrypt(data.response));
              data = data.map((e: any) => {
                return {...e, PROPERTY_TYPE_DESC: this.propertyDetailType.filter((f: any) => f.rvLowValue === e.PROPERTY_DTL_TYPE)[0]?.rvMeaning}
              });
              this.propertyDetailsTable.loading = false;
              this.propertyDetailsTable.tblData = data;
              this.propertyDetailsTable.tblData = [
                ...this.propertyDetailsTable.tblData.filter((e: any) => e.ACTIVE_TAG === 'A'),
                ...this.propertyDetailsTable.tblData.filter((e: any) => e.ACTIVE_TAG === 'I')
              ];
              this.jsonDataService.contorlLoading(false);
            } else {
                this.jsonDataService.contorlLoading(false);
                this.propertyDetailsTable.loading = false;
                this.appMessageService.showAppMessage(data.message, "error");
            }
          });
        });
      });
    } catch(e) {
      
    }
  }

  onRowClick(ev: any): void {
    try {
      if(ev!=null) {
        this.selectedRow = ev;
        this.addUpdateFlag = false;
        this.formLoader();
      } else {
        this.selectedRow = '';
        this.addUpdateFlag = true;
        this.formClearer();
      }
      this.deleteEnabler();
    } catch(e) {
      
    }
  }

  formLoader() {
    try {
      this.propertyDetailsForm.patchValue({
        PROPERTY_DTL_ID     : this.selectedRow.PROPERTY_DTL_ID,
        PROPERTY_DTL_TYPE   : this.selectedRow.PROPERTY_DTL_TYPE,  
        PROPERTY_DTL_DESC   : this.selectedRow.PROPERTY_DTL_DESC,  
        OTHERS_DESC_TAG     : this.selectedRow.OTHERS_DESC_TAG,    
        SEQ_NO              : this.selectedRow.SEQ_NO,             
        DESCRIPTION_FIL     : this.selectedRow.DESCRIPTION_FIL,    
        DESCRIPTION_TAGLISH : this.selectedRow.DESCRIPTION_TAGLISH,
        CLASS_CD            : this.selectedRow.CLASS_NAME,           
        ACTIVE_TAG          : this.selectedRow.ACTIVE_TAG,         
        REMARKS             : this.selectedRow.REMARKS,            
        USER_ID             : this.selectedRow.USER_ID,            
        LAST_UPDATE         : this.selectedRow.LAST_UPDATE,        
      });
      this.propertyDetailsForm.get('CLASS_CD')?.setValue(this.selectedRow.CLASS_CD, {emitModelToViewChange: false});
    } catch(e) {
      
    }
  }
  
  formClearer() {
    try {
      this.propertyDetailsForm.patchValue({
        PROPERTY_DTL_ID     : "",
        PROPERTY_DTL_TYPE   : "",
        PROPERTY_DTL_DESC   : "",
        OTHERS_DESC_TAG     : "",
        SEQ_NO              : "",
        DESCRIPTION_FIL     : "",
        DESCRIPTION_TAGLISH : "",
        CLASS_CD            : "",
        ACTIVE_TAG          : "A",
        REMARKS             : "",
        USER_ID             : "",
        LAST_UPDATE         : "",
      });
      this.selectedRow = [];
    } catch(e) {
      
    }
  }

  deleteEnabler() {
    try {
      let tempDeleteFlag = this.tempNewData.filter((e: any) => {
        return e.PROPERTY_DTL_DESC == this.selectedRow.PROPERTY_DTL_DESC
      });
      if(tempDeleteFlag=='' || tempDeleteFlag==null) {
        this.deleteDisabler = true;
      } else {
        this.deleteDisabler = false;
      }
    } catch(e) {
      
    }
  }

  openClassDialog() {
    try {
      this.dialog.open(ClassLovComponent, {
        width: '512px',
        disableClose: true,
        data: {
          moduleId: this.moduleId,
          userId: this.userDetailService.userId,
        }
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null && data.button === "OK") {
          const content = data.content;
          this.propertyDetailsForm.get('CLASS_CD')?.setValue(content.CLASS_NAME);
          this.propertyDetailsForm.get('CLASS_CD')?.setValue(content.CLASS_CD, {emitModelToViewChange: false});
          this.propertyDetailsForm.get('CLASS_NAME')?.setValue(content.CLASS_NAME);
        } else {
          this.propertyDetailsForm.get('CLASS_CD')?.setValue("");
        }
      });
    } catch(e) {

    }
  }
  
  addRow(): void {
    try {
      this.propertyDetailsForm.get("SEQ_NO")?.updateValueAndValidity();
      this.propertyDetailsForm.get("PROPERTY_DTL_DESC")?.updateValueAndValidity();
      if(this.propertyDetailsForm.valid) {
        this.formService.hideFormMsg("property-details-error-message");
        this.propertyDetailsTable.tblData.unshift(this.formValues());
        this.propertyDetailsTable.tblData = [...this.propertyDetailsTable.tblData];
        this.tempNewData.push(this.formValues());
        this.formClearer();
        this.saveDisabler = false;
      } else {
        this.errorMessageSetter();
      }
    } catch(e) {
      
    }
  }

  formValues(): any {
    try {
      return {
        PROPERTY_DTL_ID     : isNaN(parseInt(this.propertyDetailsForm.get('PROPERTY_DTL_ID')?.value)) ? "" : parseInt(this.propertyDetailsForm.get('PROPERTY_DTL_ID')?.value),
        PROPERTY_DTL_TYPE   : this.propertyDetailsForm.get('PROPERTY_DTL_TYPE')?.value,
        PROPERTY_TYPE_DESC  : this.propertyDetailType.filter((e: any) => e.rvLowValue === this.propertyDetailsForm.get('PROPERTY_DTL_TYPE')?.value)[0]?.rvMeaning,
        PROPERTY_DTL_DESC   : this.propertyDetailsForm.get('PROPERTY_DTL_DESC')?.value,
        OTHERS_DESC_TAG     : this.propertyDetailsForm.get('OTHERS_DESC_TAG')?.value,
        SEQ_NO              : this.propertyDetailsForm.get('SEQ_NO')?.value,
        DESCRIPTION_FIL     : this.propertyDetailsForm.get('DESCRIPTION_FIL')?.value,
        DESCRIPTION_TAGLISH : this.propertyDetailsForm.get('DESCRIPTION_TAGLISH')?.value,
        CLASS_CD            : this.propertyDetailsForm.get('CLASS_CD')?.value,
        CLASS_NAME          : this.propertyDetailsForm.get('CLASS_NAME')?.value || this.selectedRow?.CLASS_NAME,
        ACTIVE_TAG          : this.propertyDetailsForm.get('ACTIVE_TAG')?.value,
        REMARKS             : this.propertyDetailsForm.get('REMARKS')?.value,
        USER_ID             : this.userId,
        LAST_UPDATE         : this.propertyDetailsForm.get('LAST_UPDATE')?.value,
      }
    } catch(e) {
      
    }
  }

  errorMessageSetter() {
    try {
      if(this.propertyDetailsForm.invalid) {
        for(const field in this.propertyDetailsForm.controls) {
          const controlName = this.propertyDetailsForm.get(field);
          if(controlName?.errors?.required) {
            this.errorMessage = 'There are missing information. Please provide necessary information needed.';
          }
          if(controlName?.errors?.propDtlDescDuplicate) {
            this.errorMessage = controlName?.errors?.propDtlDescDuplicate;
          }
          if(controlName?.errors?.seqNoDuplicate) {
            this.errorMessage = controlName?.errors?.seqNoDuplicate;
          }
          this.formService.showFormMsg("property-details-error-message", this.errorMessage, "E");
        }
      }
    } catch(e) {
      
    }
  }

  onUpdate(): void {
    try {
      this.propertyDetailsForm.get("SEQ_NO")?.updateValueAndValidity();
      this.propertyDetailsForm.get("PROPERTY_DTL_DESC")?.updateValueAndValidity();
      if(this.propertyDetailsForm.valid) {
        this.updateTable();
        if(this.deleteDisabler) {
          this.updateDbData();
        } else {
          this.updateNewData();
        }
        this.selectedRow = '';
        this.addUpdateFlag = true;
        this.formClearer();
        this.saveDisabler = false;
        this.deleteEnabler();
        this.formService.hideFormMsg('property-details-error-message');
      } else {
        this.errorMessageSetter();
      }
    } catch(e) {
      
    }
  }

  updateTable() {
    try {
      let updateIndex = this.propertyDetailsTable.tblData.indexOf(this.selectedRow);
      this.propertyDetailsTable.tblData[updateIndex] = this.formValues();
      if(this.deleteDisabler) {
        this.propertyDetailsTable.tblData[updateIndex].PROPERTY_DTL_ID = this.propertyDetailsTable.tblData[updateIndex].PROPERTY_DTL_ID.toString().padStart(2, 0);
      }
      this.propertyDetailsTable.tblData = [...this.propertyDetailsTable.tblData];
    } catch(e) {
      
    }
  }

  updateDbData() {
    try {
      let tempDbData = this.tempUpdateData.find(
        (dbData: { PROPERTY_DTL_ID: any }) => dbData.PROPERTY_DTL_ID == this.selectedRow.PROPERTY_DTL_ID
      );
      if((this.tempUpdateData == '' || this.tempUpdateData == null) || (tempDbData=='' || tempDbData==null)) {
        this.tempUpdateData.push(this.formValues());
      } else {
        tempDbData.PROPERTY_DTL_ID     = parseInt(this.propertyDetailsForm.get('PROPERTY_DTL_ID')?.value);
        tempDbData.PROPERTY_DTL_TYPE   = this.propertyDetailsForm.get('PROPERTY_DTL_TYPE')?.value;
        tempDbData.PROPERTY_DTL_DESC   = this.propertyDetailsForm.get('PROPERTY_DTL_DESC')?.value;
        tempDbData.OTHERS_DESC_TAG     = this.propertyDetailsForm.get('OTHERS_DESC_TAG')?.value;
        tempDbData.SEQ_NO              = this.propertyDetailsForm.get('SEQ_NO')?.value;
        tempDbData.DESCRIPTION_FIL     = this.propertyDetailsForm.get('DESCRIPTION_FIL')?.value;
        tempDbData.DESCRIPTION_TAGLISH = this.propertyDetailsForm.get('DESCRIPTION_TAGLISH')?.value;
        tempDbData.CLASS_CD            = this.propertyDetailsForm.get('CLASS_CD')?.value;
        tempDbData.ACTIVE_TAG          = this.propertyDetailsForm.get('ACTIVE_TAG')?.value;
        tempDbData.REMARKS             = this.propertyDetailsForm.get('REMARKS')?.value;
        tempDbData.USER_ID             = this.userId;
        tempDbData.LAST_UPDATE         = this.propertyDetailsForm.get('LAST_UPDATE')?.value;
      }
    } catch(e) {
      
    }
  }

  updateNewData() {
    try {
      let tempNewConfigdata = this.tempNewData.find(
        (e: { PROPERTY_DTL_DESC: any }) => e.PROPERTY_DTL_DESC == this.selectedRow.PROPERTY_DTL_DESC
      );
      tempNewConfigdata.PROPERTY_DTL_ID     = this.propertyDetailsForm.get('PROPERTY_DTL_ID')?.value;
      tempNewConfigdata.PROPERTY_DTL_TYPE   = this.propertyDetailsForm.get('PROPERTY_DTL_TYPE')?.value;
      tempNewConfigdata.PROPERTY_DTL_DESC   = this.propertyDetailsForm.get('PROPERTY_DTL_DESC')?.value;
      tempNewConfigdata.OTHERS_DESC_TAG     = this.propertyDetailsForm.get('OTHERS_DESC_TAG')?.value;
      tempNewConfigdata.SEQ_NO              = this.propertyDetailsForm.get('SEQ_NO')?.value;
      tempNewConfigdata.DESCRIPTION_FIL     = this.propertyDetailsForm.get('DESCRIPTION_FIL')?.value;
      tempNewConfigdata.DESCRIPTION_TAGLISH = this.propertyDetailsForm.get('DESCRIPTION_TAGLISH')?.value;
      tempNewConfigdata.CLASS_CD            = this.propertyDetailsForm.get('CLASS_CD')?.value;
      tempNewConfigdata.ACTIVE_TAG          = this.propertyDetailsForm.get('ACTIVE_TAG')?.value;
      tempNewConfigdata.REMARKS             = this.propertyDetailsForm.get('REMARKS')?.value;
      tempNewConfigdata.USER_ID             = this.userId;
      tempNewConfigdata.LAST_UPDATE         = this.propertyDetailsForm.get('LAST_UPDATE')?.value;
    } catch(e) {
      
    }
  }

  deleteRow(): void {
    try {
      let clickedData = this.selectedRow;
      this.propertyDetailsTable.tblData = this.propertyDetailsTable.tblData.filter(function(e: any) {
        return e.PROPERTY_DTL_DESC != clickedData.PROPERTY_DTL_DESC;
      });
      this.tempNewData = this.tempNewData.filter(function(e: any) {
        return e.PROPERTY_DTL_DESC != clickedData.PROPERTY_DTL_DESC;
      });
      this.selectedRow = '';
      this.formClearer();
      this.deleteDisabler = true;
      this.addUpdateFlag = true;
      if((this.tempNewData=='' || this.tempNewData==null) && (this.tempUpdateData=='' || this.tempUpdateData==null)) {
        this.saveDisabler = true;
      }
    } catch(e) {
      
    }
  }

  onSave(): void {
    try {
      this.formHider = true;
      // this.formService.showFormLoader(null, "typhoon-flood-zoning-form", "Saving.<br>Please wait ...", null, null);
      this.jsonDataService.contorlLoading(true);
      this.api.savePropertyDetails(this.tempNewData).subscribe({
        next: (data: any) => {
          this.securityService.checkRequestKeyResponse(data, () => {
            this.securityService.hasValidCsrfToken(data, () => {
              if(data.status == 'SUCCESS') {
                this.api.savePropertyDetails(this.tempUpdateData).subscribe({
                  next: (res: any) => {
                    this.securityService.checkRequestKeyResponse(res, () => {
                      this.securityService.hasValidCsrfToken(data, () => {
                        if(res.status == 'SUCCESS') {
                          this.onComplete();
                        } else {
                          this.onFail();
                        }
                      });
                    });
                  },
                  error: () => {
                    this.onFail();
                  }
                });
              } else {
                this.onFail();
              }
            });
          });
        },
        error: () => {
          this.onFail();
        }
      });
    } catch(e) {
      
    }
  }
  
  onComplete() {
    try {
      this.formHider = false;
      this.tempNewData = [];
      this.tempUpdateData = [];
      this.saveDisabler = true;
      this.deleteEnabler();
      this.formClearer();
      this.getpropertyDetails();
      this.formService.hideFormMsg("property-details-error-message");
      this.openSaveDialog();
    } catch(e) {
      
    }
  }

  onFail() {
    try {
      this.formHider = false;
      this.tempNewData = [];
      this.tempUpdateData = [];
      this.deleteEnabler();
      this.formClearer();
      this.getpropertyDetails();
      this.formService.hideFormMsg("property-details-error-message");
      this.openErrorDialog("Saving Property Details Failed");
    } catch(e) {
      
    }
  }

  openErrorDialog(message: string) {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'ERROR',
          content: message,
        }
      });
    } catch(e) {
      
    }
  }

  openSaveDialog() {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'SUCCESS',
          content: 'Property Details successfully saved!',
        }
      });
    } catch(e) {
      
    }
  }

}
