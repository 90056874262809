import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';
import { PlanLovComponent } from '../../common/plan-lov/plan-lov.component';

@Component({
  selector: 'pa-hcip-parameter-validation-maintenance',
  templateUrl: './pa-hcip-parameter-validation-maintenance.component.html',
  styleUrls: ['./pa-hcip-parameter-validation-maintenance.component.css']
})
export class PAHCIPParameterValidationComponent implements OnInit, LeaveConfirmation {

  moduleId: string = 'BMM136';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc?.toUpperCase() ?? "";
  constructor(
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    public _formService: FormService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService
  ) { 
    this.userIdLoggedIn = this._userDetailService.userId ?? "";
  }

  ngOnInit(): void {
    this.btns.pahcipParamValidation.addDisabled = true;
  }

  canDeactivate(): boolean | Observable<boolean> {
    if(this.tempData!='') {
      return false;
    } else {
      return true;
    }
  }

  datePipe = new DatePipe('en-us');
  formHider: boolean = false;
  
  /* Boolean Stuffs */

  public btns = {
    pahcipParamValidation: {
      addDisabled: true,
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true
    },
    lov: {
      lineDisabled: false,
      sublineDisabled: true,
      planDisabled: true
    }
  }

  public showForm: boolean = true;

  private rowData: any;
  private tempDataRow: any;
  private errorMessage: string = "";
  private userIdLoggedIn: string;
  private pahcipParamValidationPerPlanPerilList : any;
  private tempData: any = [];
  public emailTypeList: any = [];

  public forms = {
    lineSublinePlanLovForm: this.fb.group({
      lineCd: [null],
      lineName: [null],
      lineCdDisp: [{ value: '', disabled: 'true' }],
      sublineCd: [null],
      sublineName: [null],
      sublineCdDisp: [{ value: '', disabled: 'true' }],
      planCd: [null],
      planName: [null],
      planCdDisp: [{ value: '', disabled: 'true' }]
    }),
    pahcipParamValidationForm: this.fb.group({
      lineCd: [null, Validators.required],
      sublineCd: [null, Validators.required],
      planCd: [null, Validators.required],
      planName: [null],
      planDesc: [null],
      minPrinAge: [null],
      maxPrinAge: [null],
      minSpouseAge: [null],
      maxParentSpouseAge: [null],
      minChildSiblingAge: [null],
      maxChildSiblingAge: [null],
      maxNoChildSibling: [null],
      inclusionText: [null],
      prinAgeErrMsg: [null],
      depAgeErrMsg: [null],
      maxNoErrMsg: [null],
      dependentsTag: [null, Validators.required],
      maxDepWithPrem: [null],
      maxDepErrMsg: [null],
      otherRiskDtl: [null],
      remarks: [null],
      lastUpdateUser: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }]
    }),
    resetpahcipParamValidationForm: () => {
      this.forms.pahcipParamValidationForm.patchValue({
      planCd: [null],
      planName: [null],
      planDesc: [null],
      minPrinAge: [null],
      maxPrinAge: [null],
      minSpouseAge: [null],
      maxParentSpouseAge: [null],
      minChildSiblingAge: [null],
      maxChildSiblingAge: [null],
      maxNoChildSibling: [null],
      inclusionText: [null],
      prinAgeErrMsg: [null],
      depAgeErrMsg: [null],
      maxNoErrMsg: [null],
      dependentsTag: [null],
      maxDepWithPrem: [null],
      maxDepErrMsg: [null],
      otherRiskDtl: [null],
      remarks: [null],
      lastUpdateUser: [null],
      lastUpdate: [null]
      });
      this.btns.pahcipParamValidation.updateDisabled = true;
      this.btns.pahcipParamValidation.deleteDisabled = true;
      this._formService.hideFormMsg("pahcipParamValidation-error-message");
      this.rowData = null;
    },
    resetLineSublineLovForm: () => {
      this.forms.lineSublinePlanLovForm.reset();
    },
    resetAllForms: () => {
      this.forms.resetLineSublineLovForm();
      this.forms.resetpahcipParamValidationForm();
    }
  }

  private pahcipParamValidationFormManipulate = {
    dbToForm: (data: any) => {
      return {
        lineCd: data.LINE_CD,
        sublineCd: data.SUBLINE_CD,
        planCd: data.PLAN_CD,
        planName: data.PLAN_NAME,
        planDesc:  data.PLAN_CD + ' - ' +data.PLAN_NAME,
        minPrinAge: data.MIN_PRIN_AGE,
        maxPrinAge: data.MAX_PRIN_AGE,
        minSpouseAge: data.MIN_SPOUSE_AGE,
        maxParentSpouseAge: data.MAX_PARENT_SPOUSE_AGE,
        minChildSiblingAge: data.MIN_CHILD_SIBLING_AGE,
        maxChildSiblingAge: data.MAX_CHILD_SIBLING_AGE,
        maxNoChildSibling: data.MAX_NO_CHILD_SIBLING,
        inclusionText: data.INCLUSION_TEXT,
        prinAgeErrMsg: data.PRIN_AGE_ERR_MSG,
        depAgeErrMsg: data.DEP_AGE_ERR_MSG,
        maxNoErrMsg: data.MAX_NO_ERR_MSG,
        dependentsTag: data.DEPENDENTS_TAG,
        maxDepWithPrem: data.MAX_DEP_WITH_PREM.toString(),
        maxDepErrMsg: data.MAX_DEP_ERR_MSG,
        otherRiskDtl: data.OTHER_RISK_DTL,
        remarks: data.REMARKS,
        lastUpdateUser: data.LAST_USER,
        lastUpdate:  data.LAST_USER_UPDATE ? this.datePipe.transform(data.LAST_USER_UPDATE, 'MM/dd/YYYY h:mm:ss a', '+0000') : ''
      };
    },
    formToDb: (data: any) => {
      return {
        LINE_CD: data.lineCd,
        SUBLINE_CD: data.sublineCd,
        PLAN_CD: data.planCd,
        PLAN_NAME: data.planName,
        PLAN_DESC: data.planCd + ' - ' + data.planName,
        MIN_PRIN_AGE: data.minPrinAge,
        MAX_PRIN_AGE: data.maxPrinAge,
        MIN_SPOUSE_AGE: data.minSpouseAge,
        MAX_PARENT_SPOUSE_AGE: data.maxParentSpouseAge,
        MIN_CHILD_SIBLING_AGE: data.minChildSiblingAge,
        MAX_CHILD_SIBLING_AGE: data.maxChildSiblingAge,
        MAX_NO_CHILD_SIBLING: data.maxNoChildSibling,
        INCLUSION_TEXT: data.inclusionText,
        PRIN_AGE_ERR_MSG: data.prinAgeErrMsg,
        DEP_AGE_ERR_MSG: data.depAgeErrMsg,
        MAX_NO_ERR_MSG: data.maxNoErrMsg,
        DEPENDENTS_TAG: data.dependentsTag,
        MAX_DEP_WITH_PREM: data.maxDepWithPrem.toString(),
        MAX_DEP_ERR_MSG: data.maxDepErrMsg,
        OTHER_RISK_DTL: data.otherRiskDtl,
        REMARKS: data.remarks,
        LAST_USER: this.userIdLoggedIn,
        LAST_USER_UPDATE: null
      };
    }
  }

  public openLov = {
    line: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'LINE',
          moduleId: this.moduleId
        },
      }
      this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button === 'OK') {
          this.forms.resetpahcipParamValidationForm();
          if (output) {
            this.forms.lineSublinePlanLovForm.patchValue({
              lineCd: output.LINE_CD,
              lineName: output.LINE_NAME,
              lineCdDisp: `${output.LINE_CD} - ${output.LINE_NAME}`,
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null,
              planCd: null,
              planName: null,
              planCdDisp: null,
            });
            this.forms.pahcipParamValidationForm.patchValue({
              lineCd: output.LINE_CD,
              sublineCd: null,
              planCd: null
            });
            this.btns.lov.sublineDisabled = false;
            this.btns.pahcipParamValidation.addDisabled = true;
          }
          else {
            this.forms.lineSublinePlanLovForm.patchValue({
              lineCd: null,
              lineName: null,
              lineCdDisp: null,
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null,
              planCd: null,
              planName: null,
              planCdDisp: null,
            });
            this.forms.pahcipParamValidationForm.patchValue({
              lineCd: output.LINE_CD,
              sublineCd: null,
              planCd: null
            });
            this.btns.lov.sublineDisabled = true;
            this.btns.pahcipParamValidation.addDisabled = true;
          }
          this.pahcipParamValidationTblConfig.tblData = [];
          this.tempData = [];
          this.btns.pahcipParamValidation.saveDisabled = true;
        }
      });
    },
    subline: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'SUBLINE',
          moduleId: this.moduleId
        },
      }
      this.forms.resetpahcipParamValidationForm();
      LOVOPTIONS.data.lineCd = this.forms.lineSublinePlanLovForm.get('lineCd')?.value;
      this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
        let output = dataOutput.content;
        if (output) {
          this.forms.lineSublinePlanLovForm.patchValue({
            lineCd: output.LINE_CD,
            lineName: output.LINE_NAME,
            lineCdDisp: `${output.LINE_CD} - ${output.LINE_NAME}`,
            sublineCd: output.SUBLINE_CD,
            subLineName: output.SUBLINE_NAME,
            sublineCdDisp: `${output.SUBLINE_CD} - ${output.SUBLINE_NAME}`,
            planCd: null,
            planName: null,
            planCdDisp: null,
          });
          this.forms.pahcipParamValidationForm.patchValue({
            lineCd: output.LINE_CD,
            sublineCd: output.SUBLINE_CD,
            planCd: null,
          });
          this.btns.lov.planDisabled = false;
          this.btns.pahcipParamValidation.addDisabled = false;
        } 
        else {
          this.forms.lineSublinePlanLovForm.patchValue({
            sublineCd: null,
            sublineName: null,
            sublineCdDisp: null,
            planCd: null,
            planName: null,
            planCdDisp: null,
          });
          this.forms.pahcipParamValidationForm.patchValue({
            lineCd: null,
            sublineCd: null,
            planCd: null
          });
          this.btns.pahcipParamValidation.addDisabled = true;
        }
        this.getData.pahcipParamValidation();
      });
    },
    plan: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'PLAN',
          moduleId: this.moduleId,
          lineCd: this.forms.lineSublinePlanLovForm.get('lineCd')?.value,
          sublineCd: this.forms.lineSublinePlanLovForm.get('sublineCd')?.value,
        },
      };
      this._matDialog.open(PlanLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button?.toUpperCase() === 'OK') {
          this.forms.resetpahcipParamValidationForm();
          if (output.PLAN_CD) {
            this.forms.lineSublinePlanLovForm.patchValue({
              planCd: output.PLAN_CD,
              planName: output.PLAN_NAME,
              planCdDisp: `${output.PLAN_CD} - ${output.PLAN_NAME}`,
            });
            this.forms.pahcipParamValidationForm.patchValue({
              planCd: output.PLAN_CD,
              planName: output.PLAN_NAME,
              planDesc: `${output.PLAN_CD} - ${output.PLAN_NAME}`
            });
            this.btns.pahcipParamValidation.addDisabled = false;
          }
          else {
            this.forms.lineSublinePlanLovForm.patchValue({
              planCd: null,
              planName: null,
              planCdDisp: null,
            });
            this.btns.pahcipParamValidation.addDisabled = true;
          }
        }
      });
    },
  };

  private getData = {
    pahcipParamValidation: () => {
      try {
        this.pahcipParamValidationTblConfig.tblData = [];
        this.tempData = [];
        this.pahcipParamValidationTblConfig.loading = true;
        this._jDS.contorlLoading(true);
        this._api.getPAHCIPParameterValidation({
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          type: "MODULE"
        }).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
              let content = JSON.parse(this._jDS.decrypt(data.response));
              this._jDS.contorlLoading(false);
              this.pahcipParamValidationTblConfig.tblData = content.data.filter((d: any) => 
                d.LINE_CD == this.forms.lineSublinePlanLovForm.get('lineCd')?.value 
                && d.SUBLINE_CD == this.forms.lineSublinePlanLovForm.get('sublineCd')?.value);
              this.btns.pahcipParamValidation.addDisabled = false;
              this.pahcipParamValidationTblConfig.loading = false;
              });
            });
          },
          error: (error: any) => {
            this.pahcipParamValidationTblConfig.loading = false;
            this._jDS.contorlLoading(false);
          }
        });
      }
      catch (e) {
    console.error(e);
      }
    }
  }

  pahcipParamValidationTblConfig = {
    cols: [
      {
        key: "PLAN_DESC",
        header: "Plan",
        dataType: "string",
        width: '20%'
      },
      {
        key: "MIN_PRIN_AGE",
        header: "Minimum Principal Age",
        dataType: "string",
        width: '12%'
      },
      {
        key: "MAX_PRIN_AGE",
        header: "Maximum Principal Age",
        dataType: "string",
        width: '12%'
      },
      {
        key: "MIN_SPOUSE_AGE",
        header: "Minimum Spouse Age",
        dataType: "string",
        width: '12%'
      },
      {
        key: "MAX_PARENT_SPOUSE_AGE",
        header: "Maximum Spouse Age",
        dataType: "string",
        width: '12%'
      }
    ],
    tblData: [] as any[],
    selection: "single",
    paginator: true,
    rowsPerPage: 5,
    lazy: false,
    totalRecords: 10,
    loading: false,
  }

  onRowClick(ev: any) {
    try {
      if(ev!=null){
        ev.update = true;
        this.btns.lov.planDisabled = !ev.temp;
        this.rowData = ev;
        this.populateFormFromTable();
      }
      else{
        this.btns.lov.planDisabled = false;
        this.forms.resetpahcipParamValidationForm();
      }
    }
    catch (e) {
    console.error(e);
      this.forms.resetpahcipParamValidationForm();
      this.rowData = null;
      //this.indexRow = null;
    }
  }

  populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.pahcipParamValidation.updateDisabled = !data.update;
      this.btns.pahcipParamValidation.deleteDisabled = !data.temp;
      this.tempDataRow = !data.temp;
      this.forms.pahcipParamValidationForm.patchValue(this.pahcipParamValidationFormManipulate.dbToForm(data));
    }
    catch (e) {
    console.error(e);
    }
  }

  public onAdd() {
    try {
      this._formService.hideFormMsg("pahcipParamValidation-error-message");
      if (!this.seqNoDupCheck()) {
        this._formService.showFormMsg("pahcipParamValidation-error-message", "Line, Subline and Plan combination already exists. Please enter a different Plan", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("pahcipParamValidation-error-message");
        }, 10000);
      }
      if (this.checkRequired()) {
        this._formService.showFormMsg("pahcipParamValidation-error-message", "There are missing information. Please provide necessary information needed.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("pahcipParamValidation-error-message");
        }, 10000);
      }
      else if (this.forms.pahcipParamValidationForm.valid&&this.forms.pahcipParamValidationForm.get('dependentsTag')?.value!='') {
        this.forms.pahcipParamValidationForm.patchValue({
          lineCd: this.forms.lineSublinePlanLovForm.get('lineCd')?.value,
          sublineCd: this.forms.lineSublinePlanLovForm.get('sublineCd')?.value
        });
        let rowToBeAdded: { [key: string]: any } = this.pahcipParamValidationFormManipulate.formToDb(this.forms.pahcipParamValidationForm.value);
        rowToBeAdded.temp = true;
        this.tempData.push(rowToBeAdded);
        this.pahcipParamValidationTblConfig.tblData = [rowToBeAdded, ...this.pahcipParamValidationTblConfig.tblData];
        this.forms.resetpahcipParamValidationForm();
        this.btns.pahcipParamValidation.saveDisabled = false;
        this.btns.lov.planDisabled = false;
      }
      else {
        this.showErrorValidator.pahcipParamValidation();
      }
    }
    catch (e) {
    console.error(e);
    }
  }

  public onDelete() {
    try {
      let clickData = this.rowData;
      let rowToBeDeleted: { [key: string]: any } = this.pahcipParamValidationFormManipulate.formToDb(this.forms.pahcipParamValidationForm.value);
      
      let tempInsertChecker = this.tempData.find(
        (m: { LINE_CD: any, PLAN_CD: any, SUBLINE_CD: any; }) => m.PLAN_CD == clickData.PLAN_CD && clickData.LINE_CD == m.LINE_CD && m.SUBLINE_CD == clickData.SUBLINE_CD);

      if (tempInsertChecker!=null && tempInsertChecker!='') {
        this.pahcipParamValidationTblConfig.tblData = this.pahcipParamValidationTblConfig.tblData.filter((d: any) => {
          return d !== this.rowData;
        });
        this.tempData = this.tempData.filter(
          function(e: any) {
            return e.PLAN_CD != clickData.PLAN_CD && e.LINE_CD != clickData.LINE_CD && e.SUBLINE_CD != clickData.SUBLINE_CD;
          }
        );
        this.forms.resetpahcipParamValidationForm();
        if (this.tempData==null || this.tempData=='') {
          this.btns.lov.lineDisabled = false;
          this.btns.lov.sublineDisabled = false;
          this.btns.lov.planDisabled = false;
          this.btns.pahcipParamValidation.saveDisabled = true;
        }
      }
      else {
        this.pahcipParamValidationTblConfig.tblData = this.pahcipParamValidationTblConfig.tblData.filter((d: any) => {
          return d !== this.rowData;
        });
        this.tempData.push(rowToBeDeleted);
        this.btns.pahcipParamValidation.saveDisabled = false;
        this.forms.resetpahcipParamValidationForm();
      }
    }
    catch (e) {
    console.error(e);
    }
  }

  public onUpdate() {
    try {
      this._formService.hideFormMsg("pahcipParamValidation-error-message");
      if (!this.seqNoDupCheck()) {
        this._formService.showFormMsg("pahcipParamValidation-error-message", "Line, Subline and Plan combination already exists. Please enter a different Plan", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("pahcipParamValidation-error-message");
        }, 10000);
      }
      if (this.checkRequired()) {
        this._formService.showFormMsg("pahcipParamValidation-error-message", "There are missing information. Please provide necessary information needed.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("pahcipParamValidation-error-message");
        }, 10000);
      }
      else if (this.forms.pahcipParamValidationForm.valid) {
        let clickData = this.rowData;
        let toBeUpdatedIndex = this.pahcipParamValidationTblConfig.tblData.indexOf(this.rowData);
        let rowToBeUpdated: { [key: string]: any } = this.pahcipParamValidationFormManipulate.formToDb(this.forms.pahcipParamValidationForm.value);
        if(this.rowData.temp){
          rowToBeUpdated.temp = true;
        }
        this.tempData.push(rowToBeUpdated);
        this.forms.resetpahcipParamValidationForm();
        this.pahcipParamValidationTblConfig.tblData[toBeUpdatedIndex] = rowToBeUpdated;
        this.btns.pahcipParamValidation.saveDisabled = false;
        this.pahcipParamValidationTblConfig.tblData = [... this.pahcipParamValidationTblConfig.tblData];
        this.btns.lov.planDisabled = false;
      }
      else {
        this.showErrorValidator.pahcipParamValidation();
      }
    }
    catch (e) {
    console.error(e);
    }
  }

  public onSave() {
    try {
      this.showForm = false;
      this.formHider = true;
      this._formService.showFormLoader(null, "pahcipParamValidation-maintenance-loading", "Saving.<br>Please wait ...", null, null);
      if (this.tempData.length > 0) {
        for(let item of this.tempData){
          item.MIN_PRIN_AGE = item.MIN_PRIN_AGE==0?item.MIN_PRIN_AGE.toString():item.MIN_PRIN_AGE?item.MIN_PRIN_AGE.toString():null;
          item.MAX_PRIN_AGE = item.MAX_PRIN_AGE==0?item.MAX_PRIN_AGE.toString():item.MAX_PRIN_AGE?item.MAX_PRIN_AGE.toString():null;
          item.MIN_SPOUSE_AGE = item.MIN_SPOUSE_AGE==0?item.MIN_SPOUSE_AGE.toString():item.MIN_SPOUSE_AGE?item.MIN_SPOUSE_AGE.toString():null;
          item.MAX_PARENT_SPOUSE_AGE = item.MAX_PARENT_SPOUSE_AGE==0?item.MAX_PARENT_SPOUSE_AGE.toString():item.MAX_PARENT_SPOUSE_AGE?item.MAX_PARENT_SPOUSE_AGE.toString():null;
          item.MIN_CHILD_SIBLING_AGE = item.MIN_CHILD_SIBLING_AGE==0?item.MIN_CHILD_SIBLING_AGE.toString():item.MIN_CHILD_SIBLING_AGE?item.MIN_CHILD_SIBLING_AGE.toString():null;
          item.MAX_CHILD_SIBLING_AGE = item.MAX_CHILD_SIBLING_AGE==0?item.MAX_CHILD_SIBLING_AGE.toString():item.MAX_CHILD_SIBLING_AGE?item.MAX_CHILD_SIBLING_AGE.toString():null;
          item.MAX_NO_CHILD_SIBLING = item.MAX_NO_CHILD_SIBLING==0?item.MAX_NO_CHILD_SIBLING.toString():item.MAX_NO_CHILD_SIBLING?item.MAX_NO_CHILD_SIBLING.toString():null;
        }

        this._api.savePAHCIPParameterValidation(this.tempData).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
              if (data.status === 'SUCCESS') {
                this.onComplete.pahcipParamValidation();
              }
              else {
                this.onComplete.pahcipParamValidationError();
              }
            });
            });
          },
          error: (data: any) => {
            this.onComplete.pahcipParamValidationError();
          },
        });
      }
      else
        this.onComplete.pahcipParamValidation();
    }
    catch (e) {
    console.error(e);
    }
  }

  private onComplete = {
    pahcipParamValidation: () => {
      this.formHider = false;
      this.tempData = [];
      this.getData.pahcipParamValidation();
      this.btns.pahcipParamValidation.saveDisabled = true;
      this.showForm = true;
      this._formService.hideFormLoader("pahcipParamValidation-maintenance-loading");
      this.forms.resetpahcipParamValidationForm();
      this.openDialog('SUCCESS', 'PA-HCIP Parameter Validation details successfully saved');
    },
    pahcipParamValidationError: () => {
      this.formHider = false;
      this.showForm = true;
      this.btns.pahcipParamValidation.saveDisabled = false;
      this.openDialog('FAILED', 'PA-HCIP Parameter Validation details saving failed');
      this._formService.hideFormLoader("pahcipParamValidation-maintenance-loading");
    },
  }

  openDialog(title: any, message: any) {
    try {
      this.showForm = true;
      this._formService.hideFormLoader("pahcipParamValidation-maintenance-loading");
      this._matDialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        } 
      });
    } catch(e) {
    }
  }

  seqNoDupCheck() {
    if (!this.forms.pahcipParamValidationForm.get('planCd')?.value) return true;
    let hasDuplicate: any = this.pahcipParamValidationTblConfig.tblData.filter((d: any) => {
      if (!this.rowData && this.btns.pahcipParamValidation.updateDisabled){
        return d.PLAN_CD == this.forms.pahcipParamValidationForm.get('planCd')?.value;
      }
      else {
        return this.rowData.PLAN_CD !== this.forms.pahcipParamValidationForm.get('planCd')?.value && d.PLAN_CD == this.forms.pahcipParamValidationForm.get('planCd')?.value;
      }
    }).length > 0 ? true : false;
    
    if(hasDuplicate) {
      return false;
    } else {
      return true;
    }
  }

  checkDuplicatePlanSubgrp(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.trim()?.toUpperCase();
      if (!value) return null;
      let hasDuplicate: boolean = this.pahcipParamValidationTblConfig.tblData.filter((d: any) => {
        if (!this.rowData && this.btns.pahcipParamValidation.updateDisabled){
          return d.PLAN_SUBGRP.trim().toUpperCase() === value;
        }
        else {
          return this.rowData.PLAN_SUBGRP.trim().toUpperCase() !== value && d.PLAN_SUBGRP.trim().toUpperCase() === value;
        }
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupe: true } : null;
    }
  }

  public showErrorValidator = {
    pahcipParamValidation: () => {
      try {
        Object.keys(this.forms.pahcipParamValidationForm.controls).forEach(key => {
          const controlErrors = this.forms.pahcipParamValidationForm.get(key)?.errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              if (keyError == 'hasDupe' && controlErrors[keyError] === true)
                {this.errorMessage = "PA-HCIP Parameter Validation already exists. Please add a unique PA-HCIP Parameter Validation.";}
              else if (keyError == 'required' && controlErrors[keyError] === true)
                {this.errorMessage = "There are missing information. Please provide necessary information needed.";}
              // else if(this.checkFieldNumbers())
              //   {this.errorMessage = "There are missing information. Please provide necessary information needed.";}
              });
          }
        });
        if(!this.errorMessage){
          this.errorMessage = "There are missing information. Please provide necessary information needed.";
        }
        this._formService.showFormMsg("pahcipParamValidation-error-message", this.errorMessage, "E");
        //this.openDialog('ERROR', this.errorMessage);
        setTimeout(()=>{                           
          this._formService.hideFormMsg("pahcipParamValidation-error-message");
        }, 10000);
      }
      catch (e) {
    console.error(e);
      }
    },
  }

  checkRequired(){
    try{
      if(this.forms.pahcipParamValidationForm.get('maxDepWithPrem')?.value.trim()==='' || this.forms.pahcipParamValidationForm.get('maxDepWithPrem')?.value===null) {
        return true;
      }else{
        return false;
      }
    }catch(e){
      return true;
    }

  }
  autoUppercase(ev: any) {
    try{
      ev.target.value = ev.target.value.toUpperCase();
    }
    catch(e) {
    }
  }

  allowNumericDigitsOnlyWODecimal(event: any) {
    try {
      if (/[0-9]/.test(String.fromCharCode(event.keyCode))) {
        return true;
      }
      else {
        event.preventDefault();
        return false;
      }
    }
    catch (e) {
    console.error(e);
      return undefined; 
    }
  }

  // checkFieldNumbers() {
  //   try {
  //     if (this.forms.pahcipParamValidationForm.get('minPrinAge')?.value>=0&&
  //     this.forms.pahcipParamValidationForm.get('maxPrinAge')?.value>=0&&
  //     this.forms.pahcipParamValidationForm.get('minSpouseAge')?.value>=0&&
  //     this.forms.pahcipParamValidationForm.get('maxParentSpouseAge')?.value>=0&&
  //     this.forms.pahcipParamValidationForm.get('minChildSiblingAge')?.value>=0&&
  //     this.forms.pahcipParamValidationForm.get('maxChildSiblingAge')?.value>=0&&
  //     this.forms.pahcipParamValidationForm.get('maxNoChildSibling')?.value>=0&&
  //     this.forms.pahcipParamValidationForm.get('maxDepWithPrem')?.value>0) {
  //       return true;
  //     }
  //     else {
  //       return false;
  //     }
  //   }
  //   catch (e) {
  //     return false;
  //   }
  // }
}
