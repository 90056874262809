import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { SecurityService } from 'src/app/services/security.service';
import { CustomValidators } from 'src/app/utils/custom-validator';

@Component({
  selector: 'agent-group-maintenance',
  templateUrl: './agent-group-maintenance.component.html',
  styleUrls: ['./agent-group-maintenance.component.css']
})
export class AgentGroupMaintenanceComponent implements OnInit, LeaveConfirmation {

  selectedRowData: any;
  errorMessage: string = "";
  userId = this.jsonDataService.retrieveFromStorage('userId');
  formHider: boolean = false;
  datePipe = new DatePipe('en-ph');
  moduleId: string = 'BMM072';
  moduleName = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId === 'BMM072' })[0].moduleDesc.toUpperCase();

  //Button Togglers
  addUpdateToggler: boolean = true;
  deleteDisabler: boolean = true;
  saveDisabler: boolean = true;

  //Temp Data
  tempAddData: any = [];
  tempUpdateData: any = [];

  agentGroupTable: any = {
    cols: [
      {
        key: "AGENT_GRP",
        header: "Agent Dealer Group Code",
        dataType: "string"
      },
      {
        key: "AGENT_GRP_DESC",
        header: "Agent Dealer Group",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "Active",
        dataType: "checkbox"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  constructor(
    private jsonDataService: JsonDataService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    private fb: FormBuilder,
    private api: APICallService,
    private formService: FormService,
    private dialog: MatDialog,
    private securityService: SecurityService
  ) { }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempAddData!='' || this.tempUpdateData!='') {
      return false;
    } else {
      return true;
    }
  }

  agentGroupForm = this.fb.group({
    ACTIVE_TAG    : ['A', Validators.required],
    AGENT_GRP     : ['', [Validators.required, this.duplicateAgentGroup(), CustomValidators.requiredTrim]],
    AGENT_GRP_DESC: ['', [Validators.required, CustomValidators.requiredTrim]],
    EDIT_PREM_TAG : ['N', Validators.required],
    HIDE_COMM_TAG : ['N'],
    LAST_UPDATE   : [{value: '', disabled: true}],
    REMARKS       : [''],
    USER_ID       : [{value: '', disabled: true}],
  });

    ngOnInit(): void {
        try {
            this.getAgentGroup();
        } catch (e) {
    console.error(e);
          
        }
    }

  getAgentGroup() {
    try {
      this.agentGroupTable.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.api.getAgentGroup({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE"
      }).subscribe((data: any) => {
            this.securityService.checkRequestKeyResponse(data, () => {
              this.securityService.hasValidCsrfToken(data, () => {
                if (data.status === "SUCCESS") {
                  data = JSON.parse(this.jsonDataService.decrypt(data.response));
                  this.jsonDataService.contorlLoading(false);
                  this.agentGroupTable.tblData = data;
                  this.agentGroupTable.loading = false;
                } else {
                    this.jsonDataService.contorlLoading(false);
                    this.agentGroupTable.loading = false;
                    this.appMessageService.showAppMessage(data.message, "error");
                }
              });                
            });
      });
    } catch(e) {
      
    }
  }

  onRowClick(ev: any) {
    try {
      this.selectedRowData = ev;
      if(ev!=null) {
        this.formLoader();
        this.addUpdateToggler = false;
      } else {
        this.formClearer();
        this.addUpdateToggler = true;
      }
      this.deleteEnabler();
    } catch(e) {
      
    }
  }

  addRow() {
    try {
      if(this.formValidator()) {
        this.tableInserter();
        this.tempAddInserter();
        this.formClearer();
        this.saveDisabler = false;
      }
    } catch(e) {
      
    }
  }

  onUpdate() {
    try {
      if(this.formValidator()) {
        if(this.deleteDisabler) {
          this.updateTable();
          this.updateDbData();
          this.saveDisabler = false;
        } else {
          this.updateTable();
          this.updateNewData();
        }
        this.selectedRowData = '';
        this.addUpdateToggler = true;
        this.formClearer();
      }
    } catch(e) {
      
    }
  }

  deleteTableRow() {
    try {
      this.agentGroupTable.tblData = this.agentGroupTable.tblData.filter((e: any) => {
        return e.AGENT_GRP != this.selectedRowData.AGENT_GRP
      });
      this.tempAddData = this.tempAddData.filter((e: any) => {
        return e.AGENT_GRP != this.selectedRowData.AGENT_GRP
      });
      this.selectedRowData = '';
      this.formClearer();
      this.deleteDisabler = true;
      this.addUpdateToggler = true;
      if((this.tempAddData=='' || this.tempAddData==null) && (this.tempUpdateData=='' || this.tempUpdateData==null)) {
        this.saveDisabler = true;
      }
    } catch(e) {
      
    }
  }

  onSave() {
    try {
        this.formHider = true;
        this.jsonDataService.contorlLoading(true);
        this.api.saveAgentGroup(this.tempAddData).subscribe({
            next: (data: any) => {
                this.securityService.checkRequestKeyResponse(data, () => {
                  this.securityService.hasValidCsrfToken(data, () => {
                    if(data.status == 'SUCCESS') {
                      this.api.saveAgentGroup(this.tempUpdateData).subscribe({
                          next: (res: any) => {
                              this.securityService.checkRequestKeyResponse(res, () => {
                                this.securityService.hasValidCsrfToken(res, () => {
                                  if(res.status == 'SUCCESS') {
                                    this.onComplete();
                                    this.getAgentGroup();
                                  } else {
                                      this.jsonDataService.contorlLoading(false);
                                      this.openErrorDialog('Saving agent group details failed.');
                                  }
                                });
                              });
                          },
                          error: () => {
                              this.openErrorDialog('Saving agent group details failed.');
                          }
                      });
                    } else {
                        this.openErrorDialog('Saving agent group details failed.');
                    }
                  });
                });
            },
            error: () => {
                this.openErrorDialog('Saving agent group details failed.');
            }
        });
    } catch(e) {
      
    }
  }

  onComplete() {
    try {
      this.formService.hideFormLoader("agent-group-form");
      this.jsonDataService.contorlLoading(false);
      this.formHider = false;
      this.openSaveDialog();
      this.tempAddData = [];
      this.tempUpdateData = [];
      this.formClearer();
      this.deleteEnabler();
      this.saveDisabler = true;
    } catch(e) {
      
    }
  }

  openErrorDialog(message: string) {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'ERROR',
          content: message,
        }
      });
    } catch(e) {
      
    }
  }

  openSaveDialog() {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'SUCCESS',
          content: 'Agent Group details Successfully saved!',
        }
      });
    } catch(e) {
      
    }
  }

  updateTable() {
    try {
      let updateIndex = this.agentGroupTable.tblData.indexOf(this.selectedRowData);
      this.agentGroupTable.tblData[updateIndex] = {
        ACTIVE_TAG    : this.agentGroupForm.get('ACTIVE_TAG')?.value,
        AGENT_GRP     : this.agentGroupForm.get('AGENT_GRP')?.value,
        AGENT_GRP_DESC: this.agentGroupForm.get('AGENT_GRP_DESC')?.value,
        EDIT_PREM_TAG : this.agentGroupForm.get('EDIT_PREM_TAG')?.value,
        HIDE_COMM_TAG : this.agentGroupForm.get('HIDE_COMM_TAG')?.value,
        REMARKS       : this.agentGroupForm.get('REMARKS')?.value,
      };
      this.agentGroupTable.tblData = [...this.agentGroupTable.tblData];
    } catch(e) {
      
    }
  }

  updateNewData() {
    try {
      let tempNewConfigData = this.tempAddData.find(
        (agentGroup: { AGENT_GRP: any }) => agentGroup.AGENT_GRP == this.agentGroupForm.get('AGENT_GRP')?.value
      );
      tempNewConfigData.ACTIVE_TAG    = this.agentGroupForm.get('ACTIVE_TAG')?.value;
      tempNewConfigData.AGENT_GRP     = this.agentGroupForm.get('AGENT_GRP')?.value;
      tempNewConfigData.AGENT_GRP_DESC= this.agentGroupForm.get('AGENT_GRP_DESC')?.value;
      tempNewConfigData.EDIT_PREM_TAG = this.agentGroupForm.get('EDIT_PREM_TAG')?.value;
      tempNewConfigData.HIDE_COMM_TAG = this.agentGroupForm.get('HIDE_COMM_TAG')?.value;
      tempNewConfigData.REMARKS       = this.agentGroupForm.get('REMARKS')?.value;
      tempNewConfigData.USER_ID       = this.userId;
    } catch(e) {
      
    }
  }

  updateDbData() {
    try {
      if(this.tempUpdateData=='' || this.tempUpdateData==null) {
        this.tempUpdateInserter();
      } else {
        let tempDbData = this.tempUpdateData.find(
          (agentGroup: { AGENT_GRP: any }) => agentGroup.AGENT_GRP == this.agentGroupForm.get('AGENT_GRP')?.value
        );
        if(tempDbData=='' || tempDbData==null) {
          this.tempUpdateInserter();
        } else {
          tempDbData.ACTIVE_TAG    = this.agentGroupForm.get('ACTIVE_TAG')?.value;
          tempDbData.AGENT_GRP     = this.agentGroupForm.get('AGENT_GRP')?.value;
          tempDbData.AGENT_GRP_DESC= this.agentGroupForm.get('AGENT_GRP_DESC')?.value;
          tempDbData.EDIT_PREM_TAG = this.agentGroupForm.get('EDIT_PREM_TAG')?.value;
          tempDbData.HIDE_COMM_TAG = this.agentGroupForm.get('HIDE_COMM_TAG')?.value;
          tempDbData.REMARKS       = this.agentGroupForm.get('REMARKS')?.value;
          tempDbData.USER_ID       = this.userId;
        }
      }
    } catch(e) {
      
    }
  }

  deleteEnabler() {
    try {
      if(this.selectedRowData != null) {
        let tempDeleteFlag = this.tempAddData.filter((e: any) => {
          return e.AGENT_GRP == this.selectedRowData.AGENT_GRP
        });
        if(tempDeleteFlag=='' || tempDeleteFlag==null) {
          this.deleteDisabler = true;
        } else {
          this.deleteDisabler = false;
        }
      } else {
        this.deleteDisabler = true;
      }
      
    } catch(e) {
      
    }
  }

  tableInserter() {
    try {
      this.agentGroupTable.tblData.unshift({
        ACTIVE_TAG    : this.agentGroupForm.get('ACTIVE_TAG')?.value,
        AGENT_GRP     : this.agentGroupForm.get('AGENT_GRP')?.value,
        AGENT_GRP_DESC: this.agentGroupForm.get('AGENT_GRP_DESC')?.value,
        EDIT_PREM_TAG : this.agentGroupForm.get('EDIT_PREM_TAG')?.value,
        HIDE_COMM_TAG : this.agentGroupForm.get('HIDE_COMM_TAG')?.value,
        REMARKS       : this.agentGroupForm.get('REMARKS')?.value,
      });
      this.agentGroupTable.tblData = [...this.agentGroupTable.tblData];
    } catch(e) {
      
    }
  }

  tempAddInserter() {
    try {
      this.tempAddData.push({
        ACTIVE_TAG    : this.agentGroupForm.get('ACTIVE_TAG')?.value,
        AGENT_GRP     : this.agentGroupForm.get('AGENT_GRP')?.value,
        AGENT_GRP_DESC: this.agentGroupForm.get('AGENT_GRP_DESC')?.value,
        EDIT_PREM_TAG : this.agentGroupForm.get('EDIT_PREM_TAG')?.value,
        HIDE_COMM_TAG : this.agentGroupForm.get('HIDE_COMM_TAG')?.value,
        REMARKS       : this.agentGroupForm.get('REMARKS')?.value,
        USER_ID       : this.userId,
      });
    } catch(e) {
      
    }
  }

  tempUpdateInserter() {
    try {
      this.tempUpdateData.push({
        ACTIVE_TAG    : this.agentGroupForm.get('ACTIVE_TAG')?.value,
        AGENT_GRP     : this.agentGroupForm.get('AGENT_GRP')?.value,
        AGENT_GRP_DESC: this.agentGroupForm.get('AGENT_GRP_DESC')?.value,
        EDIT_PREM_TAG : this.agentGroupForm.get('EDIT_PREM_TAG')?.value,
        HIDE_COMM_TAG : this.agentGroupForm.get('HIDE_COMM_TAG')?.value,
        REMARKS       : this.agentGroupForm.get('REMARKS')?.value,
        USER_ID       : this.userId,
      });
    } catch(e) {
      
    }
  }

  formValidator() {
    try {
      if(this.agentGroupForm.invalid) {
        for(const field in this.agentGroupForm.controls) {
          const controlName = this.agentGroupForm.get(field);
          if(controlName?.errors?.required) {
            this.errorMessage = 'There are missing information. Please provide necessary information needed';
          }
          if(controlName?.errors?.agentGroupDuplicateCode) {
            this.errorMessage = 'Agent Group Code already exists. Please add a unique Agent Group Code.';
          }
        }
        this.formService.showFormMsg("agent-group-error-message", this.errorMessage, "E");
        return false;
      } else {
        this.formService.hideFormMsg("agent-group-error-message");
        return true;
      }
    } catch(e) {
      return true;
    }
  }

  duplicateAgentGroup(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      let agentGroupDuplicate  = this.agentGroupTable.tblData.filter(function(e: any) {
        return e.AGENT_GRP.trim() == control.value.trim();
      });
      if(agentGroupDuplicate!='') {
        return { "agentGroupDuplicateCode": { value: control.value } }
      }
      return null;
    }
  }

  formLoader() {
    try {
      this.agentGroupForm.get('AGENT_GRP')?.disable();
      this.agentGroupForm.patchValue({
        ACTIVE_TAG    : this.selectedRowData.ACTIVE_TAG,
        AGENT_GRP     : this.selectedRowData.AGENT_GRP,
        AGENT_GRP_DESC: this.selectedRowData.AGENT_GRP_DESC,
        EDIT_PREM_TAG : this.selectedRowData.EDIT_PREM_TAG,
        HIDE_COMM_TAG : this.selectedRowData.HIDE_COMM_TAG,
        LAST_UPDATE   : this.selectedRowData.LAST_UPDATE == "0000-00-00 00:00:00" ? "00-00-0000 00:00:00" : this.datePipe.transform(this.selectedRowData.LAST_UPDATE, 'MM/dd/YYYY h:mm:ss a', '+0000'),
        REMARKS       : this.selectedRowData.REMARKS,
        USER_ID       : this.selectedRowData.USER_ID,
      });
    } catch(e) {
      
    }
  }

  formClearer() {
    try {
      this.agentGroupForm.get('AGENT_GRP')?.enable();
      this.agentGroupForm.patchValue({
        ACTIVE_TAG    : 'A',
        AGENT_GRP     : '',
        AGENT_GRP_DESC: '',
        EDIT_PREM_TAG : 'N',
        HIDE_COMM_TAG : 'N',
        LAST_UPDATE   : '',
        REMARKS       : '',
        USER_ID       : '',
      });
      this.deleteEnabler();
    } catch(e) {
      
    }
  }

}
