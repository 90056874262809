import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { APICallService } from 'src/app/services/api-call.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

@Component({
  selector: 'deductible-lov-dialog',
  templateUrl: './deductible-lov-dialog.component.html',
  styleUrls: ['./deductible-lov-dialog.component.css']
})
export class DeductibleLovDialog {

  moduleId: string = 'BMM137';

  constructor(private apiCallService: APICallService,
    private jsonDataService: JsonDataService,
    private appMessageService: AppMessageService,
    private securityService: SecurityService,
    private userDetailService: UserDetailsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<DeductibleLovDialog>) {
    try {
      this.getDeductible();
    } catch (e) {
    console.error(e);
      
    }
  }

  deductibleTblConfig: any = {
    cols: [
      {
        key: "DEDUCTIBLE_CD",
        header: "Code",
        dataType: "string"
      },
      {
        key: "DEDUCTIBLE_TITLE",
        header: "Deductible",
        dataType: "string"
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15
  }

  getDeductible() {
    try {
      this.deductibleTblConfig.loading = true;
      this.jsonDataService.contorlLoading(true);
      let lineCd = this.data.lineCd;
      let sublineCd = this.data.sublineCd;
      if (lineCd && sublineCd) {
        const params = {
          queryParams:{
            lineCd: lineCd,
            sublineCd: sublineCd
          },
          moduleId: this.moduleId,
          userId: this.userDetailService.userId,
          type: "LOV"
        }
        this.apiCallService.getDeductible(params).subscribe({
          next: (data: any) => {
            this.securityService.checkRequestKeyResponse(data, ()=>{
              this.securityService.hasValidCsrfToken(data, ()=>{
                let content = JSON.parse(this.jsonDataService.decrypt(data.response));
                if (data.status === "SUCCESS") {
                  let filteredData = content.filter((d: any) => (d.ACTIVE_TAG == 'A'));
                  this.jsonDataService.contorlLoading(false);
                  this.deductibleTblConfig.tblData = filteredData;
                  this.deductibleTblConfig.loading = false;
                } else {
                  this.jsonDataService.contorlLoading(false);
                  this.deductibleTblConfig.loading = false;
                  this.appMessageService.showAppMessage(data.message, "error");
                }
              });
            })
          },
          error: (e: any) => {
            this.jsonDataService.contorlLoading(false);
            this.deductibleTblConfig.loading = false;
          }
        });
      }else{
        this.jsonDataService.contorlLoading(false);
        this.deductibleTblConfig.tblData = [];
        this.deductibleTblConfig.loading = false;
      }
    } catch (e) {
    console.error(e);
      
    }
  }

  onRowClick(ev: any) {
    try {
      this.data = ev;
    } catch (e) {
    console.error(e);
      
    }
  }

  onClose() {
    try {
      if (this.data == '' || this.data == null) {
        this._dialogRef.close({ content: '' });
      } else {
        this._dialogRef.close({ content: this.data, moduleId: this.moduleId });
      }
    } catch (e) {
    console.error(e);
      
    }
  }

  onBack() {
    try {
      this._dialogRef.close({ content: '', option: 'back' });
    } catch (e) {
    console.error(e);
      
    }
  }
}