import { Component, Inject, OnInit } from '@angular/core';
import { APICallService } from 'src/app/services/api-call.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { SecurityService } from 'src/app/services/security.service';
import { JsonDataService } from 'src/app/services/json-data.service';

@Component({
  selector: 'vehicle-maintentance-select-model-make',
  templateUrl: './vehicle-maintentance-select-model-make.component.html',
  styleUrls: ['../vehicle-maintenance.component.css']
})
export class VehicleMaintentanceSelectModelMakeComponent implements OnInit {

  constructor(
    private api: APICallService,
    private _dialogRef: MatDialogRef<VehicleMaintentanceSelectModelMakeComponent>,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService,
    @Inject(MAT_DIALOG_DATA) public fromMainData: any
  ) { }

  ngOnInit(): void {
    this.getVehicleModelOrMake();
  }

  private vehicleModuleId: string = 'BMM025';
  private tblConfigModel: any = {
    cols: [
      {
        key: "CAR_COMPANY",
        header: "Make",
        dataType: "string",
        width: '35%',
      },
      {
        key: "MAKE_CD",
        header: "Model Code",
        dataType: "string",
        disabled: false,
        width: '25%',
      },
      {
        key: "MAKE_DESC",
        header: "Model",
        dataType: "string",
        disabled: false
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    //tblCaption: "Vehicles",
    totalRecords: 15,
    loading: false,
  }

  private tblConfigMake: any = {
    cols: [
      {
        key: "CAR_COMPANY_CD",
        header: "Make Code",
        dataType: "string"
      },
      {
        key: "CAR_COMPANY",
        header: "Make",
        dataType: "string",
        disabled: false
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    //tblCaption: "Vehicles",
    totalRecords: 15
  }

  public outData: any;
  dataTableConfig: any;

  onRowClick(ev: any) {
    
    this.outData = ev;
  }


  getVehicleModelOrMake() {
    try {
      if (this.fromMainData.table == "MODEL") {
        this.dataTableConfig = this.tblConfigModel;
        this._jDS.contorlLoading(true);
        this.dataTableConfig.loading = true;
        this.api.getAllVehiclesMake({
          moduleId: this.vehicleModuleId,
          userId: this._userDetailService.userId,
          type: "MODULE",
        }).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                if (data.status === "SUCCESS") {
                  let content = JSON.parse(this._jDS.decrypt(data?.response));
                  let rows = content?.data ?? [];
                  rows = this.filterAllActive(rows);
                  if (this.fromMainData.makeCode == null)
                    this.tblConfigModel.tblData = rows;
                  else {
                    this.tblConfigModel.tblData = rows.filter((d: any) => {
                      return +d.CAR_COMPANY_CD === +this.fromMainData.makeCode
                    });
                    if (this.tblConfigModel.tblData.length === 1) {
                      this.outData = this.tblConfigModel.tblData[0];
                      this.onClose('OK');
                    }
                  }
                }
                this._jDS.contorlLoading(false);
                this.dataTableConfig.loading = false;
              });
            });
          },
          error: (data: any) => {
            console.error(data);
            this._securityService.checkRequestKeyResponse(data, () => {
              this.dataTableConfig.loading = false;
              this._jDS.contorlLoading(false);
            });
          },
        });
      }
      if (this.fromMainData.table == "MAKE") {
        this.dataTableConfig = this.tblConfigMake;
        this._jDS.contorlLoading(true);
        this.dataTableConfig.loading = true;
        this.api.getAllVehiclesCompany({
          moduleId: this.vehicleModuleId,
          userId: this._userDetailService.userId,
          type: "MODULE",
        }).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                if (data.status === "SUCCESS") {
                  let content = JSON.parse(this._jDS.decrypt(data?.response));
                  let rows = content?.data ?? [];
                  this.tblConfigMake.tblData = this.filterAllActive(rows);
                }
                this.dataTableConfig.loading = false;
                this._jDS.contorlLoading(false);
              });
            });
          },
          error: (data: any) => {
            console.error(data);
            this._securityService.checkRequestKeyResponse(data, () => {
              this.dataTableConfig.loading = false;
              this._jDS.contorlLoading(false);
            });
          },
        });
      }
    }
    catch (e) {
    console.error(e);
      console.log("Error @ getVehicleModelOrMake() vehicle-mtn-lov");
    }
  }

  filterAllActive(d: any) {
    try {
      return d.filter((d: any) => {
        return d.ACTIVE_TAG === "A";
      });
    }
    catch (e) {
    console.error(e);
      console.log("Error @ filterAllActive() vehicle-mtn-lov");
      return d;
    }
  }

  onClose(str: string) {
    this._dialogRef.close({ table: this.fromMainData.table, content: this.outData, button: str });
  }

}
