import { Component, OnInit } from '@angular/core';

import { APICallService } from 'src/app/services/api-call.service'
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { AppMessageService } from 'src/app/services/app-message.service';

import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';

import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { SecurityService } from 'src/app/services/security.service';

@Component({
  selector: 'reason-cancellation-maintenance',
  templateUrl: './reason-cancellation-maintenance.component.html',
  styleUrls: ['./reason-cancellation-maintenance.component.css']
})
export class ReasonCancellationMaintenanceComponent implements OnInit, LeaveConfirmation {

  datePipe = new DatePipe('en-us');

  addDisabled : boolean = true;
  saveDisabled : boolean = true;
  deleteDisabled : boolean = true;
  sublineDisabled : boolean = true;
  tranPosted : boolean = false;
  noLoadingInProcess : boolean = true;
  validated : boolean = false;
  submitted : boolean = false;
  isReasonSelected : boolean = false;
  isSuccessSaved : boolean = false;

  sublineField: string = '';
  currentUser: string = this.userDetailService.userId;
  rowClickedData: any;
  moduleId: string = 'BMM087';

  effDate = new FormControl(new Date());

  tempAddedData: any = [];
  tempUpdateData: any = [];
  
  reasonCancellationForm = this.fb.group({
    reasonCd: [null],
    reasonCode: [null],
    activeTag: ['A', [Validators.required]],
    reason: [null, [Validators.required]],
    remarks: [null],
    lastUser: [null],
    lastUpdate: [null],
    mode: [null]
  });
  
  constructor(
    private _APICallService: APICallService, 
    public _Dialog: MatDialog, 
    private fb: FormBuilder, 
    private formService: FormService, 
    private appMessageService: AppMessageService,
    private jsonDataService: JsonDataService,
    private userDetailService: UserDetailsService,
    private securityService: SecurityService
  ) { }
  
  canDeactivate(): Observable<boolean> | boolean {
    try {
      if(this.tempAddedData!='' || this.tempUpdateData!='') {
        return false;
      } else {
        return true;
      }
    } catch(e) {
      return true;
    }
  }
  
  tblConfigReasonCancellation: any = {
    cols: [
      {
        key: "REASON_CODE",
        header: "Reason Code",
        dataType: "string",
        width: '20%'
      },
      {
        key: "REASON_DESC",
        header: "Reason",
        dataType: "string",
        disabled: false
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        disabled: true,
        width: '55px'
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15
  }

  ngOnInit(): void {
    try {
      this._APICallService.refreshNeeded$
      .subscribe(() => {
        this.getReasonCancellation();
      })
      this.getReasonCancellation();
    }
    catch(e) {
      
    }
  }

  initOnGet(): void {
    try {
      this._APICallService.refreshNeeded$
      .subscribe(() => {
        this.getReasonCancellation();
      })
      this.getReasonCancellation();
    }
    catch(e) {
      
    }
  }

  getReasonCancellation() {
    try {
      this.tblConfigReasonCancellation.loading = true;
      this.jsonDataService.contorlLoading(true);
      this._APICallService.getReasonCancellation({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE"
      }).subscribe((data: any) => {
        this.securityService.checkRequestKeyResponse(data, () => {
          this.securityService.hasValidCsrfToken(data, () => {
            if (data.status === "SUCCESS") {
              data = JSON.parse(this.jsonDataService.decrypt(data.response));
              this.jsonDataService.contorlLoading(false);
              this.tblConfigReasonCancellation.tblData = data;
              this.tblConfigReasonCancellation.loading = false;
            } else {
                this.jsonDataService.contorlLoading(false);
                this.tblConfigReasonCancellation.loading = false;
                this.appMessageService.showAppMessage(data.message, "error");
            }
          });
        });
      });
    }
    catch (e) {
    console.error(e);
      
    }
  }

  insertReasonCancellation() {
    try {
      this.tranPosted = true;
      this.validateInsert();
      if (this.validated) {
        this.addFormToTable();
      }
    }
    catch(e) {
      
    }
  }

  addFormToTable() {
    try {
      this.reasonCancellationForm.patchValue({
        activeTag: this.reasonCancellationForm.get('activeTag')?.value,
        lastUser: this.currentUser,
        lastUpdate: new Date(),
      });
      let formToData: any = { // this.datePipe.transform(new Date(), 'MM/dd/yyyy')
        ACTIVE_TAG: this.reasonCancellationForm.get('activeTag')?.value,
        REASON_DESC: this.reasonCancellationForm.get('reason')?.value,
        REMARKS: this.reasonCancellationForm.get('remarks')?.value,
        LAST_UPDATE: this.datePipe.transform(new Date(this.reasonCancellationForm.get('lastUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        LAST_USER: this.reasonCancellationForm.get('lastUser')?.value,
        MODE: 'I'
      }
      this.tblConfigReasonCancellation.tblData.unshift(formToData);
      this.tblConfigReasonCancellation.tblData = [...this.tblConfigReasonCancellation.tblData];
      this.tempAddedData.push(formToData);
      this.resetFormValues();
      // this.appMessageService.showAppMessage("Reason for cancellation added!", "success");
    }
    catch(e) {
      
    }
  }

  updateReasonCancellation() {
    try {
      this.tranPosted = true;
      this.validateUpdate();
      if (this.validated) {
        this.updateFormTable();
      }
    }
    catch(e) {
      
    }
  }

  updateFormTable() {
    try {
      let tempInsertChecker = this.tempAddedData.find(
        (r: { REASON_CD: any; }) => r.REASON_CD == this.reasonCancellationForm.get('reasonCd')?.value);
      let tempUpdateChecker = this.tempUpdateData.find(
        (r: { REASON_CD: any; }) => r.REASON_CD == this.reasonCancellationForm.get('reasonCd')?.value);
      
      this.tableDataUpdate();
      if(tempInsertChecker!=null && tempInsertChecker!='') {
        tempInsertChecker.ACTIVE_TAG = this.reasonCancellationForm.get('activeTag')?.value;
        tempInsertChecker.REASON_DESC = this.reasonCancellationForm.get('reason')?.value;
        tempInsertChecker.REMARKS = this.reasonCancellationForm.get('remarks')?.value;
        tempInsertChecker.LAST_USER = this.reasonCancellationForm.get('lastUser')?.value;
        tempInsertChecker.MODE = 'I';
      } 
      else if(tempUpdateChecker!=null && tempUpdateChecker!='') {
        tempUpdateChecker.ACTIVE_TAG = this.reasonCancellationForm.get('activeTag')?.value;
        tempUpdateChecker.REASON_DESC = this.reasonCancellationForm.get('reason')?.value;
        tempUpdateChecker.REMARKS = this.reasonCancellationForm.get('remarks')?.value;
        tempUpdateChecker.LAST_USER = this.reasonCancellationForm.get('lastUser')?.value;
        tempUpdateChecker.MODE = 'U';
      }
      else {
        this.tempUpdateData.push(this.formValueToData());
      }
      this.saveDisabled = false;
      this.resetFormValues();
      // this.appMessageService.showAppMessage("Reason for cancellation updated!", "success");
    }
    catch(e) {
      
    }
  }

  tableDataUpdate() {
    try {
      this.reasonCancellationForm.patchValue({
        activeTag: this.reasonCancellationForm.get('activeTag')?.value,
        userID: this.currentUser,
        lastUserUpdate: new Date()
      });
      let updateIndex = this.tblConfigReasonCancellation.tblData.indexOf(this.rowClickedData);
      this.tblConfigReasonCancellation.tblData[updateIndex] = {
        REASON_CD: this.reasonCancellationForm.get('reasonCd')?.value,
        REASON_CODE: this.reasonCancellationForm.get('reasonCode')?.value,
        ACTIVE_TAG: this.reasonCancellationForm.get('activeTag')?.value,
        REASON_DESC: this.reasonCancellationForm.get('reason')?.value,
        REMARKS: this.reasonCancellationForm.get('remarks')?.value,
        LAST_UPDATE: this.datePipe.transform(new Date(this.reasonCancellationForm.get('lastUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        LAST_USER: this.reasonCancellationForm.get('lastUser')?.value,
        MODE: 'U'
      };
      this.tblConfigReasonCancellation.tblData = [...this.tblConfigReasonCancellation.tblData];
    }
    catch(e) {
      
    }
  }

  formValueToData() {
    try {
      let formToData: any = { 
        REASON_CD: this.reasonCancellationForm.get('reasonCd')?.value,
        ACTIVE_TAG: this.reasonCancellationForm.get('activeTag')?.value,
        REASON_DESC: this.reasonCancellationForm.get('reason')?.value,
        REMARKS: this.reasonCancellationForm.get('remarks')?.value,
        LAST_UPDATE: this.datePipe.transform(new Date(this.reasonCancellationForm.get('lastUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        LAST_USER: this.reasonCancellationForm.get('lastUser')?.value,
        MODE: 'U'
      };
      return formToData;
    }
    catch(e) {
      
    }
  }

  toSave() {
    try {
      this.noLoadingInProcess = false;
      this.jsonDataService.contorlLoading(true);
      this.formService.showFormLoader(null, "generate-reason-cancellation-form", "Please wait ...", null, null);
      this._APICallService.saveReasonCancellation(this.tempAddedData).subscribe({
        next: (data: any) => {
            this.securityService.checkRequestKeyResponse(data, () => {
              this.securityService.hasValidCsrfToken(data, () => {
                if(data.status == 'SUCCESS' && this.tranPosted) {
                  this._APICallService.saveReasonCancellation(this.tempUpdateData).subscribe({
                      next: (res: any) => {
                          this.securityService.checkRequestKeyResponse(res, () => {
                            this.securityService.hasValidCsrfToken(res, () => {
                              if(res.status == 'SUCCESS' && this.tranPosted) {
                                this.openDialog('SUCCESS', 'Reason for Cancellation details Successfully saved!');
                                this.tempAddedData = [];
                                this.tempUpdateData = [];
                                this.initOnGet();
                                this.resetFormValues();
                                this.jsonDataService.contorlLoading(false);
                              } else {
                                this.openDialog('ERROR', 'An error has occured while saving details for Reason for Cancellation');
                              }
                            });
                          });
                      },
                      error: () => {
                        this.openDialog('ERROR', 'An error has occured while saving details for Reason for Cancellation');
                      }
                  });
                } else {
                  this.openDialog('ERROR', 'An error has occured while saving details for Reason for Cancellation');
                }
              });
            });
        },
        error: () => {
          this.openDialog('ERROR', 'An error has occured while saving details for Reason for Cancellation');
        }
      });
    }
    catch(e) {
      
    }
  }

  deleteReasonCancellation() {
    try {
      let clickData = this.rowClickedData;
      this.tblConfigReasonCancellation.tblData = this.tblConfigReasonCancellation.tblData.filter(
        function(e: any) {
          return e.REASON_DESC != clickData.REASON_DESC;
        }
      );
      this.tempAddedData = this.tempAddedData.filter(
        function(e: any) {
          return e.REASON_DESC != clickData.REASON_DESC;
        }
      );
      this.resetFormValues();
    }
    catch(e) {
      
    }
  }

  saveChecker() {
    try {
      if((this.tempAddedData==null || this.tempAddedData=='') && (this.tempUpdateData==null || this.tempUpdateData=='')) {
        return true;
      }
      else {
        return false;
      }
    }
    catch(e) {
      return true;
    }
  }

  deleteChecker(ev: any) {
    try {
      let tempDataFlag;
      tempDataFlag = this.tempAddedData.filter(function(e: any) {
        return e.QUESTION==ev.QUESTION;
      });
      if(tempDataFlag==''||tempDataFlag==null) {
        return true;
      } else {
        return false;
      }
    }
    catch(e) {
      return true;
    }
  }

  onRowClick(ev: any) {
    try {    
      if(ev!=null) {
        this.rowClickedData = ev;
        this.reasonCancellationForm.get('reasonCode')?.disable();
        this.loadFormValues();
        this.saveDisabled = this.saveChecker();
        this.deleteDisabled = this.deleteChecker(ev);
      }
      else {
        this.resetFormValues();
      }
    }
    catch(e) {
      
    }
  }

  loadFormValues() {
    try {
      this.reasonCancellationForm.patchValue({
        reasonCd: this.rowClickedData.REASON_CD,
        reasonCode: this.rowClickedData.REASON_CODE,
        activeTag: this.rowClickedData.ACTIVE_TAG,
        reason: this.rowClickedData.REASON_DESC,
        remarks: this.rowClickedData.REMARKS,
        lastUser: this.rowClickedData.LAST_USER,   
        lastUpdate: this.rowClickedData.LAST_UPDATE
      });
      this.addDisabled = true;
      this.isReasonSelected = true;
    }
    catch(e) {
      
    }
  }

  resetFormValues() {
    try {
      this.reasonCancellationForm.patchValue({
        reasonCd: null,
        reasonCode: null,
        activeTag: 'A',
        reason: null,
        remarks: null,
        lastUser: null,   
        lastUpdate: null
      });

      this.addDisabled = false;
      this.saveDisabled = this.saveChecker();
      this.deleteDisabled = true;
      this.isReasonSelected = false;

      this.rowClickedData = '' || null;
    }
    catch (e) {
    console.error(e);
      
    }
  }

  openDialog(title: any, message: any) {
    try {
      this.noLoadingInProcess = true;
      this.formService.hideFormLoader("generate-reason-cancellation-form");
      this._Dialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        } 
      });
    } catch(e) {
      
    }
  }

  validateInsert() {
    try {
      this.validated = false;
      if (!this.requiredFieldCheck()){
        // this.openDialog('ERROR', 'Kindly fill-out required fields.');
        setTimeout(()=>{                           //10 secs countdown
          this.formService.hideFormMsg("reason-cancellation-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
      
    }
  } 

  validateUpdate() {
    try {
      this.validated = false;
      if (!this.requiredFieldCheck()){
        // this.openDialog('ERROR', 'There are missing information. Please provide the necessary information needed.');
        setTimeout(()=>{                          
          this.formService.hideFormMsg("reason-cancellation-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
      
    }
  }

  requiredFieldCheck() {
    try {
      if( 
        (this.reasonCancellationForm.get('activeTag')?.value ?? '').trim().length === 0 || 
        (this.reasonCancellationForm.get('reason')?.value ?? '').trim().length === 0
      ) {
        this.formService.showFormMsg("reason-cancellation-error-message", 'There are missing information. Please provide the necessary information needed.', "E");
        return false;
      }
      else {
        return true;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }

}
