<div class="mtn-container">
    <div class="mtn-title">
        <p>{{ moduleName?.toUpperCase() }}</p>
    </div>
    <div *ngIf="showForm" class="form-content">
        <form [formGroup]="forms.filterLovForm" class="form">
            <div class="form-container-grid top-form">
                <div class="field required">
                    <label>Line</label>
                </div>
                <div class="">
                    <input formControlName="lineCdDisp">
                    <button matSuffix class="btn btn-search" type="button" (click)="openLov['line']()" [disabled]="btns.filterLov.lineLovSearchDisabled"
                         >
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
                <div class="field required">
                    <label>Subline</label>
                </div>
                <div class="">
                    <input formControlName="sublineCdDisp">
                    <button matSuffix class="btn btn-search" type="button" (click)="openLov['subline']()" [disabled]="btns.filterLov.sublineLovSearchDisabled"
                         >
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
                <div class="field required">
                    <label>Plan</label>
                </div>
                <div class="">
                    <input formControlName="planDisp">
                    <button matSuffix class="btn btn-search" type="button" (click)="openLov['plan']()" [disabled]="btns.filterLov.planLovSearchDisabled"
                         >
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
                <div class="field">
                    <label>Client Type Group</label>
                </div>
                <div class="">
                    <input formControlName="clientTypeDisp">
                    <button matSuffix class="btn btn-search" type="button" (click)="openLov['clientType']('FILTER')" [disabled]="btns.filterLov.clientTypeLovSearchDisabled"
                         >
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
            </div>
        </form>
    </div>
    <osp-table [tblConfig]="productCodetblConfig" (onRowClick)="onRowClick($event)" [selectedRow]="selectedRow"></osp-table>
    <div id="product-code-maintenance-loading">
        <div class="form-loader"></div>
    </div>
    <div *ngIf="showForm" class="form-content">
        <form [formGroup]="forms.productCodeForm" class="form">
            <div id="product-code-error-message"></div>
            <div class="form-container-grid first">
                <div class="field required">
                    <label>Product Code</label>
                </div>
                <div class="">
                    <input (input)="autoUppercase($event)" formControlName="productCode">
                </div>
                <div class="field required">
                    <label>Active</label>
                </div>
                <div class="">
                    <select formControlName="active">
                        <option *ngFor="let activeType of selections.activeTypes" [value]="activeType.cd">
                            {{activeType.name}}
                        </option>
                    </select>
                </div>
                <div class="field required">
                    <label>User Type</label>
                </div>
                <div class="">
                    <select formControlName="userType">
                        <option *ngFor="let userType of selections.userTypes" [ngValue]="userType.cd">
                            {{userType.name}}
                        </option>
                    </select>
                </div>
                <div class="field required">
                    <label>User Level</label>
                </div>
                <div class="">
                    <select formControlName="userLevel">
                        <option *ngFor="let userLevel of selections.userLevels" [ngValue]="userLevel.cd">
                            {{userLevel.name}}
                        </option>
                    </select>
                </div>
                <div class="field required">
                    <label>Client Type Group</label>
                </div>
                <div class="">
                    <input formControlName="clientTypeDisp">
                    <button matSuffix class="btn btn-search" type="button" (click)="openLov['clientType']('FORM')"
                        [disabled]="btns.productCode.formLovSearchDisabled || btns.productCode.clientTypeLovSearchDisabled" >
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
                <div class="field">
                    <label>Agent Group</label>
                </div>
                <div class="">
                    <select formControlName="agentGrp">
                        <option *ngFor="let agentGrp of selections.agentGrps" [ngValue]="agentGrp.cd">
                            {{agentGrp.name}}
                        </option>
                    </select>
                </div>
                <div class="field required">
                    <label>Effective Date</label>
                </div>
                <div class="">
                    <input date-input [matDatepicker]="effDatePicker" [min]="minEffDate" [max]="maxEffDate" formControlName="effectiveDate" placeholder="MM/DD/YYYY"> 
                    <mat-datepicker-toggle class="mtn-datepicker" matSuffix [for]="effDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #effDatePicker></mat-datepicker>
                </div>
                <div class="field">
                    <label>Expiry Date</label>
                </div>
                <div class="">
                    <input date-input [matDatepicker]="exDatePicker" [min]="minExpDate" formControlName="expiryDate" placeholder="MM/DD/YYYY">
                    <mat-datepicker-toggle matSuffix [for]="exDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #exDatePicker></mat-datepicker>
                </div>
                <div class="field required">
                    <label>Total Count</label>
                </div>
                <div class="">
                    <input type="number" min="0" posNumInput formControlName="totalCount">
                </div>
                <div class="field">
                    <label>Used Count</label>
                </div>
                <div class="">
                    <input formControlName="usedCount" >
                </div>
            </div>
            <div class="mtn-line"></div>
            <div class="form-container-grid">
                <div class="field">
                    <label>Project Code</label>
                </div>
                <div class="">
                    <input formControlName="projectDisp">
                    <button matSuffix class="btn btn-search" type="button" (click)="openLov['project']()"
                        [disabled]="btns.productCode.formLovSearchDisabled" >
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
                <div class="field">
                    <label>Agent</label>
                </div>
                <div class="">
                    <input formControlName="agentDisp">
                    <button matSuffix class="btn btn-search" type="button" (click)="openLov['agent']()"
                        [disabled]="btns.productCode.formLovSearchDisabled" >
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
                <div class="field">
                    <label>BMA</label>
                </div>
                <div class="">
                    <input formControlName="bmaDisp">
                    <button matSuffix class="btn btn-search" type="button" (click)="openLov['bma']()"
                        [disabled]="btns.productCode.formLovSearchDisabled" >
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
                <div class="field">
                    <label>Referrer</label>
                </div>
                <div class="">
                    <input formControlName="referrorDisp">
                    <button matSuffix class="btn btn-search" type="button" (click)="openLov['referror']()"
                        [disabled]="btns.productCode.formLovSearchDisabled" >
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
                <div class="field">
                    <label>Source Code</label>
                </div>
                <div class="">
                    <input formControlName="srcCdDisp">
                    <button matSuffix class="btn btn-search" type="button" (click)="openLov['src']()"
                        [disabled]="btns.productCode.formLovSearchDisabled" >
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
                <div class="field ">
                    <label>Source Extension</label>
                </div>
                <div class="">
                    <input formControlName="srcExtDisp">
                    <button matSuffix class="btn btn-search" type="button" (click)="openLov['srcExt']()"
                        [disabled]="btns.productCode.formLovSearchDisabled" >
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
                <div class="field">
                    <label>Remarks</label>
                </div>
                <div class="span">
                    <input formControlName="remarks">
                </div>
                <div class="field">
                    <label>Create User</label>
                </div>
                <div class="">
                    <input formControlName="createUser">
                </div>
                <div class="field">
                    <label>Create Date</label>
                </div>
                <div class="">
                    <input formControlName="createDate">
                </div>
                <div class="field">
                    <label>Last Update User</label>
                </div>
                <div class="">
                    <input formControlName="lastUpdateUser">
                </div>
                <div class="field">
                    <label>Last Update</label>
                </div>
                <div class="">
                    <input formControlName="lastUpdate">
                </div>
            </div>
        </form>
        <div class="btn-grp">
            <button *ngIf="btns.productCode.updateDisabled" class="btn btn2" type="button" (click)="onAdd()" [disabled]="btns.productCode.addDisabled" >ADD</button>
            <button *ngIf="!btns.productCode.updateDisabled" class="btn btn2" type="button" (click)="onUpdate()" >UPDATE</button>
            <button class="btn btn2" type="button" [disabled]='btns.productCode.deleteDisabled' (click)="onDelete()" >DELETE</button>
        </div>
        <div class="mtn-title"></div>
        <div class="btn-grp">
            <button class="btn btn2" type="button" [disabled]='btns.productCode.saveDisabled' (click)="onSave()" >SAVE</button>
        </div>
    </div>
</div>