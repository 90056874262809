<div class="mtn-container">
    <div class="mtn-title">
        <p>VEHICLE MAINTENANCE (MODEL)</p>
    </div>
    <osp-table [tblConfig]="tblConfig" (onRowClick)="onRowClick($event)"></osp-table>
    <div class="form-container" id="vehicle-form-model">
        <div class="form-loader"></div>
    </div>
    <div *ngIf="showForm" class="form-content">
        <form class="form" [formGroup]="vehicleModelForm" novalidate>
            <div id="vehicle-model-error-message"></div>
            <div class="form-container-grid-2">
                <div class="field required">
                    <label>Make</label>
                </div>
                <div class="">
                    <input formControlName="carCompany" readonly>
                    <button matSuffix class="btn btn-search" type="button" (click)="openMakeSelection()"
                        [disabled]="tempDataRow">
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
                <div class="field">
                    <label>Model Code</label>
                </div>
                <div class="">
                    <input formControlName="makeCD" readonly >
                </div>
                <div class="field">
                    <label>Active</label>
                </div>
                <div class="">
                    <input class="" type="checkbox" formControlName="active" >
                </div>
                <div class="field required">
                    <label>Model</label>
                </div>
                <div class="">
                    <input class="uppercase" formControlName="makeDesc">
                </div>
                <div class="field">
                    <label>Remarks</label>
                </div>
                <div class="">
                    <input formControlName="remarks" >
                </div>
                <div class="field">
                    <label>Last Update User</label>
                </div>
                <div class="">
                    <input formControlName="userId" readonly>
                </div>
                <div class="field">
                    <label>Last Update</label>
                </div>
                <div class="">
                    <input formControlName="lastUpdate" readonly>
                </div>
            </div>
        </form>
        <div class="btn-grp-2 btn-grp-2-l">
            <button *ngIf="updateBtnDisabled" class="btn btn2" type="button" (click)="onAdd()" >ADD</button>
            <button *ngIf="!updateBtnDisabled" class="btn btn2" type="button" (click)="onUpdate()" >UPDATE</button>
            <button class="btn btn2" type="button" [disabled]='deleteBtnDisabled' (click)="onDelete()" >DELETE</button>
        </div>
        <div class="mtn-title"></div>
        <div class="btn-grp-2 btn-grp-2-l">
            <button class="btn btn2" type="button" [disabled]='saveBtnDisabled' (click)="onSave()" >SAVE</button>
            <button class="btn btn2" type="button" (click)="onReturn()">RETURN</button> 
        </div>
    </div>
</div>