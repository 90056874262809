import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

@Component({
  selector: 'agent-lov',
  templateUrl: './agent-lov.component.html',
  styleUrls: ['./agent-lov.component.css']
})
export class AgentLovComponent implements OnInit {

  private agentModuleId: string = 'BMM017';
  private selectedData: any;
  public titleLov: string = "Agent".toUpperCase();

  agentTable: any = {
    cols: [
      {
        key: "AGENT_CD",
        header: "Agent Code",
        dataType: "string"
      },
      {
        key: "AGENT_NAME",
        header: "Agent Name",
        dataType: "string"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  };

  constructor(
    private jsonDataService: JsonDataService,
    private dialogRef: MatDialogRef<AgentLovComponent>,
    private _api: APICallService,
    private securityService: SecurityService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    @Inject(MAT_DIALOG_DATA) public fromMainData: any,
  ) {
    this.getAgentData();
  }

  ngOnInit(): void {
  }

  onRowClick(ev: any) {
    try {
      this.selectedData = ev;
    } catch (e) {
      console.error(e);
    }
  }

  private getAgentData(): void {
    try {
      this.agentTable.loading = true;
      this.jsonDataService.contorlLoading(true);
      this._api.getAllAgents({
        lineCd: this.fromMainData.lineCd,
        moduleId: this.fromMainData.moduleId,
        userId: this.userDetailService.userId,
        type: "LOV"
      }).subscribe({
        next: (data: any) => {
          this.securityService.checkRequestKeyResponse(data, () => {
            this.securityService.hasValidCsrfToken(data, ()=>{
              let content = JSON.parse(this.jsonDataService.decrypt(data.response));
              // if (content.moduleId === this.agentModuleId) {
              this.jsonDataService.contorlLoading(false);
              // this.agentTable.tblData = content.data;
              if (this.fromMainData.moduleId == 'BMM185' || this.fromMainData.moduleId == 'BMM186') {
                this.agentTable.tblData = content.filter((e: any) => e.ACTIVE_TAG == 'A' && e.AGENT_TYPE == 'D');
              } else {
                this.agentTable.tblData = content.filter((e: any) => e.ACTIVE_TAG == 'A');
              }
              this.agentTable.loading = false;
              // } else {
              //   this.agentTable.loading = false;
              //   this.jsonDataService.contorlLoading(false);
              //   this.appMessageService.showAppMessage("You do not have the correct privilege to access the data. Kindly contact your administrator for assistance.", "error");
              // }
            });
          });
        },
        error: (e: any) => {
          this.securityService.checkRequestKeyResponse(e, () => {
            this.agentTable.loading = false;
            this.jsonDataService.contorlLoading(false);
            this.appMessageService.showAppMessage(e, "error");
          });
        }
      });
    }
    catch (e) {
      console.error(e);
      this.agentTable.loading = false;
      this.jsonDataService.contorlLoading(false);
    }
  }

  onClose(str: string) {
    try {
      if (this.selectedData == '' || this.selectedData == null) {
        this.dialogRef.close({ content: '', button: str });
      }
      else {
        this.dialogRef.close({ content: this.selectedData, button: str });
      }
    } catch (e) {
      console.error(e);  
    }
  }

}
