import { Component, Inject, OnInit } from '@angular/core';
import { APICallService } from 'src/app/services/api-call.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContentObserver } from '@angular/cdk/observers';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';


@Component({
  selector: 'province-lov',
  templateUrl: './province-lov.component.html',
  styleUrls: ['./province-lov.component.css']
})
export class ProvinceLovDialogComponent implements OnInit {

  userTypeModuleId: string = 'BMM047';

  constructor(private apiCallService: APICallService,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<ProvinceLovDialogComponent>) { }
  
  ngOnInit(): void {
    this.getProvince();
  }


  // Variable Initializer
  issueSourceData: any = [];

  // Issue Source Initializer
  provinceTableConfig: any = {
    cols: [
      {
        key: "provinceCd",
        header: "Code",
        dataType: "number"
      },
      {
        key: "provinceName",
        header: "Province",
        dataType: "string"
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15
  }

  // Gets data for Issue Source Table
  getProvince() {
    try {
      this.provinceTableConfig.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.jsonDataService.contorlLoading(false);
      this.provinceTableConfig.loading = false;
      let provinceList = this.jsonDataService.data.province.filter((e: any) => e.countryCd==this.data.countryCd);
      this.provinceTableConfig.tblData = provinceList.sort(function(a:any, b:any){
        let aProvinceCd = Number(a.provinceCd);
        let bProvinceCd = Number(b.provinceCd);
        return aProvinceCd - bProvinceCd;
      });
    } 
    catch(e) {
      
    }
  }

  onRowClick(ev: any) {
    try {
      this.data = ev;
    }
    catch(e) {
      
    }
  }

  onClose() {
    try {
      if(this.data=='' || this.data==null){
        this._dialogRef.close({content: ''}); 
      } else {
        this._dialogRef.close({content: this.data, moduleId: this.data.moduleId});
      }
    }
    catch(e) {
      
    }
  }

  onBack() {
    try {
      this._dialogRef.close({content: '', option: 'back'});
    }
    catch(e) {
      
    }
  }

}

