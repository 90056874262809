<div class="mtn-container">
    <div class="mtn-title">
        <p>{{ moduleName?.toUpperCase() }}</p>
    </div>
    <div *ngIf="showForm" class="form-content">
        <form [formGroup]="forms.lineSublineLovForm" class="form">
            <div class="form-container-grid top-form">
                <div class="field required">
                    <label>Line</label>
                </div>
                <div class="">
                    <input formControlName="lineCdDisp">
                    <button matSuffix class="btn btn-search" type="button" (click)="openLov['line']()"
                        [disabled]="btns.rpaPerilMapping.lineSearchDisabled" >
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
                <div class="field required">
                    <label>Subline</label>
                </div>
                <div class="">
                    <input formControlName="sublineCdDisp">
                    <button matSuffix class="btn btn-search" type="button" (click)="openLov['subline']()"
                        [disabled]="btns.rpaPerilMapping.sublineSearchDisabled" >
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
            </div>
        </form>
    </div>
    <osp-table [tblConfig]="rpaPerilMappingTblConfig" (onRowClick)="onRowClick($event)" [selectedRow]="selectedRow"></osp-table>
    <div id="rpa-peril-mapping-maintenance-loading">
        <div class="form-loader"></div>
    </div>
    <div *ngIf="showForm" class="form-content">
        <form [formGroup]="forms.rpaPerilMappingForm" class="form">
            <div id="rpa-peril-mapping-error-message"></div>
            <div class="form-container-grid">
                <div class="field required">
                    <label>Sequence No</label>
                </div>
                <div class="">
                    <input posNumInput formControlName="seqNo" type="number" min="1">
                </div>
                <div class="field">
                    <label>BPI MS Code</label>
                </div>
                <div class="">
                    <input formControlName="bmCode" maxlength="8">
                </div>
                <div class="field required">
                    <label>Peril</label>
                </div>
                <div class="span">
                    <input formControlName="perilCdDisp" readonly>
                    <button matSuffix class="btn btn-search" type="button" (click)="openLov['peril']()"
                        [disabled]="btns.rpaPerilMapping.perilSearchDisabled">
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
                <div class="field">
                    <label>Last Update User</label>
                </div>
                <div class="">
                    <input formControlName="lastUpdateUser" >
                </div>
                <div class="field">
                    <label>Last Update</label>
                </div>
                <div class="">
                    <input formControlName="lastUpdate" >
                </div>
            </div>
        </form>
        <div class="btn-grp">
            <button *ngIf="btns.rpaPerilMapping.updateDisabled" class="btn btn2" type="button" (click)="onAdd()" [disabled]="btns.rpaPerilMapping.addDisabled" >ADD</button>
            <button *ngIf="!btns.rpaPerilMapping.updateDisabled" class="btn btn2" type="button" (click)="onUpdate()" >UPDATE</button>
            <button class="btn btn2" type="button" [disabled]='btns.rpaPerilMapping.deleteDisabled' (click)="onDelete()" >DELETE</button>
        </div>
        <div class="mtn-title"></div>
        <div class="btn-grp">
            <button class="btn btn2" type="button" [disabled]='btns.rpaPerilMapping.saveDisabled' (click)="onSave()" >SAVE</button>
        </div>
    </div>
</div>