import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';

@Component({
  selector: 'coverage-type-maintenance',
  templateUrl: './coverage-type-maintenance.component.html',
  styleUrls: ['./coverage-type-maintenance.component.css']
})
export class CoverageTypeMaintenanceComponent implements OnInit, LeaveConfirmation {

  private moduleId: string = 'BMM052';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc?.toUpperCase() ?? "";
  
  constructor(
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    public _formService: FormService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService) {
      this.userIdLoggedIn = this._userDetailService.userId ?? "";
    }

  ngOnInit(): void {
    this.getData.coverageType();
  }

  canDeactivate(): boolean | Observable<boolean> {
    if(this.tempData!='') {
      return false;
    } else {
      return true;
    }
  }

  public btns = {
    coverageType: {
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      addDisabled: true,
    },
  }

  public selections = {
    activeTypes: [
      { cd: 'A', name: 'Yes' },
      { cd: 'I', name: 'No' },
    ],
    renewTypes: [
      { cd: 'Y', name: 'Yes' },
      { cd: 'N', name: 'No' },
    ]
  }

  public showForm: boolean = true;

  private rowData: any;
  private tempDataRow: any;
  private errorMessage: string = "";
  private userIdLoggedIn: string;
  private tempData: any = [];

  public forms = {
    coverageTypeForm: this.fb.group({
      coverageTypeCd: [null],
      coverageTypeCdDisp: [{ value: '', disabled: 'true' }],
      coverageTypeDesc: [null, [Validators.required, Validators.maxLength(30), this.checkDuplicateCoverageTypeDesc(), this.noWhiteSpaceCheck()]],
      activeTag: ['A', [Validators.required]],
      renewTag: [null, [Validators.required]],
      bmCode: [null, [Validators.required, Validators.maxLength(8), this.noWhiteSpaceCheck()]],
      bmShortDesc: [null, [Validators.maxLength(10)]],
      remarks: [null, [Validators.maxLength(4000)]],
      userID: [{ value: '', disabled: 'true' }],
      lastUserUpdate: [{ value: '', disabled: 'true' }],
    }),
    resetCoverageTypeForm: () => {
      this.forms.coverageTypeForm.reset();
      this.forms.coverageTypeForm.patchValue({
        activeTag: 'A'
      });
      this.btns.coverageType.updateDisabled = this.btns.coverageType.deleteDisabled = true;
      this._formService.hideFormMsg("coverage-type-error-message");
      // this.rowData = null;
    }
  }

  private coverageTypeFormManipulate = {
    dbToForm: (data: any) => {
      return {
        coverageTypeCd: data.COVERAGE_TYPE_CD,
        coverageTypeCdDisp: data.COVERAGE_TYPE_CD_DISP,
        coverageTypeDesc: data.COVERAGE_TYPE_DESC,
        activeTag: data.ACTIVE_TAG,
        renewTag: data.RENEW_TAG,
        bmCode: data.BM_CODE,
        bmShortDesc: data.BM_SHORT_DESC,
        remarks: data.REMARKS,
        userID: data.USER_ID,
        lastUserUpdate: data.LAST_UPDATE,
      };
    },
    formToDb: (data: any, mode: any) => {
      return {
        COVERAGE_TYPE_CD: data.coverageTypeCd,
        COVERAGE_TYPE_CD_DISP: data.coverageTypeCd?.toString().padStart(2, 0) ?? null,
        COVERAGE_TYPE_DESC: data.coverageTypeDesc.trim(),
        ACTIVE_TAG: data.activeTag,
        RENEW_TAG: data.renewTag,
        BM_CODE: data.bmCode,
        BM_SHORT_DESC: data.bmShortDesc,
        REMARKS: data.remarks,
        USER_ID: this.userIdLoggedIn,
        LAST_UPDATE: null,
        MODE: mode
      };
    },
  }

  public coverageTypeTblConfig = {
    cols: [
      {
        key: "COVERAGE_TYPE_CD_DISP",
        header: "Coverage Type Code",
        dataType: "string",
        width: '25%'
      },
      {
        key: "COVERAGE_TYPE_DESC",
        header: "Coverage Type",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        width: '64px'
      }
    ],
    tblData: [] as any[],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  private getData = {
    coverageType: () => {
      try {
        this.coverageTypeTblConfig.tblData = [];
        this.coverageTypeTblConfig.loading = true;
        this._jDS.contorlLoading(true);
        this._api.getCoverageType({
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          type: "MODULE"
        }).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                let content = JSON.parse(this._jDS.decrypt(data.response));
                this._jDS.contorlLoading(false);
                this.coverageTypeTblConfig.tblData = content.data;
                this.btns.coverageType.addDisabled = false;
                this.coverageTypeTblConfig.loading = false;
              });
            });
          },
          error: (error: any) => {
            this.coverageTypeTblConfig.loading = false;
            this._jDS.contorlLoading(false);
          }
        });
      }
      catch (e) {
        console.error(e);
      }
    }
  }

  public onRowClick(ev: any) {
    try {
      ev.update = true;
      this.rowData = ev;
      this.populateFormFromTable();
    }
    catch (e) {
      this.forms.resetCoverageTypeForm();
      this.rowData = null;
      //this.indexRow = null;
    }
  }

  private populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.coverageType.updateDisabled = !data.update;
      this.btns.coverageType.deleteDisabled = !data.temp;
      this.tempDataRow = !data.temp;
      this.forms.coverageTypeForm.patchValue(this.coverageTypeFormManipulate.dbToForm(data));
    }
    catch (e) {
      console.error(e);
    }
  }

  public onAdd() {
    try {
      this._formService.hideFormMsg("coverage-type-error-message");
      if (this.forms.coverageTypeForm.valid) {
        let rowToBeAdded: { [key: string]: any } = this.coverageTypeFormManipulate.formToDb(this.forms.coverageTypeForm.value, 'I');
        rowToBeAdded.temp = true;
        this.tempData.push(rowToBeAdded);
        this.coverageTypeTblConfig.tblData = [rowToBeAdded, ...this.coverageTypeTblConfig.tblData];
        this.forms.resetCoverageTypeForm();
        this.btns.coverageType.saveDisabled = false;
      }
      else {
        this.showErrorValidator.coverageType();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public onDelete() {
    try {
      let clickData = this.rowData;
      this.coverageTypeTblConfig.tblData = this.coverageTypeTblConfig.tblData.filter((d: any) => {
        return d !== this.rowData;
      });
      this.tempData = this.tempData.filter(
        function(e: any) {
          return e.COVERAGE_TYPE_DESC != clickData.COVERAGE_TYPE_DESC;
        }
      );
      this.forms.resetCoverageTypeForm();
    }
    catch (e) {
      console.error(e);
    }
  }

  public onUpdate() {
    try {
      this._formService.hideFormMsg("coverage-type-error-message");
      
      if (this.forms.coverageTypeForm.valid) {
        let clickData = this.rowData;
        let toBeUpdatedIndex = this.coverageTypeTblConfig.tblData.indexOf(this.rowData);
        let coverageTypeCdRowValue = this.rowData.COVERAGE_TYPE_CD;
        let rowToBeUpdated: { [key: string]: any } = this.coverageTypeFormManipulate.formToDb(this.forms.coverageTypeForm.value, 'U');
        let tempInsertChecker = this.tempData.find(
          (j: { COVERAGE_TYPE_DESC: any; }) => j.COVERAGE_TYPE_DESC == clickData.COVERAGE_TYPE_DESC && clickData.MODE == 'I');
        let tempUpdateChecker = this.tempData.find(
          (j: { COVERAGE_TYPE_DESC: any; }) => j.COVERAGE_TYPE_DESC == clickData.COVERAGE_TYPE_DESC && clickData.MODE == 'U');
        if (this.rowData.temp) {
          rowToBeUpdated.temp = true;
        }
        else {
          rowToBeUpdated.onDbButUpdatedTemp = true;
          rowToBeUpdated.COVERAGE_TYPE_CD = coverageTypeCdRowValue;
        }

        if (tempInsertChecker!=null && tempInsertChecker!='') {
          //tempInsertChecker = rowToBeUpdated;
          tempInsertChecker.COVERAGE_TYPE_DESC = rowToBeUpdated.COVERAGE_TYPE_DESC;
          tempInsertChecker.ACTIVE_TAG = rowToBeUpdated.ACTIVE_TAG;
          tempInsertChecker.RENEW_TAG = rowToBeUpdated.RENEW_TAG;
          tempInsertChecker.BM_CODE = rowToBeUpdated.BM_CODE;
          tempInsertChecker.BM_SHORT_DESC = rowToBeUpdated.BM_SHORT_DESC;
          tempInsertChecker.REMARKS = rowToBeUpdated.REMARKS;
          tempInsertChecker.USER_ID = rowToBeUpdated.USER_ID;
          tempInsertChecker.MODE = 'I';
          rowToBeUpdated.MODE = 'I';
        }
        else if (tempUpdateChecker!=null && tempUpdateChecker!='') {
          tempUpdateChecker.COVERAGE_TYPE_DESC = rowToBeUpdated.COVERAGE_TYPE_DESC;
          tempUpdateChecker.ACTIVE_TAG = rowToBeUpdated.ACTIVE_TAG;
          tempUpdateChecker.RENEW_TAG = rowToBeUpdated.RENEW_TAG;
          tempUpdateChecker.BM_CODE = rowToBeUpdated.BM_CODE;
          tempUpdateChecker.BM_SHORT_DESC = rowToBeUpdated.BM_SHORT_DESC;
          tempUpdateChecker.REMARKS = rowToBeUpdated.REMARKS;
          tempUpdateChecker.USER_ID = rowToBeUpdated.USER_ID;
          tempUpdateChecker.MODE = 'U';
        }
        else { 
          this.tempData.push(rowToBeUpdated);
        }
        this.forms.resetCoverageTypeForm();
        this.coverageTypeTblConfig.tblData[toBeUpdatedIndex] = rowToBeUpdated;
        this.btns.coverageType.saveDisabled = false;
        this.coverageTypeTblConfig.tblData = [... this.coverageTypeTblConfig.tblData];
      }
      else {
        this.showErrorValidator.coverageType();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public onSave() {
    try {
      this.btns.coverageType.saveDisabled = true;
      this.showForm = false;
      this._formService.showFormLoader(null, "coverage-type-maintenance-loading", "Saving.<br>Please wait ...", null, null);
      if (this.tempData.length > 0) {
        this._api.saveCoverageType(this.tempData).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                if (data.status === 'SUCCESS') {
                  this.onComplete.coverageType();
                }
                else {
                  this.onComplete.coverageTypeError();
                }
              });
            });
          },
          error: (data: any) => {
            this.onComplete.coverageTypeError();
          },
        });
      }
      else
        this.onComplete.coverageType();
    }
    catch (e) {
      console.error(e);
    }
  }

  private onComplete = {
    coverageType: () => {
      this.tempData = [];
      this.getData.coverageType();
      this.btns.coverageType.saveDisabled = true;
      this.showForm = true;
      this._formService.hideFormLoader("coverage-type-maintenance-loading");
      this.forms.resetCoverageTypeForm();
      this.openDialog('SUCCESS', 'Coverage Type details successfully saved');
    },
    coverageTypeError: () => {
      this.showForm = true;
      this.btns.coverageType.saveDisabled = false;
      this.openDialog('FAILED', 'Coverage Type details saving failed');
      this._formService.hideFormLoader("coverage-type-maintenance-loading");
    }
  }

  openDialog(title: any, message: any) {
    try {
      this.showForm = true;
      this._formService.hideFormLoader("coverage-type-maintenance-loading");
      this._matDialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        } 
      });
    } catch(e) {
      console.error(e);
    }
  }

  checkDuplicateCoverageTypeDesc(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.trim()?.toUpperCase();
      if (!value) return null;
      let hasDuplicate: boolean = this.coverageTypeTblConfig.tblData.filter((d: any) => {
        if (!this.rowData && this.btns.coverageType.updateDisabled)
          return d.COVERAGE_TYPE_DESC.trim().toUpperCase() === value;
        else
          return this.rowData.COVERAGE_TYPE_DESC.trim().toUpperCase() !== value && d.COVERAGE_TYPE_DESC.trim().toUpperCase() === value;
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupe: true } : null;
    }
  }

  noWhiteSpaceCheck(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: true };
    }
  }

  public showErrorValidator = {
    coverageType: () => {
      try {
        Object.keys(this.forms.coverageTypeForm.controls).forEach(key => {
          const controlErrors = this.forms.coverageTypeForm.get(key)?.errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              if (keyError == 'whitespace' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
              else if (keyError == 'hasDupe' && controlErrors[keyError] === true)
                this.errorMessage = "Coverage Type already exists. Please add a unique Coverage Type.";
              else if (keyError == 'required' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
            });
          }
        });
        this._formService.showFormMsg("coverage-type-error-message", this.errorMessage, "E");
        //this.openDialog('ERROR', this.errorMessage);
        setTimeout(()=>{                           
          this._formService.hideFormMsg("coverage-type-error-message");
        }, 10000);
      }
      catch (e) {
        console.error(e);
      }
    },
  }

}
