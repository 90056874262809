import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';

@Component({
  selector: 'marital-status-maintenance',
  templateUrl: './marital-status-maintenance.component.html',
  styleUrls: ['./marital-status-maintenance.component.css']
})
export class MaritalStatusMaintenanceComponent implements OnInit, LeaveConfirmation {

  private moduleId: string = 'BMM018';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc?.toUpperCase() ?? "";

  constructor(
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    public _formService: FormService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService
  ) { 
    this.userIdLoggedIn = this._userDetailService.userId ?? "";
  }

  ngOnInit(): void {
    this.getData.maritalStat();
  }

  canDeactivate(): boolean | Observable<boolean> {
    if(this.tempData!='') {
      return false;
    } else {
      return true;
    }
  }

  public btns = {
    maritalStat: {
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      addDisabled: true,
    },
  }

  public selections = {
    activeTypes: [
      { cd: 'A', name: 'Yes' },
      { cd: 'I', name: 'No' },
    ]
  }

  public showForm: boolean = true;

  private rowData: any;
  private tempDataRow: any;
  private errorMessage: string = "";
  private userIdLoggedIn: string;
  private tempData: any = [];

  public forms = {
    maritalStatForm: this.fb.group({
      maritalStatCd: [null],
      maritalStatCdDisp: [{ value: '', disabled: 'true' }],
      maritalDesc: [null, [Validators.required, Validators.maxLength(20), this.checkDuplicateMaritalDesc(), this.noWhiteSpaceCheck()]],
      activeTag: ['A', [Validators.required]],
      bmCode: [null, [Validators.required, Validators.maxLength(8), this.noWhiteSpaceCheck()]],
      bmShortDesc: [null, [Validators.maxLength(10)]],
      remarks: [null, [Validators.maxLength(4000)]],
      userID: [{ value: '', disabled: 'true' }],
      lastUserUpdate: [{ value: '', disabled: 'true' }],
    }),
    resetMaritalStatForm: () => {
      this.forms.maritalStatForm.reset();
      this.forms.maritalStatForm.patchValue({
        activeTag: 'A'
      });
      this.btns.maritalStat.updateDisabled = this.btns.maritalStat.deleteDisabled = true;
      this._formService.hideFormMsg("marital-stat-error-message");
      // this.rowData = null;
      // this.forms.sacForm.get('tsiAmt')?.enable();
    }
  }

  private maritalStatFormManipulate = {
    dbToForm: (data: any) => {
      return {
        maritalStatCd: data.MARITAL_STAT_CD,
        maritalStatCdDisp: data.MARITAL_STAT_CD_DISP,
        maritalDesc: data.MARITAL_DESC,
        activeTag: data.ACTIVE_TAG,
        bmCode: data.BM_CODE,
        bmShortDesc: data.BM_SHORT_DESC,
        remarks: data.REMARKS,
        userID: data.USER_ID,
        lastUserUpdate: data.LAST_UPDATE,
      };
    },
    formToDb: (data: any, mode: any) => {
      return {
        MARITAL_STAT_CD: data.maritalStatCd,
        MARITAL_STAT_CD_DISP: data.maritalStatCd?.toString().padStart(2, 0) ?? null,
        MARITAL_DESC: data.maritalDesc.trim(),
        ACTIVE_TAG: data.activeTag,
        BM_CODE: data.bmCode,
        BM_SHORT_DESC: data.bmShortDesc,
        REMARKS: data.remarks,
        USER_ID: this.userIdLoggedIn,
        LAST_UPDATE: null,
        MODE: mode
      };
    },
  }

  public maritalStatTblConfig = {
    cols: [
      {
        key: "MARITAL_STAT_CD_DISP",
        header: "Marital Status Code",
        dataType: "string",
        width: '25%'
      },
      {
        key: "MARITAL_DESC",
        header: "Marital Status",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        width: '64px'
      }
    ],
    tblData: [] as any[],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  private getData = {
    maritalStat: () => {
      try {
        this.maritalStatTblConfig.tblData = [];
        this.maritalStatTblConfig.loading = true;
        this._jDS.contorlLoading(true);
        this._api.getMaritalStatus({
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          type: "MODULE"
        }).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                let content = JSON.parse(this._jDS.decrypt(data.response));
                this._jDS.contorlLoading(false);
                this.maritalStatTblConfig.tblData = content.data;
                this.btns.maritalStat.addDisabled = false;
                this.maritalStatTblConfig.loading = false;
              });
            });
          },
          error: (error: any) => {
            this.maritalStatTblConfig.loading = false;
            this._jDS.contorlLoading(false);
          }
        });
      }
      catch (e) {
        console.error(e);
      }
    }
  }

  public onRowClick(ev: any) {
    try {
      ev.update = true;
      this.rowData = ev;
      this.populateFormFromTable();
    }
    catch (e) {
      this.forms.resetMaritalStatForm();
      this.rowData = null;
      //this.indexRow = null;
    }
  }

  private populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.maritalStat.updateDisabled = !data.update;
      this.btns.maritalStat.deleteDisabled = !data.temp;
      this.tempDataRow = !data.temp;
      this.forms.maritalStatForm.patchValue(this.maritalStatFormManipulate.dbToForm(data));
    }
    catch (e) {
      console.error(e);
    }
  }

  public onAdd() {
    try {
      this._formService.hideFormMsg("marital-stat-error-message");
      if (this.forms.maritalStatForm.valid) {
        let rowToBeAdded: { [key: string]: any } = this.maritalStatFormManipulate.formToDb(this.forms.maritalStatForm.value, 'I');
        rowToBeAdded.temp = true;
        this.tempData.push(rowToBeAdded);
        this.maritalStatTblConfig.tblData = [rowToBeAdded, ...this.maritalStatTblConfig.tblData];
        this.forms.resetMaritalStatForm();
        this.btns.maritalStat.saveDisabled = false;
      }
      else {
        this.showErrorValidator.maritalStat();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public onDelete() {
    try {
      let clickData = this.rowData;
      this.maritalStatTblConfig.tblData = this.maritalStatTblConfig.tblData.filter((d: any) => {
        return d !== this.rowData;
      });
      this.tempData = this.tempData.filter(
        function(e: any) {
          return e.MARITAL_DESC != clickData.MARITAL_DESC;
        }
      );
      this.forms.resetMaritalStatForm();
    }
    catch (e) {
      console.error(e);
    }
  }

  public onUpdate() {
    try {
      this._formService.hideFormMsg("marital-stat-error-message");
      
      if (this.forms.maritalStatForm.valid) {
        let clickData = this.rowData;
        let toBeUpdatedIndex = this.maritalStatTblConfig.tblData.indexOf(this.rowData);
        let maritalStatCdRowValue = this.rowData.MARITAL_STAT_CD;
        let rowToBeUpdated: { [key: string]: any } = this.maritalStatFormManipulate.formToDb(this.forms.maritalStatForm.value, 'U');
        let tempInsertChecker = this.tempData.find(
          (m: { MARITAL_DESC: any; }) => m.MARITAL_DESC == clickData.MARITAL_DESC && clickData.MODE == 'I');
        let tempUpdateChecker = this.tempData.find(
          (m: { MARITAL_DESC: any; }) => m.MARITAL_DESC == clickData.MARITAL_DESC && clickData.MODE == 'U');
        if (this.rowData.temp) {
          rowToBeUpdated.temp = true;
        }
        else {
          rowToBeUpdated.onDbButUpdatedTemp = true;
          rowToBeUpdated.MARITAL_STAT_CD = maritalStatCdRowValue;
        }

        if (tempInsertChecker!=null && tempInsertChecker!='') {
          //tempInsertChecker = rowToBeUpdated;
          tempInsertChecker.MARITAL_DESC = rowToBeUpdated.MARITAL_DESC;
          tempInsertChecker.ACTIVE_TAG = rowToBeUpdated.ACTIVE_TAG;
          tempInsertChecker.BM_CODE = rowToBeUpdated.BM_CODE;
          tempInsertChecker.BM_SHORT_DESC = rowToBeUpdated.BM_SHORT_DESC;
          tempInsertChecker.REMARKS = rowToBeUpdated.REMARKS;
          tempInsertChecker.USER_ID = rowToBeUpdated.USER_ID;
          tempInsertChecker.MODE = 'I';
          rowToBeUpdated.MODE = 'I';
        }
        else if (tempUpdateChecker!=null && tempUpdateChecker!='') {
          tempUpdateChecker.MARITAL_DESC = rowToBeUpdated.MARITAL_DESC;
          tempUpdateChecker.ACTIVE_TAG = rowToBeUpdated.ACTIVE_TAG;
          tempUpdateChecker.BM_CODE = rowToBeUpdated.BM_CODE;
          tempUpdateChecker.BM_SHORT_DESC = rowToBeUpdated.BM_SHORT_DESC;
          tempUpdateChecker.REMARKS = rowToBeUpdated.REMARKS;
          tempUpdateChecker.USER_ID = rowToBeUpdated.USER_ID;
          tempUpdateChecker.MODE = 'U';
        }
        else { 
          this.tempData.push(rowToBeUpdated);
        }
        this.forms.resetMaritalStatForm();
        this.maritalStatTblConfig.tblData[toBeUpdatedIndex] = rowToBeUpdated;
        this.btns.maritalStat.saveDisabled = false;
        this.maritalStatTblConfig.tblData = [... this.maritalStatTblConfig.tblData];
      }
      else {
        this.showErrorValidator.maritalStat();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public onSave() {
    try {
      this.btns.maritalStat.saveDisabled = true;
      this.showForm = false;
      this._formService.showFormLoader(null, "marital-stat-maintenance-loading", "Saving.<br>Please wait ...", null, null);
      if (this.tempData.length > 0) {
        this._api.saveMaritalStatus(this.tempData).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                if (data.status === 'SUCCESS') {
                  this.onComplete.maritalStat();
                }
                else {
                  this.onComplete.maritalStatError();
                }
              });
            });
          },
          error: (data: any) => {
            this.onComplete.maritalStatError();
          },
        });
      }
      else
        this.onComplete.maritalStat();
    }
    catch (e) {
      console.error(e);
    }
  }

  private onComplete = {
    maritalStat: () => {
      this.tempData = [];
      this.getData.maritalStat();
      this.btns.maritalStat.saveDisabled = true;
      this.showForm = true;
      this._formService.hideFormLoader("marital-stat-maintenance-loading");
      this.forms.resetMaritalStatForm();
      this.openDialog('SUCCESS', 'Marital Status details successfully saved');
    },
    maritalStatError: () => {
      this.showForm = true;
      this.btns.maritalStat.saveDisabled = false;
      this.openDialog('FAILED', 'Marital Status details saving failed');
      this._formService.hideFormLoader("marital-stat-maintenance-loading");
    },
  }

  openDialog(title: any, message: any) {
    try {
      this.showForm = true;
      this._formService.hideFormLoader("marital-stat-maintenance-loading");
      this._matDialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        } 
      });
    } catch(e) {
      console.error(e);
    }
  }

  noWhiteSpaceCheck(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: true };
    }
  }

  checkDuplicateMaritalDesc(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.trim()?.toUpperCase();
      if (!value) return null;
      let hasDuplicate: boolean = this.maritalStatTblConfig.tblData.filter((d: any) => {
        if (!this.rowData && this.btns.maritalStat.updateDisabled)
          return d.MARITAL_DESC.trim().toUpperCase() === value;
        else
          return this.rowData.MARITAL_DESC.trim().toUpperCase() !== value && d.MARITAL_DESC.trim().toUpperCase() === value;
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupe: true } : null;
    }
  }

  public showErrorValidator = {
    maritalStat: () => {
      try {
        Object.keys(this.forms.maritalStatForm.controls).forEach(key => {
          const controlErrors = this.forms.maritalStatForm.get(key)?.errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              if (keyError == 'whitespace' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
              else if (keyError == 'hasDupe' && controlErrors[keyError] === true)
                this.errorMessage = "Marital Status already exists. Please add a unique Marital Status.";
              else if (keyError == 'required' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
            });
          }
        });
        this._formService.showFormMsg("marital-stat-error-message", this.errorMessage, "E");
        //this.openDialog('ERROR', this.errorMessage);
        setTimeout(()=>{                           
          this._formService.hideFormMsg("marital-stat-error-message");
        }, 10000);
      }
      catch (e) {
        console.error(e);
      }
    },
  }

}
