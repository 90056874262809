import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, AbstractControl, ValidatorFn } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs/internal/Observable';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { CustomValidators } from 'src/app/utils/custom-validator';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';

@Component({
  selector: 'fire-item-code-maintenance',
  templateUrl: './fire-item-code-maintenance.component.html',
  styleUrls: ['./fire-item-code-maintenance.component.css']
})
export class FireItemCodeMaintenanceComponent implements OnInit {

  addUpdateFlag: boolean = true;
  datePipe = new DatePipe('en-us');
  selectedRow: any;
  tempNewData: any = [];
  tempUpdateData: any = [];
  userId: string = this.userDetailService.userId;
  errorMessage: string = '';
  saveDisabler: boolean = true;
  deleteDisabler: boolean = true;
  formHider: boolean = false;
  moduleId: string = 'BMM107';
  moduleName = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId === 'BMM107' })[0].moduleDesc.toUpperCase();
  itemGrp = this.jsonDataService.data.refCds.filter((data: any) => data.rvDomain === 'FI_ITEM_GRP');
  
  table: any = {
    cols: [
      {
        key: "ITEM_CD",
        header: "Item Code",
        dataType: "string"
      },
      {
        key: "ITEM_SHORT_DESC",
        header: "Short Description",
        dataType: "string"
      },
      {
        key: "ITEM_LONG_DESC",
        header: "Long Description",
        dataType: "string"
      },
      {
        key: "BM_CODE",
        header: "BPIMS Code",
        dataType: "string"
      },
      {
        key: "BM_ITEM_CODE",
        header: "BPIMS Item Code",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  form = this.fb.group({
    ITEM_CD             : [{value: '', disabled: true}],
    ITEM_SHORT_DESC     : ['', {validators: [Validators.required, this.existingShortDesc(), CustomValidators.requiredTrim], updateOn: 'blur'}],
    ITEM_LONG_DESC      : ['', {validators: [Validators.required, CustomValidators.requiredTrim], updateOn: 'blur'}],
    BM_CODE             : [''],
    BM_ITEM_CODE        : ['', {validators: [Validators.required, CustomValidators.requiredTrim], updateOn: 'blur'}],
    ITEM_LABEL          : ['', {validators: [Validators.required, CustomValidators.requiredTrim], updateOn: 'blur'}],
    ITEM_CD_DTL         : [''],
    ITEM_GRP            : ['', {validators: [Validators.required, CustomValidators.requiredTrim], updateOn: 'blur'}],
    ACTIVE_TAG          : ['A', {validators: [Validators.required, CustomValidators.requiredTrim], updateOn: 'blur'}],
    REMARKS             : [''],
    USER_ID             : [{value: '', disabled: true}],
    LAST_UPDATE         : [{value: '', disabled: true}],
  });

  constructor(
    private fb: FormBuilder,
    private api: APICallService,
    private dialog: MatDialog,
    private jsonDataService: JsonDataService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    private formService: FormService,
    private securityService: SecurityService
  ) { }

  ngOnInit(): void {
    this.getDbData();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempNewData!='' || this.tempUpdateData!='') {
      return false;
    } else {
      return true;
    }
  }

  existingShortDesc(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const dupe = this.table.tblData.filter((e: any) => e.ITEM_SHORT_DESC.toLowerCase().trim() === control.value.toLowerCase().trim() && this.selectedRow?.ITEM_SHORT_DESC != control.value);
      return dupe.length > 0 ? {"shortDescDupe": "Short Description already exists. Please add a unique Short Description."} : null;
    }
  }

  getDbData(): void {
    try {
      this.table.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.api.getFireItemCode({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE"
      }).subscribe((data: any) => {
        this.securityService.checkRequestKeyResponse(data, () => {
          this.securityService.hasValidCsrfToken(data, () => {
            if (data.status === "SUCCESS") {
              data = JSON.parse(this.jsonDataService.decrypt(data.response));
              this.table.tblData = data.data;
              this.table.loading = false;
              this.jsonDataService.contorlLoading(false);
            } else {
              this.jsonDataService.contorlLoading(false);
              this.table.loading = false;
              this.appMessageService.showAppMessage(data.message, "error");
            }
          });
        });
      });
    } catch(e) {
      
    }
  }

  onRowClick(ev: any): void {
    try {
      if(ev!=null) {
        this.selectedRow = ev;
        this.deleteEnabler();
        this.addUpdateFlag = false;
        this.formLoader();
      } else {
        this.selectedRow = '';
        this.deleteEnabler();
        this.addUpdateFlag = true;
        this.formClearer();
      }
    } catch(e) {
      
    }
  }

  formLoader() {
    try {
      if(this.deleteDisabler == true) {
        this.form.get("ITEM_CD")?.disable();
      }
      this.form.patchValue({
        ITEM_CD             : this.selectedRow.ITEM_CD,
        ITEM_SHORT_DESC     : this.selectedRow.ITEM_SHORT_DESC,
        ITEM_LONG_DESC      : this.selectedRow.ITEM_LONG_DESC,
        BM_CODE             : this.selectedRow.BM_CODE,
        BM_ITEM_CODE        : this.selectedRow.BM_ITEM_CODE,
        ITEM_LABEL          : this.selectedRow.ITEM_LABEL,
        ITEM_CD_DTL         : this.selectedRow.ITEM_CD_DTL,
        ITEM_GRP            : this.selectedRow.ITEM_GRP,
        ACTIVE_TAG          : this.selectedRow.ACTIVE_TAG,
        REMARKS             : this.selectedRow.REMARKS,
        USER_ID             : this.selectedRow.USER_ID,
        LAST_UPDATE         : this.selectedRow.LAST_UPDATE,
      });
    } catch(e) {
      
    }
  }
  
  formClearer() {
    try {
      this.formService.hideFormMsg("fire-item-code-error-message");
      // this.form.get("ITEM_CD")?.enable();
      this.form.patchValue({
        ITEM_CD             : "",
        ITEM_SHORT_DESC     : "",
        ITEM_LONG_DESC      : "",
        BM_CODE             : "",
        BM_ITEM_CODE        : "",
        ITEM_LABEL          : "",
        ITEM_CD_DTL         : "",
        ITEM_GRP            : "",
        ACTIVE_TAG          : "A",
        REMARKS             : "",
        USER_ID             : "",
        LAST_UPDATE         : "",
      });
      this.selectedRow = [];
    } catch(e) {
      
    }
  }

  deleteEnabler() {
    try {
      let tempDeleteFlag = this.tempNewData.filter((e: any) => {
        return e.ITEM_SHORT_DESC == this.selectedRow.ITEM_SHORT_DESC
      });
      if(tempDeleteFlag=='' || tempDeleteFlag==null) {
        this.deleteDisabler = true;
      } else {
        this.deleteDisabler = false;
      }
    } catch(e) {
      
    }
  }

  addRow(): void {
    try {
      if(this.form.valid) {
        this.formService.hideFormMsg("fire-item-code-error-message");
        this.table.tblData.unshift(this.formValues());
        this.table.tblData = [...this.table.tblData];
        this.tempNewData.push(this.formValues());
        this.formClearer();
        this.saveDisabler = false;
      } else {
        this.errorMessageSetter();
      }
    } catch(e) {
      
    }
  }

  formValues(): any {
    try {
      const values = this.form.getRawValue();
      return {
        ITEM_CD             : values?.ITEM_CD === '' ? '' : values?.ITEM_CD.padStart(3,0),
        ITEM_SHORT_DESC     : values?.ITEM_SHORT_DESC,
        ITEM_LONG_DESC      : values?.ITEM_LONG_DESC,
        BM_CODE             : values?.BM_CODE,
        BM_ITEM_CODE        : values?.BM_ITEM_CODE,
        ITEM_LABEL          : values?.ITEM_LABEL,
        ITEM_CD_DTL         : values?.ITEM_CD_DTL,
        ITEM_GRP            : values?.ITEM_GRP,
        ACTIVE_TAG          : values?.ACTIVE_TAG,
        REMARKS             : values?.REMARKS,
        USER_ID             : this.userDetailService.userId,
        LAST_UPDATE         : values?.LAST_UPDATE,
      }
    } catch(e) {
      
    }
  }

  errorMessageSetter() {
    try {
      if(this.form.invalid) {
        for(const field in this.form.controls) {
          const controlName = this.form.get(field);
          if(controlName?.errors?.required) {
            this.errorMessage = 'There are missing information. Please provide necessary information needed.';
          }
          if(controlName?.errors?.shortDescDupe) {
            this.errorMessage = controlName?.errors?.shortDescDupe;
          }
          this.formService.showFormMsg("fire-item-code-error-message", this.errorMessage, "E");
        }
      }
    } catch(e) {
      
    }
  }

  onUpdate(): void {
    try {
      if(this.form.valid) {
        this.updateTable();
        if(this.deleteDisabler) {
          this.updateDbData();
        } else {
          this.updateNewData();
        }
        this.selectedRow = '';
        this.addUpdateFlag = true;
        this.formClearer();
        this.saveDisabler = false;
        this.deleteEnabler();
        this.formService.hideFormMsg('fire-item-code-error-message');
      } else {
        this.errorMessageSetter();
      }
    } catch(e) {
      
    }
  }

  updateTable() {
    try {
      const values = this.form.getRawValue();
      let updateIndex = this.table.tblData.indexOf(this.selectedRow);
      this.table.tblData[updateIndex] = this.formValues();
      this.table.tblData = [...this.table.tblData];
    } catch(e) {
      
    }
  }

  updateDbData() {
    try {
      let tempDbData = this.tempUpdateData.find((data: { ITEM_CD: any}) => data.ITEM_CD == this.selectedRow.ITEM_CD);
      if((this.tempUpdateData == '' || this.tempUpdateData == null) || (tempDbData=='' || tempDbData==null)) {
        this.tempUpdateData.push(this.formValues());
      } else {
        const values = this.form.getRawValue();
        tempDbData.ITEM_CD             = values?.ITEM_CD.padStart(3,0);
        tempDbData.ITEM_SHORT_DESC     = values?.ITEM_SHORT_DESC;
        tempDbData.ITEM_LONG_DESC      = values?.ITEM_LONG_DESC;
        tempDbData.BM_CODE             = values?.BM_CODE;
        tempDbData.BM_ITEM_CODE        = values?.BM_ITEM_CODE;
        tempDbData.ITEM_LABEL          = values?.ITEM_LABEL;
        tempDbData.ITEM_CD_DTL         = values?.ITEM_CD_DTL;
        tempDbData.ITEM_GRP            = values?.ITEM_GRP;
        tempDbData.ACTIVE_TAG          = values?.ACTIVE_TAG;
        tempDbData.REMARKS             = values?.REMARKS;
        tempDbData.USER_ID             = this.userDetailService.userId;
        tempDbData.LAST_UPDATE         = values?.LAST_UPDATE;
      }
    } catch(e) {
      
    }
  }

  updateNewData() {
    try {
      let tempNewConfigdata = this.tempNewData.find((data: { ITEM_CD: any}) => data.ITEM_CD == this.selectedRow.ITEM_CD);
      const values = this.form.getRawValue();
      tempNewConfigdata.ITEM_CD             = values?.ITEM_CD.padStart(3,0);
      tempNewConfigdata.ITEM_SHORT_DESC     = values?.ITEM_SHORT_DESC;
      tempNewConfigdata.ITEM_LONG_DESC      = values?.ITEM_LONG_DESC;
      tempNewConfigdata.BM_CODE             = values?.BM_CODE;
      tempNewConfigdata.BM_ITEM_CODE        = values?.BM_ITEM_CODE;
      tempNewConfigdata.ITEM_LABEL          = values?.ITEM_LABEL;
      tempNewConfigdata.ITEM_CD_DTL         = values?.ITEM_CD_DTL;
      tempNewConfigdata.ITEM_GRP            = values?.ITEM_GRP;
      tempNewConfigdata.ACTIVE_TAG          = values?.ACTIVE_TAG;
      tempNewConfigdata.REMARKS             = values?.REMARKS;
      tempNewConfigdata.USER_ID             = this.userDetailService.userId;
      tempNewConfigdata.LAST_UPDATE         = values?.LAST_UPDATE;
    } catch(e) {
      
    }
  }

  deleteRow(): void {
    try {
      let clickedData = this.selectedRow;
      this.table.tblData = this.table.tblData.filter((e: any) => e != clickedData);
      this.tempNewData = this.tempNewData.filter((e: any) => e.ITEM_SHORT_DESC != clickedData.ITEM_SHORT_DESC);
      this.selectedRow = '';
      this.formClearer();
      this.deleteEnabler();
      this.deleteDisabler = true;
      this.addUpdateFlag = true;
      if((this.tempNewData=='' || this.tempNewData==null) && (this.tempUpdateData=='' || this.tempUpdateData==null)) {
        this.saveDisabler = true;
      }
    } catch(e) {
      
    }
  }

  onSave(): void {
    try {
      this.tempNewData = this.tempNewData.map((e: any) => {
        return {...e, ITEM_CD: +e?.ITEM_CD}
      });
      this.tempUpdateData = this.tempUpdateData.map((e: any) => {
        return {...e, ITEM_CD: +e?.ITEM_CD}
      });
      this.formHider = true;
      this.jsonDataService.contorlLoading(true);
      this.api.saveFireItemCode(this.tempNewData).subscribe({
        next: (data: any) => {
          this.securityService.checkRequestKeyResponse(data, () => {
            this.securityService.hasValidCsrfToken(data, () => {
              if(data.status == 'SUCCESS') {
                this.api.saveFireItemCode(this.tempUpdateData).subscribe({
                  next: (res: any) => {
                    this.securityService.checkRequestKeyResponse(res, () => {
                      this.securityService.hasValidCsrfToken(res, () => {
                        if(res.status == 'SUCCESS') {
                          this.onComplete();
                        } else {
                          this.onFail();
                        }
                      });
                    });
                  },
                  error: () => {
                    this.onFail();
                  }
                });
              } else {
                this.onFail();
              }
            });
          });
        },
        error: () => {
          this.onFail();
        }
      });
    } catch(e) {
      
    }
  }
  
  onComplete() {
    try {
      this.formHider = false;
      this.tempNewData = [];
      this.tempUpdateData = [];
      this.saveDisabler = true;
      this.deleteEnabler();
      this.formClearer();
      this.getDbData();
      this.formService.hideFormMsg("fire-item-code-error-message");
      this.openSaveDialog();
    } catch(e) {
      
    }
  }

  onFail() {
    try {
      this.formHider = false;
      this.tempNewData = [];
      this.tempUpdateData = [];
      this.deleteEnabler();
      this.formClearer();
      this.getDbData();
      this.formService.hideFormMsg("fire-item-code-error-message");
      this.openErrorDialog("Saving Fire Item Code Failed");
    } catch(e) {
      
    }
  }

  openErrorDialog(message: string) {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'ERROR',
          content: message,
        }
      });
    } catch(e) {
      
    }
  }

  openSaveDialog() {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'SUCCESS',
          content: 'Fire Item Code Details Successfully Saved!',
        }
      });
    } catch(e) {
      
    }
  }

}
