import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { CustomValidators } from 'src/app/utils/custom-validator';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { StateLovComponent } from '../../common/state-lov/state-lov.component';

@Component({
  selector: 'fire-typhoon-zone-maintenance',
  templateUrl: './fire-typhoon-zone-maintenance.component.html',
  styleUrls: ['./fire-typhoon-zone-maintenance.component.css']
})
export class FireTyphoonZoneMaintenanceComponent implements OnInit, LeaveConfirmation {

  private moduleId: string = 'BMM189';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc ?? "";

  constructor(
    public _formService: FormService,
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService,
    private _appMessageService: AppMessageService,
  ) {
    this.userIdLoggedIn = this._userDetailService.userId ?? "";
  }

  public canDeactivate(): boolean | Observable<boolean> {
    return !(this.fireTyphoonZoneTblConfig.tblData?.filter((row: any) => ['I', 'U'].includes(row.operation)).length > 0);
  }

  public btns = {
    fireTyphoonZone: {
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      addDisabled: false,
    },
  };

  public showForm: boolean = true;

  //* Data Stuffs */
  private rowData: any;
  private errorMessage: string = "";
  private userIdLoggedIn: string;
  //? set selectedRow to [] to unselect the table
  public selectedRow!: any[];

  //* lambda data */
  private lambdaLimitData: number = 10000;
  private offsetPages: any[] = [];
  private tempDataFromGet: any[] = [];

  public forms = {
    fireTyphoonZoneForm: this.fb.group({
      typhoonCd: [null, [Validators.required, Validators.maxLength(25), this.checkDuplicateTyphoonZone(), CustomValidators.requiredTrim]],
      typhoonShortDesc: [null, [Validators.required, Validators.maxLength(50), this.checkDuplicateTyphoonShortDesc(), CustomValidators.requiredTrim]],
      typhoonLongDesc: [null, [Validators.required, Validators.maxLength(1000), CustomValidators.requiredTrim]],
      active: ['A', [Validators.required]],
      bmCode: [null, [Validators.maxLength(8)]],
      remarks: [null, [Validators.maxLength(1000)]],
      lastUpdateUser: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }],
    }),
    resetfireTyphoonZoneForm: () => {
      this.forms.fireTyphoonZoneForm.reset();
      this.forms.fireTyphoonZoneForm.patchValue({
        active: 'A',
      });
      this.btns.fireTyphoonZone.updateDisabled = this.btns.fireTyphoonZone.deleteDisabled = true;
      this._formService.hideFormMsg("fire-typhoon-zone-error-message");
      this.rowData = null;
      this.forms.fireTyphoonZoneForm.get('typhoonCd')?.enable();
    },
  };

  private fireTyphoonZoneFormManipulate = {
    dbToForm: (data: any) => {
      return {
        typhoonCd: data.TYPHOON_ZONE_CD,
        typhoonShortDesc: data.TYPHOON_SHORT_DESC,
        typhoonLongDesc: data.TYPHOON_LONG_DESC,
        active: data.ACTIVE_TAG,
        bmCode: data.BM_CODE,
        remarks: data.REMARKS,
        lastUpdateUser: data.USER_ID,
        lastUpdate: data.LAST_UPDATE,
      };
    },
    formToDb: (data: any) => {
      return {
        TYPHOON_ZONE_CD: data.typhoonCd?.trim(),
        TYPHOON_SHORT_DESC: data.typhoonShortDesc?.trim(),
        TYPHOON_LONG_DESC: data.typhoonLongDesc?.trim(),
        ACTIVE_TAG: data.active,
        BM_CODE: data.bmCode?.trim(),
        REMARKS: data.remarks,
        USER_ID: null,
        LAST_UPDATE: null,
      };
    },
  }

  public fireTyphoonZoneTblConfig: any = {
    cols: [
      {
        key: "TYPHOON_ZONE_CD",
        header: "Typhoon Zone Code",
        dataType: "string",
        width: '144px',
      },
      {
        key: "TYPHOON_SHORT_DESC",
        header: "Short Description",
        dataType: "string",
        width: '196px',
      },
      {
        key: "TYPHOON_LONG_DESC",
        header: "Long Description",
        dataType: "string",
      },
      {
        key: "BM_CODE",
        header: "BPIMS Code",
        dataType: "string",
        width: '128px',
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        width: '48px',
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  ngOnInit(): void {
    this.getData.fireTyphoonZone();
  }

  private getData = {
    fireTyphoonZone: () => {
      try {
        this._jDS.contorlLoading(true);
        this._api.getFireTyphoonZone({
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          type: "MODULE"
        }).subscribe({
          next: (response: any) => {
            this._securityService.checkRequestKeyResponse(response, () => {
              this._securityService.hasValidCsrfToken(response, () => {
                if (response.status === "SUCCESS") {
                  let content = JSON.parse(this._jDS.decrypt(response?.response));
                    this.fireTyphoonZoneTblConfig.tblData = [...content.data];
                    this._jDS.contorlLoading(false);
                    this.fireTyphoonZoneTblConfig.loading = false;
                }
                else {
                  this.fireTyphoonZoneTblConfig.tblData = [];
                  this._jDS.contorlLoading(false);
                  this.fireTyphoonZoneTblConfig.loading = false;
                }
              });
            });
          },
          error: (e: any) => {
            this._securityService.checkRequestKeyResponse(e, () => {

              this._jDS.contorlLoading(false);
              this.fireTyphoonZoneTblConfig.loading = false;
            });
          }
        });
      }
      catch (e) {
    console.error(e);

      }
    }
  };

  public selections = {
    activeTypes: [
      { cd: 'A', name: 'Yes' },
      { cd: 'I', name: 'No' },
    ],
  };

  public onRowClick(ev: any) {
    try {
      if (ev) {
        this.rowData = ev;
        this.forms.fireTyphoonZoneForm.get('typhoonCd')?.disable();
        this.populateFormFromTable();
      }
      else {
        this.rowData = null;
        this.forms.resetfireTyphoonZoneForm();
      }
    }
    catch (e) {
    console.error(e);
      this.rowData = null;
      this.forms.resetfireTyphoonZoneForm();
    }
  }

  private populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.fireTyphoonZone.updateDisabled = false;
      this.btns.fireTyphoonZone.deleteDisabled = !(this.rowData?.operation === 'I');
      this.forms.fireTyphoonZoneForm.patchValue(this.fireTyphoonZoneFormManipulate.dbToForm(data));
    }
    catch (e) {
    console.error(e);

    }
  }

  public onAdd(): void {
    try {
      this._formService.hideFormMsg("fire-typhoon-zone-error-message");
      if (this.forms.fireTyphoonZoneForm.valid) {
        let rowToBeAdded: { [key: string]: any } = this.fireTyphoonZoneFormManipulate.formToDb(this.forms.fireTyphoonZoneForm.getRawValue());
        rowToBeAdded.operation = 'I';
        this.fireTyphoonZoneTblConfig.tblData = [rowToBeAdded, ...this.fireTyphoonZoneTblConfig.tblData];
        this.forms.resetfireTyphoonZoneForm();
        this.btns.fireTyphoonZone.saveDisabled = false;
      }
      else {
        this.showErrorValidator.fireTyphoonZone();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onUpdate(): void {
    try {
      this._formService.hideFormMsg("fire-typhoon-zone-error-message");
      if (this.forms.fireTyphoonZoneForm.valid) {
        const indexOfRow = this.fireTyphoonZoneTblConfig.tblData.indexOf(this.rowData);
        let rowToBeUpdated: { [key: string]: any } = this.fireTyphoonZoneFormManipulate.formToDb(this.forms.fireTyphoonZoneForm.getRawValue());
        rowToBeUpdated.operation = (this.rowData.operation === 'I') ? 'I' : 'U';
        rowToBeUpdated.TYPHOON_ZONE_CD = this.rowData?.TYPHOON_ZONE_CD;
        this.forms.resetfireTyphoonZoneForm();
        this.fireTyphoonZoneTblConfig.tblData[indexOfRow] = rowToBeUpdated;
        this.selectedRow = [];
        this.btns.fireTyphoonZone.saveDisabled = false;
        this.fireTyphoonZoneTblConfig.tblData = [...this.fireTyphoonZoneTblConfig.tblData];
      }
      else {
        this.showErrorValidator.fireTyphoonZone();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onDelete(): void {
    try {
      this.fireTyphoonZoneTblConfig.tblData = this.fireTyphoonZoneTblConfig.tblData.filter((row: any) => {
        return row !== this.rowData;
      });
      this.forms.resetfireTyphoonZoneForm();
      let toBeSaved = this.fireTyphoonZoneTblConfig.tblData.filter((row: any) => ['I', 'U'].includes(row.operation));
      if (toBeSaved.length > 0) {
        this.btns.fireTyphoonZone.saveDisabled = false;
      }
      else{
        this.btns.fireTyphoonZone.saveDisabled = true;
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onSave(): void {
    try {
      this.showForm = false;
      this._formService.showFormLoader(null, "fire-typhoon-zone-maintenance-loading", "Saving.<br>Please wait ...", null, null, true);
      let toBeSaved = this.fireTyphoonZoneTblConfig.tblData.filter((row: any) => ['I', 'U'].includes(row.operation));
      if (toBeSaved.length > 0) {
        this._api.saveFireTyphoonZone(toBeSaved).subscribe({
          next: (response: any) => {
            this._securityService.checkRequestKeyResponse(response, () => {
              this._securityService.hasValidCsrfToken(response, () => {
                if (response.status === 'SUCCESS') {
                  this.onComplete.fireTyphoonZone();
                }
                else {
                  this.onComplete.fireTyphoonZoneError();
                }
              });
            });
          },
          error: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {

              this.onComplete.fireTyphoonZoneError();
            });
          }
        });
      }
      else {
        this.onComplete.fireTyphoonZone();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  private onComplete = {
    fireTyphoonZone: () => {
      this._jDS.contorlLoading(false);
      this._formService.hideFormLoader("fire-typhoon-zone-maintenance-loading");
      this.openDialog('SUCCESS', this.moduleName.replace('Maintenance', ''), 'saved');
      this.btns.fireTyphoonZone.saveDisabled = true;
      this.showForm = true;
      this.forms.resetfireTyphoonZoneForm();
    },
    fireTyphoonZoneError: () => {
      this._jDS.contorlLoading(false);
      this.openDialog('FAILED', this.moduleName.replace('Maintenance', ''), 'saving');
      this._formService.hideFormLoader("fire-typhoon-zone-maintenance-loading");
      this.showForm = true;
      this.forms.resetfireTyphoonZoneForm();
    },
  }

  private openDialog(title: string, contentTitle: string, contentDetail: string) {
    try {
      this._matDialog.open(DialogMsgComponent, {
        disableClose: true,
        width: '512px',
        data: {
          title: title,
          content:
            title === 'SUCCESS'
              ? `${contentTitle} details successfully ${contentDetail}!`
              : `${contentTitle} details ${contentDetail} failed!`,
        },
      }).afterClosed().subscribe(() => {
        if (title === 'SUCCESS') {
          this.getData.fireTyphoonZone();
        }
      });
    }
    catch (e) {
    console.error(e);

    }
  }

  //* Validator Stuffs Down here */

  private checkDuplicateTyphoonZone(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.trim()?.toUpperCase();
      if (!value) return null;
      let hasDuplicate: boolean = this.fireTyphoonZoneTblConfig?.tblData.filter((d: any) => {
        if (!this.rowData)
          return d['TYPHOON_ZONE_CD']?.trim().toUpperCase() === value;
        else
          return this.rowData['TYPHOON_ZONE_CD']?.trim()?.toUpperCase() !== value && d['TYPHOON_ZONE_CD']?.trim()?.toUpperCase() === value;
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupe: true } : null;
    }
  }

  private checkDuplicateTyphoonShortDesc(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.trim()?.toUpperCase();
      if (!value) return null;
      let hasDuplicate: boolean = this.fireTyphoonZoneTblConfig?.tblData.filter((d: any) => {
        if (!this.rowData)
          return d['TYPHOON_SHORT_DESC']?.trim().toUpperCase() === value;
        else
          return this.rowData['TYPHOON_SHORT_DESC']?.trim()?.toUpperCase() !== value && d['TYPHOON_SHORT_DESC']?.trim()?.toUpperCase() === value;
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupe: true } : null;
    }
  }

  public showErrorValidator = {
    fireTyphoonZone: () => {
      try {
        Object.keys(this.forms.fireTyphoonZoneForm.controls).forEach(key => {
          const controlErrors = this.forms.fireTyphoonZoneForm.get(key)?.errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              if (keyError == 'hasDupe' && controlErrors[keyError] === true && key === 'typhoonCd')
                this.errorMessage = "Typhoon Zone Code already exists. Please use a unique Typhoon Zone Code.";
              else if (keyError == 'hasDupe' && controlErrors[keyError] === true && key === 'typhoonShortDesc')
                this.errorMessage = "Typhoon Zone Short Description already exists. Please use a unique Typhoon Zone Short Description.";
              else if (keyError == 'required' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
            });
          }
        });
        this._formService.showFormMsg("fire-typhoon-zone-error-message", this.errorMessage, "E");
      }
      catch (e) {
    console.error(e);

      }
    },
  }

}
