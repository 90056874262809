<section id="introduction-maint">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>

        <div class="form-content">
            <form [formGroup]="paramForm" class="form">
                <div class="form-container-grid top-form">
                    <div class="field required">
                        <label>Subline</label>
                    </div>
                    <div class="lov-search">
                        <input readonly formControlName="sublineField">
                        <button matSuffix class="btn btn-search" type="button" (click)="openSublineDialog()"><mat-icon>search</mat-icon></button>
                    </div>
                </div>
            </form>
        </div>

        <div class='table-intro'>
            <osp-table [tblConfig]="tblConfigVehicleFilter" (onRowClick)="onRowClick($event)"></osp-table>
        </div>

        <!-- Loading Animation -->
        <div id="generate-intro-form">
            <div class="form-loader"></div>
        </div>

        <div class="form-content" [hidden]="formHider">

            <form [formGroup]="vehicleFilterForm" class="form" *ngIf="noLoadingInProcess">
                <div id="vehicle-filter-error-message"></div>
                <div class="form-container-grid">

                    <div class="field required">
                        <label>Plan</label>
                    </div>
                    <div class="lov-search span">
                        <input type="text" readonly formControlName="planField">
                        <button type="button" class="btn-search btn" (click)="openPlanDialog()" [disabled]='planDisabled'>
                            <mat-icon>
                                search
                            </mat-icon>
                        </button>
                    </div>

                    <div class="field">
                        <label>Filter Year for Brand New</label>
                    </div>
                    <div>
                        <input formControlName="filterYearNew"  (keypress)="allowNumericDigitsOnlyWODecimal($event)"  type="number" min="0">
                    </div>
                    <div class="field">
                        <label>Filter Year for Used</label>
                    </div>
                    <div class="field">
                        <input formControlName="filterYearUsed" (keypress)="allowNumericDigitsOnlyWODecimal($event)"  type="number" min="0">
                    </div>
                    <div class="field">
                        <label>High Risk Filter</label>
                    </div>
                    <div>
                        <mat-select formControlName="filterHighRiskId" multiple>
                            <mat-option *ngFor="let filterHighRisk of selections.filterHighRiskIds" [value]="filterHighRisk.cd">
                                {{ filterHighRisk.name }}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="field">
                        <label>Make Type Filter</label>
                    </div>
                    <div>
                        <mat-select formControlName="filterMakeTypeCd" multiple>
                            <mat-option *ngFor="let filterMakeType of selections.filterMakeTypeCds" [value]="filterMakeType.cd">
                                {{ filterMakeType.name }}
                            </mat-option>
                        </mat-select>
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="userId">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUserUpdate">
                    </div>
                </div>
            </form>

            <div class='btn-row'>
                <button type="button" id='btn-add' *ngIf="!isVehicleFilterSelected" title='Register a new introduction' class="btn btn2" value='ADD' type="submit" [disabled]='addUpdDisabled' (click)="insertVehicleFilter()">ADD</button>
                <button type="button" id='btn-update' *ngIf="isVehicleFilterSelected" title='Update an introduction' class="btn btn2" value='UPDATE' type="submit" [disabled]='addUpdDisabled' (click)='updateVehicleFilter()'>UPDATE</button>
                <button type="button" id='btn-delete' title='Remove selected introduction' class="btn btn2" value='DELETE' type="submit" [disabled]='deleteDisabled' (click)='deleteVehicleFilter()'>DELETE</button>
            </div> 

            <div class="mtn-title"></div>

            <div class='btn-row'>
                <button type="button" id='btn-save' title='Save changes on introduction' class="btn btn2" value='SAVE' type="submit" [disabled]='saveDisabled' (click)='toSave()'>SAVE</button>
            </div>

        </div>
    </div>

</section>
