<section id="pol-sequence-no">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <div class="form-content">
            <form [formGroup]="filterPolSeqNoForm" class="form">
                <div class="form-container-grid top-form">
                    <div class="field required">
                        <label>Contract Type</label>
                    </div>
                    <div class="lov-search">
                        <input readonly formControlName="contractTypeFilter">
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov('contractType')">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                    <div class="field required">
                        <label>Record Type</label>
                    </div>
                    <div class="">
                        <select formControlName="recordTypeFilter" (change)="triggerLazy()">
                            <option *ngFor="let x of options.recordType" [value]="x.rvLowValue">{{ x.rvMeaning }}
                            </option>
                        </select>
                    </div>
                </div>
            </form>
        </div>
        <div class="table-line">
            <osp-table #tbl [tblConfig]="tblConfig" (onRowClick)="onRowClick($event)" (onLazyLoad)="lazyLoad($event)">
            </osp-table>
        </div>
        <div id="src-ext-form">
            <div class="form-loader"></div>
        </div>
        <div class="form-content" *ngIf="!isSaving">
            <form class="form" [formGroup]="form">
                <div id="src-ext-form-msg"></div>
                <div class="form-container-grid">
                    <div class="field">
                        <label>Policy Sequence</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="bmPolSeqNo" readonly>
                    </div>
                    <div class="field">
                        <label></label>
                    </div>
                    <div class="">
                    </div>

                    <div class="field">
                        <label>Payment Reference No.</label>
                    </div>
                    <div class="">
                        <input required type="text" formControlName="paytRefNo">
                    </div>
                    <div class="field">
                        <label>Policy No.</label>
                    </div>
                    <div class="">
                        <input  type="text" formControlName="policyNo" readonly>
                    </div>
                    
                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input type="text" formControlName="remarks" maxlength="4000">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input readonly type="text" formControlName="userId">
                    </div>
                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input readonly type="text" formControlName="lastUpdate">
                    </div>
                </div>
                <div class="btn-row">
                    <button class="btn btn2" (click)="onClickSave()" [disabled]="selectedRow == null || form.pristine">SAVE</button>
                </div>

                <div class="mtn-title"></div>

                <div class="btn-row">
                    <button class="btn btn2" (click)="upload.open()">UPLOAD NEW SEQUENCE</button>
                    <button class="btn btn2"
                        [disabled]="filterPolSeqNoForm.get('contractTypeFilter')?.value == ''"
                        (click)="openDeleteDialog()">DELETE UNUSED SEQUENCE</button>
                </div>
            </form>
        </div>
    </div>
</section>

<lov #lov [data]="lovData" (selection)="selectedFromLov($event)"></lov>
<upload #upload [data]="uploadConfig"></upload>

<ng-template #deleteDialog>
    <h3 class="overlay-header" mat-dialog-title>DELETE UNUSED SEQUENCE</h3>
    <mat-dialog-content>
        <div class="dlg-content">
            <div class="p-grid cust-margin">
                <div class="p-col-12 p-grid">
                    <div class="p-col-12 p-grid">
                        <div class="p-col-4 p-text-right field">
                            <label>Contract Type</label>
                        </div>
                        <div class="p-col-7">
                            <input matInput type="text" disabled
                                [ngModel]="filterPolSeqNoForm.get('contractTypeFilter')?.value">
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-grid cust-margin">
                <div class="p-col-12 p-grid">
                    <div class="p-col-12 p-grid">
                        <div class="p-col-4 p-text-right field required">
                            <label>Start Sequence</label>
                        </div>
                        <div class="p-col-7">
                            <input matInput type="text" [(ngModel)]="minSeqNo" numbersOnly [disabled]="disableStartSeq">
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-grid cust-margin">
                <div class="p-col-12 p-grid">
                    <div class="p-col-12 p-grid">
                        <div class="p-col-4 p-text-right field">
                            <label>End Sequence</label>
                        </div>
                        <div class="p-col-7">
                            <input matInput type="text" disabled [ngModel]="maxSeqNo">
                        </div>
                    </div>
                </div>
            </div>
            <div class="dlg-note">
                <span>{{ dlgNote }}</span>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="dlg-btns">
            <button mat-button class="btn btn2" [mat-dialog-close]="true">CANCEL</button>
            <button mat-button class="btn btn2" (click)="onClickProceed()">PROCEED</button>
        </div>
    </mat-dialog-actions>
</ng-template>