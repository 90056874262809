import { Component, OnInit } from '@angular/core';

import { APICallService } from 'src/app/services/api-call.service'
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { SecurityService } from 'src/app/services/security.service';

import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe, formatDate, DecimalPipe } from '@angular/common';

import { Observable } from 'rxjs';

import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { ClientGroupLovDialog } from '../../common/client-group-lov-dialog/client-group-lov-dialog.component';
import { ProjectLovComponent } from '../../common/project-lov/project-lov.component';
import { AgentLovComponent } from '../../common/agent-lov/agent-lov.component';
import { PerilLovComponent } from '../../common/peril-lov/peril-lov.component';
import { RiskProfileLovComponent } from '../../common/risk-profile-lov/risk-profile-lov.component';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';
import { ThrowStmt } from '@angular/compiler';
import { CustomValidators } from 'src/app/utils/custom-validator';

@Component({
  selector: 'account-type-maintenance',
  templateUrl: './account-type-maintenance.component.html',
  styleUrls: ['./account-type-maintenance.component.css'],
  providers: [DecimalPipe],
})
export class AccountTypeMaintenanceComponent implements OnInit, LeaveConfirmation {

  datePipe = new DatePipe('en-us');


  saveDisabledAccountType : boolean = true;
  deleteDisabledAccountType : boolean = true;
  saveDisabledAmtCoveredPremAmtLmtPerAccType : boolean = true;
  deleteDisabledAmtCoveredPremAmtLmtPerAccType : boolean = true;
  tranPosted : boolean = false;
  noLoadingInProcesstblConfigAccountType : boolean = true;
  noLoadingInProcessAmtCoveredPremAmtLmtPerAccType : boolean = true;
  validated : boolean = false;
  submitted : boolean = false;
  isAccountTypeSelected : boolean = false;
  isAmtCoveredPremAmtLmtPerAccTypeSelected : boolean = false;
  isSuccessSaved : boolean = false;
  currCdField: string = '';
  minEffDate: Date = new Date();
  effDateFromField: any = '';
  currentUser: string = this.userDetailService.userId || "";
  rowClickedData: any;
  rowClickedData2: any;
  moduleId: string = "BMM093";
  public moduleTitle = this.jsonDataService.data.moduleList.filter((e: any) => e.moduleId == this.moduleId)[0].moduleDesc.toUpperCase();

  effDate = new FormControl(new Date());
  
  tempAddedDataAccountType: any = [];
  tempUpdateDataAccountType: any = [];
  tempAddedDataAmtCoveredPremAmtLmtPerAccType: any = [];
  tempUpdateDataAmtCoveredPremAmtLmtPerAccType: any = [];

  accountTypeForm = this.fb.group({
    userTypeCd: [null, [Validators.required, Validators.maxLength(1), CustomValidators.requiredTrim]],
    activeTag: ['A', [Validators.required]],
    userTypeDesc: ['', [Validators.required, CustomValidators.requiredTrim]],
    editAmtCovered: ['N', [Validators.required]],
    editPremRate: ['N', [Validators.required]],
    editPremAmt: ['N'],
    hidePremRate: ['N', [Validators.required]],
    hideComm: ['N', [Validators.required]],
    mainUserType: [null],
    agentCd: [null],
    agentName: [null],
    agentDisp:[null],
    projectCd: [null],
    projectName: [null],
    projectDisp: [null],
    clientType: [null],
    clientTypeName: [null],
    clientTypeDisp: [null],
    riskProfCd: [null],
    riskProfName: [null],
    riskProfDisp: [null],
    enOtp: ['N'],
    enClientCd: ['N'],
    enPaytTerms: ['N'],
    paytTermsPath: [null],
    remarks: [null],
    lastUpdateName: [null],
    lastUserUpdate: [null],
  });

  amtCoveredPremAmtLmtPerAccTypeForm = this.fb.group({
    userType: [null, [Validators.required]],
    userLvl: [null, [Validators.required]],
    userLvlDesc:[null],
    activeTag: ['A', [Validators.required]],
    itemTypeCd: [null, [Validators.required]],
    itemTypeDesc:[null],
    lineCd: [null, [Validators.required]],
    lineName: [null],
    lineCdDisp: [null],
    perilCd: [null, [Validators.required]],
    perilCdName: [null],
    perilField: [null],
    amtCoveredLmt: [null],
    amtCoveredMinPct: [null],
    amtCoveredMaxPct: [null],
    minPremRate: [null],
    maxPremRate: [null],
    remarks: [null],
    lastUpdateName: [null],
    lastUserUpdate: [null]
  });

  constructor(
    private _APICallService: APICallService, 
    public _Dialog: MatDialog, 
    private _matDialog: MatDialog,
    private fb: FormBuilder, 
    public formService: FormService, 
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService,
    private decimalPipe: DecimalPipe,
  ) { }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempAddedDataAccountType!='' || this.tempUpdateDataAccountType!='') {
      return false;
    } else {
      return true;
    }
  }

  tblConfigAccountType: any = {
    cols: [
      {
        key: "USER_TYPE",
        header: "User Type Code",
        dataType: "string"
      },
      {
        key: "USER_TYPE_DESC",
        header: "User Type",
        dataType: "string",
        width: '25%'
      },
      {
        key: "AGENT_NAME",
        header: "Agent",
        dataType: "string",
        disabled: false
      },
      {
        key: "PROJECT_DESC",
        header: "Project",
        dataType: "string",
        disabled: false
      },
      {
        key: "CLIENT_DESC",
        header: "Client",
        dataType: "string",
        disabled: false
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        disabled: true,
        width: '64px'
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15
  }

  tblConfigAmtCoveredPremAmtLmtPerAccType: any = {
    cols: [
      {
        key: "USER_LEVEL_DESC",
        header: "User Level",
        dataType: "string",
        width: '20%'
      },
      {
        key: "ITEM_TYPE_DESC",
        header: "Item Type",
        dataType: "string",
        disabled: false,
        width: '26%'
      },
      {
        key: "LINE_NAME",
        header: "Line",
        dataType: "string",
        disabled: false
      },
      {
        key: "PERIL_LNAME",
        header: "Peril",
        dataType: "string",
        disabled: false
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        disabled: true,
        width: '64px'
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15
  }


  firstLoad:boolean = true;
  ngOnInit(): void {
    try {
      this.getAllAccountType();
    }
    catch(e) {
    }
  }

  getAllAccountType() {
    try {
      this.tblConfigAccountType.loading = true;
      this.jsonDataService.contorlLoading(true);
      this._APICallService.getAllAccType({
          moduleId: this.moduleId,
          userId: this.userDetailService.userId,
          type: "MODULE"
      }).subscribe({
        next: (data: any) => {
            this.securityService.checkRequestKeyResponse(data, () => {
              this.securityService.hasValidCsrfToken(data, () => {
                if (data.status === "SUCCESS") {
                    let content = JSON.parse(this.jsonDataService.decrypt(data.response));
                    this.jsonDataService.contorlLoading(false);
                    this.tblConfigAccountType.loading = false;
                    this.tblConfigAccountType.tblData = content.data;
                } else {
                    this.jsonDataService.contorlLoading(false);
                    this.tblConfigAccountType.loading = false;
                    this.appMessageService.showAppMessage(data.message, "error");
                }
              });
            });
          },
          error: () => {
            this.jsonDataService.contorlLoading(false);
            this.tblConfigAccountType.loading = false;
          }
            });
    }
    catch (e) {
    console.error(e);
    }
  }

  getAllAmtCoveredPremAmtLmtPerAccType() {
    try {
      this.tblConfigAmtCoveredPremAmtLmtPerAccType.loading = true;
      this.jsonDataService.contorlLoading(true);
      this._APICallService.getAllAmtCoveredPremAmtLmtPerAccType({
          moduleId: this.moduleId,
          userId: this.userDetailService.userId,
          type: "LOV"
      }).subscribe({
        next: (data: any) => {
            this.securityService.checkRequestKeyResponse(data, () => {
              this.securityService.hasValidCsrfToken(data, () => {
                if (data.status === "SUCCESS") {
                  data = JSON.parse(this.jsonDataService.decrypt(data.response));
                  let filteredData = data.data.filter((d: any) =>
                      (d.USER_TYPE == this.accountTypeForm.get('userTypeCd')?.value.toUpperCase()));
                  this.jsonDataService.contorlLoading(false);
                  this.tblConfigAmtCoveredPremAmtLmtPerAccType.loading = false;
                  this.tblConfigAmtCoveredPremAmtLmtPerAccType.tblData = filteredData;
                  
                } else {
                    this.jsonDataService.contorlLoading(false);
                    this.tblConfigAmtCoveredPremAmtLmtPerAccType.loading = false;
                    this.appMessageService.showAppMessage(data.message, "error");
                }
              });
            });
          },
          error: (e: any) => {
              this.tblConfigAmtCoveredPremAmtLmtPerAccType.loading = false;
              this.jsonDataService.contorlLoading(false);
          }
            });
    }
    catch (e) {
    console.error(e);
    }
  }

  onRowClickAccountType(ev: any) {
    try {    
      if(ev!=null) {
        this.formService.hideFormMsg("account-type-error-message");
        this.rowClickedData = ev;
        this.loadFormValuesAccountType();
        this.getAllAmtCoveredPremAmtLmtPerAccType();
        this.saveDisabledAccountType = this.saveCheckerAccountType();
        this.deleteDisabledAccountType = this.deleteCheckerAccountType(ev);
        this.resetFormValuesAmtCoveredPremAmtLmtPerAccType();
        this.isAccountTypeSelected = true;
      }
      else {
        this.tblConfigAmtCoveredPremAmtLmtPerAccType.tblData =[];
        this.resetFormValuesAccountType();
        this.resetFormValuesAmtCoveredPremAmtLmtPerAccType();
        this.isAccountTypeSelected = false;
      }
    }
    catch(e) {
    }
  }

  onRowClickAmtCoveredPremAmtLmtPerAccType(ev: any) {
    try {    
      if(ev!=null) {
        this.formService.hideFormMsg("account-type-amt-error-message");
        this.rowClickedData2 = ev;
        this.loadFormValuesAmtCoveredPremAmtLmtPerAccType();
        this.saveDisabledAmtCoveredPremAmtLmtPerAccType = this.saveCheckerAmtCoveredPremAmtLmtPerAccType();
        this.deleteDisabledAmtCoveredPremAmtLmtPerAccType = this.deleteCheckerAmtCoveredPremAmtLmtPerAccType(ev);
        this.deleteDisabledAmtCoveredPremAmtLmtPerAccType ? this.amtCoveredPremAmtLmtPerAccTypeForm.get('itemTypeCd')?.disable() : this.amtCoveredPremAmtLmtPerAccTypeForm.get('itemTypeCd')?.enable();
        this.deleteDisabledAmtCoveredPremAmtLmtPerAccType ? this.amtCoveredPremAmtLmtPerAccTypeForm.get('userLvl')?.disable() : this.amtCoveredPremAmtLmtPerAccTypeForm.get('userLvl')?.enable();
        this.isAmtCoveredPremAmtLmtPerAccTypeSelected = true;
      }
      else {
        this.amtCoveredPremAmtLmtPerAccTypeForm.get('itemTypeCd')?.enable();
        this.amtCoveredPremAmtLmtPerAccTypeForm.get('userLvl')?.enable();
        this.resetFormValuesAmtCoveredPremAmtLmtPerAccType();
        this.isAmtCoveredPremAmtLmtPerAccTypeSelected = false;
      }
    }
    catch(e) {
    }
  }

  loadFormValuesAccountType() {
    try {
      this.accountTypeForm.patchValue({
        userTypeCd: this.rowClickedData.USER_TYPE,
        activeTag: this.rowClickedData.ACTIVE_TAG,
        userTypeDesc: this.rowClickedData.USER_TYPE_DESC,
        editAmtCovered: this.rowClickedData.EDIT_SI_TAG,
        editPremRate: this.rowClickedData.EDIT_PREM_TAG,
        editPremAmt: this.rowClickedData.EDIT_PREM_AMT_TAG,
        hidePremRate: this.rowClickedData.HIDE_PREM_RT_TAG,
        hideComm: this.rowClickedData.HIDE_COMM_TAG,
        mainUserType: this.rowClickedData.MAIN_USER_TYPE,
        agentCd: this.rowClickedData.AGENT_CD,
        agentName: this.rowClickedData.AGENT_NAME,
        agentDisp: this.rowClickedData.AGENT_CD ? this.rowClickedData.AGENT_CD + ' - '+ this.rowClickedData.AGENT_NAME : '',
        projectCd: this.rowClickedData.PROJECT_CD,
        projectName: this.rowClickedData.PROJECT_DESC,
        projectDisp: this.rowClickedData.PROJECT_CD ? this.rowClickedData.PROJECT_CD + ' - '+ this.rowClickedData.PROJECT_DESC : '',
        clientType: this.rowClickedData.CLIENT_CD,
        clientTypeName: this.rowClickedData.CLIENT_DESC,
        clientTypeDisp: this.rowClickedData.CLIENT_CD ? this.rowClickedData.CLIENT_CD + ' - ' + this.rowClickedData.CLIENT_DESC : '',
        riskProfCd: this.rowClickedData.RISK_PROF_CD,
        riskProfName: this.rowClickedData.RISK_PROF_DESC,
        riskProfDisp: this.rowClickedData.RISK_PROF_CD ? this.rowClickedData.RISK_PROF_CD + ' - '+ this.rowClickedData.RISK_PROF_DESC : '',
        enOtp: this.rowClickedData.ENABLE_OTP,
        enClientCd: this.rowClickedData.ENABLE_CLIENT_CD,
        enPaytTerms: this.rowClickedData.PAYT_TERMS_TAG,
        paytTermsPath: this.rowClickedData.PAYT_TERMS_PATH,
        remarks: this.rowClickedData.REMARKS,
        lastUpdateName: this.rowClickedData.USER_ID,   
        lastUserUpdate: this.rowClickedData.LAST_UPDATE
      });
    }
    catch (e) {
    console.error(e);
    }
  }

  loadFormValuesAmtCoveredPremAmtLmtPerAccType() {
    try {
      this.rowClickedData2.SI_AMT_LIMIT = this.rowClickedData2.SI_AMT_LIMIT !== null && this.rowClickedData2.SI_AMT_LIMIT !== "" && typeof this.rowClickedData2.SI_AMT_LIMIT === 'string' ? +this.rowClickedData2.SI_AMT_LIMIT.replace(/,/g, '') : this.rowClickedData2.SI_AMT_LIMIT;
      this.rowClickedData2.SI_MIN_PCT = this.rowClickedData2.SI_MIN_PCT !== null && this.rowClickedData2.SI_MIN_PCT !== "" && typeof this.rowClickedData2.SI_MIN_PCT === 'string' ? +this.rowClickedData2.SI_MIN_PCT.replace(/,/g, '') : this.rowClickedData2.SI_MIN_PCT;
      this.rowClickedData2.SI_MAX_PCT = this.rowClickedData2.SI_MAX_PCT !== null && this.rowClickedData2.SI_MAX_PCT !== "" && typeof this.rowClickedData2.SI_MAX_PCT === 'string' ? +this.rowClickedData2.SI_MAX_PCT.replace(/,/g, '') : this.rowClickedData2.SI_MAX_PCT;
      this.rowClickedData2.PREM_RT_MIN = this.rowClickedData2.PREM_RT_MIN !== null && this.rowClickedData2.PREM_RT_MIN !== "" && typeof this.rowClickedData2.PREM_RT_MIN === 'string' ? +this.rowClickedData2.PREM_RT_MIN.replace(/,/g, '') : this.rowClickedData2.PREM_RT_MIN;
      this.rowClickedData2.PREM_RT_MAX = this.rowClickedData2.PREM_RT_MAX !== null && this.rowClickedData2.PREM_RT_MAX !== "" && typeof this.rowClickedData2.PREM_RT_MAX === 'string' ? +this.rowClickedData2.PREM_RT_MAX.replace(/,/g, '') : this.rowClickedData2.PREM_RT_MAX;
      this.amtCoveredPremAmtLmtPerAccTypeForm.patchValue({
        userType:  this.accountTypeForm.get('userTypeCd')?.value,
        userLvl: this.rowClickedData2.USER_LEVEL,
        userLvlDesc: this.rowClickedData2.USER_LEVEL + ' - ' + this.jsonDataService.data.refCds.filter((data: any) => data.rvDomain === 'USER_LEVEL' && data.rvMeaning === this.rowClickedData2.USER_LEVEL).map((data: any) => { return data.rvLowValue }),
        itemTypeCd: this.rowClickedData2.ITEM_TYPE_CD,
        itemTypeDesc: this.rowClickedData2.ITEM_TYPE_CD + ' - ' + this.jsonDataService.data.refCds.filter((data: any) => data.rvDomain === 'ITEM_TYPE_CD' && data.rvMeaning === this.rowClickedData2.ITEM_TYPE_CD).map((data: any) => { return data.rvLowValue }),
        activeTag: this.rowClickedData2.ACTIVE_TAG,
        lineCd: this.rowClickedData2.LINE_CD,
        lineName: this.rowClickedData2.LINE_NAME,
        lineCdDisp: this.rowClickedData2.LINE_CD + ' - ' +this.rowClickedData2.LINE_NAME,
        perilCd: this.rowClickedData2.PERIL_CD,
        perilCdName: this.rowClickedData2.PERIL_LNAME,
        perilField: this.rowClickedData2.PERIL_CD + ' - '+this.rowClickedData2.PERIL_LNAME,
        amtCoveredLmt: this.decimalPipe.transform(this.rowClickedData2.SI_AMT_LIMIT, '1.2-2'),
        amtCoveredMinPct: this.decimalPipe.transform(this.rowClickedData2.SI_MIN_PCT, '1.9'),
        amtCoveredMaxPct: this.decimalPipe.transform(this.rowClickedData2.SI_MAX_PCT, '1.9'),
        minPremRate: this.decimalPipe.transform(this.rowClickedData2.PREM_RT_MIN, '1.9'),
        maxPremRate: this.decimalPipe.transform(this.rowClickedData2.PREM_RT_MAX, '1.9'),
        remarks: this.rowClickedData2.REMARKS,
        lastUpdateName: this.rowClickedData2.USER_ID,   
        lastUserUpdate: this.rowClickedData2.LAST_UPDATE
      });
    }
    catch (e) {
    console.error(e);
    }
  }

  resetFormValuesAccountType() {
    try {
      this.accountTypeForm.reset();
      this.accountTypeForm.patchValue({
        activeTag: 'A',
        editAmtCovered: 'N',
        editPremRate: 'N',
        editPremAmt: 'N',
        hidePremRate: 'N',
        hideComm: 'N',
        enOtp: 'N',
        enClientCd: 'N',
        enPaytTerms: 'N',
      });
      this.saveDisabledAccountType = this.saveCheckerAccountType();
      this.deleteDisabledAccountType = true;
      this.isAccountTypeSelected = false;
      this.rowClickedData = '' || null;
      this.formService.hideFormMsg("account-type-error-message");
    }
    catch (e) {
    console.error(e);
    }
  }

  resetFormValuesAmtCoveredPremAmtLmtPerAccType() {
    this.amtCoveredPremAmtLmtPerAccTypeForm.get('itemTypeCd')?.enable();
    this.amtCoveredPremAmtLmtPerAccTypeForm.get('userLvl')?.enable();
    try {
      this.amtCoveredPremAmtLmtPerAccTypeForm.reset();
      this.amtCoveredPremAmtLmtPerAccTypeForm.patchValue({
        activeTag: 'A',
      });
      this.saveDisabledAmtCoveredPremAmtLmtPerAccType = this.saveCheckerAmtCoveredPremAmtLmtPerAccType();
      this.deleteDisabledAmtCoveredPremAmtLmtPerAccType = true;
      this.isAmtCoveredPremAmtLmtPerAccTypeSelected = false;
      this.rowClickedData2 = '' || null;
      this.formService.hideFormMsg("account-type-amt-error-message");
    }
    catch (e) {
    console.error(e);
    }
  }

  deleteCheckerAccountType(ev: any) {
    try {
      let tempDataFlag;
      tempDataFlag = this.tempAddedDataAccountType.filter(function(e: any) {
        return e.USER_TYPE==ev.USER_TYPE;
      });
      if(ev.temp==true){
        return false;
      }else if(tempDataFlag==''||tempDataFlag==null) {
        return true;
      }
       else {
        return false;
      }
    }
    catch(e) {
      return true;
    }
  }

  deleteCheckerAmtCoveredPremAmtLmtPerAccType(ev: any) {
    try {
      let tempDataFlag;
      tempDataFlag = this.tempAddedDataAmtCoveredPremAmtLmtPerAccType.filter(function(e: any) {
        return e.USER_TYPE==ev.USER_TYPE;
      });
      if(ev.temp==true){
        return false;
      }
      else if(tempDataFlag==''||tempDataFlag==null) {
        return true;
      } else {
        return false;
      }
    }
    catch(e) {
      return true;
    }
  }

  saveCheckerAccountType() {
    try {
      if((this.tempAddedDataAccountType==null || this.tempAddedDataAccountType=='') && (this.tempUpdateDataAccountType==null || this.tempUpdateDataAccountType=='')) {
        return true;
      }
      else {
        return false;
      }
    }
    catch(e) {
      return true;
    }
  }

  saveCheckerAmtCoveredPremAmtLmtPerAccType() {
    try {
      if((this.tempAddedDataAmtCoveredPremAmtLmtPerAccType==null || this.tempAddedDataAmtCoveredPremAmtLmtPerAccType=='') && (this.tempUpdateDataAmtCoveredPremAmtLmtPerAccType==null || this.tempUpdateDataAmtCoveredPremAmtLmtPerAccType=='')) {
        return true;
      }
      else {
        return false;
      }
    }
    catch(e) {
      return true;
    }
  }

  addAccountType() {
    try {
      this.tranPosted = true;
      //this.validateInsertAccountType();
      if (this.accountTypeForm.valid&&this.userTypeDupCheck()&&this.userTypeDescDupCheck()) {
        this.addFormToTableAccountType();
      }
      else{
        if(!this.userTypeDescDupCheck()){
          this.userTypeDescDupCheck();
        }
        else if(!this.userTypeDupCheck()){
          this.userTypeDupCheck();
        }
        else if(this.saveCheckerAccountType()||this.accountTypeForm.invalid){
          this.formService.showFormMsg("account-type-error-message", 'There are missing information. Please provide necessary information needed', "E");
        }
      }
    }
    catch(e) {
    }
  }

  addFormToTableAccountType() {
    try {
      let formToData: any = { 
        USER_TYPE: this.accountTypeForm.get('userTypeCd')?.value,
        USER_TYPE_DESC: this.accountTypeForm.get('userTypeDesc')?.value,
        EDIT_SI_TAG: this.accountTypeForm.get('editAmtCovered')?.value,
        EDIT_PREM_TAG: this.accountTypeForm.get('editPremRate')?.value,
        EDIT_PREM_AMT_TAG: this.accountTypeForm.get('editPremAmt')?.value,
        HIDE_PREM_RT_TAG: this.accountTypeForm.get('hidePremRate')?.value,
        HIDE_COMM_TAG: this.accountTypeForm.get('hideComm')?.value,
        MAIN_USER_TYPE: this.accountTypeForm.get('mainUserType')?.value,
        AGENT_CD: this.accountTypeForm.get('agentCd')?.value,
        AGENT_NAME: this.accountTypeForm.get('agentName')?.value,
        PROJECT_CD: this.accountTypeForm.get('projectCd')?.value,
        PROJECT_DESC: this.accountTypeForm.get('projectName')?.value,
        CLIENT_CD: this.accountTypeForm.get('clientType')?.value,
        CLIENT_DESC: this.accountTypeForm.get('clientTypeName')?.value,
        RISK_PROF_CD: this.accountTypeForm.get('riskProfCd')?.value,
        RISK_PROF_DESC: this.accountTypeForm.get('riskProfName')?.value,
        ENABLE_OTP: this.accountTypeForm.get('enOtp')?.value,
        ENABLE_CLIENT_CD: this.accountTypeForm.get('enClientCd')?.value,
        PAYT_TERMS_TAG: this.accountTypeForm.get('enPaytTerms')?.value,
        PAYT_TERMS_PATH: this.accountTypeForm.get('paytTermsPath')?.value,
        ACTIVE_TAG: this.accountTypeForm.get('activeTag')?.value,
        REMARKS: this.accountTypeForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.accountTypeForm.get('lastUserUpdate')?.value), 'MM/dd/yyyy h:mm:ss a'),
        USER_ID: this.accountTypeForm.get('lastUpdateName')?.value,
        temp: true
      }
      
      this.tblConfigAccountType.tblData.unshift(formToData);
      this.tblConfigAccountType.tblData = [...this.tblConfigAccountType.tblData];
      this.tempAddedDataAccountType.push(formToData);
      this.resetFormValuesAccountType();
      this.appMessageService.showAppMessage("New account type added!", "success");
    }
    catch(e) {
    }
  }

  addAmtCoveredPremAmtLmtPerAccType() {
    try {
      this.tranPosted = true;
      //this.validateInsertAmtCoveredPremAmtLmtPerAccType();
      this.amtCoveredPremAmtLmtPerAccTypeForm.patchValue({
        userType: this.accountTypeForm.get('userTypeCd')?.value
      });
      if (this.amtCoveredPremAmtLmtPerAccTypeForm.valid&&this.checkAmount()&&this.combinationAmtCoveredDupCheck()&&this.saveCheckerAccountType()&&this.numLimitCheck()&&this.isAccountTypeSelected==true) {
        this.addFormToTableAmtCoveredPremAmtLmtPerAccType();
      }
      else{
        if(!this.checkAmount()){
          this.checkAmount();
        }
        else if(!this.numLimitCheck()){
          this.numLimitCheck();
        }
        else if(!this.combinationAmtCoveredDupCheck()){
          this.combinationAmtCoveredDupCheck();
        }
        else if(!this.saveCheckerAccountType()){
          this.formService.showFormMsg("account-type-amt-error-message", 'Kindy save first the information in the Account Type Maintenance above.', "E");
        }
        else{
          this.formService.showFormMsg("account-type-amt-error-message", 'There are missing information. Please provide necessary information needed.', "E");
        }
      }
    }
    catch(e) {
    }
  }

  addFormToTableAmtCoveredPremAmtLmtPerAccType() {
    if(this.amtCoveredPremAmtLmtPerAccTypeForm.valid){
      try {
        let formToScreen: any = { 
          USER_TYPE: this.accountTypeForm.get('userTypeCd')?.value,
          USER_LEVEL: this.amtCoveredPremAmtLmtPerAccTypeForm.get('userLvl')?.value,
          USER_LEVEL_DESC: this.amtCoveredPremAmtLmtPerAccTypeForm.get('userLvl')?.value + ' - ' + this.jsonDataService.data.refCds.filter((data: any) => data.rvDomain === 'USER_LEVEL' && data.rvLowValue === this.amtCoveredPremAmtLmtPerAccTypeForm.get('userLvl')?.value).map((data: any) => { return data.rvMeaning }),
          ITEM_TYPE_CD: this.amtCoveredPremAmtLmtPerAccTypeForm.get('itemTypeCd')?.value,
          ITEM_TYPE_DESC: this.amtCoveredPremAmtLmtPerAccTypeForm.get('itemTypeCd')?.value + ' - ' + this.jsonDataService.data.refCds.filter((data: any) => data.rvDomain === 'ITEM_TYPE_CD' && data.rvLowValue === this.amtCoveredPremAmtLmtPerAccTypeForm.get('itemTypeCd')?.value).map((data: any) => { return data.rvMeaning }),
          LINE_CD: this.amtCoveredPremAmtLmtPerAccTypeForm.get('lineCd')?.value,
          LINE_NAME: this.amtCoveredPremAmtLmtPerAccTypeForm.get('lineCdDisp')?.value,
          PERIL_CD: this.amtCoveredPremAmtLmtPerAccTypeForm.get('perilCd')?.value,
          PERIL_LNAME: this.amtCoveredPremAmtLmtPerAccTypeForm.get('perilCdName')?.value,
          SI_AMT_LIMIT: this.amtCoveredPremAmtLmtPerAccTypeForm.get('amtCoveredLmt')?.value,
          SI_MIN_PCT: this.amtCoveredPremAmtLmtPerAccTypeForm.get('amtCoveredMinPct')?.value,
          SI_MAX_PCT: this.amtCoveredPremAmtLmtPerAccTypeForm.get('amtCoveredMaxPct')?.value,
          PREM_RT_MIN: this.amtCoveredPremAmtLmtPerAccTypeForm.get('minPremRate')?.value,
          PREM_RT_MAX: this.amtCoveredPremAmtLmtPerAccTypeForm.get('maxPremRate')?.value,
          ACTIVE_TAG: this.amtCoveredPremAmtLmtPerAccTypeForm.get('activeTag')?.value,
          REMARKS: this.amtCoveredPremAmtLmtPerAccTypeForm.get('remarks')?.value,
          LAST_UPDATE: this.datePipe.transform(new Date(this.accountTypeForm.get('lastUserUpdate')?.value), 'MM/dd/yyyy h:mm:ss a'),
          USER_ID: this.amtCoveredPremAmtLmtPerAccTypeForm.get('lastUpdateName')?.value,
          temp: true
        }
        let formToData: any = { 
          USER_TYPE: this.accountTypeForm.get('userTypeCd')?.value,
          USER_LEVEL: this.amtCoveredPremAmtLmtPerAccTypeForm.get('userLvl')?.value,
          USER_LEVEL_DESC: this.amtCoveredPremAmtLmtPerAccTypeForm.get('userLvl')?.value + ' - ' + this.jsonDataService.data.refCds.filter((data: any) => data.rvDomain === 'USER_LEVEL' && data.rvLowValue === this.amtCoveredPremAmtLmtPerAccTypeForm.get('userLvl')?.value).map((data: any) => { return data.rvMeaning }),
          ITEM_TYPE_CD: this.amtCoveredPremAmtLmtPerAccTypeForm.get('itemTypeCd')?.value,
          ITEM_TYPE_DESC: this.amtCoveredPremAmtLmtPerAccTypeForm.get('itemTypeCd')?.value + ' - ' + this.jsonDataService.data.refCds.filter((data: any) => data.rvDomain === 'ITEM_TYPE_CD' && data.rvLowValue === this.amtCoveredPremAmtLmtPerAccTypeForm.get('itemTypeCd')?.value).map((data: any) => { return data.rvMeaning }),
          LINE_CD: this.amtCoveredPremAmtLmtPerAccTypeForm.get('lineCd')?.value,
          LINE_NAME: this.amtCoveredPremAmtLmtPerAccTypeForm.get('lineName')?.value,
          PERIL_CD: this.amtCoveredPremAmtLmtPerAccTypeForm.get('perilCd')?.value,
          PERIL_LNAME: this.amtCoveredPremAmtLmtPerAccTypeForm.get('perilCdName')?.value,
          SI_AMT_LIMIT: this.amtCoveredPremAmtLmtPerAccTypeForm.get('amtCoveredLmt')?.value,
          SI_MIN_PCT: this.amtCoveredPremAmtLmtPerAccTypeForm.get('amtCoveredMinPct')?.value,
          SI_MAX_PCT: this.amtCoveredPremAmtLmtPerAccTypeForm.get('amtCoveredMaxPct')?.value,
          PREM_RT_MIN: this.amtCoveredPremAmtLmtPerAccTypeForm.get('minPremRate')?.value,
          PREM_RT_MAX: this.amtCoveredPremAmtLmtPerAccTypeForm.get('maxPremRate')?.value,
          ACTIVE_TAG: this.amtCoveredPremAmtLmtPerAccTypeForm.get('activeTag')?.value,
          REMARKS: this.amtCoveredPremAmtLmtPerAccTypeForm.get('remarks')?.value,
          LAST_UPDATE: this.datePipe.transform(new Date(this.accountTypeForm.get('lastUserUpdate')?.value), 'MM/dd/yyyy h:mm:ss a'),
          USER_ID: this.amtCoveredPremAmtLmtPerAccTypeForm.get('lastUpdateName')?.value,
          temp: true
        }
        this.tblConfigAmtCoveredPremAmtLmtPerAccType.tblData.unshift(formToScreen);
        this.tblConfigAmtCoveredPremAmtLmtPerAccType.tblData = [...this.tblConfigAmtCoveredPremAmtLmtPerAccType.tblData];
        this.tempAddedDataAmtCoveredPremAmtLmtPerAccType.push(formToData);
        this.resetFormValuesAmtCoveredPremAmtLmtPerAccType();
        this.appMessageService.showAppMessage("New account type details added!", "success");
      }
      catch(e) {
      }
  }
  }
  
  form : any;

  updateAccountType() {
    this.formService.showFormLoader(null, "generate-emp-form", "Please wait ...", null, null);
    try {
      this.tranPosted = true;
      this.form = this.accountTypeForm.getRawValue();
      if (this.accountTypeForm.valid&&this.checkUpdateAccountType()) {
        this.updateFormTableAccountType();
      }
      else{
        if(!this.userTypeDescDupCheck()){
          this.userTypeDescDupCheck();
        }
        else {
          this.formService.showFormMsg("account-type-error-message", 'There are missing information. Please provide necessary information needed.', "E");
        }
      }
    }
    catch(e) {

    }
  }

  checkUpdateAccountType(){
    if(this.isAccountTypeSelected&&(this.rowClickedData.USER_TYPE_DESC.trim().toUpperCase() === this.form.userTypeDesc.trim().toUpperCase())){
      this.form = [];
      return true;
    }
    else if(this.isAccountTypeSelected&&!this.userTypeDescDupCheck()){
      this.form = [];
      this.formService.showFormMsg("account-type-error-message", 'Unable to add record. User Type Desc already exists. Please add a unique User Type Desc.', "E");
      return false;
    }
    else if(this.isAccountTypeSelected&&this.userTypeDescDupCheck()){
      this.form = [];
      return true;
    }
    else{
      this.form = [];
      this.formService.showFormMsg("account-type-error-message", 'Unable to add record. User Type Desc already exists. Please add a unique User Type Desc.', "E");
      return false;
    }
  }

  updateFormTableAccountType() {
    try {
      this.tableDataUpdateAccountType();
      this.tempUpdateDataAccountType.push(this.formValueToDataAccountType());
      this.saveDisabledAccountType = false;
      this.resetFormValuesAccountType();
      this.resetFormValuesAmtCoveredPremAmtLmtPerAccType();
      this.deleteDisabledAccountType = this.deleteCheckerAccountType(this.rowClickedData);
      this.appMessageService.showAppMessage("Account Type details updated!", "success");
    }
    catch(e) {
    }
  }

  tableDataUpdateAccountType() {
    try {
      this.accountTypeForm.patchValue({
        activeTag: this.accountTypeForm.get('activeTag')?.value,
        lastUpdateName: this.currentUser,
        lastUserUpdate: new Date(),
      });
      let updateIndex = this.tblConfigAccountType.tblData.indexOf(this.rowClickedData);
      if(this.rowClickedData.temp==true){
        this.tblConfigAccountType.tblData[updateIndex] = {
          USER_TYPE: this.accountTypeForm.get('userTypeCd')?.value,
          USER_TYPE_DESC: this.accountTypeForm.get('userTypeDesc')?.value,
          EDIT_SI_TAG: this.accountTypeForm.get('editAmtCovered')?.value,
          EDIT_PREM_TAG: this.accountTypeForm.get('editPremRate')?.value,
          EDIT_PREM_AMT_TAG: this.accountTypeForm.get('editPremAmt')?.value,
          HIDE_PREM_RT_TAG: this.accountTypeForm.get('hidePremRate')?.value,
          HIDE_COMM_TAG: this.accountTypeForm.get('hideComm')?.value,
          MAIN_USER_TYPE: this.accountTypeForm.get('mainUserType')?.value,
          AGENT_CD: this.accountTypeForm.get('agentCd')?.value,
          AGENT_NAME: this.accountTypeForm.get('agentName')?.value,
          PROJECT_CD: this.accountTypeForm.get('projectCd')?.value,
          PROJECT_DESC: this.accountTypeForm.get('projectName')?.value,
          CLIENT_CD: this.accountTypeForm.get('clientType')?.value,
          CLIENT_DESC: this.accountTypeForm.get('clientTypeName')?.value,
          RISK_PROF_CD: this.accountTypeForm.get('riskProfCd')?.value,
          RISK_PROF_DESC: this.accountTypeForm.get('riskProfName')?.value,
          ENABLE_OTP: this.accountTypeForm.get('enOtp')?.value,
          ENABLE_CLIENT_CD: this.accountTypeForm.get('enClientCd')?.value,
          PAYT_TERMS_TAG: this.accountTypeForm.get('enPaytTerms')?.value,
          PAYT_TERMS_PATH: this.accountTypeForm.get('paytTermsPath')?.value,
          ACTIVE_TAG: this.accountTypeForm.get('activeTag')?.value,
          REMARKS: this.accountTypeForm.get('remarks')?.value,
          LAST_USER_UPDATE: this.datePipe.transform(new Date(this.accountTypeForm.get('lastUserUpdate')?.value), 'MM/dd/yyyy h:mm:ss a'),
          USER_ID: this.accountTypeForm.get('lastUpdateName')?.value,
          temp: true
        };
      }
      else{
        this.tblConfigAccountType.tblData[updateIndex] = {
          USER_TYPE: this.accountTypeForm.get('userTypeCd')?.value,
          USER_TYPE_DESC: this.accountTypeForm.get('userTypeDesc')?.value,
          EDIT_SI_TAG: this.accountTypeForm.get('editAmtCovered')?.value,
          EDIT_PREM_TAG: this.accountTypeForm.get('editPremRate')?.value,
          EDIT_PREM_AMT_TAG: this.accountTypeForm.get('editPremAmt')?.value,
          HIDE_PREM_RT_TAG: this.accountTypeForm.get('hidePremRate')?.value,
          HIDE_COMM_TAG: this.accountTypeForm.get('hideComm')?.value,
          MAIN_USER_TYPE: this.accountTypeForm.get('mainUserType')?.value,
          AGENT_CD: this.accountTypeForm.get('agentCd')?.value,
          AGENT_NAME: this.accountTypeForm.get('agentName')?.value,
          PROJECT_CD: this.accountTypeForm.get('projectCd')?.value,
          PROJECT_DESC: this.accountTypeForm.get('projectName')?.value,
          CLIENT_CD: this.accountTypeForm.get('clientType')?.value,
          CLIENT_DESC: this.accountTypeForm.get('clientTypeName')?.value,
          RISK_PROF_CD: this.accountTypeForm.get('riskProfCd')?.value,
          RISK_PROF_DESC: this.accountTypeForm.get('riskProfName')?.value,
          ENABLE_OTP: this.accountTypeForm.get('enOtp')?.value,
          ENABLE_CLIENT_CD: this.accountTypeForm.get('enClientCd')?.value,
          PAYT_TERMS_TAG: this.accountTypeForm.get('enPaytTerms')?.value,
          PAYT_TERMS_PATH: this.accountTypeForm.get('paytTermsPath')?.value,
          ACTIVE_TAG: this.accountTypeForm.get('activeTag')?.value,
          REMARKS: this.accountTypeForm.get('remarks')?.value,
          LAST_USER_UPDATE: this.datePipe.transform(new Date(this.accountTypeForm.get('lastUserUpdate')?.value), 'MM/dd/yyyy h:mm:ss a'),
          USER_ID: this.accountTypeForm.get('lastUpdateName')?.value,
        };
      }
      this.tblConfigAccountType.tblData = [...this.tblConfigAccountType.tblData];
    }
    catch(e) {
    }
  }

  formValueToDataAccountType() {
    try {
      let formToData: any = { 
        USER_TYPE: this.accountTypeForm.get('userTypeCd')?.value,
        USER_TYPE_DESC: this.accountTypeForm.get('userTypeDesc')?.value,
        EDIT_SI_TAG: this.accountTypeForm.get('editAmtCovered')?.value,
        EDIT_PREM_TAG: this.accountTypeForm.get('editPremRate')?.value,
        EDIT_PREM_AMT_TAG: this.accountTypeForm.get('editPremAmt')?.value,
        HIDE_PREM_RT_TAG: this.accountTypeForm.get('hidePremRate')?.value,
        HIDE_COMM_TAG: this.accountTypeForm.get('hideComm')?.value,
        MAIN_USER_TYPE: this.accountTypeForm.get('mainUserType')?.value,
        AGENT_CD: this.accountTypeForm.get('agentCd')?.value,
        AGENT_NAME: this.accountTypeForm.get('agentName')?.value,
        PROJECT_CD: this.accountTypeForm.get('projectCd')?.value,
        CLIENT_CD: this.accountTypeForm.get('clientType')?.value,
        RISK_PROF_CD: this.accountTypeForm.get('riskProfCd')?.value,
        ENABLE_OTP: this.accountTypeForm.get('enOtp')?.value,
        ENABLE_CLIENT_CD: this.accountTypeForm.get('enClientCd')?.value,
        PAYT_TERMS_TAG: this.accountTypeForm.get('enPaytTerms')?.value,
        PAYT_TERMS_PATH: this.accountTypeForm.get('paytTermsPath')?.value,
        ACTIVE_TAG: this.accountTypeForm.get('activeTag')?.value,
        REMARKS: this.accountTypeForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.accountTypeForm.get('lastUserUpdate')?.value), 'MM/dd/yyyy h:mm:ss a'),
        USER_ID: this.accountTypeForm.get('lastUpdateName')?.value,
      }
      return formToData;
    }
    catch(e) {
    }
  }

  updateAmtCoveredPremAmtLmtPerAccType() {
    this.formService.hideFormMsg("account-type-amt-error-message");
    try {
      this.tranPosted = true;
      this.amtCoveredPremAmtLmtPerAccTypeForm.patchValue({
        userType: this.accountTypeForm.get('userTypeCd')?.value
      });
      this.amtCoveredPremAmtLmtPerAccTypeForm.get('itemTypeCd')?.enable();
      this.amtCoveredPremAmtLmtPerAccTypeForm.get('userLvl')?.enable();
      if (this.amtCoveredPremAmtLmtPerAccTypeForm.valid&&this.checkAmount()&&this.numLimitCheck()) {
        this.updateFormTableAmtCoveredPremAmtLmtPerAccType();
      }
      else{
        this.amtCoveredPremAmtLmtPerAccTypeForm.get('itemTypeCd')?.disable();
        this.amtCoveredPremAmtLmtPerAccTypeForm.get('userLvl')?.disable();
        if(!this.checkAmount()){
          this.checkAmount();
        }
        else if(!this.numLimitCheck()){
          this.numLimitCheck();
        }
        else{
          this.formService.showFormMsg("account-type-amt-error-message", 'An error has occured while updating details for amount covered and premium amount limit per account type', "E");
        }
      }
    }
    catch(e) {
    }
  }

  updateFormTableAmtCoveredPremAmtLmtPerAccType() {
    try {
      this.tableDataUpdateAmtCoveredPremAmtLmtPerAccType();
      this.tempUpdateDataAmtCoveredPremAmtLmtPerAccType.push(this.formValueToDataAmtCoveredPremAmtLmtPerAccType());
      this.saveDisabledAmtCoveredPremAmtLmtPerAccType = false;
      this.resetFormValuesAmtCoveredPremAmtLmtPerAccType();
      this.appMessageService.showAppMessage("Account Type details updated!", "success");
      this.rowClickedData2 = [];
    }
    catch(e) {
    }
  }

  tableDataUpdateAmtCoveredPremAmtLmtPerAccType() {
    try {
      this.amtCoveredPremAmtLmtPerAccTypeForm.patchValue({
        userType: this.accountTypeForm.get('userTypeCd')?.value,
        activeTag: this.amtCoveredPremAmtLmtPerAccTypeForm.get('activeTag')?.value,
        lastUpdateName: this.currentUser,
        lastUserUpdate: new Date(),
      });
      let updateIndex = this.tblConfigAmtCoveredPremAmtLmtPerAccType.tblData.indexOf(this.rowClickedData2);

      if(this.rowClickedData2.temp==true){
        this.tblConfigAmtCoveredPremAmtLmtPerAccType.tblData[updateIndex] = {
          USER_LEVEL: this.amtCoveredPremAmtLmtPerAccTypeForm.get('userLvl')?.value,
          USER_LEVEL_DESC: this.amtCoveredPremAmtLmtPerAccTypeForm.get('userLvl')?.value + ' - ' + this.jsonDataService.data.refCds.filter((data: any) => data.rvDomain === 'USER_LEVEL' && data.rvLowValue === this.amtCoveredPremAmtLmtPerAccTypeForm.get('userLvl')?.value).map((data: any) => { return data.rvMeaning }),
          ITEM_TYPE_CD: this.amtCoveredPremAmtLmtPerAccTypeForm.get('itemTypeCd')?.value,
          ITEM_TYPE_DESC: this.amtCoveredPremAmtLmtPerAccTypeForm.get('itemTypeCd')?.value + ' - ' + this.jsonDataService.data.refCds.filter((data: any) => data.rvDomain === 'ITEM_TYPE_CD' && data.rvLowValue === this.amtCoveredPremAmtLmtPerAccTypeForm.get('itemTypeCd')?.value).map((data: any) => { return data.rvMeaning }),
          LINE_CD: this.amtCoveredPremAmtLmtPerAccTypeForm.get('lineCd')?.value,
          LINE_NAME: this.amtCoveredPremAmtLmtPerAccTypeForm.get('lineName')?.value,
          PERIL_CD: this.amtCoveredPremAmtLmtPerAccTypeForm.get('perilCd')?.value,
          PERIL_LNAME: this.amtCoveredPremAmtLmtPerAccTypeForm.get('perilCdName')?.value,
          SI_AMT_LIMIT: this.amtCoveredPremAmtLmtPerAccTypeForm.get('amtCoveredLmt')?.value,
          SI_MIN_PCT: this.amtCoveredPremAmtLmtPerAccTypeForm.get('amtCoveredMinPct')?.value,
          SI_MAX_PCT: this.amtCoveredPremAmtLmtPerAccTypeForm.get('amtCoveredMaxPct')?.value,
          PREM_RT_MIN: this.amtCoveredPremAmtLmtPerAccTypeForm.get('minPremRate')?.value,
          PREM_RT_MAX: this.amtCoveredPremAmtLmtPerAccTypeForm.get('maxPremRate')?.value,
          ACTIVE_TAG: this.amtCoveredPremAmtLmtPerAccTypeForm.get('activeTag')?.value,
          REMARKS: this.amtCoveredPremAmtLmtPerAccTypeForm.get('remarks')?.value,
          LAST_UPDATE: this.datePipe.transform(new Date(this.accountTypeForm.get('lastUserUpdate')?.value), 'MM/dd/yyyy h:mm:ss a'),
          USER_ID: this.amtCoveredPremAmtLmtPerAccTypeForm.get('lastUpdateName')?.value,
          temp: true
        };
      }
      else{
        this.tblConfigAmtCoveredPremAmtLmtPerAccType.tblData[updateIndex] = {
          USER_LEVEL: this.amtCoveredPremAmtLmtPerAccTypeForm.get('userLvl')?.value,
          USER_LEVEL_DESC: this.amtCoveredPremAmtLmtPerAccTypeForm.get('userLvl')?.value + ' - ' + this.jsonDataService.data.refCds.filter((data: any) => data.rvDomain === 'USER_LEVEL' && data.rvLowValue === this.amtCoveredPremAmtLmtPerAccTypeForm.get('userLvl')?.value).map((data: any) => { return data.rvMeaning }),
          ITEM_TYPE_CD: this.amtCoveredPremAmtLmtPerAccTypeForm.get('itemTypeCd')?.value,
          ITEM_TYPE_DESC: this.amtCoveredPremAmtLmtPerAccTypeForm.get('itemTypeCd')?.value + ' - ' + this.jsonDataService.data.refCds.filter((data: any) => data.rvDomain === 'ITEM_TYPE_CD' && data.rvLowValue.toString() === this.amtCoveredPremAmtLmtPerAccTypeForm.get('itemTypeCd')?.value.toString()).map((data: any) => { return data.rvMeaning }),
          LINE_CD: this.amtCoveredPremAmtLmtPerAccTypeForm.get('lineCd')?.value,
          LINE_NAME: this.amtCoveredPremAmtLmtPerAccTypeForm.get('lineName')?.value,
          PERIL_CD: this.amtCoveredPremAmtLmtPerAccTypeForm.get('perilCd')?.value,
          PERIL_LNAME: this.amtCoveredPremAmtLmtPerAccTypeForm.get('perilCdName')?.value,
          SI_AMT_LIMIT: this.amtCoveredPremAmtLmtPerAccTypeForm.get('amtCoveredLmt')?.value,
          SI_MIN_PCT: this.amtCoveredPremAmtLmtPerAccTypeForm.get('amtCoveredMinPct')?.value,
          SI_MAX_PCT: this.amtCoveredPremAmtLmtPerAccTypeForm.get('amtCoveredMaxPct')?.value,
          PREM_RT_MIN: this.amtCoveredPremAmtLmtPerAccTypeForm.get('minPremRate')?.value,
          PREM_RT_MAX: this.amtCoveredPremAmtLmtPerAccTypeForm.get('maxPremRate')?.value,
          ACTIVE_TAG: this.amtCoveredPremAmtLmtPerAccTypeForm.get('activeTag')?.value,
          REMARKS: this.amtCoveredPremAmtLmtPerAccTypeForm.get('remarks')?.value,
          LAST_UPDATE: this.datePipe.transform(new Date(this.accountTypeForm.get('lastUserUpdate')?.value), 'MM/dd/yyyy h:mm:ss a'),
          USER_ID: this.amtCoveredPremAmtLmtPerAccTypeForm.get('lastUpdateName')?.value,
        };
      }
      this.tblConfigAmtCoveredPremAmtLmtPerAccType.tblData = [...this.tblConfigAmtCoveredPremAmtLmtPerAccType.tblData];
    }
    catch(e) {
    }
  }

  formValueToDataAmtCoveredPremAmtLmtPerAccType() {
    try {
      let formToData: any = { 
        USER_TYPE: this.accountTypeForm.get('userTypeCd')?.value,
        USER_LEVEL: this.amtCoveredPremAmtLmtPerAccTypeForm.get('userLvl')?.value,
        USER_LEVEL_DESC: this.amtCoveredPremAmtLmtPerAccTypeForm.get('userLvl')?.value + ' - ' + this.jsonDataService.data.refCds.filter((data: any) => data.rvDomain === 'USER_LEVEL' && data.rvMeaning === this.amtCoveredPremAmtLmtPerAccTypeForm.get('userLvl')?.value).map((data: any) => { return data.rvLowValue }),
        ITEM_TYPE_CD: this.amtCoveredPremAmtLmtPerAccTypeForm.get('itemTypeCd')?.value,
        ITEM_TYPE_DESC: this.amtCoveredPremAmtLmtPerAccTypeForm.get('itemTypeCd')?.value + ' - ' + this.jsonDataService.data.refCds.filter((data: any) => data.rvDomain === 'ITEM_TYPE_CD' && data.rvMeaning === this.amtCoveredPremAmtLmtPerAccTypeForm.get('itemTypeCd')?.value).map((data: any) => { return data.rvLowValue }),
        LINE_CD: this.amtCoveredPremAmtLmtPerAccTypeForm.get('lineCd')?.value,
        LINE_NAME: this.amtCoveredPremAmtLmtPerAccTypeForm.get('lineName')?.value,
        PERIL_CD: this.amtCoveredPremAmtLmtPerAccTypeForm.get('perilCd')?.value,
        PERIL_LNAME: this.amtCoveredPremAmtLmtPerAccTypeForm.get('perilCdDisp')?.value,
        SI_AMT_LIMIT: this.amtCoveredPremAmtLmtPerAccTypeForm.get('amtCoveredLmt')?.value,
        SI_MIN_PCT: this.amtCoveredPremAmtLmtPerAccTypeForm.get('amtCoveredMinPct')?.value,
        SI_MAX_PCT: this.amtCoveredPremAmtLmtPerAccTypeForm.get('amtCoveredMaxPct')?.value,
        PREM_RT_MIN: this.amtCoveredPremAmtLmtPerAccTypeForm.get('minPremRate')?.value,
        PREM_RT_MAX: this.amtCoveredPremAmtLmtPerAccTypeForm.get('maxPremRate')?.value,
        ACTIVE_TAG: this.amtCoveredPremAmtLmtPerAccTypeForm.get('activeTag')?.value,
        REMARKS: this.amtCoveredPremAmtLmtPerAccTypeForm.get('remarks')?.value,
        LAST_UPDATE: this.datePipe.transform(new Date(this.accountTypeForm.get('lastUserUpdate')?.value), 'MM/dd/yyyy h:mm:ss a'),
        USER_ID: this.amtCoveredPremAmtLmtPerAccTypeForm.get('lastUpdateName')?.value,
      }
      return formToData;
    }
    catch(e) {
    }
  }

  deleteAccountType() {
    try {
      let clickData = this.rowClickedData;
      this.tblConfigAccountType.tblData = this.tblConfigAccountType.tblData.filter(
        function(e: any) {
          return e.USER_TYPE != clickData.USER_TYPE;
        }
      );
      this.tempAddedDataAccountType = this.tempAddedDataAccountType.filter(
        function(e: any) {
          return e.USER_TYPE != clickData.USER_TYPE;
        }
      );
      this.resetFormValuesAccountType();
    }
    catch(e) {
    }
  }

  deleteAmtCoveredPremAmtLmtPerAccType() {
    try {
      let clickData = this.rowClickedData2;
      this.tblConfigAmtCoveredPremAmtLmtPerAccType.tblData = this.tblConfigAmtCoveredPremAmtLmtPerAccType.tblData.filter(
        function(e: any) {
          return e.USER_TYPE!=clickData.USER_TYPE||e.USER_LEVEL!=clickData.USER_LEVEL||
            e.ITEM_TYPE_CD!=clickData.ITEM_TYPE_CD||e.LINE_CD!=clickData.LINE_CD
            ||e.PERIL_CD!=clickData.PERIL_CD;
        }
      );
      this.tempAddedDataAmtCoveredPremAmtLmtPerAccType = this.tempAddedDataAmtCoveredPremAmtLmtPerAccType.filter(
        function(e: any) {
          return e.USER_TYPE!=clickData.USER_TYPE&&e.USER_LEVEL!=clickData.USER_LEVEL&&
            e.ITEM_TYPE_CD!=clickData.ITEM_TYPE_CD&&e.LINE_CD!=clickData.LINE_CD
            &&e.PERIL_CD!=clickData.PERIL_CD;
        }
      );
      this.isAmtCoveredPremAmtLmtPerAccTypeSelected = false;
      this.resetFormValuesAmtCoveredPremAmtLmtPerAccType();
    }
    catch(e) {
    }
  }

  toSaveAccountType() {
    try {
      this.noLoadingInProcesstblConfigAccountType = false;
      this.formService.showFormLoader(null, "generate-curr-form", "Please wait ...", null, null);
      if(this.tempAddedDataAccountType.length!=0) {
        ///*
        this.saveAddedDataAccountType();
        //*/
      } else {
        if(this.tempUpdateDataAccountType.length!=0) {
            this.saveUpdateDataAccountType();
            //*/
        } else {
            this.resetFormValuesAccountType();
            this.tempAddedDataAccountType = [];
            this.tempUpdateDataAccountType = [];
        }
      }
    }
    catch(e) {
    }
  }

  saveAddedDataAccountType () {
    this._APICallService.saveAllAccType(this.tempAddedDataAccountType)
        .subscribe({
          next: (response : any) => {
                this.securityService.checkRequestKeyResponse(response, () => {
                  this.securityService.hasValidCsrfToken(response, () => {
                    if (response.status == "SUCCESS" && this.tranPosted) {
                        if(this.tempUpdateDataAccountType.length!=0) {
                            this.saveUpdateDataAccountType();
                            //*/
                        } else {
                            this.openDialog('SUCCESS', 'New account type details successfully saved!');
                            this.isSuccessSaved = true;
                            this.getAllAccountType();
                            this.tempAddedDataAccountType = [];
                            this.tempUpdateDataAccountType = [];
                            this.resetFormValuesAccountType();
                            this.resetFormValuesAmtCoveredPremAmtLmtPerAccType();
                        }
                    } else {
                      this.formService.showFormMsg("account-type-error-message", 'An error has occured while saving details for new account type', "E");
                    }
                  });
                });
          }, 
          error: () => {
            this.submitted = false;
            this.isSuccessSaved = false;
            this.formService.showFormMsg("account-type-error-message", 'An error has occured while saving details for new account type', "E");
          }
        });
  }

  saveUpdateDataAccountType() {
    this._APICallService.saveAllAccType(this.tempUpdateDataAccountType)
    .subscribe({
      next: (response : any) => {
            this.securityService.checkRequestKeyResponse(response, () => {
              this.securityService.hasValidCsrfToken(response, () => {
                if (response.status == "SUCCESS" && this.tranPosted) {
                    this.openDialog('SUCCESS', 'Existing account type details successfully saved!');
                    this.isSuccessSaved = true;
                    this.getAllAccountType();
                    this.tempAddedDataAccountType = [];
                    this.tempUpdateDataAccountType = [];
                    this.resetFormValuesAccountType();
                } else {
                    this.formService.showFormMsg("account-type-error-message", 'An error has occured while saving details for existing account type', "E");
                }
              });
            });
      }, 
      error: () => {
        this.submitted = false;
        this.isSuccessSaved = false;
        this.formService.showFormMsg("account-type-error-message", 'An error has occured while saving details for existing account type', "E");
      }
    });
  }

  toSaveAmtCoveredPremAmtLmtPerAccType() {
    try {
      this.noLoadingInProcessAmtCoveredPremAmtLmtPerAccType = false;
      this.formService.showFormLoader(null, "generate-curr-rt-form", "Please wait ...", null, null);
      if(this.tempAddedDataAmtCoveredPremAmtLmtPerAccType.length!=0) {
        ///*
        this.saveAddedDataAmtCoveredPremAmtLmtPerAccType();
        //*/
      } else {
        if(this.tempUpdateDataAmtCoveredPremAmtLmtPerAccType.length!=0) {
            this.saveUpdateDataAmtCoveredPremAmtLmtPerAccType();
            //*/
        } else {
          
          this.tempAddedDataAmtCoveredPremAmtLmtPerAccType = [];
          this.tempUpdateDataAmtCoveredPremAmtLmtPerAccType = [];
            this.resetFormValuesAmtCoveredPremAmtLmtPerAccType();
        }
      }
    }
    catch(e) {
    }
  }

  saveAddedDataAmtCoveredPremAmtLmtPerAccType () {
    this._APICallService.saveAllAmtCoveredPremAmtLmtPerAccType(this.tempAddedDataAmtCoveredPremAmtLmtPerAccType)
        .subscribe({
          next: (response : any) => {
                this.securityService.checkRequestKeyResponse(response, () => {
                  this.securityService.hasValidCsrfToken(response, () => {
                    if (response.status == "SUCCESS" && this.tranPosted) {
                        if(this.tempUpdateDataAmtCoveredPremAmtLmtPerAccType.length!=0) {
                          
                            this.saveUpdateDataAmtCoveredPremAmtLmtPerAccType();
                        } else {
                          
                            this.openDialog('SUCCESS', 'New amount covered and premium amount limit per account type details Successfully saved!');
                            
                            this.isSuccessSaved = true;
                            
                            this.getAllAmtCoveredPremAmtLmtPerAccType();
                            
                            this.tempAddedDataAmtCoveredPremAmtLmtPerAccType = [];
                            
                            this.tempUpdateDataAmtCoveredPremAmtLmtPerAccType = [];
                            
                            this.resetFormValuesAmtCoveredPremAmtLmtPerAccType();
                            
                        }
                    } else {
                      this.formService.showFormMsg("account-type-amt-error-message", 'An error has occured while saving details for new amount covered and premium amount limit per account type', "E");
                    }
                  });
                });
          }, 
          error: () => {
            this.submitted = false;
            this.isSuccessSaved = false;
            this.formService.showFormMsg("account-type-amt-error-message", 'An error has occured while saving details for new amount covered and premium amount limit per account type', "E");
          }
        });
  }

  saveUpdateDataAmtCoveredPremAmtLmtPerAccType() {
    this.tempUpdateDataAmtCoveredPremAmtLmtPerAccType = this.tempUpdateDataAmtCoveredPremAmtLmtPerAccType.map((e: any) => {
      return {
        ...e,
        PREM_RT_MAX: e.PREM_RT_MAX !== null && e.PREM_RT_MAX !== "" ? e.PREM_RT_MAX.toString() : "",
        PREM_RT_MIN: e.PREM_RT_MIN !== null && e.PREM_RT_MIN !== "" ? e.PREM_RT_MIN.toString() : "",
        SI_AMT_LIMIT: e.SI_AMT_LIMIT !== null && e.SI_AMT_LIMIT !== "" ? e.SI_AMT_LIMIT.toString() : "",
        SI_MAX_PCT: e.SI_MAX_PCT !== null && e.SI_MAX_PCT !== "" ? e.SI_MAX_PCT.toString() : "",
        SI_MIN_PCT: e.SI_MIN_PCT !== null && e.SI_MIN_PCT !== "" ? e.SI_MIN_PCT.toString() : "",
      }
    });
    this._APICallService.saveAllAmtCoveredPremAmtLmtPerAccType(this.tempUpdateDataAmtCoveredPremAmtLmtPerAccType)
    .subscribe({
      next: (response : any) => {
            this.securityService.checkRequestKeyResponse(response, () => {
              this.securityService.hasValidCsrfToken(response, () => {
                
                if (response.status == "SUCCESS" && this.tranPosted) {
                    this.openDialog('SUCCESS', 'Existing amount covered and premium amount limit per account type details. Successfully saved!');
                    
                    this.isSuccessSaved = true;
                    
                    this.getAllAmtCoveredPremAmtLmtPerAccType();
                    
                    this.tempAddedDataAmtCoveredPremAmtLmtPerAccType = [];
                    
                    this.tempUpdateDataAmtCoveredPremAmtLmtPerAccType = [];
                    
                    this.resetFormValuesAmtCoveredPremAmtLmtPerAccType();
                    
                } else {
                  this.formService.showFormMsg("account-type-amt-error-message", 'An error has occured while saving details for existing amount covered and premium amount limit per account type.', "E");
                }
              });
            });
      }, 
      error: () => {
        this.submitted = false;
        this.isSuccessSaved = false;
        this.formService.showFormMsg("account-type-amt-error-message", 'An error has occured while saving details for amount covered and premium amount limit per account type', "E");
      }
    });
  }

  validateInsertAccountType() {
    try {
      this.validated = false;
      if (!this.requiredFieldCheckAccountType()){
        setTimeout(()=>{                           //10 secs countdown
          this.formService.hideFormMsg("account-type-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
    }
  }

  validateInsertAmtCoveredPremAmtLmtPerAccType() {
    try {
      this.validated = false;
      if (!this.requiredFieldCheckAmtCoveredPremAmtLmtPerAccType()){
        setTimeout(()=>{                          
          this.formService.hideFormMsg("account-type-amt-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
    }
  }

  validateUpdateAccountType() {
    try {
      this.validated = false;
      if (!this.requiredFieldCheckAccountType()){
        setTimeout(()=>{                          
          this.formService.hideFormMsg("account-type-error-message");
        }, 10000);
      }
      else if (!this.requiredFieldCheckAccountType()){
        setTimeout(()=>{                          
          this.formService.hideFormMsg("account-type-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
    }
  }

  validateUpdateAmtCoveredPremAmtLmtPerAccType() {
    try {
      this.validated = false;
      if (!this.requiredFieldCheckAmtCoveredPremAmtLmtPerAccType()){
        setTimeout(()=>{                          
          this.formService.hideFormMsg("account-type-amt-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
    }
  }

  requiredFieldCheckAccountType() {
    try {
      if(this.accountTypeForm.get('activeTag')?.value=='' || this.accountTypeForm.get('activeTag')?.value==null ) {
        this.formService.showFormMsg("account-type-error-message", 'There are missing information. Please provide the necessary information needed.', "E");
        return false;
      }
      else {
        return true;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }

  requiredFieldCheckAmtCoveredPremAmtLmtPerAccType() {
    try {
      if(this.amtCoveredPremAmtLmtPerAccTypeForm.get('activeTag')?.value=='' || this.amtCoveredPremAmtLmtPerAccTypeForm.get('activeTag')?.value==null ) {
        this.formService.showFormMsg("account-type-amt-error-message", 'There are missing information. Please provide the necessary information needed.', "E");
        return false;
      }
      else {
        return true;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }

  checkAmount() {
    try {
      if(+this.amtCoveredPremAmtLmtPerAccTypeForm.get('amtCoveredMinPct')?.value>+this.amtCoveredPremAmtLmtPerAccTypeForm.get('amtCoveredMaxPct')?.value&&(this.amtCoveredPremAmtLmtPerAccTypeForm.get('amtCoveredMinPct')?.value&&this.amtCoveredPremAmtLmtPerAccTypeForm.get('amtCoveredMaxPct')?.value)) {
        this.formService.showFormMsg("account-type-amt-error-message", 'Amount Covered Min. Percentage should not be greater than Amount Covered Max Percentage.', "E");
        return false;
      }
      if(+this.amtCoveredPremAmtLmtPerAccTypeForm.get('minPremRate')?.value>+this.amtCoveredPremAmtLmtPerAccTypeForm.get('maxPremRate')?.value&&(this.amtCoveredPremAmtLmtPerAccTypeForm.get('minPremRate')?.value&&this.amtCoveredPremAmtLmtPerAccTypeForm.get('maxPremRate')?.value)){
        this.formService.showFormMsg("account-type-amt-error-message", 'Min. Premium Rate should not be greater than Max Premium Rate.', "E");
        return false;
      }
      else {
        return true;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }

  userTypeDupCheck() {
    try {
      let currCdForm = this.accountTypeForm.get('userTypeCd')?.value.trim().toUpperCase() ?? "";
      this.currCdField = this.tblConfigAccountType.tblData.filter(function(e: any) {
        return e.USER_TYPE.trim().toUpperCase()==currCdForm;
      });
      if(this.currCdField=='' || this.currCdField==null) {
        return true;
      } else {
        this.formService.showFormMsg("account-type-error-message", 'Unable to add record. User Type Code already exists. Please add a unique User Type Code.', "E");
        return false;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }

  userTypeDescDupCheck(){
    try {
      let currCdForm = this.accountTypeForm.get('userTypeDesc')?.value.trim().toUpperCase() ?? "";
      this.currCdField = this.tblConfigAccountType.tblData.filter(function(e: any) {
        return e.USER_TYPE_DESC.trim().toUpperCase()==currCdForm;
      });
      if(this.currCdField=='' || this.currCdField==null) {
        return true;
      } else {
        this.formService.showFormMsg("account-type-error-message", 'Unable to add record. User Type Desc already exists. Please add a unique User Type Desc.', "E");
        return false;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }
  
  numLimitCheck() {
    try {
      let currCdForm1 = this.amtCoveredPremAmtLmtPerAccTypeForm.get('amtCoveredLmt')?.value ? this.amtCoveredPremAmtLmtPerAccTypeForm.get('amtCoveredLmt')?.value.length : "";
      let currCdForm2 = this.amtCoveredPremAmtLmtPerAccTypeForm.get('amtCoveredMinPct')?.value ? this.amtCoveredPremAmtLmtPerAccTypeForm.get('amtCoveredMinPct')?.value.length : "";
      let currCdForm3 = this.amtCoveredPremAmtLmtPerAccTypeForm.get('amtCoveredMaxPct')?.value ? this.amtCoveredPremAmtLmtPerAccTypeForm.get('amtCoveredMaxPct')?.value.length : "";
      let currCdForm4 = this.amtCoveredPremAmtLmtPerAccTypeForm.get('minPremRate')?.value ? this.amtCoveredPremAmtLmtPerAccTypeForm.get('minPremRate')?.value.length : "";
      let currCdForm5 = this.amtCoveredPremAmtLmtPerAccTypeForm.get('maxPremRate')?.value ? this.amtCoveredPremAmtLmtPerAccTypeForm.get('maxPremRate')?.value.length : "";
      if(currCdForm1 > 17 && (currCdForm1 != 0 || currCdForm1 != "")) {
        this.formService.showFormMsg("account-type-amt-error-message", 'Invalid value in Amount Covered Limit field.', "E");
        return false;
      }
      else if(currCdForm2 > 13 && (currCdForm2 != 0 || currCdForm2 != "")) {
        this.formService.showFormMsg("account-type-amt-error-message", 'Invalid value in Amount Covered Min. Percentage field.', "E");
        return false;
      }
      else if(currCdForm3 > 13 && (currCdForm3 != 0 || currCdForm3 != "")) {
        this.formService.showFormMsg("account-type-amt-error-message", 'Invalid value in Amount Covered Max Percentage field.', "E");
        return false;
      }
      else if(currCdForm4 > 13 && (currCdForm4 != 0 || currCdForm4 != "")) {
        this.formService.showFormMsg("account-type-amt-error-message", 'Invalid value in Min. Premium Rate field.', "E");
        return false;
      }
      else if(currCdForm5 > 13 && (currCdForm5 != 0 || currCdForm5 != "")) {
        this.formService.showFormMsg("account-type-amt-error-message", 'Invalid value in Max Premium Rate field.', "E");
        return false;
      }
      else {
        return true;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }

  combinationAmtCoveredDupCheck() {
    try {
      let currCdForm1 = this.accountTypeForm.get('userTypeCd')?.value ?? "";
      let currCdForm2 = this.amtCoveredPremAmtLmtPerAccTypeForm.get('userLvl')?.value ?? "";
      let currCdForm3 = this.amtCoveredPremAmtLmtPerAccTypeForm.get('itemTypeCd')?.value ?? "";
      let currCdForm4 = this.amtCoveredPremAmtLmtPerAccTypeForm.get('lineCd')?.value ?? "";
      let currCdForm5 = this.amtCoveredPremAmtLmtPerAccTypeForm.get('perilCd')?.value ?? "";
      this.currCdField = this.tblConfigAmtCoveredPremAmtLmtPerAccType.tblData.filter(function(e: any) {
        if(e.USER_TYPE==currCdForm1&&e.USER_LEVEL==currCdForm2&&
          e.ITEM_TYPE_CD==currCdForm3&&e.LINE_CD==currCdForm4
          &&e.PERIL_CD==currCdForm5){
            return true;
        }
        else{
          return null;
        };
      });
      if(this.currCdField=='' || this.currCdField==null) {
        return true;
      } else {
        this.formService.showFormMsg("account-type-amt-error-message", 'Combination of User Level, Item Type, Line, and Peril  under the User Type selected already exists. Please add a unique combination.', "E");
        return false;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }

  effDateFromCheck() {
    let effDateFromTemp = this.datePipe.transform(new Date(this.amtCoveredPremAmtLmtPerAccTypeForm.get('effDateFrom')?.value), 'MM/dd/yyyy');
    this.effDateFromField = this.tblConfigAmtCoveredPremAmtLmtPerAccType.tblData.filter(function(e: any) {
      return e.EFF_DATE_FROM==effDateFromTemp;
    });
    if(this.effDateFromField=='' || this.effDateFromField==null) {
      return true;
    } else {
      this.formService.showFormMsg("account-type-amt-error-message", 'Unable to add record at the entered Effective Date From. Effective Dates cannot be concurrent. Please add a different Effective Date From', "E");
      return false;
    }
  }

  openDialog(title: any, message: any) {
    try {
      this.noLoadingInProcesstblConfigAccountType = true;
      this.noLoadingInProcessAmtCoveredPremAmtLmtPerAccType = true;
      this.formService.hideFormLoader("generate-curr-form");
      this.formService.hideFormLoader("generate-curr-rt-form");
      this._Dialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        } 
      });
    } catch(e) {
    }
  }

  dateFilter(dateString: any) {
    try {
      if (dateString=='' || dateString==null) {
        return '';
      }
      else if (dateString=='0000-00-00' || dateString=='0000-00-00 12:00:00 AM' || dateString=='1970-01-01') {
        return '';
      }
      else {
        return formatDate(dateString, 'yyyy-MM-dd', 'en');
      }
    }
    catch(e) {
      return '';
    }
  }

  autoUppercase(ev: any) {
    try{
      ev.target.value = ev.target.value.toUpperCase();
    }
    catch(e) {
    }
  }

  allowNumericDigitsOnly(event: any) {
    try {
      if (/[0-9.]/.test(String.fromCharCode(event.keyCode))) {
        return true;
      }
      else {
        event.preventDefault();
        return false;
      }
    }
    catch (e) {
    console.error(e);
      return undefined;
    }
  }

  
  public openLov = {
    line: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'LINE',
          moduleId: this.moduleId
        },
      }
      this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button?.toUpperCase() === 'OK') {
          //this.forms.resetProductCodeForm();
          if (output) {
            this.amtCoveredPremAmtLmtPerAccTypeForm.patchValue({
              lineCd: output.LINE_CD,
              lineName: output.LINE_NAME,
              lineCdDisp: `${output.LINE_CD} - ${output.LINE_NAME}`,
              perilCd: '',
              perilCdName: '',
              perilField: ''
            });
            //this.btns.productCode.addDisabled = true;
            //this.btns.filterLov.sublineLovSearchDisabled = false;
            //this.btns.filterLov.planLovSearchDisabled = true;
          }
          else {
            this.amtCoveredPremAmtLmtPerAccTypeForm.patchValue({
              lineCd: null,
              lineName: null,
              lineCdDisp: null
            });
            //this.btns.productCode.addDisabled = this.btns.productCode.formLovSearchDisabled = true;
            //this.btns.filterLov.sublineLovSearchDisabled = true;
            //this.btns.filterLov.planLovSearchDisabled = true;
          }
          //this.getData.productCodes();
        }
      });
    },
    clientType: (opt: any) => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'CLIENT_GROUP',
          moduleId: this.moduleId,
        },
      };
      this._matDialog.open(ClientGroupLovDialog, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.option?.toUpperCase() !== "BACK") {
          if (output.CLIENT_CD) {
            if (opt === 'FILTER') {
              //this.forms.resetProductCodeForm();
              this.accountTypeForm.patchValue({
                clientType: output.CLIENT_CD,
                clientTypeName: output.CLIENT_DESC,
                clientTypeDisp: `${output.CLIENT_CD} - ${output.CLIENT_DESC}`,
              });
              this.accountTypeForm.patchValue({
                clientType: output.CLIENT_CD,
                clientTypeName: output.CLIENT_DESC,
                clientTypeDisp: `${output.CLIENT_CD} - ${output.CLIENT_DESC}`,
              });
              //this.btns.productCode.clientTypeLovSearchDisabled = true;
              //this.getData.productCodes();
            }
            else if (opt === 'FORM') {
              this.accountTypeForm.patchValue({
                clientType: output.CLIENT_CD,
                clientTypeName: output.CLIENT_DESC,
                clientTypeDisp: `${output.CLIENT_CD} - ${output.CLIENT_DESC}`,
              });
            }
          }
          else {
            if (opt === 'FILTER') {
              this.accountTypeForm.patchValue({
                clientType: null,
                clientTypeName: null,
                clientTypeDisp: null,
              });
              this.accountTypeForm.patchValue({
                clientType: null,
                clientTypeName: null,
                clientTypeDisp: null,
              });
              //this.btns.productCode.clientTypeLovSearchDisabled = false;
              //this.getData.productCodes();
            }
            else if (opt === 'FORM') {
              this.accountTypeForm.patchValue({
                clientType: null,
                clientTypeName: null,
                clientTypeDisp: null,
              });
            }
          }
        }
      });
    },
    project: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          moduleId: this.moduleId
        },
      };
      this._matDialog.open(ProjectLovComponent, LOVOPTIONS).afterClosed().subscribe((lovData: any) => {
        if (lovData?.button === 'OK') {
          if (lovData?.content?.PROJECT_CD) {
            let data = lovData?.content;
            this.accountTypeForm.patchValue({
              projectCd: data.PROJECT_CD,
              projectName: data.PROJECT_DESC,
              projectDisp: `${data.PROJECT_CD} - ${data.PROJECT_DESC}`,
            });
          }
          else {
            this.accountTypeForm.patchValue({
              projectCd: null,
              projectName: null,
              projectDisp: null,
            });
          }
        }
      });
    },
    riskProf: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          moduleId: this.moduleId
        },
      };
      this._matDialog.open(RiskProfileLovComponent, LOVOPTIONS).afterClosed().subscribe((lovData: any) => {
        if (lovData?.button === 'OK') {
          if (lovData?.content?.RISK_PROF_CD) {
            let data = lovData?.content;
            this.accountTypeForm.patchValue({
              riskProfCd: data.RISK_PROF_CD,
              riskProfName: data.RISK_PROF_DESC,
              riskProfDisp: `${data.RISK_PROF_CD} - ${data.RISK_PROF_DESC}`,
            });
          }
          else {
            this.accountTypeForm.patchValue({
              riskProfCd: null,
              riskProfName: null,
              riskProfDisp: null,
            });
          }
        }
      });
    },
    peril: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'PERIL',
          lineCd: this.amtCoveredPremAmtLmtPerAccTypeForm.get('lineCd')?.value,
          moduleId: this.moduleId,
          perilCdExclude: /*perilCdExists*/ true
        },
      };
      LOVOPTIONS.data.lineCd = this.amtCoveredPremAmtLmtPerAccTypeForm.get('lineCd')?.value;
      this._matDialog.open(PerilLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
        let output = dataOutput.content;
        if (dataOutput.button?.toUpperCase() === 'OK') {
          if (output?.PERIL_CD) {
            this.amtCoveredPremAmtLmtPerAccTypeForm.patchValue({
              perilCd: output.PERIL_CD,
              perilCdName: output.PERIL_LNAME,
              perilField: `${output.PERIL_CD.toString().padStart(2, 0)} - ${output.PERIL_LNAME}`,
            });
          }
          else {
            this.amtCoveredPremAmtLmtPerAccTypeForm.patchValue({
              perilCd: null,
              perilCdName: null,
              perilField: null,
            });
          }
        }
      });
    },
    agent: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          moduleId: this.moduleId
        },
      };
      this._matDialog.open(AgentLovComponent, LOVOPTIONS).afterClosed().subscribe((lovData: any) => {
        if (lovData?.button === 'OK') {
          if (lovData?.content?.AGENT_CD) {
            let data = lovData?.content;
            this.accountTypeForm.patchValue({
              agentCd: data.AGENT_CD,
              agentName: data.AGENT_NAME,
              agentDisp: `${data.AGENT_CD} - ${data.AGENT_NAME}`,
            });
          }
          else {
            this.accountTypeForm.patchValue({
              agentCd: null,
              agentName: null,
              agentDisp: null,
            });
          }
        }
      });
    },
  };

  private getData = {
      agentDropDown: () => {
      const filteredMag = this.jsonDataService.data.agent.map(
        (data: any) => { 
          return {  value: data.agentCd,
                    text: data.agentName 
                  } 
        });
        return filteredMag;
        },
        userLvlDropDown: () => {
        const filteredMag = this.jsonDataService.data.refCds.filter((a:any) => a.rvDomain === "USER_LEVEL").map(
          (data: any) => { 
            return {  value: data.rvLowValue,
                      text: data.rvMeaning 
                    } 
          });
          return filteredMag;
        },
        itemTypeCdDropDown: () => {
        const filteredMag = this.jsonDataService.data.refCds.filter((a:any) => a.rvDomain === "ITEM_TYPE_CD").map(
          (data: any) => { 
            return {  value: data.rvLowValue,
                      text: data.rvMeaning 
                    } 
          });
          return filteredMag;
          },
        userTypeDropDown: () => {
        const filteredMag = this.jsonDataService.data.refCds.filter((a:any) => a.rvDomain === "BMM013.USER_TYPE").map(
          (data: any) => { 
            return {  value: data.rvLowValue,
                      text: data.rvMeaning 
                    } 
          });
          return filteredMag;
          },
        
    }

  public selections = {
    agentDropDown: [
      ...this.getData.agentDropDown()
    ],
    userLvlDropDown: [
      ...this.getData.userLvlDropDown()
    ],
    itemTypeCdDropDown: [
      ...this.getData.itemTypeCdDropDown()
    ],
    userTypeDropDown: [
      ...this.getData.userTypeDropDown()
    ],
  }

  
  rateFormat(fieldName:string){
    if(this.amtCoveredPremAmtLmtPerAccTypeForm.get(fieldName)?.value!==''&&this.amtCoveredPremAmtLmtPerAccTypeForm.get(fieldName)?.value!==null){
      let fieldFloat = parseFloat(this.amtCoveredPremAmtLmtPerAccTypeForm.get(fieldName)?.value.replace(/,/g, ''))
      let num = fieldName == 'amtCoveredLmt' ? 2 : 9;
      let fieldRate = fieldFloat.toFixed(num);
      if(isNaN(fieldFloat)||fieldRate=='NaN'||Math.sign(fieldFloat)==-1){
        this.amtCoveredPremAmtLmtPerAccTypeForm.get(fieldName)?.setValue('');
      }
      else{
        this.amtCoveredPremAmtLmtPerAccTypeForm.get(fieldName)?.setValue(fieldRate);
      }
    }
  }

}
