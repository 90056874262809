<section id="dashboard">
	<div class="dashboard-nav">
        <div class="dashboard-nav-content">
            <span>
                <img src="../../assets/images/custom/company-logo/bpims-logo2.png" alt="bpims-logo">
            </span>
            <span class="dashboard-title">
                Online Sales Portal
            </span>
            <span class="right">
                <span>
                    <span class="bpi-red" (click)="showChangePassword()">{{ userId }}</span>
                </span>
                <span>
                    <span class="logout-button" (click)="logout()">Logout</span>
                </span>
            </span>
        </div>
    </div>
    <div class="wrapper">
        <mat-sidenav-container class="nav-container" [ngStyle]="{ 'min-height.px': heightAdjuster }">
            <mat-sidenav mode="side" opened class="nav-wrapper" id="test-height" (resized)="onResized($event)">
                <div class="nav-menu">
                    <ul>
                        <ng-container *ngFor="let genMenu of generalMenus">
                            <li *ngIf="!genMenu.hidden">
                                <div>
                                    <button [routerLink]="['/dashboard/'+ genMenu.name]" routerLinkActive="nav-menu-active">{{genMenu.title}}</button>
                                </div>
                            </li>
                        </ng-container>
                        <ng-container *ngFor="let submenu of renewalMenuRoutes">
                            <li *ngIf="!submenu.hidden">
                                <div>
                                    <button (click)="submenu.showSubOptions = !submenu.showSubOptions" [ngClass]="submenu.showSubOptions ? 'nav-menu-active' : ''">{{submenu.title}}</button>
                                </div>
                            </li>
                            <div class="sub-menu" *ngIf="submenu.showSubOptions">
                                <ng-container *ngFor="let option of submenu.options">
                                    <li *ngIf="!option.hidden">
                                        <div>
                                            <button [routerLink]="['/dashboard/'+ option.name]" routerLinkActive="nav-menu-active">{{option.title}}</button>
                                        </div>
                                    </li>
                                </ng-container>
                            </div>
                        </ng-container>
                        <li>
                            <div>
                                <button [routerLink]="['/dashboard/home']" (click)="toggleMtn()" [ngClass]="menuButtons.maintenanceShow ? 'nav-menu-active' : ''">Maintenance</button>
                            </div>
                        </li>
                        <!-- MAINTENANCE -->
                        <div class="sub-menu" *ngIf="menuButtons.maintenanceShow">
                            <ng-container *ngFor="let submenu of maintenanceMenuRoutes">
                                <li *ngIf="!submenu.hidden">
                                    <div>
                                        <button (click)="submenu.showSubOptions = !submenu.showSubOptions" [ngClass]="submenu.showSubOptions ? 'nav-menu-active' : ''">{{submenu.title}}</button>
                                    </div>
                                </li>
                                <div class="sub-menu" *ngIf="submenu.showSubOptions">
                                    <ng-container *ngFor="let option of submenu.options">
                                        <li *ngIf="!option.hidden">
                                            <div>
                                                <button [routerLink]="['/dashboard/'+ option.name]" routerLinkActive="nav-menu-active">{{option.title}}</button>
                                            </div>
                                        </li>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                        <li>
                            <div>
                                <button (click)="deployChanges()">Deploy Changes</button>
                            </div>
                        </li>
                    </ul>
                </div>
            </mat-sidenav>
            <mat-sidenav-content>
                <div class="nav-content">
                    <router-outlet></router-outlet>
                </div>
            </mat-sidenav-content>
        </mat-sidenav-container>
    </div>
</section>