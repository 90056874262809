import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { DialogBoxService } from 'src/app/services/dialog-box.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { SecurityService } from 'src/app/services/security.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { SysUsrsMtnUserAccessComponent } from './sys-usrs-mtn-user-access/sys-usrs-mtn-user-access.component';

@Component({
  selector: 'system-users-maintentance',
  templateUrl: './system-users-maintentance.component.html',
  styleUrls: ['./system-users-maintentance.component.css']
})
export class SystemUsersMaintentanceComponent implements OnInit, LeaveConfirmation {

  moduleId: string = 'BMM078';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc?.toUpperCase() ?? "";
  datePipe = new DatePipe('en-us');

  constructor(
    private fb: FormBuilder,
    private _api: APICallService,
    private _matDialog: MatDialog,
    private formService: FormService,
    private dialogBoxService: DialogBoxService,
    private userDetailService: UserDetailsService,
    private _jDS: JsonDataService,
    private securityService: SecurityService,
    private appMessageService: AppMessageService
  ) {
    this.userIdLoggedIn = this.userDetailService.userId || "";
  }

  ngOnInit(): void {
    this.getSystemUsers();
    //? temp ?//
    //this.systemUsersMainForm.valueChanges.subscribe(() => this.formService.hideFormMsg("system-users-error-message"));
  }

  canDeactivate(): boolean | Observable<boolean> {
    return this.tblConfig.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true)).length > 0 ? false : true;
  }

  public updateBtnDisabled: boolean = true;
  public deleteBtnDisabled: boolean = true;
  public saveBtnDisabled: boolean = true;
  public showError: boolean = false;
  public showForm: boolean = true;
  public userOnDB: boolean = false;
  public userAccessBtnDisabled: boolean = true;
  public resetUserPwBtnDisabled: boolean = true;
  public tempDataRow: boolean = false;
  private errorMessage: string = "test";
  private rowData: any;
  private userIdLoggedIn: string;
  private emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

  private dbToForm(data: any) {
    return {
      userId: data.USER_ID,
      fullName: data.FULL_NAME,
      firstName: data.FIRST_NAME,
      middleName: data.MIDDLE_NAME,
      lastName: data.LAST_NAME,
      email: data.EMAIL_ADDRESS,
      active: data.ACTIVE_TAG,
      effectiveDate: data.EFF_DATE,
      expiryDate: data.EXPIRY_DATE,
      remarks: data.REMARKS,
      lastUpdateUser: data.LAST_USER,
      lastUpdate: new DatePipe('en_PH').transform(data.LAST_USER_UPDATE, 'MM/dd/YYYY h:mm:ss a'),
    };
  }

  private formToDb(data: any) {
    return {
      USER_ID: data.userId?.trim(),
      FULL_NAME: data.fullName,
      FIRST_NAME: data.firstName,
      MIDDLE_NAME: data.middleName,
      LAST_NAME: data.lastName,
      EMAIL_ADDRESS: data.email,
      ACTIVE_TAG: data.active,
      EFF_DATE: data.effectiveDate ? this.datePipe.transform(new Date(data.effectiveDate), 'yyyy-MM-dd') : null,
      EXPIRY_DATE: data.expiryDate ? this.datePipe.transform(new Date(data.expiryDate), 'yyyy-MM-dd') : null,
      REMARKS: data.remarks,
      LAST_USER_UPDATE: data.lastUpdate,
      LAST_USER: data.lastUpdateUser
    };
  }

  tblConfig: any = {
    cols: [
      {
        key: "USER_ID",
        header: "User ID",
        dataType: "string",
        width: '20%'
      },
      {
        key: "FULL_NAME",
        header: "Name",
        dataType: "string"
      },
      {
        key: "EMAIL_ADDRESS",
        header: "Email Address",
        dataType: "string"
      },
      {
        key: "EFF_DATE",
        header: "Effective Date",
        dataType: "date",
        width: '17%'
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        width: '64px',
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  public systemUsersMainForm = this.fb.group({
    userId: [null, [Validators.required, Validators.maxLength(10), this.checkDuplicateUserId(), this.checkRequired()], ],
    fullName: [null],
    firstName: [null, [Validators.required, Validators.maxLength(20), this.checkRequired()]],
    middleName: [null, [Validators.maxLength(30)]],
    lastName: [null, [Validators.required, Validators.maxLength(30), this.checkRequired()]],
    email: [null, [Validators.required, Validators.pattern(this.emailPattern), Validators.maxLength(208), , this.checkRequired()]],
    active: ['A', [Validators.required]],
    effectiveDate: [new Date(), [Validators.required]],
    expiryDate: [null],
    remarks: [null, [Validators.maxLength(4000)]],
    lastUpdate: [{ value: '', disabled: true }],
    lastUpdateUser: [{ value: '', disabled: true }],
  });

  activeTypes = [
    { cd: 'A', name: 'Yes' },
    { cd: 'I', name: 'No' },
  ];

  getSystemUsers() {
    this.tblConfig.loading = true;
    this._jDS.contorlLoading(true);
    this._api.getSystemUsersMtn({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE"
    }).subscribe({
      next: (data: any) => {
            this.securityService.checkRequestKeyResponse(data, () => {
              this.securityService.hasValidCsrfToken(data, ()=>{
                this.tblConfig.tblData = JSON.parse(this._jDS.decrypt(data.response));
                this._jDS.contorlLoading(false);
                this.tblConfig.loading = false;
              });
            });
      },
      error: (e: any) => {
        this._jDS.contorlLoading(false);
        console.error(e);
        this.tblConfig.loading = false;
        this.appMessageService.showAppMessage(e.message, "error");
      },
    });
  }

  onRowClick(ev: { [key: string]: any }) {
    try {
      // if (ev.temp === null || ev.temp === undefined || ev.onDbButUpdatedTemp === true) {
      //   this.userOnDB = true;
      //   //? Old Update Field 06/16/2021
      //   //this.systemUsersMainForm.get('userId')?.disable();
      // }
      // else {
      //   this.userOnDB = false;
      //   //? Old Update Field 06/16/2021
      //   //this.systemUsersMainForm.get('userId')?.enable();
      // }
      this.userOnDB = (ev.temp === null || ev.temp === undefined || ev.onDbButUpdatedTemp === true) ? true : false;
      //! Fix for 06/16/2021
      this.systemUsersMainForm.get('userId')?.disable();
      ev.update = true;
      this.rowData = ev;
      this.userAccessBtnDisabled = this.resetUserPwBtnDisabled = !this.userOnDB;
      this.populateFormFromTable();
    }
    catch (e) {
    console.error(e);
      this.onFormReset();
      this.rowData = null;
      //this.indexRow = null;
      this.userAccessBtnDisabled = true;
      this.updateBtnDisabled = true;
      this.deleteBtnDisabled = true;
      this.resetUserPwBtnDisabled = true;
      this.tempDataRow = false;
    }
  }

  populateFormFromTable() {
    try {
      let data = this.rowData;
      this.updateBtnDisabled = !data.update;
      this.deleteBtnDisabled = !data.temp;
      this.tempDataRow = !data.temp;
      this.systemUsersMainForm.patchValue(this.dbToForm(data));
    }
    catch (e) {
    console.error(e);
    }
  }

  onAdd() {
    try {
      this.formService.hideFormMsg("system-users-error-message");
      if (this.systemUsersMainForm.valid) {
        this.systemUsersMainForm.patchValue({
          userId: this.userIDCaps(this.systemUsersMainForm.value).toUpperCase(),
          fullName: this.nameCombiner(this.systemUsersMainForm.value).toUpperCase(),
        })
        let rowToBeAdded: { [key: string]: any } = this.formToDb(this.systemUsersMainForm.value);
        rowToBeAdded.temp = true;
        this.tblConfig.tblData = [rowToBeAdded, ...this.tblConfig.tblData];
        this.onFormReset();
        this.saveBtnDisabled = false;
      }
      else {
        this.showErrorValidator();
        //this.checkAllValidators();
      }
    }
    catch (e) {
    console.error(e);
    }
  }

  onDelete() {
    try {
      this.tblConfig.tblData = this.tblConfig.tblData.filter((d: any) => {
        return d !== this.rowData;
      });
      this.onFormReset();
      this.updateBtnDisabled = true;
      this.deleteBtnDisabled = true;
    }
    catch (e) {
    console.error(e);
    }
  }

  onUpdate() {
    try {
      this.formService.hideFormMsg("system-users-error-message");
      if (this.systemUsersMainForm.valid) {
        let toBeUpdatedIndex = this.tblConfig.tblData.indexOf(this.rowData);
        this.systemUsersMainForm.patchValue({
          fullName: this.nameCombiner(this.systemUsersMainForm.value).toUpperCase(),
        });
        let userId = this.rowData.USER_ID;
        let rowToBeUpdated: { [key: string]: any } = this.formToDb(this.systemUsersMainForm.value);
        if (this.rowData.temp) {
          rowToBeUpdated.temp = true;
        } else {
          rowToBeUpdated.onDbButUpdatedTemp = true;
          //? Old Update Field 06/16/2021
          //rowToBeUpdated.USER_ID = userId;
        }
        //! Fix for 06/16/2021
        rowToBeUpdated.USER_ID = userId;
        this.onFormReset();
        this.tblConfig.tblData[toBeUpdatedIndex] = rowToBeUpdated;
        //this.tblConfig.tblData[toBeUpdatedIndex];
        this.updateBtnDisabled = true;
        this.deleteBtnDisabled = true;
        this.saveBtnDisabled = false;
      }
      else {
        this.showErrorValidator();
        //this.checkAllValidators();
      }
      this.tblConfig.tblData = [...this.tblConfig.tblData];
    }
    catch (e) {
    console.error(e);
    }
  }

  onSave() {
    try {
      this.showForm = false;
      this.formService.showFormLoader(null, "system-user-maintenance-loading", "Saving.<br>Please wait ...", null, null);
      let toBeInsertedUpdated = this.tblConfig.tblData.filter((d: any) => {
        return d.temp === true || d.onDbButUpdatedTemp === true;
      });
      if (toBeInsertedUpdated.length > 0)
        toBeInsertedUpdated.map((d: any) => {
          d.LAST_USER = this.userIdLoggedIn;
        });
      this._api.saveSystemUsersMtn(toBeInsertedUpdated).subscribe({
        next: (data: any) => {
            this.securityService.checkRequestKeyResponse(data, () => {
              this.securityService.hasValidCsrfToken(data, ()=>{
                if (data.status === 'SUCCESS') this.onComplete();
                else this.onCompleteError();
              });
            });
        },
        error: (data: any) => {
          this.onCompleteError();
        },
      });
      // setTimeout(() => {
      //   this.onComplete();
      // }, 3000);
    }
    catch (e) {
    console.error(e);
    }
  }

  onComplete() {
    try {
      this.getSystemUsers();
      this.saveBtnDisabled = true;
      this.openDialog('SUCCESS', 'saved');
      this.showForm = true;
      this.formService.hideFormLoader("system-user-maintenance-loading");
    }
    catch (e) {
    console.error(e);
    }
  }

  onCompleteError() {
    try {
      this.showForm = true;
      this.saveBtnDisabled = false;
      this.openDialog('FAILED', 'saving');
      this.formService.hideFormLoader("system-user-maintenance-loading");
    }
    catch (e) {
    console.error(e);
    }
  }

  openDialog(title: string, contentDetail: string) {
    try {
      this._matDialog.open(DialogMsgComponent, {
        disableClose: true,
        width: '512px',
        data: {
          title: title,
          content:
            title === 'SUCCESS'
              ? `System User details successfully ${contentDetail}!`
              : `System User details ${contentDetail} failed!`,
        },
      });
    }
    catch (e) {
    console.error(e);
    }
  }

  showErrorValidator() {
    try {
      Object.keys(this.systemUsersMainForm.controls).forEach(key => {
        const controlErrors = this.systemUsersMainForm.get(key)?.errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach(keyError => {
            if (keyError == 'hasDupe' && controlErrors[keyError] === true)
              this.errorMessage = "User ID already exists. Please add a unique User ID.";
            else if (keyError == 'pattern' && key == 'email')
              this.errorMessage = "Wrong Email Pattern!";
            else if (keyError == 'matDatepickerParse' && key == 'effectiveDate')
              this.errorMessage = "Invalid date format. Kindly input as MM/DD/YYYY.";
            else if (keyError == 'matDatepickerParse' && key == 'expiryDate')
              this.errorMessage = "Invalid date format. Kindly input as MM/DD/YYYY.";
            else if (keyError == 'required' && controlErrors[keyError] === true)
              this.errorMessage = "There are missing information. Please provide necessary information needed.";
            else if (keyError == 'emptySpace' && controlErrors[keyError] === true)
              this.errorMessage = "There are missing information. Please provide necessary information needed.";
            else if (keyError == 'maxlength' && controlErrors[keyError].actualLength>controlErrors[keyError].requiredLength )
              this.errorMessage = "Entered input exceeds the maximum character length.";
          });
        }
      });
      this.formService.showFormMsg("system-users-error-message", this.errorMessage, "E");
      setTimeout(()=>{                           
        this.formService.hideFormMsg("system-users-error-message");
      }, 10000);
    }
    catch (e) {
    console.error(e);
    }
  }

 checkRequired(){
    try {
      return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (!value) return null;
        let isEmptySpace: boolean = value?.trim()==''||value?.trim()==null;
        return isEmptySpace ? { emptySpace: true } : null;
      }
    }catch (e) {
    console.error(e);
      return true;
    }
  }
  checkDuplicateUserId(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.trim()?.toUpperCase();
      if (!value) return null;
      let hasDuplicate: boolean = this.tblConfig.tblData.filter((d: any) => {
        if (!this.rowData && this.updateBtnDisabled)
          return d.USER_ID.toUpperCase().trim() === value; //fix this
        else
          return this.rowData.USER_ID.toUpperCase().trim() !== value && d.USER_ID.toUpperCase().trim() === value;
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupe: true } : null;
    }
  }

  onFormReset() {
    try {
      this.systemUsersMainForm.reset();
      this.systemUsersMainForm.patchValue({
        active: 'A',
        effectiveDate: new Date(),
      });
      this.userOnDB = false;
      this.systemUsersMainForm.get('userId')?.enable();
    }
    catch (e) {
    console.error(e);
    }
  }

  resetUserPw() {
    try {
      let resetPWAcct: any = {
        userId: '', 
        isAdmin: '',
        userIdAdmin: ''
      };
      resetPWAcct.userId = this.rowData.USER_ID;
      resetPWAcct.isAdmin = 'Y';
      resetPWAcct.userIdAdmin = this.userIdLoggedIn;
      this.dialogBoxService.open({
        messageType: "C",
        content: "Are you sure you want to reset the password of User " + resetPWAcct.userId + "?",
        buttons: [
          { text: "No" },
          { text: "Yes", action: () => {
            this._api.resetUserPw(resetPWAcct).subscribe((res: any) => {
                this.securityService.checkRequestKeyResponse(res, () => {
                  this.securityService.hasValidCsrfToken(res, ()=>{
                    res = JSON.parse(this._jDS.decrypt(res.response));
                    if (res.status == "SUCCESS") {
                      this.openDialog('SUCCESS', 'reset password');
                    } else {
                      this.openDialog('FAILED', 'reset password');
                    }
                  });
                });
            });
          }}
        ]
      }); 
    }
    catch (e) {
    console.error(e);
    }
  }
  datePattern(ev: any) {
    try{
      //let inputChar = String.fromCharCode(event.charCode)
      if (ev.target.value.length<4) {
        let firstPattern = /^\d{2}\/$/;
        if(!ev.target.value.match(firstPattern)){
          ev.target.value = ev.target.value.substring(0,2);
        }
      } else if(ev.target.value.length<7){
        let firstPattern = /^\d{2}\/\d{2}\/$/;
        if(!ev.target.value.match(firstPattern)){
          ev.target.value = ev.target.value.substring(0,5);
        }
      } else if(ev.target.value.length>6&&ev.target.value.length<11){
        let firstPattern = /^\d{2}\/\d{2}\/\d+$/;
        if(!ev.target.value.match(firstPattern)){
          ev.target.value = ev.target.value.substring(0,ev.target.value.length-1);
        }
      } else if(ev.target.value.length==10){
        let firstPattern = /^\d{2}\/\d{2}\/\d{4}$/;
        if(!ev.target.value.match(firstPattern)){
          ev.target.value = ev.target.value.substring(0,ev.target.value.length-1);
        }
      } else{
        ev.target.value = ev.target.value.substring(0,ev.target.value.length-1);
      }
    }
    catch(e) {
      console.error(e);
    }
  }
  nameCombiner(data: any) {
    if (data.middleName)
      return `${data.firstName} ${data.middleName.charAt(0)}. ${data.lastName}`;
    return `${data.firstName} ${data.lastName}`;
  }

  userIDCaps(data:any) {
    return `${data.userId}`;
  }

  checkNameLength(fieldName:string){
    try {
      if(this.systemUsersMainForm.get(fieldName)?.value!=='' && this.systemUsersMainForm.get(fieldName)?.value!==null) {
        let trimmedName = this.systemUsersMainForm.get(fieldName)?.value?.trim();
        if (fieldName == 'firstName' && trimmedName.length > 20) {
          this.systemUsersMainForm.get(fieldName)?.setValue(trimmedName.substring(0, 20));
        } else if (fieldName == 'middleName' && trimmedName.length > 30) {
          this.systemUsersMainForm.get(fieldName)?.setValue(trimmedName.substring(0, 30));
        } else if (fieldName == 'lastName' && trimmedName.length > 30) {
          this.systemUsersMainForm.get(fieldName)?.setValue(trimmedName.substring(0, 30));
        } else {
          this.systemUsersMainForm.get(fieldName)?.setValue(trimmedName);
        }
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  openUserAccessModal() {
    try {
      const MODALOPTS = {
        disableClose: true,
        width: '512px',
        maxHeight: '90vh',
        data: {
          userId: this.rowData.USER_ID,
        }
      };
      this._matDialog.open(SysUsrsMtnUserAccessComponent, MODALOPTS);
    }
    catch (e) {
    console.error(e);
    }
  }

  autoUppercase(ev: any) {
    try{
      ev.target.value = ev.target.value.toUpperCase();
    }
    catch(e) {
      console.error(e);
    }
  }

}
