import { Component, Inject, OnInit } from '@angular/core';
import { APICallService } from 'src/app/services/api-call.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { AppMessageService } from 'src/app/services/app-message.service';

@Component({
  selector: 'report-lov',
  templateUrl: './report-lov.component.html',
  styleUrls: ['./report-lov.component.css']
})
export class ReportLovComponent implements OnInit {

  reportModuleId: string = 'BMM033';
  private selectedData: any;
  public titleLov: string = "REPORTS".toUpperCase();

  reportTable: any = {
    cols: [
      {
        key: "REPORT_ID",
        header: "Report ID",
        dataType: "string",
        width: '30%'
      },
      {
        key: "REPORT_NAME",
        header: "Report Name",
        dataType: "string"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  };

  constructor(
    private jsonDataService: JsonDataService,
    private dialogRef: MatDialogRef<ReportLovComponent>,
    private _api: APICallService,
    private securityService: SecurityService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    @Inject(MAT_DIALOG_DATA) public fromMainData: any,
  ) {
    this.getReportData();
  }

  ngOnInit(): void {
  }

  onRowClick(ev: any) {
    try {
      this.selectedData = ev;
    } catch (e) {
    console.error(e);

    }
  }

  private getReportData(): void {
    try {
      this.reportTable.loading = true;
      this.jsonDataService.contorlLoading(true);
      this._api.getReport({
        moduleId: this.fromMainData.moduleId,
        userId: this.userDetailService.userId,
        type: "LOV"
      }).subscribe({
        next: (response: any) => {
          this.securityService.checkRequestKeyResponse(response, () => {
            this.securityService.hasValidCsrfToken(response, () => {
              let content = JSON.parse(this.jsonDataService.decrypt(response.response));
              if (content.moduleId === this.reportModuleId) {
                this.jsonDataService.contorlLoading(false);
                this.reportTable.tblData = content.data.filter((d: any) => (d.ACTIVE_TAG === 'A'));
                this.reportTable.loading = false;
              } else {
                this.reportTable.loading = false;
                this.jsonDataService.contorlLoading(false);
                this.appMessageService.showAppMessage("You do not have the correct privilege to access the data. Kindly contact your administrator for assistance.", "error");
              }
            });
          });
        },
        error: (e: any) => {
          this.securityService.checkRequestKeyResponse(e, () => {

            this.reportTable.loading = false;
            this.jsonDataService.contorlLoading(false);
            this.appMessageService.showAppMessage(e, "error");
          });
        }
      });
    }
    catch (e) {
    console.error(e);

      this.reportTable.loading = false;
      this.jsonDataService.contorlLoading(false);
    }
  }

  onClose(str: string) {
    try {
      if (this.selectedData == '' || this.selectedData == null) {
        this.dialogRef.close({ content: '', button: str });
      }
      else {
        this.dialogRef.close({ content: this.selectedData, button: str });
      }
    } catch (e) {
    console.error(e);

    }
  }

}
