<section id="eq-zone-maint">    
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <!-- Container for Earthquake Zone Table using prime ng -->
        <div class = 'table-user'>
            <osp-table [tblConfig]="eqZoneTblConfig" (onRowClick)="onRowClick($event)"></osp-table>
        </div>
        <!-- Loading Animation -->
        <div id="eq-zone-maintenance-loading">
            <div class="form-loader"></div>
        </div>
        <div class="form-content" *ngIf="showForm">
            <form [formGroup]="forms.eqZoneForm" class="form">
                <div id="eq-zone-error-message"></div>
                <div class="form-container-grid">
                    <div class="field">
                        <label>EQ Zone Code</label>
                    </div>
                    <div class="">
                        <input formControlName="eqZoneCdDisp" type="text" readonly>
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag">
                            <option *ngFor="let activeType of selections.activeTypes" [value]="activeType.cd">
                                {{activeType.name}}
                            </option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>EQ Zone Short Description</label>
                    </div>
                    <div class="">
                        <input formControlName="eqZoneShortDesc" type="text" maxlength="25">
                    </div>
                    <div class="field required">
                        <label>BPIMS Code</label>
                    </div>
                    <div class="">
                        <input formControlName="bmCode" type="text" maxlength="8">
                    </div>

                    
                    <div class="field required">
                        <label>EQ Zone Long Description</label>
                    </div>
                    <div class="span">
                        <input formControlName="eqZoneLongDesc" type="text" maxlength="1000">
                    </div>
                    
                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" type="text" maxlength="1000">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="userID">
                    </div>
                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUpdate">
                    </div>
                </div>
            </form>
            <div class="btn-row">
                <button *ngIf="btns.eqZone.updateDisabled" class="btn btn2" type="button" (click)='onAdd()'>ADD</button>
                <button *ngIf="!btns.eqZone.updateDisabled" class="btn btn2" type="button" (click)='onUpdate()'>UPDATE</button>
                <button class="btn btn2" type="button" [disabled]='btns.eqZone.deleteDisabled' (click)='onDelete()'>DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-row">
                <button class="btn btn2" type="button" [disabled]='btns.eqZone.saveDisabled' (click)='onSave()'>SAVE</button>
            </div>
        </div>
    </div>
</section>