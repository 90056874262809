<div class="mtn-container">
    <div class="mtn-title">
        <p>{{ moduleName }}</p>
    </div>
    <div class="outer-wrapper p-datatable mtn-header">
        <osp-table [tblConfig]="tblConfig.refCodes" (onRowClick)="onRowClick.refCodes($event)"></osp-table>
        <div id="refCodes-maintenance-loading"><div class="form-loader"></div></div>
        <div *ngIf="showForm.refCodes" class="form-content">
            <form [formGroup]="forms.refCodesForm" class="form">
                <div id="refCodes-error-message"></div>
                <div class="form-container-grid">
                    <div class="field required">
                        <label>Domain</label>
                    </div>
                    <div class="field">
                        <input formControlName="domain" type="text" maxlength="100">
                    </div>

                    <div class="field required">
                        <label>Meaning</label>
                    </div>
                    <div class="field">
                        <input formControlName="meaning" type="text" maxlength="100">
                    </div>

                    <div class="field required">
                        <label>Low Value</label>
                    </div>
                    <div class="field">
                        <input formControlName="lVal" type="text" maxlength="240">
                    </div>

                    <div class="field">
                        <label>High Value</label>
                    </div>
                    <div class="field">
                        <input formControlName="hVal" type="text" maxlength="240">
                    </div>

                    <div class="field">
                        <label>Abbreviation</label>
                    </div>
                    <div class="field">
                        <input formControlName="abbreviation" type="text" maxlength="240">
                    </div>

                    <div class="field">
                        <label></label>
                    </div>
                    <div class="field">
                    </div>
                    
                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input formControlName="lastUpdateUser" >
                    </div>
                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="lastUpdate" >
                    </div>
                </div>
            </form>
            <div class="btn-grp">
                <button *ngIf="btns.refCodes.updateDisabled" class="btn btn2" type="button" (click)="onAdd.refCodes()" [disabled]="btns.refCodes.addDisabled" >ADD</button>
                <button *ngIf="!btns.refCodes.updateDisabled" class="btn btn2" type="button" (click)="onUpdate.refCodes()" >UPDATE</button>
                <button class="btn btn2" type="button" (click)="onDelete.refCodes()" [disabled]='btns.refCodes.deleteDisabled'>DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-grp">
                <button class="btn btn2" type="button" (click)="onSave.refCodes()" [disabled]='btns.refCodes.saveDisabled'>SAVE</button>
            </div>
        </div>
    </div>
</div>
