import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { JsonDataService } from './json-data.service';
import { AppMessageService } from './app-message.service';
import { UserDetailsService } from './user-details.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root'
})
export class SecurityService {

    constructor (
        private router: Router,
        private jsonDataService: JsonDataService,
        private appMessageService: AppMessageService,
        private userDetailsService: UserDetailsService,
        private matDialog: MatDialog,
    ) { }

    public generateBssidRequestKey():string {
        const bssid = sessionStorage.getItem("bssid") || "1";
        const rkc = sessionStorage.getItem("rkc") || "0";

        if (bssid !== "1" && rkc !== "0") {
            let token1 = this.createToken(bssid);
            let token2 = this.createToken(token1);
            let token3 = this.createToken(token2);

            token1 = token1.substr(0,  8) + rkc + token1.substr( 8);
            token2 = token2.substr(0, 16) + rkc + token2.substr(16);
            token3 = token3.substr(0, 24) + rkc + token3.substr(24);

            return `${token1}.${token2}.${token3}`;
        }

        return "1";
    }

    public buildCsrfToken(requestParameter:string) {
        const token1 = this.createToken(requestParameter);
        const token2 = this.createToken(token1);
        const token3 = this.createToken(token2);
        return `${token1}.${token2}.${token3}`;
    }

    private createToken(data:string):string {
        return this.jsonDataService.encrypt(
            data.substr(30, 1) + data.substr(10, 1) + data.substr(40, 1) +
            data.substr(15, 1) + data.substr(35, 1) + data.substr( 5, 1) +
            data.substr(25, 1) + data.substr(20, 1));
    }

    private removeSession():void {
        sessionStorage.removeItem("bssid");
        sessionStorage.removeItem("rkc");
        this.matDialog.closeAll();
        this.router.navigate(["/"]);
        this.userDetailsService.resetUserDetails();
    }

    public checkRequestKeyResponse(response:any, func:Function):void {
        if (response.status === "INVALID_BSSID") {
            this.jsonDataService.contorlLoading(false);
            this.removeSession();
        } else if (["INVALID_REQUEST_KEY_COUNTER", "INVALID_REQUEST_KEY"].indexOf(response.status) >= 0) {
            this.jsonDataService.contorlLoading(false);
            response.status === "INVALID_REQUEST_KEY_COUNTER" ? sessionStorage.setItem("rkc", response.rkc) : "";
            this.appMessageService.showAppMessage("There's something wrong in your request. Please try again.", "error");
        } else {
            const rkc = String(Number(sessionStorage.getItem("rkc") || "0") + 1);
            sessionStorage.setItem("rkc", rkc);
            func();
        }
    }

    public hasValidCsrfToken(response:any, func:Function, showErrorMsg:boolean = true):void {
        try {
            const csrfToken = response.csrfToken;
            const csrfTokens = csrfToken.split(".");
            const token1 = this.isValidToken(csrfTokens[2], csrfTokens[1]);
            const token2 = this.isValidToken(csrfTokens[1], csrfTokens[0]);
            const token3 = this.isValidToken(csrfTokens[0], response.response);

            if (token1 && token2 && token3) {
                func();
            } else {
                this.jsonDataService.contorlLoading(false);
                this.removeSession();
                showErrorMsg ? this.appMessageService.showAppMessage("We are unable to process your request. Please try again later.", "error") : "";
            }
        } catch (e) {
    console.error(e);
            this.jsonDataService.contorlLoading(false);
            this.removeSession();
            showErrorMsg ? this.appMessageService.showAppMessage("We are unable to process your request. Please try again later.", "error") : "";
        }
    }

    private isValidToken(token1:string, token2:string):boolean {
        const decryptedToken = this.jsonDataService.decrypt(token1);

        if (   decryptedToken.substr(0, 1) !== token2.substr(30, 1)
            || decryptedToken.substr(1, 1) !== token2.substr(10, 1)
            || decryptedToken.substr(2, 1) !== token2.substr(40, 1)
            || decryptedToken.substr(3, 1) !== token2.substr(15, 1)
            || decryptedToken.substr(4, 1) !== token2.substr(35, 1)
            || decryptedToken.substr(5, 1) !== token2.substr( 5, 1)
            || decryptedToken.substr(6, 1) !== token2.substr(25, 1)
            || decryptedToken.substr(7, 1) !== token2.substr(20, 1)
        ) {
            return false;
        }

        return true;
    }

}
