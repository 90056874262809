<div class="mtn-container">
    <div class="mtn-title">
        <p>{{ moduleName?.toUpperCase() }}</p>
    </div>
    <osp-table [tblConfig]="bfbSrcExtTblConfig" (onRowClick)="onRowClick($event)" [selectedRow]="selectedRow"></osp-table>
    <div id="bfb-src-ext-maintenance-loading">
        <div class="form-loader"></div>
    </div>
    <div *ngIf="showForm" class="form-content">
        <form [formGroup]="forms.bfbSrcExtForm" class="form">
            <div id="bfb-src-ext-error-message"></div>
            <div class="form-container-grid">
                <div class="field required">
                    <label>Referror Code</label>
                </div>
                <div class="">
                    <input formControlName="referrorCdDisp" readonly>
                    <button matSuffix class="btn btn-search" type="button" (click)="openLov['referror']()"
                        [disabled]="btns.bfbSrcExt.referrorSearchDisabled">
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
                <div class="field required">
                    <label>Source Extension Code</label>
                </div>
                <div class="">
                    <input formControlName="srcExtCdDisp" readonly>
                    <button matSuffix class="btn btn-search" type="button" (click)="openLov['srcExt']()"
                        [disabled]="btns.bfbSrcExt.srcExtSearchDisabled">
                        <mat-icon>search</mat-icon>
                    </button>
                </div>
                <div class="field required">
                    <label>Effective Date</label>
                </div>
                <div class="">
                    <input date-input [matDatepicker]="effDatePicker" formControlName="effectiveDate" placeholder="MM/DD/YYYY" (input)="datePattern($event)">
                    <mat-datepicker-toggle matSuffix [for]="effDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #effDatePicker></mat-datepicker>
                </div>
                <div class="field">
                    <label>Expiry Date</label>
                </div>
                <div class="">
                    <input date-input [matDatepicker]="exDatePicker" formControlName="expiryDate" placeholder="MM/DD/YYYY" (input)="datePattern($event)">
                    <mat-datepicker-toggle matSuffix [for]="exDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #exDatePicker></mat-datepicker>
                </div>
                <div class="field">
                    <label>Last Update User</label>
                </div>
                <div class="">
                    <input formControlName="lastUser">
                </div>
                <div class="field">
                    <label>Last Update</label>
                </div>
                <div class="">
                    <input formControlName="lastUserUpdate">
                </div>
            </div>
        </form>
        <div class="btn-grp">
            <button *ngIf="btns.bfbSrcExt.updateDisabled" class="btn btn2" type="button" (click)="onAdd()">ADD</button>
            <button *ngIf="!btns.bfbSrcExt.updateDisabled" class="btn btn2" type="button"
                (click)="onUpdate()">UPDATE</button>
            <button class="btn btn2" type="button" [disabled]='btns.bfbSrcExt.deleteDisabled'
                (click)="onDelete()">DELETE</button>
        </div>
        <div class="mtn-title"></div>
        <div class="btn-grp">
            <button class="btn btn2" type="button" [disabled]='btns.bfbSrcExt.saveDisabled'
                (click)="onSave()">SAVE</button>
        </div>
    </div>
</div>