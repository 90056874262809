import { Component, Inject, OnInit } from '@angular/core';
import { APICallService } from 'src/app/services/api-call.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { AppMessageService } from 'src/app/services/app-message.service';

@Component({
  selector: 'line-warr-clause-lov',
  templateUrl: './line-warr-clause-lov.component.html',
  styleUrls: ['./line-warr-clause-lov.component.css']
})
export class LineWarrClauseLovComponent implements OnInit {

  lineWarrClauseModuleId: string = 'BMM066';
  private selectedData: any;
  public titleLov: string = "Warranties and Clauses".toUpperCase();

  lineWarrClauseTable: any = {
    cols: [
      {
        key: "WC_CD",
        header: "Code",
        dataType: "string"
      },
      {
        key: "WC_TITLE",
        header: "Warranties and Clauses",
        dataType: "string"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  };

  constructor(
    private jsonDataService: JsonDataService,
    private dialogRef: MatDialogRef<LineWarrClauseLovComponent>,
    private _api: APICallService,
    private securityService: SecurityService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    @Inject(MAT_DIALOG_DATA) public fromMainData: any,
  ) {
    this.getLineWarrClauseData();
  }

  ngOnInit(): void {
  }

  onRowClick(ev: any) {
    try {
      this.selectedData = ev;
    } catch (e) {
    console.error(e);
      
    }
  }

  private getLineWarrClauseData(): void {
    try {
      this.lineWarrClauseTable.loading = true;
      this.jsonDataService.contorlLoading(true);
      this._api.getLineWarrClause({
        lineCd: this.fromMainData.lineCd,
        moduleId: this.fromMainData.moduleId,
        userId: this.userDetailService.userId,
        type: "LOV"
      }).subscribe({
        next: (data: any) => {
          this.securityService.checkRequestKeyResponse(data, () => {
            this.securityService.hasValidCsrfToken(data, () => {
              let content = JSON.parse(this.jsonDataService.decrypt(data.response));
              if (content.moduleId === this.lineWarrClauseModuleId) {
                this.jsonDataService.contorlLoading(false);
                this.lineWarrClauseTable.tblData = content.data.filter((d: any) => (d.LINE_CD === this.fromMainData.lineCd && d.ACTIVE_TAG === 'A' && !((this.fromMainData.wcCdExclude ?? []).includes(d.WC_CD))));
                this.lineWarrClauseTable.loading = false;
              } else {
                this.lineWarrClauseTable.loading = false;
                this.jsonDataService.contorlLoading(false);
                this.appMessageService.showAppMessage("You do not have the correct privilege to access the data. Kindly contact your administrator for assistance.", "error");
              }
            });
          });
        },
        error: (e: any) => {
          this.securityService.checkRequestKeyResponse(e, () => {
            this.securityService.hasValidCsrfToken(e, () => {
              this.lineWarrClauseTable.loading = false;
              this.jsonDataService.contorlLoading(false);
              this.appMessageService.showAppMessage(e, "error");
            });
          });
        }
      });
    }
    catch (e) {
    console.error(e);
      
      this.lineWarrClauseTable.loading = false;
      this.jsonDataService.contorlLoading(false);
    }
  }

  onClose(str: string) {
    try {
      if (this.selectedData == '' || this.selectedData == null) {
        this.dialogRef.close({ content: '', button: str });
      }
      else {
        this.dialogRef.close({ content: this.selectedData, button: str });
      }
    } catch (e) {
    console.error(e);
      
    }
  }

}
