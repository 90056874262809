import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';
import { PlanLovComponent } from '../../common/plan-lov/plan-lov.component';

@Component({
  selector: 'benefits-coverage-maintenance',
  templateUrl: './benefits-coverage-maintenance.component.html',
  styleUrls: ['./benefits-coverage-maintenance.component.css']
})
export class BenefitsCoverageMaintenanceComponent implements OnInit, LeaveConfirmation {

  moduleId: string = 'BMM114';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc?.toUpperCase() ?? "";
  constructor(
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    public _formService: FormService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService
  ) { 
    this.userIdLoggedIn = this._userDetailService.userId ?? "";
  }

  ngOnInit(): void {
    this.btns.benCov.addDisabled = true;
  }

  canDeactivate(): boolean | Observable<boolean> {
    if(this.tempData!='') {
      return false;
    } else {
      return true;
    }
  }

  /* Boolean Stuffs */

  public btns = {
    benCov: {
      addDisabled: true,
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true
    },
    lov: {
      lineDisabled: false,
      sublineDisabled: true,
      planDisabled: true
    }
  }

  public showForm: boolean = true;

  private rowData: any;
  private tempDataRow: any;
  private errorMessage: string = "";
  private userIdLoggedIn: string;
  private benCovPerPlanPerilList : any;
  private tempData: any = [];
  public emailTypeList: any = [];

  public forms = {
    lineSublinePlanLovForm: this.fb.group({
      lineCd: [null],
      lineName: [null],
      lineCdDisp: [{ value: '', disabled: 'true' }],
      sublineCd: [null],
      sublineName: [null],
      sublineCdDisp: [{ value: '', disabled: 'true' }],
      planCd: [null],
      planName: [null],
      planCdDisp: [{ value: '', disabled: 'true' }]
    }),
    benCovForm: this.fb.group({
      lineCd: [null],
      sublineCd: [null],
      planCd: [null],
      planSubgrp: [null, [Validators.required, this.checkDuplicatePlanSubgrp(), this.noWhiteSpaceCheck()]],
      planSubgrpTitle: [null, [Validators.required, this.noWhiteSpaceCheck()]],
      planSubgrpText: [null],
      seqNo: [null],
      lastUpdateUser: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }]
    }),
    resetBenCovForm: () => {
      this.forms.benCovForm.get('planSubgrp')?.enable();
      this.forms.benCovForm.reset();
      this.btns.benCov.updateDisabled = true;
      this.btns.benCov.deleteDisabled = true;
      this._formService.hideFormMsg("ben-cov-error-message");
      this.rowData = null;
    },
    resetLineSublineLovForm: () => {
      this.forms.lineSublinePlanLovForm.reset();
    },
    resetAllForms: () => {
      this.forms.resetLineSublineLovForm();
      this.forms.resetBenCovForm();
    }
  }

  private benCovFormManipulate = {
    dbToForm: (data: any) => {
      return {
        lineCd: data.LINE_CD,
        sublineCd: data.SUBLINE_CD,
        planCd: data.PLAN_CD,
        planSubgrp: data.PLAN_SUBGRP.toUpperCase(),
        planSubgrpTitle: data.PLAN_SUBGRP_TITLE,
        planSubgrpText: data.PLAN_SUBGRP_TEXT,
        seqNo: data.SEQ_NO,
        lastUpdateUser: data.USER_ID,
        lastUpdate: data.LAST_UPDATE
      };
    },
    formToDb: (data: any, mode: any) => {
      return {
        LINE_CD: data.lineCd,
        SUBLINE_CD: data.sublineCd,
        PLAN_CD: data.planCd,
        PLAN_SUBGRP: data.planSubgrp.toUpperCase(),
        PLAN_SUBGRP_TITLE: data.planSubgrpTitle,
        PLAN_SUBGRP_TEXT: data.planSubgrpText,
        SEQ_NO: data.seqNo=='' || data.seqNo==null ? null : parseInt(data.seqNo),
        USER_ID: this.userIdLoggedIn,
        LAST_UPDATE: null,
        MODE: mode
      };
    }
  }

  public openLov = {
    line: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'LINE',
          moduleId: this.moduleId
        },
      }
      this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button === 'OK') {
          this.forms.resetBenCovForm();
          if (output) {
            this.forms.lineSublinePlanLovForm.patchValue({
              lineCd: output.LINE_CD,
              lineName: output.LINE_NAME,
              lineCdDisp: `${output.LINE_CD} - ${output.LINE_NAME}`,
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null,
              planCd: null,
              planName: null,
              planCdDisp: null,
            });
            this.forms.benCovForm.patchValue({
              lineCd: output.LINE_CD,
              sublineCd: null,
              planCd: null
            });
            this.tempData = [];
            this.btns.lov.sublineDisabled = false;
            this.btns.benCov.addDisabled = true;
          }
          else {
            this.forms.lineSublinePlanLovForm.patchValue({
              lineCd: null,
              lineName: null,
              lineCdDisp: null,
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null,
              planCd: null,
              planName: null,
              planCdDisp: null,
            });
            this.forms.benCovForm.patchValue({
              lineCd: output.LINE_CD,
              sublineCd: null,
              planCd: null
            });
            this.btns.lov.sublineDisabled = true;
            this.btns.benCov.addDisabled = true;
          }
          this.benCovTblConfig.tblData = [];
          this.btns.benCov.saveDisabled = true;
        }
      });
    },
    subline: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'SUBLINE',
          moduleId: this.moduleId
        },
      }
      LOVOPTIONS.data.lineCd = this.forms.lineSublinePlanLovForm.get('lineCd')?.value;
      this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
        let output = dataOutput.content;
        if (output) {
          this.forms.resetBenCovForm();
          this.forms.lineSublinePlanLovForm.patchValue({
            lineCd: output.LINE_CD,
            lineName: output.LINE_NAME,
            lineCdDisp: `${output.LINE_CD} - ${output.LINE_NAME}`,
            sublineCd: output.SUBLINE_CD,
            subLineName: output.SUBLINE_NAME,
            sublineCdDisp: `${output.SUBLINE_CD} - ${output.SUBLINE_NAME}`,
            planCd: null,
            planName: null,
            planCdDisp: null,
          });
          this.forms.benCovForm.patchValue({
            lineCd: output.LINE_CD,
            sublineCd: output.SUBLINE_CD,
            planCd: null,
          });
          this.tempData = [];
          this.btns.lov.planDisabled = false;
        } 
        else {
          this.forms.lineSublinePlanLovForm.patchValue({
            sublineCd: null,
            sublineName: null,
            sublineCdDisp: null,
            planCd: null,
            planName: null,
            planCdDisp: null,
          });
          this.forms.benCovForm.patchValue({
            lineCd: null,
            sublineCd: null,
            planCd: null
          });
          this.btns.benCov.addDisabled = true;
        }
        this.benCovTblConfig.tblData = [];
        this.btns.benCov.saveDisabled = true;
      });
    },
    plan: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'PLAN',
          moduleId: this.moduleId,
          lineCd: this.forms.lineSublinePlanLovForm.get('lineCd')?.value,
          sublineCd: this.forms.lineSublinePlanLovForm.get('sublineCd')?.value,
        },
      };
      this._matDialog.open(PlanLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button?.toUpperCase() === 'OK') {
          this.forms.resetBenCovForm();
          if (output.PLAN_CD) {
            this.forms.lineSublinePlanLovForm.patchValue({
              planCd: output.PLAN_CD,
              planName: output.PLAN_NAME,
              planCdDisp: `${output.PLAN_CD} - ${output.PLAN_NAME}`,
            });
            this.forms.benCovForm.patchValue({
              planCd: output.PLAN_CD
            });
            this.btns.benCov.addDisabled = false;
          }
          else {
            this.forms.lineSublinePlanLovForm.patchValue({
              planCd: null,
              planName: null,
              planCdDisp: null,
            });
            this.btns.benCov.addDisabled = true;
          }
          this.tempData = [];
          this.getData.benCov();
        }
      });
    },
  };

  private getData = {
    benCov: () => {
      try {
        this.benCovTblConfig.tblData = [];
        this.benCovTblConfig.loading = true;
        this._jDS.contorlLoading(true);
        this._api.getBenefitsCoverage({
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          type: "MODULE"
        }).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                let content = JSON.parse(this._jDS.decrypt(data.response));
                this.benCovTblConfig.tblData = content.data.filter((d: any) => 
                  d.LINE_CD == this.forms.lineSublinePlanLovForm.get('lineCd')?.value 
                  && d.SUBLINE_CD == this.forms.lineSublinePlanLovForm.get('sublineCd')?.value
                  && d.PLAN_CD == this.forms.lineSublinePlanLovForm.get('planCd')?.value);
                this.getData.benCovPerPlanPeril();
              });
            });
          },
          error: (error: any) => {
            this.benCovTblConfig.loading = false;
            this._jDS.contorlLoading(false);
          }
        });
      }
      catch (e) {
        console.error(e);
      }
    },
    benCovPerPlanPeril: () => {
      try {
        this._api.getBenefitsCoveragePerPlanPeril({
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          type: "LOV"
        }).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                let content = JSON.parse(this._jDS.decrypt(data.response));
                this.benCovPerPlanPerilList = content.data.filter((d: any) => 
                d.LINE_CD == this.forms.lineSublinePlanLovForm.get('lineCd')?.value 
                && d.SUBLINE_CD == this.forms.lineSublinePlanLovForm.get('sublineCd')?.value
                && d.PLAN_CD == this.forms.lineSublinePlanLovForm.get('planCd')?.value);
                this._jDS.contorlLoading(false);
                this.benCovTblConfig.loading = false;
                this.btns.benCov.addDisabled = false;
              });
            });
          },
          error: (error: any) => {

          }
        });
      }
      catch (e) {
        console.error(e);
      }
    }
  }

  benCovTblConfig = {
    cols: [
      {
        key: "PLAN_SUBGRP",
        header: "Plan Subgroup",
        dataType: "string",
        width: '20%'
      },
      {
        key: "PLAN_SUBGRP_TITLE",
        header: "Subgroup Title",
        dataType: "string",
        width: '25%'
      },
      {
        key: "PLAN_SUBGRP_TEXT",
        header: "Subgroup Text",
        dataType: "string"
      }
    ],
    tblData: [] as any[],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  onRowClick(ev: any) {
    try {
      ev.update = true;
      this.rowData = ev;
      this.populateFormFromTable();
    }
    catch (e) {
      this.forms.resetBenCovForm();
      this.rowData = null;
      //this.indexRow = null;
    }
  }

  populateFormFromTable() {
    try {
      let data = this.rowData;
      this.forms.benCovForm.get('planSubgrp')?.disable();
      this.btns.benCov.updateDisabled = !data.update;
      this.btns.benCov.deleteDisabled = !data.temp;
      this.tempDataRow = !data.temp;
      this.forms.benCovForm.patchValue(this.benCovFormManipulate.dbToForm(data));
    }
    catch (e) {
      console.error(e);
    }
  }

  public onAdd() {
    try {
      this._formService.hideFormMsg("ben-cov-error-message");
      if (!this.seqNoDupCheck()) {
        this._formService.showFormMsg("ben-cov-error-message", "Sequence No. for this Line, Subline and Plan already exists. Please enter a different Sequence No.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("ben-cov-error-message");
        }, 10000);
      }
      else if (this.forms.benCovForm.valid) {
        this.forms.benCovForm.patchValue({
          lineCd: this.forms.lineSublinePlanLovForm.get('lineCd')?.value,
          sublineCd: this.forms.lineSublinePlanLovForm.get('sublineCd')?.value,
          planCd: this.forms.lineSublinePlanLovForm.get('planCd')?.value,
        });
        let rowToBeAdded: { [key: string]: any } = this.benCovFormManipulate.formToDb(this.forms.benCovForm.value, 'I');
        rowToBeAdded.temp = true;
        this.tempData.push(rowToBeAdded);
        this.benCovTblConfig.tblData = [rowToBeAdded, ...this.benCovTblConfig.tblData];
        this.forms.resetBenCovForm();
        this.btns.benCov.saveDisabled = false;
      }
      else {
        this.showErrorValidator.benCov();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public onDelete() {
    try {
      let clickData = this.rowData;
      this.forms.benCovForm.get('planSubgrp')?.enable();
      let rowToBeDeleted: { [key: string]: any } = this.benCovFormManipulate.formToDb(this.forms.benCovForm.value, 'D');
      let benCovPerPlanPerilFiltered = this.benCovPerPlanPerilList.filter((d: any) => {
        return d.PLAN_SUBGRP === clickData.PLAN_SUBGRP
      });
      let tempInsertChecker = this.tempData.find(
        (p: { PLAN_SUBGRP: any; }) => p.PLAN_SUBGRP == clickData.PLAN_SUBGRP && clickData.MODE == 'I');

      if (tempInsertChecker!=null && tempInsertChecker!='') {
        this.benCovTblConfig.tblData = this.benCovTblConfig.tblData.filter((d: any) => {
          return d !== this.rowData;
        });
        this.tempData = this.tempData.filter(
          function(e: any) {
            return e.PLAN_SUBGRP != clickData.PLAN_SUBGRP;
          }
        );
        this.forms.resetBenCovForm();
        if (this.tempData==null || this.tempData=='') {
          this.btns.lov.lineDisabled = false;
          this.btns.lov.sublineDisabled = false;
          this.btns.lov.planDisabled = false;
          this.btns.benCov.saveDisabled = true;
        }
      }
      else if (benCovPerPlanPerilFiltered.length > 0) {
        this._formService.showFormMsg("ben-cov-error-message", "Unable to delete an item with existing record in Benefits and Coverage per Plan Peril table.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("ben-cov-error-message");
        }, 10000);
      }
      else {
        this.benCovTblConfig.tblData = this.benCovTblConfig.tblData.filter((d: any) => {
          return d !== this.rowData;
        });
        this.tempData.push(rowToBeDeleted);
        this.btns.benCov.saveDisabled = false;
        this.forms.resetBenCovForm();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public onUpdate() {
    try {
      this._formService.hideFormMsg("ben-cov-error-message");
      if (!this.seqNoDupCheck()) {
        this._formService.showFormMsg("ben-cov-error-message", "Sequence No. for this Line, Subline and Plan already exists. Please enter a different Sequence No.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("ben-cov-error-message");
        }, 10000);
      }
      else if (this.forms.benCovForm.valid) {
        let clickData = this.rowData;
        let toBeUpdatedIndex = this.benCovTblConfig.tblData.indexOf(this.rowData);
        this.forms.benCovForm.get('planSubgrp')?.enable();
        let rowToBeUpdated: { [key: string]: any } = this.benCovFormManipulate.formToDb(this.forms.benCovForm.value, 'U');
        let tempInsertChecker = this.tempData.find(
          (m: { PLAN_SUBGRP: any; }) => m.PLAN_SUBGRP == clickData.PLAN_SUBGRP && clickData.MODE == 'I');
        let tempUpdateChecker = this.tempData.find(
          (m: { PLAN_SUBGRP: any; }) => m.PLAN_SUBGRP == clickData.PLAN_SUBGRP && clickData.MODE == 'U');
        if (tempInsertChecker!=null && tempInsertChecker!='') {
          //tempInsertChecker = rowToBeUpdated;
          tempInsertChecker.PLAN_SUBGRP_TITLE = rowToBeUpdated.PLAN_SUBGRP_TITLE;
          tempInsertChecker.PLAN_SUBGRP_TEXT = rowToBeUpdated.PLAN_SUBGRP_TEXT;
          tempInsertChecker.SEQ_NO = rowToBeUpdated.SEQ_NO;
          tempInsertChecker.USER_ID = rowToBeUpdated.USER_ID;
          tempInsertChecker.MODE = 'I';
          rowToBeUpdated.MODE = 'I';
        }
        else if (tempUpdateChecker!=null && tempUpdateChecker!='') {
          tempUpdateChecker.PLAN_SUBGRP_TITLE = rowToBeUpdated.PLAN_SUBGRP_TITLE;
          tempUpdateChecker.PLAN_SUBGRP_TEXT = rowToBeUpdated.PLAN_SUBGRP_TEXT;
          tempUpdateChecker.SEQ_NO = rowToBeUpdated.SEQ_NO;
          tempUpdateChecker.USER_ID = rowToBeUpdated.USER_ID;
          tempUpdateChecker.MODE = 'U';
        }
        else { 
          this.tempData.push(rowToBeUpdated);
        }
        this.forms.resetBenCovForm();
        this.benCovTblConfig.tblData[toBeUpdatedIndex] = rowToBeUpdated;
        this.btns.benCov.saveDisabled = false;
        this.benCovTblConfig.tblData = [... this.benCovTblConfig.tblData];
      }
      else {
        this.showErrorValidator.benCov();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public onSave() {
    try {
      this.btns.benCov.saveDisabled = true;
      this.showForm = false;
      this._formService.showFormLoader(null, "ben-cov-maintenance-loading", "Saving.<br>Please wait ...", null, null);
      if (this.tempData.length > 0) {
        this._api.saveBenefitsCoverage(this.tempData).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                if (data.status === 'SUCCESS') {
                  this.onComplete.benCov();
                }
                else {
                  this.onComplete.benCovError();
                }
              });
            });
          },
          error: (data: any) => {
            this.onComplete.benCovError();
          },
        });
      }
      else
        this.onComplete.benCov();
    }
    catch (e) {
      console.error(e);
    }
  }

  private onComplete = {
    benCov: () => {
      this.tempData = [];
      this.getData.benCov();
      this.btns.benCov.saveDisabled = true;
      this.showForm = true;
      this._formService.hideFormLoader("ben-cov-maintenance-loading");
      this.forms.resetBenCovForm();
      this.openDialog('SUCCESS', 'Plan Subgroup details successfully saved');
    },
    benCovError: () => {
      this.showForm = true;
      this.btns.benCov.saveDisabled = false;
      this.openDialog('FAILED', 'Plan Subgroup details saving failed');
      this._formService.hideFormLoader("ben-cov-maintenance-loading");
    },
  }

  openDialog(title: any, message: any) {
    try {
      this.showForm = true;
      this._formService.hideFormLoader("ben-cov-maintenance-loading");
      this._matDialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        } 
      });
    } catch(e) {
      console.error(e);
    }
  }

  seqNoDupCheck() {
    if (!this.forms.benCovForm.get('seqNo')?.value) return true;
    let hasDuplicate: any = this.benCovTblConfig.tblData.filter((d: any) => {
      if (!this.rowData && this.btns.benCov.updateDisabled){
        return d.SEQ_NO == this.forms.benCovForm.get('seqNo')?.value;
      }
      else {
        return this.rowData.SEQ_NO !== this.forms.benCovForm.get('seqNo')?.value && d.SEQ_NO == this.forms.benCovForm.get('seqNo')?.value;
      }
    }).length > 0 ? true : false;
    
    if(hasDuplicate) {
      return false;
    } else {
      return true;
    }
  }

  noWhiteSpaceCheck(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: true };
    }
  }

  checkDuplicatePlanSubgrp(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.trim()?.toUpperCase();
      if (!value) return null;
      let hasDuplicate: boolean = this.benCovTblConfig.tblData.filter((d: any) => {
        if (!this.rowData && this.btns.benCov.updateDisabled){
          return d.PLAN_SUBGRP.trim().toUpperCase() === value;
        }
        else {
          return this.rowData.PLAN_SUBGRP.trim().toUpperCase() !== value && d.PLAN_SUBGRP.trim().toUpperCase() === value;
        }
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupe: true } : null;
    }
  }

  public showErrorValidator = {
    benCov: () => {
      try {
        Object.keys(this.forms.benCovForm.controls).forEach(key => {
          const controlErrors = this.forms.benCovForm.get(key)?.errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              if (keyError == 'whitespace' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
              else if (keyError == 'hasDupe' && controlErrors[keyError] === true)
                this.errorMessage = "Plan Subgroup already exists. Please add a unique Plan Subgroup.";
              else if (keyError == 'required' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
            });
          }
        });
        this._formService.showFormMsg("ben-cov-error-message", this.errorMessage, "E");
        //this.openDialog('ERROR', this.errorMessage);
        setTimeout(()=>{                           
          this._formService.hideFormMsg("ben-cov-error-message");
        }, 10000);
      }
      catch (e) {
        console.error(e);
      }
    },
  }

  autoUppercase(ev: any) {
    try{
      ev.target.value = ev.target.value.toUpperCase();
    }
    catch(e) {
      console.error(e);
    }
  }

  integerFormat(fieldName:string){
    try {
      if(this.forms.benCovForm.get(fieldName)?.value!==''&&this.forms.benCovForm.get(fieldName)?.value!==null){
        let fieldFloat = parseFloat(this.forms.benCovForm.get(fieldName)?.value)
        if(isNaN(fieldFloat)){
          this.forms.benCovForm.get(fieldName)?.setValue('');
        }
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  allowNumericDigitsOnlyWODecimal(event: any) {
    try {
      if (/[0-9]/.test(String.fromCharCode(event.keyCode))) {
        return true;
      }
      else {
        event.preventDefault();
        return false;
      }
    }
    catch (e) {
      console.error(e);
      return undefined; 
    }
  }

}
