import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[posNumInput]'
})
export class PosNumInputDirective {

  constructor(
    private el: ElementRef
  ) { }

  private bannedKeys: string[] = ['Minus', 'KeyE', 'Period', 'NumpadDecimal'];

  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste'
  ];

  @HostListener("keydown", ["$event"])
  onKeyDown(e: KeyboardEvent) {
    if (
      this.navigationKeys.indexOf(e.key) > -1      // Allow: navigation keys: backspace, delete, arrows etc.
      || (e.key === "a" && e.ctrlKey === true)        // Allow: Ctrl+A
      || (e.key === "c" && e.ctrlKey === true)        // Allow: Ctrl+C
      || (e.key === "v" && e.ctrlKey === true)        // Allow: Ctrl+V
      || (e.key === "x" && e.ctrlKey === true)        // Allow: Ctrl+X
      || (e.key === "a" && e.metaKey === true)        // Allow: Cmd+A (Mac)
      || (e.key === "c" && e.metaKey === true)        // Allow: Cmd+C (Mac)
      || (e.key === "v" && e.metaKey === true)        // Allow: Cmd+V (Mac)
      || (e.key === "x" && e.metaKey === true)        // Allow: Cmd+X (Mac)
    ) {
      // let it happen, don't do anything
      return;
    }
    if ([...this.bannedKeys].includes(e.code) || e.shiftKey || (!parseInt(e.key) && parseInt(e.key) !== 0)) {
      e.preventDefault();
    }
  }

  @HostListener("paste", ["$event"])
  onPaste(e: ClipboardEvent) {
    e.preventDefault();
    let pasteValue = e.clipboardData?.getData("text/plain").replace(/[^0-9]/g, "");
    pasteValue && document.execCommand("insertText", false, pasteValue);
  }
}
