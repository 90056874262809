<div class="mtn-container">
    <div class="mtn-title">
        <p>{{moduleName}}</p>
    </div>
    <div class="outer-wrapper p-datatable mtn-header">
        <osp-table [tblConfig]="tblConfig.mHighRisk" (onRowClick)="onRowClick.mHighRisk($event)"></osp-table>
        <div id="mHighRisk-maintenance-loading"><div class="form-loader"></div></div>

        <div class="form-content" [hidden]="!showForm.mHighRisk">

            <form [formGroup]="forms.mHighRiskForm" class="form">
                <div id="mHighRisk-error-message"></div>
                <div class="form-container-grid">
                    <div class="field">
                        <label>High Risk ID</label>
                    </div>
                    <div class="">
                        <input formControlName="highRiskIdPadded" type="text">
                    </div>

                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag" >
                            <option *ngFor="let activeType of selections.activeTypes" [value]="activeType.cd">
                                {{activeType.name}}
                            </option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>High Risk</label>
                    </div>
                    <div class="span">
                        <input formControlName="highRiskDesc" type="text" maxlength="50">
                    </div>

                    <div class="field">
                        <label>Remarks</label>
                        </div>
                    <div class="span">
                        <input formControlName="remarks" type="text" maxlength="4000">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="lastUpdateUser">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="lastUpdate">
                    </div>
                </div>    
            </form>

            <div class="btn-grp">
                <button *ngIf="btns.mHighRisk.updateDisabled" class="btn btn2" type="button" (click)="onAdd.mHighRisk()" [disabled]="btns.mHighRisk.addDisabled" >ADD</button>
                <button *ngIf="!btns.mHighRisk.updateDisabled" class="btn btn2" type="button" (click)="onUpdate.mHighRisk()" >UPDATE</button>
                <button class="btn btn2" type="button" (click)="onDelete.mHighRisk()" [disabled]='btns.mHighRisk.deleteDisabled'>DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-grp">
                <button type="button" class="btn btn2" [disabled]="btns.mHighRisk.saveDisabled" (click)="onSave.mHighRisk()">SAVE</button>
            </div>
        </div>
    </div> 
</div>