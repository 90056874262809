<section id="plan-notification">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName.toUpperCase() }}</p>
        </div>
        <div *ngIf="showForm" class="form-content">
            <form [formGroup]="forms.filterLovForm" class="form">
                <div class="form-container-grid top-form">
                    <div class="field required">
                        <label>Line</label>
                    </div>
                    <div class="">
                        <input formControlName="lineCdDisp">
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['line']()" [disabled]="btns.filterLov.lineLovSearchDisabled"
                             >
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                    <div class="field required">
                        <label>Subline</label>
                    </div>
                    <div class="">
                        <input formControlName="sublineCdDisp">
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['subline']()" [disabled]="btns.filterLov.sublineLovSearchDisabled"
                             >
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                    <div class="field required">
                        <label>Plan</label>
                    </div>
                    <div class="span">
                        <input formControlName="planDisp">
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['plan']()" [disabled]="btns.filterLov.planLovSearchDisabled"
                             >
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <osp-table [tblConfig]="planNotifTblConfig" (onRowClick)="onRowClick($event)" [selectedRow]="selectedRow"></osp-table>
        <div id="plan-notification-maintenance-loading">
            <div class="form-loader"></div>
        </div>
        <div *ngIf="showForm" class="form-content">
            <form [formGroup]="forms.planNotifForm" class="form">
                <div id="plan-notification-error-message"></div>
                <div class="form-container-grid">
                    <div class="field required">
                        <label>Screen</label>
                    </div>
                    <div class="">
                        <select formControlName="screenCd">
                            <option *ngFor="let screenCd of selections.screenCds" [ngValue]="screenCd.cd">
                                {{screenCd.disp}}
                            </option>
                        </select>
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="active">
                            <option *ngFor="let activeType of selections.activeTypes" [ngValue]="activeType.cd">
                                {{activeType.name}}
                            </option>
                        </select>
                    </div>
                    <div class="field required">
                        <label>Notification Path</label>
                    </div>
                    <div class="span">
                        <input formControlName="notifPath" maxlength="65535" >
                    </div>
                    <div class="field required">
                        <label>Notification Title</label>
                    </div>
                    <div class="span">
                        <input formControlName="notifTitle" maxlength="500" >
                    </div>
                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" maxlength="4000" >
                    </div>
                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input formControlName="lastUpdateUser" >
                    </div>
                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="lastUpdate" >
                    </div>
                </div>
            </form>
            <div class="btn-grp">
                <button *ngIf="btns.planNotif.updateDisabled" class="btn btn2" type="button" (click)="onAdd()" [disabled]="btns.planNotif.addDisabled" >ADD</button>
                <button *ngIf="!btns.planNotif.updateDisabled" class="btn btn2" type="button" (click)="onUpdate()" >UPDATE</button>
                <button class="btn btn2" type="button" [disabled]='btns.planNotif.deleteDisabled' (click)="onDelete()" >DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-grp">
                <button class="btn btn2" type="button" [disabled]='btns.planNotif.saveDisabled' (click)="onSave()" >SAVE</button>
            </div>
        </div>
    </div>
</section>