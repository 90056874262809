import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserDetailsService } from './user-details.service';

@Injectable({
  providedIn: 'root'
})
export class PageValidationService {
  currentUrl!: any[];
  constructor(
    private router: Router,
    private userDetailsService: UserDetailsService
  ) { }

  validatePageAccessibility() {
    this.currentUrl = this.router.url.split("/");
    let pageAccess: boolean = false;
    // ? This line below is for Local Host Testing: 
    pageAccess = window.location.hostname.includes("localhost") || window.location.port.includes("4200") ? true : false;
    // *LOGIN / REGISTER VALIDATION
    // if ((["login", "register"].indexOf(this.currentUrl[2]) >= 0 || this.currentUrl[2].includes("login"))
    //   && this.satisfyLoginRegisterRequirement()) {
    if (this.currentUrl[1] === "home" && this.satisfyLoginRegisterRequirement()) {
      pageAccess = true;
      //this.router.navigate(["dashboard"]);
    }
    // *DASHBOARD VALIDATION
    else if (this.currentUrl[1] === "dashboard" && this.satisfyDashboardRequirement()) {
      pageAccess = true;
    }
    // ? If page has no access, defaults to "/home/login"
    if (!pageAccess) {
      this.router.navigate(["home/login"]);
    }
  }

  satisfyLoginRegisterRequirement() {
    // Kung may nakalogin na
    return !this.userDetailsService.sessionExists();
  }

  // ? If logged in, Satisfy this function
  satisfyDashboardRequirement() {
    // Kung may nakalogin na
    return this.userDetailsService.userId;
  }

  hasSessionCheckRoute() {
    const currentUrl = this.router.url.split("/");
    if (currentUrl[1] === "home") {
      this.router.navigate(["dashboard"]);
    }
  }

}
