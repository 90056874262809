import { Component, OnInit } from '@angular/core';
import { APICallService } from 'src/app/services/api-call.service';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { DialogBoxService } from 'src/app/services/dialog-box.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { forkJoin, Observable } from 'rxjs';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { SecurityService } from 'src/app/services/security.service'; 
@Component({
  selector: 'segment-code-maintenance',
  templateUrl: './segment-code-maintenance.component.html',
  styleUrls: ['./segment-code-maintenance.component.css']
})
export class SegmentCodeMaintenanceComponent implements OnInit, LeaveConfirmation {
  
  datePipe = new DatePipe('en-us');

  addDisabled : boolean = false;
  saveDisabled : boolean = true;
  deleteDisabled : boolean = true;
  tranPosted : boolean = false;
  noLoadingInProcess : boolean = true;
  validated : boolean = false;
  userIDReadOnly : boolean = false;
  isLineSelected : boolean = false;
  pubUserStdRatesCodeField: string = '';
  currentUser: string = this.jsonDataService.retrieveFromStorage('userId');
  rowClickedData: any;
  selectedData : any = null;
  moduleId: string = 'BMM148';
  perilDisabled: boolean = true;
  moduleName: string = '';
  tempAddedData: any = [];
  tempUpdateData: any = [];

  segmentCodeForm = this.formBuilder.group({
    segmentCode: [null, [Validators.required]],
    activeTag: ['A', [Validators.required]],
    segment: [null, [Validators.required, Validators.maxLength(50)]],
    origSegment: [null],
    bpimsCode: [null, [Validators.required, Validators.maxLength(8)]],
    bpimsShortDesc: [null,  [Validators.maxLength(10)]],
    remarks: [null],
    userID: [null],
    lastUserUpdate: [null]
  });

  constructor(
    private apiCallService: APICallService,
    public _Dialog: MatDialog, 
    private userDetailService: UserDetailsService,
    private formService: FormService, 
    private formBuilder: FormBuilder, 
    private appMessageService: AppMessageService,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService
  ) {
    let moduleLists = JSON.parse(this.jsonDataService.decrypt(sessionStorage.getItem("mdls") || "")); 
    this.moduleName = moduleLists.filter((data: any) => { return data.moduleId === this.moduleId })[0].moduleDesc.toUpperCase();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempAddedData!='' || this.tempUpdateData!='') {
      return false;
    } else {
      return true;
    }
  }

  segmentCodeTblConfig: any = {
    cols: [
      {
        key: "SEGMENT_CD",
        header: "Segment Code",
        dataType: "number"
      },
      {
        key: "SEGMENT_DESC",
        header: "Segment",
        dataType: "string"
      },
      {
        key: "BM_CODE",
        header: "BPI MS Code",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        width: '10%'
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }
  ngOnInit() {
    try {
      this.apiCallService.refreshNeeded$
            .subscribe(() => {
              this.getAllSegmentCode();
          });
      this.getAllSegmentCode();
    }
    catch(e) {
    }
  }

  async getAllSegmentCode(){
    try{
      const params = {
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE"
      }
      this.segmentCodeTblConfig.loading = true;
      this.apiCallService.getSegmentCode(params).subscribe({
        next: (data: any) => {
          this.securityService.checkRequestKeyResponse(data, ()=>{
            this.securityService.hasValidCsrfToken(data, ()=>{
              data = JSON.parse(this.jsonDataService.decrypt(data.response));
              let rawData:any = [];
              if(!Array.isArray(data)){
                rawData.push(data);
              }
              else{
                rawData = data;
              }
              this.segmentCodeTblConfig.tblData = rawData;
              this.segmentCodeTblConfig.loading = false;
            });
          })
        },
        error: (e: any) => {
          this.segmentCodeTblConfig.loading = false;
        }
      });
    }catch(e){
    }
  }

  updateLine() {
    try {
      this.tranPosted = true;
      this.validateUpdate();
      if (this.validated) {
        this.updateFormTable();
      }
    }
    catch(e) {
    }
  }

  updateFormTable() {
    try {
      let tempInsertChecker = this.tempAddedData.find((line: { SEGMENT_DESC: any }) => 
        line.SEGMENT_DESC.trim().toUpperCase() == this.segmentCodeForm.get('origSegment')?.value.trim().toUpperCase()
      );
      let tempUpdateChecker = this.tempUpdateData.find((line: { SEGMENT_DESC: any }) => 
        line.SEGMENT_DESC.trim().toUpperCase() == this.segmentCodeForm.get('origSegment')?.value.trim().toUpperCase()
      );
      
      this.tableDataUpdate();
        
      // Adds Updated Data that exists in the database to an object array for updated datas
      if(tempInsertChecker!=null && tempInsertChecker!='') {
        //this.tempUpdateData.push(this.formValueToData());
        tempInsertChecker.SEGMENT_CD = this.segmentCodeForm.get('segmentCode')?.value;
        tempInsertChecker.ACTIVE_TAG = this.segmentCodeForm.get('activeTag')?.value;
        tempInsertChecker.SEGMENT_DESC = this.segmentCodeForm.get('segment')?.value;
        tempInsertChecker.BM_CODE = this.segmentCodeForm.get('bpimsCode')?.value;
        tempInsertChecker.BM_SHORT_DESC = this.segmentCodeForm.get('bpimsShortDesc')?.value;
        tempInsertChecker.REMARKS = this.segmentCodeForm.get('remarks')?.value;
        tempInsertChecker.LAST_USER_UPDATE = this.datePipe.transform(new Date(this.segmentCodeForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a');
        tempInsertChecker.USER_ID = this.segmentCodeForm.get('userID')?.value;
        tempInsertChecker.MODE = 'I';
      } 
      else if(tempUpdateChecker!=null && tempUpdateChecker!='') {
        tempUpdateChecker.SEGMENT_CD  = this.segmentCodeForm.get('segmentCode')?.value;
        tempUpdateChecker.ACTIVE_TAG = this.segmentCodeForm.get('activeTag')?.value;
        tempUpdateChecker.SEGMENT_DESC = this.segmentCodeForm.get('segment')?.value;
        tempUpdateChecker.BM_CODE = this.segmentCodeForm.get('bpimsCode')?.value;
        tempUpdateChecker.BM_SHORT_DESC = this.segmentCodeForm.get('bpimsShortDesc')?.value;
        tempUpdateChecker.REMARKS = this.segmentCodeForm.get('remarks')?.value;
        tempUpdateChecker.LAST_USER_UPDATE = this.datePipe.transform(new Date(this.segmentCodeForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a');
        tempUpdateChecker.USER_ID = this.segmentCodeForm.get('userID')?.value;
        tempUpdateChecker.MODE = 'U';
      }
      else {
        this.tempUpdateData.push(this.formValueToData());
      }
      this.saveDisabled = false;
      this.resetFormValues();
      this.appMessageService.showAppMessage("Standard Rates details updated!", "success");
    }
    catch(e) {
    }
  }

  tableDataUpdate() {
    try {
      this.segmentCodeForm.patchValue({
        activeTag: this.segmentCodeForm.get('activeTag')?.value,
        userID: this.currentUser,
        lastUserUpdate: new Date()
      });
      let updateIndex = this.segmentCodeTblConfig.tblData.indexOf(this.rowClickedData);
      
      this.segmentCodeTblConfig.tblData[updateIndex] = {
        SEGMENT_CD: this.segmentCodeForm.get('segmentCode')?.value,
        ACTIVE_TAG: this.segmentCodeForm.get('activeTag')?.value,
        SEGMENT_DESC: this.segmentCodeForm.get('segment')?.value,
        BM_CODE: this.segmentCodeForm.get('bpimsCode')?.value,
        BM_SHORT_DESC: this.segmentCodeForm.get('bpimsShortDesc')?.value,
        REMARKS: this.segmentCodeForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.segmentCodeForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.segmentCodeForm.get('userID')?.value,
        MODE: 'U'
      };
      this.segmentCodeTblConfig.tblData = [...this.segmentCodeTblConfig.tblData];
    }
    catch(e) {
    }
  }

  formValueToData() {
    try {
      let formToData: any = { // this.datePipe.transform(new Date(), 'MM/dd/yyyy')
        SEGMENT_CD: this.segmentCodeForm.get('segmentCode')?.value,
        ACTIVE_TAG: this.segmentCodeForm.get('activeTag')?.value,
        SEGMENT_DESC: this.segmentCodeForm.get('segment')?.value,
        BM_CODE: this.segmentCodeForm.get('bpimsCode')?.value,
        BM_SHORT_DESC: this.segmentCodeForm.get('bpimsShortDesc')?.value,
        REMARKS: this.segmentCodeForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.segmentCodeForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.segmentCodeForm.get('userID')?.value,
        MODE: 'U'
      }
      return formToData;
    }
    catch(e) {
    }
  }
  deleteLine() {
    try {
      let clickData = this.rowClickedData;
      this.segmentCodeTblConfig.tblData = this.segmentCodeTblConfig.tblData.filter(
        function(e: any) {
          return e.SEGMENT_DESC.trim().toUpperCase() !== clickData.SEGMENT_DESC.trim().toUpperCase();
        }
      );
      this.tempAddedData = this.tempAddedData.filter(
        function(e: any) {

          return e.SEGMENT_DESC.trim().toUpperCase() !== clickData.SEGMENT_DESC.trim().toUpperCase();
        }
      );
      this.resetFormValues();
    }
    catch(e) {
    }
  }

  insertLine() {
    try {
      this.tranPosted = true;
      this.validateInsert();
      if (this.validated) {
        this.addFormToTable();
      }
    }
    catch(e) {
    }
  }
  addFormToTable() {
    try {
      this.segmentCodeForm.patchValue({
        activeTag: this.segmentCodeForm.get('activeTag')?.value,
        userID: this.currentUser,
        lastUserUpdate: new Date()
      });
      let formToData: any = { // this.datePipe.transform(new Date(), 'MM/dd/yyyy')
        SEGMENT_CD: this.segmentCodeForm.get('segmentCode')?.value,
        SEGMENT_DESC: this.segmentCodeForm.get('segment')?.value,
        BM_SHORT_DESC: this.segmentCodeForm.get('bpimsShortDesc')?.value,
        ACTIVE_TAG: this.segmentCodeForm.get('activeTag')?.value,
        BM_CODE: this.segmentCodeForm.get('bpimsCode')?.value,
        REMARKS: this.segmentCodeForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.segmentCodeForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.segmentCodeForm.get('userID')?.value,
        MODE: 'I'
      }
      this.segmentCodeTblConfig.tblData.unshift(formToData);
      this.segmentCodeTblConfig.tblData = [...this.segmentCodeTblConfig.tblData];
      this.tempAddedData.push(formToData);
      this.resetFormValues();
      this.appMessageService.showAppMessage("Standard Rates details added!", "success");
    }
    catch(e) {
    }
  }

  saveData(tempChangeData:any[]){
    this.apiCallService.saveSegmentCode(tempChangeData)
    .subscribe({
      next: (response : any) => {
        this.securityService.checkRequestKeyResponse(response, () => {
          this.securityService.hasValidCsrfToken(response, ()=>{
            if (response.status == "SUCCESS" && this.tranPosted) {
              this.openDialog('SUCCESS', 'Details Successfully saved!');
              this.getAllSegmentCode();
              this.resetFormValues();
              this.tempAddedData = [];
              this.tempUpdateData = [];
              this.saveDisabled = this.saveChecker();
            }  
            else {
              this.getAllSegmentCode();
              this.saveDisabled = this.saveChecker();
              this.openDialog('ERROR', 'An error has occured while saving details');
            }
          });
        });
      }, 
      error: () => {
        this.openDialog('ERROR', 'An error has occured while saving details');
      }
    });
  }

  toSave() {
    try {
      this.noLoadingInProcess = false;
      this.formService.showFormLoader(null, "generate-line-form", "Please wait ...", null, null);
      let tempChangeData= this.tempAddedData.concat(this.tempUpdateData);
      if(tempChangeData.length!=0) {
        this.saveData(tempChangeData);
      } else {
        this.getAllSegmentCode();
        this.resetFormValues();
        this.tempAddedData = [];
        this.tempUpdateData = [];
        this.saveDisabled = this.saveChecker();
      }
    }
    catch(e) {
    }
  }
  saveChecker() {
    try {
      if((this.tempAddedData==null || this.tempAddedData=='') && (this.tempUpdateData==null || this.tempUpdateData=='')) {
        return true;
      }
      else {
        return false;
      }
    }
    catch(e) {
      return true;
    }
  }

  deleteChecker(ev: any) {
    try {
      let tempDataFlag;
      tempDataFlag = this.tempAddedData.filter(function(e: any) {
        let returnValue = e.SEGMENT_DESC.trim().toUpperCase()==ev.SEGMENT_DESC.trim().toUpperCase();
        return returnValue;
      });
      if(tempDataFlag==''||tempDataFlag==null) {
        return true;
      } else {
        return false;
      }
    }
    catch(e) {
      return true;
    }
  }

  onRowClick(ev: any) {
    try {    
      if(ev!=null) {
        this.rowClickedData = ev;
        this.loadFormValues();
        this.saveDisabled = this.saveChecker();
        this.deleteDisabled = this.deleteChecker(ev);
      }
      else {
        this.resetFormValues();
      }
    }
    catch(e) {
    }
  }

  loadFormValues() {
    try {
      this.segmentCodeForm.patchValue({
        segmentCode: this.rowClickedData.SEGMENT_CD,
        activeTag: this.rowClickedData.ACTIVE_TAG,
        segment: this.rowClickedData.SEGMENT_DESC,
        origSegment: this.rowClickedData.SEGMENT_DESC,
        bpimsCode: this.rowClickedData.BM_CODE,
        bpimsShortDesc: this.rowClickedData.BM_SHORT_DESC,
        remarks: this.rowClickedData.REMARKS,
        userID: this.rowClickedData.USER_ID,
        lastUserUpdate: this.rowClickedData.LAST_USER_UPDATE
      });
      this.addDisabled = true;
      this.userIDReadOnly = true;
      this.isLineSelected = true;
    }
    catch(e) {
    }
  }

  resetFormValues() {
    try {
      this.selectedData = null;
      // this.paramForm.get('lineCD')?.enable();
      this.segmentCodeForm.patchValue({
        segmentCode: null,
        activeTag: 'A',
        segment: null,
        origSegment: null,
        bpimsCode: null,
        bpimsShortDesc: null,
        remarks: null,
        userID: null,
        lastUserUpdate: null
      });

      this.addDisabled = false;
      this.saveDisabled = this.saveChecker();
      this.deleteDisabled = true;
      this.userIDReadOnly = false;
      this.isLineSelected = false;

      this.rowClickedData = '' || null;
    }
    catch (e) {
    console.error(e);
    }
  }

  openDialog(title: any, message: any) {
    try {
      this.noLoadingInProcess = true;
      this.formService.hideFormLoader("generate-line-form");
      this._Dialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        } 
      });
    } catch(e) {
    }
  }

  validateInsert() {
    try {
      this.validated = false;
      if (!this.requiredFieldCheck()){
        setTimeout(()=>{                           //10 secs countdown
          this.formService.hideFormMsg("segment-code-error-message");
        }, 10000);
      }
      else if (!this.duplicateCheck(this.segmentCodeTblConfig.tblData)) {
        setTimeout(()=>{                           
          this.formService.hideFormMsg("segment-code-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
    }
  }

  requiredFieldCheck() {
    try {
      if(this.segmentCodeForm.get('activeTag')?.value==='' || this.segmentCodeForm.get('activeTag')?.value===null 
        || this.segmentCodeForm.get('segment')?.value.trim()==='' || this.segmentCodeForm.get('segment')?.value===null
        || this.segmentCodeForm.get('bpimsCode')?.value.trim()===''||this.segmentCodeForm.get('bpimsCode')?.value===null) {
        this.formService.showFormMsg("segment-code-error-message", 'There are missing information. Please provide the necessary information needed.', "E");
        return false;
      }
      else {
        return true;
      }
   }
    catch (e) {
    console.error(e);
      return false;
    }
  }
  duplicateCheck(tblData:any) {
    try {
      let fieldSegmentDesc = this.segmentCodeForm.get('segment')?.value;
      this.pubUserStdRatesCodeField = tblData.filter(function(e: any) {
        if(e.SEGMENT_DESC.trim().toUpperCase()==fieldSegmentDesc.trim().toUpperCase()){
          return true
        }
        else{
          return false
        }
      });
      if(this.pubUserStdRatesCodeField=='' || this.pubUserStdRatesCodeField==null) {
        return true;
      } else {
        this.formService.showFormMsg("segment-code-error-message", 'Segment already exists. Please add a unique Segment', "E");
        return false;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }
  validateUpdate() {
    try {
      this.validated = false;
      let fieldSegmentDesc = this.segmentCodeForm.get('origSegment')?.value;
      let filteredTblData = this.segmentCodeTblConfig.tblData.filter(function(e:any){
        if(e.SEGMENT_DESC.trim().toUpperCase()==fieldSegmentDesc.trim().toUpperCase()){
          return false;
        }
        else{
          return true;
        }
      });
      if (!this.requiredFieldCheck()){
        setTimeout(()=>{                          
          this.formService.hideFormMsg("segment-code-error-message");
        }, 10000);
      }
      else if (!this.duplicateCheck(filteredTblData)) {
        setTimeout(()=>{                           
          this.formService.hideFormMsg("segment-code-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
    }
  }
  
}
