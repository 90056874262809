<section id="cat-zone-fire-rates-bpi">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName.toUpperCase() }}</p>
        </div>
        <div *ngIf="showForm" class="form-content">
            <form [formGroup]="forms.filterLovForm" class="form">
                <div class="form-container-grid top-form">
                    <div class="field required">
                        <label>Property Class Code</label>
                    </div>
                    <div class="">
                        <input formControlName="classCdDisp">
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['class']()" [disabled]="btns.filterLov.classLovSearchDisabled"
                             >
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                    <div class="field required">
                        <label>Line</label>
                    </div>
                    <div class="">
                        <input formControlName="lineCdDisp">
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['line']()" [disabled]="btns.filterLov.lineLovSearchDisabled"
                             >
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                    <div class="field required">
                        <label>Subline</label>
                    </div>
                    <div class="">
                        <input formControlName="sublineCdDisp">
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['subline']()" [disabled]="btns.filterLov.sublineLovSearchDisabled"
                             >
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                    <div class="field required">
                        <label>Plan</label>
                    </div>
                    <div class="">
                        <input formControlName="planDisp">
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['plan']()" [disabled]="btns.filterLov.planLovSearchDisabled"
                             >
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                    <div class="field required">
                        <label>Peril</label>
                    </div>
                    <div class="">
                        <input formControlName="perilDisp">
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['peril']()" [disabled]="btns.filterLov.perilLovSearchDisabled"
                             >
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                    <div class="field required">
                        <label>Catastrophic Zone Code</label>
                    </div>
                    <div class="">
                        <input formControlName="catZoneDisp">
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['catZone']()" [disabled]="btns.filterLov.catZoneLovSearchDisabled"
                             >
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <osp-table [tblConfig]="catZoneFireRatesBpiTblConfig" (onRowClick)="onRowClick($event)" [selectedRow]="selectedRow"></osp-table>
        <div id="cat-zone-fire-rates-bpi-maintenance-loading">
            <div class="form-loader"></div>
        </div>
        <div *ngIf="showForm" class="form-content">
            <form [formGroup]="forms.catZoneFireRatesBpiForm" class="form">
                <div id="cat-zone-fire-rates-bpi-error-message"></div>
                <div class="form-container-grid">
                    <div class="field required">
                        <label>No. of Floors</label>
                    </div>
                    <div class="">
                        <input formControlName="noOfFloors" posNumInput type="number" min="1">
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="active" >
                            <option *ngFor="let activeType of selections.activeTypes" [ngValue]="activeType.cd">
                                {{activeType.name}}
                            </option>
                        </select>
                    </div>
                    <div class="field required">
                        <label>Premium Rate</label>
                    </div>
                    <div class="">
                        <input decimal type="text" formControlName="premRt" [digits]="3" [decimals]="9" >
                    </div>
                    <div class="field required">
                        <label>Default</label>
                    </div>
                    <div class="">
                        <select formControlName="default">
                            <option *ngFor="let defaultType of selections.defaultTypes" [ngValue]="defaultType.cd">
                                {{defaultType.name}}
                            </option>
                        </select>
                    </div>
                    <div class="field required">
                        <label>Commission Rate</label>
                    </div>
                    <div class="">
                        <input decimal type="text" formControlName="commRt" [digits]="3" [decimals]="9" >
                    </div>
                    <div></div>
                    <div></div>
                    <div class="field required">
                        <label>Effective Date</label>
                    </div>
                    <div class="">
                        <input date-input [matDatepicker]="effDatePicker" [max]="maxEffDate" formControlName="effDateFrom" placeholder="MM/DD/YYYY"> 
                        <mat-datepicker-toggle class="mtn-datepicker" matSuffix [for]="effDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #effDatePicker></mat-datepicker>
                    </div>
                    <div class="field">
                        <label>Expiry Date</label>
                    </div>
                    <div class="">
                        <input date-input [matDatepicker]="exDatePicker" [min]="minExpDate" formControlName="effDateTo" placeholder="MM/DD/YYYY">
                        <mat-datepicker-toggle matSuffix [for]="exDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #exDatePicker></mat-datepicker>
                    </div>
                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" maxlength="4000" >
                    </div>
                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input formControlName="lastUpdateUser">
                    </div>
                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="lastUpdate">
                    </div>
                </div>
            </form>
            <div class="btn-grp">
                <button *ngIf="btns.catZoneFireRatesBpi.updateDisabled" class="btn btn2" type="button" (click)="onAdd()" [disabled]="btns.catZoneFireRatesBpi.addDisabled" >ADD</button>
                <button *ngIf="!btns.catZoneFireRatesBpi.updateDisabled" class="btn btn2" type="button" (click)="onUpdate()" >UPDATE</button>
                <button class="btn btn2" type="button" [disabled]='btns.catZoneFireRatesBpi.deleteDisabled' (click)="onDelete()" >DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-grp">
                <button class="btn btn2" type="button" [disabled]='btns.catZoneFireRatesBpi.saveDisabled' (click)="onSave()" >SAVE</button>
            </div>
        </div>
    </div>
</section>