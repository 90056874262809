<section id="reset-tab">
    <form [formGroup]="passwordDetails">
        <div id="reset-form">
            <div class="form-loader"></div>
            <div class="form-fields">
                <div class="reset-title">
                    <p>CREATE  A  NEW  PASSWORD</p>
                </div>
                <div class="form">
                    <div class="col-case">
                        <div class="field required">
                            <label>Enter your new password</label>
                            <mat-form-field appearance="fill">
                                <input matInput formControlName="npw" [type]="showNewPassword ? 'text' : 'password'">
                                <button mat-icon-button matSuffix (click)="showNewPassword = !showNewPassword">
                                    <mat-icon>{{showNewPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                        <div class="field required">
                            <label>Re-enter your new password</label>
                            <mat-form-field appearance="fill">
                                <input matInput formControlName="rpw" [type]="showRepeatPassword ? 'text' : 'password'">
                                <button mat-icon-button matSuffix (click)="showRepeatPassword = !showRepeatPassword">
                                    <mat-icon>{{showRepeatPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div id="resetFormMsg"></div>
        </div>
        <div class="form-btns">
            <button class="btn btn4" [routerLink]="['/home/login']">Back</button>
            <button class="btn btn2" (click)="resetPassword()">SUBMIT</button>
        </div>
    </form>
</section>