import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';

@Component({
  selector: 'email-content-maintenance',
  templateUrl: './email-content-maintenance.component.html',
  styleUrls: ['./email-content-maintenance.component.css']
})
export class EmailContentMaintenanceComponent implements OnInit, LeaveConfirmation {

  moduleId: string = 'BMM065';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc?.toUpperCase() ?? "";

  constructor(
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    public _formService: FormService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService
  ) {
    this.userIdLoggedIn = this._userDetailService.userId ?? "";
  }

  ngOnInit(): void {
    this.btns.emailContent.addDisabled = true;
  }

  canDeactivate(): boolean | Observable<boolean> {
    if(this.tempData!='') {
      return false;
    } else {
      return true;
    }
  }

  /* Boolean Stuffs */

  public btns = {
    emailContent: {
      addDisabled: true,
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true
    },
    lov: {
      lineDisabled: false,
      sublineDisabled: true
    }
  }

  public showForm: boolean = true;

  private rowData: any;
  private tempDataRow: any;
  private errorMessage: string = "";
  private userIdLoggedIn: string;
  private tempData: any = [];
  public emailTypeList: any = [];

  public forms = {
    lineSublineLovForm: this.fb.group({
      lineCd: [null],
      lineName: [null],
      lineCdDisp: [{ value: '', disabled: 'true' }],
      sublineCd: [null],
      sublineName: [null],
      sublineCdDisp: [{ value: '', disabled: 'true' }],
    }),
    emailContentForm: this.fb.group({
      lineCd: [null],
      sublineCd: [null],
      emailType: [null, [Validators.required]],
      emailTypeDisp: [null],
      emailSubject: [null],
      emailBody: [null],
      headerImgPath: [null],
      lastUpdateUser: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }]
    }),
    resetEmailContentForm: () => {
      this.forms.emailContentForm.reset();
      this.emailTypeList = this.getData.emailTypeList();
      this.btns.emailContent.updateDisabled = true;
      this.btns.emailContent.deleteDisabled = true;
      this.forms.emailContentForm.get("emailType")?.enable();
      this._formService.hideFormMsg("email-content-error-message");
      this.rowData = null;
    },
    resetLineSublineLovForm: () => {
      this.forms.lineSublineLovForm.reset();
    },
    resetAllForms: () => {
      this.forms.resetLineSublineLovForm();
      this.forms.resetEmailContentForm();
    }
  }

  private emailContentFormManipulate = {
    dbToForm: (data: any) => {
      return {
        lineCd: data.LINE_CD,
        sublineCd: data.SUBLINE_CD,
        emailType: data.EMAIL_TYPE,
        emailTypeDisp: data.EMAIL_TYPE_DISP,
        emailSubject: data.EMAIL_SUBJECT,
        emailBody: data.EMAIL_BODY,
        headerImgPath: data.HEADER_IMG_PATH,
        lastUpdateUser: data.USER_ID,
        lastUpdate: data.LAST_UPDATE
      };
    },
    formToDb: (data: any, mode: any) => {
      return {
        LINE_CD: data.lineCd,
        SUBLINE_CD: data.sublineCd,
        EMAIL_TYPE: data.emailType,
        EMAIL_TYPE_DISP: data.emailTypeDisp,
        EMAIL_SUBJECT: data.emailSubject,
        EMAIL_BODY: data.emailBody,
        HEADER_IMG_PATH: data.headerImgPath,
        USER_ID: this.userIdLoggedIn,
        LAST_UPDATE: null,
        MODE: mode
      };
    }
  }

  public openLov = {
    line: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'LINE',
          moduleId: this.moduleId
        },
      }
      this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button === 'OK') {
          this.forms.resetEmailContentForm();
          if (output) {
            this.forms.lineSublineLovForm.patchValue({
              lineCd: output.LINE_CD,
              lineName: output.LINE_NAME,
              lineCdDisp: `${output.LINE_CD} - ${output.LINE_NAME}`,
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null
            });
            this.forms.emailContentForm.patchValue({
              lineCd: output.LINE_CD,
              sublineCd: null
            });
            this.btns.lov.sublineDisabled = false;
            this.btns.emailContent.addDisabled = true;
            this.tempData = [];
          }
          else {
            this.forms.lineSublineLovForm.patchValue({
              lineCd: null,
              lineName: null,
              lineCdDisp: null
            });
            this.forms.emailContentForm.patchValue({
              lineCd: output.LINE_CD,
              sublineCd: null
            });
            this.btns.lov.sublineDisabled = true;
            this.btns.emailContent.addDisabled = true;
          }
          this.emailContentTblConfig.tblData = [];
          this.btns.emailContent.saveDisabled = true;
        }
      });
    },
    subline: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'SUBLINE',
          moduleId: this.moduleId
        },
      }
      LOVOPTIONS.data.lineCd = this.forms.lineSublineLovForm.get('lineCd')?.value;
      this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
        let output = dataOutput.content;
        if (output) {
          this.forms.resetEmailContentForm();
          this.forms.lineSublineLovForm.patchValue({
            lineCd: output.LINE_CD,
            lineName: output.LINE_NAME,
            lineCdDisp: `${output.LINE_CD} - ${output.LINE_NAME}`,
            sublineCd: output.SUBLINE_CD,
            subLineName: output.SUBLINE_NAME,
            sublineCdDisp: `${output.SUBLINE_CD} - ${output.SUBLINE_NAME}`
          });
          this.forms.emailContentForm.patchValue({
            lineCd: output.LINE_CD,
            sublineCd: output.SUBLINE_CD
          });
          this.btns.emailContent.addDisabled = false;
          this.tempData = [];
        } 
        else {
          this.forms.lineSublineLovForm.patchValue({
            sublineCd: null,
            sublineName: null,
            sublineCdDisp: null
          });
          this.forms.emailContentForm.patchValue({
            lineCd: null,
            sublineCd: null
          });
          this.btns.emailContent.addDisabled = true;
        }
        this.getData.emailContent();
      });
    }
  };

  private getData = {
    emailContent: () => {
      try {
        this.emailContentTblConfig.tblData = [];
        this.emailContentTblConfig.loading = true;
        this._jDS.contorlLoading(true);
        this._api.getEmailContent({
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          type: "MODULE"
        }).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                let content = JSON.parse(this._jDS.decrypt(data.response));
                this._jDS.contorlLoading(false);
                this.emailContentTblConfig.tblData = content.data.filter((d: any) => 
                  d.LINE_CD == this.forms.lineSublineLovForm.get('lineCd')?.value && d.SUBLINE_CD == this.forms.lineSublineLovForm.get('sublineCd')?.value);
                this.btns.emailContent.addDisabled = false;
                this.emailContentTblConfig.loading = false;
                this.emailTypeList = this.getData.emailTypeList();
              });
            });
          },
          error: (error: any) => {
            this.emailContentTblConfig.loading = false;
            this._jDS.contorlLoading(false);
          }
        });
      }
      catch (e) {
        console.error(e);
      }
    },
    emailTypeList: () => {
      this.emailTypeList = [];
      let emailType: any[] = [];
      let addedEmailType: any = [];

      for (const added of this.emailContentTblConfig.tblData) {
        addedEmailType.push(String(added.EMAIL_TYPE));
      }
      emailType.push({      
        cd: '',
        name: '',
        tbl: ''
      });
      
      for (const e of this._jDS.data.refCds.filter((a:any) => a.rvDomain === 'BMM065.EMAIL_TYPE' && !addedEmailType.includes(a.rvLowValue))) {
        emailType.push({      
          cd: e.rvLowValue, 
          name: e.rvLowValue + " - " + e.rvMeaning,
          tbl: e.rvMeaning
        });
      }
      return emailType;
    }
  }

  emailContentTblConfig = {
    cols: [
      {
        key: "EMAIL_TYPE_DISP",
        header: "Email Type",
        dataType: "string",
        width: '20%'
      },
      {
        key: "EMAIL_SUBJECT",
        header: "Email Subject",
        dataType: "string",
        width: '25%'
      },
      {
        key: "EMAIL_BODY",
        header: "Email Body",
        dataType: "string"
      }
    ],
    tblData: [] as any[],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  onRowClick(ev: any) {
    try {
      ev.update = true;
      this.rowData = ev;
      this.emailTypeList = [];
      for (const e of this._jDS.data.refCds.filter((a:any) => a.rvDomain === 'BMM065.EMAIL_TYPE' && a.rvLowValue === ev.EMAIL_TYPE)) {
        this.emailTypeList.push({      
          cd: e.rvLowValue, 
          name: e.rvLowValue + " - " + e.rvMeaning,
          tbl: e.rvMeaning
        });
      }
      this.populateFormFromTable();
      this.forms.emailContentForm.get("emailType")?.disable();
    }
    catch (e) {
      this.forms.resetEmailContentForm();
      this.rowData = null;
      //this.indexRow = null;
    }
  }

  populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.emailContent.updateDisabled = !data.update;
      this.btns.emailContent.deleteDisabled = !data.temp;
      this.tempDataRow = !data.temp;
      this.forms.emailContentForm.patchValue(this.emailContentFormManipulate.dbToForm(data));
    }
    catch (e) {
      console.error(e);
    }
  }

  onAdd() {
    try {
      this._formService.hideFormMsg("email-content-error-message");
      if (this.forms.emailContentForm.valid) {
        this.forms.emailContentForm.patchValue({
          lineCd: this.forms.lineSublineLovForm.get('lineCd')?.value,
          sublineCd: this.forms.lineSublineLovForm.get('sublineCd')?.value,
          emailTypeDisp: this.emailTypeList.filter((e: any) => e.cd == this.forms.emailContentForm.get('emailType')?.value)[0].tbl
        });
        let rowToBeAdded: { [key: string]: any } = this.emailContentFormManipulate.formToDb(this.forms.emailContentForm.value, 'I');
        rowToBeAdded.temp = true;
        this.tempData.push(rowToBeAdded);
        this.emailContentTblConfig.tblData = [rowToBeAdded, ...this.emailContentTblConfig.tblData];
        this.forms.resetEmailContentForm();
        this.btns.emailContent.saveDisabled = false;
      }
      else {
        this.showErrorValidator.emailContent();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public onDelete() {
    try {
      let clickData = this.rowData;
      this.forms.emailContentForm.get('emailType')?.enable();
      let rowToBeDeleted: { [key: string]: any } = this.emailContentFormManipulate.formToDb(this.forms.emailContentForm.value, 'D');
      let tempInsertChecker = this.tempData.find(
        (p: { EMAIL_TYPE: any; }) => p.EMAIL_TYPE == clickData.EMAIL_TYPE && clickData.MODE == 'I');
      this.emailContentTblConfig.tblData = this.emailContentTblConfig.tblData.filter((d: any) => {
        return d !== this.rowData;
      });

      if (tempInsertChecker!=null && tempInsertChecker!='') {
        this.tempData = this.tempData.filter(
          function(e: any) {
            return e.EMAIL_TYPE != clickData.EMAIL_TYPE;
          }
        );
        if (this.tempData==null || this.tempData=='') {
          this.btns.emailContent.saveDisabled = true;
        }
      }
      else {
        this.tempData.push(rowToBeDeleted);
        this.btns.emailContent.saveDisabled = false;
      }
      this.forms.resetEmailContentForm();
    }
    catch (e) {
      console.error(e);
    }
  }

  public onUpdate() {
    try {
      this._formService.hideFormMsg("email-content-error-message");
      
      if (this.forms.emailContentForm.valid) {
        this.forms.emailContentForm.get('emailType')?.enable();
        let clickData = this.rowData;
        let toBeUpdatedIndex = this.emailContentTblConfig.tblData.indexOf(this.rowData);
        let emailTypeRowValue = this.rowData.EMAIL_TYPE;
        let rowToBeUpdated: { [key: string]: any } = this.emailContentFormManipulate.formToDb(this.forms.emailContentForm.value, 'U');
        let tempInsertChecker = this.tempData.find(
          (p: { EMAIL_TYPE: any; }) => p.EMAIL_TYPE == clickData.EMAIL_TYPE && clickData.MODE == 'I');
        let tempUpdateChecker = this.tempData.find(
          (p: { EMAIL_TYPE: any; }) => p.EMAIL_TYPE == clickData.EMAIL_TYPE && clickData.MODE == 'U');
        if (this.rowData.temp) {
          rowToBeUpdated.temp = true;
        }
        else {
          rowToBeUpdated.onDbButUpdatedTemp = true;
          rowToBeUpdated.EMAIL_TYPE = emailTypeRowValue;
        }

        if (tempInsertChecker!=null && tempInsertChecker!='') {
          //tempInsertChecker = rowToBeUpdated;
          tempInsertChecker.EMAIL_SUBJECT = rowToBeUpdated.EMAIL_SUBJECT;
          tempInsertChecker.EMAIL_BODY = rowToBeUpdated.EMAIL_BODY;
          tempInsertChecker.HEADER_IMG_PATH = rowToBeUpdated.HEADER_IMG_PATH;
          tempInsertChecker.USER_ID = rowToBeUpdated.USER_ID;
          tempInsertChecker.MODE = 'I';
          rowToBeUpdated.MODE = 'I';
        }
        else if (tempUpdateChecker!=null && tempUpdateChecker!='') {
          tempUpdateChecker.EMAIL_SUBJECT = rowToBeUpdated.EMAIL_SUBJECT;
          tempUpdateChecker.EMAIL_BODY = rowToBeUpdated.EMAIL_BODY;
          tempUpdateChecker.HEADER_IMG_PATH = rowToBeUpdated.HEADER_IMG_PATH;
          tempUpdateChecker.USER_ID = rowToBeUpdated.USER_ID;
          tempUpdateChecker.MODE = 'U';
        }
        else { 
          this.tempData.push(rowToBeUpdated);
        }
        this.forms.resetEmailContentForm();
        this.emailContentTblConfig.tblData[toBeUpdatedIndex] = rowToBeUpdated;
        this.btns.emailContent.saveDisabled = false;
        this.emailContentTblConfig.tblData = [... this.emailContentTblConfig.tblData];
      }
      else {
        this.showErrorValidator.emailContent();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public onSave() {
    try {
      this.btns.emailContent.saveDisabled = true;
      this.showForm = false;
      this._formService.showFormLoader(null, "email-content-maintenance-loading", "Saving.<br>Please wait ...", null, null);
      if (this.tempData.length > 0) {
        this._api.saveEmailContent(this.tempData).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                if (data.status === 'SUCCESS') {
                  this.onComplete.emailContent();
                }
                else {
                  this.onComplete.emailContentError();
                }
              });
            });
          },
          error: (data: any) => {
            this.onComplete.emailContentError();
          },
        });
      }
      else
        this.onComplete.emailContent();
    }
    catch (e) {
      console.error(e);
    }
  }

  private onComplete = {
    emailContent: () => {
      this.tempData = [];
      this.getData.emailContent();
      this.btns.emailContent.saveDisabled = true;
      this.showForm = true;
      this._formService.hideFormLoader("email-content-maintenance-loading");
      this.forms.resetEmailContentForm();
      this.openDialog('SUCCESS', 'Email Content details successfully saved');
    },
    emailContentError: () => {
      this.showForm = true;
      this.btns.emailContent.saveDisabled = false;
      this.openDialog('FAILED', 'Email Content details saving failed');
      this._formService.hideFormLoader("email-content-maintenance-loading");
    },
  }

  openDialog(title: any, message: any) {
    try {
      this.showForm = true;
      this._formService.hideFormLoader("email-content-maintenance-loading");
      this._matDialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        } 
      });
    } catch(e) {
      console.error(e);
    }
  }

  public showErrorValidator = {
    emailContent: () => {
      try {
        Object.keys(this.forms.emailContentForm.controls).forEach(key => {
          const controlErrors = this.forms.emailContentForm.get(key)?.errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              if (keyError == 'required' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
            });
          }
        });
        this._formService.showFormMsg("email-content-error-message", this.errorMessage, "E");
        //this.openDialog('ERROR', this.errorMessage);
        setTimeout(()=>{                           
          this._formService.hideFormMsg("email-content-error-message");
        }, 10000);
      }
      catch (e) {
        console.error(e);
      }
    }
  }
}
