import { Component, Inject, OnInit } from '@angular/core';
import { APICallService } from 'src/app/services/api-call.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

@Component({
  selector: 'bma-lov',
  templateUrl: './bma-lov.component.html',
  styleUrls: ['./bma-lov.component.css']
})
export class BmaLovComponent implements OnInit {

  bmaModuleId: string = 'BMM057';

  constructor(private apiCallService: APICallService,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    @Inject(MAT_DIALOG_DATA) public dataIn: any,
    private _dialogRef: MatDialogRef<BmaLovComponent>) {
    if (this.dataIn?.table === "BMA_CD")
      this.bmaTableConfig = this.bmaTableConfigBmaCd;
    else
      this.bmaTableConfig = this.bmaTableConfigBmCode;
    this.getBma();
  }

  ngOnInit(): void {
  }

  // Variable Initializer
  bmaData: any = [];

  data: any;

  bmaTableConfig: any;

  // Source Code Initializer
  bmaTableConfigBmCode: any = {
    cols: [
      {
        key: "BM_CODE",
        header: "BMA Code",
        dataType: "string"
      },
      {
        key: "BMA_DESC",
        header: "BMA Description",
        dataType: "string"
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15
  }

  bmaTableConfigBmaCd: any = {
    cols: [
      {
        key: "BMA_CD",
        header: "BMA Code",
        dataType: "string"
      },
      {
        key: "BMA_DESC",
        header: "BMA Description",
        dataType: "string"
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15
  }

  async getBma() {
    try {
      /*
      this.bmaTableConfig.loading = true;
      this.bmaData = await this.apiCallService.getAgentBma().toPromise();
      this.bmaData = this.bmaData.content;
      this.bmaTableConfig.loading = false;
      this.bmaTableConfig.tblData = this.bmaData;
      */
      this.bmaTableConfig.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.apiCallService.getAgentBma({
        moduleId: this.dataIn.moduleId,
        userId: this.userDetailService.userId,
        type: "LOV"
      }).subscribe((response: any) => {
        this.securityService.checkRequestKeyResponse(response, () => {
          this.securityService.hasValidCsrfToken(response, () => {
            if (response.status === 'SUCCESS') {
              let data = JSON.parse(this.jsonDataService.decrypt(response.response));
              if (data.moduleId === this.bmaModuleId) {
                this.jsonDataService.contorlLoading(false);
                this.bmaTableConfig.loading = false;
                this.bmaTableConfig.tblData = data.data.filter((a: any) => a.ACTIVE_TAG === 'A');
                if (this.dataIn.lpad) {
                  this.bmaTableConfig.tblData = this.bmaTableConfig.tblData.map((c: any) => { return { ...c, BMA_CD: parseInt(c.BMA_CD)?.toString().padStart(this.dataIn?.lpad, "0") } })
                }
              } else {
                this.jsonDataService.contorlLoading(false);
                this.bmaTableConfig.loading = false;
                this.appMessageService.showAppMessage("You do not have the correct privilege to access the data. Kindly contact your administrator for assistance.", "error");
              }
            } else {
              this.jsonDataService.contorlLoading(false);
              this.bmaTableConfig.loading = false;
              this.appMessageService.showAppMessage(response.message, "error");
            }
          });
        });
      });
    }
    catch (e) {
    console.error(e);

    }
  }

  onRowClick(ev: any) {
    try {
      this.data = ev;
    }
    catch (e) {
    console.error(e);

    }
  }

  onClose() {
    try {
      if (this.data == '' || this.data == null) {
        this._dialogRef.close({ content: '' });
      } else {
        this._dialogRef.close({ content: this.data, moduleId: this.data.moduleId });
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  onBack() {
    try {
      this._dialogRef.close({ content: '', option: 'back' });
    }
    catch (e) {
    console.error(e);

    }
  }

}
