import { DatePipe } from '@angular/common';
import { Component, OnInit, Type } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';
import { PerilLovComponent } from '../../common/peril-lov/peril-lov.component';
import { CustomValidators } from 'src/app/utils/custom-validator';

interface RowData {
    paytOpt: any,
    credCardInstPaytOpt: any,
}

@Component({
    selector: 'payment-options-maintenance',
    templateUrl: './payment-options-maintenance.html',
    styleUrls: ['./payment-options-maintenance.css']
})
export class PaymentOptionsMaintenanceComponent implements OnInit, LeaveConfirmation {

    moduleId: string = 'BMM064';
    moduleName: string = "";
    formHider: boolean = false;  

    constructor(
        private fb: FormBuilder,
        private _matDialog: MatDialog,
        private _api: APICallService,
        public _formService: FormService,
        private _jsonDS: JsonDataService,
        private _securityService: SecurityService,
        private _userDetailService: UserDetailsService,
        private _appMessageService: AppMessageService
    ) {
        this.userIdLoggedIn = this._userDetailService.userId || "";
    }

    ngOnInit(): void {
        this.moduleName = this._jsonDS.data.moduleList.filter((data: any) => { return data.moduleId === this.moduleId })[0].moduleDesc.toUpperCase();
        this.forms.disableCredCardInstPaytOpt();
        this.getData.paytOpt();
    }

    canDeactivate(): boolean | Observable<boolean> {
        return this.tblConfig.paytOpt.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true)).length > 0 || this.tblConfig.credCardInstPaytOpt.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true)).length > 0
            ? false : true;
    }

    test() {
        this.forms.enableCredCardInstPaytOpt();
    }

    allowNumericDigitsOnly(event: any, field: any) {
        try {
            if (field === 'seqNo') {
                if (/[0-9]/.test(String.fromCharCode(event.keyCode))) {
                    return true;
                } else {
                    event.preventDefault();
                    return false;
                }
            } else {
                if (/[0-9.]/.test(String.fromCharCode(event.keyCode))) {
                    return true;
                } else {
                    event.preventDefault();
                    return false;
                }
            }
        } catch (e) {
    console.error(e);
            return undefined;
        }
    }

    /* Boolean Stuffs */

    public btns = {
        paytOpt: {
            updateDisabled: true,
            deleteDisabled: true,
            saveDisabled: true,
            addDisabled: false,
        },
        credCardInstPaytOpt: {
            lovDisabled: true,
            updateDisabled: true,
            deleteDisabled: true,
            saveDisabled: true,
            addDisabled: true,
        }
    }

    public showForm = {
        paytOpt: true,
        credCardInstPaytOpt: true,
    }

    /* Data Stuffs */

    private rowData: RowData = {
        paytOpt: null,
        credCardInstPaytOpt: null,
    }

    private errorMessage: RowData = {
        paytOpt: null,
        credCardInstPaytOpt: null,
    }

    private userIdLoggedIn: string;

    /* Form Object  */

    public forms = {
        paytOptForm: this.fb.group({
            paytCd: [''],
            paytCdPadded: [{ value: '', disabled: 'true' }],
            activeTag: ['A', [Validators.required]],
            paynamicsCd: ['', [this.checkDuplicatePaynamicsCd('paytOpt'), Validators.maxLength(20)]],
            default:  ['', [Validators.required]],
            paytDesc: ['', [Validators.required, this.checkDuplicatePaytDesc(), Validators.maxLength(250), CustomValidators.requiredTrim]],
            imgPath: ['', [Validators.maxLength(500)]],
            seqNo: ['', [this.checkDuplicateSeqNo('paytOpt')]],
            minPaytAmt: ['', [this.checkDecimalsExceeded(14, 2)]],
            remarks: ['', [Validators.maxLength(4000)]],
            lastUpdateUser: [{ value: '', disabled: 'true' }],
            lastUpdate: [{ value: '', disabled: 'true' }],
        }),
        credCardInstPaytOptForm: this.fb.group({
            ccInstCd: [null],
            ccInstCdPadded: [{ value: '', disabled: 'true' }],
            activeTag: ['A', [Validators.required]],
            paynamicsCd: [null, [this.checkDuplicatePaynamicsCd('credCardInstPaytOpt'), Validators.maxLength(20)]],
            seqNo: [null, [this.checkDuplicateSeqNo('credCardInstPaytOpt')]],
            ccInstDesc: [null, [Validators.required, this.checkDuplicateInstDesc(), Validators.maxLength(250), CustomValidators.requiredTrim]],
            imgPath: [null, [Validators.maxLength(500)]],
            remarks: [null, [Validators.maxLength(4000)]],
            lastUpdateUser: [{ value: '', disabled: 'true' }],
            lastUpdate: [{ value: '', disabled: 'true' }],
        }),
        resetCredCardInstPaytOptForm: () => {
            this.forms.credCardInstPaytOptForm.reset();
            this.forms.credCardInstPaytOptForm.patchValue({
                activeTag: 'A'
            });
            this.btns.credCardInstPaytOpt.updateDisabled = true;
            this.btns.credCardInstPaytOpt.deleteDisabled = true;
            this._formService.hideFormMsg("credCardInstPaytOpt-error-message");
            this.rowData.credCardInstPaytOpt = null;
        },
        resetPaytOptForm: () => {
            this.forms.paytOptForm.reset();
            this.forms.paytOptForm.patchValue({
                activeTag: 'A',
                default: ''
            });
            this.btns.paytOpt.updateDisabled = true;
            this.btns.paytOpt.deleteDisabled = true;
            this._formService.hideFormMsg("paytOpt-error-message");
            this.rowData.paytOpt = null;
        },
        resetAllForms: function () {
            this.resetPaytOptForm();
            this.resetCredCardInstPaytOptForm();
        },
        disableCredCardInstPaytOpt: () => {
            this.forms.resetCredCardInstPaytOptForm();
            this.forms.credCardInstPaytOptForm.disable();
            this.tblConfig.credCardInstPaytOpt.tblData = [];
            this.btns.credCardInstPaytOpt.addDisabled = true;
        },
        enableCredCardInstPaytOpt: () => {
            this.forms.resetCredCardInstPaytOptForm();
            this.forms.credCardInstPaytOptForm.enable();
            this.forms.credCardInstPaytOptForm.get('lastUpdateUser')?.disable();
            this.forms.credCardInstPaytOptForm.get('lastUpdate')?.disable();
            this.forms.credCardInstPaytOptForm.get('ccInstCdPadded')?.disable();
            this.btns.credCardInstPaytOpt.addDisabled = false;
        }
    };

    /* Table Object */

    public tblConfig: RowData = {
        paytOpt: {
            cols: [
                {
                    key: "PAYT_CD_PADDED",
                    header: "Payment Code",
                    dataType: "string",
                    width: '17%'
                },
                {
                    key: "PAYNAMICS_CD",
                    header: "Paynamics Code",
                    dataType: "string",
                    width: '17%'
                },
                {
                    key: "PAYT_DESC",
                    header: "Payment Description",
                    dataType: "string",
                    width: '30%'
                },
                {
                    key: "MIN_PAYT_AMT",
                    header: "Minimum Payment Amount",
                    dataType: "currency",
                    width: '30%'
                },
                {
                    key: "ACTIVE_TAG",
                    header: "A",
                    dataType: "checkbox",
                    width: '6%'
                },
            ],
            tblData: [],
            selection: "single",
            paginator: true,
            rowsPerPage: 10,
            lazy: false,
            totalRecords: 15,
            loading: false,
        },
        credCardInstPaytOpt: {
            cols: [
                {
                    key: "CC_INST_CD_PADDED",
                    header: "Credit Card Installment Code",
                    dataType: "string",
                    width: "30%"
                },
                {
                    key: "PAYNAMICS_CD",
                    header: "Paynamics Code",
                    dataType: "string",
                    width: "30%"
                },
                {
                    key: "CC_INST_DESC",
                    header: "Credit Card Installment Desciption",
                    dataType: "string",
                    width: "35%"
                },
                {
                    key: "ACTIVE_TAG",
                    header: "A",
                    dataType: "checkbox",
                    width: "5%"
                }
            ],
            tblData: [] as any[],
            selection: "single",
            paginator: true,
            rowsPerPage: 10,
            lazy: false,
            totalRecords: 15,
            loading: false,
        },
    }

    private formManipulation = {
        paytOpt: {
            dbToForm: function (data: any) {
                return {
                    paytCd: (data.PAYT_CD) ? data.PAYT_CD : null, //(data.PAYT_CD) ? data.PAYT_CD.toString().padStart(2, 0) : null,
                    paytCdPadded: data.PAYT_CD_PADDED,
                    paynamicsCd: data.PAYNAMICS_CD,
                    activeTag: data.ACTIVE_TAG,
                    default: data.DEFAULT_TAG,
                    paytDesc: data.PAYT_DESC,
                    imgPath: data.IMAGE_PATH,
                    seqNo: data.SEQ_NO,
                    minPaytAmt: data.MIN_PAYT_AMT,
                    remarks: data.REMARKS,
                    lastUpdateUser: data.LAST_USER,
                    lastUpdate: data.LAST_USER_UPDATE
                };
            },
            formToDb: function (data: any) {
                return {
                    PAYT_CD: data.paytCd,
                    PAYT_CD_PADDED: (data.paytCd) ? data.paytCd.toString().padStart(2, 0) : null,
                    PAYNAMICS_CD: data.paynamicsCd,
                    ACTIVE_TAG: data.activeTag,
                    DEFAULT_TAG: data.default,
                    PAYT_DESC: data.paytDesc,
                    IMAGE_PATH: data.imgPath,
                    SEQ_NO: data.seqNo,
                    MIN_PAYT_AMT: data.minPaytAmt,
                    REMARKS: data.remarks,
                    LAST_USER: null,
                    LAST_USER_UPDATE: null,
                };
            },
        },
        credCardInstPaytOpt: {
            dbToForm: function (data: any) {
                return {
                    ccInstCd: (data.CC_INST_CD) ? data.CC_INST_CD : null,
                    ccInstCdPadded: data.CC_INST_CD_PADDED,
                    paynamicsCd: data.PAYNAMICS_CD,
                    ccInstDesc: data.CC_INST_DESC,
                    seqNo: data.SEQ_NO,
                    activeTag: data.ACTIVE_TAG,
                    imgPath: data.IMAGE_PATH,
                    remarks: data.REMARKS,
                    lastUpdateUser: data.LAST_USER,
                    lastUpdate: data.LAST_USER_UPDATE
                };
            },
            formToDb: function (data: any) {
                return {
                    CC_INST_CD: data.ccInstCd,
                    CC_INST_CD_PADDED: (data.ccInstCd) ? data.ccInstCd.toString().padStart(2, 0) : null,
                    PAYNAMICS_CD: data.paynamicsCd,
                    CC_INST_DESC: data.ccInstDesc,
                    SEQ_NO: data.seqNo,
                    ACTIVE_TAG: data.activeTag,
                    IMAGE_PATH: data.imgPath,
                    REMARKS: data.remarks,
                    LAST_USER: null,
                    LAST_USER_UPDATE: null
                };
            },
        }
    }

    public selections = {
        activeTypes: [
            { cd: 'A', name: 'Yes' },
            { cd: 'I', name: 'No' },
        ],
        defaultTypes: [
            { cd: 'Y', name: 'Yes' },
            { cd: 'N', name: 'No' },
        ],
    };

    private getData = {
        paytOpt: () => {
            try {
                this.tblConfig.paytOpt.tblData = [];
                this.tblConfig.paytOpt.loading = true;
                this._jsonDS.contorlLoading(true);
                this._api.getPaytOpt({
                    moduleId: this.moduleId,
                    userId: this._userDetailService.userId,
                    type: "MODULE"
                }).subscribe({ 
                    next: (response: any) => {
                        this._securityService.checkRequestKeyResponse(response, () => {
                            this._securityService.hasValidCsrfToken(response, () => {
                                try {
                                    if (response.status === 'SUCCESS') {
                                        response = JSON.parse(this._jsonDS.decrypt(response.response));
                                        this._jsonDS.contorlLoading(false);
                                        let filteredData = response.data.map((e: any) => {
                                            return {...e, MIN_PAYT_AMT: e.MIN_PAYT_AMT ? +e.MIN_PAYT_AMT : '' }
                                        });
                                        this.tblConfig.paytOpt.tblData = filteredData;
                                    } else {
                                        this.tblConfig.paytOpt.loading = false;
                                        this._jsonDS.contorlLoading(false);
                                        this._appMessageService.showAppMessage(response.message, "error");
                                    }
                                } catch (e) {
    console.error(e);
                                    this._jsonDS.contorlLoading(false);
                                }
                                this.tblConfig.paytOpt.loading = false;
                            });
                        });
                    }, error: (e: any) => {
                        this.tblConfig.paytOpt.loading = false;
                        this._jsonDS.contorlLoading(false);
                    }
                });
            } catch (e) {
    console.error(e);
                this.tblConfig.paytOpt.loading = false;
                this._jsonDS.contorlLoading(false);
            }
        },
        credCardInstPaytOpt: () => {
            try {
                this.tblConfig.credCardInstPaytOpt.tblData = [];
                this.tblConfig.credCardInstPaytOpt.loading = true;
                this._jsonDS.contorlLoading(true);
                this._api.getCredCardInstPaytOpt({
                    moduleId: this.moduleId,
                    userId: this._userDetailService.userId,
                    type: "MODULE"
                }).subscribe({
                    next: (response: any) => {
                        this._securityService.checkRequestKeyResponse(response, () => {
                            this._securityService.hasValidCsrfToken(response, () => {
                                try {
                                    if (response.status === 'SUCCESS') {
                                        response = JSON.parse(this._jsonDS.decrypt(response.response));
                                        this._jsonDS.contorlLoading(false);
                                        let filteredData = response.data;
                                        this.tblConfig.credCardInstPaytOpt.tblData = filteredData;
                                    } else {
                                        this.tblConfig.credCardInstPaytOpt.loading = false;
                                        this._jsonDS.contorlLoading(false);
                                        this._appMessageService.showAppMessage(response.message, "error");
                                    }
                                } catch (e) {
    console.error(e);
                                }
                                this.tblConfig.credCardInstPaytOpt.loading = false;
                                this._jsonDS.contorlLoading(false);
                            });
                        });
                    }, error: (e: any) => {
                        this.tblConfig.credCardInstPaytOpt.loading = false;
                        this._jsonDS.contorlLoading(false);
                    }
                });
            } catch (e) {
    console.error(e);
                this.tblConfig.credCardInstPaytOpt.loading = false;
                this._jsonDS.contorlLoading(false);
            }
        }
    }

    public onRowClick = {
        paytOpt: (ev: any) => {
            try {
                ev.update = true;
                this.rowData.paytOpt = ev;
                this.populateFormFromTable.paytOpt();
                if (ev.PAYT_DESC.toUpperCase() === "CREDIT CARD INSTALLMENT") {
                    this.forms.enableCredCardInstPaytOpt();
                    this.getData.credCardInstPaytOpt();
                } else {
                    this.forms.disableCredCardInstPaytOpt();
                }
            } catch (e) {
    console.error(e);
                this.forms.resetPaytOptForm();
                this.forms.disableCredCardInstPaytOpt();
                this.rowData.paytOpt = null;
            }
        },
        credCardInstPaytOpt: (ev: any) => {
            try {
                ev.update = true;
                this.rowData.credCardInstPaytOpt = ev;
                this.populateFormFromTable.credCardInstPaytOpt();
            } catch (e) {
    console.error(e);
                this.forms.resetCredCardInstPaytOptForm();
                this.rowData.credCardInstPaytOpt = null;
            }
        }
    }

    private populateFormFromTable = {
        paytOpt: () => {
            try {
                let data = this.rowData.paytOpt;
                this.btns.paytOpt.updateDisabled = !data.update;
                this.btns.paytOpt.deleteDisabled = !data.temp;
                this.forms.paytOptForm.patchValue(this.formManipulation.paytOpt.dbToForm(data));
            } catch (e) {
    console.error(e);
            }
        },
        credCardInstPaytOpt: () => {
            try {
                let data = this.rowData.credCardInstPaytOpt;
                this.btns.credCardInstPaytOpt.updateDisabled = !data.update;
                this.btns.credCardInstPaytOpt.deleteDisabled = !data.temp;
                this.forms.credCardInstPaytOptForm.patchValue(this.formManipulation.credCardInstPaytOpt.dbToForm(data));
            } catch (e) {
    console.error(e);
            }
        },
    }

    public onAdd = {
        paytOpt: () => {
            try {
                this._formService.hideFormMsg("paytOpt-error-message");
                if (this.forms.paytOptForm.valid) {
                    let rowToBeAdded: { [key: string]: any } = this.formManipulation.paytOpt.formToDb(this.forms.paytOptForm.value);
                    rowToBeAdded.temp = true;
                    this.tblConfig.paytOpt.tblData = [rowToBeAdded, ...this.tblConfig.paytOpt.tblData];
                    this.forms.resetPaytOptForm();
                    this.btns.paytOpt.saveDisabled = false;
                } else {
                    this.showErrorValidator.paytOpt();
                }
            } catch (e) {
    console.error(e);
            }
        },
        credCardInstPaytOpt: () => {
            try {
                this._formService.hideFormMsg("plan-peril-error-message");
                if (this.forms.credCardInstPaytOptForm.valid) {
                    let rowToBeAdded: { [key: string]: any } = this.formManipulation.credCardInstPaytOpt.formToDb(this.forms.credCardInstPaytOptForm.value);
                    rowToBeAdded.temp = true;
                    this.tblConfig.credCardInstPaytOpt.tblData = [rowToBeAdded, ...this.tblConfig.credCardInstPaytOpt.tblData];
                    this.forms.resetCredCardInstPaytOptForm();
                    this.btns.credCardInstPaytOpt.saveDisabled = false;
                } else {
                    this.showErrorValidator.credCardInstPaytOpt();
                }
            } catch (e) {
    console.error(e);
            }
        }
    }

    public onDelete = {
        paytOpt: () => {
            try {
                this.tblConfig.paytOpt.tblData = this.tblConfig.paytOpt.tblData.filter((d: any) => {
                    return d !== this.rowData.paytOpt;
                });
                this.forms.resetPaytOptForm();
                let toBeSaved = this.tblConfig.paytOpt.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true));
                if (toBeSaved.length === 0) {
                    this.btns.paytOpt.saveDisabled = true;
                } else {
                    this.btns.paytOpt.saveDisabled = false;
                }
            } catch (e) {
    console.error(e);
            }
        },
        credCardInstPaytOpt: () => {
            try {
                this.tblConfig.credCardInstPaytOpt.tblData = this.tblConfig.credCardInstPaytOpt.tblData.filter((d: any) => {
                    return d !== this.rowData.credCardInstPaytOpt;
                });
                this.forms.resetCredCardInstPaytOptForm();
                let toBeSaved = this.tblConfig.credCardInstPaytOpt.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true));
                if (toBeSaved.length === 0) {
                    this.btns.paytOpt.saveDisabled = true;
                } else {
                    this.btns.paytOpt.saveDisabled = false;
                }
            } catch (e) {
    console.error(e);
            }
        }
    }

    public onUpdate = {
        paytOpt: () => {
            try {
                this._formService.hideFormMsg("paytOpt-error-message");
                if (this.forms.paytOptForm.valid) {
                    let toBeUpdatedIndex = this.tblConfig.paytOpt.tblData.indexOf(this.rowData.paytOpt);
                    let rowToBeUpdated: { [key: string]: any } = this.formManipulation.paytOpt.formToDb(this.forms.paytOptForm.value);
                    if (this.rowData.paytOpt.temp) {
                        rowToBeUpdated.temp = true;
                    } else {
                        rowToBeUpdated.onDbButUpdatedTemp = true;
                    }
                    this.forms.resetPaytOptForm();
                    this.tblConfig.paytOpt.tblData[toBeUpdatedIndex] = rowToBeUpdated;
                    this.btns.paytOpt.saveDisabled = false;
                    this.tblConfig.paytOpt.tblData = [... this.tblConfig.paytOpt.tblData];
                } else {
                    this.showErrorValidator.paytOpt();
                }
            } catch (e) {
    console.error(e);
            }
        },
        credCardInstPaytOpt: () => {
            try {
                this._formService.hideFormMsg("credCardInstPaytOpt-error-message");
                if (this.forms.credCardInstPaytOptForm.valid) {
                    let toBeUpdatedIndex = this.tblConfig.credCardInstPaytOpt.tblData.indexOf(this.rowData.credCardInstPaytOpt);
                    let rowToBeUpdated: { [key: string]: any } = this.formManipulation.credCardInstPaytOpt.formToDb(this.forms.credCardInstPaytOptForm.value);
                    if (this.rowData.credCardInstPaytOpt.temp) {
                        rowToBeUpdated.temp = true;
                    } else {
                        rowToBeUpdated.onDbButUpdatedTemp = true;
                    }
                    this.forms.resetCredCardInstPaytOptForm();
                    this.tblConfig.credCardInstPaytOpt.tblData[toBeUpdatedIndex] = rowToBeUpdated;
                    this.btns.credCardInstPaytOpt.saveDisabled = false;
                    this.tblConfig.credCardInstPaytOpt.tblData = [... this.tblConfig.credCardInstPaytOpt.tblData];
                } else {
                    this.showErrorValidator.credCardInstPaytOpt();
                }
            } catch (e) {
    console.error(e);
            }
        },
    }

    public onSave = {
        paytOpt: () => {
            this.showForm.paytOpt = false;
            this._formService.showFormLoader(null, "paytOpt-maintenance-loading", "Saving.<br>Please wait ...", null, null);
            let toBeSaved = this.tblConfig.paytOpt.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true));
            if (toBeSaved.length > 0) {
                toBeSaved.map((d: any) => {
                    d.LAST_USER = this.userIdLoggedIn;
                });
                this._api.savePaytOpt(toBeSaved).subscribe({
                    next: (response: any) => {
                        this._securityService.checkRequestKeyResponse(response, () => {
                            this._securityService.hasValidCsrfToken(response, () => {
                                if (response.status === 'SUCCESS') {
                                    this.onComplete.paytOpt();
                                } else {
                                    this.onComplete.paytOptError();
                                }
                            });
                        });
                    }, error: (response: any) => {
                        this.onComplete.paytOptError();
                    },
                });
            } else {
                this.onComplete.paytOpt();
            }
        },
        credCardInstPaytOpt: () => {
            this.showForm.credCardInstPaytOpt = false;
            this._formService.showFormLoader(null, "credCardInstPaytOpt-maintenance-loading", "Saving.<br>Please wait ...", null, null);
            let toBeSaved = this.tblConfig.credCardInstPaytOpt.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true));
            if (toBeSaved.length > 0) {
                toBeSaved.map((d: any) => {
                    d.USER_ID = this.userIdLoggedIn;
                });
                this._api.saveCredCardInstPaytOpt(toBeSaved).subscribe({
                    next: (response: any) => {
                        this._securityService.checkRequestKeyResponse(response, () => {
                            this._securityService.hasValidCsrfToken(response, () => {
                                if (response.status === 'SUCCESS') {
                                    this.onComplete.credCardInstPaytOpt();
                                } else {
                                    this.onComplete.credCardInstPaytOptError();
                                }
                            }); 
                        });
                    }, error: (response: any) => {
                        this.onComplete.credCardInstPaytOptError();
                    },
                });
            } else {
                this.onComplete.credCardInstPaytOpt();
            }
        }
    }

    openDialog(title: string, contentTitle: string, contentDetail: string) {
        try {
            this._matDialog.open(DialogMsgComponent, {
                disableClose: true,
                width: '512px',
                data: {
                title: title,
                content:
                    title === 'SUCCESS'
                    ? `${contentTitle} details successfully ${contentDetail}!`
                    : `${contentTitle} details ${contentDetail} failed!`,
                },
            });
        } catch (e) {
    console.error(e);
        }
    }

    private onComplete = {
        paytOpt: () => {
            this.getData.paytOpt();
            this.btns.paytOpt.saveDisabled = true;
            this.showForm.paytOpt = true;
            this._formService.hideFormLoader("paytOpt-maintenance-loading");
            this.openDialog('SUCCESS', 'Payment Options', 'saved');
        },
        credCardInstPaytOpt: () => {
            this.getData.credCardInstPaytOpt();
            this.btns.credCardInstPaytOpt.saveDisabled = true;
            this.showForm.credCardInstPaytOpt = true;
            this._formService.hideFormLoader("credCardInstPaytOpt-maintenance-loading");
            this.openDialog('SUCCESS', 'Credit Card Installment Options', 'saved');
        },
        paytOptError: () => {
            this.showForm.paytOpt = true;
            this.btns.paytOpt.saveDisabled = false;
            this.openDialog('FAILED', 'Payment Options', 'saving');
            this._formService.hideFormLoader("paytOpt-maintenance-loading");
        },
        credCardInstPaytOptError: () => {
            this.showForm.credCardInstPaytOpt = true;
            this.btns.credCardInstPaytOpt.saveDisabled = false;
            this.openDialog('FAILED', 'Credit Card Installment Options', 'saving');
            this._formService.hideFormLoader("credCardInstPaytOpt-maintenance-loading");
        },
    }

    /* Validator Stuffs Down here */

    checkDuplicatePaynamicsCd(module: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value === null ? control.value : control.value.trim();
            if (!value) {
                return null;
            }
            let hasDuplicate: boolean = this.tblConfig[module as keyof RowData].tblData.filter((d: any) => {
                if (!this.rowData[module as keyof RowData]) {
                    let pay_cd = d.PAYNAMICS_CD || '';
                    //return d.PAYNAMICS_CD.toUpperCase() === value.toUpperCase();
                    return pay_cd.toUpperCase() === value.toUpperCase();
                } else {
                    let pay_cd = d.PAYNAMICS_CD || '';
                    let pay_cd1 = this.rowData[module as keyof RowData].PAYNAMICS_CD || '';
                    //return this.rowData[module as keyof RowData].PAYNAMICS_CD.toUpperCase() !== value.toUpperCase() && d.PAYNAMICS_CD.toUpperCase() === value.toUpperCase();
                    return pay_cd1.toUpperCase() !== value.toUpperCase() && pay_cd.toUpperCase() === value.toUpperCase();
                }
            }).length > 0 ? true : false;
            return hasDuplicate ? { hasDupePaynamicsCd: true } : null;
        }
    }

    checkDuplicatePaytDesc(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value === null ? control.value : control.value.trim();
            if (!value) {
                return null;
            } 
            let hasDuplicate: boolean = this.tblConfig.paytOpt.tblData.filter((d: any) => {
                if (!this.rowData.paytOpt) {
                    return d.PAYT_DESC.toUpperCase() === value.toUpperCase();
                } else {
                    return this.rowData.paytOpt.PAYT_DESC.toUpperCase() !== value.toUpperCase() && d.PAYT_DESC.toUpperCase() === value.toUpperCase();
                }
            }).length > 0 ? true : false;
            return hasDuplicate ? { hasDupePaytDesc: true } : null;
        }
    }

    checkDuplicateInstDesc(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value === null ? control.value : control.value.trim();
            if (!value) {
                return null;
            } 
            let hasDuplicate: boolean = this.tblConfig.credCardInstPaytOpt.tblData.filter((d: any) => {
                if (!this.rowData.credCardInstPaytOpt) {
                    return d.CC_INST_DESC.toUpperCase() === value.toUpperCase();
                } else {
                    return this.rowData.credCardInstPaytOpt.CC_INST_DESC.toUpperCase() !== value.toUpperCase() && d.CC_INST_DESC.toUpperCase() === value.toUpperCase();
                }
            }).length > 0 ? true : false;
            return hasDuplicate ? { hasDupeInstDesc: true } : null;
        }
    }

    checkDuplicateSeqNo(module: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            if (!value) {
                return null;
            }
            let hasDuplicate: boolean = this.tblConfig[module as keyof RowData].tblData.filter((d: any) => {
                if (!this.rowData[module as keyof RowData]) {
                    return d.SEQ_NO == value;
                } else {
                    return this.rowData[module as keyof RowData].SEQ_NO != value && d.SEQ_NO == value;
                }
            }).length > 0 ? true : false;
            return hasDuplicate ? { hasDupeSeqNo: true } : null;
        }
    }

    private checkDecimalsExceeded(digits: number, decimals: number): ValidatorFn {
      return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value && parseFloat(this._formService.setToNumberWithDecimal(control.value));
        if (!value) return null;
        let isExceeded: boolean = value > +"".padEnd(digits, '9') + +('.' + ''.padEnd(decimals, '9'))
        return isExceeded ? { hasDecimalsExceeded: true } : null;
      }
    }

    public showErrorValidator = {
        paytOpt: () => {
            try {
                Object.keys(this.forms.paytOptForm.controls).forEach(key => {
                    const controlErrors = this.forms.paytOptForm.get(key)?.errors;
                    if (controlErrors != null) {
                        Object.keys(controlErrors).forEach(keyError => {
                            if (keyError == 'required' && controlErrors[keyError] === true){
                                this.errorMessage.paytOpt = "There are missing information. Please provide necessary information needed.";
                            } else if (keyError == 'hasDupeSeqNo' && controlErrors[keyError] === true) {
                                this.errorMessage.paytOpt = "Sequence No. already exists. Please choose a different Sequence No.";
                            } else if (keyError == 'hasDupePaynamicsCd' && controlErrors[keyError] === true) {
                                this.errorMessage.paytOpt = "Paynamics Code already exists. Please choose a different Paynamics Code.";
                            } else if (keyError == 'hasDupePaytDesc' && controlErrors[keyError] === true) {
                                this.errorMessage.paytOpt = "Payment Description already exists. Please choose a different Payment Description.";
                            } else if (keyError == 'hasDecimalsExceeded' && controlErrors[keyError] === true) {
                                this.errorMessage.paytOpt = "Invalid value in Minimum Payment Amount. The value entered exceeded the maximum limit.";
                            }
                        });
                    }
                });
                this._formService.showFormMsg("paytOpt-error-message", this.errorMessage.paytOpt, "E");
            } catch (e) {
    console.error(e);
            }
        },
        credCardInstPaytOpt: () => {
            try {
                Object.keys(this.forms.credCardInstPaytOptForm.controls).forEach(key => {
                    const controlErrors = this.forms.credCardInstPaytOptForm.get(key)?.errors;
                    if (controlErrors != null) {
                        Object.keys(controlErrors).forEach(keyError => {
                            if (keyError == 'hasDupePaynamicsCd' && controlErrors[keyError] === true) {
                                this.errorMessage.credCardInstPaytOpt = "Paynamics Code already exists. Please choose a different Paynamics Code.";
                            } else if (keyError == 'required' && controlErrors[keyError] === true) {
                                this.errorMessage.credCardInstPaytOpt = "There are missing information. Please provide necessary information needed.";
                            } else if (keyError == 'hasDupeSeqNo' && controlErrors[keyError] === true) {
                                this.errorMessage.credCardInstPaytOpt = "Sequence No. already exists. Please choose a different Sequence No.";
                            } else if (keyError == 'hasDupeInstDesc' && controlErrors[keyError] === true) {
                                this.errorMessage.credCardInstPaytOpt = "Credit Card Installment Description already exists. Please choose a different Credit Card Installment Description.";
                            }
                        });
                    }
                });
                this._formService.showFormMsg("credCardInstPaytOpt-error-message", this.errorMessage.credCardInstPaytOpt, "E");
            } catch (e) {
    console.error(e);
            }
        }
    }
}
