import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, ValidatorFn, AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { CustomValidators } from 'src/app/utils/custom-validator';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';

@Component({
  selector: 'mv-type-maintenance',
  templateUrl: './mv-type-maintenance.component.html',
  styleUrls: ['./mv-type-maintenance.component.css']
})
export class MvTypeMaintenanceComponent implements OnInit {


  addUpdateFlag: boolean = true;
  datePipe = new DatePipe('en-us');
  selectedRow: any;
  tempNewData: any = [];
  tempUpdateData: any = [];
  userId: string = this.userDetailService.userId;
  errorMessage: string = '';
  saveDisabler: boolean = true;
  deleteDisabler: boolean = true;
  formHider: boolean = false;
  moduleId: string = 'BMM090';
  moduleName = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId === 'BMM090' })[0].moduleDesc.toUpperCase();
  
  mvTypeTable: any = {
    cols: [
      {
        key: "MV_TYPE",
        header: "MV Type Code",
        dataType: "string"
      },
      {
        key: "MV_TYPE_DESC",
        header: "MV Type Description",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  mvTypeForm = this.fb.group({
    MV_TYPE: ['', [Validators.required, this.duplicateMvType(), CustomValidators.requiredTrim]],
    ACTIVE_TAG: ['A', [Validators.required]],
    MV_TYPE_DESC: ['', [Validators.required, CustomValidators.requiredTrim]],
    REMARKS: [''],
    LAST_USER: [{value: '', disabled: true}],
    LAST_USER_UPDATE: [{value: '', disabled: true}],
  });

  constructor(
    private fb: FormBuilder,
    private api: APICallService,
    private dialog: MatDialog,
    private jsonDataService: JsonDataService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    private formService: FormService,
    private securityService: SecurityService
  ) { }

  ngOnInit(): void {
    try {
      this.getMvType();
    } catch(e) {
      
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempNewData!='' || this.tempUpdateData!='') {
      return false;
    } else {
      return true;
    }
  }

  duplicateMvType(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      let mvTypeDuplicate = this.mvTypeTable.tblData.filter(function(e: any) {
        return e.MV_TYPE.trim()==control.value.trim();
      });
      if(mvTypeDuplicate!='') {
        return { "mvTypeDuplicate": { value: control.value } }
      }
      return null;
    }
  }

  getMvType(): void {
    try {
      this.mvTypeTable.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.api.getMvType({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE"
      }).subscribe((data: any) => {
        this.securityService.checkRequestKeyResponse(data, () => {
          this.securityService.hasValidCsrfToken(data, () => {
            if (data.status === "SUCCESS") {
              data = JSON.parse(this.jsonDataService.decrypt(data.response));
              this.mvTypeTable.loading = false;
              this.jsonDataService.contorlLoading(false);
              this.mvTypeTable.tblData = data;
            } else {
                this.jsonDataService.contorlLoading(false);
                this.mvTypeTable.loading = false;
                this.appMessageService.showAppMessage(data.message, "error");
            }
          });
        });
      });
    } catch(e) {
      
    }
  }

  onRowClick(ev: any): void {
    try {
      if(ev!=null) {
        this.selectedRow = ev;
        this.addUpdateFlag = false;
        this.formLoader();
      } else {
        this.selectedRow = '';
        this.addUpdateFlag = true;
        this.formClearer();
      }
      this.deleteEnabler();
    } catch(e) {
      
    }
  }

  formLoader() {
    try {
      this.mvTypeForm.get("MV_TYPE")?.disable();
      this.mvTypeForm.patchValue({
        MV_TYPE:          this.selectedRow.MV_TYPE,
        ACTIVE_TAG:       this.selectedRow.ACTIVE_TAG,
        MV_TYPE_DESC:     this.selectedRow.MV_TYPE_DESC,
        REMARKS:          this.selectedRow.REMARKS,
        LAST_USER:        this.selectedRow.LAST_USER,
        LAST_USER_UPDATE: this.selectedRow.LAST_USER_UPDATE,
      });
    } catch(e) {
      
    }
  }

  formClearer() {
    try {
      this.mvTypeForm.get("MV_TYPE")?.enable();
      this.mvTypeForm.patchValue({
        MV_TYPE:          "",
        ACTIVE_TAG:       "A",
        MV_TYPE_DESC:     "",
        REMARKS:          "",
        LAST_USER:        "",
        LAST_USER_UPDATE: "",
      });
    } catch(e) {
      
    }
  }

  deleteEnabler() {
    try {
      let tempDeleteFlag = this.tempNewData.filter((e: any) => {
        return e.MV_TYPE == this.selectedRow.MV_TYPE
      });
      if(tempDeleteFlag=='' || tempDeleteFlag==null) {
        this.deleteDisabler = true;
      } else {
        this.deleteDisabler = false;
      }
    } catch(e) {
      
    }
  }

  addRow(): void {
    try {
      if(this.mvTypeForm.valid) {
        this.formService.hideFormMsg("mv-type-error-message");
        this.tableInserter();
        this.tempAddInserter();
        this.formClearer();
        this.saveDisabler = false;
      } else {
        this.errorMessageSetter();
      }
    } catch(e) {
      
    }
  }

  tableInserter(): void {
    try {
      this.mvTypeTable.tblData.unshift(this.formValues());
      this.mvTypeTable.tblData = [...this.mvTypeTable.tblData];
    } catch(e) {
      
    }
  }

  tempAddInserter(): void {
    try {
      this.tempNewData.push(this.formValues());
    } catch(e) {
      
    }
  }

  formValues(): any {
    try {
      return {
        MV_TYPE:          this.mvTypeForm.get("MV_TYPE")?.value,
        ACTIVE_TAG:       this.mvTypeForm.get("ACTIVE_TAG")?.value,
        MV_TYPE_DESC:     this.mvTypeForm.get("MV_TYPE_DESC")?.value,
        REMARKS:          this.mvTypeForm.get("REMARKS")?.value,
        LAST_USER:        this.userId,
        LAST_USER_UPDATE: this.mvTypeForm.get("LAST_USER_UPDATE")?.value,
      }
    } catch(e) {
      
    }
  }

  errorMessageSetter() {
    try {
      if(this.mvTypeForm.invalid) {
        for(const field in this.mvTypeForm.controls) {
          const controlName = this.mvTypeForm.get(field);
          if(controlName?.errors?.required) {
            this.errorMessage = 'There are missing information. Please provide necessary information needed.';
          }
          if(controlName?.errors?.mvTypeDuplicate) {
            this.errorMessage = 'MV Type Code already exists. Please add a unique MV Type Code.';
          }
          this.formService.showFormMsg("mv-type-error-message", this.errorMessage, "E");
        }
      }
    } catch(e) {
      
    }
  }

  onUpdate(): void {
    try {
      if(this.mvTypeForm.valid) {
        this.updateTable();
        if(this.deleteDisabler) {
          this.updateDbData();
        } else {
          this.updateNewData();
        }
        this.selectedRow = '';
        this.addUpdateFlag = true;
        this.formClearer();
        this.saveDisabler = false;
        this.deleteEnabler();
        this.formService.hideFormMsg('mv-type-error-message');
      } else {
        this.errorMessageSetter();
      }
    } catch(e) {
      
    }
  }

  updateTable() {
    try {
      let updateIndex = this.mvTypeTable.tblData.indexOf(this.selectedRow);
      this.mvTypeTable.tblData[updateIndex] = this.formValues();
      this.mvTypeTable.tblData = [...this.mvTypeTable.tblData];
    } catch(e) {
      
    }
  }

  updateDbData() {
    try {
      let tempDbData = this.tempUpdateData.find(
        (mvType: { MV_TYPE: any }) => mvType.MV_TYPE == this.selectedRow.MV_TYPE
      );
      if((this.tempUpdateData == '' || this.tempUpdateData == null) || (tempDbData=='' || tempDbData==null)) {
        this.tempUpdateData.push(this.formValues());
      } else {
        tempDbData.MV_TYPE          = this.mvTypeForm.get("MV_TYPE")?.value;
        tempDbData.ACTIVE_TAG       = this.mvTypeForm.get("ACTIVE_TAG")?.value;
        tempDbData.MV_TYPE_DESC     = this.mvTypeForm.get("MV_TYPE_DESC")?.value;
        tempDbData.REMARKS          = this.mvTypeForm.get("REMARKS")?.value;
        tempDbData.LAST_USER        = this.userId;
        tempDbData.LAST_USER_UPDATE = this.mvTypeForm.get("LAST_USER_UPDATE")?.value;
      }
    } catch(e) {
      
    }
  }

  updateNewData() {
    try {
      let tempNewConfigdata = this.tempNewData.find(
        (mvType: { MV_TYPE: any }) => mvType.MV_TYPE == this.selectedRow.MV_TYPE
      );
      tempNewConfigdata.MV_TYPE          = this.mvTypeForm.get("MV_TYPE")?.value;
      tempNewConfigdata.ACTIVE_TAG       = this.mvTypeForm.get("ACTIVE_TAG")?.value;
      tempNewConfigdata.MV_TYPE_DESC     = this.mvTypeForm.get("MV_TYPE_DESC")?.value;
      tempNewConfigdata.REMARKS          = this.mvTypeForm.get("REMARKS")?.value;
      tempNewConfigdata.LAST_USER        = this.userId;
      tempNewConfigdata.LAST_USER_UPDATE = this.mvTypeForm.get("LAST_USER_UPDATE")?.value;
    } catch(e) {
      
    }
  }

  deleteRow(): void {
    try {
      let clickedData = this.selectedRow;
      this.mvTypeTable.tblData = this.mvTypeTable.tblData.filter(function(e: any) {
        return e.MV_TYPE != clickedData.MV_TYPE;
      });
      this.tempNewData = this.tempNewData.filter(function(e: any) {
        return e.MV_TYPE != clickedData.MV_TYPE;
      });
      this.selectedRow = '';
      this.formClearer();
      this.deleteDisabler = true;
      this.addUpdateFlag = true;
      if((this.tempNewData=='' || this.tempNewData==null) && (this.tempUpdateData=='' || this.tempUpdateData==null)) {
        this.saveDisabler = true;
      }
    } catch(e) {
      
    }
  }

  onSave(): void {
    try {
      this.formHider = true;
      this.formService.showFormLoader(null, "mv-type-form", "Saving.<br>Please wait ...", null, null);
      this.jsonDataService.contorlLoading(true);
      this.api.saveMvType(this.tempNewData).subscribe({
        next: (data: any) => {
          this.securityService.checkRequestKeyResponse(data, () => {
            this.securityService.hasValidCsrfToken(data, () => {
              if(data.status == 'SUCCESS') {
                this.api.saveMvType(this.tempUpdateData).subscribe({
                  next: (res: any) => {
                    this.securityService.checkRequestKeyResponse(res, () => {
                      this.securityService.hasValidCsrfToken(res, () => {
                        if(res.status == 'SUCCESS') {
                          this.onComplete();
                        } else {
                          this.onFail();
                        }
                      });
                    });
                  },
                  error: () => {
                    this.onFail();
                  }
                });
              } else {
                this.onFail();
              }
            });
          });
        },
        error: () => {
          this.onFail();
        }
      });
    } catch(e) {
      
    }
  }

  onComplete() {
    try {
      this.formService.hideFormLoader("mv-type-form");
      this.formHider = false;
      this.tempNewData = [];
      this.tempUpdateData = [];
      this.addUpdateFlag = true;
      this.saveDisabler = true;
      this.formClearer();
      this.deleteEnabler();
      this.getMvType();
      this.jsonDataService.contorlLoading(false);
      this.formService.hideFormMsg("mv-type-error-message");
      this.openSaveDialog();
    } catch(e) {
      
    }
  }

  onFail() {
    try {
      this.formService.hideFormLoader("mv-type-form");
      this.formHider = false;
      this.tempNewData = [];
      this.tempUpdateData = [];
      this.addUpdateFlag = true;
      this.formClearer();
      this.deleteEnabler();
      this.getMvType();
      this.jsonDataService.contorlLoading(false);
      this.formService.hideFormMsg("mv-type-error-message");
      this.openErrorDialog("Saving MV Type Failed");
    } catch(e) {
      
    }
  }

  openErrorDialog(message: string) {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'ERROR',
          content: message,
        }
      });
    } catch(e) {
      
    }
  }

  openSaveDialog() {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'SUCCESS',
          content: 'MV Type Details Successfully Saved!',
        }
      });
    } catch(e) {
      
    }
  }
}
