<section id='curr-maint'>
    <div class="p-datatable mtn-container">
        <div class="mtn-title">
            <p>{{moduleTitle}}</p>
        </div>
        <!-- Red Title Border -->
        <div class="p-datatable-header"><span class="header-title">CURRENCY</span></div>
        <!-- Container for Currency Table using prime ng -->
        <div class = 'table-curr'>
            <osp-table [tblConfig]="tblConfigCurr" (onRowClick)="onRowClickCurr($event)"></osp-table>
        </div>
        <!-- Loading Animation -->
        <div id="generate-curr-form">
            <div class="form-loader"></div>
        </div>
        <!-- Container for Currency Fields -->
        <div class="form-content">
            
            <form [formGroup]="currForm" class="form" *ngIf="noLoadingInProcessCurr">
                <div id="curr-error-message"></div>
                <div class="form-container-grid">
                    <div class="field required">
                        <label>Currency Code</label>
                    </div>
                    <div class="">
                        <input formControlName="currencyCd" type="text" maxlength="3" (input)="autoUppercase($event)">
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag">
                            <option value="A" selected>Yes</option>
                            <option value="I">No</option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>Currency Word</label>
                    </div>
                    <div class="">
                        <input formControlName="currencyWord" type="text" maxlength="100">
                    </div>
                    <div class="field required">
                        <label>Currency Description</label>
                    </div>
                    <div class="">
                        <input formControlName="currencyDesc" type="text" maxlength="100">
                    </div>

                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" type="text" maxlength="4000">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUpdateName">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUserUpdate">
                    </div>

                </div>
                <div class='btn-row'>
                    <button type="button" id='btn-add' *ngIf="!isCurrSelected" title='Register a currency' class="btn btn2" value='ADD' (click)='addCurrency()'>ADD</button>
                    <button type="button" id='btn-update' *ngIf="isCurrSelected" title='Update a currency' class="btn btn2" value='UPDATE' (click)='updateCurrency()'>UPDATE</button>
                    <button type="button" id='btn-delete' title='Remove a currency' class="btn btn2" value='DELETE' (click)='deleteCurrency()' [disabled]='deleteDisabledCurr'>DELETE</button>
                </div> 

                <div class="mtn-title"></div>

                <div class='btn-row'>
                    <button type="button" id='btn-save' title='Save changes on currency details' class="btn btn2" value='SAVE' (click)='toSaveCurr()' [disabled]='saveDisabledCurr'>SAVE</button>
                </div>
            </form>
        </div>  

        <!-- Red Title Border -->
        <div class="p-datatable-header"><span class="header-title">CURRENCY RATE HISTORY</span></div>
        <!-- Container for Currency Table using prime ng -->
        <div class = 'table-curr'>
            <osp-table [tblConfig]="tblConfigCurrRt" (onRowClick)="onRowClickCurrRt($event)"></osp-table>
        </div>
        <!-- Loading Animation -->
        <div id="generate-curr-rt-form">
            <div class="form-loader"></div>
        </div>
        <!-- Container for Tax Fields -->
        <div class="form-content">
            
            <form [formGroup]="currRtForm" class="form" *ngIf="noLoadingInProcessCurrRt">
                <div id="curr-rt-error-message"></div>
                <div class="form-container-grid">
                    <div class="field">
                        <label>History No.</label>
                    </div>
                    <div class="">
                        <input formControlName="histNoDisp" type="text" readonly>
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag">
                            <option value="A" selected>Yes</option>
                            <option value="I">No</option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>Currency Rate</label>
                    </div>
                    <div class="">
                        <input formControlName="currencyRt" (focus)="formService.setFieldToNumberWithDecimal(currRtForm, 'currencyRt', $event)"
                        (blur)="formService.setFieldToNumberWithDecimal(currRtForm, 'currencyRt', $event)" (keypress)="allowNumericDigitsOnly($event)" (focusout)="rateFormat()">
                    </div>
                    <div class="field required">
                        <label>Effective Date From</label>
                    </div>
                    <div class="">
                        <input formControlName="effDateFrom" type="text" placeholder="MM/DD/YYYY" [matDatepicker]="effDateFromPicker" [min]="minEffDate" (keypress)="allowNumericAndSlashOnly($event)">
                        <mat-datepicker-toggle matSuffix [for]="effDateFromPicker"></mat-datepicker-toggle>
                        <mat-datepicker #effDateFromPicker></mat-datepicker>
                    </div>

                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" type="text" maxlength="4000">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUpdateName">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUserUpdate">
                    </div>

                </div>
                <div class='btn-row'>
                    <button type="button" id='btn-add' *ngIf="!isCurrRtSelected" title='Register a new rate' class="btn btn2" value='ADD' (click)='addCurrencyRt()'>ADD</button>
                    <button type="button" id='btn-update' *ngIf="isCurrRtSelected" title='Update a rate' class="btn btn2" value='UPDATE' (click)='updateCurrencyRt()'>UPDATE</button>
                    <button type="button" id='btn-delete' title='Remove a rate' class="btn btn2" value='DELETE' (click)='deleteCurrencyRt()' [disabled]='deleteDisabledCurrRt'>DELETE</button>
                </div> 

                <div class="mtn-title"></div>

                <div class='btn-row'>
                    <button type="button" id='btn-save' title='Save changes on currency rate details' class="btn btn2" value='SAVE' (click)='toSaveCurrRt()' [disabled]='saveDisabledCurrRt'>SAVE</button>
                </div>
            </form>
        </div> 
    </div>
</section>