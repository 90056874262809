<section id="property-assessment-wordings-maint">    
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <!-- Container for Property Assessment Wordings Table using prime ng -->
        <div class = 'table-user'>
            <osp-table [tblConfig]="propertyAssessmentWordingsTblConfig" (onRowClick)="onRowClick($event)"></osp-table>
        </div>
        <!-- Loading Animation -->
        <div id="property-assessment-wordings-maintenance-loading">
            <div class="form-loader"></div>
        </div>
        <div class="form-content" *ngIf="showForm">
            <form [formGroup]="forms.propertyAssessmentWordingsForm" class="form">
                <div id="property-assessment-wordings-error-message"></div>
                <div class="form-container-grid">
                    <div class="field required">
                        <label>Code</label>
                    </div>
                    <div class="">
                        <input formControlName="assessmentCd" type="text" maxlength="10" (input)="autoUppercase($event)" (keypress)="allowAlphanumericOnly($event)">
                    </div>
                    <div>
                    </div>
                    <div>
                    </div>

                    <div class="field required">
                        <label>Language</label>
                    </div>
                    <div class="">
                        <select formControlName="language">
                            <option *ngFor="let language of selections.languages" [value]="language.cd">
                                {{language.cd}} - {{language.name}}
                            </option>
                        </select>
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag">
                            <option *ngFor="let activeType of selections.activeTypes" [value]="activeType.cd">
                                {{activeType.name}}
                            </option>
                        </select>
                    </div>

                    <div class="field">
                        <label>Title</label>
                    </div>
                    <div class="span">
                        <input formControlName="title" type="text" maxlength="500">
                    </div>

                    <div class="field required">
                        <label>Wordings</label>
                    </div>
                    <div class="span">
                        <input formControlName="wordings" type="text">
                    </div>

                    <div class="field">
                        <label>Answer if Yes</label>
                    </div>
                    <div class="span">
                        <input formControlName="answerYes" type="text" maxlength="250" title='Complete statement to be displayed for a Yes answer.'>
                    </div>

                    <div class="field">
                        <label>Answer if No</label>
                    </div>
                    <div class="span">
                        <input formControlName="answerNo" type="text" maxlength="250" title='Complete statement to be displayed for a No answer.'>
                    </div>

                    <div class="field">
                        <label>Image Path</label>
                    </div>
                    <div class="span">
                        <input formControlName="imagePath" type="text" maxlength="500">
                    </div>
                    
                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" type="text" maxlength="4000">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="userID">
                    </div>
                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUpdate">
                    </div>
                </div>
            </form>
            <div class="btn-row">
                <button *ngIf="btns.propertyAssessmentWordings.updateDisabled" class="btn btn2" type="button" (click)="onAdd()">ADD</button>
                <button *ngIf="!btns.propertyAssessmentWordings.updateDisabled" class="btn btn2" type="button" (click)="onUpdate()">UPDATE</button>
                <button class="btn btn2" type="button" [disabled]='btns.propertyAssessmentWordings.deleteDisabled' (click)="onDelete()"> DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-row">
                <button class="btn btn2" type="button" [disabled]='btns.propertyAssessmentWordings.saveDisabled' (click)="onSave()">SAVE</button>
            </div>
        </div>
    </div>
</section>