import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/home/login/login.component';
import { ForgotPasswordComponent } from './components/home/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/home/reset-password/reset-password.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DashboardHomeComponent } from './components/dashboard/dashboard-home/dashboard-home.component';
import { GenerateRpaComponent } from './components/dashboard/generate-rpa/generate-rpa.component';
import { GenerateItReportsComponent } from './components/dashboard/generate-it-reports/generate-it-reports.component';
import { GenerateReportsComponent } from './components/dashboard/generate-reports/generate-reports.component';
import { PolicyRenewalComponent } from './components/dashboard/policy-renewal/policy-renewal.component';
import { SystemParameterMaintenanceComponent } from './components/dashboard/system-parameter-maintenance/system-parameter-maintenance.component';
import { AgentMaintenanceComponent } from './components/dashboard/agent-maintenance/agent-maintenance.component';
import { MaritalStatusMaintenanceComponent } from './components/dashboard/marital-status-maintenance/marital-status-maintenance.component';
import { JobCategoryMaintenanceComponent } from './components/dashboard/job-category-maintenance/job-category-maintenance.component';
import { CoverageTypeMaintenanceComponent } from './components/dashboard/coverage-type-maintenance/coverage-type-maintenance.component';
import { IssueSourceMaintenanceComponent } from './components/dashboard/issue-source-maintenance/issue-source-maintenance.component';
import { TaxChargesMaintenanceComponent } from './components/dashboard/tax-charges-maintenance/tax-charges-maintenance.component';
import { VehicleMaintenanceComponent } from './components/dashboard/vehicle-maintenance/vehicle-maintenance.component';
import { FmvMaintenanceComponent } from './components/dashboard/fmv-maintenance/fmv-maintenance.component';
import { LineMaintenanceComponent } from './components/dashboard/line-maintenance/line-maintenance.component';
import { EmployeeMaintenanceComponent } from './components/dashboard/employee-maintenance/employee-maintenance.component';
import { BfbMaintenanceComponent } from './components/dashboard/bfb-maintenance/bfb-maintenance.component';
import { DirtycheckGuard } from './components/common/dirtycheck.guard';
import { CheckGuard } from './components/common/guard/check.guard';
import { SystemUsersMaintentanceComponent } from './components/dashboard/system-users-maintentance/system-users-maintentance.component';
import { AgencyMaintenanceComponent } from './components/dashboard/agency-maintenance/agency-maintenance.component';
import { SublineMaintenanceComponent } from './components/dashboard/subline-maintenance/subline-maintenance.component';
import { PlanMaintenanceComponent } from './components/dashboard/plan-maintenance/plan-maintenance.component';
import { TaxMaintenanceComponent } from './components/dashboard/tax-maintenance/tax-maintenance.component';
import { AssureIdTypeMaintenanceComponent } from './components/dashboard/assure-id-type-maintenance/assure-id-type-maintenance.component';
import { CoverageMaintenanceComponent } from './components/dashboard/coverage-maintenance/coverage-maintenance.component';
import { BenefitsCoverageMaintenanceComponent } from './components/dashboard/benefits-coverage-maintenance/benefits-coverage-maintenance.component';
import { BenefitsCoveragePerPlanPerilMaintenanceComponent } from './components/dashboard/benefits-coverage-per-plan-peril-maintenance/benefits-coverage-per-plan-peril-maintenance.component';
import { StandardAmountCoveredMaintenanceComponent } from './components/dashboard/standard-amount-covered-maintenance/standard-amount-covered-maintenance.component';
import { AgentGroupMaintenanceComponent } from './components/dashboard/agent-group-maintenance/agent-group-maintenance.component';
import { MortgageeMaintenanceComponent } from './components/dashboard/mortgagee-maintenance/mortgagee-maintenance.component';
import { ProjectMaintenanceComponent } from './components/dashboard/project-maintenance/project-maintenance.component';
import { IntroductionMaintenanceComponent } from './components/dashboard/introduction-maintenance/introduction-maintenance.component';
import { ReportMaintenanceComponent } from './components/dashboard/report-maintenance/report-maintenance.component';
import { ReportSignatoryMaintenanceComponent } from './components/dashboard/report-signatory-maintenance/report-signatory-maintenance.component';
import { ReportParameterMaintenanceComponent } from './components/dashboard/report-parameter-maintenance/report-parameter-maintenance.component';
import { WarrantiesClausesMaintenanceComponent } from './components/dashboard/warranties-clauses-maintenance/warranties-clauses-maintenance.component';
import { WarrClausePerPlanMaintenanceComponent } from './components/dashboard/warr-clause-per-plan-maintenance/warr-clause-per-plan-maintenance.component';
import { OccupationMaintenanceComponent } from './components/dashboard/occupation-maintenance/occupation-maintenance.component';
import { OccupationClassMaintenanceComponent } from './components/dashboard/occupation-class-maintenance/occupation-class-maintenance.component';
import { ContractTypeMaintenanceComponent } from './components/dashboard/contract-type-maintenance/contract-type-maintenance.component';
import { FaqMainMaintenanceComponent } from './components/dashboard/faq-main-maintenance/faq-main-maintenance.component';
import { FaqSublineMaintenanceComponent } from './components/dashboard/faq-subline-maintenance/faq-subline-maintenance.component';
import { SrcExtMaintenanceComponent } from './components/dashboard/src-ext-maintenance/src-ext-maintenance.component';
import { ReferrorMaintenanceComponent } from './components/dashboard/referror-maintenance/referror-maintenance.component';
import { ClientGroupMaintenanceComponent } from './components/dashboard/client-group-maintenance/client-group-maintenance.component';
import { SrcCdMaintenanceComponent } from './components/dashboard/src-cd-maintenance/src-cd-maintenance.component';
import { BmaMaintenanceComponent } from './components/dashboard/bma-maintenance/bma-maintenance.component';
import { ReasonCancellationMaintenanceComponent } from './components/dashboard/reason-cancellation-maintenance/reason-cancellation-maintenance.component';
import { CurrencyMaintenanceComponent } from './components/dashboard/currency-maintenance/currency-maintenance.component';
import { PublicUserStandardRatesMaintenanceComponent } from './components/dashboard/public-user-standard-rates-maintenance/public-user-standard-rates-maintenance.component';
import { PublicUserStandardRatesPerVehicleMaintenanceComponent } from './components/dashboard/pub-user-std-rts-per-vehicle-maintenance/pub-user-std-rts-per-vehicle-maintenance.component';
import { PaymentOptionsMaintenanceComponent } from './components/dashboard/payment-options-maintenance/payment-options-maintenance';
import { PaymentOptionsAccessMaintenanceComponent } from './components/dashboard/payment-options-access-maintenance/payment-options-access-maintenance';
import { TariffRiskCodeMaintenanceComponent } from './components/dashboard/tariff-risk-code-maintenance/tariff-risk-code-maintenance.component';
import { BfbStandardRatesMaintenanceComponent } from './components/dashboard/bfb-standard-rates-maintenance/bfb-standard-rates-maintenance.component';
import { BfbSrcExtMaintenanceComponent } from './components/dashboard/bfb-src-ext-maintenance/bfb-src-ext-maintenance.component';
import { MvPremTypeMaintenanceComponent } from './components/dashboard/mv-prem-type-maintenance/mv-prem-type-maintenance.component';
import { BpiMsStandardRatesMaintenanceComponent } from './components/dashboard/bpi-ms-standard-rates-maintenance/bpi-ms-standard-rates-maintenance.component';
import { RpaClauseMappingMaintenanceComponent } from './components/dashboard/rpa-clause-mapping-maintenance/rpa-clause-mapping-maintenance.component';
import { RpaGenPageMappingMaintenanceComponent } from './components/dashboard/rpa-gen-page-mapping-maintenance/rpa-gen-page-mapping-maintenance.component';
import { RpaPerilMappingMaintenanceComponent } from './components/dashboard/rpa-peril-mapping-maintenance/rpa-peril-mapping-maintenance.component';
import { MvTypeMaintenanceComponent } from './components/dashboard/mv-type-maintenance/mv-type-maintenance.component';
import { BfbStandardRatesPerVehicleMaintenanceComponent } from './components/dashboard/bfb-standard-rates-per-vehicle-maintenance/bfb-standard-rates-per-vehicle-maintenance.component';
import { BpiMsStandardRatesPerVehicleMaintenanceComponent } from './components/dashboard/bpi-ms-standard-rates-maintenance-per-vehicle/bpi-ms-standard-rates-per-vehicle-maintenance.component';
import { VoucherCodeMaintenanceComponent } from './components/dashboard/voucher-code-maintenance/voucher-code-maintenance.component';
import { SourceOfFundsMaintenanceComponent } from './components/dashboard/source-of-fund-maintenance/source-of-funds-maintenance';
import { DealershipStandardRatesMaintenanceComponent } from './components/dashboard/dealership-standard-rates-maintenance/dealership-standard-rates-maintenance.component';
import { CitizenshipNationalityMaintenanceComponent } from './components/dashboard/citizenship-nationality-maintenance/citizenship-nationality-maintenance';
import { RelationshipMaintenanceComponent } from './components/dashboard/relationship-maintenance/relationship-maintenance.component';
import { MotorHighRiskMaintenanceComponent } from './components/dashboard/motor-high-risk-maintenance/motor-high-risk-maintenance';
import { AgencyStandardRatesMaintenanceComponent } from './components/dashboard/agency-standard-rates-maintenance/agency-standard-rates-maintenance.component';
import { AgencyStandardRatesPerVehicleMaintenanceComponent } from './components/dashboard/agency-standard-rates-per-vehicle-maintenance/agency-standard-rates-per-vehicle-maintenance.component';
import { ReferenceCodesMaintenanceComponent } from './components/dashboard/reference-codes-maintenance/reference-codes-maintenance';
import { PurposeMaintenanceComponent } from './components/dashboard/purpose-maintenance/purpose-maintenance';
import { EmailContentMaintenanceComponent } from './components/dashboard/email-content-maintenance/email-content-maintenance.component';
import { SegmentCodeMaintenanceComponent } from './components/dashboard/segment-code-maintenance/segment-code-maintenance.component';
import { CustomerUserAccountMaintenanceComponent } from './components/dashboard/customer-user-account-maintenance/customer-user-account-maintenance.component';
import { CustomerMaintenanceComponent } from './components/dashboard/customer-maintenance/customer-maintenance.component';
import { RiskProfileMaintenanceComponent } from './components/dashboard/risk-profile-maintenance/risk-profile-maintenance.component';
import { DeductibleMaintenanceComponent } from './components/dashboard/deductible-maintenance/deductible-maintenance.component';
import { PlanAccessMaintenanceComponent } from './components/dashboard/plan-access-maintenance/plan-access-maintenance';
import { DeductibleMaintenancePerClientGroupComponent } from './components/dashboard/deductible-maintenance-per-client-group/deductible-maintenance-per-client-group.component';
import { DeductibleMaintenancePerClientGroupPerIssueSourceComponent } from './components/dashboard/deductible-maintenance-per-client-group-per-issue-source/deductible-maintenance-per-client-group-per-issue-source.component';
import { ApplicableDeductiblePerPlanMaintenanceComponent } from './components/dashboard/applicable-deductible-per-plan-maintenance/applicable-deductible-per-plan-maintenance.component';
import { DeductibleMaintenancePerClientGroupCMFComponent } from './components/dashboard/deductible-maintenance-per-client-group-cmf/deductible-maintenance-per-client-group-cmf.component';
import { DeductibleMaintenancePerClientGroupPerIssueSourceCMFComponent } from './components/dashboard/deductible-maintenance-per-client-group-per-issue-source-cmf/deductible-maintenance-per-client-group-per-issue-source-cmf.component';
import { UpdateRenewalDetailsComponent } from './components/dashboard/update-renewal-details/update-renewal-details.component';
import { ProductCodeMaintenanceComponent } from './components/dashboard/product-code-maintenance/product-code-maintenance.component';
import { PolicyCancellationComponent } from './components/dashboard/policy-cancellation/policy-cancellation.component';
import { ClientGroupPerUserTypeMaintenanceComponent } from './components/dashboard/client-group-per-user-type-maintenance/client-group-per-user-type-maintenance.component';
import { ClientGroupStandardRatesMaintenanceComponent } from './components/dashboard/client-group-standard-rates-maintenance/client-group-standard-rates-maintenance.component';
import { ApplicableClientGroupPerUserIDComponent } from './components/dashboard/applicable-client-group-per-user-id/applicable-client-group-per-user-id.component';
import { StandardRatesPerPlanMaintenanceComponent } from './components/dashboard/standard-rates-per-plan-maintenance/standard-rates-per-plan-maintenance.component';
import { TowingAmtPerClientGrpVehicleMaintenanceComponent } from './components/dashboard/towing-amt-per-client-grp-vehicle-maintenance/towing-amt-per-client-grp-vehicle-maintenance.component';
import { TowingAmtPerClientGrpMaintenanceComponent } from './components/dashboard/towing-amt-per-client-grp-maintenance/towing-amt-per-client-grp-maintenance.component';
import { BlockedVehicleMaintenanceComponent } from './components/dashboard/blocked-vehicle-maintenance/blocked-vehicle-maintenance.component';
import { StandardRatesPerPlanBasedIssuingOfficeMaintenanceComponent } from './components/dashboard/standard-rates-per-plan-based-issuing-office-maintenance/standard-rates-per-plan-based-issuing-office-maintenance.component';
import { ClientGroupRatesForFixedPlanMaintenanceComponent } from './components/dashboard/client-group-rates-for-fixed-plan-maintenance/client-group-rates-for-fixed-plan-maintenance.component';
import { RiskRatingQuestionsMaintenanceComponent } from './components/dashboard/risk-rating-questions-maintenance/risk-rating-questions-maintenance.component';
import { ClientGroupRatesForFixedPlanBasedIOMaintenanceComponent } from './components/dashboard/client-group-rates-for-fixed-plan-based-io-maintenance/client-group-rates-for-fixed-plan-based-io-maintenance.component';
import { FireAccumulationRegisterComponent } from './components/dashboard/fire-accumulation-register/fire-accumulation-register.component';
import { UpdatePolicyAccumulationRegisterComponent } from './components/dashboard/update-policy-accumulation-register/update-policy-accumulation-register.component';
import { ClientGroupRatesForFlexiblePlanPerIssueSoureMaintenanceComponent } from './components/dashboard/client-group-rates-for-flexible-plan-per-iss-maintenance/client-group-rates-for-flexible-plan-per-iss-maintenance.component';
import { VehicleFilterMaintenanceComponent } from './components/dashboard/vehicle-filter-maintenance/vehicle-filter-maintenance.component';
import { PAHCIPParameterValidationComponent } from './components/dashboard/pa-hcip-parameter-validation-maintenance/pa-hcip-parameter-validation-maintenance.component';
import { StandardAmountPerClientGroupPerCarMaintenanceComponent } from './components/dashboard/standard-amount-per-client-group-per-car-maintenance/standard-amount-per-client-group-per-car-maintenance.component';
import { QuickLinksMaintenanceComponent } from './components/dashboard/quick-links-maintenance/quick-links-maintenance';
import { OccupancyMaintenanceComponent } from './components/dashboard/occupancy-maintenance/occupancy-maintenance.component';
import { TyphoonFloodZoningMaintenanceComponent } from './components/dashboard/typhoon-flood-zoning-maintenance/typhoon-flood-zoning-maintenance.component';
import { EarthquakeZoneMaintenanceComponent } from './components/dashboard/earthquake-zone-maintenance/earthquake-zone-maintenance.component';
import { RegionMaintenanceComponent } from './components/dashboard/region-maintenance/region-maintenance.component';
import { PlanAmtLimitsMaintenanceComponent } from './components/dashboard/plan-amt-limits-maintenance/plan-amt-limits-maintenance.component';
import { PropertyAssessmentWordingsMaintenanceComponent } from './components/dashboard/property-assessment-wordings-maintenance/property-assessment-wordings-maintenance.component';
import { PropertySiLimitMaintenanceComponent } from './components/dashboard/property-si-limit-maintenance/property-si-limit-maintenance.component';
import { SrcCdPerDealershipMaintenanceComponent } from './components/dashboard/src-cd-per-dealership-maintenance/src-cd-per-dealership-maintenance.component';
import { SrcExtPerDealershipMaintenanceComponent } from './components/dashboard/src-ext-per-dealership-maintenance/src-ext-per-dealership-maintenance.component';
import { AccountTypeMaintenanceComponent } from './components/dashboard/account-type-maintenance/account-type-maintenance.component';
import { FireDistrictMaintenanceComponent } from './components/dashboard/fire-district-maintenance/fire-district-maintenance.component';
import { ConstructionTypeMaintenanceComponent } from './components/dashboard/construction-type-maintenance/construction-type-maintenance.component';
import { StateMaintenanceComponent } from './components/dashboard/state-maintenance/state-maintenance.component';
import { ClassMaintenanceComponent } from './components/dashboard/class-maintenance/class-maintenance.component';
import { HazardGradeMaintenanceComponent } from './components/dashboard/hazard-grade-maintenance/hazard-grade-maintenance.component';
import { ConstructionYearMaintenanceComponent } from './components/dashboard/construction-year-maintenance/construction-year-maintenance.component';
import { PlanNotificationMaintenanceComponent } from './components/dashboard/plan-notification-maintenance/plan-notification-maintenance.component';
import { EffectiveDateBasisMaintenanceComponent } from './components/dashboard/effective-date-basis-maintenance/effective-date-basis-maintenance.component';
import { PropertyDetailsMaintenanceComponent } from './components/dashboard/property-details-maintenance/property-details-maintenance.component';
import { PreApprovedSubdivisionMaintenannceComponent } from './components/dashboard/pre-approved-subdivision-maintenance/pre-approved-subdivision-maintenance.component';
import { DetermineHcaPlanMaintenanceComponent } from './components/dashboard/determine-hca-plan-maintenance/determine-hca-plan-maintenance.component';
import { FirePolWordingsMaintenanceComponent } from './components/dashboard/fire-pol-wordings-maintenance/fire-pol-wordings-maintenance.component';
import { GeographicZoningMaintenanceComponent } from './components/dashboard/geographic-zoning-maintenance/geographic-zoning-maintenance.component';
import { ClientGroupPerAgentGroupMaintenanceComponent } from './components/dashboard/client-group-per-agent-group-maintenance/client-group-per-agent-group-maintenance.component';
import { CatZoneFireRatesPuMaintenanceComponent } from './components/dashboard/cat-zone-fire-rates-pu-maintenance/cat-zone-fire-rates-pu-maintenance.component';
import { CatZoneFireRatesAgentMaintenanceComponent } from './components/dashboard/cat-zone-fire-rates-agent-maintenance/cat-zone-fire-rates-agent-maintenance.component';
import { CatZoneFireRatesBpiMaintenanceComponent } from './components/dashboard/cat-zone-fire-rates-bpi-maintenance/cat-zone-fire-rates-bpi-maintenance.component';
import { CatZoneFireRatesBpimsMaintenanceComponent } from './components/dashboard/cat-zone-fire-rates-bpims-maintenance/cat-zone-fire-rates-bpims-maintenance.component';
import { ApplicableItemCodePerPlanMaintenanceComponent } from './components/dashboard/applicable-item-code-per-plan-maintenance/applicable-item-code-per-plan-maintenance.component';
import { BusinessCodeMaintenanceComponent } from './components/dashboard/business-code-maintenance/business-code-maintenance.component';
import { FirePremRtForAgentMaintenanceComponent } from './components/dashboard/fire-prem-rt-for-agent-maintenance/fire-prem-rt-for-agent-maintenance.component';
import { FirePremRtForBpimsMaintenanceComponent } from './components/dashboard/fire-prem-rt-for-bpims-maintenance/fire-prem-rt-for-bpims-maintenance.component';
import { FirePremRtForPublicMaintenanceComponent } from './components/dashboard/fire-prem-rt-for-public-maintenance/fire-prem-rt-for-public-maintenance.component';
import { FirePremRtForBpiMaintenanceComponent } from './components/dashboard/fire-prem-rt-for-bpi-maintenance/fire-prem-rt-for-bpi-maintenance.component';
import { FirePremiumRatesPerClientGroupMaintenanceComponent } from './components/dashboard/fire-premium-rates-per-client-group-maintenance/fire-premium-rates-per-client-group-maintenance.component';
import { OccupancyBusinessCodePerPlanMaintenanceComponent } from './components/dashboard/occupancy-business-code-per-plan-maintenance/occupancy-business-code-per-plan-maintenance.component';
import { OpenPolicySequenceNoMaintenanceComponent } from './components/dashboard/open-policy-sequence-no-maintenance/open-policy-sequence-no-maintenance.component';
import { FireTyphoonZoneMaintenanceComponent } from './components/dashboard/fire-typhoon-zone-maintenance/fire-typhoon-zone-maintenance.component';
import { FireFloodZoneMaintenanceComponent } from './components/dashboard/fire-flood-zone-maintenance/fire-flood-zone-maintenance.component';
import { QuotationWordingsMaintenanceComponent } from './components/dashboard/quotation-wordings-maintenance/quotation-wordings-maintenance.component';
import { FireCatPerilsPremiumRatesPerClientGroupMaintenanceComponent } from './components/dashboard/fire-cat-perils-premium-rates-per-client-group-maintenance/fire-cat-perils-premium-rates-per-client-group-maintenance.component';
import { SrcExtForRelaCodeMtnComponent } from './components/dashboard/src-ext-for-rela-code-mtn/src-ext-for-rela-code-mtn.component';
import { FireItemCodeMaintenanceComponent } from './components/dashboard/fire-item-code-maintenance/fire-item-code-maintenance.component';
import { PolicySequenceNumberMtnComponent } from './components/dashboard/policy-sequence-number-mtn/policy-sequence-number-mtn.component';
import { UserLevelMtnComponent } from './components/dashboard/user-level-mtn/user-level-mtn.component';
import { CyberPremiumsMaintenanceComponent } from './components/dashboard/cyber-premiums-maintenance/cyber-premiums-maintenance.component';
import { PaymentFrequencyMaintenanceComponent } from './components/dashboard/payment-frequency-maintenance/payment-frequency-maintenance.component';
import { ClaimDocumentReview } from './components/dashboard/claim-document-review/claim-document-review.component';
import { ClaimRecordUpload } from './components/dashboard/claim-record-upload/claim-record-upload.component';

const routes: Routes = [
    { path: "", redirectTo: "home", pathMatch: "full" },
    {
        path: "home", component: HomeComponent,
        children: [
            { path: "", redirectTo: "login", pathMatch: "full" },
            { path: "login", component: LoginComponent },
            { path: "forgot", component: ForgotPasswordComponent },
            { path: "reset", component: ResetPasswordComponent }
        ]
    },
    {
        path: "dashboard", component: DashboardComponent,
        children: [
            { path: "", redirectTo: "home", pathMatch: "full" },
            { path: "home", component: DashboardHomeComponent },
            { path: "generate-rpa", component: GenerateRpaComponent },
            { path: "generate-it-reports", component: GenerateItReportsComponent },
            { path: "generate-reports", component: GenerateReportsComponent },
            { path: "system-parameter-maintenance", component: SystemParameterMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "agent-maintenance", component: AgentMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "marital-status-maintenance", component: MaritalStatusMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "job-category-maintenance", component: JobCategoryMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "coverage-type-maintenance", component: CoverageTypeMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "issue-source-maintenance", component: IssueSourceMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "tax-charges-maintenance", component: TaxChargesMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "vehicle-maintenance", component: VehicleMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "fmv-maintenance", component: FmvMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "line-maintenance", component: LineMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "bpims-maintenance", component: EmployeeMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "bfb-maintenance", component: BfbMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "agency-maintenance", component: AgencyMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "system-users-maintenance", component: SystemUsersMaintentanceComponent, canDeactivate: [CheckGuard] },
            { path: "subline-maintenance", component: SublineMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "plan-maintenance", component: PlanMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "coverage-maintenance", component: CoverageMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "benefits-coverage-maintenance", component: BenefitsCoverageMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "benefits-coverage-per-plan-peril-maintenance", component: BenefitsCoveragePerPlanPerilMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "tax-maintenance", component: TaxMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "assured-id-type-maintenance", component: AssureIdTypeMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "standard-amount-covered-maintenance", component: StandardAmountCoveredMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "agent-group-maintenance", component: AgentGroupMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "mortgagee-maintenance", component: MortgageeMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "project-maintenance", component: ProjectMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "introduction-maintenance", component: IntroductionMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "upload/process-renewal", component: PolicyRenewalComponent },
            { path: "report-maintenance", component: ReportMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "report-signatory-maintenance", component: ReportSignatoryMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "report-parameter-maintenance", component: ReportParameterMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "warranties-clauses-maintenance", component: WarrantiesClausesMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "warr-clause-per-plan-maintenance", component: WarrClausePerPlanMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "occupation-maintenance", component: OccupationMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "occupation-class-maintenance", component: OccupationClassMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "contract-type-maintenance", component: ContractTypeMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "faq-main-maintenance", component: FaqMainMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "faq-subline-maintenance", component: FaqSublineMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "referror-maintenance", component: ReferrorMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "client-group-maintenance", component: ClientGroupMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "source-code-maintenance", component: SrcCdMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "bma-maintenance", component: BmaMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "reason-cancellation-maintenance", component: ReasonCancellationMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "source-extension-maintenance", component: SrcExtMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "currency-maintenance", component: CurrencyMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "public-user-standard-rates-maintenance", component: PublicUserStandardRatesMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "payment-options-maintenance", component: PaymentOptionsMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "pub-user-std-rts-per-vehicle-maintenance", component: PublicUserStandardRatesPerVehicleMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "bfb-standard-rates-maintenance", component: BfbStandardRatesMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "bfb-src-ext-maintenance", component: BfbSrcExtMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "payment-options-access-maintenance", component: PaymentOptionsAccessMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "tariff-risk-code-maintenance", component: TariffRiskCodeMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "mv-prem-type-maintenance", component: MvPremTypeMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "bpi-ms-standard-rates-maintenance", component: BpiMsStandardRatesMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "rpa-peril-mapping-maintenance", component: RpaPerilMappingMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "rpa-gen-page-mapping-maintenance", component: RpaGenPageMappingMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "rpa-clause-mapping-maintenance", component: RpaClauseMappingMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "mv-type-maintenance", component: MvTypeMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "bfb-standard-rates-per-vehicle-maintenance", component: BfbStandardRatesPerVehicleMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "bpi-ms-standard-rates-per-vehicle-maintenance", component: BpiMsStandardRatesPerVehicleMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "voucher-code-maintenance", component: VoucherCodeMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "source-of-funds-maintenance", component: SourceOfFundsMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "dealership-standard-rates-maintenance", component: DealershipStandardRatesMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "citizenship-nationality-maintenance", component: CitizenshipNationalityMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "relationship-maintenance", component: RelationshipMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "motor-high-risk-maintenance", component: MotorHighRiskMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "agency-standard-rates-maintenance", component: AgencyStandardRatesMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "agency-standard-rates-per-vehicle-maintenance", component: AgencyStandardRatesPerVehicleMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "reference-codes-maintenance", component: ReferenceCodesMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "purpose-maintenance", component: PurposeMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "email-content-maintenance", component: EmailContentMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "segment-code-maintenance", component: SegmentCodeMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "customer-user-account-maintenance", component: CustomerUserAccountMaintenanceComponent },
            { path: "customer-maintenance", component: CustomerMaintenanceComponent },
            { path: "risk-profile-maintenance", component: RiskProfileMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "deductible-maintenance", component: DeductibleMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "plan-access-maintenance", component: PlanAccessMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "deductible-maintenance-per-client-group", component: DeductibleMaintenancePerClientGroupComponent, canDeactivate: [CheckGuard] },
            { path: "deductible-maintenance-per-client-group-per-issue-source", component: DeductibleMaintenancePerClientGroupPerIssueSourceComponent, canDeactivate: [CheckGuard] },
            { path: "applicable-deductible-per-plan-maintenance", component: ApplicableDeductiblePerPlanMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "deductible-maintenance-per-client-group-chev-mazda-ford", component: DeductibleMaintenancePerClientGroupCMFComponent, canDeactivate: [CheckGuard] },
            { path: "deductible-maintenance-per-client-group-per-issue-source-chev-mazda-ford", component: DeductibleMaintenancePerClientGroupPerIssueSourceCMFComponent, canDeactivate: [CheckGuard] },
            { path: "update-renewal-details", component: UpdateRenewalDetailsComponent },
            { path: "product-code-maintenance", component: ProductCodeMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "policy-cancellation", component: PolicyCancellationComponent, canDeactivate: [CheckGuard] },
            { path: "client-group-per-user-type-maintenance", component: ClientGroupPerUserTypeMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "client-group-standard-rates-maintenance", component: ClientGroupStandardRatesMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "applicable-client-group-per-user-id-maintenance", component: ApplicableClientGroupPerUserIDComponent, canDeactivate: [CheckGuard] },
            { path: "standard-rates-per-plan-maintenance", component: StandardRatesPerPlanMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "towing-amt-per-client-grp-vehicle-maintenance", component: TowingAmtPerClientGrpVehicleMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "towing-amt-per-client-grp-maintenance", component: TowingAmtPerClientGrpMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "blocked-vehicle-maintenance", component: BlockedVehicleMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "standard-rates-per-plan-based-issuing-office-maintenance", component: StandardRatesPerPlanBasedIssuingOfficeMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "client-group-rates-for-fixed-plan-maintenance", component: ClientGroupRatesForFixedPlanMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "risk-rating-questions-maintenance", component: RiskRatingQuestionsMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "client-group-rates-for-fixed-plan-based-io-maintenance", component: ClientGroupRatesForFixedPlanBasedIOMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "fire-accumulation-register", component: FireAccumulationRegisterComponent, canDeactivate: [CheckGuard] },
            { path: "update-policy-accumulation-register", component: UpdatePolicyAccumulationRegisterComponent, canDeactivate: [CheckGuard] },
            { path: "client-group-rates-for-flexible-plan-per-iss-maintenance", component: ClientGroupRatesForFlexiblePlanPerIssueSoureMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "quick-links-maintenance", component: QuickLinksMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "vehicle-filter-maintenance", component: VehicleFilterMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "pa-hcip-parameter-validation-maintenance", component: PAHCIPParameterValidationComponent, canDeactivate: [CheckGuard] },
            { path: "standard-amount-per-client-group-per-car-maintenance", component: StandardAmountPerClientGroupPerCarMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "occupancy-maintenance", component: OccupancyMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "typhoon-flood-zoning-maintenance", component: TyphoonFloodZoningMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "earthquake-zone-maintenance", component: EarthquakeZoneMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "region-maintenance", component: RegionMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "plan-amt-limits-maintenance", component: PlanAmtLimitsMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "property-assessment-wordings-maintenance", component: PropertyAssessmentWordingsMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "property-si-limit-maintenance", component: PropertySiLimitMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "src-cd-per-dealership-maintenance", component: SrcCdPerDealershipMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "src-ext-per-dealership-maintenance", component: SrcExtPerDealershipMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "account-type-maintenance", component: AccountTypeMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "fire-district-maintenance", component: FireDistrictMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "construction-type-maintenance", component: ConstructionTypeMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "state-maintenance", component: StateMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "class-maintenance", component: ClassMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "hazard-grade-maintenance", component: HazardGradeMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "construction-year-maintenance", component: ConstructionYearMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "plan-notification-maintenance", component: PlanNotificationMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "effective-date-basis-maintenance", component: EffectiveDateBasisMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "property-details-maintenance", component: PropertyDetailsMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "pre-approved-subdivision-maintenance", component: PreApprovedSubdivisionMaintenannceComponent, canDeactivate: [CheckGuard] },
            { path: "determine-hca-plan-maintenance", component: DetermineHcaPlanMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "fire-pol-wordings-maintenance", component: FirePolWordingsMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "geographic-zoning-maintenance", component: GeographicZoningMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "client-group-per-agent-group-maintenance", component: ClientGroupPerAgentGroupMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "cat-zone-fire-rates-pu-maintenance", component: CatZoneFireRatesPuMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "cat-zone-fire-rates-agent-maintenance", component: CatZoneFireRatesAgentMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "cat-zone-fire-rates-bpi-maintenance", component: CatZoneFireRatesBpiMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "cat-zone-fire-rates-bpims-maintenance", component: CatZoneFireRatesBpimsMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "applicable-item-code-per-plan-maintenance", component: ApplicableItemCodePerPlanMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "business-code-maintenance", component: BusinessCodeMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "fire-prem-rt-for-agent-maintenance", component: FirePremRtForAgentMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "fire-prem-rt-for-bpims-maintenance", component: FirePremRtForBpimsMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "fire-prem-rt-for-public-maintenance", component: FirePremRtForPublicMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "fire-prem-rt-for-bpi-maintenance", component: FirePremRtForBpiMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "fire-premium-rates-per-client-group-maintenance", component: FirePremiumRatesPerClientGroupMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "occupancy-business-code-per-plan-maintenance", component: OccupancyBusinessCodePerPlanMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "open-policy-sequence-no-maintenance", component: OpenPolicySequenceNoMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "fire-flood-zone-maintenance", component: FireFloodZoneMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "fire-typhoon-zone-maintenance", component: FireTyphoonZoneMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "quotation-wordings-maintenance", component: QuotationWordingsMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "fire-cat-perils-premium-rates-per-client-group-maintenance", component: FireCatPerilsPremiumRatesPerClientGroupMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "src-ext-for-rela-code-mtn", component: SrcExtForRelaCodeMtnComponent, canDeactivate: [CheckGuard] },
            { path: "fire-item-code-maintenance", component: FireItemCodeMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "policy-sequence-number-mtn", component: PolicySequenceNumberMtnComponent, canDeactivate: [CheckGuard] },
            { path: "user-level-mtn", component: UserLevelMtnComponent, canDeactivate: [CheckGuard] },
            { path: "cyber-premiums-maintenance", component: CyberPremiumsMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "payment-frequency-maintenance", component: PaymentFrequencyMaintenanceComponent, canDeactivate: [CheckGuard] },
            { path: "claim-document-review", component: ClaimDocumentReview},
            { path: "claim-record-upload", component: ClaimRecordUpload},
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
export const RoutingComponents = [
    HomeComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    DashboardComponent,
    DashboardHomeComponent,
    GenerateRpaComponent,
    GenerateItReportsComponent,
    GenerateReportsComponent,
    PolicyRenewalComponent,
    SystemParameterMaintenanceComponent,
    AgentMaintenanceComponent,
    MaritalStatusMaintenanceComponent,
    JobCategoryMaintenanceComponent,
    CoverageTypeMaintenanceComponent,
    IssueSourceMaintenanceComponent,
    TaxChargesMaintenanceComponent,
    VehicleMaintenanceComponent,
    FmvMaintenanceComponent,
    LineMaintenanceComponent,
    EmployeeMaintenanceComponent,
    BfbMaintenanceComponent,
    PlanMaintenanceComponent,
    TaxMaintenanceComponent,
    CoverageMaintenanceComponent,
    BenefitsCoverageMaintenanceComponent,
    BenefitsCoveragePerPlanPerilMaintenanceComponent,
    IntroductionMaintenanceComponent,
    ReportMaintenanceComponent,
    ReportSignatoryMaintenanceComponent,
    ReportParameterMaintenanceComponent,
    WarrantiesClausesMaintenanceComponent,
    WarrClausePerPlanMaintenanceComponent,
    FaqMainMaintenanceComponent,
    FaqSublineMaintenanceComponent,
    OccupationMaintenanceComponent,
    OccupationClassMaintenanceComponent,
    ContractTypeMaintenanceComponent,
    ReferrorMaintenanceComponent,
    ClientGroupMaintenanceComponent,
    SrcCdMaintenanceComponent,
    BmaMaintenanceComponent,
    ReasonCancellationMaintenanceComponent,
    CurrencyMaintenanceComponent,
    PublicUserStandardRatesMaintenanceComponent,
    PaymentOptionsMaintenanceComponent,
    PublicUserStandardRatesPerVehicleMaintenanceComponent,
    BfbStandardRatesMaintenanceComponent,
    BfbSrcExtMaintenanceComponent,
    PaymentOptionsAccessMaintenanceComponent,
    TariffRiskCodeMaintenanceComponent,
    MvPremTypeMaintenanceComponent,
    BpiMsStandardRatesMaintenanceComponent,
    RpaPerilMappingMaintenanceComponent,
    RpaGenPageMappingMaintenanceComponent,
    RpaClauseMappingMaintenanceComponent,
    MvTypeMaintenanceComponent,
    BfbStandardRatesPerVehicleMaintenanceComponent,
    BpiMsStandardRatesPerVehicleMaintenanceComponent,
    VoucherCodeMaintenanceComponent,
    SourceOfFundsMaintenanceComponent,
    DealershipStandardRatesMaintenanceComponent,
    CitizenshipNationalityMaintenanceComponent,
    RelationshipMaintenanceComponent,
    MotorHighRiskMaintenanceComponent,
    AgencyStandardRatesMaintenanceComponent,
    AgencyStandardRatesPerVehicleMaintenanceComponent,
    ReferenceCodesMaintenanceComponent,
    PurposeMaintenanceComponent,
    EmailContentMaintenanceComponent,
    SegmentCodeMaintenanceComponent,
    CustomerUserAccountMaintenanceComponent,
    CustomerMaintenanceComponent,
    RiskProfileMaintenanceComponent,
    DeductibleMaintenanceComponent,
    PlanAccessMaintenanceComponent,
    DeductibleMaintenancePerClientGroupComponent,
    DeductibleMaintenancePerClientGroupPerIssueSourceComponent,
    ApplicableDeductiblePerPlanMaintenanceComponent,
    DeductibleMaintenancePerClientGroupCMFComponent,
    DeductibleMaintenancePerClientGroupPerIssueSourceCMFComponent,
    UpdateRenewalDetailsComponent,
    ProductCodeMaintenanceComponent,
    PolicyCancellationComponent,
    ClientGroupStandardRatesMaintenanceComponent,
    ApplicableClientGroupPerUserIDComponent,
    StandardRatesPerPlanMaintenanceComponent,
    TowingAmtPerClientGrpMaintenanceComponent,
    TowingAmtPerClientGrpVehicleMaintenanceComponent,
    BlockedVehicleMaintenanceComponent,
    StandardRatesPerPlanBasedIssuingOfficeMaintenanceComponent,
    ClientGroupRatesForFixedPlanMaintenanceComponent,
    RiskRatingQuestionsMaintenanceComponent,
    ClientGroupRatesForFixedPlanBasedIOMaintenanceComponent,
    ClientGroupRatesForFlexiblePlanPerIssueSoureMaintenanceComponent,
    VehicleFilterMaintenanceComponent,
    PAHCIPParameterValidationComponent,
    StandardAmountPerClientGroupPerCarMaintenanceComponent,
    OccupancyMaintenanceComponent,
    TyphoonFloodZoningMaintenanceComponent,
    EarthquakeZoneMaintenanceComponent,
    RegionMaintenanceComponent,
    PlanAmtLimitsMaintenanceComponent,
    PropertyAssessmentWordingsMaintenanceComponent,
    PropertySiLimitMaintenanceComponent,
    SrcCdPerDealershipMaintenanceComponent,
    SrcExtPerDealershipMaintenanceComponent,
    AccountTypeMaintenanceComponent,
    FireDistrictMaintenanceComponent,
    ConstructionTypeMaintenanceComponent,
    StateMaintenanceComponent,
    ClassMaintenanceComponent,
    HazardGradeMaintenanceComponent,
    ConstructionYearMaintenanceComponent,
    PlanNotificationMaintenanceComponent,
    EffectiveDateBasisMaintenanceComponent,
    PropertyDetailsMaintenanceComponent,
    PreApprovedSubdivisionMaintenannceComponent,
    DetermineHcaPlanMaintenanceComponent,
    FirePolWordingsMaintenanceComponent,
    GeographicZoningMaintenanceComponent,
    ClientGroupPerAgentGroupMaintenanceComponent,
    CatZoneFireRatesPuMaintenanceComponent,
    CatZoneFireRatesAgentMaintenanceComponent,
    CatZoneFireRatesBpiMaintenanceComponent,
    CatZoneFireRatesBpimsMaintenanceComponent,
    ApplicableItemCodePerPlanMaintenanceComponent,
    BusinessCodeMaintenanceComponent,
    FirePremRtForAgentMaintenanceComponent,
    FirePremRtForBpimsMaintenanceComponent,
    FirePremRtForPublicMaintenanceComponent,
    FirePremRtForBpiMaintenanceComponent,
    FirePremiumRatesPerClientGroupMaintenanceComponent,
    OccupancyBusinessCodePerPlanMaintenanceComponent,
    OpenPolicySequenceNoMaintenanceComponent,
    FireFloodZoneMaintenanceComponent,
    FireTyphoonZoneMaintenanceComponent,
    QuotationWordingsMaintenanceComponent,
    FireCatPerilsPremiumRatesPerClientGroupMaintenanceComponent,
    SrcExtForRelaCodeMtnComponent,
    FireItemCodeMaintenanceComponent,
    PolicySequenceNumberMtnComponent,
    UserLevelMtnComponent
]
