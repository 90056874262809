import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { CustomValidators } from 'src/app/utils/custom-validator';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { HazardGrdLovComponent } from '../../common/hazard-grd-lov/hazard-grd-lov.component';
import { TariffLovComponent } from '../../common/tariff-lov/tariff-lov.component';

@Component({
  selector: 'occupancy-maintenance',
  templateUrl: './occupancy-maintenance.component.html',
  styleUrls: ['./occupancy-maintenance.component.css']
})
export class OccupancyMaintenanceComponent implements OnInit {

  private moduleId: string = 'BMM126';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc ?? "";

  constructor(
    public _formService: FormService,
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService,
    private _appMessageService: AppMessageService,
  ) {
    this.userIdLoggedIn = this._userDetailService.userId ?? "";
  }

  public canDeactivate(): boolean | Observable<boolean> {
    return !(this.occupancyTypeTblConfig.tblData?.filter((row: any) => ['I', 'U'].includes(row.operation)).length > 0);
  }

  public btns = {
    occupancyType: {
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      addDisabled: false,
    },
  };

  public showForm: boolean = true;

  //* Data Stuffs */
  private rowData: any;
  private errorMessage: string = "";
  private userIdLoggedIn: string;
  //? set selectedRow to [] to unselect the table
  public selectedRow!: any[];

  public forms = {
    occupancyTypeForm: this.fb.group({
      occupancyCd: [{ value: '', disabled: 'true' }],
      occupancyDesc: [null, [Validators.required, Validators.maxLength(2000), CustomValidators.requiredTrim]],
      active: ['A', [Validators.required]],
      tariffCd: [null],
      tariffDesc: [null],
      tariffDisp: [{ value: '', disabled: 'true' }],
      hazardGrdCd: [null, [Validators.maxLength(8)]],
      hazardGrdDesc: [null],
      hazardGrdDisp: [{ value: '', disabled: 'true' }],
      bmCode: [null, [Validators.required, Validators.maxLength(8), CustomValidators.requiredTrim]],
      remarks: [null, [Validators.maxLength(1000)]],
      lastUpdateUser: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }],
    }),
    resetOccupancyTypeForm: () => {
      this.forms.occupancyTypeForm.reset();
      this.forms.occupancyTypeForm.patchValue({
        active: 'A',
      });
      this.btns.occupancyType.updateDisabled = this.btns.occupancyType.deleteDisabled = true;
      this._formService.hideFormMsg("occupancy-type-error-message");
      this.rowData = null;
    }
  }

  private occupancyTypeFormManipulate = {
    dbToForm: (data: any) => {
      return {
        occupancyCd: data.OCCUPANCY_CD,
        occupancyDesc: data.OCCUPANCY_DESC,
        active: data.ACTIVE_TAG,
        tariffCd: data.TARIFF_CD,
        tariffDesc: data.TARIFF_DESC,
        tariffDisp: data.TARIFF_CD,
        hazardGrdCd: data.HAZARD_GRD_CD,
        hazardGrdDisp: data.HAZARD_GRD_CD,
        bmCode: data.BM_CODE,
        remarks: data.REMARKS,
        lastUpdateUser: data.USER_ID,
        lastUpdate: data.LAST_UPDATE,
      };
    },
    formToDb: (data: any) => {
      return {
        OCCUPANCY_CD: data.occupancyCd,
        OCCUPANCY_DESC: data.occupancyDesc,
        ACTIVE_TAG: data.active,
        TARIFF_CD: data.tariffCd?.trim(),
        TARIFF_DESC: data.tariffDesc,
        HAZARD_GRD_CD: data.hazardGrdCd?.trim(),
        BM_CODE: data.bmCode?.trim(),
        REMARKS: data.remarks,
        USER_ID: null,
        LAST_UPDATE: null,
      };
    },
  }

  public occupancyTypeTblConfig: any = {
    cols: [
      {
        key: "OCCUPANCY_CD",
        header: "Occupancy Code",
        dataType: "string",
        width: '144px',
      },
      {
        key: "OCCUPANCY_DESC",
        header: "Occupancy Description",
        dataType: "string",
      },
      {
        key: "BM_CODE",
        header: "Tariff Code",
        dataType: "string",
        width: '128px',
      },
      {
        key: "TARIFF_CD",
        header: "Tariff Short Description",
        dataType: "string",
        width: '128px',
      },
      {
        key: "HAZARD_GRD_CD",
        header: "Hazard Grade Code",
        dataType: "string",
        width: '152px',
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        width: '48px',
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  ngOnInit(): void {
    this.getData.occupancyType();
  }

  public openLov = {
    tariff: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          moduleId: this.moduleId,
          shortDescOnly: true,
        },
      }
      this._matDialog.open(TariffLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
        let output = dataOutput.content;
        if (dataOutput?.button?.toUpperCase() !== 'BACK') {
          if (output?.TARIFF_CD) {
            this.forms.occupancyTypeForm.patchValue({
              tariffCd: output.TARIFF_CD,
              tariffDesc: output.TARIFF_DESC,
              tariffDisp: output.TARIFF_CD,
            });
          }
          else {
            this.forms.occupancyTypeForm.patchValue({
              tariffCd: null,
              tariffDesc: null,
              tariffDisp: null,
            });
          }
        }
      });
    },
    hazardGrd: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          moduleId: this.moduleId
        },
      }
      this._matDialog.open(HazardGrdLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
        let output = dataOutput.content;
        if (dataOutput?.button?.toUpperCase() !== 'BACK') {
          if (output?.HAZARD_GRD_CD) {
            this.forms.occupancyTypeForm.patchValue({
              hazardGrdCd: output.HAZARD_GRD_CD,
              hazardGrdDisp: output.HAZARD_GRD_CD,
            });
          }
          else {
            this.forms.occupancyTypeForm.patchValue({
              hazardGrdCd: null,
              hazardGrdDisp: null,
            });
          }
        }
      });
    },
  };

  private getData = {
    occupancyType: () => {
      try {
        this.occupancyTypeTblConfig.tblData = [];
        this._jDS.contorlLoading(true);
        this.occupancyTypeTblConfig.loading = true;
        this._api.getOccupancy({
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          type: "MODULE",
        }).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                if (data.status === "SUCCESS") {
                  let content = JSON.parse(this._jDS.decrypt(data?.response));
                  this.occupancyTypeTblConfig.tblData = [...content.data.filter((d: any) => d.ACTIVE_TAG == 'A'), ...content.data.filter((d: any) => d.ACTIVE_TAG != 'A')];
                  this._jDS.contorlLoading(false);
                  this.occupancyTypeTblConfig.loading = false;
                }
                else {

                  this._jDS.contorlLoading(false);
                  this.occupancyTypeTblConfig.loading = false;
                }
              });
            });
          },
          error: (e: any) => {
            this._securityService.checkRequestKeyResponse(e, () => {

              this._jDS.contorlLoading(false);
              this.occupancyTypeTblConfig.loading = false;
            });
          }
        });
      }
      catch (e) {
    console.error(e);

      }
    },
  };

  public selections = {
    activeTypes: [
      { cd: 'A', name: 'Yes' },
      { cd: 'I', name: 'No' },
    ],
  };

  public onRowClick(ev: any) {
    try {
      if (ev) {
        this.rowData = ev;
        this.populateFormFromTable();
      }
      else {
        this.rowData = null;
        this.forms.resetOccupancyTypeForm();
      }
    }
    catch (e) {
    console.error(e);
      this.rowData = null;
      this.forms.resetOccupancyTypeForm();
    }
  }

  private populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.occupancyType.updateDisabled = false;
      this.btns.occupancyType.deleteDisabled = !(this.rowData?.operation === 'I');
      this.forms.occupancyTypeForm.patchValue(this.occupancyTypeFormManipulate.dbToForm(data));
    }
    catch (e) {
    console.error(e);

    }
  }

  public onAdd(): void {
    try {
      this._formService.hideFormMsg("occupancy-type-error-message");
      if (this.forms.occupancyTypeForm.valid) {
        let rowToBeAdded: { [key: string]: any } = this.occupancyTypeFormManipulate.formToDb(this.forms.occupancyTypeForm.getRawValue());
        rowToBeAdded.operation = 'I';
        this.occupancyTypeTblConfig.tblData = [rowToBeAdded, ...this.occupancyTypeTblConfig.tblData];
        this.forms.resetOccupancyTypeForm();
        this.btns.occupancyType.saveDisabled = false;
      }
      else {
        this.showErrorValidator.occupancyType();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onUpdate(): void {
    try {
      this._formService.hideFormMsg("occupancy-type-error-message");
      if (this.forms.occupancyTypeForm.valid) {
        const indexOfRow = this.occupancyTypeTblConfig.tblData.indexOf(this.rowData);
        let rowToBeUpdated: { [key: string]: any } = this.occupancyTypeFormManipulate.formToDb(this.forms.occupancyTypeForm.getRawValue());
        rowToBeUpdated.operation = (this.rowData.operation === 'I') ? 'I' : 'U';
        rowToBeUpdated.OCCUPANCY_CD = this.rowData?.OCCUPANCY_CD;
        this.forms.resetOccupancyTypeForm();
        this.occupancyTypeTblConfig.tblData[indexOfRow] = rowToBeUpdated;
        this.selectedRow = [];
        this.btns.occupancyType.saveDisabled = false;
        this.occupancyTypeTblConfig.tblData = [...this.occupancyTypeTblConfig.tblData];
      }
      else {
        this.showErrorValidator.occupancyType();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onDelete(): void {
    try {
      this.occupancyTypeTblConfig.tblData = this.occupancyTypeTblConfig.tblData.filter((row: any) => {
        return row !== this.rowData;
      });
      this.forms.resetOccupancyTypeForm();
      this.btns.occupancyType.saveDisabled = false;
    }
    catch (e) {
    console.error(e);

    }
  }

  public onSave(): void {
    try {
      this._jDS.contorlLoading(true);
      this.showForm = false;
      this._formService.showFormLoader(null, "occupancy-type-maintenance-loading", "Saving.<br>Please wait ...", null, null, true);
      let toBeSaved = this.occupancyTypeTblConfig.tblData.filter((row: any) => ['I', 'U'].includes(row.operation));
      if (toBeSaved.length > 0) {
        this._api.saveOccupancy(toBeSaved).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                if (data.status === 'SUCCESS') {
                  this.onComplete.occupancyType();
                }
                else {
                  this.onComplete.occupancyTypeError();
                }
              });
            });
          },
          error: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {

              this.onComplete.occupancyTypeError();
            });
          }
        });
      }
      else {
        this.onComplete.occupancyType();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  private onComplete = {
    occupancyType: () => {
      this._jDS.contorlLoading(false);
      this._formService.hideFormLoader("occupancy-type-maintenance-loading");
      this.openDialog('SUCCESS', this.moduleName.replace('Maintenance', ''), 'saved');
      this.btns.occupancyType.saveDisabled = true;
      this.showForm = true;
      this.forms.resetOccupancyTypeForm();
    },
    occupancyTypeError: () => {
      this._jDS.contorlLoading(false);
      this.openDialog('FAILED', this.moduleName.replace('Maintenance', ''), 'saving');
      this._formService.hideFormLoader("occupancy-type-maintenance-loading");
      this.showForm = true;
      this.forms.resetOccupancyTypeForm();
    },
  }

  private openDialog(title: string, contentTitle: string, contentDetail: string) {
    try {
      this._matDialog.open(DialogMsgComponent, {
        disableClose: true,
        width: '512px',
        data: {
          title: title,
          content:
            title === 'SUCCESS'
              ? `${contentTitle} details successfully ${contentDetail}!`
              : `${contentTitle} details ${contentDetail} failed!`,
        },
      }).afterClosed().subscribe(() => {
        if (title === 'SUCCESS') {
          this.getData.occupancyType();
        }
      });
    }
    catch (e) {
    console.error(e);

    }
  }

  //* Validator Stuffs Down here */

  private checkDuplicate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.trim()?.toUpperCase();
      if (!value) return null;
      let hasDuplicate: boolean = this.occupancyTypeTblConfig?.tblData.filter((d: any) => {
        if (!this.rowData)
          return d['OCCUPANCY_DESC']?.trim().toUpperCase() === value;
        else
          return this.rowData['OCCUPANCY_DESC']?.trim().toUpperCase() !== value && d['OCCUPANCY_DESC']?.trim().toUpperCase() === value;
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupe: true } : null;
    }
  }

  private checkDupes(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const occupancyDescValue = control.get('occupancyDesc')?.value?.trim()?.toUpperCase();
      const tariffCdValue = control.get('tariffCd')?.value ?? '';
      if (!occupancyDescValue) {
        return null;
      }
      let hasDuplicate: boolean = this.occupancyTypeTblConfig.tblData.filter((d: any) => {
        return this.checkBool(d['OCCUPANCY_DESC']?.trim().toUpperCase(), occupancyDescValue, d['TARIFF_CD'] ?? '', tariffCdValue);
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupe: true } : null;
    }
  }

  private checkBool(dataOccupancyDesc: string, occupancyDescVal: string, dataTariffCd: string, tariffCdValue: string): boolean {
    if (!this.rowData)
      return occupancyDescVal === dataOccupancyDesc && tariffCdValue === dataTariffCd;
    return !(this.rowData['OCCUPANCY_DESC'] === dataOccupancyDesc && (this.rowData['TARIFF_CD'] ?? '') == dataTariffCd)
      && (occupancyDescVal === dataOccupancyDesc && tariffCdValue === dataTariffCd);
  }

  public showErrorValidator = {
    occupancyType: () => {
      try {
        if (this.forms.occupancyTypeForm.errors) {
          const controlError = this.forms.occupancyTypeForm.errors;
          Object.keys(controlError).forEach(keyError => {
            if (keyError == 'hasDupe' && controlError[keyError] === true)
              this.errorMessage = "Unable to add record. Occupancy Description with same Tariff Code already exists.";
          });
        }
        else {
          Object.keys(this.forms.occupancyTypeForm.controls).forEach(key => {
            const controlErrors = this.forms.occupancyTypeForm.get(key)?.errors;
            if (controlErrors != null) {
              Object.keys(controlErrors).forEach(keyError => {
                if (keyError == 'hasDupe' && controlErrors[keyError] === true)
                  this.errorMessage = "Occupancy Description already exists. Please use a unique Occupancy Description.";
                else if (keyError == 'required' && controlErrors[keyError] === true)
                  this.errorMessage = "There are missing information. Please provide necessary information needed.";
              });
            }
          });
        }
        this._formService.showFormMsg("occupancy-type-error-message", this.errorMessage, "E");
      }
      catch (e) {
    console.error(e);

      }
    },
  }

}
