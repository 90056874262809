import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';

@Component({
  selector: 'deploy-changes',
  templateUrl: './deploy-changes.component.html',
  styleUrls: ['./deploy-changes.component.css']
})
export class DeployChangesComponent implements OnInit {

  public showStatus: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<DeployChangesComponent>,
    private _api: APICallService,
    private _formService: FormService,
  ) { }

  ngOnInit(): void {
    this.deployChanges();
  }

    private deployChanges() {
        this.showStatus = false;
        this._formService.showFormLoader(null, "deploy-changes-loading", "Deploying Changes.<br>Please wait ...", null, null);
        this._api.deployChanges().subscribe({
            next: (data: any) => {
                if (data.status === "SUCCESS") {
                    this.status.complete();
                } else {
                    this.status.error();
                }
            },
            error: (data: any) => {
                console.log("Error Saving @ deployChanges() deploy-changes");
                this.status.error();
            }
        });
    }

  private status = {
    complete: () => {
      this.data.content = `Deployed changes successfully.`;
      this._formService.hideFormLoader("deploy-changes-loading");
      this.showStatus = true;
    },
    error: () => {
      this.data.content = `Deploying changes FAILED.`;
      this._formService.hideFormLoader("deploy-changes-loading");
      this.showStatus = true;
    },
  }

}
