import { DatePipe } from '@angular/common';
import { Component, OnInit, Type } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';
import { PerilLovComponent } from '../../common/peril-lov/peril-lov.component';

interface RowData {
    srcFunds: any
}

@Component({
    selector: 'source-of-funds-maintenance',
    templateUrl: './source-of-funds-maintenance.html',
    styleUrls: ['./source-of-funds-maintenance.css']
})
export class SourceOfFundsMaintenanceComponent implements OnInit, LeaveConfirmation {

    moduleId: string = 'BMM120';
    moduleName: string = "";
    formHider: boolean = false;  

    constructor(
        private fb: FormBuilder,
        private _matDialog: MatDialog,
        private _api: APICallService,
        public _formService: FormService,
        private _jsonDS: JsonDataService,
        private _securityService: SecurityService,
        private _userDetailService: UserDetailsService,
        private _appMessageService: AppMessageService
    ) {
        this.userIdLoggedIn = this._userDetailService.userId || "";
    }

    ngOnInit(): void {
        this.moduleName = this._jsonDS.data.moduleList.filter((data: any) => { return data.moduleId === this.moduleId })[0].moduleDesc.toUpperCase();
        this.getData.srcFunds();
    }

    canDeactivate(): boolean | Observable<boolean> {
        return this.tblConfig.srcFunds.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true)).length > 0 ? false : true;
    }

    test() {
        console.table(this.forms.srcFundsForm.value);
    }

    allowNumericDigitsOnly(event: any) {
        try {
            if (/[0-9.]/.test(String.fromCharCode(event.keyCode))) {
                return true;
            } else {
                event.preventDefault();
                return false;
            }
        } catch (e) {
    console.error(e);
            return undefined;
        }
    }

    /* Boolean Stuffs */

    public btns = {
        srcFunds: {
            updateDisabled: true,
            deleteDisabled: true,
            saveDisabled: true,
            addDisabled: false,
        }
    }

    public showForm = {
        srcFunds: true
    }

    /* Data Stuffs */

    private rowData: RowData = {
        srcFunds: null
    }

    private errorMessage: RowData = {
        srcFunds: null
    }

    private userIdLoggedIn: string;

    /* Form Object  */

    public forms = {
        srcFundsForm: this.fb.group({
            srcFundCd: [''],
            srcFundCdPadded: [{ value: '', disabled: 'true' }],
            activeTag: ['A', [Validators.required]],
            srcFundDesc: ['', [this.checkDuplicateSrcFundDesc('srcFunds'), Validators.maxLength(50), Validators.required]],
            noTin:  [''],
            regEmp: [''],
            donation: [''],
            polExpPer: [''],
            overseas: [''],
            bpimsCd: ['', [Validators.maxLength(8)]],
            bpimsShortDesc: ['', [Validators.maxLength(10)]],
            remarks: ['', [Validators.maxLength(4000)]],
            lastUpdateUser: [{ value: '', disabled: 'true' }],
            lastUpdate: [{ value: '', disabled: 'true' }],
        }),
        resetSrcFundsForm: () => {
            this.forms.srcFundsForm.reset();
            this.forms.srcFundsForm.patchValue({
                activeTag: 'A',
                noTin: '',
                regEmp: '',
                donation: '',
                polExpPer: '',
                overseas: '',
            });
            this.btns.srcFunds.updateDisabled = true;
            this.btns.srcFunds.deleteDisabled = true;
            this._formService.hideFormMsg("srcFunds-error-message");
            this.rowData.srcFunds = null;
        }
    };

    /* Table Object */

    public tblConfig: RowData = {
        srcFunds: {
            cols: [
                {
                    key: "SRC_FUND_CD_PADDED",
                    header: "Source of Fund Code",
                    dataType: "string",
                    width: '24%'
                },
                {
                    key: "SOURCE_FUND_DESC",
                    header: "Source of Fund",
                    dataType: "string",
                    width: '70%'
                },
                {
                    key: "ACTIVE_TAG",
                    header: "A",
                    dataType: "checkbox",
                    width: '6%'
                },
            ],
            tblData: [],
            selection: "single",
            paginator: true,
            rowsPerPage: 10,
            lazy: false,
            totalRecords: 15,
            loading: false,
        }
    }

    private formManipulation = {
        srcFunds: {
            dbToForm: function (data: any) {
                return {
                    srcFundCd: (data.SOURCE_FUND_CD) ? data.SOURCE_FUND_CD : null,
                    srcFundCdPadded: data.SRC_FUND_CD_PADDED,
                    activeTag: data.ACTIVE_TAG,
                    srcFundDesc: data.SOURCE_FUND_DESC,
                    noTin:  data.NO_TIN_TAG,
                    regEmp: data.REG_EMP_TAG,
                    donation: data.DONATION_TAG,
                    polExpPer: data.PEP_TAG,
                    overseas: data.OVERSEAS_TAG,
                    bpimsCd: data.BM_CODE,
                    bpimsShortDesc: data.BM_SHORT_DESC,
                    remarks: data.REMARKS,
                    lastUpdateUser: data.USER_ID,
                    lastUpdate: data.LAST_UPDATE
                };
            },
            formToDb: function (data: any) {
                return {
                    SOURCE_FUND_CD: data.srcFundCd,
                    SRC_FUND_CD_PADDED: (data.srcFundCd) ? data.srcFundCd.toString().padStart(2, 0) : null,
                    ACTIVE_TAG: data.activeTag,
                    SOURCE_FUND_DESC: data.srcFundDesc,
                    NO_TIN_TAG: data.noTin,
                    REG_EMP_TAG: data.regEmp,
                    DONATION_TAG: data.donation,
                    PEP_TAG: data.polExpPer,
                    OVERSEAS_TAG: data.overseas,
                    BM_CODE: data.bpimsCd,
                    BM_SHORT_DESC: data.bpimsShortDesc,
                    REMARKS: data.remarks,
                    USER_ID: null,
                    LAST_UPDATE: null
                };
            }
        }
    }

    public selections = {
        activeTypes: [
            { cd: 'A', name: 'Yes' },
            { cd: 'I', name: 'No' },
        ],
        noTinTypes: [
            { cd: 'Y', name: 'Yes' },
            { cd: 'N', name: 'No' },
        ],
        regEmpTypes: [
            { cd: 'Y', name: 'Yes' },
            { cd: 'N', name: 'No' },
        ],
        donationTypes: [
            { cd: 'Y', name: 'Yes' },
            { cd: 'N', name: 'No' },
        ],
        polExpPersonTypes: [
            { cd: 'Y', name: 'Yes' },
            { cd: 'N', name: 'No' },
        ],
        overseasTypes: [
            { cd: 'Y', name: 'Yes' },
            { cd: 'N', name: 'No' },
        ],

    };

    private getData = {
        srcFunds: () => {
            try {
                this.tblConfig.srcFunds.tblData = [];
                this.tblConfig.srcFunds.loading = true;
                this._jsonDS.contorlLoading(true);
                this._api.getSrcFund({
                    moduleId: this.moduleId,
                    userId: this._userDetailService.userId,
                    type: "MODULE"
                }).subscribe({ 
                    next: (data: any) => {
                        this._securityService.checkRequestKeyResponse(data, () => {
                            this._securityService.hasValidCsrfToken(data, ()=>{
                                try {
                                    if (data.status === 'SUCCESS') {
                                        data = JSON.parse(this._jsonDS.decrypt(data.response));
                                        this._jsonDS.contorlLoading(false);
                                        let filteredData = data.data;
                                        this.tblConfig.srcFunds.tblData = filteredData;
                                    } else {
                                        this.tblConfig.srcFunds.loading = false;
                                        this._jsonDS.contorlLoading(false);
                                        this._appMessageService.showAppMessage(data.message, "error");
                                    }
                                } catch (e) {
    console.error(e);
                                }
                                this.tblConfig.srcFunds.loading = false;
                            });
                        });
                    },
                    error: (e: any) => {
                        this.tblConfig.srcFunds.loading = false;
                        this._jsonDS.contorlLoading(false);
                    }
                });
            } catch (e) {
    console.error(e);
                this.tblConfig.srcFunds.loading = false;
                this._jsonDS.contorlLoading(false);
            }
        }
    }

    public onRowClick = {
        srcFunds: (ev: any) => {
            try {
                ev.update = true;
                this.rowData.srcFunds = ev;
                this.populateFormFromTable.srcFunds();
            } catch (e) {
    console.error(e);
                this.forms.resetSrcFundsForm();
                this.rowData.srcFunds = null;
            }
        }
    }

    private populateFormFromTable = {
        srcFunds: () => {
            try {
                let data = this.rowData.srcFunds;
                this.btns.srcFunds.updateDisabled = !data.update;
                this.btns.srcFunds.deleteDisabled = !data.temp;
                this.forms.srcFundsForm.patchValue(this.formManipulation.srcFunds.dbToForm(data));
            } catch (e) {
    console.error(e);
            }
        }
    }

    public onAdd = {
        srcFunds: () => {
            try {
                this._formService.hideFormMsg("srcFunds-error-message");
                if (this.forms.srcFundsForm.valid&& !this.checkRequired()) {
                    let rowToBeAdded: { [key: string]: any } = this.formManipulation.srcFunds.formToDb(this.forms.srcFundsForm.value);
                    rowToBeAdded.temp = true;
                    this.tblConfig.srcFunds.tblData = [rowToBeAdded, ...this.tblConfig.srcFunds.tblData];
                    this.forms.resetSrcFundsForm();
                    this.btns.srcFunds.saveDisabled = false;
                } else {
                    this.showErrorValidator.srcFunds();
                }
            }
            catch (e) {
    console.error(e);
            }
        }
    }

    public onDelete = {
        srcFunds: () => {
            try {
                this.tblConfig.srcFunds.tblData = this.tblConfig.srcFunds.tblData.filter((d: any) => {
                    return d !== this.rowData.srcFunds;
                });
                this.forms.resetSrcFundsForm();
                let toBeSaved = this.tblConfig.srcFunds.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true));
                if (toBeSaved.length === 0) {
                    this.btns.srcFunds.saveDisabled = true;
                } else {
                    this.btns.srcFunds.saveDisabled = false;
                }
            } catch (e) {
    console.error(e);
            }
        }
    }

    public onUpdate = {
        srcFunds: () => {
            try {
                this._formService.hideFormMsg("srcFunds-error-message");
                if (this.forms.srcFundsForm.valid&& !this.checkRequired()) {
                    let toBeUpdatedIndex = this.tblConfig.srcFunds.tblData.indexOf(this.rowData.srcFunds);
                    let rowToBeUpdated: { [key: string]: any } = this.formManipulation.srcFunds.formToDb(this.forms.srcFundsForm.value);
                    if (this.rowData.srcFunds.temp) {
                        rowToBeUpdated.temp = true;
                    } else {
                        rowToBeUpdated.onDbButUpdatedTemp = true;
                    }
                    this.forms.resetSrcFundsForm();
                    this.tblConfig.srcFunds.tblData[toBeUpdatedIndex] = rowToBeUpdated;
                    this.btns.srcFunds.saveDisabled = false;
                    this.tblConfig.srcFunds.tblData = [... this.tblConfig.srcFunds.tblData];
                } else {
                    this.showErrorValidator.srcFunds();
                }
            } catch (e) {
    console.error(e);
            }
        }
    }

    public onSave = {
        srcFunds: () => {
            this.showForm.srcFunds = false;
            this._formService.showFormLoader(null, "srcFunds-maintenance-loading", "Saving.<br>Please wait ...", null, null);
            let toBeSaved = this.tblConfig.srcFunds.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true));
            if (toBeSaved.length > 0) {
                toBeSaved.map((d: any) => {
                    d.LAST_USER = this.userIdLoggedIn;
                });
                this._api.saveSrcFund(toBeSaved).subscribe({
                    next: (data: any) => {
                        this._securityService.checkRequestKeyResponse(data, () => {
                            this._securityService.hasValidCsrfToken(data, ()=>{
                                if (data.status === 'SUCCESS') {
                                    this.onComplete.srcFunds();
                                } else {
                                    this.onComplete.srcFundsError();
                                }
                            });
                        });
                    },
                    error: (data: any) => {
                        this.onComplete.srcFundsError();
                    },
                });
            } else {
                this.onComplete.srcFunds();
            }
        }
    }

    openDialog(title: string, contentTitle: string, contentDetail: string) {
        try {
            this._matDialog.open(DialogMsgComponent, {
                disableClose: true,
                width: '512px',
                data: {
                title: title,
                content:
                    title === 'SUCCESS'
                    ? `${contentTitle} details successfully ${contentDetail}!`
                    : `${contentTitle} details ${contentDetail} failed!`,
                },
            });
        } catch (e) {
    console.error(e);
        }
    }

    private onComplete = {
        srcFunds: () => {
            this.getData.srcFunds();
            this.btns.srcFunds.saveDisabled = true;
            this.showForm.srcFunds = true;
            this._formService.hideFormLoader("srcFunds-maintenance-loading");
            this.openDialog('SUCCESS', 'Source of Funds', 'saved');
        },
        srcFundsError: () => {
            this.showForm.srcFunds = true;
            this.btns.srcFunds.saveDisabled = false;
            this.openDialog('FAILED', 'Source of Funds', 'saving');
            this._formService.hideFormLoader("srcFunds-maintenance-loading");
        }
    }

    /* Validator Stuffs Down here */

    checkDuplicateSrcFundDesc(module: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value === null ? control.value : control.value.trim();
            if (!value) {
                return null;
            }
            let hasDuplicate: boolean = this.tblConfig[module as keyof RowData].tblData.filter((d: any) => {
                if (!this.rowData[module as keyof RowData]) {
                    return d.SOURCE_FUND_DESC.toLowerCase() === value.toLowerCase();
                } else {
                    return this.rowData[module as keyof RowData].SOURCE_FUND_DESC.toLowerCase() !== value.toLowerCase() && d.SOURCE_FUND_DESC.toLowerCase() === value.toLowerCase();
                }
            }).length > 0 ? true : false;
            return hasDuplicate ? { hasDupeSrcFundDesc: true } : null;
        }
    }

    /*checkDuplicatePaytDesc(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            if (!value) {
                return null;
            } 
            let hasDuplicate: boolean = this.tblConfig.srcFunds.tblData.filter((d: any) => {
                if (!this.rowData.srcFunds) {
                    return d.PAYT_DESC === value;
                } else {
                    return this.rowData.srcFunds.PAYT_DESC !== value && d.PAYT_DESC === value;
                }
            }).length > 0 ? true : false;
            return hasDuplicate ? { hasDupePaytDesc: true } : null;
        }
    }

    checkDuplicateSeqNo(module: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            if (!value) {
                return null;
            }
            let hasDuplicate: boolean = this.tblConfig[module as keyof RowData].tblData.filter((d: any) => {
                if (!this.rowData[module as keyof RowData]) {
                    return d.SEQ_NO == value;
                } else {
                    return this.rowData[module as keyof RowData].SEQ_NO != value && d.SEQ_NO == value;
                }
            }).length > 0 ? true : false;
            return hasDuplicate ? { hasDupeSeqNo: true } : null;
        }
    }*/
    checkRequired(){
        if(this.forms.srcFundsForm.get('srcFundDesc')?.value.trim()=='' || this.forms.srcFundsForm.get('srcFundDesc')?.value==null) {
          return true;
        }else{
          return false;
        }
      }
    public showErrorValidator = {
        srcFunds: () => {
            try {
                Object.keys(this.forms.srcFundsForm.controls).forEach(key => {
                    const controlErrors = this.forms.srcFundsForm.get(key)?.errors;
                    if (controlErrors != null) {
                        Object.keys(controlErrors).forEach(keyError => {
                            if (keyError == 'required' && controlErrors[keyError] === true){
                                this.errorMessage.srcFunds = "There are missing information. Please provide necessary information needed.";
                            } else if (keyError == 'hasDupeSrcFundDesc' && controlErrors[keyError] === true) {
                                this.errorMessage.srcFunds = "Source of Fund already exists. Please add a unique Source of Fund.";
                            }
                        });
                    }
                });
                if(this.checkRequired()){
                    this.errorMessage.srcFunds = "There are missing information. Please provide necessary information needed.";
                }
                this._formService.showFormMsg("srcFunds-error-message", this.errorMessage.srcFunds, "E");
            } catch (e) {
    console.error(e);
            }
        }
    }
}
