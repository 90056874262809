<section id="subline">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>

        <div class="form-content">
            <form [formGroup]="lineForm"  class="form">
                <div class="form-container-grid top-form">
                    <div class="field required">
                        <label>Line</label>
                    </div>
                    <div class="">
                        <input formControlName="LINE_NAME">
                        <button matSuffix class="btn btn-search" type="button" (click)="checkOpenLineDialog()"><mat-icon>search</mat-icon></button>
                    </div>
                </div>
            </form>
        </div>

        <osp-table [tblConfig]="sublineTable" (onRowClick)="onRowClick($event)"></osp-table>

        <!-- <div id="subline-form"><div class="form-loader"></div></div> -->

        <div class="form-content" [hidden]="formHider">

            <form [formGroup]="sublineForm" class="form">
                <div id="subline-error-message"></div>
                <div class="form-container-grid">

                    <div class="field required">
                        <label>Subline Code</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="SUBLINE_CD" oninput="this.value = this.value.toUpperCase()" maxlength="7">
                    </div>

                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="ACTIVE_TAG">
                            <option value="A">Yes</option>
                            <option value="I">No</option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>Subline Name</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="SUBLINE_NAME" maxlength="50">
                    </div>

                    <div class="field required">
                        <label>Subline Time</label>
                    </div>
                    <div class="">
                        <input type="time" step="1" formControlName="SUBLINE_TIME">
                    </div>

                    <div class="field">
                        <label>Sequence No</label>
                    </div>
                    <div class="">
                        <input type="number" formControlName="SEQ_NO" min="0" (change)="seqNoValidation($event.target)" posNumInput>
                    </div>

                    <div class="field">
                        <label>User Type View</label>
                    </div>
                    <div class="">
                        <select formControlName="USER_TYPE_VIEW">
                            <option value=""></option>
                            <option *ngFor="let userTypeView of userTypeViewList" [value]="userTypeView.rvLowValue">{{ userTypeView.rvMeaning }}</option>
                        </select>
                    </div>

                    <div class="field">
                        <label>Image Path</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="IMAGE_PATH" maxlength="500">
                    </div>

                    <div class="field required">
                        <label>Avail Now</label>
                    </div>
                    <div class="">
                        <select formControlName="AVAIL_TAG">
                            <option value=""></option>
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                        </select>
                    </div>

                    <div class="field">
                        <label>Short Description</label>
                    </div>
                    <div class="span">
                        <textarea formControlName="SHORT_DESC" maxlength="4000"></textarea>
                    </div>

                    <div class="field">
                        <label>Condition</label>
                    </div>
                    <div class="span">
                        <input type="text" formControlName="CONDITION_TEXT" maxlength="500">
                    </div>

                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input type="text" formControlName="REMARKS" maxlength="4000">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input formControlName="USER_ID">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="LAST_UPDATE">
                    </div>
                </div>
            </form>

            <div class="btn-grp">
                <button type="button" class="btn btn2" [hidden]="!addUpdateToggler" (click)="addRow()">ADD</button>
                <button type="button" class="btn btn2" [hidden]="addUpdateToggler" (click)="onUpdate()">UPDATE</button>
                <button type="button" class="btn btn2" [disabled]="deleteDisabler" (click)="deleteTableRow()">DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-grp">
                <button type="button" class="btn btn2" [disabled]="saveDisabler" (click)="onSave()">SAVE</button>
            </div>

        </div>
    </div>

</section>
