import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { DialogBoxService } from 'src/app/services/dialog-box.service';
import { FormService } from 'src/app/services/form.service';
import { SecurityService } from 'src/app/services/security.service';

@Component({
    selector: 'forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

    constructor(
        private formBuilder: FormBuilder,
        private apiCallService: APICallService,
        private appMessageService: AppMessageService,
        private formService: FormService,
        private router: Router,
        private securityService: SecurityService
    ) { }

    ngOnInit(): void {
    }

    forgotDetails: FormGroup = this.formBuilder.group({
        userId: ["", [Validators.required]]
    })

    sendResetEmail(): void {
        this.formService.hideFormMsg("forgotFormMsg")
        if(this.forgotDetails.valid) {
            this.formService.showFormLoader(null, "forgot-form", "Sending email.<br>Please wait ...", null, null);
            this.apiCallService.forgotPassword(this.forgotDetails.value).subscribe((response: any) => {
                this.securityService.checkRequestKeyResponse(response, () => {
                    if (response.status == "SUCCESS") {
                        this.appMessageService.showAppMessage("Email has been sent.", "success");
                        this.formService.hideFormLoader("forgot-form");
                        this.router.navigate(["home/login"]);
                    } else {
                        this.formService.hideFormLoader("forgot-form");
                        this.formService.showFormMsg("forgotFormMsg", response.message, "E");
                    }
                });
            });
        } else {
            this.formService.showFormMsg("forgotFormMsg", "Please fill up all the required fields", "E")
        }
    }
}
