import { Component, OnInit } from '@angular/core';

import { APICallService } from 'src/app/services/api-call.service'
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { SecurityService } from 'src/app/services/security.service';

import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe, formatDate } from '@angular/common';

import { Observable } from 'rxjs';

import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';

@Component({
  selector: 'currency-maintenance',
  templateUrl: './currency-maintenance.component.html',
  styleUrls: ['./currency-maintenance.component.css']
})
export class CurrencyMaintenanceComponent implements OnInit, LeaveConfirmation {

  datePipe = new DatePipe('en-us');

  saveDisabledCurr : boolean = true;
  deleteDisabledCurr : boolean = true;
  saveDisabledCurrRt : boolean = true;
  deleteDisabledCurrRt : boolean = true;
  noLoadingInProcessCurr : boolean = true;
  noLoadingInProcessCurrRt : boolean = true;
  validated : boolean = false;
  submitted : boolean = false;
  isCurrSelected : boolean = false;
  isCurrRtSelected : boolean = false;
  isSuccessSaved : boolean = false;
  currCdField: string = '';
  minEffDate: Date = new Date();
  effDateFromField: any = '';
  currentUser: string = this.userDetailService.userId || "";
  rowClickedDataCurr: any;
  rowClickedDataCurrRt: any;
  moduleId: string = "BMM116";
  public moduleTitle = this.jsonDataService.data.moduleList.filter((e: any) => e.moduleId == this.moduleId)[0].moduleDesc.toUpperCase();

  effDate = new FormControl(new Date());

  tempAddedDataCurr: any = [];
  tempUpdateDataCurr: any = [];
  tempAddedDataCurrRt: any = [];
  tempUpdateDataCurrRt: any = [];
  tempDataCurr: any = [];
  tempDataCurrRt: any = [];

  currForm = this.fb.group({
    currencyCd: [null, [Validators.required, Validators.maxLength(3)]],
    activeTag: ['A', [Validators.required]],
    currencyWord: [null, [Validators.required]],
    currencyDesc: [null, [Validators.required]],
    remarks: [null],
    lastUpdateName: [null],
    lastUserUpdate: [null],
    mode: [null]
  });

  currRtForm = this.fb.group({
    currencyCd: [null],
    histNo: [null, [Validators.required]],
    histNoDisp: [null],
    activeTag: ['A', [Validators.required]],
    currencyRt: [null, [Validators.required]],
    effDateFrom: [new Date(), [Validators.required]],
    remarks: [null],
    lastUpdateName: [null],
    lastUserUpdate: [null],
    mode: [null]
  });

  constructor(
    private _APICallService: APICallService, 
    public _Dialog: MatDialog, 
    private fb: FormBuilder, 
    public formService: FormService, 
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService
  ) { }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempAddedDataCurr!='' || this.tempUpdateDataCurr!=''
      || this.tempAddedDataCurrRt!='' || this.tempUpdateDataCurrRt!='') {
      return false;
    } else {
      return true;
    }
  }

  tblConfigCurr: any = {
    cols: [
      {
        key: "CURRENCY_CD",
        header: "Currency Code",
        dataType: "string",
        width: '25%'
      },
      {
        key: "CURRENCY_WORD",
        header: "Currency",
        dataType: "string",
        disabled: false
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        disabled: true,
        width: '64px'
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15
  }

  tblConfigCurrRt: any = {
    cols: [
      {
        key: "HIST_NO_DISP",
        header: "History No.",
        dataType: "string",
        width: '20%'
      },
      {
        key: "CURRENCY_RT",
        header: "Currency Rate",
        dataType: "rateDecimal",
        disabled: false,
        width: '26%'
      },
      {
        key: "EFF_DATE_FROM",
        header: "Effective Date From",
        dataType: "date",
        disabled: false
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        disabled: true,
        width: '64px'
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15
  }

  ngOnInit(): void {
    try {
      this.getAllCurrency();
      this.resetFormValuesCurr();
    }
    catch(e) {
      console.error(e);
    }
  }

  getAllCurrency() {
    try {
      this.tblConfigCurr.loading = true;
      this.jsonDataService.contorlLoading(true);
      this._APICallService.getCurrency({
          moduleId: this.moduleId,
          userId: this.userDetailService.userId,
          type: "MODULE"
      }).subscribe((data: any) => {
            this.securityService.checkRequestKeyResponse(data, () => {
              this.securityService.hasValidCsrfToken(data, () => {
                if (data.status === "SUCCESS") {
                    let content = JSON.parse(this.jsonDataService.decrypt(data.response));
                    this.jsonDataService.contorlLoading(false);
                    this.tblConfigCurr.loading = false;
                    this.tblConfigCurr.tblData = content.data;
                } else {
                    this.jsonDataService.contorlLoading(false);
                    this.tblConfigCurr.loading = false;
                    this.appMessageService.showAppMessage(data.message, "error");
                }
              });
            });
        });
    }
    catch (e) {
      console.error(e);
    }
  }

  getAllCurrencyRt() {
    try {
      this.tblConfigCurrRt.loading = true;
      this.jsonDataService.contorlLoading(true);
      this._APICallService.getCurrencyRt({
          moduleId: this.moduleId,
          userId: this.userDetailService.userId,
          type: "MODULE"
      }).subscribe((data: any) => {
            this.securityService.checkRequestKeyResponse(data, () => {
              this.securityService.hasValidCsrfToken(data, () => {
                if (data.status === "SUCCESS") {
                    data = JSON.parse(this.jsonDataService.decrypt(data.response));
                    let filteredData = data.filter((d: any) =>
                      (d.CURRENCY_CD == this.currForm.get('currencyCd')?.value.toUpperCase()));
                    this.jsonDataService.contorlLoading(false);
                    this.tblConfigCurrRt.loading = false;
                    this.tblConfigCurrRt.tblData = filteredData;
                    this.currRtForm.patchValue({
                      currencyCd: this.currForm.get('currencyCd')?.value
                    });
                } else {
                    this.jsonDataService.contorlLoading(false);
                    this.tblConfigCurrRt.loading = false;
                    this.appMessageService.showAppMessage(data.message, "error");
                }
              });
            });
        });
    }
    catch (e) {
      console.error(e);
    }
  }

  onRowClickCurr(ev: any) {
    try {    
      if(ev!=null) {
        this.rowClickedDataCurr = ev;
        this.currForm.get('currencyCd')?.disable();
        this.loadFormValuesCurr();
        this.saveDisabledCurr = this.saveCheckerCurr();
        this.deleteDisabledCurr = this.deleteCheckerCurr(ev);
        this.resetFormValuesCurrRt();
        this.tempAddedDataCurrRt = [];
        this.tempUpdateDataCurrRt = [];
        this.getAllCurrencyRt();
      }
      else {
        this.resetFormValuesCurr();
      }
    }
    catch(e) {
      console.error(e);
    }
  }

  onRowClickCurrRt(ev: any) {
    try {    
      if(ev!=null && this.isCurrSelected) {
        this.rowClickedDataCurrRt = ev;
        this.loadFormValuesCurrRt();
        this.saveDisabledCurrRt = this.saveCheckerCurrRt();
        this.deleteDisabledCurrRt = this.deleteCheckerCurrRt(ev);
      }
      else {
        this.resetFormValuesCurrRt();
      }
    }
    catch(e) {
      console.error(e);
    }
  }

  loadFormValuesCurr() {
    try {
      this.currForm.patchValue({
        currencyCd: this.rowClickedDataCurr.CURRENCY_CD,
        activeTag: this.rowClickedDataCurr.ACTIVE_TAG,
        currencyWord: this.rowClickedDataCurr.CURRENCY_WORD,
        currencyDesc: this.rowClickedDataCurr.CURRENCY_DESC,
        remarks: this.rowClickedDataCurr.REMARKS,
        lastUpdateName: this.rowClickedDataCurr.UPDATE_USER,   
        lastUserUpdate: this.rowClickedDataCurr.LAST_USER_UPDATE
      });
      this.isCurrSelected = true;
    }
    catch (e) {
      console.error(e);
    }
  }

  loadFormValuesCurrRt() {
    try {
      this.currRtForm.get('effDateFrom')?.disable();
      this.currRtForm.patchValue({
        currencyCd: this.currForm.get('currencyCd')?.value.toUpperCase(),
        histNo: this.rowClickedDataCurrRt.HIST_NO,
        histNoDisp: this.rowClickedDataCurrRt.HIST_NO_DISP,
        activeTag: this.rowClickedDataCurrRt.ACTIVE_TAG,
        currencyRt: parseFloat(this.rowClickedDataCurrRt.CURRENCY_RT).toFixed(9),
        effDateFrom: this.dateFilter(this.rowClickedDataCurrRt.EFF_DATE_FROM),
        remarks: this.rowClickedDataCurrRt.REMARKS,
        lastUpdateName: this.rowClickedDataCurrRt.UPDATE_USER,   
        lastUserUpdate: this.rowClickedDataCurrRt.LAST_USER_UPDATE
      });
      this.isCurrRtSelected = true;
    }
    catch (e) {
      console.error(e);
    }
  }

  resetFormValuesCurr() {
    try {
      this.currForm.get('currencyCd')?.enable();
      this.currForm.patchValue({
        currencyCd: null,
        activeTag: 'A',
        currencyWord: null,
        currencyDesc: null,
        remarks: null,
        lastUpdateName: null,
        lastUserUpdate: null
      });
      this.resetFormValuesCurrRt();
      this.tblConfigCurrRt.tblData = this.tblConfigCurrRt.tblData.filter(
        function(e: any) {
          return e.HIST_NO == 0;
        }
      );
      this.saveDisabledCurr = this.saveCheckerCurr();
      this.deleteDisabledCurr = true;
      this.isCurrSelected = false;
      this.rowClickedDataCurr = '' || null;
      this.rowClickedDataCurrRt = '' || null;
      this.formService.hideFormMsg("curr-error-message");
    }
    catch (e) {
      console.error(e);
    }
  }

  resetFormValuesCurrRt() {
    try {
      this.currRtForm.get('effDateFrom')?.enable();
      this.currRtForm.patchValue({
        currencyCd: null,
        histNo: null,
        histNoDisp: null,
        activeTag: 'A',
        currencyRt: null,
        effDateFrom: new Date(),
        remarks: null,
        lastUpdateName: null,
        lastUserUpdate: null
      });
      this.saveDisabledCurrRt = this.saveCheckerCurrRt();
      this.deleteDisabledCurrRt = true;
      this.isCurrRtSelected = false;
      this.rowClickedDataCurrRt = '' || null;
      this.formService.hideFormMsg("curr-rt-error-message");
      if (this.currForm.get('currencyCd')?.value == null || this.currForm.get('currencyCd')?.value == '') {
        this.currRtForm.disable();
      }
      else {
        this.currRtForm.enable();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  deleteCheckerCurr(ev: any) {
    try {
      let tempDataFlag;
      tempDataFlag = this.tempAddedDataCurr.filter(function(e: any) {
        return e.CURRENCY_CD==ev.CURRENCY_CD;
      });
      if(tempDataFlag==''||tempDataFlag==null) {
        return true;
      } else {
        return false;
      }
    }
    catch(e) {
      return true;
    }
  }

  deleteCheckerCurrRt(ev: any) {
    try {
      let tempDataFlag;
      let evEffDateFrom = this.datePipe.transform(new Date(ev.EFF_DATE_FROM), 'yyyy-MM-dd');
      tempDataFlag = this.tempAddedDataCurrRt.filter(function(e: any) {
        return e.EFF_DATE_FROM==evEffDateFrom;
      });
      if(tempDataFlag==''||tempDataFlag==null) {
        return true;
      } else {
        return false;
      }
    }
    catch(e) {
      return true;
    }
  }

  saveCheckerCurr() {
    try {
      if((this.tempAddedDataCurr==null || this.tempAddedDataCurr=='') && (this.tempUpdateDataCurr==null || this.tempUpdateDataCurr=='')) {
        return true;
      }
      else {
        return false;
      }
    }
    catch(e) {
      return true;
    }
  }

  saveCheckerCurrRt() {
    try {
      if((this.tempAddedDataCurrRt==null || this.tempAddedDataCurrRt=='') && (this.tempUpdateDataCurrRt==null || this.tempUpdateDataCurrRt=='')) {
        return true;
      }
      else {
        return false;
      }
    }
    catch(e) {
      return true;
    }
  }

  addCurrency() {
    try {
      this.validateInsertCurr();
      if (this.validated) {
        this.addFormToTableCurr();
      }
    }
    catch(e) {
      console.error(e);
    }
  }

  addFormToTableCurr() {
    try {
      let formToData: any = { // this.datePipe.transform(new Date(), 'MM/dd/yyyy')
        CURRENCY_CD: this.currForm.get('currencyCd')?.value.toUpperCase(),
        ACTIVE_TAG: this.currForm.get('activeTag')?.value,
        CURRENCY_WORD: this.currForm.get('currencyWord')?.value,
        CURRENCY_DESC: this.currForm.get('currencyDesc')?.value,
        REMARKS: this.currForm.get('remarks')?.value,
        UPDATE_USER: this.currentUser,
        MODE: 'I'
      }
      this.tblConfigCurr.tblData = [formToData, ...this.tblConfigCurr.tblData];
      this.tempAddedDataCurr.push(formToData);
      this.resetFormValuesCurr();
    }
    catch(e) {
      console.error(e);
    }
  }

  addCurrencyRt() {
    try {
      this.validateInsertCurrRt();
      if (this.validated) {
        this.addFormToTableCurrRt();
      }
    }
    catch(e) {
      console.error(e);
    }
  }

  addFormToTableCurrRt() {
    try {
      let formToScreen: any = { // this.datePipe.transform(new Date(), 'MM/dd/yyyy')
        CURRENCY_CD: this.currForm.get('currencyCd')?.value.toUpperCase(),
        ACTIVE_TAG: this.currRtForm.get('activeTag')?.value,
        CURRENCY_RT: parseFloat(this.currRtForm.get('currencyRt')?.value),
        EFF_DATE_FROM: this.datePipe.transform(new Date(this.currRtForm.get('effDateFrom')?.value), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
        REMARKS: this.currRtForm.get('remarks')?.value,
        UPDATE_USER: this.currentUser,
        MODE: 'I'
      }
      let formToData: any = { // this.datePipe.transform(new Date(), 'MM/dd/yyyy')
        CURRENCY_CD: this.currForm.get('currencyCd')?.value.toUpperCase(),
        ACTIVE_TAG: this.currRtForm.get('activeTag')?.value,
        CURRENCY_RT: parseFloat(this.currRtForm.get('currencyRt')?.value).toFixed(9),
        EFF_DATE_FROM: this.datePipe.transform(new Date(this.currRtForm.get('effDateFrom')?.value), 'yyyy-MM-dd'),
        REMARKS: this.currRtForm.get('remarks')?.value,
        UPDATE_USER: this.currentUser,
        MODE: 'I'
      }
      this.tblConfigCurrRt.tblData = [formToScreen, ...this.tblConfigCurrRt.tblData];
      this.tempAddedDataCurrRt.push(formToData);
      this.resetFormValuesCurrRt();
    }
    catch(e) {
      console.error(e);
    }
  }

  updateCurrency() {
    //this.noLoadingInProcess = false;
    //this.formService.showFormLoader(null, "generate-emp-form", "Please wait ...", null, null);
    try {
      this.validateUpdateCurr();
      if (this.validated) {
        this.updateFormTableCurr();
      }
    }
    catch(e) {
      console.error(e);
    }
  }

  updateFormTableCurr() {
    try {
      let tempInsertChecker = this.tempAddedDataCurr.find(
        (curr: { CURRENCY_CD: any; }) => curr.CURRENCY_CD == this.currForm.get('currencyCd')?.value);
      let tempUpdateChecker = this.tempUpdateDataCurr.find(
        (curr: { CURRENCY_CD: any; }) => curr.CURRENCY_CD == this.currForm.get('currencyCd')?.value);

      this.tableDataUpdateCurr();
      
      // Adds Updated Data that exists in the database to an object array for updated datas
      if(tempInsertChecker!=null && tempInsertChecker!='') {
        tempInsertChecker.CURRENCY_CD = this.currForm.get('currencyCd')?.value.toUpperCase();
        tempInsertChecker.ACTIVE_TAG = this.currForm.get('activeTag')?.value;
        tempInsertChecker.CURRENCY_WORD = this.currForm.get('currencyWord')?.value;
        tempInsertChecker.CURRENCY_DESC = this.currForm.get('currencyDesc')?.value;
        tempInsertChecker.REMARKS = this.currForm.get('remarks')?.value;
        tempInsertChecker.LAST_USER_UPDATE = this.datePipe.transform(new Date(this.currForm.get('lastUserUpdate')?.value), 'MM/dd/yyyy h:mm:ss a');
        tempInsertChecker.UPDATE_USER = this.currForm.get('lastUpdateName')?.value;
        tempInsertChecker.MODE = 'I';
      } 
      else if(tempUpdateChecker!=null && tempUpdateChecker!='') {
        tempUpdateChecker.CURRENCY_CD = this.currForm.get('currencyCd')?.value.toUpperCase();
        tempUpdateChecker.ACTIVE_TAG = this.currForm.get('activeTag')?.value;
        tempUpdateChecker.CURRENCY_WORD = this.currForm.get('currencyWord')?.value;
        tempUpdateChecker.CURRENCY_DESC = this.currForm.get('currencyDesc')?.value;
        tempUpdateChecker.REMARKS = this.currForm.get('remarks')?.value;
        tempUpdateChecker.LAST_USER_UPDATE = this.datePipe.transform(new Date(this.currForm.get('lastUserUpdate')?.value), 'MM/dd/yyyy h:mm:ss a');
        tempUpdateChecker.UPDATE_USER = this.currForm.get('lastUpdateName')?.value;
        tempUpdateChecker.MODE = 'U';
      }
      else {
        this.tempUpdateDataCurr.push(this.formValueToDataCurr());
      }
      this.resetFormValuesCurr();
    }
    catch(e) {
      console.error(e);
    }
  }

  tableDataUpdateCurr() {
    try {
      this.currForm.patchValue({
        activeTag: this.currForm.get('activeTag')?.value,
        lastUpdateName: this.currentUser,
        lastUserUpdate: new Date(),
      });
      let updateIndex = this.tblConfigCurr.tblData.indexOf(this.rowClickedDataCurr);
      this.tblConfigCurr.tblData[updateIndex] = {
        CURRENCY_CD: this.currForm.get('currencyCd')?.value.toUpperCase(),
        ACTIVE_TAG: this.currForm.get('activeTag')?.value,
        CURRENCY_WORD: this.currForm.get('currencyWord')?.value,
        CURRENCY_DESC: this.currForm.get('currencyDesc')?.value,
        REMARKS: this.currForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.currForm.get('lastUserUpdate')?.value), 'MM/dd/yyyy h:mm:ss a'),
        UPDATE_USER: this.currForm.get('lastUpdateName')?.value,
        MODE: 'U'
      };
      this.tblConfigCurr.tblData = [... this.tblConfigCurr.tblData];
    }
    catch(e) {
      console.error(e);
    }
  }

  formValueToDataCurr() {
    try {
      let formToData: any = { 
        CURRENCY_CD: this.currForm.get('currencyCd')?.value.toUpperCase(),
        ACTIVE_TAG: this.currForm.get('activeTag')?.value,
        CURRENCY_WORD: this.currForm.get('currencyWord')?.value,
        CURRENCY_DESC: this.currForm.get('currencyDesc')?.value,
        REMARKS: this.currForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.currForm.get('lastUserUpdate')?.value), 'MM/dd/yyyy h:mm:ss a'),
        UPDATE_USER: this.currForm.get('lastUpdateName')?.value,
        MODE: 'U'
      }
      return formToData;
    }
    catch(e) {
      console.error(e);
    }
  }

  updateCurrencyRt() {
    //this.noLoadingInProcess = false;
    //this.formService.showFormLoader(null, "generate-emp-form", "Please wait ...", null, null);
    try {
      this.validateUpdateCurrRt();
      if (this.validated) {
        this.updateFormTableCurrRt();
      }
    }
    catch(e) {
      console.error(e);
    }
  }

  updateFormTableCurrRt() {
    try {
      let tempInsertChecker = this.tempAddedDataCurrRt.find(
        (currRt: { EFF_DATE_FROM: any; }) => currRt.EFF_DATE_FROM == this.currRtForm.get('effDateFrom')?.value);
      let tempUpdateChecker = this.tempUpdateDataCurrRt.find(
        (currRt: { EFF_DATE_FROM: any; }) => new Date(currRt.EFF_DATE_FROM).getMonth()==new Date(this.currRtForm.get('effDateFrom')?.value).getMonth() 
          && new Date(currRt.EFF_DATE_FROM).getDate()==new Date(this.currRtForm.get('effDateFrom')?.value).getDate()
          && new Date(currRt.EFF_DATE_FROM).getFullYear()==new Date(this.currRtForm.get('effDateFrom')?.value).getFullYear());

      this.tableDataUpdateCurrRt();

      // Adds Updated Data that exists in the database to an object array for updated datas
      if(tempInsertChecker!=null && tempInsertChecker!='') {
        tempInsertChecker.ACTIVE_TAG = this.currRtForm.get('activeTag')?.value;
        tempInsertChecker.CURRENCY_RT = this.currRtForm.get('currencyRt')?.value;
        tempInsertChecker.REMARKS = this.currRtForm.get('remarks')?.value;
        tempInsertChecker.LAST_USER_UPDATE = this.datePipe.transform(new Date(this.currRtForm.get('lastUserUpdate')?.value), 'MM/dd/yyyy h:mm:ss a');
        tempInsertChecker.UPDATE_USER = this.currRtForm.get('lastUpdateName')?.value;
        tempInsertChecker.MODE = 'I';
      } 
      else if(tempUpdateChecker!=null && tempUpdateChecker!='') {
        tempUpdateChecker.ACTIVE_TAG = this.currRtForm.get('activeTag')?.value;
        tempUpdateChecker.CURRENCY_RT = this.currRtForm.get('currencyRt')?.value;
        tempUpdateChecker.REMARKS = this.currRtForm.get('remarks')?.value;
        tempUpdateChecker.LAST_USER_UPDATE = this.datePipe.transform(new Date(this.currRtForm.get('lastUserUpdate')?.value), 'MM/dd/yyyy h:mm:ss a');
        tempUpdateChecker.UPDATE_USER = this.currRtForm.get('lastUpdateName')?.value;
        tempUpdateChecker.MODE = 'U';
      }
      else {
        this.tempUpdateDataCurrRt.push(this.formValueToDataCurrRt());
      }
      this.resetFormValuesCurrRt();
    }
    catch(e) {
      console.error(e);
    }
  }

  tableDataUpdateCurrRt() {
    try {
      this.currRtForm.patchValue({
        activeTag: this.currRtForm.get('activeTag')?.value,
        currencyRt: parseFloat(this.currRtForm.get('currencyRt')?.value).toFixed(9),
        lastUpdateName: this.currentUser,
        lastUserUpdate: new Date(),
      });
      let updateIndex = this.tblConfigCurrRt.tblData.indexOf(this.rowClickedDataCurrRt);
      this.tblConfigCurrRt.tblData[updateIndex] = {
        CURRENCY_CD: this.currForm.get('currencyCd')?.value.toUpperCase(),
        HIST_NO: this.currRtForm.get('histNo')?.value,
        HIST_NO_DISP: this.currRtForm.get('histNoDisp')?.value,
        ACTIVE_TAG: this.currRtForm.get('activeTag')?.value,
        CURRENCY_RT: parseFloat(this.currRtForm.get('currencyRt')?.value),
        EFF_DATE_FROM: this.datePipe.transform(new Date(this.currRtForm.get('effDateFrom')?.value), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"),
        REMARKS: this.currRtForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.currRtForm.get('lastUserUpdate')?.value), 'MM/dd/yyyy h:mm:ss a'),
        UPDATE_USER: this.currRtForm.get('lastUpdateName')?.value,
        MODE: 'U'
      };
      this.tblConfigCurrRt.tblData = [... this.tblConfigCurrRt.tblData];
    }
    catch(e) {
      console.error(e);
    }
  }

  formValueToDataCurrRt() {
    try {
      let formToData: any = { 
        CURRENCY_CD: this.currForm.get('currencyCd')?.value.toUpperCase(),
        HIST_NO: this.currRtForm.get('histNo')?.value,
        HIST_NO_DISP: this.currRtForm.get('histNoDisp')?.value,
        ACTIVE_TAG: this.currRtForm.get('activeTag')?.value,
        CURRENCY_RT: this.currRtForm.get('currencyRt')?.value,
        EFF_DATE_FROM: this.datePipe.transform(new Date(this.currRtForm.get('effDateFrom')?.value), 'yyyy-MM-dd'),
        REMARKS: this.currRtForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.currRtForm.get('lastUserUpdate')?.value), 'MM/dd/yyyy h:mm:ss a'),
        UPDATE_USER: this.currRtForm.get('lastUpdateName')?.value,
        MODE: 'U'
      }
      return formToData;
    }
    catch(e) {
      console.error(e);
    }
  }

  deleteCurrency() {
    try {
      let clickData = this.rowClickedDataCurr;
      this.tblConfigCurr.tblData = this.tblConfigCurr.tblData.filter(
        function(e: any) {
          return e.CURRENCY_CD != clickData.CURRENCY_CD;
        }
      );
      this.tempAddedDataCurr = this.tempAddedDataCurr.filter(
        function(e: any) {
          return e.CURRENCY_CD != clickData.CURRENCY_CD;
        }
      );
      this.resetFormValuesCurr();
    }
    catch(e) {
      console.error(e);
    }
  }

  deleteCurrencyRt() {
    try {
      let clickData = this.rowClickedDataCurrRt;
      let cdEffDateFrom = this.datePipe.transform(new Date(clickData.EFF_DATE_FROM), 'yyyy-MM-dd');
      this.tblConfigCurrRt.tblData = this.tblConfigCurrRt.tblData.filter(
        function(e: any) {
          return e.EFF_DATE_FROM != clickData.EFF_DATE_FROM;
        }
      );
      this.tempAddedDataCurrRt = this.tempAddedDataCurrRt.filter(
        function(e: any) {
          return e.EFF_DATE_FROM != cdEffDateFrom;
        }
      );
      this.resetFormValuesCurrRt();
    }
    catch(e) {
      console.error(e);
    }
  }

  toSaveCurr() {
    try {
      this.tempDataCurr = [...this.tempAddedDataCurr, ...this.tempUpdateDataCurr];
      this.noLoadingInProcessCurr = false;
      this.formService.showFormLoader(null, "generate-curr-form", "Please wait ...", null, null);
      if(this.tempDataCurr.length!=0) {
        this.saveDataCurr();
      } 
      else {
        // ideally not gonna go here since save btn is disabled if no temp data
        this.getAllCurrency();
        this.resetFormValuesCurr();
      }
    }
    catch(e) {
      console.error(e);
    }
  }

  saveDataCurr () {
    this._APICallService.saveCurrency(this.tempDataCurr)
        .subscribe({
          next: (response : any) => {
                this.securityService.checkRequestKeyResponse(response, () => {
                  this.securityService.hasValidCsrfToken(response, () => {
                    if (response.status == "SUCCESS") {
                        this.tempDataCurr = [];
                        this.tempAddedDataCurr = [];
                        this.tempUpdateDataCurr = [];
                        this.isSuccessSaved = true;
                        this.getAllCurrency();
                        this.resetFormValuesCurr();
                        this.openDialog('SUCCESS', 'Currency details successfully saved'); 
                    } else {
                        this.openDialog('ERROR', 'An error has occured while saving details for new currency');
                    }
                  });
                });
          }, 
          error: () => {
            this.submitted = false;
            this.isSuccessSaved = false;
            this.openDialog('ERROR', 'An error has occured while saving details for new currency');
          }
        });
  }

  toSaveCurrRt() {
    try {
      this.tempDataCurrRt = [...this.tempAddedDataCurrRt, ...this.tempUpdateDataCurrRt];
      this.noLoadingInProcessCurrRt = false;
      this.formService.showFormLoader(null, "generate-curr-rt-form", "Please wait ...", null, null);
      if(this.tempDataCurrRt.length!=0) {
        this.saveDataCurrRt();
      } 
      else {
        // ideally not gonna go here since save btn is disabled if no temp data
        this.getAllCurrencyRt();
        this.resetFormValuesCurrRt();
      }
    }
    catch(e) {
      console.error(e);
    }
  }

  saveDataCurrRt () {
    this._APICallService.saveCurrencyRt(this.tempDataCurrRt)
        .subscribe({
          next: (response : any) => {
                this.securityService.checkRequestKeyResponse(response, () => {
                  this.securityService.hasValidCsrfToken(response, () => {
                    if (response.status == "SUCCESS") {
                        this.tempDataCurrRt = [];
                        this.tempAddedDataCurrRt = [];
                        this.tempUpdateDataCurrRt = [];
                        this.isSuccessSaved = true;
                        this.getAllCurrencyRt();
                        this.resetFormValuesCurrRt();
                        this.openDialog('SUCCESS', 'Currency rate details successfully saved'); 
                    } else {
                        this.openDialog('ERROR', 'An error has occured while saving details for new currency rate');
                    }
                  });
                });
          }, 
          error: () => {
            this.submitted = false;
            this.isSuccessSaved = false;
            this.openDialog('ERROR', 'An error has occured while saving details for new currency rate');
          }
        });
  }

  validateInsertCurr() {
    try {
      this.validated = false;
      if (!this.requiredFieldCheckCurr()){
        setTimeout(()=>{                           //10 secs countdown
          this.formService.hideFormMsg("curr-error-message");
        }, 10000);
      }
      else if (!this.currCdDupCheck()) {
        setTimeout(()=>{                           
          this.formService.hideFormMsg("curr-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  validateInsertCurrRt() {
    try {
      this.validated = false;
      if (!this.requiredFieldCheckCurrRt()){
        setTimeout(()=>{                          
          this.formService.hideFormMsg("curr-rt-error-message");
        }, 10000);
      }
      else if (!this.effDateFromMinCheck()) {
        setTimeout(()=>{                           
          this.formService.hideFormMsg("curr-rt-error-message");
        }, 10000);
      }
      else if (!this.effDateFromCheck()) {
        setTimeout(()=>{                           
          this.formService.hideFormMsg("curr-rt-error-message");
        }, 10000);
      }
      else if (!this.tempCurrCheck()) {
        setTimeout(()=>{                           
          this.formService.hideFormMsg("curr-rt-error-message");
        }, 10000);
      }
      else if (!this.currencyRtCheck()) {
        setTimeout(()=>{                           
          this.formService.hideFormMsg("curr-rt-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  validateUpdateCurr() {
    try {
      this.validated = false;
      if (!this.requiredFieldCheckCurr()){
        setTimeout(()=>{                          
          this.formService.hideFormMsg("curr-error-message");
        }, 10000);
      }
      else if (!this.requiredFieldCheckCurr()){
        setTimeout(()=>{                          
          this.formService.hideFormMsg("curr-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  validateUpdateCurrRt() {
    try {
      this.validated = false;
      if (!this.requiredFieldCheckCurrRt()){
        setTimeout(()=>{                          
          this.formService.hideFormMsg("curr-rt-error-message");
        }, 10000);
      }
      else if (!this.currencyRtCheck()) {
        setTimeout(()=>{                           
          this.formService.hideFormMsg("curr-rt-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  requiredFieldCheckCurr() {
    try {
      if(this.currForm.get('currencyCd')?.value?.trim()=='' || this.currForm.get('currencyCd')?.value?.trim()==null 
        || this.currForm.get('activeTag')?.value=='' || this.currForm.get('activeTag')?.value==null 
        || this.currForm.get('currencyWord')?.value?.trim()=='' || this.currForm.get('currencyWord')?.value?.trim()==null
        || this.currForm.get('currencyDesc')?.value?.trim()=='' || this.currForm.get('currencyDesc')?.value?.trim()==null) {
        this.formService.showFormMsg("curr-error-message", 'There are missing information. Please provide the necessary information needed.', "E");
        return false;
      }
      else {
        return true;
      }
    }
    catch (e) {
      console.error(e);
      return false;
    }
  }

  requiredFieldCheckCurrRt() {
    try {
      if(this.currRtForm.get('activeTag')?.value=='' || this.currRtForm.get('activeTag')?.value==null 
        || this.currRtForm.get('currencyRt')?.value=='' || this.currRtForm.get('currencyRt')?.value==null
        || this.currRtForm.get('effDateFrom')?.value=='' || this.currRtForm.get('effDateFrom')?.value==null) {
        this.formService.showFormMsg("curr-rt-error-message", 'There are missing information. Please provide the necessary information needed.', "E");
        return false;
      }
      else {
        return true;
      }
    }
    catch (e) {
      console.error(e);
      return false;
    }
  }

  currCdDupCheck() {
    try {
      let currCdForm = this.currForm.get('currencyCd')?.value.trim().toUpperCase() ?? "";
      this.currCdField = this.tblConfigCurr.tblData.filter(function(e: any) {
        return e.CURRENCY_CD.trim().toUpperCase()==currCdForm;
      });
      if(this.currCdField=='' || this.currCdField==null) {
        return true;
      } else {
        this.formService.showFormMsg("curr-error-message", 'Unable to add record. Currency Code already exists. Please add a unique Currency Code.', "E");
        return false;
      }
    }
    catch (e) {
      console.error(e);
      return false;
    }
  }

  effDateFromCheck() {
    let effDateFromTemp = new Date(this.currRtForm.get('effDateFrom')?.value);
    this.effDateFromField = this.tblConfigCurrRt.tblData.filter(function(e: any) {
      return (new Date(e.EFF_DATE_FROM).getMonth()==effDateFromTemp.getMonth() && new Date(e.EFF_DATE_FROM).getDate()==effDateFromTemp.getDate() && new Date(e.EFF_DATE_FROM).getFullYear()==effDateFromTemp.getFullYear());
    });
    if(this.effDateFromField=='' || this.effDateFromField==null) {
      return true;
    } else {
      this.formService.showFormMsg("curr-rt-error-message", 'Unable to add record at the entered Effective Date From. Effective Dates cannot be concurrent. Please add a different Effective Date From', "E");
      return false;
    }
  }

  effDateFromMinCheck() {
    let effDateFromTemp = this.datePipe.transform(new Date(this.currRtForm.get('effDateFrom')?.value), 'yyyy-MM-dd');
    let dateToday = this.datePipe.transform(new Date(this.minEffDate), 'yyyy-MM-dd');
    if (dateToday && effDateFromTemp) {
      if(dateToday <= effDateFromTemp) {
        return true;
      } else {
        this.formService.showFormMsg("curr-rt-error-message", 'Unable to add record at the entered Effective Date From. Please add a different Effective Date From', "E");
        return false;
      }
    } else {
      this.formService.showFormMsg("curr-rt-error-message", 'Unable to add record at the entered Effective Date From. Please add a different Effective Date From', "E");
      return false;
    }
    
  }

  tempCurrCheck() {
    try {
      if(this.tempAddedDataCurr.length!=0 && !this.deleteDisabledCurr) {
        this.formService.showFormMsg("curr-rt-error-message", 'Kindly save first the Currency Code details above before adding a Currency Rate History.', "E");
        return false;
      }
      else {
        return true;
      }
    }
    catch (e) {
      console.error(e);
      return false;
    }
  }

  currencyRtCheck() {
    let resultCheck: boolean = true;
    if (parseFloat(this.formService.setToNumberWithDecimal(this.currRtForm.get('currencyRt')?.value)) > 999.999999999) {
      this.formService.showFormMsg("curr-rt-error-message", 'Invalid value in Currency Rate. The value entered exceeded the maximum limit.', "E");
      resultCheck = false;
    }
    else {
      resultCheck = true;
    }
    return resultCheck;
  }

  openDialog(title: any, message: any) {
    try {
      this.noLoadingInProcessCurr = true;
      this.noLoadingInProcessCurrRt = true;
      this.formService.hideFormLoader("generate-curr-form");
      this.formService.hideFormLoader("generate-curr-rt-form");
      this._Dialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        } 
      });
    } catch(e) {
      console.error(e);
    }
  }

  dateFilter(dateString: any) {
    try {
      if (dateString=='' || dateString==null) {
        return '';
      }
      else if (dateString=='0000-00-00' || dateString=='0000-00-00 12:00:00 AM' || dateString=='1970-01-01') {
        return '';
      }
      else {
        return formatDate(dateString, 'yyyy-MM-dd', 'en');
      }
    }
    catch(e) {
      return '';
    }
  }

  rateFormat(){
    if(this.currRtForm.get('currencyRt')?.value!=='' && this.currRtForm.get('currencyRt')?.value!==null){
      let fieldFloat = parseFloat(this.currRtForm.get('currencyRt')?.value.replace(/,/g, ''))
      let fieldRate = fieldFloat.toFixed(9);
      if(isNaN(fieldFloat)||fieldRate=='NaN'){
        this.currRtForm.get('currencyRt')?.setValue('');
      }
      else{
        this.currRtForm.get('currencyRt')?.setValue(fieldRate);
      }
    }
  }

  autoUppercase(ev: any) {
    try{
      ev.target.value = ev.target.value.toUpperCase();
    }
    catch(e) {
      console.error(e);
    }
  }

  allowNumericDigitsOnly(event: any) {
    try {
      if (/[0-9.]/.test(String.fromCharCode(event.keyCode))) {
        return true;
      }
      else {
        event.preventDefault();
        return false;
      }
    }
    catch (e) {
      console.error(e);
      return undefined;
    }
  }

  allowNumericAndSlashOnly(event: any) {
    try {
      if (/^([0-9/]+)$/.test(String.fromCharCode(event.keyCode))) {
        return true;
      }
      else {
        event.preventDefault();
        return false;
      }
    }
    catch (e) {
      console.error(e);
      return undefined; 
    }
  }

}
