import { DatePipe } from '@angular/common';
import { Component, OnInit, Type } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';
import { PlanLovComponent } from '../../common/plan-lov/plan-lov.component';
import { AccountTypeLovComponent } from '../../common/acc-type-lov/acc-type-lov.component';
import { PaymentOptionsLovComponent } from '../../common/payt-opt-lov/payt-opt-lov.component';
import { CheckLovComponent } from 'src/app/components/common/guard/check-lov/check-lov.component';
import { CustomValidators } from 'src/app/utils/custom-validator';

interface RowData {
    refCodes: any
}

@Component({
    selector: 'reference-codes-maintenance',
    templateUrl: './reference-codes-maintenance.html',
    styleUrls: ['./reference-codes-maintenance.css']
})
export class ReferenceCodesMaintenanceComponent implements OnInit, LeaveConfirmation {

    moduleId: string = 'BMM012';
    public moduleName: string = "";
    toBeDeleted: any = [];

    constructor(
        private fb: FormBuilder,
        private _matDialog: MatDialog,
        private _api: APICallService,
        private _formService: FormService,
        private _jsonDS: JsonDataService,
        private _securityService: SecurityService,
        private _userDetailService: UserDetailsService,
        private _appMessageService: AppMessageService
    ) {
        this.userIdLoggedIn = this._userDetailService.userId || "";
    }

    ngOnInit(): void {
        this.moduleName = this._jsonDS.data.moduleList.filter((data: any) => { return data.moduleId === this.moduleId })[0].moduleDesc.toUpperCase();
        this.setDisabledFields("enable");
        this.getData.refCodes();
    }

    canDeactivate(): boolean | Observable<boolean> {
        return this.tblConfig.refCodes.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true)).length > 0 ? false : true;
    }

    test() {
        //console.table(this.forms.refCodesForm.value);
    }

    /* Boolean Stuffs */

    public btns = {
        refCodes: {
            updateDisabled: true,
            deleteDisabled: true,
            saveDisabled: true,
            addDisabled: false
        }
    }

    public showForm = {
        refCodes: true
    }

    /* Data Stuffs */

    private rowData: RowData = {
        refCodes: null
    }

    private errorMessage: RowData = {
        refCodes: null
    }

    private userIdLoggedIn: string;

    /* Form Object  */

    public forms = {
        refCodesForm: this.fb.group({
            //domain: ['', [this.checkDuplicateLValOnDomain('refCodes'), Validators.required, Validators.maxLength(100)]],
            domain: [{ value: '', disabled: 'true' }],
            meaning: ['', [Validators.required, Validators.maxLength(100), CustomValidators.requiredTrim]],
            lVal: [{ value: '', disabled: 'true' }],
            //lVal: ['', [this.checkDuplicateLVal('refCodes'), Validators.required, Validators.maxLength(240)]],
            hVal: ['', [Validators.maxLength(240)]],
            abbreviation: ['', [Validators.maxLength(240)]],
            lastUpdateUser: [{ value: '', disabled: 'true' }],
            lastUpdate: [{ value: '', disabled: 'true' }]
        }),
        resetRefCodesForm: () => {
            this.forms.refCodesForm.reset();
            this.setDisabledFields("enable");
            this.btns.refCodes.addDisabled = false;
            this.btns.refCodes.deleteDisabled = true;
            this.btns.refCodes.updateDisabled = true;
            this._formService.hideFormMsg("refCodes-error-message");
            this.rowData.refCodes = null;
        },
        resetAllForms: function () {
            this.resetRefCodesForm();
        }
    };

    /* Table Object */

    public tblConfig: RowData = {
        refCodes: {
            cols: [
                {
                    key: "RV_LOW_VALUE",
                    header: "Low Value",
                    dataType: "string",
                    width: "20%"
                },
                {
                    key: "RV_HIGH_VALUE",
                    header: "High Value",
                    dataType: "string",
                    width: "20%"
                },
                {
                    key: "RV_ABBREVIATION",
                    header: "Abbreviation",
                    dataType: "string",
                    width: "20%"
                },
                {
                    key: "RV_DOMAIN",
                    header: "Domain",
                    dataType: "string",
                    width: "20%"
                },
                {
                    key: "RV_MEANING",
                    header: "Meaning",
                    dataType: "string",
                    width: "20%"
                }
            ],
            tblData: [] as any[],
            selection: "single",
            paginator: true,
            rowsPerPage: 10,
            lazy: false,
            totalRecords: 15,
            loading: false,
        },
    }

    private formManipulation = {
        refCodes: {
            dbToForm: function (data: any) {
                return {
                    domain: data.RV_DOMAIN,
                    meaning: data.RV_MEANING,
                    lVal: data.RV_LOW_VALUE,
                    hVal: data.RV_HIGH_VALUE,
                    abbreviation: data.RV_ABBREVIATION,
                    lastUpdateUser: data.LAST_USER,
                    lastUpdate: data.LAST_USER_UPDATE
                };
            },
            formToDb: function (data: any) {
                return {
                    RV_DOMAIN: data.domain,
                    RV_MEANING: data.meaning,
                    RV_LOW_VALUE: data.lVal,
                    RV_HIGH_VALUE: data.hVal,
                    RV_ABBREVIATION: data.abbreviation,
                    LAST_USER: null,
                    LAST_USER_UPDATE: null,
                };
            },
        }
    }

    setDisabledFields (enable: string) {
        if (enable === 'enable') {
            this.forms.refCodesForm.get("domain")?.enable();
            this.forms.refCodesForm.get("lVal")?.enable();
            this.forms.refCodesForm.controls["domain"].setValidators([this.checkDuplicateLValOnDomain('refCodes'), Validators.required, Validators.maxLength(100), CustomValidators.requiredTrim]);
            this.forms.refCodesForm.controls["lVal"].setValidators([this.checkDuplicateLVal('refCodes'), Validators.required, Validators.maxLength(240), CustomValidators.requiredTrim]);
        } else {
            this.forms.refCodesForm.get("domain")?.disable();
            this.forms.refCodesForm.get("lVal")?.disable();
        }

        this.forms.refCodesForm.controls["domain"].updateValueAndValidity();
        this.forms.refCodesForm.controls["lVal"].updateValueAndValidity();
    }

    private getData = {
        refCodes: () => { //recode pa
            try {
                this.tblConfig.refCodes.tblData = [];
                this.tblConfig.refCodes.loading = true;
                this._jsonDS.contorlLoading(true);
                this._api.getRefCodes({
                    moduleId: this.moduleId,
                    userId: this._userDetailService.userId,
                    type: "MODULE"
                }).subscribe({
                    next: (response: any) => {
                        this._securityService.checkRequestKeyResponse(response, () => {
                            this._securityService.hasValidCsrfToken(response, () => {
                                try {
                                    if (response.status === 'SUCCESS') {
                                        response = JSON.parse(this._jsonDS.decrypt(response.response));
                                        this._jsonDS.contorlLoading(false);
                                        let filteredData = response.data;
                                        this.tblConfig.refCodes.tblData = filteredData;
                                        this.toBeDeleted = [];
                                    } else {
                                        this.tblConfig.refCodes.loading = false;
                                        this._jsonDS.contorlLoading(false);
                                        this.toBeDeleted = [];
                                        this._appMessageService.showAppMessage(response.message, "error");
                                    }
                                } catch (e) {
    console.error(e);
                                }
                                this.tblConfig.refCodes.loading = false;
                            });
                        });
                    }, error: (e: any) => {
                        this.tblConfig.refCodes.loading = false;
                        this._jsonDS.contorlLoading(false);
                    }
                });
            } catch (e) {
    console.error(e);
                this.tblConfig.refCodes.loading = false;
                this._jsonDS.contorlLoading(false);
            }
        }
    }

    public onRowClick = {
        refCodes: (ev: any) => {
            try {
                this.setDisabledFields("disable");
                ev.update = true;
                this.rowData.refCodes = ev;
                if (ev != null) {
                    this.populateFormFromTable.refCodes();
                    this.btns.refCodes.deleteDisabled = false;
                    this.btns.refCodes.addDisabled = false;
                } else {
                    this.forms.resetRefCodesForm();    
                    this.btns.refCodes.deleteDisabled = true;
                    this.btns.refCodes.addDisabled = false;
                }
                this._formService.hideFormMsg("refCodes-error-message");
            } catch (e) {
    console.error(e);
                this.forms.resetRefCodesForm();
                this.rowData.refCodes = null;
                this.setDisabledFields("enable");
            }
        }
    }

    private populateFormFromTable = {
        refCodes: () => {
            try {
                let data = this.rowData.refCodes;
                this.btns.refCodes.updateDisabled = !data.update;
                this.btns.refCodes.deleteDisabled = !data.temp;
                this.forms.refCodesForm.patchValue(this.formManipulation.refCodes.dbToForm(data));
            } catch (e) {
    console.error(e);
            }
        }
    }

    public onAdd = {
        refCodes: () => {
            try {
                this._formService.hideFormMsg("refCodes-error-message");
                if (this.forms.refCodesForm.valid) {
                    let rowToBeAdded: { [key: string]: any } = this.formManipulation.refCodes.formToDb(this.forms.refCodesForm.value);
                    var del = this.toBeDeleted.map(function (row:any) { return row.RV_DOMAIN && row.RV_LOW_VALUE; }).indexOf(rowToBeAdded.RV_DOMAIN && rowToBeAdded.RV_LOW_VALUE);
                    if (del != -1) {
                        this.toBeDeleted.splice(del,1);
                    }
                    rowToBeAdded.temp = true;
                    this.tblConfig.refCodes.tblData = [rowToBeAdded, ...this.tblConfig.refCodes.tblData];
                    this.forms.resetRefCodesForm();
                    this.btns.refCodes.saveDisabled = false;
                    this.forms.refCodesForm.markAsDirty();
                } else {
                    this.showErrorValidator.refCodes();
                }
            } catch (e) {
    console.error(e);
            }
        }
    }

    public onDelete = {
        refCodes: () => {
            try {
                this.tblConfig.refCodes.tblData = this.tblConfig.refCodes.tblData.filter((d: any) => {
                    return d !== this.rowData.refCodes;
                });

                this.toBeDeleted.push(this.rowData.refCodes);
                this.forms.resetRefCodesForm();
                this.btns.refCodes.saveDisabled = false;
                this.forms.refCodesForm.markAsDirty();
            } catch (e) {
    console.error(e);
            }
        }
    }

    public onUpdate = {
        refCodes: () => {
            try {
                this._formService.hideFormMsg("refCodes-error-message");
                if (this.forms.refCodesForm.valid) {
                    this.setDisabledFields("enable");
                    let toBeUpdatedIndex = this.tblConfig.refCodes.tblData.indexOf(this.rowData.refCodes);
                    let rowToBeUpdated: { [key: string]: any } = this.formManipulation.refCodes.formToDb(this.forms.refCodesForm.value);
                    
                    if (this.rowData.refCodes.temp) {
                        rowToBeUpdated.temp = true;
                    } else {
                        rowToBeUpdated.onDbButUpdatedTemp = true;
                    }
                    this.forms.resetRefCodesForm();
                    this.tblConfig.refCodes.tblData[toBeUpdatedIndex] = rowToBeUpdated;
                    this.btns.refCodes.saveDisabled = false;
                    this.tblConfig.refCodes.tblData = [... this.tblConfig.refCodes.tblData];
                    
                } else {
                    this.showErrorValidator.refCodes();
                }
            } catch (e) {
    console.error(e);
            }
        }
    }

    public onSave = {
        refCodes: () => {
            this.showForm.refCodes = false;
            this._formService.showFormLoader(null, "refCodes-maintenance-loading", "Saving.<br>Please wait ...", null, null);
            let toBeSaved = this.tblConfig.refCodes.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true));
            if (toBeSaved.length > 0 || this.toBeDeleted.length > 0) {
                toBeSaved.map((d: any) => {
                    d.LAST_USER = this.userIdLoggedIn;
                });
                this._api.saveRefCodes({
                    tobeSaved: toBeSaved,
                    tobeDeleted: this.toBeDeleted
                }).subscribe({
                    next: (response: any) => {
                        this._securityService.checkRequestKeyResponse(response, () => {
                            this._securityService.hasValidCsrfToken(response, () => {
                                if (response.status === 'SUCCESS') {
                                    this.onComplete.refCodes();
                                } else { 
                                    this.onComplete.refCodesError();
                                }
                            });
                        });
                    }, error: (response: any) => {
                        this.onComplete.refCodesError();
                    },
                });
            } else {
                this.onComplete.refCodes();
            }
        }
    }

    openDialog(title: string, contentTitle: string, contentDetail: string) {
        try {
            this._matDialog.open(DialogMsgComponent, {
                disableClose: true,
                width: '512px',
                data: {
                title: title,
                content:
                    title === 'SUCCESS'
                    ? `${contentTitle} details successfully ${contentDetail}!`
                    : `${contentTitle} details ${contentDetail} failed!`,
                },
            });
        } catch (e) {
    console.error(e);
        }
    }

    private onComplete = {
        refCodes: () => {
            this.getData.refCodes();
            this.btns.refCodes.saveDisabled = true;
            this.showForm.refCodes = true;
            this.toBeDeleted = [];
            this._formService.hideFormLoader("refCodes-maintenance-loading");
            this.openDialog('SUCCESS', 'Reference Codes', 'saved');
            this.setDisabledFields("enable");
            this.forms.refCodesForm.markAsPristine();
        },
        refCodesError: () => {
            this.showForm.refCodes = true;
            this.btns.refCodes.saveDisabled = false;
            this.openDialog('FAILED', 'Reference Codes', 'saving');
            this._formService.hideFormLoader("refCodes-maintenance-loading");
        }
    }

    /* Validator Stuffs Down here */

    /*checkDuplicatePlanName(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (!value) return null;
        let hasDuplicate: boolean = this.tblConfig.paytOpt.tblData.filter((d: any) => {
            if (!this.rowData.paytOpt)
            return d.PLAN_NAME === value;
            else
            return this.rowData.paytOpt.PLAN_NAME !== value && d.PLAN_NAME === value;
        }).length > 0 ? true : false;
        return hasDuplicate ? { hasDupe: true } : null;
        }
    }

    checkDuplicatePeril(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (!value) return null;
        let hasDuplicate: boolean = this.tblConfig.planPeril.tblData.filter((d: any) => {
            if (!this.rowData.planPeril)
            return d.PERIL_CD === value;
            else
            return this.rowData.planPeril.PERIL_CD !== value && d.PERIL_CD === value;
        }).length > 0 ? true : false;
        return hasDuplicate ? { hasDupe: true } : null;
        }
    } */
    checkDuplicateLValOnDomain(module: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value === null ? control.value : control.value.trim();;
            if (!value) {
                return null;
            } 
            let hasDuplicate: boolean = this.tblConfig[module as keyof RowData].tblData.filter((d: any) => {
                if (!this.rowData[module as keyof RowData]) {
                    return d.RV_DOMAIN.toUpperCase() == value.toUpperCase()
                           && d.RV_LOW_VALUE.toUpperCase() == this.forms.refCodesForm.get('lVal')?.value.toUpperCase();
                } else {
                    return d.RV_DOMAIN.toUpperCase() == value.toUpperCase() 
                           && this.rowData[module as keyof RowData].RV_DOMAIN.toUpperCase() == value.toUpperCase()
                           && d.RV_LOW_VALUE.toUpperCase() == this.forms.refCodesForm.get('lVal')?.value.toUpperCase()
                           && this.rowData[module as keyof RowData].RV_LOW_VALUE.toUpperCase() != this.forms.refCodesForm.get('lVal')?.value.toUpperCase();
                }
            }).length > 0 ? true : false;
            return hasDuplicate ? { hasDupeMeaning: true } : null;
        }
    }

    checkDuplicateLVal(module: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value === null ? control.value : control.value.trim();;
            if (!value) {
                return null;
            } 
            let hasDuplicate: boolean = this.tblConfig[module as keyof RowData].tblData.filter((d: any) => {
                if (!this.rowData[module as keyof RowData]) {
                    return d.RV_DOMAIN.toUpperCase() == this.forms.refCodesForm.get('domain')?.value.toUpperCase() 
                           && d.RV_LOW_VALUE.toUpperCase() == value.toUpperCase();
                } else {
                    return d.RV_DOMAIN.toUpperCase() == this.forms.refCodesForm.get('domain')?.value.toUpperCase() 
                           && this.rowData[module as keyof RowData].RV_DOMAIN.toUpperCase() == this.forms.refCodesForm.get('domain')?.value.toUpperCase()
                           && d.RV_LOW_VALUE.toUpperCase() == value.toUpperCase()
                           && this.rowData[module as keyof RowData].RV_LOW_VALUE.toUpperCase() != value.toUpperCase();
                }
            }).length > 0 ? true : false;
            return hasDuplicate ? { hasDupeLVal: true } : null;
        }
    }

    public showErrorValidator = {
        refCodes: () => {
            try {
                Object.keys(this.forms.refCodesForm.controls).forEach(key => {
                    const controlErrors = this.forms.refCodesForm.get(key)?.errors;
                    if (controlErrors != null) {
                        Object.keys(controlErrors).forEach(keyError => {
                            if (keyError == 'hasDupeLVal' && controlErrors[keyError] === true)
                                this.errorMessage.refCodes = "Low Value under the entered Domain already exists. Please add a unique value of Low Value for the entered Domain.";
                            else if (keyError == 'required' && controlErrors[keyError] === true)
                                this.errorMessage.refCodes = "There are missing information. Please provide necessary information needed.";
                        });
                    }
                });
                this._formService.showFormMsg("refCodes-error-message", this.errorMessage.refCodes, "E");
            } catch (e) {
    console.error(e);
            }
        }
    }
}
