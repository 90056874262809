import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[alphanumeric]'
})
export class AlphanumericInputDirective {

  constructor(
    private control: NgControl,
    private el: ElementRef
  ) { }

  @Input() withSpace: boolean = false;

  @Input() withSpecialCharacters: boolean = false;

  ngAfterViewInit(): void {
    this.control.valueChanges?.subscribe(data => {
      let start = this.el.nativeElement.selectionStart;
      let end = this.el.nativeElement.selectionEnd;
      this.control.control?.setValue(String(data || '').toUpperCase(), { emitEvent: false });
      this.el.nativeElement.setSelectionRange(start, end);
    })
  }

  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste'
  ];

  @HostListener("textInput", ["$event"])
  onKeyDown(e: any) {
    if (
      (this.withSpace && e.data == ' ')             // Allow space
      || (this.withSpecialCharacters)                 // Allow all characters
    ) {
      // let it happen, don't do anything
      return;
    }

    // Ensure that it is a number and stop the keypress
    if (!e.data.match(/[a-zA-Z0-9]/g)) {
      e.preventDefault();
    }
  }

  @HostListener("paste", ["$event"])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    let pastedInput = '';
    if (!this.withSpecialCharacters) {
      pastedInput = String(this.withSpace ? event.clipboardData?.getData("text/plain").replace(/[^a-zA-Z0-9\s]/g, "") : event.clipboardData?.getData("text/plain").replace(/[^a-zA-Z0-9]/g, "")).toUpperCase();
    } else {
      pastedInput = String(event.clipboardData?.getData("text/plain")).toUpperCase();
    }

    pastedInput && document.execCommand("insertText", false, pastedInput);
  }

}
