import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { ClientGroupLovDialog } from '../../common/client-group-lov-dialog/client-group-lov-dialog.component';
import { ContractTypeLovDialogComponent } from '../../common/contract-type-lov/contract-type-lov.component';
import { DeductibleLovDialog } from '../../common/deductible-lov-dialog/deductible-lov-dialog.component';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';

@Component({
    selector: 'deductible-maintenance-per-client-group',
    templateUrl: './deductible-maintenance-per-client-group.component.html',
    styleUrls: ['./deductible-maintenance-per-client-group.component.css']
})
export class DeductibleMaintenancePerClientGroupComponent implements OnInit, LeaveConfirmation {

    datePipe = new DatePipe('en-us');

    moduleId: string = "BMM138";
    currentUser: string = this.userDetailsService.userId || "";

    sublineDisabled: Boolean = true;
    deductibleDisabled: Boolean = false;
    saveDisabled: Boolean = true;
    deleteDisabled: Boolean = true;
    isLineSelected: Boolean = false;
    validated: Boolean = false;
    formHider: Boolean = false;

    rowClickedData: any;

    deductibleTblData: any = [];
    tempAddedData: any = [];
    tempUpdatedData: any = [];

    paramForm = this.fb.group({
        clientGroupCd: [null],
        clientGroupName: [null],
        clientGroupField: [null],
        contractTypeCd: [null],
        contractTypeName: [null],
        contractTypeField: [null],
        lineCd: [null],
        lineName: [null],
        lineField: [null],
        sublineCd: [null],
        sublineName: [null],
        sublineField: [null]
    });

    deductibleForm = this.fb.group({
        deductibleCd: [null],
        deductibleName: [null],
        deductibleField: [null],
        activeTag: ['A'],
        deductibleText: [null],
        deductibleType: [null],
        rangeSwitch: [null],
        deductibleRate: [null],
        deductibleAmt: [null],
        minAmt: [null],
        maxAmt: [null],
        remarks: [null],
        lastUpdateUser: [null],
        lastUpdate: [null]
    });

    deductibleTblConfig: any = {
        cols: [
            {
                key: "DEDUCTIBLE_CD",
                header: "Deductible Code",
                dataType: "string",
            },
            {
                key: "DEDUCTIBLE_TITLE",
                header: "Deductible",
                dataType: "string",
            },
            {
                key: "DEDUCTIBLE_TYPE",
                header: "Deductible Type",
                dataType: "string",
                disabled: false,
            },
            {
                key: "DEDUCTIBLE_RATE",
                header: "Deductible Rate",
                dataType: "rateDecimal",
                disabled: false,
            },
            {
                key: "DEDUCTIBLE_AMT",
                header: "Deductible Amount",
                dataType: "currency",
                disabled: false,
            },
            {
                key: "ACTIVE_TAG",
                header: "A",
                dataType: "checkbox",
                disabled: true,
                width: '64px'
            }
        ],
        tblData: [],
        selection: "single",
        paginator: true,
        rowsPerPage: 10,
        lazy: false,
        totalRecords: 15,
        loading: false
    }

    constructor(
        private fb: FormBuilder,
        private jsonDataService: JsonDataService,
        private apiCallService: APICallService,
        private userDetailsService: UserDetailsService,
        private appMessageService: AppMessageService,
        private formService: FormService,
        private _Dialog: MatDialog,
        private securityService: SecurityService
    ) { }

    ngOnInit(): void {
        try {
            this.getDeductiblePerClientGroup();
        } catch (e) {
    console.error(e);
        }
    }

    canDeactivate(): Observable<boolean> | boolean {
        if (this.tempAddedData != '' || this.tempUpdatedData != '') {
            return false;
        } else {
            return true;
        }
    }

    initOnGet(): void {
        try {
            this.onRowClick(null);
            this.saveDisabled = this.saveChecker();
            this.filterTblData();
        } catch (e) {
    console.error(e);
        }
    }

    public selections = {
        activeTag: [
            { cd: 'A', name: 'Yes' },
            { cd: 'I', name: 'No' },
        ],
        dedTypes: [
            ...this.getDeductibleTypes()
        ],
        rangeSwitches: [
            ...this.getRangeSwitches()
        ]
    }

    async getDeductiblePerClientGroup() {
        try {
            this.deductibleTblConfig.loading = true;
            this.jsonDataService.contorlLoading(true);
            this.apiCallService.getDeductiblePerClientGroup({
                moduleId: this.moduleId,
                userId: this.userDetailsService.userId,
                type: "MODULE"
            }).subscribe((response: any) => {
                this.securityService.checkRequestKeyResponse(response, () => {
                    this.securityService.hasValidCsrfToken(response, () => {
                        if (response.status === "SUCCESS") {
                            response = JSON.parse(this.jsonDataService.decrypt(response.response));
                            this.jsonDataService.contorlLoading(false);
                            this.deductibleTblData = response;
                            this.deductibleTblConfig.loading = false;
                        } else {
                            this.jsonDataService.contorlLoading(false);
                            this.deductibleTblConfig.loading = false;
                            this.appMessageService.showAppMessage(response.message, "error");
                        }
                    });
                });
            });
        } catch (e) {
    console.error(e);
        }
    }

    filterTblData() {
        try {
            this.deductibleTblConfig.tblData = this.deductibleTblData.data.filter((d: any) =>
            (d.CLIENT_CD == this.paramForm.get('clientGroupCd')?.value
                && d.CONTRACT_TYPE_CD == this.paramForm.get('contractTypeCd')?.value
                && d.LINE_CD == this.paramForm.get('lineCd')?.value
                && d.SUBLINE_CD == this.paramForm.get('sublineCd')?.value));
            this.insertTempAddedData(this.deductibleTblConfig.tblData);
            this.changeTempUpdatedData(this.deductibleTblConfig.tblData);
        } catch (e) {
    console.error(e);
        }
    }

    getDeductibleTypes() {
        try {
            const dedTypes = this.jsonDataService.data.refCds.filter((data: any) => data.rvDomain === 'DEDUCTIBLE_TYPE').map((data: any) => { return { cd: data.rvLowValue, name: data.rvMeaning } });
            return dedTypes;
        } catch (e) {
    console.error(e);
        }
    }

    getRangeSwitches() {
        try {
            const rangeSwitches = this.jsonDataService.data.refCds.filter((data: any) => data.rvDomain === 'RANGE_SW').map((data: any) => { return { cd: data.rvLowValue, name: data.rvMeaning } });
            return rangeSwitches;
        } catch (e) {
    console.error(e);
        }
    }

    public openLov(lovToOpen: string) {
        try {
            const LOVOPTIONS: { [key: string]: any } = {
                disableClose: true,
                width: '512px',
                data: {
                    table: lovToOpen,
                    moduleId: this.moduleId
                },
            }
            switch (lovToOpen.toUpperCase()) {
                case "CLIENT_GROUP":
                    this._Dialog.open(ClientGroupLovDialog, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
                        let output = dataOutput.content;
                        this.paramForm.get('clientGroupCd')?.setValue(output.CLIENT_CD == null || output.CLIENT_CD == '' ? '' : output.CLIENT_CD);
                        this.paramForm.get('clientGroupName')?.setValue(output.CLIENT_CD == null || output.CLIENT_CD == '' ? '' : output.CLIENT_DESC);
                        this.paramForm.get('clientGroupField')?.setValue(output.CLIENT_CD == null || output.CLIENT_CD == '' ? '' : output.CLIENT_CD + " - " + output.CLIENT_DESC);
                        this.discardTempData();
                        this.initOnGet();
                        this.deductibleFieldDataClearer();
                    });
                    break;
                case "CONTRACT_TYPE":
                    this._Dialog.open(ContractTypeLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
                        let output = dataOutput.content;
                        this.paramForm.get('contractTypeCd')?.setValue(output.CONTRACT_TYPE_CD == null || output.CONTRACT_TYPE_CD == '' ? '' : output.CONTRACT_TYPE_CD);
                        this.paramForm.get('contractTypeName')?.setValue(output.CONTRACT_TYPE_CD == null || output.CONTRACT_TYPE_CD == '' ? '' : output.CONTRACT_TYPE_DESC);
                        this.paramForm.get('contractTypeField')?.setValue(output.CONTRACT_TYPE_CD == null || output.CONTRACT_TYPE_CD == '' ? '' : output.CONTRACT_TYPE_CD + " - " + output.CONTRACT_TYPE_DESC);
                        this.discardTempData();
                        this.initOnGet();
                        this.deductibleFieldDataClearer();
                    });
                    break;
                case "LINE":
                    this._Dialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
                        let output = dataOutput.content;
                        if (output) {
                            this.sublineDisabled = false;
                            this.paramForm.patchValue({
                                lineCd: output.LINE_CD,
                                lineName: output.LINE_NAME,
                                lineField: `${output.LINE_CD} - ${output.LINE_NAME}`,
                                sublineCd: null,
                                sublineName: null,
                                sublineField: null
                            });
                            this.deductibleForm.patchValue({
                                deductibleCd: null,
                                deductibleName: null,
                                deductibleField: null
                            });
                        } else {
                            this.sublineDisabled = true;
                            this.paramForm.patchValue({
                                lineCd: null,
                                lineName: null,
                                lineField: null,
                                sublineCd: null,
                                sublineName: null,
                                sublineField: null
                            });
                            this.deductibleForm.patchValue({
                                deductibleCd: null,
                                deductibleName: null,
                                deductibleField: null
                            });
                        }
                        this.discardTempData();
                        this.initOnGet();
                        this.deductibleFieldDataClearer();
                    });
                    break;
                case "SUBLINE":
                    LOVOPTIONS.data.lineCd = this.paramForm.get('lineCd')?.value;
                    this._Dialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
                        let output = dataOutput.content;
                        if (output) {
                            this.paramForm.patchValue({
                                sublineCd: output.SUBLINE_CD,
                                sublineName: output.SUBLINE_NAME,
                                sublineField: `${output.SUBLINE_CD} - ${output.SUBLINE_NAME}`
                            });
                            this.deductibleForm.patchValue({
                                deductibleCd: null,
                                deductibleName: null,
                                deductibleField: null
                            });
                        } else {
                            this.paramForm.patchValue({
                                sublineCd: null,
                                sublineName: null,
                                sublineField: null,
                            });
                            this.deductibleForm.patchValue({
                                deductibleCd: null,
                                deductibleName: null,
                                deductibleField: null
                            });
                        }
                        this.discardTempData();
                        this.initOnGet();
                        this.deductibleFieldDataClearer();
                    });
                    break;
                case "DEDUCTIBLE":
                    LOVOPTIONS.data.lineCd = this.paramForm.get('lineCd')?.value;
                    LOVOPTIONS.data.sublineCd = this.paramForm.get('sublineCd')?.value;
                    this._Dialog.open(DeductibleLovDialog, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
                        let output = dataOutput.content;
                        this.deductibleForm.get('deductibleCd')?.setValue(output.DEDUCTIBLE_CD == null || output.DEDUCTIBLE_CD == '' ? '' : output.DEDUCTIBLE_CD);
                        this.deductibleForm.get('deductibleName')?.setValue(output.DEDUCTIBLE_CD == null || output.DEDUCTIBLE_CD == '' ? '' : output.DEDUCTIBLE_TITLE);
                        this.deductibleForm.get('deductibleField')?.setValue(output.DEDUCTIBLE_CD == null || output.DEDUCTIBLE_CD == '' ? '' : output.DEDUCTIBLE_CD + " - " + output.DEDUCTIBLE_TITLE);
                    });
                    break;
            }
        } catch (e) {
    console.error(e);
        }
    }

    onRowClick(ev: any) {
        try {
            if (ev != null) {
                this.rowClickedData = ev;
                this.deductibleDisabled = true;
                this.isLineSelected = true;
                this.deleteDisabled = this.deleteChecker(ev);
                this.deductibleFieldDataLoader(ev);
            } else {
                this.rowClickedData = null;
                this.deductibleDisabled = false;
                this.isLineSelected = false;
                this.deleteDisabled = true;
                this.deductibleFieldDataClearer();
            }
        } catch (e) {
    console.error(e);
        }
    }

    deductibleFieldDataLoader(ev: any) {
        try {
            let deductibleRate: string = '';
            let deductibleAmt: string= '';
            let minAmt: string= '';
            let maxAmt: string= '';
            
            if(ev.DEDUCTIBLE_RATE!==''&&ev.DEDUCTIBLE_RATE!==null){
                deductibleRate = (+ev.DEDUCTIBLE_RATE).toFixed(9)+'';
            }
            if(ev.DEDUCTIBLE_AMT!==''&&ev.DEDUCTIBLE_AMT!==null){
                deductibleAmt = (+ev.DEDUCTIBLE_AMT).toFixed(2)+'';
            }
            if(ev.MIN_AMT!==''&&ev.MIN_AMT!==null){
                minAmt = (+ev.MIN_AMT).toFixed(2)+'';
            }
            if(ev.MAX_AMT!==''&&ev.MAX_AMT!==null){
                maxAmt = (+ev.MAX_AMT).toFixed(2)+'';
            }
            
            this.deductibleForm.patchValue({
                deductibleCd: ev.DEDUCTIBLE_CD,
                deductibleName: ev.DEDUCTIBLE_TITLE,
                deductibleField: `${ev.DEDUCTIBLE_CD} - ${ev.DEDUCTIBLE_TITLE}`,
                activeTag: ev.ACTIVE_TAG,
                deductibleText: ev.DEDUCTIBLE_TEXT,
                deductibleType: ev.DEDUCTIBLE_TYPE,
                rangeSwitch: ev.RANGE_SW,
                deductibleRate: deductibleRate,
                deductibleAmt: deductibleAmt,
                minAmt: minAmt,
                maxAmt: maxAmt,
                remarks: ev.REMARKS,
                lastUpdateUser: ev.USER_ID
            });
            if (ev.MODE == 'I' || ev.MODE == 'U') {
                this.deductibleForm.get('lastUpdate')?.setValue(this.datePipe.transform(ev.LAST_UPDATE, 'MM/dd/yyyy hh:mm:ss a'));
            } else {
                this.deductibleForm.get('lastUpdate')?.setValue(this.datePipe.transform(ev.LAST_UPDATE, 'MM/dd/yyyy hh:mm:ss a', '+0000'));
            }
        } catch (e) {
            console.error(e);
        }
    }

    deductibleFieldDataClearer() {
        try {
            this.deductibleForm.patchValue({
                deductibleCd: null,
                deductibleName: null,
                deductibleField: null,
                activeTag: 'A',
                deductibleText: null,
                deductibleType: null,
                rangeSwitch: null,
                deductibleRate: null,
                deductibleAmt: null,
                minAmt: null,
                maxAmt: null,
                remarks: null,
                lastUpdateUser: null,
                lastUpdate: null
            });
        } catch (e) {
    console.error(e);
        }
    }

    insertLine() {
        try {
            this.validateInsert();
            if (this.validated) {
                this.addFormToTable();
                this.initOnGet();
            }
        } catch (e) {
    console.error(e);
        }
    }

    validateInsert() {
        try {
            this.validated = false;
            if (!this.requiredFieldCheck()) {
                setTimeout(() => {
                    this.formService.hideFormMsg("error-message");
                }, 10000);
            } else if (this.duplicateCheck(this.deductibleTblConfig.tblData)) {
                setTimeout(() => {
                    this.formService.hideFormMsg("error-message");
                }, 10000);
            } else if (this.rateCheck()) {
                setTimeout(() => {
                    this.formService.hideFormMsg("error-message");
                }, 10000);
            } else if (this.numberCheck('deductibleAmt') || this.numberCheck('minAmt') || this.numberCheck('maxAmt')) {
                setTimeout(() => {
                    this.formService.hideFormMsg("error-message");
                }, 10000);
            } else {
                this.validated = true;
            }
        } catch (e) {
    console.error(e);
        }
    }

    addFormToTable() {
        try {
            let fieldDeductibleRate = this.deductibleForm.get('deductibleRate')?.value?+this.deductibleForm.get('deductibleRate')?.value:null;
            let fieldDeductibleAmt= this.deductibleForm.get('deductibleAmt')?.value?+this.deductibleForm.get('deductibleAmt')?.value:null;
            let formToData: any = {
                CLIENT_CD: this.paramForm.get('clientGroupCd')?.value,
                CONTRACT_TYPE_CD: this.paramForm.get('contractTypeCd')?.value,
                LINE_CD: this.paramForm.get('lineCd')?.value,
                SUBLINE_CD: this.paramForm.get('sublineCd')?.value,
                DEDUCTIBLE_CD: this.deductibleForm.get('deductibleCd')?.value,
                DEDUCTIBLE_TITLE: this.deductibleForm.get('deductibleName')?.value,
                ACTIVE_TAG: this.deductibleForm.get('activeTag')?.value,
                DEDUCTIBLE_TEXT: this.deductibleForm.get('deductibleText')?.value,
                DEDUCTIBLE_TYPE: this.deductibleForm.get('deductibleType')?.value,
                RANGE_SW: this.deductibleForm.get('rangeSwitch')?.value,
                DEDUCTIBLE_RATE: fieldDeductibleRate,
                DEDUCTIBLE_AMT: fieldDeductibleAmt,
                MIN_AMT: this.deductibleForm.get('minAmt')?.value,
                MAX_AMT: this.deductibleForm.get('maxAmt')?.value,
                REMARKS: this.deductibleForm.get('remarks')?.value,
                USER_ID: this.currentUser,
                LAST_UPDATE: new Date,
                MODE: 'I'
            }
            this.deductibleTblConfig.tblData.unshift(formToData);
            this.tempAddedData.push(formToData);
            this.appMessageService.showAppMessage("Details added successfully!", "success");
        } catch (e) {
    console.error(e);
        }
    }

    updateLine() {
        try {
            this.validateUpdate();
            if (this.validated) {
                this.updateFormTable();
                this.initOnGet();
            }
        } catch (e) {
    console.error(e);
        }
    }

    validateUpdate() {
        try {
            this.validated = false;
            if (!this.requiredFieldCheck()) {
                setTimeout(() => {
                    this.formService.hideFormMsg("error-message");
                }, 10000);
            } else if (this.rateCheck()) {
                setTimeout(() => {
                    this.formService.hideFormMsg("error-message");
                }, 10000);
            } else if (this.numberCheck('deductibleAmt') || this.numberCheck('minAmt') || this.numberCheck('maxAmt')) {
                setTimeout(() => {
                    this.formService.hideFormMsg("error-message");
                }, 10000);
            } else {
                this.validated = true;
            }
        } catch (e) {
    console.error(e);
        }
    }

    updateFormTable() {
        try {
            let rowIndex = this.deductibleTblConfig.tblData.indexOf(this.rowClickedData);
            let fieldDeductibleRate = this.deductibleForm.get('deductibleRate')?.value?+this.deductibleForm.get('deductibleRate')?.value:null;
            let fieldDeductibleAmt= this.deductibleForm.get('deductibleAmt')?.value?+this.deductibleForm.get('deductibleAmt')?.value:null;
            this.deductibleTblConfig.tblData[rowIndex] = {
                CLIENT_CD: this.paramForm.get('clientGroupCd')?.value,
                CONTRACT_TYPE_CD: this.paramForm.get('contractTypeCd')?.value,
                LINE_CD: this.paramForm.get('lineCd')?.value,
                SUBLINE_CD: this.paramForm.get('sublineCd')?.value,
                DEDUCTIBLE_CD: this.deductibleForm.get('deductibleCd')?.value,
                DEDUCTIBLE_TITLE: this.deductibleForm.get('deductibleName')?.value,
                ACTIVE_TAG: this.deductibleForm.get('activeTag')?.value,
                DEDUCTIBLE_TEXT: this.deductibleForm.get('deductibleText')?.value,
                DEDUCTIBLE_TYPE: this.deductibleForm.get('deductibleType')?.value,
                RANGE_SW: this.deductibleForm.get('rangeSwitch')?.value,
                DEDUCTIBLE_RATE: fieldDeductibleRate,
                DEDUCTIBLE_AMT: fieldDeductibleAmt,
                MIN_AMT: this.deductibleForm.get('minAmt')?.value,
                MAX_AMT: this.deductibleForm.get('maxAmt')?.value,
                REMARKS: this.deductibleForm.get('remarks')?.value,
                USER_ID: this.currentUser,
                LAST_UPDATE: new Date,
                MODE: 'U'
            }
            this.tempUpdatedData.push(this.deductibleTblConfig.tblData[rowIndex]);
            this.appMessageService.showAppMessage("Details updated!", "success");
        } catch (e) {
    console.error(e);
        }
    }

    deleteLine() {
        try {
            let clickData = this.rowClickedData;
            this.deductibleTblConfig.tblData = this.deductibleTblConfig.tblData.filter(
                function (e: any) {
                    return e.DEDUCTIBLE_CD != clickData.DEDUCTIBLE_CD;
                }
            );
            this.tempAddedData = this.tempAddedData.filter(
                function (e: any) {
                    return e.CLIENT_CD != clickData.CLIENT_CD
                        || e.CONTRACT_TYPE_CD != clickData.CONTRACT_TYPE_CD
                        || e.LINE_CD != clickData.LINE_CD
                        || e.SUBLINE_CD != clickData.SUBLINE_CD
                        || e.DEDUCTIBLE_CD != clickData.DEDUCTIBLE_CD;
                }
            );
            this.tempUpdatedData = this.tempUpdatedData.filter(
                function (e: any) {
                    return e.CLIENT_CD != clickData.CLIENT_CD
                        || e.CONTRACT_TYPE_CD != clickData.CONTRACT_TYPE_CD
                        || e.LINE_CD != clickData.LINE_CD
                        || e.SUBLINE_CD != clickData.SUBLINE_CD
                        || e.DEDUCTIBLE_CD != clickData.DEDUCTIBLE_CD;
                }
            );
            this.isLineSelected = false;
            this.deleteDisabled = true;
            this.deductibleDisabled = false;
            this.saveDisabled = this.saveChecker();
            this.deductibleFieldDataClearer();
        } catch (e) {
    console.error(e);
        }
    }

    deleteChecker(ev: any) {
        try {
            let tempDataFlag = this.tempAddedData.filter(function (e: any) {
                let returnValue = e.CLIENT_CD == ev.CLIENT_CD
                    && e.CONTRACT_TYPE_CD == ev.CONTRACT_TYPE_CD
                    && e.LINE_CD == ev.LINE_CD
                    && e.SUBLINE_CD == ev.SUBLINE_CD
                    && e.DEDUCTIBLE_CD == ev.DEDUCTIBLE_CD;
                return returnValue;
            });
            if (tempDataFlag == '' || tempDataFlag == null) {
                return true;
            } else {
                return false;
            }
        } catch (e) {
    console.error(e);
            return true;
        }
    }

    saveChanges() {
        try {
            this.formHider = true;
            this.formService.showFormLoader(null, "generate-line-form", "Please wait ...", null, null);
            let tempChangeData = this.tempAddedData.concat(this.tempUpdatedData);
            if (tempChangeData.length != 0) {
                for(let item of tempChangeData){
                    item.DEDUCTIBLE_RATE = item.DEDUCTIBLE_RATE==0?item.DEDUCTIBLE_RATE.toString():item.DEDUCTIBLE_RATE?item.DEDUCTIBLE_RATE.toString():null;
                    item.DEDUCTIBLE_AMT = item.DEDUCTIBLE_AMT==0?item.DEDUCTIBLE_AMT.toString():item.DEDUCTIBLE_AMT?item.DEDUCTIBLE_AMT.toString():null;
                }
                this.saveData(tempChangeData);
                this.initOnGet();
            }
        } catch (e) {
    console.error(e);
        }
    }

    saveData(tempChangeData: any[]) {
        try {
            this.apiCallService.saveDeductiblePerClientGroup(tempChangeData).subscribe({
                next: (response: any) => {
                    this.securityService.checkRequestKeyResponse(response, () => {
                        this.securityService.hasValidCsrfToken(response, () => {
                            if (response.status == "SUCCESS") {
                                this.openDialog('SUCCESS', 'Details Successfully saved!');
                                this.discardTempData();
                                this.saveDisabled = this.saveChecker();
                                this.formHider = false;
                                this.getDeductiblePerClientGroup();
                            } else {
                                this.openDialog('ERROR', 'An error has occured while saving details');
                            }
                        });
                    });
                }, error: () => {
                    this.openDialog('ERROR', 'An error has occured while saving details');
                }
            });
        } catch (e) {
    console.error(e);
        }
    }

    saveChecker() {
        try {
            if ((this.tempAddedData == null || this.tempAddedData == '')
                && (this.tempUpdatedData == null || this.tempUpdatedData == '')) {
                return true;
            } else {
                return false;
            }
        } catch (e) {
    console.error(e);
            return true;
        }
    }

    insertTempAddedData(data: any) {
        try {
            if (this.tempAddedData.length > 0) {
                for (let addedData = 0; addedData < this.tempAddedData.length; addedData++) {
                    if (this.tempAddedData[addedData].CLIENT_CD == this.paramForm.get('clientGroupCd')?.value
                        && this.tempAddedData[addedData].CONTRACT_TYPE_CD == this.paramForm.get('contractTypeCd')?.value
                        && this.tempAddedData[addedData].LINE_CD == this.paramForm.get('lineCd')?.value
                        && this.tempAddedData[addedData].SUBLINE_CD == this.paramForm.get('sublineCd')?.value) {
                        data.unshift(this.tempAddedData[addedData]);
                    }
                }
            }
            return data;
        } catch (e) {
    console.error(e);
        }
    }

    changeTempUpdatedData(data: any) {
        try {
            if (this.tempUpdatedData.length > 0) {
                for (let updatedData = 0; updatedData < this.tempUpdatedData.length; updatedData++) {
                    if (this.tempUpdatedData[updatedData].CLIENT_CD == this.paramForm.get('clientGroupCd')?.value
                        && this.tempUpdatedData[updatedData].CONTRACT_TYPE_CD == this.paramForm.get('contractTypeCd')?.value
                        && this.tempUpdatedData[updatedData].LINE_CD == this.paramForm.get('lineCd')?.value
                        && this.tempUpdatedData[updatedData].SUBLINE_CD == this.paramForm.get('sublineCd')?.value) {
                        for (let d = 0; d < data.length; d++) {
                            if (this.tempUpdatedData[updatedData].DEDUCTIBLE_CD == data[d].DEDUCTIBLE_CD) {
                                data[d] = this.tempUpdatedData[updatedData];
                            }
                        }
                    }
                }
            }
            return data;
        } catch (e) {
    console.error(e);
        }
    }

    openDialog(title: any, message: any) {
        try {
            this.formService.hideFormLoader("generate-line-form");
            this._Dialog.open(DialogMsgComponent, {
                disableClose: true,
                width: "500px",
                data: {
                    title: title,
                    content: message
                }
            });
        } catch (e) {
    console.error(e);
        }
    }

    requiredFieldCheck() {
        try {
            if (this.paramForm.get('clientGroupField')?.value == '' || this.paramForm.get('clientGroupField')?.value == null
                || this.paramForm.get('contractTypeField')?.value == '' || this.paramForm.get('contractTypeField')?.value == null
                || this.paramForm.get('lineField')?.value == '' || this.paramForm.get('lineField')?.value == null
                || this.paramForm.get('sublineField')?.value == '' || this.paramForm.get('sublineField')?.value == null
                || this.deductibleForm.get('deductibleField')?.value == '' || this.deductibleForm.get('deductibleField')?.value == null
                || this.deductibleForm.get('activeTag')?.value == '' || this.deductibleForm.get('activeTag')?.value == null) {
                this.formService.showFormMsg("error-message", 'There are missing information. Please provide the necessary information needed.', "E");
                return false;
            } else {
                return true;
            }
        } catch (e) {
    console.error(e);
            return false;
        }
    }

    duplicateCheck(tblData: any) {
        try {
            let filteredData = tblData.filter((d: any) =>
            (d.CLIENT_CD == this.paramForm.get('clientGroupCd')?.value
                && d.CONTRACT_TYPE_CD == this.paramForm.get('contractTypeCd')?.value
                && d.LINE_CD == this.paramForm.get('lineCd')?.value
                && d.SUBLINE_CD == this.paramForm.get('sublineCd')?.value
                && d.DEDUCTIBLE_CD == this.deductibleForm.get('deductibleCd')?.value));
            if (filteredData.length == 0) {
                return false;
            } else {
                this.formService.showFormMsg("error-message", 'Deductible Code already exists. Please enter a different deductible.', "E");
                return true;
            }
        } catch (e) {
    console.error(e);
            return true;
        }
    }

    rateCheck() {
        let rate = parseFloat(this.deductibleForm.get('deductibleRate')?.value);
        let deductibleRate = this.deductibleForm.get('deductibleRate')?.value;
        let fieldLabel = 'Deductible Rate';
        if (deductibleRate == '' || deductibleRate == null) {
            return false
        }
        if (rate < 0) {
            this.formService.showFormMsg("error-message", 'Invalid value in ' + fieldLabel + ' field. The value entered is a negative number', "E");
            return true;
        }
        if (rate < 1000) {
            return false
        }
        else {
            this.formService.showFormMsg("error-message", 'Invalid value in ' + fieldLabel + ' field. The value entered exceeded the maximum limit', "E");
            return true
        }
    }

    numberCheck(fieldName: string) {
        let fieldFloat = parseFloat(this.deductibleForm.get(fieldName)?.value);
        let fieldAmt = this.deductibleForm.get(fieldName)?.value;
        let fieldLabel = '';
        if (fieldName == 'deductibleAmt') {
            fieldLabel = 'Deductible Amount'
        }
        else if (fieldName == 'minAmt') {
            fieldLabel = 'Minimum Amount'
        }
        else if (fieldName == 'maxAmt') {
            fieldLabel = 'Maximum Amount'
        }
        if (fieldAmt == '' || fieldAmt == null) {
            return false
        }
        if (fieldFloat < 0) {
            this.formService.showFormMsg("error-message", 'Invalid value in ' + fieldLabel + ' field. The value entered is a negative number', "E");
            return true;
        }
        if (fieldAmt.length > 17) {
            this.formService.showFormMsg("error-message", 'Invalid value in ' + fieldLabel + ' field. The value entered exceeded the maximum limit', "E");
            return true
        }
        else {
            return false
        }
    }

    deductibleRateFormat() {
        if (this.deductibleForm.get("deductibleRate")?.value !== '' && this.deductibleForm.get("deductibleRate")?.value !== null) {
            let deductibleFloat = parseFloat(this.deductibleForm.get("deductibleRate")?.value.replace(/,/g, ''))
            let deductibleRate = deductibleFloat.toFixed(9);
            if (isNaN(deductibleFloat) || deductibleRate == 'NaN') {
                this.deductibleForm.get("deductibleRate")?.setValue('');
            } else {
                this.deductibleForm.get("deductibleRate")?.setValue(deductibleRate);
            }
        }
    }

    amtFormat(fieldName: string) {
        if (this.deductibleForm.get(fieldName)?.value !== '' && this.deductibleForm.get(fieldName)?.value !== null) {
            let fieldFloat = parseFloat(this.deductibleForm.get(fieldName)?.value.replace(/,/g, ''))
            let fieldAmt = fieldFloat.toFixed(2);
            if (isNaN(fieldFloat) || fieldAmt == 'NaN') {
                this.deductibleForm.get(fieldName)?.setValue('');
            } else {
                this.deductibleForm.get(fieldName)?.setValue(fieldAmt);
            }
        }
    }

    discardTempData() {
        this.tempAddedData = [];
        this.tempUpdatedData = [];
    }
}