import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[date-input]'
})
export class DateInputDirective {

  constructor(
  ) { }

  @HostListener("textInput", ["$event"])
  onKeyDown(e: any) {
    if (!e.data.match(/[0-9//]/g)) {
      e.preventDefault();
    }
  }

  @HostListener("paste", ["$event"])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    let pastedInput = String(event.clipboardData?.getData("text/plain").replace(/[^0-9//]/g, ""));
    pastedInput && document.execCommand("insertText", false, pastedInput);
  }

}
