import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';

@Component({
  selector: 'earthquake-zone-maintenance',
  templateUrl: './earthquake-zone-maintenance.component.html',
  styleUrls: ['./earthquake-zone-maintenance.component.css']
})
export class EarthquakeZoneMaintenanceComponent implements OnInit, LeaveConfirmation {

  private moduleId: string = 'BMM141';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc?.toUpperCase() ?? "";

  constructor(
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    public _formService: FormService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService
  ) { 
    this.userIdLoggedIn = this._userDetailService.userId ?? "";
  }

  ngOnInit(): void {
    this.getData.eqZone();
  }

  canDeactivate(): boolean | Observable<boolean> {
    if(this.tempData!='') {
      return false;
    } else {
      return true;
    }
  }

  public btns = {
    eqZone: {
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      addDisabled: true,
    },
  }

  public selections = {
    activeTypes: [
      { cd: 'A', name: 'Yes' },
      { cd: 'I', name: 'No' },
    ]
  }

  public showForm: boolean = true;

  private rowData: any;
  private tempDataRow: any;
  private errorMessage: string = "";
  private userIdLoggedIn: string;
  private tempData: any = [];

  public forms = {
    eqZoneForm: this.fb.group({
      eqZoneCd: [null],
      eqZoneCdDisp: [{ value: '', disabled: 'true' }],
      eqZoneShortDesc: [null, [Validators.required, Validators.maxLength(25), this.checkDuplicateEqZoneShortDesc(), this.noWhiteSpaceCheck()]],
      eqZoneLongDesc: [null, [Validators.required, Validators.maxLength(1000), this.noWhiteSpaceCheck()]],
      activeTag: ['A', [Validators.required]],
      bmCode: [null, [Validators.required, Validators.maxLength(8), this.noWhiteSpaceCheck()]],
      remarks: [null, [Validators.maxLength(4000)]],
      userID: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }],
    }),
    resetEqZoneForm: () => {
      this.forms.eqZoneForm.reset();
      this.forms.eqZoneForm.patchValue({
        activeTag: 'A'
      });
      this.btns.eqZone.updateDisabled = this.btns.eqZone.deleteDisabled = true;
      this._formService.hideFormMsg("eq-zone-error-message");
      // this.rowData = null;
    }
  }

  private eqZoneFormManipulate = {
    dbToForm: (data: any) => {
      return {
        eqZoneCd: data.EQ_ZONE_CD,
        eqZoneCdDisp: data.EQ_ZONE_CD_DISP,
        eqZoneShortDesc: data.EQ_ZONE_SHORT_DESC,
        eqZoneLongDesc: data.EQ_ZONE_LONG_DESC,
        activeTag: data.ACTIVE_TAG,
        bmCode: data.BM_CODE,
        remarks: data.REMARKS,
        userID: data.USER_ID,
        lastUpdate: data.LAST_UPDATE,
      };
    },
    formToDb: (data: any, mode: any) => {
      return {
        EQ_ZONE_CD: data.eqZoneCd,
        EQ_ZONE_CD_DISP: data.eqZoneCd?.toString().padStart(2, 0) ?? null,
        EQ_ZONE_SHORT_DESC: data.eqZoneShortDesc.trim(),
        EQ_ZONE_LONG_DESC: data.eqZoneLongDesc.trim(),
        ACTIVE_TAG: data.activeTag,
        BM_CODE: data.bmCode,
        REMARKS: data.remarks,
        USER_ID: this.userIdLoggedIn,
        LAST_UPDATE: null,
        MODE: mode
      };
    },
  }

  public eqZoneTblConfig = {
    cols: [
      {
        key: "EQ_ZONE_CD_DISP",
        header: "EQ Zone Code",
        dataType: "string",
        width: '16%'
      },
      {
        key: "EQ_ZONE_SHORT_DESC",
        header: "EQ Zone Short Description",
        dataType: "string",
        width: '30%'
      },
      {
        key: "EQ_ZONE_LONG_DESC",
        header: "EQ Zone Long Description",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        width: '64px'
      }
    ],
    tblData: [] as any[],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  private getData = {
    eqZone: () => {
      try {
        this.eqZoneTblConfig.tblData = [];
        this.eqZoneTblConfig.loading = true;
        this._jDS.contorlLoading(true);
        this._api.getEarthquakeZone({
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          type: "MODULE"
        }).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                let content = JSON.parse(this._jDS.decrypt(data.response));
                this._jDS.contorlLoading(false);
                this.eqZoneTblConfig.tblData = content.data;
                this.btns.eqZone.addDisabled = false;
                this.eqZoneTblConfig.loading = false;
              });
            });
          },
          error: (error: any) => {
            this.eqZoneTblConfig.loading = false;
            this._jDS.contorlLoading(false);
          }
        });
      }
      catch (e) {
        console.error(e);
      }
    }
  }

  public onRowClick(ev: any) {
    try {
      ev.update = true;
      this.rowData = ev;
      this.populateFormFromTable();
    }
    catch (e) {
      this.forms.resetEqZoneForm();
      this.rowData = null;
      //this.indexRow = null;
    }
  }

  private populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.eqZone.updateDisabled = !data.update;
      this.btns.eqZone.deleteDisabled = !data.temp;
      this.tempDataRow = !data.temp;
      this.forms.eqZoneForm.patchValue(this.eqZoneFormManipulate.dbToForm(data));
    }
    catch (e) {
      console.error(e);
    }
  }

  public onAdd() {
    try {
      this._formService.hideFormMsg("eq-zone-error-message");
      if (this.forms.eqZoneForm.valid) {
        let rowToBeAdded: { [key: string]: any } = this.eqZoneFormManipulate.formToDb(this.forms.eqZoneForm.value, 'I');
        rowToBeAdded.temp = true;
        this.tempData.push(rowToBeAdded);
        this.eqZoneTblConfig.tblData = [rowToBeAdded, ...this.eqZoneTblConfig.tblData];
        this.forms.resetEqZoneForm();
        this.btns.eqZone.saveDisabled = false;
      }
      else {
        this.showErrorValidator.eqZone();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public onDelete() {
    try {
      let clickData = this.rowData;
      this.eqZoneTblConfig.tblData = this.eqZoneTblConfig.tblData.filter((d: any) => {
        return d !== this.rowData;
      });
      this.tempData = this.tempData.filter(
        function(e: any) {
          return e.EQ_ZONE_SHORT_DESC != clickData.EQ_ZONE_SHORT_DESC || e.EQ_ZONE_LONG_DESC != clickData.EQ_ZONE_LONG_DESC;
        }
      );
      this.forms.resetEqZoneForm();
    }
    catch (e) {
      console.error(e);
    }
  }

  public onUpdate() {
    try {
      this._formService.hideFormMsg("eq-zone-error-message");
      
      if (this.forms.eqZoneForm.valid) {
        let clickData = this.rowData;
        let toBeUpdatedIndex = this.eqZoneTblConfig.tblData.indexOf(this.rowData);
        let eqZoneCdRowValue = this.rowData.EQ_ZONE_CD;
        let rowToBeUpdated: { [key: string]: any } = this.eqZoneFormManipulate.formToDb(this.forms.eqZoneForm.value, 'U');
        let tempInsertChecker = this.tempData.find(
          (j: { EQ_ZONE_SHORT_DESC: any, EQ_ZONE_LONG_DESC: any; }) => j.EQ_ZONE_SHORT_DESC == clickData.EQ_ZONE_SHORT_DESC && j.EQ_ZONE_LONG_DESC == clickData.EQ_ZONE_LONG_DESC && clickData.MODE == 'I');
        let tempUpdateChecker = this.tempData.find(
          (j: { EQ_ZONE_SHORT_DESC: any, EQ_ZONE_LONG_DESC: any; }) => j.EQ_ZONE_SHORT_DESC == clickData.EQ_ZONE_SHORT_DESC && j.EQ_ZONE_LONG_DESC == clickData.EQ_ZONE_LONG_DESC && clickData.MODE == 'U');
        if (this.rowData.temp) {
          rowToBeUpdated.temp = true;
        }
        else {
          rowToBeUpdated.onDbButUpdatedTemp = true;
          rowToBeUpdated.EQ_ZONE_CD = eqZoneCdRowValue;
        }

        if (tempInsertChecker!=null && tempInsertChecker!='') {
          //tempInsertChecker = rowToBeUpdated;
          tempInsertChecker.EQ_ZONE_SHORT_DESC = rowToBeUpdated.EQ_ZONE_SHORT_DESC;
          tempInsertChecker.EQ_ZONE_LONG_DESC = rowToBeUpdated.EQ_ZONE_LONG_DESC;
          tempInsertChecker.ACTIVE_TAG = rowToBeUpdated.ACTIVE_TAG;
          tempInsertChecker.BM_CODE = rowToBeUpdated.BM_CODE;
          tempInsertChecker.REMARKS = rowToBeUpdated.REMARKS;
          tempInsertChecker.USER_ID = rowToBeUpdated.USER_ID;
          tempInsertChecker.MODE = 'I';
          rowToBeUpdated.MODE = 'I';
        }
        else if (tempUpdateChecker!=null && tempUpdateChecker!='') {
          tempUpdateChecker.EQ_ZONE_SHORT_DESC = rowToBeUpdated.EQ_ZONE_SHORT_DESC;
          tempUpdateChecker.EQ_ZONE_LONG_DESC = rowToBeUpdated.EQ_ZONE_LONG_DESC;
          tempUpdateChecker.ACTIVE_TAG = rowToBeUpdated.ACTIVE_TAG;
          tempUpdateChecker.BM_CODE = rowToBeUpdated.BM_CODE;
          tempUpdateChecker.REMARKS = rowToBeUpdated.REMARKS;
          tempUpdateChecker.USER_ID = rowToBeUpdated.USER_ID;
          tempUpdateChecker.MODE = 'U';
        }
        else { 
          this.tempData.push(rowToBeUpdated);
        }
        this.forms.resetEqZoneForm();
        this.eqZoneTblConfig.tblData[toBeUpdatedIndex] = rowToBeUpdated;
        this.btns.eqZone.saveDisabled = false;
        this.eqZoneTblConfig.tblData = [... this.eqZoneTblConfig.tblData];
      }
      else {
        this.showErrorValidator.eqZone();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public onSave() {
    try {
      this.btns.eqZone.saveDisabled = true;
      this.showForm = false;
      this._formService.showFormLoader(null, "eq-zone-maintenance-loading", "Saving.<br>Please wait ...", null, null);
      if (this.tempData.length > 0) {
        this._api.saveEarthquakeZone(this.tempData).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                if (data.status === 'SUCCESS') {
                  this.onComplete.eqZone();
                }
                else {
                  this.onComplete.eqZoneError();
                }
              });
            });
          },
          error: (data: any) => {
            this.onComplete.eqZoneError();
          },
        });
      }
      else
        this.onComplete.eqZone();
    }
    catch (e) {
      console.error(e);
    }
  }

  private onComplete = {
    eqZone: () => {
      this.tempData = [];
      this.getData.eqZone();
      this.btns.eqZone.saveDisabled = true;
      this.showForm = true;
      this._formService.hideFormLoader("eq-zone-maintenance-loading");
      this.forms.resetEqZoneForm();
      this.openDialog('SUCCESS', 'Earthquake Zone details successfully saved');
    },
    eqZoneError: () => {
      this.showForm = true;
      this.btns.eqZone.saveDisabled = false;
      this.openDialog('FAILED', 'Earthquake Zone details saving failed');
      this._formService.hideFormLoader("eq-zone-maintenance-loading");
    }
  }

  openDialog(title: any, message: any) {
    try {
      this.showForm = true;
      this._formService.hideFormLoader("eq-zone-maintenance-loading");
      this._matDialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        } 
      });
    } catch(e) {
      console.error(e);
    }
  }

  checkDuplicateEqZoneShortDesc(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.trim()?.toUpperCase();
      if (!value) return null;
      let hasDuplicate: boolean = this.eqZoneTblConfig.tblData.filter((d: any) => {
        if (!this.rowData && this.btns.eqZone.updateDisabled)
          return d.EQ_ZONE_SHORT_DESC.trim().toUpperCase() === value;
        else
          return this.rowData.EQ_ZONE_SHORT_DESC.trim().toUpperCase() !== value && d.EQ_ZONE_SHORT_DESC.trim().toUpperCase() === value;
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupe: true } : null;
    }
  }

  noWhiteSpaceCheck(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: true };
    }
  }

  public showErrorValidator = {
    eqZone: () => {
      try {
        Object.keys(this.forms.eqZoneForm.controls).forEach(key => {
          const controlErrors = this.forms.eqZoneForm.get(key)?.errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              if (keyError == 'whitespace' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
              else if (keyError == 'hasDupe' && controlErrors[keyError] === true)
                this.errorMessage = "Earthquake Zone already exists. Please add a unique Earthquake Zone.";
              else if (keyError == 'required' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
            });
          }
        });
        this._formService.showFormMsg("eq-zone-error-message", this.errorMessage, "E");
        //this.openDialog('ERROR', this.errorMessage);
        setTimeout(()=>{                           
          this._formService.hideFormMsg("eq-zone-error-message");
        }, 10000);
      }
      catch (e) {
        console.error(e);
      }
    },
  }

}