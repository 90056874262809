import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { BmaLovComponent } from '../../common/bma-lov/bma-lov.component';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { IssSrcComponent } from '../../common/iss-src/iss-src.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { CustomValidators } from 'src/app/utils/custom-validator';

@Component({
  selector: 'src-ext-maintenance',
  templateUrl: './src-ext-maintenance.component.html',
  styleUrls: ['./src-ext-maintenance.component.css']
})
export class SrcExtMaintenanceComponent implements OnInit, LeaveConfirmation {

  moduleId: string = 'BMM056';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc?.toUpperCase() ?? "";

  constructor(
    public _formService: FormService,
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    private _jDS: JsonDataService,
    private _securityService: SecurityService,
    private _userDetailService: UserDetailsService,
    private _appMessageService: AppMessageService
  ) { }

  ngOnInit(): void {
    this.getData.srcExt();
  }

  canDeactivate(): boolean | Observable<boolean> {
    return this.srcExtTblConfig.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true)).length > 0 ? false : true;
  }

  public btns = {
    srcExt: {
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      addDisabled: true,
    },
  }

  public showForm: boolean = true;

  /* Data Stuffs */

  private rowData: any;
  private tempDataRow: any;
  private errorMessage: string = "";

  public forms = {
    srcExtForm: this.fb.group({
      srcExtCd: [null],
      srcExtCdDisp: [{ value: '', disabled: 'true' }],
      srcExtDesc: [null, [Validators.required, Validators.maxLength(100), this.checkDuplicateSrcExtDesc(), CustomValidators.requiredTrim]],
      active: ['A', [Validators.required]],
      issCd: [null],
      issName: [null],
      issDisp: [{ value: '', disabled: 'true' }],
      bmaCd: [null],
      bmaBmCode: [null],
      bmaDesc: [null],
      bmaDisp: [{ value: '', disabled: 'true' }],
      brCode: [null, [Validators.maxLength(10)]],
      bmCode: [null, [Validators.required, Validators.maxLength(8), CustomValidators.requiredTrim, this.checkDuplicateBmCode()]],
      remarks: [null, [Validators.maxLength(4000)]],
      lastUpdateUser: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }],
    }),
    resetSrcExtForm: () => {
      this.forms.srcExtForm.reset();
      this.forms.srcExtForm.patchValue({
        active: 'A'
      });
      this.btns.srcExt.updateDisabled = this.btns.srcExt.deleteDisabled = true;
      this._formService.hideFormMsg("src-ext-error-message");
    },
  }

  private srcExtFormManipulate = {
    dbToForm: (data: any) => {
      return {
        srcExtCd: data.SRC_EXT_CD,
        srcExtCdDisp: data.SRC_EXT_CD?.toString().padStart(3, 0),
        srcExtDesc: data.SRC_EXT_DESC,
        active: data.ACTIVE_TAG,
        issCd: data.ISS_CD,
        issName: data.ISS_NAME,
        issDisp: data.ISS_CD ? `${data.ISS_CD} - ${data.ISS_NAME}` : null,
        bmaCd: data.BMA_CD,
        bmaBmCode: data.BMA_BM_CODE,
        bmaDesc: data.BMA_DESC,
        bmaDisp: data.BMA_CD ? `${data.BMA_BM_CODE} - ${data.BMA_DESC}` : null,
        brCode: data.BR_CODE,
        bmCode: data.BM_CODE,
        remarks: data.REMARKS,
        lastUpdateUser: data.USER_ID,
        lastUpdate: data.LAST_UPDATE,
      };
    },
    formToDb: (data: any) => {
      return {
        SRC_EXT_CD: data.srcExtCd,
        SRC_EXT_DESC: data.srcExtDesc.trim(),
        ISS_CD: data.issCd,
        ISS_NAME: data.issName,
        BMA_CD: data.bmaCd,
        BMA_BM_CODE: data.bmaBmCode,
        BMA_DESC: data.bmaDesc,
        BR_CODE: data.brCode,
        BM_CODE: data.bmCode,
        ACTIVE_TAG: data.active,
        REMARKS: data.remarks,
        USER_ID: null,
        LAST_UPDATE: null,
      };
    },
  }

  public selections = {
    activeTypes: [
      { cd: 'A', name: 'Yes' },
      { cd: 'I', name: 'No' },
    ],
  }

  public srcExtTblConfig = {
    cols: [
      {
        key: "SRC_EXT_CD",
        header: "Source Extension Code",
        dataType: "string"
      },
      {
        key: "SRC_EXT_DESC",
        header: "Source Extension",
        dataType: "string",
        width: '256px'
      },
      {
        key: "ISS_NAME",
        header: "Issue Source",
        dataType: "string",
        width: '128px'
      },
      {
        key: "BMA_DESC",
        header: "BMA",
        dataType: "string"
      },
      {
        key: "BM_CODE",
        header: "BPIMS Code",
        dataType: "string"
      },
      {
        key: "BR_CODE",
        header: "Branch Code",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "Active",
        dataType: "checkbox",
        width: '64px'
      }
    ],
    tblData: [] as any[],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  public openLov = {
    iss: () => {
      try {
        const LOVOPTIONS: { [key: string]: any } = {
          disableClose: true,
          width: '512px',
          data: { moduleId: this.moduleId }
        };
        this._matDialog.open(IssSrcComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
          if (!dataOut.option) {
            let content = dataOut.content;
            if (content) {
              this.forms.srcExtForm.patchValue({
                issCd: content.ISS_CD,
                issName: content.ISS_NAME,
                issDisp: `${content.ISS_CD} - ${content.ISS_NAME}`,
              });
            }
            else {
              this.forms.srcExtForm.patchValue({
                issCd: null,
                issName: null,
                issDisp: null,
              });
            }
          }
        });
      }
      catch (e) {
    console.error(e);

      }
    },
    bma: () => {
      try {
        const LOVOPTIONS: { [key: string]: any } = {
          disableClose: true,
          width: '512px',
          data: {
            table: "BM_CODE",
            moduleId: this.moduleId
          },
        };
        this._matDialog.open(BmaLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
          if (!dataOut.option) {
            let content = dataOut.content;
            if (content) {
              this.forms.srcExtForm.patchValue({
                bmaCd: content.BMA_CD,
                bmaBmCode: content.BM_CODE,
                bmaDesc: content.BMA_DESC,
                bmaDisp: `${content.BM_CODE} - ${content.BMA_DESC}`,
              });
            }
            else {
              this.forms.srcExtForm.patchValue({
                bmaCd: null,
                bmaBmCode: null,
                bmaDesc: null,
                bmaDisp: null,
              });
            }
          }
        });
      }
      catch (e) {
    console.error(e);

      }
    },
  }

  private getData = {
    srcExt: () => {
      try {
        this.srcExtTblConfig.tblData = [];
        this.srcExtTblConfig.loading = true;
        this._jDS.contorlLoading(true);
        this._api.getAllSourceExtension({
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          type: "MODULE"
        }).subscribe({
          next: (response: any) => {
            this._securityService.checkRequestKeyResponse(response, () => {
              this._securityService.hasValidCsrfToken(response, () => {
                if (response.status === 'SUCCESS') {
                  let content = JSON.parse(this._jDS.decrypt(response.response));
                  this.srcExtTblConfig.tblData = content.data;
                  this.btns.srcExt.addDisabled = false;
                }
                else {
                  this._appMessageService.showAppMessage(response.message, "error");
                }
                this._jDS.contorlLoading(false);
                this.srcExtTblConfig.loading = false;
              });
            });
          },
          error: (error: any) => {
            this.srcExtTblConfig.loading = false;
            this._jDS.contorlLoading(false);
            this._appMessageService.showAppMessage(error.message, "error");
          }
        })
      }
      catch (e) {
    console.error(e);

      }
    }
  }

  public onRowClick(ev: any) {
    try {
      ev.update = true;
      this.rowData = ev;
      this.populateFormFromTable();
    }
    catch (e) {
    console.error(e);
      this.forms.resetSrcExtForm();
      this.rowData = null;
      //this.indexRow = null;
    }
  }

  private populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.srcExt.updateDisabled = !data.update;
      this.btns.srcExt.deleteDisabled = !data.temp;
      this.tempDataRow = !data.temp;
      this.forms.srcExtForm.patchValue(this.srcExtFormManipulate.dbToForm(data));
    }
    catch (e) {
    console.error(e);

    }
  }

  public onAdd() {
    try {
      this._formService.hideFormMsg("src-ext-error-message");
      if (this.forms.srcExtForm.valid) {
        let rowToBeAdded: { [key: string]: any } = this.srcExtFormManipulate.formToDb(this.forms.srcExtForm.value);
        rowToBeAdded.temp = true;
        this.srcExtTblConfig.tblData = [rowToBeAdded, ...this.srcExtTblConfig.tblData];
        this.forms.resetSrcExtForm();
        this.btns.srcExt.saveDisabled = false;
      }
      else {
        this.showErrorValidator.srcExt();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onDelete() {
    try {
      this.srcExtTblConfig.tblData = this.srcExtTblConfig.tblData.filter((d: any) => {
        return d !== this.rowData;
      });
      this.forms.resetSrcExtForm();
    }
    catch (e) {
    console.error(e);

    }
  }

  public onUpdate() {
    try {
      this._formService.hideFormMsg("src-ext-error-message");
      if (this.forms.srcExtForm.valid) {
        let toBeUpdatedIndex = this.srcExtTblConfig.tblData.indexOf(this.rowData);
        let srcExtCdRowValue = this.rowData.SRC_EXT_CD;
        let rowToBeUpdated: { [key: string]: any } = this.srcExtFormManipulate.formToDb(this.forms.srcExtForm.value);
        if (this.rowData.temp) {
          rowToBeUpdated.temp = true;
        }
        else {
          rowToBeUpdated.onDbButUpdatedTemp = true;
          rowToBeUpdated.SRC_EXT_CD = srcExtCdRowValue;
        }
        this.forms.resetSrcExtForm();
        this.srcExtTblConfig.tblData[toBeUpdatedIndex] = rowToBeUpdated;
        this.btns.srcExt.saveDisabled = false;
        this.srcExtTblConfig.tblData = [... this.srcExtTblConfig.tblData];
      }
      else {
        this.showErrorValidator.srcExt();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onSave() {
    try {
      this.showForm = false;
      this._formService.showFormLoader(null, "src-ext-maintenance-loading", "Saving.<br>Please wait ...", null, null, true);
      let toBeSaved = this.srcExtTblConfig.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true));
      if (toBeSaved.length > 0) {
        // toBeSaved.map((d: any) => {
        //   d.USER_ID = this.userIdLoggedIn;
        // });
        //? To Be Uncomment later if chronological is needed ?//
        // let toBeAdded = toBeSaved.filter((d: any) => d.temp).reverse();
        // let toBeUpdated = toBeSaved.filter((d: any) => d.onDbButUpdatedTemp);
        // toBeSaved = [...toBeAdded, ...toBeUpdated];
        this._jDS.contorlLoading(true);
        this._api.saveSourceExtension(toBeSaved).subscribe({
          next: (response: any) => {
            this._securityService.checkRequestKeyResponse(response, () => {
              this._securityService.hasValidCsrfToken(response, () => {
                this._jDS.contorlLoading(false);
                if (response.status === 'SUCCESS') this.onComplete.srcExt();
                else this.onComplete.srcExtError();
              });
            });
          },
          error: (data: any) => {

            this._jDS.contorlLoading(false);
            this.onComplete.srcExtError();
          },
        });
      }
      else {
        // Optimized to stop request if theres no new rows
        this.onComplete.srcExt();
      }
      // setTimeout(() => {
      //   this.onComplete.srcExt();
      // }, 3000);
    }
    catch (e) {
    console.error(e);

    }
  }

  private onComplete = {
    srcExt: () => {
      this.getData.srcExt();
      this.btns.srcExt.saveDisabled = true;
      this.showForm = true;
      this._formService.hideFormLoader("src-ext-maintenance-loading");
      this.openDialog('SUCCESS', 'Source Extension', 'saved');
    },
    srcExtError: () => {
      this.showForm = true;
      this.btns.srcExt.saveDisabled = false;
      this.openDialog('FAILED', 'Source Extension', 'saving');
      this._formService.hideFormLoader("src-ext-maintenance-loading");
    },
  }

  openDialog(title: string, contentTitle: string, contentDetail: string) {
    try {
      this._matDialog.open(DialogMsgComponent, {
        disableClose: true,
        width: '512px',
        data: {
          title: title,
          content:
            title === 'SUCCESS'
              ? `${contentTitle} details successfully ${contentDetail}!`
              : `${contentTitle} details ${contentDetail} failed!`,
        },
      });
    }
    catch (e) {
    console.error(e);

    }
  }

  /* Validator Stuffs Down here */

  checkDuplicateSrcExtDesc(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.trim()?.toUpperCase();
      if (!value) return null;
      let hasDuplicate: boolean = this.srcExtTblConfig.tblData.filter((d: any) => {
        if (!this.rowData && this.btns.srcExt.updateDisabled)
          return d.SRC_EXT_DESC.toUpperCase() === value;
        else
          return this.rowData.SRC_EXT_DESC.trim().toUpperCase() !== value && d.SRC_EXT_DESC.trim().toUpperCase() === value;
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupeSrcExtCd: true } : null;
    }
  }

  checkDuplicateBmCode(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.trim()?.toUpperCase();
      if (!value) return null;
      let hasDuplicate: boolean = this.srcExtTblConfig.tblData.filter((d: any) => {
        const trimLeadingZeros = (str: string) => str?.replace(/^0+/, '');
        if (!this.rowData && this.btns.srcExt.updateDisabled)
          return trimLeadingZeros(d.BM_CODE)?.toUpperCase() === trimLeadingZeros(value);
        else
          return this.rowData.BM_CODE?.trim().toUpperCase() !== value && trimLeadingZeros(d.BM_CODE)?.trim().toUpperCase() === trimLeadingZeros(value);
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupeBmCode: true } : null;
    }
  }

  public showErrorValidator = {
    srcExt: () => {
      try {
        Object.keys(this.forms.srcExtForm.controls).forEach(key => {
          const controlErrors = this.forms.srcExtForm.get(key)?.errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              if (keyError == 'hasDupeSrcExtCd' && controlErrors[keyError] === true)
                this.errorMessage = "Source Extension Description already exists. Please add a unique Source Extension Description.";
              if (keyError == 'hasDupeBmCode' && controlErrors[keyError] === true)
                this.errorMessage = "BPIMS Code already exists. Please add a unique BPIMS Code.";
              else if (keyError == 'required' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
            });
          }
        });
        this._formService.showFormMsg("src-ext-error-message", this.errorMessage, "E");
      }
      catch (e) {
    console.error(e);

      }
    },
  }

}
