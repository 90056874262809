import { AfterViewInit, Component, OnInit } from '@angular/core';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { Observable } from 'rxjs';

import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';

import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';

@Component({
    selector: 'generate-rpa',
    templateUrl: './generate-rpa.component.html',
    styleUrls: ['./generate-rpa.component.css']
})
export class GenerateRpaComponent implements OnInit {

    constructor(
        private formBuilder: FormBuilder,
        private jsonDataService: JsonDataService,
        private apiCallService: APICallService,
        private formService: FormService,
        private securityService: SecurityService,
        public matDialog: MatDialog,
    ) { }

    ngOnInit(): void {
        this.jsonDataService.data.refCds.filter((e: any) => e.rvDomain == "RPA_BUS_TYPE").forEach((businessType: any) => {
            this.businessTypes.push(businessType);
        });
        this.jsonDataService.data.lines.forEach((lines: any) => {
            this.lines.push(lines);
        });
        this.jsonDataService.data.sublines.forEach((sublines: any) => {
            this.sublines.push(sublines);
        });
        this.filteredLineOptions = this.lines;
    }

    gcashOrLines: Array<String> = ['MC', 'PC'];
    gcashOrSublines: Array<String> = ['CTPL', 'PCI'];
    clientOrLines: Array<String> = ['PC'];
    clientOrSublines: Array<String> = ['PCI'];

    hasLineCd: boolean = true;
    singleDate: boolean = true;

    extractParams: FormGroup = this.formBuilder.group({
        businessType: ['', Validators.required],
        lineCd: ['', Validators.required],
        sublineCd: ["ALL", Validators.required],
        extractBy: ['', Validators.required],
        dateAsOf: ['', Validators.required],
        dateFrom: [''],
        dateTo: [''],
    });

    filteredLineOptions: any = [];
    filteredSublineOptions: any = [{ lineCd: "", sublineCd: "", sublineName: "" }];

    businessTypes: any = [];

    // fix later use jsondataservice
    lines: any = [];

    sublines: any = [{
        lineCd: "ALL", sublineCd: "ALL", sublineName: "ALL"
    }, {
        lineCd: "PA", sublineCd: "GPA", sublineName: "Group Personal Accident"
    }];

    extractMethod: any = [{
        methodId: 1, methodName: "Effective Date"
    }, {
        methodId: 2, methodName: "Issue Date"
    }];

    getSublines() {
        if (this.extractParams.value.lineCd === "PA") {
            //this.filteredSublineOptions = this.sublines.filter((e: any) => e.lineCd == this.extractParams.value.lineCd || e.lineCd == "ALL");
            this.filteredSublineOptions = this.sublines.filter((e: any) => e.lineCd == this.extractParams.value.lineCd || e.lineCd == "ALL");
            this.hasLineCd = false;
            this.extractParams.patchValue({ sublineCd: "GPA" });
        }
        else {
            if (this.extractParams.get('businessType')?.value !== 'G' && this.extractParams.get('businessType')?.value !== 'C') {
                this.filteredSublineOptions = this.sublines.filter((e: any) => e.lineCd == this.extractParams.value.lineCd || e.lineCd == "ALL");
                this.extractParams.patchValue({ sublineCd: "ALL" })
                this.hasLineCd = false;
            } else if (this.extractParams.get('businessType')?.value == 'G') {
                this.filteredSublineOptions = this.sublines.filter((e: any) => e.lineCd == this.extractParams.value.lineCd && this.gcashOrSublines.includes(e.sublineCd));
            } else if (this.extractParams.get('businessType')?.value == 'C') {
                this.filteredSublineOptions = this.sublines.filter((e: any) => e.lineCd == this.extractParams.value.lineCd && this.clientOrSublines.includes(e.sublineCd));
            }
        }
    };

    evaluateFields() {
        try {
            if (this.extractParams.get('businessType')?.value !== 'G' && this.extractParams.get('businessType')?.value !== 'C') {
                this.filteredLineOptions = this.lines;
                if (this.extractParams.value.lineCd === null || this.extractParams.value.lineCd === '') {
                    this.filteredSublineOptions = this.sublines.filter((e: any) => e.lineCd == "NONE");
                }
                else {
                    this.getSublines();
                }
                this.extractParams.get('lineCd')?.enable();
                this.extractParams.get('sublineCd')?.enable();
            } else if (this.extractParams.get('businessType')?.value == 'G') {
                this.filteredLineOptions = this.lines.filter((e: any) => this.gcashOrLines.includes(e.lineCd));
            } else if (this.extractParams.get('businessType')?.value == 'C') {
                this.filteredLineOptions = this.lines.filter((e: any) => this.clientOrLines.includes(e.lineCd));
            }
        }
        catch (e) {
            console.error(e);
        }
    }

    validateExtraction() {
        this.apiCallService.validateExtraction(this.extractParams.value).subscribe((response: any) => {
            this.securityService.checkRequestKeyResponse(response, () => {
                this.securityService.hasValidCsrfToken(response, () => {
                    if (response.status == "SUCCESS") {
                        if (response.extractExists == "Y") {
                            //do later
                        }
                    }
                });
            });
        });
    }

    extractRPA() {
        this.formService.hideFormMsg("generate-rpa-form-msg");
        if (this.extractParams.valid) {
            this.formService.showFormLoader(null, "generate-rpa-form", "Extracting.<br>Please wait ...", null, null);
            this.apiCallService.extractRPA(this.extractParams.value).subscribe((response: any) => {
                this.securityService.checkRequestKeyResponse(response, () => {
                    this.securityService.hasValidCsrfToken(response, () => {
                        if (response.status == "SUCCESS") {
                            let content = JSON.parse(this.jsonDataService.decrypt(response.response));
                            if (content.rowCount > 0) {
                                this.formService.hideFormLoader("generate-rpa-form");
                                this.printRPA();
                                this.showSuccess();
                            } else {
                                this.formService.hideFormLoader("generate-rpa-form");
                                this.showNoRecords();
                            }
                        } else {
                            this.formService.hideFormLoader("generate-rpa-form");
                            this.formService.showFormMsg("generate-rpa-form-msg", response.message, "E");
                        }
                    });
                });
            })
        } else {
            this.formService.showFormMsg("generate-rpa-form-msg", "Please fill all the required fields.", "E");
        }

    }

    printRPA() {
        this.formService.showFormLoader(null, "generate-rpa-form", "Generating CSV file.<br>Please wait ...", null, null);
        this.apiCallService.printRPA(this.extractParams.value).subscribe((response: any) => {
            this.securityService.checkRequestKeyResponse(response, () => {
                this.securityService.hasValidCsrfToken(response, () => {
                    if (response.status == "SUCCESS") {
                        let content = JSON.parse(this.jsonDataService.decrypt(response.response));
                        window.open(content.link);
                        this.formService.hideFormLoader("generate-rpa-form");
                    } else {
                        this.formService.hideFormLoader("generate-rpa-form");
                        this.formService.showFormMsg("generate-rpa-form-msg", response.message, "E");
                    }
                });
            });
        });
    }

    selectAsOfOption() {
        this.extractParams.patchValue({ dateFrom: null });
        this.extractParams.patchValue({ dateTo: null });
        this.extractParams.get('dateFrom')?.clearValidators();
        this.extractParams.get('dateFrom')?.updateValueAndValidity();
        this.extractParams.get('dateTo')?.clearValidators();
        this.extractParams.get('dateTo')?.updateValueAndValidity();
        this.extractParams.get('dateAsOf')?.setValidators([Validators.required]);
        this.extractParams.get('dateAsOf')?.updateValueAndValidity();
    }

    selectFromToOption() {
        this.extractParams.patchValue({ dateAsOf: null });
        this.extractParams.get('dateAsOf')?.clearValidators();
        this.extractParams.get('dateAsOf')?.updateValueAndValidity();
        this.extractParams.get('dateFrom')?.setValidators([Validators.required]);
        this.extractParams.get('dateFrom')?.updateValueAndValidity();
        this.extractParams.get('dateTo')?.setValidators([Validators.required]);
        this.extractParams.get('dateTo')?.updateValueAndValidity();
    }

    showNoRecords() {
        const dialogRole = this.matDialog.open(DialogMsgComponent, {
            disableClose: true,
            width: "500px",
            data: {
                title: 'ERROR',
                content: 'No records have been retrieved with the given parameters.'
            }
        });
    }

    showSuccess() {
        const dialogRole = this.matDialog.open(DialogMsgComponent, {
            disableClose: true,
            width: "500px",
            data: {
                title: 'SUCCESS',
                content: 'RPA successfully extracted!'
            }
        });
    }
}
