import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs/internal/Observable';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { FireRegisterLovComponent } from '../../common/fire-register-lov/fire-register-lov.component';

@Component({
  selector: 'update-policy-accumulation-register',
  templateUrl: './update-policy-accumulation-register.component.html',
  styleUrls: ['./update-policy-accumulation-register.component.css']
})
export class UpdatePolicyAccumulationRegisterComponent implements OnInit {
  
  moduleId: string = 'BMU002';
  moduleName = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId === this.moduleId })[0].moduleDesc.toUpperCase();

  polAccumulationRegTable: any = {
    cols: [
      {
        key: "policyNo",
        header: "Policy No.",
        dataType: "string"
      },
      {
        key: "assuredLongName",
        header: "Policy Owner",
        dataType: "string"
      },
      {
        key: "status",
        header: "Status",
        dataType: "string"
      },
      {
        key: "address",
        header: "Address",
        dataType: "string"
      },
      {
        key: "coverage",
        header: "Sum Insured",
        dataType: "currency"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }
  selectedRow: any;

  polAccumulationRegForm = this.fb.group({
    policyNo:         [{value: '', disabled: true}],
    bmFregCd:         [{value:'',  disabled: true}],
    blockCd:          [{value:'',  disabled: true}],
    assuredLongName:  [{value: '', disabled: true}],
    status:           [{value: '', disabled: true}],
    effectiveDate:    [{value: '', disabled: true}],
    expiryDate:       [{value: '', disabled: true}],
    coverage:         [{value: '', disabled: true}],
    netPremium:       [{value: '', disabled: true}],
    address:          [{value: '', disabled: true}],
    latitude:         [{value: '', disabled: true}],
    longitude:        [{value: '', disabled: true}],
    constructionDesc: [{value: '', disabled: true}],
    occupancyDesc:    [{value: '', disabled: true}],
    remarks:          [{value:'',  disabled: true}],
    lastUser:         [{value: '', disabled: true}],
    lastUserUpdate:   [{value: '', disabled: true}]
  });

  savedFlag: boolean = false;
  cancelDisabler: boolean = true;
  formHider: boolean = false;

  updateData:any[] = [];

  constructor(
    private fb: FormBuilder,
    private api: APICallService,
    private jsonDataService: JsonDataService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    private formService: FormService,
    private dialog: MatDialog,
    private securityService: SecurityService) { }

  ngOnInit(): void {
    try {
      this.getpolAccumulationReg();
    } catch(e) {
      console.error(e);
    }
  }

  getpolAccumulationReg(): void {
    try {
      this.polAccumulationRegTable.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.api.getPolicyAccumulationRegister({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE"
      }).subscribe((data: any) => {
        this.securityService.checkRequestKeyResponse(data, () => {
          this.securityService.hasValidCsrfToken(data, () => {
            if (data.status === "SUCCESS") {
                data = JSON.parse(this.jsonDataService.decrypt(data.response));
                this.polAccumulationRegTable.loading = false;
                this.jsonDataService.contorlLoading(false);
                this.polAccumulationRegTable.tblData = data;
                if(this.savedFlag) {
                  this.openSaveDialog();
                  this.savedFlag = false;
                }
            } else {
                this.jsonDataService.contorlLoading(false);
                this.polAccumulationRegTable.loading = false;
                this.appMessageService.showAppMessage(data.message, "error");
            }
          });
        });
      });
    } catch(e) {
      console.error(e);
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.updateData.length == 0;
  }

  openSaveDialog() {
    try {
      let contentTitle =  this.moduleName.replace('Maintenance', '');
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: '512px',
        data: {
          title: 'SUCCESS',
          content:
              `Successfully saved!`
        },
      });
    }
    catch (e) {
      console.error(e);  
    }
  }

  onRowClick(ev: any): void {
    try {
      this.selectedRow = ev;
      this.formLoader();
      if(ev) {
        this.cancelDisabler = false;
      } else {
        this.cancelDisabler = true;
      }
      this.polAccumulationRegForm.markAsPristine();
    } catch(e) {
      console.error(e);
    }
  }

  formLoader(): void {
    try {
      this.polAccumulationRegForm.patchValue({
        policyNo: this.selectedRow?.policyNo,
        fireRegister: this.selectedRow?.fireRegister,
        assuredLongName: this.selectedRow?.assuredLongName,
        status: this.selectedRow?.status,
        effectiveDate: this.selectedRow?.effectiveDate,
        expiryDate: this.selectedRow?.expiryDate,
        coverage: this.selectedRow?.coverage=='' || this.selectedRow?.coverage==null ? null : this.formService.setToAmountFormat(this.selectedRow?.coverage),
        netPremium: this.selectedRow?.netPremium,
        address: this.selectedRow?.address,
        latitude: this.selectedRow?.latitude,
        longitude: this.selectedRow?.longitude,
        constructionDesc: this.selectedRow?.constructionDesc,
        occupancyDesc: this.selectedRow?.occupancyDesc,
        remarks: this.selectedRow?.remarks,
        lastUser: this.selectedRow?.lastUser,
        lastUserUpdate: this.selectedRow?.lastUserUpdate,
        bmFregCd: this.selectedRow?.bmFregCd,
        blockCd: this.selectedRow?.blockCd
      });
    } catch(e) {
      console.error(e);
    }
  }

  openFireRegisterDialog() {
    try {
      this.dialog.open(FireRegisterLovComponent, {
        width: '900px',
        disableClose: true,
        data: {
          table: 'FIRE REGISTER',
          moduleId: this.moduleId,
          // stateCd: this.selectedRow.stateCd,
          // districtCd: this.selectedRow.districtCd,
          regionCd: this.selectedRow.regionCd
        }
      }).afterClosed().subscribe(data => {
        if (data.content && data.button == 'OK') {
          let {blockCd,bmFregCd} = data.content;
          this.polAccumulationRegForm.get('blockCd')?.setValue(blockCd);
          this.polAccumulationRegForm.get('bmFregCd')?.setValue(bmFregCd);
          this.polAccumulationRegForm.markAsDirty();
        }
        // this.sublineDisabled = this.sublineChecker();
      });
    } catch (e) {
      console.error(e);
    }
  }

  onUpdate() {
    this.polAccumulationRegForm.patchValue({
      coverage: parseFloat(this.formService.setToNumberWithDecimal(this.polAccumulationRegForm.get('coverage')?.value))
    });
    let data = this.polAccumulationRegForm.getRawValue();
    let { policyId } = this.selectedRow;
    data.policyId = policyId;
    // update updateData array
    let tempUpdateIdx = this.updateData.findIndex(a => a.policyId == policyId);
    if (tempUpdateIdx == -1) {
      this.updateData.push(data);
    } else {
      this.updateData[tempUpdateIdx] = data;
    }
    let tableIdx = this.polAccumulationRegTable.tblData.findIndex((a:any) => a.policyId == policyId);
    if (tableIdx == -1) {
      this.polAccumulationRegTable.tblData.push(data);
    } else {
      this.polAccumulationRegTable.tblData[tableIdx] = data;
      this.polAccumulationRegTable.tblData = [... this.polAccumulationRegTable.tblData];
    }
    this.resetForm();
  }
  

  onSave() {
    try {
      // this.formHider = true;
      // this.formService.showFormLoader(null, "policy-accumulation-register-form", "Saving.<br>Please wait ...", null, null);
      this.jsonDataService.contorlLoading(true);
      this.api.savePolicyAccumulationRegister(this.updateData).subscribe({
        next: (data: any) => {
          this.securityService.checkRequestKeyResponse(data, () => {
            this.securityService.hasValidCsrfToken(data, () => {
              if (data.status == 'SUCCESS') {
                this.onComplete();
              } else {
                this.onFail();
              }
            });
          });
        },
        error: () => {
          this.onFail();
        }
      });
    } catch (e) {
      console.error(e);
    }
  }

  onComplete() {
    try {
      // this.formService.hideFormLoader("policy-accumulation-register-form");
      this.jsonDataService.contorlLoading(false);
      this.openSaveDialog();
      // this.formHider = false;
      this.updateData = [];
      this.resetForm();
      this.getpolAccumulationReg();
    } catch (e) {
      console.error(e);
    }
  }

  onFail() {
    try {
      this.updateData = [];
      this.resetForm();
      this.getpolAccumulationReg();
      // this.formService.hideFormLoader("policy-accumulation-register-form");
      this.jsonDataService.contorlLoading(false);
      // this.formHider = false;
      this.openErrorDialog('Saving fire accumulation register failed.');
    } catch (e) {
      console.error(e);
    }
  }

  openErrorDialog(message: string) {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'ERROR',
          content: message,
        }
      });
    } catch (e) {
      console.error(e);
    }
  }

  resetForm(){
    this.selectedRow = '';
    this.formLoader();
    this.polAccumulationRegForm.markAsPristine();
  }

}
