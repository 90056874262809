<div class="mtn-container">
    <div class="mtn-title">
        <p>{{ moduleName }}</p>
    </div>
    <osp-table [tblConfig]="tblConfig" (onRowClick)="onRowClick($event)"></osp-table>
    <div id="system-user-maintenance-loading"><div class="form-loader"></div></div>
    <div *ngIf="showForm" class="form-content">
        <form [formGroup]="systemUsersMainForm" class="form">
            <div id="system-users-error-message"></div>
            <div class="form-container-grid">
                <div class="field required">
                    <label>User ID</label>
                </div>
                <div class="">
                    <input formControlName="userId" (input)="autoUppercase($event)" maxlength="10" alphanumeric>
                </div>
                <div class="field required">
                    <label>Active</label>
                </div>
                <div class="">
                    <select formControlName="active">
                        <option *ngFor="let activeType of activeTypes" [value]="activeType.cd">
                            {{activeType.name}}
                        </option>
                    </select>
                </div>
                <div class="field required">
                    <label>First Name</label>
                </div>
                <div class="">
                    <input formControlName="firstName" type="text" maxlength="20" (blur)="checkNameLength('firstName')">
                </div>
                <div class="field">
                    <label>Middle Name</label>
                </div>
                <div class="">
                    <input formControlName="middleName" type="text" maxlength="30" (blur)="checkNameLength('middleName')">
                </div>
                <div class="field required">
                    <label>Last Name</label>
                </div>
                <div class="">
                    <input formControlName="lastName" type="text" maxlength="30" (blur)="checkNameLength('lastName')">
                </div>
                <div class="field required">
                    <label>Email Address</label>
                </div>
                <div class="">
                    <input formControlName="email">
                </div>
                <div class="field required">
                    <label>Effective Date</label>
                </div>
                <div class="">
                    <input date-input [matDatepicker]="effDatePicker" formControlName="effectiveDate" placeholder="MM/DD/YYYY" (input)="datePattern($event)"> 
                    <mat-datepicker-toggle matSuffix [for]="effDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #effDatePicker></mat-datepicker>
                </div>
                <div class="field">
                    <label>Expiry Date</label>
                </div>
                <div class="">
                    <input date-input [matDatepicker]="exDatePicker" formControlName="expiryDate" placeholder="MM/DD/YYYY" (input)="datePattern($event)">
                    <mat-datepicker-toggle matSuffix [for]="exDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #exDatePicker></mat-datepicker>
                </div>
                <div class="field">
                    <label>Remarks</label>
                </div>
                <div class="span">
                    <input formControlName="remarks">
                </div>
                <div class="field">
                    <label>Last Update User</label>
                </div>
                <div class="">
                    <input formControlName="lastUpdateUser">
                </div>
                <div class="field">
                    <label>Last Update</label>
                </div>
                <div class="">
                    <input formControlName="lastUpdate">
                </div>
            </div>
            <!-- <button (click)="updateBtnDisabled = !updateBtnDisabled">test</button> -->
            <!-- <button (click)="testFunc()"> Reset </button> -->
        </form>
        <div class="btn-grp">
            <button *ngIf="updateBtnDisabled" class="btn btn2" type="button" (click)="onAdd()">ADD</button>
            <button *ngIf="!updateBtnDisabled" class="btn btn2" type="button" (click)="onUpdate()">UPDATE</button>
            <button class="btn btn2" type="button" [disabled]="deleteBtnDisabled" (click)="onDelete()">DELETE</button>
        </div>
        <div class="mtn-title"></div>
        <div class="btn-grp">
            <button type="button" class="btn btn2" [disabled]="resetUserPwBtnDisabled" (click)="resetUserPw()">PASSWORD RESET</button>
            <button class="btn btn2" type="button" [disabled]="userAccessBtnDisabled" (click)="openUserAccessModal()">USER ACCESS</button>
            <button class="btn btn2" type="button" [disabled]="saveBtnDisabled" (click)="onSave()">SAVE</button>
        </div>
    </div>
</div>