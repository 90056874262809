import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';

@Component({
  selector: 'blocked-vehicle-maintenance',
  templateUrl: './blocked-vehicle-maintenance.component.html',
  styleUrls: ['./blocked-vehicle-maintenance.component.css']
})
export class BlockedVehicleMaintenanceComponent implements OnInit, LeaveConfirmation {

  private moduleId: string = 'BMM133';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc ?? "";
  public numberRegex: RegExp = /^-?\d+\.?\d*$/;

  constructor(
    public _formService: FormService,
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService,
    private _appMessageService: AppMessageService,
  ) {
    this.userIdLoggedIn = this._userDetailService.userId ?? "";
  }

  public canDeactivate(): boolean | Observable<boolean> {
    return !(this.blockedVehiclesTblConfig.tblData?.filter((row: any) => ['I', 'U'].includes(row.operation)).length > 0);
  }

  public btns = {
    blockedVehicles: {
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      addDisabled: false,
    },
  };

  public showForm: boolean = true;

  //* Data Stuffs */
  private rowData: any;
  private errorMessage: string = "";
  private userIdLoggedIn: string;
  //? set selectedRow to [] to unselect the table
  public selectedRow!: any[];

  public forms = {
    blockedVehiclesForm: this.fb.group({
      vehicleId: [null],
      vehicleIdDisp: [{ value: '', disabled: 'true' }],
      active: ['A', [Validators.required]],
      plateNo: [null, [Validators.maxLength(10), this.checkDuplicateXNo('PLATE_NO')]],
      engineNo: [null, [Validators.maxLength(60), this.checkDuplicateXNo('ENGINE_NO')]],
      chassisNo: [null, [Validators.maxLength(60), this.checkDuplicateXNo('CHASSIS_NO')]],
      mvFileNo: [null, [Validators.maxLength(100), this.checkDuplicateXNo('MV_FILE_NO')]],
      policyNo: [null, [Validators.maxLength(50)]],
      claimNo: [null, [Validators.maxLength(50)]],
      year: [null, [Validators.maxLength(4)]],
      make: [null, [Validators.maxLength(50)]],
      model: [null, [Validators.maxLength(50)]],
      subModel: [null, [Validators.maxLength(50)]],
      remarks: [null, [Validators.maxLength(4000)]],
      lastUpdateUser: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }],
    }, {
      validators: [this.checkHasOneFilled()],
    }),
    resetBlockedVehiclesForm: () => {
      this.forms.blockedVehiclesForm.reset();
      this.forms.blockedVehiclesForm.patchValue({
        active: 'A',
      });
      this.btns.blockedVehicles.updateDisabled = this.btns.blockedVehicles.deleteDisabled = true;
      this._formService.hideFormMsg("blocked-vehicles-error-message");
      this.rowData = null;
    }
  }

  private blockedVehiclesFormManipulate = {
    dbToForm: (data: any) => {
      return {
        vehicleId: data.VEHICLE_ID,
        vehicleIdDisp: data.VEHICLE_ID,
        active: data.ACTIVE_TAG,
        plateNo: data.PLATE_NO,
        engineNo: data.ENGINE_NO,
        chassisNo: data.CHASSIS_NO,
        mvFileNo: data.MV_FILE_NO,
        policyNo: data.POLICY_NO,
        claimNo: data.CLAIM_NO,
        year: data.YEAR,
        make: data.MAKE,
        model: data.MODEL,
        subModel: data.SUB_MODEL,
        remarks: data.REMARKS,
        lastUpdateUser: data.USER_ID,
        lastUpdate: data.LAST_UPDATE,
      };
    },
    formToDb: (data: any) => {
      return {
        VEHICLE_ID: data.vehicleId,
        PLATE_NO: data.plateNo?.trim(),
        ENGINE_NO: data.engineNo?.trim(),
        CHASSIS_NO: data.chassisNo?.trim(),
        MV_FILE_NO: data.mvFileNo?.trim(),
        POLICY_NO: data.policyNo,
        CLAIM_NO: data.claimNo,
        YEAR: data.year,
        MAKE: data.make,
        MODEL: data.model,
        SUB_MODEL: data.subModel,
        ACTIVE_TAG: data.active,
        REMARKS: data.remarks,
        USER_ID: null,
        LAST_UPDATE: null,
      };
    },
  }

  public blockedVehiclesTblConfig: any = {
    cols: [
      {
        key: "VEHICLE_ID",
        header: "Vehicle ID",
        dataType: "string",
      },
      {
        key: "PLATE_NO",
        header: "Plate No.",
        dataType: "string",
      },
      {
        key: "ENGINE_NO",
        header: "Engine No.",
        dataType: "string",
      },
      {
        key: "CHASSIS_NO",
        header: "Chassis No.",
        dataType: "string",
      },
      {
        key: "MV_FILE_NO",
        header: "MV File No.",
        dataType: "string",
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        width: '48px',
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  ngOnInit(): void {
    this.getData.blockedVehicles();
  }

  private getData = {
    blockedVehicles: () => {
      try {
        this.blockedVehiclesTblConfig.tblData = [];
        this._jDS.contorlLoading(true);
        this.blockedVehiclesTblConfig.loading = true;
        this._api.getBlockedVehicles({
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          type: "MODULE",
        }).subscribe({
          next: (response: any) => {
            this._securityService.checkRequestKeyResponse(response, () => {
              this._securityService.hasValidCsrfToken(response, () => {
                if (response.status === "SUCCESS") {
                  let content = JSON.parse(this._jDS.decrypt(response?.response));
                  this.blockedVehiclesTblConfig.tblData = content.data;
                  this._jDS.contorlLoading(false);
                  this.blockedVehiclesTblConfig.loading = false;
                }
                else {

                  this._jDS.contorlLoading(false);
                  this.blockedVehiclesTblConfig.loading = false;
                }
              });
            });
          },
          error: (e: any) => {
            this._securityService.checkRequestKeyResponse(e, () => {

              this._jDS.contorlLoading(false);
              this.blockedVehiclesTblConfig.loading = false;
            });
          }
        });
      }
      catch (e) {
    console.error(e);

      }
    },
  };

  public selections = {
    activeTypes: [
      { cd: 'A', name: 'Yes' },
      { cd: 'I', name: 'No' },
    ],
  };

  public onRowClick(ev: any) {
    try {
      if (ev) {
        this.rowData = ev;
        this.populateFormFromTable();
      }
      else {
        this.rowData = null;
        this.forms.resetBlockedVehiclesForm();
      }
    }
    catch (e) {
      console.error(e);
      this.rowData = null;
      this.forms.resetBlockedVehiclesForm();
    }
  }

  private populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.blockedVehicles.updateDisabled = false;
      this.btns.blockedVehicles.deleteDisabled = !(this.rowData?.operation === 'I');
      this.forms.blockedVehiclesForm.patchValue(this.blockedVehiclesFormManipulate.dbToForm(data));
    }
    catch (e) {
    console.error(e);

    }
  }

  public onAdd(): void {
    try {
      this._formService.hideFormMsg("blocked-vehicles-error-message");
      if (this.forms.blockedVehiclesForm.valid) {
        let rowToBeAdded: { [key: string]: any } = this.blockedVehiclesFormManipulate.formToDb(this.forms.blockedVehiclesForm.getRawValue());
        rowToBeAdded.operation = 'I';
        this.blockedVehiclesTblConfig.tblData = [rowToBeAdded, ...this.blockedVehiclesTblConfig.tblData];
        this.forms.resetBlockedVehiclesForm();
        this.btns.blockedVehicles.saveDisabled = false;
      }
      else {
        this.showErrorValidator.blockedVehicles();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onUpdate(): void {
    try {
      this._formService.hideFormMsg("blocked-vehicles-error-message");
      if (this.forms.blockedVehiclesForm.valid) {
        const indexOfRow = this.blockedVehiclesTblConfig.tblData.indexOf(this.rowData);
        let rowToBeUpdated: { [key: string]: any } = this.blockedVehiclesFormManipulate.formToDb(this.forms.blockedVehiclesForm.getRawValue());
        rowToBeUpdated.operation = (this.rowData.operation === 'I') ? 'I' : 'U';
        rowToBeUpdated.VEHICLE_ID = this.rowData?.VEHICLE_ID;
        this.forms.resetBlockedVehiclesForm();
        this.blockedVehiclesTblConfig.tblData[indexOfRow] = rowToBeUpdated;
        this.selectedRow = [];
        this.btns.blockedVehicles.saveDisabled = false;
        this.blockedVehiclesTblConfig.tblData = [...this.blockedVehiclesTblConfig.tblData];
      }
      else {
        this.showErrorValidator.blockedVehicles();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onDelete(): void {
    try {
      this.blockedVehiclesTblConfig.tblData = this.blockedVehiclesTblConfig.tblData.filter((row: any) => {
        return row !== this.rowData;
      });
      this.forms.resetBlockedVehiclesForm();
      this.btns.blockedVehicles.saveDisabled = false;
    }
    catch (e) {
    console.error(e);

    }
  }

  public onSave(): void {
    try {
      this._jDS.contorlLoading(true);
      this.showForm = false;
      this._formService.showFormLoader(null, "blocked-vehicles-maintenance-loading", "Saving.<br>Please wait ...", null, null, true);
      let toBeSaved = this.blockedVehiclesTblConfig.tblData.filter((row: any) => ['I', 'U'].includes(row.operation));
      if (toBeSaved.length > 0) {
        this._api.saveBlockedVehicles(toBeSaved).subscribe({
          next: (response: any) => {
            this._securityService.checkRequestKeyResponse(response, () => {
              this._securityService.hasValidCsrfToken(response, () => {
                if (response.status === 'SUCCESS') {
                  this.onComplete.blockedVehicles();
                }
                else {
                  this.onComplete.blockedVehiclesError();
                }
              });
            });
          },
          error: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {

              this.onComplete.blockedVehiclesError();
            });
          }
        });
      }
      else {
        this.onComplete.blockedVehicles();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  private onComplete = {
    blockedVehicles: () => {
      this._jDS.contorlLoading(false);
      this._formService.hideFormLoader("blocked-vehicles-maintenance-loading");
      this.openDialog('SUCCESS', this.moduleName.replace('Maintenance', ''), 'saved');
      this.btns.blockedVehicles.saveDisabled = true;
      this.showForm = true;
      this.forms.resetBlockedVehiclesForm();
    },
    blockedVehiclesError: () => {
      this._jDS.contorlLoading(false);
      this.openDialog('FAILED', this.moduleName.replace('Maintenance', ''), 'saving');
      this._formService.hideFormLoader("blocked-vehicles-maintenance-loading");
      this.showForm = true;
      this.forms.resetBlockedVehiclesForm();
    },
  }

  private openDialog(title: string, contentTitle: string, contentDetail: string) {
    try {
      this._matDialog.open(DialogMsgComponent, {
        disableClose: true,
        width: '512px',
        data: {
          title: title,
          content:
            title === 'SUCCESS'
              ? `${contentTitle} details successfully ${contentDetail}!`
              : `${contentTitle} details ${contentDetail} failed!`,
        },
      }).afterClosed().subscribe(() => {
        if (title === 'SUCCESS') {
          this.getData.blockedVehicles();
        }
      });
    }
    catch (e) {
    console.error(e);

    }
  }

  //* Validator Stuffs Down here */

  private checkHasOneFilled(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const plateNo = control.get('plateNo')?.value?.trim();
      const engineNo = control.get('engineNo')?.value?.trim();
      const chassisNo = control.get('chassisNo')?.value?.trim();
      const mvFileNo = control.get('mvFileNo')?.value?.trim();
      let noneFilled = (plateNo || engineNo || chassisNo || mvFileNo) ? false : true;
      return noneFilled ? { noneFilled: true } : null;
    }
  }

  checkDuplicateXNo(name: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.trim()?.toUpperCase();
      if (!value) return null;
      let hasDuplicate: boolean = this.blockedVehiclesTblConfig?.tblData.filter((d: any) => {
        if (!this.rowData)
          return d[name]?.toUpperCase() === value;
        else
          return this.rowData[name]?.trim()?.toUpperCase() !== value && d[name]?.trim()?.toUpperCase() === value;
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupe: true } : null;
    }
  }

  public showErrorValidator = {
    blockedVehicles: () => {
      try {
        if (this.forms.blockedVehiclesForm.errors) {
          const controlError = this.forms.blockedVehiclesForm.errors;
          Object.keys(controlError).forEach(keyError => {
            if (keyError == 'noneFilled' && controlError[keyError] === true)
              this.errorMessage = "Kindly fill up at least one of the fields: Plate No., Engine No., Chassis No., MV File No.";
          });
        }
        else {
          Object.keys(this.forms.blockedVehiclesForm.controls).forEach(key => {
            const controlErrors = this.forms.blockedVehiclesForm.get(key)?.errors;
            if (controlErrors != null) {
              Object.keys(controlErrors).forEach(keyError => {
                if (keyError == 'required' && controlErrors[keyError] === true)
                  this.errorMessage = "There are missing information. Please provide necessary information needed.";
                else if (keyError == 'hasDupe' && controlErrors[keyError] === true && key == 'plateNo')
                  this.errorMessage = "Plate No. already exists. Please enter a unqiue Plate No.";
                else if (keyError == 'hasDupe' && controlErrors[keyError] === true && key == 'chassisNo')
                  this.errorMessage = "Chassis No. already exists. Please enter a unqiue Chassis No.";
                else if (keyError == 'hasDupe' && controlErrors[keyError] === true && key == 'engineNo')
                  this.errorMessage = "Engine No. already exists. Please enter a unqiue Engine No.";
                else if (keyError == 'hasDupe' && controlErrors[keyError] === true && key == 'mvFileNo')
                  this.errorMessage = "MV File No. already exists. Please enter a unqiue MV File No.";
              });
            }
          });
        }
        this._formService.showFormMsg("blocked-vehicles-error-message", this.errorMessage, "E");
      }
      catch (e) {
    console.error(e);

      }
    },
  }

  public allowNumericDigitsOnly(event: any) {
    if (this.numberRegex.test(String.fromCharCode(event.keyCode))) {
      return true;
    }
    else {
      event.preventDefault();
      return false;
    }
  }

}
