import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { CheckLovComponent } from './check-lov/check-lov.component';

export interface LeaveConfirmation {
  canDeactivate: () => Observable<boolean> | boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CheckGuard implements CanDeactivate<LeaveConfirmation> {
  constructor(
    public dialog: MatDialog,
    private userDetailService: UserDetailsService,
  ) { }
  canDeactivate(component: LeaveConfirmation) {
    if (this.userDetailService.sessionExists()) {
      return component.canDeactivate() ? true : this.confirmDialog();
    }
    return true;
  }

  confirmDialog(): Observable<boolean> {
    let dialogRef = this.dialog.open(CheckLovComponent, {
      width: '500px',
    });
    return dialogRef.afterClosed();
  }
  
}
