import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { ClassLovComponent } from '../../common/class-lov/class-lov.component';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { EQZoneLovComponent } from '../../common/eq-zone-lov/eq-zone-lov.component';
import { FloodZoneLovComponent } from '../../common/flood-zone-lov/flood-zone-lov.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';
import { PerilLovComponent } from '../../common/peril-lov/peril-lov.component';
import { PlanLovComponent } from '../../common/plan-lov/plan-lov.component';
import { TyphoonZoneLovComponent } from '../../common/typhoon-zone-lov/typhoon-zone-lov.component';

@Component({
  selector: 'cat-zone-fire-rates-pu-maintenance',
  templateUrl: './cat-zone-fire-rates-pu-maintenance.component.html',
  styleUrls: ['./cat-zone-fire-rates-pu-maintenance.component.css']
})
export class CatZoneFireRatesPuMaintenanceComponent implements OnInit, OnDestroy, LeaveConfirmation {

  private moduleId: string = 'BMM177';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc ?? "";
  private datePipe = new DatePipe('en-us');
  private valueChangesSubscriptions: Subscription = new Subscription();

  constructor(
    public _formService: FormService,
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService,
    private _appMessageService: AppMessageService,
  ) {
    this.userIdLoggedIn = this._userDetailService.userId ?? "";
  }

  canDeactivate(): boolean | Observable<boolean> {
    return !(this.catZoneFireRatesPuTblConfig.tblData.filter((row: any) => ['I', 'U'].includes(row.operation)).length > 0);
  }

  public btns = {
    catZoneFireRatesPu: {
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      addDisabled: true,
      formLovSearchDisabled: true,
    },
    filterLov: {
      classLovSearchDisabled: false,
      lineLovSearchDisabled: false,
      sublineLovSearchDisabled: true,
      planLovSearchDisabled: true,
      perilLovSearchDisabled: true,
      catZoneLovSearchDisabled: true,
    }
  };

  public showForm: boolean = true;

  public maxEffDate!: Date | undefined;
  public minExpDate!: Date | undefined;

  //* Data Stuffs */
  private rowData: any;
  private errorMessage: string = "";
  private userIdLoggedIn: string;
  //? set selectedRow to [] to unselect the table
  public selectedRow!: any[];

  private lpadNum = {
    noOfFloor: 2
  };

  private catPerils = {
    eq: this._jDS.data.params.find((a: any) => a.paramName == 'CAT_PERIL_EQ')?.paramValueV?.split(',') ?? [],
    ty: this._jDS.data.params.find((a: any) => a.paramName == 'CAT_PERIL_TY')?.paramValueV?.split(',') ?? [],
    fl: this._jDS.data.params.find((a: any) => a.paramName == 'CAT_PERIL_FL')?.paramValueV?.split(',') ?? [],
  };

  public forms = {
    filterLovForm: this.fb.group({
      classCd: [null],
      classCdName: [null],
      lineCd: [null],
      lineName: [null],
      sublineCd: [null],
      sublineName: [null],
      planCd: [null],
      planName: [null],
      perilCd: [null],
      perilName: [null],
      catZoneCd: [null],
      catZoneName: [null],
      classCdDisp: [{ value: '', disabled: 'true' }],
      lineCdDisp: [{ value: '', disabled: 'true' }],
      sublineCdDisp: [{ value: '', disabled: 'true' }],
      planDisp: [{ value: '', disabled: 'true' }],
      perilDisp: [{ value: '', disabled: 'true' }],
      catZoneDisp: [{ value: '', disabled: 'true' }],
    }),
    catZoneFireRatesPuForm: this.fb.group({
      classCd: [null],
      lineCd: [null],
      sublineCd: [null],
      planCd: [null],
      perilCd: [null],
      catZoneCd: [null],
      noOfFloors: [null, [Validators.required]],
      active: ["A", [Validators.required]],
      default: ["N", [Validators.required]],
      premRt: [null, [Validators.required, this.checkDecimalsExceeded(3, 9)]],
      commRt: [null, [Validators.required, this.checkDecimalsExceeded(3, 9)]],
      effDateFrom: [new Date(), [Validators.required]],
      effDateTo: [null],
      remarks: [null],
      lastUpdateUser: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }],
    }, {
      validators: [this.checkDupes(), this.checkHasDefault()],
    }),
    resetCatZoneFireRatesPuForm: () => {
      this.forms.catZoneFireRatesPuForm.reset();
      this.btns.catZoneFireRatesPu.updateDisabled = true;
      this.btns.catZoneFireRatesPu.deleteDisabled = true;

      this.forms.catZoneFireRatesPuForm.patchValue({
        active: 'A',
        effDateFrom: new Date(),
        default: 'N'
      });

      this._formService.hideFormMsg("cat-zone-fire-rates-pu-error-message");
      this.rowData = null;
      this.forms.catZoneFireRatesPuForm.get('noOfFloors')?.enable();
      this.forms.catZoneFireRatesPuForm.get('effDateFrom')?.enable();
      this.forms.catZoneFireRatesPuForm.get('premRt')?.enable();
    },
    resetFilterLovForm: () => {
      this.forms.filterLovForm.reset();
    },
    resetAllForms: () => {
      this.forms.resetFilterLovForm();
      this.forms.resetCatZoneFireRatesPuForm();
    }
  }

  private catZoneFireRatesPuFormManipulate = {
    dbToForm: (data: any) => {
      return {
        classCd: data.CLASS_CD,
        lineCd: data.LINE_CD,
        sublineCd: data.SUBLINE_CD,
        planCd: data.PLAN_CD,
        perilCd: data.PERIL_CD,
        catZoneCd: data.CAT_ZONE_CD,
        noOfFloors: data.NO_OF_FLOOR,
        active: data.ACTIVE_TAG,
        default: data.DEFAULT_TAG,
        premRt: data.PREM_RT && parseFloat(data.PREM_RT).toFixed(9),
        commRt: data.COMM_RT && parseFloat(data.COMM_RT).toFixed(9),
        effDateFrom: data.EFF_DATE_FROM ? new Date(data.EFF_DATE_FROM) : null,
        effDateTo: data.EFF_DATE_TO ? new Date(data.EFF_DATE_TO) : null,
        remarks: data.REMARKS,
        lastUpdateUser: data.USER_ID,
        lastUpdate: data.LAST_UPDATE,
      };
    },
    formToDb: (data: any) => {
      return {
        CLASS_CD: data.classCd,
        LINE_CD: data.lineCd,
        SUBLINE_CD: data.sublineCd,
        PLAN_CD: data.planCd,
        PERIL_CD: data.perilCd,
        CAT_ZONE_CD: data.catZoneCd,
        NO_OF_FLOOR: data.noOfFloors,
        ACTIVE_TAG: data.active,
        DEFAULT_TAG: data.default,
        PREM_RT: parseFloat(this._formService.setToNumberWithDecimal(data.premRt)),
        COMM_RT: parseFloat(this._formService.setToNumberWithDecimal(data.commRt)),
        EFF_DATE_FROM: data.effDateFrom && this.datePipe.transform(new Date(data.effDateFrom), 'yyyy-MM-dd'),
        EFF_DATE_TO: data.effDateTo && this.datePipe.transform(new Date(data.effDateTo), 'yyyy-MM-dd'),
        REMARKS: data.remarks,
        USER_ID: null,
        LAST_UPDATE: null,
      };
    },
  }

  public catZoneFireRatesPuTblConfig: any = {
    cols: [
      {
        key: "NO_OF_FLOOR",
        header: "No. of Floors",
        dataType: "string",
      },
      {
        key: "PREM_RT",
        header: "Premium Rate",
        dataType: "rateDecimal"
      },
      {
        key: "EFF_DATE_FROM",
        header: "Effective Date",
        dataType: "date"
      },
      {
        key: "EFF_DATE_TO",
        header: "Expiry Date",
        dataType: "date"
      },
      {
        key: "COMM_RT",
        header: "Commission Rate",
        dataType: "rateDecimal"
      },
      {
        key: "DEFAULT_TAG",
        header: "Default",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        width: '48px',
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  ngOnInit(): void {
    this.minExpDate = new Date();
    this.valueChangesSubscriptions.add(this.forms.catZoneFireRatesPuForm.get('effDateFrom')?.valueChanges.subscribe((res) => {
      this.minExpDate = new Date(res);
    }));
    this.valueChangesSubscriptions.add(this.forms.catZoneFireRatesPuForm.get('effDateTo')?.valueChanges.subscribe((res) => {
      this.maxEffDate = res ? new Date(res) : undefined;
    }));
  }

  ngOnDestroy(): void {
    this.valueChangesSubscriptions.unsubscribe();
  }

  public openLov = {
    line: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'LINE',
          moduleId: this.moduleId
        },
      }
      this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button?.toUpperCase() === 'OK') {
          this.forms.resetCatZoneFireRatesPuForm();
          if (output) {
            this.forms.filterLovForm.patchValue({
              lineCd: output.LINE_CD,
              lineName: output.LINE_NAME,
              lineCdDisp: `${output.LINE_CD} - ${output.LINE_NAME}`,
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null,
              planCd: null,
              planName: null,
              planDisp: null,
              perilCd: null,
              perilName: null,
              perilDisp: null,
              catZoneCd: null,
              catZoneName: null,
              catZoneDisp: null,
            });
            this.btns.catZoneFireRatesPu.addDisabled = true;
            this.btns.filterLov.sublineLovSearchDisabled = false;
            this.btns.filterLov.perilLovSearchDisabled = false;
            this.btns.filterLov.planLovSearchDisabled = true;
            this.btns.filterLov.catZoneLovSearchDisabled = true;
          }
          else {
            this.forms.filterLovForm.patchValue({
              lineCd: null,
              lineName: null,
              lineCdDisp: null,
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null,
              planCd: null,
              planName: null,
              planDisp: null,
              perilCd: null,
              perilName: null,
              perilDisp: null,
              catZoneCd: null,
              catZoneName: null,
              catZoneDisp: null,
            });
            this.btns.catZoneFireRatesPu.addDisabled = this.btns.catZoneFireRatesPu.formLovSearchDisabled = true;
            this.btns.filterLov.sublineLovSearchDisabled = true;
            this.btns.filterLov.perilLovSearchDisabled = true;
            this.btns.filterLov.planLovSearchDisabled = true;
            this.btns.filterLov.catZoneLovSearchDisabled = true;
          }
          this.getData.catZoneFireRatesPu();
        }
      });
    },
    subline: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          lineCd: this.forms.filterLovForm.get('lineCd')?.value,
          table: 'SUBLINE',
          moduleId: this.moduleId
        },
      }
      this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button?.toUpperCase() === 'OK') {
          this.forms.resetCatZoneFireRatesPuForm();
          if (output) {
            this.forms.filterLovForm.patchValue({
              lineCd: output.LINE_CD,
              lineName: output.LINE_NAME,
              lineCdDisp: `${output.LINE_CD} - ${output.LINE_NAME}`,
              sublineCd: output.SUBLINE_CD,
              sublineName: output.SUBLINE_NAME,
              sublineCdDisp: `${output.SUBLINE_CD} - ${output.SUBLINE_NAME}`,
              planCd: null,
              planName: null,
              planDisp: null,
            });
            this.btns.filterLov.planLovSearchDisabled = false;
          }
          else {
            this.forms.filterLovForm.patchValue({
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null,
              planCd: null,
              planName: null,
              planDisp: null,
            });
            this.btns.catZoneFireRatesPu.addDisabled = this.btns.catZoneFireRatesPu.formLovSearchDisabled = true;
            this.btns.filterLov.planLovSearchDisabled = true;
          }
          this.getData.catZoneFireRatesPu();
        }
      });
    },
    plan: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'PLAN',
          moduleId: this.moduleId,
          lineCd: this.forms.filterLovForm.get('lineCd')?.value,
          sublineCd: this.forms.filterLovForm.get('sublineCd')?.value,
          lpad: 2,
        },
      };
      this._matDialog.open(PlanLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button?.toUpperCase() === 'OK') {
          this.forms.resetCatZoneFireRatesPuForm();
          if (output.PLAN_CD) {
            this.forms.filterLovForm.patchValue({
              planCd: output.PLAN_CD,
              planName: output.PLAN_NAME,
              planDisp: `${output.PLAN_CD} - ${output.PLAN_NAME}`,
            });
          }
          else {
            this.forms.filterLovForm.patchValue({
              planCd: null,
              planName: null,
              planDisp: null,
            });
            this.btns.catZoneFireRatesPu.addDisabled = this.btns.catZoneFireRatesPu.formLovSearchDisabled = true;
          }
          this.getData.catZoneFireRatesPu();
        }
      });
    },
    class: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          moduleId: this.moduleId,
          col: 'DESC',
        },
      };
      this._matDialog.open(ClassLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
        if (dataOutput.button === 'OK') {
          let output = dataOutput.content;
          this.forms.resetCatZoneFireRatesPuForm();
          if (output.CLASS_CD) {
            this.forms.filterLovForm.patchValue({
              classCd: output.CLASS_CD,
              classCdName: output.CLASS_DESC,
              classCdDisp: `${output.CLASS_CD} - ${output.CLASS_DESC}`,
            });
          }
          else {
            this.forms.filterLovForm.patchValue({
              classCd: null,
              classCdName: null,
              classCdDisp: null,
            });
            this.btns.catZoneFireRatesPu.addDisabled = this.btns.catZoneFireRatesPu.formLovSearchDisabled = true;
          }
          this.getData.catZoneFireRatesPu();
        }
      });
    },
    peril: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'PERIL',
          moduleId: this.moduleId,
          lineCd: this.forms.filterLovForm.get('lineCd')?.value,
          lpad: 3,
        },
      };
      this._matDialog.open(PerilLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
        if (dataOutput.button === 'OK') {
          let output = dataOutput.content;
          this.forms.resetCatZoneFireRatesPuForm();
          if (output.PERIL_CD) {
            this.forms.filterLovForm.patchValue({
              perilCd: +output.PERIL_CD,
              perilName: output.PERIL_LNAME,
              perilDisp: `${output.PERIL_CD.toString().padStart(LOVOPTIONS.data.lpad, 0)} - ${output.PERIL_LNAME}`,
              catZoneCd: null,
              catZoneName: null,
              catZoneDisp: null,
            });
            let catPerilAccepted = [...this.catPerils.eq.map(Number), ...this.catPerils.ty.map(Number), ...this.catPerils.fl.map(Number)];
            this.btns.filterLov.catZoneLovSearchDisabled = !(catPerilAccepted.includes(+output.PERIL_CD) && ['HA'].includes(this.forms.filterLovForm.get('lineCd')?.value));
            // this.btns.filterLov.catZoneLovSearchDisabled = false;
          }
          else {
            this.forms.filterLovForm.patchValue({
              perilCd: null,
              perilName: null,
              perilDisp: null,
              catZoneCd: null,
              catZoneName: null,
              catZoneDisp: null,
            });
            this.btns.catZoneFireRatesPu.addDisabled = this.btns.catZoneFireRatesPu.formLovSearchDisabled = true;
            this.btns.filterLov.catZoneLovSearchDisabled = true;
          }
          this.getData.catZoneFireRatesPu();
        }
      });
    },
    catZone: () => {
      if (this.catPerils.eq.map(Number).includes(this.forms.filterLovForm.get('perilCd')?.value)) {
        this.openLov.eqZone();
      }
      else if (this.catPerils.ty.map(Number).includes(this.forms.filterLovForm.get('perilCd')?.value)) {
        this.openLov.tyZone();
      }
      else if (this.catPerils.fl.map(Number).includes(this.forms.filterLovForm.get('perilCd')?.value)) {
        this.openLov.flZone();
      }
    },
    eqZone: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          desc: "LONG",
          lpad: 2,
        },
      };
      this._matDialog.open(EQZoneLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
        if (dataOutput.button === 'OK') {
          let output = dataOutput.content;
          this.forms.resetCatZoneFireRatesPuForm();
          if (output.eqZoneCd) {
            this.forms.filterLovForm.patchValue({
              catZoneCd: +output.eqZoneCd,
              catZoneName: output.eqZoneDesc,
              catZoneDisp: `${output.eqZoneCd.toString().padStart(LOVOPTIONS.data.lpad, 0)} - ${output.eqZoneDesc}`,
            });
          }
          else {
            this.forms.filterLovForm.patchValue({
              catZoneCd: null,
              catZoneName: null,
              catZoneDisp: null,
            });
            this.btns.catZoneFireRatesPu.addDisabled = this.btns.catZoneFireRatesPu.formLovSearchDisabled = true;
          }
          this.getData.catZoneFireRatesPu();
        }
      });
    },
    flZone: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          desc: "LONG",
          lpad: 2,
        },
      };
      this._matDialog.open(FloodZoneLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
        if (dataOutput.button === 'OK') {
          let output = dataOutput.content;
          this.forms.resetCatZoneFireRatesPuForm();
          if (output.FLOOD_ZONE_CD) {
            this.forms.filterLovForm.patchValue({
              catZoneCd: output.FLOOD_ZONE_CD,
              catZoneName: output.FLOOD_LONG_DESC,
              catZoneDisp: `${output.FLOOD_ZONE_CD} - ${output.FLOOD_LONG_DESC ?? ''}`,
            });
          }
          else {
            this.forms.filterLovForm.patchValue({
              catZoneCd: null,
              catZoneName: null,
              catZoneDisp: null,
            });
            this.btns.catZoneFireRatesPu.addDisabled = this.btns.catZoneFireRatesPu.formLovSearchDisabled = true;
          }
          this.getData.catZoneFireRatesPu();
        }
      });
    },
    tyZone: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          desc: "LONG",
          lpad: 2,
        },
      };
      this._matDialog.open(TyphoonZoneLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
        if (dataOutput.button === 'OK') {
          let output = dataOutput.content;
          this.forms.resetCatZoneFireRatesPuForm();
          if (output.TYPHOON_ZONE_CD) {
            this.forms.filterLovForm.patchValue({
              catZoneCd: output.TYPHOON_ZONE_CD,
              catZoneName: output.TYPHOON_LONG_DESC,
              catZoneDisp: `${output.TYPHOON_ZONE_CD} - ${output.TYPHOON_LONG_DESC ?? ''}`,
            });
          }
          else {
            this.forms.filterLovForm.patchValue({
              catZoneCd: null,
              catZoneName: null,
              catZoneDisp: null,
            });
            this.btns.catZoneFireRatesPu.addDisabled = this.btns.catZoneFireRatesPu.formLovSearchDisabled = true;
          }
          this.getData.catZoneFireRatesPu();
        }
      });
    },
  };

  private getData = {
    catZoneFireRatesPu: () => {
      try {
        this.catZoneFireRatesPuTblConfig.tblData = [];
        if (this.forms.filterLovForm.get('classCd')?.value
          && this.forms.filterLovForm.get('lineCd')?.value
          && this.forms.filterLovForm.get('sublineCd')?.value
          && this.forms.filterLovForm.get('planCd')?.value
          && this.forms.filterLovForm.get('perilCd')?.value
          && this.forms.filterLovForm.get('catZoneCd')?.value) {
          this._jDS.contorlLoading(true);
          this.catZoneFireRatesPuTblConfig.loading = true;
          this._api.getCatZoneFireRatesPu({
            classCd: this.forms.filterLovForm.get('classCd')?.value,
            lineCd: this.forms.filterLovForm.get('lineCd')?.value,
            sublineCd: this.forms.filterLovForm.get('sublineCd')?.value,
            planCd: this.forms.filterLovForm.get('planCd')?.value,
            perilCd: this.forms.filterLovForm.get('perilCd')?.value,
            catZoneCd: this.forms.filterLovForm.get('catZoneCd')?.value,
            moduleId: this.moduleId,
            userId: this._userDetailService.userId,
            type: "MODULE",
          }).subscribe({
            next: (data: any) => {
              this._securityService.checkRequestKeyResponse(data, () => {
                this._securityService.hasValidCsrfToken(data, () => {
                  if (data.status === "SUCCESS") {
                    let content = JSON.parse(this._jDS.decrypt(data?.response));
                    // let maxNoOfFloorLength = 2;
                    // if (content.data && (content.data?.length > 0)) {
                    //   maxNoOfFloorLength = Math.max(...(content.data?.map((o: any) => o.NO_OF_FLOOR) ?? [0])).toString().length;
                    // }
                    // this.lpadNum.noOfFloor = (maxNoOfFloorLength < 2) ? 2 : maxNoOfFloorLength;
                    // this.catZoneFireRatesPuTblConfig.tblData = content.data?.map((o: any) => {
                    //   return { ...o, NO_OF_FLOOR: parseInt(o.NO_OF_FLOOR)?.toString().padStart((maxNoOfFloorLength < 2) ? 2 : maxNoOfFloorLength, "0") }
                    // });
                    this.catZoneFireRatesPuTblConfig.tblData = content.data;
                    this._jDS.contorlLoading(false);
                    this.catZoneFireRatesPuTblConfig.loading = false;
                    this.btns.catZoneFireRatesPu.addDisabled = this.btns.catZoneFireRatesPu.formLovSearchDisabled = false;
                    this.btns.catZoneFireRatesPu.saveDisabled = true;
                  }
                  else {

                    this._jDS.contorlLoading(false);
                    this.catZoneFireRatesPuTblConfig.loading = false;
                    this.btns.catZoneFireRatesPu.addDisabled = this.btns.catZoneFireRatesPu.formLovSearchDisabled = true;
                  }
                });
              });
            },
            error: (e: any) => {
              this._securityService.checkRequestKeyResponse(e, () => {

                this._jDS.contorlLoading(false);
                this.catZoneFireRatesPuTblConfig.loading = false;
                this.btns.catZoneFireRatesPu.addDisabled = this.btns.catZoneFireRatesPu.formLovSearchDisabled = true;
              });
            }
          });
        }
        else {
          this.catZoneFireRatesPuTblConfig.loading = false;
          this.btns.catZoneFireRatesPu.addDisabled = this.btns.catZoneFireRatesPu.formLovSearchDisabled = true;
        }
      }
      catch (e) {
    console.error(e);

      }
    },
  };

  public selections = {
    activeTypes: [
      { cd: 'A', name: 'Yes' },
      { cd: 'I', name: 'No' },
    ],
    defaultTypes: [
      { cd: 'Y', name: 'Yes' },
      { cd: 'N', name: 'No' },
    ],
  };

  public onRowClick(ev: any) {
    try {
      if (ev) {
        this.rowData = ev;
        this.forms.catZoneFireRatesPuForm.get('noOfFloors')?.disable();
        this.forms.catZoneFireRatesPuForm.get('effDateFrom')?.disable();
        this.forms.catZoneFireRatesPuForm.get('premRt')?.disable();
        this.populateFormFromTable();
      }
      else {
        this.rowData = null;
        this.forms.resetCatZoneFireRatesPuForm();
      }
    }
    catch (e) {
    console.error(e);
      this.forms.resetCatZoneFireRatesPuForm();
      this.rowData = null;
    }
  }

  private populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.catZoneFireRatesPu.updateDisabled = false;
      this.btns.catZoneFireRatesPu.deleteDisabled = !(this.rowData?.operation === 'I');
      this.forms.catZoneFireRatesPuForm.patchValue(this.catZoneFireRatesPuFormManipulate.dbToForm(data));
    }
    catch (e) {
    console.error(e);

    }
  }

  public onAdd(): void {
    try {
      this._formService.hideFormMsg("cat-zone-fire-rates-pu-error-message");
      if (this.forms.catZoneFireRatesPuForm.valid) {
        this.forms.catZoneFireRatesPuForm.patchValue({
          ...this.forms.filterLovForm.value
        });
        let rowToBeAdded: { [key: string]: any } = this.catZoneFireRatesPuFormManipulate.formToDb(this.forms.catZoneFireRatesPuForm.getRawValue());
        rowToBeAdded.operation = 'I';
        this.catZoneFireRatesPuTblConfig.tblData = [rowToBeAdded, ...this.catZoneFireRatesPuTblConfig.tblData];
        this.forms.resetCatZoneFireRatesPuForm();
        this.btns.catZoneFireRatesPu.saveDisabled = false;
      }
      else {
        this.showErrorValidator.catZoneFireRatesPu();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onUpdate(): void {
    try {
      this._formService.hideFormMsg("cat-zone-fire-rates-pu-error-message");
      if (this.forms.catZoneFireRatesPuForm.valid) {
        const indexOfRow = this.catZoneFireRatesPuTblConfig.tblData.indexOf(this.rowData);
        let rowToBeUpdated: { [key: string]: any } = this.catZoneFireRatesPuFormManipulate.formToDb(this.forms.catZoneFireRatesPuForm.getRawValue());
        rowToBeUpdated.operation = (this.rowData.operation === 'I') ? 'I' : 'U';
        this.forms.resetCatZoneFireRatesPuForm();
        this.catZoneFireRatesPuTblConfig.tblData[indexOfRow] = rowToBeUpdated;
        this.selectedRow = [];
        this.btns.catZoneFireRatesPu.saveDisabled = false;
        this.catZoneFireRatesPuTblConfig.tblData = [...this.catZoneFireRatesPuTblConfig.tblData];
      }
      else {
        this.showErrorValidator.catZoneFireRatesPu();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onDelete(): void {
    try {
      this.catZoneFireRatesPuTblConfig.tblData = this.catZoneFireRatesPuTblConfig.tblData.filter((row: any) => {
        return row !== this.rowData;
      });
      this.forms.resetCatZoneFireRatesPuForm();
      this.btns.catZoneFireRatesPu.saveDisabled = !(this.catZoneFireRatesPuTblConfig.tblData.filter((row: any) => ['I', 'U'].includes(row.operation)).length > 0);
    }
    catch (e) {
    console.error(e);

    }
  }

  public onSave(): void {
    try {
      this._jDS.contorlLoading(true);
      this.showForm = false;
      this._formService.showFormLoader(null, "cat-zone-fire-rates-pu-maintenance-loading", "Saving.<br>Please wait ...", null, null, true);
      let toBeSaved = this.catZoneFireRatesPuTblConfig.tblData.filter((row: any) => ['I', 'U'].includes(row.operation));
      if (toBeSaved.length > 0) {
        this._api.saveCatZoneFireRatesPu(toBeSaved).subscribe({
          next: (response: any) => {
            this._securityService.checkRequestKeyResponse(response, () => {
              this._securityService.hasValidCsrfToken(response, () => {
                if (response.status === 'SUCCESS') {
                  this.onComplete.catZoneFireRatesPu();
                }
                else {
                  this.onComplete.catZoneFireRatesPuError();
                }
              });
            });
          },
          error: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {

              this.onComplete.catZoneFireRatesPuError();
            });
          }
        });
      }
      else {
        this.onComplete.catZoneFireRatesPu();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  private onComplete = {
    catZoneFireRatesPu: () => {
      this._jDS.contorlLoading(false);
      this._formService.hideFormLoader("cat-zone-fire-rates-pu-maintenance-loading");
      this.openDialog('SUCCESS', this.moduleName.replace('Maintenance', ''), 'saved');
      this.btns.catZoneFireRatesPu.saveDisabled = true;
      this.showForm = true;
      this.forms.resetCatZoneFireRatesPuForm();
    },
    catZoneFireRatesPuError: () => {
      this._jDS.contorlLoading(false);
      this.openDialog('FAILED', this.moduleName.replace('Maintenance', ''), 'saving');
      this._formService.hideFormLoader("cat-zone-fire-rates-pu-maintenance-loading");
      this.showForm = true;
      this.forms.resetCatZoneFireRatesPuForm();
    },
  }

  openDialog(title: string, contentTitle: string, contentDetail: string) {
    try {
      this._matDialog.open(DialogMsgComponent, {
        disableClose: true,
        width: '512px',
        data: {
          title: title,
          content:
            title === 'SUCCESS'
              ? `${contentTitle} details successfully ${contentDetail}!`
              : `${contentTitle} details ${contentDetail} failed!`,
        },
      }).afterClosed().subscribe(() => {
        if (title === 'SUCCESS') {
          this.getData.catZoneFireRatesPu();
        }
      });
    }
    catch (e) {
    console.error(e);

    }
  }

  //* Validator Stuffs Down here */

  private checkDecimalsExceeded(digits: number, decimals: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value && parseFloat(this._formService.setToNumberWithDecimal(control.value));
      if (!value) return null;
      let isExceeded: boolean = value > +"".padEnd(digits, '9') + +('.' + ''.padEnd(decimals, '9'))
      return isExceeded ? { hasDecimalsExceeded: true } : null;
    }
  }

  private checkHasDefault(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const defaultVal = control.get('default')?.value;
      const noOfFloors = control.get('noOfFloors')?.value;
      const effDateValue = control.get('effDateFrom')?.value && this.datePipe.transform(control.get('effDateFrom')?.value, 'yyyy-MM-dd');
      const expDateValue = control.get('effDateTo')?.value && this.datePipe.transform(control.get('effDateTo')?.value, 'yyyy-MM-dd');
      let filteredRows = this.catZoneFireRatesPuTblConfig?.tblData?.filter((d: any) => {
        let dateOverlapBool = this.dateOverlapBool1(d['EFF_DATE_FROM'], d['EFF_DATE_TO'], effDateValue, expDateValue);
        let dateOverlapBool2 = this.dateOverlapBool2(d['EFF_DATE_FROM'], d['EFF_DATE_TO'], effDateValue, expDateValue);
        return d['NO_OF_FLOOR'] == noOfFloors && (dateOverlapBool || dateOverlapBool2);
      });
      let hasDefault: boolean = filteredRows?.filter((d: any) => {
        if (!this.rowData)
          return d.DEFAULT_TAG === 'Y';
        else {
          let dateOverlapBool1 = this.dateOverlapBool1(d['EFF_DATE_FROM'], d['EFF_DATE_TO'], this.rowData['EFF_DATE_FROM'], this.rowData['EFF_DATE_TO']);
          let dateOverlapBool2 = this.dateOverlapBool2(d['EFF_DATE_FROM'], d['EFF_DATE_TO'], this.rowData['EFF_DATE_FROM'], this.rowData['EFF_DATE_TO']);
          return !(parseInt(this.rowData['NO_OF_FLOOR']) == parseInt(d['NO_OF_FLOOR'])
            && (parseFloat(this.rowData['PREM_RT']) == parseFloat(d['PREM_RT']))
            && (dateOverlapBool1 || dateOverlapBool2)
          )
            && d.DEFAULT_TAG === 'Y';
        }
      }).length > 0 ? true : false;
      if (defaultVal === 'N' || !hasDefault)
        return null;
      return { hasDefault: true };
    }
  }

  private checkDupes(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const noOfFloors = control.get('noOfFloors')?.value;
      const effDateValue = control.get('effDateFrom')?.value && this.datePipe.transform(control.get('effDateFrom')?.value, 'yyyy-MM-dd');
      const expDateValue = control.get('effDateTo')?.value && this.datePipe.transform(control.get('effDateTo')?.value, 'yyyy-MM-dd');
      const premRt = control.get('premRt')?.value && parseFloat(this._formService.setToNumberWithDecimal(control.get('premRt')?.value));
      if (!noOfFloors || !effDateValue || !premRt) {
        return null;
      }
      let hasDuplicate: boolean = this.catZoneFireRatesPuTblConfig.tblData.filter((d: any) => {
        return this.checkBoolForDupeFilter(d.NO_OF_FLOOR, d.PREM_RT, d.EFF_DATE_FROM, d.EFF_DATE_TO, noOfFloors, premRt, effDateValue, expDateValue);
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupe: true } : null;
    }
  }

  private checkBoolForDupeFilter(dataNoOfFloor: number, dataPremRt: number, dataEffDate: string, dataExpDate: string | null,
    noOfFloorVal: number, premRtVal: number, effDateVal: string, expDateVal: string | null): boolean {
    if (!this.rowData) {
      let dateOverlapBool = this.dateOverlapBool1(dataEffDate, dataExpDate, effDateVal, expDateVal);
      let dateOverlapBool2 = this.dateOverlapBool2(dataEffDate, dataExpDate, effDateVal, expDateVal);
      return +noOfFloorVal === +dataNoOfFloor && +premRtVal === +dataPremRt && (dateOverlapBool || dateOverlapBool2);
    }
    let dateOverlapBool1 = this.dateOverlapBool1(dataEffDate, dataExpDate, this.rowData.EFF_DATE_FROM, this.rowData.EFF_DATE_TO);
    let dateOverlapBool2 = this.dateOverlapBool2(dataEffDate, dataExpDate, this.rowData.EFF_DATE_FROM, this.rowData.EFF_DATE_TO);
    let dateOverlapBool3 = this.dateOverlapBool1(dataEffDate, dataExpDate, effDateVal, expDateVal);
    let dateOverlapBool4 = this.dateOverlapBool2(dataEffDate, dataExpDate, effDateVal, expDateVal);
    return !(+this.rowData.NO_OF_FLOOR === +dataNoOfFloor && +this.rowData.PREM_RT === +dataPremRt && (dateOverlapBool1 || dateOverlapBool2))
      && (+noOfFloorVal === +dataNoOfFloor && +premRtVal === +dataPremRt && (dateOverlapBool3 || dateOverlapBool4));
  }

  private dateOverlapBool1(dataEffDate: string, dataExpDate: string | null, effDateVal: string, expDateVal: string | null): boolean {
    return (new Date(effDateVal) >= new Date(dataEffDate) && new Date(effDateVal) <= new Date(dataExpDate ?? effDateVal));
  }

  private dateOverlapBool2(dataEffDate: string, dataExpDate: string | null, effDateVal: string, expDateVal: string | null): boolean {
    return (new Date(dataEffDate) <= new Date(expDateVal ?? dataEffDate) && new Date(effDateVal) <= new Date(dataExpDate ?? dataEffDate));
  }

  public showErrorValidator = {
    catZoneFireRatesPu: () => {
      try {
        if (this.forms.catZoneFireRatesPuForm.errors) {
          const controlError = this.forms.catZoneFireRatesPuForm.errors;
          Object.keys(controlError).forEach(keyError => {
            if (keyError == 'hasDupe' && controlError[keyError] === true)
              this.errorMessage = "Unable to add record. Premium rate within the entered effectivity period under the same No. of Floors already exists.";
            else if (keyError == 'hasDefault' && controlError[keyError] === true)
              this.errorMessage = "Unable to add record. Default rate for the effectivity period already exists. Only one record should be tagged as default under the same period of effectivity under the same No. of Floors.";
          });
        }
        else if (this.forms.catZoneFireRatesPuForm.get('effDateFrom')?.errors || this.forms.catZoneFireRatesPuForm.get('effDateTo')?.errors) {
          this.errorMessage = "Invalid Effective/Expiry Date. Please input valid dates.";
        }
        else {
          Object.keys(this.forms.catZoneFireRatesPuForm.controls).forEach(key => {
            const controlErrors = this.forms.catZoneFireRatesPuForm.get(key)?.errors;
            if (controlErrors != null) {
              Object.keys(controlErrors).forEach(keyError => {
                if (keyError == 'required' && controlErrors[keyError] === true)
                  this.errorMessage = "There are missing information. Please provide necessary information needed.";
                else if (keyError == 'hasDefault' && controlErrors[keyError] === true)
                  this.errorMessage = "Unable to add record. Default rate for the effectivity period already exists. Only one record should be tagged as default under the same period of effectivity under the same No. of Floors.";
                else if (keyError == 'hasDecimalsExceeded' && controlErrors[keyError] === true && key === 'premRt')
                  this.errorMessage = "Invalid value in Premium Rate. The value entered exceeded the maximum limit.";
                else if (keyError == 'hasDecimalsExceeded' && controlErrors[keyError] === true && key === 'commRt')
                  this.errorMessage = "Invalid value in Commission Rate. The value entered exceeded the maximum limit.";
                else if (keyError == 'hasDupe' && controlErrors[keyError] === true)
                  this.errorMessage = "Unable to add record. Premium rate within the entered effectivity period under the same No. of Floors already exists.";
              });
            }
          });
        }
        this._formService.showFormMsg("cat-zone-fire-rates-pu-error-message", this.errorMessage, "E");
      }
      catch (e) {
    console.error(e);

      }
    },
  }

}
