import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { APICallService } from 'src/app/services/api-call.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogMsgComponent } from '../../../common/dialog-msg/dialog-msg.component';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from '../../../../services/json-data.service';
import { DatePipe } from '@angular/common';
import { CheckLovComponent } from 'src/app/components/common/guard/check-lov/check-lov.component';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { SecurityService } from 'src/app/services/security.service';

@Component({
  selector: 'vehicle-maintenance-make',
  templateUrl: './vehicle-maintenance-make.component.html',
  styleUrls: ['../vehicle-maintenance.component.css'],
})
export class VehicleMaintenanceMakeComponent implements OnInit {
  constructor(
    private api: APICallService,
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private formService: FormService,
    private _jDS: JsonDataService,
    private _dialogRef: MatDialogRef<VehicleMaintenanceMakeComponent>,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService,
  ) {
    this.userIdLoggedIn = this._jDS.retrieveFromStorage('userId') || "";
  }

  private vehicleModuleId: string = 'BMM025';
  private userIdLoggedIn: string = '';
  public invalidShow: boolean = false;
  public updateBtnDisabled: boolean = true;
  public deleteBtnDisabled: boolean = true;
  public tempDataRow: boolean = false;
  public saveBtnDisabled: boolean = true;
  private rowData: any;
  private indexRow: any;

  public savingDiaShow: boolean = false;
  public savingShow: boolean = true;
  public savedShow: boolean = false;
  public savedShowError: boolean = false;
  public showForm: boolean = true;

  vehicleMakeForm = this.fb.group({
    carCompanyCD: [null], //CAR_COMPANY_CD
    carCompany: [null, [Validators.required, Validators.maxLength(50)]], //CAR_COMPANY
    //make: [null], //CAR_COMPANY_CD + CAR_COMPANY
    active: [true], //ACTIVE_TAG
    remarks: [null, [Validators.maxLength(4000)]], //REMARKS
    userId: [null], //USER_ID
    lastUpdate: [null], //LAST_UPDATE
  });

  formReset() {
    try {
      this.vehicleMakeForm.reset();
      this.vehicleMakeForm.get('active')?.setValue(true);
    }
    catch (e) {
      console.error(e);
    }
  }

  tblConfig: any = {
    cols: [
      {
        key: 'CAR_COMPANY_CD',
        header: 'Make',
        dataType: 'string',
      },
      {
        key: 'CAR_COMPANY',
        header: 'Model',
        dataType: 'string',
        disabled: false,
      },
      {
        key: 'ACTIVE_TAG',
        header: 'A',
        dataType: 'checkbox',
        disabled: true,
        width: '10%',
      },
    ],
    tblData: [],
    selection: 'single',
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    // tblCaption: "VEHICLES (MAKE)",
    totalRecords: 15,
    loading: false,
  };

  ngOnInit(): void {
    this.getVehiclesMake();
  }

  getVehiclesMake() {
    this.tblConfig.loading = true;
    this._jDS.contorlLoading(true);
    this.api.getAllVehiclesCompany({
      moduleId: this.vehicleModuleId,
      userId: this._userDetailService.userId,
      type: "MODULE",
    }).subscribe({
      next: (data: any) => {
        this._securityService.checkRequestKeyResponse(data, () => {
          this._securityService.hasValidCsrfToken(data, () => {
            if (data.status === "SUCCESS") {
              let content = JSON.parse(this._jDS.decrypt(data?.response));
              this.tblConfig.tblData = content?.data ?? [];
            }
            this._jDS.contorlLoading(false);
            this.tblConfig.loading = false;
          });
        });
      },
      error: (data: any) => {
        console.error(data);
        this._securityService.checkRequestKeyResponse(data, () => {
          this.tblConfig.loading = false;
          this._jDS.contorlLoading(false);
        });
      },
    });
  }

  onRowClick(ev: any) {
    try {
      this.indexRow = this.tblConfig.tblData.map((d: any) => d).indexOf(ev);
      ev.update = true;
      this.rowData = ev;
      this.populateFormFromTable();
    } catch (e) {
      this.formReset();
      this.rowData = null;
      this.indexRow = null;
      this.updateBtnDisabled = true;
      this.deleteBtnDisabled = true;
      this.tempDataRow = false;
    }
  }

  populateFormFromTable() {
    try {
      let dataModel = this.rowData;
      this.updateBtnDisabled = !dataModel.update;
      this.deleteBtnDisabled = !dataModel.temp;
      this.tempDataRow = !dataModel.temp;
      this.vehicleMakeForm.setValue({
        carCompanyCD: dataModel.CAR_COMPANY_CD, //CAR_COMPANY_CD
        carCompany: dataModel.CAR_COMPANY, //CAR_COMPANY
        //make: this.pad(dataModel.CAR_COMPANY_CD,5) + " - " + dataModel.CAR_COMPANY,
        active: dataModel.ACTIVE_TAG === 'A' ? true : false,
        remarks: dataModel.REMARKS != undefined ? dataModel.REMARKS : null,
        userId: dataModel.USER_ID != undefined ? dataModel.USER_ID : null,
        lastUpdate: dataModel.LAST_UPDATE
          ? new DatePipe('en_PH').transform(dataModel.LAST_UPDATE, 'MM/dd/YYYY h:mm:ss a', '+0000')
          : null,
      });
    }
    catch (e) {
      console.error(e);
    }
  }

  openDialog(title: string, contentDetail: string) {
    try {
      this._matDialog.open(DialogMsgComponent, {
        disableClose: true,
        width: '512px',
        data: {
          title: title,
          content:
            title === 'SUCCESS'
              ? `Vehicle Make details successfully ${contentDetail}!`
              : `Vehicle Make details ${contentDetail} failed!`,
        },
      });
    }
    catch (e) {
      console.error(e);
    }
  }

  //pad zeroes to number
  private pad(str: string, max: number): string {
    try {
      str = str.toString();
      return str.length < max ? this.pad('0' + str, max) : str;
    }
    catch (e) {
      console.error(e);
      return "";
    }
  }

  onSave(): void {
    try {
      this.formReset();
      this.formService.showFormLoader(null, "vehicle-form-make", "Saving.<br>Please wait ...", null, null, true);
      this.showForm = false;
      this.savingDiaShow = true;
      this.savingShow = true;
      this.savedShow = false;
      this.savedShowError = false;
      this.saveBtnDisabled = true;
      let toBeInsertedUpdated = this.tblConfig.tblData.filter((d: any) => {
        return d.temp === true || d.onDbButUpdatedTemp === true;
      });
      this._jDS.contorlLoading(true);
      if (toBeInsertedUpdated.length > 0) {
        toBeInsertedUpdated.map((d: any) => {
          d.USER_ID = this.userIdLoggedIn;
        });
        this.api.saveVehicleCompany(toBeInsertedUpdated).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                if (data.status === 'SUCCESS') this.onComplete();
                else this.onCompleteError();
              });
            });
          },
          error: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              console.error(data);
              this.onCompleteError();
            });
          },
        });
      }
      else {
        this.onComplete();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  onComplete() {
    try {
      this._jDS.contorlLoading(false);
      this.getVehiclesMake();
      this.formService.hideFormLoader("vehicle-form-make");
      this.showForm = true;
      this.savingShow = false;
      this.savedShow = true;
      this.openDialog('SUCCESS', 'saved');
      setTimeout(() => {
        this.savingDiaShow = false;
      }, 3000);
    }
    catch (e) {
      console.error(e);
    }
  }

  onCompleteError() {
    try {
      this._jDS.contorlLoading(false);
      this.formService.hideFormLoader("vehicle-form-make");
      this.showForm = true;
      this.savingShow = false;
      this.savedShowError = true;
      this.openDialog('FAILED', 'saving');
      setTimeout(() => {
        this.savingDiaShow = false;
      }, 3000);
      this.saveBtnDisabled = false;
    }
    catch (e) {
      console.error(e);
    }
  }

  onDelete(): void {
    try {
      this.tblConfig.tblData = this.tblConfig.tblData.filter((d: any) => {
        return d !== this.rowData;
      });
      this.formReset();
      this.updateBtnDisabled = true;
      this.deleteBtnDisabled = true;
      //this.openDialog('SUCCESS','deleted');
    }
    catch (e) {
      console.error(e);
    }
  }

  onAdd(): void {
    //this.vehicleMakeForm.value;
    try {
      this.formService.hideFormMsg("vehicle-make-error-message");
      if (!this.vehicleMakeForm.invalid) {
        this.invalidShow = false;
        let objectToBeAdded = {
          CAR_COMPANY_CD: this.vehicleMakeForm.get('carCompanyCD')?.value,
          CAR_COMPANY: this.vehicleMakeForm
            .get('carCompany')
            ?.value.toUpperCase(),
          ACTIVE_TAG: this.vehicleMakeForm.get('active')?.value ? 'A' : 'I',
          REMARKS: this.vehicleMakeForm.get('remarks')?.value,
          USER_ID: null,
          LAST_UPDATE: null,
          temp: true,
        };
        this.tblConfig.tblData = [objectToBeAdded, ...this.tblConfig.tblData];
        this.tempDataRow = false;
        this.formReset();
        this.saveBtnDisabled = false;
        //this.openDialog('SUCCESS','added');
      } else {
        this.invalidShow = true;
        let errorMessage = "There are missing information. Please provide necessary infomration needed.";
        this.formService.showFormMsg("vehicle-make-error-message", errorMessage, "E");
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  onUpdate() {
    try {
      if (!this.vehicleMakeForm.invalid) {
        this.formService.hideFormMsg("vehicle-make-error-message");
        this.invalidShow = false;
        let updatedRow: { [k: string]: any } = {
          CAR_COMPANY_CD: this.vehicleMakeForm.get('carCompanyCD')?.value,
          CAR_COMPANY: this.vehicleMakeForm
            .get('carCompany')
            ?.value.toUpperCase(),
          ACTIVE_TAG: this.vehicleMakeForm.get('active')?.value ? 'A' : 'I',
          REMARKS: this.vehicleMakeForm.get('remarks')?.value,
          USER_ID: null,
          LAST_UPDATE: null,
        };
        if (this.rowData.temp) {
          updatedRow.temp = true;
          this.tblConfig.tblData[this.indexRow] = updatedRow;
        } else {
          updatedRow.onDbButUpdatedTemp = true;
          this.tblConfig.tblData[this.indexRow] = updatedRow;
        }
        this.formReset();
        this.saveBtnDisabled = false;
        this.updateBtnDisabled = true;
        this.deleteBtnDisabled = true;
        this.tempDataRow = false;
        //this.openDialog('SUCCESS','updated');
      } else {
        this.invalidShow = true;
        let errorMessage = "There are missing information. Please provide necessary infomration needed.";
        this.formService.showFormMsg("vehicle-make-error-message", errorMessage, "E");
      }
      this.tblConfig.tblData = [...this.tblConfig.tblData];
    }
    catch (e) {
      console.error(e);
    }
  }

  onReturn() {
    if (this.tblConfig.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true)).length > 0) {
      this._matDialog.open(CheckLovComponent, {
        width: '512px',
      }).afterClosed().subscribe((dOut: any) => {
        if (dOut) {
          this._dialogRef.close();
        }
      });
    }
    else
      this._dialogRef.close();
  }
}
