import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

@Component({
    selector: 'acc-type-lov',
    templateUrl: './acc-type-lov.component.html',
    styleUrls: ['./acc-type-lov.component.css']
})
export class AccountTypeLovComponent implements OnInit {

    private selectedData: any;

    public tableSelected: any;

    public titleLov: string = "USER TYPE";

    moduleId: string = 'BMM093';

    public user: any = [];

    accTypeTable: any = {
        cols: [
            {
                key: "USER_TYPE",
                header: "User Type Code",
                dataType: "string",
                width: '30%'
            },
            {
                key: "USER_TYPE_DESC",
                header: "User Type",
                dataType: "string",
                width: '70%'
            }
        ],
        tblData: [],
        selection: "single",
        paginator: true,
        rowsPerPage: 10,
        lazy: false,
        totalRecords: 15,
        loading: false,
    };

    constructor(
        private dialogRef: MatDialogRef<AccountTypeLovComponent>,
        private _api: APICallService,
        private securityService: SecurityService,
        private jsonDataService: JsonDataService,
        private appMessageService: AppMessageService,
        private userDetailService: UserDetailsService,
        @Inject(MAT_DIALOG_DATA) public fromMainData: any,
    ) { }

    ngOnInit(): void {
        try {
            this.tableSelected = this.accTypeTable;
            this.getAccTypeData();
        } catch (e) {
    console.error(e);
            
        }
    }

    onRowClick(ev: any) {
        try {
            this.selectedData = ev;
        } catch (e) {
    console.error(e);
            
        }
    }

    getAccTypeData() {
        try {
            this.accTypeTable.loading = true;
            this.jsonDataService.contorlLoading(true);
            this.user = [];
            let rows = this.fromMainData.rows || [];
            
            for (const userType of rows) {
                this.user.push(userType.USER_TYPE);
            }
            
            this._api.getAllAccType({
                moduleId: this.fromMainData.moduleId,
                userId: this.userDetailService.userId,
                type: "LOV"
            }).subscribe({
                next: (data: any) => {
                    this.securityService.checkRequestKeyResponse(data, () => {
                        this.securityService.hasValidCsrfToken(data, () => {
                            let accType = JSON.parse(this.jsonDataService.decrypt(data.response));
                            if (accType.moduleId === this.moduleId) {
                                this.jsonDataService.contorlLoading(false);
                                if (rows.length === 0) {
                                    this.tableSelected.tblData = accType.data.filter((e: any) => e.ACTIVE_TAG == 'A');
                                } else {
                                    this.tableSelected.tblData = accType.data.filter((d: any)=> !this.user.includes(d.USER_TYPE) && d.ACTIVE_TAG == 'A');
                                }
                                this.accTypeTable.loading = false;
                            } else {
                                this.accTypeTable.loading = false;
                                this.jsonDataService.contorlLoading(false);
                                this.appMessageService.showAppMessage("You do not have the correct privilege to access the data. Kindly contact your administrator for assistance.", "error");
                            }
                        })
                    });
                },
                error: (e: any) => {
                    this.accTypeTable.loading = false;
                    this.jsonDataService.contorlLoading(false);
                    this.appMessageService.showAppMessage(e, "error");
                }
            });
        } catch (e) {
    console.error(e);
            this.accTypeTable.loading = false;
            this.jsonDataService.contorlLoading(false);
        }
    }

    onClose(str: string) {
        try {
            if (this.selectedData == '' || this.selectedData == null) {
                this.dialogRef.close({ content: '', button: str });
            } else {
                this.dialogRef.close({ content: this.selectedData, button: str });
            }
        } catch (e) {
    console.error(e);
            
        }
    }
}
