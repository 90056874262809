<section id='curr-maint'>
    <div class="p-datatable mtn-container">
        <div class="mtn-title">
            <p>{{moduleTitle}}</p>
        </div>
        <!-- Red Title Border -->
        <div class="p-datatable-header"><span class="header-title">ACCOUNT TYPE MAINTENANCE</span></div>
        <!-- Container for Currency Table using prime ng -->
        <div class = 'table-curr'>
            <osp-table [tblConfig]="tblConfigAccountType" (onRowClick)="onRowClickAccountType($event)"></osp-table>
        </div>
        <!-- Loading Animation -->
        <div id="generate-curr-form">
            <div class="form-loader"></div>
        </div>
        <!-- Container for Currency Fields -->
        <div class="form-content">
            
            <form [formGroup]="accountTypeForm" class="form" *ngIf="noLoadingInProcesstblConfigAccountType">
                <div id="account-type-error-message"></div>
                <div class="form-container-grid">
                    <div class="field required">
                        <label>User Type Code</label>
                    </div>
                    <div class="">
                        <input formControlName="userTypeCd" type="text" maxlength="1" (input)="autoUppercase($event)"  [readonly]="isAccountTypeSelected">
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag">
                            <option value="A" selected>Yes</option>
                            <option value="I">No</option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>User Type Description</label>
                    </div>
                    <div class="">
                        <input formControlName="userTypeDesc" type="text" maxlength="100">
                    </div>
                    <div class="field required">
                        <label>Edit Amount Covered</label>
                    </div>
                    <div class="">
                        <select formControlName="editAmtCovered">
                            <option value="Y" selected>Yes</option>
                            <option value="N">No</option>
                        </select>
                    </div>
                    <div class="field required">
                        <label>Edit Premium Rate</label>
                    </div>
                    <div class="">
                        <select formControlName="editPremRate">
                            <option value="Y" selected>Yes</option>
                            <option value="N">No</option>
                        </select>
                    </div>
                    <div class="field">
                        <label>Edit Premium Amount</label>
                    </div>
                    <div class="">
                        <select formControlName="editPremAmt">
                            <option value="Y" selected>Yes</option>
                            <option value="N">No</option>
                        </select>
                    </div>
                    <div class="field required">
                        <label>Hide Premium Rate</label>
                    </div>
                    <div class="">
                        <select formControlName="hidePremRate">
                            <option value="Y" selected>Yes</option>
                            <option value="N">No</option>
                        </select>
                    </div>
                    <div class="field required">
                        <label>Hide Commission</label>
                    </div>
                    <div class="">
                        <select formControlName="hideComm">
                            <option value="Y" selected>Yes</option>
                            <option value="N">No</option>
                        </select>
                    </div>
                    <div class="field">
                        <label>Main User Type</label>
                    </div>
                    <div class="">
                        <select formControlName="mainUserType">
                            <option *ngFor="let userType of selections.userTypeDropDown" [value]="userType.value">
                                {{ userType.text }}
                            </option>
                        </select>
                    </div>

                    <div class="field">
                        <label>Agent</label>
                    </div>
                    <div class="">
                        <input formControlName="agentDisp">
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['agent']()">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>

                    <div class="field">
                        <label>Project</label>
                    </div>
                    <div class="">
                        <input formControlName="projectDisp">
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['project']()"
                            >
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                    
                    <div class="field">
                        <label>Client</label>
                    </div>
                    <div class="">
                        <input formControlName="clientTypeDisp">
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['clientType']('FILTER')" 
                             >
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                    
                    <div class="field">
                        <label>Risk Profile</label>
                    </div>
                    <div class="">
                        <input formControlName="riskProfDisp">
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['riskProf']()" 
                             >
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>

                    <div class="field">
                        <label>Enable One Time Pin</label>
                    </div>
                    <div class="">
                        <select formControlName="enOtp">
                            <option value="Y" selected>Yes</option>
                            <option value="N">No</option>
                        </select>
                    </div>

                    <div class="field">
                        <label>Enable Client Code</label>
                    </div>
                    <div class="">
                        <select formControlName="enClientCd">
                            <option value="Y" selected>Yes</option>
                            <option value="N">No</option>
                        </select>
                    </div>

                    <div class="field">
                        <label>Payment Terms</label>
                    </div>
                    <div class="">
                        <select formControlName="enPaytTerms">
                            <option value="Y" selected>Yes</option>
                            <option value="N">No</option>
                        </select>
                    </div>

                    <div class="field">
                        <label>Payment Terms Path</label>
                    </div>
                    <div class="span">
                        <input formControlName="paytTermsPath" type="text">
                    </div>

                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" type="text">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUpdateName">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUserUpdate">
                    </div>

                </div>
                <div class='btn-row'>
                    <button type="button" id='btn-add' *ngIf="!isAccountTypeSelected" title='Add an Account Type' class="btn btn2" value='ADD' type="submit" (click)='addAccountType()'>ADD</button>
                    <button type="button" id='btn-update' *ngIf="isAccountTypeSelected" title='Update an Account Type' class="btn btn2" value='UPDATE' type="submit" (click)='updateAccountType()'>UPDATE</button>
                    <button type="button" id='btn-delete' title='Remove an Account Type' class="btn btn2" value='DELETE' type="submit" (click)='deleteAccountType()' [disabled]='deleteDisabledAccountType'>DELETE</button>
                </div> 

                <div class="mtn-title"></div>

                <div class='btn-row'>
                    <button type="button" id='btn-save' title='Save changes on an Account Type details' class="btn btn2" value='SAVE' type="submit" (click)='toSaveAccountType()' [disabled]='saveDisabledAccountType'>SAVE</button>
                </div>
            </form>
        </div>  

        <!-- Red Title Border -->
        <div class="p-datatable-header"><span class="header-title">AMOUNT COVERED AND PREMIUM AMOUNT LIMIT PER ACCOUNT TYPE</span></div>
        <!-- Container for Currency Table using prime ng -->
        <div class = 'table-curr'>
            <osp-table [tblConfig]="tblConfigAmtCoveredPremAmtLmtPerAccType" (onRowClick)="onRowClickAmtCoveredPremAmtLmtPerAccType($event)" [selectedRow]="rowClickedData2"></osp-table>
        </div>
        <!-- Loading Animation -->
        <div id="generate-curr-rt-form">
            <div class="form-loader"></div>
        </div>
        <!-- Container for Tax Fields -->
        <div class="form-content">
            
            <form [formGroup]="amtCoveredPremAmtLmtPerAccTypeForm" class="form" *ngIf="noLoadingInProcessAmtCoveredPremAmtLmtPerAccType">
                <div id="account-type-amt-error-message"></div>
                <div class="form-container-grid">
                    <div class="field required">
                        <label>User Level</label>
                    </div>
                    <div class="">
                        <select formControlName="userLvl">
                            <option *ngFor="let userLvl of selections.userLvlDropDown" [value]="userLvl.value">
                                {{ userLvl.value + ' - ' + userLvl.text }}
                            </option>
                        </select>
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag">
                            <option value="A" selected>Yes</option>
                            <option value="I">No</option>
                        </select>
                    </div>
                    <div class="field required">
                        <label>Item Type</label>
                    </div>
                    <div class="">
                        <select formControlName="itemTypeCd">
                            <option *ngFor="let itemTypeCd of selections.itemTypeCdDropDown" [value]="itemTypeCd.value">
                                {{ itemTypeCd.value + ' - ' + itemTypeCd.text }}
                            </option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>Line</label>
                    </div>
                    <div class="">
                        <input formControlName="lineCdDisp" readonly>
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['line']()" [disabled]="isAmtCoveredPremAmtLmtPerAccTypeSelected&&deleteDisabledAmtCoveredPremAmtLmtPerAccType">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                    
                    <div class="field required">
                        <label>Peril</label>
                    </div>
                    <div class="span lov-search">
                        <input type="text" readonly formControlName="perilField">
                        <button type="button" class="btn-search btn" (click)="openLov['peril']()" [disabled]="!amtCoveredPremAmtLmtPerAccTypeForm.get('lineCd')?.value||(isAmtCoveredPremAmtLmtPerAccTypeSelected&&deleteDisabledAmtCoveredPremAmtLmtPerAccType)">
                            <mat-icon>
                                search
                            </mat-icon>
                        </button>
                    </div>

                    <div class="field">
                        <label>Amount Covered Limit</label>
                    </div>
                    <div class="">
                        <input formControlName="amtCoveredLmt" type="text" decimal [digits]="14" [decimals]="2">
                    </div>

                    <div class="field">
                        <label>Amount Covered Min. Percentage</label>
                    </div>
                    <div class="">
                        <input formControlName="amtCoveredMinPct" type="text" decimal [digits]="3" [decimals]="9">
                    </div>

                    <div class="field">
                        <label>Amount Covered Max Percentage</label>
                    </div>
                    <div class="">
                        <input formControlName="amtCoveredMaxPct" type="text" decimal [digits]="3" [decimals]="9">
                    </div>

                    <div class="field">
                        <label>Min. Premium Rate</label>
                    </div>
                    <div class="">
                        <input formControlName="minPremRate" type="text" decimal [digits]="3" [decimals]="9">
                    </div>

                    <div class="field">
                        <label>Max Premium Rate</label>
                    </div>
                    <div class="">
                        <input formControlName="maxPremRate" type="text"  decimal [digits]="3" [decimals]="9">
                    </div>

                    <div></div>
                    <div></div>

                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" type="text">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUpdateName">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUserUpdate">
                    </div>

                </div>
                <div class='btn-row'>
                    <button type="button" id='btn-add' *ngIf="!isAmtCoveredPremAmtLmtPerAccTypeSelected" title='Register a new rate' class="btn btn2" value='ADD' (click)='addAmtCoveredPremAmtLmtPerAccType()' type="submit">ADD</button>
                    <button type="button" id='btn-update' *ngIf="isAmtCoveredPremAmtLmtPerAccTypeSelected" title='Update a rate' class="btn btn2" value='UPDATE' (click)='updateAmtCoveredPremAmtLmtPerAccType()' type="submit">UPDATE</button>
                    <button type="button" id='btn-delete' title='Remove a rate' class="btn btn2" value='DELETE' type="submit" (click)='deleteAmtCoveredPremAmtLmtPerAccType()' [disabled]='deleteDisabledAmtCoveredPremAmtLmtPerAccType'>DELETE</button>
                </div> 

                <div class="mtn-title"></div>

                <div class='btn-row'>
                    <button type="button" id='btn-save' title='Save changes on currency rate details' class="btn btn2" value='SAVE' type="submit" (click)='toSaveAmtCoveredPremAmtLmtPerAccType()' [disabled]='saveDisabledAmtCoveredPremAmtLmtPerAccType'>SAVE</button>
                </div>
            </form>
        </div> 
    </div>
</section>