<section id="geographic-zoning">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <osp-table [tblConfig]="dataTable" (onRowClick)="onRowClick($event)" [selectedRow]="selectedRow"></osp-table>

        <!-- <div id="geographic-zoning-form"><div class="form-loader"></div></div> -->

        <div class="form-content" [hidden]="formHider">

            <form [formGroup]="dataForm" class="form">
                <div id="geographic-zoning-error-message"></div>
                <div class="form-container-grid">
                    
                    <div class="field">
                        <label>Code</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="ZONE_CD">
                    </div>

                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="ACTIVE_TAG">
                            <option value="A">Yes</option>
                            <option value="I">No</option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>Zone Name</label>
                    </div>
                    <div class="span">
                        <input formControlName="ZONE_NAME" maxlength="250">
                    </div>

                    <div class="field required">
                        <label>Description</label>
                    </div>
                    <div class="span">
                        <input formControlName="ZONE_DESC" maxlength="1000">
                    </div>

                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="REMARKS" maxlength="4000">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input formControlName="USER_ID">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="LAST_UPDATE">
                    </div>
                </div>
            </form>

            <div class="btn-grp">
                <button type="button" class="btn btn2" [hidden]="!addUpdateFlag" (click)="addRow()">ADD</button>
                <button type="button" class="btn btn2" [hidden]="addUpdateFlag" (click)="onUpdate()">UPDATE</button>
                <button type="button" class="btn btn2" [disabled]="deleteDisabler" (click)="deleteRow()">DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-grp">
                <button type="button" class="btn btn2" [disabled]="saveDisabler" (click)="onSave()">SAVE</button>
            </div>
        </div>
    </div>
</section>