import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { CustomValidators } from 'src/app/utils/custom-validator';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';

@Component({
  selector: 'tariff-risk-code-maintenance',
  templateUrl: './tariff-risk-code-maintenance.component.html',
  styleUrls: ['./tariff-risk-code-maintenance.component.css']
})
export class TariffRiskCodeMaintenanceComponent implements OnInit {


  addUpdateFlag: boolean = true;
  datePipe = new DatePipe('en-us');
  selectedRow: any;
  tempNewData: any = [];
  tempUpdateData: any = [];
  userId: string = this.userDetailService.userId;
  errorMessage: string = '';
  saveDisabler: boolean = true;
  deleteDisabler: boolean = true;
  formHider: boolean = false;
  moduleId: string = 'BMM111';
  moduleName = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId === 'BMM111' })[0].moduleDesc.toUpperCase();
  
  tariffRiskCodeTable: any = {
    cols: [
      {
        key: "TARIFF_CD",
        header: "Tariff Short Description",
        dataType: "string"
      },
      {
        key: "TARIFF_DESC",
        header: "Other Details",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  tariffRiskCodeForm = this.fb.group({
    TARIFF_CD: ['', [Validators.required, this.duplicateTariffCode(), CustomValidators.requiredTrim]],
    ACTIVE_TAG: ['A', [Validators.required]],
    TARIFF_DESC: ['', [Validators.required, CustomValidators.requiredTrim]],
    REMARKS: [''],
    USER_ID: [{value: '', disabled: true}],
    LAST_UPDATE: [{value: '', disabled: true}],
  });

  constructor(
    private fb: FormBuilder,
    private api: APICallService,
    private dialog: MatDialog,
    private jsonDataService: JsonDataService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    private formService: FormService,
    private securityService: SecurityService
  ) { }

  ngOnInit(): void {
    try {
      this.getTariff();
    } catch(e) {
      
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempNewData!='' || this.tempUpdateData!='') {
      return false;
    } else {
      return true;
    }
  }

  duplicateTariffCode(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      let tariffCodeDuplicate = this.tariffRiskCodeTable.tblData.filter(function(e: any) {
        return e.TARIFF_CD.trim()==control.value.trim();
      });
      if(tariffCodeDuplicate!='') {
        return { "tariffDuplicateCode": { value: control.value } }
      }
      return null;
    }
  }

  getTariff(): void {
    try {
      this.tariffRiskCodeTable.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.api.getTariff({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE"
      }).subscribe((data: any) => {
        this.securityService.checkRequestKeyResponse(data, () => {
          this.securityService.hasValidCsrfToken(data, () => {
            if (data.status === "SUCCESS") {
              data = JSON.parse(this.jsonDataService.decrypt(data.response));
              this.tariffRiskCodeTable.loading = false;
              this.jsonDataService.contorlLoading(false);
              this.tariffRiskCodeTable.tblData = data;
            } else {
                this.jsonDataService.contorlLoading(false);
                this.tariffRiskCodeTable.loading = false;
                this.appMessageService.showAppMessage(data.message, "error");
            }
          });
        });
      });
    } catch(e) {
      
    }
  }

  onRowClick(ev: any): void {
    try {
      if(ev!=null) {
        this.selectedRow = ev;
        this.addUpdateFlag = false;
        this.formLoader();
      } else {
        this.selectedRow = '';
        this.addUpdateFlag = true;
        this.formClearer();
      }
      this.deleteEnabler();
    } catch(e) {
      
    }
  }

  formLoader() {
    try {
      this.tariffRiskCodeForm.get("TARIFF_CD")?.disable();
      this.tariffRiskCodeForm.patchValue({
        TARIFF_CD:    this.selectedRow.TARIFF_CD,
        ACTIVE_TAG:   this.selectedRow.ACTIVE_TAG,
        TARIFF_DESC:  this.selectedRow.TARIFF_DESC,
        REMARKS:      this.selectedRow.REMARKS,
        USER_ID:      this.selectedRow.USER_ID,  
        LAST_UPDATE:  this.selectedRow.LAST_UPDATE
      });
    } catch(e) {
      
    }
  }

  deleteEnabler() {
    try {
      let tempDeleteFlag = this.tempNewData.filter((e: any) => {
        return e.TARIFF_CD == this.selectedRow.TARIFF_CD
      });
      if(tempDeleteFlag=='' || tempDeleteFlag==null) {
        this.deleteDisabler = true;
      } else {
        this.deleteDisabler = false;
      }
    } catch(e) {
      
    }
  }

  addRow(): void {
    try {
      if(this.tariffRiskCodeForm.valid) {
        this.formService.hideFormMsg("tariff-risk-code-error-message");
        this.tableInserter();
        this.tempAddInserter();
        this.formClearer();
        this.saveDisabler = false;
      } else {
        this.errorMessageSetter();
      }
    } catch(e) {
      
    }
  }

  tableInserter(): void {
    try {
      this.tariffRiskCodeTable.tblData.unshift(this.formValues());
      this.tariffRiskCodeTable.tblData = [...this.tariffRiskCodeTable.tblData];
    } catch(e) {
      
    }
  }

  tempAddInserter(): void {
    try {
      this.tempNewData.push(this.formValues());
    } catch(e) {
      
    }
  }

  formClearer() {
    try {
      this.tariffRiskCodeForm.get("TARIFF_CD")?.enable();
      this.tariffRiskCodeForm.patchValue({
        TARIFF_CD:    "",
        ACTIVE_TAG:   "A",
        TARIFF_DESC:  "",
        REMARKS:      "",
        USER_ID:      "",
        LAST_UPDATE:  "",
      });
    } catch(e) {
      
    }
  }

  formValues(): any {
    try {
      return {
        TARIFF_CD:    this.tariffRiskCodeForm.get('TARIFF_CD')?.value,
        ACTIVE_TAG:   this.tariffRiskCodeForm.get('ACTIVE_TAG')?.value,
        TARIFF_DESC:  this.tariffRiskCodeForm.get('TARIFF_DESC')?.value,
        REMARKS:      this.tariffRiskCodeForm.get('REMARKS')?.value,
        USER_ID:      this.userId,
        LAST_UPDATE:  this.tariffRiskCodeForm.get('LAST_UPDATE')?.value,
      }
    } catch(e) {
      
    }
  }

  errorMessageSetter() {
    try {
      if(this.tariffRiskCodeForm.invalid) {
        for(const field in this.tariffRiskCodeForm.controls) {
          const controlName = this.tariffRiskCodeForm.get(field);
          if(controlName?.errors?.required) {
            this.errorMessage = 'There are missing information. Please provide necessary information needed.';
          }
          if(controlName?.errors?.tariffDuplicateCode) {
            this.errorMessage = 'Tariff Code already exists. Please add a unique Tariff Code.';
          }
          this.formService.showFormMsg("tariff-risk-code-error-message", this.errorMessage, "E");
        }
      }
    } catch(e) {
      
    }
  }

  onUpdate(): void {
    try {
      if(this.tariffRiskCodeForm.valid) {
        this.updateTable();
        if(this.deleteDisabler) {
          this.updateDbData();
        } else {
          this.updateNewData();
        }
        this.selectedRow = '';
        this.addUpdateFlag = true;
        this.formClearer();
        this.saveDisabler = false;
        this.deleteEnabler();
        this.formService.hideFormMsg('tariff-risk-code-error-message');
      } else {
        this.errorMessageSetter();
      }
    } catch(e) {
      
    }
  }

  updateTable() {
    try {
      let updateIndex = this.tariffRiskCodeTable.tblData.indexOf(this.selectedRow);
      this.tariffRiskCodeTable.tblData[updateIndex] = this.formValues();
      this.tariffRiskCodeTable.tblData = [...this.tariffRiskCodeTable.tblData];
    } catch(e) {
      
    }
  }

  updateDbData() {
    try {
      let tempDbData = this.tempUpdateData.find(
        (tariff: { TARIFF_CD: any }) => tariff.TARIFF_CD == this.selectedRow.TARIFF_CD
      );
      if((this.tempUpdateData == '' || this.tempUpdateData == null) || (tempDbData=='' || tempDbData==null)) {
        this.tempUpdateData.push(this.formValues());
      } else {
        tempDbData.TARIFF_CD              = this.tariffRiskCodeForm.get('TARIFF_CD')?.value;
        tempDbData.ACTIVE_TAG             = this.tariffRiskCodeForm.get('ACTIVE_TAG')?.value;
        tempDbData.TARIFF_DESC            = this.tariffRiskCodeForm.get('TARIFF_DESC')?.value;
        tempDbData.REMARKS                = this.tariffRiskCodeForm.get('REMARKS')?.value;
        tempDbData.USER_ID                = this.userId;
        tempDbData.LAST_UPDATE            = this.tariffRiskCodeForm.get('LAST_UPDATE')?.value;
      }
    } catch(e) {
      
    }
  }

  updateNewData() {
    try {
      let tempNewConfigdata = this.tempNewData.find(
        (tariff: { TARIFF_CD: any }) => tariff.TARIFF_CD == this.selectedRow.TARIFF_CD
      );
      tempNewConfigdata.TARIFF_CD              = this.tariffRiskCodeForm.get('TARIFF_CD')?.value;
      tempNewConfigdata.ACTIVE_TAG             = this.tariffRiskCodeForm.get('ACTIVE_TAG')?.value;
      tempNewConfigdata.TARIFF_DESC            = this.tariffRiskCodeForm.get('TARIFF_DESC')?.value;
      tempNewConfigdata.REMARKS                = this.tariffRiskCodeForm.get('REMARKS')?.value;
      tempNewConfigdata.USER_ID                = this.userId;
      tempNewConfigdata.LAST_UPDATE            = this.tariffRiskCodeForm.get('LAST_UPDATE')?.value;
    } catch(e) {
      
    }
  }

  deleteRow(): void {
    try {
      let clickedData = this.selectedRow;
      this.tariffRiskCodeTable.tblData = this.tariffRiskCodeTable.tblData.filter(function(e: any) {
        return e.TARIFF_CD != clickedData.TARIFF_CD;
      });
      this.tempNewData = this.tempNewData.filter(function(e: any) {
        return e.TARIFF_CD != clickedData.TARIFF_CD;
      });
      this.selectedRow = '';
      this.formClearer();
      this.deleteDisabler = true;
      this.addUpdateFlag = true;
      if((this.tempNewData=='' || this.tempNewData==null) && (this.tempUpdateData=='' || this.tempUpdateData==null)) {
        this.saveDisabler = true;
      }
    } catch(e) {
      
    }
  }

  onSave(): void {
    try {
      this.formHider = true;
      this.formService.showFormLoader(null, "tariff-risk-code-form", "Saving.<br>Please wait ...", null, null);
      this.jsonDataService.contorlLoading(true);
      this.api.saveTariff(this.tempNewData).subscribe({
        next: (data: any) => {
          this.securityService.checkRequestKeyResponse(data, () => {
            this.securityService.hasValidCsrfToken(data, () => {
              if(data.status == 'SUCCESS') {
                this.api.saveTariff(this.tempUpdateData).subscribe({
                  next: (res: any) => {
                    this.securityService.checkRequestKeyResponse(res, () => {
                      this.securityService.hasValidCsrfToken(res, () => {
                        if(res.status == 'SUCCESS') {
                          this.onComplete();
                        } else {
                          this.onFail();
                        }
                      });
                    });
                  },
                  error: () => {
                    this.onFail();
                  }
                });
              } else {
                this.onFail();
              }
            });
          });
        },
        error: () => {
          this.onFail();
        }
      });
    } catch(e) {
      
    }
  }

  onComplete() {
    try {
      this.formService.hideFormLoader("tariff-risk-code-form");
      this.formHider = false;
      this.tempNewData = [];
      this.tempUpdateData = [];
      this.saveDisabler = true;
      this.deleteEnabler();
      this.formClearer();
      this.getTariff();
      this.jsonDataService.contorlLoading(false);
      this.formService.hideFormMsg("tariff-risk-code-error-message");
      this.openSaveDialog();
    } catch(e) {
      
    }
  }

  onFail() {
    try {
      this.formService.hideFormLoader("tariff-risk-code-form");
      this.formHider = false;
      this.tempNewData = [];
      this.tempUpdateData = [];
      this.deleteEnabler();
      this.formClearer();
      this.getTariff();
      this.jsonDataService.contorlLoading(false);
      this.formService.hideFormMsg("tariff-risk-code-error-message");
      this.openErrorDialog("Saving Tariff Risk Code Failed");
    } catch(e) {
      
    }
  }

  openErrorDialog(message: string) {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'ERROR',
          content: message,
        }
      });
    } catch(e) {
      
    }
  }

  openSaveDialog() {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'SUCCESS',
          content: 'Tariff Risk Code Details Successfully Saved!',
        }
      });
    } catch(e) {
      
    }
  }
}
