import { DatePipe } from '@angular/common';
import { Component, OnInit, Type } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';
import { PerilLovComponent } from '../../common/peril-lov/peril-lov.component';
import { ContractTypeLovDialogComponent } from '../../common/contract-type-lov/contract-type-lov.component';
import { CurrencyLovComponent } from '../../common/currency-lov/currency-lov.component';
import { CoverageTypeLovComponent } from '../../common/coverage-type-lov/coverage-type-lov.component';
import { SrcCdLovComponent } from '../../common/src-cd-lov/src-cd-lov.component';
import { CustomValidators } from 'src/app/utils/custom-validator';
import { SrcExtLovComponent } from '../../common/src-ext-lov/src-ext-lov.component';

interface RowData {
  plan: any,
  planPeril: any,
}

@Component({
  selector: 'plan-maintenance',
  templateUrl: './plan-maintenance.component.html',
  styleUrls: ['./plan-maintenance.component.css']
})
export class PlanMaintenanceComponent implements OnInit, LeaveConfirmation {

  moduleId: string = 'BMM038';
  public moduleName: string = this._jsonDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc?.toUpperCase() ?? "";
  public maxEffDate!: Date | undefined;
  public minExpDate!: Date | undefined;

  constructor(
    public _formService: FormService,
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    private _jsonDS: JsonDataService,
    private _securityService: SecurityService,
    private _userDetailService: UserDetailsService,
    private _appMessageService: AppMessageService
  ) {
    this.userIdLoggedIn = this._userDetailService.userId || "";
    this.forms.planForm.get('effDateFrom')?.valueChanges.subscribe((res) => {
      this.minExpDate = res ? new Date(res) : undefined;
    });
    this.forms.planForm.get('effDateTo')?.valueChanges.subscribe((res) => {
      this.maxEffDate = res ? new Date(res) : undefined;
    });
  }

  private addDay(date: Date, day: number): Date {
    date = new Date(date);
    date.setDate(date.getDate() + day);
    return date;
  }

  ngOnInit(): void {
    this.forms.disablePlanPeril();
  }

  canDeactivate(): boolean | Observable<boolean> {
    return this.tblConfig.plan.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true)).length > 0 || this.tblConfig.planPeril.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true)).length > 0
      ? false : true;
  }

  test() {
    console.table(this.forms.planForm.value);
    this.forms.enablePlanPeril();
  }

  /* Boolean Stuffs */

  public btns = {
    plan: {
      updateDisabled: true,
      sublineSearchDisabled: true,
      lovDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      addDisabled: true,
    },
    planPeril: {
      lovDisabled: true,
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      addDisabled: true,
    }
  }

  public showForm = {
    plan: true,
    planPeril: true,
  }

  /* Data Stuffs */

  private rowData: RowData = {
    plan: null,
    planPeril: null,
  }

  private errorMessage: RowData = {
    plan: null,
    planPeril: null,
  }

  private userIdLoggedIn: string;

  /* Form Object  */

  public forms = {
    lineSubLineLovForm: this.fb.group({
      lineCd: [null],
      subLineCd: [null],
      lineName: [null],
      subLineName: [null],
      lineCdWithDescDisplay: [{ value: '', disabled: 'true' }],
      subLineCdWithDescDisplay: [{ value: '', disabled: 'true' }],
    }),
    planForm: this.fb.group({
      lineCd: [null],
      subLineCd: [null],
      planCd: [null],
      planCdDisplay: [{ value: '', disabled: 'true' }],
      planName: [null, [Validators.required, CustomValidators.requiredTrim]],
      planName2: [null],
      planShortName: [null],
      planLongDesc: [null],
      termsPath: [null],
      planSubTitle: [null],
      active: ['A', [Validators.required]],
      dispCoverage: ['N', [Validators.required]],
      seqNo: [null, [this.checkDuplicateSeqNo('plan')]],
      effDateFrom: [null, [Validators.required]],
      effDateTo: [null],
      contractTypeCd: [null],
      contractTypeDesc: [null],
      contractTypeDisp: [{ value: '', disabled: 'true' }],
      coverageTypeCd: [null],
      coverageTypeDesc: [null],
      coverageTypeDisp: [{ value: '', disabled: 'true' }],
      bmCode: [null, [Validators.required, CustomValidators.requiredTrim]],
      rpaRateFlag: [null],
      currencyCd: [null, [Validators.required]],
      currencyDesc: [null, [Validators.required]],
      currencyDisp: [{ value: '', disabled: 'true' }],
      currencyRt: [null, [Validators.required, this.checkDecimalsExceeded(3, 9)]],
      withReferral: [null, [Validators.required]],
      summarizedPrem: [null, [Validators.required]],
      supportingDocs: [null, [Validators.required]],
      premiumText: [null],
      effDateRange: [null],
      otherRiskDtls: [null],
      renewal: [null, [Validators.required]],
      sameDayUser: [null],
      srcCd: [null],
      srcBmCode: [null],
      srcDesc: [null],
      srcDisp: [{ value: '', disabled: 'true' }],
      srcExtCd: [null],
      srcExtBmCode: [null],
      srcExtDesc: [null],
      srcExtDisp: [{ value: '', disabled: 'true' }],
      openingText: [null],
      closingText: [null],
      remarks: [null],
      lastUpdateUser: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }],
    }),
    planPerilForm: this.fb.group({
      lineCd: [null],
      subLineCd: [null],
      planCd: [null],
      perilCd: [null, [Validators.required, this.checkDuplicatePeril()]],
      perilName: [null, [Validators.required]],
      perilCdDisplay: [{ value: '', disabled: 'true' }],
      perilDesc: [null],
      defaultRate: [null, [this.checkDecimalsExceeded(3, 9)]],
      defaultTsi: [null, [this.checkDecimalsExceeded(14, 2)]],
      fmvSw: ['N', [Validators.required]],
      ratingTblSw: ['N', [Validators.required]],
      multiplierRt: [null, [this.checkDecimalsExceeded(3, 9)]],
      seqNo: [null, [this.checkDuplicateSeqNo('planPeril')]],
      active: ['A', [Validators.required]],
      hlCoverage: ['Y', [Validators.required]],
      remarks: [null],
      lastUpdateUser: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }],
    }),
    resetLineSubLineForm: function () {
      this.lineSubLineLovForm.reset();
    },
    resetPlanPerilForm: () => {
      this.forms.planPerilForm.reset();
      this.forms.planPerilForm.patchValue({
        active: 'A',
        hlCoverage: 'Y',
        fmvSw: 'N',
        ratingTblSw: 'N',
      });
      this.btns.planPeril.updateDisabled = true;
      this.btns.planPeril.deleteDisabled = true;
      this.btns.planPeril.lovDisabled = false;
      this._formService.hideFormMsg("plan-peril-error-message");
      this.rowData.planPeril = null;
    },
    resetPlanForm: () => {
      this.forms.planForm.reset();
      this.forms.planForm.patchValue({
        active: 'A',
        dispCoverage: 'N',
      });
      this.btns.plan.updateDisabled = true;
      this.btns.plan.deleteDisabled = true;
      this._formService.hideFormMsg("plan-error-message");
      this.rowData.plan = null;
    },
    resetAllForms: function () {
      this.resetLineSubLineForm();
      this.resetPlanForm();
      this.resetPlanPerilForm();
    },
    disablePlanPeril: () => {
      this.forms.resetPlanPerilForm();
      this.forms.planPerilForm.disable();
      this.tblConfig.planPeril.tblData = [];
      this.btns.planPeril.lovDisabled = true;
      this.btns.planPeril.addDisabled = true;
    },
    enablePlanPeril: () => {
      this.forms.resetPlanPerilForm();
      this.forms.planPerilForm.enable();
      this.forms.planPerilForm.get('lastUpdateUser')?.disable();
      this.forms.planPerilForm.get('lastUpdate')?.disable();
      this.forms.planPerilForm.get('perilCdDisplay')?.disable();
      this.btns.planPeril.lovDisabled = false;
      this.btns.planPeril.addDisabled = false;
    }
  };

  /* Table Object */

  public tblConfig: RowData = {
    plan: {
      cols: [
        {
          key: "PLAN_CD",
          header: "Plan Code",
          dataType: "string"
        },
        {
          key: "PLAN_NAME",
          header: "Plan Name",
          dataType: "string"
        },
        {
          key: "SUB_TITLE",
          header: "Plan Subtitle",
          dataType: "string"
        },
        {
          key: "SHORT_DESC",
          header: "Opening Text",
          dataType: "string"
        },
        {
          key: "ACTIVE_TAG",
          header: "Active",
          dataType: "checkbox",
        }
      ],
      tblData: [] as any[],
      selection: "single",
      paginator: true,
      rowsPerPage: 10,
      lazy: false,
      totalRecords: 15,
      loading: false,
    },
    planPeril: {
      cols: [
        {
          key: "PERIL_CD",
          header: "Peril Code",
          dataType: "string"
        },
        {
          key: "PERIL_LNAME",
          header: "Peril Name",
          dataType: "string"
        },
        {
          key: "PERIL_DESC",
          header: "Peril Description",
          dataType: "string"
        },
        {
          key: "ACTIVE_TAG",
          header: "Active",
          dataType: "checkbox",
        }
      ],
      tblData: [] as any[],
      selection: "single",
      paginator: true,
      rowsPerPage: 10,
      lazy: false,
      totalRecords: 15,
      loading: false,
    },
  }

  private formManipulation = {
    plan: {
      dbToForm: (data: any) => {
        return {
          lineCd: data.LINE_CD,
          subLineCd: data.SUBLINE_CD,
          planCd: data.PLAN_CD,
          planCdDisplay: (data.PLAN_CD) ? data.PLAN_CD.toString().padStart(2, 0) : null,
          planName: data.PLAN_NAME,
          planName2: data.PLAN_NAME_2,
          planShortName: data.PLAN_SHORT_NAME,
          planLongDesc: data.LONG_DESC,
          termsPath: data.TERMS_PATH,
          planSubTitle: data.SUB_TITLE,
          active: data.ACTIVE_TAG,
          dispCoverage: data.COVERAGE_TAG,
          seqNo: data.SEQ_NO,
          effDateFrom: data.EFF_DATE_FROM,
          effDateTo: data.EFF_DATE_TO,
          contractTypeCd: data.CONTRACT_TYPE_CD,
          contractTypeDesc: data.CONTRACT_TYPE_DESC,
          contractTypeDisp: `${data.CONTRACT_TYPE_CD ?? ""} - ${data.CONTRACT_TYPE_DESC ?? ""}`,
          coverageTypeCd: data.COVERAGE_TYPE_CD,
          coverageTypeDesc: data.COVERAGE_TYPE_DESC,
          coverageTypeDisp: `${data.COVERAGE_TYPE_CD ?? ""} - ${data.COVERAGE_TYPE_DESC ?? ""}`,
          bmCode: data.BM_CODE,
          rpaRateFlag: data.RPA_RATE_FLAG,
          currencyCd: data.CURRENCY_CD,
          currencyDesc: data.CURRENCY_DESC,
          currencyDisp: `${data.CURRENCY_CD} - ${data.CURRENCY_DESC}`,
          currencyRt: !isNaN(parseFloat(data.CURRENCY_RT)) ? parseFloat(data.CURRENCY_RT).toFixed(9) : null,
          withReferral: data.REFERRAL_TAG,
          summarizedPrem: data.SUMMARIZED_PREM_TAG,
          supportingDocs: data.SUPPORT_DOC_TAG,
          premiumText: data.PREMIUM_TEXT,
          effDateRange: data.EFF_DATE_RANGE,
          otherRiskDtls: data.OTHER_RISK_DTL,
          renewal: data.RENEW_TAG,
          sameDayUser: data.SAME_DAY_USER?.split(",") ?? null,
          srcCd: data.SRC_CD,
          srcBmCode: data.SRC_BM_CODE,
          srcDesc: data.SRC_CD_DESC,
          srcDisp: `${data.SRC_BM_CODE ?? ""} - ${data.SRC_CD_DESC ?? ""}`,
          srcExtCd: data.SRC_EXT_CD,
          srcExtBmCode: data.SRC_EXT_BM_CODE,
          srcExtDesc: data.SRC_EXT_DESC,
          srcExtDisp: `${data.SRC_EXT_BM_CODE ?? ""} - ${data.SRC_EXT_DESC ?? ""}`,
          openingText: data.SHORT_DESC,
          closingText: data.SHORT_DESC2,
          remarks: data.REMARKS,
          lastUpdateUser: data.LAST_USER,
          lastUpdate: data.LAST_USER_UPDATE,
        };
      },
      formToDb: (data: any) => {
        return {
          LINE_CD: data.lineCd,
          SUBLINE_CD: data.subLineCd,
          PLAN_CD: data.planCd,
          PLAN_NAME: data.planName,
          PLAN_NAME_2: data.planName2,
          PLAN_SHORT_NAME: data.planShortName,
          LONG_DESC: data.planLongDesc,
          TERMS_PATH: data.termsPath,
          SUB_TITLE: data.planSubTitle,
          ACTIVE_TAG: data.active,
          COVERAGE_TAG: data.dispCoverage,
          SEQ_NO: data.seqNo,
          EFF_DATE_FROM: data.effDateFrom ? new DatePipe('en-us').transform(data.effDateFrom, 'yyyy-MM-dd') : null,
          EFF_DATE_TO: data.effDateTo ? new DatePipe('en-us').transform(data.effDateTo, 'yyyy-MM-dd') : null,
          CONTRACT_TYPE_CD: data.contractTypeCd,
          CONTRACT_TYPE_DESC: data.contractTypeDesc,
          COVERAGE_TYPE_CD: data.coverageTypeCd,
          COVERAGE_TYPE_DESC: data.coverageTypeDesc,
          BM_CODE: data.bmCode,
          RPA_RATE_FLAG: data.rpaRateFlag,
          CURRENCY_CD: data.currencyCd,
          CURRENCY_DESC: data.currencyDesc,
          CURRENCY_RT: parseFloat(this._formService.setToNumberWithDecimal(data.currencyRt)),
          REFERRAL_TAG: data.withReferral,
          SUMMARIZED_PREM_TAG: data.summarizedPrem,
          SUPPORT_DOC_TAG: data.supportingDocs,
          PREMIUM_TEXT: data.premiumText,
          EFF_DATE_RANGE: data.effDateRange,
          OTHER_RISK_DTL: data.otherRiskDtls,
          RENEW_TAG: data.renewal,
          SAME_DAY_USER: data.sameDayUser?.join() ?? null,
          SRC_CD: data.srcCd,
          SRC_BM_CODE: data.srcBmCode,
          SRC_CD_DESC: data.srcDesc,
          SRC_EXT_CD: data.srcExtCd,
          SRC_EXT_BM_CODE: data.srcExtBmCode,
          SRC_EXT_DESC: data.srcExtDesc,
          SHORT_DESC: data.openingText,
          SHORT_DESC2: data.closingText,
          REMARKS: data.remarks,
          LAST_USER: null,
          LAST_USER_UPDATE: null,
        };
      },
    },
    planPeril: {
      dbToForm: (data: any) => {
        return {
          lineCd: data.LINE_CD,
          subLineCd: data.SUBLINE_CD,
          planCd: data.PLAN_CD,
          perilCd: data.PERIL_CD,
          perilName: data.PERIL_LNAME,
          perilCdDisplay: (data.PERIL_CD) ? `${data.PERIL_CD.toString().padStart(2, 0)} - ${data.PERIL_LNAME}` : data.PERIL_CD,
          perilDesc: data.PERIL_DESC,
          defaultRate: !isNaN(parseFloat(data.DEFAULT_RATE)) ? parseFloat(data.DEFAULT_RATE).toFixed(9) : null,
          defaultTsi: !isNaN(parseFloat(data.DEFAULT_TSI)) ? parseFloat(data.DEFAULT_TSI).toFixed(2) : null,
          fmvSw: data.FMV_SW,
          ratingTblSw: data.RATING_TBL_SW,
          multiplierRt: !isNaN(parseFloat(data.MULTIPLIER_RATE)) ? parseFloat(data.MULTIPLIER_RATE).toFixed(9) : null,
          seqNo: data.SEQ_NO,
          active: data.ACTIVE_TAG,
          hlCoverage: data.HL_TAG,
          remarks: data.REMARKS,
          lastUpdateUser: data.USER_ID,
          lastUpdate: data.LAST_UPDATE,
        };
      },
      formToDb: (data: any) => {
        return {
          LINE_CD: data.lineCd,
          SUBLINE_CD: data.subLineCd,
          PLAN_CD: data.planCd,
          PERIL_LNAME: data.perilName,
          PERIL_CD: data.perilCd,
          PERIL_DESC: data.perilDesc,
          DEFAULT_RATE: parseFloat(this._formService.setToNumberWithDecimal(data.defaultRate)),
          DEFAULT_TSI: parseFloat(this._formService.setToNumberWithDecimal(data.defaultTsi)),
          FMV_SW: data.fmvSw,
          RATING_TBL_SW: data.ratingTblSw,
          MULTIPLIER_RATE: parseFloat(this._formService.setToNumberWithDecimal(data.multiplierRt)),
          SEQ_NO: data.seqNo,
          HL_TAG: data.hlCoverage,
          ACTIVE_TAG: data.active,
          REMARKS: data.remarks,
          USER_ID: null,
          LAST_UPDATE: null,
        };
      },
    }
  }

  //* LoV */

  public openLov(lovToOpen: string) {
    try {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: lovToOpen,
          moduleId: this.moduleId
        },
      }
      switch (lovToOpen.toUpperCase()) {
        case "LINE":
          this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
            let output = dataOutput.content;
            if (dataOutput.button?.toUpperCase() === 'OK') {
              this.forms.resetPlanForm();
              this.forms.resetPlanPerilForm();
              if (output?.LINE_CD) {
                this.forms.lineSubLineLovForm.patchValue({
                  lineCd: output.LINE_CD,
                  lineName: output.LINE_NAME,
                  lineCdWithDescDisplay: `${output.LINE_CD} - ${output.LINE_NAME}`,
                  subLineCd: null,
                  subLineName: null,
                  subLineCdWithDescDisplay: null,
                });
                this.btns.plan.sublineSearchDisabled = false;
                this.btns.plan.lovDisabled = true;
              }
              else {
                this.forms.lineSubLineLovForm.patchValue({
                  lineCd: null,
                  lineName: null,
                  lineCdWithDescDisplay: null,
                  subLineCd: null,
                  subLineName: null,
                  subLineCdWithDescDisplay: null,
                });
                this.btns.plan.addDisabled = true;
                this.btns.plan.lovDisabled = true;
                this.btns.plan.sublineSearchDisabled = true;
              }
              this.tblConfig.plan.tblData = [];
              this.forms.disablePlanPeril();
            }
          });
          break;
        case "SUBLINE":
          LOVOPTIONS.data.lineCd = this.forms.lineSubLineLovForm.get('lineCd')?.value;
          this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
            let output = dataOutput.content;
            if (dataOutput.button?.toUpperCase() === 'OK') {
              this.forms.resetPlanForm();
              this.forms.resetPlanPerilForm();
              if (output?.SUBLINE_CD) {
                this.forms.lineSubLineLovForm.patchValue({
                  lineCd: output.LINE_CD,
                  lineName: output.LINE_NAME,
                  lineCdWithDescDisplay: `${output.LINE_CD} - ${output.LINE_NAME}`,
                  subLineCd: output.SUBLINE_CD,
                  subLineName: output.SUBLINE_NAME,
                  subLineCdWithDescDisplay: `${output.SUBLINE_CD} - ${output.SUBLINE_NAME}`,
                });
                this.btns.plan.addDisabled = false;
                this.btns.plan.lovDisabled = false;
              }
              else {
                this.forms.lineSubLineLovForm.patchValue({
                  subLineCd: null,
                  subLineName: null,
                  subLineCdWithDescDisplay: null,
                });
                this.btns.plan.addDisabled = true;
                this.btns.plan.lovDisabled = true;
              }
              this.getData.plan();
              this.forms.disablePlanPeril();
            }
          });
          break;
        case "PERIL":
          LOVOPTIONS.data.lineCd = this.forms.lineSubLineLovForm.get('lineCd')?.value;
          this._matDialog.open(PerilLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
            let output = dataOutput.content;
            if (dataOutput.button?.toUpperCase() === 'OK') {
              if (output?.PERIL_CD) {
                this.forms.planPerilForm.patchValue({
                  perilCd: output.PERIL_CD,
                  perilName: output.PERIL_LNAME,
                  perilCdDisplay: `${output.PERIL_CD.toString().padStart(2, 0)} - ${output.PERIL_LNAME}`,
                });
              }
              else {
                this.forms.planPerilForm.patchValue({
                  perilCd: null,
                  perilName: null,
                  perilCdDisplay: null,
                });
              }
            }
          });
          break;
        case "CONTRACT_TYPE":
          this._matDialog.open(ContractTypeLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
            let output = dataOutput.content;
            if (dataOutput?.option?.toUpperCase() !== 'BACK') {
              if (output?.CONTRACT_TYPE_CD) {
                this.forms.planForm.patchValue({
                  contractTypeCd: output.CONTRACT_TYPE_CD,
                  contractTypeDesc: output.CONTRACT_TYPE_DESC,
                  contractTypeDisp: `${output.CONTRACT_TYPE_CD} - ${output.CONTRACT_TYPE_DESC}`,
                });
              }
              else {
                this.forms.planForm.patchValue({
                  contractTypeCd: null,
                  contractTypeDesc: null,
                  contractTypeDisp: null,
                });
              }
            }
          });
          break;
        case "CURRENCY":
          this._matDialog.open(CurrencyLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
            let output = dataOutput.content;
            if (dataOutput?.button?.toUpperCase() !== 'BACK') {
              if (output?.CURRENCY_CD) {
                this.forms.planForm.patchValue({
                  currencyCd: output.CURRENCY_CD,
                  currencyDesc: output.CURRENCY_DESC,
                  currencyDisp: `${output.CURRENCY_CD} - ${output.CURRENCY_DESC}`,
                });
              }
              else {
                this.forms.planForm.patchValue({
                  currencyCd: null,
                  currencyDesc: null,
                  currencyDisp: null,
                });
              }
            }
          });
          break;
        case "COVERAGE_TYPE":
          LOVOPTIONS.data.lpad = 2;
          this._matDialog.open(CoverageTypeLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
            let output = dataOutput.content;
            if (dataOutput?.button?.toUpperCase() !== 'BACK') {
              if (output?.COVERAGE_TYPE_CD) {
                this.forms.planForm.patchValue({
                  coverageTypeCd: output.COVERAGE_TYPE_CD,
                  coverageTypeDesc: output.COVERAGE_TYPE_DESC,
                  coverageTypeDisp: `${output.COVERAGE_TYPE_CD} - ${output.COVERAGE_TYPE_DESC}`,
                });
              }
              else {
                this.forms.planForm.patchValue({
                  coverageTypeCd: null,
                  coverageTypeDesc: null,
                  coverageTypeDisp: null,
                });
              }
            }
          });
          break;
        case "SRC_CD":
          LOVOPTIONS.data.col = 'BM_CODE';
          LOVOPTIONS.data.lpad = 2;
          this._matDialog.open(SrcCdLovComponent, LOVOPTIONS).afterClosed().subscribe((lovData: any) => {
            if (!lovData.option) {
              if (lovData?.content?.SRC_CD) {
                const data = lovData?.content;
                this.forms.planForm.patchValue({
                  srcCd: data.SRC_CD,
                  srcBmCode: data.BM_CODE,
                  srcDesc: data.SRC_CD_DESC,
                  srcDisp: `${data.BM_CODE} - ${data.SRC_CD_DESC}`,
                });
              }
              else {
                this.forms.planForm.patchValue({
                  srcCd: null,
                  srcBmCode: null,
                  srcDesc: null,
                  srcDisp: null,
                });
              }
            }
          });
          break;
          case "SRC_EXT_CD":
            LOVOPTIONS.data.col = 'BM_CODE';
            LOVOPTIONS.data.lpad = 2;
            this._matDialog.open(SrcExtLovComponent, LOVOPTIONS).afterClosed().subscribe((lovData: any) => {
              if (!lovData.option) {
                if (lovData?.content?.SRC_EXT_CD) {
                  const data = lovData?.content;
                  this.forms.planForm.patchValue({
                    srcExtCd: data.SRC_EXT_CD,
                    srcExtBmCode: data.BM_CODE,
                    srcExtDesc: data.SRC_EXT_DESC,
                    srcExtDisp: `${data.BM_CODE} - ${data.SRC_EXT_DESC}`,
                  });
                }
                else {
                  this.forms.planForm.patchValue({
                    srcExtCd: null,
                    srcExtBmCode: null,
                    srcExtDesc: null,
                    srcExtDisp: null,
                  });
                }
              }
            });
            break;
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  private getData = {
    plan: () => {
      try {
        this.tblConfig.plan.tblData = [];
        if (this.forms.lineSubLineLovForm.get('lineCd')?.value && this.forms.lineSubLineLovForm.get('subLineCd')?.value) {
          this.tblConfig.plan.loading = true;
          this._jsonDS.contorlLoading(true);
          this._api.getAllPlan({
            moduleId: this.moduleId,
            userId: this._userDetailService.userId,
            type: "MODULE"
          }).subscribe({
            next: (response: any) => {
              this._securityService.checkRequestKeyResponse(response, () => {
                this._securityService.hasValidCsrfToken(response, () => {
                  try {
                    if (response.status === 'SUCCESS') {
                      let data = JSON.parse(this._jsonDS.decrypt(response.response));
                      this._jsonDS.contorlLoading(false);
                      let filteredData = data.data.filter((d: any) =>
                        (d.LINE_CD == this.forms.lineSubLineLovForm.get('lineCd')?.value && d.SUBLINE_CD == this.forms.lineSubLineLovForm.get('subLineCd')?.value));
                      this.tblConfig.plan.tblData = filteredData;
                    } else {
                      this.tblConfig.plan.loading = false;
                      this._jsonDS.contorlLoading(false);
                      this._appMessageService.showAppMessage(response.message, "error");
                    }
                  }
                  catch (e) {
    console.error(e);
                    console.error(e);
                  }
                  this.tblConfig.plan.loading = false;
                });
              });
            },
            error: (e: any) => {

              this.tblConfig.plan.loading = false;
              this._jsonDS.contorlLoading(false);
            }
          });
        }
        else {
          this.tblConfig.plan.loading = false;
          this._jsonDS.contorlLoading(false);
        }
      }
      catch (e) {
    console.error(e);

        this.tblConfig.plan.loading = false;
        this._jsonDS.contorlLoading(false);
      }
    },
    planPeril: () => {
      try {
        this.tblConfig.planPeril.tblData = [];
        this.tblConfig.planPeril.loading = true;
        this._jsonDS.contorlLoading(true);
        this._api.getAllPlanPeril({
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          type: "MODULE"
        }).subscribe({
          next: (response: any) => {
            this._securityService.checkRequestKeyResponse(response, () => {
              this._securityService.hasValidCsrfToken(response, () => {
                try {
                  if (response.status === 'SUCCESS') {
                    let data = JSON.parse(this._jsonDS.decrypt(response.response));
                    this._jsonDS.contorlLoading(false);
                    let filteredData = data.data.filter((d: any) =>
                    (d.LINE_CD == this.forms.lineSubLineLovForm.get('lineCd')?.value
                      && d.SUBLINE_CD == this.forms.lineSubLineLovForm.get('subLineCd')?.value
                      && d.PLAN_CD == this.forms.planForm.get('planCd')?.value));
                    this.tblConfig.planPeril.tblData = filteredData;
                  } else {
                    this.tblConfig.planPeril.loading = false;
                    this._jsonDS.contorlLoading(false);
                    this._appMessageService.showAppMessage(response.message, "error");
                  }
                } catch (e) {
    console.error(e);

                }
                this.tblConfig.planPeril.loading = false;
                this._jsonDS.contorlLoading(false);
              });
            });
          },
          error: (e: any) => {

            this.tblConfig.planPeril.loading = false;
            this._jsonDS.contorlLoading(false);
          }
        })
      }
      catch (e) {
    console.error(e);

        this.tblConfig.planPeril.loading = false;
        this._jsonDS.contorlLoading(false);
      }
    },
    rpaRateFlag: () => {
      const rpaRateFlags = this._jsonDS.data.refCds.filter((data: any) => data.rvDomain === 'RPA_RATE_FLAG').map((data: any) => { return { cd: data.rvLowValue, name: data.rvMeaning } });
      return rpaRateFlags;
    },
    referralTag: () => {
      const referralTags = this._jsonDS.data.refCds.filter((data: any) => data.rvDomain === 'REFERRAL_TAG').map((data: any) => { return { cd: data.rvLowValue, name: data.rvMeaning } });
      return referralTags;
    },
    sameDayUser: () => {
      const sameDayUsers = this._jsonDS.data.refCds.filter((data: any) => data.rvDomain === 'BMM013.USER_TYPE').map((data: any) => { return { cd: data.rvLowValue, name: data.rvMeaning } });
      return sameDayUsers;
    },
  }

  public selections = {
    activeTypes: [
      { cd: 'A', name: 'Yes' },
      { cd: 'I', name: 'No' },
    ],
    coverageTypes: [
      { cd: 'Y', name: 'Yes' },
      { cd: 'N', name: 'No' },
    ],
    rpaRateFlags: [
      { cd: null, name: '' },
      ...this.getData.rpaRateFlag()
    ],
    referralTags: [
      { cd: null, name: '' },
      ...this.getData.referralTag()
    ],
    summarizedPrems: [
      { cd: null, name: '' },
      { cd: 'Y', name: 'Yes' },
      { cd: 'N', name: 'No' },
    ],
    supportingDocs: [
      { cd: null, name: '' },
      { cd: 'Y', name: 'Yes' },
      { cd: 'N', name: 'No' },
    ],
    renewTags: [
      { cd: null, name: '' },
      { cd: 'N', name: 'No' },
      { cd: 'Y', name: 'Yes' },
    ],
    sameDayUsers: [
      ...this.getData.sameDayUser()
    ],
    fmvSw: [
      { cd: 'N', name: 'No' },
      { cd: 'Y', name: 'Yes' },
    ],
    rtTableSw: [
      { cd: 'N', name: 'No' },
      { cd: 'Y', name: 'Yes' },
    ],
  };

  public onRowClick = {
    plan: (ev: any) => {
      try {
        ev.update = true;
        this.rowData.plan = ev;
        this.populateFormFromTable.plan();
        if (!ev.temp) {
          this.forms.enablePlanPeril();
          this.getData.planPeril();
        }
        else {
          this.forms.disablePlanPeril();
        }
      }
      catch (e) {
    console.error(e);
        this.forms.resetPlanForm();
        this.forms.disablePlanPeril();
        this.rowData.plan = null;
        //this.indexRow = null;
      }
    },
    planPeril: (ev: any) => {
      try {
        ev.update = true;
        this.rowData.planPeril = ev;
        this.populateFormFromTable.planPeril();
        if (!ev.temp) {
          this.btns.planPeril.lovDisabled = true;
        }
        else {
          this.btns.planPeril.lovDisabled = false;
        }
      }
      catch (e) {
    console.error(e);
        this.forms.resetPlanPerilForm();
        this.rowData.planPeril = null;
      }
    }
  }

  private populateFormFromTable = {
    plan: () => {
      try {
        let data = this.rowData.plan;
        this.btns.plan.updateDisabled = !data.update;
        this.btns.plan.deleteDisabled = !data.temp;
        this.forms.planForm.patchValue(this.formManipulation.plan.dbToForm(data));
      }
      catch (e) {
    console.error(e);

      }
    },
    planPeril: () => {
      try {
        let data = this.rowData.planPeril;
        this.btns.planPeril.updateDisabled = !data.update;
        this.btns.planPeril.deleteDisabled = !data.temp;
        this.forms.planPerilForm.patchValue(this.formManipulation.planPeril.dbToForm(data));
      }
      catch (e) {
    console.error(e);

      }
    },
  }

  public onAdd = {
    plan: () => {
      try {
        this._formService.hideFormMsg("plan-error-message");
        if (this.forms.planForm.valid) {
          this.forms.planForm.patchValue({
            lineCd: this.forms.lineSubLineLovForm.get('lineCd')?.value,
            subLineCd: this.forms.lineSubLineLovForm.get('subLineCd')?.value,
          });
          let rowToBeAdded: { [key: string]: any } = this.formManipulation.plan.formToDb(this.forms.planForm.value);
          rowToBeAdded.temp = true;
          this.tblConfig.plan.tblData = [rowToBeAdded, ...this.tblConfig.plan.tblData];
          this.forms.resetPlanForm();
          this.btns.plan.saveDisabled = false;
        }
        else {
          this.showErrorValidator.plan();
        }
      }
      catch (e) {
    console.error(e);

      }
    },
    planPeril: () => {
      try {
        this._formService.hideFormMsg("plan-peril-error-message");
        if (this.forms.planPerilForm.valid) {
          this.forms.planPerilForm.patchValue({
            lineCd: this.forms.lineSubLineLovForm.get('lineCd')?.value,
            subLineCd: this.forms.lineSubLineLovForm.get('subLineCd')?.value,
            planCd: this.forms.planForm.get('planCd')?.value,
          });
          let rowToBeAdded: { [key: string]: any } = this.formManipulation.planPeril.formToDb(this.forms.planPerilForm.value);
          rowToBeAdded.temp = true;
          this.tblConfig.planPeril.tblData = [rowToBeAdded, ...this.tblConfig.planPeril.tblData];
          this.forms.resetPlanPerilForm();
          this.btns.planPeril.saveDisabled = false;
        }
        else {
          this.showErrorValidator.planPeril();
        }
      }
      catch (e) {
    console.error(e);

      }
    }
  }

  public onDelete = {
    plan: () => {
      try {
        this.tblConfig.plan.tblData = this.tblConfig.plan.tblData.filter((d: any) => {
          return d !== this.rowData.plan;
        });
        this.forms.resetPlanForm();
      }
      catch (e) {
    console.error(e);

      }
    },
    planPeril: () => {
      try {
        this.tblConfig.planPeril.tblData = this.tblConfig.planPeril.tblData.filter((d: any) => {
          return d !== this.rowData.planPeril;
        });
        this.forms.resetPlanPerilForm();
      }
      catch (e) {
    console.error(e);

      }
    }
  }

  public onUpdate = {
    plan: () => {
      try {
        this._formService.hideFormMsg("plan-error-message");
        if (this.forms.planForm.valid) {
          let toBeUpdatedIndex = this.tblConfig.plan.tblData.indexOf(this.rowData.plan);
          let rowToBeUpdated: { [key: string]: any } = this.formManipulation.plan.formToDb(this.forms.planForm.value);
          if (this.rowData.plan.temp) {
            rowToBeUpdated.temp = true;
          }
          else {
            rowToBeUpdated.onDbButUpdatedTemp = true;
          }
          this.forms.resetPlanForm();
          this.tblConfig.plan.tblData[toBeUpdatedIndex] = rowToBeUpdated;
          this.btns.plan.saveDisabled = false;
          this.tblConfig.plan.tblData = [... this.tblConfig.plan.tblData];
        }
        else {
          this.showErrorValidator.plan();
        }
      }
      catch (e) {
    console.error(e);

      }
    },
    planPeril: () => {
      try {
        this._formService.hideFormMsg("plan-peril-error-message");
        if (this.forms.planPerilForm.valid) {
          let toBeUpdatedIndex = this.tblConfig.planPeril.tblData.indexOf(this.rowData.planPeril);
          let rowToBeUpdated: { [key: string]: any } = this.formManipulation.planPeril.formToDb(this.forms.planPerilForm.value);
          if (this.rowData.planPeril.temp) {
            rowToBeUpdated.temp = true;
          }
          else {
            rowToBeUpdated.onDbButUpdatedTemp = true;
          }
          this.forms.resetPlanPerilForm();
          this.tblConfig.planPeril.tblData[toBeUpdatedIndex] = rowToBeUpdated;
          this.btns.planPeril.saveDisabled = false;
          this.tblConfig.planPeril.tblData = [... this.tblConfig.planPeril.tblData];
        }
        else {
          this.showErrorValidator.planPeril();
        }
      }
      catch (e) {
    console.error(e);

      }
    },
  }

  public onSave = {
    plan: () => {
      this.showForm.plan = false;
      this._formService.showFormLoader(null, "plan-maintenance-loading", "Saving.<br>Please wait ...", null, null, true);
      let toBeSaved = this.tblConfig.plan.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true));
      if (toBeSaved.length > 0) {
        toBeSaved.map((d: any) => {
          d.LAST_USER = this.userIdLoggedIn;
        });
        this._jsonDS.contorlLoading(true);
        this._api.savePlan(toBeSaved).subscribe({
          next: (response: any) => {
            this._securityService.checkRequestKeyResponse(response, () => {
              this._securityService.hasValidCsrfToken(response, () => {
                this._jsonDS.contorlLoading(false);
                if (response.status === 'SUCCESS') this.onComplete.plan();
                else this.onComplete.planError();
              });
            });
          },
          error: (data: any) => {

            this._jsonDS.contorlLoading(false);
            this.onComplete.planError();
          },
        });
      }
      else {
        this.onComplete.plan();
      }
    },
    planPeril: () => {
      this.showForm.planPeril = false;
      this._formService.showFormLoader(null, "plan-peril-maintenance-loading", "Saving.<br>Please wait ...", null, null, true);
      let toBeSaved = this.tblConfig.planPeril.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true));
      if (toBeSaved.length > 0) {
        toBeSaved.map((d: any) => {
          d.USER_ID = this.userIdLoggedIn;
        });
        this._jsonDS.contorlLoading(true);
        this._api.savePlanPeril(toBeSaved).subscribe({
          next: (response: any) => {
            this._securityService.checkRequestKeyResponse(response, () => {
              this._securityService.hasValidCsrfToken(response, () => {
                this._jsonDS.contorlLoading(false);
                if (response.status === 'SUCCESS') this.onComplete.planPeril();
                else this.onComplete.planPerilError();
              });
            });
          },
          error: (data: any) => {
            this._jsonDS.contorlLoading(false);

            this.onComplete.planPerilError();
          },
        });
      }
      else {
        this.onComplete.planPeril();
      }
    }
  }

  private openDialog(title: string, contentTitle: string, contentDetail: string) {
    try {
      this._matDialog.open(DialogMsgComponent, {
        disableClose: true,
        width: '512px',
        data: {
          title: title,
          content:
            title === 'SUCCESS'
              ? `${contentTitle} details successfully ${contentDetail}!`
              : `${contentTitle} details ${contentDetail} failed!`,
        },
      });
    }
    catch (e) {
    console.error(e);

    }
  }

  private onComplete = {
    plan: () => {
      this.getData.plan();
      this.btns.plan.saveDisabled = true;
      this.showForm.plan = true;
      this._formService.hideFormLoader("plan-maintenance-loading");
      this.openDialog('SUCCESS', 'Plan', 'saved');
      this.forms.resetPlanForm();
    },
    planPeril: () => {
      this.getData.planPeril();
      this.btns.planPeril.saveDisabled = true;
      this.showForm.planPeril = true;
      this._formService.hideFormLoader("plan-peril-maintenance-loading");
      this.openDialog('SUCCESS', 'Plan Peril', 'saved');
      this.forms.resetPlanPerilForm();
    },
    planError: () => {
      this.showForm.plan = true;
      this.btns.plan.saveDisabled = false;
      this.openDialog('FAILED', 'Plan', 'saving');
      this._formService.hideFormLoader("plan-maintenance-loading");
      this.forms.resetPlanForm();
    },
    planPerilError: () => {
      this.showForm.planPeril = true;
      this.btns.planPeril.saveDisabled = false;
      this.openDialog('FAILED', 'Plan Peril', 'saving');
      this._formService.hideFormLoader("plan-peril-maintenance-loading");
      this.forms.resetPlanPerilForm();
    },
  }

  /* Validator Stuffs Down here */

  private checkDuplicatePlanName(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) return null;
      let hasDuplicate: boolean = this.tblConfig.plan.tblData.filter((d: any) => {
        if (!this.rowData.plan)
          return d.PLAN_NAME === value;
        else
          return this.rowData.plan.PLAN_NAME !== value && d.PLAN_NAME === value;
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupe: true } : null;
    }
  }

  private checkDuplicatePeril(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) return null;
      let hasDuplicate: boolean = this.tblConfig.planPeril.tblData.filter((d: any) => {
        if (!this.rowData.planPeril)
          return +d.PERIL_CD === +value;
        else
          return +this.rowData.planPeril.PERIL_CD !== +value && +d.PERIL_CD === +value;
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupe: true } : null;
    }
  }

  private checkDuplicateSeqNo(module: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) return null;
      let hasDuplicate: boolean = this.tblConfig[module as keyof RowData].tblData.filter((d: any) => {
        if (!this.rowData[module as keyof RowData])
          return d.SEQ_NO == value;
        else
          return this.rowData[module as keyof RowData].SEQ_NO != value && d.SEQ_NO == value;
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupeSeqNo: true } : null;
    }
  }

  private checkDecimalsExceeded(digits: number, decimals: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value && parseFloat(this._formService.setToNumberWithDecimal(control.value));
      if (!value) return null;
      let isExceeded: boolean = value > +"".padEnd(digits, '9') + +('.' + ''.padEnd(decimals, '9'))
      return isExceeded ? { hasDecimalsExceeded: true } : null;
    }
  }

  public showErrorValidator = {
    plan: () => {
      try {
        if (this.forms.planForm.get('effDateFrom')?.errors || this.forms.planForm.get('effDateTo')?.errors) {
          this.errorMessage.plan = "Invalid Effective Date From/To. Please input valid dates.";
        }
        else {
          Object.keys(this.forms.planForm.controls).forEach(key => {
            const controlErrors = this.forms.planForm.get(key)?.errors;
            if (controlErrors != null) {
              Object.keys(controlErrors).forEach(keyError => {
                if (keyError == 'hasDupe' && controlErrors[keyError] === true)
                  this.errorMessage.plan = "Unable to add record. Plan Name for this Line and Subline already exists.";
                else if (keyError == 'hasDecimalsExceeded' && controlErrors[keyError] === true && key === 'currencyRt')
                  this.errorMessage.plan = "Invalid value in Currency Rate. The value entered exceeded the maximum limit.";
                else if (keyError == 'required' && controlErrors[keyError] === true)
                  this.errorMessage.plan = "There are missing information. Please provide necessary information needed.";
                else if (keyError == 'hasDupeSeqNo' && controlErrors[keyError] === true)
                  this.errorMessage.plan = "Sequence No. already exists. Please choose a different Sequence No.";
              });
            }
          });
        }
        this._formService.showFormMsg("plan-error-message", this.errorMessage.plan, "E");
      }
      catch (e) {
    console.error(e);

      }
    },
    planPeril: () => {
      try {
        Object.keys(this.forms.planPerilForm.controls).forEach(key => {
          const controlErrors = this.forms.planPerilForm.get(key)?.errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              if (keyError == 'hasDupe' && controlErrors[keyError] === true)
                this.errorMessage.planPeril = "Unable to add record. Peril for this Line, Subline, and Plan already exists.";
              else if (keyError == 'hasDecimalsExceeded' && controlErrors[keyError] === true && key === 'multiplierRt')
                this.errorMessage.planPeril = "Invalid value in Multiplier Rate. The value entered exceeded the maximum limit.";
              else if (keyError == 'hasDecimalsExceeded' && controlErrors[keyError] === true && key === 'defaultRate')
                this.errorMessage.planPeril = "Invalid value in Default Rate. The value entered exceeded the maximum limit.";
              else if (keyError == 'hasDecimalsExceeded' && controlErrors[keyError] === true && key === 'defaultTsi')
                this.errorMessage.planPeril = "Invalid value in Default Amount Covered. The value entered exceeded the maximum limit.";
              else if (keyError == 'required' && controlErrors[keyError] === true)
                this.errorMessage.planPeril = "There are missing information. Please provide necessary information needed.";
              else if (keyError == 'hasDupeSeqNo' && controlErrors[keyError] === true)
                this.errorMessage.planPeril = "Sequence No. already exists. Please choose a different Sequence No.";
            });
          }
        });
        this._formService.showFormMsg("plan-peril-error-message", this.errorMessage.planPeril, "E");
      }
      catch (e) {
    console.error(e);

      }
    }
  }

  public allowNumericDigitsOnly(event: any) {
    if (/[0-9.]/.test(String.fromCharCode(event.keyCode))) {
      return true;
    }
    else {
      event.preventDefault();
      return false;
    }
  }

  public floatFormatBlur(event: any, field: string, form: FormGroup, places: number) {
    if (event.target.value) {
      form.get(field)?.setValue(parseFloat(event.target.value).toFixed(places));
    }
  }

}
