<section id="open-policy-sequence-no-maintenance">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <div class="form-content">
            <form [formGroup]="paramForm" class="form">
                <div class="form-container-grid">
                    <div class="field required">
                        <label>Contract Type</label>
                    </div>
                    <div class="">
                        <input formControlName="CONTRACT_TYPE_CD" readonly>
                        <button matSuffix class="btn btn-search" type="button" (click)="openContractDialog()"><mat-icon>search</mat-icon></button>
                    </div>
                    <div class="field required">
                        <label>Record Type</label>
                    </div>
                    <div class="">
                        <select formControlName="POL_RECORD_TYPE" (change)="onRecordTypeChange()">
                            <option *ngFor="let recType of recordType" [value]="recType.rvLowValue">{{ recType.rvMeaning }}</option>
                        </select>
                    </div>
                </div>
                <div class="mtn-title"></div>
            </form>
        </div>

        <osp-table [tblConfig]="table" (onRowClick)="onRowClick($event)" [selectedRow]="selectedRow"></osp-table>

        <div class="form-content" [hidden]="formHider">

            <form [formGroup]="form" class="form">
                <div id="open-policy-sequence-no-error-message"></div>
                <div class="form-container-grid">
                    
                    <div class="field required">
                        <label>Open Policy ID</label>
                    </div>
                    <div class="">
                        <input formControlName="OPEN_POLICY_ID">
                    </div>
                    
                    <div class="field required">
                        <label>Status</label>
                    </div>
                    <div class="">
                        <select formControlName="OP_STAT_CD">
                            <option *ngFor="let opStatCd of opStatCd" [value]="opStatCd.rvLowValue">{{ opStatCd.rvMeaning }}</option>
                        </select>
                    </div>
                    
                    <div class="field required">
                        <label>Policy Sequence</label>
                    </div>
                    <div class="">
                        <input formControlName="BM_POL_SEQ_NO" numbersOnlyWithoutDecimal>
                    </div>
                    
                    <div class="field required">
                        <label>Payment Ref. No.</label>
                    </div>
                    <div class="">
                        <input formControlName="PAYT_REF_NO" numbersOnlyWithoutDecimal>
                    </div>
                    
                    <div class="field required">
                        <label>Effective Date</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="EFF_DATE_FROM" placeholder="MM/DD/YYYY" [matDatepicker]="effDatePicker" date-input>
                        <mat-datepicker-toggle matSuffix [for]="effDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #effDatePicker></mat-datepicker>
                    </div>
                    
                    <div class="field required">
                        <label>Expiry Date</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="EFF_DATE_TO" placeholder="MM/DD/YYYY" [matDatepicker]="expDatePicker" date-input>
                        <mat-datepicker-toggle matSuffix [for]="expDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #expDatePicker></mat-datepicker>
                    </div>
                    
                    <div class="field required">
                        <label>Policy Holder</label>
                    </div>
                    <div class="span">
                        <input formControlName="OPEN_POLICY_HOLDER" maxlength="500">
                    </div>
                    
                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="REMARKS" maxlength="4000">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input formControlName="USER_ID">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="LAST_UPDATE">
                    </div>
                </div>
            </form>

            <div class="btn-grp">
                <button type="button" class="btn btn2" [hidden]="!addUpdateFlag" (click)="addRow()">ADD</button>
                <button type="button" class="btn btn2" [hidden]="addUpdateFlag" (click)="onUpdate()">UPDATE</button>
                <button type="button" class="btn btn2" [disabled]="deleteDisabler" (click)="deleteRow()">DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-grp">
                <button type="button" class="btn btn2" [disabled]="addUpdateFlag" (click)="openViewPolicies()">VIEW POLICIES</button>
                <button type="button" class="btn btn2" [disabled]="saveDisabler" (click)="onSave()">SAVE</button>
            </div>
        </div>
    </div>
</section>