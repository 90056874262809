import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { BmaLovComponent } from '../../common/bma-lov/bma-lov.component';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { SrcCdLovComponent } from '../../common/src-cd-lov/src-cd-lov.component';
import { SrcExtLovComponent } from '../../common/src-ext-lov/src-ext-lov.component';
import { CustomValidators } from 'src/app/utils/custom-validator';

@Component({
  selector: 'referror-maintenance',
  templateUrl: './referror-maintenance.component.html',
  styleUrls: ['./referror-maintenance.component.css']
})
export class ReferrorMaintenanceComponent implements OnInit, LeaveConfirmation {
  
  selectedRow: any;
  datePipe = new DatePipe('en-ph');
  errorMessage: string = "";
  userId = this.userDetailService.userId;
  formHider: boolean = false;
  recordsPerPage: number = 10000;

  addUpdateFlag: boolean = true;
  deleteDisabler: boolean = true;
  saveDisabler: boolean = true;

  selectedBMA: any;
  selectedSourceCode: any;
  selectedSourceExtension: any;

  tempAddData: any = [];
  tempUpdateData: any = [];
  pages: any = [];
  moduleId: string = 'BMM058';
  activeTags: any = [];
  moduleName = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId === 'BMM058' })[0].moduleDesc.toUpperCase();

  referrorTable: any = {
    cols: [
      {
        key: "REFERROR_CD",
        header: "Referror Code",
        dataType: "string"
      },
      {
        key: "REFERROR_DESC",
        header: "Referror",
        dataType: "string"
      },
      {
        key: "BMA_DESC",
        header: "BMA",
        dataType: "string"
      },
      {
        key: "BM_CODE",
        header: "BPIMS Code",
        dataType: "string"
      },
      {
        key: "SRC_CD_DESC",
        header: "Source Code",
        dataType: "string"
      },
      {
        key: "SRC_EXT_DESC",
        header: "Source Extension",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  referrorForm = this.fb.group({
    REFERROR_CD: [{value: '', disabled: true}],
    REFERROR_DESC: ['', [Validators.required, this.duplicateReferrorDesc(), CustomValidators.requiredTrim]],
    ACTIVE_TAG: ['A', Validators.required],
    SRC_CD: [{value: '', disabled: true}],
    BMA_CD: [{value: '', disabled: true}],
    SRC_EXT_CD: [{value: '', disabled: true}],
    BM_CODE: ['', [Validators.required, CustomValidators.requiredTrim, this.duplicateBmCode()]],
    BM_SHORT_DESC: [''],
    REMARKS: [''],
    USER_ID: [{value: '', disabled: true}],
    LAST_UPDATE: [{value: '', disabled: true}],
  });

  constructor(
    private fb: FormBuilder,
    private api: APICallService,
    private jsonDataService: JsonDataService,
    private formService: FormService,
    private dialog: MatDialog,
    private securityService: SecurityService,
    private userDetailService: UserDetailsService,
    private appMessageService: AppMessageService
    ) { 
      for (const refCd of this.jsonDataService.data.refCds.filter((a:any) => a.rvDomain === "BMM013.ACTIVE_TAG")) {
        this.activeTags.push({
            value: refCd.rvLowValue,
            text: refCd.rvMeaning
        });
      }
    }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempAddData!='' || this.tempUpdateData!='') {
      return false;
    } else {
      return true;
    }
  }

  ngOnInit(): void {
    try {
      this.getReferror();
    } catch(e) {
      
    }
  }

  getReferror() {
    try {
      this.referrorTable.tblData = [];
      this.referrorTable.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.api.getReferrorPageCount({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE",
        recordsCount: this.recordsPerPage
      }).subscribe((data: any) => {
        this.securityService.checkRequestKeyResponse(data, () => {
          this.securityService.hasValidCsrfToken(data, () => {
            if(data.status == "SUCCESS") {
              data = JSON.parse(this.jsonDataService.decrypt(data.response));
              let pageCount = data;
              for(let i = 1; i <= pageCount; i++) {
                  let offSetValue = (i-1) * this.recordsPerPage;
                  this.pages.push(offSetValue);
              }
              this.getReferrorData();
            } else {
                this.jsonDataService.contorlLoading(false);
            }
          });
        });
      });
    } catch(e) {
      
    }
  }

  async getReferrorData() {
    try {
      this.jsonDataService.contorlLoading(true);
      const offSetValue = this.pages[0];
      this.api.getReferror({
          offSetValue: offSetValue,
          moduleId: this.moduleId,
          userId: this.userDetailService.userId,
          type: "MODULE"
      }).subscribe((data: any) => {
        this.securityService.checkRequestKeyResponse(data, () => {
          this.securityService.hasValidCsrfToken(data, () => {
            if (data.status === 'SUCCESS') {
              this.pages.splice(0, 1);
              const content = JSON.parse(this.jsonDataService.decrypt(data.response));
              this.referrorTable.tblData = this.referrorTable.tblData.concat(content.data).sort((a: any, b: any) => (a.REFERROR_CD > b.REFERROR_CD) ? 1 : -1);
              if(this.pages.length !== 0) {
                  this.getReferrorData();
              } else {
                  this.referrorTable.loading = false;
                  this.jsonDataService.contorlLoading(false);
              }
            } else {
                this.referrorTable.loading = false;
                this.jsonDataService.contorlLoading(false);
                this.appMessageService.showAppMessage(data.message, "error");
            }
          });
        });
      });
    } catch(e) {
      
    }
  }

  onRowClick(ev: any) {
    try {
      if(ev!=null) {
        this.selectedRow = ev;
        this.selectedBMA = {
          BMA_CD: ev.BMA_CD,
          BMA_DESC: ev.BMA_DESC,
        };
        this.selectedSourceCode = {
          SRC_CD: ev.SRC_CD,
          SRC_CD_DESC: ev.SRC_CD_DESC,
        };
        this.selectedSourceExtension = {
          SRC_EXT_CD: ev.SRC_EXT_CD,
          SRC_EXT_DESC: ev.SRC_EXT_DESC,
        };
        this.addUpdateFlag = false;
        this.formLoader();
      } else {
        this.selectedRow = '';
        this.addUpdateFlag = true;
        this.formClearer();
      }
      this.deleteEnabler();
    } catch(e) {
      
    }
  }

  addRow() {
    try {
      if(this.referrorForm.valid) {
        this.formService.hideFormMsg("referror-error-message");
        this.tableInserter();
        this.tempAddInserter();
        this.formClearer();
        this.saveDisabler = false;
      } else {
        this.errorMessageSetter();
      }
    } catch(e) {
      
    }
  }

  onUpdate() {
    try {
      if(this.referrorForm.valid) {
        this.updateTable();
        if(this.deleteDisabler) {
          this.updateDbData();
        } else {
          this.updateNewData();
        }
        this.selectedRow = '';
        this.addUpdateFlag = true;
        this.formClearer();
        this.saveDisabler = false;
        this.deleteEnabler();
        this.formService.hideFormMsg('referror-error-message');
      } else {
        this.errorMessageSetter();
      }
    } catch(e) {
      
    }
  }

  deleteRow() {
    try {
      let clickedData = this.selectedRow;
      this.referrorTable.tblData = this.referrorTable.tblData.filter(function(e: any) {
        return e.REFERROR_DESC != clickedData.REFERROR_DESC;
      });
      this.tempAddData = this.tempAddData.filter(function(e: any) {
        return e.REFERROR_DESC != clickedData.REFERROR_DESC;
      });
      this.selectedRow = '';
      this.formClearer();
      this.deleteDisabler = true;
      this.addUpdateFlag = true;
      if((this.tempAddData=='' || this.tempAddData==null) && (this.tempUpdateData=='' || this.tempUpdateData==null)) {
        this.saveDisabler = true;
      }
    } catch(e) {
      
    }
  }

  onSave() {
    try {
        this.formHider = true;
        this.formService.showFormLoader(null, "referror-form", "Saving.<br>Please wait ...", null, null);
        this.jsonDataService.contorlLoading(true);
        this.api.saveReferror(this.tempAddData).subscribe({
            next: (data: any) => {
                this.securityService.checkRequestKeyResponse(data, () => {
                  this.securityService.hasValidCsrfToken(data, () => {
                    if(data.status == 'SUCCESS') {
                      this.api.saveReferror(this.tempUpdateData).subscribe({
                          next: (res: any) => {
                              this.securityService.checkRequestKeyResponse(res, () => {
                                this.securityService.hasValidCsrfToken(res, () => {
                                  if(res.status == 'SUCCESS') {
                                    this.onComplete();
                                    this.openSaveDialog();
                                  } else {
                                    this.onError();
                                  }
                                });
                              });
                          },
                          error: () => {
                            this.onError();
                          }
                      });
                    } else {
                      this.onError();
                    }
                  });
                });
            },
            error: () => {
              this.onError();
            }
        });    
    } catch(e) {
      
    }
  }

  updateTable() {
    try {
      let updateIndex = this.referrorTable.tblData.indexOf(this.selectedRow);
      this.referrorTable.tblData.splice(updateIndex, 1, {
        REFERROR_CD:    this.referrorForm.get('REFERROR_CD')?.value,
        REFERROR_DESC:  this.referrorForm.get('REFERROR_DESC')?.value,
        ACTIVE_TAG:     this.referrorForm.get('ACTIVE_TAG')?.value,
        SRC_CD:         this.selectedSourceCode.SRC_CD,
        SRC_CD_DESC:    this.selectedSourceCode.SRC_CD_DESC,
        BMA_CD:         this.selectedBMA.BMA_CD,
        BMA_DESC:       this.selectedBMA.BMA_DESC,
        SRC_EXT_CD:     this.selectedSourceExtension.SRC_EXT_CD,
        SRC_EXT_DESC:   this.selectedSourceExtension.SRC_EXT_DESC,
        BM_CODE:        this.referrorForm.get('BM_CODE')?.value,
        BM_SHORT_DESC:  this.referrorForm.get('BM_SHORT_DESC')?.value,
        REMARKS:        this.referrorForm.get('REMARKS')?.value,
        USER_ID:        this.selectedRow.USER_ID,
        LAST_UPDATE:    this.selectedRow.LAST_UPDATE,
        REFRESH:        this.selectedRow.REFRESH == undefined ? 1 : !this.selectedRow.REFRESH,
      });
      this.referrorTable.tblData = [...this.referrorTable.tblData];
    } catch(e) {
      
    }
  }

  updateDbData() {
    try {
      let tempDbData = this.tempUpdateData.find(
        (referror: { REFERROR_DESC: any }) => referror.REFERROR_DESC == this.selectedRow.BMA_DESC
      );
      if(this.tempUpdateData == '' || this.tempUpdateData == null) {
        this.tempUpdateInserter();
      } else if(tempDbData=='' || tempDbData==null) {
        this.tempUpdateInserter();
      } else {
        tempDbData.REFERROR_CD     = this.referrorForm.get('REFERROR_CD')?.value;
        tempDbData.REFERROR_DESC   = this.referrorForm.get('REFERROR_DESC')?.value;
        tempDbData.ACTIVE_TAG      = this.referrorForm.get('ACTIVE_TAG')?.value;
        tempDbData.SRC_CD          = this.selectedSourceCode.SRC_CD;
        tempDbData.BMA_CD          = this.selectedBMA.BMA_CD;
        tempDbData.SRC_EXT_CD      = this.selectedSourceExtension.SRC_EXT_CD;
        tempDbData.BM_CODE         = this.referrorForm.get('BM_CODE')?.value;
        tempDbData.BM_SHORT_DESC   = this.referrorForm.get('BM_SHORT_DESC')?.value;
        tempDbData.REMARKS         = this.referrorForm.get('REMARKS')?.value;
        tempDbData.LAST_UPDATE     = this.referrorForm.get('LAST_UPDATE')?.value;
      }
    } catch(e) {
      
    }
  }

  updateNewData() {
    try {
      let tempNewConfigdata = this.tempAddData.find(
        (referror: { REFERROR_DESC: any }) => referror.REFERROR_DESC == this.selectedRow.REFERROR_DESC
      );
      tempNewConfigdata.REFERROR_CD     = this.referrorForm.get('REFERROR_CD')?.value;
      tempNewConfigdata.REFERROR_DESC   = this.referrorForm.get('REFERROR_DESC')?.value;
      tempNewConfigdata.ACTIVE_TAG      = this.referrorForm.get('ACTIVE_TAG')?.value;
      tempNewConfigdata.SRC_CD          = this.selectedSourceCode.SRC_CD;
      tempNewConfigdata.BMA_CD          = this.selectedBMA.BMA_CD;
      tempNewConfigdata.SRC_EXT_CD      = this.selectedSourceExtension.SRC_EXT_CD;
      tempNewConfigdata.BM_CODE         = this.referrorForm.get('BM_CODE')?.value;
      tempNewConfigdata.BM_SHORT_DESC   = this.referrorForm.get('BM_SHORT_DESC')?.value;
      tempNewConfigdata.REMARKS         = this.referrorForm.get('REMARKS')?.value;
      tempNewConfigdata.LAST_UPDATE     = this.referrorForm.get('LAST_UPDATE')?.value;
    } catch(e) {
      
    }
  }

  tempUpdateInserter() {
    try {
      this.tempUpdateData.push({
        REFERROR_CD:    this.referrorForm.get('REFERROR_CD')?.value,
        REFERROR_DESC:  this.referrorForm.get('REFERROR_DESC')?.value,
        ACTIVE_TAG:     this.referrorForm.get('ACTIVE_TAG')?.value,
        SRC_CD:         this.selectedSourceCode.SRC_CD,
        BMA_CD:         this.selectedBMA.BMA_CD,
        SRC_EXT_CD:     this.selectedSourceExtension.SRC_EXT_CD,
        BM_CODE:        this.referrorForm.get('BM_CODE')?.value,
        BM_SHORT_DESC:  this.referrorForm.get('BM_SHORT_DESC')?.value,
        REMARKS:        this.referrorForm.get('REMARKS')?.value,
        LAST_UPDATE:    this.referrorForm.get('LAST_UPDATE')?.value,
      });
    } catch(e) {
      
    }
  }

  formLoader() {
    try {
      this.referrorForm.patchValue({
        REFERROR_CD:    this.selectedRow.REFERROR_CD,
        REFERROR_DESC:  this.selectedRow.REFERROR_DESC,
        ACTIVE_TAG:     this.selectedRow.ACTIVE_TAG,
        SRC_CD:         this.selectedRow.SRC_CD_DESC,
        BMA_CD:         this.selectedRow.BMA_DESC,
        SRC_EXT_CD:     this.selectedRow.SRC_EXT_DESC,
        BM_CODE:        this.selectedRow.BM_CODE,
        BM_SHORT_DESC:  this.selectedRow.BM_SHORT_DESC,
        REMARKS:        this.selectedRow.REMARKS,
        USER_ID:        this.selectedRow.USER_ID,
        LAST_UPDATE:    this.selectedRow.LAST_UPDATE,  //this.datePipe.transform(this.selectedRow.LAST_UPDATE, 'MM/dd/YYYY h:mm:ss a', '+0000'),
      });
    } catch(e) {
      
    }
  }

  formClearer() {
    try {
      this.referrorForm.patchValue({
        REFERROR_CD:    '',
        REFERROR_DESC:  '',
        ACTIVE_TAG:     'A',
        SRC_CD:         '',
        BMA_CD:         '',
        SRC_EXT_CD:     '',
        BM_CODE:        '',
        BM_SHORT_DESC:  '',
        REMARKS:        '',
        USER_ID:        '',
        LAST_UPDATE:    '',
      });
      this.selectedBMA = undefined;
      this.selectedSourceCode = undefined;
      this.selectedSourceExtension = undefined;
    } catch(e) {
      
    }
  }

  tableInserter() {
    try {
      this.referrorTable.tblData.unshift({
        REFERROR_DESC:  this.referrorForm.get('REFERROR_DESC')?.value,
        ACTIVE_TAG:     this.referrorForm.get('ACTIVE_TAG')?.value,
        SRC_CD:         this.selectedSourceCode == undefined ? '' : this.selectedSourceCode.SRC_CD,
        SRC_CD_DESC:    this.selectedSourceCode == undefined ? '' : this.selectedSourceCode.SRC_CD_DESC,
        BMA_CD:         this.selectedBMA == undefined ? '' : this.selectedBMA.BMA_CD,
        BMA_DESC:       this.selectedBMA == undefined ? '' : this.selectedBMA.BMA_DESC,
        SRC_EXT_CD:     this.selectedSourceExtension == undefined ? '' : this.selectedSourceExtension.SRC_EXT_CD,
        SRC_EXT_DESC:   this.selectedSourceExtension == undefined ? '' : this.selectedSourceExtension.SRC_EXT_DESC,
        BM_CODE:        this.referrorForm.get('BM_CODE')?.value,
        BM_SHORT_DESC:  this.referrorForm.get('BM_SHORT_DESC')?.value,
        REMARKS:        this.referrorForm.get('REMARKS')?.value,
      });
      this.referrorTable.tblData = [...this.referrorTable.tblData];
    } catch(e) {
      
    }
  }

  tempAddInserter() {
    try {
      this.tempAddData.push({
        REFERROR_DESC:  this.referrorForm.get('REFERROR_DESC')?.value,
        ACTIVE_TAG:     this.referrorForm.get('ACTIVE_TAG')?.value,
        SRC_CD:         this.selectedSourceCode == undefined ? '' : this.selectedSourceCode.SRC_CD,
        BMA_CD:         this.selectedBMA == undefined ? '' : this.selectedBMA.BMA_CD,
        SRC_EXT_CD:     this.selectedSourceExtension == undefined ? '' : this.selectedSourceExtension.SRC_EXT_CD,
        BM_CODE:        this.referrorForm.get('BM_CODE')?.value,
        BM_SHORT_DESC:  this.referrorForm.get('BM_SHORT_DESC')?.value,
        REMARKS:        this.referrorForm.get('REMARKS')?.value,
      });
    } catch(e) {
      
    }
  }

  errorMessageSetter() {
    try {
      if(this.referrorForm.invalid) {
        for(const field in this.referrorForm.controls) {
          const controlName = this.referrorForm.get(field);
          if(controlName?.errors?.required) {
            this.errorMessage = 'There are missing information. Please provide necessary information needed.';
          }
          if(controlName?.errors?.referrorDuplicateDesc) {
            this.errorMessage = 'Referror already exists. Please add a unique Referror.';
          }
          if(controlName?.errors?.bmCodeDuplicate) {
            this.errorMessage = 'BPIMS Code already exists. Please add a unique BPIMS Code.';
          }
          this.formService.showFormMsg("referror-error-message", this.errorMessage, "E");
        }
      }
    } catch(e) {
      
    }
  }

  openBMALOV() {
    try {
      this.dialog.open(BmaLovComponent, {
        width: '700px',
        disableClose: true,
        data: {moduleId: this.moduleId}
      }).afterClosed().subscribe((data: any) => {
        this.selectedBMA = data.content == '' || data.content == null ? this.selectedBMA : data.content;
        this.referrorForm.get('BMA_CD')?.setValue(this.selectedBMA == null ? '' : this.selectedBMA.BMA_DESC);
      });
    } catch(e) {
      
    }
  }

  openSourceCodeLOV() {
    try {
      this.dialog.open(SrcCdLovComponent, {
        width: '700px',
        disableClose: true,
        data: {moduleId: this.moduleId}
      }).afterClosed().subscribe((data: any) => {
        this.selectedSourceCode = data.content == '' || data.content == null ? this.selectedSourceCode : data.content;
        this.referrorForm.get('SRC_CD')?.setValue(this.selectedSourceCode == null ? '' : this.selectedSourceCode.SRC_CD_DESC);
      });
    } catch(e) {
      
    }
  }

  openSourceExtLOV() {
    try {
      this.dialog.open(SrcExtLovComponent, {
        width: '700px',
        disableClose: true,
        data: {moduleId: this.moduleId}
      }).afterClosed().subscribe((data: any) => {
        this.selectedSourceExtension = data.content == '' || data.content == null ? this.selectedSourceExtension : data.content;
        this.referrorForm.get('SRC_EXT_CD')?.setValue(this.selectedSourceExtension == null ? '' : this.selectedSourceExtension.SRC_EXT_DESC);
      });
    } catch(e) {
      
    }
  }

  deleteEnabler() {
    try {
      let tempDeleteFlag = this.tempAddData.filter((e: any) => {
        return e.REFERROR_DESC == this.selectedRow.REFERROR_DESC
      });
      if(tempDeleteFlag=='' || tempDeleteFlag==null) {
        this.deleteDisabler = true;
      } else {
        this.deleteDisabler = false;
      }
    } catch(e) {
      
    }
  }

  onComplete() {
    try {
      this.formService.hideFormLoader("referror-form");
      this.formHider = false;
      this.tempAddData = [];
      this.tempUpdateData = [];
      this.referrorTable.tblData = [];
      this.saveDisabler = true;
      this.formClearer();
      this.getReferror();
      this.deleteEnabler();
    } catch(e) {
      
    }
  }

  onError() {
    try {
      this.formService.hideFormLoader("referror-form");
      this.formHider = false;
      this.tempAddData = [];
      this.tempUpdateData = [];
      this.referrorTable.tblData = [];
      this.formClearer();
      this.getReferror();
      this.deleteEnabler();
      this.openErrorDialog('Saving referror details failed.');
    } catch(e) {
      
    }
  }

  openSaveDialog() {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'SUCCESS',
          content: 'Referror details Successfully saved!',
        }
      });
    } catch(e) {
      
    }
  }

  openErrorDialog(message: string) {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'ERROR',
          content: message,
        }
      });
    } catch(e) {
      
    }
  }
  
  duplicateReferrorDesc(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const selected = this.selectedRow
      let referrorDescDuplicate = this.referrorTable.tblData.filter(function(e: any) {
        return e.REFERROR_DESC.trim()==control.value.trim() && control.value.trim() !== selected?.REFERROR_DESC?.trim();
      });
      if(referrorDescDuplicate!='') {
        return { "referrorDuplicateDesc": { value: control.value } }
      }
      return null;
    }
  }
  
  duplicateBmCode(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const selected = this.selectedRow
      const trimLeadingZeros = (str: string) => str?.replace(/^0+/, '');
      let bmCodeDuplicate = this.referrorTable.tblData.filter(function(e: any) {
        return trimLeadingZeros(e.BM_CODE)?.trim()==trimLeadingZeros(control.value)?.trim() && control.value?.trim() !== selected?.BM_CODE?.trim();
      });
      if(bmCodeDuplicate!='') {
        return { "bmCodeDuplicate": { value: control.value } }
      }
      return null;
    }
  }

}
