import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { CustomValidators } from 'src/app/utils/custom-validator';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';

@Component({
  selector: 'geographic-zoning-maintenance',
  templateUrl: './geographic-zoning-maintenance.component.html',
  styleUrls: ['./geographic-zoning-maintenance.component.css']
})
export class GeographicZoningMaintenanceComponent implements OnInit {


  addUpdateFlag: boolean = true;
  datePipe = new DatePipe('en-us');
  selectedRow: any;
  tempNewData: any = [];
  tempUpdateData: any = [];
  userId: string = this.userDetailService.userId;
  errorMessage: string = '';
  saveDisabler: boolean = true;
  deleteDisabler: boolean = true;
  formHider: boolean = false;
  moduleId: string = 'BMM167';
  moduleName = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId === 'BMM167' })[0].moduleDesc.toUpperCase();
  
  dataTable: any = {
    cols: [
      {
        key: "ZONE_CD",
        header: "Code",
        dataType: "string"
      },
      {
        key: "ZONE_NAME",
        header: "Zone Name",
        dataType: "string"
      },
      {
        key: "ZONE_DESC",
        header: "Description",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  dataForm = this.fb.group({
    ZONE_CD     : [{value: '', disabled: true}],
    ZONE_NAME   : ['', [Validators.required, this.duplicateName(), CustomValidators.requiredTrim]],
    ZONE_DESC   : ['', [Validators.required, CustomValidators.requiredTrim]],
    ACTIVE_TAG  : ['A', [Validators.required]],
    REMARKS     : [''],
    USER_ID     : [{value: '', disabled: true}],
    LAST_UPDATE : [{value: '', disabled: true}],
  });

  constructor(
    private fb: FormBuilder,
    private api: APICallService,
    private dialog: MatDialog,
    private jsonDataService: JsonDataService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    private formService: FormService,
    private securityService: SecurityService
  ) { }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempNewData!='' || this.tempUpdateData!='') {
      return false;
    } else {
      return true;
    }
  }

  duplicateName(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const selectedRow = this.selectedRow;
      let duplicate = this.dataTable.tblData.filter(function(e: any) {
        return e.ZONE_NAME.trim().toLowerCase()==control.value.trim().toLowerCase() && control.value!=selectedRow.ZONE_NAME;
      });
      if(duplicate!='') {
        return { "duplicateName": "Zone Name already exists. Please add a unique Zone Name." }
      }
      return null;
    }
  }

  ngOnInit(): void {
    this.getGeographicZoning();
  }

  getGeographicZoning(): void {
    try {
      this.dataTable.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.api.getGeographicZoning({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE"
      }).subscribe((data: any) => {
        this.securityService.checkRequestKeyResponse(data, () => {
          this.securityService.hasValidCsrfToken(data, () => {
            if (data.status === "SUCCESS") {
              data = JSON.parse(this.jsonDataService.decrypt(data.response));
              this.dataTable.loading = false;
              this.jsonDataService.contorlLoading(false);
              this.dataTable.tblData = data;
            } else {
                this.jsonDataService.contorlLoading(false);
                this.dataTable.loading = false;
                this.appMessageService.showAppMessage(data.message, "error");
            }
          });
        });
      });
    } catch(e) {
      
    }
  }

  onRowClick(ev: any): void {
    try {
      if(ev!=null) {
        this.selectedRow = ev;
        this.addUpdateFlag = false;
        this.formLoader();
      } else {
        this.selectedRow = '';
        this.addUpdateFlag = true;
        this.formClearer();
      }
      this.deleteEnabler();
    } catch(e) {
      
    }
  }

  formLoader() {
    try {
      this.dataForm.patchValue({
        ZONE_CD     : this.selectedRow.ZONE_CD    ,
        ZONE_NAME   : this.selectedRow.ZONE_NAME  ,
        ZONE_DESC   : this.selectedRow.ZONE_DESC  ,
        ACTIVE_TAG  : this.selectedRow.ACTIVE_TAG ,
        REMARKS     : this.selectedRow.REMARKS    ,
        USER_ID     : this.selectedRow.USER_ID    ,
        LAST_UPDATE : this.selectedRow.LAST_UPDATE,
      });
    } catch(e) {
      
    }
  }
  
  formClearer() {
    try {
      this.dataForm.patchValue({
        ZONE_CD     : "",
        ZONE_NAME   : "",
        ZONE_DESC   : "",
        ACTIVE_TAG  : "A",
        REMARKS     : "",
        USER_ID     : "",
        LAST_UPDATE : "",
      });
    } catch(e) {
      
    }
  }

  deleteEnabler() {
    try {
      let tempDeleteFlag = this.tempNewData.filter((e: any) => {
        return e.ZONE_NAME == this.selectedRow.ZONE_NAME
      });
      if(tempDeleteFlag=='' || tempDeleteFlag==null) {
        this.deleteDisabler = true;
      } else {
        this.deleteDisabler = false;
      }
    } catch(e) {
      
    }
  }

  addRow(): void {
    try {
      if(this.dataForm.valid) {
        this.formService.hideFormMsg("geographic-zoning-error-message");
        this.tableInserter();
        this.tempAddInserter();
        this.formClearer();
        this.saveDisabler = false;
      } else {
        this.errorMessageSetter();
      }
    } catch(e) {
      
    }
  }

  tableInserter(): void {
    try {
      this.dataTable.tblData.unshift(this.formValues());
      this.dataTable.tblData = [...this.dataTable.tblData];
    } catch(e) {
      
    }
  }

  tempAddInserter(): void {
    try {
      this.tempNewData.push(this.formValues());
    } catch(e) {
      
    }
  }

  formValues(): any {
    try {
      return {
        ZONE_CD     : isNaN(parseInt(this.dataForm.get('ZONE_CD')?.value)) ? "" : parseInt(this.dataForm.get('ZONE_CD')?.value),
        ZONE_NAME   : this.dataForm.get("ZONE_NAME")?.value,
        ZONE_DESC   : this.dataForm.get("ZONE_DESC")?.value,
        ACTIVE_TAG  : this.dataForm.get("ACTIVE_TAG")?.value,
        REMARKS     : this.dataForm.get("REMARKS")?.value,
        USER_ID     : this.userId,
        LAST_UPDATE : this.dataForm.get("LAST_UPDATE")?.value, 
      }
    } catch(e) {
      
    }
  }

  errorMessageSetter() {
    try {
      if(this.dataForm.invalid) {
        for(const field in this.dataForm.controls) {
          const controlName = this.dataForm.get(field);
          if(controlName?.errors?.required) {
            this.errorMessage = 'There are missing information. Please provide necessary information needed.';
          }
          if(controlName?.errors?.duplicateName) {
            this.errorMessage = controlName?.errors?.duplicateName;
          }
          this.formService.showFormMsg("geographic-zoning-error-message", this.errorMessage, "E");
        }
      }
    } catch(e) {
      
    }
  }

  onUpdate(): void {
    try {
      if(this.dataForm.valid) {
        this.updateTable();
        if(this.deleteDisabler) {
          this.updateDbData();
        } else {
          this.updateNewData();
        }
        this.selectedRow = '';
        this.addUpdateFlag = true;
        this.formClearer();
        this.saveDisabler = false;
        this.deleteEnabler();
        this.formService.hideFormMsg('geographic-zoning-error-message');
      } else {
        this.errorMessageSetter();
      }
    } catch(e) {
      
    }
  }

  updateTable() {
    try {
      let updateIndex = this.dataTable.tblData.indexOf(this.selectedRow);
      this.dataTable.tblData[updateIndex] = this.formValues();
      this.dataTable.tblData[updateIndex].ZONE_CD = this.dataTable.tblData[updateIndex].ZONE_CD === "" ? "" : this.dataTable.tblData[updateIndex].ZONE_CD.toString().padStart(2, 0);
      this.dataTable.tblData = [...this.dataTable.tblData];
    } catch(e) {
      
    }
  }

  updateDbData() {
    try {
      let tempDbData = this.tempUpdateData.find(
        (data: { ZONE_NAME: any }) => data.ZONE_NAME == this.selectedRow.ZONE_NAME
      );
      if((this.tempUpdateData == '' || this.tempUpdateData == null) || (tempDbData=='' || tempDbData==null)) {
        this.tempUpdateData.push(this.formValues());
      } else {
        tempDbData.ZONE_CD     = parseInt(this.dataForm.get('ZONE_CD')?.value);
        tempDbData.ZONE_NAME   = this.dataForm.get("ZONE_NAME")?.value;
        tempDbData.ZONE_DESC   = this.dataForm.get("ZONE_DESC")?.value;
        tempDbData.ACTIVE_TAG  = this.dataForm.get("ACTIVE_TAG")?.value;
        tempDbData.REMARKS     = this.dataForm.get("REMARKS")?.value;
        tempDbData.USER_ID     = this.userId;
        tempDbData.LAST_UPDATE = this.dataForm.get("LAST_UPDATE")?.value;
      }
    } catch(e) {
      
    }
  }

  updateNewData() {
    try {
      let tempNewConfigdata = this.tempNewData.find(
        (data: { ZONE_NAME: any }) => data.ZONE_NAME == this.selectedRow.ZONE_NAME
      );
      tempNewConfigdata.ZONE_CD     = this.dataForm.get('ZONE_CD')?.value;
      tempNewConfigdata.ZONE_NAME   = this.dataForm.get("ZONE_NAME")?.value;
      tempNewConfigdata.ZONE_DESC   = this.dataForm.get("ZONE_DESC")?.value;
      tempNewConfigdata.ACTIVE_TAG  = this.dataForm.get("ACTIVE_TAG")?.value;
      tempNewConfigdata.REMARKS     = this.dataForm.get("REMARKS")?.value;
      tempNewConfigdata.USER_ID     = this.userId;
      tempNewConfigdata.LAST_UPDATE = this.dataForm.get("LAST_UPDATE")?.value;
    } catch(e) {
      
    }
  }

  deleteRow(): void {
    try {
      let clickedData = this.selectedRow;
      this.dataTable.tblData = this.dataTable.tblData.filter(function(e: any) {
        return e.ZONE_NAME != clickedData.ZONE_NAME;
      });
      this.tempNewData = this.tempNewData.filter(function(e: any) {
        return e.ZONE_NAME != clickedData.ZONE_NAME;
      });
      this.selectedRow = '';
      this.formClearer();
      this.deleteDisabler = true;
      this.addUpdateFlag = true;
      if((this.tempNewData=='' || this.tempNewData==null) && (this.tempUpdateData=='' || this.tempUpdateData==null)) {
        this.saveDisabler = true;
      }
    } catch(e) {
      
    }
  }

  onSave(): void {
    try {
      this.formHider = true;
      this.jsonDataService.contorlLoading(true);
      this.api.saveGeographicZoning(this.tempNewData).subscribe({
        next: (data: any) => {
          this.securityService.checkRequestKeyResponse(data, () => {
            this.securityService.hasValidCsrfToken(data, () => {
              if(data.status == 'SUCCESS') {
                this.api.saveGeographicZoning(this.tempUpdateData).subscribe({
                  next: (res: any) => {
                    this.securityService.checkRequestKeyResponse(res, () => {
                      this.securityService.hasValidCsrfToken(res, () => {
                        if(res.status == 'SUCCESS') {
                          this.onComplete();
                        } else {
                          this.onFail();
                        }
                      });
                    });
                  },
                  error: () => {
                    this.onFail();
                  }
                });
              } else {
                this.onFail();
              }
            });
          });
        },
        error: () => {
          this.onFail();
        }
      });
    } catch(e) {
      
    }
  }
  
  onComplete() {
    try {
      this.formHider = false;
      this.tempNewData = [];
      this.tempUpdateData = [];
      this.saveDisabler = true;
      this.deleteEnabler();
      this.formClearer();
      this.getGeographicZoning();
      this.formService.hideFormMsg("geographic-zoning-error-message");
      this.openSaveDialog();
    } catch(e) {
      
    }
  }

  onFail() {
    try {
      this.formService.hideFormLoader("geographic-zoning-form");
      this.formHider = false;
      this.tempNewData = [];
      this.tempUpdateData = [];
      this.deleteEnabler();
      this.formClearer();
      this.getGeographicZoning();
      this.formService.hideFormMsg("geographic-zoning-error-message");
      this.openErrorDialog("Saving Geographic Zoning Failed");
    } catch(e) {
      
    }
  }

  openErrorDialog(message: string) {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'ERROR',
          content: message,
        }
      });
    } catch(e) {
      
    }
  }

  openSaveDialog() {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'SUCCESS',
          content: 'Geographic Zoning Details Successfully Saved!',
        }
      });
    } catch(e) {
      
    }
  }
}
