import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ClientGroupLovDialog } from 'src/app/components/common/client-group-lov-dialog/client-group-lov-dialog.component';

import { APICallService } from 'src/app/services/api-call.service';
import { DialogBoxService } from 'src/app/services/dialog-box.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';

@Component({
    templateUrl: './deactivate-voucher-dialog.component.html',
    styleUrls: ['./deactivate-voucher-dialog.component.css']
})
export class DeactivateVoucherDialogComponent implements OnInit {

    private moduleId: string = 'BMM158';
    public dialogTitle: string = 'DEACTIVATE VOUCHER CODE';
    private datePipe = new DatePipe('en-us');

    private dateParameters: any[] = [];
    public datesRequired: boolean = false;

    public deactivateVoucherCodeForm = this.formBuilder.group({
        lineCd: ["", [Validators.required]],
        lineDisplay: [""],
        sublineCd: ["", [Validators.required]],
        sublineDisplay: [""],
        planCd: ["", [Validators.required]],
        planDisplay: [""],
        clientTypeGroup: [""],
        clientTypeGroupDisplay: [""],
        batchNo: [""],
        voucherCode: [""],
        fromDate: [""],
        toDate: [""],
        dateParameter: [""],
    });

    private refreshTable: boolean = false;

    constructor(
        // PUBLIC
        @Inject(MAT_DIALOG_DATA) public dataIn: any,

        // PRIVATE
        private formBuilder: FormBuilder,
        private matDialogRef: MatDialogRef<DeactivateVoucherDialogComponent>,
        private _matDialog: MatDialog,
        private apiCallService: APICallService,
        private jsonDataService: JsonDataService,
        private securityService: SecurityService,
        private dialogBoxService: DialogBoxService,
        private formService: FormService,
    ) {
        // console.log(this.dataIn.voucherCodeFilterForm);
        this.deactivateVoucherCodeForm.patchValue({
            ...this.dataIn.voucherCodeFilterForm
        });
        this.dateParameters = this.jsonDataService.data.refCds.filter((data: any) => data.rvDomain === 'VOUCHER_DATE_PARAM').map((data: any) => { return { cd: data.rvLowValue, name: data.rvMeaning } });
        // console.log(this.dateParameters);
        if (this.dateParameters.length > 0) {
            this.selections.dateParameters = [
                { cd: '', name: '' },
                ...this.dateParameters
            ];
        }
        this.dateParameterChanges();
    }

    private dateParameterChanges(): void {
        this.deactivateVoucherCodeForm.get("dateParameter")?.valueChanges.subscribe((control: any) => {
            if (control) {
                this.deactivateVoucherCodeForm.get('fromDate')?.setValidators(Validators.required);
                this.deactivateVoucherCodeForm.get('toDate')?.setValidators(Validators.required);
                this.datesRequired = true;
            }
            else {
                this.deactivateVoucherCodeForm.get('fromDate')?.clearValidators();
                this.deactivateVoucherCodeForm.get('toDate')?.clearValidators();
                this.datesRequired = false;
            }
            this.deactivateVoucherCodeForm.get('fromDate')?.updateValueAndValidity({ onlySelf: true, emitEvent: false });
            this.deactivateVoucherCodeForm.get('toDate')?.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        });
    }

    public selections = {
        dateParameters: [
            { cd: '', name: '' },
            { cd: 'eff_date_from', name: 'Effective Date' },
            { cd: 'eff_date_to', name: 'Expiry Date' },
        ]
    };

    ngOnInit(): void {
    }

    public openLineLOV(option: string): void {
        if (option === 'clientTypeGroup') {
            const LOVOPTIONS: { [key: string]: any } = {
                disableClose: true,
                width: '512px',
                data: {
                    table: 'CLIENT_GROUP',
                    moduleId: this.moduleId,
                },
            };
            this._matDialog.open(ClientGroupLovDialog, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
                let output = dataOutput.content;
                if (output.CLIENT_CD) {
                    this.deactivateVoucherCodeForm.patchValue({
                        clientTypeGroup: output.CLIENT_CD,
                        clientTypeGroupDisplay: `${output.CLIENT_CD} - ${output.CLIENT_DESC}`,
                    });
                }
                else {
                    this.deactivateVoucherCodeForm.patchValue({
                        clientTypeGroup: null,
                        clientTypeGroupDisplay: null,
                    });
                }
            });
        }
    }

    public onCancel(): void {
        this.matDialogRef.close({ refreshTable: this.refreshTable });
    }

    public onProceed(): void {
        this.formService.hideFormMsg("deactivate-voucher-code-error-message");
        if (this.deactivateVoucherCodeForm.valid) {
            let values = this.prepareData({ ...this.deactivateVoucherCodeForm.getRawValue() });
            this.jsonDataService.contorlLoading(true);
            this.apiCallService.deactivateVouchers(values).subscribe({
                next: (response: any) => {
                    this.securityService.checkRequestKeyResponse(response, () => {
                        this.securityService.hasValidCsrfToken(response, () => {
                            if (response.status === "SUCCESS") {
                                let content = JSON.parse(this.jsonDataService.decrypt(response.response)).result;
                                this.jsonDataService.contorlLoading(false);
                                if (content.count) {
                                    this.dialogBoxService.open({
                                        messageType: "S",
                                        content: `Deactivated Vouchers. ${content.count} voucher(s) has been deactivated.`,
                                        buttons: [{
                                            text: "OK",
                                            action: () => { this.refreshTable = true; }
                                        }]
                                    }, true);
                                }
                                else {
                                    this.dialogBoxService.open({
                                        messageType: "I",
                                        content: `No record found for the given parameter.`,
                                        buttons: [{
                                            text: "OK",
                                            action: () => { }
                                        }]
                                    }, true);
                                }
                                this.jsonDataService.contorlLoading(false);
                            }
                            else {
                                this.jsonDataService.contorlLoading(false);
                            }
                        });
                    });
                },
                error: (data: any) => {
                    this.securityService.checkRequestKeyResponse(data, () => {
                        this.jsonDataService.contorlLoading(false);
                    });
                }
            });
        }
        else {
            this.formService.showFormMsg("deactivate-voucher-code-error-message", "There are missing information. Please provide necessary information needed.", "E");
            setTimeout(() => {
                this.formService.hideFormMsg("deactivate-voucher-code-error-message");
            }, 5000);
        }
    }

    private prepareData(data: any): any {
        return {
            lineCd: data.lineCd,
            sublineCd: data.sublineCd,
            planCd: data.planCd,
            clientTypeGroup: data.clientTypeGroup,
            batchNo: data.batchNo === '' ? null : data.batchNo,
            voucherCode: data.voucherCode,
            dateParam: data.dateParameter,
            fromDate: data.fromDate ? this.datePipe.transform(new Date(data.fromDate), 'yyyy-MM-dd') : "",
            toDate: data.toDate ? this.datePipe.transform(new Date(data.toDate), 'yyyy-MM-dd') : "",
        };
    };

}
