<section id="tax-charge-maint">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>

        <div class="form-content">
            <form [formGroup]="paramForm" class="form">
                <div class="form-container-grid top-form">
                    <div class="field required">
                        <label>Line</label>
                    </div>
                    <div class="lov-search">
                        <input readonly formControlName="lineField">
                        <button matSuffix class="btn-search btn" type="button" (click)="checkOpenLineDialog()"><mat-icon>search</mat-icon></button>
                    </div>
                    <div class="field required">
                        <label>Issue Source</label>
                    </div>
                    <div class="lov-search">
                        <input readonly formControlName="issueField">
                        <button matSuffix class="btn-search btn" type="button" (click)="checkOpenIssDialog()"><mat-icon>search</mat-icon></button>
                    </div>
                </div>
            </form>
        </div>
        <div class='table-tax-charges'>
            <osp-table [tblConfig]="tblConfigTaxCharges" (onRowClick)="onRowClick($event)"></osp-table>
        </div>
        <!-- Loading Animation -->
        <!-- <div id="generate-tax-charges-form">
            <div class="form-loader"></div>
        </div> -->

        <div class="form-content">
            
            <form [formGroup]="taxChargeForm" class="form" *ngIf="noLoadingInProcess">
                <div id="tax-error-message"></div>
                <div class="form-container-grid">
                    <div class="field required">
                        <label>Tax Name</label>
                    </div>
                    <div class="lov-search">
                        <input type="text" readonly formControlName="taxField">
                        <button type="button" class="btn-search btn" (click)="openTaxDialog()" [disabled]='taxNameDisabled'>
                            <mat-icon>
                                search
                            </mat-icon>
                        </button>
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag">
                            <option value="A" selected>Yes</option>
                            <option value="I">No</option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>Rate</label>
                    </div>
                    <div class="">
                        <input formControlName="rate" [digits]="3" [decimals]="9" decimal>
                    </div>

                    <div class="field required">
                        <label>Tax Type</label>
                    </div>
                    <div class="">
                        <select formControlName="taxType">
                            <option *ngFor="let taxType of taxTypeList" [value]="taxType.rvLowValue">{{ taxType.rvMeaning }}</option>
                        </select>
                    </div>

                    <div class="field">
                        <label>Tax Amount</label>
                    </div>
                    <div class="">
                        <input formControlName="taxAmt" decimal [digits]="10" [decimals]="2">
                    </div><div></div><div></div>

                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" type="text">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="userID">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUserUpdate">
                    </div>

                </div>
                <div class='btn-row'>
                    <button type="button" id='btn-add' *ngIf="!isTaxChargeSelected" title='Register a new tax' class="btn btn2" value='ADD' (click)='insertTaxCharge()' type="submit" [disabled]='addUpdDisabled'>ADD</button>
                    <button type="button" id='btn-update' *ngIf="isTaxChargeSelected" title='Update a tax' class="btn btn2" value='UPDATE' (click)='updateTaxCharge()' type="submit" [disabled]='addUpdDisabled'>UPDATE</button>
                    <button type="button" id='btn-delete' title='Remove a tax charge' class="btn btn2" value='DELETE' (click)='deleteTaxCharge()' type="submit" [disabled]='deleteDisabled'>DELETE</button>
                </div> 

                <div class="mtn-title"></div>

                <div class='btn-row'>
                    <button type="button" id='btn-save' title='Save changes on tax details' class="btn btn2" value='SAVE' type="submit" (click)='toSave()' [disabled]='saveDisabled'>SAVE</button>
                </div>
            </form>
        </div> 


    </div>
</section>