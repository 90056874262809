import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

@Component({
    selector: 'customer-maintenance',
    templateUrl: './customer-maintenance.component.html',
    styleUrls: ['./customer-maintenance.component.css']
})
export class CustomerMaintenanceComponent implements OnInit {

    moduleId: string = 'BMM015';
    public moduleName: string = '';

    datePipe = new DatePipe('en-us');

    tblAssuredId: any;
    tblAssuredAtt: any;

    assuredForm = this.fb.group({
        assuredNo: [null],
        clientType: [null],
        activeTag: [null],
        firstName: [null],
        lastName: [null],
        middleName: [null],
        suffix: [null],
        displayName: [null],
        emailAdd: [null],
        birthDate: [null],
        nationality: [null],
        gender: [null],
        maritalStatus: [null],
        compName: [null],
        regNo: [null],
        occupation: [null],
        jobCategory: [null],
        permanentAdd1: [null],
        permanentAdd2: [null],
        permanentAdd3: [null],
        permanentAdd4: [null],
        permanentCountry: [null],
        permanentCity: [null],
        permanentProvince: [null],
        permanentPostalCd: [null],
        riskRatingScore: [null],
        riskProfile: [null],
        fundSource: [null],
        noTinTag: [null],
        tinNo: [null],
        mobileNo: [null],
        telNo: [null],
        birthCountry: [null],
        birthCity: [null],
        birthProvince: [null],
        bpimsCd: [null],
        bpimsShortDesc: [null],
        sameAsPermAdd: [null],
        presentAdd1: [null],
        presentAdd2: [null],
        presentAdd3: [null],
        presentAdd4: [null],
        presentCountry: [null],
        presentCity: [null],
        presentProvince: [null],
        presentPostalCd: [null],
        nameOfEmployer: [null],
        natureOfEmployment: [null],
        rmNo: [null],
        createUser: [null],
        createDate: [null],
        lastUserUpdate: [null],
        lastUser: [null]
    })

    assuredIdForm = this.fb.group({
        idType: [null],
        imgFile: [null],
        idNo: [null],
        expDate: [null],
        uploadUser: [null],
        uploadDate: [null],
        lastUpdateUser: [null],
        lastUpdate: [null]
    })

    assuredAttForm = this.fb.group({
        fileId: [null],
        file: [null],
        fileSize: [null],
        deleteTag: [null],
        uploadUser: [null],
        uploadDate: [null],
        lastUpdateUser: [null],
        lastUpdate: [null]
    })

    tblConfigAssured: any = {
        cols: [
            {
                key: "ASSD_NO",
                header: "Assured No.",
                dataType: "string",
                width: '15%'
            },
            {
                key: "DISPLAY_NAME",
                header: "Name",
                dataType: "string",
                disabled: false,
                width: '25%'
            },
            {
                key: "COMPANY_NAME",
                header: "Company Name",
                dataType: "string",
                disabled: false,
                width: '25%'
            },
            {
                key: "EMAIL_ADDRESS",
                header: "Email Address",
                dataType: "string",
                disabled: false,
                width: '25%'
            },
            {
                key: "ACTIVE_TAG",
                header: "A",
                dataType: "checkbox",
                disabled: true,
                width: '64px'
            }
        ],
        tblData: [],
        selection: "single",
        paginator: true,
        rowsPerPage: 10,
        lazy: false,
        totalRecords: 15,
        loading: false
    }

    tblConfigAssuredId: any = {
        cols: [
            {
                key: "ID_TYPE_DESC",
                header: "ID Type",
                dataType: "string",
                width: '20%'
            },
            {
                key: "FILE_NAME",
                header: "Image File Name",
                dataType: "string",
                width: '40%'
            },
            {
                key: "ID_NO",
                header: "ID No.",
                dataType: "string",
                width: '40%'
            }
        ],
        tblData: [],
        selection: "single",
        paginator: true,
        rowsPerPage: 10,
        lazy: false,
        totalRecords: 15,
        loading: false
    }

    tblConfigAssuredAtt: any = {
        cols: [
            {
                key: "FILE_ID",
                header: "File ID",
                dataType: "string",
                width: '10%'
            },
            {
                key: "FILE_NAME",
                header: "File Name",
                dataType: "string",
                disabled: false
            },
            {
                key: "DELETE_TAG",
                header: "Delete Tag",
                dataType: "string",
                disabled: false,
                width: '20%'
            }
        ],
        tblData: [],
        selection: "single",
        paginator: true,
        rowsPerPage: 10,
        lazy: false,
        totalRecords: 15,
        loading: false
    }

    constructor(
        private fb: FormBuilder,
        private jsonDataService: JsonDataService,
        private apiCallService: APICallService,
        private userDetailsService: UserDetailsService,
        private appMessageService: AppMessageService,
        private securityService: SecurityService
    ) { }

    ngOnInit(): void {
        try {
            this.moduleName = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId === this.moduleId })[0].moduleDesc.toUpperCase();
            this.getAssuredList();
        } catch (e) {
    console.error(e);
        }
    }

    async getAssuredList() {
        try {
            this.tblConfigAssured.loading = true;
            this.jsonDataService.contorlLoading(true);
            this.apiCallService.getAssured({
                moduleId: this.moduleId,
                userId: this.userDetailsService.userId,
                type: "MODULE"
            }).subscribe((response: any) => {
                this.securityService.checkRequestKeyResponse(response, () => {
                    this.securityService.hasValidCsrfToken(response, () => {
                        if (response.status === "SUCCESS") {
                            response = JSON.parse(this.jsonDataService.decrypt(response.response));
                            this.tblConfigAssured.tblData = response.data;
                        } else {
                            this.jsonDataService.contorlLoading(false);
                            this.tblConfigAssured.loading = false;
                            this.appMessageService.showAppMessage(response.message, "error");
                        }
                    });        
                });
                new Promise<void>((resolve) => {
                    resolve(this.getAssuredIdList());
                });
            });
        } catch (e) {
    console.error(e);
        }
    }

    async getAssuredIdList() {
        try {
            this.apiCallService.getAssuredId({
                moduleId: 'BMM063',
                userId: this.userDetailsService.userId,
                type: "MODULE"
            }).subscribe((response: any) => {
                this.securityService.checkRequestKeyResponse(response, () => {
                    this.securityService.hasValidCsrfToken(response, () => {
                        if (response.status === "SUCCESS") {
                            response = JSON.parse(this.jsonDataService.decrypt(response.response));
                            this.tblAssuredId = response.data;
                        } else {
                            this.appMessageService.showAppMessage(response.message, "error");
                        }
                    });
                });
                new Promise<void>((resolve) => {
                    resolve(this.getAssuredAttachmentList());
                });
            });
        } catch (e) {
    console.error(e);
        }
    }

    async getAssuredAttachmentList() {
        try {
            this.apiCallService.getAssuredAttachment({
                moduleId: 'BMM121',
                userId: this.userDetailsService.userId,
                type: "MODULE"
            }).subscribe((response: any) => {
                this.securityService.checkRequestKeyResponse(response, () => {
                    this.securityService.hasValidCsrfToken(response, () => {
                        if (response.status === "SUCCESS") {
                            response = JSON.parse(this.jsonDataService.decrypt(response.response));
                            this.tblAssuredAtt = response.data;
                        } else {
                            this.appMessageService.showAppMessage(response.message, "error");
                        }
                    });
                });
            });
            new Promise<void>(() => {
                this.jsonDataService.contorlLoading(false);
                this.tblConfigAssured.loading = false;
            });
        } catch (e) {
    console.error(e);
        }
    }

    getClientType(brTag: String) {
        try {
            const clientType = this.jsonDataService.data.refCds.filter((data: any) => data.rvDomain === 'BMM015.BR_TAG' && data.rvLowValue === brTag).map((data: any) => { return data.rvMeaning });
            return clientType;
        } catch (e) {
    console.error(e);
        }
    }

    onAssuredRowClick(ev: any) {
        try {
            if (ev != null) {
                this.assuredFieldDataLoader(ev);
            } else {
                this.assuredFieldDataClearer();
            }
            this.filterTblData();
            this.assuredIdFieldDataClearer();
            this.assuredAttFieldDataClearer();
        } catch (e) {
    console.error(e);
        }
    }

    onAssuredIdRowClick(ev: any) {
        try {
            if (ev != null) {
                this.assuredIdFieldDataLoader(ev);
            } else {
                this.assuredIdFieldDataClearer();
            }
        } catch (e) {
    console.error(e);
        }
    }

    onAssuredAttRowClick(ev: any) {
        try {
            if (ev != null) {
                this.assuredAttFieldDataLoader(ev);
            } else {
                this.assuredAttFieldDataClearer();
            }
        } catch (e) {
    console.error(e);
        }
    }

    assuredFieldDataLoader(ev: any) {
        try {
            this.assuredForm.patchValue({
                assuredNo: this.replaceNull(ev.ASSD_NO),
                clientType: this.replaceNull(this.concatCdAndDesc(ev.BR_TAG, this.getClientType(ev.BR_TAG))),
                activeTag: this.replaceNull(ev.ACTIVE_TAG),
                firstName: this.replaceNull(ev.FIRST_NAME),
                lastName: this.replaceNull(ev.LAST_NAME),
                middleName: this.replaceNull(ev.MIDDLE_NAME),
                suffix: this.replaceNull(ev.SUFFIX),
                displayName: this.replaceNull(ev.DISPLAY_NAME),
                emailAdd: this.replaceNull(ev.EMAIL_ADDRESS),
                birthDate: this.replaceNull(this.formatDate(ev.BIRTH_DATE)),
                nationality: this.replaceNull(this.concatCdAndDesc(ev.NATIONALITY_CD, ev.NATIONALITY_DESC)),
                gender: this.replaceNull(this.concatCdAndDesc(ev.GENDER_CD, ev.GENDER_DESC)),
                maritalStatus: this.replaceNull(this.concatCdAndDesc(ev.MARITAL_STAT_CD, ev.MARITAL_DESC)),
                compName: this.replaceNull(ev.COMPANY_NAME),
                regNo: this.replaceNull(ev.REGISTRATION_NO),
                occupation: this.replaceNull(this.concatCdAndDesc(ev.OCCUPATION_CD, ev.OCCUPATION_DESC)),
                jobCategory: this.replaceNull(this.concatCdAndDesc(ev.CATEGORY_CD, ev.CATEGORY_DESC)),
                permanentAdd1: this.replaceNull(ev.ADDRESS_1),
                permanentAdd2: this.replaceNull(ev.ADDRESS_2),
                permanentAdd3: this.replaceNull(ev.ADDRESS_3),
                permanentAdd4: this.replaceNull(ev.ADDRESS_4),
                permanentCountry: this.replaceNull(this.concatCdAndDesc(ev.COUNTRY_CD, ev.COUNTRY_NAME)),
                permanentCity: this.replaceNull(this.concatCdAndDesc(ev.CITY_CD, ev.CITY_NAME)),
                permanentProvince: this.replaceNull(this.concatCdAndDesc(ev.PROVINCE_CD, ev.PROVINCE_NAME)),
                permanentPostalCd: this.replaceNull(this.concatCdAndDesc(ev.POSTAL_CD, ev.POSTAL_DESC)),
                riskRatingScore: this.replaceNull(ev.RISK_RATING_SCORE),
                riskProfile: this.replaceNull(this.concatCdAndDesc(ev.RISK_PROF_CD, ev.RISK_PROF_DESC)),
                fundSource: this.replaceNull(this.concatCdAndDesc(ev.SOURCE_FUND, ev.SOURCE_FUND_DESC)),
                noTinTag: this.replaceNull(ev.NO_TIN_TAG),
                tinNo: this.replaceNull(ev.TIN_NO),
                mobileNo: this.replaceNull(ev.MOBILE_NO),
                telNo: this.replaceNull(ev.TEL_NO),
                birthCountry: this.replaceNull(this.concatCdAndDesc(ev.BIRTH_COUNTRY_CD, ev.BIRTH_COUNTRY_NAME)),
                birthCity: this.replaceNull(this.concatCdAndDesc(ev.BIRTH_CITY_CD, ev.BIRTH_CITY_NAME)),
                birthProvince: this.replaceNull(this.concatCdAndDesc(ev.BIRTH_PROVINCE_CD, ev.BIRTH_PROVINCE_NAME)),
                bpimsCd: this.replaceNull(ev.BM_CODE),
                bpimsShortDesc: this.replaceNull(ev.BM_SHORT_DESC),
                sameAsPermAdd: this.replaceNull(ev.SAME_ADDR_TAG),
                presentAdd1: this.replaceNull(ev.PRE_ADDRESS_1),
                presentAdd2: this.replaceNull(ev.PRE_ADDRESS_2),
                presentAdd3: this.replaceNull(ev.PRE_ADDRESS_3),
                presentAdd4: this.replaceNull(ev.PRE_ADDRESS_4),
                presentCountry: this.replaceNull(this.concatCdAndDesc(ev.PRE_COUNTRY_CD, ev.PRE_COUNTRY_NAME)),
                presentCity: this.replaceNull(this.concatCdAndDesc(ev.PRE_CITY_CD, ev.PRE_CITY_NAME)),
                presentProvince: this.replaceNull(this.concatCdAndDesc(ev.PRE_PROVINCE_CD, ev.PRE_PROVINCE_NAME)),
                presentPostalCd: this.replaceNull(this.concatCdAndDesc(ev.PRE_POSTAL_CD, ev.PRE_POSTAL_DESC)),
                nameOfEmployer: this.replaceNull(ev.EMP_BUS_NAME),
                natureOfEmployment: this.replaceNull(ev.EMP_BUS_NATURE),
                rmNo: this.replaceNull(ev.RM_NO),
                createUser: this.replaceNull(ev.CREATE_USER),
                createDate: this.replaceNull(this.formatDate(ev.CREATE_DATE)),
                lastUserUpdate: this.replaceNull(this.formatDate(ev.LAST_USER_UPDATE)),
                lastUser: this.replaceNull(ev.LAST_USER)
            });
        } catch (e) {
    console.error(e);
        }
    }

    assuredFieldDataClearer() {
        try {
            this.assuredForm.patchValue({
                assuredNo: [null],
                clientType: [null],
                activeTag: [null],
                firstName: [null],
                lastName: [null],
                middleName: [null],
                suffix: [null],
                displayName: [null],
                emailAdd: [null],
                birthDate: [null],
                nationality: [null],
                gender: [null],
                maritalStatus: [null],
                compName: [null],
                regNo: [null],
                occupation: [null],
                jobCategory: [null],
                permanentAdd1: [null],
                permanentAdd2: [null],
                permanentAdd3: [null],
                permanentAdd4: [null],
                permanentCountry: [null],
                permanentCity: [null],
                permanentProvince: [null],
                permanentPostalCd: [null],
                riskRatingScore: [null],
                riskProfile: [null],
                fundSource: [null],
                noTinTag: [null],
                tinNo: [null],
                mobileNo: [null],
                telNo: [null],
                birthCountry: [null],
                birthCity: [null],
                birthProvince: [null],
                bpimsCd: [null],
                bpimsShortDesc: [null],
                sameAsPermAdd: [null],
                presentAdd1: [null],
                presentAdd2: [null],
                presentAdd3: [null],
                presentAdd4: [null],
                presentCountry: [null],
                presentCity: [null],
                presentProvince: [null],
                presentPostalCd: [null],
                nameOfEmployer: [null],
                natureOfEmployment: [null],
                rmNo: [null],
                createUser: [null],
                createDate: [null],
                lastUserUpdate: [null],
                lastUser: [null]
            });
        } catch (e) {
    console.error(e);
        }
    }

    assuredIdFieldDataLoader(ev: any) {
        try {
            this.assuredIdForm.patchValue({
                idType: this.replaceNull(ev.ID_TYPE_DESC),
                imgFile: this.replaceNull(ev.FILE_NAME),
                idNo: this.replaceNull(ev.ID_NO),
                expDate: this.replaceNull(this.formatDate(ev.EXPIRY_DATE)),
                uploadUser: this.replaceNull(ev.UPLOAD_USER),
                uploadDate: this.replaceNull(this.formatDate(ev.UPLOAD_DATE)),
                lastUpdateUser: this.replaceNull(ev.USER_ID),
                lastUpdate: this.replaceNull(this.formatDate(ev.LAST_UPDATE))
            });
        } catch (e) {
    console.error(e);
        }
    }

    assuredIdFieldDataClearer() {
        try {
            this.assuredIdForm.patchValue({
                idType: [null],
                imgFile: [null],
                idNo: [null],
                expDate: [null],
                uploadUser: [null],
                uploadDate: [null],
                lastUpdateUser: [null],
                lastUpdate: [null],
            });
        } catch (e) {
    console.error(e);
        }
    }

    assuredAttFieldDataLoader(ev: any) {
        try {
            this.assuredAttForm.patchValue({
                fileId: this.replaceNull(ev.FILE_ID),
                file: this.replaceNull(ev.FILE_NAME),
                fileSize: this.replaceNull(this.convertToMB(ev.FILE_SIZE)),
                deleteTag: this.replaceNull(ev.DELETE_TAG),
                uploadUser: this.replaceNull(ev.UPLOAD_USER),
                uploadDate: this.replaceNull(this.formatDate(ev.UPLOAD_DATE)),
                lastUpdateUser: this.replaceNull(ev.LAST_USER),
                lastUpdate: this.replaceNull(this.formatDate(ev.LAST_USER_UPDATE))
            });
        } catch (e) {
    console.error(e);
        }
    }

    assuredAttFieldDataClearer() {
        try {
            this.assuredAttForm.patchValue({
                fileId: [null],
                file: [null],
                fileSize: [null],
                deleteTag: [null],
                uploadUser: [null],
                uploadDate: [null],
                lastUpdateUser: [null],
                lastUpdate: [null]
            });
        } catch (e) {
    console.error(e);
        }
    }

    filterTblData() {
        try {
            this.tblConfigAssuredId.tblData = this.tblAssuredId.filter((d: any) =>
                (d.ASSD_NO == this.assuredForm.get('assuredNo')?.value));
            this.tblConfigAssuredAtt.tblData = this.tblAssuredAtt.filter((d: any) =>
                (d.ASSD_NO == this.assuredForm.get('assuredNo')?.value));
        } catch (e) {
    console.error(e);
        }
    }

    replaceNull(ev: any) {
        if ((ev == null) || (ev == '')) {
            return '-';
        } else {
            return ev;
        }
    }

    concatCdAndDesc(cd: any, desc: any) {
        if ((cd == null) || (desc == null)) {
            return null;
        } else {
            return cd + ' - ' + desc;
        }
    }

    formatDate(date: any) {
        try {
            return this.datePipe.transform(new Date(date), 'MM/dd/yyyy');
        } catch (e) {
    console.error(e);
            return '00/00/0000';
        }
    }

    convertToMB(fileSize: any) {
        let convertedFileSize: any;
        if (fileSize != null && fileSize != '') {
            convertedFileSize = (fileSize * 0.000001).toFixed(6) + ' MB';
            return convertedFileSize;
        } else {
            return null;
        }
    }
}