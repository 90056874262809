<div class="mtn-container">
    <div class="mtn-title">
        <p>{{moduleName}}</p>
    </div>
    <div class="outer-wrapper p-datatable mtn-header">
        <div class="p-datatable-header"><span class="header-title">PAYMENT OPTIONS</span></div>
        <div *ngIf="showForm.paytOpt" class="form-content"></div>
        <osp-table [tblConfig]="tblConfig.paytOpt" (onRowClick)="onRowClick.paytOpt($event)"></osp-table>
        <div id="paytOpt-maintenance-loading"><div class="form-loader"></div></div>

        <div class="form-content" [hidden]="!showForm.paytOpt">

            <form [formGroup]="forms.paytOptForm" class="form">
                <div id="paytOpt-error-message"></div>
                <div class="form-container-grid">
                    <div class="field">
                        <label>Payment Code</label>
                    </div>
                    <div class="">
                        <input formControlName="paytCdPadded" type="text">
                    </div>

                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag" >
                            <option *ngFor="let activeType of selections.activeTypes" [value]="activeType.cd">
                                {{activeType.name}}
                            </option>
                        </select>
                    </div>

                    <div class="field">
                        <label>Paynamics Code</label>
                    </div>
                    <div class="">
                        <input formControlName="paynamicsCd" type="text" maxlength="20">
                    </div>

                    <div class="field required">
                        <label>Default</label>
                    </div>
                    <div class="">
                        <select formControlName="default">
                            <option *ngFor="let defaultType of selections.defaultTypes" [value]="defaultType.cd">
                                {{defaultType.name}}
                            </option>
                        </select>
                    </div>
                    <div class="field required">
                        <label>Payment Description</label>
                    </div>
                    <div class="">
                        <input formControlName="paytDesc" type="text" maxlength="250">
                    </div>

                    <div class="field">
                        <label>Image Path</label>
                    </div>
                    <div class="">
                        <input formControlName="imgPath" type="text" maxlength="500">
                    </div>

                    <div class="field">
                        <label>Sequence No.</label>
                    </div>
                    <div class="">
                        <input formControlName="seqNo" (blur)="_formService.setToNumber(forms.paytOptForm, 'seqNo', $event)" (keypress)="allowNumericDigitsOnly($event, 'seqNo')">
                    </div>

                    <!-- <div class="field">
                        <label>Minimum Payment Amount</label>
                    </div>
                    <div class="">
                        <input class="currency" formControlName="minPaytAmt" (focus)="_formService.setFieldToNumberWithDecimal(forms.paytOptForm, 'minPaytAmt', $event)"
                        (blur)="_formService.setFieldToNumberWithDecimal(forms.paytOptForm, 'minPaytAmt', $event); _formService.setFieldToAmountFormat(forms.paytOptForm, 'minPaytAmt', $event)" (keypress)="allowNumericDigitsOnly($event, 'minPaytAmt')">
                    </div> decimal [digits]="3" [decimals]="9" -->

                    <div class="field">
                        <label>Minimum Payment Amount</label>
                    </div>
                    <div class="">
                        <input class="currency" formControlName="minPaytAmt" decimal [digits]="14" [decimals]="2">
                    </div>

                    <div class="field">
                        <label>Remarks</label>
                        </div>
                    <div class="span">
                        <input formControlName="remarks" type="text" maxlength="4000">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="lastUpdateUser">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="lastUpdate">
                    </div>
                </div>    
            </form>

            <div class="btn-grp">
                <button *ngIf="btns.paytOpt.updateDisabled" class="btn btn2" type="button" (click)="onAdd.paytOpt()" [disabled]="btns.paytOpt.addDisabled" >ADD</button>
                <button *ngIf="!btns.paytOpt.updateDisabled" class="btn btn2" type="button" (click)="onUpdate.paytOpt()" >UPDATE</button>
                <button class="btn btn2" type="button" (click)="onDelete.paytOpt()" [disabled]='btns.paytOpt.deleteDisabled'>DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-grp">
                <button type="button" class="btn btn2" [disabled]="btns.paytOpt.saveDisabled" (click)="onSave.paytOpt()">SAVE</button>
            </div>
        </div>
    </div> 
    <div class="outer-wrapper p-datatable mtn-header">
        <div class="p-datatable-header"><span class="header-title">CREDIT CARD INSTALLMENT PAYMENT OPTIONS</span></div>
        <osp-table [tblConfig]="tblConfig.credCardInstPaytOpt" (onRowClick)="onRowClick.credCardInstPaytOpt($event)"></osp-table>
        <div id="credCardInstPaytOpt-maintenance-loading"><div class="form-loader"></div></div>
        <div *ngIf="showForm.credCardInstPaytOpt" class="form-content">
            <form [formGroup]="forms.credCardInstPaytOptForm" class="form">
                <div id="credCardInstPaytOpt-error-message"></div>
                <div class="form-container-grid top-form">
                    <div class="field">
                        <label>Credit Card Installment Code</label>
                    </div>
                    <div class="">
                        <input formControlName="ccInstCdPadded" type="text">
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag" >
                            <option *ngFor="let activeType of selections.activeTypes" [value]="activeType.cd">
                                {{activeType.name}}
                            </option>
                        </select>
                    </div>
                    <div class="field">
                        <label>Paynamics Code</label>
                    </div>
                    <div class="">
                        <input formControlName="paynamicsCd" type="text" maxlength="20">
                    </div>

                    <div class="field">
                        <label>Sequence No</label>
                    </div>
                    <div class="">
                        <input formControlName="seqNo" (blur)="_formService.setToNumber(forms.credCardInstPaytOptForm, 'seqNo', $event)" (keypress)="allowNumericDigitsOnly($event, 'seqNo')">
                    </div>
                    <div class="field required">
                        <label>Credit Card Installment Description</label>
                    </div>
                    <div class="">
                        <input formControlName="ccInstDesc" type="text" maxlength="250">
                    </div>
                    <div class="field">
                        <label>Image Path</label>
                    </div>
                    <div class="">
                        <input formControlName="imgPath" type="text" maxlength="500">
                    </div>
                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" type="text" maxlength="4000">
                    </div>
                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input formControlName="lastUpdateUser" >
                    </div>
                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="lastUpdate" >
                    </div>
                </div>
            </form>
            <div class="btn-grp">
                <button *ngIf="btns.credCardInstPaytOpt.updateDisabled"class="btn btn2" type="button" (click)="onAdd.credCardInstPaytOpt()" [disabled]="btns.credCardInstPaytOpt.addDisabled">ADD</button>
                <button *ngIf="!btns.credCardInstPaytOpt.updateDisabled" class="btn btn2" type="button" (click)="onUpdate.credCardInstPaytOpt()" >UPDATE</button>
                <button class="btn btn2" type="button" (click)="onDelete.credCardInstPaytOpt()" [disabled]="btns.credCardInstPaytOpt.deleteDisabled">DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-grp">
                <button class="btn btn2" type="button" (click)="onSave.credCardInstPaytOpt()" [disabled]="btns.credCardInstPaytOpt.saveDisabled">SAVE</button>
            </div>
        </div>
    </div>
</div>