import { Component, OnInit } from '@angular/core';
import { APICallService } from 'src/app/services/api-call.service';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { DialogBoxService } from 'src/app/services/dialog-box.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { forkJoin, Observable } from 'rxjs';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { SecurityService } from 'src/app/services/security.service'; 
import { PlanLovComponent } from '../../common/plan-lov/plan-lov.component';
import { UserTypeLovDialogComponent } from '../../common/user-type-lov/user-type-lov.component';
import { UserLevelLovDialogComponent } from '../../common/user-level-lov/user-level-lov.component';
@Component({
  selector: 'effective-date-basis-maintenance',
  templateUrl: './effective-date-basis-maintenance.component.html',
  styleUrls: ['./effective-date-basis-maintenance.component.css']
})
export class EffectiveDateBasisMaintenanceComponent implements OnInit, LeaveConfirmation {
  
  datePipe = new DatePipe('en-us');

  addDisabled : boolean = false;
  saveDisabled : boolean = true;
  deleteDisabled : boolean = true;
  tranPosted : boolean = false;
  noLoadingInProcess : boolean = true;
  validated : boolean = false;
  userIDReadOnly : boolean = false;
  isLineSelected : boolean = false;
  pubUserStdRatesCodeField: string = '';
  currentUser: string = this.jsonDataService.retrieveFromStorage('userId');
  rowClickedData: any;
  selectedData : any = null;
  moduleId: string = 'BMM182';
  sublineDisabled: boolean = true;
  planDisabled:boolean = false;
  moduleName: string = '';
  sublineTimeTagList:any = [];
  selectedLine: any = {
    LINE_CD: '',
    LINE_NAME: '',
    LINE_FIELD: ''
  };

  selectedSubline: any = {
    SUBLINE_CD: '',
    SUBLINE_NAME: '',
    SUBLINE_FIELD: ''
  };

  selectedPlan: any = {
    PLAN_CD: '',
    PLAN_NAME: '',
    PLAN_FIELD: ''
  };

  selectedUserType: any = {
    USER_TYPE:'',
    USER_TYPE_DESC:'',
    USER_TYPE_FIELD:''
  };

  selectedUserLevel: any = {
    USER_LEVEL_CD:'',
    USER_LEVEL_DESC:'',
    USER_LEVEL_FIELD:''
  };
  tempAddedData: any = [];
  tempUpdateData: any = [];

  paramForm = this.formBuilder.group({
    lineCd: [null],
    lineName: [null],
    lineField: [null],
    sublineCd: [null],
    sublineName: [null],
    sublineField: [null],
    userLevelCd:[null],
    userLevelDesc:[null],
    userLevelField:[null],
    userTypeCd:[null],
    userTypeDesc:[null],
    userTypeField:[null]
  });
  effectiveDateBasisForm = this.formBuilder.group({
    planCd:[null],
    planName:[null],
    planField:[null],
    allowableDateRange: [null, [Validators.required, Validators.maxLength(17)]],
    activeTag: ['A', [Validators.required]],
    sublineTimeTag: [null],
    remarks: [null],
    userID: [null],
    lastUserUpdate: [null]
  });

  constructor(
    private apiCallService: APICallService,
    public _Dialog: MatDialog, 
    private userDetailService: UserDetailsService,
    private formService: FormService, 
    private formBuilder: FormBuilder, 
    private appMessageService: AppMessageService,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService
  ) {
    this.sublineTimeTagList = this.jsonDataService.data.refCds.filter((e: any) => e.rvDomain=="SUBLINE_TIME_TAG");  
    this.moduleName = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId === this.moduleId })[0].moduleDesc.toUpperCase();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempAddedData!='' || this.tempUpdateData!='') {
      return false;
    } else {
      return true;
    }
  }

  effectiveDateBasisTblConfig: any = {
    cols: [
      {
        key: "PLAN_CD",
        header: "Plan Code",
        dataType: "number"
      },
      {
        key: "PLAN_NAME",
        header: "Plan",
        dataType: "string"
      },
      {
        key: "ALLOW_DATE_RANGE",
        header: "Allowable Date Range",
        dataType: "integer"
      },
      {
        key: "SUBLINE_TIME_TAG",
        header: "Subline Time Tag",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        width: '10%'
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }
  ngOnInit() {
  }

  initOnGet(): void {
    try {
      this.apiCallService.refreshNeeded$
            .subscribe(() => {
              this.getAllEffectiveDateBasis();
          });
      this.getAllEffectiveDateBasis();
    }
    catch(e) {
    }
  }

  async getAllEffectiveDateBasis(){
    try{
      let lineCd = this.paramForm.get('lineCd')?.value;
      let sublineCd = this.paramForm.get('sublineCd')?.value;
      let userLevelCd = this.paramForm.get('userLevelCd')?.value;
      let userTypeCd = this.paramForm.get('userTypeCd')?.value;

      if (lineCd && sublineCd&& userLevelCd&& userTypeCd ) {
        const params = {
          queryParams:{
            userTypeCd: userTypeCd,
            sublineCd: sublineCd,
            lineCd: lineCd,
            userLevelCd: userLevelCd,
          },
          moduleId: this.moduleId,
          userId: this.userDetailService.userId,
          type: "MODULE"
        }
        this.effectiveDateBasisTblConfig.loading = true;
        this.apiCallService.getEffectiveDateBasis(params).subscribe({
          next: (data: any) => {
            this.securityService.checkRequestKeyResponse(data, ()=>{
              this.securityService.hasValidCsrfToken(data, ()=>{
                data = JSON.parse(this.jsonDataService.decrypt(data.response));
                let rawData:any = [];
                let finalData:any = [];
                if(!Array.isArray(data)){
                  rawData.push(data);
                }
                else{
                  rawData = data;
                }
                
                this.effectiveDateBasisTblConfig.tblData = rawData;
                this.effectiveDateBasisTblConfig.loading = false;
                this.tempAddedData = [];
                this.tempUpdateData = [];
                this.saveDisabled = this.saveChecker();
              });
            })
          },
          error: (e: any) => {
            this.effectiveDateBasisTblConfig.loading = false;
          }
        });
      } else{
        this.effectiveDateBasisTblConfig.loading = true;
        this.effectiveDateBasisTblConfig.tblData = [];
        this.effectiveDateBasisTblConfig.loading = false;
        this.tempAddedData = [];
        this.tempUpdateData = [];
        this.saveDisabled = this.saveChecker();
      }
    }catch(e){
    }
  }

  updateLine() {
    try {
      this.tranPosted = true;
      this.validateUpdate();
      if (this.validated) {
        this.updateFormTable();
      }
    }
    catch(e) {
    }
  }
  updateFormTable() {
    try {
      let tempInsertChecker = this.tempAddedData.find((line: { PLAN_CD: any }) => 
        +line.PLAN_CD == +this.effectiveDateBasisForm.get('planCd')?.value
      );
      let tempUpdateChecker = this.tempUpdateData.find((line: { PLAN_CD: any }) => 
        +line.PLAN_CD == +this.effectiveDateBasisForm.get('planCd')?.value
      );
      
      this.tableDataUpdate();
      // Adds Updated Data that exists in the database to an object array for updated datas
      if(tempInsertChecker!=null && tempInsertChecker!='') {
        //this.tempUpdateData.push(this.formValueToData());
        tempInsertChecker.LINE_CD= this.paramForm.get('lineCd')?.value;
        tempInsertChecker.SUBLINE_CD= this.paramForm.get('sublineCd')?.value;
        tempInsertChecker.PLAN_CD= +this.effectiveDateBasisForm.get('planCd')?.value;
        tempInsertChecker.PLAN_NAME= this.effectiveDateBasisForm.get('planName')?.value;
        tempInsertChecker.PLAN_FIELD= this.effectiveDateBasisForm.get('planField')?.value;
        tempInsertChecker.USER_TYPE= this.paramForm.get('userTypeCd')?.value;
        tempInsertChecker.USER_LEVEL_CD= this.paramForm.get('userLevelCd')?.value;
        tempInsertChecker.ALLOW_DATE_RANGE = +this.effectiveDateBasisForm.get('allowableDateRange')?.value;
        tempInsertChecker.ACTIVE_TAG = this.effectiveDateBasisForm.get('activeTag')?.value;
        tempInsertChecker.SUBLINE_TIME_TAG = this.effectiveDateBasisForm.get('sublineTimeTag')?.value;
        tempInsertChecker.REMARKS = this.effectiveDateBasisForm.get('remarks')?.value;
        tempInsertChecker.LAST_USER_UPDATE = this.datePipe.transform(new Date(this.effectiveDateBasisForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a');
        tempInsertChecker.USER_ID = this.effectiveDateBasisForm.get('userID')?.value;
        tempInsertChecker.MODE = 'I';
      } 
      else if(tempUpdateChecker!=null && tempUpdateChecker!='') {
        tempUpdateChecker.SUBLINE_CD= this.paramForm.get('sublineCd')?.value;
        tempUpdateChecker.LINE_CD= this.paramForm.get('lineCd')?.value;
        tempUpdateChecker.PLAN_CD= +this.effectiveDateBasisForm.get('planCd')?.value;
        tempUpdateChecker.PLAN_NAME= this.effectiveDateBasisForm.get('planName')?.value;
        tempUpdateChecker.PLAN_FIELD= this.effectiveDateBasisForm.get('planField')?.value;
        tempUpdateChecker.USER_TYPE= this.paramForm.get('userTypeCd')?.value;
        tempUpdateChecker.USER_LEVEL_CD= this.paramForm.get('userLevelCd')?.value;
        tempUpdateChecker.ALLOW_DATE_RANGE  = +this.effectiveDateBasisForm.get('allowableDateRange')?.value;
        tempUpdateChecker.ACTIVE_TAG = this.effectiveDateBasisForm.get('activeTag')?.value;
        tempUpdateChecker.SUBLINE_TIME_TAG = this.effectiveDateBasisForm.get('sublineTimeTag')?.value;
        tempUpdateChecker.REMARKS = this.effectiveDateBasisForm.get('remarks')?.value;
        tempUpdateChecker.LAST_USER_UPDATE = this.datePipe.transform(new Date(this.effectiveDateBasisForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a');
        tempUpdateChecker.USER_ID = this.effectiveDateBasisForm.get('userID')?.value;
        tempUpdateChecker.MODE = 'U';
      }
      else {
        this.tempUpdateData.push(this.formValueToData());
      }
      this.saveDisabled = false;
      this.resetFormValues();
    }
    catch(e) {
    }
  }

  tableDataUpdate() {
    try {
      this.effectiveDateBasisForm.patchValue({
        activeTag: this.effectiveDateBasisForm.get('activeTag')?.value,
        userID: this.currentUser,
        lastUserUpdate: new Date()
      });
      let updateIndex = this.effectiveDateBasisTblConfig.tblData.indexOf(this.rowClickedData);
      
      this.effectiveDateBasisTblConfig.tblData[updateIndex] = {
        SUBLINE_CD: this.paramForm.get('sublineCd')?.value,
        LINE_CD: this.paramForm.get('lineCd')?.value,
        PLAN_CD: +this.effectiveDateBasisForm.get('planCd')?.value,
        PLAN_NAME: this.effectiveDateBasisForm.get('planName')?.value,
        PLAN_FIELD: this.effectiveDateBasisForm.get('planField')?.value,
        USER_TYPE: this.paramForm.get('userTypeCd')?.value,
        USER_LEVEL_CD: this.paramForm.get('userLevelCd')?.value,
        ALLOW_DATE_RANGE: +this.effectiveDateBasisForm.get('allowableDateRange')?.value,
        ACTIVE_TAG: this.effectiveDateBasisForm.get('activeTag')?.value,
        SUBLINE_TIME_TAG: this.effectiveDateBasisForm.get('sublineTimeTag')?.value,
        REMARKS: this.effectiveDateBasisForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.effectiveDateBasisForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.effectiveDateBasisForm.get('userID')?.value,
        MODE: 'U'
      };
      this.effectiveDateBasisTblConfig.tblData = [...this.effectiveDateBasisTblConfig.tblData];
    }
    catch(e) {
    }
  }

  formValueToData() {
    try {
      let formToData: any = { // this.datePipe.transform(new Date(), 'MM/dd/yyyy')
        SUBLINE_CD: this.paramForm.get('sublineCd')?.value,
        LINE_CD: this.paramForm.get('lineCd')?.value,
        PLAN_CD: this.effectiveDateBasisForm.get('planCd')?.value,
        PLAN_NAME: this.effectiveDateBasisForm.get('planName')?.value,
        PLAN_FIELD: this.effectiveDateBasisForm.get('planField')?.value,
        USER_TYPE: this.paramForm.get('userTypeCd')?.value,
        USER_LEVEL_CD: this.paramForm.get('userLevelCd')?.value,
        ALLOW_DATE_RANGE: this.effectiveDateBasisForm.get('allowableDateRange')?.value,
        ACTIVE_TAG: this.effectiveDateBasisForm.get('activeTag')?.value,
        SUBLINE_TIME_TAG: this.effectiveDateBasisForm.get('sublineTimeTag')?.value,
        REMARKS: this.effectiveDateBasisForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.effectiveDateBasisForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.effectiveDateBasisForm.get('userID')?.value,
        MODE: 'U'
      }
      return formToData;
    }
    catch(e) {
    }
  }
  deleteLine() {
    try {
      let clickData = this.rowClickedData;
      this.effectiveDateBasisTblConfig.tblData = this.effectiveDateBasisTblConfig.tblData.filter(
        function(e: any) {
          return +e.PLAN_CD !== +clickData.PLAN_CD;
        }
      );
      this.tempAddedData = this.tempAddedData.filter(
        function(e: any) {
          return +e.PLAN_CD !== +clickData.PLAN_CD;
        }
      );
      this.resetFormValues();
      this.appMessageService.showAppMessage("Standard Rates details deleted!", "success");
    }
    catch(e) {
    }
  }

  insertLine() {
    try {
      this.tranPosted = true;
      this.validateInsert();
      if (this.validated) {
        this.addFormToTable();
      }
    }
    catch(e) {
    }
  }
  addFormToTable() {
    try {
      this.effectiveDateBasisForm.patchValue({
        activeTag: this.effectiveDateBasisForm.get('activeTag')?.value,
        userID: this.currentUser,
        lastUserUpdate: new Date()
      });
      let formToData: any = { // this.datePipe.transform(new Date(), 'MM/dd/yyyy')
        SUBLINE_CD: this.paramForm.get('sublineCd')?.value,
        LINE_CD: this.paramForm.get('lineCd')?.value,
        PLAN_CD: +this.effectiveDateBasisForm.get('planCd')?.value,
        PLAN_NAME: this.effectiveDateBasisForm.get('planName')?.value,
        PLAN_FIELD: this.effectiveDateBasisForm.get('planField')?.value,
        USER_TYPE: this.paramForm.get('userTypeCd')?.value,
        USER_LEVEL_CD: this.paramForm.get('userLevelCd')?.value,
        ALLOW_DATE_RANGE: +this.effectiveDateBasisForm.get('allowableDateRange')?.value,
        ACTIVE_TAG: this.effectiveDateBasisForm.get('activeTag')?.value,
        SUBLINE_TIME_TAG: this.effectiveDateBasisForm.get('sublineTimeTag')?.value,
        REMARKS: this.effectiveDateBasisForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.effectiveDateBasisForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.effectiveDateBasisForm.get('userID')?.value,
        MODE: 'I'
      }
      this.effectiveDateBasisTblConfig.tblData.unshift(formToData);
      this.effectiveDateBasisTblConfig.tblData = [...this.effectiveDateBasisTblConfig.tblData];
      this.tempAddedData.push(formToData);
      this.resetFormValues();
    }
    catch(e) {
    }
  }

  saveData(tempChangeData:any[]){
    this.apiCallService.saveEffectiveDateBasis(tempChangeData)
    .subscribe({
      next: (response : any) => {
        this.securityService.checkRequestKeyResponse(response, () => {
          this.securityService.hasValidCsrfToken(response, ()=>{
            if (response.status == "SUCCESS" && this.tranPosted) {
              this.openDialog('SUCCESS', 'Details Successfully saved!');
              this.initOnGet();
              this.resetFormValues();
              this.tempAddedData = [];
              this.tempUpdateData = [];
              this.saveDisabled = this.saveChecker();
            }  
            else {
              this.initOnGet();
              this.saveDisabled = this.saveChecker();
              this.openDialog('ERROR', 'An error has occured while saving details');
            }
          });
        });
      }, 
      error: () => {
        this.openDialog('ERROR', 'An error has occured while saving details');
      }
    });
  }

  toSave() {
    try {
      this.noLoadingInProcess = false;
      this.formService.showFormLoader(null, "generate-line-form", "Please wait ...", null, null);
      let tempChangeData= this.tempAddedData.concat(this.tempUpdateData);
      if(tempChangeData.length!=0) {
        for(let item of tempChangeData){
          item.ALLOW_DATE_RANGE = item.ALLOW_DATE_RANGE.toString();
        }
        this.saveData(tempChangeData);
      } else {
        this.initOnGet();
        this.resetFormValues();
        this.tempAddedData = [];
        this.tempUpdateData = [];
        this.saveDisabled = this.saveChecker();
      }
    }
    catch(e) {
    }
  }
  saveChecker() {
    try {
      if((this.tempAddedData==null || this.tempAddedData=='') && (this.tempUpdateData==null || this.tempUpdateData=='')) {
        return true;
      }
      else {
        return false;
      }
    }
    catch(e) {
      return true;
    }
  }

  deleteChecker(ev: any) {
    try {
      let tempDataFlag;
      tempDataFlag = this.tempAddedData.filter(function(e: any) {
        return +e.PLAN_CD==+ev.PLAN_CD;
      });
      if(tempDataFlag==''||tempDataFlag==null) {
        return true;
      } else {
        return false;
      }
    }
    catch(e) {
      return true;
    }
  }
  sublineChecker(){
    try{
      let lineCd = this.paramForm.get('lineCd')?.value;
      if(lineCd!==''&&lineCd!==null){
        return false;
      }
      else{ 
        return true;
      }
    }catch(e){
      return true;
    }
  }
  onRowClick(ev: any) {
    try {    
      if(ev!=null) {
        this.rowClickedData = ev;
        this.effectiveDateBasisForm.get('lineCode')?.disable();
        this.loadFormValues();
        this.saveDisabled = this.saveChecker();
        this.deleteDisabled = this.deleteChecker(ev);
      }
      else {
        this.resetFormValues();
      }
    }
    catch(e) {
    }
  }

  loadFormValues() {
    try {
      let allowableDateRange: string = '';
      this.effectiveDateBasisForm.patchValue({
        planCd: this.rowClickedData.PLAN_CD,
        planName: this.rowClickedData.PLAN_NAME,
        planField: this.rowClickedData.PLAN_CD + " - " + this.rowClickedData.PLAN_NAME,
        allowableDateRange: this.rowClickedData.ALLOW_DATE_RANGE,
        activeTag: this.rowClickedData.ACTIVE_TAG,
        sublineTimeTag: this.rowClickedData.SUBLINE_TIME_TAG,
        remarks: this.rowClickedData.REMARKS,
        userID: this.rowClickedData.USER_ID,
        lastUserUpdate: this.rowClickedData.LAST_USER_UPDATE
      });
      this.addDisabled = true;
      this.userIDReadOnly = true;
      this.isLineSelected = true;
      this.planDisabled = true;
    }
    catch(e) {
    }
  }

  resetFormValues() {
    try {
      this.selectedData = null;
      // this.paramForm.get('lineCD')?.enable();
      this.effectiveDateBasisForm.patchValue({
        planCd: null,
        planName: null,
        planField: null,
        allowableDateRange: null,
        activeTag: 'A',
        sublineTimeTag: null,
        remarks: null,
        userID: null,
        lastUserUpdate: null
      });

      this.addDisabled = false;
      this.saveDisabled = this.saveChecker();
      this.deleteDisabled = true;
      this.userIDReadOnly = false;
      this.isLineSelected = false;

      this.rowClickedData = '' || null;
    }
    catch (e) {
    console.error(e);
    }
  }
  openUserTypeDialog() {
    try {
      this._Dialog.open(UserTypeLovDialogComponent, {
        width: '512px',
        disableClose: true,
        data: { 
          userId: this.userDetailService.userId,
          moduleId: this.moduleId
        }
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedUserType = data.content;
          this.paramForm.get('userTypeCd')?.setValue(this.selectedUserType.USER_TYPE==null || this.selectedUserType.USER_TYPE=='' ? '' : this.selectedUserType.USER_TYPE);
          this.paramForm.get('userTypeDesc')?.setValue(this.selectedUserType.USER_TYPE==null || this.selectedUserType.USER_TYPE=='' ? '' : this.selectedUserType.USER_TYPE_DESC);
          this.paramForm.get('userTypeField')?.setValue(this.selectedUserType.USER_TYPE==null || this.selectedUserType.USER_TYPE=='' ? '' : this.selectedUserType.USER_TYPE + " - " + this.selectedUserType.USER_TYPE_DESC);
          this.initOnGet();
        }
        this.resetFormValues();
      });
    } catch(e) {
    }
  }
  openUserLevelDialog() {
    try {
      this._Dialog.open(UserLevelLovDialogComponent, {
        width: '512px',
        disableClose: true,
        data: { 
          userId: this.userDetailService.userId,
          moduleId: this.moduleId
        }
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedUserLevel = data.content;
          this.paramForm.get('userLevelCd')?.setValue(this.selectedUserLevel.USER_LEVEL_CD==null || this.selectedUserLevel.USER_LEVEL_CD=='' ? '' : this.selectedUserLevel.USER_LEVEL_CD);
          this.paramForm.get('userLevelDesc')?.setValue(this.selectedUserLevel.USER_LEVEL_CD==null || this.selectedUserLevel.USER_LEVEL_CD=='' ? '' : this.selectedUserLevel.USER_LEVEL_DESC);
          this.paramForm.get('userLevelField')?.setValue(this.selectedUserLevel.USER_LEVEL_CD==null || this.selectedUserLevel.USER_LEVEL_CD=='' ? '' : this.selectedUserLevel.USER_LEVEL_CD + " - " + this.selectedUserLevel.USER_LEVEL_DESC);
          this.initOnGet();
        }
        this.resetFormValues();
      });
    } catch(e) {
    }
  }
  openLineDialog() {
    try {
      this._Dialog.open(LineLovDialogComponent, {
        width: '512px',
        disableClose: true,
        data: {
          table: 'LINE',
          userId: this.userDetailService.userId,
          moduleId: this.moduleId
        }
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedLine = data.content;
          this.paramForm.get('lineCd')?.setValue(this.selectedLine.LINE_CD==null || this.selectedLine.LINE_CD=='' ? '' : this.selectedLine.LINE_CD);
          this.paramForm.get('lineName')?.setValue(this.selectedLine.LINE_CD==null || this.selectedLine.LINE_CD=='' ? '' : this.selectedLine.LINE_NAME);
          this.paramForm.get('lineField')?.setValue(this.selectedLine.LINE_CD==null || this.selectedLine.LINE_CD=='' ? '' : this.selectedLine.LINE_CD + " - " + this.selectedLine.LINE_NAME);
        }
        this.paramForm.get('sublineCd')?.setValue(null);
        this.paramForm.get('sublineName')?.setValue(null);
        this.paramForm.get('sublineField')?.setValue(null);
        this.resetFormValues();
        this.initOnGet();
        this.sublineDisabled = this.sublineChecker();
      });
    } catch(e) {
    }
  }
  openSublineDialog() {
    try {
      this._Dialog.open(LineLovDialogComponent, {
        width: '512px',
        disableClose: true,
        data: {
          table: 'SUBLINE',
          lineCd: this.paramForm.get('lineCd')?.value,
          userId: this.userDetailService.userId,
          moduleId: this.moduleId
        }
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedSubline = data.content;
          this.paramForm.get('sublineCd')?.setValue(this.selectedSubline.SUBLINE_CD==null || this.selectedSubline.SUBLINE_CD=='' ? '' : this.selectedSubline.SUBLINE_CD);
          this.paramForm.get('sublineName')?.setValue(this.selectedSubline.SUBLINE_CD==null || this.selectedSubline.SUBLINE_CD=='' ? '' : this.selectedSubline.SUBLINE_NAME);
          this.paramForm.get('sublineField')?.setValue(this.selectedSubline.SUBLINE_CD==null || this.selectedSubline.SUBLINE_CD=='' ? '' : this.selectedSubline.SUBLINE_CD+ " - " + this.selectedSubline.SUBLINE_NAME);
          this.initOnGet();
        }
        this.resetFormValues();
      });
    } catch(e) {
    }
  }
  openPlanDialog() {
    try {
      this._Dialog.open(PlanLovComponent, {
        width: '512px',
        disableClose: true,
        data: {
          table: 'PLAN',
          lineCd: this.paramForm.get('lineCd')?.value,
          sublineCd: this.paramForm.get('sublineCd')?.value,
          userId: this.userDetailService.userId,
          moduleId: this.moduleId
        }
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedPlan = data.content;
          this.effectiveDateBasisForm.get('planCd')?.setValue(this.selectedPlan.PLAN_CD==null || this.selectedPlan.PLAN_CD=='' ? '' : this.selectedPlan.PLAN_CD);
          this.effectiveDateBasisForm.get('planName')?.setValue(this.selectedPlan.PLAN_CD==null || this.selectedPlan.PLAN_CD=='' ? '' : this.selectedPlan.PLAN_NAME);
          this.effectiveDateBasisForm.get('planField')?.setValue(this.selectedPlan.PLAN_CD==null || this.selectedPlan.PLAN_CD=='' ? '' : this.selectedPlan.PLAN_CD+ " - " + this.selectedPlan.PLAN_NAME);
        }
      });
    } catch(e) {
    }
  }
  openDialog(title: any, message: any) {
    try {
      this.noLoadingInProcess = true;
      this.formService.hideFormLoader("generate-line-form");
      this._Dialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        } 
      });
    } catch(e) {
    }
  }
  
  validateInsert() {
    try {
      this.validated = false;
      if (!this.requiredFieldCheck()){
        setTimeout(()=>{                           //10 secs countdown
          this.formService.hideFormMsg("effective-date-basis-error-message");
        }, 10000);
      }
      else if (!this.duplicateCheck(this.effectiveDateBasisTblConfig.tblData)) {
        setTimeout(()=>{                           
          this.formService.hideFormMsg("effective-date-basis-error-message");
        }, 10000);
      }
      else if(this.numberCheck('allowableDateRange')){
        setTimeout(()=>{                           
          this.formService.hideFormMsg("effective-date-basis-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
    }
  }

  requiredFieldCheck() {
    try {
      if(this.paramForm.get('lineCd')?.value==='' || this.paramForm.get('lineCd')?.value===null
        ||this.paramForm.get('sublineCd')?.value==='' || this.paramForm.get('sublineCd')?.value===null
        ||this.paramForm.get('userTypeCd')?.value==='' || this.paramForm.get('userTypeCd')?.value===null
        ||this.effectiveDateBasisForm.get('planCd')?.value==='' || this.effectiveDateBasisForm.get('planCd')?.value===null
        || this.effectiveDateBasisForm.get('allowableDateRange')?.value==='' || this.effectiveDateBasisForm.get('allowableDateRange')?.value===null
        || this.effectiveDateBasisForm.get('sublineTimeTag')?.value==='' || this.effectiveDateBasisForm.get('sublineTimeTag')?.value===null  
        || this.effectiveDateBasisForm.get('activeTag')?.value==='' || this.effectiveDateBasisForm.get('activeTag')?.value===null ) {
        this.formService.showFormMsg("effective-date-basis-error-message", 'There are missing information. Please provide the necessary information needed.', "E");
        return false;
      }
      else {
        return true;
      }
   }
    catch (e) {
    console.error(e);
      return false;
    }
  }
  validateUpdate() {
    try {
      this.validated = false;
      let fieldPlanCd= this.effectiveDateBasisForm.get('planCd')?.value;
      let filteredTblData = this.effectiveDateBasisTblConfig.tblData.filter(function(e:any){
        let ePlanCd = e.PLAN_CD;
        if(+ePlanCd!=+fieldPlanCd){
          return true
        }
        else{
          return false
        }
      })
      if (!this.requiredFieldCheck()){
        setTimeout(()=>{                          
          this.formService.hideFormMsg("effective-date-basis-error-message");
        }, 10000);
      }
      else if(this.numberCheck('allowableDateRange')){
        setTimeout(()=>{                           
          this.formService.hideFormMsg("effective-date-basis-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
    }
  }
  duplicateCheck(tblData:any) {
    try {
      let fieldPlanCd = parseInt(this.effectiveDateBasisForm.get('planCd')?.value);
      this.pubUserStdRatesCodeField = tblData.filter(function(e: any) {
        if(+e.PLAN_CD==+fieldPlanCd){
          return true
        }
        else{
          return false
        }
      });
      if(this.pubUserStdRatesCodeField=='' || this.pubUserStdRatesCodeField==null) {
        return true;
      } else {
        this.formService.showFormMsg("effective-date-basis-error-message", 'Plan already exists. Please add a different Plan', "E");
        return false;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  } 
  rateCheck(fieldName:string){
    let rate = parseFloat(this.effectiveDateBasisForm.get(fieldName)?.value);
    let fieldRate = this.effectiveDateBasisForm.get(fieldName)?.value;
    if(fieldRate===''||fieldRate===null){
      return false
    }
    if(rate<0){
      this.formService.showFormMsg("effective-date-basis-error-message", 'Entered rate is a negative number', "E");
      return true;
    }
    if(rate<1000){
      return false
    }
    else{
      this.formService.showFormMsg("effective-date-basis-error-message", 'Entered rate must have 9 decimal places', "E");
      return true
    }
  }
  numberCheck(fieldName:string){
    let fieldFloat = parseFloat(this.effectiveDateBasisForm.get(fieldName)?.value);
    let fieldAmt = this.effectiveDateBasisForm.get(fieldName)?.value;
    if(fieldAmt===''||fieldAmt===null){
      return false
    }
    if(fieldAmt.length>17){
      this.formService.showFormMsg("effective-date-basis-error-message", 'Entered input exceeds the maximum character length', "E");
      return true
    }
    else{
      return false
    }
  }
  rateFormat(fieldName:string){
    if(this.effectiveDateBasisForm.get(fieldName)?.value!==''&&this.effectiveDateBasisForm.get(fieldName)?.value!==null){
      let fieldFloat = parseFloat(this.effectiveDateBasisForm.get(fieldName)?.value)
      let fieldRate = fieldFloat.toFixed(9);
      if(isNaN(fieldFloat)||fieldRate=='NaN'){
        this.effectiveDateBasisForm.get(fieldName)?.setValue('');
      }
      else{
        this.effectiveDateBasisForm.get(fieldName)?.setValue(fieldRate);
      }
    }
  }
  amtFormat(fieldName:string){
    if(this.effectiveDateBasisForm.get(fieldName)?.value!==''&&this.effectiveDateBasisForm.get(fieldName)?.value!==null){
      let fieldFloat = parseFloat(this.effectiveDateBasisForm.get(fieldName)?.value.replace(/,/g, ''))
      let fieldAmt = fieldFloat.toFixed(0);
      if(isNaN(fieldFloat)||fieldAmt=='NaN'){
        this.effectiveDateBasisForm.get(fieldName)?.setValue('');
      }
      else{
        this.effectiveDateBasisForm.get(fieldName)?.setValue(fieldAmt);
      }
    }
  }
  numOnlyPattern(ev: any) {
    try{
      const pattern = /^-?[0-9]\d*$/;   /^-?[0-9]\d*$/
      //let inputChar = String.fromCharCode(event.charCode)
      if (!pattern.test(ev.target.value)) {
        if(ev.target.value.length == 1){
          ev.target.value = ev.target.value.replace(/[^0-9-]/g, "");
        }
        else{
          ev.target.value = ev.target.value.replace(/[^0-9]/g, "");
        }
      }
    }
    catch(e) {
    }
  }
}
function getDateNum(dateInput:Date){
  if(dateInput==null){
    return 0;
  }
  let year = dateInput.getFullYear();
  let month = dateInput.getMonth()+1;
  let day = dateInput.getDate();
  let resultString = year+""+padLeadingZeros(month, 2)+""+padLeadingZeros(day, 2);
  
  let result = parseInt(resultString);
  return result;
}
function padLeadingZeros(num:number, size:number) {
  let sNum = num+"";
  while (sNum.length < size) sNum = "0" + sNum;
  return sNum;
}