import { Component, ElementRef } from '@angular/core';
import { map } from 'rxjs/operators';

import { DeviceDetectorService } from 'ngx-device-detector';

import { APICallService } from './services/api-call.service';
import { JsonDataService } from './services/json-data.service';
import { PageValidationService } from './services/page-validation.service';
import { UserDetailsService } from './services/user-details.service';
import { SecurityService } from './services/security.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

    doneCheckingAuth: boolean = false;
    title = 'OSP ADMIN';

    constructor (
        // PUBLIC
        public jsonDataService: JsonDataService,

        // PRIVATE
        private elementRef: ElementRef,
        private deviceDetectorService: DeviceDetectorService,
        private pageValidationService: PageValidationService,
        private userDetailsService: UserDetailsService,
        private apiCallService: APICallService,
        private securityService: SecurityService
    ) {
        this.setLastActionHostListener();
        this.checkAuthorization();
    }

    private setLastActionHostListener(): void {
        this.elementRef.nativeElement.addEventListener("keypress", () => {
            this.userDetailsService.lastActionTime = new Date();
        });
        this.elementRef.nativeElement.addEventListener("click", () => {
            this.userDetailsService.lastActionTime = new Date();
        });

        if (this.deviceDetectorService.isDesktop()) {
            this.elementRef.nativeElement.addEventListener("mousemove", () => {
                this.userDetailsService.lastActionTime = new Date();
            });
        } else {
            this.elementRef.nativeElement.addEventListener("touchmove", () => {
                this.userDetailsService.lastActionTime = new Date();
            });
        }
    }

    async checkAuthorization():Promise<void> {
        await this.apiCallService.checkAuthorization().pipe(
            map((response:any) => {
                this.securityService.checkRequestKeyResponse(response, () => {
                    if (response.content !== undefined) {
                        const content = JSON.parse(this.jsonDataService.decrypt(response.content));

                        if (response.status === "AUTHORIZED") {
                            if (content.activeTag === "L") {
                                // Do nothing for now.
                            } else {
                                this.userDetailsService.setUserDetails("S", content.userId, content.name, content.email, content.expiry, content.windowId);
                                this.pageValidationService.hasSessionCheckRoute();
                            }
                        } else if (response.status === "LIMIT_REACHED") {
                            this.userDetailsService.setUserDetails("S", content.userId, content.name, content.email, content.expiry, content.windowId);
                            this.pageValidationService.hasSessionCheckRoute();
                        }
                    } else {
                        sessionStorage.removeItem("bssid");
                        sessionStorage.removeItem("rkc");
                        this.userDetailsService.resetUserDetails();
                    }
                });
            })
        ).toPromise();

        this.doneCheckingAuth = true;
    }

}
