import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { CustomValidators } from 'src/app/utils/custom-validator';
import { AgentLovComponent } from '../../common/agent-lov/agent-lov.component';
import { BmaLovComponent } from '../../common/bma-lov/bma-lov.component';
import { ClientGroupLovDialog } from '../../common/client-group-lov-dialog/client-group-lov-dialog.component';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';
import { PlanLovComponent } from '../../common/plan-lov/plan-lov.component';
import { ProjectLovComponent } from '../../common/project-lov/project-lov.component';
import { RefLovComponent } from '../../common/ref-lov/ref-lov.component';
import { SrcCdLovComponent } from '../../common/src-cd-lov/src-cd-lov.component';
import { SrcExtLovComponent } from '../../common/src-ext-lov/src-ext-lov.component';

@Component({
  selector: 'product-code-maintenance',
  templateUrl: './product-code-maintenance.component.html',
  styleUrls: ['./product-code-maintenance.component.css']
})
export class ProductCodeMaintenanceComponent implements OnInit, LeaveConfirmation {

  private moduleId: string = 'BMM157';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc ?? "";
  private datePipe = new DatePipe('en-us');

  constructor(
    public _formService: FormService,
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService,
    private _appMessageService: AppMessageService,
  ) {
    this.userIdLoggedIn = this._userDetailService.userId ?? "";
    this.minEffDate = new Date();
    this.minExpDate = new Date();
    this.forms.productCodeForm.get('effectiveDate')?.valueChanges.subscribe((res) => {
      this.minExpDate = new Date(res);
    });
    this.forms.productCodeForm.get('expiryDate')?.valueChanges.subscribe((res) => {
      this.maxEffDate = res ? new Date(res) : undefined;
    });
  }

  private addDay(date: Date, day: number): Date {
    date = new Date(date);
    date.setDate(date.getDate() + day);
    return date;
  }

  canDeactivate(): boolean | Observable<boolean> {
    return !(this.productCodetblConfig.tblData.filter((row: any) => ['I', 'U'].includes(row.operation)).length > 0);
  }

  public btns = {
    productCode: {
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      addDisabled: true,
      formLovSearchDisabled: true,
      clientTypeLovSearchDisabled: true,
    },
    filterLov: {
      lineLovSearchDisabled: false,
      sublineLovSearchDisabled: true,
      planLovSearchDisabled: true,
      clientTypeLovSearchDisabled: false,
    }
  };

  public showForm: boolean = true;

  public minEffDate: Date;
  public maxEffDate!: Date | undefined;
  public minExpDate!: Date | undefined;

  //* Data Stuffs */
  private rowData: any;
  private errorMessage: string = "";
  private userIdLoggedIn: string;
  //? set selectedRow to [] to unselect the table
  public selectedRow!: any[];

  public forms = {
    filterLovForm: this.fb.group({
      lineCd: [null],
      lineName: [null],
      sublineCd: [null],
      sublineName: [null],
      planCd: [null],
      planName: [null],
      clientType: [null],
      clientTypeName: [null],
      lineCdDisp: [{ value: '', disabled: 'true' }],
      sublineCdDisp: [{ value: '', disabled: 'true' }],
      planDisp: [{ value: '', disabled: 'true' }],
      clientTypeDisp: [{ value: '', disabled: 'true' }],
    }),
    productCodeForm: this.fb.group({
      lineCd: [null],
      sublineCd: [null],
      planCd: [null],
      clientType: [null, [Validators.required]],
      clientTypeName: [null, [Validators.required]],
      clientTypeDisp: [{ value: '', disabled: 'true' }],
      productCode: [null, [Validators.required, CustomValidators.requiredTrim]],
      userType: [null, [Validators.required]],
      userLevel: [null, [Validators.required]],
      agentGrp: [null],
      active: ["A", [Validators.required]],
      effectiveDate: [new Date(), [Validators.required]],
      expiryDate: [null],
      totalCount: [null, [Validators.required]],
      usedCount: [{ value: '', disabled: 'true' }],
      projectCd: [null],
      projectName: [null],
      projectDisp: [{ value: '', disabled: 'true' }],
      agentCd: [null],
      agentName: [null],
      agentDisp: [{ value: '', disabled: 'true' }],
      bmaCd: [null],
      bmaBmCode: [null],
      bmaName: [null],
      bmaDisp: [{ value: '', disabled: 'true' }],
      referrorCd: [null],
      referrorBmCode: [null],
      referrorName: [null],
      referrorDisp: [{ value: '', disabled: 'true' }],
      srcCd: [null],
      srcBmCode: [null],
      srcCdName: [null],
      srcCdDisp: [{ value: '', disabled: 'true' }],
      srcExtCd: [null],
      srcExtBmCode: [null],
      srcExtName: [null],
      srcExtDisp: [{ value: '', disabled: 'true' }],
      remarks: [null],
      createUser: [{ value: '', disabled: 'true' }],
      createDate: [{ value: '', disabled: 'true' }],
      lastUpdateUser: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }],
    }, {
      validators: [this.checkDupes()],
    }),
    resetProductCodeForm: () => {
      this.forms.productCodeForm.reset();
      this.btns.productCode.updateDisabled = true;
      this.btns.productCode.deleteDisabled = true;

      this.forms.productCodeForm.patchValue({
        active: 'A',
        effectiveDate: new Date(),
        clientType: this.forms.filterLovForm.get('clientType')?.value,
        clientTypeName: this.forms.filterLovForm.get('clientTypeName')?.value,
        clientTypeDisp: this.forms.filterLovForm.get('clientTypeDisp')?.value,
      });

      this._formService.hideFormMsg("product-code-error-message");
      this.rowData = null;
      this.forms.productCodeForm.get('productCode')?.enable();
      this.forms.productCodeForm.get('effectiveDate')?.enable();
      this.forms.productCodeForm.get('userType')?.enable();
      this.forms.productCodeForm.get('userLevel')?.enable();
    },
    resetFilterLovForm: () => {
      this.forms.filterLovForm.reset();
    },
    resetAllForms: () => {
      this.forms.resetFilterLovForm();
      this.forms.resetProductCodeForm();
    }
  }

  private productCodeFormManipulate = {
    dbToForm: (data: any) => {
      return {
        lineCd: data.LINE_CD,
        sublineCd: data.SUBLINE_CD,
        planCd: data.PLAN_CD,
        clientType: data.CLIENT_CD,
        clientTypeName: data.CLIENT_TYPE_DESC,
        clientTypeDisp: `${data.CLIENT_CD ?? ''} - ${data.CLIENT_TYPE_DESC ?? ''}`,
        productCode: data.PRODUCT_CODE,
        active: data.ACTIVE_TAG,
        userType: data.USER_TYPE,
        userLevel: data.USER_LEVEL,
        agentGrp: data.AGENT_GRP,
        effectiveDate: data.EFFECTIVE_DATE ? new Date(data.EFFECTIVE_DATE) : null,
        expiryDate: data.EXPIRY_DATE ? new Date(data.EXPIRY_DATE) : null,
        totalCount: data.TOTAL_COUNT,
        usedCount: data.USED_COUNT,
        projectCd: data.PROJECT_CD,
        projectName: data.PROJECT_DESC,
        projectDisp: `${data.PROJECT_CD ?? ''} - ${data.PROJECT_DESC ?? ''}`,
        agentCd: data.AGENT_CD,
        agentName: data.AGENT_DESC,
        agentDisp: `${data.AGENT_CD ?? ''} - ${data.AGENT_DESC ?? ''}`,
        bmaCd: data.BMA_CD,
        bmaBmCode: data.BMA_BM_CODE,
        bmaName: data.BMA_DESC,
        bmaDisp: `${data.BMA_BM_CODE ?? ''} - ${data.BMA_DESC ?? ''}`,
        referrorCd: data.REFERROR_CD,
        referrorBmCode: data.REFERROR_BM_CODE,
        referrorName: data.REFERROR_DESC,
        referrorDisp: `${data.REFERROR_BM_CODE ?? ''} - ${data.REFERROR_DESC ?? ''}`,
        srcCd: data.SRC_CD,
        srcBmCode: data.SRC_BM_CODE,
        srcCdName: data.SRC_CD_DESC,
        srcCdDisp: `${data.SRC_BM_CODE ?? ''} - ${data.SRC_CD_DESC ?? ''}`,
        srcExtCd: data.SRC_EXT_CD,
        srcExtBmCode: data.SRC_EXT_BM_CODE,
        srcExtName: data.SRC_EXT_DESC,
        srcExtDisp: `${data.SRC_EXT_BM_CODE ?? ''} - ${data.SRC_EXT_DESC ?? ''}`,
        remarks: data.REMARKS,
        createUser: data.CREATE_USER,
        createDate: data.CREATE_DATE,
        lastUpdateUser: data.LAST_UPDATE_USER,
        lastUpdate: data.LAST_UPDATE,
      };
    },
    formToDb: (data: any) => {
      return {
        LINE_CD: data.lineCd,
        SUBLINE_CD: data.sublineCd,
        PLAN_CD: data.planCd,
        CLIENT_CD: data.clientType,
        CLIENT_TYPE_DESC: data.clientTypeName,
        PRODUCT_CODE: data.productCode?.trim()?.toUpperCase(),
        ACTIVE_TAG: data.active,
        USER_TYPE: data.userType,
        USER_TYPE_DESC: this.selections.userTypes.find((a: any) => a.cd === data.userType)?.name,
        USER_LEVEL: data.userLevel,
        USER_LEVEL_DESC: this.selections.userLevels.find((a: any) => a.cd === data.userLevel)?.name,
        AGENT_GRP: data.agentGrp,
        EFFECTIVE_DATE: data.effectiveDate ? this.datePipe.transform(new Date(data.effectiveDate), 'yyyy-MM-dd') : null,
        EXPIRY_DATE: data.expiryDate ? this.datePipe.transform(new Date(data.expiryDate), 'yyyy-MM-dd') : null,
        TOTAL_COUNT: data.totalCount == "" ? null : data.totalCount,
        USED_COUNT: data.usedCount,
        PROJECT_CD: data.projectCd,
        PROJECT_DESC: data.projectName,
        AGENT_CD: data.agentCd,
        AGENT_DESC: data.agentName,
        BMA_CD: data.bmaCd,
        BMA_BM_CODE: data.bmaBmCode,
        BMA_DESC: data.bmaName,
        REFERROR_CD: data.referrorCd,
        REFERROR_BM_CODE: data.referrorBmCode,
        REFERROR_DESC: data.referrorName,
        SRC_CD: data.srcCd,
        SRC_BM_CODE: data.srcBmCode,
        SRC_CD_DESC: data.srcCdName,
        SRC_EXT_CD: data.srcExtCd,
        SRC_EXT_BM_CODE: data.srcExtBmCode,
        SRC_EXT_DESC: data.srcExtName,
        REMARKS: data.remarks,
        CREATE_USER: data.createUser,
        CREATE_DATE: data.createDate,
        LAST_UPDATE_USER: null,
        LAST_UPDATE: null,
      };
    },
  }

  public productCodetblConfig: any = {
    cols: [
      {
        key: "PRODUCT_CODE",
        header: "Product Code",
        dataType: "string",
      },
      {
        key: "USER_TYPE_DESC",
        header: "User Type",
        dataType: "string"
      },
      {
        key: "USER_LEVEL_DESC",
        header: "User Level",
        dataType: "string"
      },
      {
        key: "CLIENT_TYPE_DESC",
        header: "Client Type Group",
        dataType: "string"
      },
      {
        key: "EFFECTIVE_DATE",
        header: "Effective Date",
        dataType: "date"
      },
      {
        key: "EXPIRY_DATE",
        header: "Expiry Date",
        dataType: "date"
      },
      {
        key: "TOTAL_COUNT",
        header: "Total Count",
        dataType: "number"
      },
      {
        key: "USED_COUNT",
        header: "Used Count",
        dataType: "number"
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        width: '48px',
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  ngOnInit(): void {
  }

  public openLov = {
    line: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'LINE',
          moduleId: this.moduleId
        },
      }
      this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button?.toUpperCase() === 'OK') {
          this.forms.resetProductCodeForm();
          if (output) {
            this.forms.filterLovForm.patchValue({
              lineCd: output.LINE_CD,
              lineName: output.LINE_NAME,
              lineCdDisp: `${output.LINE_CD} - ${output.LINE_NAME}`,
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null,
              planCd: null,
              planName: null,
              planDisp: null,
            });
            this.btns.productCode.addDisabled = true;
            this.btns.filterLov.sublineLovSearchDisabled = false;
            this.btns.filterLov.planLovSearchDisabled = true;
          }
          else {
            this.forms.filterLovForm.patchValue({
              lineCd: null,
              lineName: null,
              lineCdDisp: null,
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null,
              planCd: null,
              planName: null,
              planDisp: null,
            });
            this.btns.productCode.addDisabled = this.btns.productCode.formLovSearchDisabled = true;
            this.btns.filterLov.sublineLovSearchDisabled = true;
            this.btns.filterLov.planLovSearchDisabled = true;
          }
          this.getData.productCodes();
        }
      });
    },
    subline: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          lineCd: this.forms.filterLovForm.get('lineCd')?.value,
          table: 'SUBLINE',
          moduleId: this.moduleId
        },
      }
      this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button?.toUpperCase() === 'OK') {
          this.forms.resetProductCodeForm();
          if (output) {
            this.forms.filterLovForm.patchValue({
              lineCd: output.LINE_CD,
              lineName: output.LINE_NAME,
              lineCdDisp: `${output.LINE_CD} - ${output.LINE_NAME}`,
              sublineCd: output.SUBLINE_CD,
              sublineName: output.SUBLINE_NAME,
              sublineCdDisp: `${output.SUBLINE_CD} - ${output.SUBLINE_NAME}`,
              planCd: null,
              planName: null,
              planDisp: null,
            });
            this.btns.filterLov.planLovSearchDisabled = false;
          }
          else {
            this.forms.filterLovForm.patchValue({
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null,
              planCd: null,
              planName: null,
              planDisp: null,
            });
            this.btns.productCode.addDisabled = this.btns.productCode.formLovSearchDisabled = true;
            this.btns.filterLov.planLovSearchDisabled = true;
          }
          this.getData.productCodes();
        }
      });
    },
    plan: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'PLAN',
          moduleId: this.moduleId,
          lineCd: this.forms.filterLovForm.get('lineCd')?.value,
          sublineCd: this.forms.filterLovForm.get('sublineCd')?.value,
          lpad: 2,
        },
      };
      this._matDialog.open(PlanLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button?.toUpperCase() === 'OK') {
          this.forms.resetProductCodeForm();
          if (output.PLAN_CD) {
            this.forms.filterLovForm.patchValue({
              planCd: output.PLAN_CD,
              planName: output.PLAN_NAME,
              planDisp: `${output.PLAN_CD} - ${output.PLAN_NAME}`,
            });
          }
          else {
            this.forms.filterLovForm.patchValue({
              planCd: null,
              planName: null,
              planDisp: null,
            });
            this.btns.productCode.addDisabled = this.btns.productCode.formLovSearchDisabled = true;
          }
          this.getData.productCodes();
        }
      });
    },
    clientType: (opt: any) => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'CLIENT_GROUP',
          moduleId: this.moduleId,
        },
      };
      this._matDialog.open(ClientGroupLovDialog, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.option?.toUpperCase() !== "BACK") {
          if (output.CLIENT_CD) {
            if (opt === 'FILTER') {
              this.forms.resetProductCodeForm();
              this.forms.filterLovForm.patchValue({
                clientType: output.CLIENT_CD,
                clientTypeName: output.CLIENT_DESC,
                clientTypeDisp: `${output.CLIENT_CD} - ${output.CLIENT_DESC}`,
              });
              this.forms.productCodeForm.patchValue({
                clientType: output.CLIENT_CD,
                clientTypeName: output.CLIENT_DESC,
                clientTypeDisp: `${output.CLIENT_CD} - ${output.CLIENT_DESC}`,
              });
              this.btns.productCode.clientTypeLovSearchDisabled = true;
              this.getData.productCodes();
            }
            else if (opt === 'FORM') {
              this.forms.productCodeForm.patchValue({
                clientType: output.CLIENT_CD,
                clientTypeName: output.CLIENT_DESC,
                clientTypeDisp: `${output.CLIENT_CD} - ${output.CLIENT_DESC}`,
              });
            }
          }
          else {
            if (opt === 'FILTER') {
              this.forms.filterLovForm.patchValue({
                clientType: null,
                clientTypeName: null,
                clientTypeDisp: null,
              });
              this.forms.productCodeForm.patchValue({
                clientType: null,
                clientTypeName: null,
                clientTypeDisp: null,
              });
              this.btns.productCode.clientTypeLovSearchDisabled = false;
              this.getData.productCodes();
            }
            else if (opt === 'FORM') {
              this.forms.productCodeForm.patchValue({
                clientType: null,
                clientTypeName: null,
                clientTypeDisp: null,
              });
            }
          }
        }
      });
    },
    project: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          moduleId: this.moduleId
        },
      };
      this._matDialog.open(ProjectLovComponent, LOVOPTIONS).afterClosed().subscribe((lovData: any) => {
        if (lovData?.button === 'OK') {
          if (lovData?.content?.PROJECT_CD) {
            let data = lovData?.content;
            this.forms.productCodeForm.patchValue({
              projectCd: data.PROJECT_CD,
              projectName: data.PROJECT_DESC,
              projectDisp: `${data.PROJECT_CD} - ${data.PROJECT_DESC}`,
            });
          }
          else {
            this.forms.productCodeForm.patchValue({
              projectCd: null,
              projectName: null,
              projectDisp: null,
            });
          }
        }
      });
    },
    agent: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          moduleId: this.moduleId
        },
      };
      this._matDialog.open(AgentLovComponent, LOVOPTIONS).afterClosed().subscribe((lovData: any) => {
        if (lovData?.button === 'OK') {
          if (lovData?.content?.AGENT_CD) {
            let data = lovData?.content;
            this.forms.productCodeForm.patchValue({
              agentCd: data.AGENT_CD,
              agentName: data.AGENT_NAME,
              agentDisp: `${data.AGENT_CD} - ${data.AGENT_NAME}`,
            });
          }
          else {
            this.forms.productCodeForm.patchValue({
              agentCd: null,
              agentName: null,
              agentDisp: null,
            });
          }
        }
      });
    },
    bma: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: "BM_CODE",
          moduleId: this.moduleId,
          lpad: 6,
        },
      };
      this._matDialog.open(BmaLovComponent, LOVOPTIONS).afterClosed().subscribe((lovData: any) => {
        if (!lovData.option) {
          let data = lovData.content;
          if (data) {
            this.forms.productCodeForm.patchValue({
              bmaCd: data.BMA_CD?.toString().padStart(6, 0),
              bmaBmCode: data.BM_CODE,
              bmaName: data.BMA_DESC,
              bmaDisp: `${data.BM_CODE} - ${data.BMA_DESC}`,
            });
          }
          else {
            this.forms.productCodeForm.patchValue({
              bmaCd: null,
              bmaBmCode: null,
              bmaName: null,
              bmaDisp: null,
            });
          }
        }
      });
    },
    referror: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          col: 'BM',
          moduleId: this.moduleId,
        }
      };
      this._matDialog.open(RefLovComponent, LOVOPTIONS).afterClosed().subscribe((lovData: any) => {
        if (!lovData.option) {
          if (lovData?.content?.REFERROR_CD) {
            const data = lovData?.content;
            this.forms.productCodeForm.patchValue({
              referrorCd: data.REFERROR_CD?.toString().padStart(6, 0),
              referrorBmCode: data.BM_CODE,
              referrorName: data.REFERROR_DESC,
              referrorDisp: `${data.BM_CODE} - ${data.REFERROR_DESC}`,
            });
          }
          else {
            this.forms.productCodeForm.patchValue({
              referrorCd: null,
              referrorBmCode: null,
              referrorName: null,
              referrorDisp: null,
            });
          }
        }
      });
    },
    src: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          col: 'BM',
          moduleId: this.moduleId
        }
      };
      this._matDialog.open(SrcCdLovComponent, LOVOPTIONS).afterClosed().subscribe((lovData: any) => {
        if (!lovData.option) {
          if (lovData?.content?.SRC_CD) {
            const data = lovData?.content;
            this.forms.productCodeForm.patchValue({
              srcCd: data.SRC_CD,
              srcBmCode: data.BM_CODE,
              srcCdName: data.SRC_CD_DESC,
              srcCdDisp: `${data.BM_CODE} - ${data.SRC_CD_DESC}`,
            });
          }
          else {
            this.forms.productCodeForm.patchValue({
              srcCd: null,
              srcBmCode: null,
              srcCdName: null,
              srcCdDisp: null,
            });
          }
        }
      });
    },
    srcExt: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          col: 'BM',
          moduleId: this.moduleId,
        }
      };
      this._matDialog.open(SrcExtLovComponent, LOVOPTIONS).afterClosed().subscribe((lovData: any) => {
        if (!lovData.option) {
          if (lovData?.content?.SRC_EXT_CD) {
            const data = lovData?.content;
            this.forms.productCodeForm.patchValue({
              srcExtCd: data.SRC_EXT_CD?.toString().padStart(6, 0),
              srcExtBmCode: data.BM_CODE,
              srcExtName: data.SRC_EXT_DESC,
              srcExtDisp: `${data.BM_CODE} - ${data.SRC_EXT_DESC}`,
            });
          }
          else {
            this.forms.productCodeForm.patchValue({
              srcExtCd: null,
              srcExtBmCode: null,
              srcExtName: null,
              srcExtDisp: null,
            });
          }
        }
      });
    }
  };

  private getData = {
    productCodes: () => {
      try {
        this.productCodetblConfig.tblData = [];
        if (this.forms.filterLovForm.get('lineCd')?.value
          && this.forms.filterLovForm.get('sublineCd')?.value
          && this.forms.filterLovForm.get('planCd')?.value) {
          this._jDS.contorlLoading(true);
          this.productCodetblConfig.loading = true;
          this._api.getProductCodes({
            lineCd: this.forms.filterLovForm.get('lineCd')?.value,
            sublineCd: this.forms.filterLovForm.get('sublineCd')?.value,
            planCd: this.forms.filterLovForm.get('planCd')?.value,
            clientType: this.forms.filterLovForm.get('clientType')?.value,
            moduleId: this.moduleId,
            userId: this._userDetailService.userId,
            type: "MODULE",
          }).subscribe({
            next: (response: any) => {
              this._securityService.checkRequestKeyResponse(response, () => {
                this._securityService.hasValidCsrfToken(response, () => {
                  if (response.status === "SUCCESS") {
                    let content = JSON.parse(this._jDS.decrypt(response?.response));
                    this.productCodetblConfig.tblData = content.data;
                    this._jDS.contorlLoading(false);
                    this.productCodetblConfig.loading = false;
                    this.btns.productCode.addDisabled = this.btns.productCode.formLovSearchDisabled = false;
                    if (this.forms.filterLovForm.get('clientType')?.value) {
                      this.btns.productCode.clientTypeLovSearchDisabled = true;
                    }
                    else {
                      this.btns.productCode.clientTypeLovSearchDisabled = false;
                    }
                  }
                  else {

                    this._jDS.contorlLoading(false);
                    this.productCodetblConfig.loading = false;
                    this.btns.productCode.addDisabled = this.btns.productCode.formLovSearchDisabled = true;
                  }
                });
              });
            },
            error: (e: any) => {
              this._securityService.checkRequestKeyResponse(e, () => {

                this._jDS.contorlLoading(false);
                this.productCodetblConfig.loading = false;
                this.btns.productCode.addDisabled = this.btns.productCode.formLovSearchDisabled = true;
              });
            }
          });
        }
        else {
          this.productCodetblConfig.loading = false;
          this.btns.productCode.addDisabled = this.btns.productCode.formLovSearchDisabled = true;
        }
      }
      catch (e) {
    console.error(e);

      }
    },
    userLevels: () => {
      const filteredUserLevels = this._jDS.data.refCds.filter((data: any) => data.rvDomain === 'USER_LEVEL').map((data: any) => { return { cd: data.rvLowValue, name: data.rvMeaning } });
      return filteredUserLevels;
    },
    userTypes: () => {
      const filteredUserTypes: any[] = this._jDS.data.userType.map((data: any) => { return { cd: data.userType, name: data.userTypeDesc } });
      return filteredUserTypes;
    },
    agentGrps: () => {
      const filteredAgentGrps: any[] = this._jDS.data.agentGrp.filter((data: any) => data.activeTag === 'A').map((data: any) => { return { cd: data.agentGrp, name: data.agentGrpDesc } });
      return filteredAgentGrps;
    },
  };

  public selections = {
    activeTypes: [
      { cd: 'A', name: 'Yes' },
      { cd: 'I', name: 'No' },
    ],
    userLevels: [
      { cd: null, name: '' },
      ...this.getData.userLevels()
    ],
    userTypes: [
      { cd: null, name: '' },
      ...this.getData.userTypes()
    ],
    agentGrps: [
      { cd: null, name: '' },
      ...this.getData.agentGrps()
    ],
  };

  public onRowClick(ev: any) {
    try {
      if (ev) {
        this.rowData = ev;
        this.forms.productCodeForm.get('productCode')?.disable();
        this.forms.productCodeForm.get('effectiveDate')?.disable();
        this.forms.productCodeForm.get('userType')?.disable();
        this.forms.productCodeForm.get('userLevel')?.disable();
        this.populateFormFromTable();
      }
      else {
        this.rowData = null;
        this.forms.resetProductCodeForm();
      }
    }
    catch (e) {
    console.error(e);
      this.forms.resetProductCodeForm();
      this.rowData = null;
    }
  }

  private populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.productCode.updateDisabled = false;
      this.btns.productCode.deleteDisabled = !(this.rowData?.operation === 'I');
      //this.btns.productCode.lineSearchDisabled = this.btns.productCode.sublineSearchDisabled = true;
      this.forms.productCodeForm.patchValue(this.productCodeFormManipulate.dbToForm(data));
    }
    catch (e) {
    console.error(e);

    }
  }

  public onAdd(): void {
    try {
      this._formService.hideFormMsg("product-code-error-message");
      if (this.forms.productCodeForm.valid) {
        this.forms.productCodeForm.patchValue({
          lineCd: this.forms.filterLovForm.get('lineCd')?.value,
          sublineCd: this.forms.filterLovForm.get('sublineCd')?.value,
          planCd: this.forms.filterLovForm.get('planCd')?.value,
        });
        let rowToBeAdded: { [key: string]: any } = this.productCodeFormManipulate.formToDb(this.forms.productCodeForm.getRawValue());
        rowToBeAdded.operation = 'I';
        this.productCodetblConfig.tblData = [rowToBeAdded, ...this.productCodetblConfig.tblData];
        this.forms.resetProductCodeForm();
        this.btns.productCode.saveDisabled = false;
      }
      else {
        this.showErrorValidator.productCodes();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onUpdate(): void {
    try {
      this._formService.hideFormMsg("product-code-error-message");
      if (this.forms.productCodeForm.valid) {
        const indexOfRow = this.productCodetblConfig.tblData.indexOf(this.rowData);
        let rowToBeUpdated: { [key: string]: any } = this.productCodeFormManipulate.formToDb(this.forms.productCodeForm.getRawValue());
        rowToBeUpdated.operation = (this.rowData.operation === 'I') ? 'I' : 'U';
        this.forms.resetProductCodeForm();
        this.productCodetblConfig.tblData[indexOfRow] = rowToBeUpdated;
        this.selectedRow = [];
        this.btns.productCode.saveDisabled = false;
        this.productCodetblConfig.tblData = [...this.productCodetblConfig.tblData];
      }
      else {
        this.showErrorValidator.productCodes();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onDelete(): void {
    try {
      this.productCodetblConfig.tblData = this.productCodetblConfig.tblData.filter((row: any) => {
        return row !== this.rowData;
      });
      this.forms.resetProductCodeForm();
      this.btns.productCode.saveDisabled = false;
    }
    catch (e) {
    console.error(e);

    }
  }

  public onSave(): void {
    try {
      this._jDS.contorlLoading(true);
      this.showForm = false;
      this._formService.showFormLoader(null, "product-code-maintenance-loading", "Saving.<br>Please wait ...", null, null, true);
      let toBeSaved = this.productCodetblConfig.tblData.filter((row: any) => ['I', 'U'].includes(row.operation));
      if (toBeSaved.length > 0) {
        this._api.saveProductCodes(toBeSaved).subscribe({
          next: (response: any) => {
            this._securityService.checkRequestKeyResponse(response, () => {
              this._securityService.hasValidCsrfToken(response, () => {
                if (response.status === 'SUCCESS') {
                  this.onComplete.productCodes();
                }
                else {
                  this.onComplete.productCodesError();
                }
              });
            });
          },
          error: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {

              this.onComplete.productCodesError();
            });
          }
        });
      }
      else {
        this.onComplete.productCodes();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  private onComplete = {
    productCodes: () => {
      this._jDS.contorlLoading(false);
      this._formService.hideFormLoader("product-code-maintenance-loading");
      this.openDialog('SUCCESS', this.moduleName.replace('Maintenance', ''), 'saved');
      this.btns.productCode.saveDisabled = true;
      this.showForm = true;
      this.forms.resetProductCodeForm();
    },
    productCodesError: () => {
      this._jDS.contorlLoading(false);
      this.openDialog('FAILED', this.moduleName.replace('Maintenance', ''), 'saving');
      this._formService.hideFormLoader("product-code-maintenance-loading");
      this.showForm = true;
      this.forms.resetProductCodeForm();
    },
  }

  openDialog(title: string, contentTitle: string, contentDetail: string) {
    try {
      this._matDialog.open(DialogMsgComponent, {
        disableClose: true,
        width: '512px',
        data: {
          title: title,
          content:
            title === 'SUCCESS'
              ? `${contentTitle} details successfully ${contentDetail}!`
              : `${contentTitle} details ${contentDetail} failed!`,
        },
      }).afterClosed().subscribe(() => {
        if (title === 'SUCCESS') {
          this.getData.productCodes();
        }
      });
    }
    catch (e) {
    console.error(e);

    }
  }

  public autoUppercase(ev: any) {
    ev.target.value = ev.target.value?.toUpperCase();
  }

  //* Validator Stuffs Down here */

  private checkDupes(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const productCodeValue = control.get('productCode')?.value?.trim()?.toUpperCase();
      const effDateValue = control.get('effectiveDate')?.value;
      const userTypeValue = control.get('userType')?.value;
      const userLevelValue = control.get('userLevel')?.value;
      if (!productCodeValue || !effDateValue || !userLevelValue || !userTypeValue) {
        return null;
      }
      let hasDuplicate: boolean = this.productCodetblConfig.tblData.filter((d: any) => {
        return this.checkBool(d.PRODUCT_CODE, productCodeValue, d.USER_TYPE, userTypeValue, d.USER_LEVEL,
          userLevelValue, d.EFFECTIVE_DATE, d.EXPIRY_DATE, effDateValue);
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupe: true } : null;
    }
  }

  private checkBool(dataProdCode: string, prodCodeVal: string, dataUserType: string,
    userTypeVal: string, dataUserLevel: string, userLevelVal: string, dataEffDate: string,
    dataExpDate: string | null, effDateVal: string): boolean {
    if (!this.rowData)
      return prodCodeVal === dataProdCode
        && userTypeVal === dataUserType
        && userLevelVal === dataUserLevel
        && (new Date(effDateVal) >= new Date(dataEffDate) && new Date(effDateVal) <= new Date(dataExpDate ?? effDateVal));
    return !(this.rowData.PRODUCT_CODE === dataProdCode
      && this.rowData.USER_TYPE == dataUserType
      && this.rowData.USER_LEVEL == dataUserLevel
      && (new Date(this.rowData.EFFECTIVE_DATE) >= new Date(dataEffDate) && new Date(this.rowData.EFFECTIVE_DATE) <= new Date(dataExpDate ?? this.rowData.EFFECTIVE_DATE)))
      && (prodCodeVal === dataProdCode
        && userTypeVal === dataUserType
        && userLevelVal === dataUserLevel
        && (new Date(effDateVal) >= new Date(dataEffDate) && new Date(effDateVal) <= new Date(dataExpDate ?? effDateVal)));
  }

  public showErrorValidator = {
    productCodes: () => {
      try {
        if (this.forms.productCodeForm.errors) {
          const controlError = this.forms.productCodeForm.errors;
          Object.keys(controlError).forEach(keyError => {
            if (keyError == 'hasDupe' && controlError[keyError] === true)
              this.errorMessage = "Unable to add record. Product Code within the entered effectivity period already exists.";
          });
        }
        else if (this.forms.productCodeForm.get('effectiveDate')?.errors || this.forms.productCodeForm.get('expiryDate')?.errors) {
          this.errorMessage = "Invalid Effective/Expiry Date. Please input valid dates.";
        }
        else {
          Object.keys(this.forms.productCodeForm.controls).forEach(key => {
            const controlErrors = this.forms.productCodeForm.get(key)?.errors;
            if (controlErrors != null) {
              Object.keys(controlErrors).forEach(keyError => {
                if (keyError == 'required' && controlErrors[keyError] === true)
                  this.errorMessage = "There are missing information. Please provide necessary information needed.";
                else if (keyError == 'hasDupe' && controlErrors[keyError] === true)
                  this.errorMessage = "Unable to add record. Product Code within the entered effectivity period already exists.";
              });
            }
          });
        }
        this._formService.showFormMsg("product-code-error-message", this.errorMessage, "E");
      }
      catch (e) {
    console.error(e);

      }
    },
  }

}
