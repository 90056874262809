<section id="client-group-per-agent-group">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <osp-table [tblConfig]="table" (onRowClick)="onRowClick($event)" [selectedRow]="selectedRow"></osp-table>

        <div class="form-content" [hidden]="formHider">

            <form [formGroup]="form" class="form">
                <div id="client-group-per-agent-group-error-message"></div>
                <div class="form-container-grid">
                    
                    <div class="field required">
                        <label>Agent Group</label>
                    </div>
                    <div class="span">
                        <input formControlName="AGENT_GRP" readonly>
                        <button class="btn-search btn" (click)="openAgentGrpDialog()">
                            <mat-icon>
                                search
                            </mat-icon>
                        </button>
                    </div>
                    
                    <div class="field required">
                        <label>Item Level 1</label>
                    </div>
                    <div class="">
                        <select formControlName="ITEM_LEVEL1">
                            <option *ngFor="let other of itemLevel1DropDown"
                                    [value]="other.rvLowValue">
                                {{ other.rvLowValue + " - " + other.rvMeaning }}
                            </option>
                        </select>
                    </div>
                    
                    <div class="field required">
                        <label>Item Level 2</label>
                    </div>
                    <div class="">
                        <select formControlName="ITEM_LEVEL2">
                            <option *ngFor="let other of itemLevel2DropDown"
                                    [value]="other.rvLowValue">
                                {{ other.rvLowValue + " - " + other.rvMeaning }}
                            </option>
                        </select>
                    </div>
                    
                    <div class="field required">
                        <label>Client Group</label>
                    </div>
                    <div class="span">
                        <input formControlName="CLIENT_CD" readonly>
                        <button class="btn-search btn" (click)="openClientGroupDialog()">
                            <mat-icon>
                                search
                            </mat-icon>
                        </button>
                    </div>
                    
                    <div class="field required">
                        <label>Effective Date</label>
                    </div>
                    <div class="">
                        <input formControlName="EFF_DATE_FROM" type="text" placeholder="MM/DD/YYYY" [matDatepicker]="effDatePicker" date-input>
                        <mat-datepicker-toggle matSuffix [for]="effDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #effDatePicker></mat-datepicker>
                    </div>
                    
                    <div class="field">
                        <label>Expiry Date</label>
                    </div>
                    <div class="">
                        <input formControlName="EFF_DATE_TO" type="text" placeholder="MM/DD/YYYY" [matDatepicker]="expDatePicker" date-input>
                        <mat-datepicker-toggle matSuffix [for]="expDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #expDatePicker></mat-datepicker>
                    </div>

                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="ACTIVE_TAG">
                            <option value="A">Yes</option>
                            <option value="I">No</option>
                        </select>
                    </div>
                    
                    <div class=""></div>
                    <div class=""></div>

                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="REMARKS" maxlength="4000">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input formControlName="LAST_USER">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="LAST_USER_UPDATE">
                    </div>
                </div>
            </form>

            <div class="btn-grp">
                <button type="button" class="btn btn2" [hidden]="!addUpdateFlag" (click)="addRow()">ADD</button>
                <button type="button" class="btn btn2" [hidden]="addUpdateFlag" (click)="onUpdate()">UPDATE</button>
                <button type="button" class="btn btn2" [disabled]="deleteDisabler" (click)="deleteRow()">DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-grp">
                <button type="button" class="btn btn2" [disabled]="saveDisabler" (click)="onSave()">SAVE</button>
            </div>
        </div>
    </div>
</section>