import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { CustomValidators } from 'src/app/utils/custom-validator';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';

@Component({
  selector: 'contract-type-maintenance',
  templateUrl: './contract-type-maintenance.component.html',
  styleUrls: ['./contract-type-maintenance.component.css']
})
export class ContractTypeMaintenanceComponent implements OnInit, LeaveConfirmation {

  selectedRow: any;
  datePipe = new DatePipe('en-ph');
  errorMessage: string = "";
  userId = this.userDetailService.userId;
  formHider: boolean = false;
  moduleId: string = 'BMM051';
  moduleName: string = this.jsonDataService.data.moduleList.filter((e: any) => e.moduleId === this.moduleId)[0]?.moduleDesc?.toUpperCase();

  addUpdateFlag: boolean = true;
  deleteDisabler: boolean = true;
  saveDisabler: boolean = true;

  tempAddData: any = [];
  tempUpdateData: any = [];

  contractTypeTable: any = {
    cols: [
      {
        key: "CONTRACT_TYPE_CD",
        header: "CONTRACT TYPE CODE",
        dataType: "string"
      },
      {
        key: "CONTRACT_TYPE_DESC",
        header: "Contract Type Description",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "Active",
        dataType: "checkbox"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  contractTypeForm = this.fb.group({
    CONTRACT_TYPE_CD: [{value: '', disabled: true}],
    CONTRACT_TYPE_DESC: ['', [Validators.required, this.duplicate(), CustomValidators.requiredTrim]],
    ACTIVE_TAG: ['A', Validators.required],
    BM_CODE: ['', [Validators.required, CustomValidators.requiredTrim]],
    BM_SHORT_DESC: ['', [Validators.required, CustomValidators.requiredTrim]],
    COC_LETTER: [''],
    RENEW_TAG: ['', Validators.required],
    REMARKS: [''],
    USER_ID: [{value: '', disabled: true}],
    LAST_UPDATE: [{value: '', disabled: true}],
  });

  constructor(
    private fb: FormBuilder,
    private api: APICallService,
    private jsonDataService: JsonDataService,
    private formService: FormService,
    private dialog: MatDialog,
    private userDetailService: UserDetailsService,
    private securityService: SecurityService,
    private appMessageService: AppMessageService,
  ) { }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempAddData!='' || this.tempUpdateData!='') {
      return false;
    } else {
      return true;
    }
  }

  ngOnInit(): void {
    try {
      this.getContractType();
    } catch(e) {
      
    }
  }

  getContractType() {
    try {
      this.contractTypeTable.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.api.getContractType({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE"
      }).subscribe((data: any) => {
        this.securityService.checkRequestKeyResponse(data, () => {
          this.securityService.hasValidCsrfToken(data, () => {
            if(data.status === 'SUCCESS') {
              data = JSON.parse(this.jsonDataService.decrypt(data.response));
              this.jsonDataService.contorlLoading(false);
              this.contractTypeTable.tblData = data.data;
              this.contractTypeTable.loading = false;
            } else {
              this.jsonDataService.contorlLoading(false);
              this.contractTypeTable.loading = false;
              this.appMessageService.showAppMessage(data.message, "error");
            }
          })
        });
      });
    } catch(e) {
      
    }
  }

  onRowClick(ev: any) {
    try {
      if(ev!=null) {
        this.selectedRow = ev;
        this.addUpdateFlag = false;
        this.formLoader();
      } else {
        this.selectedRow = '';
        this.addUpdateFlag = true;
        this.formClearer();
      }
      this.deleteEnabler();
    } catch(e) {
      
    }
  }

  formLoader() {
    try {
      this.contractTypeForm.patchValue({
        CONTRACT_TYPE_CD    : this.selectedRow.CONTRACT_TYPE_CD,
        CONTRACT_TYPE_DESC  : this.selectedRow.CONTRACT_TYPE_DESC,
        ACTIVE_TAG          : this.selectedRow.ACTIVE_TAG,
        BM_CODE             : this.selectedRow.BM_CODE,
        BM_SHORT_DESC       : this.selectedRow.BM_SHORT_DESC,
        COC_LETTER          : this.selectedRow.COC_LETTER,
        RENEW_TAG           : this.selectedRow.RENEW_TAG,
        REMARKS             : this.selectedRow.REMARKS,
        USER_ID             : this.selectedRow.USER_ID,
        LAST_UPDATE         : this.selectedRow.LAST_UPDATE,
      });
    } catch(e) {
      
    }
  }

  formClearer() {
    try {
      this.contractTypeForm.patchValue({
        CONTRACT_TYPE_CD    : '',
        CONTRACT_TYPE_DESC  : '',
        ACTIVE_TAG          : 'A',
        BM_CODE             : '',
        BM_SHORT_DESC       : '',
        COC_LETTER          : '',
        RENEW_TAG           : '',
        REMARKS             : '',
        USER_ID             : '',
        LAST_UPDATE         : '',
      });
    } catch(e) {
      
    }
  }

  errorMessageSetter() {
    try {
      if(this.contractTypeForm.invalid) {
        for(const field in this.contractTypeForm.controls) {
          const controlName = this.contractTypeForm.get(field);
          if(controlName?.errors?.required) {
            this.errorMessage = 'There are missing information. Please provide necessary information needed.';
          }
          if(controlName?.errors?.duplicate) {
            this.errorMessage = 'Contract Type Description already exists. Please add a unique Contract Type Description.';
          }
          this.formService.showFormMsg("contract-type-error-message", this.errorMessage, "E");
        }
      }
    } catch(e) {
      
    }
  }

  tableInserter() {
    try {
      this.contractTypeTable.tblData.unshift({
        CONTRACT_TYPE_DESC  : this.contractTypeForm.get('CONTRACT_TYPE_DESC')?.value,
        ACTIVE_TAG          : this.contractTypeForm.get('ACTIVE_TAG')?.value,
        BM_CODE             : this.contractTypeForm.get('BM_CODE')?.value,
        BM_SHORT_DESC       : this.contractTypeForm.get('BM_SHORT_DESC')?.value,
        COC_LETTER          : this.contractTypeForm.get('COC_LETTER')?.value,
        RENEW_TAG           : this.contractTypeForm.get('RENEW_TAG')?.value,
        REMARKS             : this.contractTypeForm.get('REMARKS')?.value,
      });
      this.contractTypeTable.tblData = [...this.contractTypeTable.tblData];
    } catch(e) {
      
    }
  }

  tempAddInserter() {
    try {
      this.tempAddData.push({
        CONTRACT_TYPE_DESC  : this.contractTypeForm.get('CONTRACT_TYPE_DESC')?.value,
        ACTIVE_TAG          : this.contractTypeForm.get('ACTIVE_TAG')?.value,
        BM_CODE             : this.contractTypeForm.get('BM_CODE')?.value,
        BM_SHORT_DESC       : this.contractTypeForm.get('BM_SHORT_DESC')?.value,
        COC_LETTER          : this.contractTypeForm.get('COC_LETTER')?.value,
        RENEW_TAG           : this.contractTypeForm.get('RENEW_TAG')?.value,
        REMARKS             : this.contractTypeForm.get('REMARKS')?.value,
        USER_ID             : this.userId,
      });
    } catch(e) {
      
    }
  }

  tempUpdateInserter() {
    try {
      this.tempUpdateData.push({
        CONTRACT_TYPE_CD    : this.contractTypeForm.get('CONTRACT_TYPE_CD')?.value,
        CONTRACT_TYPE_DESC  : this.contractTypeForm.get('CONTRACT_TYPE_DESC')?.value,
        ACTIVE_TAG          : this.contractTypeForm.get('ACTIVE_TAG')?.value,
        BM_CODE             : this.contractTypeForm.get('BM_CODE')?.value,
        BM_SHORT_DESC       : this.contractTypeForm.get('BM_SHORT_DESC')?.value,
        COC_LETTER          : this.contractTypeForm.get('COC_LETTER')?.value,
        RENEW_TAG           : this.contractTypeForm.get('RENEW_TAG')?.value,
        REMARKS             : this.contractTypeForm.get('REMARKS')?.value,
        USER_ID             : this.userId,
      });
    } catch(e) {
      
    }
  }

  addRow() {
    try {
      if(this.contractTypeForm.valid) {
        this.formService.hideFormMsg("contract-type-error-message");
        this.tableInserter();
        this.tempAddInserter();
        this.formClearer();
        this.saveDisabler = false;
      } else {
        this.errorMessageSetter();
      }
    } catch(e) {
      
    }
  }

  updateTable() {
    try {
      let updateIndex = this.contractTypeTable.tblData.indexOf(this.selectedRow);
      this.contractTypeTable.tblData[updateIndex] = {
        CONTRACT_TYPE_CD:    this.contractTypeForm.get('CONTRACT_TYPE_CD')?.value,
        CONTRACT_TYPE_DESC:  this.contractTypeForm.get('CONTRACT_TYPE_DESC')?.value,
        ACTIVE_TAG:          this.contractTypeForm.get('ACTIVE_TAG')?.value,
        BM_CODE:             this.contractTypeForm.get('BM_CODE')?.value,
        BM_SHORT_DESC:       this.contractTypeForm.get('BM_SHORT_DESC')?.value,
        COC_LETTER:          this.contractTypeForm.get('COC_LETTER')?.value,
        RENEW_TAG:           this.contractTypeForm.get('RENEW_TAG')?.value,
        REMARKS:             this.contractTypeForm.get('REMARKS')?.value,
        USER_ID:             this.selectedRow.USER_ID,
        LAST_UPDATE:         this.selectedRow.LAST_UPDATE,
      }
      this.contractTypeTable.tblData = [...this.contractTypeTable.tblData];
    } catch(e) {
      
    }
  }

  updateNewData() {
    try {
      let tempNewConfigdata = this.tempAddData.find(
        (contractType: { CONTRACT_TYPE_DESC: any }) => contractType.CONTRACT_TYPE_DESC == this.selectedRow.CONTRACT_TYPE_DESC
      );
      tempNewConfigdata.CONTRACT_TYPE_DESC = this.contractTypeForm.get('CONTRACT_TYPE_DESC')?.value;
      tempNewConfigdata.ACTIVE_TAG         = this.contractTypeForm.get('ACTIVE_TAG')?.value;
      tempNewConfigdata.BM_CODE            = this.contractTypeForm.get('BM_CODE')?.value;
      tempNewConfigdata.BM_SHORT_DESC      = this.contractTypeForm.get('BM_SHORT_DESC')?.value;
      tempNewConfigdata.COC_LETTER         = this.contractTypeForm.get('COC_LETTER')?.value;
      tempNewConfigdata.RENEW_TAG          = this.contractTypeForm.get('RENEW_TAG')?.value;
      tempNewConfigdata.REMARKS            = this.contractTypeForm.get('REMARKS')?.value;
    } catch(e) {
      
    }
  }

  updateDbData() {
    try {
      let tempDbData = this.tempUpdateData.find(
        (contractType: { CONTRACT_TYPE_DESC: any }) => contractType.CONTRACT_TYPE_DESC == this.contractTypeForm.get('CONTRACT_TYPE_DESC')?.value
      );
      if(this.tempUpdateData == '' || this.tempUpdateData == null) {
        this.tempUpdateInserter();
      } else if(tempDbData=='' || tempDbData==null) {
        this.tempUpdateInserter();
      } else {
        tempDbData.CONTRACT_TYPE_CD    = this.contractTypeForm.get('CONTRACT_TYPE_CD')?.value;
        tempDbData.CONTRACT_TYPE_DESC  = this.contractTypeForm.get('CONTRACT_TYPE_DESC')?.value;
        tempDbData.ACTIVE_TAG          = this.contractTypeForm.get('ACTIVE_TAG')?.value;
        tempDbData.BM_CODE             = this.contractTypeForm.get('BM_CODE')?.value;
        tempDbData.BM_SHORT_DESC       = this.contractTypeForm.get('BM_SHORT_DESC')?.value;
        tempDbData.COC_LETTER          = this.contractTypeForm.get('COC_LETTER')?.value;
        tempDbData.RENEW_TAG           = this.contractTypeForm.get('RENEW_TAG')?.value;
        tempDbData.REMARKS             = this.contractTypeForm.get('REMARKS')?.value;
        tempDbData.USER_ID             = this.selectedRow.USER_ID;
      }
    } catch(e) {
      
    }
  }

  onUpdate() {
    try {
      if(this.contractTypeForm.valid) {
        this.updateTable();
        if(this.deleteDisabler) {
          this.updateDbData();
        } else {
          this.updateNewData();
        }
        this.selectedRow = '';
        this.addUpdateFlag = true;
        this.formClearer();
        this.saveDisabler = false;
        this.deleteDisabler = true;
        this.formService.hideFormMsg('contract-type-error-message');
      } else {
        this.errorMessageSetter();
      }
    } catch(e) {
      
    }
  }

  deleteEnabler() {
    try {
      let tempDeleteFlag = this.tempAddData.filter((e: any) => {
        return e.CONTRACT_TYPE_DESC == this.selectedRow.CONTRACT_TYPE_DESC
      });
      if(tempDeleteFlag=='' || tempDeleteFlag==null) {
        this.deleteDisabler = true;
      } else {
        this.deleteDisabler = false;
      }
    } catch(e) {
      
    }
  }

  deleteRow() {
    try {
      let clickedData = this.selectedRow;
      this.contractTypeTable.tblData = this.contractTypeTable.tblData.filter(function(e: any) {
        return e.CONTRACT_TYPE_DESC != clickedData.CONTRACT_TYPE_DESC;
      });
      this.tempAddData = this.tempAddData.filter(function(e: any) {
        return e.CONTRACT_TYPE_DESC != clickedData.CONTRACT_TYPE_DESC;
      });
      this.selectedRow = '';
      this.formClearer();
      this.deleteDisabler = true;
      this.addUpdateFlag = true;
      if((this.tempAddData=='' || this.tempAddData==null) && (this.tempUpdateData=='' || this.tempUpdateData==null)) {
        this.saveDisabler = true;
      }
    } catch(e) {
      
    }
  }

  onSave() {
    try {
      this.formHider = true;
      this.formService.showFormLoader(null, "contract-type-form", "Saving.<br>Please wait ...", null, null);
      this.jsonDataService.contorlLoading(true);
      this.api.saveContractType(this.tempAddData).subscribe({
          next: (data: any) => {
              this.securityService.checkRequestKeyResponse(data, () => {
                this.securityService.hasValidCsrfToken(data, () => {
                  if(data.status == 'SUCCESS') {
                    this.api.saveContractType(this.tempUpdateData).subscribe({
                        next: (res: any) => {
                            this.securityService.checkRequestKeyResponse(res, () => {
                              this.securityService.hasValidCsrfToken(res, () => {
                                if(res.status == 'SUCCESS') {
                                  this.onComplete();
                                } else {
                                    this.onFail();
                                }
                              });
                            });
                        },
                        error: () => {
                            this.onFail();
                        }
                    });
                  } else {
                      this.onFail();
                  }
                });
              });
          },
          error: () => {
              this.onFail();
          }
      });
    } catch(e) {
      
    }
  }

  onComplete() {
    try {
      this.formService.hideFormLoader("contract-type-form");
      this.jsonDataService.contorlLoading(false);
      this.openSaveDialog();
      this.formHider = false;
      this.tempAddData = [];
      this.tempUpdateData = [];
      this.saveDisabler = true;
      this.deleteEnabler();
      this.formClearer();
      this.getContractType();
    } catch(e) {
      
    }
  }

  onFail() {
    try {
      this.tempAddData = [];
      this.tempUpdateData = [];
      this.deleteEnabler();
      this.getContractType();
      this.formService.hideFormLoader("contract-type-form");
      this.jsonDataService.contorlLoading(false);
      this.formHider = false;
      this.openErrorDialog('Saving contract type details failed.');
    } catch(e) {
      
    }
  }

  openErrorDialog(message: string) {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'ERROR',
          content: message,
        }
      });
    } catch(e) {
      
    }
  }

  openSaveDialog() {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'SUCCESS',
          content: 'Contract Type details Successfully saved!',
        }
      });
    } catch(e) {
      
    }
  }

  duplicate(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const selected = this.selectedRow;
      let duplicate = this.contractTypeTable.tblData.filter(function(e: any) {
        return e.CONTRACT_TYPE_DESC.toLowerCase().trim()==control.value.toLowerCase().trim() && control.value.toLowerCase().trim() !== selected?.CONTRACT_TYPE_DESC?.toLowerCase()?.trim();
      });
      if(duplicate!='') {
        return { "duplicate": { value: control.value } }
      }
      return null;
    }
  }
}
