<section id="referror">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <osp-table [tblConfig]="referrorTable" (onRowClick)="onRowClick($event)" [selectedRow]='selectedRow'></osp-table>
        <!-- <div id="referror-form"><div class="form-loader"></div></div> -->
        <div class="form-content" [hidden]="formHider">
            <form [formGroup]="referrorForm" class="form">
                <div id="referror-error-message"></div>
                <div class="form-container-grid">
                    <div class="field">
                        <label>Referror Code</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="REFERROR_CD">
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="ACTIVE_TAG">
                            <option *ngFor="let option of activeTags" [value]="option.value" [disabled]="option.value == 'L' || option.value == 'T'">
                                {{option.text}}
                            </option>
                        </select>
                    </div>
                    <div class="field required">
                        <label>Referror</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="REFERROR_DESC" oninput="this.value = this.value.toUpperCase()" maxlength="30">
                    </div>
                    <div class="field">
                        <label>BMA</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="BMA_CD">
                        <button class="btn-search btn" (click)="openBMALOV()">
                            <mat-icon>
                                search
                            </mat-icon>
                        </button>
                    </div>
                    <div class="field">
                        <label>Source Code</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="SRC_CD">
                        <button class="btn-search btn" (click)="openSourceCodeLOV()">
                            <mat-icon>
                                search
                            </mat-icon>
                        </button>
                    </div>
                    <div class="field">
                        <label>Source Extension</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="SRC_EXT_CD">
                        <button class="btn-search btn" (click)="openSourceExtLOV()">
                            <mat-icon>
                                search
                            </mat-icon>
                        </button>
                    </div>
                    <div class="field required">
                        <label>BPIMS Code</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="BM_CODE" oninput="this.value = this.value.toUpperCase()" maxlength="8">
                    </div>
                    <div class="field">
                        <label>BPIMS Short Description</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="BM_SHORT_DESC" maxlength="10">
                    </div>
                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="REMARKS" maxlength="4000">
                    </div>
                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input formControlName="USER_ID">
                    </div>
                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="LAST_UPDATE">
                    </div>
                </div>
            </form>
            <div class="btn-grp">
                <button type="button" class="btn btn2" [hidden]="!addUpdateFlag" (click)="addRow()">ADD</button>
                <button type="button" class="btn btn2" [hidden]="addUpdateFlag" (click)="onUpdate()">UPDATE</button>
                <button type="button" class="btn btn2" [disabled]="deleteDisabler" (click)="deleteRow()">DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-grp">
                <button type="button" class="btn btn2" [disabled]="saveDisabler" (click)="onSave()">SAVE</button>
            </div>
        </div>
    </div>
</section>