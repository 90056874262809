        <section id="agents">
            <div class="mtn-container">
                <div class="mtn-title">
                    <p>{{this.moduleName}}</p>
                </div>
                <osp-table [tblConfig]="agentTableConfig" (onRowClick)="onRowClick($event)"></osp-table>

                <div id="agent-form"><div class="form-loader"></div></div>

                <div class="form-content" [hidden]="formHider">

                    <form [formGroup]="agentsForm" class="form">
                        <div id="agent-error-message"></div>
                        <div class="form-container-grid">
    
                            <div class="field required">
                                <label>Agent User ID</label>
                            </div>
                            <div class="">
                                <input formControlName="agentCode" type="text" maxlength="10" (input)="autoUppercase($event)" alphanumeric>
                            </div>

                            <div class="field required">
                                <label>Active</label>
                            </div>
                            <div class="">
                                <select formControlName="activeTag">
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>

                            <div class="field required">
                                <label>Name</label>
                            </div>
                            <div class="span">
                                <input formControlName="agentName" type="text" maxlength="50" (blur)="checkNameLength('agentName')">
                            </div>

                            <div class="field required">
                                <label>Agent Code</label>
                            </div>
                            <div class="">
                                <input formControlName="agentNumCode" type="text" maxlength="8" alphanumeric>
                            </div>
                            <div class="field required">
                                <label>Agent Type</label>
                            </div>
                            <div class="">
                                <select formControlName="agentTypeCd">
                                    <option *ngFor="let agentType of agentTypeList" [value]="agentType.rvLowValue">{{ agentType.rvMeaning }}</option>
                                </select>
                            </div>

                            <div class="field required">
                                <label>Email Address</label>
                            </div>
                            <div class="">
                                <input formControlName="emailAddress" type="email" maxlength="208">
                            </div>

                            <div class="field required">
                                <label>Mobile Number</label>
                            </div>
                            <div class="">
                                <input formControlName="mobileNumber" type="text" placeholder="09XXXXXXXXX" maxlength="11" (input)="numOnlyPattern($event)">
                            </div>

                            <div class="field required">
                                <label>Issue Source</label>
                            </div>
                            <div class="">
                                <input type="text" formControlName="issueSourceCd">
                                <button class="btn-search btn" (click)="openLovDialog('iss')">
                                    <mat-icon>
                                        search
                                    </mat-icon>
                                </button>
                            </div>
                            <div class="field">
                                <label>Default BMA</label>
                            </div>
                            <div class="">
                                <input type="text" formControlName="agentBmaCd">
                                <button class="btn-search btn" (click)="openLovDialog('bma')">
                                    <mat-icon>
                                        search
                                    </mat-icon>
                                </button>
                            </div>

                            <div class="field">
                                <label>Default Source Code</label>
                            </div>
                            <div class="">
                                <input type="text" formControlName="agentSourceCode">
                                <button class="btn-search btn" (click)="openLovDialog('sourceCode')">
                                    <mat-icon>
                                        search
                                    </mat-icon>
                                </button>
                            </div>
                            <div class="field">
                                <label>Default Source Extension</label>
                            </div>
                            <div class="">
                                <input type="text" formControlName="agentSourceExtensionCd">
                                <button class="btn-search btn" (click)="openLovDialog('sourceExtension')">
                                    <mat-icon>
                                        search
                                    </mat-icon>
                                </button>
                            </div>

                            <div class="field">
                                <label>Agent Group</label>
                            </div>
                            <div class="">
                                <select formControlName="agentGroup">
                                    <option value=""></option>
                                    <option *ngFor="let agntGrp of agentGroup" [value]="agntGrp.agentGrp">{{agntGrp.agentGrpDesc}}</option>
                                </select>
                            </div>
                            <div class="field">
                                <label>Agency Name</label>
                            </div>
                            <div class="">
                                <select [ngClass]="{'p-col agent-select' : true}" formControlName="agencyCode">
                                    <!-- <option value=""></option> -->
                                    <option value=''></option>
                                    <option *ngFor="let agency of agencyList" [value]="agency.agencyCd">{{ agency.agencyName }}</option>
                                </select>
                            </div>

                            <div class="field required">
                                <label>Edit Premium</label>
                            </div>
                            <div class="">
                                <select formControlName="editPremium">
                                    <option value="Y">Yes</option>
                                    <option value="N">No</option>
                                </select>
                            </div>
                            <div class="field">
                                <label>
                                    Ren. Notice Non-sending
                                </label>
                                <mat-icon 
                                    #tooltip="matTooltip" 
                                    matTooltip="Yes - tagged a non-sending. Renewal Notice will only be visible to Agent's Dashboard.&#13;No - tagged as sending. Renewal Notice will be sent directly to client and will be visible to Agent's Dashboard." 
                                    matTooltipPosition="right" 
                                    matTooltipClass="tooltip">help_outline</mat-icon>
                            </div>
                            <div class="">
                                <select formControlName="renNoticeTag">
                                    <option value="Y">Yes</option>
                                    <option value="N">No</option>
                                </select>
                            </div>

                            <div class="field required">
                                <label>Effective Date</label>
                            </div>
                            <div class="">
                                <input formControlName="effDate" type="text" placeholder="MM/DD/YYYY" date-input [matDatepicker]="effDatePicker" (input)="datePattern($event)">
                                <mat-datepicker-toggle matSuffix [for]="effDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #effDatePicker></mat-datepicker>
                            </div>

                            <div class="field">
                                <label>Expiry Date</label>
                            </div>
                            <div class="">
                                <input formControlName="expDate" type="text" placeholder="MM/DD/YYYY" date-input [matDatepicker]="expDatePicker" (input)="datePattern($event)">
                                <mat-datepicker-toggle matSuffix [for]="expDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #expDatePicker></mat-datepicker>
                            </div>
                            
                            <div class="field">
                                <label>RPA Staff</label>
                            </div>
                            <div class="">
                                <select formControlName="rpaStaff" (change)="rpaOnChange()">
                                    <option value=''></option>
                                    <option *ngFor="let rpa of rpaStaffList" [value]="rpa.rpaStaffCd">{{ rpa.rpaStaff + " - " + rpa.rpaDept }}</option>
                                </select>
                            </div>
    
                            <div class="field">
                                <label>RPA Dept.</label>
                            </div>
                            <div class="">
                                <input formControlName="rpaDept" readonly>
                            </div>

                            <div class="field">
                                <label>Remarks</label>
                            </div>
                            <div class="span">
                                <input formControlName="remarks" type="text" maxlength="4000">
                            </div>

                            <div class="field">
                                <label>Last Update User</label>
                            </div>
                            <div class="">
                                <input type="text" formControlName="lastUpdateUser">
                            </div>

                            <div class="field">
                                <label>Last Update</label>
                            </div>
                            <div class="">
                                <input type="text" formControlName="lastDate">
                            </div>

                        </div>
                    </form>

                    <div class="btn-grp">
                        <button type="button" class="btn btn2" [hidden]="selectedAgent!=null" (click)="addRecord()">ADD</button>
                        <button type="button" class="btn btn2" [hidden]="selectedAgent==null" (click)="updateRow()">UPDATE</button>
                        <button type="button" class="btn btn2" [disabled]="deleteDisabler" (click)="deleteRow()">DELETE</button>
                    </div>
                    <div class="mtn-title"></div>
                    <div class="btn-grp">
                        <button type="button" class="btn btn2" [disabled]="selectedAgent==null" (click)="resetUserPw()">PASSWORD RESET</button>
                        <button type="button" class="btn btn2" [disabled]="saveDisabler" (click)="toSave()">SAVE</button>
                    </div>

                </div>
            </div>
        </section>