import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { JsonDataService } from 'src/app/services/json-data.service';
import { FormService } from 'src/app/services/form.service';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { SecurityService } from 'src/app/services/security.service';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs/internal/Observable';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { StateLovComponent } from '../../common/state-lov/state-lov.component';
import { OccupancyLovComponent } from '../../common/occupancy-lov/occupancy-lov.component';
import { ConstructionLovComponent } from '../../common/construction-lov/construction-lov.component';
import { RegionLovComponent } from '../../common/region-lov/region-lov.component';
import { DistrictLovComponent } from '../../common/district-lov/district-lov.component';

@Component({
  selector: 'fire-accumulation-register',
  templateUrl: './fire-accumulation-register.component.html',
  styleUrls: ['./fire-accumulation-register.component.css']
})
export class FireAccumulationRegisterComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private api: APICallService,
    private dialog: MatDialog,
    private jsonDataService: JsonDataService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    private formService: FormService,
    private securityService: SecurityService
  ) { }

  moduleId: string = 'BMM147';
  moduleName = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId == this.moduleId })[0].moduleDesc.toUpperCase();
  formHider: boolean = false;

  fireAccumulationRegTable: any = {
    cols: [
      {
        key: "blockCd",
        header: "Block Code",
        dataType: "string",
        width: '100px'
      },
      {
        key: "bmFregCd",
        header: "Register",
        dataType: "string",
        width: '100px'
      },
      {
        key: "stateBmCd",
        header: "State Code",
        dataType: "string",
        width: '100px'
      },
      {
        key: "stateLongDesc",
        header: "State",
        dataType: "string",
        width: '250px'
      },
      {
        key: "districtBmCd",
        header: "District Code",
        dataType: "string",
        width: '120px'
      },
      {
        key: "distDesc",
        header: "District",
        dataType: "string",
        width: '250px'
      },
      {
        key: "streetEstate",
        header: "Street/Estate",
        dataType: "string",
        width: '250px'
      },
      {
        key: "latitude",
        header: "Latitude",
        dataType: "string",
        width: '100px'
      },
      {
        key: "longitude",
        header: "Longitude",
        dataType: "string",
        width: '100px'
      },
      {
        key: "activeTag",
        header: "A",
        dataType: "checkbox",
        width: '64px'
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    scrollable: true,
    scrollableWidth: "100%",
    loading: false,
  }
  selectedRow: any;

  savedFlag: boolean = false;
  addUpdateFlag: boolean = true;
  updateData: any[] = [];

  selectedState: any = {}

  fireAccumulationRegisterForm = this.fb.group({
    blockCd: [{ value: '', disabled: true }],
    bmFregCd: ['', [Validators.required, this.duplicateRegister()]],
    bmStateCd:[],
    activeTag: ['A', [Validators.required]],
    stateCd: ['', [Validators.required]],
    stateDesc: [''],
    districtCd: ['', [Validators.required]],
    distLongDesc: [''],
    latitude: ['', [Validators.required]],
    longitude: ['', [Validators.required]],
    streetNo: [''],
    streetEstate: [''],
    situation: [''],
    occupancyCd: ['',[Validators.required]],
    occupancyDesc: [''],
    constructionCd: [''],
    constructionDesc: [''],
    fireProCd: ['', [Validators.required]],
    remarks: [''],
    userId: [{ value: '', disabled: true }],
    lastUpdate: [{ value: '', disabled: true }],
  });

  paramForm = this.fb.group({
    stateCd: [],
    stateDesc: [],
    bmCode:[],
    regionCd: [{value:'',disabled:true}],
    regionDesc: [],
    filterBy:['R'],
    register:[],
    bmDistrictCd: []
  });

  isSearchDisabled:Boolean = false;
  isRegionDisabled: Boolean = true;
  isStateDisabled: Boolean = true;
  isStateFormDisabled:Boolean = true;
  isDistrictDisabled: Boolean = true;
  isOccupancyDisabled: Boolean = true;
  isConstructionDisabled: Boolean = true;

  fireProCds: any[] = this.jsonDataService.data.fireProCd || [];

  showRegisterParam: boolean = true;

  ngOnInit(): void {
    this.fireAccumulationRegisterForm.disable();

    this.paramForm.get('filterBy')?.valueChanges.subscribe(e=>{
      if(e=='R'){
        this.paramForm.get('register')?.enable();
        this.isRegionDisabled  = true;
        this.isStateDisabled = true;
        this.isSearchDisabled = false;
        this.showRegisterParam = true;
        this.paramForm.patchValue({
          regionCd: '',
          stateCd: '',
          regionDesc:'',
          stateDesc:'',
          bmDistrictCd: ''
        });
      }else if(e=='D'){
        this.paramForm.get('bmDistrictCd')?.enable();
        this.isRegionDisabled  = true;
        this.isStateDisabled = true;
        this.isSearchDisabled = false;
        this.showRegisterParam = false;
        this.paramForm.patchValue({
          regionCd: '',
          stateCd: '',
          regionDesc:'',
          stateDesc:'',
          register: ''
        });
      }else{
        this.paramForm.get('register')?.disable();
        this.paramForm.get('bmDistrictCd')?.enable();
        this.isRegionDisabled  = false;
        this.isSearchDisabled = true;
        this.paramForm.patchValue({
          register: '',
          bmDistrictCd: ''
        });
      }
      this.resetForm();
      this.fireAccumulationRegTable.tblData = [];
      this.disableForm(true);
      this.selectedRow = '';
    })
  }

  getFireAccumulationReg(): void {
    try {
      this.fireAccumulationRegTable.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.api.getFireAccumulationRegister({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE",
        stateCd: this.paramForm.get('stateCd')?.value,
        register: this.paramForm.get('register')?.value,
        regionCd: this.paramForm.get('regionCd')?.value,
        bmDistrictCd : this.paramForm.get('bmDistrictCd')?.value
      }).subscribe((data: any) => {
        this.securityService.checkRequestKeyResponse(data, () => {
          this.securityService.hasValidCsrfToken(data, () => {
            if (data.status === "SUCCESS") {
              data = JSON.parse(this.jsonDataService.decrypt(data.response));
              this.fireAccumulationRegTable.loading = false;
              this.jsonDataService.contorlLoading(false);
              data.data = data.data.map((d:any)=>{
                return{...d,
                  stateDesc: d.stateBmCd + ' - ' + d.stateDesc,
                  distLongDesc: d.districtCd ? (d.districtBmCd + ' - ' + d.distLongDesc) : null}
              })
              this.fireAccumulationRegTable.tblData = data.data;
              if (this.savedFlag) {
                this.openSaveDialog();
                this.savedFlag = false;
              }
              this.disableForm(false);
              this.selectedRow = '';
            } else {
              this.jsonDataService.contorlLoading(false);
              this.fireAccumulationRegTable.loading = false;
              this.appMessageService.showAppMessage(data.message, "error");
            }
          });
        });
      });
    } catch (e) {
      console.error(e);
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    return this.fireAccumulationRegisterForm.pristine;
  }

  duplicateRegister(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      // let tariffCodeDuplicate = this.tariffRiskCodeTable.tblData.filter(function(e: any) {
      //   return e.TARIFF_CD.trim()==control.value.trim();
      // });
      // if(tariffCodeDuplicate!='') {
      //   return { "tariffDuplicateCode": { value: control.value } }
      // }
      return null;
    }
  }

  openSaveDialog() {
    try {
      let contentTitle =  this.moduleName.replace('Maintenance', '');
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: '512px',
        data: {
          title: 'SUCCESS',
          content:
              `Successfully saved!`
        },
      });
    }
    catch (e) {
      console.error(e);
    }
  }

  onRowClick(ev: any): void {
    try {
      this.selectedRow = ev;
      this.formLoader();
      if (ev) {
        this.addUpdateFlag = false;
      } else {
        this.addUpdateFlag = true;
        this.resetForm();
      }
      this.fireAccumulationRegisterForm.markAsPristine();
    } catch (e) {
      console.error(e);
    }
  }

  formLoader(): void {
    try {
      this.fireAccumulationRegisterForm.patchValue(this.selectedRow);
    } catch (e) {
      console.error(e);
    }
  }

  // addRow() {
  //   if (this.fireAccumulationRegisterForm.invalid) {
  //     this.appMessageService.showAppMessage("There are missing information. Please provide necessary information needed.", "error");
  //   } else {
  //     let data = this.fireAccumulationRegisterForm.getRawValue();
  //     let tempId: any = this.updateData.reduce((a, b) => Math.max(a.tempId || 0), -1) + 1;
  //     data.tempId = tempId;
  //     this.updateData.push(data);
  //     this.fireAccumulationRegTable.tblData.unshift(data);
  //     this.fireAccumulationRegTable.tblData = [...this.fireAccumulationRegTable.tblData];
  //     this.resetForm();
  //     this.appMessageService.showAppMessage("Register Added!", "success");
  //   }
  // }
  // onUpdate() {
  //   let data = this.fireAccumulationRegisterForm.getRawValue();
  //   let { blockCd, tempId } = this.selectedRow;
  //   // TODO: validate duplicate
  //   data.tempId = tempId;
  //   // update updateData array
  //   let tempUpdateIdx = this.updateData.findIndex(a => a.blockCd == blockCd && a.tempId == tempId);
  //   if (tempUpdateIdx == -1) {
  //     this.updateData.push(data);
  //   } else {
  //     this.updateData[tempUpdateIdx] = data;
  //   }
  //   let tableIdx = this.fireAccumulationRegTable.tblData.findIndex((a: any) => a.blockCd == blockCd && a.tempId == tempId);
  //   if (tableIdx == -1) {
  //     this.fireAccumulationRegTable.tblData.push(data);
  //   } else {
  //     this.fireAccumulationRegTable.tblData[tableIdx] = data;
  //   }
  // }
  // deleteRow() {
  //   let { tempId } = this.selectedRow;
  //   let tempUpdateIdx = this.updateData.findIndex(a => a.tempId == tempId);
  //   this.updateData.splice(tempUpdateIdx, 1);
  //   let tableIdx = this.fireAccumulationRegTable.tblData.findIndex((a: any) => a.tempId == tempId);
  //   this.fireAccumulationRegTable.tblData.splice(tableIdx, 1);
  //   this.fireAccumulationRegTable.tblData = [...this.fireAccumulationRegTable.tblData];
  //   this.resetForm();
  // }

  onSave() {
    if(this.fireAccumulationRegisterForm.invalid){
      this.openErrorDialog('There are missing information. Please provide necessary information needed.');
      return;
    }
    try {
      // this.formHider = true;
      // this.formService.showFormLoader(null, "fire-accumulation-register-form", "Saving.<br>Please wait ...", null, null);
      this.jsonDataService.contorlLoading(true);
      this.api.saveFireAccumulationRegister([this.fireAccumulationRegisterForm.getRawValue()]).subscribe({
        next: (data: any) => {
          this.securityService.checkRequestKeyResponse(data, () => {
            this.securityService.hasValidCsrfToken(data, () => {
              if (data.status == 'SUCCESS') {
                data = JSON.parse(this.jsonDataService.decrypt(data.response));
                if(data.message){
                  this.onFail(data.message)
                }else{
                  this.onComplete();
                }
              } else {
                this.onFail();
              }
            });
          });
        },
        error: () => {
          this.onFail();
        }
      });
    } catch (e) {
      console.error(e);
    }
  }

  onComplete() {
    try {
      // this.formService.hideFormLoader("fire-accumulation-register-form");
      this.jsonDataService.contorlLoading(false);
      this.openSaveDialog();
      // this.formHider = false;
      this.updateData = [];
      this.resetForm();
      this.getFireAccumulationReg();
    } catch (e) {
      console.error(e);
    }
  }

  onFail(message?:string) {
    try {
      // this.updateData = [];
      // this.resetForm();
      // this.getFireAccumulationReg();
      // this.formService.hideFormLoader("fire-accumulation-register-form");
      this.jsonDataService.contorlLoading(false);
      // this.formHider = false;
      this.openErrorDialog(message|| 'Saving fire accumulation register failed.');
    } catch (e) {
      console.error(e);
    }
  }

  openErrorDialog(message: string) {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'ERROR',
          content: message,
        }
      });
    } catch (e) {
      console.error(e);
    }
  }

  openStatesDialog(fromForm?:Boolean) {
    try {
      this.dialog.open(StateLovComponent, {
        width: '512px',
        disableClose: true,
        data: {
          table: 'STATE',
          moduleId: this.moduleId,
          regionCd: this.paramForm.get('regionCd')?.value
        }
      }).afterClosed().subscribe(data => {
        if (data.content && data.button == 'OK') {
          let { stateCd, stateLongDesc, bmCode } = data.content;
          let stateDesc = bmCode + " - " + stateLongDesc;
          if (stateCd != this.paramForm.get('stateCd')?.value && !fromForm) {
            this.resetForm();
            this.paramForm.get('stateCd')?.setValue(stateCd);
            this.paramForm.get('stateDesc')?.setValue(stateDesc);
            this.paramForm.get('bmCode')?.setValue(bmCode);
            this.fireAccumulationRegisterForm.get('stateCd')?.setValue(stateCd);
            this.fireAccumulationRegisterForm.get('stateDesc')?.setValue(stateDesc);
            this.fireAccumulationRegisterForm.get('bmStateCd')?.setValue(bmCode);
            this.getFireAccumulationReg();
            this.disableForm(false);
          }else if(fromForm){
            this.fireAccumulationRegisterForm.patchValue({
              stateCd:stateCd,
              stateDesc: stateDesc,
              bmStateCd: bmCode,
              districtCd: '',
              distLongDesc:''
            });
            this.fireAccumulationRegisterForm.markAsDirty();
          }
        }
        // this.sublineDisabled = this.sublineChecker();
      });
    } catch (e) {
      console.error(e);
    }
  }

  openOccupancyDialog() {
    try {
      this.dialog.open(OccupancyLovComponent, {
        width: '512px',
        disableClose: true,
        data: {
          table: 'OCCUPANCY',
          moduleId: this.moduleId
        }
      }).afterClosed().subscribe(data => {

        if (data.content && data.button == 'OK') {
          let { occupancyDesc, occupancyCd, bmCode } = data.content;
          occupancyDesc = bmCode + " - " + occupancyDesc;
          this.fireAccumulationRegisterForm.get('occupancyCd')?.setValue(occupancyCd);
          this.fireAccumulationRegisterForm.get('occupancyDesc')?.setValue(occupancyDesc);
          this.fireAccumulationRegisterForm.markAsDirty();
        }
      });
    } catch (e) {
      console.error(e);
    }
  }

  openDistrictDialog() {
    try {
      this.dialog.open(DistrictLovComponent, {
        width: '512px',
        disableClose: true,
        data: {
          table: 'DISTRICT',
          moduleId: this.moduleId,
          stateCd: this.fireAccumulationRegisterForm.get('stateCd')?.value
        }
      }).afterClosed().subscribe(data => {

        if (data.content && data.button == 'OK') {
          let { districtCd, distLongDesc, bmCode } = data.content;
          distLongDesc = bmCode + " - " + distLongDesc;
          this.fireAccumulationRegisterForm.get('districtCd')?.setValue(districtCd);
          this.fireAccumulationRegisterForm.get('distLongDesc')?.setValue(distLongDesc);
          this.fireAccumulationRegisterForm.markAsDirty();
        }
      });
    } catch (e) {
      console.error(e);
    }
  }

  openConstructionDialog() {
    try {
      this.dialog.open(ConstructionLovComponent, {
        width: '512px',
        disableClose: true,
        data: {
          table: 'CONSTRUCTION',
          moduleId: this.moduleId
        }
      }).afterClosed().subscribe(data => {
        if (data.content && data.button == 'OK') {
          let { constructionCd, constructionDesc } = data.content;
          constructionDesc = constructionCd + " - " + constructionDesc;
          this.fireAccumulationRegisterForm.get('constructionCd')?.setValue(constructionCd);
          this.fireAccumulationRegisterForm.get('constructionDesc')?.setValue(constructionDesc);
          this.fireAccumulationRegisterForm.markAsDirty();
        }
      });
    } catch (e) {
      console.error(e);
    }
  }

  openRegionDialog() {
    try {
      this.dialog.open(RegionLovComponent, {
        width: '512px',
        disableClose: true,
        data: {
          table: 'REGION',
          moduleId: this.moduleId
        }
      }).afterClosed().subscribe(data => {
        if (data.content && data.button == 'OK') {
          let { regionCd, regionDesc } = data.content;
          if (regionCd != this.paramForm.get('regionCd')?.value) {
            regionDesc = regionCd + " - " + regionDesc;
            this.paramForm.get('regionCd')?.setValue(regionCd);
            this.paramForm.get('regionDesc')?.setValue(regionDesc);
            this.resetState();
            this.disableForm(true);
            this.isStateDisabled = false;
          }
        }
      });
    } catch (e) {
      console.error(e);
    }
  }

  resetState() {
    this.paramForm.patchValue({
      stateCd: null,
      stateDesc: null,
    })
    this.fireAccumulationRegTable.tblData = [];
    this.resetForm();
    this.addUpdateFlag = true;
  }

  resetForm() {
    this.fireAccumulationRegisterForm.reset();
    this.fireAccumulationRegisterForm.get('activeTag')?.setValue('A');
    if (this.paramForm.get('stateCd')?.value) {
      let { stateCd, stateDesc, bmCode } = this.paramForm.getRawValue();
      this.fireAccumulationRegisterForm.get('stateCd')?.setValue(stateCd);
      this.fireAccumulationRegisterForm.get('stateDesc')?.setValue(stateDesc);
      this.fireAccumulationRegisterForm.get('bmStateCd')?.setValue(bmCode);
    }
    this.fireAccumulationRegisterForm.markAsPristine();
  }

  disableForm(isDisabled: Boolean) {
    this.isStateFormDisabled = isDisabled || (this.paramForm.get('filterBy')?.value != 'R' && this.paramForm.get('filterBy')?.value != 'D');
    this.isDistrictDisabled = isDisabled;
    this.isOccupancyDisabled = isDisabled;
    this.isConstructionDisabled = isDisabled;
    isDisabled ? this.fireAccumulationRegisterForm.disable() : this.fireAccumulationRegisterForm.enable();
  }
}
