import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { DialogBoxComponent } from 'src/app/components/common/dialog-box/dialog-box';

@Injectable({
    providedIn: 'root'
})
export class DialogBoxService {

    private dialogTitle: any = {
        S: "Success",
        E: "Error",
        C: "Confirmation",
        I: "Information"
    }

    constructor(
        private matDialog: MatDialog
    ) { }

    open(params: any, disableClose?: boolean): void {
        this.matDialog.open(DialogBoxComponent, {
            // DEFAULT WIDTH
            width: "400px",
            data: {
                title: this.dialogTitle[params.messageType],
                content: params.content,
                buttons: params.buttons
            },
            disableClose: disableClose
        }).afterClosed().subscribe((action) => {
            action ? action() : "";
        });
    }
}