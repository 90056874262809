<section id='bfb-maint'>
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <!-- Container for BFB Table using prime ng -->
        <div class = 'table-user'>
            <osp-table [tblConfig]="tblConfigEmp" (onRowClick)="onRowClick($event)"></osp-table>
        </div>
        <!-- Loading Animation -->
        <div id="generate-emp-form">
            <div class="form-loader"></div>
        </div>

        <!-- Container for BFB Fields -->
        <div class="form-content">
            
            <form [formGroup]="usersForm" class="form" *ngIf="noLoadingInProcess">
                <div id="bfb-error-message"></div>
                <div class="form-container-grid">
                    <div class="field required">
                        <label>User ID</label>
                    </div>
                    <div class="">
                        <input formControlName="userID" type="text" maxlength="10" (input)="autoUppercase($event)" alphanumeric>
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag">
                            <option *ngFor="let option of activeTags" [value]="option.value" [disabled]="option.value == 'L' || option.value == 'T'">
                                {{option.text}}
                            </option><option value="I">No</option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>First Name</label>
                    </div>
                    <div class="">
                        <input formControlName="firstName" type="text" maxlength="20" (blur)="checkNameLength('firstName')">
                    </div>
                    <div class="field">
                        <label>Middle Name</label>
                    </div>
                    <div class="">
                        <input formControlName="middleName" type="text" maxlength="30" (blur)="checkNameLength('middleName')">
                    </div>

                    <div class="field required">
                        <label>Last Name</label>
                    </div>
                    <div class="">
                        <input formControlName="lastName" type="text" maxlength="30" (blur)="checkNameLength('lastName')">
                    </div>
                    <div class="field required">
                        <label>Email Address</label>
                    </div>
                    <div class="">
                        <input formControlName="emailAdd" type="email" maxlength="60">
                    </div>

                    <div class="field required">
                        <label>Issue Source</label>
                    </div>
                    <div class="lov-search">
                        <input type="text" readonly formControlName="issueField">
                        <button type="button" class="btn-search btn" (click)="openIssDialog()">
                            <mat-icon>
                                search
                            </mat-icon>
                        </button>
                    </div>

                    <div class="field required">
                        <label>Mobile Number</label>
                    </div>
                    <div class="">
                        <input formControlName="mobileNumber" type="text" maxlength="16" (input)="numOnlyPattern($event)">
                    </div>

                    <div class="field required">
                        <label>Employee No.</label>
                    </div>
                    <div class="">
                        <input formControlName="employeeNo" type="text" placeholder="00NNNNN" maxlength="8" (input)="numOnlyPattern($event)">
                    </div>
                    <div class="field">
                        <label>Default BMA</label>
                    </div>
                    <div class="lov-search">
                        <input type="text" readonly formControlName="bmaDesc">
                        <button type="button" class="btn-search btn" (click)="openBmaDialog()">
                            <mat-icon>
                                search
                            </mat-icon>
                        </button>
                    </div>

                    <div class="field">
                        <label>Default Source Code</label>
                    </div>
                    <div class="lov-search">
                        <input type="text" readonly formControlName="srcCodeDesc">
                        <button type="button" class="btn-search btn" (click)="openSrcCodeDialog()">
                            <mat-icon>
                                search
                            </mat-icon>
                        </button>
                    </div>
                    <div class="field">
                        <label>Default Source Extension</label>
                    </div>
                    <div class="lov-search">
                        <input type="text" readonly formControlName="srcExtDesc">
                        <button type="button" class="btn-search btn" (click)="openSrcExtDialog()">
                            <mat-icon>
                                search
                            </mat-icon>
                        </button>
                    </div>

                    <div class="field required">
                        <label>Effective Date</label>
                    </div>
                    <div class="">
                        <input date-input formControlName="effDate" type="text" placeholder="MM/DD/YYYY" [matDatepicker]="effDatePicker" (input)="datePattern($event)" maxlength="10">
                        <mat-datepicker-toggle matSuffix [for]="effDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #effDatePicker></mat-datepicker>
                    </div>
                    <div class="field">
                        <label>Expiry Date</label>
                    </div>
                    <div class="">
                        <input date-input formControlName="expiryDate" type="text" placeholder="MM/DD/YYYY" [matDatepicker]="expiryDatePicker" (input)="datePattern($event)" maxlength="10">
                        <mat-datepicker-toggle matSuffix [for]="expiryDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #expiryDatePicker></mat-datepicker>
                    </div>

                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" type="text">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUpdateName">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUserUpdate">
                    </div>

                </div>
                
                <div class='btn-row'>
                    <button type="button" id='btn-add' *ngIf="!isEmpSelected" title='Register an employee' class="btn btn2" value='ADD' type="submit" (click)='insertEmployee()'>ADD</button>
                    <button type="button" id='btn-update' *ngIf="isEmpSelected" title='Register an employee' class="btn btn2" value='UPDATE' (click)='updateEmployee()' type="submit">UPDATE</button>
                    <button type="button" id='btn-delete' title='Remove an employee' class="btn btn2" value='DELETE' type="submit" (click)='deleteEmployee()' [disabled]='deleteDisabled'>DELETE</button>
                </div> 

                <div class="mtn-title">
                </div>

                <div class='btn-row'>
                    <button type="button" class="btn btn2" [disabled]="!isEmpSelected" (click)="resetUserPw()">PASSWORD RESET</button>
                    <button type="button" id='btn-save' title='Save changes on employee details' class="btn btn2" value='SAVE' type="submit" (click)='toSave()' [disabled]='saveDisabled'>SAVE</button>
                </div>
            </form>
        </div>
    </div>
</section>