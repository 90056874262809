import { Component, OnInit } from '@angular/core';
import { APICallService } from 'src/app/services/api-call.service';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { forkJoin, Observable } from 'rxjs';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { SecurityService } from 'src/app/services/security.service'; 
import { CountryLovDialogComponent } from '../../common/country-lov/country-lov.component';
import { ProvinceLovDialogComponent } from '../../common/province-lov/province-lov.component';
import { CityLovDialogComponent } from '../../common/city-lov/city-lov.component';
@Component({
  selector: 'pre-approved-subdivision-maintenance',
  templateUrl: './pre-approved-subdivision-maintenance.component.html',
  styleUrls: ['./pre-approved-subdivision-maintenance.component.css']
})
export class PreApprovedSubdivisionMaintenannceComponent implements OnInit, LeaveConfirmation {
  
  datePipe = new DatePipe('en-us');

  addDisabled : boolean = false;
  saveDisabled : boolean = true;
  deleteDisabled : boolean = true;
  tranPosted : boolean = false;
  noLoadingInProcess : boolean = true;
  validated : boolean = false;
  userIDReadOnly : boolean = false;
  isLineSelected : boolean = false;
  pubUserStdRatesCodeField: string = '';
  currentUser: string = this.jsonDataService.retrieveFromStorage('userId');
  rowClickedData: any;
  selectedData : any = null;
  moduleId: string = 'BMM165';
  moduleName: string = '';
  tempAddedData: any = [];
  tempUpdateData: any = [];
  provinceDisabled: boolean = true;
  cityDisabled: boolean = true;

  selectedCountry: any = {
    countryCd: '',
    countryName: '',
    countryField: ''
  };
  selectedProvince: any = {
    provinceCd: '',
    provinceName: '',
    provinceField: ''
  };
  selectedCity: any = {
    cityCd: '',
    cityName: '',
    cityField: ''
  };
  paramForm = this.formBuilder.group({
    countryCd: [null],
    countryName: [null],
    countryField: [null],
    provinceCd: [null],
    provinceName: [null],
    provinceField: [null],
    cityCd: [null],
    cityName: [null],
    cityField: [null],
  });
  preApprovedSubdivisionForm = this.formBuilder.group({
    subdivCode: [null, [Validators.required]],
    activeTag: ['A', [Validators.required]],
    subdivName: [null, [Validators.required, Validators.maxLength(1000)]],
    origSubdivName: [null],
    bpimsCode: [null, [Validators.maxLength(8)]],
    bpimsShortDesc: [null, [Validators.maxLength(10)]],
    remarks: [null],
    userID: [null],
    lastUserUpdate: [null]
  });

  constructor(
    private apiCallService: APICallService,
    public _Dialog: MatDialog, 
    private userDetailService: UserDetailsService,
    private formService: FormService, 
    private formBuilder: FormBuilder, 
    private appMessageService: AppMessageService,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService
  ) {
    let moduleLists = JSON.parse(this.jsonDataService.decrypt(sessionStorage.getItem("mdls") || "")); 
    this.moduleName = moduleLists.filter((data: any) => { return data.moduleId === this.moduleId })[0].moduleDesc.toUpperCase();
  }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempAddedData!='' || this.tempUpdateData!='') {
      return false;
    } else {
      return true;
    }
  }

  preApprovedSubdivisionTblConfig: any = {
    cols: [
      {
        key: "SUBDIV_CD",
        header: "Code",
        dataType: "padNumber",
        width: '15%'
      },
      {
        key: "SUBDIV_NAME",
        header: "Subdivision Name",
        dataType: "string",
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        width: '10%'
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }
  ngOnInit() {
  }
  initOnGet(): void {
    try {
      this.apiCallService.refreshNeeded$
            .subscribe(() => {
              this.getAllPreApprovedSubdivision();
          });
      this.getAllPreApprovedSubdivision();
    }
    catch(e) {
    }
  }

  async getAllPreApprovedSubdivision(){
    try{
      let countryCd = this.paramForm.get('countryCd')?.value;
      let provinceCd = this.paramForm.get('provinceCd')?.value;
      let cityCd = this.paramForm.get('cityCd')?.value;

      if (countryCd && provinceCd&& cityCd) {
        const params = {
          queryParams:{
            provinceCd: provinceCd,
            countryCd: countryCd,
            cityCd: cityCd,
          },
          moduleId: this.moduleId,
          userId: this.userDetailService.userId,
          type: "MODULE"
        }
        this.preApprovedSubdivisionTblConfig.loading = true;
        this.apiCallService.getPreApprovedSubdivision(params).subscribe({
          next: (data: any) => {
            this.securityService.checkRequestKeyResponse(data, ()=>{
              this.securityService.hasValidCsrfToken(data, ()=>{
                data = JSON.parse(this.jsonDataService.decrypt(data.response));
                let rawData:any = [];
                if(!Array.isArray(data)){
                  rawData.push(data);
                }
                else{
                  rawData = data;
                }
                if(rawData.length>0){
                  for(let item of rawData){
                    item.ZONE_FIELD = item.ZONE_NAME +" - "+ item.ZONE_DESC;
                  }
                }
                this.preApprovedSubdivisionTblConfig.tblData = rawData;
                this.preApprovedSubdivisionTblConfig.loading = false;
                this.tempAddedData = [];
                this.tempUpdateData = [];
                this.saveDisabled = this.saveChecker();
              });
            });
          },
          error: (e: any) => {
            this.preApprovedSubdivisionTblConfig.loading = false;
          }
        });
      } else{
        this.preApprovedSubdivisionTblConfig.loading = true;
        this.preApprovedSubdivisionTblConfig.tblData = [];
        this.preApprovedSubdivisionTblConfig.loading = false;
        this.tempAddedData = [];
        this.tempUpdateData = [];
        this.saveDisabled = this.saveChecker();
      }
    }catch(e){
    }
  }

  updateLine() {
    try {
      this.tranPosted = true;
      this.validateUpdate();
      if (this.validated) {
        this.updateFormTable();
      }
    }
    catch(e) {
    }
  }

  updateFormTable() {
    try {
      let tempInsertChecker = this.tempAddedData.find((line: { SUBDIV_NAME: any }) => 
        line.SUBDIV_NAME.trim().toUpperCase() == this.preApprovedSubdivisionForm.get('origSubdivName')?.value.trim().toUpperCase()
      );
      let tempUpdateChecker = this.tempUpdateData.find((line: { SUBDIV_NAME: any }) => 
        line.SUBDIV_NAME.trim().toUpperCase() == this.preApprovedSubdivisionForm.get('origSubdivName')?.value.trim().toUpperCase()
      );
      
      this.tableDataUpdate();
        
      // Adds Updated Data that exists in the database to an object array for updated datas
      if(tempInsertChecker!=null && tempInsertChecker!='') {
        //this.tempUpdateData.push(this.formValueToData());
        tempInsertChecker.SUBDIV_CD = this.preApprovedSubdivisionForm.get('subdivCode')?.value;
        tempInsertChecker.BM_SHORT_DESC = this.preApprovedSubdivisionForm.get('bpimsShortDesc')?.value;
        tempInsertChecker.SUBDIV_NAME = this.preApprovedSubdivisionForm.get('subdivName')?.value;
        tempInsertChecker.BM_CODE = this.preApprovedSubdivisionForm.get('bpimsCode')?.value;
        tempInsertChecker.PROVINCE_CD = this.paramForm.get('provinceCd')?.value;
        tempInsertChecker.PROVINCE_NAME = this.paramForm.get('provinceName')?.value;
        tempInsertChecker.PROVINCE_FIELD = this.paramForm.get('provinceField')?.value;
        tempInsertChecker.CITY_CD = this.paramForm.get('cityCd')?.value;
        tempInsertChecker.CITY_NAME = this.paramForm.get('cityName')?.value;
        tempInsertChecker.CITY_FIELD = this.paramForm.get('cityField')?.value;
        tempInsertChecker.COUNTRY_CD = this.paramForm.get('countryCd')?.value;
        tempInsertChecker.COUNTRY_NAME = this.paramForm.get('countryName')?.value;
        tempInsertChecker.COUNTRY_FIELD = this.paramForm.get('countryField')?.value;
        tempInsertChecker.ACTIVE_TAG = this.preApprovedSubdivisionForm.get('activeTag')?.value;
        tempInsertChecker.REMARKS = this.preApprovedSubdivisionForm.get('remarks')?.value;
        tempInsertChecker.LAST_USER_UPDATE = this.datePipe.transform(new Date(this.preApprovedSubdivisionForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a');
        tempInsertChecker.USER_ID = this.preApprovedSubdivisionForm.get('userID')?.value;
        tempInsertChecker.MODE = 'I';
      } 
      else if(tempUpdateChecker!=null && tempUpdateChecker!='') {
        tempUpdateChecker.SUBDIV_CD  = this.preApprovedSubdivisionForm.get('subdivCode')?.value;
        tempUpdateChecker.BM_SHORT_DESC = this.preApprovedSubdivisionForm.get('bpimsShortDesc')?.value;
        tempUpdateChecker.SUBDIV_NAME = this.preApprovedSubdivisionForm.get('subdivName')?.value;
        tempUpdateChecker.BM_CODE = this.preApprovedSubdivisionForm.get('bpimsCode')?.value;
        tempUpdateChecker.PROVINCE_CD = this.paramForm.get('provinceCd')?.value;
        tempUpdateChecker.PROVINCE_NAME = this.paramForm.get('provinceName')?.value;
        tempUpdateChecker.PROVINCE_FIELD = this.paramForm.get('provinceField')?.value;
        tempUpdateChecker.CITY_CD = this.paramForm.get('cityCd')?.value;
        tempUpdateChecker.CITY_NAME = this.paramForm.get('cityName')?.value;
        tempUpdateChecker.CITY_FIELD = this.paramForm.get('cityField')?.value;
        tempUpdateChecker.COUNTRY_CD = this.paramForm.get('countryCd')?.value;
        tempUpdateChecker.COUNTRY_NAME = this.paramForm.get('countryName')?.value;
        tempUpdateChecker.COUNTRY_FIELD = this.paramForm.get('countryField')?.value;
        tempUpdateChecker.ACTIVE_TAG = this.preApprovedSubdivisionForm.get('activeTag')?.value;
        tempUpdateChecker.REMARKS = this.preApprovedSubdivisionForm.get('remarks')?.value;
        tempUpdateChecker.LAST_USER_UPDATE = this.datePipe.transform(new Date(this.preApprovedSubdivisionForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a');
        tempUpdateChecker.USER_ID = this.preApprovedSubdivisionForm.get('userID')?.value;
        tempUpdateChecker.MODE = 'U';
      }
      else {
        this.tempUpdateData.push(this.formValueToData());
      }
      this.saveDisabled = false;
      this.resetFormValues();
      this.appMessageService.showAppMessage("State details updated!", "success");
    }
    catch(e) {
    }
  }

  tableDataUpdate() {
    try {
      this.preApprovedSubdivisionForm.patchValue({
        activeTag: this.preApprovedSubdivisionForm.get('activeTag')?.value,
        userID: this.currentUser,
        lastUserUpdate: new Date()
      });
      let updateIndex = this.preApprovedSubdivisionTblConfig.tblData.indexOf(this.rowClickedData);
      
      this.preApprovedSubdivisionTblConfig.tblData[updateIndex] = {
        SUBDIV_CD: this.preApprovedSubdivisionForm.get('subdivCode')?.value,
        BM_SHORT_DESC: this.preApprovedSubdivisionForm.get('bpimsShortDesc')?.value,
        SUBDIV_NAME: this.preApprovedSubdivisionForm.get('subdivName')?.value,
        BM_CODE: this.preApprovedSubdivisionForm.get('bpimsCode')?.value,
        PROVINCE_CD: this.paramForm.get('provinceCd')?.value,
        PROVINCE_NAME: this.paramForm.get('provinceName')?.value,
        PROVINCE_FIELD: this.paramForm.get('provinceField')?.value,
        CITY_CD: this.paramForm.get('cityCd')?.value,
        CITY_NAME: this.paramForm.get('cityName')?.value,
        CITY_FIELD: this.paramForm.get('cityField')?.value,
        COUNTRY_CD: this.paramForm.get('countryCd')?.value,
        COUNTRY_NAME: this.paramForm.get('countryName')?.value,
        COUNTRY_FIELD: this.paramForm.get('countryField')?.value,
        ACTIVE_TAG: this.preApprovedSubdivisionForm.get('activeTag')?.value,
        REMARKS: this.preApprovedSubdivisionForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.preApprovedSubdivisionForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.preApprovedSubdivisionForm.get('userID')?.value,
        MODE: 'U'
      };
      this.preApprovedSubdivisionTblConfig.tblData = [...this.preApprovedSubdivisionTblConfig.tblData];
    }
    catch(e) {
    }
  }

  formValueToData() {
    try {
      let formToData: any = { // this.datePipe.transform(new Date(), 'MM/dd/yyyy')
        SUBDIV_CD: this.preApprovedSubdivisionForm.get('subdivCode')?.value,
        BM_SHORT_DESC: this.preApprovedSubdivisionForm.get('bpimsShortDesc')?.value,
        SUBDIV_NAME: this.preApprovedSubdivisionForm.get('subdivName')?.value,
        BM_CODE: this.preApprovedSubdivisionForm.get('bpimsCode')?.value,
        PROVINCE_CD: this.paramForm.get('provinceCd')?.value,
        PROVINCE_NAME: this.paramForm.get('provinceName')?.value,
        PROVINCE_FIELD: this.paramForm.get('provinceField')?.value,
        CITY_CD: this.paramForm.get('cityCd')?.value,
        CITY_NAME: this.paramForm.get('cityName')?.value,
        CITY_FIELD: this.paramForm.get('cityField')?.value,
        COUNTRY_CD: this.paramForm.get('countryCd')?.value,
        COUNTRY_NAME: this.paramForm.get('countryName')?.value,
        COUNTRY_FIELD: this.paramForm.get('countryField')?.value,
        ACTIVE_TAG: this.preApprovedSubdivisionForm.get('activeTag')?.value,
        REMARKS: this.preApprovedSubdivisionForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.preApprovedSubdivisionForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.preApprovedSubdivisionForm.get('userID')?.value,
        MODE: 'U'
      }
      return formToData;
    }
    catch(e) {
    }
  }
  deleteLine() {
    try {
      let clickData = this.rowClickedData;
      this.preApprovedSubdivisionTblConfig.tblData = this.preApprovedSubdivisionTblConfig.tblData.filter(
        function(e: any) {
          return e.SUBDIV_NAME.trim().toUpperCase() !== clickData.SUBDIV_NAME.trim().toUpperCase();
        }
      );
      this.tempAddedData = this.tempAddedData.filter(
        function(e: any) {

          return e.SUBDIV_NAME.trim().toUpperCase() !== clickData.SUBDIV_NAME.trim().toUpperCase();
        }
      );
      this.resetFormValues();
    }
    catch(e) {
    }
  }

  insertLine() {
    try {
      this.tranPosted = true;
      this.validateInsert();
      if (this.validated) {
        this.addFormToTable();
      }
    }
    catch(e) {
    }
  }
  addFormToTable() {
    try {
      this.preApprovedSubdivisionForm.patchValue({
        activeTag: this.preApprovedSubdivisionForm.get('activeTag')?.value,
        userID: this.currentUser,
        lastUserUpdate: new Date()
      });
      let formToData: any = { // this.datePipe.transform(new Date(), 'MM/dd/yyyy')
        SUBDIV_CD: this.preApprovedSubdivisionForm.get('subdivCode')?.value,
        BM_SHORT_DESC: this.preApprovedSubdivisionForm.get('bpimsShortDesc')?.value,
        SUBDIV_NAME: this.preApprovedSubdivisionForm.get('subdivName')?.value,
        BM_CODE: this.preApprovedSubdivisionForm.get('bpimsCode')?.value,
        PROVINCE_CD: this.paramForm.get('provinceCd')?.value,
        PROVINCE_NAME: this.paramForm.get('provinceName')?.value,
        PROVINCE_FIELD: this.paramForm.get('provinceField')?.value,
        CITY_CD: this.paramForm.get('cityCd')?.value,
        CITY_NAME: this.paramForm.get('cityName')?.value,
        CITY_FIELD: this.paramForm.get('cityField')?.value,
        COUNTRY_CD: this.paramForm.get('countryCd')?.value,
        COUNTRY_NAME: this.paramForm.get('countryName')?.value,
        COUNTRY_FIELD: this.paramForm.get('countryField')?.value,
        ACTIVE_TAG: this.preApprovedSubdivisionForm.get('activeTag')?.value,
        REMARKS: this.preApprovedSubdivisionForm.get('remarks')?.value,
        LAST_USER_UPDATE: this.datePipe.transform(new Date(this.preApprovedSubdivisionForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        USER_ID: this.preApprovedSubdivisionForm.get('userID')?.value,
        MODE: 'I'
      }
      this.preApprovedSubdivisionTblConfig.tblData.unshift(formToData);
      this.preApprovedSubdivisionTblConfig.tblData = [...this.preApprovedSubdivisionTblConfig.tblData];
      this.tempAddedData.push(formToData);
      this.resetFormValues();
      this.appMessageService.showAppMessage("State details added!", "success");
    }
    catch(e) {
    }
  }

  saveData(tempChangeData:any[]){
    this.apiCallService.savePreApprovedSubdivision(tempChangeData)
    .subscribe({
      next: (response : any) => {
        this.securityService.checkRequestKeyResponse(response, () => {
          this.securityService.hasValidCsrfToken(response, ()=>{
            if (response.status == "SUCCESS" && this.tranPosted) {
              this.openDialog('SUCCESS', 'Details Successfully saved!');
              this.initOnGet();
              this.resetFormValues();
              this.tempAddedData = [];
              this.tempUpdateData = [];
              this.saveDisabled = this.saveChecker();
            }  
            else {
              this.initOnGet();
              this.saveDisabled = this.saveChecker();
              this.openDialog('ERROR', 'An error has occured while saving details');
            }
          });
        });
      }, 
      error: () => {
        this.openDialog('ERROR', 'An error has occured while saving details');
      }
    });
  }

  toSave() {
    try {
      this.noLoadingInProcess = false;
      this.formService.showFormLoader(null, "generate-line-form", "Please wait ...", null, null);
      let tempChangeData= this.tempAddedData.concat(this.tempUpdateData);
      if(tempChangeData.length!=0) {
        this.saveData(tempChangeData);
      } else {
        this.initOnGet();
        this.resetFormValues();
        this.tempAddedData = [];
        this.tempUpdateData = [];
        this.saveDisabled = this.saveChecker();
      }
    }
    catch(e) {
    }
  }
  saveChecker() {
    try {
      if((this.tempAddedData==null || this.tempAddedData=='') && (this.tempUpdateData==null || this.tempUpdateData=='')) {
        return true;
      }
      else {
        return false;
      }
    }
    catch(e) {
      return true;
    }
  }

  deleteChecker(ev: any) {
    try {
      let tempDataFlag;
      tempDataFlag = this.tempAddedData.filter(function(e: any) {
        let returnValue = e.SUBDIV_CD==ev.SUBDIV_CD;
        return returnValue;
      });
      if(tempDataFlag==''||tempDataFlag==null) {
        return true;
      } else {
        return false;
      }
    }
    catch(e) {
      return true;
    }
  }

  provinceChecker(){
    try{
      let countryCd = this.paramForm.get('countryCd')?.value;
      if(countryCd!==''&&countryCd!==null){
        return false;
      }
      else{ 
        return true;
      }
    }catch(e){
      return true;
    }
  }
  cityChecker(){
    try{
      let provinceCd = this.paramForm.get('provinceCd')?.value;
      if(provinceCd!==''&&provinceCd!==null){
        return false;
      }
      else{ 
        return true;
      }
    }catch(e){
      return true;
    }
  }
  onRowClick(ev: any) {
    try {    
      if(ev!=null) {
        this.rowClickedData = ev;
        this.loadFormValues();
        this.saveDisabled = this.saveChecker();
        this.deleteDisabled = this.deleteChecker(ev);
      }
      else {
        this.resetFormValues();
      }
    }
    catch(e) {
    }
  }

  loadFormValues() {
    try {
      this.preApprovedSubdivisionForm.patchValue({
        subdivCode: this.rowClickedData.SUBDIV_CD,
        bpimsShortDesc: this.rowClickedData.BM_SHORT_DESC,
        subdivName: this.rowClickedData.SUBDIV_NAME,
        origSubdivName: this.rowClickedData.SUBDIV_NAME,
        bpimsCode: this.rowClickedData.BM_CODE,
        provinceCd: this.rowClickedData.PROVINCE_CD,
        provinceName: this.rowClickedData.PROVINCE_NAME,
        provinceField: this.rowClickedData.PROVINCE_FIELD,
        cityCd: this.rowClickedData.CITY_CD,
        cityName: this.rowClickedData.CITY_NAME,
        cityField: this.rowClickedData.CITY_FIELD,
        countryCd: this.rowClickedData.COUNTRY_CD,
        countryName: this.rowClickedData.COUNTRY_NAME,
        countryField: this.rowClickedData.COUNTRY_FIELD,
        activeTag: this.rowClickedData.ACTIVE_TAG,
        remarks: this.rowClickedData.REMARKS,
        userID: this.rowClickedData.USER_ID,
        lastUserUpdate: this.rowClickedData.LAST_USER_UPDATE
      });
      this.addDisabled = true;
      this.userIDReadOnly = true;
      this.isLineSelected = true;
    }
    catch(e) {
    }
  }

  resetFormValues() {
    try {
      this.selectedData = null;
      // this.paramForm.get('lineCD')?.enable();
      this.preApprovedSubdivisionForm.patchValue({
        subdivCode: null,
        bpimsShortDesc: null,
        subdivName: null,
        origSubdivName: null,
        bpimsCode: null,
        activeTag: 'A',
        remarks: null,
        userID: null,
        lastUserUpdate: null
      });

      this.addDisabled = false;
      this.saveDisabled = this.saveChecker();
      this.deleteDisabled = true;
      this.userIDReadOnly = false;
      this.isLineSelected = false;

      this.rowClickedData = '' || null;
    }
    catch (e) {
    console.error(e);
    }
  }

  openCountryDialog() {
    try {
      this._Dialog.open(CountryLovDialogComponent, {
        width: '512px',
        disableClose: true,
        data: {
          table: 'COUNTRY',
          moduleId: this.moduleId,
          userId: this.userDetailService.userId,
        }
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedCountry = data.content;
          this.paramForm.get('countryCd')?.setValue(this.selectedCountry.countryCd==null || this.selectedCountry.countryCd=='' ? '' : this.selectedCountry.countryCd);
          this.paramForm.get('countryName')?.setValue(this.selectedCountry.countryCd==null || this.selectedCountry.countryCd=='' ? '' : this.selectedCountry.countryName);
          this.paramForm.get('countryField')?.setValue(this.selectedCountry.countryCd==null || this.selectedCountry.countryCd=='' ? '' : this.selectedCountry.countryName);
        }
        this.paramForm.get('provinceCd')?.setValue(null);
        this.paramForm.get('provinceName')?.setValue(null);
        this.paramForm.get('provinceField')?.setValue(null);
        this.resetFormValues();
        this.initOnGet();
        this.provinceDisabled = this.provinceChecker();
      });
    } catch(e) {
    }
  }
  openProvinceDialog() {
    try {
      this._Dialog.open(ProvinceLovDialogComponent, {
        width: '512px',
        disableClose: true,
        data: {
          table: 'COUNTRY',
          moduleId: this.moduleId,
          userId: this.userDetailService.userId,
          countryCd: this.paramForm.get('countryCd')?.value,
        }
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedProvince = data.content;
          this.paramForm.get('provinceCd')?.setValue(this.selectedProvince.provinceCd==null || this.selectedProvince.provinceCd=='' ? '' : this.selectedProvince.provinceCd);
          this.paramForm.get('provinceName')?.setValue(this.selectedProvince.provinceCd==null || this.selectedProvince.provinceCd=='' ? '' : this.selectedProvince.provinceName);
          this.paramForm.get('provinceField')?.setValue(this.selectedProvince.provinceCd==null || this.selectedProvince.provinceCd=='' ? '' : this.selectedProvince.provinceName);
        }
        this.paramForm.get('cityCd')?.setValue(null);
        this.paramForm.get('cityName')?.setValue(null);
        this.paramForm.get('cityField')?.setValue(null);
        this.resetFormValues();
        this.initOnGet();
        this.cityDisabled = this.cityChecker();
      });
    } catch(e) {
    }
  }
  openCityDialog() {
    try {
      this._Dialog.open(CityLovDialogComponent, {
        width: '512px',
        disableClose: true,
        data: {
          table: 'COUNTRY',
          moduleId: this.moduleId,
          userId: this.userDetailService.userId,
          countryCd: this.paramForm.get('countryCd')?.value,
          provinceCd: this.paramForm.get('provinceCd')?.value,
        }
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedCity = data.content;
          this.paramForm.get('cityCd')?.setValue(this.selectedCity.cityCd==null || this.selectedCity.cityCd=='' ? '' : this.selectedCity.cityCd);
          this.paramForm.get('cityName')?.setValue(this.selectedCity.cityCd==null || this.selectedCity.cityCd=='' ? '' : this.selectedCity.cityName);
          this.paramForm.get('cityField')?.setValue(this.selectedCity.cityCd==null || this.selectedCity.cityCd=='' ? '' : this.selectedCity.cityName);
          this.initOnGet();
        }
        this.resetFormValues();
      });
    } catch(e) {
    }
  }
  openDialog(title: any, message: any) {
    try {
      this.noLoadingInProcess = true;
      this.formService.hideFormLoader("generate-line-form");
      this._Dialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        } 
      });
    } catch(e) {
    }
  }

  validateInsert() {
    try {
      this.validated = false;
      if (!this.requiredFieldCheck()){
        setTimeout(()=>{                           //10 secs countdown
          this.formService.hideFormMsg("pre-approved-subdivision-error-message");
        }, 10000);
      }
      else if (!this.duplicateCheck(this.preApprovedSubdivisionTblConfig.tblData)) {
        setTimeout(()=>{                           
          this.formService.hideFormMsg("pre-approved-subdivision-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
    }
  }

  requiredFieldCheck() {
    try {
      if(this.preApprovedSubdivisionForm.get('activeTag')?.value==='' || this.preApprovedSubdivisionForm.get('activeTag')?.value===null
        || this.preApprovedSubdivisionForm.get('subdivName')?.value.trim()==='' || this.preApprovedSubdivisionForm.get('subdivName')?.value===null) {
        this.formService.showFormMsg("pre-approved-subdivision-error-message", 'There are missing information. Please provide the necessary information needed.', "E");
        return false;
      }
      else {
        return true;
      }
   }
    catch (e) {
    console.error(e);
      return false;
    }
  }

  duplicateCheck(tblData:any) {
    try {
      let fieldSubdivName = this.preApprovedSubdivisionForm.get('subdivName')?.value;
      this.pubUserStdRatesCodeField = tblData.filter(function(e: any) {
        if(e.SUBDIV_NAME.trim().toUpperCase()==fieldSubdivName.trim().toUpperCase()){
          return true
        }
        else{
          return false
        }
      });
      if(this.pubUserStdRatesCodeField=='' || this.pubUserStdRatesCodeField==null) {
        return true;
      } else {
        this.formService.showFormMsg("pre-approved-subdivision-error-message", 'Subdivision Name already exists. Please add a unique Subdivision Name', "E");
        return false;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }

  validateUpdate() {
    try {
      this.validated = false;
      let fieldSubdivName = this.preApprovedSubdivisionForm.get('origSubdivName')?.value;
      let filteredTblData = this.preApprovedSubdivisionTblConfig.tblData.filter(function(e:any){
        if(e.SUBDIV_NAME.trim().toUpperCase()==fieldSubdivName.trim().toUpperCase()){
          return false;
        }
        else{
          return true;
        }
      });
      if (!this.requiredFieldCheck()){
        setTimeout(()=>{                          
          this.formService.hideFormMsg("pre-approved-subdivision-error-message");
        }, 10000);
      }
      else if (!this.duplicateCheck(filteredTblData)) {
        setTimeout(()=>{                           
          this.formService.hideFormMsg("pre-approved-subdivision-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
    }
  }
  
}
