import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { SecurityService } from 'src/app/services/security.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';

@Component({
    selector: 'generate-reports',
    templateUrl: './generate-reports.component.html',
    styleUrls: ['./generate-reports.component.css']
})
export class GenerateReportsComponent implements OnInit {

    constructor(
        private formBuilder: FormBuilder,
        private formService: FormService,
        private apiCallService: APICallService,
        private jsonDataService: JsonDataService,
        private matDialog: MatDialog,
        private userDetailService: UserDetailsService,
        private router: Router,
        private securityService: SecurityService
    ) { }

    datePipe = new DatePipe('en-us');

    reportParams: FormGroup = this.formBuilder.group({
        lineCd: ['', Validators.required],
        sublineCd: ["ALL", Validators.required],
        reportType: [Validators.required],
        extractBy: ['', Validators.required],
        dateFrom: ['', Validators.required],
        dateTo: ['', Validators.required],
        format: ['', Validators.required],
        userId: [this.userDetailService.userId]
    })

    hasLineCd: boolean = true;

    filteredSublineOptions: any = [{ lineCd: "", sublineCd: "", sublineName: "" }];

    lines: any = [];

    sublines: any = [{
        lineCd: "ALL", sublineCd: "ALL", sublineName: "ALL"
    }];

    //fix later using json data service
    reportTypes: any = []

    extractMethod: any = [{
        methodId: 1, methodName: "Effective Date"
    }, {
        methodId: 2, methodName: "Issue Date"
    }];

    formats: any = [
        {name: "PDF", id: "pdf"},
        {name: "Excel", id: "excel"}
    ]

    ngOnInit(): void {
        try {
            this.jsonDataService.data.lines.forEach((lines: any) => {
                this.lines.push(lines);
            });
            this.jsonDataService.data.sublines.forEach((sublines: any) => {
                this.sublines.push(sublines);
            });
            this.jsonDataService.data.reports.filter((e: any) => e.reportGroup == "G")
                .forEach((reportType: any) => {
                    this.reportTypes.push(reportType);
                }
            );
        }
        catch (e){

        }
    }

    getSublines() {
        try {
            this.filteredSublineOptions = this.sublines.filter((e: any) => e.lineCd == this.reportParams.value.lineCd || e.lineCd == "ALL");
            this.hasLineCd = false;
            this.reportParams.patchValue({ sublineCd: "ALL" });
        }
        catch (e){

        }
    };

    evaluateFields() {
        try {
            if (this.reportParams.get('reportType')?.value=='BMR004' || 
                this.reportParams.get('reportType')?.value=='BMR005' || 
                this.reportParams.get('reportType')?.value=='BMR009' ||
                this.reportParams.get('reportType')?.value=='BMR013' ||
                this.reportParams.get('reportType')?.value=='BMR014') 
            {
                this.reportParams.get('lineCd')?.enable();
                this.reportParams.get('sublineCd')?.enable();
            }
            else if (this.reportParams.get('reportType')?.value=='BMR012') 
            {
                this.reportParams.patchValue({ 
                    lineCd: null,
                    sublineCd: null
                });
                this.filteredSublineOptions = this.sublines.filter((e: any) => e.lineCd == "");
                this.reportParams.get('lineCd')?.disable();
                this.reportParams.get('sublineCd')?.disable();
            }
        }
        catch (e) {
            console.error(e);
        }
    }

    validateParams() {
        try {
            this.formService.hideFormMsg("generate-report-form-msg");
            if(this.reportParams.valid) {
                //remove this if pdf function is ready
                // if(this.reportParams.value.format != "pdf") {
                this.formService.showFormLoader(null, "generate-report-form", "Validating parameters.<br>Please wait ...", null, null)
                let data = this.transformData(this.reportParams.value);
                ///*
                this.apiCallService.generateITReports(data, "validate").subscribe((response: any) => {
                    this.securityService.checkRequestKeyResponse(response, () => {
                        this.securityService.hasValidCsrfToken(response, () => {
                            if(response.status == "SUCCESS") {
                                if(response.rowCount > 0) {
                                    this.formService.hideFormLoader("generate-report-form");
                                    this.printReport();
                                } else {
                                    this.formService.hideFormLoader("generate-report-form");
                                    this.showNoRecords();
                                }
                            } else {
                                this.formService.hideFormLoader("generate-report-form");
                                this.formService.showFormMsg("generate-report-form-msg", response.message, "E");
                            }
                        });
                    });
                }); 
                //*/
                //remove this if pdf function is ready
                // } else {
                //     this.formService.showFormMsg("generate-report-form-msg", "This feature is not yet available.", "E");
                // }
            } else {
                this.formService.showFormMsg("generate-report-form-msg", "Please fill all the required fields.", "E");
            }
        }
        catch (e) {
            console.error(e);
        }
    }

    printReport() {
        try {
            this.formService.showFormLoader(null, "generate-report-form", "Generating report.<br>Please wait ...", null, null)
            let data = this.transformData(this.reportParams.value);
            this.apiCallService.generateReports(data, this.reportParams.value.format).subscribe((res: any) => {
                if(res.status == "SUCCESS") {
                    if (this.reportParams.value.format.toUpperCase() === 'PDF') {
                        let response = JSON.parse(this.jsonDataService.decrypt(res.content));
                        if (response.userId.toUpperCase() === this.userDetailService.userId.toUpperCase()) {
                            let link = response.link.split("/");
                            link[3] = encodeURIComponent(link[3]);
                            link = link.join("/");
                            this.formService.hideFormLoader("generate-report-form");
                            window.open("https://" + link);
                        } else {
                            this.formService.hideFormLoader("generate-report-form");
                            this.apiCallService.logout().subscribe();
                            this.router.navigate(["home/login"]);
                        }
                    } else {
                        let response = JSON.parse(this.jsonDataService.decrypt(res.response));
                        this.securityService.checkRequestKeyResponse(res, () => {
                            this.securityService.hasValidCsrfToken(res, () => {
                                this.formService.hideFormLoader("generate-report-form");
                                window.open("https://" + response.link);
                            });
                        });
                    }
                } else {
                    this.formService.hideFormLoader("generate-report-form");
                    this.formService.showFormMsg("generate-report-form-msg", res.message, "E");
                }
            });
        }
        catch (e) {
            console.error(e);
        }
    }

    transformData(data: any) {
        try {
            let transformedData = {
                lineCd: data.lineCd,
                sublineCd: data.sublineCd,
                reportType: data.reportType,
                reportName: this.reportTypes.filter((types: any) => types.reportId == data.reportType)[0].reportName,
                extractBy: data.extractBy,
                dateFrom: this.datePipe.transform(data.dateFrom, 'MM/dd/yyyy'),
                dateTo: this.datePipe.transform(data.dateTo, 'MM/dd/yyyy'),
                dateNow: this.datePipe.transform(new Date(), 'MM/dd/yyyy'),
                printingUser: this.userDetailService.userId
                //printingUser: this.jsonDataService.retrieveFromStorage('userId')
            };
            return transformedData;
        }
        catch (e) {
            console.error(e);
            return '';
        }
    }

    showNoRecords() {
        try {
            const dialogRole = this.matDialog.open(DialogMsgComponent, {
                disableClose: true,
                width: "500px",
                data: {
                    title: 'ERROR',
                    content: 'No records have been retrieved with the given parameters.'
                }
            });
        }
        catch (e) {
            console.error(e);
        }
    }
}
