import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { CustomValidators } from 'src/app/utils/custom-validator';

@Component({
  selector: 'project-maintenance',
  templateUrl: './project-maintenance.component.html',
  styleUrls: ['./project-maintenance.component.css']
})
export class ProjectMaintenanceComponent implements OnInit, LeaveConfirmation {

  moduleId: string = 'BMM059';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc?.toUpperCase() ?? "";

  constructor(
    public _formService: FormService,
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    private _jDS: JsonDataService,
    private _securityService: SecurityService,
    private _userDetailService: UserDetailsService,
    private _appMessageService: AppMessageService
  ) {
    this.userIdLoggedIn = this._jDS.retrieveFromStorage('userId') || "";
  }

  ngOnInit(): void {
    this.getData.project();
  }

  canDeactivate(): boolean | Observable<boolean> {
    return this.projectTblConfig.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true)).length > 0 ? false : true;
  }

  /* Boolean Stuffs */

  public btns = {
    project: {
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      addDisabled: true,
    },
  }

  public showForm: boolean = true;

  /* Data Stuffs */

  private rowData: any;
  private tempDataRow: any;
  private errorMessage: string = "";
  private userIdLoggedIn: string;

  public forms = {
    projectForm: this.fb.group({
      projectCd: [null],
      projectCdDisp: [{ value: '', disabled: 'true' }],
      active: ['A', [Validators.required]],
      projectDesc: [null, [Validators.required, Validators.maxLength(30), this.checkDuplicateProjectDesc(), CustomValidators.requiredTrim]],
      bmCode: [null, [Validators.required, Validators.maxLength(8), CustomValidators.requiredTrim, this.checkDuplicateBmCode()]],
      bmShortDesc: [null, [Validators.maxLength(10)]],
      remarks: [null, [Validators.maxLength(4000)]],
      lastUpdateUser: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }],
    }),
    resetProjectForm: () => {
      this.forms.projectForm.reset();
      this.forms.projectForm.patchValue({
        active: 'A'
      });
      this.btns.project.updateDisabled = this.btns.project.deleteDisabled = true;
      this._formService.hideFormMsg("project-error-message");
      // this.rowData = null;
    }
  }

  private projectFormManipulate = {
    dbToForm: (data: any) => {
      return {
        projectCd: data.PROJECT_CD,
        projectCdDisp: data.PROJECT_CD ? data.PROJECT_CD.toString().padStart(2, 0) : null,
        projectDesc: data.PROJECT_DESC,
        bmCode: data.BM_CODE,
        bmShortDesc: data.BM_SHORT_DESC,
        active: data.ACTIVE_TAG,
        remarks: data.REMARKS,
        lastUpdateUser: data.USER_ID,
        lastUpdate: data.LAST_UPDATE,
      };
    },
    formToDb: (data: any) => {
      return {
        PROJECT_CD: data.projectCd,
        PROJECT_DESC: data.projectDesc.trim(),
        BM_CODE: data.bmCode,
        BM_SHORT_DESC: data.bmShortDesc,
        ACTIVE_TAG: data.active,
        REMARKS: data.remarks,
        USER_ID: null,
        LAST_UPDATE: null,
      };
    },
  }

  public selections = {
    activeTypes: [
      { cd: 'A', name: 'Yes' },
      { cd: 'I', name: 'No' },
    ],
  }

  public projectTblConfig = {
    cols: [
      {
        key: "PROJECT_CD",
        header: "Project Code",
        dataType: "string",
        width: "128px"
      },
      {
        key: "PROJECT_DESC",
        header: "Project Description",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "Active",
        dataType: "checkbox",
        width: "64px"
      }
    ],
    tblData: [] as any[],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  private getData = {
    project: () => {
      try {
        this.projectTblConfig.tblData = [];
        this.projectTblConfig.loading = true;
        this._jDS.contorlLoading(true);
        this._api.getProject({
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          type: "MODULE"
        }).subscribe({
          next: (response: any) => {
            this._securityService.checkRequestKeyResponse(response, () => {
              this._securityService.hasValidCsrfToken(response, () => {
                if (response.status === 'SUCCESS') {
                  let content = JSON.parse(this._jDS.decrypt(response.response));
                  this.projectTblConfig.tblData = content.data;
                  this.btns.project.addDisabled = false;
                }
                else {
                  this._appMessageService.showAppMessage(response.message, "error");
                }
                this._jDS.contorlLoading(false);
                this.projectTblConfig.loading = false;
              });
            });
          },
          error: (error: any) => {
            this.projectTblConfig.loading = false;
            this._jDS.contorlLoading(false);
            this._appMessageService.showAppMessage(error, "error");
          }
        });
      }
      catch (e) {
    console.error(e);

      }
    },
  }

  public onRowClick(ev: any) {
    try {
      ev.update = true;
      this.rowData = ev;
      this.populateFormFromTable();
    }
    catch (e) {
    console.error(e);
      this.forms.resetProjectForm();
      this.rowData = null;
      //this.indexRow = null;
    }
  }

  private populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.project.updateDisabled = !data.update;
      this.btns.project.deleteDisabled = !data.temp;
      this.tempDataRow = !data.temp;
      this.forms.projectForm.patchValue(this.projectFormManipulate.dbToForm(data));
    }
    catch (e) {
    console.error(e);

    }
  }

  public onAdd() {
    try {
      this._formService.hideFormMsg("project-error-message");
      if (this.forms.projectForm.valid) {
        let rowToBeAdded: { [key: string]: any } = this.projectFormManipulate.formToDb(this.forms.projectForm.value);
        rowToBeAdded.temp = true;
        this.projectTblConfig.tblData = [rowToBeAdded, ...this.projectTblConfig.tblData];
        this.forms.resetProjectForm();
        this.btns.project.saveDisabled = false;
      }
      else {
        this.showErrorValidator.project();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onDelete() {
    try {
      this.projectTblConfig.tblData = this.projectTblConfig.tblData.filter((d: any) => {
        return d !== this.rowData;
      });
      this.forms.resetProjectForm();
    }
    catch (e) {
    console.error(e);

    }
  }

  public onUpdate() {
    try {
      this._formService.hideFormMsg("project-error-message");
      if (this.forms.projectForm.valid) {
        let toBeUpdatedIndex = this.projectTblConfig.tblData.indexOf(this.rowData);
        let projectCdRowValue = this.rowData.PROJECT_CD;
        let rowToBeUpdated: { [key: string]: any } = this.projectFormManipulate.formToDb(this.forms.projectForm.value);
        if (this.rowData.temp) {
          rowToBeUpdated.temp = true;
        }
        else {
          rowToBeUpdated.onDbButUpdatedTemp = true;
          rowToBeUpdated.PROJECT_CD = projectCdRowValue;
        }
        this.forms.resetProjectForm();
        this.projectTblConfig.tblData[toBeUpdatedIndex] = rowToBeUpdated;
        this.btns.project.saveDisabled = false;
        this.projectTblConfig.tblData = [... this.projectTblConfig.tblData];
      }
      else {
        this.showErrorValidator.project();
      }
    }
    catch (e) {
    console.error(e);

    }
  }

  public onSave() {
    try {
      this.showForm = false;
      this._formService.showFormLoader(null, "project-maintenance-loading", "Saving.<br>Please wait ...", null, null, true);
      let toBeSaved = this.projectTblConfig.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true));
      if (toBeSaved.length > 0) {
        toBeSaved.map((d: any) => {
          d.USER_ID = this.userIdLoggedIn;
        });
        this._jDS.contorlLoading(true);
        this._api.saveProject(toBeSaved).subscribe({
          next: (response: any) => {
            this._securityService.checkRequestKeyResponse(response, () => {
              this._securityService.hasValidCsrfToken(response, () => {
                this._jDS.contorlLoading(false);
                if (response.status === 'SUCCESS') this.onComplete.project();
                else this.onComplete.projectError();
              });
            });
          },
          error: (data: any) => {

            this._jDS.contorlLoading(false);
            this.onComplete.projectError();
          },
        });
      }
      else
        this.onComplete.project();
      // setTimeout(() => {
      //   this.onComplete.project();
      // }, 3000);
    }
    catch (e) {
    console.error(e);

    }
  }

  private onComplete = {
    project: () => {
      this.getData.project();
      this.btns.project.saveDisabled = true;
      this.showForm = true;
      this._formService.hideFormLoader("project-maintenance-loading");
      this.openDialog('SUCCESS', 'Project', 'saved');
    },
    projectError: () => {
      this.showForm = true;
      this.btns.project.saveDisabled = false;
      this.openDialog('FAILED', 'Project', 'saving');
      this._formService.hideFormLoader("project-maintenance-loading");
    },
  }

  openDialog(title: string, contentTitle: string, contentDetail: string) {
    try {
      this._matDialog.open(DialogMsgComponent, {
        disableClose: true,
        width: '512px',
        data: {
          title: title,
          content:
            title === 'SUCCESS'
              ? `${contentTitle} details successfully ${contentDetail}!`
              : `${contentTitle} details ${contentDetail} failed!`,
        },
      });
    }
    catch (e) {
    console.error(e);

    }
  }

  /* Validator Stuffs Down here */

  checkDuplicateProjectDesc(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.trim()?.toUpperCase();
      if (!value) return null;
      let hasDuplicate: boolean = this.projectTblConfig.tblData.filter((d: any) => {
        if (!this.rowData)
          return d.PROJECT_DESC.trim().toUpperCase() === value;
        else
          return this.rowData.PROJECT_DESC.trim().toUpperCase() !== value && d.PROJECT_DESC.trim().toUpperCase() === value;
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupeProjectDesc: true } : null;
    }
  }

  checkDuplicateBmCode(): ValidatorFn {
    const trimLeadingZeros = (str: string) => str?.replace(/^0+/, '');
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value?.trim()?.toUpperCase();
      if (!value) return null;
      let hasDuplicate: boolean = this.projectTblConfig.tblData.filter((d: any) => {
        if (!this.rowData)
          return trimLeadingZeros(d.BM_CODE)?.trim().toUpperCase() === trimLeadingZeros(value);
        else
          return this.rowData.BM_CODE?.trim().toUpperCase() !== value && trimLeadingZeros(d.BM_CODE)?.trim().toUpperCase() === trimLeadingZeros(value);
      }).length > 0 ? true : false;
      return hasDuplicate ? { hasDupeBmCode: true } : null;
    }
  }

  public showErrorValidator = {
    project: () => {
      try {
        Object.keys(this.forms.projectForm.controls).forEach(key => {
          const controlErrors = this.forms.projectForm.get(key)?.errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              if (keyError == 'hasDupeProjectDesc' && controlErrors[keyError] === true)
                this.errorMessage = "Project Description already exists. Please add a unique Project Description.";
              if (keyError == 'hasDupeBmCode' && controlErrors[keyError] === true)
                this.errorMessage = "BPIMS Code already exists. Please add a unique BPIMS Code.";
              else if (keyError == 'required' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
            });
          }
        });
        this._formService.showFormMsg("project-error-message", this.errorMessage, "E");
      }
      catch (e) {
    console.error(e);

      }
    },
  }

}
