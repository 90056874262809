import { ElementRef, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { JsonDataService } from '../../../../services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
//xlsx
import * as XLSX from 'xlsx';

@Component({
	selector: 'upload-fmv',
	templateUrl: './upload-fmv.component.html',
	styleUrls: ['./upload-fmv.component.css']
})
export class UploadFmvComponent implements OnInit {

	constructor(
		private userDetailService: UserDetailsService,
		private apiCallService: APICallService,
		private formService: FormService,
		private formBuilder: FormBuilder,
		private jds: JsonDataService,
		private ss: SecurityService
	) { }

	ngOnInit(): void {
		this.retrievalParams.userId = this.userDetailService.userId || null
		this.getInitUploadData();
	}

	@ViewChild("excelFile")
	excelFile!: ElementRef;

	files: FormGroup = this.formBuilder.group({
		file: [File, Validators.required]
	})

	errorMessage: any = "No file selected. Please select a file first.";
	retrievalParams: any = {
		type: "",
		userId: ""
	};
	excelRows: any = [];

	addButtonState: boolean = true;
	saveButtonState: boolean = false;
	showErrorButtonState: boolean = false;
	showAllButtonState: boolean = false;

	dataTableConfig: any = {
		cols: [
		  {
			key: "makeTypeCode",
			header: "Make Type Code",
			dataType: "string",
			width: '150px'
		  },
		  {
			key: "modelYear",
			header: "Year Model",
			dataType: "string",
			disabled: false,
			width: '100px'
		  },
		  {
			key: "make",
			header: "Make",
			dataType: "string",
			disabled: false,
			width: '250px'
		  },
		  {
			key: "model",
			header: "Model",
			dataType: "string",
			disabled: false,
			width: '250px'
		  },
		  {
			key: "subModel",
			header: "Submodel",
			dataType: "string",
			disabled: false,
			width: '250px'
		  },
		  {
			key: "gasType",
			header: "Gas Type",
			dataType: "string",
			disabled: false,
			width: '100px'
		  },
		  {
			key: "latestFMV",
			header: "Fair Market Value",
			dataType: "string",
			disabled: false,
			width: '220px'
		  },
		  {
			key: "effDateFrom",
			header: "Eff Date^From",
			dataType: "date",
			disabled: false,
			width: '120px'
		  },
		  {
			key: "effDateTo",
			header: "Eff Date^To",
			dataType: "date",
			disabled: false,
			width: '120px'
		  },
		  {
			key: "status",
			header: "Status",
			dataType: "string",
			disabled: false,
			width: '270px'
		  }
		],
		tblData: [],
		selection: "single",
		paginator: true,
		rowsPerPage: 10,
		lazy: false,
		scrollable: true,
    	scrollableWidth: "100%",
		//tblCaption: "Vehicles",
		// totalRecords: 15
	  }

	excelLength: number = 0;
	lastUploadIndex: number = 0;
	newExcelRows: any[] = [];
	addBulkCount: number = 200;
	uploadBulkCount: number = 100;
	uploadResult: any[] = [];
	moduleId: string = 'BMM028';

	//RETRIEVAL FUNCTIONS

	getInitUploadData() {
		this.retrievalParams.type = "I";
		this.getFMVUploadData(this.retrievalParams)
	}

	getSuccessUploadData() {
		this.retrievalParams.type = "A";
		this.getFMVUploadData(this.retrievalParams)
	}

	getErrorUploadData() {
		this.retrievalParams.type = "E";
		this.getFMVUploadData(this.retrievalParams)
	}

	getFMVUploadData(params: any) {
		this.formService.showFormLoader(null, "upload-fmv-form", "Retrieving FMV Data.<br>Please wait...", null, null);
		this.apiCallService.getFMVUploadData({
			moduleId: this.moduleId,
			userId: this.userDetailService.userId,
			type: "MODULE",
			...params
		}).subscribe((response: any) => {
			this.ss.checkRequestKeyResponse(response, () => {
				this.formService.hideFormLoader("upload-fmv-form");
				this.dataTableConfig.tblData = JSON.parse(this.jds.decrypt(response.content));
			});
		});
	}

	//BUTTON FUNCTIONS

	addFMVData() {
		this.formService.hideFormMsg("uploadFMVFormMsg");
		if (!this.files.valid || this.files.value.file.name == "File") {
			this.formService.showFormMsg("uploadFMVFormMsg", "Please upload a valid file first.", "E");
		} else {
			this.processExcel(this.files.value.file);
			//Change this into asynchronous function later
			setTimeout(
				(e: any) => {
					if(this.errorMessage == "") {
						this.formService.showFormLoader(null, "upload-fmv-form", "Adding FMV Data.<br>Please wait...", null, null);
						this.apiCallService.addFMVUploadData(this.excelRows).subscribe((response: any) => {
							if (response.status == "SUCCESS") {
								this.formService.hideFormLoader("upload-fmv-form");
								this.addButtonState = false;
								this.saveButtonState = true;
								this.getSuccessUploadData();
							} else {
								this.formService.hideFormLoader("upload-fmv-form");
								this.formService.showFormMsg("uploadFMVFormMsg", this.errorMessage, "E");
							}
						})
					} else {
						this.formService.showFormMsg("uploadFMVFormMsg", this.errorMessage, "E");
					}
				}, 1000
			)
		}
	}

	validateFile() {
		this.formService.hideFormMsg("uploadFMVFormMsg");
		if (!this.files.valid || this.files.value.file.name == "File") {
			this.formService.showFormMsg("uploadFMVFormMsg", "Please upload a valid file first.", "E");
		} else {
			this.processExcel(this.files.value.file);
			this.proceedAdd();
		}
	}

	newAddExcelRows: any[] = [];
	lastAddIndex: number = 0;

	proceedAdd() {
		var batchCount = this.addBulkCount;
		var limit = (this.lastAddIndex + batchCount) > this.excelLength ? this.excelLength : (this.lastAddIndex + batchCount);
		this.newAddExcelRows = [];

		for(var i = this.lastAddIndex; i < limit; i++) {
			this.newAddExcelRows.push(this.excelRows[i]);
		}

		this.lastAddIndex = limit;
		this.newAddFMVData();
	}

	newAddFMVData() {
		setTimeout(
			(e: any) => {
				if(this.errorMessage == "") {
					this.formService.showFormLoader(null, "upload-fmv-form", "Adding FMV Data.<br>Please wait...", null, null);
					this.apiCallService.addFMVUploadData(this.newAddExcelRows).subscribe((response: any) => {
						this.ss.checkRequestKeyResponse(response, async () => {

							if(this.lastAddIndex !== this.excelLength) {
								this.proceedAdd();
							} else {
								this.lastAddIndex = 0;

								var x = JSON.parse(this.jds.decrypt(response.content));

								if (response.status == "SUCCESS") {
									this.formService.hideFormLoader("upload-fmv-form");
									this.addButtonState = false;
									this.saveButtonState = true;
									this.getSuccessUploadData();
								} else {
									this.formService.hideFormLoader("upload-fmv-form");
									this.formService.showFormMsg("uploadFMVFormMsg", this.errorMessage, "E");
								}
							}
						});
					});
				} else {
					this.formService.showFormMsg("uploadFMVFormMsg", this.errorMessage, "E");
				}
			}, 1000
		);
	}

	saveFMVData() {
		this.formService.hideFormMsg("uploadFMVFormMsg");
		this.formService.showFormLoader(null, "upload-fmv-form", "Saving FMV Data.<br>Please wait...", null, null);
		this.apiCallService.saveFMVUploadData(this.excelRows).subscribe((response: any) => {
			if (response.status == "SUCCESS") {
				this.formService.hideFormLoader("upload-fmv-form");
				this.saveButtonState = false;
				
				this.getSuccessUploadData();
			} else {
				this.formService.hideFormLoader("upload-fmv-form");
				this.formService.showFormMsg("uploadFMVFormMsg", response.message, "E")
			}

			this.showErrorButtonState = true;
		})
	}

	getErrorFMVData() {
		this.formService.hideFormMsg("uploadFMVFormMsg");
		this.getErrorUploadData();
		this.showAllButtonState = !this.showAllButtonState;
	}

	getAllFMVData() {
		this.getSuccessUploadData();
		this.showAllButtonState = !this.showAllButtonState;
	}

	//EXCEL FUNCTIONS

	resetFileField() {
		this.excelFile.nativeElement.value = "";
	}

	fileHandlerFunction(event: any) {
		this.formService.hideFormMsg("uploadFMVFormMsg")
		const excel: File = event.target.files[0];
        var regex = /^([a-zA-Z0-9\s_\\.\-:])+(.xls|.xlsx)$/;
		if (regex.test(excel.name)) {
            this.files.patchValue({
				file: excel
			})
        } else {
            this.formService.showFormMsg("uploadFMVFormMsg", "Please upload a valid Excel file.", "E");
			this.resetFileField();
			this.files.patchValue({
				file: ""
			})
        }
	}

	processExcel(data: File) {
		this.jds.contorlLoading(true);
		this.excelRows = [];
        const reader: FileReader = new FileReader();
		reader.readAsBinaryString(data);
		reader.onload = (e: any) => {
			const binarystr: string = e.target.result;
			const wb: XLSX.WorkBook = XLSX.read(binarystr, {type: 'binary'});

			const wsname: string = wb.SheetNames[0];
			const ws: XLSX.WorkSheet = wb.Sheets[wsname]

			const excelData: any = XLSX.utils.sheet_to_json(ws);

			let errorMessage = "No file selected. Please select a file first.";

			for (var i = 0; i < excelData.length; i++) {
				let excelRow: any = {};

				if (!excelData[i].MAKE_TYPE_CODE || 
                    !excelData[i].YEAR_MODEL || 
                    !excelData[i].MAKE || 
                    //!excelData[i].FAIR_MARKET_VALUE ||
                    !excelData[i].EFF_DATE_FROM ||
                    !excelData[i].EFF_DATE_TO) 
                {
                    errorMessage = "Excel format is invalid. There might be missing columns or values. Kindly check your file.";
					this.excelRows =[];
                    break;
                }
    
                if (
                    isNaN(excelData[i].YEAR_MODEL) || 
                    isNaN(excelData[i].FAIR_MARKET_VALUE) ||
                    !this.isValidStringDate(this.dateToDateString(this.serialNumberToDate(excelData[i].EFF_DATE_FROM))) ||
                    !this.isValidStringDate(this.dateToDateString(this.serialNumberToDate(excelData[i].EFF_DATE_TO)))) 
                {
					errorMessage = "Excel data is invalid. Some data are not allowed. Kindly check your file.";
					this.excelRows =[];
                    break;
                }
    
                excelRow.makeTypeCode = excelData[i].MAKE_TYPE_CODE;
                excelRow.modelYear = excelData[i].YEAR_MODEL;
                excelRow.make = excelData[i].MAKE;
                excelRow.model = excelData[i].MODEL || '';
                excelRow.subModel = excelData[i].SUB_MODEL || '';
                excelRow.gasType = excelData[i].GAS_TYPE || '';
                excelRow.latestFMV = excelData[i].FAIR_MARKET_VALUE || 0;
                excelRow.effDateFrom = this.dateToDateString(this.serialNumberToDate(excelData[i].EFF_DATE_FROM));
                excelRow.effDateTo = this.dateToDateString(this.serialNumberToDate(excelData[i].EFF_DATE_TO));
                excelRow.status = null;
				excelRow.userId = this.retrievalParams.userId;

				this.excelRows.push(excelRow);

				errorMessage = "";
			}

			this.errorMessage = errorMessage;
			this.jds.contorlLoading(false);

			this.excelLength = this.excelRows.length;
		}
	}

	proceedUpload() {
		var batchCount = this.uploadBulkCount;
		var limit = (this.lastUploadIndex + batchCount) > this.excelLength ? this.excelLength : (this.lastUploadIndex + batchCount);
		this.newExcelRows = [];

		for(var i = this.lastUploadIndex; i < limit; i++) {
			this.newExcelRows.push(this.excelRows[i]);
		}

		this.lastUploadIndex = limit;
		this.newSaveFMVData();
	}

	newSaveFMVData() {
		this.formService.hideFormMsg("uploadFMVFormMsg");
		this.formService.showFormLoader(null, "upload-fmv-form", "Saving FMV Data.<br>Please wait...", null, null);
		this.apiCallService.saveFMVUploadData(this.newExcelRows).subscribe((response: any) => {
			this.ss.checkRequestKeyResponse(response, async () => {

				if(this.lastUploadIndex !== this.excelLength) {
					this.proceedUpload();
				} else {
					this.lastUploadIndex = 0;
					var x = JSON.parse(this.jds.decrypt(response.content));

					this.formService.hideFormLoader("upload-fmv-form");
					this.formService.showFormMsg("uploadFMVFormMsg", x.message, "E");
					this.getSuccessUploadData();
				}

				this.showErrorButtonState = true;
			});
		});
	}

	//MISCELLANEOUS

	serialNumberToDate(serial: number) {
		// Converts Serial Number from excel to Date
		var utcDays  = Math.floor(serial - 25569);
        var utcValue = utcDays * 86400;                                        
        var dateInfo = new Date(utcValue * 1000);
    
        var fractionalDay = serial - Math.floor(serial) + 0.0000001;
    
        var totalSeconds = Math.floor(86400 * fractionalDay);
    
        var seconds = totalSeconds % 60;
    
        totalSeconds -= seconds;
    
        var hours = Math.floor(totalSeconds / (60 * 60));
        var minutes = Math.floor(totalSeconds / 60) % 60;
    
        return new Date(dateInfo.getFullYear(), dateInfo.getMonth(), dateInfo.getDate(), hours, minutes, seconds);
	}

	dateToDateString(date: Date) {
		// Returns date string with MM/DD/YYYY format
        let newDate = ((date.getMonth() + 1).toString().padStart(2, '0') + '/' + date.getDate().toString().padStart(2, '0') + '/' + date.getFullYear());
        return newDate;
	}

	isValidStringDate(dateString: string) {
		// Accepted format is MM/DD/YYYY

		// First check for the pattern
        if(!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString))
            return false;
    
        // Parse the date parts to integers
        var parts = dateString.split("/");
        var day = parseInt(parts[1], 10);
        var month = parseInt(parts[0], 10);
        var year = parseInt(parts[2], 10);
    
        // Check the ranges of month and year
        if(year < 1000 || year > 3000 || month == 0 || month > 12)
            return false;
    
        var monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];
    
        // Adjust for leap years
        if(year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
            monthLength[1] = 29;
    
        // Check the range of the day
        return day > 0 && day <= monthLength[month - 1];
	}
}
