import { Component, Inject, OnInit } from '@angular/core';
import { APICallService } from 'src/app/services/api-call.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { AppMessageService } from 'src/app/services/app-message.service';

@Component({
  selector: 'construction-lov',
  templateUrl: './construction-lov.component.html',
  styleUrls: ['./construction-lov.component.css']
})
export class ConstructionLovComponent {

  moduleId: string = 'BMM125';
  private selectedData: any;
  public titleLov: string = "CONSTRUCTION";

  table: any = {
    cols: [
      {
        key: "bmCode",
        header: "Construction Code",
        dataType: "string",
        width: '40%'
      },
      {
        key: "constructionDesc",
        header: "Construction",
        dataType: "string"
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  };

  constructor(
    private jsonDataService: JsonDataService,
    private dialogRef: MatDialogRef<ConstructionLovComponent>,
    private _api: APICallService,
    private securityService: SecurityService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    @Inject(MAT_DIALOG_DATA) public fromMainData: any,
  ) { 
    this.getData();
  }

  onRowClick(ev: any) {
    try {
      this.selectedData = ev;
    } catch (e) {
    console.error(e);
      
    }
  }

  private getData(): void {
    try {
      this.table.loading = true;
      this.jsonDataService.contorlLoading(true);
      this._api.getConstructionType({
        moduleId: this.fromMainData.moduleId,
        userId: this.userDetailService.userId,
        type: "LOV"
      }).subscribe({
        next: (data: any) => {
          this.securityService.checkRequestKeyResponse(data, () => {
            this.securityService.hasValidCsrfToken(data, ()=>{
              let content = JSON.parse(this.jsonDataService.decrypt(data.response));
              if (content.moduleId === this.moduleId) {
                this.jsonDataService.contorlLoading(false);
                this.table.tblData = content.data.filter((d: any) =>{
                  if(d.ACTIVE_TAG === 'A'){
                    d.constructionCd = d.CONSTRUCTION_CD;
                    d.bmCode = d.BM_CODE;
                    d.constructionDesc = d.CONSTRUCTION_DESC;
                    return true;
                  }else{
                    return false;
                  }
                });
                this.table.loading = false;
              } else {
                this.table.loading = false;
                this.jsonDataService.contorlLoading(false);
                this.appMessageService.showAppMessage("You do not have the correct privilege to access the data. Kindly contact your administrator for assistance.", "error");
              }
            });
          });
        },
        error: (e: any) => {
          this.securityService.checkRequestKeyResponse(e, () => {
            
            this.table.loading = false;
            this.jsonDataService.contorlLoading(false);
            this.appMessageService.showAppMessage(e, "error");
          });
        }
      });
    }
    catch (e) {
    console.error(e);
      
      this.table.loading = false;
      this.jsonDataService.contorlLoading(false);
    }
  }

  onClose(str: string) {
    try {
      if (this.selectedData == '' || this.selectedData == null) {
        this.dialogRef.close({ content: '', button: str });
      }
      else {
        this.dialogRef.close({ content: this.selectedData, button: str });
      }
    } catch (e) {
    console.error(e);
      
    }
  }

}
