<section id="property-details">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <osp-table [tblConfig]="propertyDetailsTable" (onRowClick)="onRowClick($event)" [selectedRow]="selectedRow"></osp-table>

        <!-- <div id="property-details-form"><div class="form-loader"></div></div> -->

        <div class="form-content" [hidden]="formHider">

            <form [formGroup]="propertyDetailsForm" class="form">
                <div id="property-details-error-message"></div>
                <div class="form-container-grid">
                    
                    <div class="field required">
                        <label>Property DTL ID</label>
                    </div>
                    <div class="">
                        <input formControlName="PROPERTY_DTL_ID">
                    </div>

                    <div class="field required">
                        <label>Type</label>
                    </div>
                    <div class="">
                        <select formControlName="PROPERTY_DTL_TYPE">
                            <option *ngFor="let type of propertyDetailType"
                                    [value]="type.rvLowValue">
                                {{ type.rvLowValue + " - " + type.rvMeaning }}
                            </option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>Description</label>
                    </div>
                    <div class="span">
                        <input formControlName="PROPERTY_DTL_DESC" maxlength="500">
                    </div>

                    <div class="field required">
                        <label>Other Description Tag</label>
                    </div>
                    <div class="">
                        <select formControlName="OTHERS_DESC_TAG">
                            <option *ngFor="let other of othersDescTag"
                                    [value]="other.rvLowValue">
                                {{ other.rvLowValue + " - " + other.rvMeaning }}
                            </option>
                        </select>
                    </div>

                    <div class="field required">
                        <label>Class</label>
                    </div>
                    <div class="">
                        <input formControlName="CLASS_CD" readonly>
                        <button class="btn-search btn" (click)="openClassDialog()">
                            <mat-icon>
                                search
                            </mat-icon>
                        </button>
                    </div>

                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="ACTIVE_TAG">
                            <option value="A">Yes</option>
                            <option value="I">No</option>
                        </select>
                    </div>

                    <div class="field">
                        <label>Sequence No</label>
                    </div>
                    <div class="">
                        <input formControlName="SEQ_NO" numbersOnly>
                    </div>

                    <div class="field">
                        <label>Description in Filipino</label>
                    </div>
                    <div class="span">
                        <input formControlName="DESCRIPTION_FIL" maxlength="500">
                    </div>

                    <div class="field">
                        <label>Description in Taglish</label>
                    </div>
                    <div class="span">
                        <input formControlName="DESCRIPTION_TAGLISH" maxlength="500">
                    </div>

                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="REMARKS" maxlength="4000">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input formControlName="USER_ID">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input formControlName="LAST_UPDATE">
                    </div>
                </div>
            </form>

            <div class="btn-grp">
                <button type="button" class="btn btn2" [hidden]="!addUpdateFlag" (click)="addRow()">ADD</button>
                <button type="button" class="btn btn2" [hidden]="addUpdateFlag" (click)="onUpdate()">UPDATE</button>
                <button type="button" class="btn btn2" [disabled]="deleteDisabler" (click)="deleteRow()">DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-grp">
                <button type="button" class="btn btn2" [disabled]="saveDisabler" (click)="onSave()">SAVE</button>
            </div>
        </div>
    </div>
</section>