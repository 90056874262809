<section id="effective-date-basis">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{this.moduleName}}</p>
        </div>
        <div class="form-content">
            <form [formGroup]="paramForm" class="form">
                <div class="form-container-grid top-form">
                    <div class="field required">
                        <label>User Type</label>
                    </div>
                    <div class="lov-search">
                        <input readonly formControlName="userTypeField">
                        <button matSuffix class="btn-search btn" type="button" (click)="openUserTypeDialog()"><mat-icon>search</mat-icon></button>
                    </div>
                    <div class="field required">
                        <label>User Level</label>
                    </div>
                    <div class="lov-search">
                        <input readonly formControlName="userLevelField">
                        <button matSuffix class="btn-search btn" type="button" (click)="openUserLevelDialog()"><mat-icon>search</mat-icon></button>
                    </div>
                    <div class="field required">
                        <label>Line</label>
                    </div>
                    <div class="lov-search">
                        <input readonly formControlName="lineField">
                        <button matSuffix class="btn-search btn" type="button" (click)="openLineDialog()"><mat-icon>search</mat-icon></button>
                    </div>
                    <div class="field required">
                        <label>Subline</label>
                    </div>
                    <div class="lov-search">
                        <input readonly formControlName="sublineField">
                        <button matSuffix class="btn-search btn" type="button" (click)="openSublineDialog()" [disabled]="sublineDisabled"><mat-icon>search</mat-icon></button>
                    </div>
                </div>
            </form>
        </div>
        <div class = 'table-line'>
            <osp-table [tblConfig]="effectiveDateBasisTblConfig" (onRowClick)="onRowClick($event)"></osp-table>
        </div>

        <!-- Loading Animation -->
        <div id="generate-line-form">
            <div class="form-loader"></div>
        </div>

        <!-- Container for Line Fields -->
        <div class="form-content">
            <form [formGroup]="effectiveDateBasisForm" class="form" *ngIf="noLoadingInProcess">
                <div id="effective-date-basis-error-message"></div>
                <div class="form-container-grid">
                    <div class="field required">
                        <label>Plan</label>
                    </div>
                    <div class="lov-search span">
                        <input readonly formControlName="planField">
                        <button matSuffix class="btn-search btn" type="button" (click)="openPlanDialog()" [disabled]="isLineSelected"><mat-icon>search</mat-icon></button>
                    </div>
                    <div class="field required">
                        <label>Allowable Date Range</label>
                    </div>
                    <div class="">
                        <input class="input-right-align" formControlName="allowableDateRange" type="text" maxlength="14" (input)="numOnlyPattern($event)" (focusout)="amtFormat('allowableDateRange')">
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag">
                            <option value="A" selected>Yes</option>
                            <option value="I">No</option>
                        </select>
                    </div>
                    <div class="field required">
                        <label>Subline Time Tag</label>
                    </div>
                    <div class="">
                        <select formControlName="sublineTimeTag">
                            <option *ngFor="let sublineTimeTag of sublineTimeTagList" [value]="sublineTimeTag.rvLowValue">{{sublineTimeTag.rvLowValue}} - {{sublineTimeTag.rvMeaning }}</option>
                        </select>
                    </div>
                    <div class="field">
                    </div>
                    <div class="">
                    </div>
                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" type="text" maxlength="4000">
                    </div>
                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="userID">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUserUpdate">
                    </div>
                </div>
                <div class='btn-row'>
                    <button type="button" id='btn-add' *ngIf="!isLineSelected" title='Register a new line' class="btn btn2" value='ADD' type="submit" [disabled]='addDisabled' (click)="insertLine()">ADD</button>
                    <button type="button" id='btn-update' *ngIf="isLineSelected" title='Update a line' class="btn btn2" value='UPDATE' type="submit" (click)="updateLine()">UPDATE</button>
                    <button type="button" id='btn-delete' title='Remove a line' class="btn btn2" value='DELETE' type="submit" [disabled]='deleteDisabled' (click)="deleteLine()">DELETE</button>
                </div> 

                <div class="mtn-title"></div>

                <div class='btn-row'>
                    <button type="button" id='btn-save' title='Save changes on line details' class="btn btn2" value='SAVE' type="submit" (click)="toSave()" [disabled]='saveDisabled'>SAVE</button>
                </div>
            </form>
        </div>
    </div>
</section>