import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { PlanLovComponent } from '../plan-lov/plan-lov.component';

@Component({
  selector: 'fire-item-code-lov',
  templateUrl: './fire-item-code-lov.component.html',
  styleUrls: ['./fire-item-code-lov.component.css']
})
export class FireItemCodeLovComponent implements OnInit {

  private selectedData: any;

  public tableSelected: any;

  public titleLov: string = "Item Code";

  moduleId: string = 'BMM107';

  table: any = {
      cols: [
          {
              key: "ITEM_CD",
              header: "Code",
              dataType: "string",
              width: '15%'
          },
          {
              key: "ITEM_LONG_DESC",
              header: "Line",
              dataType: "string",
              width: '85%'
          },
      ],
      tblData: [],
      selection: "single",
      paginator: true,
      rowsPerPage: 10,
      lazy: false,
      totalRecords: 15,
      loading: false,
  };

  constructor(
      private dialogRef: MatDialogRef<FireItemCodeLovComponent>,
      private _api: APICallService,
      private securityService: SecurityService,
      private jsonDataService: JsonDataService,
      private appMessageService: AppMessageService,
      private userDetailService: UserDetailsService,
      @Inject(MAT_DIALOG_DATA) public fromMainData: any,
  ) {
      try {
          this.tableSelected = this.table;
          this.getFireItemCode();
      } catch (e) {
    console.error(e);

      }
  }

  ngOnInit(): void {
  }

  onRowClick(ev: any) {
      try {
          this.selectedData = ev;
      } catch (e) {
    console.error(e);

      }
  }

  getFireItemCode() {
      try {
          this.table.loading = true;
          this.jsonDataService.contorlLoading(true);
          this._api.getFireItemCode({
              moduleId: this.fromMainData.moduleId,
              userId: this.userDetailService.userId,
              type: "LOV"
          }).subscribe({
              next: (response: any) => {
                  this.securityService.checkRequestKeyResponse(response, () => {
                      this.securityService.hasValidCsrfToken(response, () => {
                          let resContent = JSON.parse(this.jsonDataService.decrypt(response.response));
                          if (resContent.moduleId === this.moduleId) {
                              this.jsonDataService.contorlLoading(false);
                              this.fromMainData.itemCdExclude = (this.fromMainData?.itemCdExclude ?? []).map((e: any) => +e);
                              this.tableSelected.tblData = resContent.data.filter((d: any) => (d.ACTIVE_TAG === 'A' && !((this.fromMainData?.itemCdExclude ?? []).includes(+d.ITEM_CD))));
                              this.table.loading = false;
                          } else {
                              this.table.loading = false;
                              this.jsonDataService.contorlLoading(false);
                              this.appMessageService.showAppMessage("You do not have the correct privilege to access the data. Kindly contact your administrator for assistance.", "error");
                          }
                      });
                  });
              },
              error: (e: any) => {

                  this.table.loading = false;
                  this.jsonDataService.contorlLoading(false);
                  this.appMessageService.showAppMessage(e, "error");
              }
          });
      } catch (e) {
    console.error(e);

          this.table.loading = false;
          this.jsonDataService.contorlLoading(false);
      }
  }

  onClose(str: string) {
      try {
          if (this.selectedData == '' || this.selectedData == null) {
              this.dialogRef.close({ content: '', button: str });
          } else {
              this.dialogRef.close({ content: this.selectedData, button: str });
          }
      } catch (e) {
    console.error(e);

      }
  }
}
