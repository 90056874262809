import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { SecurityService } from 'src/app/services/security.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { JsonDataService } from 'src/app/services/json-data.service'; 

@Component({
    selector: 'generate-it-reports',
    templateUrl: './generate-it-reports.component.html',
    styleUrls: ['./generate-it-reports.component.css']
})
export class GenerateItReportsComponent implements OnInit {

    constructor(
        private formBuilder: FormBuilder,
        private apiCallService: APICallService,
        private formService: FormService,
        private matDialog: MatDialog,
        private userDetailService: UserDetailsService,
        private securityService: SecurityService,
        private router: Router,
        private jsonDataService: JsonDataService
    ) { }

    ngOnInit(): void {
        this.repTranTypes = this.jsonDataService.data.refCds.filter((e: any) => e.rvDomain=="REP_TRAN_TYPE");
    }

    datePipe = new DatePipe('en-us');

    reportParams: FormGroup = this.formBuilder.group({
        reportType: ['', Validators.required],
        transactionType: [[], Validators.required],
        dateFrom: ['', Validators.required],
        dateTo: ['', Validators.required],
        format: ['', Validators.required],
        userId: [this.userDetailService.userId]
    });

    reportTypes: any = [
        {name: "Audit Trail", id: "BMR007"},
    ];

    repTranTypes: any = [];

    transactionTypes: any = [
        // {name: "Assured", id: ""},
        {name: "BPI MS Users", id: "BMM044A"},
        {name: "FMV", id: "BMM028A"},
        {name: "Issue Source", id: "BMM022A"},
        {name: "Make", id: "BMM025A"},
        {name: "Model", id: "BMM026A"},
        {name: "Quotations", id: "BMT011A"},
        {name: "Sub-Model", id: "BMM027A"},
        {name: "System Parameter", id: "BMM011A"},
        {name: "Tax Charges", id: "BMM024A"},
        {name: "Taxes", id: "BMM031A"},
        // {name: "User Role and Access", id: "R11"},
        // {name: "Users", id: "R12"},
    ];

    formats: any = [
        {name: "PDF", id: "pdf"},
        {name: "Excel", id: "excel"}
    ];

    validateParams() {
        try {
            this.formService.hideFormMsg("generate-it-form-msg");
            if(this.reportParams.valid) {
                this.formService.showFormLoader(null, "generate-it-form", "Validating parameters.<br>Please wait ...", null, null)
                let data = this.transformData(this.reportParams.value);
                this.apiCallService.generateITReports(data, "validate").subscribe((response: any) => {
                    this.securityService.checkRequestKeyResponse(response, () => {
                        this.securityService.hasValidCsrfToken(response, () => {
                            if(response.status == "SUCCESS") {
                                if(response.rowCount > 0) {
                                    this.formService.hideFormLoader("generate-it-form");
                                    this.printReport();
                                } else {
                                    this.formService.hideFormLoader("generate-it-form");
                                    this.showNoRecords();
                                }
                            } else {
                                this.formService.hideFormLoader("generate-it-form");
                                this.formService.showFormMsg("generate-it-form-msg", response.message, "E");
                            }
                        });
                    });
                }); 
            } else {
                this.formService.showFormMsg("generate-it-form-msg", "Please fill all the required fields.", "E");
            }
        }
        catch (e) {
            console.error(e);
        }
    }

    printReport() {
        try { 
            this.formService.showFormLoader(null, "generate-it-form", "Generating report.<br>Please wait ...", null, null)
            let data = this.transformData(this.reportParams.value);
            this.apiCallService.generateITReports(data, this.reportParams.value.format).subscribe((res: any) => {
                if(res.status == "SUCCESS") {
                    if (this.reportParams.value.format.toUpperCase() === 'PDF') {
                        let response = JSON.parse(this.jsonDataService.decrypt(res.content));
                        if (response.userId.toUpperCase() === this.userDetailService.userId.toUpperCase()) {
                            let link = response.link.split("/");
                            link[3] = encodeURIComponent(link[3]);
                            link = link.join("/");
                            this.formService.hideFormLoader("generate-it-form");
                            window.open("https://" + link);
                        } else {
                            this.formService.hideFormLoader("generate-it-form");
                            this.apiCallService.logout().subscribe();
                            this.router.navigate(["home/login"]);
                        }
                    } else {
                        let response = JSON.parse(this.jsonDataService.decrypt(res.response));
                        this.securityService.checkRequestKeyResponse(res, () => {
                            this.securityService.hasValidCsrfToken(res, () => {
                                this.formService.hideFormLoader("generate-it-form");
                                window.open("https://" + response.link);
                            });
                        });
                    }
                } else {
                    this.formService.hideFormLoader("generate-it-form");
                    this.formService.showFormMsg("generate-it-form-msg", res.message, "E");
                }
            }); 
        }
        catch (e) {
            console.error(e);
        }
    }

    transformData(data: any) {
        try {
            let transformedData = {
                reportType: data.reportType,
                reportName: this.reportTypes.filter((types: any) => types.id = data.reportType)[0].name,
                transactionType: "\"" + data.transactionType.join("\", \"") + "\"",
                dateFrom: this.datePipe.transform(data.dateFrom, 'MM/dd/yyyy h:mm:ss a'),
                dateTo: this.datePipe.transform(data.dateTo, 'MM/dd/yyyy h:mm:ss a'),
                dateNow: this.datePipe.transform(new Date(), 'MM/dd/yyyy h:mm:ss a'),
                printingUser: this.userDetailService.userId
            };
            return transformedData;
        }
        catch (e) {
            console.error(e);
            return '';
        }
    }

    showNoRecords() {
        try {
            const dialogRole = this.matDialog.open(DialogMsgComponent, {
                disableClose: true,
                width: "500px",
                data: {
                    title: 'ERROR',
                    content: 'No records have been retrieved with the given parameters.'
                }
            });
        }
        catch (e) {
            console.error(e);
        }
    }
}
