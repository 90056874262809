import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';
import { PlanLovComponent } from '../../common/plan-lov/plan-lov.component';

@Component({
  selector: 'plan-amt-limits-maintenance',
  templateUrl: './plan-amt-limits-maintenance.component.html',
  styleUrls: ['./plan-amt-limits-maintenance.component.css']
})
export class PlanAmtLimitsMaintenanceComponent implements OnInit, LeaveConfirmation {

  moduleId: string = 'BMM181';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc?.toUpperCase() ?? "";

  constructor(
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    public _formService: FormService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService
  ) { 
    this.userIdLoggedIn = this._userDetailService.userId ?? "";
  }

  ngOnInit(): void {
    this.btns.planAmtLimits.addDisabled = true;
  }

  canDeactivate(): boolean | Observable<boolean> {
    if(this.tempData!='') {
      return false;
    } else {
      return true;
    }
  }

  /* Boolean Stuffs */

  public btns = {
    planAmtLimits: {
      addDisabled: true,
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      planDisabled: true
    },
    lov: {
      lineDisabled: false,
      sublineDisabled: true
    }
  }

  public selections = {
    activeTypes: [
      { cd: 'A', name: 'Yes' },
      { cd: 'I', name: 'No' },
    ]
  }

  public showForm: boolean = true;

  private rowData: any;
  private tempDataRow: any;
  private errorMessage: string = "";
  private userIdLoggedIn: string;
  private tempData: any = [];

  public forms = {
    lineSublineLovForm: this.fb.group({
      lineCd: [null],
      lineName: [null],
      lineCdDisp: [{ value: '', disabled: 'true' }],
      sublineCd: [null],
      sublineName: [null],
      sublineCdDisp: [{ value: '', disabled: 'true' }],
    }),
    planAmtLimitsForm: this.fb.group({
      lineCd: [null],
      sublineCd: [null],
      planCd: [null, [Validators.required]],
      planName: [null],
      planField: [{ value: '', disabled: 'true' }, [Validators.required]],
      minPremAmt: [null, [Validators.required]],
      siAmtMin: [null],
      siAmtMax: [null],
      remarks: [null],
      activeTag: ['A', [Validators.required]],
      userID: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }]
    }),
    resetPlanAmtLimitsForm: () => {
      this.forms.planAmtLimitsForm.reset();
      this.forms.planAmtLimitsForm.patchValue({
        activeTag: 'A',
        lineCd: this.forms.lineSublineLovForm.get('lineCd')?.value,
        sublineCd: this.forms.lineSublineLovForm.get('sublineCd')?.value
      });
      this.btns.planAmtLimits.updateDisabled = true;
      this.btns.planAmtLimits.deleteDisabled = true;
      this.btns.planAmtLimits.planDisabled = false;
      this._formService.hideFormMsg("plan-amt-limits-error-message");
      this.rowData = null;
    },
    resetLineSublineLovForm: () => {
      this.forms.lineSublineLovForm.reset();
    },
    resetAllForms: () => {
      this.forms.resetLineSublineLovForm();
      this.forms.resetPlanAmtLimitsForm();
    }
  }

  private planAmtLimitsFormManipulate = {
    dbToForm: (data: any) => {
      return {
        planCd: data.PLAN_CD,
        planCdDisp: data.PLAN_CD_DISP,
        planName: data.PLAN_NAME,
        planField: data.PLAN_CD?.toString().padStart(2, 0) + " - " + data.PLAN_NAME,
        minPremAmt: data.MIN_PREM_AMT==null ? null : this._formService.setToAmountFormat(data.MIN_PREM_AMT),
        siAmtMin: data.SI_AMT_MIN==null ? null : this._formService.setToAmountFormat(data.SI_AMT_MIN),
        siAmtMax: data.SI_AMT_MAX==null ? null : this._formService.setToAmountFormat(data.SI_AMT_MAX),
        activeTag: data.ACTIVE_TAG,
        remarks: data.REMARKS,
        userID: data.USER_ID,
        lastUpdate: data.LAST_UPDATE,
      };
    },
    formToDb: (data: any, mode: any) => {
      return {
        LINE_CD: data.lineCd,
        SUBLINE_CD: data.sublineCd,
        PLAN_CD: data.planCd,
        PLAN_CD_DISP: data.planCd?.toString().padStart(2, 0),
        PLAN_NAME: data.planName,
        PLAN_FIELD: data.planCd + " - " + data.planName,
        MIN_PREM_AMT: data.minPremAmt=='' || data.minPremAmt==null ? null : parseFloat(this._formService.setToNumberWithDecimal(data.minPremAmt)),
        SI_AMT_MIN: data.siAmtMin=='' || data.siAmtMin==null ? null : parseFloat(this._formService.setToNumberWithDecimal(data.siAmtMin)),
        SI_AMT_MAX: data.siAmtMax=='' || data.siAmtMax==null ? null : parseFloat(this._formService.setToNumberWithDecimal(data.siAmtMax)),
        ACTIVE_TAG: data.activeTag,
        REMARKS: data.remarks,
        USER_ID: this.userIdLoggedIn,
        LAST_UPDATE: null,
        MODE: mode
      };
    },
    formToTemp: (data: any, mode: any) => {
      return {
        LINE_CD: data.lineCd,
        SUBLINE_CD: data.sublineCd,
        PLAN_CD: data.planCd,
        PLAN_CD_DISP: data.planCd?.toString().padStart(2, 0),
        PLAN_NAME: data.planName,
        PLAN_FIELD: data.planCd + " - " + data.planName,
        MIN_PREM_AMT: data.minPremAmt=='' || data.minPremAmt==null ? null : parseFloat(this._formService.setToNumberWithDecimal(data.minPremAmt)).toFixed(2),
        SI_AMT_MIN: data.siAmtMin=='' || data.siAmtMin==null ? null : parseFloat(this._formService.setToNumberWithDecimal(data.siAmtMin)).toFixed(2),
        SI_AMT_MAX: data.siAmtMax=='' || data.siAmtMax==null ? null : parseFloat(this._formService.setToNumberWithDecimal(data.siAmtMax)).toFixed(2),
        ACTIVE_TAG: data.activeTag,
        REMARKS: data.remarks,
        USER_ID: this.userIdLoggedIn,
        LAST_UPDATE: null,
        MODE: mode
      };
    }
  }

  public planAmtLimitsTblConfig = {
    cols: [
      {
        key: "PLAN_CD_DISP",
        header: "Plan Code",
        dataType: "string",
        width: '12%'
      },
      {
        key: "PLAN_NAME",
        header: "Plan",
        dataType: "string",
        width: '20%'
      },
      {
        key: "MIN_PREM_AMT",
        header: "Minimum Premium Amount",
        dataType: "currency"
      },
      {
        key: "SI_AMT_MIN",
        header: "Minimum Sum Insured",
        dataType: "currency"
      },
      {
        key: "SI_AMT_MAX",
        header: "Maximum Sum Insured",
        dataType: "currency"
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        width: '64px'
      }
    ],
    tblData: [] as any[],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  public openLov = {
    line: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'LINE',
          moduleId: this.moduleId
        },
      }
      this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button === 'OK') {
          this.forms.resetPlanAmtLimitsForm();
          if (output) {
            this.forms.lineSublineLovForm.patchValue({
              lineCd: output.LINE_CD,
              lineName: output.LINE_NAME,
              lineCdDisp: `${output.LINE_CD} - ${output.LINE_NAME}`,
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null
            });
            this.forms.planAmtLimitsForm.patchValue({
              lineCd: output.LINE_CD,
              sublineCd: null
            });
            this.btns.lov.sublineDisabled = false;
            this.btns.planAmtLimits.addDisabled = true;
            this.tempData = [];
          }
          else {
            this.forms.lineSublineLovForm.patchValue({
              lineCd: null,
              lineName: null,
              lineCdDisp: null
            });
            this.forms.planAmtLimitsForm.patchValue({
              lineCd: output.LINE_CD,
              sublineCd: null
            });
            this.btns.lov.sublineDisabled = true;
            this.btns.planAmtLimits.addDisabled = true;
          }
          this.planAmtLimitsTblConfig.tblData = [];
          this.btns.planAmtLimits.saveDisabled = true;
          this.btns.planAmtLimits.planDisabled = true;
        }
      });
    },
    subline: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'SUBLINE',
          moduleId: this.moduleId
        },
      }
      LOVOPTIONS.data.lineCd = this.forms.lineSublineLovForm.get('lineCd')?.value;
      this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
        let output = dataOutput.content;
        if (output) {
          this.forms.resetPlanAmtLimitsForm();
          this.forms.lineSublineLovForm.patchValue({
            lineCd: output.LINE_CD,
            lineName: output.LINE_NAME,
            lineCdDisp: `${output.LINE_CD} - ${output.LINE_NAME}`,
            sublineCd: output.SUBLINE_CD,
            subLineName: output.SUBLINE_NAME,
            sublineCdDisp: `${output.SUBLINE_CD} - ${output.SUBLINE_NAME}`
          });
          this.forms.planAmtLimitsForm.patchValue({
            lineCd: output.LINE_CD,
            sublineCd: output.SUBLINE_CD
          });
          this.btns.planAmtLimits.addDisabled = false;
          this.btns.planAmtLimits.planDisabled = false;
          this.tempData = [];
        } 
        else {
          this.forms.lineSublineLovForm.patchValue({
            sublineCd: null,
            sublineName: null,
            sublineCdDisp: null
          });
          this.forms.planAmtLimitsForm.patchValue({
            lineCd: null,
            sublineCd: null
          });
          this.btns.planAmtLimits.addDisabled = true;
        }
        this.getData.planAmtLimits();
      });
    },
    plan: () => {
      let planCdExists: any[] = this.planAmtLimitsTblConfig.tblData.map((row: any) => { return +row.PLAN_CD; }).filter(Boolean);
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'PLAN',
          moduleId: this.moduleId,
          lineCd: this.forms.lineSublineLovForm.get('lineCd')?.value,
          sublineCd: this.forms.lineSublineLovForm.get('sublineCd')?.value,
          planCdExclude: planCdExists
        },
      };
      this._matDialog.open(PlanLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button?.toUpperCase() === 'OK') {
          if (output.PLAN_CD) {
            this.forms.planAmtLimitsForm.patchValue({
              planCd: output.PLAN_CD,
              planName: output.PLAN_NAME,
              planField: `${output.PLAN_CD?.toString().padStart(2, 0)} - ${output.PLAN_NAME}`
            });
          }
          else {
            this.forms.planAmtLimitsForm.patchValue({
              planCd: null,
              planName: null,
              planField: null
            });
          }
        }
      });
    },
  };

  private getData = {
    planAmtLimits: () => {
      try {
        this.planAmtLimitsTblConfig.tblData = [];
        this.planAmtLimitsTblConfig.loading = true;
        this._jDS.contorlLoading(true);
        this._api.getPlanAmtLimits({
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          type: "MODULE"
        }).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                let content = JSON.parse(this._jDS.decrypt(data.response));
                this._jDS.contorlLoading(false);
                this.planAmtLimitsTblConfig.tblData = content.data.filter((d: any) => 
                  d.LINE_CD == this.forms.lineSublineLovForm.get('lineCd')?.value 
                  && d.SUBLINE_CD == this.forms.lineSublineLovForm.get('sublineCd')?.value);
                this.btns.planAmtLimits.addDisabled = false;
                this.planAmtLimitsTblConfig.loading = false;
              });
            });
          },
          error: (error: any) => {
            this.planAmtLimitsTblConfig.loading = false;
            this._jDS.contorlLoading(false);
          }
        });
      }
      catch (e) {
        console.error(e);
      }
    }
  }

  onRowClick(ev: any) {
    try {
      ev.update = true;
      this.rowData = ev;
      this.populateFormFromTable();
    }
    catch (e) {
      this.forms.resetPlanAmtLimitsForm();
      this.rowData = null;
      //this.indexRow = null;
    }
  }

  populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.planAmtLimits.planDisabled = true;
      this.btns.planAmtLimits.updateDisabled = !data.update;
      this.btns.planAmtLimits.deleteDisabled = !data.temp;
      this.tempDataRow = !data.temp;
      this.forms.planAmtLimitsForm.patchValue(this.planAmtLimitsFormManipulate.dbToForm(data));
    }
    catch (e) {
      console.error(e);
    }
  }

  public onAdd() {
    try {
      this._formService.hideFormMsg("plan-amt-limits-error-message");
      if (!this.minPremAmtCheck()) {
        this._formService.showFormMsg("plan-amt-limits-error-message", "Invalid value in Minimum Premium Amount. The value entered exceeded the maximum limit.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("plan-amt-limits-error-message");
        }, 10000);
      }
      else if (!this.siAmtMinCheck()) {
        this._formService.showFormMsg("plan-amt-limits-error-message", "Invalid value in Minimum Sum Insured. The value entered exceeded the maximum limit.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("plan-amt-limits-error-message");
        }, 10000);
      }
      else if (!this.siAmtMaxCheck()) {
        this._formService.showFormMsg("plan-amt-limits-error-message", "Invalid value in Maximum Sum Insured. The value entered exceeded the maximum limit.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("plan-amt-limits-error-message");
        }, 10000);
      }
      else if (!this.siAmtMinAndMaxCheck()) {
        this._formService.showFormMsg("plan-amt-limits-error-message", "Maximum Sum Insured cannot be less than Minimum Sum Insured.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("plan-amt-limits-error-message");
        }, 10000);
      }
      else if (this.forms.planAmtLimitsForm.valid) {
        let rowToBeAdded: { [key: string]: any } = this.planAmtLimitsFormManipulate.formToDb(this.forms.planAmtLimitsForm.value, 'I');
        let rowToBeAddedTemp: { [key: string]: any } = this.planAmtLimitsFormManipulate.formToTemp(this.forms.planAmtLimitsForm.value, 'I');
        rowToBeAdded.temp = true;
        this.tempData.push(rowToBeAddedTemp);
        this.planAmtLimitsTblConfig.tblData = [rowToBeAdded, ...this.planAmtLimitsTblConfig.tblData];
        this.forms.resetPlanAmtLimitsForm();
        this.btns.planAmtLimits.saveDisabled = false;
      }
      else {
        this.showErrorValidator.planAmtLimits();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public onDelete() {
    try {
      let clickData = this.rowData;
      this.forms.planAmtLimitsForm.get('planField')?.enable();
      let rowToBeDeleted: { [key: string]: any } = this.planAmtLimitsFormManipulate.formToDb(this.forms.planAmtLimitsForm.value, 'D');
      let tempInsertChecker = this.tempData.find(
        (p: { PLAN_CD: any; }) => p.PLAN_CD == clickData.PLAN_CD && clickData.MODE == 'I');
      this.planAmtLimitsTblConfig.tblData = this.planAmtLimitsTblConfig.tblData.filter((d: any) => {
        return d !== this.rowData;
      });

      if (tempInsertChecker!=null && tempInsertChecker!='') {
        this.tempData = this.tempData.filter(
          function(e: any) {
            return e.PLAN_CD != clickData.PLAN_CD;
          }
        );
        if (this.tempData==null || this.tempData=='') {
          this.btns.planAmtLimits.saveDisabled = true;
        }
      }
      else {
        this.tempData.push(rowToBeDeleted);
        this.btns.planAmtLimits.saveDisabled = false;
      }
      this.forms.resetPlanAmtLimitsForm();
    }
    catch (e) {
      console.error(e);
    }
  }

  public onUpdate() {
    try {
      this._formService.hideFormMsg("plan-amt-limits-error-message");
      if (!this.minPremAmtCheck()) {
        this._formService.showFormMsg("plan-amt-limits-error-message", "Invalid value in Minimum Premium Amount. The value entered exceeded the maximum limit.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("plan-amt-limits-error-message");
        }, 10000);
      }
      else if (!this.siAmtMinCheck()) {
        this._formService.showFormMsg("plan-amt-limits-error-message", "Invalid value in Minimum Sum Insured. The value entered exceeded the maximum limit.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("plan-amt-limits-error-message");
        }, 10000);
      }
      else if (!this.siAmtMaxCheck()) {
        this._formService.showFormMsg("plan-amt-limits-error-message", "Invalid value in Maximum Sum Insured. The value entered exceeded the maximum limit.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("plan-amt-limits-error-message");
        }, 10000);
      }
      else if (!this.siAmtMinAndMaxCheck()) {
        this._formService.showFormMsg("plan-amt-limits-error-message", "Maximum Sum Insured cannot be less than Minimum Sum Insured.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("plan-amt-limits-error-message");
        }, 10000);
      }
      else if (this.forms.planAmtLimitsForm.valid) {
        this.forms.planAmtLimitsForm.get('planField')?.enable();
        let clickData = this.rowData;
        let toBeUpdatedIndex = this.planAmtLimitsTblConfig.tblData.indexOf(this.rowData);
        let planAmtLimitsCdRowValue = this.rowData.PLAN_CD;
        let rowToBeUpdated: { [key: string]: any } = this.planAmtLimitsFormManipulate.formToDb(this.forms.planAmtLimitsForm.value, 'U');
        let rowToBeUpdatedTemp: { [key: string]: any } = this.planAmtLimitsFormManipulate.formToTemp(this.forms.planAmtLimitsForm.value, 'U');
        this.forms.planAmtLimitsForm.get('planField')?.disable();
        let tempInsertChecker = this.tempData.find(
          (m: { PLAN_CD: any }) => 
            m.PLAN_CD == clickData.PLAN_CD && clickData.MODE == 'I');
        let tempUpdateChecker = this.tempData.find(
          (m: { PLAN_CD: any }) => 
            m.PLAN_CD == clickData.PLAN_CD && clickData.MODE == 'U');
        if (this.rowData.temp) {
          rowToBeUpdated.temp = true;
        }
        else {
          rowToBeUpdated.onDbButUpdatedTemp = true;
          rowToBeUpdated.PLAN_CD = planAmtLimitsCdRowValue;
        }
        if (tempInsertChecker!=null && tempInsertChecker!='') {
          //tempInsertChecker = rowToBeUpdated;
          tempInsertChecker.MIN_PREM_AMT = rowToBeUpdatedTemp.MIN_PREM_AMT;
          tempInsertChecker.SI_AMT_MIN = rowToBeUpdatedTemp.SI_AMT_MIN;
          tempInsertChecker.SI_AMT_MAX = rowToBeUpdatedTemp.SI_AMT_MAX;
          tempInsertChecker.ACTIVE_TAG = rowToBeUpdatedTemp.ACTIVE_TAG;
          tempInsertChecker.REMARKS = rowToBeUpdatedTemp.REMARKS;
          tempInsertChecker.USER_ID = rowToBeUpdatedTemp.USER_ID;
          tempInsertChecker.MODE = 'I';
          rowToBeUpdatedTemp.MODE = 'I';
        }
        else if (tempUpdateChecker!=null && tempUpdateChecker!='') {
          tempUpdateChecker.MIN_PREM_AMT = rowToBeUpdatedTemp.MIN_PREM_AMT;
          tempUpdateChecker.SI_AMT_MIN = rowToBeUpdatedTemp.SI_AMT_MIN;
          tempUpdateChecker.SI_AMT_MAX = rowToBeUpdatedTemp.SI_AMT_MAX;
          tempUpdateChecker.ACTIVE_TAG = rowToBeUpdatedTemp.ACTIVE_TAG;
          tempUpdateChecker.REMARKS = rowToBeUpdatedTemp.REMARKS;
          tempUpdateChecker.USER_ID = rowToBeUpdatedTemp.USER_ID;
          tempUpdateChecker.MODE = 'U';
        }
        else { 
          this.tempData.push(rowToBeUpdatedTemp);
        }
        this.forms.resetPlanAmtLimitsForm();
        this.planAmtLimitsTblConfig.tblData[toBeUpdatedIndex] = rowToBeUpdated;
        this.btns.planAmtLimits.saveDisabled = false;
        this.planAmtLimitsTblConfig.tblData = [... this.planAmtLimitsTblConfig.tblData];
      }
      else {
        this.showErrorValidator.planAmtLimits();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public onSave() {
    try {
      this.btns.planAmtLimits.saveDisabled = true;
      this.showForm = false;
      this._formService.showFormLoader(null, "plan-amt-limits-maintenance-loading", "Saving.<br>Please wait ...", null, null);
      if (this.tempData.length > 0) {
        this._api.savePlanAmtLimits(this.tempData).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                if (data.status === 'SUCCESS') {
                  this.onComplete.planAmtLimits();
                }
                else {
                  this.onComplete.planAmtLimitsError();
                }
              });
            });
          },
          error: (data: any) => {
            this.onComplete.planAmtLimitsError();
          },
        });
      }
      else
        this.onComplete.planAmtLimits();
    }
    catch (e) {
      console.error(e);
    }
  }

  private onComplete = {
    planAmtLimits: () => {
      this.tempData = [];
      this.getData.planAmtLimits();
      this.btns.planAmtLimits.saveDisabled = true;
      this.showForm = true;
      this._formService.hideFormLoader("plan-amt-limits-maintenance-loading");
      this.forms.resetPlanAmtLimitsForm();
      this.openDialog('SUCCESS', 'Plan Amount Limits successfully saved');
    },
    planAmtLimitsError: () => {
      this.showForm = true;
      this.btns.planAmtLimits.saveDisabled = false;
      this.openDialog('FAILED', 'Plan Amount Limits saving failed');
      this._formService.hideFormLoader("plan-amt-limits-maintenance-loading");
    },
  }

  openDialog(title: any, message: any) {
    try {
      this.showForm = true;
      this._formService.hideFormLoader("plan-amt-limits-maintenance-loading");
      this._matDialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        } 
      });
    } catch(e) {
      console.error(e);
    }
  }

  minPremAmtCheck() {
    try {
      let resultCheck: boolean = true;
      if (parseFloat(this._formService.setToNumberWithDecimal(this.forms.planAmtLimitsForm.get('minPremAmt')?.value)) > 99999999999999.99) {
        resultCheck = false;
      }
      else {
        resultCheck = true;
      }
      return resultCheck;
    }
    catch(e) {
      console.error(e);
      return false;
    }
  }

  siAmtMinCheck() {
    try {
      let resultCheck: boolean = true;
      if (parseFloat(this._formService.setToNumberWithDecimal(this.forms.planAmtLimitsForm.get('siAmtMin')?.value)) > 99999999999999.99) {
        resultCheck = false;
      }
      else {
        resultCheck = true;
      }
      return resultCheck;
    }
    catch(e) {
      console.error(e);
      return false;
    }
  }

  siAmtMaxCheck() {
    try {
      let resultCheck: boolean = true;
      if (parseFloat(this._formService.setToNumberWithDecimal(this.forms.planAmtLimitsForm.get('siAmtMax')?.value)) > 99999999999999.99) {
        resultCheck = false;
      }
      else {
        resultCheck = true;
      }
      return resultCheck;
    }
    catch(e) {
      console.error(e);
      return false;
    }
  }

  siAmtMinAndMaxCheck() {
    try {
      let resultCheck: boolean = true;
      if (parseFloat(this._formService.setToNumberWithDecimal(this.forms.planAmtLimitsForm.get('siAmtMin')?.value)) 
      && parseFloat(this._formService.setToNumberWithDecimal(this.forms.planAmtLimitsForm.get('siAmtMax')?.value)) ) {
        if (parseFloat(this._formService.setToNumberWithDecimal(this.forms.planAmtLimitsForm.get('siAmtMin')?.value)) > parseFloat(this._formService.setToNumberWithDecimal(this.forms.planAmtLimitsForm.get('siAmtMax')?.value)) ) {
          resultCheck = false;
        }
        else {
          resultCheck = true;
        }
      }
      else {
        resultCheck = true;
      }
      return resultCheck;
    }
    catch(e) {
      console.error(e);
      return false;
    }
  }

  public showErrorValidator = {
    planAmtLimits: () => {
      try {
        Object.keys(this.forms.planAmtLimitsForm.controls).forEach(key => {
          const controlErrors = this.forms.planAmtLimitsForm.get(key)?.errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              if (keyError == 'required' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
            });
          }
        });
        this._formService.showFormMsg("plan-amt-limits-error-message", this.errorMessage, "E");
        //this.openDialog('ERROR', this.errorMessage);
        setTimeout(()=>{                           
          this._formService.hideFormMsg("plan-amt-limits-error-message");
        }, 10000);
      }
      catch (e) {
        console.error(e);
      }
    },
  }

  allowNumericDigitsOnly(event: any) {
    try {
      if (/[0-9.]/.test(String.fromCharCode(event.keyCode))) {
        return true;
      }
      else {
        event.preventDefault();
        return false;
      }
    }
    catch (e) {
      console.error(e);
      return undefined;
    }
  }
}
