import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'dialog-msg',
  templateUrl: './dialog-msg.component.html',
  styleUrls: ['./dialog-msg.component.css']
})
export class DialogMsgComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<DialogMsgComponent>) { }

  ngOnInit(): void {
  }

  onBack() {
    this._dialogRef.close();
  }

}
