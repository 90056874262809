<section id="pahcipParamValidation-maint">    
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <div class="form-content">
            <form [formGroup]="forms.lineSublinePlanLovForm" class="form">
                <div class="form-container-grid top-form">
                    <div class="field required">
                        <label>Line</label>
                    </div>
                    <div class="">
                        <input formControlName="lineCdDisp" >
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['line']()" 
                        [disabled]='btns.lov.lineDisabled'>
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                    <div class="field required">
                        <label>Subline</label>
                    </div>
                    <div class="">
                        <input formControlName="sublineCdDisp" >
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['subline']()" 
                        [disabled]='btns.lov.sublineDisabled'>
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <!-- Container for Email Content Table using prime ng -->
        <div class = 'table-user'>
            <osp-table [tblConfig]="pahcipParamValidationTblConfig" (onRowClick)="onRowClick($event)"></osp-table>
        </div>
        <!-- Loading Animation -->
        <div id="pahcipParamValidation-maintenance-loading">
            <div class="form-loader"></div>
        </div>
        <div class="form-content" [hidden]="formHider">
            <form [formGroup]="forms.pahcipParamValidationForm" class="form" *ngIf="showForm">
                <div id="pahcipParamValidation-error-message"></div>
                <div class="form-container-grid">
                    
                    <div class="field required">
                        <label>Plan</label>
                    </div>
                    <div class="span">
                        <input readonly formControlName="planDesc" >
                        <button matSuffix class="btn btn-search" type="button" (click)="openLov['plan']()" 
                        [disabled]='btns.lov.planDisabled'>
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                    
                    <div class="field">
                        <label>Minimum Principal Age</label>
                    </div>
                    <div class="">
                        <input formControlName="minPrinAge" type="text" (keypress)="allowNumericDigitsOnlyWODecimal($event)" maxlength="3">
                    </div>

                    <div class="field">
                        <label>Max. Principal Age</label>
                    </div>
                    <div class="">
                        <input formControlName="maxPrinAge" type="text" (keypress)="allowNumericDigitsOnlyWODecimal($event)" maxlength="3">
                    </div>

                    <div class="field">
                        <label>Minimum Spouse Age</label>
                    </div>
                    <div class="">
                        <input formControlName="minSpouseAge" type="text" (keypress)="allowNumericDigitsOnlyWODecimal($event)" maxlength="3">
                    </div>

                    <div class="field">
                        <label>Max. Parent/Spouse Age</label>
                    </div>
                    <div class="">
                        <input formControlName="maxParentSpouseAge" type="text" (keypress)="allowNumericDigitsOnlyWODecimal($event)" maxlength="3">
                    </div>

                    <div class="field">
                        <label>Min. Child/Sibling Age</label>
                    </div>
                    <div class="">
                        <input formControlName="minChildSiblingAge" type="text" (keypress)="allowNumericDigitsOnlyWODecimal($event)" maxlength="3">
                    </div>

                    <div class="field">
                        <label>Max. Child/Sibling Age</label>
                    </div>
                    <div class="">
                        <input formControlName="maxChildSiblingAge" type="text"(keypress)="allowNumericDigitsOnlyWODecimal($event)" maxlength="3">
                    </div>

                    <div class="field">
                        <label>Max. No. of Child/Siblings</label>
                    </div>
                    <div class="">
                        <input formControlName="maxNoChildSibling" type="text" (keypress)="allowNumericDigitsOnlyWODecimal($event)" maxlength="2" >
                    </div>

                    <div class="field required">
                        <label>With Dependents</label>
                    </div>

                    <div class="">
                        <select formControlName="dependentsTag">
                            <option value="Y">Yes</option>
                            <option value="N">No</option>
                        </select>
                    </div>

                    <div class="field">
                        <label>Inclusion Text</label>
                    </div>
                    <div class="span">
                        <textarea maxlength="4000" formControlName="inclusionText"></textarea>
                    </div>

                    <div class="field">
                        <label>Principal's Age Error Message</label>
                    </div>
                    <div class="span">
                        <input type="text" maxlength="1000" formControlName="prinAgeErrMsg">
                    </div>

                    <div class="field">
                        <label>Dependent's Age Error Message</label>
                    </div>
                    <div class="span">
                        <input type="text" maxlength="1000" formControlName="depAgeErrMsg">
                    </div>

                    <div class="field">
                        <label>Max Child/Sibling Error Message</label>
                    </div>
                    <div class="span">
                        <input type="text" maxlength="1000" formControlName="maxNoErrMsg">
                    </div>

                    <div class="field required">
                        <label>Max. Dependents With Premium</label>
                    </div>
                    <div class="">
                        <input formControlName="maxDepWithPrem" type="text" required (keypress)="allowNumericDigitsOnlyWODecimal($event)" maxlength="2">
                    </div>
                    <div></div>
                    <div></div>

                    <div class="field">
                        <label>Max Dependent With Premium Error Message</label>
                    </div>
                    <div class="span">
                        <input type="text" maxlength="1000" formControlName="maxDepErrMsg">
                    </div>

                    <div class="field">
                        <label>Other Risk Detail</label>
                    </div>
                    <div class="span">
                        <input type="text" maxlength="1000" formControlName="otherRiskDtl">
                    </div>

                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input type="text" maxlength="1000" formControlName="remarks">
                    </div>

                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUpdateUser">
                    </div>
                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUpdate">
                    </div>
                </div>
            </form>
            <div class="btn-row">
                <button *ngIf="btns.pahcipParamValidation.updateDisabled" class="btn btn2" type="button" [disabled]='btns.pahcipParamValidation.addDisabled' (click)="onAdd()">ADD</button>
                <button *ngIf="!btns.pahcipParamValidation.updateDisabled" class="btn btn2" type="button" (click)="onUpdate()">UPDATE</button>
                <button class="btn btn2" type="button" [disabled]='btns.pahcipParamValidation.deleteDisabled' (click)="onDelete()"> DELETE</button>
            </div>
            <div class="mtn-title"></div>
            <div class="btn-row">
                <button class="btn btn2" type="button" [disabled]='btns.pahcipParamValidation.saveDisabled' (click)="onSave()">SAVE</button>
            </div>
        </div>
    </div>
</section>