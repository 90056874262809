import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { SecurityService } from 'src/app/services/security.service';

@Component({
    selector: 'reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

    constructor(
        private formBuilder: FormBuilder,
        private apiCallService: APICallService,
        private userDetailService: UserDetailsService,
        private router: Router,
        private activateRoute: ActivatedRoute,
        private appMessageService: AppMessageService,
        private formService: FormService,
        private jsonDataService: JsonDataService,
        private securityService: SecurityService
    ) {
        this.activateRoute.queryParams.subscribe((params: any) => {
            params.requestId ? this.initRequestId(params.requestId) : this.initRequestId(null)
        });
    }

    ngOnInit(): void {
    }

    showNewPassword: boolean = false;
    showRepeatPassword: boolean = false;

    passwordDetails: FormGroup = this.formBuilder.group({
        npw: ["", [Validators.required]],
        rpw: ["", [Validators.required]],
        un: [this.userDetailService.userId || null],
        rqId: []
    })

    resetPassword(): void {
        this.formService.hideFormMsg("resetFormMsg");
        if ((!this.passwordDetails.value.un && !this.passwordDetails.value.rqId) || (this.passwordDetails.value.un && this.passwordDetails.value.rqId)) {
            this.formService.showFormMsg("resetFormMsg", "This request is invalid. Please contact your administrator.", "E");
        } else if (this.passwordDetails.valid) {
            this.formService.showFormLoader(null, "reset-form", "Changing password.<br>Please wait...", null, null);
            this.apiCallService.resetPassword(this.passwordDetails.value).subscribe((res: any) => {
                this.securityService.checkRequestKeyResponse(res, () => {
                    // CHECK IF RESET FUNCTION IS WORKING
                    if (res.status == "SUCCESS") {
                        const response = JSON.parse(this.jsonDataService.decrypt(res.content));
                        // CHECK IF THE STATUS OF ACCOUNT
                        if (response.status == "SUCCESS") {
                            this.appMessageService.showAppMessage("Password changed successfully. Please login again.", "success");
                            this.formService.hideFormLoader("reset-form");
                            this.apiCallService.logout().subscribe();
                            this.router.navigate(["home/login"]);
                        } else {
                            this.formService.hideFormLoader("reset-form");
                            this.formService.showFormMsg("resetFormMsg", response.message, "E");
                        }
                    } else {
                        this.formService.hideFormLoader("reset-form");
                        this.formService.showFormMsg("resetFormMsg", res.message, "E");
                    }
                });
            });
        } else {
            this.formService.showFormMsg("resetFormMsg", "Please fill up all the required fields", "E");
        }
    }

    initRequestId(requestId: any) {
        this.passwordDetails.patchValue({
            rqId: requestId
        })
    }
}
