import { Component, OnInit } from '@angular/core';

import { APICallService } from 'src/app/services/api-call.service'
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';

import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { PlanLovComponent } from '../../common/plan-lov/plan-lov.component';

@Component({
  selector: 'vehicle-filter-maintenance',
  templateUrl: './vehicle-filter-maintenance.component.html',
  styleUrls: ['./vehicle-filter-maintenance.component.css']
})
export class VehicleFilterMaintenanceComponent implements OnInit, LeaveConfirmation {

  datePipe = new DatePipe('en-us');
  formHider: boolean = false;
  addUpdDisabled: boolean = true;
  saveDisabled: boolean = true;
  deleteDisabled: boolean = true;
  sublineDisabled: boolean = true;
  planDisabled: boolean = true;
  tranPosted: boolean = false;
  noLoadingInProcess: boolean = true;
  validated: boolean = false;
  submitted: boolean = false;
  isVehicleFilterSelected: boolean = false;
  moduleId: string = 'BMM124';
  public moduleName: string = this.jsonDataService.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc?.toUpperCase() ?? "";

  selectedHighRisk: FormControl = new FormControl;
  selectedMakeType: FormControl = new FormControl;

  sublineField: string = '';
  currentUser: string = this.jsonDataService.retrieveFromStorage("userId");
  rowClickedData: any;

  effDate = new FormControl(new Date());

  selectedData: any = null;

  selectedLine: any = {
    SUBLINE_CD: '',
    SUBLINE_NAME: '',
    SUBLINE_FIELD: ''
  };

  selectedPlan: any = {
    PLAN_CD: '',
    PLAN_NAME: '',
    PLAN_FIELD: ''
  };

  tempAddedData: any = [];
  tempUpdateData: any = [];
  tempDeleteData: any = [];

  paramForm = this.fb.group({
    sublineCd: [null],
    sublineName: [null],
    sublineField: [null]
  });

  vehicleFilterForm = this.fb.group({
    planCode: [null, Validators.required],
    planName: [null],
    planField: [null],
    filterYearNew: [null],
    filterYearUsed: [null],
    filterHighRiskId: [''],
    filterMakeTypeCd: [''],
    userId: [{ value: '', disabled: 'true' }],
    lastUserUpdate: [{ value: '', disabled: 'true' }]
  });

  constructor(
    private _APICallService: APICallService,
    public _Dialog: MatDialog,
    private fb: FormBuilder,
    private formService: FormService,
    private appMessageService: AppMessageService,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService,
    private userDetailService: UserDetailsService
  ) { }

  canDeactivate(): Observable<boolean> | boolean {
    try {
      if (this.tempAddedData != '' || this.tempUpdateData != '') {
        return false;
      } else {
        return true;
      }
    } catch (e) {
    console.error(e);
      return true;
    }
  }

  tblConfigVehicleFilter: any = {
    cols: [
      {
        key: "PLAN_CD",
        header: "Plan Code",
        dataType: "string",
        width: "16%"
      },
      {
        key: "PLAN_NAME",
        header: "Plan",
        dataType: "string",
        width: "16%"
      },
      {
        key: "FILTER_YEAR_NEW",
        header: "Filter Year for Brand New",
        dataType: "string",
        width: "16%"
      },
      {
        key: "FILTER_YEAR_USED",
        header: "Filter Year for Used",
        dataType: "string",
        width: '16%'
      },
      {
        key: "FILTER_HIGH_RISK_ID",
        header: "High Risk Filter",
        dataType: "string",
        width: '16%'
      },
      {
        key: "FILTER_MAKE_TYPE_CD",
        header: "Make Type Filter",
        dataType: "string",
        width: '16%'
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 5,
    lazy: false,
    totalRecords: 10
  }

  ngOnInit(): void {

  }

  initOnGet(): void {
    this.formHider = false;
    try {
      this._APICallService.refreshNeeded$
        .subscribe(() => {
          this.getVehicleFilter();
        });
      this.getVehicleFilter();
    }
    catch (e) {
    console.error(e);
    }
  }

  getVehicleFilter() {
    try {
      if (this.selectedLine.SUBLINE_CD != null && this.selectedLine.SUBLINE_CD != '') {
        this.tblConfigVehicleFilter.loading = true;
        this.jsonDataService.contorlLoading(true);
        this._APICallService.getVehicleFilter({
          moduleId: this.moduleId,
          userId: this.userDetailService.userId,
          type: "MODULE"
        }).subscribe({
          next: (data: any) => {
            this.securityService.checkRequestKeyResponse(data, () => {
              this.securityService.hasValidCsrfToken(data, () => {
                if (data.status === "SUCCESS") {
                  data = JSON.parse(this.jsonDataService.decrypt(data.response));
                  this.jsonDataService.contorlLoading(false);
                  this.tblConfigVehicleFilter.tblData = data.filter((data: any) => data.LINE_CD == 'MC' && data.SUBLINE_CD == this.selectedLine.SUBLINE_CD);
                  this.tblConfigVehicleFilter.loading = false;
                  this.addUpdDisabled = false;
                  this.planDisabled = false;
                  this.resetFormValues();
                } else {
                  this.jsonDataService.contorlLoading(false);
                  this.tblConfigVehicleFilter.loading = false;
                  this.addUpdDisabled = false;
                  this.planDisabled = false;
                  this.appMessageService.showAppMessage(data.message, "error");
                }
              });
            });
          },
          error: () => {
            this.tblConfigVehicleFilter.loading = false;
            this.jsonDataService.contorlLoading(false);
          },
        });
      }
    } catch (e) {
    console.error(e);
    }
  }

  openSublineDialog() {
    try {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'SUBLINE',
          moduleId: this.moduleId
        },
      }
      LOVOPTIONS.data.lineCd = 'MC';
      this._Dialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe(data => {
        if (data.content == '' || data.content == null) {
          this.selectedLine = this.selectedLine;
        } else {
          this.selectedLine = data.content;
          this.paramForm.get('sublineCd')?.setValue(this.selectedLine.SUBLINE_CD == null || this.selectedLine.SUBLINE_CD == '' ? '' : this.selectedLine.SUBLINE_CD);
          this.paramForm.get('sublineName')?.setValue(this.selectedLine.SUBLINE_CD == null || this.selectedLine.SUBLINE_CD == '' ? '' : this.selectedLine.SUBLINE_NAME);
          this.paramForm.get('sublineField')?.setValue(this.selectedLine.SUBLINE_CD == null || this.selectedLine.SUBLINE_CD == '' ? '' : this.selectedLine.SUBLINE_CD + " - " + this.selectedLine.SUBLINE_NAME);
          this.tempAddedData = [];
          this.tempUpdateData = [];
          this.tempDeleteData = [];
          this.initOnGet();
        }
      });
    } catch (e) {
    console.error(e);
    }
  }

  openPlanDialog() {
    try {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'PLAN',
          moduleId: this.moduleId
        },
      }
      LOVOPTIONS.data.lineCd = 'MC';
      LOVOPTIONS.data.sublineCd = this.paramForm.get('sublineCd')?.value;
      this._Dialog.open(PlanLovComponent, LOVOPTIONS).afterClosed().subscribe((data: any) => {
        let output = data.content;
        if (output) {
          this.selectedPlan = data.content;
          this.vehicleFilterForm.get('planCode')?.setValue(this.selectedPlan.PLAN_CD == null || this.selectedPlan.PLAN_CD == '' ? '' : this.selectedPlan.PLAN_CD);
          this.vehicleFilterForm.get('planName')?.setValue(this.selectedPlan.PLAN_CD == null || this.selectedPlan.PLAN_CD == '' ? '' : this.selectedPlan.PLAN_NAME);
          this.vehicleFilterForm.get('planField')?.setValue(this.selectedPlan.PLAN_CD == null || this.selectedPlan.PLAN_CD == '' ? '' : this.selectedPlan.PLAN_CD + " - " + this.selectedPlan.PLAN_NAME);
        }
      });
    } catch (e) {
    console.error(e);
    }
  }

  insertVehicleFilter() {
    try {
      this.tranPosted = true;
      this.validateInsert();
      if (this.validated) {
        this.addFormToTable();
      }
    }
    catch (e) {
    console.error(e);
    }
  }

  addFormToTable() {
    try {
      this.vehicleFilterForm.patchValue({
        userId: this.currentUser,
        lastUserUpdate: new Date()
      });
      let formToData: any = {
        LINE_CD: 'MC',
        SUBLINE_CD: this.paramForm.get('sublineCd')?.value,
        PLAN_CD: this.vehicleFilterForm.get('planCode')?.value,
        FILTER_YEAR_NEW: this.vehicleFilterForm.get('filterYearNew')?.value,
        FILTER_YEAR_USED: this.vehicleFilterForm.get('filterYearUsed')?.value,
        //FILTER_HIGH_RISK_ID: this.vehicleFilterForm.get('filterHighRiskId')?.value ? this.vehicleFilterForm.get('filterHighRiskId')?.value.map((x: string) => x.replace(/\D/g, '')) : "",
        FILTER_HIGH_RISK_ID: this.vehicleFilterForm.get('filterHighRiskId')?.value || "",
        FILTER_MAKE_TYPE_CD: this.vehicleFilterForm.get('filterMakeTypeCd')?.value || "",
        USER_ID: this.vehicleFilterForm.get('userId')?.value,
        LAST_UPDATE: null,
        temp: true
      }
      this.tblConfigVehicleFilter.tblData.unshift({
        PLAN_CD: this.vehicleFilterForm.get('planCode')?.value,
        PLAN_NAME: this.vehicleFilterForm.get('planName')?.value,
        PLAN_FIELD: this.vehicleFilterForm.get('planField')?.value,
        FILTER_YEAR_NEW: this.vehicleFilterForm.get('filterYearNew')?.value,
        FILTER_YEAR_USED: this.vehicleFilterForm.get('filterYearUsed')?.value,
        FILTER_HIGH_RISK_ID: this.vehicleFilterForm.get('filterHighRiskId')?.value || "",
        FILTER_MAKE_TYPE_CD: this.vehicleFilterForm.get('filterMakeTypeCd')?.value || "",
        USER_ID: this.vehicleFilterForm.get('userId')?.value,
        LAST_UPDATE: this.datePipe.transform(new Date(this.vehicleFilterForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        temp: true
      });
      this.tblConfigVehicleFilter.tblData = [...this.tblConfigVehicleFilter.tblData];
      this.tempAddedData.push(formToData);
      this.resetFormValues();
      this.appMessageService.showAppMessage("Vehicle Filter details added!", "success");
    }
    catch (e) {
    console.error(e);
    }
  }

  updateVehicleFilter() {
    try {
      this.tranPosted = true;
      this.validateUpdate();
      if (this.validated) {
        this.updateFormTable();
      }
    }
    catch (e) {
    console.error(e);
    }
  }

  updateFormTable() {
    try {
      this.tableDataUpdate();
      this.tempUpdateData.push(this.formValueToData());
      this.saveDisabled = false;
      this.resetFormValues();
      this.appMessageService.showAppMessage("Vehicle Filter details updated!", "success");
    }
    catch (e) {
    console.error(e);
    }
  }

  tableDataUpdate() {
    try {
      this.vehicleFilterForm.patchValue({
        userId: this.currentUser,
        lastUserUpdate: new Date()
      });
      let updateIndex = this.tblConfigVehicleFilter.tblData.indexOf(this.rowClickedData);
      if (this.rowClickedData.temp == true) {
        this.tblConfigVehicleFilter.tblData[updateIndex] = {
          PLAN_CD: this.vehicleFilterForm.get('planCode')?.value,
          PLAN_NAME: this.vehicleFilterForm.get('planName')?.value,
          PLAN_FIELD: this.vehicleFilterForm.get('planField')?.value,
          FILTER_YEAR_NEW: this.vehicleFilterForm.get('filterYearNew')?.value,
          FILTER_YEAR_USED: this.vehicleFilterForm.get('filterYearUsed')?.value,
          FILTER_HIGH_RISK_ID: this.vehicleFilterForm.get('filterHighRiskId')?.value || "",
          FILTER_MAKE_TYPE_CD: this.vehicleFilterForm.get('filterMakeTypeCd')?.value || "",
          USER_ID: this.vehicleFilterForm.get('userId')?.value,
          LAST_UPDATE: this.datePipe.transform(new Date(this.vehicleFilterForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
          temp: true
        };
      }
      else {
        this.tblConfigVehicleFilter.tblData[updateIndex] = {
          PLAN_CD: this.vehicleFilterForm.get('planCode')?.value,
          PLAN_NAME: this.vehicleFilterForm.get('planName')?.value,
          PLAN_FIELD: this.vehicleFilterForm.get('planField')?.value,
          FILTER_YEAR_NEW: this.vehicleFilterForm.get('filterYearNew')?.value,
          FILTER_YEAR_USED: this.vehicleFilterForm.get('filterYearUsed')?.value,
          FILTER_HIGH_RISK_ID: this.vehicleFilterForm.get('filterHighRiskId')?.value || "",
          FILTER_MAKE_TYPE_CD: this.vehicleFilterForm.get('filterMakeTypeCd')?.value || "",
          USER_ID: this.vehicleFilterForm.get('userId')?.value,
          LAST_UPDATE: this.datePipe.transform(new Date(this.vehicleFilterForm.get('lastUserUpdate')?.value), 'MM/dd/YYYY h:mm:ss a'),
        };
      }
      this.tblConfigVehicleFilter.tblData = [...this.tblConfigVehicleFilter.tblData];
    }
    catch (e) {
    console.error(e);
    }
  }

  formValueToData() {
    try {
      let formToData: any = {
        LINE_CD: 'MC',
        SUBLINE_CD: this.paramForm.get('sublineCd')?.value,
        PLAN_CD: this.vehicleFilterForm.get('planCode')?.value,
        FILTER_YEAR_NEW: this.vehicleFilterForm.get('filterYearNew')?.value,
        FILTER_YEAR_USED: this.vehicleFilterForm.get('filterYearUsed')?.value,
        FILTER_HIGH_RISK_ID: this.vehicleFilterForm.get('filterHighRiskId')?.value || "",
        FILTER_MAKE_TYPE_CD: this.vehicleFilterForm.get('filterMakeTypeCd')?.value || "",
        USER_ID: this.vehicleFilterForm.get('userId')?.value,
        LAST_UPDATE: null,
      }
      return formToData;
    }
    catch (e) {
    console.error(e);
    }
  }

  saveAddedData() {
    this.tempAddedData
    this._APICallService.saveVehicleFilter(this.tempAddedData)
      .subscribe({
        next: (response: any) => {
          this.securityService.checkRequestKeyResponse(response, () => {
            this.securityService.hasValidCsrfToken(response, () => {
              if (response.status == "SUCCESS" && this.tranPosted) {
                if (this.tempUpdateData.length != 0) {
                  this.saveUpdateData();
                } else {
                  if (this.tempDeleteData.length != 0) {
                    this.saveDeleteData();
                  }
                  else {
                    this.openDialog('SUCCESS', 'New Vehicle Filter details Successfully saved!');
                    this.initOnGet();
                    this.resetFormValues();
                    this.tempAddedData = [];
                    this.tempUpdateData = [];
                    this.tempDeleteData = [];
                  }
                }
              }
              else {
                this.openDialog('ERROR', 'An error has occured while saving details for new vehicle filter');
              }
            });
          });
        },
        error: () => {
          this.openDialog('ERROR', 'An error has occured while saving details for new vehicle filter');
        }
      });
  }

  saveUpdateData() {
    this._APICallService.saveVehicleFilter(this.tempUpdateData)
      .subscribe({
        next: (response: any) => {
          this.securityService.checkRequestKeyResponse(response, () => {
            this.securityService.hasValidCsrfToken(response, () => {
              if (response.status == "SUCCESS" && this.tranPosted) {
                if (this.tempDeleteData.length != 0) {
                  this.saveDeleteData();
                } else {
                  this.openDialog('SUCCESS', 'Existing vehicle filter details Successfully saved!');
                  this.initOnGet();
                  this.resetFormValues();
                  this.tempAddedData = [];
                  this.tempUpdateData = [];
                  this.tempDeleteData = [];
                }
              } else {
                this.openDialog('ERROR', 'An error has occured while saving details for existing vehicle filter');
              }
            });
          });
        },
        error: () => {
          this.openDialog('ERROR', 'An error has occured while saving details for existing vehicle filter');
        }
      });
  }

  saveDeleteData() {
    this._APICallService.saveVehicleFilter(this.tempDeleteData)
      .subscribe({
        next: (response: any) => {
          this.securityService.checkRequestKeyResponse(response, () => {
            this.securityService.hasValidCsrfToken(response, () => {
              if (response.status == "SUCCESS" && this.tranPosted) {
                this.openDialog('SUCCESS', 'Vehicle filter details successfully deleted!');
                this.initOnGet();
                this.resetFormValues();
                this.tempAddedData = [];
                this.tempUpdateData = [];
                this.tempDeleteData = [];

              } else {
                this.openDialog('ERROR', 'An error has occured while deleting details for vehicle filter');
              }
            });
          });
        },
        error: () => {
          this.openDialog('ERROR', 'An error has occured while deleting details for vehicle filter');
        }
      });
  }

  toSave() {
    try {
      this.noLoadingInProcess = false;
      this.formHider = true;
      this.formService.showFormLoader(null, "generate-intro-form", "Please wait ...", null, null);
      if (this.tempAddedData.length != 0) {
        this.saveAddedData();
      } else {
        if (this.tempUpdateData.length != 0) {
          this.saveUpdateData();
        } else {
          if (this.tempDeleteData.length != 0) {
            this.saveDeleteData();
          }
          else {
            this.initOnGet();
            this.resetFormValues();
            this.tempAddedData = [];
            this.tempUpdateData = [];
            this.tempDeleteData = [];
          }
        }
      }
    }
    catch (e) {
    console.error(e);
    }
  }

  deleteVehicleFilter() {
    try {
      this.tranPosted = true;
      let clickData = this.rowClickedData;
      this.tblConfigVehicleFilter.tblData = this.tblConfigVehicleFilter.tblData.filter(
        function (e: any) {
          return e.PLAN_CD != clickData.PLAN_CD;
        }
      );
      this.tempAddedData = this.tempAddedData.filter(
        function (e: any) {
          return e.PLAN_CD != clickData.PLAN_CD;
        }
      );
      if (clickData.temp != true) {
        clickData.delete = true;
        this.tempDeleteData.push(clickData);
      }
      this.resetFormValues();
    }
    catch (e) {
    console.error(e);
    }
  }

  saveChecker() {
    try {
      if ((this.tempAddedData == null || this.tempAddedData == '') && (this.tempUpdateData == null || this.tempUpdateData == '') && (this.tempDeleteData == null || this.tempDeleteData == '')) {
        return true;
      }
      else {
        return false;
      }
    }
    catch (e) {
    console.error(e);
      return true;
    }
  }

  deleteChecker(ev: any) {
    try {
      let tempDataFlag;
      tempDataFlag = this.tempAddedData.filter(function (e: any) {
        return e.PLAN_CD == ev.PLAN_CD;
      });
      if (tempDataFlag == '' || tempDataFlag == null) {
        return true;
      } else {
        return false;
      }
    }
    catch (e) {
    console.error(e);
      return true;
    }
  }

  onRowClick(ev: any) {
    try {
      if (ev != null) {
        this.rowClickedData = ev;
        this.loadFormValues();
        this.saveDisabled = this.saveChecker();
        this.deleteDisabled = false;
      }
      else {
        this.resetFormValues();
      }
    }
    catch (e) {
    console.error(e);
    }
  }
  selectedHighRiskList: string[] = [];
  selectedMakeTypeList: string[] = [];
  loadFormValues() {
    try {
      this.vehicleFilterForm.patchValue({
        planCode: this.rowClickedData.PLAN_CD,
        planName: this.rowClickedData.PLAN_NAME,
        planField: this.rowClickedData.PLAN_CD + ' - ' + this.rowClickedData.PLAN_NAME,
        filterYearNew: this.rowClickedData.FILTER_YEAR_NEW,
        filterYearUsed: this.rowClickedData.FILTER_YEAR_USED,
        //filterHighRiskId: this.rowClickedData.FILTER_HIGH_RISK_ID ? this.getData.filterHighRiskIds().map((x: { name: any; }) => x.name).filter((element: any) => this.rowClickedData.FILTER_HIGH_RISK_ID.includes(element.substring(0, 1)) || this.rowClickedData.FILTER_HIGH_RISK_ID.includes(element.substring(0, 2))) : '',
        filterHighRiskId: this.rowClickedData.FILTER_HIGH_RISK_ID ? this.rowClickedData.FILTER_HIGH_RISK_ID.toString().replace(/\s/g, '').split(',').map(Number) : "",
        filterMakeTypeCd: this.rowClickedData.FILTER_MAKE_TYPE_CD ? this.rowClickedData.FILTER_MAKE_TYPE_CD.toString().replace(/\s/g, '').split(',') : "",
        userId: this.rowClickedData.USER_ID || '-',
        lastUserUpdate: this.rowClickedData.LAST_UPDATE || '-'
      });
      this.planDisabled = true;
      this.isVehicleFilterSelected = true;
    }
    catch (e) {
    console.error(e);
    }
  }

  resetFormValues() {
    try {
      this.selectedData = null;
      this.vehicleFilterForm.reset();

      this.saveDisabled = this.saveChecker();
      this.deleteDisabled = true;
      this.isVehicleFilterSelected = false;
      this.planDisabled = false;
      this.rowClickedData = '' || null;
    }
    catch (e) {
    console.error(e);
    }
  }

  openDialog(title: any, message: any) {
    try {
      this.noLoadingInProcess = true;
      this.formService.hideFormLoader("generate-intro-form");
      this._Dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        }
      });
    } catch (e) {
    console.error(e);
    }
  }

  validateInsert() {
    try {
      this.validated = false;
      if (!this.requiredFieldCheck()) {
        this.formHider = false;
        setTimeout(() => {
          this.formService.hideFormMsg("vehicle-filter-error-message");
        }, 10000);
      }
      else if (!this.sublineDupCheck()) {
        this.formHider = false;
        setTimeout(() => {
          this.formService.hideFormMsg("vehicle-filter-error-message");
        }, 10000);
      }
      else if (this.vehicleFilterForm.get('filterYearNew')?.value < 0 || this.vehicleFilterForm.get('filterYearUsed')?.value < 0) {
        this.formHider = false;
        this.formService.showFormMsg("vehicle-filter-error-message", 'There are missing information. Please provide necessary information needed.', "E");
        setTimeout(() => {
          this.formService.hideFormMsg("vehicle-filter-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
    }
  }

  validateUpdate() {
    try {
      this.validated = false;
      if (!this.requiredFieldCheck()) {
        this.formHider = false;
        this.formService.showFormMsg("vehicle-filter-error-message", 'There are missing information. Please provide necessary information needed.', "E");
        setTimeout(() => {
          this.formService.hideFormMsg("vehicle-filter-error-message");
        }, 10000);
      }
      else if (this.vehicleFilterForm.get('filterYearNew')?.value < 0 || this.vehicleFilterForm.get('filterYearUsed')?.value < 0) {
        this.formHider = false;
        this.formService.showFormMsg("vehicle-filter-error-message", 'There are missing information. Please provide necessary information needed.', "E");
        setTimeout(() => {
          this.formService.hideFormMsg("vehicle-filter-error-message");
        }, 10000);
      }
      else {
        this.validated = true;
      }
    }
    catch (e) {
    console.error(e);
    }
  }

  sublineDupCheck() {
    try {
      let planForm = this.vehicleFilterForm.get('planCode')?.value;
      this.sublineField = this.tblConfigVehicleFilter.tblData.filter(function (e: any) {
        return e.PLAN_CD == planForm;
      });
      if (this.sublineField == '' || this.sublineField == null) {
        return true;
      } else {
        this.formService.showFormMsg("vehicle-filter-error-message", 'Unable to add record. Vehicle Filter for this Line and Subline already exists.', "E");
        return false;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }

  requiredFieldCheck() {
    try {
      if (this.vehicleFilterForm.get('planField')?.value == '' || this.vehicleFilterForm.get('planField')?.value == null) {
        this.formService.showFormMsg("vehicle-filter-error-message", 'There are missing information. Please provide the necessary information needed.', "E");
        return false;
      }
      else {
        return true;
      }
    }
    catch (e) {
    console.error(e);
      return false;
    }
  }


  private getData = {
    filterMakeTypeCds: () => {
      const filteredMag = this.jsonDataService.data.engineSeries.filter((engine: { makeTypeCd: null; }) => engine.makeTypeCd != null && engine.makeTypeCd != '').map((data: any) => { return { cd: data.makeTypeCd.substr(0, 1).toUpperCase(), name: data.makeTypeCd.substr(0, 1).toUpperCase() } }).filter((value: { cd: any; name: any; }, index: any, arr: any[]) => {
        return index === arr.findIndex(obj => obj.name === value.name);
      });
      return filteredMag;
    },
    filterHighRiskIds: () => {
      const filteredMag = this.jsonDataService.data.highRisk.map((data: any) => { return { cd: data.highRiskId, name: data.highRiskId + '-' + data.highRiskDesc } });
      return filteredMag;
    }
  }


  public selections = {
    filterMakeTypeCds: [
      ...this.getData.filterMakeTypeCds()
    ],
    filterHighRiskIds: [
      ...this.getData.filterHighRiskIds()
    ],
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.cd === c2.cd : c1 === c2;
  }

  allowNumericDigitsOnlyWODecimal(event: any) {
    try {
      if (/[0-9]/.test(String.fromCharCode(event.keyCode))) {
        return true;
      }
      else {
        event.preventDefault();
        return false;
      }
    }
    catch (e) {
    console.error(e);
      return undefined;
    }
  }
}