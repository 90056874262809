import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { APICallService } from 'src/app/services/api-call.service';
import { VehicleMaintentanceSelectModelMakeComponent } from './vehicle-maintentance-select-model-make/vehicle-maintentance-select-model-make.component';
import { VehicleMaintenanceModelComponent } from './vehicle-maintenance-model/vehicle-maintenance-model.component';
import { VehicleMaintenanceMakeComponent } from './vehicle-maintenance-make/vehicle-maintenance-make.component';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from '../../../services/json-data.service';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { SecurityService } from 'src/app/services/security.service';

@Component({
  selector: 'vehicle-maintenance',
  templateUrl: './vehicle-maintenance.component.html',
  styleUrls: ['./vehicle-maintenance.component.css'],
})
export class VehicleMaintenanceComponent implements OnInit, LeaveConfirmation {
  constructor(
    private api: APICallService,
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private formService: FormService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService,
  ) {
    this.userIdLoggedIn = this._userDetailService.userId ?? "";
  }

  ngOnInit(): void {
    this.getVehicles();
  }

  canDeactivate(): boolean | Observable<boolean> {
    return this.tblConfig.tblData.filter((d: any) => (d.temp === true || d.onDbButUpdatedTemp === true)).length > 0 ? false : true;
  }

  private moduleId: string = 'BMM025';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc?.toUpperCase() ?? "";

  private userIdLoggedIn: string = '';
  public invalidShow: boolean = false;
  public updateBtnDisabled: boolean = true;
  public deleteBtnDisabled: boolean = true;
  public tempDataRow: boolean = false;
  public saveBtnDisabled: boolean = true;
  private rowData: any;
  private indexRow: any;

  public savingDiaShow: boolean = false;
  public savingShow: boolean = true;
  public savedShow: boolean = false;
  public savedShowError: boolean = false;
  public showForm: boolean = true;

  vehicleMainForm = this.fb.group({
    makeCD: [null], //MAKE_CD
    makeDesc: [null], //MAKE_DESC
    carCompanyCD: [null], //CAR_COMPANY_CD
    carCompany: [null], //CAR_COMPANY
    model: [null, [Validators.required]], //MAKE_CD + MAKE_DESC
    make: [null, [Validators.required]], //CAR_COMPANY_CD + CAR_COMPANY
    subModel: [null, [Validators.required, Validators.maxLength(50)]], // ENGINE_SERIES
    subModelCD: [null], //SERIES_CD
    active: [true], //ACTIVE_TAG
    makeTypeCode: [null, [Validators.required, Validators.maxLength(8)]], //MAKE_TYPE_CD
    seats: [null, [Validators.required, Validators.pattern('^[0-9]*$'), Validators.maxLength(3)]], //SEATS
    ccWt: [null, [Validators.maxLength(5)]], //CC_WT
    gasType: [null], //GAS_TYPE
    remarks: [null, [Validators.maxLength(4000)]], //REMARKS
    userId: [null], //USER_ID
    lastUpdate: [null], //LAST_UPDATE
    contractTypeCd: [null, [Validators.required]],
    highRiskId: [null, [Validators.required]],
    maxAge: [null, [Validators.required]],
  });

  formReset() {
    try {
      this.vehicleMainForm.reset();
      this.vehicleMainForm.get('active')?.setValue(true);
    }
    catch (e) {
      console.error(e);
    }
  }

  gasTypes = [
    { cd: '', name: '' },
    { cd: 'D', name: 'DIESEL' },
    { cd: 'G', name: 'GAS' },
  ];

  contractTypes = this._jDS.data.contracts.filter((e: any) => e?.contractTypeCd === 1 || e?.contractTypeCd === 2 || e?.contractTypeCd === 4);

  highRiskIds = this._jDS.data.highRisk.filter((e: any) => e?.activeTag === "A");

  tblConfig: any = {
    cols: [
      {
        key: 'CAR_COMPANY',
        header: 'Make',
        dataType: 'string',
        width: "20%",
      },
      {
        key: 'MAKE_DESC',
        header: 'Model',
        dataType: 'string',
        disabled: false,
        width: "20%",
      },
      {
        key: 'ENGINE_SERIES',
        header: 'Sub Model',
        dataType: 'string',
        disabled: false,
      },
      {
        key: 'MAKE_TYPE_CD',
        header: 'Make Type Code',
        dataType: 'string',
        disabled: false,
        width: "20%",
      },
    ],
    tblData: [],
    selection: 'single',
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    //tblCaption: "VEHICLES",
    totalRecords: 15,
    loading: false,
  };

  getVehicles() {
    this.tblConfig.loading = true;
    this._jDS.contorlLoading(true);
    this.api.getAllVehicles({
      moduleId: this.moduleId,
      userId: this._userDetailService.userId,
      type: "MODULE",
    }).subscribe({
      next: (data: any) => {
        this._securityService.checkRequestKeyResponse(data, () => {
          this._securityService.hasValidCsrfToken(data, () => {
            if (data.status === "SUCCESS") {
              let content = JSON.parse(this._jDS.decrypt(data?.response));
              this.tblConfig.tblData = content?.data ?? [];
              this._jDS.contorlLoading(false);
              this.tblConfig.loading = false;
            }
            else {
              this._jDS.contorlLoading(false);
              this.tblConfig.loading = false;
            }
          });
        });
      },
      error: (data: any) => {
        console.error(data);
        this._securityService.checkRequestKeyResponse(data, () => {
          this.tblConfig.loading = false;
          this._jDS.contorlLoading(false);
        });
      },
    });
  }

  onRowClick(ev: any) {
    try {
      this.indexRow = this.tblConfig.tblData.map((d: any) => d).indexOf(ev);
      if (
        (ev.temp == undefined || ev.temp == null) &&
        ev.onDbButUpdatedTemp == undefined
      ) {
        this._jDS.contorlLoading(true);
        let params = {
          carCompanyCD: ev.CAR_COMPANY_CD,
          makeCD: ev.MAKE_CD,
          seriesCD: ev.SERIES_CD,
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          type: "MODULE",
        };
        this.api.getVehicle(params).subscribe((data: any) => {
          this._securityService.checkRequestKeyResponse(data, () => {
            this._securityService.hasValidCsrfToken(data, () => {
              if (data.status === "SUCCESS") {
                let content = JSON.parse(this._jDS.decrypt(data?.response));
                let rowData = content.data;
                rowData[0].update = true;
                this.rowData = rowData[0];
                this.populateFormFromTable();
              }
              this._jDS.contorlLoading(false);
            });
          });
        });
      } else {
        ev.update = true;
        this.rowData = ev;
        this.populateFormFromTable();
      }
    } catch (e) {
      this.formReset();
      this.rowData = null;
      this.indexRow = null;
      this.updateBtnDisabled = true;
      this.deleteBtnDisabled = true;
      this.tempDataRow = false;
    }
  }

  populateFormFromTable() {
    try {
      let data = this.rowData;
      this.updateBtnDisabled = !data.update;
      this.deleteBtnDisabled = !data.temp;
      this.tempDataRow = !data.temp;
      this.vehicleMainForm.setValue({
        makeCD: data.MAKE_CD, //MAKE_CD
        makeDesc: data.MAKE_DESC, //MAKE_DESC
        carCompanyCD: data.CAR_COMPANY_CD, //CAR_COMPANY_CD
        carCompany: data.CAR_COMPANY, //CAR_COMPANY
        model: this.pad(data.MAKE_CD, 5) + ' - ' + data.MAKE_DESC,
        make: this.pad(data.CAR_COMPANY_CD, 5) + ' - ' + data.CAR_COMPANY,
        subModel: data.ENGINE_SERIES,
        subModelCD: data.SERIES_CD ? this.pad(data.SERIES_CD, 5) : null,
        makeTypeCode: data.MAKE_TYPE_CD,
        active: data.ACTIVE_TAG === 'A' ? true : false,
        seats: data.SEATS != undefined ? data.SEATS : null,
        ccWt: data.CC_WT != undefined ? data.CC_WT : null,
        gasType: data.GAS_TYPE != undefined ? data.GAS_TYPE : null,
        remarks: data.REMARKS != undefined ? data.REMARKS : null,
        userId: data.USER_ID != undefined ? data.USER_ID : null,
        lastUpdate: data.LAST_UPDATE
          ? new DatePipe('en_PH').transform(data.LAST_UPDATE, 'MM/dd/YYYY h:mm:ss a', '+0000')
          : null,
        contractTypeCd: data?.CONTRACT_TYPE_CD ? data?.CONTRACT_TYPE_CD : null,
        highRiskId: data?.HIGH_RISK_ID ? data?.HIGH_RISK_ID : null,
        maxAge: data?.MAX_AGE ? data?.MAX_AGE : null,
      });
    }
    catch (e) {
      console.error(e);
    }
  }

  openDialog(title: string, contentDetail: string) {
    try {
      this._matDialog.open(DialogMsgComponent, {
        disableClose: true,
        width: '512px',
        data: {
          title: title,
          content:
            title === 'SUCCESS'
              ? `Vehicle details successfully ${contentDetail}!`
              : `Vehicle details ${contentDetail} failed!`,
        },
      });
    }
    catch (e) {
      console.error(e);
    }
  }

  //pad zeroes to number
  private pad(str: string, max: number): string {
    try {
      str = str.toString();
      return str.length < max ? this.pad('0' + str, max) : str;
    }
    catch (e) {
      console.error(e);
      return "";
    }
  }

  onSave(): void {
    try {
      this.formService.showFormLoader(
        null,
        'vehicle-form',
        'Saving.<br>Please wait ...',
        null,
        null,
        true
      );
      this.formReset();
      this.showForm = false;
      this.savingDiaShow = true;
      this.savingShow = true;
      this.savedShow = false;
      this.savedShowError = false;
      this.saveBtnDisabled = true;
      let toBeInsertedUpdated = this.tblConfig.tblData.filter((d: any) => {
        return d.temp === true || d.onDbButUpdatedTemp === true;
      });
      this._jDS.contorlLoading(true);
      if (toBeInsertedUpdated.length > 0) {
        toBeInsertedUpdated.map((d: any) => {
          d.USER_ID = this.userIdLoggedIn;
        });

        this.api.saveVehicleMaintenance(toBeInsertedUpdated).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                if (data.status === 'SUCCESS') this.onComplete();
                else this.onCompleteError();
              });
            });
          },
          error: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              console.error(data);
              this.onCompleteError();
            });
          },
        });
      }
      else {
        this.onComplete();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  onComplete() {
    try {
      this._jDS.contorlLoading(false);
      this.getVehicles();
      this.formService.hideFormLoader('vehicle-form');
      this.showForm = true;
      this.savingShow = false;
      this.savedShow = true;
      this.openDialog('SUCCESS', 'saved');
      setTimeout(() => {
        this.savingDiaShow = false;
      }, 3000);
    }
    catch (e) {
      console.error(e);
    }
  }

  onCompleteError() {
    try {
      this._jDS.contorlLoading(false);
      this.formService.hideFormLoader('vehicle-form');
      this.showForm = true;
      this.savingShow = false;
      this.savedShowError = true;
      this.openDialog('FAILED', 'saving');
      setTimeout(() => {
        this.savingDiaShow = false;
      }, 3000);
      this.saveBtnDisabled = false;
    }
    catch (e) {
      console.error(e);
    }
  }

  onDelete(): void {

    try {
      this.tblConfig.tblData = this.tblConfig.tblData.filter((d: any) => {
        return d !== this.rowData;
      });
      this.formReset();
      this.updateBtnDisabled = true;
      this.deleteBtnDisabled = true;
      //this.openDialog('SUCCESS', 'deleted');
    }
    catch (e) {
      console.error(e);
    }
  }

  onAdd(): void {
    //this.vehicleMainForm.value;
    try {
      this.formService.hideFormMsg("vehicle-error-message");

      var carCompanyCd = this.vehicleMainForm.get('carCompanyCD')?.value;
      var makeCd = this.vehicleMainForm.get('makeCD')?.value;
      var subModel = this.vehicleMainForm.get('subModel')?.value.toUpperCase();
      var makeTypeCode = this.vehicleMainForm.get('makeTypeCode')?.value;

      for (var i = 0; i < this.tblConfig.tblData.length; i++) {
        let x = this.tblConfig.tblData[i];

        if (x['CAR_COMPANY_CD'] == carCompanyCd
          && x['MAKE_CD'] == makeCd
          && (x['ENGINE_SERIES'] == subModel && subModel !== '-')) {
          this.invalidShow = true;
          let errorMessage = this.vehicleMainForm.controls.seats.errors?.pattern ? "No of Seats must be number" : "Record with the same setup already exists.";
          this.formService.showFormMsg("vehicle-error-message", errorMessage, "E");

          return;
        }

        if (subModel.trim().length == 0 || makeTypeCode.trim().length == 0) {
          this.invalidShow = true;
          let errorMessage = "There are missing information. Please provide necessary information needed.";
          this.formService.showFormMsg("vehicle-error-message", errorMessage, "E");
          return;
        }
      }

      if (!this.vehicleMainForm.invalid) {
        this.invalidShow = false;
        let objectToBeAdded = {
          CAR_COMPANY_CD: this.vehicleMainForm.get('carCompanyCD')?.value,
          CAR_COMPANY: this.vehicleMainForm.get('carCompany')?.value,
          MAKE_CD: this.vehicleMainForm.get('makeCD')?.value,
          MAKE_DESC: this.vehicleMainForm.get('makeDesc')?.value,
          CC_WT: this.vehicleMainForm.get('ccWt')?.value,
          SERIES_CD: this.vehicleMainForm.get('subModelCD')?.value,
          ENGINE_SERIES: this.vehicleMainForm
            .get('subModel')
            ?.value.toUpperCase(),
          MAKE_TYPE_CD: this.vehicleMainForm.get('makeTypeCode')?.value,
          SEATS: this.vehicleMainForm.get('seats')?.value,
          GAS_TYPE: this.vehicleMainForm.get('gasType')?.value,
          ACTIVE_TAG: this.vehicleMainForm.get('active')?.value ? 'A' : 'I',
          REMARKS: this.vehicleMainForm.get('remarks')?.value,
          USER_ID: null,
          LAST_UPDATE: null,
          temp: true,
          CONTRACT_TYPE_CD: this.vehicleMainForm.get("contractTypeCd")?.value,
          HIGH_RISK_ID: this.vehicleMainForm.get("highRiskId")?.value,
          MAX_AGE: this.vehicleMainForm.get("maxAge")?.value,
        };
        this.tblConfig.tblData = [objectToBeAdded, ...this.tblConfig.tblData];
        this.formReset();
        this.saveBtnDisabled = false;
        //this.openDialog('SUCCESS', 'added');
      }
      else {
        this.invalidShow = true;
        let errorMessage = this.vehicleMainForm.controls.seats.errors?.pattern ? "No of Seats must be number" : "There are missing information. Please provide necessary information needed.";
        this.formService.showFormMsg("vehicle-error-message", errorMessage, "E");
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  onUpdate() {
    try {
      this.formService.hideFormMsg("vehicle-error-message");
      if (!this.vehicleMainForm.invalid) {
        var subModel = this.vehicleMainForm.get('subModel')?.value.toUpperCase();
        var makeTypeCode = this.vehicleMainForm.get('makeTypeCode')?.value;

        this.invalidShow = false;

        if (subModel.trim().length == 0 || makeTypeCode.trim().length == 0) {
          this.invalidShow = true;
          let errorMessage = "There are missing information. Please provide necessary information needed.";
          this.formService.showFormMsg("vehicle-error-message", errorMessage, "E");
          return;
        }

        let updateRow: { [k: string]: any } = {
          CAR_COMPANY_CD: this.vehicleMainForm.get('carCompanyCD')?.value,
          CAR_COMPANY: this.vehicleMainForm.get('carCompany')?.value,
          MAKE_CD: this.vehicleMainForm.get('makeCD')?.value,
          MAKE_DESC: this.vehicleMainForm.get('makeDesc')?.value,
          CC_WT: this.vehicleMainForm.get('ccWt')?.value,
          SERIES_CD: this.vehicleMainForm.get('subModelCD')?.value,
          ENGINE_SERIES: this.vehicleMainForm
            .get('subModel')
            ?.value.toUpperCase(),
          MAKE_TYPE_CD: this.vehicleMainForm.get('makeTypeCode')?.value,
          SEATS: this.vehicleMainForm.get('seats')?.value,
          GAS_TYPE: this.vehicleMainForm.get('gasType')?.value,
          ACTIVE_TAG: this.vehicleMainForm.get('active')?.value ? 'A' : 'I',
          REMARKS: this.vehicleMainForm.get('remarks')?.value,
          USER_ID: null,
          LAST_UPDATE: null,
          CONTRACT_TYPE_CD: this.vehicleMainForm.get("contractTypeCd")?.value, 
				  HIGH_RISK_ID: this.vehicleMainForm.get("highRiskId")?.value,
				  MAX_AGE: this.vehicleMainForm.get("maxAge")?.value,
        };
        if (this.rowData.temp) {
          updateRow.temp = true;
          this.tblConfig.tblData[this.indexRow] = updateRow;

        }
        else {
          updateRow.onDbButUpdatedTemp = true;
          this.tblConfig.tblData[this.indexRow] = updateRow;

        }
        this.formReset();
        //this.openDialog('SUCCESS', 'updated');
        this.saveBtnDisabled = false;

        this.updateBtnDisabled = true;
        this.deleteBtnDisabled = true;
      }
      else {
        this.invalidShow = true;
        let errorMessage = this.vehicleMainForm.controls.seats.errors?.pattern ? "No of Seats must be number" : "There are missing information. Please provide necessary information needed.";
        this.formService.showFormMsg("vehicle-error-message", errorMessage, "E");

      }
      this.tblConfig.tblData = [...this.tblConfig.tblData];
    }
    catch (e) {
      console.error(e);
    }
  }

  openModelorMakeSelection(selected: string) {
    try {
      if (selected == 'MODEL' || selected == 'MAKE')
        this._matDialog
          .open(VehicleMaintentanceSelectModelMakeComponent, {
            disableClose: true,
            width: '512px',
            data: {
              table: selected,
              makeCode: this.vehicleMainForm.get('carCompanyCD')?.value,
            },
          })
          .afterClosed()
          .subscribe((data: any) => {

            // if (data != '' && data != null && data != undefined) {
            if (data.button === 'OK')
              if (data.content) {
                if (data.table == 'MODEL') {

                  this.vehicleMainForm
                    .get('model')
                    ?.setValue(
                      this.pad(data.content.MAKE_CD, 5) +
                      ' - ' +
                      data.content.MAKE_DESC
                    );
                  this.vehicleMainForm
                    .get('makeCD')
                    ?.setValue(data.content.MAKE_CD);
                  this.vehicleMainForm
                    .get('makeDesc')
                    ?.setValue(data.content.MAKE_DESC);
                } else if (data.table == 'MAKE') {

                  this.vehicleMainForm.get('model')?.setValue(null);
                  this.vehicleMainForm.get('makeCD')?.setValue(null);
                  this.vehicleMainForm.get('makeDesc')?.setValue(null);
                }
                this.vehicleMainForm
                  .get('make')
                  ?.setValue(
                    this.pad(data.content.CAR_COMPANY_CD, 5) +
                    ' - ' +
                    data.content.CAR_COMPANY
                  );
                this.vehicleMainForm
                  .get('carCompanyCD')
                  ?.setValue(data.content.CAR_COMPANY_CD);
                this.vehicleMainForm
                  .get('carCompany')
                  ?.setValue(data.content.CAR_COMPANY);
              }
              else {
                if (data.table == 'MODEL') {
                  this.vehicleMainForm.patchValue({
                    model: null,
                    makeCD: null,
                    makeDesc: null,
                  });
                }
                else {
                  this.vehicleMainForm.patchValue({
                    make: null,
                    carCompanyCD: null,
                    carCompany: null,
                    model: null,
                    makeCd: null,
                    makeDesc: null,
                  });
                }
              }
          });
    }
    catch (e) {
      console.error(e);
    }
  }

  allowNumericDigitsOnly(event: any) {
    try {
      if (/[0-9]/.test(String.fromCharCode(event.keyCode))) {
        return true;
      }
      else {
        event.preventDefault();
        return false;
      }
    }
    catch (e) {
      console.error(e);
      return undefined;
    }
  }

  openModelorMake(selected: string) {
    try {
      const SUBCRUD = {
        disableClose: true,
        width: '512px',
        maxHeight: '90vh',
      };
      if (selected === 'MODEL')
        this._matDialog.open(VehicleMaintenanceModelComponent, SUBCRUD);
      else if (selected === 'MAKE')
        this._matDialog.open(VehicleMaintenanceMakeComponent, SUBCRUD);
    }
    catch (e) {
      console.error(e);
    }
  }

}
