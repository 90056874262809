import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { APICallService } from 'src/app/services/api-call.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';

@Component({
  selector: 'tariff-lov',
  templateUrl: './tariff-lov.component.html',
  styleUrls: ['./tariff-lov.component.css']
})
export class TariffLovComponent implements OnInit {

  private tariffModuleId: string = 'BMM111';
  private selectedData: any;
  public titleLov: string = "Tariff Code".toUpperCase();
  
  tariffTable: any = {
    cols: [],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  };

  private tariffTableColDefault: any[] = [
    {
      key: "TARIFF_CD",
      header: "Tariff Code",
      dataType: "string"
    },
    {
      key: "TARIFF_DESC",
      header: "Tariff Code Description",
      dataType: "string"
    },
  ];

  private tariffTableColDescOnly: any[] = [
    {
      key: "TARIFF_CD",
      header: "Tariff Short Description",
      dataType: "string"
    },
  ];

  constructor(
    private jsonDataService: JsonDataService,
    private dialogRef: MatDialogRef<TariffLovComponent>,
    private _api: APICallService,
    private securityService: SecurityService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    @Inject(MAT_DIALOG_DATA) public fromMainData: any,
  ) {
    if (this.fromMainData.shortDescOnly) {
      this.tariffTable.cols = this.tariffTableColDescOnly;
      this.titleLov = "Tariff Short Description".toUpperCase();
    }
    else {
      this.tariffTable.cols = this.tariffTableColDefault;
    }
    this.getTariffData();
  }

  ngOnInit(): void {
  }

  onRowClick(ev: any) {
    try {
      this.selectedData = ev;
    } catch (e) {
    console.error(e);

    }
  }

  private getTariffData(): void {
    try {
      this.tariffTable.loading = true;
      this.jsonDataService.contorlLoading(true);
      this._api.getTariff({
        moduleId: this.fromMainData.moduleId,
        userId: this.userDetailService.userId,
        type: "LOV"
      }).subscribe({
        next: (data: any) => {
          this.securityService.checkRequestKeyResponse(data, () => {
            this.securityService.hasValidCsrfToken(data, () => {
              let content = JSON.parse(this.jsonDataService.decrypt(data?.response));
              // if (content.moduleId === this.tariffModuleId) {
              this.jsonDataService.contorlLoading(false);
              // this.tariffTable.tblData = content.data.filter((e: any) => e.ACTIVE_TAG == 'A');
              this.tariffTable.tblData = content.filter((e: any) => e.ACTIVE_TAG == 'A');
              this.tariffTable.loading = false;
              // } else {
              //   this.tariffTable.loading = false;
              //   this.jsonDataService.contorlLoading(false);
              //   this.appMessageService.showAppMessage("You do not have the correct privilege to access the data. Kindly contact your administrator for assistance.", "error");
              // }
            });
          });
        },
        error: (e: any) => {
          this.securityService.checkRequestKeyResponse(e, () => {

            this.tariffTable.loading = false;
            this.jsonDataService.contorlLoading(false);
            this.appMessageService.showAppMessage(e, "error");
          });
        }
      });
    }
    catch (e) {
    console.error(e);

      this.tariffTable.loading = false;
      this.jsonDataService.contorlLoading(false);
    }
  }

  onClose(str: string) {
    try {
      if (this.selectedData == '' || this.selectedData == null) {
        this.dialogRef.close({ content: '', button: str });
      }
      else {
        this.dialogRef.close({ content: this.selectedData, button: str });
      }
    } catch (e) {
    console.error(e);

    }
  }

}
