<div class="mtn-container">
    <div class="mtn-title">
        <p>{{ dialogTitle }}</p>
    </div>
    <form>
        <div class="form">
            <p id="upload-voucher-code-error-message" class="form-error-msg"></p>
            <div class="row">
                <div class="field required">
                    <label for="">File Name</label>
                    <div class="side-button">
                        <input type='file' (change)="fileHandlerFunction($event)" />
                        <button class="btn btn2" type="button" (click)="onRead()">Read File</button>
                    </div>
                </div>
            </div>
        </div>
        <osp-table [tblConfig]="dataTableConfig"></osp-table>
        <div class="form-btns">
            <button class="btn btn2" type="button" (click)="onCancel()">Cancel</button>
            <button class="btn btn2" type="button" (click)="onProceed()">Proceed</button>
        </div>
    </form>
</div>