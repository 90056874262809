import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { CustomValidators } from 'src/app/utils/custom-validator';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';

@Component({
  selector: 'system-parameter-maintenance',
  templateUrl: './system-parameter-maintenance.component.html',
  styleUrls: ['./system-parameter-maintenance.component.css']
})
export class SystemParameterMaintenanceComponent implements OnInit {


  selectedRowData: any;
  errorMessage: string = "";
  userId = this.userDetailService.userId;
  formHider: boolean = false;
  datePipe = new DatePipe('en-ph');
  parameterType = this.jsonDataService.data.refCds.filter(function(data: any) {
    return data.rvDomain == "BMM011.PARAM_TYPE"
  });
  moduleId = "BMM011";
  moduleName = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId === 'BMM011' })[0].moduleDesc.toUpperCase();

  //Button Togglers
  addUpdateToggler: boolean = true;
  deleteDisabler: boolean = true;
  saveDisabler: boolean = true;

  //Temp Data
  tempAddData: any = [];
  tempUpdateData: any = [];

  systemParameterTable: any = {
    cols: [
      {
        key: "PARAM_TYPE",
        header: "Parameter Type",
        dataType: "string"
      },
      {
        key: "PARAM_NAME",
        header: "Parameter Name",
        dataType: "string"
      },
      {
        key: "PARAM_VALUE_N",
        header: "Parameter (Number)",
        dataType: "string"
      },
      {
        key: "PARAM_VALUE_V",
        header: "Parameter (Character)",
        dataType: "string"
      },
      {
        key: "PARAM_VALUE_D",
        header: "Parameter (Date)",
        dataType: "date"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  } 

  systemParameterForm = this.fb.group({
    PARAM_TYPE      : ['', Validators.required],
    PARAM_NAME      : ['', [Validators.required, this.duplicateParameterName(), CustomValidators.requiredTrim]],
    PARAM_VALUE_N   : ['', [Validators.pattern("^[0-9].*[0-9]$"), Validators.maxLength(17)]],
    PARAM_VALUE_V   : [''],
    PARAM_VALUE_D   : [''],
    REMARKS         : [''],
    USER_ID         : [{value: '', disabled: true}],
    LAST_UPDATE     : [{value: '', disabled: true}],
  });

  constructor(
    private jsonDataService: JsonDataService,
    private fb: FormBuilder,
    private api: APICallService,
    private formService: FormService,
    private dialog: MatDialog,
    private userDetailService: UserDetailsService,
    private securityService: SecurityService
  ) { }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempAddData!='' || this.tempUpdateData!='') {
      return false;
    } else {
      return true;
    }
  }

  ngOnInit(): void {
    try {
      this.getSystemParameter();
    } catch(e) {
      
    }
  }

  onRowClick(ev: any) {
    try {
      this.selectedRowData = ev;
      if(ev!=null) {
        this.formLoader();
        this.addUpdateToggler = false;
      } else {
        this.formClearer();
        this.addUpdateToggler = true;
      }
      this.deleteEnabler();
      this.parameterTypeDisabler();
    } catch(e) {
      
    }
  }

  addRow() {
    try {
      if(this.systemParameterForm.valid) {
        this.formService.hideFormMsg("system-parameter-error-message");
        this.tableInserter();
        this.tempAddInserter();
        this.formClearer();
        this.saveDisabler = false;
      } else {
        this.errorMessageSetter();
      }
    } catch(e) {
      
    }
  }

  onUpdate() {
    try {
      if(this.systemParameterForm.valid) {
        this.updateTable();
        if(this.deleteDisabler) {
          this.updateDbData();
        } else {
          this.updateNewData();
        }
        this.selectedRowData = '';
        this.addUpdateToggler = true;
        this.formClearer();
        this.saveDisabler = false;
        this.deleteEnabler();
        this.formService.hideFormMsg('system-parameter-error-message');
      } else {
        this.errorMessageSetter();
      }
    } catch(e) {
      
    }
  }

  deleteRow() {
    try {
      let clickedData = this.selectedRowData;
      this.systemParameterTable.tblData = this.systemParameterTable.tblData.filter(function(e: any) {
        return e.PARAM_NAME != clickedData.PARAM_NAME;
      });
      this.tempAddData = this.tempAddData.filter(function(e: any) {
        return e.PARAM_NAME != clickedData.PARAM_NAME;
      });
      this.selectedRowData = '';
      this.formClearer();
      this.deleteDisabler = true;
      this.addUpdateToggler = true;
      if((this.tempAddData=='' || this.tempAddData==null) && (this.tempUpdateData=='' || this.tempUpdateData==null)) {
        this.saveDisabler = true;
      }
    } catch(e) {
      
    }
  }

  onSave() {
    try {
      this.formHider = true;
      this.formService.showFormLoader(null, "system-parameter-form", "Saving.<br>Please wait ...", null, null);
      this.jsonDataService.contorlLoading(true);
      this.api.saveSystemParameter(this.tempAddData).subscribe({
        next: (data: any) => {
            this.securityService.checkRequestKeyResponse(data, () => {
              this.securityService.hasValidCsrfToken(data, () => {
                if(data.status == 'SUCCESS') {
                  this.api.saveSystemParameter(this.tempUpdateData).subscribe({
                      next: (res: any) => {
                          this.securityService.checkRequestKeyResponse(res, () => {
                            this.securityService.hasValidCsrfToken(res, () => {
                              if(res.status == 'SUCCESS') {
                                this.onComplete();
                              } else {
                                  this.onFail();
                              }
                            });
                          });
                      },
                      error: () => {
                          this.onFail();
                      }
                  });
                } else {
                    this.onFail();
                }
              })
            });
        },
        error: () => {
            this.onFail();
        }
      });
    } catch(e) {
      
    }
  }

  duplicateParameterName(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      let parameterNameDuplicate  = this.systemParameterTable.tblData.filter(function(e: any) {
        return e.PARAM_NAME.trim() == control.value.trim();
      });
      if(parameterNameDuplicate!='') {
        return { "systemParameterDuplicateCode": { value: control.value } }
      }
      return null;
    }
  }

  getSystemParameter() {
    try {
      this.systemParameterTable.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.api.getSystemParameter({        
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE"
      }).subscribe((data: any) => {
        this.securityService.checkRequestKeyResponse(data, () => {
          this.securityService.hasValidCsrfToken(data, () => {
            if (data.status === "SUCCESS") {
              data = JSON.parse(this.jsonDataService.decrypt(data.response));
                const newData = data.map((data: any) => {
                  if(data.PARAM_VALUE_N !== null) {
                    return {...data, PARAM_VALUE_N: data.PARAM_VALUE_N.toFixed(2)}
                  }

                  if(data.PARAM_VALUE_D !== null) {
                    return {...data, PARAM_VALUE_D: new Date(data.PARAM_VALUE_D) }
                  }
  
                  return data;
                });
                this.jsonDataService.contorlLoading(false);
                this.systemParameterTable.tblData = newData;
                this.systemParameterTable.loading = false;
            } else {
                this.jsonDataService.contorlLoading(false);
                this.systemParameterTable.loading = false;
            }
          })
        });
      });
    } catch(e) {
      
    }
  }

  formLoader() {
    try {
      this.systemParameterForm.get('PARAM_TYPE')?.disable();
      this.systemParameterForm.get('PARAM_NAME')?.disable();
      // this.systemParameterForm.get('PARAM_VALUE_D')?.setValue(this.selectedRowData.PARAM_VALUE_D);
      this.systemParameterForm.patchValue({
        PARAM_TYPE      : this.selectedRowData.PARAM_TYPE,
        PARAM_NAME      : this.selectedRowData.PARAM_NAME,
        PARAM_VALUE_N   : this.selectedRowData.PARAM_VALUE_N,
        PARAM_VALUE_V   : this.selectedRowData.PARAM_VALUE_V,
        PARAM_VALUE_D   : moment(this.selectedRowData.PARAM_VALUE_D, "MM/DD/YYYY"),
        REMARKS         : this.selectedRowData.REMARKS,
        USER_ID         : this.selectedRowData.USER_ID,
        LAST_UPDATE     : this.selectedRowData.LAST_UPDATE == "0000-00-00 00:00:00" ? "00-00-0000 00:00:00" : this.datePipe.transform(this.selectedRowData.LAST_UPDATE, 'MM/dd/YYYY h:mm:ss a', '+0000'),
      });
    } catch(e) {
      
    }
  }

  formClearer() {
    try {
      this.systemParameterForm.get('PARAM_TYPE')?.enable();
      this.systemParameterForm.get('PARAM_NAME')?.enable();
      this.systemParameterForm.patchValue({
        PARAM_TYPE      : '',
        PARAM_NAME      : '',
        PARAM_VALUE_N   : '',
        PARAM_VALUE_V   : '',
        PARAM_VALUE_D   : '',
        REMARKS         : '',
        USER_ID         : '',
        LAST_UPDATE     : '',
      });
    } catch(e) {
      
    }
  }

  deleteEnabler() {
    try {
      if(this.selectedRowData != null) {
        let tempDeleteFlag = this.tempAddData.filter((e: any) => {
          return e.PARAM_NAME == this.selectedRowData.PARAM_NAME
        });
        if(tempDeleteFlag=='' || tempDeleteFlag==null) {
          this.deleteDisabler = true;
        } else {
          this.deleteDisabler = false;
        }
      } else {
        this.deleteDisabler = true;
      }
      
    } catch(e) {
      
    }
  }

  tableInserter() {
    try {
      this.systemParameterTable.tblData.unshift({
        PARAM_TYPE      : this.systemParameterForm.get('PARAM_TYPE')?.value,
        PARAM_NAME      : this.systemParameterForm.get('PARAM_NAME')?.value,
        PARAM_VALUE_N   : this.systemParameterForm.get('PARAM_VALUE_N')?.value,
        PARAM_VALUE_V   : this.systemParameterForm.get('PARAM_VALUE_V')?.value,
        PARAM_VALUE_D   : this.systemParameterForm.get('PARAM_VALUE_D')?.value ? new Date(this.systemParameterForm.get('PARAM_VALUE_D')?.value) : '',
        REMARKS         : this.systemParameterForm.get('REMARKS')?.value,
        USER_ID         : this.systemParameterForm.get('USER_ID')?.value,
        LAST_UPDATE     : this.systemParameterForm.get('LAST_UPDATE')?.value,
      });
      this.systemParameterTable.tblData = [...this.systemParameterTable.tblData];
    } catch(e) {
      
    }
  }

  tempAddInserter() {
    try {
      this.tempAddData.push({
        PARAM_TYPE      : this.systemParameterForm.get('PARAM_TYPE')?.value,
        PARAM_NAME      : this.systemParameterForm.get('PARAM_NAME')?.value,
        PARAM_VALUE_N   : this.systemParameterForm.get('PARAM_VALUE_N')?.value,
        PARAM_VALUE_V   : this.systemParameterForm.get('PARAM_VALUE_V')?.value,
        PARAM_VALUE_D   : this.datePipe.transform(this.systemParameterForm.get('PARAM_VALUE_D')?.value, 'YYYY/MM/dd'),
        REMARKS         : this.systemParameterForm.get('REMARKS')?.value,
      });
    } catch(e) {
      
    }
  }

  errorMessageSetter() {
    try {
      if(this.systemParameterForm.invalid) {
        for(const field in this.systemParameterForm.controls) {
          const controlName = this.systemParameterForm.get(field);
          if(controlName?.errors?.required) {
            this.errorMessage = 'There are missing information. Please provide necessary information needed.';
          }
          if(controlName?.errors?.systemParameterDuplicateCode) {
            this.errorMessage = 'Parameter Name already exists. Please add a unique Parameter Name.';
          }
          if(controlName?.errors?.pattern || controlName?.errors?.maxlength) {
            this.errorMessage = 'Kindly input valid number for Parameter (Number).';
          }
          if(controlName?.errors?.matDatepickerParse) {
            this.errorMessage = 'Invalid Date. Please input a valid date.';
          }
          this.formService.showFormMsg("system-parameter-error-message", this.errorMessage, "E");
        }
      }
    } catch(e) {
      
    }
  }

  updateTable() {
    try {
      let updateIndex = this.systemParameterTable.tblData.indexOf(this.selectedRowData);
      this.systemParameterTable.tblData.splice(updateIndex, 1, {
        PARAM_TYPE      : this.systemParameterForm.get('PARAM_TYPE')?.value,
        PARAM_NAME      : this.systemParameterForm.get('PARAM_NAME')?.value,
        PARAM_VALUE_N   : this.systemParameterForm.get('PARAM_VALUE_N')?.value,
        PARAM_VALUE_V   : this.systemParameterForm.get('PARAM_VALUE_V')?.value,
        PARAM_VALUE_D   : this.systemParameterForm.get('PARAM_VALUE_D')?.value ? new Date(this.systemParameterForm.get('PARAM_VALUE_D')?.value) : '',
        REMARKS         : this.systemParameterForm.get('REMARKS')?.value,
        USER_ID         : this.systemParameterForm.get('USER_ID')?.value,
        LAST_UPDATE     : this.systemParameterForm.get('LAST_UPDATE')?.value,
      });
      this.systemParameterTable.tblData = [...this.systemParameterTable.tblData];
    } catch(e) {
      
    }
  }

  updateDbData() {
    try {
      let tempDbData = this.tempUpdateData.find(
        (systemParameter: { PARAM_NAME: any }) => systemParameter.PARAM_NAME == this.systemParameterForm.get('PARAM_NAME')?.value
      );
      if(this.tempUpdateData == '' || this.tempUpdateData == null) {
        this.tempUpdateInserter();
      } else if(tempDbData=='' || tempDbData==null) {
        this.tempUpdateInserter();
      } else {
        tempDbData.PARAM_TYPE      = this.systemParameterForm.get('PARAM_TYPE')?.value;
        tempDbData.PARAM_NAME      = this.systemParameterForm.get('PARAM_NAME')?.value;
        tempDbData.PARAM_VALUE_N   = this.systemParameterForm.get('PARAM_VALUE_N')?.value;
        tempDbData.PARAM_VALUE_V   = this.systemParameterForm.get('PARAM_VALUE_V')?.value;
        tempDbData.PARAM_VALUE_D   = this.datePipe.transform(this.systemParameterForm.get('PARAM_VALUE_D')?.value, 'YYYY/MM/dd');
        tempDbData.REMARKS         = this.systemParameterForm.get('REMARKS')?.value;
        tempDbData.USER_ID         = this.systemParameterForm.get('USER_ID')?.value;
        tempDbData.LAST_UPDATE     = this.systemParameterForm.get('LAST_UPDATE')?.value;
      }
    } catch(e) {
      
    }
  }

  tempUpdateInserter() {
    try {
      this.tempUpdateData.push({
        PARAM_TYPE      : this.systemParameterForm.get('PARAM_TYPE')?.value,
        PARAM_NAME      : this.systemParameterForm.get('PARAM_NAME')?.value,
        PARAM_VALUE_N   : this.systemParameterForm.get('PARAM_VALUE_N')?.value,
        PARAM_VALUE_V   : this.systemParameterForm.get('PARAM_VALUE_V')?.value,
        PARAM_VALUE_D   : this.datePipe.transform(this.systemParameterForm.get('PARAM_VALUE_D')?.value, 'YYYY/MM/dd'),
        REMARKS         : this.systemParameterForm.get('REMARKS')?.value,
        USER_ID         : this.systemParameterForm.get('USER_ID')?.value,
        LAST_UPDATE     : this.systemParameterForm.get('LAST_UPDATE')?.value,
      });
    } catch(e) {
      
    }
  }

  updateNewData() {
    try {
      let tempNewConfigdata = this.tempAddData.find(
        (systempParameter: { PARAM_NAME: any }) => systempParameter.PARAM_NAME == this.systemParameterForm.get('PARAM_NAME')?.value
      );
      tempNewConfigdata.PARAM_TYPE      = this.systemParameterForm.get('PARAM_TYPE')?.value;
      tempNewConfigdata.PARAM_NAME      = this.systemParameterForm.get('PARAM_NAME')?.value;
      tempNewConfigdata.PARAM_VALUE_N   = this.systemParameterForm.get('PARAM_VALUE_N')?.value;
      tempNewConfigdata.PARAM_VALUE_V   = this.systemParameterForm.get('PARAM_VALUE_V')?.value;
      tempNewConfigdata.PARAM_VALUE_D   = this.datePipe.transform(this.systemParameterForm.get('PARAM_VALUE_D')?.value, 'YYYY/MM/dd');
      tempNewConfigdata.REMARKS         = this.systemParameterForm.get('REMARKS')?.value;
    } catch(e) {
      
    }
  }

  onComplete() {
    try {
      this.formService.hideFormLoader("system-parameter-form");
      this.formHider = false;
      this.tempAddData = [];
      this.tempUpdateData = [];
      this.saveDisabler = true;
      this.deleteEnabler();
      this.formClearer();
      this.getSystemParameter();
      this.openSaveDialog();
    } catch(e) {
      
    }
  }

  onFail() {
    try {
      this.tempAddData = [];
      this.tempUpdateData = [];
      this.deleteEnabler();
      this.getSystemParameter();
      this.formService.hideFormLoader("system-parameter-form");
      this.formHider = false;
      this.openErrorDialog('Saving system parameter details failed.');
    } catch(e) {
      
    }
  }

  openSaveDialog() {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'SUCCESS',
          content: 'System parameter details Successfully saved!',
        }
      });
    } catch(e) {
      
    }
  }

  openErrorDialog(message: string) {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'ERROR',
          content: message,
        }
      });
    } catch(e) {
      
    }
  }

  // PARAM_VALUE_N
  // PARAM_VALUE_V
  // PARAM_VALUE_D
  parameterTypeDisabler() {
    const type = this.systemParameterForm.get('PARAM_TYPE')?.value;
    if(type === 'N') {
      this.systemParameterForm.get("PARAM_VALUE_N")?.enable();
      this.systemParameterForm.get("PARAM_VALUE_V")?.disable();
      this.systemParameterForm.get("PARAM_VALUE_D")?.disable();
      this.systemParameterForm.patchValue({
        PARAM_VALUE_V: '',
        PARAM_VALUE_D: ''
      });
    } else if(type === 'V') {
      this.systemParameterForm.get("PARAM_VALUE_N")?.disable();
      this.systemParameterForm.get("PARAM_VALUE_V")?.enable();
      this.systemParameterForm.get("PARAM_VALUE_D")?.disable();
      this.systemParameterForm.patchValue({
        PARAM_VALUE_N: '',
        PARAM_VALUE_D: ''
      });
    } else if(type === 'D') {
      this.systemParameterForm.get("PARAM_VALUE_N")?.disable();
      this.systemParameterForm.get("PARAM_VALUE_V")?.disable();
      this.systemParameterForm.get("PARAM_VALUE_D")?.enable();
      this.systemParameterForm.patchValue({
        PARAM_VALUE_V: '',
        PARAM_VALUE_N: ''
      });
    } else {
      this.systemParameterForm.get("PARAM_VALUE_N")?.enable();
      this.systemParameterForm.get("PARAM_VALUE_V")?.enable();
      this.systemParameterForm.get("PARAM_VALUE_D")?.enable();
      this.systemParameterForm.patchValue({
        PARAM_VALUE_V: '',
        PARAM_VALUE_N: '',
        PARAM_VALUE_D: ''
      });
    }
  }
}
