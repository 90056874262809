<section id="fire-premium-rates-per-client-group">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{this.moduleName}}</p>
        </div>
        <div class="form-content">
            <form [formGroup]="paramForm" class="form">
                <div class="form-container-grid top-form">
                    <div class="field required">
                        <label>Client Group</label>
                    </div>
                    <div class="span lov-search">
                        <input readonly formControlName="clientGrpField">
                        <button matSuffix class="btn-search btn" type="button" (click)="openClientGroupDialog()"><mat-icon>search</mat-icon></button>
                    </div>
                    <div class="field required">
                        <label>Property Class</label>
                    </div>
                    <div class="lov-search">
                        <input readonly formControlName="classField">
                        <button matSuffix class="btn-search btn" type="button" (click)="openClassDialog()"><mat-icon>search</mat-icon></button>
                    </div>
                    <div class="field required">
                        <label>Line</label>
                    </div>
                    <div class="lov-search">
                        <input readonly formControlName="lineField">
                        <button matSuffix class="btn-search btn" type="button" (click)="openLineDialog()"><mat-icon>search</mat-icon></button>
                    </div>
                    <div class="field required">
                        <label>Peril</label>
                    </div>
                    <div class="lov-search">
                        <input readonly formControlName="perilField">
                        <button matSuffix class="btn-search btn" type="button" (click)="openPerilDialog()" [disabled]="perilDisabled"><mat-icon>search</mat-icon></button>
                    </div>
                    <div class="field required">
                        <label>Zone</label>
                    </div>
                    <div class="lov-search">
                        <input readonly formControlName="zoneField">
                        <button matSuffix class="btn-search btn" type="button" (click)="openZoneDialog()"><mat-icon>search</mat-icon></button>
                    </div>
                </div>
            </form>
        </div>
        <div class = 'table-line'>
            <osp-table [tblConfig]="firePremRatesPerClientGroupTblConfig" (onRowClick)="onRowClick($event)"></osp-table>
        </div>

        <!-- Loading Animation -->
        <div id="generate-line-form">
            <div class="form-loader"></div>
        </div>

        <!-- Container for Line Fields -->
        <div class="form-content">
            <form [formGroup]="firePremRatesPerClientGroupForm" class="form" *ngIf="noLoadingInProcess">
                <div id="fire-premium-rates-per-client-group-error-message"></div>
                <div class="form-container-grid">
                    <div class="field required">
                        <label>Premium Rate</label>
                    </div>
                    <div class="">
                        <input formControlName="premiumRate" type="text" maxlength="13"  (focusout)="rateFormat('premiumRate')" [readonly]="isLineSelected">
                    </div>
                    <div class="field required">
                        <label>Active</label>
                    </div>
                    <div class="">
                        <select formControlName="activeTag">
                            <option value="A" selected>Yes</option>
                            <option value="I">No</option>
                        </select>
                    </div>
                    <div class="field required">
                        <label>Commission Rate</label>
                    </div>
                    <div class="">
                        <input formControlName="commissionRate" type="text" maxlength="13" (focusout)="rateFormat('commissionRate')">
                    </div>
                    <div class="field required">
                        <label>Default</label>
                    </div>
                    <div class="">
                        <select formControlName="defaultTag">
                            <option value="Yes">Yes</option>
                            <option value="No" selected>No</option>
                        </select>
                    </div>
                    <div class="field required">
                        <label>Effective Date</label>
                    </div>
                    <div class="">
                        <input date-input formControlName="effDateFrom" type="text" placeholder="MM/DD/YYYY" [readonly]="isLineSelected" [matDatepicker]="effDateFromPicker" (input)="datePattern($event)" maxlength="10">
                        <mat-datepicker-toggle matSuffix [for]="effDateFromPicker" *ngIf="!isLineSelected"></mat-datepicker-toggle>
                        <mat-datepicker #effDateFromPicker></mat-datepicker>
                    </div>
                    <div class="field">
                        <label>Expiry Date</label>
                    </div>
                    <div class="">
                        <input date-input formControlName="effDateTo" type="text" placeholder="MM/DD/YYYY" [matDatepicker]="effDateToPicker" (input)="datePattern($event)" maxlength="10">
                        <mat-datepicker-toggle matSuffix [for]="effDateToPicker"></mat-datepicker-toggle>
                        <mat-datepicker #effDateToPicker></mat-datepicker>
                    </div>
                    <div class="field">
                        <label>Remarks</label>
                    </div>
                    <div class="span">
                        <input formControlName="remarks" type="text" maxlength="4000">
                    </div>
                    <div class="field">
                        <label>Last Update User</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="userID">
                    </div>

                    <div class="field">
                        <label>Last Update</label>
                    </div>
                    <div class="">
                        <input type="text" readonly formControlName="lastUserUpdate">
                    </div>
                </div>
                <div class='btn-row'>
                    <button type="button" id='btn-add' *ngIf="!isLineSelected" title='Register a new line' class="btn btn2" value='ADD' type="submit" [disabled]='addDisabled' (click)="insertLine()">ADD</button>
                    <button type="button" id='btn-update' *ngIf="isLineSelected" title='Update a line' class="btn btn2" value='UPDATE' type="submit" (click)="updateLine()">UPDATE</button>
                    <button type="button" id='btn-delete' title='Remove a line' class="btn btn2" value='DELETE' type="submit" [disabled]='deleteDisabled' (click)="deleteLine()">DELETE</button>
                </div> 

                <div class="mtn-title"></div>

                <div class='btn-row'>
                    <button type="button" id='btn-save' title='Save changes on line details' class="btn btn2" value='SAVE' type="submit" (click)="toSave()" [disabled]='saveDisabled'>SAVE</button>
                </div>
            </form>
        </div>
    </div>
</section>