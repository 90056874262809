import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';
import { CheckLovComponent } from '../../common/guard/check-lov/check-lov.component';
import { CustomValidators } from 'src/app/utils/custom-validator';

@Component({
  selector: 'subline-maintenance',
  templateUrl: './subline-maintenance.component.html',
  styleUrls: ['./subline-maintenance.component.css']
})
export class SublineMaintenanceComponent implements OnInit, LeaveConfirmation {

  selectedLine: any;
  selectedSubLine: any;
  datePipe = new DatePipe('en-ph');
  errorMessage: string = "";
  userId = this.userDetailService.userId;
  formHider:boolean = false;
  moduleId: string = 'BMM030';
  moduleName: string = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId === 'BMM030' })[0].moduleDesc.toUpperCase();
  userTypeViewList: any = this.jsonDataService.data.refCds.filter((e: any) => e.rvDomain=="BMM013.USER_TYPE");
  sublineDataList: any;

  // Button Togglers
  addUpdateToggler: boolean = true;
  deleteDisabler: boolean = true;
  saveDisabler: boolean = true;

  // Temp data
  tempAddData: any = [];
  tempUpdateData: any = [];

  sublineTable: any = {
    cols: [
      {
        key: "SUBLINE_CD",
        header: "Subline Code",
        dataType: "string"
      },
      {
        key: "SUBLINE_NAME",
        header: "Subline Name",
        dataType: "string"
      },
      {
        key: "SHORT_DESC",
        header: "Short Description",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "Active",
        dataType: "checkbox"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private apiCallService: APICallService,
    private formService: FormService,
    private jsonDataService: JsonDataService,
    private securityService: SecurityService,
    private userDetailService: UserDetailsService,
    private appMessageService: AppMessageService
  ) { }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempAddData!='' || this.tempUpdateData!='') {
      return false;
    } else {
      return true;
    }
  }

  lineForm = this.fb.group({
    LINE_NAME: [{value: '', disabled: true}],
  });

  sublineForm = this.fb.group({
    ACTIVE_TAG: ['A', [Validators.required]],
    CONDITION_TEXT: [''],
    IMAGE_PATH: [''],
    LAST_UPDATE: [{ value: '', disabled: true }],
    LINE_CD: ['', [Validators.required, CustomValidators.requiredTrim]],
    LONG_DESC: [''],
    REMARKS: [''],
    SEQ_NO: [''],
    USER_TYPE_VIEW: [''],
    AVAIL_TAG: ['', [Validators.required]],
    SHORT_DESC: [''],
    SUBLINE_CD: ['', [Validators.required, this.duplicateSublineCode()]],
    SUBLINE_NAME: ['', [Validators.required, CustomValidators.requiredTrim]],
    SUBLINE_TIME: ['', [Validators.required]],
    USER_ID: [{ value: '', disabled: true }],
  });

  ngOnInit(): void {
    
  }

  getSubline() {
    try {
      this.sublineTable.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.apiCallService.getAllSubline({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE"
      }).subscribe((data: any) => {
            this.securityService.checkRequestKeyResponse(data, () => {
              this.securityService.hasValidCsrfToken(data, () => {
                if (data.status === 'SUCCESS') {
                  this.formClearer();
                  data = JSON.parse(this.jsonDataService.decrypt(data.response));
                  this.sublineDataList = data;
                  this.sublineForm.get('SEQ_NO')?.setValidators([this.duplicateSeqNo(), this.negativeSeqNo()]);
                  this.sublineForm.get('SEQ_NO')?.updateValueAndValidity();
                  this.sublineTable.tblData = data.data.filter((data: any) => data.LINE_CD == this.selectedLine.LINE_CD);
                  this.sublineForm.get('SUBLINE_CD')?.updateValueAndValidity();
                  this.sublineTable.loading = false;
                  this.jsonDataService.contorlLoading(false);
                } else {
                    this.sublineTable.loading = false;
                    this.jsonDataService.contorlLoading(false);
                    this.appMessageService.showAppMessage(data.message, "error");
                }
              });
            });
      });
    } catch(e) {
      
    }
  }

  checkOpenLineDialog() {
    try {
      if(this.tempAddData!='' || this.tempUpdateData!='') {
        this.dialog.open(CheckLovComponent, {
          disableClose: true,
          width: '512px',
          data: {
              title: "Confirmation Message",
              content:
                  "You have unsaved changes. Do you want to continue?"
          }
        }).afterClosed().subscribe((a:any) => {
            if (a) {
              this.tempAddData = [];
              this.tempUpdateData = [];
              this.sublineTable.tblData = [];
              this.openLineDialog();
            }
        });
      } else {
        this.openLineDialog();
      }
    } catch(e) {
      
    }
  }

  openLineDialog() {
    try {
      this.dialog.open(LineLovDialogComponent, {
        width: '700px',
        disableClose: true,
        data: {
          table: 'LINE',
          moduleId: this.moduleId
        }
      }).afterClosed().subscribe(data => {
        if (data.content != '' && data.content != null) {
          this.selectedLine = data.content;
          this.lineForm.get('LINE_NAME')?.setValue(this.selectedLine.LINE_CD==null || this.selectedLine.LINE_CD=='' ? '' : this.selectedLine.LINE_NAME);
          this.sublineForm.get('LINE_CD')?.setValue(this.selectedLine.LINE_CD);
          this.getSubline();
        }
      });
    } catch(e) {
      
    }
  }

  onRowClick(ev: any) {
    try {
      if(ev!=null) {
        this.selectedSubLine = ev;
        this.formLoader();
        this.addUpdateToggler = false;
      } else {
        this.addUpdateToggler = true;
        this.selectedSubLine = '';
        this.formClearer();
      }
      this.deleteEnabler();
    } catch(e) {
      
    }
  }

  addRow() {
    try {
      if(this.formValidator()) {
        this.tableInserter();
        this.tempAddInserter();
        this.formClearer();
        this.deleteDisabler = true;
      }
    } catch(e) {
      
    }
  }

  formLoader() {
    try {
      this.sublineForm.get('SUBLINE_CD')?.disable();
      this.sublineForm.patchValue({
        ACTIVE_TAG:     this.selectedSubLine.ACTIVE_TAG,
        CONDITION_TEXT: this.selectedSubLine.CONDITION_TEXT,
        IMAGE_PATH:     this.selectedSubLine.IMAGE_PATH,
        LAST_UPDATE:    this.datePipe.transform(this.selectedSubLine.LAST_UPDATE, 'MM/dd/YYYY h:mm:ss a', '+0000'),
        LINE_CD:        this.selectedSubLine.LINE_CD,
        LONG_DESC:      this.selectedSubLine.LONG_DESC,
        REMARKS:        this.selectedSubLine.REMARKS,
        SEQ_NO:         this.selectedSubLine.SEQ_NO,
        SHORT_DESC:     this.selectedSubLine.SHORT_DESC,
        SUBLINE_CD:     this.selectedSubLine.SUBLINE_CD,
        SUBLINE_NAME:   this.selectedSubLine.SUBLINE_NAME,
        SUBLINE_TIME:   this.selectedSubLine.SUBLINE_TIME,
        USER_ID:        this.selectedSubLine.USER_ID,
        AVAIL_TAG:      this.selectedSubLine.AVAIL_TAG,
        USER_TYPE_VIEW: this.selectedSubLine.USER_TYPE_VIEW,
      });
    } catch(e) {
      
    }
  }

  formClearer() {
    try {
      this.sublineForm.get('SUBLINE_CD')?.enable();
      this.sublineForm.patchValue({
        ACTIVE_TAG:     'A',
        CONDITION_TEXT: '',
        IMAGE_PATH:     '',
        LAST_UPDATE:    '',
        LONG_DESC:      '',
        REMARKS:        '',
        SEQ_NO:         '',
        SHORT_DESC:     '',
        SUBLINE_CD:     '',
        SUBLINE_NAME:   '',
        SUBLINE_TIME:   '',
        USER_ID:        '',
        USER_TYPE_VIEW: '',
        AVAIL_TAG:      '',
      });
      this.deleteEnabler();
    } catch(e) {
      
    }
  }

  formValidator() {
    try {
      if(this.sublineForm.invalid) {
        for(const field in this.sublineForm.controls) {
          const controlName = this.sublineForm.get(field);
          if(controlName?.errors?.required) {
            this.errorMessage = 'There are missing information. Please provide necessary information needed';
          }
          if(controlName?.errors?.sublineDuplicateCode) {
            this.errorMessage = 'Subline code for this Line already exists. Please add a unique subline code.';
          }
          if(controlName?.errors?.sequenceNoDuplicate) {
            this.errorMessage = 'Sequence No. already exists. Please choose a different Sequence No.';
          }
          if(controlName?.errors?.negativeSeqNo) {
            this.errorMessage = 'Sequence No. only accepts positive whole numbers.';
          }
        }
        this.formService.showFormMsg("subline-error-message", this.errorMessage, "E");
        return false;
      } else {
        this.formService.hideFormMsg("subline-error-message");
        return true;
      }
    } catch(e) {
      return true;
    }
  }

  tableInserter() {
    try {
      this.sublineTable.tblData.unshift({
        ACTIVE_TAG:     this.sublineForm.get('ACTIVE_TAG')?.value,
        CONDITION_TEXT: this.sublineForm.get('CONDITION_TEXT')?.value,
        IMAGE_PATH:     this.sublineForm.get('IMAGE_PATH')?.value,
        LINE_CD:        this.sublineForm.get('LINE_CD')?.value,
        LONG_DESC:      this.sublineForm.get('LONG_DESC')?.value,
        REMARKS:        this.sublineForm.get('REMARKS')?.value,
        SEQ_NO:         this.sublineForm.get('SEQ_NO')?.value,
        SHORT_DESC:     this.sublineForm.get('SHORT_DESC')?.value,
        SUBLINE_CD:     this.sublineForm.get('SUBLINE_CD')?.value,
        SUBLINE_NAME:   this.sublineForm.get('SUBLINE_NAME')?.value,
        SUBLINE_TIME:   this.sublineForm.get('SUBLINE_TIME')?.value,
        USER_TYPE_VIEW: this.sublineForm.get('USER_TYPE_VIEW')?.value,
        AVAIL_TAG:      this.sublineForm.get('AVAIL_TAG')?.value,
      });
      this.sublineTable.tblData = [...this.sublineTable.tblData];
    } catch(e) {
      
    }
  }

  tempAddInserter() {
    try {
      this.tempAddData.push({
        ACTIVE_TAG:     this.sublineForm.get('ACTIVE_TAG')?.value,
        CONDITION_TEXT: this.sublineForm.get('CONDITION_TEXT')?.value,
        IMAGE_PATH:     this.sublineForm.get('IMAGE_PATH')?.value,
        LINE_CD:        this.sublineForm.get('LINE_CD')?.value,
        LONG_DESC:      this.sublineForm.get('LONG_DESC')?.value,
        REMARKS:        this.sublineForm.get('REMARKS')?.value,
        SEQ_NO:         this.sublineForm.get('SEQ_NO')?.value,
        SHORT_DESC:     this.sublineForm.get('SHORT_DESC')?.value,
        SUBLINE_CD:     this.sublineForm.get('SUBLINE_CD')?.value,
        SUBLINE_NAME:   this.sublineForm.get('SUBLINE_NAME')?.value,
        SUBLINE_TIME:   this.sublineForm.get('SUBLINE_TIME')?.value,
        USER_ID:        this.userId,
        USER_TYPE_VIEW: this.sublineForm.get('USER_TYPE_VIEW')?.value,
        AVAIL_TAG:      this.sublineForm.get('AVAIL_TAG')?.value,
      });
      this.saveDisabler=false;
    } catch(e) {
      
    }
  }

  tempUpdateInserter() {
    try {
      this.tempUpdateData.push({
        ACTIVE_TAG:     this.sublineForm.get('ACTIVE_TAG')?.value,
        CONDITION_TEXT: this.sublineForm.get('CONDITION_TEXT')?.value,
        IMAGE_PATH:     this.sublineForm.get('IMAGE_PATH')?.value,
        LINE_CD:        this.sublineForm.get('LINE_CD')?.value,
        LONG_DESC:      this.sublineForm.get('LONG_DESC')?.value,
        REMARKS:        this.sublineForm.get('REMARKS')?.value,
        SEQ_NO:         this.sublineForm.get('SEQ_NO')?.value,
        SHORT_DESC:     this.sublineForm.get('SHORT_DESC')?.value,
        SUBLINE_CD:     this.sublineForm.get('SUBLINE_CD')?.value,
        SUBLINE_NAME:   this.sublineForm.get('SUBLINE_NAME')?.value,
        SUBLINE_TIME:   this.sublineForm.get('SUBLINE_TIME')?.value,
        USER_ID:        this.userId,
        USER_TYPE_VIEW: this.sublineForm.get('USER_TYPE_VIEW')?.value,
        AVAIL_TAG:      this.sublineForm.get('AVAIL_TAG')?.value,
      });
      this.saveDisabler=false;
    } catch(e) {
      
    }
  }

  deleteTableRow() {
    try {
      let clickedData = this.selectedSubLine;
      this.sublineTable.tblData = this.sublineTable.tblData.filter(function(e: any) {
        return e.SUBLINE_CD != clickedData.SUBLINE_CD;
      });
      this.tempAddData = this.tempAddData.filter(function(e: any) {
        return e.SUBLINE_CD != clickedData.SUBLINE_CD;
      });
      this.selectedSubLine = '';
      this.formClearer();
      this.deleteDisabler = true;
      this.addUpdateToggler = true;
      if((this.tempAddData=='' || this.tempAddData==null) && (this.tempUpdateData=='' || this.tempUpdateData==null)) {
        this.saveDisabler = true;
      }
    } catch(e) {
      
    }
  }

  onUpdate() {
    try {
      if(this.formValidator()) {
        this.updateTable();
        if(this.deleteDisabler) {
          this.updateDbData();
        } else {
          this.updateNewData();
        }
        this.selectedSubLine = '';
        this.addUpdateToggler = true;
        this.formClearer();
        this.saveDisabler = false;
      }
    } catch(e) {
      
    }
  }

  updateTable() {
    try {
      let updateIndex = this.sublineTable.tblData.indexOf(this.selectedSubLine);
      this.sublineTable.tblData[updateIndex] = {
        ACTIVE_TAG:     this.sublineForm.get('ACTIVE_TAG')?.value,
        CONDITION_TEXT: this.sublineForm.get('CONDITION_TEXT')?.value,
        IMAGE_PATH:     this.sublineForm.get('IMAGE_PATH')?.value,
        LINE_CD:        this.sublineForm.get('LINE_CD')?.value,
        LONG_DESC:      this.sublineForm.get('LONG_DESC')?.value,
        REMARKS:        this.sublineForm.get('REMARKS')?.value,
        SEQ_NO:         this.sublineForm.get('SEQ_NO')?.value,
        SHORT_DESC:     this.sublineForm.get('SHORT_DESC')?.value,
        SUBLINE_CD:     this.sublineForm.get('SUBLINE_CD')?.value,
        SUBLINE_NAME:   this.sublineForm.get('SUBLINE_NAME')?.value,
        SUBLINE_TIME:   this.sublineForm.get('SUBLINE_TIME')?.value,
        USER_ID:        this.userId,
        USER_TYPE_VIEW: this.sublineForm.get('USER_TYPE_VIEW')?.value,
        AVAIL_TAG:      this.sublineForm.get('AVAIL_TAG')?.value,
      }
      this.sublineTable.tblData = [...this.sublineTable.tblData];
    } catch(e) {
      
    }
  }

  updateNewData() {
    try {
      let tempNewConfigdata = this.tempAddData.find(
        (subline: { SUBLINE_CD: any }) => subline.SUBLINE_CD == this.sublineForm.get('SUBLINE_CD')?.value
      ); 
        tempNewConfigdata.ACTIVE_TAG      = this.sublineForm.get('ACTIVE_TAG')?.value;
        tempNewConfigdata.CONDITION_TEXT  = this.sublineForm.get('CONDITION_TEXT')?.value;
        tempNewConfigdata.IMAGE_PATH      = this.sublineForm.get('IMAGE_PATH')?.value;
        tempNewConfigdata.LINE_CD         = this.sublineForm.get('LINE_CD')?.value;
        tempNewConfigdata.LONG_DESC       = this.sublineForm.get('LONG_DESC')?.value;
        tempNewConfigdata.REMARKS         = this.sublineForm.get('REMARKS')?.value;
        tempNewConfigdata.SEQ_NO          = this.sublineForm.get('SEQ_NO')?.value;
        tempNewConfigdata.SHORT_DESC      = this.sublineForm.get('SHORT_DESC')?.value;
        tempNewConfigdata.SUBLINE_CD      = this.sublineForm.get('SUBLINE_CD')?.value;
        tempNewConfigdata.SUBLINE_NAME    = this.sublineForm.get('SUBLINE_NAME')?.value;
        tempNewConfigdata.SUBLINE_TIME    = this.sublineForm.get('SUBLINE_TIME')?.value;
        tempNewConfigdata.USER_ID         = this.userId;
        tempNewConfigdata.USER_TYPE_VIEW  = this.sublineForm.get('USER_TYPE_VIEW')?.value;
        tempNewConfigdata.AVAIL_TAG       = this.sublineForm.get('AVAIL_TAG')?.value;
    } catch(e) {
      
    }
  }

  updateDbData() {
    try {
      if(this.tempUpdateData=='' || this.tempUpdateData==null) {
        this.tempUpdateInserter();
      } else {
        let tempDbData = this.tempUpdateData.find(
          (subline: { SUBLINE_CD: any }) => subline.SUBLINE_CD == this.sublineForm.get('SUBLINE_CD')?.value
        );
        if(tempDbData=='' || tempDbData==null) {
          this.tempUpdateInserter();
        } else {
          tempDbData.ACTIVE_TAG      = this.sublineForm.get('ACTIVE_TAG')?.value;
          tempDbData.CONDITION_TEXT  = this.sublineForm.get('CONDITION_TEXT')?.value;
          tempDbData.IMAGE_PATH      = this.sublineForm.get('IMAGE_PATH')?.value;
          tempDbData.LINE_CD         = this.sublineForm.get('LINE_CD')?.value;
          tempDbData.LONG_DESC       = this.sublineForm.get('LONG_DESC')?.value;
          tempDbData.REMARKS         = this.sublineForm.get('REMARKS')?.value;
          tempDbData.SEQ_NO          = this.sublineForm.get('SEQ_NO')?.value;
          tempDbData.SHORT_DESC      = this.sublineForm.get('SHORT_DESC')?.value;
          tempDbData.SUBLINE_CD      = this.sublineForm.get('SUBLINE_CD')?.value;
          tempDbData.SUBLINE_NAME    = this.sublineForm.get('SUBLINE_NAME')?.value;
          tempDbData.SUBLINE_TIME    = this.sublineForm.get('SUBLINE_TIME')?.value;
          tempDbData.USER_ID         = this.userId;
          tempDbData.USER_TYPE_VIEW  = this.sublineForm.get('USER_TYPE_VIEW')?.value;
          tempDbData.AVAIL_TAG       = this.sublineForm.get('AVAIL_TAG')?.value;
        }
      }
    } catch(e) {
      
    }
  }

  onSave() {
    try {
        this.formHider = true;
        this.formService.showFormLoader(null, "subline-form", "Saving.<br>Please wait ...", null, null);
        this.jsonDataService.contorlLoading(true);
        this.apiCallService.saveSubline(this.tempAddData).subscribe({
            next: (data: any) => {
                this.securityService.checkRequestKeyResponse(data, () => {
                  this.securityService.hasValidCsrfToken(data, () => {
                    if(data.status == 'SUCCESS') {
                      this.apiCallService.saveSubline(this.tempUpdateData).subscribe({
                          next: (res: any) => {
                              this.securityService.checkRequestKeyResponse(res, () => {
                                this.securityService.hasValidCsrfToken(res, () => {
                                  if(res.status == 'SUCCESS') {
                                    this.onComplete();
                                    this.getSubline();
                                  } else {
                                      this.openErrorDialog('Saving subline details failed.');
                                  }
                                });
                              });
                          },
                          error: () => {
                              this.openErrorDialog('Saving subline details failed.');
                          }
                      });
                    } else {
                        this.openErrorDialog('Saving subline details failed.');
                    }
                  });
                });
            },
            error: () => {
                this.openErrorDialog('Saving subline details failed.');
            }
        });
        this.saveDisabler = true;      
    } catch(e) {
      
    }
  }

  onComplete() {
    try {
      this.formService.hideFormLoader("subline-form");
      this.formHider = false;
      this.openSaveDialog();
      this.tempAddData = [];
      this.tempUpdateData = [];
      this.deleteEnabler();
      this.formClearer();
      this.jsonDataService.contorlLoading(false);
    } catch(e) {
      
    }
  }

  deleteEnabler() {
    try {
      let tempDeleteFlag = this.tempAddData.filter((e: any) => {
        return e.SUBLINE_CD == this.selectedSubLine.SUBLINE_CD
      });
      if(tempDeleteFlag=='' || tempDeleteFlag==null) {
        this.deleteDisabler = true;
      } else {
        this.deleteDisabler = false;
      }
    } catch(e) {
      
    }
  }

  duplicateSublineCode(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      let sublinceCodeDuplicate = this.sublineTable.tblData.filter(function(e: any) {
        return e.SUBLINE_CD.trim()==control.value.trim();
      });
      if(sublinceCodeDuplicate!='') {
        return { "sublineDuplicateCode": { value: control.value } }
      }
      return null;
    }
  }

  duplicateSeqNo(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      let sequenceNoDuplicate = this.sublineDataList.data.filter((sublines: any) => {
        return sublines.SEQ_NO === control.value && this.selectedSubLine.SEQ_NO !== control.value
      });
      if(sequenceNoDuplicate!='') {
        return { "sequenceNoDuplicate": { value: control.value } }
      }
      return null;
    }
  }

  negativeSeqNo(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if(control.value < 0) {
        return { "negativeSeqNo": { value: control.value } }
      }
      return null;
    }    
  }

  openErrorDialog(message: string) {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'ERROR',
          content: message,
        }
      });
      this.jsonDataService.contorlLoading(false);
      this.formHider = false;
    } catch(e) {
      
    }
  }

  openSaveDialog() {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'SUCCESS',
          content: 'Subline details Successfully saved!',
        }
      });
    } catch(e) {
      
    }
  }

  seqNoValidation(ev: any) {
    try {
      const rawSeqNo = ev.value;
      const seqNo = +ev.value;
      if((seqNo < 1 || !Number.isInteger(seqNo)) && !(rawSeqNo === "" || rawSeqNo === null)) {
        this.sublineForm.get("SEQ_NO")?.setValue("");
      } else {
        this.sublineForm.get("SEQ_NO")?.setValue(parseInt(rawSeqNo));
      }
    } catch(e) {
      
    }
  }
}
