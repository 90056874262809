import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { OspTableComponent } from '../../common/osp-table/osp-table.component';
import { SecurityService } from 'src/app/services/security.service'
import { CustomValidators } from 'src/app/utils/custom-validator';

// declare let gtag: Function; Uncomment to use custom events

@Component({
  selector: 'agency-maintenance',
  templateUrl: './agency-maintenance.component.html',
  styleUrls: ['./agency-maintenance.component.css']
})
export class AgencyMaintenanceComponent implements OnInit, LeaveConfirmation {

  // Event push sample.
  // gtag('event', 'TRACK_ME_BUTTON_CLICKED', {
  //   'event_category': 'BUTTON_CLICK',
  //   'event_label': 'Track Me Click',
  //   'value': 'Put a value here that is meaningful with respect to the click event'   });

  @ViewChild('agencyTbl') agencyTbl!: OspTableComponent;

  addUpdateFlag: boolean = true;
  datePipe = new DatePipe('en-us');
  selectedRowComponent: any;
  tempNewData: any = [];
  tempUpdateData: any = [];
  userId: string = this.userDetailService.userId;
  errorMessage: string = '';
  saveDisabler: boolean = true;
  deleteDisabler: boolean = true;
  formHider: boolean = false;
  moduleId: string = 'BMM076';
  public moduleName: string = "";
  activeTags: any = [];

  agencyTable: any = {
    cols: [
      {
        key: "AGENCY_CD",
        header: "Agency Code",
        dataType: "string"
      },
      {
        key: "AGENCY_NAME",
        header: "Agency Name",
        dataType: "string"
      },
      {
        key: "ACTIVE_TAG",
        header: "Active",
        dataType: "checkbox"
      },
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  agencyForm = this.fb.group({
    agencyCode: ['', [this.duplicateAgencyCode(), Validators.required, CustomValidators.requiredTrim]],
    activeTag: ['A', [Validators.required]],
    agencyName: ['', [Validators.required, Validators.maxLength(1000), CustomValidators.requiredTrim]],
    remarks: ['', [Validators.maxLength(4000)]],
    lastUpdateUser: [{value: '', disabled: true}],
    lastUpdate: [{value: '', disabled: true}],
  });

  constructor(
    private fb: FormBuilder,
    private api: APICallService,
    private dialog: MatDialog,
    private jsonDataService: JsonDataService,
    private appMessageService: AppMessageService,
    private userDetailService: UserDetailsService,
    private formService: FormService,
    private securityService: SecurityService
  ) { 
    for (const refCd of this.jsonDataService.data.refCds.filter((a:any) => a.rvDomain === "BMM013.ACTIVE_TAG")) {
      this.activeTags.push({
          value: refCd.rvLowValue,
          text: refCd.rvMeaning
      });
    }
  }

  ngOnInit(): void {
        try {
            this.moduleName = this.jsonDataService.data.moduleList.filter((data: any) => { return data.moduleId === this.moduleId })[0].moduleDesc.toUpperCase();
            this.getAgency();
        } catch(e) {
          
        }
  }

  canDeactivate(): Observable<boolean> | boolean {
    if(this.tempNewData!='' || this.tempUpdateData!='') {
      return false;
    } else {
      return true;
    }
  }

  getAgency() {
    try {
      this.agencyTable.loading = true;
      this.jsonDataService.contorlLoading(true);
      this.api.getAgencyList({
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE"
      }).subscribe({
        next: (data: any) => {
            this.securityService.checkRequestKeyResponse(data, () => {
              this.securityService.hasValidCsrfToken(data, () => {
                if (data.status === "SUCCESS") {
                  data = JSON.parse(this.jsonDataService.decrypt(data.response));
                  this.jsonDataService.contorlLoading(false);
                  this.agencyTable.tblData = data;
                  this.agencyTable.loading = false;
                } else {
                    this.jsonDataService.contorlLoading(false);
                    this.agencyTable.loading = false;
                    this.appMessageService.showAppMessage(data.message, "error");
                }
              });
            });
        },
        error: () => {
          this.agencyTable.loading = false;
          this.jsonDataService.contorlLoading(false);
        },
      });
    } catch(e) {
      
    }
  }

  onRowClick(ev: any) {
    try {
      this.selectedRowComponent = ev;
      this.buttonToggler('onRowClick');
      if(ev==null) {
        this.formClearer();
      } else {
        this.formLoader(ev);
      }
    } catch(e) {
      
    }
  }

  formLoader(ev: any) {
    try {
      this.agencyForm.get('agencyCode')?.disable();
      this.agencyForm.patchValue({
        agencyCode: ev.AGENCY_CD,
        activeTag: ev.ACTIVE_TAG,
        agencyName: ev.AGENCY_NAME,
        remarks: ev.REMARKS,
        lastUpdateUser: ev.USER_ID,
        lastUpdate: this.datePipe.transform(ev.LAST_UPDATE, 'MM/dd/YYYY h:mm:ss a', '+0000'),
      });
    } catch(e) {
      
    }
  }

  formClearer() {
    try {
      this.agencyForm.get('agencyCode')?.enable();
      this.agencyForm.patchValue({
        agencyCode: '',
        activeTag: 'A',
        agencyName: '',
        remarks: '',
        lastUpdateUser: '',
        lastUpdate: '',
      });
      this.selectedRowComponent = '';
    } catch(e) {
      
    }
  }

  buttonToggler(from: any) {
    try {
      if(from=='onRowClick') {
        this.formService.hideFormMsg("agency-error-message");
        if(this.selectedRowComponent==null) {
          this.addUpdateFlag = true;
        } else {
          this.addUpdateFlag = false;
          this.deleteDisabler = this.deleteEnabler(this.selectedRowComponent);
        }
      }
      if(from=='addRow') {
        this.addUpdateFlag = true;
        if((this.tempNewData=='' || this.tempNewData==null) && (this.tempUpdateData=='' || this.tempUpdateData==null)) {
          this.saveDisabler = true;
        } else {
          this.saveDisabler = false;
        }
      }
      if(from=='deleteRow') {
        this.deleteDisabler = true;
        this.addUpdateFlag = true;
        if(this.tempNewData=='' || this.tempNewData==null) {
          this.saveDisabler = true;
        } else {
          this.saveDisabler = false;
        }
      }
    } catch(e) {
      
    }
  }

  fieldValidator() {
    try {
      if(this.agencyForm.invalid) {
        for(const field in this.agencyForm.controls) {
          const controlName = this.agencyForm.get(field); //Use to use specific field
  
          if(controlName?.errors?.required) {
            this.errorMessage = 'There are missing information. Please provide necessary information needed';
          }
          if(controlName?.errors?.agencyCodeDuplicate) {
            this.errorMessage = 'Agency Code already exists. Please add a unique Agency Code.';
          }
        }
        return false;
      } else {
        this.errorMessage = '';
        return true;
      }
    } catch(e) {
      return true;
    }
  }

  addRow() {
    try {
      if(this.fieldValidator()) {
        this.agencyTable.tblData.unshift(this.tableAdder());
        this.agencyTable.tblData = [...this.agencyTable.tblData];
        this.tempNewData.push(this.tempFormValue());
        this.formClearer();
        this.buttonToggler('addRow');
        this.formService.hideFormMsg("agency-error-message");
      } else {
        this.formService.showFormMsg("agency-error-message", this.errorMessage, "E");
      }
    } catch(e) {
      
    }
  }

  tableAdder() {
    try {
      return {
        ACTIVE_TAG: this.agencyForm.get('activeTag')?.value,
        AGENCY_CD: this.agencyForm.get('agencyCode')?.value.toUpperCase(),
        AGENCY_NAME: this.agencyForm.get('agencyName')?.value,
        REMARKS: this.agencyForm.get('remarks')?.value,
      }
    } catch(e) {
      return {
        ACTIVE_TAG: this.agencyForm.get('activeTag')?.value,
        AGENCY_CD: this.agencyForm.get('agencyCode')?.value.toUpperCase(),
        AGENCY_NAME: this.agencyForm.get('agencyName')?.value,
        REMARKS: this.agencyForm.get('remarks')?.value,
      }
    }
  }

  tempFormValue() {
    try {
      return {
        ACTIVE_TAG: this.agencyForm.get('activeTag')?.value,
        AGENCY_CD: this.agencyForm.get('agencyCode')?.value,
        AGENCY_NAME: this.agencyForm.get('agencyName')?.value,
        REMARKS: this.agencyForm.get('remarks')?.value,
        USER_ID: this.userId,
      }
    } catch(e) {
      return {
        ACTIVE_TAG: this.agencyForm.get('activeTag')?.value,
        AGENCY_CD: this.agencyForm.get('agencyCode')?.value,
        AGENCY_NAME: this.agencyForm.get('agencyName')?.value,
        REMARKS: this.agencyForm.get('remarks')?.value,
        USER_ID: this.userId,
      }
    }
  }

  deleteEnabler(ev: any) {
    try {
      let tempDataFlag = this.tempNewData.filter(function(e: any) {
        return e.AGENCY_CD==ev.AGENCY_CD;
      });
      if(tempDataFlag==''||tempDataFlag==null) {
        return true;
      } else {
        return false;
      }
    } catch(e) {
      return false;
    }
  }

  deleteRow() {
    try {
      let clickData = this.selectedRowComponent;
      this.agencyTable.tblData = this.agencyTable.tblData.filter(function(e: any) {
        return e.AGENCY_CD != clickData.AGENCY_CD;
      });
      this.tempNewData = this.tempNewData.filter(function(e: any) {
        return e.AGENCY_CD != clickData.AGENCY_CD;
      });
      this.buttonToggler('deleteRow');
      this.formClearer();
    } catch(e) {
      
    }
  }

  onUpdate() {
    try {
      if(this.fieldValidator()) {
        this.updateTable();
        if(this.deleteDisabler) {
          this.updateDbData();
        } else {
          this.updateNewData();
        }
        this.formService.hideFormMsg("agency-error-message");
        this.buttonToggler('addRow');
        this.formClearer();
        this.deleteDisabler = true;
      } else {
        this.formService.showFormMsg("agency-error-message", this.errorMessage, "E");
      }
    } catch(e) {
      
    }
  }

  updateTable() {
    try {
      let updateIndex = this.agencyTable.tblData.indexOf(this.selectedRowComponent);
      this.agencyTable.tblData[updateIndex] = {
        ACTIVE_TAG: this.agencyForm.get('activeTag')?.value,
        AGENCY_CD: this.agencyForm.get('agencyCode')?.value,
        AGENCY_NAME: this.agencyForm.get('agencyName')?.value,
        REMARKS: this.agencyForm.get('remarks')?.value,
        USER_ID: this.selectedRowComponent.USER_ID,
        LAST_UPDATE: this.selectedRowComponent.LAST_UPDATE,
      };
      this.agencyTable.tblData = [...this.agencyTable.tblData];
    } catch(e) {
      
    }
  }

  updateNewData() {
    try {
      let tempNewConfigData = this.tempNewData.find(
        (agency: { AGENCY_CD: any; }) => agency.AGENCY_CD == this.agencyForm.get('agencyCode')?.value
      );
  
      //UPDATES TEMP DATA
      tempNewConfigData.ACTIVE_TAG = this.agencyForm.get('activeTag')?.value;
      tempNewConfigData.AGENCY_CD = this.agencyForm.get('agencyCode')?.value;
      tempNewConfigData.AGENCY_NAME = this.agencyForm.get('agencyName')?.value;
      tempNewConfigData.REMARKS = this.agencyForm.get('remarks')?.value;
      tempNewConfigData.USER_ID = this.userId;
    } catch(e) {
      
    }
  }

  updateDbData() {
    try {
      if(this.tempUpdateData == '' || this.tempUpdateData == null) {
        this.tempUpdateData.push(this.tempFormValue());
      } else {
        let tempDbData = this.tempUpdateData.find(
          (agency: { AGENCY_CD: any }) => agency.AGENCY_CD == this.agencyForm.get('agencyCode')?.value
        );
        if(tempDbData=='' || tempDbData==null) {
          this.tempUpdateData.push(this.tempFormValue());
        } else {
          //UPDATES TEMP DATA
          tempDbData.ACTIVE_TAG = this.agencyForm.get('activeTag')?.value;
          tempDbData.AGENCY_CD = this.agencyForm.get('agencyCode')?.value;
          tempDbData.AGENCY_NAME = this.agencyForm.get('agencyName')?.value;
          tempDbData.REMARKS = this.agencyForm.get('remarks')?.value;
          tempDbData.USER_ID = this.userId;
        }
      } 
    } catch(e) {
      
    }
  }

  onSave() {
    try {
      this.formHider = true;
      this.jsonDataService.contorlLoading(true);
      this.api.saveAgency(this.tempNewData).subscribe({
            next: (data: any) => {
                this.securityService.checkRequestKeyResponse(data, () => {
                  this.securityService.hasValidCsrfToken(data, () => {
                    if(data.status == 'SUCCESS') {
                      this.api.saveAgency(this.tempUpdateData).subscribe({
                          next: (res: any) => {
                              this.securityService.checkRequestKeyResponse(res, () => {
                                this.securityService.hasValidCsrfToken(res, () => {
                                  if(res.status == 'SUCCESS') {
                                    this.onComplete('Agency details Successfully saved!');
                                    this.getAgency();
                                    this.jsonDataService.contorlLoading(false);
                                  } else {
                                      this.openErrorDialog('Saving agency details failed.');
                                      this.jsonDataService.contorlLoading(false);
                                  }
                                });
                              });
                          },
                          error: () => {
                              this.openErrorDialog('Saving agency details failed.');
                          }
                      });
                    } else {
                        this.openErrorDialog('Saving agency details failed.');
                    }
                  });
                });
            },
            error: () => {
                this.openErrorDialog('Saving agency details failed.');
            }
      });
    } catch(e) {
      
    }
  }

  onComplete(message: string) {
    try {
      this.formService.hideFormLoader("agency-form");
      this.formHider = false;
      this.openSaveDialog(message);
      this.tempUpdateData = [];
      this.tempNewData = [];
      this.buttonToggler('addRow');
    } catch(e) {
      
    }
  }

  openErrorDialog(message: string) {
    try {
      this.formHider = false;
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'ERROR',
          content: message,
        }
      });
    } catch(e) {
      
    }
  }

  openSaveDialog(message: string) {
    try {
      this.dialog.open(DialogMsgComponent, {
        disableClose: true,
        width: "500px",
        data: {
          title: 'SUCCESS',
          content: message,
        }
      });
    } catch(e) {
      
    }
  }

  duplicateAgencyCode(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
        let agencyCodeDuplicate = this.agencyTable.tblData.filter(function(e: any) {
          return e.AGENCY_CD.trim()==control.value.trim();
        });
        if(agencyCodeDuplicate!='') {
            return { "agencyCodeDuplicate": { value: control.value } }
        }
        return null;
    }
  }

  autoUppercase(ev: any) {
    try{
      ev.target.value = ev.target.value.toUpperCase();
    }
    catch(e) {
      
    }
  }

}
