import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { PerilLovComponent } from '../../common/peril-lov/peril-lov.component';
import { LineLovDialogComponent } from '../../common/line-lov-dialog/line-lov-dialog.component';
import { PlanLovComponent } from '../../common/plan-lov/plan-lov.component';
import { PlanSubgrpLovComponent } from '../../common/plan-subgrp-lov/plan-subgrp-lov.component';

@Component({
  selector: 'benefits-coverage-per-plan-peril-maintenance',
  templateUrl: './benefits-coverage-per-plan-peril-maintenance.component.html',
  styleUrls: ['./benefits-coverage-per-plan-peril-maintenance.component.css']
})
export class BenefitsCoveragePerPlanPerilMaintenanceComponent implements OnInit, LeaveConfirmation {

  moduleId: string = 'BMM151';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc?.toUpperCase() ?? "";

  constructor(private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    public _formService: FormService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService) { 
      this.userIdLoggedIn = this._userDetailService.userId ?? "";
    }

  ngOnInit(): void {
    this.btns.benCovPerPlanPeril.addDisabled = true;
    this.forms.benCovPerPlanPerilForm.disable();
  }

  canDeactivate(): boolean | Observable<boolean> {
    if(this.tempData!='') {
      return false;
    } else {
      return true;
    }
  }

  /* Boolean Stuffs */

  public btns = {
    benCovPerPlanPeril: {
      addDisabled: true,
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      perilDisabled: true
    },
    lov: {
      lineDisabled: false,
      sublineDisabled: true,
      planDisabled: true,
      planSubgrpDisabled: true
    }
  }

  public showForm: boolean = true;

  private rowData: any;
  private tempDataRow: any;
  private errorMessage: string = "";
  private userIdLoggedIn: string;
  private tempData: any = [];

  public forms = {
    lineSublinePlanLovForm: this.fb.group({
      lineCd: [null],
      lineName: [null],
      lineCdDisp: [{ value: '', disabled: 'true' }],
      sublineCd: [null],
      sublineName: [null],
      sublineCdDisp: [{ value: '', disabled: 'true' }],
      planCd: [null],
      planName: [null],
      planCdDisp: [{ value: '', disabled: 'true' }],
      planSubgrp: [null],
      planSubgrpTitle: [null],
      planSubgrpDisp: [{ value: '', disabled: 'true' }]
    }),
    benCovPerPlanPerilForm: this.fb.group({
      lineCd: [null],
      sublineCd: [null],
      planCd: [null],
      planSubgrp: [null],
      perilCd: [null],
      perilName: [null],
      perilField: [{ value: '', disabled: 'true' }, [Validators.required]],
      defaultTSI: [null],
      noOfDays: [null],
      remarks: [null, [Validators.maxLength(4000)]],
      userID: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }]
    }),
    resetBenCovPerPlanPerilForm: () => {
      this.forms.benCovPerPlanPerilForm.reset();
      this.forms.benCovPerPlanPerilForm.patchValue({
        activeTag: 'A',
        lineCd: this.forms.lineSublinePlanLovForm.get('lineCd')?.value,
        sublineCd: this.forms.lineSublinePlanLovForm.get('sublineCd')?.value,
        planCd: this.forms.lineSublinePlanLovForm.get('planCd')?.value,
        planSubgrp: this.forms.lineSublinePlanLovForm.get('planSubgrp')?.value,
      });
      this.btns.benCovPerPlanPeril.updateDisabled = this.btns.benCovPerPlanPeril.deleteDisabled = true;
      this.btns.benCovPerPlanPeril.perilDisabled = false;
      this._formService.hideFormMsg("ben-cov-per-plan-peril-error-message");
    },
    resetLineSublineLovForm: () => {
      this.forms.lineSublinePlanLovForm.reset();
    },
    resetAllForms: () => {
      this.forms.resetLineSublineLovForm();
      this.forms.resetBenCovPerPlanPerilForm();
    }
  }

  private benCovPerPlanPerilFormManipulate = {
    dbToForm: (data: any) => {
      return {
        perilCd: data.PERIL_CD,
        perilName: data.PERIL_LNAME,
        perilField: data.PERIL_CD?.toString().padStart(3, 0) + " - " + data.PERIL_LNAME,
        defaultTSI: data.DEFAULT_TSI==null ? null : this._formService.setToAmountFormat(data.DEFAULT_TSI),
        noOfDays: data.NO_OF_DAYS,
        remarks: data.REMARKS,
        userID: data.USER_ID,
        lastUpdate: data.LAST_UPDATE,
      };
    },
    formToDb: (data: any, mode: any) => {
      return {
        LINE_CD: data.lineCd,
        SUBLINE_CD: data.sublineCd,
        PLAN_CD: data.planCd,
        PLAN_SUBGRP: data.planSubgrp,
        PERIL_CD: data.perilCd,
        PERIL_LNAME: data.perilName,
        PERIL_FIELD: data.perilCd?.toString().padStart(3, 0) + " - " + data.perilName,
        DEFAULT_TSI: data.defaultTSI=='' || data.defaultTSI==null ? null : parseFloat(this._formService.setToNumberWithDecimal(data.defaultTSI)),
        NO_OF_DAYS: data.noOfDays,
        REMARKS: data.remarks,
        USER_ID: this.userIdLoggedIn,
        LAST_UPDATE: null,
        MODE: mode
      };
    },
    formToTemp: (data: any, mode: any) => {
      return {
        LINE_CD: data.lineCd,
        SUBLINE_CD: data.sublineCd,
        PLAN_CD: data.planCd,
        PLAN_SUBGRP: data.planSubgrp,
        PERIL_CD: data.perilCd,
        PERIL_LNAME: data.perilName,
        PERIL_FIELD: data.perilCd?.toString().padStart(3, 0) + " - " + data.perilName,
        DEFAULT_TSI: data.defaultTSI=='' || data.defaultTSI==null ? null : parseFloat(this._formService.setToNumberWithDecimal(data.defaultTSI)).toFixed(2),
        NO_OF_DAYS: data.noOfDays,
        REMARKS: data.remarks,
        USER_ID: this.userIdLoggedIn,
        LAST_UPDATE: null,
        MODE: mode
      };
    }
  }

  public benCovPerPlanPerilTblConfig = {
    cols: [
      {
        key: "PERIL_CD",
        header: "Peril Code",
        dataType: "padNumber",
        width: '15%'
      },
      {
        key: "PERIL_LNAME",
        header: "Peril",
        dataType: "string",
      },
      {
        key: "DEFAULT_TSI",
        header: "Default Amount Covered",
        dataType: "currency",
        width: '20%'
      },
      {
        key: "NO_OF_DAYS",
        header: "No. of Days",
        dataType: "string",
        width: '15%'
      }
    ],
    tblData: [] as any[],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  public openLov = {
    line: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'LINE',
          moduleId: this.moduleId
        },
      }
      this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button === 'OK') {
          this.forms.resetBenCovPerPlanPerilForm();
          if (output) {
            this.forms.lineSublinePlanLovForm.patchValue({
              lineCd: output.LINE_CD,
              lineName: output.LINE_NAME,
              lineCdDisp: `${output.LINE_CD} - ${output.LINE_NAME}`,
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null,
              planCd: null,
              planName: null,
              planCdDisp: null,
              planSubgrp: null,
              planSubgrpTitle: null,
              planSubgrpDisp: null
            });
            this.forms.benCovPerPlanPerilForm.patchValue({
              lineCd: output.LINE_CD,
              sublineCd: null,
              planCd: null,
              planSubgrp: null
            });
            this.btns.lov.sublineDisabled = false;
            this.btns.benCovPerPlanPeril.addDisabled = true;
            this.tempData = [];
          }
          else {
            this.forms.lineSublinePlanLovForm.patchValue({
              lineCd: null,
              lineName: null,
              lineCdDisp: null,
              sublineCd: null,
              sublineName: null,
              sublineCdDisp: null,
              planCd: null,
              planName: null,
              planCdDisp: null,
              planSubgrp: null,
              planSubgrpTitle: null,
              planSubgrpDisp: null
            });
            this.forms.benCovPerPlanPerilForm.patchValue({
              lineCd: output.LINE_CD,
              sublineCd: null,
              planCd: null,
              planSubgrp: null
            });
            this.btns.lov.sublineDisabled = true;
            this.btns.benCovPerPlanPeril.addDisabled = true;
          }
          this.benCovPerPlanPerilTblConfig.tblData = [];
          this.btns.benCovPerPlanPeril.perilDisabled = true;
          this.btns.benCovPerPlanPeril.saveDisabled = true;
        }
      });
    },
    subline: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'SUBLINE',
          moduleId: this.moduleId
        },
      }
      LOVOPTIONS.data.lineCd = this.forms.lineSublinePlanLovForm.get('lineCd')?.value;
      this._matDialog.open(LineLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOutput: any) => {
        let output = dataOutput.content;
        if (output) {
          this.forms.resetBenCovPerPlanPerilForm();
          this.forms.lineSublinePlanLovForm.patchValue({
            lineCd: output.LINE_CD,
            lineName: output.LINE_NAME,
            lineCdDisp: `${output.LINE_CD} - ${output.LINE_NAME}`,
            sublineCd: output.SUBLINE_CD,
            subLineName: output.SUBLINE_NAME,
            sublineCdDisp: `${output.SUBLINE_CD} - ${output.SUBLINE_NAME}`,
            planCd: null,
            planName: null,
            planCdDisp: null,
            planSubgrp: null,
            planSubgrpTitle: null,
            planSubgrpDisp: null
          });
          this.forms.benCovPerPlanPerilForm.patchValue({
            lineCd: output.LINE_CD,
            sublineCd: output.SUBLINE_CD,
            planCd: null,
            planSubgrp: null
          });
          this.btns.lov.planDisabled = false;
          this.btns.benCovPerPlanPeril.perilDisabled = true;
          this.tempData = [];
        } 
        else {
          this.forms.lineSublinePlanLovForm.patchValue({
            sublineCd: null,
            sublineName: null,
            sublineCdDisp: null,
            planCd: null,
            planName: null,
            planCdDisp: null,
            planSubgrp: null,
            planSubgrpTitle: null,
            planSubgrpDisp: null
          });
          this.forms.benCovPerPlanPerilForm.patchValue({
            lineCd: null,
            sublineCd: null,
            planCd: null,
            planSubgrp: null
          });
          this.btns.benCovPerPlanPeril.addDisabled = true;
        }
        this.benCovPerPlanPerilTblConfig.tblData = [];
        this.btns.benCovPerPlanPeril.saveDisabled = true;
      });
    },
    plan: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'PLAN',
          moduleId: this.moduleId,
          lineCd: this.forms.lineSublinePlanLovForm.get('lineCd')?.value,
          sublineCd: this.forms.lineSublinePlanLovForm.get('sublineCd')?.value,
        },
      };
      this._matDialog.open(PlanLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button?.toUpperCase() === 'OK') {
          this.forms.resetBenCovPerPlanPerilForm();
          if (output.PLAN_CD) {
            this.forms.lineSublinePlanLovForm.patchValue({
              planCd: output.PLAN_CD,
              planName: output.PLAN_NAME,
              planCdDisp: `${output.PLAN_CD} - ${output.PLAN_NAME}`,
              planSubgrp: null,
              planSubgrpTitle: null,
              planSubgrpDisp: null
            });
            this.forms.benCovPerPlanPerilForm.patchValue({
              planCd: output.PLAN_CD,
              planSubgrp: null
            });
            this.btns.lov.planSubgrpDisabled = false;
            this.tempData = [];
          }
          else {
            this.forms.lineSublinePlanLovForm.patchValue({
              planCd: null,
              planName: null,
              planCdDisp: null,
              planSubgrp: null,
              planSubgrpTitle: null,
              planSubgrpDisp: null
            });
            this.forms.benCovPerPlanPerilForm.patchValue({
              planCd: null,
              planSubgrp: null
            });
            this.btns.benCovPerPlanPeril.addDisabled = true;
          }
          this.benCovPerPlanPerilTblConfig.tblData = [];
          this.btns.benCovPerPlanPeril.perilDisabled = true;
          this.btns.benCovPerPlanPeril.saveDisabled = true;
        }
      });
    },
    planSubgrp: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'PLAN',
          moduleId: this.moduleId,
          lineCd: this.forms.lineSublinePlanLovForm.get('lineCd')?.value,
          sublineCd: this.forms.lineSublinePlanLovForm.get('sublineCd')?.value,
          planCd: this.forms.lineSublinePlanLovForm.get('planCd')?.value
        },
      };
      this._matDialog.open(PlanSubgrpLovComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (dataOut.button?.toUpperCase() === 'OK') {
          this.forms.resetBenCovPerPlanPerilForm();
          if (output.PLAN_SUBGRP) {
            this.forms.lineSublinePlanLovForm.patchValue({
              planSubgrp: output.PLAN_SUBGRP,
              planSubgrpTitle: output.PLAN_SUBGRP_TITLE,
              planSubgrpDisp: `${output.PLAN_SUBGRP} - ${output.PLAN_SUBGRP_TITLE}`,
            });
            this.forms.benCovPerPlanPerilForm.patchValue({
              planSubgrp: output.PLAN_SUBGRP
            });
            this.btns.benCovPerPlanPeril.addDisabled = false;
          }
          else {
            this.forms.lineSublinePlanLovForm.patchValue({
              planSubgrp: null,
              planSubgrpTitle: null,
              planSubgrpDisp: null,
            });
            this.btns.benCovPerPlanPeril.addDisabled = true;
          }
          this.forms.benCovPerPlanPerilForm.enable();
          this.btns.benCovPerPlanPeril.perilDisabled = false;
          this.tempData = [];
          this.getData.benCovPerPlanPeril();
        }
      });
    },
    peril: () => {
      let perilCdExists: any[] = this.benCovPerPlanPerilTblConfig.tblData.map((row: any) => { return +row.PERIL_CD; }).filter(Boolean);
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          table: 'PERIL',
          lineCd: this.forms.lineSublinePlanLovForm.get('lineCd')?.value,
          moduleId: this.moduleId,
          perilCdExclude: perilCdExists
        },
      };
      this._matDialog.open(PerilLovComponent, LOVOPTIONS).afterClosed().subscribe((lovData: any) => {
        if (lovData?.content?.PERIL_CD) {
          const data = lovData?.content;
          this.forms.benCovPerPlanPerilForm.patchValue({
            perilCd: data.PERIL_CD?.toString().padStart(2, 0),
            perilName: data.PERIL_LNAME,
            perilField: `${data.PERIL_CD?.toString().padStart(2, 0)} - ${data.PERIL_LNAME}`
          });
        }
        else {
          this.forms.benCovPerPlanPerilForm.patchValue({
            perilCd: null,
            perilCdName: null,
            perilField: null
          });
        }
      });
    }
  };

  private getData = {
    benCovPerPlanPeril: () => {
      try {
        this.benCovPerPlanPerilTblConfig.tblData = [];
        this.benCovPerPlanPerilTblConfig.loading = true;
        this._jDS.contorlLoading(true);
        this._api.getBenefitsCoveragePerPlanPeril({
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          type: "MODULE"
        }).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                let content = JSON.parse(this._jDS.decrypt(data.response));
                this._jDS.contorlLoading(false);
                this.benCovPerPlanPerilTblConfig.tblData = content.data.filter((d: any) => 
                  d.LINE_CD == this.forms.lineSublinePlanLovForm.get('lineCd')?.value 
                  && d.SUBLINE_CD == this.forms.lineSublinePlanLovForm.get('sublineCd')?.value
                  && d.PLAN_CD == this.forms.lineSublinePlanLovForm.get('planCd')?.value
                  && d.PLAN_SUBGRP == this.forms.lineSublinePlanLovForm.get('planSubgrp')?.value);
                this.btns.benCovPerPlanPeril.addDisabled = false;
                this.benCovPerPlanPerilTblConfig.loading = false;
              });
            });
          },
          error: (error: any) => {
            this.benCovPerPlanPerilTblConfig.loading = false;
            this._jDS.contorlLoading(false);
          }
        });
      }
      catch (e) {
        console.error(e);
      }
    }
  }

  onRowClick(ev: any) {
    try {
      ev.update = true;
      this.rowData = ev;
      this.populateFormFromTable();
    }
    catch (e) {
      this.forms.resetBenCovPerPlanPerilForm();
      this.rowData = null;
      //this.indexRow = null;
    }
  }

  populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.benCovPerPlanPeril.perilDisabled = true;
      this.btns.benCovPerPlanPeril.updateDisabled = !data.update;
      this.btns.benCovPerPlanPeril.deleteDisabled = !data.temp;
      this.tempDataRow = !data.temp;
      this.forms.benCovPerPlanPerilForm.patchValue(this.benCovPerPlanPerilFormManipulate.dbToForm(data));
    }
    catch (e) {
      console.error(e);
    }
  }

  public onAdd() {
    try {
      this._formService.hideFormMsg("ben-cov-per-plan-peril-error-message");
      if (!this.defaultTSICheck()) {
        this._formService.showFormMsg("ben-cov-per-plan-peril-error-message", "Invalid value in Default Amount Covered. The value entered exceeded the maximum limit.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("ben-cov-per-plan-peril-error-message");
        }, 10000);
      }
      else if (this.forms.benCovPerPlanPerilForm.valid) {
        let rowToBeAdded: { [key: string]: any } = this.benCovPerPlanPerilFormManipulate.formToDb(this.forms.benCovPerPlanPerilForm.value, 'I');
        let rowToBeAddedTemp: { [key: string]: any } = this.benCovPerPlanPerilFormManipulate.formToTemp(this.forms.benCovPerPlanPerilForm.value, 'I');
        rowToBeAdded.temp = true;
        this.tempData.push(rowToBeAddedTemp);
        this.benCovPerPlanPerilTblConfig.tblData = [rowToBeAdded, ...this.benCovPerPlanPerilTblConfig.tblData];
        this.forms.resetBenCovPerPlanPerilForm();
        this.btns.benCovPerPlanPeril.saveDisabled = false;
      }
      else {
        this.showErrorValidator.benCovPerPlanPeril();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public onDelete() {
    try {
      let clickData = this.rowData;
      this.forms.benCovPerPlanPerilForm.get('perilField')?.enable();
      let rowToBeDeleted: { [key: string]: any } = this.benCovPerPlanPerilFormManipulate.formToDb(this.forms.benCovPerPlanPerilForm.value, 'D');
      let tempInsertChecker = this.tempData.find(
        (p: { PERIL_CD: any; }) => p.PERIL_CD == clickData.PERIL_CD && clickData.MODE == 'I');
      this.benCovPerPlanPerilTblConfig.tblData = this.benCovPerPlanPerilTblConfig.tblData.filter((d: any) => {
        return d !== this.rowData;
      });

      if (tempInsertChecker!=null && tempInsertChecker!='') {
        this.tempData = this.tempData.filter(
          function(e: any) {
            return e.PERIL_CD != clickData.PERIL_CD;
          }
        );
        if (this.tempData==null || this.tempData=='') {
          this.btns.benCovPerPlanPeril.saveDisabled = true;
        }
      }
      else {
        this.tempData.push(rowToBeDeleted);
        this.btns.benCovPerPlanPeril.saveDisabled = false;
      }
      this.forms.resetBenCovPerPlanPerilForm();
    }
    catch (e) {
      console.error(e);
    }
  }

  public onUpdate() {
    try {
      this._formService.hideFormMsg("ben-cov-per-plan-peril-error-message");
      if (!this.defaultTSICheck()) {
        this._formService.showFormMsg("ben-cov-per-plan-peril-error-message", "Invalid value in Default Amount Covered. The value entered exceeded the maximum limit.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("ben-cov-per-plan-peril-error-message");
        }, 10000);
      }
      else if (this.forms.benCovPerPlanPerilForm.valid) {
        this.forms.benCovPerPlanPerilForm.get('perilField')?.enable();
        let clickData = this.rowData;
        let toBeUpdatedIndex = this.benCovPerPlanPerilTblConfig.tblData.indexOf(this.rowData);
        let benCovPerPlanPerilCdRowValue = this.rowData.PERIL_CD;
        let rowToBeUpdated: { [key: string]: any } = this.benCovPerPlanPerilFormManipulate.formToDb(this.forms.benCovPerPlanPerilForm.value, 'U');
        let rowToBeUpdatedTemp: { [key: string]: any } = this.benCovPerPlanPerilFormManipulate.formToTemp(this.forms.benCovPerPlanPerilForm.value, 'U');
        this.forms.benCovPerPlanPerilForm.get('perilField')?.disable();
        let tempInsertChecker = this.tempData.find(
          (m: { PERIL_CD: any }) => 
            m.PERIL_CD == clickData.PERIL_CD && clickData.MODE == 'I');
        let tempUpdateChecker = this.tempData.find(
          (m: { PERIL_CD: any }) => 
            m.PERIL_CD == clickData.PERIL_CD && clickData.MODE == 'U');
        if (this.rowData.temp) {
          rowToBeUpdated.temp = true;
        }
        else {
          rowToBeUpdated.onDbButUpdatedTemp = true;
          rowToBeUpdated.PERIL_CD = benCovPerPlanPerilCdRowValue;
        }
        if (tempInsertChecker!=null && tempInsertChecker!='') {
          //tempInsertChecker = rowToBeUpdated;
          tempInsertChecker.DEFAULT_TSI = rowToBeUpdatedTemp.DEFAULT_TSI;
          tempInsertChecker.NO_OF_DAYS = rowToBeUpdatedTemp.NO_OF_DAYS;
          tempInsertChecker.REMARKS = rowToBeUpdatedTemp.REMARKS;
          tempInsertChecker.USER_ID = rowToBeUpdatedTemp.USER_ID;
          tempInsertChecker.MODE = 'I';
          rowToBeUpdatedTemp.MODE = 'I';
        }
        else if (tempUpdateChecker!=null && tempUpdateChecker!='') {
          tempUpdateChecker.DEFAULT_TSI = rowToBeUpdatedTemp.DEFAULT_TSI;
          tempUpdateChecker.NO_OF_DAYS = rowToBeUpdatedTemp.NO_OF_DAYS;
          tempUpdateChecker.REMARKS = rowToBeUpdatedTemp.REMARKS;
          tempUpdateChecker.USER_ID = rowToBeUpdatedTemp.USER_ID;
          tempUpdateChecker.MODE = 'U';
        }
        else { 
          this.tempData.push(rowToBeUpdatedTemp);
        }
        this.forms.resetBenCovPerPlanPerilForm();
        this.benCovPerPlanPerilTblConfig.tblData[toBeUpdatedIndex] = rowToBeUpdated;
        this.btns.benCovPerPlanPeril.saveDisabled = false;
        this.benCovPerPlanPerilTblConfig.tblData = [... this.benCovPerPlanPerilTblConfig.tblData];
      }
      else {
        this.showErrorValidator.benCovPerPlanPeril();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public onSave() {
    try {
      this.btns.benCovPerPlanPeril.saveDisabled = true;
      this.showForm = false;
      this._formService.showFormLoader(null, "ben-cov-per-plan-peril-maintenance-loading", "Saving.<br>Please wait ...", null, null);
      if (this.tempData.length > 0) {
        this._api.saveBenefitsCoveragePerPlanPeril(this.tempData).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                if (data.status === 'SUCCESS') {
                  this.onComplete.benCovPerPlanPeril();
                }
                else {
                  this.onComplete.benCovPerPlanPerilError();
                }
              });
            });
          },
          error: (data: any) => {
            this.onComplete.benCovPerPlanPerilError();
          },
        });
      }
      else
        this.onComplete.benCovPerPlanPeril();
    }
    catch (e) {
      console.error(e);
    }
  }

  private onComplete = {
    benCovPerPlanPeril: () => {
      this.tempData = [];
      this.getData.benCovPerPlanPeril();
      this.btns.benCovPerPlanPeril.saveDisabled = true;
      this.showForm = true;
      this._formService.hideFormLoader("ben-cov-per-plan-peril-maintenance-loading");
      this.forms.resetBenCovPerPlanPerilForm();
      this.openDialog('SUCCESS', 'Benefits and Coverage per Plan Peril details successfully saved');
    },
    benCovPerPlanPerilError: () => {
      this.showForm = true;
      this.btns.benCovPerPlanPeril.saveDisabled = false;
      this.openDialog('FAILED', 'Benefits and Coverage per Plan Peril details saving failed');
      this._formService.hideFormLoader("ben-cov-per-plan-peril-maintenance-loading");
    },
  }

  openDialog(title: any, message: any) {
    try {
      this.showForm = true;
      this._formService.hideFormLoader("ben-cov-per-plan-peril-maintenance-loading");
      this._matDialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        } 
      });
    } catch(e) {
      console.error(e);
    }
  }

  defaultTSICheck() {
    let resultCheck: boolean = true;
    if (parseFloat(this._formService.setToNumberWithDecimal(this.forms.benCovPerPlanPerilForm.get('defaultTSI')?.value)) > 99999999999999.99) {
      resultCheck = false;
    }
    else {
      resultCheck = true;
    }
    return resultCheck;
  }

  public showErrorValidator = {
    benCovPerPlanPeril: () => {
      try {
        Object.keys(this.forms.benCovPerPlanPerilForm.controls).forEach(key => {
          const controlErrors = this.forms.benCovPerPlanPerilForm.get(key)?.errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              if (keyError == 'required' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
            });
          }
        });
        this._formService.showFormMsg("ben-cov-per-plan-peril-error-message", this.errorMessage, "E");
        //this.openDialog('ERROR', this.errorMessage);
        setTimeout(()=>{                           
          this._formService.hideFormMsg("ben-cov-per-plan-peril-error-message");
        }, 10000);
      }
      catch (e) {
      console.error(e);
      }
    },
  }

  integerFormat(fieldName:string){
    try {
      if(this.forms.benCovPerPlanPerilForm.get(fieldName)?.value!==''&&this.forms.benCovPerPlanPerilForm.get(fieldName)?.value!==null){
        let fieldFloat = parseFloat(this.forms.benCovPerPlanPerilForm.get(fieldName)?.value)
        if(isNaN(fieldFloat)){
          this.forms.benCovPerPlanPerilForm.get(fieldName)?.setValue('');
        }
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  allowNumericDigitsOnly(event: any) {
    try {
      if (/[0-9.]/.test(String.fromCharCode(event.keyCode))) {
        return true;
      }
      else {
        event.preventDefault();
        return false;
      }
    }
    catch (e) {
      console.error(e);
      return undefined;
    }
  }

  allowNumericDigitsOnlyWODecimal(event: any) {
    try {
      if (/[0-9]/.test(String.fromCharCode(event.keyCode))) {
        return true;
      }
      else {
        event.preventDefault();
        return false;
      }
    }
    catch (e) {
      console.error(e);
      return undefined; 
    }
  }

}
