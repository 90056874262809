import { Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { AppMessageService } from 'src/app/services/app-message.service';
import { SecurityService } from 'src/app/services/security.service';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';
import { JsonDataService } from 'src/app/services/json-data.service';
import { OspTableComponent } from '../../common/osp-table/osp-table.component';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { LazyLoadEvent } from 'primeng/api';

@Component({
  selector: 'update-renewal-details',
  templateUrl: './update-renewal-details.component.html',
  styleUrls: ['./update-renewal-details.component.css']
})
export class UpdateRenewalDetailsComponent implements OnInit {

  @ViewChild('tbl') tbl!: OspTableComponent;
  @ViewChild('confirmationDialog') confirmationDialog!: TemplateRef<any>;

  moduleId: string = 'BMA005';

  tblConfig: any = {
    cols: [
      {
        key: "SELECT_TAG",
        header: "",
        dataType: "checkboxWithVal",
        selectAll: true,
        width: "50px",
        disabled: false,
        vals: ['Y', 'N'],
        confirmation: true,
        checked: false
      },
      {
        key: "CONTRACT_TYPE_CD",
        header: "Contract^Type",
        dataType: "string",
        width: "70px"
      },
      {
        key: "POLICY_NO",
        header: "Policy No",
        dataType: "string",
        width: "100px"
      },
      {
        key: "DISPLAY_NAME",
        header: "Insured",
        dataType: "string",
        width: "130px",
        align: 'text-l'
      },
      {
        key: "AGENT_NAME",
        header: "Agent Name",
        dataType: "string",
        width: "120px",
        align: 'text-l'
      },
      {
        key: "BMA_DESC",
        header: "BMA",
        dataType: "string",
        width: "130px",
        align: 'text-l'
      },
      {
        key: "SRC_CD_DESC",
        header: "Source Code",
        dataType: "string",
        width: "150px",
        align: 'text-l'
      },
      {
        key: "SRC_EXT_DESC",
        header: "Source Extension",
        dataType: "string",
        width: "150px",
        align: 'text-l'
      },
      {
        key: "REFERROR_DESC",
        header: "Referror",
        dataType: "string",
        width: "150px",
        align: 'text-l'
      },
      {
        key: "PROJECT_DESC",
        header: "Project Code",
        dataType: "string",
        width: "150px",
        align: 'text-l'
      },
      {
        key: "CLIENT_DESC",
        header: "Client Group",
        dataType: "string",
        width: "150px",
        align: 'text-l'
      },
      {
        key: "OLD_EXPIRY_DATE",
        header: "Expiry",
        dataType: "date",
        width: "100px"
      },
      {
        key: "TOTAL_TSI_AMT",
        header: "Sum Insured",
        dataType: "currWithoutDecimal",
        width: "120px",
        align: 'text-r'
      },
      {
        key: "TOTAL_GROSS_PREM",
        header: "Gross Premium",
        dataType: "currency",
        width: "120px",
        align: 'text-r'
      },
      {
        key: "BALANCE_AMT",
        header: "Balance",
        dataType: "currency",
        width: "100px",
        align: 'text-r'
      },
      {
        key: "MOBILE_NO",
        header: "Mobile No",
        dataType: "string",
        width: "100px",
        align: 'text-l'
      },
      {
        key: "EMAIL",
        header: "Email Address",
        dataType: "string",
        width: "120px",
        align: 'text-l'
      },
      {
        key: "DEPT_CD",
        header: "Dept Code",
        dataType: "string",
        width: "100px"
      },
      {
        key: "AGENT_CD",
        header: "Agent Code",
        dataType: "string",
        width: "100px"
      },
      {
        key: "ISS_CD",
        header: "Issuing Office",
        dataType: "string",
        width: "120px"
      },
      {
        key: "BLOCKED_REASON",
        header: "Blocking Reason",
        dataType: "string",
        width: "140px",
        align: 'text-l'
      },
      {
        key: "REMARKS",
        header: "Remarks",
        dataType: "string",
        width: "100px",
        align: 'text-l'
      },
      {
        key: "UPLOAD_USER",
        header: "Upload User",
        dataType: "string",
        width: "110px",
        align: 'text-l'
      },
      {
        key: "UPLOAD_DATE",
        header: "Upload Date",
        dataType: "date",
        width: "110px"
      },
      {
        key: "LAST_USER",
        header: "Update User",
        dataType: "string",
        width: "110px",
        align: 'text-l'
      },
      {
        key: "LAST_USER_UPDATE",
        header: "Update Date",
        dataType: "date",
        width: "110px"
      }
    ],
    tblData: [],
    selection: "single",
    paginator: true,
    rowsPerPage: 20,
    lazy: true,
    totalRecords: 15,
    scrollable: true,
    scrollableWidth: "1300px",
    pageLinks: 1
    // frozen: {}
  }

  updateRenewal: FormGroup = this.formBuilder.group({
    agent: [""],
    bma: [""],
    src: [""],
    srcExt: [null],
    referror: [""],
    project: [""],
    client: [""]
	});

  filterRenewal: FormGroup = this.formBuilder.group({
    contractTypeFilter: ["ALL"],
    uploadUserFilter: ["ALL"],
    uploadDateFrom: [""],
    uploadDateTo: [""]
	});

  retrievalParams: any = {
		type: "",
		userId: "",
    contractType: "ALL",
    uploadUser: "ALL",
    uploadDateFrom: null,
    uploadDateTo: null
	};

  lastSortedColumn: string = "";
  sorting: string = "ASC";
  selectedRow: any = null;
  saveRenewalData: any[] = [];
  rowEventData: any = null;

  tblFilterText: string = '';
  tblPage: number = 0;
  updateList: any = [];
  excludeUpdateList: any = [];
  selectAllTag: boolean = true;
  updateCtr: number = 0;
  totalBulkRecords: number = 0;

  isGetAddtlData: boolean = false;

  options: any = {
    agent: [],
    bma: [],
    src: [],
    srcExt: [],
    referror: [],
    project: [],
    client: [],
    
    contractType: [],
    uploadUser: []
  }

  constructor(
    private userDetailService: UserDetailsService,
	  private apiCallService: APICallService,
	  public formService: FormService,
	  private formBuilder: FormBuilder,
    private router: Router,
    private jsonDataService: JsonDataService,
    public dialog: MatDialog,
    private securityService: SecurityService,
    private appMessageService: AppMessageService
  ) {
    this.getDataJson();
  }

  ngOnInit(): void {
    this.retrievalParams.userId = this.userDetailService.userId || null;
  }

  getDataJson():void {

    this.options.agent = this.jsonDataService.data.agent;
    this.options.bma = this.jsonDataService.data.bma;
    this.options.referror = this.jsonDataService.data.referror;

    this.options.src = this.jsonDataService.data.src;
    this.options.srcExt = this.jsonDataService.data.srcExt;
    this.options.project = this.jsonDataService.data.project;
    this.options.client = this.jsonDataService.data.client.slice();
    // this.options.contractType = this.jsonDataService.data.contracts.slice();
    this.isGetAddtlData = this.options.referror.length == 0;
    this.options.contractType.splice(0, 0, {bmShortDesc: 'ALL'});
    this.options.uploadUser.splice(0, 0, {userId: 'ALL'});
  }

  getUploadedRenewalDataBatch(prm?: any) {
    let qryPrm: any = {};

    if(prm) {
      qryPrm = {
        first: prm.first,
        rows: prm.rows,
        search: prm.search,
        contractType: prm.contractType,
        uploadUser: prm.uploadUser,
        uploadDateFrom: prm.uploadDateFrom,
        uploadDateTo: prm.uploadDateTo,
        sortBy: prm.sortBy,
        sortDir: prm.sortDir
      }
    }

    this.tblConfig.loading = true;
    this.resetForm();
    this.jsonDataService.contorlLoading(true);
    this.apiCallService.getUploadRenewalDataBatch({
        first: qryPrm.first,
        rows: qryPrm.rows,
        search: qryPrm.search,
        contractType: qryPrm.contractType,
        uploadUser: qryPrm.uploadUser,
        uploadDateFrom: qryPrm.uploadDateFrom,
        uploadDateTo: qryPrm.uploadDateTo,
        sortBy: qryPrm.sortBy,
        sortDir: qryPrm.sortDir,
        moduleId: this.moduleId,
        userId: this.userDetailService.userId,
        type: "MODULE",
        isGetAddtlData: this.isGetAddtlData
    }).subscribe((data: any) => {
        this.securityService.checkRequestKeyResponse(data, () => {
          if (data.status === 'SUCCESS') {
            data['content'] = JSON.parse(this.jsonDataService.decrypt(data.content));

            if(this.isGetAddtlData){
              this.jsonDataService.updateAddtlData(data?.content?.jsonData || {});
              this.options.agent = this.jsonDataService.data.agent;
              this.options.bma = this.jsonDataService.data.bma;
              this.options.referror = this.jsonDataService.data.referror;
              this.isGetAddtlData = false;
            }

            this.jsonDataService.contorlLoading(false);
            this.tblConfig.loading = false;

            var rows = [];
            rows = data['content']['data'];
            this.tblConfig.totalRecords = data['content']['count'];
            this.tblConfig.pageLinks = 10;
            this.tblConfig.cols[0].checked = this.selectAllTag;

            this.options.contractType = data['content']['contracts'];
            this.options.contractType.splice(0, 0, {bmShortDesc: 'ALL'});
            
            this.options.uploadUser = data['content']['users'];
            this.options.uploadUser.splice(0, 0, {userId: 'ALL'});

            for (let i = 0; i < rows.length; i++) {
              const row = rows[i];
              
              row['SELECT_TAG'] = this.selectAllTag || (this.updateList.filter((a: any) => a.RENEWAL_ID == row['RENEWAL_ID']).length !== 0) ? 'Y' : 'N';
            }

            this.tblConfig.tblData = data['content']['data'];
          } else {
            this.jsonDataService.contorlLoading(false);
            this.tblConfig.loading = false;
            this.appMessageService.showAppMessage(data.message, "error");
          }
        });
    });
  }

  onRowClick(data: any) {
    this.updateRenewal.patchValue({
      agent: data == null ? "" : data['AGENT_CD'],
      bma: data == null ? "" : data['BMA_CD'],
      src: data == null ? "" : data['SRC_CD'],
      srcExt: data == null ? null : data['SRC_EXT_CD'],
      referror: data == null ? "" : data['REFERROR_CD'],
      project: data == null ? "" : data['PROJECT_CD'],
      client: data == null ? "" : data['CLIENT_GRP']
    });
  }

  rowEvent(data: any) {
    if(data.src && data.src == 'SELECT_TAG') {
      data['rowData'].edited = true;
      if (data['rowData'].SELECT_TAG == 'N') {
        this.updateList = this.updateList.filter((a: any) => a.RENEWAL_ID != data['rowData'].RENEWAL_ID);
        this.updateList.push(data['rowData']);

        if(this.selectAllTag) {
          this.excludeUpdateList = this.excludeUpdateList.filter((a: any) => a.RENEWAL_ID != data['rowData'].RENEWAL_ID);
        }
      } else if (data['rowData'].SELECT_TAG == 'Y') {
        this.updateList = this.updateList.filter((a: any) => a.RENEWAL_ID != data['rowData'].RENEWAL_ID);

        if(this.selectAllTag) {
          this.excludeUpdateList = this.excludeUpdateList.filter((a: any) => a.RENEWAL_ID != data['rowData'].RENEWAL_ID);
          this.excludeUpdateList.push(data['rowData']);
        }
      }
    }
  }

  toggleSelectAll(ev: any) {
    if(ev.src == 'SELECT_TAG') {
      this.tblConfig.tblData.forEach((a: any) => {
        a['SELECT_TAG'] = ev.val;
      });

      this.selectAllTag = ev.val == 'Y';
      this.tblConfig.cols[0].checked = ev.val == 'Y';
      this.updateList = [];
      this.excludeUpdateList = [];
    }
  }

  lazyLoad(ev: LazyLoadEvent) {
    let x = this.filterRenewal.getRawValue();

    if(x.uploadDateFrom && typeof x.uploadDateFrom != 'string'){
      x.uploadDateFrom = x.uploadDateFrom.format('MM/DD/YYYY');
    }

    if(x.uploadDateTo && typeof x.uploadDateTo != 'string'){
      x.uploadDateTo = x.uploadDateTo.format('MM/DD/YYYY');
    }

    setTimeout(() => {
      this.getUploadedRenewalDataBatch({
        first: ev.first,
        rows: this.tblConfig.rowsPerPage,
        search: ev.globalFilter,
        contractType: x.contractTypeFilter,
        uploadUser: x.uploadUserFilter,
        uploadDateFrom: x.uploadDateFrom == '' ? null : x.uploadDateFrom,
        uploadDateTo: x.uploadDateTo == '' ? null : x.uploadDateTo,
        sortBy: ev.sortField == undefined ? 'RENEWAL_ID' : ev.sortField,
        sortDir: ev.sortOrder == undefined ? 1 : ev.sortOrder
      });
    }, 0);
  }

  onClickUpdate() {
    // const dialogRole = this.dialog.open(this.confirmationDialog, { 
    //   width: "500px",
    //   disableClose: true
    // });

    this.prepareList(1);
  }

  confirmDialog(data: boolean) {
    if(data) {
      this.prepareList();
    }
  }

  prepareList(first?: any) {
    let y = this.updateRenewal.getRawValue();

    if(this.selectAllTag) {
      if(this.tblConfig.totalRecords == 0) {
        const dialogRole = this.dialog.open(DialogMsgComponent, { 
          disableClose: true,
          width: "500px",
          data: {
            title: 'Error',
            content: 'No record/s selected'
          }
        });

        return;
      } else if(this.tblConfig.totalRecords > 0 && first !== undefined) {
        const dialogRole = this.dialog.open(this.confirmationDialog, { 
          width: "500px",
          disableClose: true
        });

        return;
      }
      
      this.jsonDataService.contorlLoading(true);
      this.totalBulkRecords = 0;
      this.bulkUpdateRetriever({first: 0});
      return;
    } else {
      this.saveRenewalData = this.updateList.filter((a: any) => a.SELECT_TAG == 'Y').map((b: any) => {
        return {
          uploadId: b.UPLOAD_ID,
          renewalId: b.RENEWAL_ID,
          agent: y.agent,
          bma: y.bma,
          src: y.src,
          srcExt: y.srcExt,
          referror: y.referror,
          project: y.project,
          client: y.client,
          lastUser: this.retrievalParams.userId
        }
      });

      if(this.saveRenewalData.length == 0) {
        this.jsonDataService.contorlLoading(false);

        const dialogRole = this.dialog.open(DialogMsgComponent, { 
          disableClose: true,
          width: "500px",
          data: {
            title: 'Error',
            content: 'No record/s selected'
          }
        });
      } else if(this.tblConfig.totalRecords > 0 && first !== undefined) {
        const dialogRole = this.dialog.open(this.confirmationDialog, { 
          width: "500px",
          disableClose: true
        });

        return;
      } else {
        this.jsonDataService.contorlLoading(true);
        this.updateCtr = 0;
        this.proceedUpdate();
      }
    }
  }

  bulkUpdateRetriever(param: any) {
    let x = this.filterRenewal.getRawValue();

    if(x.uploadDateFrom && typeof x.uploadDateFrom != 'string'){
      x.uploadDateFrom = x.uploadDateFrom.format('MM/DD/YYYY');
    }

    if(x.uploadDateTo && typeof x.uploadDateTo != 'string'){
      x.uploadDateTo = x.uploadDateTo.format('MM/DD/YYYY');
    }

    this.apiCallService.getUploadRenewalDataBatch({
      first: param.first,
      rows: 100,
      search: this.tbl.filterText,
      contractType: x.contractTypeFilter,
      uploadUser: x.uploadUserFilter,
      uploadDateFrom: x.uploadDateFrom == '' ? null : x.uploadDateFrom,
      uploadDateTo: x.uploadDateTo == '' ? null : x.uploadDateTo,
      sortBy: 'RENEWAL_ID',
      sortDir: 1,
      moduleId: this.moduleId,
      userId: this.userDetailService.userId,
      type: "MODULE"
    }).subscribe((data: any) => {
      this.securityService.checkRequestKeyResponse(data, () => {
        data['content'] = JSON.parse(this.jsonDataService.decrypt(data.content));
        this.totalBulkRecords += data['content']['data'].length; //data['content']['count'];

        if(this.updateList.length == 0) {
          this.updateList = data['content']['data'];
        } else if(this.updateList.length > 0) {
          this.updateList = this.updateList.concat(data['content']['data']);
        }

        if(this.totalBulkRecords < data['content']['count']) {
          this.bulkUpdateRetriever({first: this.updateList.length});
        } else {
          let y = this.updateRenewal.getRawValue();
          this.updateList = this.updateList.filter((p: any) => !this.excludeUpdateList.some((e: any) => e.RENEWAL_ID == p.RENEWAL_ID));

          this.saveRenewalData = this.updateList.map((b: any) => {
            return {
              uploadId: b.UPLOAD_ID,
              renewalId: b.RENEWAL_ID,
              agent: y.agent,
              bma: y.bma,
              src: y.src,
              srcExt: y.srcExt,
              referror: y.referror,
              project: y.project,
              client: y.client,
              lastUser: this.retrievalParams.userId
            }
          });

          this.updateCtr = 0;
          this.proceedUpdate();
        }
      });
    });
  }

  proceedUpdate() {
    let el = this.saveRenewalData[this.updateCtr];

    this.apiCallService.saveRenewalDataBatch([el]).subscribe((data: any) => {
      this.securityService.checkRequestKeyResponse(data, async () => {
        try{
          let r = JSON.parse(this.jsonDataService.decrypt(data['content']));

          this.goToNext();
        } catch(error) {
          console.log(error);
          this.goToNext();
        }
      });
    });
  }

  goToNext() {
    this.updateCtr++;
    if(this.updateCtr < this.saveRenewalData.length) {
      this.proceedUpdate();
    }

    if(this.updateCtr == this.saveRenewalData.length) {
      this.jsonDataService.contorlLoading(false);

      this.lazyLoad({
        first: this.tblConfig.tblData ? 0 : this.tblConfig.tblData[0]['rownum_'] - 1,
        globalFilter: this.tbl.filterText
      });

      this.saveRenewalData = [];
      this.updateList = [];
      this.excludeUpdateList = [];
      this.updateCtr = 0;
      this.totalBulkRecords = 0;
      this.resetForm();

      const dialogRole = this.dialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: 'SUCCESS',
          content: 'Successfully updated record/s'
        }
      });
    }
  }

  enableForm(a: boolean) {
    if(a) {
      this.updateRenewal.controls["agent"].enable();
      this.updateRenewal.controls["bma"].enable();
      this.updateRenewal.controls["src"].enable();
      this.updateRenewal.controls["srcExt"].enable();
      this.updateRenewal.controls["referror"].enable();
      this.updateRenewal.controls["project"].enable();
      this.updateRenewal.controls["client"].enable();
    } else {
      this.updateRenewal.controls["agent"].disable();
      this.updateRenewal.controls["bma"].disable();
      this.updateRenewal.controls["src"].disable();
      this.updateRenewal.controls["srcExt"].disable();
      this.updateRenewal.controls["referror"].disable();
      this.updateRenewal.controls["project"].disable();
      this.updateRenewal.controls["client"].disable();
    }
  }

  resetForm() {
    this.updateRenewal.patchValue({
      agent: "",
      bma: "",
      src: "",
      srcExt: null,
      referror: "",
      project: "",
      client: ""
    });
  }

  clearTblFilter() {
    this.tbl?.clearFilter();
  }

  onClickSearch() {
    this.lazyLoad({
      first: this.tblConfig.tblData ? 0 : this.tblConfig.tblData[0]['rownum_'] - 1,
      globalFilter: this.tbl.filterText
    });
  }

}
