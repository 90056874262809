import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { APICallService } from 'src/app/services/api-call.service';
import { FormService } from 'src/app/services/form.service';
import { JsonDataService } from 'src/app/services/json-data.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { DialogMsgComponent } from '../../common/dialog-msg/dialog-msg.component';
import { LeaveConfirmation } from '../../common/guard/check.guard';
import { UserTypeLovDialogComponent } from '../../common/user-type-lov/user-type-lov.component';
import { UserLevelLovDialogComponent } from '../../common/user-level-lov/user-level-lov.component';
import { debugOutputAstAsTypeScript } from '@angular/compiler';

@Component({
  selector: 'property-si-limit-maintenance',
  templateUrl: './property-si-limit-maintenance.component.html',
  styleUrls: ['./property-si-limit-maintenance.component.css']
})
export class PropertySiLimitMaintenanceComponent implements OnInit, LeaveConfirmation {

  moduleId: string = 'BMM180';
  public moduleName: string = this._jDS.data.moduleList.find((a: any) => a.moduleId === this.moduleId)?.moduleDesc?.toUpperCase() ?? "";

  constructor(
    private fb: FormBuilder,
    private _matDialog: MatDialog,
    private _api: APICallService,
    public _formService: FormService,
    private _jDS: JsonDataService,
    private _userDetailService: UserDetailsService,
    private _securityService: SecurityService
  ) { 
    this.userIdLoggedIn = this._userDetailService.userId ?? "";
  }

  ngOnInit(): void {
    this.btns.propertySiLimit.addDisabled = true;
  }

  canDeactivate(): boolean | Observable<boolean> {
    if(this.tempData!='') {
      return false;
    } else {
      return true;
    }
  }

  /* Boolean Stuffs */

  public btns = {
    propertySiLimit: {
      addDisabled: true,
      updateDisabled: true,
      deleteDisabled: true,
      saveDisabled: true,
      userLevelDisabled: true
    }
  }

  public selections = {
    activeTypes: [
      { cd: 'A', name: 'Yes' },
      { cd: 'I', name: 'No' },
    ]
  }

  public showForm: boolean = true;

  private rowData: any;
  private tempDataRow: any;
  private errorMessage: string = "";
  private userIdLoggedIn: string;
  private tempData: any = [];

  public forms = {
    userTypeLovForm: this.fb.group({
      userType: [null],
      userTypeDisp: [null],
      userTypeField: [{ value: '', disabled: 'true' }],
    }),
    propertySiLimitForm: this.fb.group({
      userType: [null],
      userLevel: [null, [Validators.required]],
      userLevelDisp: [null],
      userLevelField: [{ value: '', disabled: 'true' }, [Validators.required]],
      siAmtMin: [null, [Validators.required]],
      siAmtMax: [null, [Validators.required]],
      remarks: [null],
      activeTag: ['A', [Validators.required]],
      userID: [{ value: '', disabled: 'true' }],
      lastUpdate: [{ value: '', disabled: 'true' }]
    }),
    resetPropertySiLimitForm: () => {
      this.forms.propertySiLimitForm.reset();
      this.forms.propertySiLimitForm.patchValue({
        activeTag: 'A',
        userType: this.forms.userTypeLovForm.get('userType')?.value
      });
      this.btns.propertySiLimit.updateDisabled = true;
      this.btns.propertySiLimit.deleteDisabled = true;
      this.btns.propertySiLimit.userLevelDisabled = false;
      this._formService.hideFormMsg("property-si-limit-error-message");
      this.rowData = null;
    },
    resetUserTypeLovForm: () => {
      this.forms.userTypeLovForm.reset();
    },
    resetAllForms: () => {
      this.forms.resetUserTypeLovForm();
      this.forms.resetPropertySiLimitForm();
    }
  }

  private propertySiLimitFormManipulate = {
    dbToForm: (data: any) => {
      return {
        userLevel: data.USER_LEVEL,
        userLevelDisp: data.USER_LEVEL_DISP,
        userLevelField: data.USER_LEVEL?.toString() + " - " + data.USER_LEVEL_DISP,
        siAmtMin: data.SI_AMT_MIN==null ? null : this._formService.setToAmountFormat(data.SI_AMT_MIN),
        siAmtMax: data.SI_AMT_MAX==null ? null : this._formService.setToAmountFormat(data.SI_AMT_MAX),
        activeTag: data.ACTIVE_TAG,
        remarks: data.REMARKS,
        userID: data.USER_ID,
        lastUpdate: data.LAST_UPDATE,
      };
    },
    formToDb: (data: any, mode: any) => {
      return {
        USER_TYPE: data.userType,
        USER_LEVEL: data.userLevel,
        USER_LEVEL_DISP: data.userLevelDisp,
        USER_LEVEL_FIELD: data.userLevel + " - " + data.userLevelDisp,
        SI_AMT_MIN: data.siAmtMin=='' || data.siAmtMin==null ? null : parseFloat(this._formService.setToNumberWithDecimal(data.siAmtMin)),
        SI_AMT_MAX: data.siAmtMax=='' || data.siAmtMax==null ? null : parseFloat(this._formService.setToNumberWithDecimal(data.siAmtMax)),
        ACTIVE_TAG: data.activeTag,
        REMARKS: data.remarks,
        USER_ID: this.userIdLoggedIn,
        LAST_UPDATE: null,
        MODE: mode
      };
    },
    formToTemp: (data: any, mode: any) => {
      return {
        USER_TYPE: data.userType,
        USER_LEVEL: data.userLevel,
        USER_LEVEL_DISP: data.userLevelDisp,
        USER_LEVEL_FIELD: data.userLevel + " - " + data.userLevelDisp,
        SI_AMT_MIN: data.siAmtMin=='' || data.siAmtMin==null ? null : parseFloat(this._formService.setToNumberWithDecimal(data.siAmtMin)).toFixed(2),
        SI_AMT_MAX: data.siAmtMax=='' || data.siAmtMax==null ? null : parseFloat(this._formService.setToNumberWithDecimal(data.siAmtMax)).toFixed(2),
        ACTIVE_TAG: data.activeTag,
        REMARKS: data.remarks,
        USER_ID: this.userIdLoggedIn,
        LAST_UPDATE: null,
        MODE: mode
      };
    }
  }

  public propertySiLimitTblConfig = {
    cols: [
      {
        key: "USER_LEVEL",
        header: "User Level Code",
        dataType: "string",
        width: '12%'
      },
      {
        key: "USER_LEVEL_DISP",
        header: "User Level",
        dataType: "string",
        width: '20%'
      },
      {
        key: "SI_AMT_MIN",
        header: "Minimum Sum Insured",
        dataType: "currency"
      },
      {
        key: "SI_AMT_MAX",
        header: "Maximum Sum Insured",
        dataType: "currency"
      },
      {
        key: "ACTIVE_TAG",
        header: "A",
        dataType: "checkbox",
        width: '64px'
      }
    ],
    tblData: [] as any[],
    selection: "single",
    paginator: true,
    rowsPerPage: 10,
    lazy: false,
    totalRecords: 15,
    loading: false,
  }

  public openLov = {
    userType: () => {
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          userId: this._userDetailService.userId,
          moduleId: this.moduleId
        },
      }
      this._matDialog.open(UserTypeLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((dataOut: any) => {
        let output = dataOut.content;
        if (output) {
          this.forms.userTypeLovForm.patchValue({
            userType: output.USER_TYPE,
            userTypeDisp: output.USER_TYPE_DESC,
            userTypeField: `${output.USER_TYPE} - ${output.USER_TYPE_DESC}`
          });
        }
        else {
          this.forms.userTypeLovForm.patchValue({
            userType: null,
            userTypeDisp: null,
            userTypeField: null
          });
        }
        this.forms.resetPropertySiLimitForm();
        this.getData.propertySiLimit();
      });
    },
    userLevel: () => {
      let userLevelExists: any[] = this.propertySiLimitTblConfig.tblData.map((row: any) => { return row.USER_LEVEL; });
      const LOVOPTIONS: { [key: string]: any } = {
        disableClose: true,
        width: '512px',
        data: {
          userId: this._userDetailService.userId,
          moduleId: this.moduleId,
          userLevelExclude: userLevelExists
        },
      };
      this._matDialog.open(UserLevelLovDialogComponent, LOVOPTIONS).afterClosed().subscribe((lovData: any) => {
        let output = lovData?.content;
        if (output) {
          this.forms.propertySiLimitForm.patchValue({
            userLevel: output.USER_LEVEL_CD,
            userLevelDisp: output.USER_LEVEL_DESC?.toString().padStart(2, 0),
            userLevelField: `${output.USER_LEVEL_CD?.toString()} - ${output.USER_LEVEL_DESC}`,
          });
        }
        else {
          this.forms.propertySiLimitForm.patchValue({
            userLevel: null,
            userLevelDisp: null,
            userLevelField: null,
          });
        }
      });
    }
  };

  private getData = {
    propertySiLimit: () => {
      try {
        this.propertySiLimitTblConfig.tblData = [];
        this.propertySiLimitTblConfig.loading = true;
        this._jDS.contorlLoading(true);
        this._api.getPropertySiLimit({
          moduleId: this.moduleId,
          userId: this._userDetailService.userId,
          type: "MODULE"
        }).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                let content = JSON.parse(this._jDS.decrypt(data.response));
                this._jDS.contorlLoading(false);
                this.propertySiLimitTblConfig.tblData = content.data.filter((d: any) => d.USER_TYPE == this.forms.userTypeLovForm.get('userType')?.value);
                this.btns.propertySiLimit.addDisabled = false;
                this.propertySiLimitTblConfig.loading = false;
                this.tempData = [];
              });
            });
          },
          error: (error: any) => {
            this.propertySiLimitTblConfig.loading = false;
            this._jDS.contorlLoading(false);
          }
        });
      }
      catch (e) {
        console.error(e);
      }
    }
  }

  onRowClick(ev: any) {
    try {
      ev.update = true;
      this.rowData = ev;
      this.populateFormFromTable();
    }
    catch (e) {
      this.forms.resetPropertySiLimitForm();
      this.rowData = null;
      //this.indexRow = null;
    }
  }

  populateFormFromTable() {
    try {
      let data = this.rowData;
      this.btns.propertySiLimit.userLevelDisabled = true;
      this.btns.propertySiLimit.updateDisabled = !data.update;
      this.btns.propertySiLimit.deleteDisabled = !data.temp;
      this.tempDataRow = !data.temp;
      this.forms.propertySiLimitForm.patchValue(this.propertySiLimitFormManipulate.dbToForm(data));
    }
    catch (e) {
      console.error(e);
    }
  }

  public onAdd() {
    try {
      this._formService.hideFormMsg("property-si-limit-error-message");
      if (!this.siAmtMinCheck()) {
        this._formService.showFormMsg("property-si-limit-error-message", "Invalid value in Minimum Sum Insured. The value entered exceeded the maximum limit.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("property-si-limit-error-message");
        }, 10000);
      }
      else if (!this.siAmtMaxCheck()) {
        this._formService.showFormMsg("property-si-limit-error-message", "Invalid value in Maximum Sum Insured. The value entered exceeded the maximum limit.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("property-si-limit-error-message");
        }, 10000);
      }
      else if (!this.siAmtMinAndMaxCheck()) {
        this._formService.showFormMsg("property-si-limit-error-message", "Maximum Sum Insured cannot be less than Minimum Sum Insured.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("property-si-limit-error-message");
        }, 10000);
      }
      else if (this.forms.propertySiLimitForm.valid) {
        let rowToBeAdded: { [key: string]: any } = this.propertySiLimitFormManipulate.formToDb(this.forms.propertySiLimitForm.value, 'I');
        let rowToBeAddedTemp: { [key: string]: any } = this.propertySiLimitFormManipulate.formToTemp(this.forms.propertySiLimitForm.value, 'I');
        rowToBeAdded.temp = true;
        this.tempData.push(rowToBeAddedTemp);
        this.propertySiLimitTblConfig.tblData = [rowToBeAdded, ...this.propertySiLimitTblConfig.tblData];
        this.forms.resetPropertySiLimitForm();
        this.btns.propertySiLimit.saveDisabled = false;
      }
      else {
        this.showErrorValidator.propertySiLimit();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public onDelete() {
    try {
      let clickData = this.rowData;
      this.propertySiLimitTblConfig.tblData = this.propertySiLimitTblConfig.tblData.filter((d: any) => {
        return d !== this.rowData;
      });
      this.tempData = this.tempData.filter(
        function(e: any) {
          return e.USER_LEVEL != clickData.USER_LEVEL;
        }
      );
      if (this.tempData==null || this.tempData=='') {
        this.btns.propertySiLimit.saveDisabled = true;
      }
      this.forms.resetPropertySiLimitForm();
    }
    catch (e) {
      console.error(e);
    }
  }

  public onUpdate() {
    try {
      this._formService.hideFormMsg("property-si-limit-error-message");
      if (!this.siAmtMinCheck()) {
        this._formService.showFormMsg("property-si-limit-error-message", "Invalid value in Minimum Sum Insured. The value entered exceeded the maximum limit.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("property-si-limit-error-message");
        }, 10000);
      }
      else if (!this.siAmtMaxCheck()) {
        this._formService.showFormMsg("property-si-limit-error-message", "Invalid value in Maximum Sum Insured. The value entered exceeded the maximum limit.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("property-si-limit-error-message");
        }, 10000);
      }
      else if (!this.siAmtMinAndMaxCheck()) {
        this._formService.showFormMsg("property-si-limit-error-message", "Maximum Sum Insured cannot be less than Minimum Sum Insured.", "E");
        setTimeout(()=>{                           
          this._formService.hideFormMsg("property-si-limit-error-message");
        }, 10000);
      }
      else if (this.forms.propertySiLimitForm.valid) {
        this.forms.propertySiLimitForm.get('userLevelField')?.enable();
        let clickData = this.rowData;
        let toBeUpdatedIndex = this.propertySiLimitTblConfig.tblData.indexOf(this.rowData);
        let propertySiLimitCdRowValue = this.rowData.USER_LEVEL;
        let rowToBeUpdated: { [key: string]: any } = this.propertySiLimitFormManipulate.formToDb(this.forms.propertySiLimitForm.value, 'U');
        let rowToBeUpdatedTemp: { [key: string]: any } = this.propertySiLimitFormManipulate.formToTemp(this.forms.propertySiLimitForm.value, 'U');
        this.forms.propertySiLimitForm.get('userLevelField')?.disable();
        let tempInsertChecker = this.tempData.find(
          (m: { USER_LEVEL: any }) => 
            m.USER_LEVEL == clickData.USER_LEVEL && clickData.MODE == 'I');
        let tempUpdateChecker = this.tempData.find(
          (m: { USER_LEVEL: any }) => 
            m.USER_LEVEL == clickData.USER_LEVEL && clickData.MODE == 'U');
        if (this.rowData.temp) {
          rowToBeUpdated.temp = true;
        }
        else {
          rowToBeUpdated.onDbButUpdatedTemp = true;
          rowToBeUpdated.USER_LEVEL = propertySiLimitCdRowValue;
        }
        if (tempInsertChecker!=null && tempInsertChecker!='') {
          //tempInsertChecker = rowToBeUpdated;
          tempInsertChecker.SI_AMT_MIN = rowToBeUpdatedTemp.SI_AMT_MIN;
          tempInsertChecker.SI_AMT_MAX = rowToBeUpdatedTemp.SI_AMT_MAX;
          tempInsertChecker.ACTIVE_TAG = rowToBeUpdatedTemp.ACTIVE_TAG;
          tempInsertChecker.REMARKS = rowToBeUpdatedTemp.REMARKS;
          tempInsertChecker.USER_ID = rowToBeUpdatedTemp.USER_ID;
          tempInsertChecker.MODE = 'I';
          rowToBeUpdatedTemp.MODE = 'I';
        }
        else if (tempUpdateChecker!=null && tempUpdateChecker!='') {
          tempUpdateChecker.SI_AMT_MIN = rowToBeUpdatedTemp.SI_AMT_MIN;
          tempUpdateChecker.SI_AMT_MAX = rowToBeUpdatedTemp.SI_AMT_MAX;
          tempUpdateChecker.ACTIVE_TAG = rowToBeUpdatedTemp.ACTIVE_TAG;
          tempUpdateChecker.REMARKS = rowToBeUpdatedTemp.REMARKS;
          tempUpdateChecker.USER_ID = rowToBeUpdatedTemp.USER_ID;
          tempUpdateChecker.MODE = 'U';
        }
        else { 
          this.tempData.push(rowToBeUpdatedTemp);
        }
        this.forms.resetPropertySiLimitForm();
        this.propertySiLimitTblConfig.tblData[toBeUpdatedIndex] = rowToBeUpdated;
        this.btns.propertySiLimit.saveDisabled = false;
        this.propertySiLimitTblConfig.tblData = [... this.propertySiLimitTblConfig.tblData];
      }
      else {
        this.showErrorValidator.propertySiLimit();
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  public onSave() {
    try {
      this.btns.propertySiLimit.saveDisabled = true;
      this.showForm = false;
      this._formService.showFormLoader(null, "property-si-limit-maintenance-loading", "Saving.<br>Please wait ...", null, null);
      if (this.tempData.length > 0) {
        this._api.savePropertySiLimit(this.tempData).subscribe({
          next: (data: any) => {
            this._securityService.checkRequestKeyResponse(data, () => {
              this._securityService.hasValidCsrfToken(data, () => {
                if (data.status === 'SUCCESS') {
                  this.onComplete.propertySiLimit();
                }
                else {
                  this.onComplete.propertySiLimitError();
                }
              });
            });
          },
          error: (data: any) => {
            this.onComplete.propertySiLimitError();
          },
        });
      }
      else
        this.onComplete.propertySiLimit();
    }
    catch (e) {
      console.error(e);
    }
  }

  private onComplete = {
    propertySiLimit: () => {
      this.tempData = [];
      this.getData.propertySiLimit();
      this.btns.propertySiLimit.saveDisabled = true;
      this.showForm = true;
      this._formService.hideFormLoader("property-si-limit-maintenance-loading");
      this.forms.resetPropertySiLimitForm();
      this.openDialog('SUCCESS', 'Property Sum Insured Limit successfully saved');
    },
    propertySiLimitError: () => {
      this.showForm = true;
      this.btns.propertySiLimit.saveDisabled = false;
      this.openDialog('FAILED', 'Property Sum Insured Limit saving failed');
      this._formService.hideFormLoader("property-si-limit-maintenance-loading");
    },
  }

  openDialog(title: any, message: any) {
    try {
      this.showForm = true;
      this._formService.hideFormLoader("property-si-limit-maintenance-loading");
      this._matDialog.open(DialogMsgComponent, { 
        disableClose: true,
        width: "500px",
        data: {
          title: title,
          content: message
        } 
      });
    } catch(e) {
      console.error(e);
    }
  }

  siAmtMinCheck() {
    try {
      let resultCheck: boolean = true;
      if (parseFloat(this._formService.setToNumberWithDecimal(this.forms.propertySiLimitForm.get('siAmtMin')?.value)) > 99999999999999.99) {
        resultCheck = false;
      }
      else {
        resultCheck = true;
      }
      return resultCheck;
    }
    catch(e) {
      console.error(e);
      return false;
    }
  }

  siAmtMaxCheck() {
    try {
      let resultCheck: boolean = true;
      if (parseFloat(this._formService.setToNumberWithDecimal(this.forms.propertySiLimitForm.get('siAmtMax')?.value)) > 99999999999999.99) {
        resultCheck = false;
      }
      else {
        resultCheck = true;
      }
      return resultCheck;
    }
    catch(e) {
      console.error(e);
      return false;
    }
  }

  siAmtMinAndMaxCheck() {
    try {
      let resultCheck: boolean = true;
      if (parseFloat(this._formService.setToNumberWithDecimal(this.forms.propertySiLimitForm.get('siAmtMin')?.value)) 
      && parseFloat(this._formService.setToNumberWithDecimal(this.forms.propertySiLimitForm.get('siAmtMax')?.value)) ) {
        if (parseFloat(this._formService.setToNumberWithDecimal(this.forms.propertySiLimitForm.get('siAmtMin')?.value)) > parseFloat(this._formService.setToNumberWithDecimal(this.forms.propertySiLimitForm.get('siAmtMax')?.value)) ) {
          resultCheck = false;
        }
        else {
          resultCheck = true;
        }
      }
      else {
        resultCheck = true;
      }
      return resultCheck;
    }
    catch(e) {
      console.error(e);
      return false;
    }
  }

  public showErrorValidator = {
    propertySiLimit: () => {
      try {
        Object.keys(this.forms.propertySiLimitForm.controls).forEach(key => {
          const controlErrors = this.forms.propertySiLimitForm.get(key)?.errors;
          if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
              if (keyError == 'required' && controlErrors[keyError] === true)
                this.errorMessage = "There are missing information. Please provide necessary information needed.";
            });
          }
        });
        this._formService.showFormMsg("property-si-limit-error-message", this.errorMessage, "E");
        //this.openDialog('ERROR', this.errorMessage);
        setTimeout(()=>{                           
          this._formService.hideFormMsg("property-si-limit-error-message");
        }, 10000);
      }
      catch (e) {
        console.error(e);
      }
    },
  }

  allowNumericDigitsOnly(event: any) {
    try {
      if (/[0-9.]/.test(String.fromCharCode(event.keyCode))) {
        return true;
      }
      else {
        event.preventDefault();
        return false;
      }
    }
    catch (e) {
      console.error(e);
      return undefined;
    }
  }

}
