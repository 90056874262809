<section id="login-tab">
    <form [formGroup]="loginDetails" ng-submit="login()">
        <div id="login-form">
            <div class="form-loader"></div>
            <div class="form-fields">
                <div class="bpi-logo">
                    <img src="../../assets/images/custom/company-logo/bpims-logo2.png" alt="bpims-logo">
                    <span class="osp-text">
                        Express Protect
                    </span>
                </div>
                <div class="login-title">
                    <p *ngIf="!showOtpForm">LOG IN TO YOUR ACCOUNT</p>
                    <p *ngIf="showOtpForm">VERIFY YOUR IDENTITY</p>
                </div>
                <ng-container *ngIf="showOtpForm">
                    <otp [parentChildCommon]="userDetails"></otp>
                </ng-container>
                <ng-container *ngIf="!showOtpForm">
                    <div class="form">
                        <div class="col-case">
                            <div class="field required">
                                <label>User ID</label>
                                <mat-form-field appearance="fill">
                                    <input matInput formControlName="un">
                                </mat-form-field>
                            </div>
                            <div class="field required">
                                <label>Password</label>
                                <mat-form-field appearance="fill">
                                    <input matInput formControlName="pw" [type]="showPasswordValue ? 'text' : 'password'">
                                    <mat-icon matSuffix (click)="showPasswordValue = !showPasswordValue">{{showPasswordValue ? 'visibility' : 'visibility_off'}}</mat-icon>
                                </mat-form-field>
                            </div>
                            <div class="field">
                                <div class="forgot-field">
                                    Forgot Password? <a href="home/forgot">Click here</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div id="loginFormMsg"></div>
        </div>
        <div *ngIf="!showOtpForm" class="form-btns">
            <button class="btn btn2" (click)="login()">LOG IN</button>
        </div>
    </form>
</section>