<section id="policy-cancellation">
    <div class="mtn-container">
        <div class="mtn-title">
            <p>{{ moduleName }}</p>
        </div>
        <osp-table [tblConfig]="policyCancellationTable" (onRowClick)="onRowClick($event)"></osp-table>

        <div id="policy-cancellation-form"><div class="form-loader"></div></div>

        <div class="form-content" [hidden]="formHider">

            <form [formGroup]="policyCancellationForm" class="form">
                <div id="policy-cancellation-error-message"></div>
                <div class="form-container-grid">
                    
                    <div class="field">
                        <label>Policy No</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="policyNo">
                    </div>

                    <div class="field">
                        <label>Plan Name</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="planName">
                    </div>

                    <div class="field">
                        <label>Assured Long Name</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="assuredLongName">
                    </div>

                    <div class="field">
                        <label>Issue Date</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="issueDate">
                    </div>

                    <div class="field">
                        <label>Effective Date</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="effectiveDate">
                    </div>

                    <div class="field">
                        <label>Expiry Date</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="expiryDate">
                    </div>

                    <div class="field">
                        <label>User ID</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="userId">
                    </div>

                    <div class="field">
                        <label>Status</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="status">
                    </div>
                </div>

                <div class="mtn-title"></div>

                <div class="form-container-grid content">
                    
                    <div class="field">
                        <label>Agent Code</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="agentCode">
                    </div>

                    <div class="field">
                        <label>Agent Name</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="agentName">
                    </div>

                    <div class="field">
                        <label>Issue Code</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="issueCode">
                    </div>

                    <div class="field">
                        <label>Source Code</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="sourceCode">
                    </div>

                    <div class="field">
                        <label>Source Ext. Code</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="sourceExtCode">
                    </div>

                    <div class="field">
                        <label>Source Ext. Name</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="sourceExtName">
                    </div>

                    <div class="field">
                        <label>Referror Code</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="referrorCode">
                    </div>

                    <div class="field">
                        <label>Referror Name</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="referrorName">
                    </div>
                </div>

                <div class="mtn-title"></div>

                <div class="form-container-grid content">
                    
                    <div class="field">
                        <label>Coverage</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="coverage" class="amount">
                    </div>

                    <div class="field">
                    </div>
                    <div class="">
                    </div>

                    <div class="field">
                        <label>Net Premium</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="netPremium" class="amount">
                    </div>

                    <div class="field">
                        <label>Total Premium</label>
                    </div>
                    <div class="">
                        <input type="text" formControlName="totalPremium" class="amount">
                    </div>
                </div>

                <div class="mtn-title"></div>

                <div class="btn-grp">
                    <button type="button" class="btn btn2" [disabled]="cancelDisabler" (click)="cancelPol.open('Return')">Cancel Policy</button>
                </div>
            </form>
        </div>
    </div>
</section>

<overlay #cancelPol id="cancel-title" [title]="'Confirmation'" class="overlay">
    <div content id="cancel-overlay">
        <div id="cancel-text">Do you really want to cancel Policy No. {{ selectedRow?.policyNo }} under <br> insured {{ selectedRow?.assuredLongName }}?</div>
        <form [formGroup]="policyCancellationForm">
            <div class="form">
                <div class="col-case">
                    <div class="field">
                        <label id="cancel-reason-text">Reason for Cancellation</label><label id="cancel-red-mark">*</label>
                        <mat-form-field class="select" appearance="fill">
                            <input matInput placeholder="Select Reason for cancellation" formControlName="reason" [matAutocomplete]="autoReason" (input)="setReasonViaInput($event)" autocomplete>
                            <mat-autocomplete #autoReason="matAutocomplete" [displayWith]="autocompleteService.setFieldText">
                                <mat-option *ngFor="let option of filteredReasonOptions | async" [value]="option">
                                    {{option.text}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>

                    <div class="field">
                        <label id="cancel-reason-text">Approved By</label>
                        <mat-form-field appearance="fill">
                            <input matInput formControlName="cancelApprovedBy">
                        </mat-form-field>
                    </div>

                    <div class="field">
                        <label id="cancel-reason-text">Remarks</label>
                        <mat-form-field appearance="fill">
                            <input matInput formControlName="cancelRemarks">
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <button mat-button buttons class="btn-primary" (click)="cancelPolicy(policyCancellationForm.getRawValue());cancelPol.close()">Proceed</button>
</overlay>